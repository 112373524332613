import React from 'react';
import PropTypes from 'prop-types';

// Logger
import ReactLogger from 'utils/ReactLogger';
const logger = new ReactLogger({level: window.REACT_LOG_LEVEL});

export const errorHandler = (error, info) => {
	logger.error('error', error);
	logger.log('info', info);
};

export class KiErrorBoundary extends React.Component {
	static propTypes = {
		error: PropTypes.object,
		message: PropTypes.string,
		errorHandler: PropTypes.func,
		children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	};

	static defaultProps = {
		error: null,
		message: 'Error rendering component',
		errorHandler: errorHandler,
	};

	// eslint-disable-next-line no-unused-vars
	static getDerivedStateFromError(error) {
		// console.log('getDerivedStateFromError', error);
		// Update state so the next render will show the fallback UI.
		return {hasError: true};
	}

	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		this.props.errorHandler(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div role="alert">
					<p>{this.props.message}</p>
					<pre>{this.props.error?.message}</pre>
				</div>
			);
		}

		return this.props.children;
	}
}

export default KiErrorBoundary;
