import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './fundingScenarioForm.theme.scss';
import KiInput from 'components/KiInput';

export const KiInputCell = ({value, id, onChange, setActiveInput, activeInput, disabled, error}) => {
	const inputRef = useRef();

	useEffect(
		() => {
			if (activeInput.id === id) {
				inputRef.current.inputNode.setSelectionRange(activeInput.cursorPosition, activeInput.cursorPosition);
			}
		},
		[activeInput]
	);

	return (
		<div className={styles.manualInput}>
			<KiInput
				value={value}
				disabled={disabled}
				onChange={val => {
					onChange(val);
					setActiveInput({id, cursorPosition: inputRef.current.inputNode.selectionStart});
				}}
				onBlur={() => {
					if (activeInput.id === id) {
						setActiveInput({id: null, cursorPosition: 0});
					}
				}}
				ref={inputRef}
				error={error}
				autoFocus={activeInput.id === id}
			/>
		</div>
	);
};

KiInputCell.propTypes = {
	value: PropTypes.string,
	id: PropTypes.string,
	onChange: PropTypes.func,
	setActiveInput: PropTypes.func,
	activeInput: PropTypes.shape({id: PropTypes.string, cursorPosition: PropTypes.number}),
	disabled: PropTypes.bool,
	error: PropTypes.string,
};
