import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import styles from './kiConfirmModal.theme.scss';
import KiButton from 'components/KiButton';

export const KiConfirmModal = props => {
	const {
		header = 'Confirm Choice',
		message = 'Are you sure?',
		acceptFunc,
		rejectFunc,
		acceptLabel = 'Accept',
		rejectLabel = 'Cancel',
		active = false,
		acceptDisabled = false,
	} = props;

	const actions = [];
	if (rejectFunc) {
		actions.push({label: rejectLabel, onClick: rejectFunc});
	}
	if (acceptFunc) {
		actions.push({label: acceptLabel, disabled: acceptDisabled, onClick: acceptFunc});
	}

	return (
		<Modal
			isOpen={active}
			contentLabel={header}
			className={`${styles.kiModalContent}`}
			overlayClassName={styles.kiModalOverlay}
		>
			<section>
				<header>{header}</header>
				<div className={styles.message}>
					{message instanceof Function ? message() : message}
					{props.children && props.children}
				</div>
			</section>
			<footer>
				{actions.map((action, idx) => {
					if (action.label === 'Cancel') {
						return (
							<KiButton
								key={idx}
								className={action.className}
								disabled={action.disabled}
								onClick={action.onClick}
								flat
								primary
							>
								{action.label}
							</KiButton>
						);
					} else {
						return (
							<KiButton
								key={idx}
								className={action.className}
								disabled={action.disabled}
								onClick={action.onClick}
								raised
								primary
							>
								{action.label}
							</KiButton>
						);
					}
				})}
			</footer>
		</Modal>
	);
};

KiConfirmModal.propTypes = {
	header: PropTypes.string,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
	acceptFunc: PropTypes.func,
	rejectFunc: PropTypes.func,
	acceptLabel: PropTypes.string,
	rejectLabel: PropTypes.string,
	active: PropTypes.bool,
	acceptDisabled: PropTypes.bool,
	children: PropTypes.node,
};

export default KiConfirmModal;
