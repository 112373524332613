import * as actionTypes from './actionTypes';

const initialState = {
	isLoading: false,
	tracks: [],
	models: [],
	assumptions: [],
	vectors: [],
};

export const Waterfall = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.WATERFALL_FETCH_TRACKS:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, tracks: action.data};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		case actionTypes.WATERFALL_FETCH_MODELS:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, models: action.data};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		case actionTypes.WATERFALL_FETCH_ASSUMPTIONS:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, assumptions: action.data};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		case actionTypes.WATERFALL_FETCH_VECTORS:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, vectors: action.data};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		default:
			return state;
	}
};

export default Waterfall;
