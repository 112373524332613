// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

// Project imports
import KiIconButton from 'components/KiIconButton';

// Local imports
import OperatorComponent from './OperatorComponent';
import ParensComponent from './ParensComponent';
import ValueComponent from './ValueComponent/';
import styles from './OperatorValueBlock.theme.scss';

function OperatorValueBlock({formula: propFormula, valueDataType, onChange, onDelete}) {
	const [formula, setFormula] = useState(propFormula);

	// On prop.formulaArray change
	useEffect(
		() => {
			setFormula(propFormula);
		},
		[propFormula]
	);

	// Update a single field
	const updateFrameworkField = (id, field, value) => {
		const index = formula.findIndex(entry => entry.id === id);
		const newFramework = _cloneDeep(formula);
		if (index > -1) {
			_set(newFramework, `[${index}].${field}`, value);
			setFormula(newFramework);
		}
		onChange(newFramework);
	};

	// Update a value and type
	const updateFrameworkValue = (id, newEntry) => {
		const index = formula.findIndex(entry => entry.id === id);
		const newFormula = _cloneDeep(formula);
		if (index > -1) {
			newFormula[index] = newEntry;
			setFormula(newFormula);
		}
		onChange(newFormula);
	};

	const renderOperatorValueBlockElements = () => {
		return formula.map(entry => {
			switch (entry.element) {
				case 'PAREN':
					return (
						<ParensComponent
							key={entry.id}
							value={entry.value}
							onChange={value => updateFrameworkField(entry.id, 'value', value)}
						/>
					);
				case 'VALUE':
					return (
						<ValueComponent
							id={entry.id}
							key={entry.id}
							isDebtFormula={true}
							formulaEntry={entry}
							valueDataType={valueDataType}
							onChange={(newValue, newType) => updateFrameworkValue(entry.id, newValue, newType)}
							isConstantAllowed={true} // Constants are always allowed in value block pairs
						/>
					);
				case 'OPERATOR':
					return (
						<OperatorComponent
							key={entry.id}
							value={entry.value}
							onChange={value => updateFrameworkField(entry.id, 'value', value)}
						/>
					);
				default:
					return <div key={entry.id}>{JSON.stringify(entry, null, 2)}</div>;
			}
		});
	};

	if (!formula) {
		return <div>Cannot render block (003). Framework not found.</div>;
	}

	return (
		<div className={styles.main}>
			{renderOperatorValueBlockElements()}
			<KiIconButton icon="cancel" onClick={onDelete} className={styles.closeButton} />
		</div>
	);
}

OperatorValueBlock.propTypes = {
	formula: PropTypes.array,
	valueDataType: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

OperatorValueBlock.defaultProps = {
	value: '',
	onChange: () => {
		return false;
	},
	onDelete: () => {
		return false;
	},
};

export default OperatorValueBlock;
