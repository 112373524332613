import React from 'react';
import PropTypes from 'prop-types';
import KiInput from 'components/KiInput';
import Select from 'react-select';
import KiCheckbox from 'components/KiCheckbox';
import {getSortFuncByField} from 'ki-common/utils/sorters';
import _ from 'lodash';

const PAY_OPTIONS_CREDIT_SUPPORT = [
	{
		label: 'Select...',
		value: '',
	},
	{
		label: 'Fee',
		value: 'fee',
	},
	{
		label: 'Due',
		value: 'due',
	},
	{
		label: 'Deferred',
		value: 'deferred',
	},
];

const PAY_OPTIONS_FEE = [
	{
		label: 'Select...',
		value: '',
	},
	{
		label: 'Due',
		value: 'due',
	},
	{
		label: 'Deferred',
		value: 'deferred',
	},
	{
		label: 'Due & Deferred',
		value: 'duedeferred',
	},
];

const PAY_OPTIONS_TRANCHE = [
	{
		label: 'Select...',
		value: '',
	},
	{
		label: 'Interest',
		value: 'dueint',
	},
	{
		label: 'Arrears',
		value: 'duearrears',
	},
	{
		label: 'Interest Arrears',
		value: 'dueintarrears',
	},
	{
		label: 'Interest And Arrears',
		value: 'interestandarrears',
	},
	{
		label: 'Principal',
		value: 'dueprin',
	},
	{
		label: 'Loss',
		value: 'loss',
	},
];

function getPayOptions(tranches, fees, creditSupports, entityId) {
	if (tranches.find(x => x._id === entityId)) {
		return PAY_OPTIONS_TRANCHE;
	}
	if (fees.find(x => x._id === entityId)) {
		return PAY_OPTIONS_FEE;
	}
	if (creditSupports.find(x => x._id === entityId)) {
		return PAY_OPTIONS_CREDIT_SUPPORT;
	}
	return [];
}

function getCalcCols(columns) {
	return columns
		.filter(col => col.isWaterfall && col.dataType === 'numeric')
		.sort(getSortFuncByField('displayName', true));
}

StepItem.propTypes = {
	step: PropTypes.object.isRequired,
	entities: PropTypes.array.isRequired,
	tranches: PropTypes.array.isRequired,
	fees: PropTypes.array.isRequired,
	creditSupports: PropTypes.array.isRequired,
	waterfallColumns: PropTypes.array.isRequired,
};

function StepItem({step, entities, tranches, fees, creditSupports, waterfallColumns}) {
	const fvCalcCols = getCalcCols(waterfallColumns);

	return (
		<div className="existing-fv-setting-form">
			<div className="step-inputs">
				<KiInput type="text" name="name" label="Description" value={step.name} disabled={true} />
				<div className="select-wrapper" style={{maxWidth: '75px'}}>
					<span style={{marginBottom: '30px'}} className={'theme-label'}>
						Step Type
					</span>
					<div style={{height: '34px', paddingTop: '10px'}}>{step.type}</div>
				</div>
				{step.type === 'pay' && (
					<div className="select-wrapper">
						<span className={'theme-label'}>Entity</span>
						<Select
							classNamePrefix="aut-select"
							value={entities.find(x => x._id === step.entity)}
							getOptionLabel={opt => {
								if (_.has(opt, 'trancheNumber')) {
									return `Tranche ${opt.classId}-${opt.trancheNumber}`;
								} else if (_.has(opt, 'supportNumber')) {
									return opt.nameId;
								}
								return opt.name;
							}}
							getOptionValue={opt => opt._id}
							options={entities}
							isDisabled={true}
						/>
					</div>
				)}
				{step.type === 'pay' && (
					<div className="select-wrapper step-pay">
						<span className={'theme-label'}>Pay</span>
						<Select
							classNamePrefix="aut-select"
							value={
								getPayOptions(tranches, fees, creditSupports, step.entity).find(
									opt => opt.value === step.pay
								) || getPayOptions(tranches, fees, creditSupports, step.entity)[0]
							}
							options={getPayOptions(tranches, fees, creditSupports, step.entity)}
							isDisabled={true}
						/>
					</div>
				)}
				{step.type === 'pay' && (
					<div className="select-wrapper">
						<span className={'theme-label'}>Override Amount</span>
						<Select
							classNamePrefix="aut-select"
							value={fvCalcCols.find(col => col._id === step.dueAmount)}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							options={fvCalcCols}
							isDisabled={true}
						/>
					</div>
				)}
				{step.type === 'assign' && (
					<div className="select-wrapper">
						<span className={'theme-label'}>Entity</span>
						<Select
							classNamePrefix="aut-select"
							value={fvCalcCols.find(x => x._id === step.entity)}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							options={fvCalcCols}
							isDisabled={true}
						/>
					</div>
				)}
				{step.type === 'assign' && (
					<div className="select-wrapper">
						<span className={'theme-label'}>Value</span>
						<Select
							classNamePrefix="aut-select"
							value={fvCalcCols.find(x => x._id === step.value)}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							options={fvCalcCols}
							isDisabled={true}
						/>
					</div>
				)}
				{(step.type === 'increase' || step.type === 'decrease') && (
					<div className="select-wrapper">
						<span className={'theme-label'}>Entity</span>
						<Select
							classNamePrefix="aut-select"
							value={fvCalcCols.find(x => x._id === step.entity)}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							options={fvCalcCols}
							isDisabled={true}
						/>
					</div>
				)}
				{(step.type === 'increase' || step.type === 'decrease') && (
					<div className="select-wrapper">
						<span className={'theme-label'}>Value</span>
						<Select
							classNamePrefix="aut-select"
							value={fvCalcCols.find(x => x._id === step.value)}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							options={fvCalcCols}
							isDisabled={true}
						/>
					</div>
				)}
				<div className="step-controls">
					<KiCheckbox checked={step.ignore} label={'Ignore Step'} className="ignore-step" disabled={true} />
				</div>
			</div>
		</div>
	);
}

export default StepItem;
