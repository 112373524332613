// Framework will be LOGIC, AND, OR rows

// Globals
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import expressionUtil from 'ki-common/utils/expressionUtil';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import uuidV4 from 'uuid/v4';

// Project imports
import ParensComponent from './ParensComponent';
import ValueComponent from './ValueComponent/';
import RelationalOperatorComponent from './RelationalOperatorComponent';
import OperatorValueBlock from './OperatorValueBlock';
import LogicComponent from './LogicComponent';
import AddOperatorValueBlockButton from './AddOperatorValueBlockButton';
import KiIconButton from 'components/KiIconButton';

// Local imports
import FormulaBuilderContext from '../FormulaBuilderContext';
import styles from './LogicAndOrBlock.theme.scss';
const cx = classNames.bind(styles);

const getOperatorValueBlock = () => {
	return {
		id: uuidV4(),
		element: 'OPERATOR_BLOCK',
		formula: [
			{
				id: uuidV4(),
				type: 'operator',
				value: '+',
				element: 'OPERATOR',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
			{
				type: 'constant',
				value: '',
				id: uuidV4(),
				element: 'VALUE',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
		],
	};
};

function LogicAndOrBlock({
	id,
	element,
	logicValue,
	framework: propFramework,
	onChange,
	onDelete,
	onElementChange,
	caseBlock,
}) {
	// Context State
	const formulaBuilderContext = useContext(FormulaBuilderContext);
	const outputFormat = formulaBuilderContext.outputFormat;
	const allColumns = formulaBuilderContext.allColumns;
	const isWaterfall = formulaBuilderContext.isWaterfall;
	const isCase = caseBlock === 'CASE';

	// If this is an THEN/ELSE line then use the outputFormat, if CASE line default
	const getValueDataType = framework => {
		if (!isCase) {
			return outputFormat;
		}
		return expressionUtil.getDataTypeFromFramework(framework, allColumns);
	};

	const getAgencyId = framework => {
		const ratingColumn = framework.find(f => f.columnType === 'absRating');
		if (ratingColumn) {
			return ratingColumn.value;
		}
	};

	// Local State
	const [framework, setFramework] = useState(propFramework);
	const [isRemovable, setIsRemovable] = useState(element !== 'LOGIC');
	const [valueDataType, setValueDataType] = useState(getValueDataType(framework));
	const [agencyId, setAgencyId] = useState(getAgencyId(framework)); // used to populate options for ratings columns

	// On prop.formulaArray change
	useEffect(
		() => {
			setFramework(propFramework);
			setValueDataType(getValueDataType(propFramework));
			setAgencyId(getAgencyId(propFramework));
		},
		[propFramework]
	);

	// On prop.element change
	useEffect(
		() => {
			setIsRemovable(element !== 'LOGIC');
		},
		[element]
	);

	// Update a single field
	const updateFrameworkField = (id, field, value) => {
		const index = framework.findIndex(entry => entry.id === id);
		const newFramework = _cloneDeep(framework);
		if (index > -1) {
			_set(newFramework, `[${index}].${field}`, value);
			setFramework(newFramework);
		}
		onChange(newFramework);
	};

	// Update a value and type
	const updateFrameworkValue = (id, newEntry) => {
		const index = framework.findIndex(entry => entry.id === id);
		const newFramework = _cloneDeep(framework);
		if (index > -1) {
			newFramework[index] = newEntry;
			setFramework(newFramework);
		}
		onChange(newFramework);
	};

	const deleteOperatorValueblock = id => {
		const newFramework = framework.filter(entry => entry.id !== id);
		setFramework(newFramework);
		onChange(newFramework);
	};

	const addOperatorValueBlock = beforeKey => {
		const newFramework = _cloneDeep(framework);
		const newBlock = getOperatorValueBlock();
		if (beforeKey === null) {
			// Add at the end of the row
			newFramework.push(newBlock);
		} else {
			// Find that key and put the new block before it
			const index = framework.findIndex(entry => entry.id === beforeKey);
			newFramework.splice(index, 0, newBlock);
		}
		setFramework(newFramework);
		onChange(newFramework);
	};

	let lockValueTypes = [];
	if (!isCase && outputFormat === 'boolean') {
		lockValueTypes = ['column', 'boolean'];
	}
	if (!isCase && outputFormat === 'numeric') {
		if (isWaterfall) {
			lockValueTypes = ['column', 'numeric'];
		} else {
			lockValueTypes = ['column', 'function', 'numeric'];
		}
	}
	if (!isCase && outputFormat === 'date_long') {
		lockValueTypes = ['column', 'date_long'];
	}

	const renderLogicFrameworkElements = () => {
		let isConstantAllowed = false;
		const toReturn = framework.reduce((elements, entry) => {
			switch (entry.element) {
				case 'PAREN':
					if (!isCase) break; // Hides parens in THEN/ELSE blocks
					elements.push(
						<ParensComponent
							key={entry.id}
							value={entry.value}
							onChange={value => updateFrameworkField(entry.id, 'value', value)}
						/>
					);
					break;
				case 'VALUE':
					elements.push(
						<ValueComponent
							id={entry.id}
							key={entry.id}
							formulaEntry={entry}
							valueDataType={valueDataType}
							onChange={(newValue, newType) => updateFrameworkValue(entry.id, newValue, newType)}
							lockValueTypes={lockValueTypes}
							isConstantAllowed={isConstantAllowed}
							agencyId={agencyId}
						/>
					);
					break;
				case 'RELATIONAL':
					isConstantAllowed = true;
					// add new operator block button (directly before relational operator)
					if (caseBlock && valueDataType === 'numeric') {
						elements.push(
							<AddOperatorValueBlockButton
								key={`${entry.id}_PLUS`}
								onClick={() => addOperatorValueBlock(entry.id)}
							/>
						);
					}
					elements.push(
						<RelationalOperatorComponent
							key={entry.id}
							value={entry.value}
							dataType={valueDataType}
							onChange={value => updateFrameworkField(entry.id, 'value', value)}
						/>
					);
					break;
				case 'OPERATOR_BLOCK':
					elements.push(
						<OperatorValueBlock
							key={entry.id}
							formula={entry.formula}
							valueDataType={valueDataType}
							onChange={value => updateFrameworkField(entry.id, 'formula', value)}
							onDelete={() => deleteOperatorValueblock(entry.id)}
						/>
					);
					break;
				default:
					elements.push(<div key={entry.id}>{JSON.stringify(entry, null, 2)}</div>);
			}
			return elements;
		}, []);
		// add new operator block button (end of logic row)
		if (valueDataType === 'numeric') {
			toReturn.push(
				<AddOperatorValueBlockButton key={`${id}_PLUS`} onClick={() => addOperatorValueBlock(null)} />
			);
		}
		return toReturn;
	};

	if (!framework) {
		return <div>Cannot render block (002). Framework not found.</div>;
	}

	// If this is an AND/OR block add a dropdown to select type before
	// and add a closing button option to remove it
	return (
		<div key={`${id}_wrapper`} className={cx('main', {removeable: isRemovable})}>
			{element !== 'LOGIC' && (
				<LogicComponent
					key={`${id}_actual`}
					value={logicValue}
					onChange={newValue => onElementChange(newValue)}
				/>
			)}
			{renderLogicFrameworkElements()}
			{element !== 'LOGIC' && <KiIconButton icon="cancel" onClick={onDelete} className={cx('closeButton')} />}
		</div>
	);
}

LogicAndOrBlock.propTypes = {
	id: PropTypes.string,
	element: PropTypes.string,
	logicValue: PropTypes.string,
	framework: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onElementChange: PropTypes.func.isRequired,
	caseBlock: PropTypes.oneOf(['CASE', 'THEN', 'ELSE', '']),
};

LogicAndOrBlock.defaultProps = {
	value: '',
	onChange: () => {
		return false;
	},
};

export default LogicAndOrBlock;
