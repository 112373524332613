import {apiUrl, _checkAuth, _handleResponse, _getHeaders, _getHeadersFile} from 'api/apiUtils';
import analytics from 'analytics';

export const fetchSubmissions = () => {
	return fetch(`${apiUrl}/submissions`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchSubmission = id => {
	analytics.sendEvent('submissions', 'get-by-id', id);
	return fetch(`${apiUrl}/submissions/${id}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const validateFullSubmission = id => {
	analytics.sendEvent('submissions', 'validate-by-id', id);
	const ts = new Date().getTime();
	return fetch(`${apiUrl}/submission/${id}/validate`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(response => {
			analytics.sendTimingEvent('submissions', 'validate', new Date().getTime() - ts);
			if (response.status !== 202) {
				return Promise.reject(response);
			}
			return Promise.resolve();
		});
};

// OLD - ${apiUrl}/submission/${id}/commit
// NEW - ${apiUrl}/submission/camunda/commitSubmission/${id}
export const commitSubmission = id => {
	analytics.sendEvent('submissions', 'commit');
	const ts = new Date().getTime();
	return fetch(`${apiUrl}/submission/camunda/commitSubmission/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(response => {
			analytics.sendTimingEvent('submissions', 'validate', new Date().getTime() - ts);
			if (response.status !== 200) {
				return Promise.reject(response);
			}
			return Promise.resolve();
		});
};

export const updateSubmissionStatus = (id, status) => {
	analytics.sendEvent('submissions', 'update-status');
	return fetch(`${apiUrl}/submission/${id}/status`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(status),
	}).then(_handleResponse);
};

export const updateSubmission = (id, submission) => {
	analytics.sendEvent('submissions', 'update');
	return fetch(`${apiUrl}/submission/${id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(submission),
	}).then(_handleResponse);
};

// Returns a submission
export const updateSubmissionAndCategorySchema = (submissionId, categorySchemaName, snapshotDate, delimiter) => {
	analytics.sendEvent('submissions', 'update-schema');
	return fetch(`${apiUrl}/submission/${submissionId}/updateSubmissionAndCategorySchema`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			categorySchemaName: categorySchemaName,
			snapshotDate: snapshotDate,
			delimiter: delimiter,
		}),
	}).then(_handleResponse);
};

export const fetchMessages = (datasetId, beginDate) => {
	return fetch(`${apiUrl}/messages/${datasetId}?beginDate=${beginDate}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const downloadFile = submissionId => {
	return fetch(`${apiUrl}/submission/downloadSubmission/${submissionId}`, {
		credentials: 'include',
		headers: _getHeadersFile(),
		method: 'GET',
	}).then(_handleResponse);
};

export default {
	fetchSubmissions,
	fetchSubmission,
	validateFullSubmission,
	commitSubmission,
	updateSubmissionStatus,
	updateSubmission,
	updateSubmissionAndCategorySchema,
	fetchMessages,
	downloadFile,
};
