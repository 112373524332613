import React from 'react';
import PropTypes from 'prop-types';
import {formatDate} from '../../../../utils/dateUtils';
import {renderPostingItems} from '../../ledgerUtils';

function JournalTableItem({item}) {
	const {postingDate, effectiveDate, postCode, description, postings} = item;

	return (
		<tr className="scenario-list-item">
			<td className="fixed-width-date">{formatDate(postingDate)}</td>
			<td className="fixed-width-date">{formatDate(effectiveDate)}</td>
			<td className="fixed-width" title={postCode}>
				{postCode}
			</td>
			<td className="cell-ellipsis" title={description}>
				{description}
			</td>
			<td className="cell-ellipsis">{renderPostingItems(postings, 'lineItem')}</td>
			<td className="fixed-width align-right">{renderPostingItems(postings, 'account')}</td>
			<td className="fixed-width align-right">{renderPostingItems(postings, 'metadata.account-code')}</td>
			<td className="fixed-width align-right">{renderPostingItems(postings, 'debit', true)}</td>
			<td className="fixed-width align-right">{renderPostingItems(postings, 'credit', true)}</td>
		</tr>
	);
}

JournalTableItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default JournalTableItem;
