// Globals
import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports
import KiInput from 'components/KiInput';
import KiColumnSelectModal from 'components/KiColumnSelectModal';

// Local imports
import FormulaBuilderContext from '../../FormulaBuilderContext';
import styles from './ValueComponent.theme.scss';

function ColumnValue({entry, valueDataType, onValueChange, onOffsetChange}) {
	// =============
	// Context State
	// =============
	const formulaBuilderContext = useContext(FormulaBuilderContext);
	const isWaterfall = formulaBuilderContext.isWaterfall;
	const allColumns = formulaBuilderContext.allColumns;

	// ===========
	// Local State
	// ===========
	const [offsetValue, setOffsetValue] = useState(isNaN(parseInt(entry.offset)) ? 0 : parseInt(entry.offset));

	const getValueOptions = () => {
		// Filter out columns by data type
		return allColumns.filter(col => {
			if (valueDataType) {
				return col.dataType === valueDataType;
			}
			return true;
		});
	};

	// Need to map hardcoded options for waterfall values to unique ID's
	// used by the KiColumnSelectModal
	const getEntryValue = () => {
		if (entry.entityId) {
			return `${entry.entityId}_${entry.value}`;
		}
		return entry.value;
	};

	// If this is waterfall and of a system column type then convert it to
	// use the correct value instead of the _id
	const convertToEntryValue = selection => {
		if (selection && selection.entityId) {
			const noMutate = _.cloneDeep(selection);
			noMutate._id = selection.value;
			return noMutate;
		}
		return selection;
	};

	const selectOptions = getValueOptions();
	const offsetColTypes = [
		'poolAggregate',
		'debtInputFV',
		'debtInputTranche',
		'debtInputCreditSupport',
		'debtInputFee',
		'debtInputTrigger',
	];
	return (
		<React.Fragment>
			<div className={styles.valueFormElementColumn}>
				<KiColumnSelectModal
					isMultiselect={false}
					columnList={selectOptions}
					value={getEntryValue()}
					onSelect={selected => {
						onValueChange(convertToEntryValue(selected));
					}}
					modalColumns={{
						showEntityType: true,
						showEntityName: isWaterfall, // Waterfall shows entity name
					}}
				/>
			</div>
			{!isWaterfall &&
				offsetColTypes.includes(_.get(entry, 'columnType', '')) && (
					<div className={styles.valueFormElementOffset}>
						<KiInput
							label="Offset"
							isNumberMasked={true}
							maskConfig={{
								allowDecimal: false,
								integerLimit: 3,
							}}
							value={offsetValue}
							// Store offset in local state here until the onBlur is triggered
							onChange={val => setOffsetValue(val)}
							onBlur={val => onOffsetChange(val.target.value)}
						/>
					</div>
				)}
		</React.Fragment>
	);
}

ColumnValue.propTypes = {
	entry: PropTypes.object,
	valueDataType: PropTypes.oneOf(['string', 'numeric', 'date_long', 'boolean', 'rating', '']),
	onValueChange: PropTypes.func.isRequired,
	onOffsetChange: PropTypes.func.isRequired,
};

ColumnValue.defaultProps = {
	value: '',
	offsetValueParam: '',
};

export default ColumnValue;
