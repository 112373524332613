const HolidaysParser = require('date-holidays-parser');
const Holidays = require('date-holidays');

const _merge = require('lodash/merge');
const _omit = require('lodash/omit');

const defaultHolidays = new Holidays();

const createCustomData = (days = {}) => {
	return Object.assign({}, defaultHolidays.__data.data, {
		holidays: {
			CUSTOM: {
				name: 'Custom Calendar',
				langs: ['en'],
				days: days || {},
			},
		},
	});
};

const defaultHolidayOptions = Object.entries(defaultHolidays.getCountries('en') || {}).reduce(
	(acc, [countryCode, countryLabel]) => {
		return [
			...acc,
			{
				label: countryLabel,
				value: countryCode.toString(),
			},
			...Object.entries(defaultHolidays.getStates(countryCode, 'en') || {}).reduce(
				(stateAcc, [stateCode, stateLabel]) => {
					return [
						...stateAcc,
						{
							label: `${countryCode} - ${stateLabel}`,
							value: `${countryCode}.${stateCode}`.toString(),
						},
						...Object.entries(defaultHolidays.getRegions(countryCode, stateCode, 'en') || {}).reduce(
							(regionAcc, [regionCode, regionLabel]) => {
								return [
									...regionAcc,
									{
										label: `${countryCode} - ${stateCode} - ${regionLabel}`,
										value: `${countryCode}.${stateCode}.${regionCode}`.toString(),
									},
								];
							},
							[]
						),
					];
				},
				[]
			),
		];
	},
	[]
);

class CustomHolidays extends HolidaysParser {
	_dateByRule(year, rule) {
		const _rule = this.holidays[rule];
		const dates = _rule.fn
			.inYear(year)
			.get(this.__timezone)
			.map(date => {
				const odate = _merge(
					{rule, disabled: !!date.disabled}, // add in rule to date results so we can match with _data
					_omit(date, ['substitute']),
					_omit(_rule, ['fn', 'enable', 'disable', 'substitute', 'active'])
				);
				if (_rule.substitute && date.substitute) {
					odate.substitute = true;
				}
				return odate;
			});
		return dates;
	}

	setDisabledByRule(rule, isActive) {
		const _rule = this.holidays[rule];
		if (!_rule) {
			return false;
		}
		if (isActive) {
			_rule.disabled = true;
		} else {
			delete _rule.disabled;
		}
	}

	getRules() {
		return Object.entries(this.holidays).reduce((acc, [ruleStr, holiday]) => {
			acc[ruleStr] = _omit(holiday, ['fn']);
			return acc;
		}, Object.assign({}, this.holidays));
	}

	getData() {
		return createCustomData(this.getRules());
	}
}

module.exports = {
	defaultHolidayOptions,
	DefaultHolidays: new Holidays(),
	Holidays: CustomHolidays,
	createCustomData,
};
