import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import KiSelect from '../../../../../components/KiSelect';
import styles from './ReplineRuleForm.theme.scss';
import KiIconButton from '../../../../../components/KiIconButton';
import classNames from 'classnames';
import KiInput from '../../../../../components/KiInput';
import KiDatePicker from '../../../../../components/KiDatePicker';
import {compareDates, formatDate} from '../../../../../utils/dateUtils';
import CreatableSelect from 'react-select/creatable';
import {removeFromArray} from '../../../../../utils/arrayUtils';
import FormBlockItemContainer from '../../common/FormBlockItemContainer';
import SidebarCreationSelect from '../../common/SidebarCreationSelect';
import AssumptionForm from '../../Assumptions/AssumptionForm';
import NotionalPoolForm from '../../NotionalPools/NotionalPoolForm';

const ALLOWED_FILTERS_LENGTH = 3;

const OPERATORS_NUMERIC = [
	{value: '<', label: '<', filterNumber: true},
	{value: '>', label: '>', filterNumber: true},
	{value: '<>', label: '<>', filterNumber: true},
	{value: '<=', label: '<=', filterNumber: true},
	{value: '>=', label: '>=', filterNumber: true},
	{value: '=', label: '=', filterNumber: true},
	{value: 'range', label: 'Range', minMaxNumber: true},
];

const OPERATORS_STRING = [
	{value: 'equals', label: 'equals', filterString: true},
	{value: 'in', label: 'in', filterStringArray: true},
	{value: 'not in', label: 'not in', filterStringArray: true},
	{value: 'contains', label: 'contains', filterString: true},
];

const OPERATORS_DATE = [
	{value: 'min', label: 'min', filterDate: true},
	{value: 'max', label: 'max', filterDate: true},
	{value: 'range', label: 'range', minmaxDate: true},
];

const OPERATORS_BY_TYPE = {
	numeric: OPERATORS_NUMERIC,
	string: OPERATORS_STRING,
	date_short: OPERATORS_DATE,
	date_long: OPERATORS_DATE,
};

const ERROR_MESSAGE_REQUIRED = 'Please fill';
const ERROR_MESSAGE_RANGE = 'Wrong range';

const ReplineRuleForm = ({
	rule,
	index,
	onDelete,
	parameters,
	onRuleChange,
	assumptions,
	selectedBaseAssumption,
	reloadAssumptions,
	notionalPoolOptions,
	selectedNotionalPools,
	reloadNotionalPools,
}) => {
	const isFilterValid = newFilter => {
		const areAllFilled = Object.keys(newFilter)
			.map(key => {
				const item = newFilter[key];
				return Array.isArray(item) ? !!item.length : !!item;
			})
			.reduce((acc, cur) => acc && cur);

		const hasOneOfRequired = newFilter.filter || (newFilter.min && newFilter.max);
		if (areAllFilled && hasOneOfRequired) {
			if (newFilter.operator === 'range') {
				if (newFilter.dataType === 'numeric') {
					return newFilter.max > newFilter.min;
				} else {
					return !compareDates(newFilter.min, newFilter.max);
				}
			} else {
				return true;
			}
		}

		return false;
	};

	const isRuleValid = newRule => {
		return (
			!!newRule.assumptionKey &&
			newRule.filters.map(filter => isFilterValid(filter)).reduce((acc, cur) => acc && cur)
		);
	};

	const addNewFilter = () => {
		const newFilters = [...rule.filters, {type: null, operator: null}];
		onRuleChange({...rule, filters: newFilters}, index, false);
	};

	const removeFilter = filterIndex => {
		const newRule = {...rule, filters: removeFromArray(rule.filters, filterIndex)};
		onRuleChange(newRule, index, isRuleValid(newRule));
	};

	const onParameterChange = (value, filterIndex) => {
		const newFilters = [
			...rule.filters.slice(0, filterIndex),
			{type: value._id, fieldType: value.dataType, operator: null},
			...rule.filters.slice(filterIndex + 1),
		];
		onRuleChange({...rule, filters: newFilters}, index, false);
	};

	const onFilterPropChange = (value, filterIndex, propName, isInt = false, removeOthers = false) => {
		const correctValue = isInt && value ? parseInt(value) : value;
		const currentFilter = rule.filters[filterIndex];
		const basicFilter = removeOthers
			? {type: currentFilter.type, fieldType: currentFilter.fieldType}
			: rule.filters[filterIndex];
		const newFilters = [
			...rule.filters.slice(0, filterIndex),
			{...basicFilter, [propName]: correctValue},
			...rule.filters.slice(filterIndex + 1),
		];
		const newRule = {...rule, filters: newFilters};
		onRuleChange(newRule, index, isRuleValid(newRule));
	};

	const getDateFromValue = value => (value ? formatDate(value) : '');

	const onAssumptionChange = value => {
		const newRule = {...rule, assumptionKey: value};
		onRuleChange(newRule, index, isRuleValid(newRule));
	};

	const onAssumptionCreated = savedAssumption => {
		reloadAssumptions();
		onAssumptionChange(savedAssumption._id);
	};

	const onNotionalPoolChange = value => {
		const newRule = {...rule, notionalPool: value};
		onRuleChange(newRule, index, isRuleValid(newRule));
	};

	const onNotionalPoolCreated = saved => {
		reloadNotionalPools();
		onNotionalPoolChange(saved._id);
	};

	const assumptionOptions = assumptions.map(assumption => ({value: assumption._id, label: assumption.name}));
	const assumptionValue = assumptionOptions.find(item => item.value === rule.assumptionKey);
	const notionalPoolValue = notionalPoolOptions.find(item => item.value === rule.notionalPool);
	const parameterOptions = parameters.map(parameter => ({
		value: parameter,
		label: parameter.detailedDisplayName,
	}));

	return (
		<FormBlockItemContainer title={`Rule ${index + 1}`} onDelete={() => onDelete(index)} isVisible={true}>
			{rule.filters.map((filter, filterIndex) => {
				const parameterValue = parameterOptions.find(param => param.value._id === filter.type);
				const operatorOptions = filter.fieldType ? OPERATORS_BY_TYPE[filter.fieldType] : [];
				const operatorValue = filter.operator
					? operatorOptions.find(item => item.value === filter.operator)
					: null;
				const filterStringArrayOptions =
					filter.filter && Array.isArray(filter.filter)
						? filter.filter.map(value => ({label: value, value: value}))
						: [];
				const filterStringArrayValue = filterStringArrayOptions;
				const isMaxNumberError = operatorValue?.minMaxNumber && filter.max <= filter.min;
				const isMaxDateError = operatorValue?.minmaxDate && compareDates(filter.min, filter.max);

				return (
					<Fragment key={filterIndex}>
						<div className={classNames('sidebar-form-section', styles.ruleParameterContainer)}>
							{filterIndex > 0 && <div className={styles.ruleParameterConnector}>AND</div>}
							<div className={styles.ruleParameterSelector}>
								<span className="form-instruction">Parameter {filterIndex + 1}</span>
								<KiSelect
									options={parameterOptions}
									onChange={option => onParameterChange(option.value, filterIndex)}
									value={parameterValue}
								/>
							</div>
							{rule.filters.length > 1 && (
								<span className={styles.ruleParameterDelete}>
									<i
										title="Remove Parameter"
										className="material-icons"
										onClick={() => removeFilter(filterIndex)}
									>
										remove_circle
									</i>
								</span>
							)}
						</div>
						{filter.type && (
							<div className={classNames('sidebar-form-section', styles.ruleParameterOperators)}>
								<KiSelect
									options={operatorOptions}
									onChange={option =>
										onFilterPropChange(option.value, filterIndex, 'operator', false, true)
									}
									value={operatorValue}
								/>
								<div className={styles.ruleParameterInputs}>
									{operatorValue?.filterNumber && (
										<KiInput
											className="substring-size-input"
											type="number"
											value={filter.filter || ''}
											onChange={value => onFilterPropChange(value, filterIndex, 'filter', true)}
											placeholder="Number"
											error={filter.filter === '' && ERROR_MESSAGE_REQUIRED}
										/>
									)}
									{operatorValue?.minMaxNumber && (
										<KiInput
											className="substring-size-input"
											type="number"
											onChange={value => onFilterPropChange(value, filterIndex, 'min', true)}
											placeholder="Minimum number"
											error={filter.min === '' && ERROR_MESSAGE_REQUIRED}
										/>
									)}
									{operatorValue?.minMaxNumber && (
										<KiInput
											className={classNames('substring-size-input', styles.minMax)}
											type="number"
											value={filter.max || ''}
											onChange={value => onFilterPropChange(value, filterIndex, 'max', true)}
											placeholder="Maximum number"
											error={
												filter.max === ''
													? ERROR_MESSAGE_REQUIRED
													: isMaxNumberError && ERROR_MESSAGE_RANGE
											}
										/>
									)}
									{operatorValue?.filterDate && (
										<KiDatePicker
											value={filter.filter || ''}
											onChange={value =>
												onFilterPropChange(getDateFromValue(value), filterIndex, 'filter')
											}
											error={filter.filter === '' && ERROR_MESSAGE_REQUIRED}
											className={styles.withErrorMessage}
										/>
									)}
									{operatorValue?.minmaxDate && (
										<KiDatePicker
											value={filter.min || ''}
											onChange={value =>
												onFilterPropChange(getDateFromValue(value), filterIndex, 'min')
											}
											placeholderText="Minimum Date"
											error={filter.min === '' && ERROR_MESSAGE_REQUIRED}
										/>
									)}
									{operatorValue?.minmaxDate && (
										<KiDatePicker
											value={filter.max || ''}
											onChange={value =>
												onFilterPropChange(getDateFromValue(value), filterIndex, 'max')
											}
											placeholderText="Maximum Date"
											className={styles.minMax}
											error={
												filter.max === ''
													? ERROR_MESSAGE_REQUIRED
													: isMaxDateError && ERROR_MESSAGE_RANGE
											}
										/>
									)}
									{operatorValue?.filterString && (
										<KiInput
											value={filter.filter || ''}
											onChange={value => onFilterPropChange(value, filterIndex, 'filter')}
											placeholder="Text"
											error={filter.filter === '' && ERROR_MESSAGE_REQUIRED}
										/>
									)}
									{operatorValue?.filterStringArray && (
										<div className={styles.withErrorMessage}>
											<CreatableSelect
												isMulti
												value={filterStringArrayValue}
												options={filterStringArrayOptions}
												onChange={values =>
													onFilterPropChange(
														(values || []).map(item => item.value),
														filterIndex,
														'filter'
													)
												}
												placeholder="Add Text"
											/>
											{filter.filter &&
												filterStringArrayValue.length === 0 && (
													<span className="ki-select-error">{ERROR_MESSAGE_REQUIRED}</span>
												)}
										</div>
									)}
								</div>
							</div>
						)}
					</Fragment>
				);
			})}
			{ALLOWED_FILTERS_LENGTH - rule.filters.length > 0 && (
				<div className={styles.addRuleParameter}>
					<KiIconButton
						icon="add_circle"
						id="addRuleParamButton"
						onClick={addNewFilter}
						title="Click to add Parameter"
					/>
					<label htmlFor="addRuleParamButton">
						Add Parameter ({ALLOWED_FILTERS_LENGTH - rule.filters.length} left)
					</label>
				</div>
			)}
			<div className="sidebar-form-section">
				<span className="form-instruction">Assumption</span>
				<SidebarCreationSelect
					value={assumptionValue}
					onChange={option => onAssumptionChange(option.value)}
					addLabel="Add New Assumption"
					options={assumptionOptions}
					onCreated={onAssumptionCreated}
					isOptionDisabled={option => option.value === selectedBaseAssumption}
				>
					<AssumptionForm />
				</SidebarCreationSelect>
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">Notional Pool</span>
				<SidebarCreationSelect
					value={notionalPoolValue}
					onChange={option => onNotionalPoolChange(option.value)}
					addLabel="Add New Notional Pool"
					options={notionalPoolOptions}
					onCreated={onNotionalPoolCreated}
					isOptionDisabled={option =>
						option.value !== rule.notionalPool &&
						option.value &&
						selectedNotionalPools.includes(option.value)
					}
				>
					<NotionalPoolForm />
				</SidebarCreationSelect>
			</div>
		</FormBlockItemContainer>
	);
};

ReplineRuleForm.propTypes = {
	rule: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	onDelete: PropTypes.func.isRequired,
	parameters: PropTypes.array.isRequired,
	onRuleChange: PropTypes.func.isRequired,
	assumptions: PropTypes.array,
	selectedBaseAssumption: PropTypes.string.isRequired,
	notionalPoolOptions: PropTypes.array,
	selectedNotionalPools: PropTypes.array,
	reloadAssumptions: PropTypes.func.isRequired,
	reloadNotionalPools: PropTypes.func.isRequired,
};

export default ReplineRuleForm;
