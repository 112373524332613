import * as actionTypes from './actionTypes';
import {associatedDataApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';

const fetchAssociatedDataList = datasetId => {
	return dispatch => {
		dispatch({type: actionTypes.ASSOCIATED_DATA_LIST_FETCH_FOR_DATASET_ID, status: 'pending'});

		return associatedDataApi
			.fetchAssociatedDataByDatasetId(datasetId)
			.then(associatedDataList =>
				dispatch({
					type: actionTypes.ASSOCIATED_DATA_LIST_FETCH_FOR_DATASET_ID,
					status: 'success',
					associatedDataList: associatedDataList,
				})
			)
			.catch(err =>
				dispatch({type: actionTypes.ASSOCIATED_DATA_LIST_FETCH_FOR_DATASET_ID, status: 'error', msg: err})
			);
	};
};

const deleteAssociatedData = associatedData => {
	return dispatch => {
		dispatch({type: actionTypes.ASSOCIATED_DATA_DELETE_BY_ID, status: 'pending'});

		return associatedDataApi
			.deleteAssociatedData(associatedData)
			.then(() => {
				dispatch(showSnackbar(`Deleted ${associatedData.fileName} successfully`));
				dispatch(fetchAssociatedDataList(associatedData.datasetId));
			})
			.catch(err => dispatch({type: actionTypes.ASSOCIATED_DATA_DELETE_BY_ID, status: 'error', msg: err}));
	};
};

export {fetchAssociatedDataList, deleteAssociatedData};
