// Globals
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

// Ki imports
import KiProgressBar from 'components/KiProgressBar';
import KiAppBar from 'components/KiAppBar';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import KiConfirmModal from 'components/KiConfirmModal';
import {fetchDataset} from 'containers/datasetList/actions';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {fetchDates} from 'components/FlyoutDates/actions';
import {showSnackbar} from 'state/actions/Snackbar';
import {columnServiceApi, manualDebtInputsApi} from 'api';

// Local imports
import './DebtInputMaintenance.scss';
import DebtInputForm from './components/DebtInputForm';
import {fetchAllTemplates, createTemplate, updateTemplate, deleteTemplate} from './actions';

export class DebtInputMaintenance extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		match: PropTypes.object,
		fetchDataset: PropTypes.func,
		fetchFundingVehicleList: PropTypes.func,
		dataset: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		fetchAllTemplates: PropTypes.func,
		createTemplate: PropTypes.func,
		updateTemplate: PropTypes.func,
		deleteTemplate: PropTypes.func,
		allSavedTemplates: PropTypes.array.isRequired,
		showSnackbar: PropTypes.func.isRequired,
		templateNames: PropTypes.array,
		fundingVehicleList: PropTypes.array,
		fetchDates: PropTypes.func,
		datasetDates: PropTypes.array,
	};

	state = {
		searchTerm: '',
		isDeleteConfirmActive: false,
		templateToDelete: null,
		templateUsageMessages: [],
		searchIsActive: {
			label: 'Manual Inputs',
			value: true,
		},
		availableColumns: [],
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Debt Input Maintenance`;
		const {
			match: {params},
			fetchDataset,
			fetchFundingVehicleList,
			fetchAllTemplates,
			fetchDates,
		} = this.props;

		if (params.datasetId) {
			return Promise.all([
				fetchDataset(params.datasetId, false),
				fetchFundingVehicleList(params.datasetId),
				fetchDates(params.datasetId),
				this.fetchAvailableDebtInputColumns(params.datasetId),
			]).then(results => {
				if (results[0].datasetId) {
					return fetchAllTemplates(results.length && results[0].datasetId);
				}
			});
		}
	}

	fetchAvailableDebtInputColumns = async datasetId => {
		const debtCalcCols = await columnServiceApi.getColumnsFromService(datasetId, {
			sources: {includeDebtCalculations: true},
		});
		const debtInputTemplates = await manualDebtInputsApi.fetchTemplates(datasetId);
		return this.setState({
			availableColumns: [
				...debtCalcCols,
				// mongo query for getTemplatesByDatasetId adds columnName to the templateName for some reason. we don't want here because it'll get persisted on updates
				...debtInputTemplates.map(dit => _.omit(dit, 'columnName')),
			],
		});
	};

	onSaveDebtTemplateDefinition = values => {
		values.datasetId = this.props.dataset.datasetId;
		if (!values.viewType) {
			values.viewType = 'fundingVehicle';
		}
		if (!values.isActive && values.isActive !== false) {
			values.isActive = true;
		}
		if (values._id) {
			return this.props
				.updateTemplate(values)
				.then(() => {
					this.fetchAvailableDebtInputColumns(this.props.dataset.datasetId);
					this.props.showSnackbar('Debt input template saved successfully.');
				})
				.catch(() => {
					this.props.showSnackbar('Error saving debt input template.');
				});
		}

		// save regular new debt input
		if (!values.isAccount || values.isNewMiddleColumn) {
			delete values.beginningBalanceCol;
			delete values.endingBalanceCol;
			values.accountColumns = null;
			// values.name = `${}`
			return this.props
				.createTemplate(values)
				.then(() => {
					this.fetchAvailableDebtInputColumns(this.props.dataset.datasetId);
					this.props.showSnackbar('Debt input template saved successfully.');
				})
				.catch(() => {
					this.props.showSnackbar('Error saving debt input template.');
				});
		}
		if (!values.accountColumns) {
			values.accountColumns = [];
		}
		// save account style debt input with begining ending cols
		// TODO: move this to the action file?
		const beginningBalanceCol = {
			datasetId: values.datasetId,
			name: `${values.name}_Beginning_Bal`,
			dataType: 'numeric',
			defaultValue: '0.00',
			fundingVehicles: values.fundingVehicles,
			viewType: values.viewType,
			isStatic: false,
			isAccount: true,
			isAccountBalance: true,
			isAsset: values.isAsset,
		};

		const endingBalanceCol = {
			datasetId: values.datasetId,
			name: `${values.name}_Ending_Bal`,
			dataType: 'numeric',
			defaultValue: '0.00',
			fundingVehicles: values.fundingVehicles,
			viewType: values.viewType,
			isStatic: false,
			isAccount: true,
			isAccountBalance: true,
			isAsset: values.isAsset,
		};

		return Promise.all([
			this.props.createTemplate(beginningBalanceCol, false),
			this.props.createTemplate(endingBalanceCol, false),
		])
			.then(results => {
				values.beginningBalanceCol = results[0]._id;
				values.endingBalanceCol = results[1]._id;
				return this.props.createTemplate(values);
			})
			.then(() => {
				this.props.showSnackbar(
					'Register input template with beginning and ending balance templates saved successfully.'
				);
			})
			.catch(() => {
				this.props.showSnackbar('Error saving debt input template.');
			});
	};

	onDeleteTemplateDefinition = (templateId, confirmed = false) => {
		if (!confirmed) {
			return this.setState({
				templateToDelete: templateId,
				isDeleteConfirmActive: true,
				templateUsageMessages: [],
			});
		}
		return this.props.deleteTemplate(this.props.match.params.datasetId, templateId).then(response => {
			if (response.messages) {
				// template is in use, display to user and disallow delete
				this.setState({templateUsageMessages: response.messages});
			} else {
				this.setState({
					isDeleteConfirmActive: false,
					templateToDelete: null,
					templateUsageMessages: [],
				});
			}
		});
	};

	getDeleteConfirmMessage = (filteredTemplates, templateToDelete, templateUsageMessages) => {
		if (!templateToDelete || !filteredTemplates || !filteredTemplates.length) {
			return '';
		}

		const templateName = filteredTemplates.find(t => t._id === templateToDelete).name;
		const message = templateUsageMessages.length
			? `Template ${templateName} may not be deleted`
			: `Are you sure you want to delete Register Template: ${templateName}`;
		return (
			<div>
				<div>{message}</div>
				{!!(templateUsageMessages && templateUsageMessages.length) && (
					<ul>{templateUsageMessages.map((m, idx) => <li key={idx}>{m}</li>)}</ul>
				)}
			</div>
		);
	};

	render() {
		const {
			match: {params},
			dataset,
			history,
			fundingVehicleList,
			allSavedTemplates,
			templateNames,
		} = this.props;
		const {searchTerm, searchIsActive, templateToDelete, isDeleteConfirmActive, templateUsageMessages} = this.state;
		let filteredTemplates = [...allSavedTemplates].filter(
			t => !(t.isAccount && !t.accountColumns) //&& !t.isTrancheAccountTemplate
		);

		if (searchTerm.length) {
			const textToMatch = searchTerm.toUpperCase();
			filteredTemplates = filteredTemplates.filter(template => {
				return template.name.toUpperCase().indexOf(textToMatch) !== -1;
			});
		}
		if (searchIsActive && searchIsActive.value !== null) {
			filteredTemplates = filteredTemplates.filter(template => {
				return template.editTemplate === searchIsActive.value;
			});
		}

		if (!_.get(dataset, 'datasetId')) {
			return <KiProgressBar />;
		}

		return (
			<section className="debt-input-maintenance">
				<header>
					<KiAppBar className="top-bar">
						<div className="top-bar-breadcrumb">
							<h1 className="link" onClick={() => history.push('/datasets')}>{`Datasets`}</h1>
							<h1
								className="link"
								onClick={() => history.push(`/dataExplorer/${params.datasetId}`)}
							>{` > ${dataset.name || ''}`}</h1>
							<h1>{` > Manage Debt Fields`}</h1>
						</div>
					</KiAppBar>
				</header>
				<div className="ki-panel">
					<div className="debt-input-search">
						<KiSelect
							name={'searchIsActive'}
							options={[
								{label: 'All', value: null},
								{label: 'System Inputs', value: false},
								{label: 'Manual Inputs', value: true},
							]}
							value={searchIsActive}
							className="search-select"
							onChange={val => this.setState({searchIsActive: val})}
						/>
						<KiInput
							type="text"
							name="legalName"
							label="Search"
							value={this.state.searchTerm}
							onChange={val => this.setState({searchTerm: val})}
							maxLength={100}
						/>
					</div>
					<div className="debt-input-new">
						<DebtInputForm
							debtColumns={this.state.availableColumns}
							datasetDates={this.props.datasetDates}
							templateNames={templateNames}
							formValues={{}}
							onSave={this.onSaveDebtTemplateDefinition}
							fundingVehicles={fundingVehicleList.filter(fv => fv.datasetId === dataset.datasetId)}
							datasetId={dataset.datasetId}
						/>
					</div>
					<div className="debt-input-existing">
						{filteredTemplates.map((template, index) => (
							<DebtInputForm
								debtColumns={this.state.availableColumns}
								datasetDates={this.props.datasetDates}
								templateNames={templateNames}
								key={index}
								formValues={template}
								allSavedTemplates={this.props.allSavedTemplates}
								onSave={this.onSaveDebtTemplateDefinition}
								fundingVehicles={fundingVehicleList.filter(fv => fv.datasetId === dataset.datasetId)}
								deleteTemplate={this.onDeleteTemplateDefinition}
								datasetId={dataset.datasetId}
							/>
						))}
						{!filteredTemplates.length && (
							<div style={{textAlign: 'center', padding: '2rem'}}>No Templates Found</div>
						)}
					</div>
				</div>
				<KiConfirmModal
					header="Delete Register Template"
					message={() =>
						this.getDeleteConfirmMessage(filteredTemplates, templateToDelete, templateUsageMessages)
					}
					acceptFunc={() => this.onDeleteTemplateDefinition(templateToDelete, true)}
					rejectFunc={() => this.setState({isDeleteConfirmActive: false})}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={isDeleteConfirmActive}
					acceptDisabled={templateUsageMessages && templateUsageMessages.length > 0}
				/>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	dataset: state.datasetList.selected,
	datasetDates: state.datasetDates.dates,
	fundingVehicleList: state.fundingVehicleList.data,
	allSavedTemplates: state.debtInputMaintenance.savedTemplates,
	templateNames: state.debtInputMaintenance.savedTemplates.filter(t => !!t.name).map(({name}) => name),
});

export default connect(mapStateToProps, {
	fetchDataset,
	fetchFundingVehicleList,
	fetchAllTemplates,
	createTemplate,
	updateTemplate,
	deleteTemplate,
	showSnackbar,
	fetchDates,
})(DebtInputMaintenance);
