import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchAuditListByDateRange = (fromDate, toDate) => {
	return fetch(`${apiUrl}/audit/?fromDate=${fromDate.toISOString()}&toDate=${toDate ? toDate.toISOString() : ''}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchAuditListByDateRange,
};
