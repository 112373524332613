import React, {useState} from 'react';
import ReplineList from '../Replines/ReplineList';
import ReplineForm from '../Replines/ReplineForm';
import PropTypes from 'prop-types';
import AssumptionList from '../Assumptions/AssumptionList';
import AssumptionForm from '../Assumptions/AssumptionForm';
import VectorList from '../Vectors/VectorList';
import VectorForm from '../Vectors/VectorForm';
import PricingAssumptionList from '../PricingAssumptions/PricingAssumptionList';
import PricingAssumptionForm from '../PricingAssumptions/PricingAssumptionForm';
import NotionalPoolList from '../NotionalPools/NotionalPoolList';
import NotionalPoolForm from '../NotionalPools/NotionalPoolForm';

export const FORECASTING_FLYOUT_MODE = {
	REPLINES_LIST: 'REPLINES_LIST',
	REPLINES_ADD: 'REPLINES_ADD',
	ASSUMPTION_LIST: 'ASSUMPTION_LIST',
	ASSUMPTION_ADD: 'ASSUMPTION_ADD',
	VECTOR_LIST: 'VECTOR_LIST',
	VECTOR_ADD: 'VECTOR_ADD',
	PRICING_ASSUMPTION_LIST: 'PRICING_ASSUMPTION_LIST',
	PRICING_ASSUMPTION_ADD: 'PRICING_ASSUMPTION_ADD',
	NOTIONAL_POOLS_LIST: 'NOTIONAL_POOLS_LIST',
	NOTIONAL_POOLS_ADD: 'NOTIONAL_POOLS_ADD',
};

const FelyoutSwitch = ({startMode}) => {
	const [mode, setMode] = useState(startMode);
	const [itemToEdit, setItemToEdit] = useState(null);
	const [isCopyMode, setIsCopyMode] = useState(false);

	const setFlyoutMode = (mode, toEdit = null, isCopy = false) => {
		setMode(mode);
		setItemToEdit(toEdit);
		setIsCopyMode(isCopy);
	};

	switch (mode) {
		case FORECASTING_FLYOUT_MODE.REPLINES_LIST:
			return <ReplineList setFlyoutMode={setFlyoutMode} />;
		case FORECASTING_FLYOUT_MODE.REPLINES_ADD:
			return <ReplineForm setFlyoutMode={setFlyoutMode} editReplineId={itemToEdit} />;
		case FORECASTING_FLYOUT_MODE.ASSUMPTION_LIST:
			return <AssumptionList setFlyoutMode={setFlyoutMode} />;
		case FORECASTING_FLYOUT_MODE.ASSUMPTION_ADD:
			return (
				<AssumptionForm
					onClose={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.ASSUMPTION_LIST)}
					editItemId={itemToEdit}
				/>
			);
		case FORECASTING_FLYOUT_MODE.VECTOR_LIST:
			return <VectorList setFlyoutMode={setFlyoutMode} />;
		case FORECASTING_FLYOUT_MODE.VECTOR_ADD:
			return <VectorForm setFlyoutMode={setFlyoutMode} editItemId={itemToEdit} isCopyMode={isCopyMode} />;
		case FORECASTING_FLYOUT_MODE.PRICING_ASSUMPTION_LIST:
			return <PricingAssumptionList setFlyoutMode={setFlyoutMode} />;
		case FORECASTING_FLYOUT_MODE.PRICING_ASSUMPTION_ADD:
			return <PricingAssumptionForm setFlyoutMode={setFlyoutMode} editItemId={itemToEdit} />;
		case FORECASTING_FLYOUT_MODE.NOTIONAL_POOLS_LIST:
			return <NotionalPoolList setFlyoutMode={setFlyoutMode} />;
		case FORECASTING_FLYOUT_MODE.NOTIONAL_POOLS_ADD:
			return (
				<NotionalPoolForm
					onClose={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.NOTIONAL_POOLS_LIST)}
					editItemId={itemToEdit}
				/>
			);
	}
};

FelyoutSwitch.propTypes = {
	startMode: PropTypes.string.isRequired,
};

export default FelyoutSwitch;
