import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {updateValue} from './actions';
import KiDnDList from 'components/KiDnDList';
import _ from 'lodash';

export class FilterList extends Component {
	static propTypes = {
		filters: PropTypes.array.isRequired,
		allFilters: PropTypes.array.isRequired,
		updateValue: PropTypes.func,
		setFormHasChanges: PropTypes.func,
		updateCallBack: PropTypes.func,
	};

	static defaultProps = {
		filters: [],
		allFilters: [],
	};

	valueChanged = (name, value) => {
		if (this.props.updateCallBack) {
			this.props.updateCallBack(value);
		} else {
			this.props.updateValue({name: name, value: value});
		}
		this.props.setFormHasChanges();
	};

	moveUp = index => {
		const filters = this.props.filters.slice();
		filters.splice(index - 1, 0, filters[index]);
		filters.splice(index + 1, 1);
		this.valueChanged('filters', filters);
	};

	moveDown = index => {
		const filters = this.props.filters.slice();
		filters.splice(index + 2, 0, filters[index]);
		filters.splice(index, 1);
		this.valueChanged('filters', filters);
	};

	remove = filterId => {
		this.valueChanged(
			'filters',
			this.props.filters.filter(f => f._id !== filterId)
		);
	};

	add = filter => {
		const filters = this.props.filters.slice();
		filters.push(Object.assign({}, filter));
		this.valueChanged('filters', filters);
	};

	onFilterReorder = filtersDnd => {
		const filters = filtersDnd.map(filter => {
			return filter.content;
		});
		this.valueChanged('filters', filters);
	};

	renderFilterItem = filter => {
		return (
			<div className="sidebar-form-setting settings-filter-list inside-dnd">
				<div>
					<p>{filter.name}</p>
				</div>
				<div className="setting-icons">
					<i title="Remove" className={`material-icons`} onClick={() => this.remove(filter._id)}>
						clear
					</i>
				</div>
			</div>
		);
	};

	getAvailableFilters = () => {
		if (!this.props.filters.length) return this.props.allFilters;

		return this.props.allFilters.filter(filter => {
			return !this.props.filters.find(f => f._id === filter._id);
		});
	};

	render() {
		return (
			<div className="settings-filter-list-body">
				<div className="">
					<Select
						classNamePrefix="aut-select"
						className=""
						value={null}
						closeOnSelect={true}
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
						hint="Select Filters"
						isClearable={false}
						options={_.sortBy(this.getAvailableFilters(), filter =>
							_.get(filter, 'name', '').toLowerCase()
						)}
						onChange={options => this.add(options)}
					/>
				</div>

				{this.props.filters.length === 0 ? (
					<div className="sidebar-form-section">
						<p className="no-columns-msg">No filters applied</p>
					</div>
				) : null}
				<KiDnDList
					items={this.props.filters.map((filter, index) => ({
						id: index,
						content: filter,
						idx: index,
					}))}
					onReorder={this.onFilterReorder}
					contentRenderFunc={this.renderFilterItem}
					listClassName={'filterListDnd'}
					itemClassName={'filterItemDnd'}
				/>
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
	updateValue,
});

export default connect(mapStateToProps, mapDispatchToProps())(FilterList);
