const systemColumns = require('./systemColumns');

const debtFloatingIndexOptions = [
	{
		label: 'Libor 1 Mo',
		value: 'LIBOR 1MO',
	},
	{
		label: 'Libor 3 Mo',
		value: 'LIBOR 3MO',
	},
	{
		label: 'FHLB Cofi',
		value: 'FHLB COFI',
	},
	{
		label: 'GNMA Mort',
		value: 'GNMA MORT',
	},
	{
		label: 'FNMA Mort',
		value: 'FNMA MORT',
	},
	{
		label: 'Treasury 3 Mo',
		value: 'TSY 3 MO',
	},
	{
		label: 'Treasury 6 Mo',
		value: 'TSY 6 MO',
	},
	{
		label: 'Treasury 1 Year',
		value: 'TSY 1 YR',
	},
	{
		label: 'Treasury 2 Year',
		value: 'TSY 2 YR',
	},
	{
		label: 'Treasury 3 Year',
		value: 'TSY 3 YR',
	},
];

const debtCohortCols = [
	{
		label: 'Funding Vehicle',
		value: 'fundingVehicle',
	},
	{
		label: 'Trust',
		value: 'trust',
	},
	{
		label: 'Pool',
		value: 'pool',
	},
	{
		label: 'Tranche',
		value: 'debt',
	},
	{
		label: 'Account',
		value: 'creditSupport',
	},
	{
		label: 'Fee',
		value: 'fee',
	},
	{
		label: 'Trigger',
		value: 'trigger',
	},
	{
		label: 'Counterparty',
		value: 'counterparty',
	},
];

const debtViewTypes = [
	{
		label: 'Summary',
		value: 'summary',
	},
	{
		label: 'Activity',
		value: 'activity',
	},
	{
		label: 'Transaction',
		value: 'transaction',
	},
	{
		label: 'Waterfall',
		value: 'waterfall',
	},
	{
		label: 'Role',
		value: 'role',
	},
];

const defaultDebtColumns = systemColumns.defaultDebtColumns;
const defaultCreditSupportColumns = systemColumns.defaultCreditSupportColumns;
const defaultFeeColumns = systemColumns.defaultFeeColumns;
const defaultTriggerColumns = systemColumns.defaultTriggerColumns;
const defaultWaterfallColumns = systemColumns.defaultWaterfallColumns;
const defaultCounterpartyColumns = systemColumns.defaultCounterpartyColumns;
const defaultCounterpartyRoleColumns = systemColumns.defaultCounterpartyRoleColumns;

const debtInputDataTypes = [
	{
		value: 'numeric',
		label: 'Numeric',
	},
	{
		value: 'date_short',
		label: 'Short Date',
	},
	{
		value: 'date_long',
		label: 'Long Date',
	},
	{
		value: 'string',
		label: 'Text',
	},
];

const debtDateContexts = [
	{
		value: 'reportDate',
		label: 'Report Date',
	},
	{
		value: 'collectionPeriod',
		label: 'Collection Period',
	},
];

const debtScenarioContexts = [
	{
		label: 'Start of Day',
		value: 'assetSnapshot',
	},
	{
		label: 'End of Day',
		value: 'lastApproved',
	},
];

const fundingScenarioContexts = [
	{
		label: 'Start of Day',
		value: 'assetSnapshot',
	},
	{
		label: 'End of Day',
		value: 'lastApproved',
	},
	{
		label: 'Pending',
		value: 'pending',
	},
	{
		label: 'Hypo',
		value: 'hypo',
	},
];

const debtFVSetupCols = systemColumns.debtFVSetupCols;
const debtBorrowingBaseCols = systemColumns.debtBorrowingBaseCols;

const debtOutputOptions = [
	{
		label: 'Collateral Performance',
		value: 'collateralPerformance',
	},
	{
		label: 'Collateral Cash Flow',
		value: 'collateralCashflow',
	},
	{
		label: 'Fees',
		value: 'fee',
	},
	{
		label: 'Waterfall',
		value: 'waterfall',
	},
	{
		label: 'Tranche Performance',
		value: 'tranchePerformance',
	},
	{
		label: 'Tranche Cashflow',
		value: 'trancheCashflow',
	},
	{
		label: 'Tranche Pricing',
		value: 'pricing',
	},
	{
		label: 'Test',
		value: 'triggerResults',
	},
	{
		label: 'Account',
		value: 'account',
	},
	{
		label: 'Variables',
		value: 'variables',
	},
];

const payFromOptions = [
	{
		columnName: 'Net Interest',
		_id: 'netInterest',
	},
	{
		columnName: 'Total Principal',
		_id: 'totalPrincipal',
	},
];

const entityOptions = [
	{
		label: 'Funding Vehicle',
		value: 'fundingVehicle',
	},
	{
		label: 'Tranche',
		value: 'tranche',
	},
	{
		label: 'Account',
		value: 'creditSupport',
	},
	{
		label: 'Trigger',
		value: 'trigger',
	},
	{
		label: 'Fee',
		value: 'fee',
	},
];

const collateralMappingTypeOptions = [
	{
		label: 'Collateral',
		value: 'collateral',
	},
	{
		label: 'Forecast',
		value: 'forecast',
	},
];
module.exports = {
	debtFloatingIndexOptions,
	debtCohortCols,
	debtViewTypes,
	defaultDebtColumns,
	defaultCreditSupportColumns,
	defaultFeeColumns,
	defaultTriggerColumns,
	defaultWaterfallColumns,
	//trancheHistoryColumns,
	defaultCounterpartyColumns,
	defaultCounterpartyRoleColumns,
	debtInputDataTypes,
	debtDateContexts,
	debtScenarioContexts,
	fundingScenarioContexts,
	debtFVSetupCols,
	debtBorrowingBaseCols,
	debtOutputOptions,
	payFromOptions,
	entityOptions,
	collateralMappingTypeOptions,
};
