import PropTypes from 'prop-types';
import React, {Component} from 'react';
import _uniqueId from 'lodash/uniqueId';
import styles from './diffList.theme.scss';

export class DiffList extends Component {
	static propTypes = {
		list: PropTypes.array,
	};

	static defaultProps = {
		list: [],
	};

	render() {
		if (!this.props.list.length) return <div>-</div>;
		return (
			<div className={styles.diffList}>
				{this.props.list.map(p => (
					<div key={p._id || _uniqueId()}>
						<span className={styles.property}>{p.property}:</span>
						<span className={styles.old}>
							{typeof p.oldValue !== 'object' ? (
								p.oldValue
							) : (
								<pre>
									<code>{JSON.stringify(p.oldValue, null, 4)}</code>
								</pre>
							)}
						</span>
						<span className={styles.separator}>&rarr;</span>
						<span className={styles.new}>
							{typeof p.newValue !== 'object' ? (
								p.newValue
							) : (
								<pre>
									<code>{JSON.stringify(p.newValue, null, 4)}</code>
								</pre>
							)}
						</span>
					</div>
				))}
			</div>
		);
	}
}
export default DiffList;
