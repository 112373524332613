// Globals
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';

// Project imports
import KiIconButton from 'components/KiIconButton';
import {getSortAscFuncByField} from 'ki-common/utils/sorters';
import KiModal2 from 'components/KiModal2';

// Action imports
import {fetchDates, fetchMappedDates} from './actions';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {fetchCalendars} from 'containers/calendars/actions';

// Local imports
import styles from './FlyoutDates.theme.scss';
import DateGroupItem from './components/dateGroupItem';
import DateDetailsForm from './components/dateDetailsForm';

function FlyoutDates() {
	const dispatch = useDispatch();

	// Redux state references
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const dataset = useSelector(state => state.datasetList.selected);
	const dates = useSelector(state => state.datasetDates.dates);
	const mappedDates = useSelector(state => state.datasetDates.mappedDates);
	const usage = useSelector(state => state.datasetDates.usage);

	// Local state
	const [selectedDateId, setSelectedDateId] = useState('');
	const [selectedEntryId, setSelectedEntryId] = useState('');
	const [isDetailModalActive, setDetailModalActive] = useState(false);
	const [isNewDefault, setNewDefault] = useState(false);
	const [usageMsg, setUsageMsg] = useState('');
	const [searchTerm, setSearchTerm] = useState('');

	const handleSelectDateClick = option => {
		// Need to set selecteDate.name for it to show up on the modal header
		setSelectedDateId(option.value);
	};

	const getDateNameOptions = dates => {
		return _.sortBy(
			dates.map(date => ({
				label: date.name,
				value: String(date._id),
			})),
			opt => opt.label.toLowerCase()
		);
	};

	// Load all the funding vehicles and calendars
	useEffect(() => {
		dispatch(fetchFundingVehicleList(_.get(dataset, 'datasetId')));
		dispatch(fetchCalendars());
		dispatch(fetchMappedDates(_.get(dataset, 'datasetId')));
	}, []);

	// If the redux dataset does not match the param datasetId (or is not loaded at all yet)
	useEffect(
		() => {
			// If no dataset or dataset mismatch
			if (dataset) {
				dispatch(fetchDates(dataset.datasetId, false, true));
				dispatch(fetchMappedDates(dataset.datasetId));
			}
		},
		[dataset]
	);

	// If the dates list changes then update the selection to the first entry
	// if no entry is selected or that entry is no longer found
	useEffect(
		() => {
			if (dates && dates.length) {
				const selectedDate = dates.find(dd => dd._id === selectedDateId);
				if (!selectedDate) {
					handleSelectDateClick(getDateNameOptions(dates)[0]);
				}
			}
		},
		[dates]
	);

	// Only trigger when the usage is updated in some way
	useEffect(
		() => {
			if (usage && usage.isUsed) {
				const message = _.get(usage, 'messages', []).map((msg, idx) => (
					<div key={idx} style={{marginBottom: '5px'}}>
						{msg}
					</div>
				));
				setUsageMsg(message);
			} else {
				setUsageMsg('');
			}
		},
		[usage]
	);

	// Handlers
	// Create a new date
	const handleCreateNewDateGroup = () => {
		setSelectedDateId('');
		setSelectedEntryId('');
		setNewDefault(true);
		setDetailModalActive(true);
	};

	// Create a new date entry
	const handleAppendNewDateEntry = dateId => {
		setSelectedDateId(dateId);
		setSelectedEntryId('');
		setNewDefault(false);
		setDetailModalActive(true);
	};

	const handleEditDateEntry = (dateId, entryId) => {
		setSelectedDateId(dateId);
		setSelectedEntryId(entryId);
		setNewDefault(false);
		setDetailModalActive(true);
	};

	const handleModalCancel = () => {
		setDetailModalActive(false);
	};

	const handleModalConfirm = date => {
		if (date) {
			setSelectedDateId(date._id);
			setDetailModalActive(false);
		} else {
			setDetailModalActive(false);
		}
	};

	const getDateEntry = () => {
		const selectedDate = selectedDateId ? dates.find(dd => dd._id === selectedDateId) : {};
		if (selectedDate.isPortfolioDate) {
			return selectedDate.entries[0]; // only ever one in this case
		} else {
			return selectedEntryId ? selectedDate.entries.find(entry => entry._id === selectedEntryId) : {};
		}
	};

	const getIsPortfolioDate = () => {
		const selectedDate = selectedDateId ? dates.find(dd => dd._id === selectedDateId) : {};
		return selectedDate.isPortfolioDate || false;
	};

	const getIsReadOnly = () => {
		const selectedDate = selectedDateId ? dates.find(dd => dd._id === selectedDateId) : {};
		return selectedDate.readOnly || false;
	};

	const getIsLatestSnapshotDate = () => {
		const selectedDate = selectedDateId ? dates.find(dd => dd._id === selectedDateId) : {};
		return selectedDate.isLatestSnapshot || false;
	};

	// Filter by search value
	// Sort in alphabetical order
	const searchBy = searchTerm.toLowerCase();
	const filteredDates = dates
		.filter(curDate => {
			if (searchBy) {
				return curDate.name.toLowerCase().includes(searchBy);
			}
			return true;
		})
		.sort(getSortAscFuncByField('name'));

	const dateNameOptions = getDateNameOptions(dates);
	const dateNameValue = dateNameOptions.find(option => option.value === selectedDateId) || null;

	return (
		<article className={styles.root}>
			{isAdmin && (
				<div className={styles.addDatesRow}>
					<KiIconButton
						id={'CreateDateBtn'}
						icon="add_circle"
						inverse
						disabled={!isAdmin}
						title="Click to create a new Date."
						onClick={() => handleCreateNewDateGroup()}
						className={styles.addDatesButton}
					/>
					<label htmlFor="CreateDateBtn" className={styles.addDatesText}>
						Create a Date
					</label>
				</div>
			)}
			<section className="flyout-panel-search">
				<p>Search Dates</p>
				<input placeholder="Name" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
			</section>
			<section className={styles.dateList}>
				{filteredDates.map(dateGroup => (
					<DateGroupItem
						key={dateGroup._id}
						dateGroup={dateGroup}
						newDateEntry={() => handleAppendNewDateEntry(dateGroup._id)}
						editDateEntry={entryId => handleEditDateEntry(dateGroup._id, entryId)}
						mappedDates={mappedDates}
					/>
				))}
			</section>
			{isDetailModalActive && (
				<DateDetailsForm
					datasetId={dataset.datasetId}
					dateId={selectedDateId}
					dateName={_.get(dateNameValue, 'label', '')}
					isPortfolioDate={getIsPortfolioDate()}
					readOnly={getIsReadOnly()}
					isLatestSnapshot={getIsLatestSnapshotDate()}
					dateEntry={getDateEntry()}
					onConfirm={handleModalConfirm}
					onCancel={handleModalCancel}
					isNewDefault={isNewDefault}
				/>
			)}
			{usageMsg && (
				<KiModal2
					active={true}
					header={'Date Usage'}
					onClose={() => setUsageMsg('')}
					actions={[{label: 'Close', onClick: () => setUsageMsg('')}]}
				>
					<span style={{marginBottom: '15px'}}>
						This date is being used in Calculations, Views, Cards, Reports, Ledgers and/or Other Dataset
						Dates. The dependency must be removed before this mapping can be deleted.
					</span>
					<span>{usageMsg}</span>
				</KiModal2>
			)}
		</article>
	);
}

export default FlyoutDates;
