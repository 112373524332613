import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchVersions = () => {
	return fetch(`${apiUrl}/versions`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchVersions,
};
