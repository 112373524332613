import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames'; // eslint-disable-line
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import options from 'ki-common/options';
import {fetchViewsByDataset} from 'components/FlyoutManageViews/actions';
import styles from './cardForm.theme.scss';
import {checkNameValid} from '../KiDataCardEditorUtils';
import {KiCreatable} from 'components/KiSelect';
import KiProgressBar from 'components/KiProgressBar';

export class TabularCardForm extends Component {
	static propTypes = {
		existingTags: PropTypes.array.isRequired,
		card: PropTypes.object,
		setSavedStatus: PropTypes.func,
		fetchViewsByDataset: PropTypes.func,
		saveCard: PropTypes.func.isRequired,
		onFlyoutClose: PropTypes.func,
		onDialogClose: PropTypes.func,
		mode: PropTypes.string,
		deleteCard: PropTypes.func,
		fundingVehicles: PropTypes.array,
		allCards: PropTypes.array,
		scenarioList: PropTypes.array,
		isDeleteDisabled: PropTypes.bool,
		isSaveDisabled: PropTypes.bool,
		reportOutputType: PropTypes.string,
	};

	static defaultProps = {
		card: {},
		existingTags: [],
		fundingVehicles: [],
		allCards: [],
		scenarioList: [],
		isDeleteDisabled: false,
		isSaveDisabled: false,
	};

	state = {
		nameError: null,
		card: {
			name: '',
			tags: [],
			settings: {
				transpose: false,
				chartType: 'data',
				showTotals: false,
			},
		},
		currentBookmark: {},
	};

	componentDidMount() {
		this.setState({card: _cloneDeep(this.props.card)});
		this.props.fetchViewsByDataset().then(results => {
			this.setState({
				currentBookmark: results.find(g => g._id === this.props.card.bookmarkId),
			});
		});

		if (this.props.allCards.length > 0) {
			this.setState({
				nameError: checkNameValid(this.props.allCards, this.props.card.name, this.props.card._id),
			});
		}
	}

	componentDidUpdate(prevProps) {
		//we need to wait for the cards to load or the submit will be enabled and you may make dupe names
		if (JSON.stringify(prevProps.allCards) !== JSON.stringify(this.props.allCards)) {
			this.setState({
				nameError: checkNameValid(this.props.allCards, this.props.card.name, this.props.card._id),
				cardListLoaded: true,
			});
		}
	}

	setCardValue = (key, value) => {
		const cardState = Object.assign({}, this.state.card);
		_set(cardState, key, value);
		this.setState({card: cardState});
		this.props.setSavedStatus(false);
	};

	setName = val => {
		this.setState({nameError: checkNameValid(this.props.allCards, val, this.state.card._id)});
		this.setCardValue('name', val);
	};

	saveCard = () => {
		const {card, currentBookmark} = this.state;
		const saveCardRequest = {
			_id: card._id,
			name: card.name,
			bookmarkId: currentBookmark._id,
			datasetId: card.datasetId,
			tags: card.tags,
			settings: {
				type: 'tabular',
				transpose: _get(card, 'settings.transpose', false),
				useLatest: false,
				chartType: card.settings.chartType || 'data',
				chartValue: card.settings.chartValue || 'data',
				sort: card.settings.sort,
				showTotals: _get(card, 'settings.showTotals', false),
				chronological: _get(card, 'settings.chronological', false),
				fundingVehicle: card.settings.fundingVehicle,
				scenarioId: card.settings.scenarioId,
				cardOutputType: this.props.reportOutputType,
			},
		};
		return this.props.saveCard(saveCardRequest);
	};

	close = () => {
		if (this.props.mode === 'dialog') {
			return this.props.onDialogClose();
		}
		this.props.onFlyoutClose();
	};

	deleteCard = () => {
		this.props.deleteCard();
	};

	render() {
		const {card, nameError, currentBookmark} = this.state;
		const {scenarioList} = this.props;

		if (!currentBookmark.explorerData) {
			return <KiProgressBar />;
		}

		const chartTypes =
			currentBookmark.explorerData.tableType === 'timeSeries'
				? options.aggregateChartTypes.filter(op => op.type !== 'pie' && op.type !== 'column')
				: options.aggregateChartTypes;

		const scenarioContextOptions =
			currentBookmark.explorerData.snapshotType === 'encumbrance'
				? scenarioList.filter(option => option.value !== 'assetSnapshot')
				: scenarioList;

		return (
			<div className="context-sidebar-panel-flex column-selector-form">
				<div>
					{!this.props.mode === 'dialog' && <header className="flyout-panel-title">MANAGE CARDS</header>}
					<section className={this.props.mode === 'dialog' ? styles.cardFormDialog : ''}>
						<div className={styles.column}>
							<div className={styles.infoSection}>
								<span className="form-instruction">View Name</span>
								{currentBookmark.name}
							</div>
							<div className={styles.formInputSection}>
								<KiInput
									label="Name"
									value={card.name}
									onChange={val => this.setName(val)}
									error={nameError}
								/>
							</div>
							<div className={styles.formSelectSection}>
								<span className="form-instruction">Display Type:</span>
								<Select
									classNamePrefix="aut-select"
									value={[
										{
											label: 'Data',
											value: 'data',
											type: 'data',
										},
										...options.aggregateChartTypes,
									].find(type => type.value === card.settings.chartType)}
									isClearable={false}
									options={[
										{
											label: 'Data',
											value: 'data',
											type: 'data',
										},
										...chartTypes,
									]}
									onChange={val => {
										this.setCardValue('settings.chartType', val.value);
										this.setCardValue('settings.chartValue', val.type);
									}}
									isDisabled={
										_get(currentBookmark, 'explorerData.tableType', 'asset') === 'asset' ||
										card.settings.transpose ||
										this.props.reportOutputType == 'csvExtract'
									}
								/>
							</div>
							<div className={styles.checkboxContainer}>
								<div className="calculation-form-section">
									<KiCheckbox
										name="Transpose"
										checked={_get(card, 'settings.transpose', false)}
										label="Transpose"
										disabled={
											card.settings.chartType !== 'data' ||
											this.props.reportOutputType == 'csvExtract'
										}
										onChange={val => this.setCardValue('settings.transpose', val)}
									/>
								</div>

								{currentBookmark.explorerData.tableType !== 'timeSeries' && (
									<div className="calculation-form-section">
										<KiCheckbox
											name="Total"
											checked={_get(card, 'settings.showTotals', false)}
											label="Totals"
											disabled={
												card.settings.chartType !== 'data' ||
												this.props.reportOutputType == 'csvExtract'
											}
											onChange={val => this.setCardValue('settings.showTotals', val)}
										/>
									</div>
								)}

								<div className="calculation-form-section">
									<KiCheckbox
										name="Chronological"
										checked={_get(card, 'settings.chronological', false)}
										label="Chronological (Time Series)"
										disabled={
											currentBookmark.explorerData.tableType !== 'timeSeries' ||
											this.props.reportOutputType == 'csvExtract'
										}
										onChange={val => this.setCardValue('settings.chronological', val)}
									/>
								</div>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.infoSection}>
								<span className="form-instruction">View Type</span>
								{
									options.explorationViewTypes.find(
										type => type.value === currentBookmark.explorerData.snapshotType
									)?.label
								}
							</div>
							<div className={styles.formSelectSection}>
								<span className="form-instruction">Tags:</span>
								<KiCreatable
									classNamePrefix="aut-select"
									isMulti={true}
									options={this.props.existingTags.map(t => ({
										value: t,
										label: t,
									}))}
									value={_get(card, 'tags', []).map(t => ({value: t, label: t}))}
									onChange={val => this.setCardValue('tags', val.map(t => t.value))}
									placeholder="Add some tags"
									noResultsText={false}
								/>
							</div>

							<div className={styles.formSelectSection}>
								<span className="form-instruction">Funding Vehicle Override:</span>
								<Select
									classNamePrefix="aut-select"
									value={this.props.fundingVehicles.find(f => f._id === card.settings.fundingVehicle)}
									isClearable={true}
									placeholder={'Report Parameter'}
									options={this.props.fundingVehicles}
									getOptionLabel={option => option.name}
									getOptionValue={option => option._id}
									onChange={val => {
										this.setCardValue('settings.fundingVehicle', _get(val, '_id', null));
									}}
								/>
							</div>
							<div className={styles.formSelectSection}>
								<span className="form-instruction">Scenario Context Override:</span>
								<Select
									classNamePrefix="aut-select"
									value={this.props.scenarioList.find(f => f.value === card.settings.scenarioId)}
									isClearable={true}
									placeholder={'Report Parameter'}
									options={scenarioContextOptions}
									getOptionLabel={option => option.label}
									getOptionValue={option => option.value}
									onChange={val => {
										this.setCardValue('settings.scenarioId', _get(val, 'value', null));
									}}
								/>
							</div>
						</div>
					</section>
				</div>
				<div>
					{this.props.reportOutputType == 'csvExtract' && (
						<p>NOTE: Some options have been disabled for Extract output type</p>
					)}
				</div>
				<section className="inline-column-form-buttons">
					{this.props.mode === 'dialog' && (
						<div className={styles.deleteContainer}>
							<KiButton
								disabled={!this.props.isDeleteDisabled && this.props.deleteCard ? false : true}
								flat
								primary
								onClick={() => this.deleteCard()}
								label="Delete"
							/>
						</div>
					)}
					<KiButton flat primary onClick={() => this.close()} label="Cancel" />
					<KiButton
						raised
						primary
						disabled={!!this.state.nameError || this.props.isSaveDisabled}
						onClick={() => this.saveCard()}
						label="Save"
					/>
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => ({}); // eslint-disable-line

const mapDispatchToProps = () => ({
	fetchViewsByDataset,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(TabularCardForm);
