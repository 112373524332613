import KiChart from './KiChart';
const _set = require('lodash/set');

class KiLineChart extends KiChart {
	buildParamConfig() {
		const config = this.params;
		const paramConfig = {
			chart: {
				type: 'line',
				height: config.height,
			},
			plotOptions: {
				line: {
					enabledMouseTracking: false,
				},
			},
			title: {
				text: config.chartTitle,
			},
			xAxis: {
				categories: config.categories,
				title: {
					text: config.xTitle,
				},
			},
			yAxis: {
				title: {
					text: config.yTitle,
				},
			},
			series: config.series,
		};

		if (config.dataLabels === true) {
			_set(paramConfig, 'plotOptions.line.dataLabels.enabled', true);
		}
		return paramConfig;
	}
}

export default KiLineChart;
