// Globals
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import Select from 'react-select';
import _ from 'lodash';

// Project imports
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

// Local imports
import {fetchQuickFilters} from 'api/fundingAnalysisApi';
import KiDatePicker from 'components/KiDatePicker';
import KiSelect, {KiCreatable} from 'components/KiSelect';
import KiInput from 'components/KiInput';

// Relative imports
import {BookmarkContext} from 'containers/fundingAnalysis/fundingAnalysisBookmark';
import styles from '../../fundingAnalysis.theme.scss';

const isBorrowingBaseEnabled = window.BORROWING_BASE_WOA_ENABLED;

function ViewSettingsParameters({bookmark, setBookmark}) {
	// Browser State
	const {datasetId} = useParams();

	// Redux State
	const dataset = useSelector(state => state.datasetList.selected);
	const allFundingVehicles = useSelector(state => state.fundingVehicleList.data);

	// Context State
	const bookmarkContext = useContext(BookmarkContext);

	// Local State
	const [nameError, setNameError] = useState('');
	const [scenarioQuickFilters, setScenarioQuickFilters] = useState(bookmarkContext.ui.scenarioFilterList);

	const explorationConstraintTypes = [{value: 'setup', label: 'FV Defined'}, {value: 'applied', label: 'Applied'}];

	const fundingViewTypes = [
		{label: 'Scenario', value: 'scenario'},
		{label: 'Summary', value: 'summary'},
		{label: 'Pool', value: 'pool'},
		{label: 'Excess', value: 'excess'},
		{label: 'Breaches', value: 'breaches'},
		{label: 'Eligibility', value: 'eligibility'},
		...(isBorrowingBaseEnabled ? [{label: 'Borrowing Base', value: 'borrowingBase'}] : []),
	];

	const [fundingViewTypesSelect, setFundingViewTypesSelect] = useState([
		{label: 'Summary', value: 'summary'},
		{label: 'Eligibility', value: 'eligibility'},
	]);

	useEffect(
		() => {
			setFundingViewTypesSelect([
				{label: 'Summary', value: 'summary'},
				{label: 'Eligibility', value: 'eligibility'},
				{label: 'Scenario', value: 'scenario', isDisabled: _.isEmpty(bookmarkContext.ui.scenarioList)},
				...(isBorrowingBaseEnabled ? [{label: 'Borrowing Base', value: 'borrowingBase'}] : []),
			]);
		},
		[bookmarkContext.ui.scenarioList]
	);

	const fetchScenarioQuickFilters = async () => {
		const lists = await fetchQuickFilters(
			dataset.datasetId,
			bookmark.settings.statementDate || dateToShortDate(new Date()),
			bookmark.settings.dateContext
		);
		setScenarioQuickFilters(lists.scenarioList);
	};

	// On Change to bookmark
	useEffect(
		() => {
			setNameError('');
			fetchScenarioQuickFilters();
		},
		[bookmarkContext.bookmark]
	);

	const handleSetViewType = async val => {
		bookmarkContext.setViewType(val.value);
	};

	const getFvValues = (type = 'fundingVehicleIds') => {
		let fvs = _.get(bookmark, `settings.${type}`, []);
		fvs = fvs === null ? [] : fvs; // BLD-23243
		if (fvs && fvs[0] && !fvs[0].fvId) {
			fvs = fvs.map(fv => {
				const name = ['all', 'global'].includes(fv.toLowerCase())
					? _.capitalize(fv)
					: _.get(allFundingVehicles.find(f => f._id === fv), 'name', '');
				return {
					fvId: fv,
					fvName: name,
				};
			});
		}
		return fvs;
	};

	const getEligibilityOptions = () => {
		const eligiblityCriteriaOptions = [
			{
				fvName: 'All',
				fvId: 'all',
			},
			{
				fvName: 'Global',
				fvId: 'global',
			},
		];
		allFundingVehicles.forEach(fv => {
			if (fv.datasetId === dataset.datasetId) {
				const option = {
					fvName: fv.name,
					fvId: fv._id,
				};
				eligiblityCriteriaOptions.push(option);
			}
		});

		return eligiblityCriteriaOptions;
	};

	const objectifyTags = (tags = []) => tags.map(tag => ({label: tag, value: tag}));

	// *************************
	// Render Helpers & Handlers
	// *************************

	const handleSetSummaryScenario = async val => {
		setBookmark({
			settings: {
				scenarioType: val.type === 'transferDate' ? 'lastCommitted' : val._id,
				transferDate: val.type === 'transferDate' ? val._id : null,
			},
		});
	};

	const renderSummaryScenarioSelector = () => {
		const isLoading = _.isEmpty(scenarioQuickFilters);

		let value;
		const pendingOptions = scenarioQuickFilters.find(sc => sc.label === 'Pending');
		if (!_.isEmpty(bookmark.settings.transferDate) && !_.isEmpty(pendingOptions)) {
			value = pendingOptions.options.find(opt => opt._id === bookmark.settings.transferDate);
		} else {
			value = scenarioQuickFilters.find(sc => sc._id === bookmark.settings.scenarioType);
		}
		// TODO may need to test default logic here

		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Scenario</span>
				<Select
					isOptionDisabled={option => !!option.disabled}
					loadingMessage={() => 'Loading...'}
					isLoading={isLoading}
					isDisabled={isLoading}
					classNamePrefix="aut-select"
					value={value}
					options={scenarioQuickFilters}
					defaultValue={{name: 'End of Day', _id: 'lastApproved'}}
					onChange={handleSetSummaryScenario}
					getOptionLabel={option => option.name}
					getOptionValue={option => option._id}
				/>
			</div>
		);
	};

	const handleActualScenarioSelector = val => {
		if (val._id) {
			// If an actual scenario has been selected
			bookmarkContext.setScenarioId(val._id);
		} else {
			// If Start of Day or End of Day was selected
			bookmarkContext.setBookmark({
				settings: {
					scenarioId: null,
					scenarioType: val.value,
				},
			});
		}
	};

	const renderActualScenarioSelector = (isBorrowingBase = false) => {
		// In order to use sub-labels label/value must be used instead of name/_id
		//let scenarioList = bookmarkContext.ui.scenarioList.map(raw => ({...raw, label: raw.name, value: raw._id}));
		const actualScenarioList = (isBorrowingBase
			? bookmarkContext.ui.scenarioBorrowingBaseList
			: bookmarkContext.ui.scenarioList
		).map(raw => ({...raw, label: raw.name, value: raw._id}));
		let scenarioList = actualScenarioList;
		// For borrowingBase views add the predefined options and filter scenario list for only matches
		if (isBorrowingBase) {
			scenarioList = [
				{
					label: 'Start of Day',
					value: 'assetSnapshot',
				},
				{
					label: 'End of Day',
					value: 'lastApproved',
				},
				// Because these scenarios include the full object we can see the lastSolvedModel,
				// this would not be true of scenarios returned in the fetch quick filter list
				{
					label: 'Hypo',
					options: scenarioList.filter(opt => opt.lastSolvedModel.isBorrowingBase),
				},
			];
		}

		// Parse the value out by type if an ID is not provided
		let value;
		if (bookmark.settings.scenarioId) {
			// Find the scenario by the ID
			value = actualScenarioList.find(opt => opt.value === bookmark.settings.scenarioId);
			// Fall back to the first Hypo scenario
			value = value ? value : actualScenarioList[0];
			// Fall back to the End of Day scenario
			value = value ? value : (value = scenarioList.find(opt => opt.value === 'lastApproved'));
		} else {
			value = scenarioList.find(opt => opt.value === bookmark.settings.scenarioType);
		}

		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Scenario</span>
				<Select
					classNamePrefix="aut-select"
					value={value}
					options={scenarioList}
					onChange={handleActualScenarioSelector}
				/>
			</div>
		);
	};

	const renderStatementDate = () => {
		return (
			<div className="sidebar-form-section">
				<span className="theme-label">Statement Date</span>
				<KiDatePicker
					onChange={val =>
						setBookmark({
							settings: {
								statementDate: dateToShortDate(val),
							},
						})
					}
					value={bookmark.settings.statementDate}
				/>
			</div>
		);
	};

	const renderDateContext = () => {
		const isLoading = _.isEmpty(bookmarkContext.ui.datasetDateList);
		return (
			<div className="sidebar-form-section">
				<span className={'theme-label'}>Date Context</span>
				<KiSelect
					getOptionLabel={o => o.name}
					isLoading={isLoading}
					loadingMessage={() => 'Loading dates...'}
					placeholder={isLoading ? 'Loading Dates...' : 'Select a date'}
					getOptionValue={date => date._id}
					defaultValue={bookmarkContext.ui.datasetDateList.find(d => d.name === 'Latest Snapshot')}
					name={'debtDateContext'}
					options={bookmarkContext.ui.datasetDateList.filter(d => d.readOnly)}
					value={bookmarkContext.ui.datasetDateList.find(
						dateColumn => dateColumn._id === bookmark.settings.dateContext
					)}
					onChange={async val => {
						setBookmark({
							settings: {
								dateContext: val._id,
							},
						});
					}}
				/>
			</div>
		);
	};

	const setEligibilityCriteria = async val => {
		let valToSet;
		if (val === null || val.includes({fvId: 'all', fvName: 'All'})) {
			valToSet = ['all'];
		} else {
			valToSet = val.filter(v => v.fvId !== 'all');
			valToSet = valToSet.map(v => v.fvId);
		}
		setBookmark({
			settings: {
				criteriaSources: valToSet,
			},
		});
	};

	const renderEligibilityCriteria = () => {
		return (
			<div className={styles.selectWrapper} style={{marginTop: '1rem'}}>
				<span className="theme-label">Eligibility Criteria</span>
				<Select
					classNamePrefix="aut-select"
					value={getFvValues('criteriaSources')}
					options={getEligibilityOptions()}
					isMulti={true}
					onChange={setEligibilityCriteria}
					getOptionLabel={option => option.fvName}
					getOptionValue={option => option.fvId}
				/>
			</div>
		);
	};

	const renderConstraints = () => {
		return (
			<div className="sidebar-form-section">
				<span className="theme-label">Constraints</span>
				<Select
					classNamePrefix="aut-select"
					value={explorationConstraintTypes.find(
						t => t.value === (bookmark.settings.constraintGroup || 'setup')
					)}
					options={explorationConstraintTypes}
					onChange={val =>
						setBookmark({
							settings: {
								constraintGroup: val.value,
							},
						})
					}
				/>
			</div>
		);
	};

	// Summary FV list should be everything in the dataset
	const getFullFvList = () => {
		const assetSourceOptions = [];
		allFundingVehicles.forEach(fv => {
			if (fv.datasetId === datasetId) {
				const option = {
					fvName: fv.name,
					fvId: fv._id,
				};
				assetSourceOptions.push(option);
			}
		});

		return assetSourceOptions;
	};

	// Scenario FV list should only be FV's from the model that scenario is using
	const getScenarioFvList = () => {
		if (bookmarkContext.scenario.fvSettings) {
			return bookmarkContext.scenario.fvSettings.map(fv => ({
				fvName: fv.fvName,
				fvId: fv.fvId,
			}));
		} else {
			return bookmarkContext.ui.scenarioList[0].fvSettings.map(fv => ({
				fvName: fv.fvName,
				fvId: fv.fvId,
			}));
		}
	};

	const handleSetFundingVehicles = async val => {
		let valToSet;
		if (val === null || val.includes({fvId: 'all', fvName: 'All'})) {
			valToSet = ['all'];
		} else {
			valToSet = val.filter(v => v.fvId !== 'all');
			valToSet = valToSet.map(v => v.fvId);
		}
		setBookmark({
			settings: {
				fundingVehicleIds: valToSet,
			},
		});
	};

	// Eligibility FV list should be everything in the dataset (should be called Source Funding Vehicles)
	const renderFundingVehicleMultiselect = (useFullFvList = true, isEligibility = false) => {
		const fvOptions = useFullFvList ? getFullFvList() : getScenarioFvList();
		// Add option for all
		fvOptions.unshift({
			fvName: 'All',
			fvId: 'all',
		});
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">{isEligibility ? 'Source ' : ''}Funding Vehicles</span>
				<Select
					classNamePrefix="aut-select"
					value={getFvValues()}
					isClearable={false}
					options={fvOptions}
					isMulti={true}
					onChange={handleSetFundingVehicles}
					getOptionLabel={option => option.fvName}
					getOptionValue={option => option.fvId}
				/>
			</div>
		);
	};

	const renderFundingVehicleSingleSelect = (useFullFvList = true) => {
		const fvOptions = useFullFvList ? getFullFvList() : getScenarioFvList();
		// Find the first entry in the list that is not all to use
		const values = getFvValues().filter(fv => fv.Id != 'all');
		return (
			<div className={styles.selectWrapper}>
				<span className="theme-label">Funding Vehicles</span>
				<Select
					classNamePrefix="aut-select"
					value={_.get(values, '0', fvOptions[0])}
					isClearable={false}
					options={fvOptions}
					isMulti={false}
					// Wrap in array because that is how they are stored
					onChange={val => handleSetFundingVehicles([val])}
					getOptionLabel={option => option.fvName}
					getOptionValue={option => option.fvId}
				/>
			</div>
		);
	};

	// Scenario FV list should only be FV's from the model that scenario is using
	// Summary FV list should be everything in the dataset
	const renderFundingVehcleSelect = () => {
		const isMultiSelect = ['scenario', 'summary', 'eligibility'].includes(bookmark.settings.viewType);
		const useFullFvList = bookmark.settings.viewType !== 'scenario';
		const isEligibility = bookmark.settings.viewType === 'eligibility';
		if (isMultiSelect) {
			return renderFundingVehicleMultiselect(useFullFvList, isEligibility);
		}
		return renderFundingVehicleSingleSelect(useFullFvList);
	};

	return (
		<div className={styles.form} style={{height: '100%', overflow: 'auto', paddingBottom: '2rem'}}>
			<KiInput
				name="name"
				label="View Name"
				type="text"
				value={bookmark.name}
				error={nameError}
				onChange={val => setBookmark({name: val})}
			/>
			<KiCreatable
				classNamePrefix="aut-select"
				isMulti
				isClearable={true}
				name={'tags'}
				options={objectifyTags(bookmark.tags)}
				placeholder={'Add Tags...'}
				value={objectifyTags(bookmark.tags)}
				onChange={(values = []) => setBookmark({tags: values.map(tag => tag.value)})}
			/>
			<div className="sidebar-form-section">
				<span className="theme-label">View Type</span>
				<Select
					classNamePrefix="aut-select"
					value={fundingViewTypes.find(vt => vt.value == bookmark.settings.tableType)}
					isClearable={false}
					options={fundingViewTypesSelect}
					onChange={handleSetViewType}
				/>
			</div>

			{/* 
			START LOGIC BLOCK
			Any changes made in this location need to be made in the two following locations
			ki-website/app/containers/fundingAnalysis/components/flyoutForms/ViewSettingsParameters.js
			ki-website/app/containers/fundingAnalysis/components/fundingExploration/QuickFilters.js
			*/}
			{bookmark.settings.tableType === 'summary' && (
				<React.Fragment>
					{renderStatementDate()}
					{renderDateContext()}
					{renderSummaryScenarioSelector()}
				</React.Fragment>
			)}
			{bookmark.settings.tableType === 'scenario' && (
				<React.Fragment>
					{renderActualScenarioSelector()}
					{renderConstraints()}
				</React.Fragment>
			)}
			{bookmark.settings.tableType === 'eligibility' && (
				<React.Fragment>
					{renderStatementDate()}
					{renderDateContext()}
					{renderEligibilityCriteria()}
				</React.Fragment>
			)}
			{bookmark.settings.tableType === 'borrowingBase' && (
				<React.Fragment>
					{renderStatementDate()}
					{renderDateContext()}
					{renderActualScenarioSelector(true)}
				</React.Fragment>
			)}
			{/* END LOGIC BLOCK */}

			{renderFundingVehcleSelect()}
		</div>
	);
}

ViewSettingsParameters.propTypes = {
	bookmark: PropTypes.object.isRequired,
	setBookmark: PropTypes.func.isRequired,
};

ViewSettingsParameters.defaultProps = {};

export default ViewSettingsParameters;
