import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {KiInput} from 'components/KiInput';
import {updateBandsType, updateBandsStep, updateBandsSteps, addFormError, removeFormError} from './actions';
import CohortBandsOdd from './CohortBandsOdd';

export class CohortBands extends Component {
	static propTypes = {
		view: PropTypes.object,
		addFormError: PropTypes.func.isRequired,
		removeFormError: PropTypes.func.isRequired,
		updateBandsType: PropTypes.func.isRequired,
		updateBandsStep: PropTypes.func.isRequired,
		updateBandsSteps: PropTypes.func.isRequired,
		setFormHasChanges: PropTypes.func,
	};

	static defaultProps = {
		view: {},
	};

	handleSetCohortBandType = option => {
		this.props.updateBandsType(option.value);
		this.props.updateBandsStep('');
		this.props.updateBandsSteps([]);

		switch (option.value) {
			case 'custom-even':
				this.props.removeFormError('custom-odd');
				this.validateEvenStep('custom-even', '');
				break;
			case 'custom-odd':
				this.props.removeFormError('custom-even');
				this.props.addFormError('custom-odd', '');
				break;
			default:
				this.props.removeFormError('custom-even');
				this.props.removeFormError('custom-odd');
		}
		this.props.setFormHasChanges();
	};

	handleSetCohortBandEvenStep = value => {
		this.props.updateBandsStep(value);
		this.validateEvenStep('custom-even', value);
		this.props.setFormHasChanges();
	};

	validateEvenStep = (name, value) => {
		if (value === '') {
			this.props.addFormError(name, 'Required');
		} else {
			this.props.removeFormError(name);
		}
	};

	render() {
		const granularityOptions = [
			{label: 'Default', value: 'default'},
			{label: 'Custom Even', value: 'custom-even'},
			{label: 'Custom Odd', value: 'custom-odd'},
		];
		return (
			<div>
				<span className="form-instruction">Granularity Type</span>
				<Select
					classNamePrefix="aut-select"
					isDisabled={false}
					value={granularityOptions.find(opt => opt.value === this.props.view.bands.type)}
					isClearable={false}
					options={granularityOptions}
					onChange={this.handleSetCohortBandType}
				/>
				{this.props.view.bands.type === 'custom-even' && (
					<KiInput
						name="custom-even"
						label="Granularity"
						type="text"
						value={this.props.view.bands.step}
						onChange={this.handleSetCohortBandEvenStep}
						error={this.props.view.formErrors['custom-even']}
						isNumberMasked={true}
					/>
				)}
				{this.props.view.bands.type === 'custom-odd' && (
					<CohortBandsOdd setFormHasChanges={this.props.setFormHasChanges} />
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	view: state.viewForm,
});

const mapDispatchToProps = () => ({
	updateBandsType,
	updateBandsStep,
	updateBandsSteps,
	addFormError,
	removeFormError,
});

export default connect(mapStateToProps, mapDispatchToProps())(CohortBands);
