import React, {useState} from 'react';
import PropTypes from 'prop-types';
import KiSelect from '../../../components/KiSelect';
import KiInput from '../../../components/KiInput';
import KiButton from '../../../components/KiButton';

//import blendedDatasetsApi from '../../../api/blendedDatasetsApi';
import datasetCopyApi from '../../../api/datasetCopyApi';

const CopyDatasetForm = props => {
	const [dataset, setDataset] = useState({});
	const [newDatasetName, setNewDatasetName] = useState('');
	const [blendedSourceError, setBlendedSourceError] = useState('');
	const [nameError, setNameError] = useState('');
	const [isRunning, setIsRunning] = useState(false);

	const executeCopy = () => {
		setIsRunning(true);
		datasetCopyApi
			.copyInternal(dataset.datasetId, newDatasetName)
			.then(() => {
				props.showSnackbar(`Copied "${dataset.name}" to new name "${newDatasetName}"`);
				props.fetchDatasetList();
				setNewDatasetName('');
				setIsRunning(false);
			})
			.catch(() => {
				props.showSnackbar(`There was an issue copying "${dataset.name}" to new name "${newDatasetName}"`);
				setNewDatasetName('');
				props.fetchDatasetList();
				setIsRunning(false);
			});
	};

	const buttonDisabled = () => {
		return nameError.length > 0 || newDatasetName.trim().length == 0 || dataset.length === 0;
	};

	const checkName = dsName => {
		setNewDatasetName(dsName.trimStart());
		//console.log(`name were chekcing is ${dsName.toLower()}`)
		//console.log(`names in use are ${props.datasets.map(d => d.name.toLower())}`)
		if (props.datasets.map(d => d.name.trimStart().toLowerCase()).includes(dsName.trimStart().toLowerCase())) {
			setNameError(`Name "${dsName}" already in use`);
		} else {
			setNameError('');
		}
	};

	const setDatasetAndCheckIfBlended = dataset => {
		setDataset(dataset);
		setBlendedSourceError('');
		/*
		blendedDatasetsApi.fetchBlendedDatasets(dataset.datasetId).then(res => {
			if (Array.isArray(res) && res.length > 0) {
				setBlendedSourceError(
					`Note: This is a blended dataset: distribution and determination dates will be unmapped`
				);
			} else {
				setBlendedSourceError('');
			}
		});
		*/
	};

	return (
		<>
			<div className={'datasetCopyForm'}>
				<div className="dataset-selector-group">
					<span className="form-instruction">Choose Dataset To Copy:</span>
					<KiSelect
						classNamePrefix="aut-select"
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
						isClearable={false}
						options={props.datasets}
						value={props.datasets.filter(x => dataset?.datasetId === x.datasetId)}
						onChange={val => {
							setDatasetAndCheckIfBlended(val);
						}}
					/>
				</div>
				<KiInput
					label="Enter New Dataset Name"
					name="newDatasetName"
					value={newDatasetName}
					onChange={val => checkName(val)}
					maxLength={100}
					className={'dataset-name'}
					error={nameError}
				/>
				<KiButton
					raised
					primary
					label="Copy"
					className={'run-button'}
					onClick={executeCopy}
					disabled={isRunning || buttonDisabled()}
				/>
			</div>
			<div style={{height: '2.5rem'}}>
				<span className="form-instruction">{blendedSourceError}</span>
			</div>
		</>
	);
};

CopyDatasetForm.propTypes = {
	datasets: PropTypes.array.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	fetchDatasetList: PropTypes.func.isRequired,
};

CopyDatasetForm.defaultProps = {
	datasets: [],
};

export default CopyDatasetForm;
