// Dependencies
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import _get from 'lodash/get';
import CreatableSelect from 'react-select/creatable';
import {AbsUploader} from '@moodysanalytics/cs-structured-ux-common';

// Externals
import KiModal from 'components/KiModal';
import KiConfirmModal from 'components/KiConfirmModal';
import {apiUrl} from 'api/apiUtils';
import {showSnackbar} from 'state/actions/Snackbar';
import KiCheckbox from 'components/KiCheckbox';
import {associatedDataApi} from 'api';

// Relative
import styles from '../AssociatedData.theme.scss';
import {PaymentFileInformation, AppendInformation, AggregateInformation} from './InformationModals';

export class UploadForm extends Component {
	static propTypes = {
		onSnapshotChange: PropTypes.func.isRequired,
		onDelimiterChange: PropTypes.func.isRequired,
		uploader: PropTypes.object,
		dataset: PropTypes.object.isRequired,
		refreshWarning: PropTypes.string,
		delimiter: PropTypes.string,
		associatedDataType: PropTypes.string,
		onAggregateToAssetChange: PropTypes.func.isRequired,
		aggregateToAsset: PropTypes.bool,
		schema: PropTypes.string,
		onAppendModeChange: PropTypes.func,
		onSchemaChange: PropTypes.func,
		uploaderRef: PropTypes.object.isRequired,
	};

	state = {
		delimiter: this.props.delimiter || 'comma',
		snapshotDate: '',
		fileName: '',
		uploadStatus: '',
		paymentFileInfoModalActive: false,
		aggregateToAsset: this.props.aggregateToAsset || false,
		schema: this.props.schema,
		schemaList: [],
		selectedAppendOption: 'append',
		showOverwriteWarning: false,
	};
	componentDidMount() {
		associatedDataApi
			.fetchAssociatedDataSchemasByDatasetId(this.props.dataset.datasetId)
			.then(schemaList => this.setState({schemaList: schemaList}));
	}

	componentDidUpdate() {
		this.props.uploader.options.endpoint = `${apiUrl}/associatedData/upload/${this.props.associatedDataType}`;
	}

	handleSnapshotDateChange = snapshotDate => {
		this.props.onSnapshotChange(snapshotDate);
		this.setState({snapshotDate: snapshotDate});
	};

	handleDelimiterChange = delimiter => {
		this.props.onDelimiterChange(delimiter);
		this.setState({delimiter: delimiter});
	};

	handleAggregateToAssetChange = aggregateToAsset => {
		this.props.onAggregateToAssetChange(aggregateToAsset);
		this.setState({aggregateToAsset: aggregateToAsset, schema: undefined});
	};

	handleSchemaChange = schema => {
		if (schema && schema.__isNew__) {
			this.setState({schema: {_id: null, name: schema.label}});
			this.props.onSchemaChange({_id: null, name: schema.label});
		} else {
			this.setState({schema: {_id: schema.value, name: schema.label}});
			this.props.onSchemaChange({_id: schema.value, name: schema.label});
		}
	};

	handleAppendModeChange = val => {
		this.props.onAppendModeChange(val === 'append');
		this.setState({selectedAppendOption: val});
		if (val === 'overwrite') {
			this.setState({showOverwriteWarning: true});
		}
	};

	getInformationModalHeader = () => {
		const associatedDataType = _get(this.props, 'associatedDataType');
		let fileType;
		if (!associatedDataType) {
			return;
		}

		if (associatedDataType === 'paymentFile') {
			fileType = 'a Payment';
		}

		if (associatedDataType === 'appendData') {
			fileType = 'an Append';
		}

		if (associatedDataType === 'aggregate') {
			fileType = 'a Child';
		}

		if (associatedDataType === 'subaccount') {
			fileType = 'a Child';
		}
		return `Upload ${fileType} File for use in Capital Markets`;
	};

	renderAggregateForm = () => {
		const appendOptions = [{value: 'append', label: 'Append'}, {value: 'overwrite', label: 'Overwrite'}];
		return (
			<Fragment>
				<div className={styles.selectWrapper}>
					<KiCheckbox
						checked={this.state.aggregateToAsset}
						label="Aggregate to Asset"
						onChange={aggregateToAsset => this.handleAggregateToAssetChange(aggregateToAsset)}
					/>
					<span className={styles.instructions}>
						If selected, child records will be aggregated immediately upon submission. Otherwise, child
						records will be available for use in aggregating columns in Data Exploration.
					</span>
				</div>
				{!this.state.aggregateToAsset && (
					<div className={styles.selectWrapper}>
						<span className={styles.label}>Choose or create a Schema</span>
						<CreatableSelect
							className={styles.selectNarrow}
							classNamePrefix="aut-select"
							isClearable={false}
							onChange={this.handleSchemaChange}
							options={this.state.schemaList.map(o => ({value: o._id, label: o.name}))}
						/>
					</div>
				)}
				{!this.state.aggregateToAsset &&
					this.state.schema &&
					this.state.schema._id &&
					this.props.refreshWarning && (
						<div className={styles.selectWrapper}>
							<span className={styles.label}>Select Mode</span>
							<Select
								className={styles.selectNarrow}
								value={appendOptions.find(opt => opt.value === this.state.selectedAppendOption)}
								classNamePrefix="aut-select"
								isClearable={false}
								options={appendOptions}
								onChange={option => this.handleAppendModeChange(option.value)}
							/>
						</div>
					)}
			</Fragment>
		);
	};

	render() {
		const delimiterOptions = [
			{value: 'comma', label: 'Comma'},
			{value: 'pipe', label: 'Pipe'},
			{value: 'tab', label: 'Tab'},
		];
		const {associatedDataType} = this.props;
		return (
			<div className={styles.uploadWrapper}>
				{associatedDataType === 'aggregate' || associatedDataType === 'subaccount'
					? this.renderAggregateForm()
					: ''}
				<div className={styles.selectWrapper}>
					<span className={styles.label}>Snapshot Date</span>
					<Select
						className={styles.selectNarrow}
						classNamePrefix="aut-select"
						isClearable={false}
						getOptionLabel={option => option.snapshotDate}
						getOptionValue={option => option.snapshotDate}
						options={this.props.dataset.snapshots}
						onChange={option => this.handleSnapshotDateChange(option.snapshotDate)}
					/>
					<div className={styles.refreshWarning}>{this.props.refreshWarning}</div>
				</div>
				<div className={styles.selectWrapper}>
					<span className={styles.label}>Delimiter</span>
					<Select
						className={styles.selectNarrow}
						value={delimiterOptions.find(opt => opt.value === this.state.delimiter)}
						classNamePrefix="aut-select"
						isClearable={false}
						options={delimiterOptions}
						onChange={option => this.handleDelimiterChange(option.value)}
					/>
				</div>
				<div>
					<AbsUploader ref={this.props.uploaderRef} config={this.props.uploader} />
					<p
						className={styles.requirements}
						onClick={() => this.setState({paymentFileInfoModalActive: true})}
					>
						File Layout Requirements
					</p>
					associatedDataType {associatedDataType}
				</div>
				<KiModal
					actions={[{label: 'Ok', onClick: () => this.setState({paymentFileInfoModalActive: false})}]}
					active={this.state.paymentFileInfoModalActive}
					header={this.getInformationModalHeader()}
					className="ki-modal payment-file-info"
					onClose={() => this.setState({paymentFileInfoModalActive: false})}
				>
					{associatedDataType === 'paymentFile' && <PaymentFileInformation />}
					{associatedDataType === 'appendData' && <AppendInformation />}
					{associatedDataType === 'aggregate' && <AggregateInformation />}
					{associatedDataType === 'subaccount' && <AggregateInformation />}
				</KiModal>
				<KiConfirmModal
					header="Warning"
					message="Your current schema will be overwritten"
					acceptFunc={() => this.setState({showOverwriteWarning: false})}
					acceptLabel="Ok"
					active={this.state.showOverwriteWarning}
				/>
			</div>
		);
	}
}

export default connect(
	null,
	{showSnackbar}
)(UploadForm);
