// Global imports
import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {getDefaultColumnIds} from 'ki-common/utils/explorerUtils';
import {explorationViewTypes} from 'ki-common/options';

// Project imports
import {KiSelect, KiCreatable} from 'components/KiSelect';
import KiInput from 'components/KiInput';
import KiDatePicker from 'components/KiDatePicker';
import CohortSelector from 'components/CohortSelector';
import GroupBySelector from 'components/GroupBySelector';

// Local imports
import {DataExplorerContext} from '../DataExplorerContext';
import TimeSeriesColumnSelector from '../components/TimeSeriesColumnSelector';
import TimeSeriesDisplayPeriodSelector from '../components/TimeSeriesDisplayPeriodSelector';
import TimeSeriesPeriodSelector from '../components/TimeSeriesPeriodSelector';

// Logger
import ReactLogger from 'utils/ReactLogger';
const logger = new ReactLogger({level: window.REACT_LOG_LEVEL});

// TODO move the fetch quickfilter method out of DataExploerContext and into this context

// Main context
export const QuickFilterSettingContext = createContext();
QuickFilterSettingContext.displayName = 'QuickFilterSettingContext';
export default QuickFilterSettingContext;

// Main provider
export const QuickFilterSettingProvider = ({children}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		renderState: {isLoading},
		bookmark: {explorerData, name, tags},
		dateContextList,
		allColumns,
		quickFilterLists,
		setBookmarkAndMarkChanged,
		setExplorerData,
		actions,
	} = dataExplorerContext;

	const dataset = useSelector(state => state.datasetList.selected);

	// Trim the names to clear whitespace
	// Validate that this one is filled out (set custom errors etc)
	const [nameError, setNameError] = useState(null);
	const handleNameChange = value => {
		if (!value || value === '' || !value.trim()) {
			setBookmarkAndMarkChanged({name: value});
			setNameError('Required Field');
		} else {
			setBookmarkAndMarkChanged({name: value});
			setNameError(null);
		}
	};
	const getNameInput = () => {
		return (
			<KiInput
				name="name"
				label="View Name"
				type="text"
				value={name}
				error={nameError}
				onChange={handleNameChange}
				//onBlur={e => valueBlur('name', e.target.value)}
			/>
		);
	};

	const getTagsInput = () => {
		return (
			<KiCreatable
				classNamePrefix="aut-select"
				isMulti={true}
				placeholder={'Add Tags'}
				options={[]}
				value={tags.map(tag => ({value: tag, label: tag}))}
				onChange={tags => setBookmarkAndMarkChanged({tags: tags.map(v => v.value)})}
			/>
		);
	};

	const handleViewTypeChange = value => {
		const updateData = {
			snapshotType: value,
		};
		if (value === 'encumbrance') {
			actions.setTableType('cohort');

			// Set isFixedDate to false
			updateData.isFixedDate = false;

			// Change explorationType to summary if currently timeseries and clear old values
			if (explorerData.tableType === 'timeSeries') {
				actions.setTimeSeriesData({columnData: '', range: '', period: ''});
			}
		}
		setExplorerData(updateData, true);
	};
	const getViewTypeDropdown = () => {
		return (
			<KiSelect
				isDisabled={isLoading}
				classNamePrefix="aut-select"
				value={explorationViewTypes.find(opt => opt.value === explorerData.snapshotType)}
				isClearable={false}
				options={explorationViewTypes}
				onChange={option => handleViewTypeChange(option.value)}
			/>
		);
	};

	const explorationOptions = [
		{
			label: 'Summary',
			value: 'cohort',
		},
		{
			label: 'Asset',
			value: 'asset',
		},
		{
			label: 'Time Series',
			value: 'timeSeries',
		},
	];
	const getExplorationOptions = () => {
		if (explorerData.snapshotType === 'encumbrance') {
			return explorationOptions.filter(option => option.value !== 'timeSeries');
		}
		return explorationOptions;
	};
	const explorationTypeChanged = type => {
		if (type === 'timeSeries') {
			actions.setTimeSeriesData({
				columnData: {id: explorerData.columns.filter(col => col.columnType === 'aggregate')[0]._id},
				range: 'last_6_periods',
				period: 'monthly',
			});
			actions.setGroupBy('');
		} else {
			actions.setTimeSeriesData({columnData: '', range: '', period: ''});
		}
		if (type === 'asset') {
			actions.setGroupBy('');
		}
		actions.setTableType(type);

		//setExplorerData({tableType: value}, true)
	};
	// Previously "View Type" and was stored as "tableType"
	const getExplorationTypeDropdown = () => {
		return (
			<KiSelect
				classNamePrefix="aut-select"
				value={getExplorationOptions().find(opt => opt.value === explorerData.tableType)}
				isDisabled={isLoading}
				isClearable={false}
				options={getExplorationOptions()}
				onChange={option => explorationTypeChanged(option.value)}
			/>
		);
	};

	const handleSetCohortColumn = columnId => {
		actions.setCohortColumn(columnId);
		// Not sure this is required, commenting out for now
		// setExplorerData({dateRange: {start: '', end: ''}});
	};
	const getCohortDropdown = () => {
		return (
			<CohortSelector
				selectedColumn={
					_get(explorerData, 'timeseries.column._id') === 'all'
						? allColumns.find(
								col =>
									_get(col, 'columnName') === 'kiSnapshotDate' && _get(col, 'columnType') === 'cohort'
						  )._id
						: explorerData?.columns[0]?._id
				}
				columns={allColumns}
				targetFunction={handleSetCohortColumn}
				isGroupBy={!!explorerData.groupBy}
				disabled={_get(explorerData, 'timeseries.column._id') === 'all' || isLoading}
			/>
		);
	};

	const getGroupByDropdown = () => {
		return (
			<GroupBySelector
				selectedColumn={explorerData.groupBy}
				columns={allColumns}
				targetFunction={actions.setGroupBy}
				assetCohortColumnId={
					getDefaultColumnIds(dataset.snapshots, allColumns, explorerData.snapshotDate).assetCohortColumnId
				}
				isDisabled={isLoading}
			/>
		);
	};

	const handleStatementDateChange = selected => {
		const statementDate = dateToShortDate(selected);
		actions.setStatementDate(statementDate);
	};

	const debouncedHandleStatementDateChange = useCallback(_debounce(handleStatementDateChange, 1000), []);
	const getStatementDateInput = (className = '') => {
		return (
			<KiDatePicker
				onChange={debouncedHandleStatementDateChange}
				value={explorerData.statementDate}
				className={className}
				disabled={isLoading}
			/>
		);
	};

	const getDateContextDropdown = () => {
		return (
			<KiSelect
				classNamePrefix="aut-select"
				isDisabled={isLoading}
				value={dateContextList.find(d => d._id === explorerData.dateContext)}
				isClearable={false}
				options={dateContextList}
				onChange={value => setExplorerData({dateContext: value._id}, true)}
				getOptionLabel={option => option.name}
				getOptionValue={option => option._id}
			/>
		);
	};

	// Default start of month
	const getStartDateContextDropdown = () => {
		return (
			<KiSelect
				classNamePrefix="aut-select"
				isDisabled={isLoading}
				value={dateContextList.find(d => d._id === explorerData.startDateContext)}
				isClearable={false}
				options={dateContextList}
				onChange={value => setExplorerData({startDateContext: value._id}, true)}
				getOptionLabel={option => option.name}
				getOptionValue={option => option._id}
			/>
		);
	};

	// Default end of month
	const getEndDateContextDropdown = () => {
		return (
			<KiSelect
				classNamePrefix="aut-select"
				isDisabled={isLoading}
				value={dateContextList.find(d => d._id === explorerData.endDateContext)}
				isClearable={false}
				options={dateContextList}
				onChange={value => setExplorerData({endDateContext: value._id}, true)}
				getOptionLabel={option => option.name}
				getOptionValue={option => option._id}
			/>
		);
	};

	/*
	{label: "Start of Day", value: "assetSnapshot"}
	{label: "End of Day", value: "lastApproved"}
	{label: "Hypo", options: [
		{label:"RecurringScenario 2019-07-01 (Open)", status:"solved", value:"6335d3a1799f560013d34657"	}
	]}
	{label: "Pending", options: [
		{label:"2019-07-01", value:"2019-07-01", }
	]}
	 */
	const findActiveScenario = scenarios => {
		const {scenarioId} = explorerData.quickFilters;

		const activeById = _flatten(scenarios.map(scenario => (scenario.options ? scenario.options : scenario))).find(
			scenario => scenario.value === scenarioId
		);

		// If the active scenario cannot be found it is invalid, let the field be blank and
		// do not default it to lastApproved without also updating the bookmark
		//  || scenarios.find(s => s.value === 'lastApproved')
		return activeById || null;
	};
	const getScenarioContextDropdown = (className = '') => {
		let scenarioOptions = quickFilterLists.scenarios;
		if (explorerData.snapshotType === 'encumbrance') {
			scenarioOptions = quickFilterLists.scenarios.filter(option => {
				return ['Start of Day', 'Hypo'].includes(option.label) ? false : true;
			});
		}
		return (
			<KiSelect
				classNamePrefix={`aut-select ${className}`}
				value={findActiveScenario(scenarioOptions)}
				isClearable={false}
				isDisabled={isLoading}
				options={scenarioOptions}
				onChange={option => actions.setQuickFilterScenario(option.value)}
			/>
		);
	};

	const getFundingVehicleDropdown = () => {
		return (
			<KiSelect
				classNamePrefix="aut-select"
				value={quickFilterLists.fundingVehicles.find(
					fv => fv.value === explorerData.quickFilters.fundingVehicleId
				)}
				isClearable={false}
				isDisabled={isLoading}
				options={quickFilterLists.fundingVehicles}
				onChange={option => actions.setQuickFilterFundingVehicle(option.value)}
			/>
		);
	};

	const getPoolDropdown = () => {
		return (
			<KiSelect
				classNamePrefix="aut-select"
				value={
					quickFilterLists.pools.find(p => p.value === explorerData.quickFilters.poolId) ||
					quickFilterLists.pools.find(p => p.value === '')
				}
				isClearable={false}
				isDisabled={isLoading}
				options={quickFilterLists.pools}
				onChange={option => actions.setQuickFilterPool(option.value)}
			/>
		);
	};

	const getTimeSeriesColumnSelector = () => {
		return (
			<TimeSeriesColumnSelector
				key="TimeSeriesColumnSelector"
				dataset={dataset}
				explorerRequest={explorerData}
				targetFunction={columnName => actions.setTimeSeriesData({columnData: {id: columnName || 'all'}})}
				disabled={isLoading}
			/>
		);
	};

	const getTimeSeriesDisplayPeriodSelector = () => {
		return (
			<TimeSeriesDisplayPeriodSelector
				key="TimeSeriesDisplayPeriodSelector"
				dataset={dataset}
				explorerRequest={explorerData}
				targetFunction={range => actions.setTimeSeriesData({range})}
				disabled={isLoading}
			/>
		);
	};

	const getTimeSeriesPeriodSelector = () => {
		return (
			<TimeSeriesPeriodSelector
				key="TimeSeriesPeriodSelector"
				dataset={dataset}
				explorerRequest={explorerData}
				targetFunction={period => actions.setTimeSeriesData({period})}
				explorerData={explorerData.data}
				disabled={isLoading}
			/>
		);
	};

	// -------------------
	// useEffect functions
	// -------------------

	// TODO placeholder
	useEffect(() => {
		logger.log('QuickFilterSettingContext');
	}, []);

	return (
		<QuickFilterSettingContext.Provider
			value={{
				getViewTypeDropdown,
				getExplorationTypeDropdown,
				getNameInput,
				getTagsInput,
				getCohortDropdown,
				getGroupByDropdown,
				getStatementDateInput,
				getDateContextDropdown,
				getStartDateContextDropdown,
				getEndDateContextDropdown,
				getScenarioContextDropdown,
				getFundingVehicleDropdown,
				getPoolDropdown,
				getTimeSeriesColumnSelector,
				getTimeSeriesDisplayPeriodSelector,
				getTimeSeriesPeriodSelector,
			}}
		>
			{children}
		</QuickFilterSettingContext.Provider>
	);
};

QuickFilterSettingProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

QuickFilterSettingProvider.defaultProps = {};
