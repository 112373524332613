import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiList from 'components/KiList';
import KiFontIcon from 'components/KiFontIcon';
import KiIconButton from 'components/KiIconButton';
import KiAppBar from 'components/KiAppBar';
import KiCheckbox from 'components/KiCheckbox';
import KiChip from 'components/KiChip';
import KiMenu from 'components/KiMenu';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import {
	openModal,
	removeAccounts,
	deactivateAccounts,
	activateAccounts,
	fetchAccounts,
} from 'containers/accounts/actions';
import AccountModal from '../AccountModal';
import KiConfirmModal from 'components/KiConfirmModal';
import _some from 'lodash/some';
import 'components/List/list.scss';
import './AccountList.scss';

export const AccountListItem = ({
	onAccountChecked,
	onAccountSelected,
	account = {},
	checked = false,
	isCurUserAccount = false,
}) => {
	const GetCheckbox = () => {
		return isCurUserAccount ? (
			<div />
		) : (
			<KiCheckbox className="select-item-checkbox" checked={checked} onChange={onAccountChecked} />
		);
	};

	return (
		<div className="checkbox-list-item">
			<div className="select-item">
				<GetCheckbox />
			</div>
			<div className="checkbox-list-item-clickable" onClick={onAccountSelected}>
				<KiFontIcon className="account-avatar">account_circle</KiFontIcon>
				<h5 className="item-name">
					{account.lastName}, {account.firstName}
				</h5>
				<p>{account.userId}</p>
				<p>{account.email}</p>
				<div>
					{account.groups.map(g => (
						<KiChip className="fv-chip" key={g}>
							{g}
						</KiChip>
					))}
				</div>
				<p>{account.isActive ? 'Active' : 'Inactive'}</p>
			</div>
		</div>
	);
};
AccountListItem.propTypes = {
	onAccountChecked: PropTypes.func,
	onAccountSelected: PropTypes.func,
	account: PropTypes.object,
	checked: PropTypes.bool,
	isCurUserAccount: PropTypes.bool,
};

export const ConfirmMsg = ({accounts = [], action}) => {
	let msg;
	switch (action) {
		case 'delete':
			msg = 'Are you sure you want to delete the following account(s)?';
			break;
		case 'deactivate':
			msg = 'Are you sure you want to deactivate the following account(s)?';
			break;
		case 'activate':
			msg = 'Are you sure you want to activate the following account(s)?';
			break;
		default:
			msg = '';
	}
	return (
		<div>
			<h2>{msg}</h2>
			<ul>
				{accounts.map(account => (
					<li key={account._id}>
						{account.lastName}, {account.firstName} ({account.userId}){' '}
					</li>
				))}
			</ul>
		</div>
	);
};
ConfirmMsg.propTypes = {
	accounts: PropTypes.array,
	action: PropTypes.string,
};

export class AccountList extends Component {
	static propTypes = {
		accounts: PropTypes.array,
		user: PropTypes.object,
		openModal: PropTypes.func.isRequired,
		removeAccounts: PropTypes.func,
		deactivateAccounts: PropTypes.func,
		activateAccounts: PropTypes.func,
		fetchAccounts: PropTypes.func,
	};

	state = {
		selectedAccount: null,
		checkedAccounts: [],
		showDeleteConfirm: false,
		showDeactivateConfirm: false,
		showActivateConfirm: false,
	};

	// Delete
	handleDeleteAccounts = () => {
		this.props.removeAccounts(this.state.checkedAccounts.map(a => a._id)).then(() => {
			this.setState({
				checkedAccounts: [],
				showDeleteConfirm: false,
			});
			this.props.fetchAccounts();
		});
	};

	showDeleteConfirmToggle = () => {
		this.setState({showDeleteConfirm: !this.state.showDeleteConfirm});
	};

	// Deactivate
	handleDeactivateAccounts = () => {
		this.props.deactivateAccounts(this.state.checkedAccounts.map(a => a._id)).then(() => {
			this.setState({
				checkedAccounts: [],
				showDeactivateConfirm: false,
			});
			this.props.fetchAccounts();
		});
	};

	showDeactivateConfirmToggle = () => {
		this.setState({
			showDeactivateConfirm: !this.state.showDeactivateConfirm,
		});
	};

	// Activate
	handleActivateAccounts = () => {
		this.props.activateAccounts(this.state.checkedAccounts.map(a => a._id)).then(() => {
			this.setState({
				checkedAccounts: [],
				showActivateConfirm: false,
			});
			this.props.fetchAccounts();
		});
	};

	showActivateConfirmToggle = () => {
		this.setState({showActivateConfirm: !this.state.showActivateConfirm});
	};

	handleAccountChecked = account => {
		if (this.state.checkedAccounts.findIndex(u => u.userId === account.userId) >= 0) {
			this.setState({
				checkedAccounts: this.state.checkedAccounts.filter(u => u.userId !== account.userId),
			});
		} else {
			this.setState({
				checkedAccounts: [account, ...this.state.checkedAccounts],
			});
		}
	};

	hasAccountAdminRights = () => {
		const matchSystemAdmins = this.props.user.groups.find(u => u === 'SystemAdmins');
		const matchUserAdmins = this.props.user.groups.find(u => u === 'UserAdmins');
		return matchSystemAdmins || matchUserAdmins;
	};

	render() {
		const {accounts, openModal} = this.props;
		const {checkedAccounts} = this.state;
		return (
			<div className="account-list">
				<AccountModal />
				<KiAppBar title="Accounts" className="top-bar">
					<KiIconButton icon="add_circle" inverse onClick={() => openModal()} />
					<KiMenu icon="more_vert" className="top-bar-menu" position="topRight">
						<KiMenuItem
							icon="delete"
							label="Delete"
							onClick={this.showDeleteConfirmToggle}
							disabled={!this.hasAccountAdminRights() || !checkedAccounts.length}
						/>
						<KiMenuItem
							icon="block"
							label="Deactivate"
							onClick={this.showDeactivateConfirmToggle}
							disabled={
								!this.hasAccountAdminRights() ||
								!checkedAccounts.length ||
								_some(checkedAccounts, {isActive: false})
							}
						/>
						<KiMenuItem
							icon="done"
							label="Activate"
							onClick={this.showActivateConfirmToggle}
							disabled={
								!this.hasAccountAdminRights() ||
								!checkedAccounts.length ||
								_some(checkedAccounts, {isActive: true})
							}
						/>
					</KiMenu>
				</KiAppBar>

				<KiList className="checkbox-list ki-panel">
					{accounts.map(account => {
						const isCurUserAccount = this.props.user.userId === account.userId;
						return (
							<AccountListItem
								key={account._id}
								className="checkbox-list-item"
								account={account}
								checked={!!this.state.checkedAccounts.find(u => u.userId === account.userId)}
								onAccountChecked={this.handleAccountChecked.bind(this, account)}
								onAccountSelected={
									isCurUserAccount
										? () => {
												return false;
										  }
										: () => openModal(account)
								}
								isCurUserAccount={isCurUserAccount}
							/>
						);
					})}
				</KiList>
				<KiConfirmModal
					header="Delete Accounts"
					message={<ConfirmMsg accounts={this.state.checkedAccounts} action="delete" />}
					acceptFunc={this.handleDeleteAccounts}
					rejectFunc={this.showDeleteConfirmToggle}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.showDeleteConfirm}
				/>
				<KiConfirmModal
					header="Deactivate Accounts"
					message={<ConfirmMsg accounts={this.state.checkedAccounts} action="deactivate" />}
					acceptFunc={this.handleDeactivateAccounts}
					rejectFunc={this.showDeactivateConfirmToggle}
					acceptLabel="Deactivate"
					rejectLabel="Cancel"
					active={this.state.showDeactivateConfirm}
				/>
				<KiConfirmModal
					header="Activate Accounts"
					message={<ConfirmMsg accounts={this.state.checkedAccounts} action="activate" />}
					acceptFunc={this.handleActivateAccounts}
					rejectFunc={this.showActivateConfirmToggle}
					acceptLabel="Activate"
					rejectLabel="Cancel"
					active={this.state.showActivateConfirm}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	openModal: state.openModal,
});

export default connect(mapStateToProps, {
	openModal,
	removeAccounts,
	deactivateAccounts,
	activateAccounts,
	fetchAccounts,
})(AccountList);
