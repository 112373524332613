import React from 'react';
import PropTypes from 'prop-types';

export const HeaderCell = props => {
	return (
		<div className="table-header">
			<div className="table-header-title">
				<div className="title-wrapper">
					<div>
						<div className="display-name" title={props.title}>
							{props.title}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
HeaderCell.propTypes = {
	title: PropTypes.string,
};

export default HeaderCell;
