import PropTypes from 'prop-types';
import React from 'react';
import NumericSummaryForm from './NumericSummaryForm';
import StringSummaryForm from './StringSummaryForm';

const SummaryFormDialog = props => {
	const isAdmin = props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
	switch (props.selectedValue) {
		case 'numeric':
			return (
				<NumericSummaryForm
					active={props.active}
					datasetId={props.datasetId}
					submitMethod={props.saveSummaryAggregateColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
				/>
			);
		case 'date_long':
		case 'date_short':
		case 'string':
			return (
				<StringSummaryForm
					type={props.selectedValue}
					active={props.active}
					datasetId={props.datasetId}
					submitMethod={props.saveSummaryAggregateColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
				/>
			);
		default:
			return null;
	}
};

SummaryFormDialog.propTypes = {
	selectedValue: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	columnList: PropTypes.array.isRequired,
	closeForm: PropTypes.func.isRequired,
	saveSummaryAggregateColumn: PropTypes.func.isRequired,
	columnToEdit: PropTypes.object,
	mode: PropTypes.string,
	user: PropTypes.object,
	datasetId: PropTypes.string,
	reloadView: PropTypes.func,
	existingTags: PropTypes.array,
};

export default SummaryFormDialog;
