import PropTypes from 'prop-types';
import React from 'react';
import KiListItem from 'components/KiList/KiListItem';

export const VisualizationLink = props => {
	if (props.show) {
		return (
			<KiListItem
				className={props.className}
				icon="show_chart"
				caption="Visualization"
				onClick={props.handleClick}
			/>
		);
	}
	return null;
};

VisualizationLink.propTypes = {
	show: PropTypes.bool,
	handleClick: PropTypes.func,
	className: PropTypes.string,
};

export default VisualizationLink;
