const dateTypes = require('../../enums').dateTypes;

const getConstraints = () => ({
	'defaultValue': (value, attributes) => {
		if (!value && value !== 0 && value !== '0') {
			return {
				presence: false,
			};
		}

		let dateFormat = dateTypes.get(attributes.format);
		if (!dateFormat) {
			dateFormat = dateTypes.get('custom'); // any undefined means custom validation
		}

		switch (attributes.dataType) {
			case 'numeric': {
				return {
					presence: false,
					numericality: true,
					length: {
						maximum: 9999
					}
				};
			}
			case 'date_short': {
				if (!attributes.format) { // sometimes there isn't a format so ignore
					return {
						presence: false,
					};
				}
				return {
					presence: false,
					format: {
						pattern: dateFormat.regex,
						flags: 'i',
					},
				};
			}
			case 'date_long': {
				if (!attributes.format) { // sometimes there isn't a format so ignore
					return {
						presence: false,
					};
				}
				return {
					presence: false,
					format: {
						pattern: dateFormat.regex,
						flags: 'i',
					},
				};
			}
			default: {
				return {
					presence: false,
					length: {
						maximum: 9999
					}
				};
			}
		}
	},
	'customFormat': (value) => {
		if (!value && value !== 0 && value !== '0') {
			return {
				presence: false,
			};
		}

		return {
			isCustomSchemaDate: true
		};
	}
});

module.exports = {
	getConstraints
};
