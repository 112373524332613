import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiInput from 'components/KiInput';
import options from 'ki-common/options';
import KiButton from 'components/KiButton';
import KiSelect, {KiCreatable} from 'components/KiSelect';
import _ from 'lodash';
import KiCheckbox from 'components/KiCheckbox';
import DateFormulaFields from './components/DateFormulaFields';

/**
 * returns column output values for dataType, displayFormat, and defaultValue derived from formula.
 * @param formula
 * @returns {{defaultValue: string, dataType: string, displayFormat: string}}
 */
export const getDateColumnOutputFromFormula = formula => {
	const functionType = _.get(formula, 'op', 'dateDiff');
	switch (functionType) {
		case 'dateAdd':
			return {
				dataType: 'date_long',
				displayFormat: 'yyyy-MM-dd',
				defaultValue: '1900-01-01',
			};
		case 'dateParse': {
			// special case for year-month, otherwise fall back to numeric
			if (_.get(formula, 'left.parseType') === 'year-month') {
				return {
					dataType: 'date_short',
					displayFormat: 'yyyy-MM',
					defaultValue: '1900-01',
				};
			}
		} // eslint-disable-next-line no-fallthrough
		case 'dateDiff':
		default: {
			return {
				dataType: 'numeric',
				displayFormat: '9,999',
				defaultValue: '0',
			};
		}
	}
};

export class DateForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		columnList: PropTypes.array.isRequired,
		existingTags: PropTypes.array.isRequired,
		columnToEdit: PropTypes.object,
		mode: PropTypes.string,
		closeForm: PropTypes.func,
		isAdmin: PropTypes.bool,
	};

	static defaultProps = {
		existingTags: [],
	};

	constructor(props, context) {
		super(props, context);
		const columnToEdit = {...props.columnToEdit};
		this.state = {
			displayName: columnToEdit.displayName || '',
			tags: columnToEdit.tags || [],
			nameError: null,
			functionType: _.get(columnToEdit, 'formula.op', 'dateDiff'),
			isGlobal: columnToEdit.createdBy === '' || null,
			formula: columnToEdit.formula,
			isValid: false,
		};
	}

	setName = val => {
		this.setState({
			displayName: val,
			nameError: val.trim().length ? null : "Name can't be blank",
		});
	};

	isDuplicateNameError = () => {
		if (_.get(this, 'props.columnToEdit.displayName') === this.state.displayName) {
			return;
		}

		const assetCols = this.props.columnList.filter(
			col => (!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset'
		);
		// Check if the name already exists in assetCols
		return assetCols.find(col => _.get(col, 'displayName').trim() === this.state.displayName.trim());
	};

	getSaveAndFormatDisabled = () => {
		const {functionType, displayName, isValid} = this.state;
		return !isValid || !functionType || !String(displayName).trim();
	};

	onSubmit = () => {
		const {displayName, tags, isGlobal, formula} = this.state;
		this.setState({isSaving: true});
		if (!displayName.trim()) {
			this.setState({nameError: 'Name cant be blank', isSaving: false});
			return;
		}
		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		} else {
			this.setState({nameError: ''});
		}
		const {displayFormat, dataType, defaultValue} = getDateColumnOutputFromFormula(formula);
		const columnForSaving = {
			_id: _.get(this.props, 'columnToEdit._id', null),
			columnName: displayName,
			displayName: displayName,
			tags: tags || [],
			displayFormat,
			dataType,
			columnType: 'asset',
			columnFormType: 'date',
			defaultValue,
			formula: formula,
			createdBy: _.get(this.props, 'columnToEdit.createdBy', null),
			isGlobal: !!isGlobal,
		};
		return this.props.submitMethod(columnForSaving);
	};

	render() {
		const {displayName, nameError, functionType, isSaving} = this.state;
		return (
			<form onSubmit={this.onSubmit} className="column-selector-form inline-column-form">
				<section className="calculation-name-tags">
					<KiInput label="Name" value={displayName} onChange={val => this.setName(val)} error={nameError} />
					<span className="form-instruction">Tags:</span>
					<KiCreatable
						isMulti={true}
						options={this.props.existingTags.map(t => ({
							value: t,
							label: t,
						}))}
						value={this.state.tags.map(t => ({value: t, label: t}))}
						onChange={val => this.setState({tags: val.map(t => t.value)})}
						placeholder="Add some tags"
					/>
					<div className="calculation-form-section">
						<span className="form-instruction">Function:</span>
						<KiSelect
							name="functionType"
							value={options.dateFunctions.find(opt => opt.value === functionType)}
							isClearable={false}
							options={options.dateFunctions}
							onChange={selected => this.setState({functionType: selected.value})}
						/>
					</div>
					<DateFormulaFields
						dateFunctionType={functionType}
						formula={this.state.formula}
						dateColumns={this.props.columnList}
						onChange={val =>
							this.setState({
								isValid: !!val.isValid,
								formula: val.formula,
							})
						}
					/>
				</section>

				{this.props.mode === 'inline' &&
					this.props.isAdmin &&
					!_.get(this, 'props.columnToEdit._id', null) && (
						<div className="calculation-form-section">
							<KiCheckbox
								name="isGlobal"
								checked={this.state.isGlobal}
								label="Make Global"
								onChange={val => this.setState({isGlobal: val})}
							/>
						</div>
					)}
				<section className="format-and-save">
					<div className="inline-column-form-buttons">
						<KiButton flat primary onClick={() => this.props.closeForm()}>
							Cancel
						</KiButton>
						<KiButton
							disabled={this.getSaveAndFormatDisabled() || isSaving}
							raised
							primary
							onClick={() => this.onSubmit()}
						>
							Save
						</KiButton>
					</div>
				</section>
			</form>
		);
	}
}

export default DateForm;
