import * as actionTypes from './actionTypes';

const initialState = {
	// housekeeping for form
	formErrors: {},
	// root
	createDate: new Date().toISOString(),
	createdBy: '',
	datasetId: '',
	isDefault: false,
	isFavorite: false,
	isGadget: false,
	name: '',
	tags: [],
	_id: null,
	// root.explorerData
	breadcrumbs: [],
	bucket: {
		min: '',
		max: '',
		value: '',
	},
	columns: [],
	filters: [],
	pageNumber: 1,
	groupBy: '',
	maxRecords: null,
	sortCalculation: '',
	sortColumn: '',
	sortOrder: 'asc',
	tableType: 'cohort',
	statementDate: '',
	dateContext: '',
	snapshotType: 'standard',
	isFixedDate: false,
	// root.explorerData.timeseries
	timeseriesColumn: '', // view.explorerData.columns[1] in BE request
	timeseriesPeriod: '', // view.timeSeriesGranularity in BE request
	timeseriesRange: '', // view.timeSeriesPeriodsDisplayed in BE request
	// root.explorerData.quickFilters
	fundingVehicleId: '',
	hypoFundingVehicleId: '',
	hypoPoolId: '',
	poolId: '',
	scenarioId: 'assetSnapshot',
	scenarioType: 'assetSnapshot',
	bands: {
		type: 'default',
	},
	startInclusive: true,
};

const View = (state = initialState, action) => {
	//eslint-disable-line complexity
	const updateColumn = (columnList, index, column) => {
		const columns = columnList.slice();
		columns.splice(index, 1, column);
		return columns;
	};

	const updateBandStep = (stepList, index, step) => {
		const steps = stepList.slice();
		steps.splice(index, 1, step);
		return steps;
	};

	const removeBandStep = (stepList, index) => {
		const steps = stepList.slice();
		steps.splice(index, 1);
		return steps;
	};

	const addBandStep = (stepList, step) => {
		const steps = stepList.slice();
		steps.push(step);
		return steps;
	};

	switch (action.type) {
		case actionTypes.VIEW_LOAD:
			return action.view;

		case actionTypes.VIEW_ERROR_SET:
			return {
				...state,
				formErrors: {...state.formErrors, [action.name]: action.value},
			};

		case actionTypes.VIEW_ERROR_REMOVE: {
			const newErrors = Object.assign({}, state.formErrors);
			delete newErrors[action.name];
			return {
				...state,
				formErrors: newErrors,
			};
		}

		case actionTypes.VIEW_NAME_SET:
			return {
				...state,
				name: action.name,
			};

		case actionTypes.VIEW_TABLETYPE_SET:
			return {
				...state,
				tableType: action.tableType,
			};

		case actionTypes.VIEW_ISFAVORITE_SET:
			return {
				...state,
				isFavorite: action.isFavorite,
			};

		case actionTypes.VIEW_ISDEFAULT_SET:
			return {
				...state,
				isDefault: action.isDefault,
			};

		case actionTypes.VIEW_TAGS_SET:
			return {
				...state,
				tags: action.tags,
			};
		case actionTypes.VIEW_FILTERS_SET:
			return {
				...state,
				filters: action.filters,
			};
		case actionTypes.VIEW_CREATEDATE_SET:
			return {
				...state,
				createDate: action.createDate,
			};
		case actionTypes.VIEW_CREATEDBY_SET:
			return {
				...state,
				createdBy: action.createdBy,
			};
		case actionTypes.VIEW_DATA_TRANSFER_VIEW_ID_SET:
			return {
				...state,
				dataTransferViewId: action.dataTransferViewId,
			};
		case actionTypes.VIEW_GROUPBY_SET:
			return {
				...state,
				groupBy: action.groupBy,
			};
		case actionTypes.VIEW_MAXRECORDS_SET:
			return {
				...state,
				maxRecords: action.maxRecords,
			};
		case actionTypes.VIEW_DATASETID_SET:
			return {
				...state,
				datasetId: action.datasetId,
			};
		case actionTypes.VIEW_ISGADGET_SET:
			return {
				...state,
				isGadget: action.isGadget,
			};
		case actionTypes.VIEW_STATEMENTDATE_SET:
			return {
				...state,
				statementDate: action.statementDate,
			};
		case actionTypes.VIEW_DATECONTEXT_SET:
			return {
				...state,
				dateContext: action.dateContext,
			};
		case actionTypes.VIEW_ISFIXEDDATE_SET:
			return {
				...state,
				isFixedDate: action.isFixedDate,
			};
		case actionTypes.VIEW_SNAPSHOTTYPE_SET:
			return {
				...state,
				snapshotType: action.snapshotType,
			};
		case actionTypes.VIEW_ID_SET:
			return {
				...state,
				filters: action.filters,
			};

		// breadcrumb code is duplicated in explorer reducer must keep in sync
		case actionTypes.VIEW_BREADCRUMBS_CLEAR:
			return {
				...state,
				breadcrumbs: [],
			};

		case actionTypes.VIEW_BREADCRUMBS_SET:
			return {
				...state,
				breadcrumbs: [...state.breadcrumbs, action.breadcrumb],
			};

		case actionTypes.VIEW_BREADCRUMBS_LOAD:
			return {
				...state,
				breadcrumbs: action.breadcrumbs,
			};

		case actionTypes.VIEW_BREADCRUMBS_UPDATE: {
			const idx = state.breadcrumbs.findIndex(b => b.id === action.breadcrumb.id);
			const bc = state.breadcrumbs[idx];
			bc.currValue = action.breadcrumb.value;
			return {
				...state,
				breadcrumbs: [
					...state.breadcrumbs.slice(0, idx),
					bc,
					...state.breadcrumbs.slice(idx + 1).filter(bc => bc.type === 'asset'),
				],
			};
		}

		case actionTypes.VIEW_BREADCRUMBS_DELETE: {
			const idx = state.breadcrumbs.findIndex(b => b.id === action.id);
			return {
				...state,
				breadcrumbs: [...state.breadcrumbs.slice(0, idx)],
			};
		}
		// end breadcrumb code

		case actionTypes.VIEW_BUCKET_SET:
			return {
				...state,
				bucket: action.bucket,
			};
		case actionTypes.VIEW_PAGENUMBER_SET:
			return {
				...state,
				pageNumber: action.pageNumber,
			};
		case actionTypes.VIEW_SORTCALCULATION_SET:
			return {
				...state,
				sortCalculation: action.sortCalculation,
			};
		case actionTypes.VIEW_SORTCOLUMN_SET:
			return {
				...state,
				sortColumn: action.sortColumn,
			};
		case actionTypes.VIEW_SORTORDER_SET:
			return {
				...state,
				sortOrder: action.sortOrder,
			};
		case actionTypes.VIEW_TIMESERIESCOLUMN_SET:
			return {
				...state,
				timeseriesColumn: action.timeseriesColumn,
			};
		case actionTypes.VIEW_TIMESERIESPERIOD_SET:
			return {
				...state,
				timeseriesPeriod: action.timeseriesPeriod,
			};
		case actionTypes.VIEW_TIMESERIESRANGE_SET:
			return {
				...state,
				timeseriesRange: action.timeseriesRange,
			};
		case actionTypes.VIEW_FUNDINGVEHICLEID_SET:
			return {
				...state,
				fundingVehicleId: action.fundingVehicleId,
			};
		case actionTypes.VIEW_HYPOFUNDINGVEHICLEID_SET:
			return {
				...state,
				hypoFundingVehicleId: action.hypoFundingVehicleId,
			};
		case actionTypes.VIEW_HYPOPOOLID_SET:
			return {
				...state,
				hypoPoolId: action.hypoPoolId,
			};
		case actionTypes.VIEW_POOLID_SET:
			return {
				...state,
				poolId: action.poolId,
			};
		case actionTypes.VIEW_SCENARIOID_SET:
			return {
				...state,
				scenarioId: action.scenarioId,
			};
		case actionTypes.VIEW_COLUMNS_SET:
			return {
				...state,
				columns: action.columns,
			};
		case actionTypes.VIEW_COHORTCOLUMN_SET:
			return {
				...state,
				columns: updateColumn(state.columns, 0, action.cohort),
			};
		case actionTypes.VIEW_COLUMN_SET:
			return {
				...state,
				columns: updateColumn(state.columns, action.index, action.column),
			};
		case actionTypes.VIEW_BANDSTYPE_SET:
			return {
				...state,
				bands: {...state.bands, type: action.bandType},
			};
		case actionTypes.VIEW_BANDSSTEP_SET:
			return {
				...state,
				bands: {...state.bands, step: action.step},
			};
		case actionTypes.VIEW_BANDSSTEPS_SET:
			return {
				...state,
				bands: {...state.bands, steps: action.steps},
			};
		case actionTypes.VIEW_BANDSSTEPS_ADD_ONE:
			return {
				...state,
				bands: {...state.bands, steps: addBandStep(state.bands.steps, action.step)},
			};
		case actionTypes.VIEW_BANDSSTEPS_UPDATE_ONE:
			return {
				...state,
				bands: {...state.bands, steps: updateBandStep(state.bands.steps, action.index, action.step)},
			};
		case actionTypes.VIEW_BANDSSTEPS_REMOVE_ONE:
			return {
				...state,
				bands: {...state.bands, steps: removeBandStep(state.bands.steps, action.index)},
			};
		case actionTypes.VIEW_GRANULARITY_SET: {
			// note this is only used by setting default granularity option in explorer
			return {
				...state,
				granularity: action.granularity,
				bands: {type: 'default'},
			};
		}
		case actionTypes.VIEW_DATERANGE_START_SET: {
			return {
				...state,
				dateRange: {...state.dateRange, start: action.start},
			};
		}
		case actionTypes.VIEW_DATERANGE_END_SET: {
			return {
				...state,
				dateRange: {...state.dateRange, end: action.end},
			};
		}
		case actionTypes.VIEW_STARTINCLUSIVE_SET:
			return {
				...state,
				startInclusive: action.startInclusive,
			};
		default:
			return state;
	}
};

export default View;
