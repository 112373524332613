const calcColumns = require('./calcColumns');
const _get = require('lodash/get');
// NOTE - enabling the log lines should only be used while testing logic, in production ki-website will fail
// to build if the logger is included since it has a hard disk file write operation included
// const log = require('../logger').getLogger({project: 'ki-common', class: 'utils/columnSearchUtil'});

const doesColumnMatch = (node, testFunction, isMatch = false) => {
	// log.info({node}, 'doesColumnMatch');
	// Inside a column
	if (node._id) {
		// log.info('doesColumnMatch _id');
		isMatch = testFunction(node) || isMatch;
		if (node.formula) {
			// log.info('doesColumnMatch node.formula');
			isMatch = doesColumnMatch(node.formula, testFunction) || isMatch;
		}
	}

	// Inside a node
	if (node.left) {
		// log.info('doesColumnMatch node.left');
		isMatch = doesColumnMatch(node.left, testFunction) || isMatch;
	}
	if (node.right) {
		// log.info('doesColumnMatch node.right');
		isMatch = doesColumnMatch(node.right, testFunction) || isMatch;
	}
	if (_get(node, 'extension.TrueColumn')) {
		// log.info('doesColumnMatch extension.TrueColumn');
		isMatch = doesColumnMatch(node.extension.TrueColumn, testFunction) || isMatch;
	}
	if (_get(node, 'extension.FalseColumn')) {
		// log.info('doesColumnMatch extension.FalseColumn');
		isMatch = doesColumnMatch(node.extension.FalseColumn, testFunction) || isMatch;
	}

	if (node.type === 'business-variables') {
		// log.info('doesColumnMatch node.type business-variables');
		Object.keys(node.value).forEach(key => {
			const value = node.value[key];
			isMatch = doesColumnMatch(value, testFunction) || isMatch;
		});
	}

	if (node.type === 'column') {
		// log.info('doesColumnMatch node.type column');
		if (Array.isArray(node.value)) {
			// log.info('doesColumnMatch node.value array');
			return node.value.some(element => {
				isMatch = doesColumnMatch(element, testFunction) || isMatch;
			});
		} else {
			// log.info('doesColumnMatch node.value');
			isMatch = doesColumnMatch(node.value, testFunction) || isMatch;
		}
	}

	return isMatch;
};

const nestedColumnSearch = (allColumns, testColumn, testFunction) => {
	if (testColumn.formula) {
		testColumn.formula = calcColumns.embedAssetColumnsForFormula(allColumns, testColumn.formula);
	}

	return doesColumnMatch(testColumn, testFunction);
};

const columnReferencesSelf = (allColumns, testColumn, columnId) => {
	// this will work on numeric asset columns, but not cumulative asset cols or business function cols
	if (Array.isArray(testColumn.formula)) {
		const matches = testColumn.formula.filter(c => c.type === 'column' && c.value === columnId);
		return matches.length > 0;
	}
	return testColumn._id === columnId;
};

const containsBusinessFunctionColumn = (allColumns, testColumn) => {
	if (testColumn.formula) {
		testColumn.formula = calcColumns.embedAssetColumnsForFormula(allColumns, testColumn.formula);
	}

	const testFunction = node => {
		const op = _get(node, 'formula.op', '');
		return op.includes('bf_');
	};

	return doesColumnMatch(testColumn, testFunction);
};

module.exports = {
	doesColumnMatch,
	nestedColumnSearch,
	columnReferencesSelf,
	containsBusinessFunctionColumn,
};
