import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';
import analytics from 'analytics';

export const fetchAccounts = () => {
	return fetch(`${apiUrl}/accounts`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const createAccount = account => {
	analytics.sendEvent('accounts', 'create', `userId:${account.userId}`);
	return fetch(`${apiUrl}/accounts`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(account),
	}).then(_handleResponse);
};

export const updateAccount = account => {
	analytics.sendEvent('accounts', 'update', `userId:${account.userId}`);
	return fetch(`${apiUrl}/accounts/${account._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(account),
	}).then(_handleResponse);
};

export const changePassword = (_id, curPassword, newPassword) => {
	analytics.sendEvent('accounts', 'changePassword', `userId:${_id}`);
	return fetch(`${apiUrl}/accounts/password/${_id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify({curPassword, newPassword}),
	}).then(_handleResponse);
};

export const removeAccount = userId => {
	analytics.sendEvent('accounts', 'remove', `user:Id:${userId}`);
	return fetch(`${apiUrl}/accounts/${userId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const removeAccounts = userIds => {
	analytics.sendEvent('accounts', 'remove', `userIds:${userIds.toString()}`);
	return fetch(`${apiUrl}/accounts/`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
		body: JSON.stringify({ids: userIds}),
	}).then(_handleResponse);
};

export const deactivateAccounts = userIds => {
	analytics.sendEvent('accounts', 'deactivate', `userIds:${userIds.toString()}`);
	return fetch(`${apiUrl}/accounts/deactivate`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify({ids: userIds}),
	}).then(_handleResponse);
};

export const activateAccounts = userIds => {
	analytics.sendEvent('accounts', 'activate', `userIds:${userIds.toString()}`);
	return fetch(`${apiUrl}/accounts/activate`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify({ids: userIds}),
	}).then(_handleResponse);
};

export const generateApiKey = userId => {
	analytics.sendEvent('accounts', 'generateApiKey', userId);
	return fetch(`${apiUrl}/accounts/${userId}/generateApiKey`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchAccounts,
	createAccount,
	updateAccount,
	changePassword,
	removeAccount,
	removeAccounts,
	deactivateAccounts,
	activateAccounts,
	generateApiKey,
};
