/*
 Constraint Type: Concentration
 Name: Is the display name of the Constraint entered by the user. This must be unique
 Data Column: Is selected from a list of available columns in the dataset and reflects the column of data that will be constrained
 Logic: can be one of the following mathematical operator used for the constraint =,<>,<,>,<=,>=,Between, in, not in, is null, is not null
 Target Min: Numeric minimum required
 Target Max: Numeric Max required
 Selection: Allows the user to identify the value to evaluate as a percentage of the pool. List includes, Max, Top 2 Bucket...Top 5 Bucket, Least, Top 5 combined, Top 10 combined, Range
 Flex: Default value the target is allowed to expand as a % below the min or above the max
 Constraint: By default this is set to checked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
 Covenant: By default this is set to unchecked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
 */

const _defaultsDeep = require('lodash/defaultsDeep');
const ccBase = require('./ccBase');

const baseContraints = ccBase.getConstraints();
const getConstraints = () => {
	return _defaultsDeep(
		{
			rowType: {
				presence: {allowEmpty: false},
				format: {
					pattern: '^ccConcentration',
					flag: 'i',
					message: 'rowType must be ccConcentration',
				},
			},
			selection: {
				presence: {allowEmpty: false},
			},
			concentrationValue: (value, options) => {
				if (options && options.selection?.value === 'top_nth_bucket') {
					return {
						presence: {
							allowEmpty: false,
						},
						numericality: {
							greaterThan: 0,
							message: 'should be a number',
						},
					};
				}
				return {};
			},
			concentrationMin: (value, options) => {
				if (options && options.selection?.value === 'top_n_combined') {
					return {
						presence: {
							allowEmpty: false,
						},
						numericality: {
							greaterThan: 0,
							message: 'should be a number greater than 0',
						},
					};
				}
				return {};
			},
			concentrationMax: (value, options) => {
				if (options && options.selection?.value === 'top_n_combined') {
					return {
						presence: {
							allowEmpty: false,
						},
						numericality: {
							greaterThan: parseInt(options.concentrationMin),
							message: `should be a number greater than the min`,
						},
					};
				}
				return {};
			},
			concentrationIncludes: (value, options) => {
				if (options && options.selection?.value === 'include') {
					return {
						presence: {allowEmpty: false},
						concentrationArray: true,
					};
				}
				return {};
			},
			concentrationExcludes: (value, options) => {
				if (
					options &&
					(options.selection?.value === 'top_nth_bucket' || options.selection?.value === 'top_n_combined')
				) {
					return {
						concentrationArray: true,
					};
				}
				return {};
			},
			logic: {
				presence: {allowEmpty: false},
			},

			target: {
				presence: {allowEmpty: false},
				targetArray: false,
				targetArrayConcentration: true,
			},
			weightedBy: {
				presence: {allowEmpty: false},
			},
		},
		baseContraints
	);
};

module.exports = {
	getConstraints,
};
