import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import {KiCreatable} from 'components/KiSelect';
import options from 'ki-common/options';
import KiButton from 'components/KiButton';
import _get from 'lodash/get';
import * as columnServiceApi from '../../../api/columnServiceApi';

export class ParentDataForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		columnList: PropTypes.array.isRequired,
		closeForm: PropTypes.func.isRequired,
		isAdmin: PropTypes.bool,
		columnToEdit: PropTypes.object,
		existingTags: PropTypes.array,
		datasetId: PropTypes.string,
	};

	static defaultProps = {
		existingTags: [],
	};

	state = {
		name: '',
		tags: [],
		nameError: null,
		numericColumns: [],
		parentColumnOptions: [],
		// aggregation items
		parentColumn: null,
		aggregationType: 'SUM',
		aggregateColumnOption: null,
		calculateColumn: null,
		isGlobal: this.props.columnToEdit && this.props.columnToEdit.createdBy === '' ? true : false,
	};

	componentDidMount() {
		const {columnToEdit} = this.props;
		this.setState(
			{
				numericColumns: this.props.columnList.filter(
					c =>
						c.dataType === 'numeric' &&
						c.columnType === 'asset' &&
						c._id !== _get(columnToEdit, '_id') &&
						_get(c, 'formula.op') !== 'parentData'
				),
				parentColumnOptions: this.props.columnList.filter(
					c =>
						c.columnType === 'asset' &&
						c._id !== _get(columnToEdit, '_id') &&
						_get(c, 'formula.op') !== 'parentData'
				),
				isGlobal: columnToEdit && !columnToEdit.createdBy ? true : false,
			},
			() => {
				if (columnToEdit) {
					this.setState({
						aggregationType: _get(columnToEdit, 'formula.left.value.aggregationType'),
						aggregateColumnOption: _get(columnToEdit, 'formula.left.value.assetColumn.value'),
						calculateColumn: _get(columnToEdit, 'formula.left.value.calculateColumn.value'),
						name: columnToEdit.displayName,
						parentColumn: _get(columnToEdit, 'formula.left.value.parentColumn.value'),
					});
				}
			}
		);
	}

	handleAggregationTypeChange = option => {
		this.setState({
			aggregationType: option.value,
			calculateColumn: option.value === 'WGHTAVG' ? this.state.numericColumns[0] : null,
		});
	};

	setName = val => {
		this.setState({
			name: val,
			nameError: val.length ? null : "Name can't be blank",
		});
	};

	isDuplicateNameError = () => {
		// Should be able to keep the same name when editing a column
		if (_get(this, 'props.columnToEdit.displayName') === this.state.name) {
			return;
		}

		const assetCols = this.props.columnList.filter(
			col => (!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset'
		);

		// Check if the name already exists in assetCols
		return assetCols.find(col => _get(col, 'displayName') === this.state.name);
	};

	getSaveAndFormatDisabled = () => {
		const {aggregationType, calculateColumn, aggregateColumnOption, parentColumn, name} = this.state;

		if (!name || !parentColumn || !aggregateColumnOption) {
			return true;
		}

		if (aggregationType === 'WGHTAVG' && !calculateColumn) {
			return true;
		}

		return false;
	};

	onSubmit = () => {
		const {columnToEdit, datasetId} = this.props;
		const {
			name,
			aggregationType,
			aggregateColumnOption,
			calculateColumn,
			tags,
			isGlobal,
			parentColumn,
		} = this.state;
		this.setState({isSaving: true});
		if (!this.state.name) {
			this.setState({nameError: "Name can't be blank", isSaving: false});
			return;
		}

		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		}

		if (!this.isDuplicateNameError()) {
			this.setState({nameError: ''});
		}

		const displayFormat = 'number';

		const column = {
			_id: columnToEdit ? columnToEdit._id : null,
			datasetId: datasetId,
			columnName: name,
			displayName: name,
			displayFormat,
			dataType: 'numeric',
			columnType: 'asset',
			aggregationType,
			tags,
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			isGlobal,
			parentColumn,
		};

		if (!column.formula) {
			column.assetColumn = this.props.columnList.find(c => c._id === aggregateColumnOption._id);
		}

		if (!column.formula && aggregationType === 'WGHTAVG') {
			column.calculateColumn = calculateColumn;
		}

		return this.props.submitMethod(column);
	};

	createFormulaPreview = () => {
		const aggregateColumnOptionName = _get(this.state.aggregateColumnOption, 'displayName', '....');
		const calculateColumnName = _get(this.state.calculateColumn, 'displayName', '....');
		const parentColumnName = _get(this.state.parentColumn, 'displayName', '....');
		const aggregationType = this.state.aggregationType;

		if (aggregationType === 'WGHTAVG') {
			return `Weighted average (${aggregateColumnOptionName} by ${calculateColumnName}) by ${parentColumnName}`;
		}

		if (aggregationType === 'MAX' || aggregationType === 'MIN') {
			return `${aggregationType} (${aggregateColumnOptionName}) by ${parentColumnName}`;
		}

		return `${aggregationType} (${aggregateColumnOptionName}) by ${parentColumnName}`;
	};

	setParentColumn = columnId => {
		columnServiceApi.getColumnByIdFromService(this.props.datasetId, columnId, true).then(opt => {
			delete opt.detailedDisplayName;
			delete opt.htmlDisplayName;
			this.setState({parentColumn: opt});
		});
	};

	setAggregateColumnOption = columnId => {
		columnServiceApi.getColumnByIdFromService(this.props.datasetId, columnId, true).then(opt => {
			delete opt.detailedDisplayName;
			delete opt.htmlDisplayName;
			this.setState({aggregateColumnOption: opt});
		});
	};

	setCalculateColumn = columnId => {
		columnServiceApi.getColumnByIdFromService(this.props.datasetId, columnId, true).then(opt => {
			delete opt.detailedDisplayName;
			delete opt.htmlDisplayName;
			this.setState({calculateColumn: opt});
		});
	};
	render() {
		const showPreview = () => {
			if (this.state.aggregationType === 'WGHTAVG' && this.state.calculateColumn) {
				return true;
			}

			// parentColumn and aggregateColumnOption are required for all columns
			return this.state.parentColumn && this.state.aggregateColumnOption;
		};

		return (
			<section className="column-selector-form inline-column-form">
				<div>
					<KiInput
						label="Name"
						value={this.state.name}
						onChange={val => this.setName(val)}
						error={this.state.nameError}
					/>
					<section className="calculation-form-section">
						<span className="form-instruction">Tags:</span>
						<KiCreatable
							classNamePrefix="aut-select"
							isMulti={true}
							options={this.props.existingTags.map(t => ({
								value: t,
								label: t,
							}))}
							value={this.state.tags.map(t => ({value: t, label: t}))}
							onChange={val => this.setState({tags: val.map(t => t.value)})}
							placeholder="Add some tags"
							noResultsText={false}
						/>
					</section>

					<section className="calculation-form-section">
						<span className="form-instruction">Select Parent Column</span>
						<Select
							classNamePrefix="aut-select"
							value={this.state.parentColumn}
							isClearable={false}
							options={this.state.parentColumnOptions}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							onChange={opt => this.setParentColumn(opt._id)}
						/>
					</section>

					<section className="calculation-form-section">
						<span className="form-instruction">Select a Column to Aggregate</span>
						<Select
							classNamePrefix="aut-select"
							value={this.state.aggregateColumnOption}
							isClearable={false}
							options={this.state.numericColumns}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							onChange={opt => this.setAggregateColumnOption(opt._id)}
						/>
					</section>

					{this.state.parentColumn && (
						<section className="calculation-form-section">
							<span className="form-instruction">Select Aggregation Type</span>
							<Select
								classNamePrefix="aut-select"
								value={options.cumulativeAggregationTypes.find(
									opt => opt.value === this.state.aggregationType
								)}
								isClearable={false}
								options={options.cumulativeAggregationTypes}
								onChange={this.handleAggregationTypeChange}
							/>
						</section>
					)}

					{this.state.aggregationType === 'WGHTAVG' && (
						<section className="calculation-form-section">
							<span className="form-instruction">Select a Column to Weigh by</span>
							<Select
								classNamePrefix="aut-select"
								value={this.state.calculateColumn}
								isClearable={false}
								options={this.state.numericColumns}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								onChange={opt => this.setCalculateColumn(opt._id)}
							/>
						</section>
					)}

					{/* Temporarily commented out, should only be used for non-numerics */}
					{/* {['MIN', 'MAX'].includes(this.state.aggregationType) && this.state.displayFormat !== 'numeric' (
						<section className="calculation-form-section">
							<span className="form-instruction">Select a Column to Aggregate by</span>
							<Select
								classNamePrefix="aut-select"
								value={this.state.calculateColumn}
								isClearable={false}
								options={this.state.numericColumns}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								onChange={opt => this.setState({calculateColumn: opt})}
							/>
						</section>
					)} */}

					{showPreview() && (
						<section className="calculation-form-section">
							<table className="formula-preview">
								<tr>
									<th>LOGIC</th>
								</tr>
								<tr>
									<td>{this.createFormulaPreview()}</td>
								</tr>
							</table>
						</section>
					)}
					{this.props.isAdmin &&
						!_get(this, 'props.columnToEdit._id', null) && (
							<div className="calculation-form-section">
								<KiCheckbox
									name="isGlobal"
									checked={this.state.isGlobal}
									label="Make Global"
									onChange={val => this.setState({isGlobal: val})}
								/>
							</div>
						)}
				</div>
				<section className="format-and-save">
					<div className="inline-column-form-buttons">
						<KiButton flat primary onClick={() => this.props.closeForm()}>
							Cancel
						</KiButton>
						<KiButton
							disabled={this.getSaveAndFormatDisabled() || this.state.isSaving}
							raised
							primary
							onClick={() => this.onSubmit()}
						>
							Save
						</KiButton>
					</div>
				</section>
			</section>
		);
	}
}

export default ParentDataForm;
