// Globals
import React, {useState} from 'react';
import PropTypes from 'prop-types';

// Project imports

// Local imports
import styles from './KiFormulaBuilderComponent.theme.scss';

function ParensComponent({value, onChange}) {
	const [isActive, setIsActive] = useState(false);

	const canOpen = !value || value.indexOf('(') > -1;
	const canClose = !value || value.indexOf(')') > -1;

	const addParen = (event, paren) => {
		event.stopPropagation();
		onChange(`${value}${paren}`);
		setIsActive(false);
	};

	const close = event => {
		event.stopPropagation();
		setIsActive(false);
		onChange('');
	};

	return (
		<div className={styles.parenComponent} onClick={() => setIsActive(true)}>
			{!isActive && <div className={styles.parenComponentBlank}>{value || '...'}</div>}
			{isActive && (
				<div className={styles.parenComponentBlank}>
					{canOpen && (
						<button key="open-paren" onClick={e => addParen(e, '(')}>
							(
						</button>
					)}
					{canClose && (
						<button key="close-paren" onClick={e => addParen(e, ')')}>
							)
						</button>
					)}
					<button key="clear" onClick={e => close(e)}>
						CLR
					</button>
				</div>
			)}
		</div>
	);
}

ParensComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

ParensComponent.defaultProps = {
	value: '',
	onChange: () => {
		return false;
	},
};

export default ParensComponent;
