import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import bfData from 'ki-common/businessFunctions';
import KiAppBar from 'components/KiAppBar';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import FunctionMappingFilters from './components/FunctionMappingFilters';
import FunctionMappingList from './components/FunctionMappingList';
import {fetchDatasetList, fetchDataset} from 'containers/datasetList/actions';
import {
	fetchBusinessFunctionColumnsByDatasetId,
	fetchDefaultMappingsByDatasetId,
	clearBusinessFunctionColumnsByDatasetId,
} from './actions';
import DefaultMappings from './components/DefaultMappings';
import {datasetsApi, columnServiceApi} from 'api';

export class BusinessFunctions extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		user: PropTypes.object,
		fetchDatasetList: PropTypes.func.isRequired,
		fetchDataset: PropTypes.func.isRequired,
		fetchBusinessFunctionColumnsByDatasetId: PropTypes.func.isRequired,
		fetchDefaultMappingsByDatasetId: PropTypes.func.isRequired,
		clearBusinessFunctionColumnsByDatasetId: PropTypes.func.isRequired,
		datasets: PropTypes.array.isRequired,
		dataset: PropTypes.object,
		bfColumns: PropTypes.array.isRequired,
		bfDefaultMappings: PropTypes.array.isRequired,
	};

	static defaultProps = {
		datasets: [],
		dataset: {},
		bfColumns: [],
	};

	state = {
		selectedFunction: null,
		tabIndex: 0,
		assetColumns: [],
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Business Function Library`;
		return Promise.all([this.props.fetchDatasetList(), this.props.clearBusinessFunctionColumnsByDatasetId()]);
	}

	setDataset = dataset => {
		columnServiceApi
			.getColumnsFromService(dataset.datasetId, {
				sources: {
					includeAssetColumns: true,
					includeAssetCalculations: true,
				},
			})
			.then(cols => this.setState({assetColumns: cols}));
		return Promise.all([
			this.props.fetchDataset(dataset.datasetId),
			this.props.fetchBusinessFunctionColumnsByDatasetId(dataset.datasetId),
			this.props.fetchDefaultMappingsByDatasetId(dataset.datasetId),
		]);
	};

	setFunction = col => {
		this.setState({selectedFunction: col});
	};

	setVariable = variable => {
		this.setState({selectedVariable: variable});
	};

	saveColumn = col => {
		col.modifiedDate = new Date().toISOString();
		return datasetsApi.upsertColumns([col], this.props.dataset.datasetId).then(() => {
			this.props.fetchBusinessFunctionColumnsByDatasetId(this.props.dataset.datasetId);
		});
	};

	deleteColumn = _id => {
		return datasetsApi.deleteColumn(_id, this.props.dataset.datasetId).then(() => {
			this.props.fetchBusinessFunctionColumnsByDatasetId(this.props.dataset.datasetId);
		});
	};

	onVariableClick = variableName => {
		const variable = bfData.businessVariablesMap.get(variableName);
		if (variable) {
			this.setState({
				selectedVariable: variable,
				tabIndex: 1,
			});
		}
	};

	onTabClick = index => {
		this.setState({tabIndex: index});
	};

	render() {
		const {user, datasets, dataset, bfColumns, bfDefaultMappings} = this.props;
		const {selectedFunction, selectedVariable, tabIndex, assetColumns} = this.state;
		const isAdmin = user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		return (
			<div className="container-wrapper">
				<div className="container-body">
					<header>
						<KiAppBar className="top-bar">
							<div className="top-bar-breadcrumb">
								<h1>Business Function Library</h1>
							</div>
						</KiAppBar>
					</header>
					<div className="ki-panel">
						<FunctionMappingFilters
							datasets={datasets}
							setDataset={this.setDataset}
							setFunction={this.setFunction}
							setVariable={this.setVariable}
						/>
						<KiTabs onChange={this.onTabClick} index={tabIndex}>
							<KiTab title="Business Functions">
								<FunctionMappingList
									isAdmin={isAdmin}
									user={user}
									dataset={dataset}
									bfColumns={bfColumns}
									assetColumns={assetColumns}
									saveColumn={this.saveColumn}
									deleteColumn={this.deleteColumn}
									bfDefaultMappings={bfDefaultMappings}
									selectedFunction={selectedFunction}
									selectedVariable={selectedVariable}
									onVariableClick={this.onVariableClick}
								/>
							</KiTab>
							{isAdmin && (
								<KiTab title="Default Mappings">
									<DefaultMappings
										selectedFunction={selectedFunction}
										selectedVariable={selectedVariable}
									/>
								</KiTab>
							)}
						</KiTabs>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	user: state.user,
	datasets: state.datasetList.data,
	dataset: state.datasetList.selected,
	bfColumns: state.businessFunctions.columns,
	bfDefaultMappings: state.businessFunctions.defaultMappings,
});

const mapDispatchToProps = () => ({
	fetchDatasetList,
	fetchDataset,
	fetchBusinessFunctionColumnsByDatasetId,
	fetchDefaultMappingsByDatasetId,
	clearBusinessFunctionColumnsByDatasetId,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(BusinessFunctions);
