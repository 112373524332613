const assert = require('assert');

const promiseExpectedError = data => {
	assert.strictEqual(data, Symbol('Promise Rejection'), 'Expected error was not thrown');
};

const badObjectIDString = '000000000000000000000000';

const mongoObjectIDStringRegex = '^[a-f\\d]{24}$';
const ISO8601_DATE_PATTERN = /(\d{4}\-\d{2}\-\d{2}T\d{2}\:\d{2}\:\d{2}\.\d{3}Z)/; // eslint-disable-line
const GUID_PATTERN = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;

const longDateRegexStr = '^\\d{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])$';
const longDateRe = new RegExp(longDateRegexStr);

module.exports = {
	promiseExpectedError,
	mongoObjectIDStringRegex,
	badObjectIDString,
	ISO8601_DATE_PATTERN,
	GUID_PATTERN,
	longDateRegexStr,
	longDateRe,
};
