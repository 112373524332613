import React from 'react';
import PropTypes from 'prop-types';
import KiCheckbox from '../../../../components/KiCheckbox';
import {formatDateWithHours} from '../../../../utils/dateUtils';
import KiProgressBar from '../../../../components/KiProgressBar';
import Loader from '../../Loader';

function LedgerGeneratePostsTableItem({
	item: {
		code,
		description,
		effectiveDateCalendar,
		lastGeneration: {timestamp, postingDate, effectiveDate: lastEffectiveDate, status},
		calculatedEffectiveDate,
		checked,
	},
	index,
	toggleActive,
	loading,
}) {
	const INVALID_STATUSES = ['INVALID', 'ERROR'];

	const loaderContent = () => (
		<td colSpan={2} className="border-right no-padding">
			<KiProgressBar />
		</td>
	);

	return (
		<tr className="scenario-list-item" key={code}>
			<td className="select-item">
				<KiCheckbox
					className="select-item-checkbox no-margin"
					checked={checked}
					onChange={() => toggleActive(index)}
				/>
			</td>
			<td title={code}>{code}</td>
			<td title={description}>{description}</td>
			<Loader isActive={loading} loaderContent={loaderContent}>
				<td title={effectiveDateCalendar}>{effectiveDateCalendar}</td>
				<td title={calculatedEffectiveDate} className="border-right">
					{calculatedEffectiveDate}
				</td>
			</Loader>
			<td title={formatDateWithHours(timestamp)}>{formatDateWithHours(timestamp)}</td>
			<td title={postingDate}>{postingDate}</td>
			<td title={lastEffectiveDate}>{lastEffectiveDate}</td>
			<td title={status.name} className={INVALID_STATUSES.includes(status._id) ? 'color-red' : ''}>
				{status.name ? status.name : ''}
			</td>
		</tr>
	);
}

LedgerGeneratePostsTableItem.propTypes = {
	item: PropTypes.object,
	toggleActive: PropTypes.func,
	index: PropTypes.number.isRequired,
	loading: PropTypes.bool,
};

LedgerGeneratePostsTableItem.defaultProps = {
	item: {},
	loading: true,
};

export default LedgerGeneratePostsTableItem;
