import {submissionsApi, categorySchemasApi} from 'api';
import * as actionTypes from './actionTypes';
import socketManager from '../../socket';

socketManager.on('submission', submissionId => (dispatch, getState) => {
	if (getState().submission._id && getState().submission._id === submissionId) {
		dispatch(fetchSubmission(submissionId)); //eslint-disable-line no-use-before-define
	}
});

export const fetchSubmission = id => dispatch => {
	return submissionsApi.fetchSubmission(id).then(submission => {
		dispatch({type: actionTypes.SUBMISSION_FETCH, payload: submission});
		return submission;
	});
};

export const resetSubmission = () => ({type: actionTypes.SUBMISSION_RESET});

export const fetchCategorySchemas = () => dispatch => {
	return categorySchemasApi.fetchCategorySchemaList().then(categorySchemas => {
		dispatch({type: actionTypes.CATEGORY_SCHEMAS_FETCHED, payload: categorySchemas});
		return categorySchemas;
	});
};

export const updateColumn = column => (dispatch, getState) => {
	const {
		submission: {
			categorySchemaId,
			categorySchema: {columns = []},
		},
	} = getState();

	if (!categorySchemaId) {
		return Promise.reject(new Error('no category schema id set for this submission'));
	}

	return categorySchemasApi.updateColumn(categorySchemaId, column).then(() => {
		dispatch({
			type: actionTypes.SUBMISSION_UPDATE_SCHEMA_COLUMN,
			payload: {idx: columns.findIndex(c => c.id === column.id), column: column},
		});
		return column;
	});
};

export const validateSchemaColumn = column => (dispatch, getState) => {
	const {
		submission: {
			_id: submissionId,
			categorySchema: {
				columns = [], //eslint-disable-line
			},
		},
	} = getState();

	if (!submissionId) {
		return Promise.reject(new Error('No schema found in current redux state'));
	}

	return categorySchemasApi.validateSchema(submissionId, [column]).then(validatedColumns => {
		if (validatedColumns && validatedColumns.length) {
			dispatch({
				type: actionTypes.SUBMISSION_VALIDATE_SCHEMA_COLUMN,
				payload: {idx: columns.findIndex(c => c.id === column.id), column: validatedColumns[0]},
			});
			return validatedColumns[0];
		}
	});
};

export const validateSchema = (columns = []) => (dispatch, getState) => {
	const {submission} = getState();

	if (!submission._id) {
		return Promise.reject(new Error('No schema found in current redux state'));
	}

	return categorySchemasApi.validateSchema(submission._id, columns).then(validatedColumns => {
		// This should update the existing columns
		// dispatch({type: actionTypes.SUBMISSION_SET_SCHEMA_COLUMNS, payload: validatedColumns});
		return validatedColumns;
	});
};

export const fetchCategorySchema = () => (dispatch, getState) => {
	const {submission} = getState();

	if (!submission._id) {
		return Promise.reject(new Error('No schema found in current redux state'));
	}
	return categorySchemasApi.validateSchema(submission._id, []).then(validatedColumns => {
		dispatch({type: actionTypes.SUBMISSION_SET_SCHEMA_COLUMNS, payload: validatedColumns});
		return validatedColumns;
	});
};

export const updateSubmissionAndSchema = (submissionId, categorySchemaName, snapshotDate, delimiter) => dispatch => {
	return submissionsApi
		.updateSubmissionAndCategorySchema(submissionId, categorySchemaName, snapshotDate, delimiter)
		.then(updatedSubmission => {
			dispatch(resetSubmission());
			dispatch({type: actionTypes.SUBMISSION_FETCH, payload: updatedSubmission});
			dispatch(fetchCategorySchema());
		});
};

/* eslint-enable arrow-body-style */

export default {
	fetchSubmission,
	resetSubmission,
	fetchCategorySchemas,
	validateSchema,
	validateSchemaColumn,
	updateSubmissionAndSchema,
};
