import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiButton from 'components/KiButton';
import ColConstantFuncPicker from './ColConstFuncPicker';

class TextFormConcatFields extends Component {
	static propTypes = {
		assetColumns: PropTypes.array.isRequired,
		onColumnsSelect: PropTypes.func.isRequired,
		selectedColumns: PropTypes.array.isRequired,
		reloadView: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.state = {
			selectedType: null,
			selectedValue: null,
			expressionValues: this.props.selectedColumns,
			editIndex: null,
		};
	}

	addConcatenateValue = () => {
		const {selectedType, selectedValue, editIndex} = this.state;

		const expressionValues = [...this.state.expressionValues];
		if (editIndex !== null) {
			expressionValues.splice(editIndex, 1, {
				type: selectedType,
				value: selectedValue,
			});
		} else {
			expressionValues.push({
				type: selectedType,
				value: selectedValue,
			});
		}
		this.setState({
			expressionValues: expressionValues,
			selectedType: null,
			selectedValue: null,
			editIndex: null,
		});
		this.props.onColumnsSelect(expressionValues);
	};

	onMoveUpClick = idx => {
		if (idx !== -1 && idx !== 0) {
			const expressionValues = this.state.expressionValues.slice();
			expressionValues.splice(idx - 1, 0, expressionValues[idx]);
			expressionValues.splice(idx + 1, 1);
			this.setState({
				expressionValues: expressionValues,
			});
			this.props.onColumnsSelect(expressionValues);
		}
	};

	onMoveDownClick = idx => {
		if (idx !== -1 && idx !== this.state.expressionValues.length + 1) {
			const expressionValues = this.state.expressionValues.slice();
			expressionValues.splice(idx + 2, 0, expressionValues[idx]);
			expressionValues.splice(idx, 1);
			this.setState({
				expressionValues: expressionValues,
			});
			this.props.onColumnsSelect(expressionValues);
		}
	};

	onEditClick = idx => {
		const {expressionValues} = this.state;
		this.setState({
			editIndex: idx,
			selectedType: expressionValues[idx].type,
			selectedValue: expressionValues[idx].value,
		});
	};

	onDeleteClick = idx => {
		const expressionValues = [...this.state.expressionValues];
		expressionValues.splice(idx, 1);
		this.setState({expressionValues: expressionValues});
		this.props.onColumnsSelect(expressionValues);
	};

	render() {
		const {assetColumns} = this.props;
		const {selectedType, selectedValue, expressionValues, editIndex} = this.state;

		return (
			<div>
				<div className="calculation-form-section">
					<span className="form-instruction">Column/Constant:</span>
					<div className="concat-value-picker form-instruction-box">
						<ColConstantFuncPicker
							onChange={(type, value) =>
								this.setState({
									selectedType: type,
									selectedValue: value,
								})
							}
							columns={assetColumns}
							type={selectedType}
							value={selectedValue}
							log="TextFormConcatFields"
							reloadView={this.props.reloadView}
						/>
						<KiButton className="add-btn" onClick={this.addConcatenateValue} disabled={!selectedValue}>
							<i className="material-icons">{editIndex !== null ? 'save' : 'add'}</i>
						</KiButton>
					</div>

					<section className="expression-table">
						<header className="form-instructions">Expression</header>
						{expressionValues.map((exp, idx) => (
							<div key={idx} className="expression-item">
								<p>{exp.type.value === 'column' ? exp.value.displayName : exp.value}</p>
								<div className="row-actions">
									<i
										title="Move Up"
										className="material-icons"
										onClick={() => this.onMoveUpClick(idx)}
									>
										keyboard_arrow_up
									</i>
									<i
										title="Move Down"
										className="material-icons"
										onClick={() => this.onMoveDownClick(idx)}
									>
										keyboard_arrow_down
									</i>
									<i title="Edit" className="material-icons" onClick={() => this.onEditClick(idx)}>
										mode_edit
									</i>
									<i
										title="Delete"
										className="material-icons"
										onClick={() => this.onDeleteClick(idx)}
									>
										delete
									</i>
								</div>
							</div>
						))}
					</section>
				</div>
			</div>
		);
	}
}

export default TextFormConcatFields;
