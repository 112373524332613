// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import moment from 'moment';
import {Portal} from 'react-overlays';

// Relative
import styles from './KiDatePicker.theme.scss';
import {DATEPICKER_FORMAT_YMD} from '../../utils/dateUtils';

const cx = classNames.bind(styles);

// allows the calendar to escape overflowing containers
const CalendarContainer = ({children}) => {
	const el = document.getElementById('calendar-portal');

	return <Portal container={el}>{children}</Portal>;
};
CalendarContainer.propTypes = {children: PropTypes.node};

export class KiDatePicker extends Component {
	static propTypes = {
		className: PropTypes.string,
		dateFormat: PropTypes.string,
		placeholderText: PropTypes.string,
		disabled: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		isModal: PropTypes.bool,
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		labelClassName: PropTypes.string,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		// JS Date, Moment Object, or Date String
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		// Should only be a JS Date
		minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		// to exclude specific dates
		excludeDates: PropTypes.array,
		includeDates: PropTypes.array,
	};

	static defaultProps = {
		className: '',
		dateFormat: DATEPICKER_FORMAT_YMD,
		placeholderText: 'YYYY-MM-DD',
		disabled: false,
		isModal: false,
		value: null,
		minDate: null,
		maxDate: null,
		excludeDates: [],
	};

	constructor(props) {
		super(props);

		this.datepickerRef = React.createRef();
	}

	handleChange = date => {
		const {onChange} = this.props;
		if (onChange) onChange(this.getParsedDate(date));
	};

	handleBlur = rawValue => {
		const {onBlur} = this.props;
		if (onBlur) onBlur(this.getParsedDate(_get(rawValue.target, 'value')));
	};

	handleKeyDown = event => {
		// Close on Tab
		if (event.keyCode === 9) {
			this.datepickerRef.current.setOpen(false);
		}
	};

	clickOutside = () => {
		this.datepickerRef.current.cancelFocusInput();
		this.datepickerRef.current.setOpen(false);
	};

	// JS Date, Moment Object, or Date String
	getParsedDate = date => {
		if (date instanceof Date) {
			return date;
		} else if (moment.isMoment(date)) {
			return date.toDate();
		} else if (moment(date, 'YYYY-MM-DD', true).isValid()) {
			return moment(date).toDate();
		} else if (date) {
			const jDate = new Date(date);
			if (jDate instanceof Date && !isNaN(jDate)) {
				return jDate;
			}
		}
		return null;
	};

	render() {
		const {
			className,
			dateFormat,
			placeholderText,
			error,
			isModal,
			label,
			labelClassName,
			onBlur, // eslint-disable-line
			onChange, // eslint-disable-line
			value, // eslint-disable-line
			minDate,
			maxDate,
			excludeDates,
			includeDates,
			...others
		} = this.props;

		const datePickerProps = {
			dateFormat,
			placeholderText,
			onChange: this.handleChange,
			onBlur: this.handleBlur,
			onClickOutside: this.clickOutside,
			onKeyDown: this.handleKeyDown,
			className: cx('datepicker-input'),
			selected: this.getParsedDate(value),
			withPortal: isModal,
			fixedHeight: true,
			...(Array.isArray(excludeDates) && {excludeDates: excludeDates.map(this.getParsedDate)}),
			...(Array.isArray(includeDates) &&
				includeDates.length && {includeDates: includeDates.map(this.getParsedDate)}),
			...others,
		};

		if (minDate) {
			datePickerProps.minDate = this.getParsedDate(minDate);
		}

		if (maxDate) {
			datePickerProps.maxDate = this.getParsedDate(maxDate);
		}

		return (
			<div className={cx('container', className, {hasError: !!error})}>
				{label ? <label className={cx('label', labelClassName)}>{label}</label> : null}
				<DatePicker popperContainer={CalendarContainer} {...datePickerProps} ref={this.datepickerRef} />
				{error ? <span className={cx('error')}>{error}</span> : null}
			</div>
		);
	}
}
export default KiDatePicker;
