import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {QuickFilter} from './DebtFilters';
import KiSelect from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';
import KiDatePicker from 'components/KiDatePicker';
import {withRouter} from 'react-router';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchDates} from 'components/FlyoutDates/actions';
import {fetchCalendars} from 'containers/calendars/actions';
import {
	setIsCustomDateRange,
	setRangeDate,
	setRangeOccurrences,
	setEndDate,
	setDate,
	setCustomStartDate,
	setCustomEndDate,
} from '../actions';
import styles from './DebtFilters.theme.scss';

export const RANGE_OCCURRENCE_OPTIONS = [
	{value: 1, label: 'Last Period'},
	{value: 3, label: 'Last 3 Periods'},
	{value: 6, label: 'Last 6 Periods'},
	{value: 9, label: 'Last 9 Periods'},
	{value: 12, label: 'Last 12 Periods'},
	{value: -1, label: 'All Periods'},
];

export const dateFilter = (fundingVehicleId, dateType = 'range', date) => {
	if (!fundingVehicleId || !dateType) return false;
	const matchingEntry =
		date.entries.find(entry => entry.fundingVehicles.includes(fundingVehicleId)) ||
		date.entries.find(entry => entry.fundingVehicles.includes('default'));
	return matchingEntry && matchingEntry.dateType === dateType;
};

const DateOptionLabel = props => {
	return (
		<span>
			{props.name} (<i style={{textTransform: 'capitalize'}}>{props.dateType}</i>)
		</span>
	);
};
DateOptionLabel.propTypes = {
	name: PropTypes.string,
	dateType: PropTypes.oneOf(['static', 'recurring', 'range']),
};

export const getRangeDateType = (dates = [], groupId, fundingVehicleId = 'default') => {
	if (!groupId) return 'static';
	const dateMatch = dates.find(date => date.groupId === groupId);
	if (!dateMatch) return 'static';
	const entryMatch =
		dateMatch.entries.find(x => x.fundingVehicles.includes(fundingVehicleId)) ||
		dateMatch.entries.find(x => x.fundingVehicles.includes('default'));
	return (entryMatch && entryMatch.dateType) || 'static';
};

export const DebtFilterDateControls = props => {
	useEffect(() => {
		props.fetchDates(props.match.params.datasetId, true, false);
		props.fetchCalendars();
	}, []);

	// also clearing out the range date if your change in fvId or type resulted in the selection being invalid
	useEffect(
		() => {
			if (!props.fundingVehicleId) {
				props.setRangeDate(null);
				props.setRangeOccurrences(1);
			}
		},
		[props.fundingVehicleId]
	);
	useEffect(
		() => {
			if ([props.assetDateContext, props.debtDateContext].includes('lastCutoffDate')) {
				props.setRangeDate(null);
				props.setRangeOccurrences(1);
			}
		},
		[props.assetDateContext, props.debtDateContext]
	);
	const isLastCutoffDateSelectedForContext = [props.assetDateContext, props.debtDateContext].includes(
		'lastCutoffDate'
	);
	const isPoolTransactionView = props.cohortColumn === 'pool' && props.viewType === 'transaction';
	const isLastPeriodOnly = isLastCutoffDateSelectedForContext || isPoolTransactionView;
	return (
		<div className={styles.dateControls}>
			<QuickFilter label="Period/Range" className={styles.rangeOccurrences}>
				<KiSelect
					value={
						props.rangeDates.find(opt => opt.groupId === props.rangeDate) ||
						RANGE_OCCURRENCE_OPTIONS.find(opt => opt.value === props.rangeOccurrences)
					}
					onChange={opt => {
						if (opt.groupId) {
							props.setRangeDate(opt, true);
							props.setRangeOccurrences(1);
						} else {
							props.setRangeDate(null, true);
							props.setRangeOccurrences(opt.value, 'recurring');
						}
					}}
					getOptionValue={opt => opt.groupId || opt.value}
					getOptionLabel={opt => opt.name || opt.label}
					options={[
						{
							label: 'Periods',
							options: RANGE_OCCURRENCE_OPTIONS.map(x => ({
								// pool transaction view only gets to pick Last Period, so mark all others disabled
								...x,
								isDisabled: isLastPeriodOnly && x.value !== 1,
							})),
						},
						{
							label: 'Date Ranges',
							options: props.rangeDates.map(x => ({
								...x,
								isDisabled: isLastPeriodOnly,
							})),
						},
					]}
					isDisabled={props.isLoading || !props.fundingVehicleId || props.viewType === 'role'}
				/>
			</QuickFilter>
			<QuickFilter label="Date Range" className={styles.dateRange} isHidden={!props.rangeDate}>
				<KiCheckbox
					checked={props.isCustomDateRange}
					onChange={val => props.setIsCustomDateRange(val, true)}
					label="Custom"
					className={styles.customCheckbox}
					disabled={props.isLoading}
				/>
				<div className={styles.debtRangeInputs}>
					<KiDatePicker
						className={styles.datePicker}
						disabled={props.isLoading || !props.isCustomDateRange}
						onChange={val => props.setCustomStartDate(dateToShortDate(val), true)}
						value={props.start_date}
					/>
					<span className={styles.debtRangeInputsHyphen}>-</span>
					<KiDatePicker
						className={styles.datePicker}
						disabled={props.isLoading || !props.isCustomDateRange}
						onChange={val => props.setCustomEndDate(dateToShortDate(val), true)}
						value={props.end_date}
					/>
				</div>
			</QuickFilter>
			<QuickFilter
				label="Statement Date"
				labelInfo="Most recent reporting period based on this date"
				className={styles.statementDate}
			>
				<KiDatePicker
					className={styles.datePicker}
					onChange={val => props.setDate(dateToShortDate(val), 'statementDate', true)}
					value={props.snapshotDate}
					disabled={props.isLoading}
				/>
			</QuickFilter>
		</div>
	);
};

DebtFilterDateControls.propTypes = {
	rangeDates: PropTypes.array,
	// rangeType: PropTypes.string,
	rangeDate: PropTypes.string,
	rangeOccurrences: PropTypes.any,
	// setRangeType: PropTypes.func,
	setRangeDate: PropTypes.func,
	setRangeOccurrences: PropTypes.func,
	isCustomDateRange: PropTypes.bool,
	datasetId: PropTypes.string,
	fundingVehicleId: PropTypes.string,
	start_date: PropTypes.string,
	end_date: PropTypes.string,
	setCustomStartDate: PropTypes.func.isRequired,
	setCustomEndDate: PropTypes.func.isRequired,
	setIsCustomDateRange: PropTypes.func.isRequired,
	match: PropTypes.object,
	fetchDates: PropTypes.func.isRequired,
	fetchCalendars: PropTypes.func.isRequired,
	snapshotDate: PropTypes.string,
	setDate: PropTypes.func.isRequired,
	viewType: PropTypes.string,
	cohortColumn: PropTypes.string,
	assetDateContext: PropTypes.string,
	debtDateContext: PropTypes.string,
	isLoading: PropTypes.bool,
};

DebtFilterDateControls.defaultProps = {
	rangeType: 'static',
	rangeDate: null,
	rangeOccurrences: 1,
	dates: [],
	rangeDates: [],
};

const mapStateToProps = state => {
	const {
		debtExploration: {
			view: {settings},
		},
	} = state;
	return {
		rangeDates: state.datasetDates.dates.filter(
			dateFilter.bind(this, settings.quickFilters.fundingVehicleId, 'range')
		),
		fundingVehicleId: settings.quickFilters.fundingVehicleId,
		rangeType: settings.quickFilters.rangeType,
		rangeDate: settings.quickFilters.rangeDate,
		rangeOccurrences: settings.quickFilters.rangeOccurrences,
		start_date: settings.start_date,
		end_date: settings.end_date,
		isCustomDateRange: settings.isCustomDateRange,
		snapshotDate: settings.snapshotDate,
		viewType: settings.viewType,
		cohortColumn: settings.cohortColumn,
		assetDateContext: settings.assetDateContext,
		debtDateContext: settings.debtDateContext,
	};
};

const actionCreators = {
	fetchDates,
	fetchCalendars,
	setRangeDate,
	setRangeOccurrences,
	setIsCustomDateRange,
	showSnackbar,
	setCustomStartDate,
	setCustomEndDate,
	setDate,
	setEndDate,
};

export default connect(
	mapStateToProps,
	actionCreators
)(withRouter(DebtFilterDateControls));
