import React from 'react';
import PropTypes from 'prop-types';
import styles from './KiCardExplorer.theme.scss';
import {getChartTypes, getSummaryColumnList, defaultData} from './KiCardExplorerUtils';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import Select from 'react-select';
import KiDatePicker from 'components/KiDatePicker';
import {buildTitle} from 'ki-common/utils/explorerUtils';

function KiCardExplorerFilters(props) {
	const {
		data,
		view,
		card,
		displayType,
		fundingVehicle,
		fundingVehicles,
		statementDate,
		setDisplayType,
		setFundingVehicle,
		setStatementDate,
		setScenarioOption,
		summaryColumn,
		setSummaryColumn,
		columnType,
		setColumnType,
		scenarioList,
		scenarioId,
	} = props;
	const chartTypes = getChartTypes();
	const summaryColumnList = getSummaryColumnList(view, columnType);

	const scenarioContextOptions =
		view?.explorerData.snapshotType === 'encumbrance'
			? scenarioList.filter(option => option.value !== 'assetSnapshot')
			: scenarioList;

	return (
		<section className={styles.cardFilters}>
			<div className={styles.double}>
				<span className="form-instruction">Display Type:</span>
				<Select
					classNamePrefix="aut-select"
					value={displayType}
					isClearable={false}
					isDisabled={
						(view.explorerData.tableType !== 'cohort' && view.explorerData.tableType !== 'timeSeries') ||
						card.settings.transpose
					}
					options={[
						{
							label: 'Data',
							value: 'data',
							type: 'data',
						},
						...chartTypes,
					]}
					onChange={val => {
						setDisplayType(val);
						//setSummaryColumn(null);
					}}
				/>
			</div>
			<div>
				<span className="form-instruction">Statement Date</span>
				<KiDatePicker
					className={styles.filterDatePicker}
					value={statementDate}
					onChange={val => setStatementDate(dateToShortDate(val))}
				/>
			</div>
			<div className={styles.double}>
				<span className="form-instruction">Funding Vehicle</span>
				<Select
					classNamePrefix="aut-select"
					isClearable={true}
					isSearchable={true}
					options={fundingVehicles}
					value={fundingVehicle}
					getOptionLabel={option => option.name}
					getOptionValue={option => option._id}
					onChange={option => setFundingVehicle(option)}
				/>
			</div>
			<div className={styles.double}>
				<span className="form-instruction">Scenario Context</span>
				<Select
					classNamePrefix="aut-select"
					isClearable={true}
					isSearchable={true}
					options={scenarioContextOptions}
					value={scenarioList.find(s => s.value == scenarioId)}
					getOptionLabel={option => option.label}
					getOptionValue={option => option.value}
					onChange={option => setScenarioOption(option)}
				/>
			</div>
			{displayType.value !== 'data' &&
				!/\b(\w*pie\w*)\b/.test(displayType.value) && (
					<div>
						<span className="form-instruction">Column Type:</span>
						<Select
							classNamePrefix="aut-select"
							value={columnType}
							isClearable={false}
							options={[
								{
									label: 'Amounts',
									value: 'number',
								},
								{
									label: 'Percents',
									value: 'percent',
								},
							]}
							onChange={val => setColumnType(val)}
						/>
					</div>
				)}
			{displayType.value !== 'data' &&
				!/\b(\w*pie\w*)\b/.test(displayType.value) && (
					<div>
						<span className="form-instruction">Summary Column:</span>
						<Select
							classNamePrefix="aut-select"
							value={summaryColumn}
							isClearable={false}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id || option.id}
							options={summaryColumnList}
							onChange={val => setSummaryColumn(val)}
						/>
					</div>
				)}
			{/\b(\w*pie\w*)\b/.test(displayType.value) && (
				<div>
					<span className="form-instruction">Summary Column:</span>
					<Select
						classNamePrefix="aut-select"
						value={summaryColumn}
						isClearable={true}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option.id}
						options={data.columns.slice(1).map(col => {
							return {
								...col,
								displayName: buildTitle(col.displayName, col.calculation, col.calculateColum),
							};
						})}
						onChange={val => setSummaryColumn(val)}
					/>
				</div>
			)}
		</section>
	);
}

KiCardExplorerFilters.propTypes = {
	data: PropTypes.object.isRequired,
	card: PropTypes.object.isRequired,
	view: PropTypes.object.isRequired,
	fundingVehicles: PropTypes.array,
	displayType: PropTypes.object,
	fundingVehicle: PropTypes.object,
	statementDate: PropTypes.string,
	summaryColumn: PropTypes.object,
	columnType: PropTypes.object,
	setDisplayType: PropTypes.func,
	setFundingVehicle: PropTypes.func,
	setStatementDate: PropTypes.func,
	setSummaryColumn: PropTypes.func,
	setColumnType: PropTypes.func,
	scenarioList: PropTypes.array,
	scenarioId: PropTypes.string,
	setScenarioOption: PropTypes.func,
};

KiCardExplorerFilters.defaultProps = {
	data: defaultData,
	view: {},
	card: {},
	statementDate: dateToShortDate(new Date()),
	scenarioList: [],
};

export default KiCardExplorerFilters;
