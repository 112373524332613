import {compareDates, formatDate} from '../../../../utils/dateUtils';
import cloneDeep from 'lodash/cloneDeep';
import {TRANSACTION_TYPE_CREDIT, TRANSACTION_TYPE_DEBIT} from '../../ledgerUtils';

export const convertEntryToFormData = (entry, accountOptions) => {
	const formEntry = cloneDeep(entry);
	const {postings, postingDate, effectiveDate} = formEntry;
	postings.forEach(posting => {
		const {debit, credit, accountId} = posting;
		if (debit !== 0) {
			posting.type = TRANSACTION_TYPE_DEBIT;
			posting.amount = debit;
		} else {
			posting.type = TRANSACTION_TYPE_CREDIT;
			posting.amount = credit;
		}
		delete posting.debit;
		delete posting.credit;

		const foundAccount = accountOptions.find(option => option.value === accountId);
		posting.account = foundAccount ? foundAccount : null;
		delete posting.accountId;
	});

	formEntry.postingDate = formatDate(postingDate);
	formEntry.effectiveDate = formatDate(effectiveDate);
	delete formEntry.valid;

	return formEntry;
};

export const convertFormDataToEntry = formData => {
	const saveFormData = cloneDeep(formData);
	// mapping data for the request
	saveFormData.postingDate = formatDate(saveFormData.postingDate);
	saveFormData.effectiveDate = formatDate(saveFormData.effectiveDate);
	saveFormData.postings = saveFormData.postings.map(posting => {
		if (posting.type.value === TRANSACTION_TYPE_DEBIT.value) {
			posting.debit = Number(posting.amount);
			posting.credit = 0;
		} else {
			posting.credit = Number(posting.amount);
			posting.debit = 0;
		}
		posting.accountId = posting.account.value;
		delete posting.amount;
		delete posting.type;
		delete posting.account;

		return posting;
	});

	return saveFormData;
};

export const convertEntriesToListData = (entries, closeDate, accounts) => {
	return entries.map(entry => {
		const summaryDebit = entry.postings.reduce((total, {debit}) => (total += debit), 0);
		const summaryCredit = entry.postings.reduce((total, {credit}) => (total += credit), 0);

		// everything should have a value
		const valuesValid = !Object.values(entry).some(value => {
			return value === null || value === '';
		});
		// effective date must be after close date
		const datesValid = !compareDates(closeDate, entry.effectiveDate);
		// debit credit must balance
		const totalsValid = summaryDebit === summaryCredit;

		entry.valid = valuesValid && datesValid && totalsValid;

		entry.postings = entry.postings.map(posting => {
			const accountFound = accounts.find(account => posting.accountId === account._id);
			posting.account = accountFound ? accountFound.description : '';
			return posting;
		});
		return entry;
	});
};
