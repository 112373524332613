import React from 'react';
import PropTypes from 'prop-types';
import {compareDates} from '../../../../utils/dateUtils';
import KiIconButton from 'components/KiIconButton';
import KiDatePicker from 'components/KiDatePicker';
import KiInput from 'components/KiInput';
import {ledgerCurrencyFormat} from '../../ledgerUtils';

function ManualEntryPostItem({
	formData,
	onFormItemChange,
	undoChanges,
	onSave,
	canEntryBeSaved,
	debit,
	credit,
	ledgerCloseDate,
	addLineItem,
}) {
	const {postCode, description, postingDate, effectiveDate} = formData;

	return (
		<div className="horizontal-inputs-container significant">
			<div>
				<KiInput
					type="text"
					label="Post Code"
					value={postCode}
					onChange={value => onFormItemChange('postCode', value)}
				/>
			</div>
			<div className="long-wrapper">
				<KiInput
					type="text"
					label="Post Description"
					value={description}
					onChange={value => onFormItemChange('description', value)}
				/>
			</div>
			<div>
				<span className="theme-label">Posting Date</span>
				<KiDatePicker
					className="datepicker"
					value={postingDate}
					onChange={value => onFormItemChange('postingDate', value)}
				/>
			</div>
			<div>
				<span className="theme-label">Effective Date</span>
				<KiDatePicker
					className={`${compareDates(ledgerCloseDate, effectiveDate) ? 'red-input' : ''} datepicker`}
					value={effectiveDate}
					onChange={value => onFormItemChange('effectiveDate', value)}
				/>
			</div>
			<div className="text-wrapper">
				<span className="theme-label">Debits</span>
				<div>{ledgerCurrencyFormat(debit)}</div>
			</div>
			<div className="text-wrapper">
				<span className="theme-label">Credits</span>
				<div>{ledgerCurrencyFormat(credit)}</div>
			</div>
			<div className="to-right button-wrapper button-wrapper-size-3">
				<KiIconButton icon="add" onClick={addLineItem} />
				<KiIconButton icon="undo" onClick={undoChanges} />
				<KiIconButton icon="save" onClick={onSave} disabled={!canEntryBeSaved()} />
			</div>
		</div>
	);
}

ManualEntryPostItem.propTypes = {
	formData: PropTypes.object.isRequired,
	onFormItemChange: PropTypes.func.isRequired,
	undoChanges: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	canEntryBeSaved: PropTypes.func.isRequired,
	debit: PropTypes.number.isRequired,
	credit: PropTypes.number.isRequired,
	ledgerCloseDate: PropTypes.string.isRequired,
	addLineItem: PropTypes.func.isRequired,
};

export default ManualEntryPostItem;
