import _ from 'lodash';

const reduceStepNumbers = steps => {
	const stepTally = _.countBy(steps, 'priority');
	const sortedStepGroups = Object.keys(stepTally);

	steps = _.sortBy(steps, 'priority');
	steps.forEach(step => {
		step.priority = sortedStepGroups.indexOf(String(step.priority)) + 1;
	});
	return steps;
};

const breakGrouping = (steps, index) => {
	const targetGroup = steps[index].priority;

	const itemsToBreak = steps.filter(item => {
		return item.priority === targetGroup;
	});

	const totalInGroup = itemsToBreak.length;

	let stepsAdded = 0;
	steps.forEach(step => {
		if (step.priority === targetGroup) {
			step.priority = targetGroup + stepsAdded;
			stepsAdded++;
		} else if (step.priority > targetGroup) {
			step.priority += totalInGroup;
		}
	});
	return steps
};

export {reduceStepNumbers, breakGrouping};
