import PropTypes from 'prop-types';
import React, {useState, useCallback, useEffect} from 'react';
import classnames from 'classnames';
import KiIconButton from 'components/KiIconButton';
import _get from 'lodash/get';
import styles from './KiTwoPanelList.theme.scss';
import KiTooltip from '../KiTooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';
import {saveConstraintOrder} from 'api/fundingVehiclesApi';
import {useDispatch} from 'react-redux';
import {showSnackbar} from 'state/actions/Snackbar';

export const DefaultEditItem = props => {
	if (props.item === null) {
		return <span />;
	}

	return (
		<span onClick={this.props.onClick}>
			{Object.keys(props.item).map(key => {
				return (
					<div key={key}>
						{key} - {JSON.stringify(props.item[key], null, 2)}
					</div>
				);
			})}
		</span>
	);
};

DefaultEditItem.propTypes = {
	item: PropTypes.object,
	// eslint-disable-next-line react/no-unused-prop-types
	onClick: PropTypes.func,
};

DefaultEditItem.defaultProps = {
	item: {},
	onClick: () => {
		// eslint-disable-next-line
		console.log('Default onClick event');
	},
};

export const DefaultListItem = props => {
	if (props.item === null) {
		return <span />;
	}

	return <span>{JSON.stringify(props.item, null, 2)}</span>;
};

DefaultListItem.propTypes = {
	item: PropTypes.object,
};

DefaultListItem.defaultProps = {
	item: {},
};

const seperateListIntoCategories = list => {
	const completeList = {};

	const excludeList = list.filter(item => {
		return item.rowType === 'ccExclude';
	});
	const otherConstraintsList = list.filter(item => {
		return item.rowType !== 'ccExclude';
	});

	completeList['ccExclude'] = excludeList.sort(dynamicSortByProperty('sequenceNum'));
	completeList['ccOtherConstraints'] = otherConstraintsList.sort(dynamicSortByProperty('sequenceNum'));

	return completeList;
};

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	expanded: {
		flexDirection: 'column',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

function dynamicSortByProperty(property) {
	let sortOrder = 1;
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function(a, b) {
		/* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
		const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
}

// QA class handle is ktpl
/*
the components can be passed in either using the function
	eg. editItemComponent: DefaultEditItem,
or by passing in a full component block
	eg. editItemComponent: <DefaultEditItem onClick={() => {CODE HERE}} />
 */
export const KiTwoPanelList = props => {
	const dispatch = useDispatch();

	const first = props.itemList[0];
	const itemId = _get(first, props.itemKeyAttribute, '');

	const [selectedItemId, setSelectedItemId] = useState(itemId);
	const [selectedItem, setSelectedItem] = useState(first || null);
	const [showEditPanel, setShowEditPanel] = useState(first ? true : false);

	const [itemListLocalCategorized, setItemListLocalCategorized] = useState(
		seperateListIntoCategories(props.itemList)
	);

	const classes = useStyles();

	useEffect(
		() => {
			// Handles refreshing of item list when it is saved with new settings, adding new item, removing existing item
			setItemListLocalCategorized(seperateListIntoCategories(props.itemList));

			// Handles refreshing selected item when it is saved with new settings
			if (selectedItemId) {
				const foundItem = props.itemList.find(item => {
					return item._id === selectedItemId;
				});
				if (!foundItem) {
					setSelectedItemId('');
					setSelectedItem({});
				} else {
					setSelectedItemId(foundItem._id);
					setSelectedItem(foundItem);
				}
			}
		},
		[props.itemList]
	);

	const handleItemClick = useCallback((itemId, item) => {
		setSelectedItemId(itemId);
		setSelectedItem(item);
		setShowEditPanel(true);
	});
	const handleAddClick = useCallback(() => {
		setSelectedItemId('');
		setSelectedItem({});
		setShowEditPanel(true);
	});

	const reverseListGeneric = () => {
		if (itemListLocalCategorized['ccExclude']?.length || itemListLocalCategorized['ccOtherConstraints']?.length) {
			const currentIndexExclude = itemListLocalCategorized['ccExclude'].findIndex(item => {
				return item._id === selectedItemId;
			});
			const currentIndexOtherConstraints = itemListLocalCategorized['ccOtherConstraints'].findIndex(item => {
				return item._id === selectedItemId;
			});

			if (currentIndexExclude !== -1) {
				let newList = [...itemListLocalCategorized['ccExclude']];
				newList = newList.reverse();

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccExclude'] = newList;

				setItemListLocalCategorized(newObject);
			} else if (currentIndexOtherConstraints !== -1) {
				let newList = [...itemListLocalCategorized['ccOtherConstraints']];
				newList = newList.reverse();

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccOtherConstraints'] = newList;

				setItemListLocalCategorized(newObject);
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const popItemTopListGeneric = () => {
		if (itemListLocalCategorized['ccExclude']?.length || itemListLocalCategorized['ccOtherConstraints']?.length) {
			const currentIndexExclude = itemListLocalCategorized['ccExclude'].findIndex(item => {
				return item._id === selectedItemId;
			});
			const currentIndexOtherConstraints = itemListLocalCategorized['ccOtherConstraints'].findIndex(item => {
				return item._id === selectedItemId;
			});

			if (currentIndexExclude !== -1) {
				const copyList = [...itemListLocalCategorized['ccExclude']];

				const element = copyList[currentIndexExclude];

				copyList.splice(currentIndexExclude, 1);

				const finalList = [element, ...copyList];

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccExclude'] = finalList;

				setItemListLocalCategorized(newObject);
			} else if (currentIndexOtherConstraints !== -1) {
				const copyList = [...itemListLocalCategorized['ccOtherConstraints']];

				const element = copyList[currentIndexOtherConstraints];

				copyList.splice(currentIndexOtherConstraints, 1);

				const finalList = [element, ...copyList];

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccOtherConstraints'] = finalList;

				setItemListLocalCategorized(newObject);
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const moveItemUpOneGeneric = () => {
		if (itemListLocalCategorized['ccExclude']?.length || itemListLocalCategorized['ccOtherConstraints']?.length) {
			const currentIndexExclude = itemListLocalCategorized['ccExclude'].findIndex(item => {
				return item._id === selectedItemId;
			});
			const currentIndexOtherConstraints = itemListLocalCategorized['ccOtherConstraints'].findIndex(item => {
				return item._id === selectedItemId;
			});

			if (currentIndexExclude !== -1) {
				const newList = [...itemListLocalCategorized['ccExclude']];

				if (currentIndexExclude > 0) {
					const element = newList[currentIndexExclude];
					newList[currentIndexExclude] = newList[currentIndexExclude - 1];
					newList[currentIndexExclude - 1] = element;
				}

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccExclude'] = newList;

				setItemListLocalCategorized(newObject);
			} else if (currentIndexOtherConstraints !== -1) {
				const newList = [...itemListLocalCategorized['ccOtherConstraints']];

				if (currentIndexOtherConstraints > 0) {
					const element = newList[currentIndexOtherConstraints];
					newList[currentIndexOtherConstraints] = newList[currentIndexOtherConstraints - 1];
					newList[currentIndexOtherConstraints - 1] = element;
				}

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccOtherConstraints'] = newList;

				setItemListLocalCategorized(newObject);
			} else {
				return null;
			}
		} else {
			return null;
		}
	};
	const moveItemDownOneGeneric = () => {
		if (itemListLocalCategorized['ccExclude']?.length || itemListLocalCategorized['ccOtherConstraints']?.length) {
			const currentIndexExclude = itemListLocalCategorized['ccExclude'].findIndex(item => {
				return item._id === selectedItemId;
			});
			const currentIndexOtherConstraints = itemListLocalCategorized['ccOtherConstraints'].findIndex(item => {
				return item._id === selectedItemId;
			});

			if (currentIndexExclude !== -1) {
				const newList = [...itemListLocalCategorized['ccExclude']];

				if (currentIndexExclude !== -1 && currentIndexExclude < newList.length - 1) {
					const element = newList[currentIndexExclude];
					newList[currentIndexExclude] = newList[currentIndexExclude + 1];
					newList[currentIndexExclude + 1] = element;
				}

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccExclude'] = newList;

				setItemListLocalCategorized(newObject);
			} else if (currentIndexOtherConstraints !== -1) {
				const newList = [...itemListLocalCategorized['ccOtherConstraints']];

				if (currentIndexOtherConstraints !== -1 && currentIndexOtherConstraints < newList.length - 1) {
					const element = newList[currentIndexOtherConstraints];
					newList[currentIndexOtherConstraints] = newList[currentIndexOtherConstraints + 1];
					newList[currentIndexOtherConstraints + 1] = element;
				}

				const newObject = JSON.parse(JSON.stringify(itemListLocalCategorized));

				newObject['ccOtherConstraints'] = newList;

				setItemListLocalCategorized(newObject);
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	const handleSaveConstraintOrdering = async () => {
		const allSeqIdsExclude = itemListLocalCategorized['ccExclude'].map(obj => obj._id);

		const allSeqIdsOtherConstraints = itemListLocalCategorized['ccOtherConstraints'].map(obj => obj._id);

		const combinedList = allSeqIdsExclude.concat(allSeqIdsOtherConstraints);

		try {
			await saveConstraintOrder(combinedList);
			const opts = {
				msg: `Saved Constraints Order Successfully`,
			};
			dispatch(showSnackbar(opts));
		} catch (err) {
			dispatch(showSnackbar('Error Saving Constraint Group'));
			const opts = {
				msg: `Failed to Save Constraints Order. Reason: ${err.message || 'Unknown'}`,
				className: styles.snackbarError,
			};
			dispatch(showSnackbar(opts));
		}
	};

	const {listHeader, listItemComponent, editItemComponent, className} = props;
	let headerElement;

	switch (typeof listHeader) {
		case 'object':
			headerElement = React.cloneElement(listHeader, {}, []);
			break;

		case 'function':
			headerElement = React.createElement(listHeader, {}, []);
			break;

		case 'string':
		default:
			headerElement = listHeader;
	}

	return (
		<div className={classnames(`${styles['outerWrapper']}`, `${className}`)}>
			<div className={styles.listPanel}>
				<div className={styles.listHeaderRow}>
					<div className={styles.listHeader}>{headerElement}</div>
					<KiTooltip message="Add New Constraint">
						<KiIconButton
							icon="add_circle"
							className={classnames(`${styles['addButton']}`, 'qa_ktpl_add')}
							onClick={() => handleAddClick()}
							inverse={true}
						/>
					</KiTooltip>
					<KiTooltip message="Reverse List">
						<KiIconButton
							icon="loop"
							className={classnames(`${styles['addButton']}`, 'qa_ktpl_add')}
							onClick={() => {
								reverseListGeneric();
							}}
							inverse={true}
							disabled={
								itemListLocalCategorized['ccExclude'].length <= 0 &&
								itemListLocalCategorized['ccOtherConstraints'].length <= 0
							}
						/>
					</KiTooltip>
					<KiTooltip message="Pop Selected To Top">
						<KiIconButton
							icon="shuffle"
							className={classnames(`${styles['addButton']}`, 'qa_ktpl_add')}
							onClick={() => {
								popItemTopListGeneric();
							}}
							inverse={true}
							disabled={
								itemListLocalCategorized['ccExclude'].length <= 0 &&
								itemListLocalCategorized['ccOtherConstraints'].length <= 0
							}
						/>
					</KiTooltip>
					<KiTooltip message="Move Item Up">
						<KiIconButton
							icon="arrow_drop_up"
							className={classnames(`${styles['addButton']}`, 'qa_ktpl_add')}
							onClick={() => {
								moveItemUpOneGeneric();
							}}
							inverse={true}
							disabled={
								itemListLocalCategorized['ccExclude'].length <= 0 &&
								itemListLocalCategorized['ccOtherConstraints'].length <= 0
							}
						/>
					</KiTooltip>
					<KiTooltip message="Move Item Down">
						<KiIconButton
							icon="arrow_drop_down"
							className={classnames(`${styles['addButton']}`, 'qa_ktpl_add')}
							onClick={() => {
								moveItemDownOneGeneric();
							}}
							inverse={true}
							disabled={
								itemListLocalCategorized['ccExclude'].length <= 0 &&
								itemListLocalCategorized['ccOtherConstraints'].length <= 0
							}
						/>
					</KiTooltip>
					<KiTooltip message="Save Constraint Order">
						<KiIconButton
							icon="save"
							className={classnames(`${styles['addButton']}`, 'qa_ktpl_add')}
							onClick={() => {
								//console.log('clicked save button');
								handleSaveConstraintOrdering();
							}}
							inverse={true}
							disabled={
								itemListLocalCategorized['ccExclude'].length <= 0 &&
								itemListLocalCategorized['ccOtherConstraints'].length <= 0
							}
						/>
					</KiTooltip>
				</div>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						Exclusions
					</AccordionSummary>
					<AccordionDetails classes={{root: classes.expanded}}>
						{itemListLocalCategorized?.['ccExclude']
							? itemListLocalCategorized['ccExclude'].map((item, index) => {
									const itemId = _get(item, props.itemKeyAttribute, '');

									return (
										<div
											key={itemId}
											className={classnames({
												[`qa_ktpl_list_item_${itemId}`]: true,
												[`${styles['listItem']}`]: true,
												[`${styles['activeListItem']}`]: selectedItemId === itemId,
											})}
											onClick={() => handleItemClick(itemId, item)}
										>
											<div className={styles.listNumber}>{index + 1}.</div>
											{typeof listItemComponent === 'object'
												? React.cloneElement(
														listItemComponent,
														{
															itemId,
															item,
														},
														[]
												  )
												: React.createElement(
														listItemComponent,
														{
															itemId,
															item,
														},
														[]
												  )}
										</div>
									);
							  })
							: null}
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						Compliance
					</AccordionSummary>
					<AccordionDetails classes={{root: classes.expanded}}>
						{itemListLocalCategorized?.['ccOtherConstraints']
							? itemListLocalCategorized['ccOtherConstraints'].map((item, index) => {
									const itemId = _get(item, props.itemKeyAttribute, '');

									return (
										<div
											key={itemId}
											className={classnames({
												[`qa_ktpl_list_item_${itemId}`]: true,
												[`${styles['listItem']}`]: true,
												[`${styles['activeListItem']}`]: selectedItemId === itemId,
											})}
											onClick={() => handleItemClick(itemId, item)}
										>
											<div className={styles.listNumber}>
												{itemListLocalCategorized['ccExclude'].length + index + 1}.
											</div>
											{typeof listItemComponent === 'object'
												? React.cloneElement(
														listItemComponent,
														{
															itemId,
															item,
														},
														[]
												  )
												: React.createElement(
														listItemComponent,
														{
															itemId,
															item,
														},
														[]
												  )}
										</div>
									);
							  })
							: null}
					</AccordionDetails>
				</Accordion>
			</div>
			{/* For showing Edit panel when Clicking on an Item Component */}
			<div className={styles.itemPanel}>
				{showEditPanel &&
					(typeof editItemComponent === 'object'
						? React.cloneElement(
								editItemComponent,
								{
									item: selectedItem,
									itemId: selectedItemId,
								},
								[]
						  )
						: React.createElement(
								editItemComponent,
								{
									item: selectedItem,
									itemId: selectedItemId,
								},
								[]
						  ))}
			</div>
		</div>
	);
};

KiTwoPanelList.propTypes = {
	itemList: PropTypes.array,
	itemKeyAttribute: PropTypes.string,
	listItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
	editItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
	listHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
	className: PropTypes.string,
};

KiTwoPanelList.defaultProps = {
	itemList: [],
	itemKeyAttribute: 'id',
	listItemComponent: DefaultListItem,
	editItemComponent: DefaultEditItem,
	listHeader: 'Item List',
	className: '',
};

export default KiTwoPanelList;
