const mongoObjectIDStringRegex = require('../../utils/testUtils').mongoObjectIDStringRegex;
const dateStringRegex = require('../../utils/dateHelpers').dateStringRegex;

const getConstraints = () => ({
	'_id': {
		presence: false,
		format: {
			pattern: mongoObjectIDStringRegex,
			flags: 'i',
			message: '_id should be a valid string of a mongo ObjectID',
		},
	},
	'identifier': {
		presence: {allowEmpty: false},
	},
	'fundingVehicleId': {
		presence: false,
		format: {
			pattern: mongoObjectIDStringRegex,
			flags: 'i',
			message: 'fundingVehicleId must be a valid mongo ObjectID string',
		},
	},
	'summaryInfo': {
		presence: {allowEmpty: false},
	},
	'summaryInfo.totalCount': {
		presence: {allowEmpty: false},
		numericality: {
			onlyInteger: true,
			noStrings: true
		},
	},
	'summaryInfo.totalBalance': {
		presence: {allowEmpty: false},
		numericality: {
			noStrings: true,
		},
	},
	'summaryInfo.snapshotDate': {
		presence: {allowEmpty: false},
		format: {
			pattern: dateStringRegex,
			flags: 'i',
		},
	},
});

module.exports = {
	getConstraints
};
