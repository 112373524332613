import React, {useEffect} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import styles from '../index.theme.scss';
import {connect} from 'react-redux';
import {fetchProjectionScenarios} from '../../../actions';
import KiButton from '../../../../../components/KiButton';
import KiIconButton from '../../../../../components/KiIconButton';

const ScenarioListTable = ({scenarios, selectScenario, fetchProjectionScenarios, showDeleteConfirmation}) => {
	useEffect(() => {
		fetchProjectionScenarios();
	}, []);

	/* eslint-disable react/display-name, react/prop-types */
	return (
		<AbsTable
			data={scenarios}
			columns={[
				{
					Header: 'Scenario Name',
					accessor: 'name',
				},
				{Header: 'Data Set', accessor: 'dataset.name'},
				{
					Header: 'Collateral',
					id: 'collateral',
					accessor: d => d.collateral?.map?.(item => item.name).join(', '),
				},
				{Header: 'Filter', id: 'filters', accessor: d => d.filters?.map?.(item => item.name).join(', ')},
				{Header: 'Model', accessor: 'model.name'},
				{Header: 'Repline Set', accessor: 'repline.name'},
				{Header: 'Call', accessor: 'call.name', width: 90},
				{
					Header: 'Updated',
					id: 'updated',
					accessor: 'lastUpdate',
					Cell: ({value, row}) =>
						value ? `${moment(value).format('YYYY-MM-DD HH:mm')} by ${row.original.user}` : '',
				},
				{
					id: 'delete-button',
					width: 120,
					displayName: 'delete button',
					Cell: ({row}) => (
						<div className={styles.actionsColumn}>
							<KiButton mini={true} primary onClick={() => selectScenario(row.original)}>
								View
							</KiButton>
							<KiIconButton icon="delete" onClick={() => showDeleteConfirmation(row.original._id)} />
						</div>
					),
				},
			]}
		/>
	);
};

ScenarioListTable.propTypes = {
	scenarios: PropTypes.arrayOf(PropTypes.object),
	selectScenario: PropTypes.func.isRequired,
	showDeleteConfirmation: PropTypes.func,
	fetchProjectionScenarios: PropTypes.func,
};

ScenarioListTable.defaultProps = {
	scenarios: [],
};

const mapStateToProps = state => ({
	scenarios: state.forecasting.projectionScenarios,
});

const mapDispatchToProps = {
	fetchProjectionScenarios,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScenarioListTable);
