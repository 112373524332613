import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {explorerApi} from 'api';
import styles from './ColumnMenu.theme.scss';

export class SummaryData extends Component {
	state = {
		summary: [],
		loaded: false,
	};

	componentDidMount() {
		explorerApi
			.fetchColumnSummary(
				this.props.columnId,
				this.props.dataExplorer,
				this.props.columnList,
				this.props.snapshotDate
			)
			.then(summary => {
				this.setState({
					summary: summary,
					loaded: true,
				});
			});
	}

	render() {
		return (
			<div>
				<table>
					<tbody>
						{this.state.loaded ? (
							this.state.summary.map((dataPoint, idx) => (
								<tr key={idx} className={styles.row}>
									<td>{dataPoint.name}</td>
									<td>{dataPoint.value}</td>
								</tr>
							))
						) : (
							<tr className={styles.row}>
								<td colSpan="2">loading...</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		);
	}
}

SummaryData.propTypes = {
	columnId: PropTypes.string.isRequired,
	dataExplorer: PropTypes.object.isRequired,
	columnList: PropTypes.array.isRequired,
	snapshotDate: PropTypes.string.isRequired,
};

export default SummaryData;
