// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiSelect, {KiCreatable} from 'components/KiSelect';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import validators from 'ki-common/validators';
import ccConcentrationLimit from './validators/ccConcentrationLimit';
const constraints = ccConcentrationLimit.getConstraints();

const ConcentrationLimitForm = props => {
	const {constraintData, isSaveClick} = props;
	const [formError, setFormError] = useState('');
	const [dataOptions, setDataOptions] = useState([]);
	const [selectionOptions, setSelectionOptions] = useState([]);
	const isDateValue = ['date_long', 'date_short'].includes(constraintData.dataColumn?.dataType);

	useEffect(
		() => {
			setDataOptions(
				ConstraintHelpers.getCohortColumns(
					props.dataColumns,
					props.constraintData?.dataColumn,
					true, // isNew
					props.isGroupBy,
					false // onlyNumeric
				)
			);
			setSelectionOptions(
				ConstraintHelpers.getCohortColumns(
					props.dataColumns,
					props.constraintData?.dataColumn,
					true, // isNew
					props.isGroupBy,
					true, // onlyNumeric
					props.eligibleColumns
				)
			);
		},
		[props.dataColumns, props.constraintData?.dataColumn, props.isGroupBy, props.eligibleColumns]
	);

	useEffect(
		() => {
			props.updateFormHasError(!!formError);
		},
		[props.updateFormHasError, formError]
	);

	useEffect(
		() => {
			if (isSaveClick) {
				setFormError(validators.validate(props.constraintData, constraints));
			}
		},
		[props.constraintData, isSaveClick]
	);

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						isDisabled={props.isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.dataColumn}
						isLoading={props.loadingColumns}
						isClearable={false}
						options={dataOptions}
						onChange={dc => {
							props.setConstraintItem('dataColumn', dc);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						isDisabled={!constraintData.dataColumn || props.isReadOnly}
						classNamePrefix="aut-select"
						value={options.concentrationLogicOptions.find(
							option => option.value === constraintData.logic?.value
						)}
						isClearable={false}
						options={options.concentrationLogicOptions}
						onChange={l => {
							const keys = [];
							const values = [];
							keys.push('logic');
							values.push(l);
							keys.push('target');
							if (l.value === 'between') {
								values.push(['', '']);
							} else {
								values.push(['']);
							}
							props.setConstraintItem(keys, values);
						}}
					/>
				</div>
				{constraintData.logic?.value === 'between' && (
					<div
						className={styles.selectWrapper}
						style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
					>
						<KiInput
							disabled={props.isReadOnly}
							type="text"
							label="Min"
							value={constraintData.target[0]}
							isNumberMasked={!isDateValue}
							onChange={t => {
								const newValue = [t, constraintData.target[1]];
								props.setConstraintItem('target', newValue);
							}}
							error={formError?.target}
						/>
						<KiInput
							disabled={props.isReadOnly}
							type="text"
							label="Max"
							value={constraintData.target[1]}
							isNumberMasked={!isDateValue}
							onChange={t => props.setConstraintItem('target', [constraintData.target[0], t])}
							error={formError?.target}
						/>
					</div>
				)}
				{constraintData.logic?.value !== 'between' && (
					<div className={styles.selectWrapper}>
						<KiInput
							disabled={props.isReadOnly}
							type="text"
							name="name"
							label={'Target'}
							isNumberMasked={!isDateValue}
							value={constraintData.target?.toString()}
							onChange={t => {
								props.setConstraintItem('target', [t]);
							}}
							error={formError?.target}
						/>
					</div>
				)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Selection</span>
					<KiSelect
						isDisabled={props.isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.selection}
						isClearable={false}
						options={options.selections}
						onChange={sel => props.setConstraintItem('selection', sel)}
					/>
				</div>
				{constraintData.selection?.value === 'include' && (
					<div className={styles.selectWrapper}>
						<span className="theme-label">Includes</span>
						<KiCreatable
							isDisabled={props.isReadOnly}
							isMulti={true}
							type="text"
							placeholder={'Enter a value...'}
							clearable={false}
							noResultsText={false}
							value={constraintData.concentrationIncludes?.map(t => ({
								value: t,
								label: t,
							}))}
							onChange={t => props.setConstraintItem('concentrationIncludes', t.map(t => t.value))}
						/>
						<div className={styles.ccConcentrationIncludesDateError}>
							{formError?.concentrationIncludes}
						</div>
					</div>
				)}
				{constraintData.selection?.value === 'top_nth_bucket' && (
					<div className={styles.selectWrapper}>
						<span className="theme-label">Excludes</span>
						<KiCreatable
							isDisabled={props.isReadOnly}
							isMulti={true}
							type="text"
							placeholder={'Enter a value...'}
							clearable={false}
							noResultsText={false}
							value={constraintData.concentrationExcludes?.map(t => ({value: t, label: t}))}
							onChange={t => props.setConstraintItem('concentrationExcludes', t.map(t => t.value))}
						/>
						<div className={styles.ccConcentrationIncludesDateError}>
							{formError?.concentrationExcludes}
						</div>
						<KiInput
							className={styles.constraintInput}
							disabled={props.isReadOnly}
							type="text"
							label="Value"
							value={constraintData.concentrationValue}
							isNumberMasked={!isDateValue}
							onChange={t => {
								props.setConstraintItem('concentrationValue', t);
							}}
							error={formError?.concentrationValue}
						/>
					</div>
				)}
				{constraintData.selection?.value === 'top_n_combined' && (
					<div className={styles.selectWrapper}>
						<span className="theme-label">Excludes</span>
						<KiCreatable
							isDisabled={props.isReadOnly}
							isMulti={true}
							type="text"
							placeholder={'Enter a value...'}
							clearable={false}
							noResultsText={false}
							value={
								constraintData.concentrationExcludes &&
								constraintData.concentrationExcludes.map(t => ({
									value: t,
									label: t,
								}))
							}
							onChange={t => {
								props.setConstraintItem('concentrationExcludes', t.map(t => t.value));
							}}
						/>
						<div className={styles.ccConcentrationIncludesDateError}>
							{formError?.concentrationExcludes}
						</div>
						<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
							<KiInput
								disabled={props.isReadOnly}
								type="text"
								label="Min"
								value={constraintData.concentrationMin}
								isNumberMasked={!isDateValue}
								onChange={t => {
									props.setConstraintItem('concentrationMin', t);
								}}
								error={formError?.concentrationMin}
							/>
							<KiInput
								disabled={props.isReadOnly}
								type="text"
								label="Max"
								value={constraintData.concentrationMax}
								isNumberMasked={!isDateValue}
								onChange={t => {
									props.setConstraintItem('concentrationMax', t);
								}}
								error={formError?.concentrationMax}
							/>
						</div>
					</div>
				)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Weighted By</span>
					<KiSelect
						isDisabled={props.isReadOnly}
						classNamePrefix="aut-select"
						value={constraintData.weightedBy}
						isClearable={false}
						options={selectionOptions}
						onChange={sel => props.setConstraintItem('weightedBy', sel)}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
			</div>
		</div>
	);
};

ConcentrationLimitForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func,
	dataColumns: PropTypes.array,
	eligibleColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	updateFormHasError: PropTypes.func,
	isSaveClick: PropTypes.bool,
};

ConcentrationLimitForm.defaultProps = {
	constraintData: {},
	dataColumns: [],
};

export default ConcentrationLimitForm;
