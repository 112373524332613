import * as actionTypes from './actionTypes';

const initialState = {
	bookmarks: [],
	userBookmarks: [],
};

export const Bookmarks = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DATA_BOOKMARKS_FETCH:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true
					};
				case 'success':
					return {
						...state,
						isLoading: false,
						allBookmarks: action.payload,
						userBookmarks: action.payload.filter(bookmark => bookmark.isGadget === false),
					};
				case 'error':
					return {
						...state,
						isLoading: false
					};
				default:
					return state;
			}
		case actionTypes.DATA_BOOKMARKS_REMOVE:
			return {
				...state,
				allBookmarks: state.allBookmarks.filter(bookmark => !action.payload.includes(bookmark._id)),
				userBookmarks: state.userBookmarks.filter(bookmark => !action.payload.includes(bookmark._id)),
			};
		default:
			return state;
	}
};

export default Bookmarks;
