import React from 'react';
import PropTypes from 'prop-types';
import KiAppBar from 'components/KiAppBar';
import KiIconButton from 'components/KiIconButton';

TopBar.propTypes = {
	history: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	dataset: PropTypes.object.isRequired,
	isEdit: PropTypes.bool,
	onEdit: PropTypes.func.isRequired,
	onNavigate: PropTypes.func.isRequired,
	fundingVehicle: PropTypes.object.isRequired,
};

function TopBar({history, id, dataset, isEdit, onEdit, onNavigate, fundingVehicle}) {
	const tab = 'Models';
	const title = 'Click to create a new Model.';
	return (
		<header>
			<KiAppBar className="top-bar">
				<div className="top-bar-breadcrumb">
					<h1 style={{cursor: 'pointer'}} className="link" onClick={() => history.push('/datasets')}>
						Datasets
					</h1>
					<h1
						style={{cursor: 'pointer'}}
						className="link"
						onClick={() => history.push(`/datasets/${id}/debt`)}
					>
						{` > ${dataset && dataset.name}`}
					</h1>
					<h1 style={{cursor: 'pointer'}} className="link" onClick={onNavigate}>
						&nbsp;&gt;&nbsp;{tab}
					</h1>
					{fundingVehicle && fundingVehicle._id && <h1>{` > ${fundingVehicle.name}`}</h1>}
				</div>
				{!isEdit && (
					<KiIconButton
						icon="add_circle"
						inverse
						disabled={false} //TODO - get isAdmin
						title={title}
						onClick={onEdit}
					/>
				)}
			</KiAppBar>
		</header>
	);
}

export default TopBar;
