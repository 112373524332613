import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './KiCardForecasting.scss';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import {buildCalculation} from 'ki-common/utils/explorerUtils';
import _ from 'lodash';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {waterfallApi} from 'api';
import KiCardFilters from './components/KiCardFilters';
import HeaderCell from './components/HeaderCell';
import TableCell from './components/TableCell';
import {debtOutputOptions} from 'ki-common/options/debt';

const defaultResultView = 'collateralCashflow';

// Returns object {columns: [], rows: []}
const getTableColumnsAndRows = ({rows = [], columns = [], columnInfo = []}, transpose, viewType) => {
	let transformedRows = [...rows];
	let transformedColumns = viewType === 'activity' || viewType === 'waterfall' ? [...columns] : [...columnInfo];
	if (transpose) {
		transformedRows = transformedColumns
			.map((colInfo = {}, colIdx) => ({
				data: [
					`${colInfo.displayName}${buildCalculation(colInfo.calculation)}`,
					...rows.map(row => row.data[colIdx]),
				],
			}))
			.slice(1);
		if (transformedColumns.length) {
			transformedColumns = [
				`${transformedColumns[0].displayName}${buildCalculation(transformedColumns[0].calculation)}`,
				...rows.map(row => row.data[0]),
			];
		}
	}
	const getHeaderCell = col => {
		const title = transpose ? col : `${col.displayName}${buildCalculation(col.calculation)}`;
		return <HeaderCell title={title} />;
	};
	return {
		rows: transformedRows,
		columns: transformedColumns.map((col, idx) => ({
			minWidth: 120,
			maxWidth: 180,
			Header: getHeaderCell(col),
			id: `${col.id}-${idx}`,
			accessor: rowData => rowData.data[idx],
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<TableCell column={!transpose ? col : idx ? columns[row.index + 1] : col} cellValue={row.value} />
			),
		})),
	};
};

async function fetchData(card, statementDate, transpose, setAllResults) {
	// eslint-disable-line
	try {
		const scenarioId = card.settings.scenarioId;
		const datasetId = card.datasetId;
		const data = await waterfallApi.getLatestLockedProjectionResults(scenarioId, statementDate, datasetId);
		if (data.length > 0) {
			setAllResults(data);
			const defaultView = data.find(v => v.tag === defaultResultView);
			return getTableColumnsAndRows(defaultView.response, transpose, 'waterfall');
		} else {
			setAllResults(data);
			return getTableColumnsAndRows({rows: [], columns: [], columnInfo: []});
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

function KiCardForecasting(props) {
	const {card} = props;
	// set up state
	const [data, setData] = useState({rows: [], columns: []});
	const [statementDate, setStatementDate] = useState(dateToShortDate(new Date()));
	const [reactTableLoading, setReactTableLoading] = useState(false);
	const [error, setError] = useState('');
	const [subEntities, setSubEntities] = useState([]);
	const [subEntity, setSubEntity] = useState('');
	const [allResults, setAllResults] = useState([]);
	const [selectedView, setSelectedView] = useState('');

	useEffect(
		() => {
			setError('');
			setReactTableLoading(true);
			fetchData(card, statementDate, _.get(card, 'settings.transpose', false), setAllResults)
				.then(result => {
					setData(result);
					setSelectedView(defaultResultView);
					setSubEntities([]);
					//setEntities(allResults.map)
				})
				.catch(err => setError(err.message || err))
				.finally(() => setReactTableLoading(false));
		},
		[statementDate]
	);

	const setEntityView = view => {
		const selected = allResults.find(r => r.tag === view);
		if (selected === null) {
			return;
		}
		const formatted = getTableColumnsAndRows(
			selected.response,
			_.get(card, 'settings.transpose', false),
			'waterfall'
		);
		setData(formatted);
		setSelectedView(view);

		if (selected.name) {
			// this is a view that has a subtype
			const newSubEntities = allResults.filter(r => r.tag === view).map(r => {
				return {value: r.name, name: r.name};
			});
			setSubEntities(newSubEntities);
			setSubEntity(newSubEntities.length > 0 ? newSubEntities[0] : '');
		} else {
			setSubEntities([]);
			setSubEntity('');
		}
	};

	const setSubEntityView = subEnt => {
		const selected = allResults.find(r => r.tag === selectedView && r.name === subEnt.value);
		if (selected === null) {
			return;
		}
		const formatted = getTableColumnsAndRows(
			selected.response,
			_.get(card, 'settings.transpose', false),
			'waterfall'
		);
		setData(formatted);
		setSubEntity(subEnt);
	};

	return (
		<div className="table-container" style={{overflow: 'auto'}}>
			<KiCardFilters
				subEntity={subEntity}
				subEntities={subEntities}
				entities={debtOutputOptions.filter(v => allResults.map(r => r.tag).includes(v.value))}
				entity={selectedView}
				setEntityView={setEntityView}
				statementDate={statementDate}
				setSubEntity={setSubEntityView}
				setStatementDate={setStatementDate}
			/>
			{!!error && <div className="dataCardError">{`Error fetching data: ${error}`}</div>}
			<AbsTable
				isSortEnabled={true}
				isFilterEnabled={false}
				data={data.rows}
				isLoading={reactTableLoading}
				columns={data.columns}
				isHeaderSticky={true}
				showNoDataMessage={!data.row || !!data.rows.length}
			/>
		</div>
	);
}

KiCardForecasting.propTypes = {
	card: PropTypes.object,
};

KiCardForecasting.defaultProps = {
	card: {
		settings: {
			statementDate: new Date(),
			fundingVehicleId: '',
			transpose: false,
		},
	},
};

export default KiCardForecasting;
