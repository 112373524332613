import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import {fetchDatasetList, setSelectedDataset} from '../datasetList/actions';
import {fetchAssociatedDataList} from '../associatedDataList/actions';
import styles from './AssociatedData.theme.scss';
import _get from 'lodash/get';
import KiModal from 'components/KiModal';
import {showSnackbar} from 'state/actions/Snackbar';
import {associatedDataApi} from 'api';
import {PaymentFileInformation, AppendInformation, AggregateInformation} from './components/InformationModals';
import UploadResults from './components/UploadResults';
import FileTypePicker from './components/FileTypePicker';
import UploadForm from './components/UploadForm';
import KiWizard from 'components/KiWizard';
import WizardStep from 'components/KiWizard/components/WizardStep';
import {apiUrl} from 'api/apiUtils';
import Schema from './components/Schema';
import {loadAllColumns} from './components/Schema/actions';
import {ABSUPLOADER_STATUSES} from '@moodysanalytics/cs-structured-ux-common';

const getDefaultValueForAggregate = dataType => {
	switch (dataType) {
		case 'numeric':
			return 'sum';
		default:
			return 'firstValid';
	}
};

export class AssociatedData extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		datasetList: PropTypes.object,
		dataset: PropTypes.object,
		fetchDatasetList: PropTypes.func.isRequired,
		fetchAssociatedDataList: PropTypes.func.isRequired,
		setSelectedDataset: PropTypes.func.isRequired,
		match: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		delimiter: PropTypes.string,
		showSnackbar: PropTypes.func.isRequired,
		associatedDataSchema: PropTypes.object,
		loadAllColumns: PropTypes.func,
	};

	static defaultProps = {
		datasetList: null,
		dataset: null,
		associatedDataSchema: {},
	};

	state = {
		delimiter: this.props.delimiter || 'comma',
		snapshotDate: '',
		associatedDataType: null,
		paymentFileInfoModalActive: false,
		fileName: null,
		fileUploadId: null,
		resultData: null,
		schemaColumns: [],
		schemaErrors: [],
		uploadStatus: null, //"submitting" | "submitted" | "uploading" | "upload finalizing" | "upload successful"
		isCommitting: false,
		refreshWarning: '',
		existingAssociatedDatas: [],
		currentStep: 1,
		showSchemaStep: false,
		showSpinner: false,
		appendMode: true,
		schema: '',
		aggregateToAsset: false,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Associated Data`;
		if (!_get(this.props.datasetList, 'data.length')) {
			this.props.fetchDatasetList().then(l => {
				this.props.setSelectedDataset(
					l.datasetList.find(d => d.datasetId === this.props.match.params.datasetId)
				);
				this.props.fetchAssociatedDataList(this.props.dataset.datasetId).then(result => {
					this.setState({existingAssociatedDatas: result.associatedDataList});
				});
			});
		} else {
			this.props.setSelectedDataset(
				this.props.datasetList.data.find(d => d.datasetId === this.props.match.params.datasetId)
			);
			this.props.fetchAssociatedDataList(this.props.dataset.datasetId).then(result => {
				this.setState({existingAssociatedDatas: result.associatedDataList});
			});
		}
	}

	handleCancel = () => {
		this.props.history.push(`/associatedDataList/${this.props.dataset.datasetId}`);
	};

	onStepNext = () => {
		this.setState({currentStep: this.state.currentStep + 1});
	};

	onStepCancel = () => {
		this.handleCancel();
	};

	onStepBack = () => {
		this.setState({currentStep: this.state.currentStep - 1});
	};

	commitButtonDisabled = () => {
		return this.state.uploadStatus !== ABSUPLOADER_STATUSES.UPLOAD_SUCCESSFUL || this.state.isCommitting;
	};

	getMappedColumnsFromProps = columnList => {
		const required = columnList.filter(c => c.mapType === 'required');
		return required.map(c => c.mapColumn);
	};

	handleCommitAppendData = () => {
		const {
			resultData,
			fileName,
			associatedDataType,
			snapshotDate,
			delimiter,
			fileUploadId,
			appendMode,
			schema,
		} = this.state;
		const data = {
			datasetId: this.props.datasetList.selected.datasetId,
			fileName: fileName,
			fileType: associatedDataType,
			snapshotDate: snapshotDate,
			assetsMatched: resultData.assetsMatched || resultData.totalRecords - resultData.unmatchedRecords.count,
			totalRecords: resultData.paymentRecords || resultData.totalRecords,
			unmatchedRecordsCount: resultData.unmatchedPayments
				? resultData.unmatchedPayments.count
				: resultData.unmatchedRecords.count,
			delimiter: delimiter,
			fileUploadId: fileUploadId,
			schemaColumns: this.props.associatedDataSchema.schemaColumns,
			subaccountId: schema._id,
			subaccountName: schema.name,
			appendMode: appendMode,
		};
		this.setState({isCommitting: true});
		this.toggleSpinner();
		return associatedDataApi
			.preCommitData(data)
			.then(returnValue => {
				associatedDataApi.commitAssociatedData(returnValue).then(() => {
					this.toggleSpinner();
					this.setState({isCommitting: false});
					this.props.history.push(`/associatedDataList/${this.props.datasetList.selected.datasetId}`);
				});
			})
			.catch(err => {
				this.toggleSpinner();
				this.setState({isCommitting: false});
				this.props.showSnackbar(err.message);
			});
	};

	handleGetResultSummaryAppendData = () => {
		const {fileName, associatedDataType, snapshotDate, delimiter, fileUploadId} = this.state;
		const data = {
			datasetId: this.props.datasetList.selected.datasetId,
			fileName: fileName,
			fileType: associatedDataType,
			snapshotDate: snapshotDate,
			delimiter: delimiter,
			fileUploadId: fileUploadId,
			schemaColumns: this.props.associatedDataSchema.schemaColumns,
		};
		this.setState({isCommitting: true});
		this.toggleSpinner();
		return associatedDataApi
			.resultSummaryAppendData(data)
			.then(res => {
				this.toggleSpinner();
				this.setState({isCommitting: false});
				this.setState({resultData: res});
				this.onStepNext();
			})
			.catch(err => {
				this.toggleSpinner();
				this.setState({isCommitting: false});
				this.props.showSnackbar(err.message);
			});
	};

	handleCommitPaymentData = () => {
		const {resultData, fileName, associatedDataType, snapshotDate, delimiter, fileUploadId} = this.state;
		const data = {
			datasetId: this.props.datasetList.selected.datasetId,
			fileName: fileName,
			fileType: associatedDataType,
			snapshotDate: snapshotDate,
			assetsMatched: resultData.assetsMatched || resultData.totalRecords - resultData.unmatchedRecords.count,
			totalRecords: resultData.paymentRecords || resultData.totalRecords,
			unmatchedRecordsCount: resultData.unmatchedPayments
				? resultData.unmatchedPayments.count
				: resultData.unmatchedRecords.count,
			delimiter: delimiter,
			fileUploadId: fileUploadId,
		};
		this.setState({isCommitting: true});
		this.toggleSpinner();
		return associatedDataApi
			.preCommitData(data)
			.then(returnValue => {
				associatedDataApi.commitAssociatedData(returnValue).then(() => {
					this.toggleSpinner();
					this.setState({isCommitting: false});
					this.props.history.push(`/associatedDataList/${this.props.datasetList.selected.datasetId}`);
				});
			})
			.catch(err => {
				this.toggleSpinner();
				this.setState({isCommitting: false});
				this.props.showSnackbar(err.message);
			});
	};

	handleSnapshotDateChange = snapshotDate => {
		this.setState({snapshotDate: snapshotDate}, () => this.handleWarningMessage());
	};

	handleDelimiterChange = delimiter => {
		this.setState({delimiter: delimiter});
	};

	handleAggregateToAssetChange = aggregateToAsset => {
		this.setState({
			aggregateToAsset: aggregateToAsset,
			associatedDataType: aggregateToAsset ? 'aggregate' : 'subaccount',
		});
	};

	handleSchemaChange = schema => {
		this.setState({schema: schema});
	};

	handleAppendModeChange = appendMode => {
		this.setState({appendMode: appendMode});
	};

	handleWarningMessage = () => {
		const {existingAssociatedDatas, associatedDataType, snapshotDate} = this.state;
		const alreadyExists = existingAssociatedDatas.find(
			s => s.snapshotDate === snapshotDate && s.fileType === associatedDataType
		);
		if (alreadyExists) {
			this.setState({refreshWarning: 'File was previously submitted proceeding will refresh your data'});
		} else {
			this.setState({refreshWarning: ''});
		}
	};

	handleFileTypeChange = associatedDataType => {
		switch (associatedDataType) {
			case 'appendData':
				this.setState(
					{
						associatedDataType: associatedDataType,
						showSchemaStep: true,
						requiredColumns: ['AssetID'],
					},
					() => this.handleWarningMessage()
				);
				break;
			case 'aggregate':
			case 'subaccount':
				// because aggregate and subaccount share the same form we disambiguate here
				this.setState(
					{
						associatedDataType: this.state.aggregateToAsset ? 'aggregate' : 'subaccount',
						showSchemaStep: true,
						requiredColumns: ['AssetID'],
					},
					() => this.handleWarningMessage()
				);
				break;
			default:
				this.setState(
					{
						associatedDataType: associatedDataType,
						showSchemaStep: false,
						requiredColumns: [],
					},
					() => this.handleWarningMessage()
				);
		}
	};

	// It's insufficient to rely on children.length because of conditional rendering.
	numberOfSteps = () => (this.state.showSchemaStep ? 4 : 3);

	disableFileTypeNextButton = () => {
		if (!this.state.associatedDataType) {
			return true;
		}
		return false;
	};

	disableUploadButton = () => {
		const isChildData =
			this.state.associatedDataType === 'aggregate' || this.state.associatedDataType === 'subaccount';
		const formIsValid =
			this.state.delimiter &&
			this.state.snapshotDate &&
			this.state.associatedDataType &&
			(isChildData ? this.state.aggregateToAsset || this.state.schema : true);
		return !(formIsValid && this.state.uploadStatus === ABSUPLOADER_STATUSES.SUBMITTED);
	};

	toggleSpinner = () => {
		this.setState({showSpinner: !this.state.showSpinner});
	};

	uploaderRef = React.createRef();
	uploader = {
		options: {
			validation: {
				allowedExtensions: ['csv', 'txt', 'tsv'],
			},
			chunkingEnabled: true,
			chunkSize: 1000000,
			endpoint: `${apiUrl}/associatedData/upload/${this.state.associatedDataType}`,
		},
		callbacks: {
			onSubmit: (id, file) => {
				this.setState({
					fileName: file.name,
				});
			},
			onStatusChange: (id, oldStatus, newStatus) => {
				this.setState({uploadStatus: newStatus});
			},
			// continue here
			onUploadChunkSuccess: (id, partIndex, totalParts, res) => {
				if (partIndex === 0) {
					// first chunk is done and can use the response
					if (this.state.showSchemaStep) {
						// BE sends over an unnecessary flag for new in the messages we dont need it
						res.schemaColumns.forEach(c => {
							if (c.messages) {
								c.messages.forEach((m, idx) => {
									if (m.code === 'newColumnFound') {
										c.messages.splice(idx, 1);
									}
								});
							}
							// BE doesn't give us aggregation info so add defaults here
							if (this.state.associatedDataType === 'aggregate' && !c.aggregation) {
								c.aggregation = getDefaultValueForAggregate(c.dataType);
							}
						});
						this.props.loadAllColumns(res.schemaColumns);
						this.setState({originalColumns: res.schemaColumns});
						this.setState({mappedColumns: this.getMappedColumnsFromProps(this.state.originalColumns)});
					} else {
						this.setState({resultData: res});
					}
				}
				if (partIndex === totalParts - 1) {
					// we are now done and can move on
					this.setState({fileUploadId: res.fileUploadId});
					this.setState({uploadStatus: ABSUPLOADER_STATUSES.UPLOAD_SUCCESSFUL});
					this.toggleSpinner();
					this.onStepNext();
				}
			},
			onError: (id, name, errorReason, response) => {
				const msg = response?.message || errorReason;
				this.props.showSnackbar(msg);
				this.toggleSpinner();
			},
		},
	};

	determineFileType = (associatedDataType, aggregateToAsset) => {
		// because aggregate and subaccount share the same form we disambiguate here
		if (associatedDataType !== 'aggregate') {
			return associatedDataType;
		} else {
			return aggregateToAsset ? 'aggregate' : 'subaccount';
		}
	};

	startUpload = () => {
		const associatedDataType = this.determineFileType(this.state.associatedDataType, this.state.aggregateToAsset);
		const params = {
			datasetId: this.props.dataset.datasetId,
			associatedDataType: associatedDataType,
			snapshotDate: this.state.snapshotDate,
			delimiter: this.state.delimiter,
			header: false, //todo add checkbox
		};
		switch (associatedDataType) {
			case 'aggregate':
			case 'subaccount':
				params.subaccountId = this.state.schema._id;
				break;
		}
		this.setState({associatedDataType: associatedDataType}, () => {
			this.uploader.params = params;
			this.uploaderRef.current.triggerUpload();
			this.toggleSpinner();
		});
	};

	getInformationModalHeader = () => {
		const associatedDataType = this.state.associatedDataType;
		let fileType;
		if (!associatedDataType) {
			return;
		}

		if (associatedDataType === 'paymentFile') {
			fileType = 'Payment';
		}

		if (associatedDataType === 'appendData') {
			fileType = 'Append';
		}

		if (associatedDataType === 'aggregate') {
			fileType = 'Aggregate';
		}
		return `Upload a ${fileType} File for use in Capital Markets`;
	};

	requiredColumnsAreMapped = () => {
		const mappedColumns = this.props.associatedDataSchema.schemaColumns.filter(c => c.mapColumn);
		return mappedColumns.length === this.state.requiredColumns.length;
	};

	render() {
		if (!this.props.dataset) return null;
		const {resultData, snapshotDate, fileName, showSpinner} = this.state;
		const {dataset} = this.props;

		return (
			<section className={styles.associatedData}>
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1 className="link" onClick={() => this.props.history.push('/datasets')}>{`Datasets`}</h1>
						<h1>{` > ${dataset.name || ''}`}</h1>
						<h1 className="link" onClick={this.handleCancel}>{` > Associated Data`}</h1>
						<h1>{` > Upload`}</h1>
					</div>
				</KiAppBar>
				<KiWizard
					currentStepNumber={this.state.currentStep}
					onNext={this.onNextHandler}
					onPrevious={this.onPreviousHandler}
					numberOfSteps={this.numberOfSteps()}
					showSpinner={showSpinner}
				>
					<WizardStep
						cancelButtonLabel={'Cancel'}
						nextButtonLabel={'Continue'}
						onCancelClick={this.onStepCancel}
						onNextClick={this.onStepNext}
						showBackButton={false}
						showCancelButton={true}
						showNextButton={true}
						title={'Choose a Data Type'}
						enableBackButton={false}
						disableNextButton={this.disableFileTypeNextButton()}
					>
						<FileTypePicker onChange={val => this.handleFileTypeChange(val)} />
					</WizardStep>
					<WizardStep
						cancelButtonLabel={'Cancel'}
						nextButtonLabel={'Upload'}
						onCancelClick={this.onStepCancel}
						onNextClick={this.startUpload}
						onBackClick={this.onStepBack}
						showBackButton={true}
						showCancelButton={true}
						showNextButton={true}
						title={'Upload'}
						enableBackButton={true}
						disableNextButton={this.disableUploadButton()}
					>
						<UploadForm
							onSnapshotChange={val => this.handleSnapshotDateChange(val)}
							onDelimiterChange={val => this.handleDelimiterChange(val)}
							onAggregateToAssetChange={val => this.handleAggregateToAssetChange(val)}
							onAppendModeChange={val => this.handleAppendModeChange(val)}
							onSchemaChange={val => this.handleSchemaChange(val)}
							uploader={this.uploader}
							uploaderRef={this.uploaderRef}
							dataset={dataset}
							refreshWarning={this.state.refreshWarning}
							delimiter={this.props.delimiter}
							associatedDataType={this.state.associatedDataType}
							toggleSpinner={this.toggleSpinner}
						/>
					</WizardStep>
					{this.state.showSchemaStep && (
						<WizardStep
							cancelButtonLabel={'Cancel'}
							nextButtonLabel={'Next'}
							onCancelClick={this.onStepCancel}
							onNextClick={this.handleGetResultSummaryAppendData}
							onBackClick={this.onStepBack}
							showBackButton={true}
							showCancelButton={true}
							showNextButton={true}
							title={'Schema'}
							enableBackButton={true}
							disableNextButton={!this.requiredColumnsAreMapped()}
						>
							<Schema
								originalColumnList={this.state.originalColumns}
								requiredColumns={this.state.requiredColumns}
								fileType={this.state.associatedDataType}
								fileUploadId={this.state.fileUploadId}
								showSnackbar={this.props.showSnackbar}
							/>
						</WizardStep>
					)}
					<WizardStep
						cancelButtonLabel={'Cancel'}
						nextButtonLabel={'Commit'}
						onCancelClick={this.onStepCancel}
						onNextClick={
							this.state.showSchemaStep ? this.handleCommitAppendData : this.handleCommitPaymentData
						}
						onBackClick={this.onStepBack}
						showBackButton={true}
						showCancelButton={true}
						showNextButton={true}
						title={'Review'}
						enableBackButton={true}
						disableNextButton={this.commitButtonDisabled()}
					>
						<UploadResults
							associatedDataType={this.state.associatedDataType}
							resultData={resultData}
							snapshotDate={snapshotDate}
							fileName={fileName}
						/>
					</WizardStep>
				</KiWizard>
				<KiModal
					actions={[{label: 'Ok', onClick: () => this.setState({paymentFileInfoModalActive: false})}]}
					active={this.state.paymentFileInfoModalActive}
					header={this.getInformationModalHeader()}
					className="ki-modal payment-file-info"
					onClose={() => this.setState({paymentFileInfoModalActive: false})}
				>
					{this.state.associatedDataType === 'paymentFile' && <PaymentFileInformation />}
					{this.state.associatedDataType === 'appendData' && <AppendInformation />}
					{this.state.associatedDataType === 'aggregate' && <AggregateInformation />}
				</KiModal>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		app: state.app,
		datasetList: state.datasetList,
		dataset: state.datasetList.selected,
		user: state.user,
		associatedDataSchema: state.associatedDataSchema,
	};
};

export default connect(
	mapStateToProps,
	{
		fetchDatasetList,
		setSelectedDataset,
		showSnackbar,
		fetchAssociatedDataList,
		loadAllColumns,
	}
)(AssociatedData);
