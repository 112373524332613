// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import debtUtils from 'ki-common/utils/debtUtils';
import systemColumns from 'ki-common/options/systemColumns';
import _ from 'lodash';

// Project imports
import {columnServiceApi} from 'api';
import KiSelect from 'components/KiSelect';

// Local imports
import styles from './ColumnSelection.theme.scss';
import ColumnSelectionItem from './ColumnSelectionItem';

const SYSTEM_DATE_NAME_MAP = new Map([
	['kiCollectionEndDate', 'Collection Period End Date'],
	['kiReportDate', 'Report Date'],
	['kiDistributionDate', 'Funding Distribution Date'],
	['kiDeterminationDate', 'Funding Determination Date'],
	['lastCutoffDate', 'Latest Cutoff Date'],
]);

function ColumnSelection({bookmarkColumns, viewType, onChange, datasetId, settings}) {
	const [columns, setColumns] = useState([]);
	const [columnsLoading, setColumnsLoading] = useState(true);

	// Fetch columns based on viewType
	useEffect(
		() => {
			async function fetchData() {
				setColumnsLoading(true);
				let params = {};
				switch (viewType) {
					case 'trust':
						params = {
							sources: {
								includeDateColumns: true,
								includePoolAggregateColumns: true,
							},
							options: {
								convertAssetDateColumnsToFVDate: true,
								debtInputsNoChildColumns: true,
							},
							filters: {},
						};
						break;
					// case 'counterparty':
					// 	params = {
					// 		sources: {
					// 			includeFundingVehicle: true,
					// 			includeAbsRatingColumns: true,
					// 			includeCounterpartySetupColumns: true,
					// 		},
					// 		options: {
					// 			convertAssetDateColumnsToFVDate: true,
					// 			debtInputsNoChildColumns: true,
					// 		},
					// 		filters: {},
					// 	};
					// 	break;
					default:
						params = debtUtils.getColServiceParamsForView(viewType, {
							isActivity: settings.viewType === 'activity',
						});
						params.options.debtInputsNoChildColumns = true;
						break;
				}
				const res = await columnServiceApi.getColumnsFromService(datasetId, params);
				const filteredCols = res.filter(col => {
					if (col.availableForDebt === false) return false;
					if (settings.viewType !== 'activity') return true;
					switch (viewType) {
						case 'fundingVehicle':
							return col.columnType === 'cohort' ? col.displayName === 'Funding Vehicle Name' : true;
						case 'debt':
							return col.columnType === 'trancheSetup' ? col.columnName === 'name' : true;
						case 'creditSupport':
							return col.columnType === 'creditSupportSetup' ? col.columnName === 'name' : true;
						case 'fee':
							return col.columnType === 'feeSetup' ? col.columnName === 'name' : true;
						case 'trigger':
							return col.columnType === 'triggerSetup' ? col.columnName === 'name' : true;
						default:
							return true;
					}
				});
				const sortedColumns = _.sortBy(filteredCols, col =>
					_.lowerCase(
						col.htmlDisplayName
							? col.htmlDisplayName.replace(/<\/?[bi]>/g, '')
							: col.detailedDisplayName || col.displayName
					)
				);
				setColumnsLoading(false);
				setColumns(sortedColumns);
			}

			if (viewType === 'waterfall') {
				const sortedColumns = _.sortBy(systemColumns.defaultWaterfallColumns, col => {
					col.htmlDisplayName = col.displayName;
				});

				setColumns(sortedColumns);
				setColumnsLoading(false);
			} else {
				fetchData();
			}
		},
		[viewType, settings]
	);

	const handlePickerChange = selectedColumn => {
		onChange([
			...bookmarkColumns,
			{
				...selectedColumn,
				displayName: _.get(selectedColumn, 'detailedDisplayName', selectedColumn.displayName),
			},
		]);
	};

	const handleDeleteColumn = idx => {
		onChange(bookmarkColumns.filter((col, stateIdx) => stateIdx !== idx));
	};

	const handleColumnChange = (idx, key, val) => {
		const changedColumns = bookmarkColumns.reduce((acc, col, existingIdx) => {
			if (idx === existingIdx) {
				return [
					...acc,
					{
						...col,
						[key]: val,
					},
				];
			}
			return [...acc, col];
		}, []);
		onChange(changedColumns);
	};

	const handleMoveUp = index => {
		if (index > 1) {
			const changedColumns = bookmarkColumns.slice();
			changedColumns.splice(index - 1, 2, bookmarkColumns[index], bookmarkColumns[index - 1]);
			onChange(changedColumns);
		}
	};

	const handleMoveDown = index => {
		if (index < bookmarkColumns.length - 1) {
			const changedColumns = bookmarkColumns.slice();
			changedColumns.splice(index, 2, bookmarkColumns[index + 1], bookmarkColumns[index]);
			onChange(changedColumns);
		}
	};

	const getColumnNameForRendering = col => {
		const assetColumn = col.assetColumn || {};
		const columnName = col.columnName || assetColumn.columnName || '';
		if (col.dataType === 'date_long') {
			const dsDateMapId = col.dsDateMapId || assetColumn.dsDateMapId;
			const columnNameIsSystemColumn =
				columnName.search(/^ki(Report|CollectionEnd|Distribution|Determination)Date$/) === 0;
			if (columnNameIsSystemColumn) {
				return `${SYSTEM_DATE_NAME_MAP.get(columnName)} (System)`;
			} else if (dsDateMapId || col.columnType === 'fvDate') {
				return col.displayName;
			}
		}
		return columnName;
	};

	const createMarkup = htmlString => {
		return {__html: htmlString};
	};

	const isActivityView = settings.viewType === 'activity';
	return (
		<div className={styles.root}>
			<header className={styles.header}>
				<div className={'input-row'}>
					<div className="theme-label">Column/Object</div>
					<KiSelect
						isLoading={columnsLoading}
						formatOptionLabel={option => (
							<span dangerouslySetInnerHTML={createMarkup(option.htmlDisplayName)} />
						)}
						getOptionLabel={option =>
							option.htmlDisplayName
								? option.htmlDisplayName.replace(/<\/?[bi]>/g, '')
								: option.detailedDisplayName || option.displayName
						}
						getOptionValue={option => option._id || option.columnName}
						controlShouldRenderValue={false}
						hideSelectedOptions={isActivityView}
						value={isActivityView ? bookmarkColumns : null}
						options={columns}
						onChange={handlePickerChange}
					/>
				</div>
			</header>
			<section className={styles.columnList}>
				{bookmarkColumns.map((vc, idx) => (
					<ColumnSelectionItem
						index={idx}
						isLast={idx === bookmarkColumns.length - 1}
						displayName={vc.displayName}
						entity={vc.entity}
						aggregateLevel={vc.aggregateLevel}
						columnName={getColumnNameForRendering(vc)}
						displayFormat={vc.displayFormat}
						dataType={vc.dataType}
						calculation={vc.calculation}
						key={`${vc._id || vc.columnName}-${idx}`}
						moveUp={() => handleMoveUp(idx)}
						moveDown={() => handleMoveDown(idx)}
						handleDelete={() => handleDeleteColumn(idx)}
						handleChange={(key, val) => handleColumnChange(idx, key, val)}
					/>
				))}
			</section>
		</div>
	);
}

ColumnSelection.propTypes = {
	bookmarkColumns: PropTypes.array.isRequired,
	viewType: PropTypes.oneOf([
		'trust',
		'fundingVehicle',
		'pool',
		'debt',
		'creditSupport',
		'trigger',
		'fee',
		'waterfall',
	]).isRequired,
	onChange: PropTypes.func.isRequired,
	datasetId: PropTypes.string.isRequired,
	settings: PropTypes.object,
};

ColumnSelection.defaultProps = {
	settings: {},
	bookmarkColumns: [],
};

export default ColumnSelection;
