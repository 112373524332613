import React from 'react';
import PropTypes from 'prop-types';
import FormBlockItemContainer from '../../common/FormBlockItemContainer';
import KiIconButton from 'components/KiIconButton';
import InterestRateAssumption from './InterestRateAssumption';
const InterestRate = props => {
	const addRateSelection = () =>
		props.onChange({
			rateAssumptions: [
				{
					indexName: props.indexList?.[0]?.value ?? null,
					rate: null,
					vectorId: null,
				},
				...props.rateAssumptions,
			],
		});
	const updateRateSelections = (index, changes) => {
		props.onChange({
			rateAssumptions: props.rateAssumptions.reduce((acc, rateAssumption, idx) => {
				if (idx === index) {
					return [...acc, {...rateAssumption, ...changes}];
				}
				return [...acc, rateAssumption];
			}, []),
		});
	};
	const removeRateSelection = indexToDelete =>
		props.onChange(assumption => ({
			rateAssumptions: assumption.rateAssumptions.filter((rateAssumption, idx) => idx !== indexToDelete),
		}));
	const handleDelete = () => props.onChange({interestRateState: false, rateAssumptions: []});
	return (
		<FormBlockItemContainer
			isVisible={props.isVisible}
			readOnly={props.readOnly}
			onDelete={handleDelete}
			title={
				<div style={{display: 'flex', alignItems: 'center'}}>
					<div>Interest Rates</div>
					<KiIconButton
						key="add_rate"
						icon="add_circle"
						inverse
						hidden={props.readOnly}
						disabled={props.readOnly}
						onClick={() => addRateSelection()}
					/>
				</div>
			}
		>
			{props.rateAssumptions.map((opt, r) => {
				return (
					<InterestRateAssumption
						key={`ira_${r}`}
						vectorIndex={r}
						vectorList={props.vectors.map(v => ({label: v.name, value: v._id}))}
						indexList={props.indexList}
						irMapping={opt}
						readOnly={props.readOnly}
						updateRateSelections={updateRateSelections}
						removeRateSelection={removeRateSelection}
					/>
				);
			})}
		</FormBlockItemContainer>
	);
};

InterestRate.propTypes = {
	isVisible: PropTypes.bool,
	readOnly: PropTypes.bool,
	rateAssumptions: PropTypes.array,
	vectors: PropTypes.array.isRequired,
	indexList: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

InterestRate.defaultProps = {
	isVisible: false,
	readOnly: false,
	indexList: [],
	rateAssumptions: [],
	vectors: [],
};

export default InterestRate;
