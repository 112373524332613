import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchBorrowingBaseConfig = (datasetId, fundingVehicleId) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/fundingVehicles/${fundingVehicleId}/borrowingBase/config`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const createBorrowingBaseConfig = (datasetId, fundingVehicleId, borrowingBaseConfig) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/fundingVehicles/${fundingVehicleId}/borrowingBase/config`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(borrowingBaseConfig),
	}).then(_handleResponse);
};

export const updateBorrowingBaseConfig = (datasetId, fundingVehicleId, borrowingBaseConfig) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/fundingVehicles/${fundingVehicleId}/borrowingBase/config`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(borrowingBaseConfig),
	}).then(_handleResponse);
};
