import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
//import KiButton from '../../../components/KiButton';
import KiConfirmModal from '../../../components/KiConfirmModal';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import KiIconButton from '../../../components/KiIconButton';
import {apiUtils} from 'api';
import _ from 'lodash';
const apiUrl = apiUtils.apiUrl;

import {fetchScenarios} from '../../../api/fundingAnalysisApi';
import datasetHiddenSnapshotsApi from '../../../api/datasetHiddenSnapshotsApi';

const encumbranceRollbackForm = props => {
	const [dataset, setDataset] = useState({});
	const [isRollbackConfirmActive, setIsRollbackConfirmActive] = useState(false);
	const [checkpoints, setCheckpoints] = useState([]);
	const [unrolledScenarioDeps, setUnrolledScenarioDeps] = useState({});

	/*
	const buttonDisabled = () => {
		return dataset.datasetId ? false : true;
	};
*/
	const selectDataset = async val => {
		setDataset(val);
		const cp = await fetchScenarios(val.datasetId, true);
		setCheckpoints(cp.filter(p => p.status == 'closed'));
	};

	const _findEarliestScenario = () => {
		const sorted = _.sortBy(checkpoints, 'transferDate');
		if (sorted.length > 0) {
			return sorted[0];
		} else {
			return null;
		}
	};

	const selectUnrolledScenario = async row => {
		const deps = await datasetHiddenSnapshotsApi.previewDependencies(dataset.datasetId, row?._id);
		setUnrolledScenarioDeps(Object.assign({...deps}, {scenarioId: row?._id, transferDate: row?.transferDate}));
		setIsRollbackConfirmActive(true);
	};

	const {
		scenarioName,
		latestActiveSnapshot,
		deletedScenarios,
		affectedFvs,
		scenarioId,
		transferDate,
		numRunningJobs,
	} = unrolledScenarioDeps;

	const executeRollBack = async () => {
		setIsRollbackConfirmActive(false);

		let earliestScenario;
		if (!scenarioId) {
			earliestScenario = _findEarliestScenario();
		}
		const selectedScenario = scenarioId || earliestScenario._id;
		const selectedTransferDt = transferDate || earliestScenario.transferDate;
		if (selectedScenario) {
			try {
				const results = await datasetHiddenSnapshotsApi.rollbackScenario(
					dataset.datasetId,
					selectedScenario,
					selectedTransferDt
				);
				const msg = scenarioId
					? `Rollback of scenario "${scenarioName}" completed`
					: `Rollback of dataset "${dataset.name}" completed`;
				if (results?.SnapshotHides?.requiresCamundaJob) {
					props.showSnackbar(`Rollback started. Please check log for details`);
				} else {
					props.showSnackbar(msg);
				}
				const cp = await fetchScenarios(dataset.datasetId, true);
				setCheckpoints(cp);
			} catch (error) {
				props.showSnackbar(`There was an issue rolling back`);
			}
		} else {
			props.showSnackbar(`There was nothing to undo`);
		}
	};

	const downloadLink = scenarioId => {
		return `${apiUrl}/datasetHiddenSnapshots/extract/${dataset.datasetId}/${scenarioId}`;
	};

	const getRollbackConfirmMessage = () => {
		const message =
			numRunningJobs > 0
				? 'The rollback cannot be processed because there are running jobs'
				: scenarioName == ''
					? `Are you sure you want to initialize dataset "${dataset.name}"?  This is irreversible.`
					: `Are you sure you want to rollback scenario "${scenarioName}"?  This is irreversible.`;
		return (
			<div>
				<div style={{marginBottom: '1em'}}>{message}</div>
				<p />
				{numRunningJobs == 0 && (
					<>
						<div className="confirmationMessageItem">
							- The latest active snapshot will be {latestActiveSnapshot}. Later snapshots are available
							for reprocessing on the &quot;Snapshot Reprocess&quot; tab. Note that the administration
							module must be updated to reflect these changes.
						</div>
						{Array.isArray(deletedScenarios) &&
							deletedScenarios.length > 0 && (
								<div className="confirmationMessageItem">
									- The following scenarios will be rolled back: {deletedScenarios.join(', ')}
								</div>
							)}
						{Array.isArray(affectedFvs) &&
							affectedFvs.length > 0 && (
								<div className="confirmationMessageItem">
									- The following funding vehicles will be affected by the rollback:{' '}
									{affectedFvs.join(', ')}
								</div>
							)}
					</>
				)}
			</div>
		);
	};

	return (
		<div>
			<KiConfirmModal
				header="Rollback Scenario"
				message={getRollbackConfirmMessage()}
				acceptDisabled={numRunningJobs > 0}
				acceptFunc={() => executeRollBack()}
				rejectFunc={() => setIsRollbackConfirmActive(false)}
				acceptLabel="Rollback"
				rejectLabel="Cancel"
				active={isRollbackConfirmActive}
			/>
			<div className={'datasetCopyForm'}>
				<div className="dataset-selector-group">
					<span className="form-instruction">Choose Dataset:</span>
					<div className="snapshotTileArea">
						<Select
							classNamePrefix="aut-select"
							className="datasetSelectorUndoForm"
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							isClearable={false}
							options={props.datasets}
							value={props.datasets.filter(x => dataset?.datasetId === x.datasetId)}
							onChange={val => {
								selectDataset(val);
							}}
						/>
						{dataset?.datasetId && (
							<div className="rollbackAllMessage">
								<span>Undo All Encumbrance:</span>
								<KiIconButton
									icon={'undo'}
									onClick={() => selectUnrolledScenario()}
									disabled={checkpoints.length == 0}
								/>
								<KiIconButton
									icon={'cloud_download'}
									href={downloadLink('fullUndo')}
									disabled={checkpoints.length == 0}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={'checkpointsTable'}>
				<AbsTable
					data={checkpoints}
					columns={[
						{
							Header: 'Scenario Name',
							accessor: 'name',
						},
						{
							Header: 'Snapshot Date',
							accessor: 'snapshotDate',
						},
						{
							Header: 'Transfer Date',
							accessor: 'transferDate',
						},
						{
							Header: 'Funding Vehicles Included',
							accessor: d => d.fvSettings?.map?.(item => item.fvName).join(', '),
						},
						{
							id: 'actions',
							width: 75,
							displayName: 'actions',
							/* eslint-disable react/display-name, react/prop-types */
							Cell: ({row}) => (
								<div className={'actionsColumn'}>
									<KiIconButton icon={'undo'} onClick={() => selectUnrolledScenario(row.original)} />
									<KiIconButton icon={'cloud_download'} href={downloadLink(row.original._id)} />
								</div>
							),
						},
					]}
				/>
			</div>
		</div>
	);
};

encumbranceRollbackForm.propTypes = {
	datasets: PropTypes.array.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

encumbranceRollbackForm.defaultProps = {
	datasets: [],
};

export default encumbranceRollbackForm;
