// Globals
import React from 'react';

// Project imports

// Action imports

// Local imports
import FundingAnalysisProvider from './fundingAnalysisProvider';
import {BookmarkProvider} from './fundingAnalysisBookmark';
import FundingPageLayout from './FundingPageLayout';

function FundingAnalysis() {
	// Wrap the FundingPageLayout with the context
	return (
		<div className="container-wrapper">
			<FundingAnalysisProvider>
				<BookmarkProvider>
					<FundingPageLayout />
				</BookmarkProvider>
			</FundingAnalysisProvider>
		</div>
	);
}

export default FundingAnalysis;
