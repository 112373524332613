import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import KiConfirmModal from '../../../../components/KiConfirmModal';
import {getPoolsForFundingVehicle} from '../../../../api/fundingVehiclesApi';
import styles from './DeletionModal.theme.scss';

const DeletionModal = ({fv, onCancel, onDeleteConfirmed}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [relatedItems, setRelatedItems] = useState([]);

	useEffect(
		() => {
			if (fv) {
				setIsLoading(true);
				getPoolsForFundingVehicle(fv.datasetId, fv._id).then(result => {
					setRelatedItems(result);
					setIsLoading(false);
				});
			}
		},
		[fv]
	);

	const message =
		relatedItems.length > 0 ? (
			`Assets/Pools are currently assigned to ${
				fv?.name
			}, please execute a Drawdown in Full to remove assets before deleting the Funding Vehicle.`
		) : (
			<div>
				<p>{`Are you sure you want to delete the following funding vehicle?`}</p>
				<p className={styles.fvName}>{fv?.name}</p>
				<p className={styles.firstNote}>
					Note: Any Reports using the above Funding Vehicle(s) will be affected by this change.
				</p>
				<p>Note: Any Predefined Cards associated with the above Funding Vehicle(s) will be deleted.</p>
			</div>
		);

	return (
		<KiConfirmModal
			header="Delete Funding Vehicle"
			message={isLoading ? 'loading...' : message}
			acceptFunc={onDeleteConfirmed}
			rejectFunc={onCancel}
			acceptLabel="Delete"
			rejectLabel="Cancel"
			active={!!fv}
			acceptDisabled={isLoading || relatedItems.length > 0}
		/>
	);
};

DeletionModal.propTypes = {
	fv: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
	onDeleteConfirmed: PropTypes.func.isRequired,
};

export default DeletionModal;
