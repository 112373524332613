const FundingVehicleStatus = require('../enums').FundingVehicleStatus;
const _get = require('lodash/get');
const _sortBy = require('lodash/sortBy');

// Summary/Derived info
const getFundingVehicleStatusEnum = fundingVehicle => {
	if (!fundingVehicle) {
		return '';
	}
	const systemDate = Date.now();
	const inactiveDate = Date.parse(fundingVehicle.inactiveDate);
	const noticeStartDate = Date.parse(_get(fundingVehicle, 'noticeRecurrence.startDate'));
	const noticeEndDate = Date.parse(_get(fundingVehicle, 'noticeRecurrence.endDate'));

	if (inactiveDate && systemDate >= inactiveDate) {
		return FundingVehicleStatus.inactive;
	}
	if (noticeStartDate && noticeEndDate && (systemDate > noticeStartDate && systemDate < noticeEndDate)) {
		return FundingVehicleStatus.notice;
	}
	if ((inactiveDate && systemDate < inactiveDate) || !inactiveDate) {
		return FundingVehicleStatus.active;
	}
};

const getFundingVehicleStatusStr = fundingVehicle => getFundingVehicleStatusEnum(fundingVehicle).toString();

const defaultFundingVehicleCards = [
	{
		name: 'Borrowing Base Summary',
		type: 'borrowingBase',
	},
	{
		name: 'Assets in Breach',
		type: 'assetsInBreach',
	},
	{
		name: 'Aggregate Tests',
		type: 'aggregateTests',
	},
	{
		name: 'Excess Concentration',
		type: 'excessConcentration',
	},
	{
		name: 'Breaches',
		type: 'breaches',
	},
	{
		name: 'Compliance',
		type: 'compliance',
	},
];

// rowTypeDisplayNameMap.get(rowType) returns value or undefined
const rowTypeDisplayNameMap = new Map([
	['ccBalanceLimit', 'Balance Limit'],
	['ccConcentration', 'Concentration'],
	['ccWeightedAvg', 'Weighted Average'],
	['ccExclude', 'Exclusion'],
	['ccConcentrationLimit', 'Concentration Limit'],
]);

// In this case all the 'debt' entries are actually 'tranche'
const splitFundingVehicleSettingsByType = fullList => {
	const calculations = fullList.filter(s => s.rowType === 'calculation') || [];
	const legal = fullList.filter(s => s.rowType === 'legal') || [];
	const debt = fullList.filter(s => s.rowType === 'debt') || [];
	const fees = fullList.filter(s => s.rowType === 'fee') || [];
	const creditSupports = fullList.filter(s => s.rowType === 'creditSupport') || [];
	const counterparties = fullList.filter(s => s.rowType === 'counterparty') || [];
	const constraints = fullList.filter(s => new RegExp('^cc', 'g').test(s.rowType)) || [];
	return {
		calculations: _sortBy(calculations, [calculations, constraint => constraint.name.toLowerCase()]),
		legal: _sortBy(legal, [legal => legal.name.toLowerCase()]),
		debt: _sortBy(debt, [debt => debt.name.toLowerCase()]),
		tranches: _sortBy(debt, [debt => debt.name.toLowerCase()]),
		fees: _sortBy(fees, [fee => fee.name.toLowerCase()]),
		creditSupports: _sortBy(creditSupports, [cs => cs.name.toLowerCase()]),
		//counterparties: _sortBy(counterparties, [cp => cp.legalName.toLowerCase()]),
		counterparties,
		constraints,
	};
};

module.exports = {
	getFundingVehicleStatusStr,
	defaultFundingVehicleCards,
	rowTypeDisplayNameMap,
	splitFundingVehicleSettingsByType,
};
