import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import KiIconButton from 'components/KiIconButton';

import './ExplorerBreadcrumbs.scss';

export const Breadcrumb = props => {
	if (props.data.type == 'asset') {
		return (
			<section className="breadcrumb">
				<p>All Assets</p>
				<KiIconButton icon="cancel" onClick={props.onDelete.bind(this, props.data)} />
			</section>
		);
	} else if (props.data.type == 'timeSeries') {
		return (
			<section className="breadcrumb">
				<p>Time Series</p>
				<KiIconButton icon="cancel" onClick={props.onDelete.bind(this, props.data)} />
			</section>
		);
	} else {
		return (
			<section className="breadcrumb">
				<Select
					classNamePrefix="aut-select"
					name="form-field-name"
					id={props.data.id}
					value={props.data.options.find(opt => opt.value === props.data.currValue)}
					isClearable={false}
					options={props.data.options}
					onChange={props.onChange}
					isDisabled={props.disabled}
				/>
				<KiIconButton icon="cancel" onClick={props.onDelete.bind(this, props.data)} />
			</section>
		);
	}
};

Breadcrumb.propTypes = {
	type: PropTypes.string,
	data: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export const ExplorerBreadcrumbs = props => {
	const {data = [], onChange, onDelete} = props;
	return (
		<section className="breadcrumb-list">
			{data.map(breadcrumb => (
				<Breadcrumb
					key={breadcrumb.id}
					data={breadcrumb}
					onChange={onChange}
					onDelete={onDelete}
					disabled={props.disabled}
				/>
			))}
		</section>
	);
};

ExplorerBreadcrumbs.propTypes = {
	data: PropTypes.array.isRequired,
	onDelete: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default ExplorerBreadcrumbs;
