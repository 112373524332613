const _get = require('lodash/get');
const _every = require('lodash/every');
const _isEmpty = require('lodash/isEmpty');

const isVisibleDefault = () => {
	return true;
};

const isRequiredDefault = (variableName, bfCol) => {
	// eslint-disable-next-line
	const bfDefinition = businessFunctionsMap.get(bfCol.formula.op);
	return bfDefinition.requiredVariables.includes(variableName);
};

const businessVariableList = [
	{
		name: 'totalPrepayments',
		displayName: 'Total Prepayments',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('totalPrepayments', bfCol),
	},
	{
		name: 'beginningBalance',
		displayName: 'Beginning Balance',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('beginningBalance', bfCol),
	},
	{
		name: 'scheduledPrincipal',
		displayName: 'Scheduled Principal',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('scheduledPrincipal', bfCol),
	},
	{
		name: 'loanAge',
		displayName: 'Loan Age',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('loanAge', bfCol),
	},
	{
		name: 'paymentAmount',
		displayName: 'Payment Amount',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('paymentAmount', bfCol);
		},
	},
	{
		name: 'apr',
		displayName: 'APR',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('apr', bfCol),
	},
	{
		name: 'remainingTerm',
		displayName: 'Remaining Term',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('remainingTerm', bfCol);
		},
	},
	{
		name: 'oddLastPayment',
		displayName: 'Odd Last Payment',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('oddLastPayment', bfCol);
		},
	},
	{
		name: 'oddFirstPayment',
		displayName: 'Odd First Payment',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('oddFirstPayment', bfCol);
		},
	},
	{
		name: 'interestAccrual',
		displayName: 'Interest Accrual',
		type: 'option',
		options: [
			{label: '30/360', value: '30/360'},
			{label: '30/365', value: '30/365'},
			{label: 'Actual/360', value: 'Actual/360'},
			{label: 'Actual/365', value: 'Actual/365'},
			{label: 'Actual/Actual', value: 'Actual/Actual'},
		],
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('interestAccrual', bfCol),
	},
	{
		name: 'firstPaymentDelay',
		displayName: 'First Payment Delay',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb') {
				const interestAccrual = _get(bfCol, 'formula.left.value.interestAccrual.value', 'Not Found');
				return interestAccrual.includes('Actual') ? true : false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb') {
				return false;
			}
			return isRequiredDefault('firstPaymentDelay', bfCol);
		},
	},
	{
		name: 'lastPaymentDelay',
		displayName: 'Last Payment Delay',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb') {
				const interestAccrual = _get(bfCol, 'formula.left.value.interestAccrual.value', 'Not Found');
				return interestAccrual.includes('Actual') ? true : false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb') {
				return false;
			}
			return isRequiredDefault('lastPaymentDelay', bfCol);
		},
	},
	{
		name: 'delinquentAmount',
		displayName: 'Delinquency Amount',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('delinquentAmount', bfCol);
		},
	},
	{
		name: 'delinquencyBalanceTreatment',
		displayName: 'Delinquency Balance Treatment',
		type: 'option',
		options: [{label: 'Next', value: 'begOfTerm'}, {label: 'Last', value: 'endOfTerm'}],
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('delinquencyBalanceTreatment', bfCol);
		},
	},
	{
		name: 'payaheadAmount',
		displayName: 'Payahead Amount',
		type: 'numeric',
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('payaheadAmount', bfCol);
		},
	},
	{
		name: 'payaheadBalanceTreatment',
		displayName: 'Payahead Balance Treatment',
		type: 'option',
		options: [{label: 'Next', value: 'begOfTerm'}, {label: 'Last', value: 'endOfTerm'}],
		isVisible: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return true;
		},
		isRequired: bfCol => {
			if (_get(bfCol, 'formula.op', '') === 'bf_dpb' && _get(bfCol, 'formula.meta.hasPaymentFile', false) === true) {
				return false;
			}
			return isRequiredDefault('payaheadBalanceTreatment', bfCol);
		},
	},
	/*{
		name: 'loanAmount',
		displayName: 'Loan Amount',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('loanAmount', bfCol),
	},
	{
		name: 'numberOfPayments',
		displayName: 'Number of Payments',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('numberOfPayments', bfCol),
	},
	{
		name: 'compoundingPeriodsAnnum',
		displayName: 'Compounding Periods per Annum',
		type: 'numeric',
		isVisible: isVisibleDefault,
		isRequired: bfCol => isRequiredDefault('compoundingPeriodsAnnum', bfCol),
	}*/
];

const isReadyForUseDefault = bfCol => {
	const mappings = _get(bfCol, 'formula.left.value', {});
	return _every(mappings, (entry, key) => {
		// eslint-disable-next-line
		const isRequired = businessVariablesMap.get(key).isRequired(bfCol);
		if (isRequired) {
			return !_isEmpty(entry.value);
		} else {
			return true; // Not required
		}
	});
};

const businessFunctionList = [
	{
		opType: 'bf_smm',
		abbreviation: 'SMM',
		displayName: 'Single Month Mortality',
		type: 'numeric',
		imageName: 'smm',
		requiredVariables: ['totalPrepayments', 'beginningBalance', 'scheduledPrincipal'],
		optionalVariables: [],
		isReadyForUse: isReadyForUseDefault,
	},
	{
		opType: 'bf_cpr',
		abbreviation: 'CPR',
		displayName: 'Constant Prepayment Rate',
		type: 'numeric',
		imageName: 'cpr',
		requiredVariables: ['totalPrepayments', 'beginningBalance', 'scheduledPrincipal'],
		optionalVariables: [],
		isReadyForUse: isReadyForUseDefault,
	},
	{
		opType: 'bf_abs',
		abbreviation: 'ABS',
		displayName: 'Absolute Prepayment Speed',
		type: 'numeric',
		imageName: 'abs',
		requiredVariables: ['totalPrepayments', 'beginningBalance', 'scheduledPrincipal', 'loanAge'],
		optionalVariables: [],
		isReadyForUse: isReadyForUseDefault,
	},
	{
		opType: 'bf_dpb',
		abbreviation: 'DPB',
		displayName: 'Discounted Principal Balance',
		type: 'numeric',
		imageName: 'dpb',
		requiredVariables: ['paymentAmount', 'remainingTerm', 'interestAccrual'],
		optionalVariables: [
			'oddLastPayment',
			'oddFirstPayment',
			'apr',
			'firstPaymentDelay',
			'lastPaymentDelay',
			'delinquentAmount',
			'delinquencyBalanceTreatment',
			'payaheadAmount',
			'payaheadBalanceTreatment',
		],
		isReadyForUse: isReadyForUseDefault,
	},
	/*{
		opType: 'bf_pymt',
		abbreviation: 'PYMT',
		displayName: 'Payment',
		type: 'numeric',
		imageName: 'pymt',
		requiredVariables: ['loanAmount', 'apr', 'numberOfPayments', 'compoundingPeriodsAnnum'],
		optionalVariables: [],
		isReadyForUse: isReadyForUseDefault,
	},
	{
		opType: 'bf_anrt',
		abbreviation: 'ANRT',
		displayName: 'Annualized Rate',
		type: 'numeric',
		imageName: 'anrt',
		requiredVariables: ['loanAmount', 'paymentAmount', 'numberOfPayments'],
		optionalVariables: [],
		isReadyForUse: isReadyForUseDefault,
	}*/
];

const businessVariablesMap = new Map(businessVariableList.map(entry => [entry.name, entry]));
const businessFunctionsMap = new Map(businessFunctionList.map(entry => [entry.opType, entry]));

module.exports = {
	businessVariableList,
	businessVariablesMap,
	businessFunctionList,
	businessFunctionsMap,
};
