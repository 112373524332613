import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import AppRouter from './AppRouter';
import ReactGA from 'react-ga';
import './index.scss';

import createMainStore from './state/store';

createMainStore().then(store => {
	ReactGA.initialize(window.GA_TRACKING_CODE);
	ReactDOM.render(
		<Provider store={store}>
			<AppRouter />
		</Provider>,
		document.getElementById('root')
	);
});
