import PropTypes from 'prop-types';
import React, {Component} from 'react';
import './CollapsiblePanel.scss';

class CollapsiblePanel extends Component {
	static propTypes = {
		children: PropTypes.node,
		header: PropTypes.node,
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		className: PropTypes.string,
		collapsed: PropTypes.bool,
		onCollapseToggleClick: PropTypes.func,
	};

	state = {
		collapsed: false,
	};

	toggleCollapsed = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	render() {
		const {
			header,
			className = '',
			children,
			title = '',
			onCollapseToggleClick = this.toggleCollapsed,
			collapsed = this.state.collapsed,
		} = this.props;

		return (
			<article className={`collapsible-panel ${className}`}>
				<header onClick={onCollapseToggleClick}>
					<div className="title">{title}</div>
					<div className="header-actions">
						<div className="collapse">
							<i className="material-icons">{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
						</div>
					</div>
				</header>
				<section className={collapsed ? 'collapsed' : ''}>{children}</section>
				<footer />
			</article>
		);
	}
}

export default CollapsiblePanel;
