import React, {useCallback, useEffect, useState} from 'react';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import PropTypes from 'prop-types';
import styles from '../common/List.theme.scss';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import KiInput from '../../../../components/KiInput';
import KiConfirmModal from '../../../../components/KiConfirmModal';
import _debounce from 'lodash/debounce';
import NewItemSection from '../common/NewItemSection';
import ListActionsCell from '../common/ListActionsCell';
import ListUpdatedAtCell from '../common/ListUpdatedAtCell';
import {deleteNotionalPool, getNotionalPoolDeps, getNotionalPools} from '../../../../api/waterfallApi';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../../state/actions/Snackbar';
/* eslint-disable react/display-name, react/prop-types */

const NotionalPoolList = ({setFlyoutMode, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fullList, setFullList] = useState([]);
	const [currentList, setCurrentList] = useState([]);
	const [searchPhrase, setSearchPhrase] = useState('');
	const [deletionTarget, setDeletionTarget] = useState();
	const [deleteReferentialMsgActive, setDeleteReferentialMsgActive] = useState(false);
	const [deletionDeps, setDeletionDeps] = useState([]);

	const filterList = (phrase, fullList) =>
		setCurrentList(fullList.filter(item => item.name.toLowerCase().includes(phrase.toLowerCase())));

	const loadData = () => {
		setIsLoading(true);
		getNotionalPools().then(items => {
			setFullList(items);
			filterList(searchPhrase, items);
			setIsLoading(false);
		});
	};

	useEffect(() => {
		loadData();
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				id: 'name',
				sortable: true,
				isSorted: true,
			},
			{
				Header: 'Description',
				accessor: 'description',
				sortable: true,
			},
			{
				Header: 'Updated',
				accessor: 'lastUpdated',
				sortable: true,
				Cell: ({value}) => <ListUpdatedAtCell value={value} />,
			},
			{
				Header: '',
				id: 'actions',
				Cell: row => (
					<ListActionsCell
						actions={[
							{
								title: 'Edit',
								icon: 'edit',
								callback: () => {
									setFlyoutMode(FORECASTING_FLYOUT_MODE.NOTIONAL_POOLS_ADD, row.row.original?._id);
								},
							},
							{title: 'Delete', icon: 'delete', callback: () => setDeletionTarget(row.row.original)},
						]}
					/>
				),
				sortable: false,
				width: 76,
			},
		],
		[]
	);

	const filterListDebounce = useCallback(_debounce(phrase => filterList(phrase, fullList), 500), [fullList]);

	const onSearch = phrase => {
		setSearchPhrase(phrase);
		filterListDebounce(phrase);
	};

	const onDeleteConfirmation = () => {
		setIsLoading(true);
		getNotionalPoolDeps(deletionTarget._id).then(result => {
			if (Array.isArray(result) && result.length > 0) {
				setDeletionDeps(result);
				setDeleteReferentialMsgActive(true);
				setDeletionTarget(null);
				setIsLoading(false);
			} else {
				deleteNotionalPool(deletionTarget._id).then(() => {
					setDeletionTarget(null);
					showSnackbar('Notional Pool deleted successfully');
					loadData();
				});
			}
		});
	};

	return (
		<>
			<NewItemSection
				label="Notional Pool"
				onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.NOTIONAL_POOLS_ADD)}
			/>
			<section className={styles.filters}>
				<div>
					<KiInput label="Search Notional Pools" onChange={onSearch} value={searchPhrase} />
				</div>
			</section>
			<AbsTable data={currentList} columns={columns} isLoading={isLoading} isSortEnabled={true} isFilterEnabled={true}/>
			<KiConfirmModal
				id="NotionalPoolDeletionConfirmModal"
				header="Delete Notional Pool"
				message={`Are you sure you want to delete "${deletionTarget?.name}"?`}
				acceptFunc={onDeleteConfirmation}
				rejectFunc={() => setDeletionTarget(null)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={!!deletionTarget}
			/>
			<KiConfirmModal
				active={deleteReferentialMsgActive}
				acceptDisabled={true}
				rejectFunc={() => setDeleteReferentialMsgActive(false)}
				rejectLabel="OK"
				header="Cannot delete"
				message="The notional pool could not be deleted because it is used in the following replines"
			>
				<ul>
					{deletionDeps.map(c => {
						return <li key={c._id}>{c.name}</li>;
					})}
				</ul>
			</KiConfirmModal>
		</>
	);
};

NotionalPoolList.propTypes = {
	setFlyoutMode: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(
	null,
	{showSnackbar}
)(NotionalPoolList);
