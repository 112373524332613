import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import ManualEntryTableItem from './ManualEntryTableItem';
import KiConfirmModal from '../../../../components/KiConfirmModal';
import {deleteManualEntry} from '../../../../api/ledgersApi';

function ManualEntryTable({entries, ledgerId, onEdit, afterDelete}) {
	const [entryToDelete, setEntryToDelete] = useState(false);

	const ENTRIES_TABLE_ACTIONS = [
		{
			title: 'Edit',
			icon: 'mode_edit',
			onClick: item => onEdit(item),
		},
		{
			title: 'Delete',
			icon: 'delete',
			onClick: item => {
				setEntryToDelete(item._id);
			},
		},
	];

	const deleteEntry = () => {
		deleteManualEntry(ledgerId, entryToDelete).then(() => {
			afterDelete();
		});
		setEntryToDelete(false);
	};

	return (
		<Fragment>
			<div className="scenario-list-box list-box-size-0">
				{entries.length ? (
					<table className="scenario-list-table scenario-list-table-ledger journal">
						<thead>
							<tr>
								<th className="fixed-width">Post Code</th>
								<th className="fixed-width-date">Posting Date</th>
								<th className="fixed-width-date">Effective Date</th>
								<th>Description</th>
								<th>Line Item</th>
								<th className="fixed-width align-right">Account</th>
								<th className="fixed-width align-right">Debit</th>
								<th className="fixed-width align-right">Credit</th>
								<th className={`fixed-width-actions-${ENTRIES_TABLE_ACTIONS.length}`} />
							</tr>
						</thead>
						<tbody>
							{entries.map((item, i) => (
								<ManualEntryTableItem item={item} key={i} actions={ENTRIES_TABLE_ACTIONS} />
							))}
						</tbody>
					</table>
				) : (
					<div className="no-list-items-container">
						<div>No posts found</div>
					</div>
				)}
			</div>
			<KiConfirmModal
				message="Are you certain you want to delete this manual entry?"
				acceptFunc={deleteEntry}
				rejectFunc={() => setEntryToDelete(false)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={entryToDelete !== false}
			/>
		</Fragment>
	);
}

ManualEntryTable.propTypes = {
	entries: PropTypes.arrayOf(PropTypes.object),
	ledgerId: PropTypes.string,
	afterDelete: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
};

ManualEntryTable.defaultProps = {
	entries: [],
};

export default ManualEntryTable;
