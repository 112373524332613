import * as actionTypes from './actionTypes';

const initialState = {
	isLoading: false,
	waterfall: [],
};

function DatasetMappings(state = initialState, action) {
	switch (action.type) {
		case actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success': {
					return {
						...state,
						isLoading: false,
						waterfall: action.data,
					};
				}
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		case actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET_FV:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success': {
					return {
						...state,
						isLoading: false,
						waterfall: action.data,
					};
				}
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		default:
			return state;
	}
}

export default DatasetMappings;
