import React, {useState} from 'react';
import PropTypes from 'prop-types';
import KiModal from 'components/KiModal';
import KiInput from '../../../../components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import KiSelect from 'components/KiSelect';
import {useMergedState} from 'utils/customHooks';
import {addFundingVehicle} from '../../../../api/fundingVehiclesApi';
import styles from './FundingVehicleModal.theme.scss';

function FundingVehicleModal({dataset, fundingVehicles, isActive, onClose, onSave}) {
	const initialFormState = {
		name: '',
		copyFrom: null,
		copyCC: false,
	};
	const [formData, mergeFormData] = useMergedState(initialFormState);
	const [nameError, setNameError] = useState(null);

	const onNameChange = name => {
		mergeFormData({name});
		if (!name) {
			setNameError('Name is required');
		} else if (fundingVehicles.find(fv => fv.name === name)) {
			setNameError('Name already in use');
		} else {
			setNameError(null);
		}
	};

	const onSubmit = () => {
		const request = {
			fv: {
				name: formData.name,
				datasetId: dataset.datasetId,
				datasetName: dataset.name,
			},
			copy: {
				from: formData.copyFrom,
				legal: false,
				cc: formData.copyCC,
			},
		};
		addFundingVehicle(request).then(() => {
			mergeFormData(initialFormState);
			onSave();
		});
	};

	const fvOptions = fundingVehicles.map(fv => ({label: fv.name, value: fv._id}));
	const fvValue = fvOptions.find(fv => fv._id === formData.copyFrom);
	const isSubmitDisabled = !formData.name || !!nameError;

	return (
		<KiModal
			actions={[
				{label: 'Cancel', onClick: onClose},
				{label: 'Save', onClick: onSubmit, disabled: isSubmitDisabled},
			]}
			active={isActive}
			header={`${dataset.name}: Create Funding Vehicle`}
			className={styles.fvModal}
			onClose={onClose}
		>
			<form>
				<KiInput
					type="text"
					label="Name"
					value={formData.name}
					onChange={onNameChange}
					maxLength={50}
					error={nameError}
				/>
				<div>
					<span className="theme-label">Copy:</span>
					<KiSelect
						value={fvValue}
						options={fvOptions}
						onChange={option => mergeFormData({copyFrom: option?.value || null})}
						isClearable={true}
					/>
				</div>
				<div className={styles.checkbox}>
					<KiCheckbox
						checked={formData.copyCC}
						disabled={!formData.copyFrom}
						label="Constraints"
						onChange={copyCC => mergeFormData({copyCC})}
					/>
				</div>
			</form>
		</KiModal>
	);
}

FundingVehicleModal.propTypes = {
	dataset: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	fundingVehicles: PropTypes.array,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default FundingVehicleModal;
