import * as actionTypes from './actionTypes';

const initialState = {
	isLoading: false,
	data: [],
	active: false,
	account: null,
	formValues: {
		userId: '',
		password: '',
		email: '',
		firstName: '',
		lastName: '',
		groups: [
			'Users'
		],
		apiKey: '',
	},
	formErrors: {},
};

const Accounts = (state = initialState, action) => { //eslint-disable-line complexity
	switch (action.type) {
		case actionTypes.ACCOUNTS_FETCH:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true
					};
				case 'success':
					return {
						...state,
						isLoading: false,
						data: action.accounts
					};
				case 'error':
					return {
						...state,
						isLoading: false
					};
				default:
					return state;
			}

		case actionTypes.ACCOUNTS_ADD:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						isLoading: false,
						data: [
							...state.data,
							action.account,
						],
					};
				case 'error':
					return {
						...state,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.ACCOUNTS_UPDATE:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true
					};
				case 'success':
					return {
						...state,
						isLoading: false,
						data: state.data.map(account => {
							if (account._id !== action.account._id) {
								return account;
							}
							return {
								...account,
								...action.account,
							};
						})
					};
				case 'error':
					return {
						...state,
						isLoading: false
					};
				default:
					return state;
			}

		case actionTypes.ACCOUNTS_REMOVE_MANY:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true
					};
				case 'success':
					return {
						...state,
						isLoading: false,
						data: state.data.filter(account => !action.removedIds.includes(account._id)),
					};
				case 'error':
					return {
						...state,
						isLoading: false
					};
				default:
					return state;
			}

		case actionTypes.ACCOUNTS_REMOVE:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true
					};
				case 'success':
					return {
						...state,
						isLoading: false,
						data: state.data.filter(account => account._id !== action.removedId),
					};
				case 'error':
					return {
						...state,
						isLoading: false
					};
				default:
					return state;
			}

		case actionTypes.ACCOUNT_MODAL_OPEN:
			return {
				...state,
				selected: action.selected,
				accountModalActive: true,
			};

		case actionTypes.ACCOUNT_MODAL_CLOSE:
			return {
				...state,
				accountModalActive: false,
			};

		default:
			return state;
	}
};

export default Accounts;
