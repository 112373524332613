import {apiUrl, _getHeaders} from './apiUtils';

export const fetchOne = async filterId => {
	const url = new URL(`${apiUrl}/datasetFilters/${filterId}`, document.baseURI);
	try {
		const fetchResult = await fetch(url, {
			credentials: 'include',
			headers: _getHeaders(),
		});
		const response = await fetchResult.json();
		if (fetchResult.status !== 200) {
			return Promise.reject('Filter fetchOne failed');
		}
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};
export const fetchMany = async (datasetId, userId) => {
	const url = new URL(`${apiUrl}/datasetFilters`, document.baseURI);
	if (datasetId) {
		url.searchParams.set('datasetId', datasetId);
	}
	if (userId) {
		url.searchParams.set('userId', userId);
	}
	try {
		const fetchResult = await fetch(url, {
			credentials: 'include',
			headers: _getHeaders(),
		});
		const response = await fetchResult.json();
		if (fetchResult.status !== 200) {
			return Promise.reject('Filter fetchMany failed');
		}
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const createOne = async filter => {
	const url = new URL(`${apiUrl}/datasetFilters`, document.baseURI);
	try {
		const fetchResult = await fetch(url, {
			credentials: 'include',
			headers: _getHeaders('POST'),
			method: 'POST',
			body: JSON.stringify(filter),
		});
		const response = await fetchResult.json();
		if (fetchResult.status !== 200) {
			return Promise.reject('Filter creation failed');
		}
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateOne = async filter => {
	const url = new URL(`${apiUrl}/datasetFilters/${filter._id}`, document.baseURI);
	try {
		const fetchResult = await fetch(url, {
			credentials: 'include',
			headers: _getHeaders('PUT'),
			method: 'PUT',
			body: JSON.stringify(filter),
		});
		const response = await fetchResult.json();
		if (fetchResult.status !== 200) {
			return Promise.reject('Filter update failed');
		}
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const removeOne = async filterId => {
	const url = new URL(`${apiUrl}/datasetFilters/${filterId}`, document.baseURI);
	try {
		const fetchResult = await fetch(url, {
			credentials: 'include',
			headers: _getHeaders('DELETE'),
			method: 'DELETE',
		});
		const response = await fetchResult.json();
		if (fetchResult.status !== 200) {
			return Promise.reject(response);
		}
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};

export default {
	fetchOne,
	fetchMany,
	createOne,
	updateOne,
	removeOne,
};
