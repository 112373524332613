// Globals
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';

// Website imports
import {KiInput} from '../../../../components/KiInput';
import {updateInArray} from '../../../../utils/arrayUtils';

// Local imports
import styles from './customBandsOdd.theme.scss';
import {DataExplorerContext} from '../../DataExplorerContext';

const CohortBandsOddRow = ({band, index, formErrors, setFormErrors}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark: {explorerData},
		setExplorerData,
	} = dataExplorerContext;

	const [bandState, setBandState] = useState(Object.assign({}, band));

	const validateStepSize = () => {
		const bandSize = Number(bandState.end) - Number(bandState.start);
		const errorName = `step-${index}`;
		if (bandSize < Number(bandState.step)) {
			setFormErrors({[errorName]: 'greater than band'});
		} else if (bandSize / Number(bandState.step) > 1000) {
			setFormErrors({[errorName]: '1000 steps max'});
		} else {
			setFormErrors({[errorName]: ''});
		}
	};

	const updateBand = (value, name) => {
		validateStepSize();
		const step = {...band, [name]: value};
		setExplorerData(
			{
				bands: {...explorerData.bands, steps: updateInArray(explorerData.bands.steps, step, index)},
			},
			true
		);
	};

	return (
		<div className={styles.bandRow}>
			<KiInput
				name={`start-${index}`}
				type="text"
				value={bandState.start}
				error={formErrors[`start-${index}`]}
				className={styles.skinny}
				onChange={val => setBandState({start: val})}
				onBlur={val => updateBand(val.target.value, 'start')}
				isNumberMasked={true}
			/>
			<KiInput
				name={`end-${index}`}
				type="text"
				value={bandState.end}
				error={formErrors[`end-${index}`]}
				className={styles.skinny}
				onChange={val => setBandState({end: val})}
				onBlur={val => updateBand(val.target.value, 'end')}
				isNumberMasked={true}
			/>
			<KiInput
				name={`step-${index}`}
				type="text"
				value={bandState.step}
				error={formErrors[`step-${index}`]}
				className={styles.skinny}
				onChange={val => setBandState({step: val})}
				onBlur={val => updateBand(val.target.value, 'step')}
				isNumberMasked={true}
			/>
		</div>
	);
};

CohortBandsOddRow.propTypes = {
	band: PropTypes.object,
	index: PropTypes.number,
	setFormErrors: PropTypes.func,
	formErrors: PropTypes.object,
};

export default CohortBandsOddRow;
