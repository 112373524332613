import React from 'react';
import PropTypes from 'prop-types';
import FormBlockItemContainer from '../../common/FormBlockItemContainer';
import KiSelect from '../../../../../components/KiSelect';
import styles from './Delinqnecy.theme.scss';
import DelinquencySubForm from './DelinquencySubForm';
import DelinquencyNonPerformingForm from './DelinquencyNonPerformingForm';

export const getDelinquencyStartingStructure = () => ({
	delinqRate: {
		type: null,
		rate: '0',
		vectorId: null,
		months: '0',
	},
	nonPerfLoansToDef: {
		flag: false,
		delinqMinMonths: '',
		bankruptMinMonths: '',
		reoFlag: false,
		foreclosedFlag: false,
	},
	servicerAdvance: {
		base: null,
		type: null,
		rate: '0',
		vectorId: null,
		months: '0',
	},
});

export const getDelinquencyValidationStartingStructure = () => ({
	delinqRate: {
		rate: '',
		months: '',
		vectorId: '',
	},
	nonPerfLoansToDef: {
		delinqMinMonths: '',
		bankruptMinMonths: '',
	},
	servicerAdvance: {
		base: '',
		type: '',
		rate: '',
		vectorId: '',
		months: '',
	},
});

import {delinquencyServicerAdvancesOptions as SERVICER_ADVANCES_OPTIONS} from 'ki-common/options/assumptions';

const Delinquency = ({isVisible, onChange, delinquency, validationErrors, vectors}) => {
	const delinqRate = delinquency?.delinqRate || {};
	const servicerAdvance = delinquency?.servicerAdvance || {};
	const nonPerfLoansToDef = delinquency?.nonPerfLoansToDef || {};

	const onSectionChange = (change = {}, propertyName) => {
		onChange(assumption => ({
			...assumption,
			delinquency: {
				...assumption.delinquency,
				[propertyName]: {
					...assumption.delinquency[propertyName],
					...change,
				},
			},
		}));
	};

	const handleDelete = () => {
		onChange(assumption => ({
			...assumption,
			delinquencyState: false,
			delinquency: getDelinquencyStartingStructure(),
		}));
	};

	return (
		<FormBlockItemContainer isVisible={isVisible} readOnly={false} onDelete={handleDelete} title="Delinquency">
			<div className={styles.innerBox}>
				<div className={styles.subtitle}>New Delinquencies</div>
				<DelinquencySubForm
					typeLabel="Delinquency"
					vectors={vectors}
					onChange={change => onSectionChange(change, 'delinqRate')}
					subFormData={delinqRate}
					subFormErrors={validationErrors.delinqRate}
				/>
			</div>

			<div className={styles.innerBox}>
				<div className={styles.subtitle}>Default Non-Performing</div>
				<DelinquencyNonPerformingForm
					onChange={change => onSectionChange(change, 'nonPerfLoansToDef')}
					subFormData={nonPerfLoansToDef}
					subFormErrors={validationErrors.nonPerfLoansToDef}
				/>
			</div>

			<div className={styles.innerBox}>
				<div className={styles.subtitle}>Servicer</div>
				<div className="sidebar-form-section">
					<span className="form-instruction">Advances</span>
					<KiSelect
						options={SERVICER_ADVANCES_OPTIONS}
						onChange={option => onSectionChange({base: option?.value || null}, 'servicerAdvance')}
						value={SERVICER_ADVANCES_OPTIONS.filter(opt => opt.value === servicerAdvance?.base)}
						isClearable={true}
						errorMessage={validationErrors?.servicerAdvance?.base}
					/>
					<DelinquencySubForm
						typeLabel="Advance Rate"
						vectors={vectors}
						onChange={change => onSectionChange(change, 'servicerAdvance')}
						subFormData={servicerAdvance}
						subFormErrors={validationErrors.servicerAdvance}
					/>
				</div>
			</div>
		</FormBlockItemContainer>
	);
};

Delinquency.propTypes = {
	isVisible: PropTypes.bool,
	delinquency: PropTypes.object,
	validationErrors: PropTypes.object,
	vectors: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

Delinquency.defaultProps = {
	isVisible: false,
	vectors: [],
};

export default Delinquency;
