import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import KiButton from 'components/KiButton';
import styles from './kiButtonSpinner.theme.scss';

export class KiButtonSpinner extends Component {
	static propTypes = {
		className: PropTypes.string,
		showSpinner: PropTypes.bool,
		showButton: PropTypes.bool,
		disabled: PropTypes.bool,
		onClick: PropTypes.func,
		label: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		showSpinner: false,
		showButton: true,
		disabled: false,
		label: 'Submit',
	};

	render() {
		return (
			<Fragment>
				{this.props.showSpinner && (
					<div className={`${styles.ldsSpinner} ${this.props.className}`}>
						<div />
						<div />
						<div />
						<div />
						<div />
						<div />
						<div />
						<div />
						<div />
						<div />
						<div />
						<div />
					</div>
				)}
				{this.props.showButton && (
					<KiButton
						primary={true}
						id="wizard-next-button"
						disabled={this.props.disabled}
						className={this.props.className}
						onClick={this.props.onClick}
					>
						{this.props.label}
					</KiButton>
				)}
			</Fragment>
		);
	}
}
export default KiButtonSpinner;
