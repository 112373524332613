import React, {Fragment, useEffect, useState} from 'react';
import {fetchBalanceForLedger} from '../../../../api/ledgersApi';
import BalanceTable from './BalanceTable';
import PropTypes from 'prop-types';
import KiInput from '../../../../components/KiInput';
import {connect} from 'react-redux';
import KiButton from '../../../../components/KiButton';
import fetchWithJob from '../../../../socket/fetchWithJob';
import Loader from '../../Loader';

function LedgerBalanceSummary({id, startDate, endDate, dateContext}) {
	const [searchTerm, setSearchTerm] = useState('');
	const [balanceData, setBalanceData] = useState({});
	const [isLoading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');

	const fetchBalance = () => {
		setLoading(true);
		fetchWithJob(() => fetchBalanceForLedger(id, searchTerm, startDate, endDate, dateContext.value))
			.then(balance => {
				setBalanceData(balance);
				setLoading(false);
			})
			.catch(err => {
				if (err.status === 'FAILED') {
					const msg = err.stacktrace[0];
					setErrorMsg(msg || 'An error occurred loading the ledger');
				}
				setLoading(false);
			});
	};

	useEffect(
		() => {
			fetchBalance();
		},
		[id, startDate, endDate, dateContext]
	);

	const onSearchChange = value => {
		setSearchTerm(value);
		if (value === '') {
			fetchBalance();
		}
	};

	const handleKeyDown = e => {
		if (e.keyCode === 13) {
			fetchBalance();
		}
	};

	if (errorMsg) {
		return (
			<div className="no-list-items-container">
				<div>{errorMsg}</div>
			</div>
		);
	}

	return (
		<Loader isActive={isLoading}>
			<Fragment>
				<div className="horizontal-inputs-container">
					<div className="long-wrapper">
						<KiInput
							label="Search by Account Name"
							value={searchTerm}
							onChange={onSearchChange}
							onKeyDown={e => handleKeyDown(e)}
							className="search-input"
							maxLength={100}
						/>
					</div>
					<div className="button-wrapper">
						<KiButton primary onClick={() => fetchBalance()}>
							Search
						</KiButton>
					</div>
				</div>
			</Fragment>

			{balanceData.accounts && balanceData.accounts.length ? (
				<div>
					<BalanceTable balance={balanceData} />
				</div>
			) : (
				<div className="no-list-items-container">
					<div>No accounts found</div>
				</div>
			)}
		</Loader>
	);
}

LedgerBalanceSummary.propTypes = {
	id: PropTypes.string.isRequired,
	dateContext: PropTypes.object.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
	dateContext: state.ledgerExploration.dateContext,
	startDate: state.ledgerExploration.startDate,
	endDate: state.ledgerExploration.endDate,
});

export default connect(mapStateToProps)(LedgerBalanceSummary);
