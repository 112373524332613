import {_getHeaders, _handleResponse, apiUrl} from 'api/apiUtils';
import {formatDate, getTomorrowDate} from '../utils/dateUtils';
import {getCalculatedDates} from './datasetDatesApi';

export const fetchLedger = id => {
	return fetch(`${apiUrl}/ledgers/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const fetchLedgers = () => {
	return fetch(`${apiUrl}/ledgers/`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	})
		.then(_handleResponse)
		.then(response => response.data.ledgers);
};

const fetchLedgerJob = (urlSuffix, ledgerId, accountId, startDate, endDate, dateContext) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/${urlSuffix}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			dateType: dateContext,
			startDate: formatDate(startDate),
			endDate: formatDate(getTomorrowDate(new Date(endDate))),
			accountId: accountId,
		}),
	})
		.then(_handleResponse)
		.then(response => response.data.jobId);
};

export const fetchBalanceForLedger = (ledgerId, accountId, startDate, endDate, dateContext) => {
	return fetchLedgerJob('balanceSummary', ledgerId, accountId, startDate, endDate, dateContext);
};

export const fetchAccountDetailsForLedger = (ledgerId, accountId, startDate, endDate, dateContext) => {
	return fetchLedgerJob('accountDetails', ledgerId, accountId, startDate, endDate, dateContext);
};

export const fetchJournalForLedger = (ledgerId, startDate, endDate, dateContext) => {
	return fetchLedgerJob('journal', ledgerId, null, startDate, endDate, dateContext);
};

export const fetchLedgerAccounts = ledgerId => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/accounts`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
	})
		.then(_handleResponse)
		.then(response => response.data.jobId);
};

export const fetchLedgerAccountsMapping = (ledgerId, activeOnly = true, checkIsUsed = false) => {
	const url = new URL(`${apiUrl}/ledgers/${ledgerId}/accounts/mapping`, document.baseURI);

	if (activeOnly) {
		url.searchParams.set('active', activeOnly.toString());
	}
	if (checkIsUsed) {
		url.searchParams.set('checkIsUsed', checkIsUsed.toString());
	}

	return fetch(url.toString(), {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	})
		.then(_handleResponse)
		.then(response => response.data.accounts);
};

export const updateAccounts = (ledgerId, accountsData) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/accounts/mapping/update`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(accountsData),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const fetchManualEntries = ledgerId => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/manual/pending`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	})
		.then(_handleResponse)
		.then(response => response.data.manualEntries);
};

export const postManualEntry = (ledgerId, entry) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/manual/pending`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(entry),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const putManualEntry = (ledgerId, entry) => {
	const id = entry._id;
	delete entry._id;

	return fetch(`${apiUrl}/ledgers/${ledgerId}/manual/pending/${id}`, {
		credentials: 'include',
		method: 'PUT',
		headers: _getHeaders('PUT'),
		body: JSON.stringify(entry),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const saveManualEntry = (ledgerId, entry) => {
	if (entry._id) {
		return putManualEntry(ledgerId, entry);
	} else {
		return postManualEntry(ledgerId, entry);
	}
};

export const commitManualEntries = (ledgerId, entriesIds) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/manual/pending/commit`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({manualEntriesIds: entriesIds}),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const deleteManualEntries = (ledgerId, entriesIds) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/manual/pending`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
		body: JSON.stringify({manualEntriesIds: entriesIds}),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const deleteManualEntry = (ledgerId, entryId) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/manual/pending/${entryId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const updateLedgerPeriodDate = (ledgers, newDate) => {
	const data = {
		ledgerIds: ledgers,
		closeDate: formatDate(newDate),
	};

	return fetch(`${apiUrl}/ledgers/closeDate`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(data),
	}).then(_handleResponse);
};

export const updateLedger = (ledgerId, newDate, newExportCode = null) => {
	const data = {
		closeDate: formatDate(newDate),
		exportCode: newExportCode && newExportCode.length ? newExportCode : null,
	};

	return fetch(`${apiUrl}/ledgers/${ledgerId}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(data),
	}).then(_handleResponse);
};

export const fetchTemplates = (ledgerId, activeOnly = true) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/templates${activeOnly ? '?active=true' : ''}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	})
		.then(_handleResponse)
		.then(response => response.data.templates);
};

export const calculateEffectiveDates = (datasetId, fundingVehicleId, date, templates) => {
	const data = [];
	templates.forEach(({effectiveDate}) => {
		const itemToPush = {
			groupId: effectiveDate,
			statementDate: formatDate(date),
		};
		if (fundingVehicleId) {
			itemToPush.fundingVehicleId = fundingVehicleId;
		}
		data.push(itemToPush);
	});

	return getCalculatedDates(data, datasetId);
};

export const generateTemplates = (ledgerId, postDate, templates) => {
	const convertedTemplates = templates.map(({_id, calculatedEffectiveDate}) => ({
		templateId: _id ? _id : '',
		effectiveDate: formatDate(calculatedEffectiveDate),
	}));

	const data = {
		postDate: formatDate(postDate),
		templates: convertedTemplates,
	};

	return fetch(`${apiUrl}/ledgers/${ledgerId}/templates/generate`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(data),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const submitLedger = (ledgerId, startDate, endDate, dateContext) => {
	const data = {
		startDate: formatDate(startDate),
		endDate: formatDate(endDate),
		dateContext,
	};

	return fetch(`${apiUrl}/ledgers/${ledgerId}/submit`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(data),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const updateTemplates = (ledgerId, templateData) => {
	return fetch(`${apiUrl}/ledgers/${ledgerId}/templates/update`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(templateData),
	})
		.then(_handleResponse)
		.then(response => response.data);
};

export const getGenerateLedgerExportURL = (dateContext, startDate, endDate, ledgers, method) => {
	const url = new URL(`${apiUrl}/ledgers/${method}`, document.baseURI);

	if (dateContext) url.searchParams.set('dateType', dateContext);
	if (startDate) url.searchParams.set('startDate', startDate);
	if (endDate) url.searchParams.set('endDate', endDate);
	if (ledgers && ledgers.length) url.searchParams.set('ledgers', ledgers.join(','));

	return url;
};
