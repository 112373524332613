import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'components/KiSelect';
import styles from './DebtFilters.theme.scss';
import {showSnackbar} from 'state/actions/Snackbar';
import _ from 'lodash';
import KiButton from 'components/KiButton';
import {
	fetchDebtData,
	fetchWaterfallData,
	setScenario,
	setDateContext,
	setFundingVehicleQuickFilter,
	setRangeOccurrences,
	setRangeDate,
	resetChangeState,
} from '../actions';
import {debtCohortCols, debtScenarioContexts, debtOutputOptions, debtViewTypes} from 'ki-common/options/debt';
import DebtFilterDateControls from 'containers/debt/components/DebtFilterDateControls';
import debtUtils from 'ki-common/utils/debtUtils';

/**
 * return only the allowed scenario context options for the provided entityType and viewType
 * ex: Only pool transaction should allow lastCommitted (Last Locked) and should filter out lastApproved (End of Day)
 * @param opts
 * @param entityType
 * @param viewType
 * @returns {*[]}
 */

export const QuickFilter = props => {
	if (props.isHidden) return null;
	return (
		<div className={props.className || styles.quickFilter}>
			{!!props.label && (
				<label className={styles.label}>
					{props.label}
					{!!props.labelInfo && (
						<i className={styles.dateInfoIcon} title={props.labelInfo}>
							info
						</i>
					)}
				</label>
			)}
			{props.children}
		</div>
	);
};

QuickFilter.propTypes = {
	label: PropTypes.string,
	labelInfo: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node,
	isHidden: PropTypes.bool,
};

QuickFilter.defaultProps = {
	isHidden: false,
	className: styles.quickFilter,
};

export class DebtFilters extends Component {
	static propTypes = {
		setScenario: PropTypes.func.isRequired,
		setCohortColumn: PropTypes.func.isRequired,
		setOutputOption: PropTypes.func.isRequired,
		setOutputItem: PropTypes.func.isRequired,
		setFundingVehicleQuickFilter: PropTypes.func.isRequired,
		settings: PropTypes.object,
		fundingVehicleList: PropTypes.array,
		// searchTerm: PropTypes.string,
		// setSearchTerm: PropTypes.func.isRequired,
		waterfallData: PropTypes.array,
		fetchWaterfallData: PropTypes.func.isRequired,
		fetchDebtData: PropTypes.func.isRequired,
		tableDataDirty: PropTypes.bool.isRequired,
		showSnackbar: PropTypes.func,
		setRangeDate: PropTypes.func,
		setRangeOccurrences: PropTypes.func,
		isLoading: PropTypes.bool.isRequired,
	};

	static defaultProps = {
		settings: {},
		fundingVehicleList: [],
		searchTerm: '',
		waterfallData: [],
	};

	state = {
		isDistribution: false,
		isOutput: false,
		outputValue: null,
	};

	selectCohortColumn = cohortCol => {
		const viewTypeOptions = this.getViewTypeOptions(cohortCol);
		const viewType = viewTypeOptions.find(col => col.value === this.props.settings.viewType);
		if (!viewType) {
			this.selectViewType(this.getViewTypeOptions()[0].value);
			this.props.setCohortColumn(cohortCol, this.getViewTypeOptions()[0].value, true);
		} else {
			this.props.setCohortColumn(cohortCol, this.props.settings.viewType, true);
		}
		if (cohortCol !== 'pool' && this.props.settings.quickFilters.scenarioType === 'lastCommitted') {
			this.props.setScenario('lastApproved');
		}
	};

	selectViewType = viewTypeValue => {
		if (viewTypeValue === 'activity') {
			if (this.props.fundingVehicleList.length) {
				this.props.setFundingVehicleQuickFilter(this.props.fundingVehicleList[0]._id, false);
			} else {
				this.props.showSnackbar('At least one Funding Vehicle is required for the Activity View');
				return;
			}
			this.props.setCohortColumn(this.props.settings.cohortColumn, viewTypeValue, true);
			// if (this.props.settings.snapshotDate) {
			// 	this.props.setDate(this.props.settings.snapshotDate, fetchData);
			// }
		} else if (viewTypeValue === 'transaction') {
			this.props.setFundingVehicleQuickFilter(this.props.fundingVehicleList[0]._id, false);
			this.props.setCohortColumn(this.props.settings.cohortColumn, viewTypeValue, true);
			/*
				this is annoyingly for pool transaction.
				only pool can currently select a transaction view.
				it must have a funding vehicle selected and cannot be unselected for All FVs
				pool transaction can only use lastCommitted (Last Locked) and assetSnapshot, not End Of Day
				it can only use Last Period, thus we have to clear the rangeDate and set rangeOccurrences to 1
			 */
			this.props.setScenario('lastCommitted');
			this.props.setRangeDate(null);
			this.props.setRangeOccurrences(1);
		} else if (viewTypeValue === 'distribution') {
			this.setState({isDistribution: true, outputValue: debtOutputOptions[1].value});
			this.props.setCohortColumn(this.props.settings.cohortColumn, viewTypeValue, true);
			this.props.setOutputOption(debtOutputOptions[1].value);
		} else if (viewTypeValue === 'waterfall') {
			this.setState({isDistribution: true, outputValue: debtOutputOptions[1].value});
			this.props.setCohortColumn(this.props.settings.cohortColumn, viewTypeValue, true);
			this.props.setOutputOption(debtOutputOptions[1].value);
		} else if (viewTypeValue === 'role') {
			this.setState({isDistribution: false, outputValue: debtOutputOptions[1].value});
			this.props.setCohortColumn('counterpartyRole', viewTypeValue, true);
			this.props.setOutputOption(null);
		} else {
			// If we have chosen a summary after a role view...the cohort must be set back so the proper columns are sent
			const safeCohort =
				this.props.settings.cohortColumn === 'counterpartyRole'
					? 'counterparty'
					: this.props.settings.cohortColumn;
			this.setState({isDistribution: false, outputValue: debtOutputOptions[1].value});
			this.props.setCohortColumn(safeCohort, viewTypeValue, true);
			this.props.setOutputOption(null);
		}
		if (
			viewTypeValue !== 'transaction' &&
			_.get(this.props, 'settings.quickFilters.scenarioType') === 'lastCommitted'
		) {
			// This ensures that lastCommitted, only used by pool transaction views, is replaced with a default when switching out of pool transaction
			this.props.setScenario('lastApproved');
		}
	};

	handleGenerateClick = () => {
		const {settings} = this.props;
		return this.props.fetchWaterfallData(settings.quickFilters.fundingVehicleId, settings.snapshotDate).then(() => {
			this.props.setOutputOption(this.state.outputValue || debtOutputOptions[1].value);
		});
	};

	handleSaveTable = () => {
		return this.props.fetchDebtData(true);
	};

	handleOutputType = value => {
		this.setState({outputValue: value});
		this.props.setOutputOption(value);
		const selectedDatas = this.props.waterfallData.filter(data => data.tag === value);
		if (selectedDatas.length > 1) {
			this.setState({outputItemValue: {label: selectedDatas[0].name, value: selectedDatas[0].name}});
			this.props.setOutputItem(selectedDatas[0].name);
		} else {
			this.props.setOutputItem(null);
		}
	};

	handleOutputItem = val => {
		this.setState({outputItemValue: val});
		this.props.setOutputItem(val.value);
	};

	getViewTypeOptions = cohortCol => {
		const cohortColumn = cohortCol || this.props.settings.cohortColumn;
		const VIEW_TYPE_MAP = {
			activity: ['fundingVehicle', 'debt', 'creditSupport', 'fee', 'trigger', 'waterfall'],
			transaction: [],
			waterfall: ['fundingVehicle'],
			role: ['counterparty', 'counterpartyRole'],
		};
		return debtViewTypes.filter(opt => _.get(VIEW_TYPE_MAP, opt.value, [cohortColumn]).includes(cohortColumn));
	};

	applyFilters = () => {
		this.props.fetchDebtData();
	};

	render() {
		const {settings, waterfallData, fundingVehicleList, isLoading} = this.props;
		const scenarioType = _.get(settings, 'quickFilters.scenarioType', 'lastApproved');
		const fundingVehicleId = _.get(settings, 'quickFilters.fundingVehicleId', null);
		const showWaterfallFilters =
			this.state.isDistribution && !!waterfallData.find(d => d.tag === this.state.outputValue);
		const {cohortColumn, viewType} = _.get(this.props, 'settings', {});
		return (
			<React.Fragment>
				<div className={styles.filters}>
					<QuickFilter label="Entity" className={styles.entity}>
						<Select
							classNamePrefix="aut-select"
							value={debtCohortCols.find(
								col =>
									col.value ===
									(settings.cohortColumn == 'counterpartyRole'
										? 'counterparty'
										: settings.cohortColumn)
							)}
							isClearable={false}
							options={debtCohortCols}
							onChange={val => this.selectCohortColumn(val.value, null, true)}
							isDisabled={isLoading}
						/>
					</QuickFilter>

					<QuickFilter label="View" className={styles.view}>
						<Select
							classNamePrefix="aut-select"
							value={debtViewTypes.find(col => col.value === settings.viewType)}
							isClearable={false}
							onChange={val => this.selectViewType(val.value)}
							options={this.getViewTypeOptions()}
							isDisabled={isLoading}
						/>
					</QuickFilter>

					<QuickFilter label="Funding Vehicle">
						<Select
							classNamePrefix="aut-select"
							value={fundingVehicleList.filter(({_id}) => _id === fundingVehicleId)}
							getOptionValue={fv => fv._id}
							getOptionLabel={fv => fv.name}
							options={fundingVehicleList}
							onChange={opt => this.props.setFundingVehicleQuickFilter(_.get(opt, '_id', null), true)}
							isClearable={cohortColumn !== 'pool' || viewType !== 'transaction'}
							isDisabled={isLoading}
						/>
					</QuickFilter>

					<QuickFilter label="Scenario Context" className={styles.scenarioContext}>
						<Select
							classNamePrefix="aut-select"
							value={debtScenarioContexts.find(opt => opt.value === scenarioType)}
							isClearable={false}
							options={debtUtils.getAvailableScenarioContextOptions(
								debtScenarioContexts,
								cohortColumn,
								viewType
							)}
							onChange={opt => this.props.setScenario(opt.value, true)}
							isDisabled={isLoading}
						/>
					</QuickFilter>

					<DebtFilterDateControls isLoading={isLoading} />

					{!!this.props.tableDataDirty && (
						<KiButton
							primary={true}
							style={{alignSelf: 'center'}}
							label="Save Table Data"
							onClick={() => this.handleSaveTable()}
						/>
					)}
					<KiButton
						style={{alignSelf: 'center'}}
						raised
						primary
						label="Apply"
						onMouseUp={this.applyFilters}
						disabled={!settings.hasChanged || isLoading}
					/>
				</div>
				{showWaterfallFilters && (
					<div className={styles.waterfallDataDisplay}>
						<QuickFilter
							label="Entity"
							className={styles.waterfallOutputEntitySelect}
							isHidden={!showWaterfallFilters}
						>
							<Select
								classNamePrefix="aut-select"
								value={debtOutputOptions.find(opt => opt.value === this.state.outputValue)}
								isClearable={false}
								options={debtOutputOptions}
								onChange={val => this.handleOutputType(val.value)}
							/>
						</QuickFilter>

						<QuickFilter
							label="Item"
							className={styles.waterfallOutputItem}
							isHidden={!showWaterfallFilters}
						>
							<Select
								classNamePrefix="aut-select"
								value={this.state.outputItemValue}
								isClearable={false}
								options={waterfallData
									.filter(data => data.tag === this.state.outputValue)
									.map(item => ({label: item.name, value: item.name}))}
								onChange={val => this.handleOutputItem(val)}
							/>
						</QuickFilter>
					</div>
				)}
			</React.Fragment>
		);
	}
}

const actionCreators = {
	fetchWaterfallData,
	fetchDebtData,
	setScenario,
	setDateContext, //unused?
	setFundingVehicleQuickFilter,
	showSnackbar,
	setRangeOccurrences,
	setRangeDate,
	resetChangeState,
};

export default connect(
	null,
	actionCreators
)(DebtFilters);
