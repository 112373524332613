import PropTypes from 'prop-types';
import React from 'react';
import KiListItem from 'components/KiList/KiListItem';

export const SummaryLink = props => {
	return <KiListItem className={props.className} caption="Summary" icon="info_outline" onClick={props.handleClick} />;
};

SummaryLink.propTypes = {
	handleClick: PropTypes.func.isRequired,
	className: PropTypes.string,
};

export default SummaryLink;
