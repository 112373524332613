import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const getActivePools = datasetId => {
	return fetch(`${apiUrl}/pools/active/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getPendingPools = datasetId => {
	return fetch(`${apiUrl}/pools/pending/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getInactivePools = datasetId => {
	return fetch(`${apiUrl}/pools/inactive/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	getActivePools,
	getPendingPools,
	getInactivePools,
};
