import React from 'react';
import styles from '../AssociatedData.theme.scss';

export const PaymentFileInformation = () => {
	return (
		<div>
			<p style={{color: '#135E82', fontWeight: 'bold'}}>
				To upload your payment file for use in Capital Markets, please adhere to the following file formatting
				and associated data requirements:
			</p>
			<p>- No required header record.</p>
			<p>
				- First column must be Account (Alphanumeric String): Account should be common to the Payment File and
				Dataset Snapshot, to facilitate a common mapping.
			</p>
			<p>
				- Second column must be the PaymentDate: This field must be formatted as YYYY-MM-DD. Payments will be
				evaluated on a monthly basis so the DAYS assignment will not be used. For example, if the assigned
				Snapshot Date is 2022-06-15, any payments in June will be assumed as a June Payment (month 1), any
				payments in July will be assumed as the July payment (month 2), etc.
			</p>
			<p>- Third column must be PaymentAmount (numeric). Commas can be accepted in quotation marks.</p>
			<p>- Fourth column must be DelinquencyAmount (numeric). Commas can be accepted in quotation marks.</p>
			<p>
				- Fifth column must be PayaheadAmount (numeric). PayaheadAmount is passed as a negative balance with a
				leading negative sign (-). Commas can be accepted in quotation marks.
			</p>
			<p>Commas can be accepted in quotation marks. Comma, Pipe and Tab delimited submissions are accepted.</p>
			<table className={`${styles.paymentFileTable} ki-list-table`}>
				<thead>
					<tr>
						<th>Account</th>
						<th>PaymentDate</th>
						<th>PaymentAmount</th>
						<th>DelinquencyAmount</th>
						<th>PayaheadAmount</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<p>1000123</p>
						</td>
						<td>
							<p>2022-10-14</p>
						</td>
						<td>
							<p>300</p>
						</td>
						<td>
							<p>600</p>
						</td>
						<td>
							<p>0</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>1000123</p>
						</td>
						<td>
							<p>2022-11-14</p>
						</td>
						<td>
							<p>300</p>
						</td>
						<td>
							<p>0</p>
						</td>
						<td>
							<p>0</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>1000124</p>
						</td>
						<td>
							<p>2022-10-14</p>
						</td>
						<td>
							<p>400</p>
						</td>
						<td>
							<p>0</p>
						</td>
						<td>
							<p>-400</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export const AppendInformation = () => {
	return (
		<div>
			<p style={{color: '#135E82', fontWeight: 'bold'}}>
				Append can be used to append additional columns of Data to an existing Dataset Snapshot. To use Append,
				please adhere to the following formatting requirements:
			</p>
			<p>- Header record reflecting the desired display name of the column is required.</p>
			<p>
				- First column must be Asset ID (Alphanumeric String): Asset ID should be common to the Appended data
				and Dataset Snapshot in order to facilitate a common mapping.
			</p>
			<p>- Beyond the first column, an open-ended number of columns can be provided.</p>
			<p>- All submitted columns will be treated as text strings.</p>
			<p>- Only Comma, Pipe, and Tab delimited submissions are supported.</p>
			<p>- Only TXT, CSV, AND TSV file types are supported.</p>
		</div>
	);
};

export const AggregateInformation = () => {
	return (
		<div>
			<p style={{color: '#135E82', fontWeight: 'bold'}}>
				A Child file can be used to aggregate to the account level (using a number of aggregation methods) and
				appended as additional columns to an existing Dataset Snapshot for use in Ki. To use Child file, please
				adhere to the following formatting requirements:
			</p>
			<p>- Header record reflecting the name of the column is required.</p>
			<p>- Required fields can be mapped to your file and must include:</p>
			<p style={{textIndent: '30px'}}>
				- Asset ID (Alphanumeric String): Asset ID should be common to the Aggregate File and Dataset Snapshot
				in order to facilitate a common mapping.
			</p>
			<p>
				- Beyond required column, any number of other text, date, and/or numeric columns can be included for
				aggregation.
			</p>
			<p>- Commas used as thousand separators are accepted within quotation marks (“1,000”).</p>
			<p>- Only Comma, Pipe, and Tab delimited submissions are supported.</p>
			<p>- Only TXT, CSV, AND TSV file extensions are supported.</p>
		</div>
	);
};

export default {
	AppendInformation,
	PaymentFileInformation,
	AggregateInformation,
};
