import React from 'react';
import PropTypes from 'prop-types';
import JournalTableItem from './JournalTableItem';

function JournalTable({journal}) {
	return (
		<div className="scenario-list-box list-box-size-2">
			{journal.length ? (
				<table className="scenario-list-table scenario-list-table-ledger journal">
					<thead>
						<tr>
							<th className="fixed-width-date">Posting Date</th>
							<th className="fixed-width-date">Effective Date</th>
							<th className="fixed-width">Post Code</th>
							<th>Description</th>
							<th>Line Item</th>
							<th className="fixed-width align-right">Account</th>
							<th className="fixed-width align-right">Account Code</th>
							<th className="fixed-width align-right">Debit</th>
							<th className="fixed-width align-right">Credit</th>
						</tr>
					</thead>
					<tbody>{journal.map((item, i) => <JournalTableItem item={item} key={i} />)}</tbody>
				</table>
			) : (
				<div className="no-list-items-container">
					<div>No posts found</div>
				</div>
			)}
		</div>
	);
}

JournalTable.propTypes = {
	journal: PropTypes.arrayOf(PropTypes.object),
};

JournalTable.defaultProps = {
	journal: [],
};

export default JournalTable;
