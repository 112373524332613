import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import options from 'ki-common/options';
import stringHelpers from 'ki-common/utils/stringHelpers';
import KiButton from 'components/KiButton';
import calcColumns from 'ki-common/utils/calcColumns';
import _get from 'lodash/get';
import * as columnServiceApi from '../../../api/columnServiceApi';
import {KiCreatable} from '../../KiSelect';

export class NumericSummaryForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		columnList: PropTypes.array.isRequired,
		closeForm: PropTypes.func.isRequired,
		isAdmin: PropTypes.bool,
		columnToEdit: PropTypes.object,
		existingTags: PropTypes.array,
		datasetId: PropTypes.string,
	};

	static defaultProps = {
		existingTags: [],
	};

	state = {
		name: '',
		tags: [],
		nameError: null,
		numericColumns: [],
		// aggregation items
		aggregationType: 'SUM',
		assetOption: {},
		calculateColumn: null,
		// ratio items
		numeratorOption: options.ratioOptions[0],
		denominatorOption: options.ratioOptions[0],
		numeratorColumn: null,
		denominatorColumn: null,
		availableForDebt: false,
		ignoreNullsZeros: false,
		isGlobal: false,
	};

	UNSAFE_componentWillMount() {
		const {columnToEdit, columnList} = this.props;

		this.setState(
			{
				numericColumns: this.props.columnList.filter(
					c => c.dataType === 'numeric' && c.columnType === 'asset' && c._id !== _get(columnToEdit, '_id')
				),
			},
			() => {
				if (columnToEdit) {
					const calculateColumn = columnToEdit.calculateColumnId ? columnToEdit.calculateColumn : null;

					this.setState({
						name: columnToEdit.displayName,
						tags: columnToEdit.tags || [],
						availableForDebt: !!columnToEdit.availableForDebt,
						ignoreNullsZeros: !!columnToEdit.ignoreNullsZeros || this.state.ignoreNullsZeros,
						aggregationType: columnToEdit.calculation,
						assetOption: columnToEdit.assetColumn,
						calculateColumn: calculateColumn,
						isGlobal: !columnToEdit.createdBy,
					});

					if (!columnToEdit.assetOption && columnToEdit.assetColumnId) {
						this.fetchAndSetAssetOption(columnToEdit.assetColumnId);
					}

					if (columnToEdit.formula) {
						this.setState({
							numeratorOption: options.ratioOptions.find(
								opt => opt.value === columnToEdit.formula.left.aggregate
							),
							numeratorColumn: columnList.find(c => c._id === columnToEdit.formula.left.value),
							denominatorOption: options.ratioOptions.find(
								opt => opt.value === columnToEdit.formula.right.aggregate
							),
							denominatorColumn: columnList.find(c => c._id === columnToEdit.formula.right.value),
						});
					}
				}
			}
		);
	}

	handleAggregationTypeChange = option => {
		this.setState({
			aggregationType: option.value,
			//calculateColumn: option.value === 'WGHTAVG' ? this.state.numericColumns[0] : null,
			calculateColumn: null,
		});
	};

	setName = val => {
		let nameError = null;

		if (!val.trim().length) {
			nameError = "Name can't be blank";
		} else if (!val.match(stringHelpers.alphanumericRegex)) {
			nameError = 'Name must be Alphanumeric!';
		}

		this.setState({
			name: val,
			nameError: nameError,
		});
	};

	isDuplicateNameError = () => {
		// Should be able to kepe the same name when editing a column
		if (_get(this, 'props.columnToEdit.displayName') === this.state.name) {
			return;
		}

		const summaryCols = this.props.columnList.filter(
			col => (col.columnType === 'aggregate' || col.columnType === 'ratio') && col.calculation
		);

		// Check if the name already exists in summaryCols
		return summaryCols.find(col => _get(col, 'displayName').trim() === this.state.name.trim());
	};

	fetchAndSetAssetOption = columnId => {
		columnServiceApi.getColumnByIdFromService(this.props.datasetId, columnId, true).then(opt => {
			delete opt.detailedDisplayName;
			delete opt.htmlDisplayName;
			this.setState({assetOption: opt});
		});
	};

	fetchAndSetCalculateColumn = columnId => {
		columnServiceApi.getColumnByIdFromService(this.props.datasetId, columnId, true).then(opt => {
			delete opt.detailedDisplayName;
			delete opt.htmlDisplayName;
			this.setState({calculateColumn: opt});
		});
	};

	getSaveAndFormatDisabled = () => {
		const {
			name,
			assetOption,
			aggregationType,
			numeratorOption,
			denominatorOption,
			numeratorColumn,
			denominatorColumn,
			calculateColumn,
		} = this.state;

		// Because assetOption starts as an empty object we have to check for the _id to make sure it
		// has been filled out, a straight !assetOption will alwasy be "truthy"
		if (aggregationType === 'RATIO') {
			return !name || !numeratorOption || !denominatorOption || !numeratorColumn || !denominatorColumn;
		} else if (aggregationType === 'WGHTAVG') {
			return !assetOption._id || !name || !calculateColumn;
		} else if (!assetOption?._id || !name) {
			return true;
		}
		return false;
	};

	onSubmit = () => {
		const {columnToEdit, datasetId} = this.props;
		const {
			name,
			aggregationType,
			assetOption,
			calculateColumn,
			tags,
			availableForDebt,
			ignoreNullsZeros,
			isGlobal,
		} = this.state;

		this.setState({isSaving: true});
		if (!this.state.name) {
			this.setState({nameError: 'Name cant be blank', isSaving: false});
			return;
		}

		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		}

		if (!name.match(stringHelpers.alphanumericRegex)) {
			this.setState({nameError: 'Name must be Alphanumeric!'});
			return;
		}

		if (!this.isDuplicateNameError()) {
			this.setState({nameError: ''});
		}

		let displayFormat = 'number';
		if (['COUNT_PCT', 'SUM_PCT'].includes(aggregationType)) {
			displayFormat = '';
		} else if (aggregationType === 'RATIO') {
			displayFormat = '9,999.99999';
		}

		const column = {
			_id: columnToEdit ? columnToEdit._id : null,
			datasetId: datasetId,
			columnName: name,
			displayName: name,
			displayFormat,
			dataType: 'numeric',
			columnType: 'aggregate',
			calculation: aggregationType,
			tags: tags,
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			availableForDebt: !!availableForDebt,
			ignoreNullsZeros: !!ignoreNullsZeros,
			isGlobal: isGlobal,
		};

		if (aggregationType === 'RATIO') {
			column.columnName = `${name}`;

			column.formula = calcColumns.createRatioColumn(this.state);
			column.assetColumn = null;
		}

		if (!column.formula) {
			column.assetColumnId = assetOption._id;
			column.assetColumn = assetOption;
		}

		if (!column.formula && aggregationType === 'WGHTAVG') {
			column.calculateColumnId = calculateColumn._id;
			column.calculateColumn = calculateColumn;
		}

		return this.props.submitMethod([column]);
	};

	isExposeToDebtCheckboxDisabled = (isAdmin, columnToEdit) => {
		if (!isAdmin) return true; //not an admin
		if (_get(columnToEdit, '_id')) {
			// existing column
			return !!columnToEdit.availableForDebt || !!columnToEdit.createdBy;
		}
		return false;
	};

	getForm = () => {
		const {
			aggregationType,
			numericColumns,
			numeratorOption,
			denominatorOption,
			numeratorColumn,
			denominatorColumn,
		} = this.state;

		if (aggregationType === 'RATIO') {
			return (
				<div>
					<section className="calculation-form-section">
						<span className="form-instruction">Numerator:</span>
						<Select
							classNamePrefix="aut-select"
							value={numeratorOption}
							isClearable={false}
							options={options.ratioOptions}
							onChange={selected => this.setState({numeratorOption: selected})}
						/>
					</section>
					<Select
						classNamePrefix="aut-select"
						value={numeratorColumn}
						isClearable={false}
						className="column-select"
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						options={numericColumns}
						onChange={column => this.setState({numeratorColumn: column})}
					/>
					<section className="calculation-form-section">
						<span className="form-instruction">Denominator:</span>
						<Select
							classNamePrefix="aut-select"
							value={denominatorOption}
							isClearable={false}
							options={options.ratioOptions}
							onChange={selected => this.setState({denominatorOption: selected})}
						/>
					</section>
					<Select
						classNamePrefix="aut-select"
						value={denominatorColumn}
						isClearable={false}
						className="column-select"
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						options={numericColumns}
						onChange={column => this.setState({denominatorColumn: column})}
					/>
				</div>
			);
		}

		return (
			<div>
				<section className="calculation-form-section">
					<span className="form-instruction">Select a Column to Aggregate by</span>

					<Select
						classNamePrefix="aut-select"
						value={this.state.assetOption}
						options={this.state.numericColumns}
						isClearable={false}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						onChange={opt => this.fetchAndSetAssetOption(opt._id)}
					/>
				</section>
				<section className="calculation-form-section">
					<div className={aggregationType !== 'WGHTAVG' ? 'hide-it' : ''}>
						<span className="form-instruction">Select Asset Column to Weight By</span>
						<Select
							classNamePrefix="aut-select"
							value={this.state.calculateColumn}
							isClearable={false}
							options={this.state.numericColumns}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							onChange={opt => this.fetchAndSetCalculateColumn(opt._id)}
						/>
					</div>
				</section>
			</div>
		);
	};

	render() {
		return (
			<section className="column-selector-form inline-column-form">
				<div>
					<KiInput
						label="Name"
						value={this.state.name}
						onChange={val => this.setName(val)}
						error={this.state.nameError}
					/>
					<section className="calculation-form-section">
						<span className="form-instruction">Tags:</span>
						<KiCreatable
							classNamePrefix="aut-select"
							isMulti={true}
							options={this.props.existingTags.map(t => ({
								value: t,
								label: t,
							}))}
							value={this.state.tags.map(t => ({value: t, label: t}))}
							onChange={val => this.setState({tags: val.map(t => t.value)})}
							placeholder="Add some tags"
							noResultsText={false}
						/>
					</section>
					<section className="calculation-form-section">
						<span className="form-instruction">Select Aggregation Type</span>
						<Select
							classNamePrefix="aut-select"
							value={options.summaryAggregateColumnTypes.find(
								opt => opt.value === this.state.aggregationType
							)}
							isClearable={false}
							isDisabled={!!_get(this, 'props.columnToEdit._id', null)}
							options={options.summaryAggregateColumnTypes}
							onChange={this.handleAggregationTypeChange}
						/>
						{(this.state.aggregationType === 'WGHTAVG' || this.state.aggregationType === 'AVG') && (
							<div className="calculation-form-section">
								<KiCheckbox
									name="ignoreNullsZeros"
									checked={!!this.state.ignoreNullsZeros}
									label="Exclude Nulls/Zeros"
									onChange={val => this.setState({ignoreNullsZeros: !!val})}
								/>
							</div>
						)}
					</section>
					{this.getForm()}
					<div className="calculation-form-section">
						<KiCheckbox
							name="availableForDebt"
							checked={!!this.state.availableForDebt}
							label="Expose to Debt"
							disabled={this.isExposeToDebtCheckboxDisabled(this.props.isAdmin, this.props.columnToEdit)}
							onChange={val =>
								this.setState(state => ({
									availableForDebt: !!val,
									isGlobal: !!val || !!state.isGlobal,
								}))
							}
						/>
						<KiCheckbox
							name="isGlobal"
							checked={this.state.isGlobal}
							disabled={!this.props.isAdmin || !!_get(this, 'props.columnToEdit._id', null)}
							label="Make Global"
							onChange={val =>
								this.setState({
									isGlobal: !!val,
									availableForDebt: !!val,
								})
							}
						/>
					</div>
				</div>
				<section className="format-and-save">
					<div className="inline-column-form-buttons">
						<KiButton flat primary onClick={() => this.props.closeForm()}>
							Cancel
						</KiButton>
						<KiButton
							disabled={this.getSaveAndFormatDisabled() || this.state.isSaving}
							raised
							primary
							onClick={() => this.onSubmit()}
						>
							Save
						</KiButton>
					</div>
				</section>
			</section>
		);
	}
}

export default NumericSummaryForm;
