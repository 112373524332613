import * as actionTypes from './actionTypes';
import _ from 'lodash';

const initialState = {
	error: null,
	isLoading: false,
	tableDataDirty: false,
	updatedActivityDates: [],
	data: {
		rows: [],
		columnInfo: [],
	},
	bookmarks: [],
	currentBookmarkView: null,
	dateContextMessage: '',
	appliedViewFilters: {},
	view: {
		name: 'Default Debt View',
		isDefault: true,
		isFavorite: false,
		createdBy: null,
		tags: [],
		isGadget: false,
		columns: [],
		settings: {
			datasetId: null,
			cohortColumn: 'fundingVehicle',
			viewType: 'summary',
			sortColumn: '',
			sortOrder: 'asc',
			sortCalculation: '',
			date: null,
			date_start: null,
			date_end: null,
			isCustomDateRange: false,
			fundingVehicleId: null,
			activityStartDate: 'collectionEndDate',
			activityEndDate: 'collectionEndDate',
			quickFilters: {
				scenarioType: 'lastApproved',
				scenarioId: null,
				hypoPoolId: null,
				fundingVehicleId: null,
				poolId: null,
				rangeType: 'static',
				rangeDate: null,
				rangeOccurrences: 1,
			},
			hasChanged: false,
		},
		viewColumns: {
			fundingVehicle: [],
			trust: [],
			pool: [],
			debt: [],
			creditSupport: [],
			fee: [],
			trigger: [],
		},
	},
	waterfallData: [],
	fundingVehicleDates: [],
};

export const DebtExplorer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DE_BOOKMARK_ADDED:
			return {
				...state,
				bookmarks: [...state.bookmarks, action.data],
			};
		case actionTypes.DE_BOOKMARK_UPDATED:
			return {
				...state,
				bookmarks: state.bookmarks.reduce(
					(acc, bookmark) => [...acc, bookmark._id === action.data._id ? action.data : bookmark],
					[]
				),
				view: {
					...state.view,
					name: action.data.name,
					isFavorite: action.data.isFavorite,
					isDefault: action.data.isDefault,
					isGlobal: action.data.isGlobal,
					createdBy: action.data.createdBy,
				},
			};
		case actionTypes.DEBT_EXPLORER_SET_DATASET:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						datasetId: action.datasetId,
					},
				},
			};
		case actionTypes.DEBT_EXPLORER_SET_COHORT_COLUMN:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						cohortColumn: action.columnId,
						...(action.markChange && {hasChanged: true}),
					},
				},
			};
		case actionTypes.DEBT_EXPLORER_SET_VIEW_TYPE:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						viewType: action.viewId,
					},
				},
			};
		case actionTypes.DEBT_EXPLORER_SET_DATE_CONTEXT:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						dateContext: action.dateContext,
					},
				},
			};
		case actionTypes.DEBT_EXPLORER_SET_FUNDING_VEHICLE_LIST:
			return {
				...state,
				fundingVehicleDates: action.data,
			};
		case actionTypes.DEBT_EXPLORER_SET_DATE:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						snapshotDate: action.dates.reportDate,
						start_date: action.dates.startDate,
						end_date: action.dates.endDate,
						...(action.markChange && {hasChanged: true}),
					},
				},
			};
		case actionTypes.DEBT_EXPLORER_SET_CUSTOM_START_DATE:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						start_date: action.date,
						...(action.markChange && {hasChanged: true}),
					},
				},
			};
		case actionTypes.DEBT_EXPLORER_SET_CUSTOM_END_DATE:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						end_date: action.date,
						...(action.markChange && {hasChanged: true}),
					},
				},
			};

		case actionTypes.DEBT_EXPLORER_SET_CUSTOM_DATE_RANGE:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						isCustomDateRange: action.isCustom,
						...(action.markChange && {hasChanged: true}),
					},
				},
			};

		case actionTypes.DEBT_EXPLORER_SET_FUNDING_VEHICLE:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						fundingVehicle: action.fundingVehicle,
					},
				},
			};
		case actionTypes.FETCH_DEBT_EXPLORATION_DATA:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						error: null,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						tableDataDirty: false,
						updatedActivityDates: [],
						data: action.data,
						error: null,
						isLoading: false,
					};

				case 'error':
					return {
						...state,
						error: action.error,
						isLoading: false,
					};
				default:
					return state;
			}

		case actionTypes.FETCH_WATERFALL_EXPLORATION_DATA:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						error: null,
						isLoading: true,
					};
				case 'success':
					return {
						...state,
						waterfallData: action.data,
						error: null,
						isLoading: false,
					};

				case 'error':
					return {
						...state,
						error: action.error,
						isLoading: false,
					};
				default:
					return state;
			}
		case actionTypes.DEBT_EXPLORER_UPDATE_ROW_DATA: {
			return {
				...state,
				tableDataDirty: true,
				data: {
					...state.data,
					rows: action.data,
				},
				error: null,
				isLoading: false,
			};
		}
		case actionTypes.DEBT_EXPLORER_UPDATE_ACTIVITY_DATES: {
			return {
				...state,
				updatedActivityDates: action.updatedActivityDates,
				error: null,
				isLoading: false,
			};
		}

		case actionTypes.DE_VIEW_FETCH:
		case actionTypes.DE_VIEW_DEFAULT_FETCH: {
			switch (action.status) {
				case 'pending':
					return {
						...state,
						isLoading: true,
					};
				case 'success': {
					// action.data.settings.snapshotDate =
					// 	action.data.settings.snapshotDate || dateToShortDate(new Date());
					return {
						...state,
						currentBookmarkView: action.data,
						view: action.data,
						isLoading: false,
					};
				}
				case 'error': {
					return {
						...state,
						error: action.error,
						isLoading: false,
					};
				}
				default:
					return state;
			}
		}

		case actionTypes.DE_APPLY_VIEW_CHANGES: {
			return {
				...state,
				view: {
					...state.view,
					name: action.name || state.view.name,
					isDefault: action.isDefault,
					isFavorite: action.isFavorite,
					isGlobal: action.isGlobal,
					settings: {
						activityStartDate: 'collectionEndDate',
						activityEndDate: 'collectionEndDate',
						...(action.settings || state.view.settings),
						quickFilters: {
							scenarioType: 'lastApproved',
							fundingVehicleId: null,
							poolId: null,
							...(action.settings.quickFilters || state.view.settings.quickFilters),
						},
					},
					viewColumns: action.viewColumns || state.view.viewColumns,
					tags: action.tags || state.view.tags,
				},
			};
		}

		case actionTypes.DE_BOOKMARK_DELETED: {
			return {
				...state,
				bookmarks: state.bookmarks.filter(x => x._id !== action.bookmarkId),
			};
		}

		case actionTypes.DE_VIEWS_FETCHED: {
			return {
				...state,
				bookmarks: action.data,
			};
		}

		case actionTypes.DE_QUICK_FILTER_SET: {
			if (['activityStartDate', 'activityEndDate'].includes(action.key)) {
				return {
					...state,
					view: {
						...state.view,
						settings: {
							...state.view.settings,
							[action.key]: action.value,
							...(action.markChange && {hasChanged: true}),
						},
					},
				};
			} else {
				return {
					...state,
					view: {
						...state.view,
						settings: {
							...state.view.settings,
							...(action.markChange && {hasChanged: true}),
							quickFilters: {
								...state.view.settings.quickFilters,
								[action.key]: action.value,
							},
						},
					},
				};
			}
		}

		case actionTypes.DE_SORT_SET:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						sortColumn: action.sortColumn,
						sortOrder: action.sortOrder,
						sortCalculation: action.sortCalculation,
					},
				},
			};

		case actionTypes.DE_DATE_CONTEXT_MSG_SET:
			return {
				...state,
				dateContextMessage: action.data,
			};

		case actionTypes.RESET_CHANGE_STATE:
			return {
				...state,
				view: {
					...state.view,
					settings: {
						...state.view.settings,
						hasChanged: false,
					},
				},
			};

		case actionTypes.DE_APPLY_VIEW_FILTERS:
			return {
				...state,
				appliedViewFilters: _.cloneDeep(state.view),
			};

		default:
			return state;
	}
};

export default DebtExplorer;
