import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import KiIconButton from 'components/KiIconButton';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import {connect} from 'react-redux';
import {TRANSACTION_TYPE_OPTIONS} from '../../../ledgerUtils';
import {removeTemplateLine} from './actions';

function TemplateLine({
	item,
	itemIndex,
	index,
	fvId,
	deleteDisabled,
	onItemChange,
	dateContextOptions,
	amountOptions,
	accountOptions,
	entityTypeOptions,
	entityNameOptions,
	removeTemplateLine,
	isVehicleLedger,
}) {
	const {description, account, dateContext, amount, entryType, entityType, entityName} = item;
	const [filteredAmountOptions, setFilteredAmountOptions] = useState(amountOptions);
	const [filteredEntityNameOptions, setFilteredEntityNameOptions] = useState([]);

	const filterVehicleAmountOptions = () => {
		const filteredAmountOptions = entityType
			? amountOptions.filter(amount => amount.entityType === entityType.value)
			: [];
		setFilteredAmountOptions(filteredAmountOptions);
	};

	const filterVehicleEntityNameOptions = () => {
		const filteredEntityNameOptions = entityType
			? entityNameOptions.filter(
					name => name.rowType === entityType.value && (name.fvId === fvId || name.value === fvId)
			  )
			: [];
		setFilteredEntityNameOptions(filteredEntityNameOptions);
	};

	useEffect(
		() => {
			if (isVehicleLedger) {
				filterVehicleAmountOptions();
			} else {
				setFilteredAmountOptions(amountOptions);
			}
		},
		[entityType, amountOptions]
	);

	useEffect(
		() => {
			filterVehicleEntityNameOptions();
		},
		[entityType, entityNameOptions]
	);

	return (
		<div className="horizontal-inputs-container">
			<div className="long-wrapper">
				<KiInput
					type="text"
					label="Line Description"
					value={description}
					onChange={value => onItemChange('description', index, value)}
				/>
			</div>
			<div>
				<span className="theme-label">Account</span>
				<KiSelect
					options={accountOptions}
					value={account}
					onChange={value => onItemChange('account', index, value)}
				/>
			</div>
			{isVehicleLedger && (
				<Fragment>
					<div>
						<span className="theme-label">Entity Type</span>
						<KiSelect
							options={entityTypeOptions}
							value={entityType}
							onChange={value => onItemChange('entityType', index, value)}
						/>
					</div>
					<div>
						<span className="theme-label">Entity Name</span>
						<KiSelect
							options={filteredEntityNameOptions}
							value={entityName}
							onChange={value => onItemChange('entityName', index, value)}
						/>
					</div>
				</Fragment>
			)}
			<div>
				<span className="theme-label">Date Context</span>
				<KiSelect
					options={dateContextOptions}
					value={dateContext}
					onChange={value => onItemChange('dateContext', index, value)}
				/>
			</div>
			<div>
				<span className="theme-label">Amount</span>
				<KiSelect
					options={filteredAmountOptions}
					value={amount}
					onChange={value => onItemChange('amount', index, value)}
				/>
			</div>
			<div>
				<span className="theme-label">Debit/Credit</span>
				<KiSelect
					options={TRANSACTION_TYPE_OPTIONS}
					value={entryType}
					onChange={value => onItemChange('entryType', index, value)}
				/>
			</div>
			<div className="to-right button-wrapper button-wrapper-size-1">
				<KiIconButton
					icon="delete"
					disabled={deleteDisabled}
					onClick={() => removeTemplateLine(itemIndex, index)}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = ({ledgerTemplateConfiguration}) => ({
	dateContextOptions: ledgerTemplateConfiguration.dateContextOptions,
	amountOptions: ledgerTemplateConfiguration.amountOptions,
	accountOptions: ledgerTemplateConfiguration.accountOptions,
	entityTypeOptions: ledgerTemplateConfiguration.entityTypeOptions,
	entityNameOptions: ledgerTemplateConfiguration.entityNameOptions,
	isVehicleLedger: ledgerTemplateConfiguration.isVehicleLedger,
});

TemplateLine.propTypes = {
	item: PropTypes.object.isRequired,
	itemIndex: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	fvId: PropTypes.string,
	deleteDisabled: PropTypes.bool.isRequired,
	onItemChange: PropTypes.func.isRequired,
	removeTemplateLine: PropTypes.func.isRequired,
	dateContextOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	amountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	entityTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	entityNameOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	isVehicleLedger: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {removeTemplateLine})(TemplateLine);
