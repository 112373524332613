import React from 'react';
import PropTypes from 'prop-types';
import KiSelect from '../../../../../components/KiSelect';
import KiCheckbox from '../../../../../components/KiCheckbox';
import styles from './Delinqnecy.theme.scss';
import KiRadioGroup from '../../../../../components/KiRadioGroup';
import classNames from 'classnames';
import KiRadioButton from '../../../../../components/KiRadioButton';
import {getDelinquencyStartingStructure} from './Delinquency';

const MIN_MONTH_OPTIONS = new Array(12).fill({}).map((item, index) => ({label: index + 1, value: index + 1}));

const DelinquencyNonPerformingForm = ({subFormData, onChange}) => {
	const onFlagChange = value => {
		const change = value
			? {...getDelinquencyStartingStructure().nonPerfLoansToDef, flag: true}
			: getDelinquencyStartingStructure().nonPerfLoansToDef;
		onChange(change);
	};

	return (
		<>
			<div className="sidebar-form-section">
				<KiRadioGroup
					className={classNames('input-row-radio-group', styles.radioGroup)}
					value={subFormData.flag ? 'yes' : 'no'}
					onChange={value => onFlagChange(value === 'yes')}
				>
					<KiRadioButton label="Yes" value="yes" />
					<KiRadioButton label="No" value="no" />
				</KiRadioGroup>
			</div>
			{subFormData.flag && (
				<>
					<div className="sidebar-form-section">
						<span className="form-instruction">Delinquent Min Months</span>
						<KiSelect
							options={MIN_MONTH_OPTIONS}
							onChange={option => onChange({delinqMinMonths: option.value})}
							value={MIN_MONTH_OPTIONS.filter(opt => opt.value === subFormData.delinqMinMonths)}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Bankrupt Min Months</span>
						<KiSelect
							options={MIN_MONTH_OPTIONS}
							onChange={option => onChange({bankruptMinMonths: option.value})}
							value={MIN_MONTH_OPTIONS.filter(opt => opt.value === subFormData.bankruptMinMonths)}
						/>
					</div>
					<div className={styles.checkboxes}>
						<KiCheckbox
							checked={subFormData.reoFlag}
							label="REO"
							onChange={value => onChange({reoFlag: value})}
						/>
						<KiCheckbox
							checked={subFormData.foreclosedFlag}
							label="Foreclosed"
							onChange={value => onChange({foreclosedFlag: value})}
						/>
					</div>
				</>
			)}
		</>
	);
};

DelinquencyNonPerformingForm.propTypes = {
	subFormData: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default DelinquencyNonPerformingForm;
