import {businessFunctionsApi, datasetsApi} from 'api';
import * as actionTypes from './actionTypes';

const fetchBusinessFunctionColumnsByDatasetId = datasetId => dispatch => {
	dispatch({type: actionTypes.BUSINESS_FUNCTIONS_FETCH_COLUMNS_BY_DATASETID, status: 'pending'});
	return datasetsApi
		.fetchBusinessFunctionColumns(datasetId)
		.then(bfColumns => {
			dispatch({
				type: actionTypes.BUSINESS_FUNCTIONS_FETCH_COLUMNS_BY_DATASETID,
				status: 'success',
				data: bfColumns,
			});
		})
		.catch(e =>
			dispatch({
				type: actionTypes.BUSINESS_FUNCTIONS_FETCH_COLUMNS_BY_DATASETID,
				status: 'error',
				error: e,
			})
		);
};

const fetchDefaultMappingById = mappingId => dispatch => {
	dispatch({type: actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPING, status: 'pending'});
	return businessFunctionsApi
		.fetchDefaultMappingById(mappingId)
		.then(bfMapping => {
			dispatch({
				type: actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPING,
				status: 'success',
				data: bfMapping,
			});
		})
		.catch(e => dispatch({type: actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPING, status: 'error', error: e}));
};

const fetchDefaultMappingsByDatasetId = datasetId => dispatch => {
	dispatch({type: actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPINGS_BY_DATASET, status: 'pending'});
	return businessFunctionsApi
		.fetchDefaultMappingsByDatasetId(datasetId)
		.then(bfMappings => {
			dispatch({
				type: actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPINGS_BY_DATASET,
				status: 'success',
				data: bfMappings,
			});
		})
		.catch(e =>
			dispatch({
				type: actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPINGS_BY_DATASET,
				status: 'error',
				error: e,
			})
		);
};

const updateDefaultMapping = (mappingId, updatedMapping) => dispatch => {
	dispatch({type: actionTypes.BUSINESS_FUNCTIONS_UPDATE_DEFAULT_MAPPINGS, status: 'pending'});
	return businessFunctionsApi
		.updateDefaultMapping(mappingId, updatedMapping)
		.then(resMapping => {
			dispatch({
				type: actionTypes.BUSINESS_FUNCTIONS_UPDATE_DEFAULT_MAPPINGS,
				status: 'success',
				data: resMapping,
			});
			dispatch(fetchBusinessFunctionColumnsByDatasetId(updatedMapping.datasetId));
		})
		.catch(e => {
			dispatch({type: actionTypes.BUSINESS_FUNCTIONS_UPDATE_DEFAULT_MAPPINGS, status: 'error', error: e});
		});
};

const clearBusinessFunctionColumnsByDatasetId = () => ({
	type: actionTypes.CLEAR_BUSINESS_FUNCTION_COLUMNS_BY_DATASET_ID,
});

export {
	fetchDefaultMappingById,
	fetchDefaultMappingsByDatasetId,
	updateDefaultMapping,
	fetchBusinessFunctionColumnsByDatasetId,
	clearBusinessFunctionColumnsByDatasetId,
};
