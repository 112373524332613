import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiDatePicker from 'components/KiDatePicker';
import KiButton from 'components/KiButton';
import dateHelpers from 'ki-common/utils/dateHelpers';
import validators from 'ki-common/validators';
import {SelectorWithAdd} from 'components/SelectorWithAdd';
import CollapsiblePanel from 'components/CollapsiblePanel';
import KiProgressBar from 'components/KiProgressBar';
import {apiUrl} from 'api/apiUtils';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import {getSortAscFuncByField} from 'ki-common/utils/sorters';
import Select from 'react-select';
import {AbsUploader, ABSUPLOADER_STATUSES} from '@moodysanalytics/cs-structured-ux-common';
import './SubmissionUpload.scss';

const constraints = validators.submissions.submissionUpload.getConstraints();
export class SubmissionUpload extends Component {
	static propTypes = {
		routeToNewSubmission: PropTypes.func,
		submission: PropTypes.object,
		categorySchemaList: PropTypes.array,
		isSubmissionUploading: PropTypes.bool.isRequired,
		testNameDateUniqueFunc: PropTypes.func.isRequired,
		updateSubmissionAndSchema: PropTypes.func.isRequired,
		showSnackbar: PropTypes.func.isRequired,
		params: PropTypes.object,
		dataset: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			formValues: {
				name: '',
				snapshotDate: '',
				delimiter: 'comma',
			},
			uploadId: null,
			nameList: [],
			formErrors: {},
			isReadOnly: false,
			uploadDisabled: true,
			refreshWarning: '',
			uploadError: null,
			collapsed: false,
			isUploading: false,
			queuedFile: null,
		};
		// Uploader
		this.uploaderRef = React.createRef();
		this.uploader = {
			options: {
				autoUpload: false,
				validation: {
					allowedExtensions: ['csv', 'txt', 'tsv', 'csv.gz', 'txt.gz', 'tsv.gz', 'gz'],
				},
				chunkingEnabled: true,
				chunkSize: 1000000,
				endpoint: `${apiUrl}/submission`,
			},
			callbacks: {
				onSubmit: id => {
					this.setState({queuedFile: id});
				},
				onStatusChange: (id, oldStatus, newStatus) => {
					this.setState({
						isUploading: newStatus === ABSUPLOADER_STATUSES.UPLOADING,
					});
				},
				onUploadChunkSuccess: (id, partIndex, totalParts, res) => {
					if (res.id && partIndex === 0) {
						this.setState({collapsed: true});
						this.uploader.params = {
							submissionId: res.id,
							categorySchemaName: this.state.formValues.name,
							snapshotDate: dateHelpers.dateToShortDate(this.state.formValues.snapshotDate),
							delimiter: this.state.formValues.delimiter,
						};
						this.props.routeToNewSubmission(res.id, this.props.dataset.datasetId || 'create');
					}
				},
				onError: (id, name, errorReason, response) => {
					if (errorReason && !response) {
						this.props.showSnackbar(errorReason);
						return;
					}
					this.setState({
						uploadError: response.message,
						collapsed: true,
					});
					const submissionId = _get(response, 'data.id');
					if (submissionId) {
						this.props.routeToNewSubmission(submissionId, this.props.dataset.datasetId || 'create');
					}
				},
			},
		};
	}

	componentDidMount() {
		this.setNameList(this.props.categorySchemaList);
		this.setControls(this.props.isSubmissionUploading, this.props.submission);
		this.setFormValues(this.props.submission);

		if (this.props.params && this.props.params.id !== 'upload') {
			this.setState({collapsed: true});
		}
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			if (this.props.submission._id !== prevProps.submission._id) {
				this.setFormValues(this.props.submission);
			}
			if (!_isEqual(this.props.categorySchemaList, prevProps.categorySchemaList)) {
				this.setNameList(this.props.categorySchemaList);
			}
			this.setControls(this.props.isSubmissionUploading, this.props.submission);
		}
	}

	isExistingSubmission = () => {
		if (this.props.submission._id) {
			return true;
		}
		return false;
	};

	// If submission record exists
	// If name is empty or has an error
	// If snapshostDate is empty or has an error
	isUploadDisabled = (formValues, formErrors) => {
		const values = formValues || this.state.formValues;
		const errors = formErrors || this.state.formErrors;

		return this.isExistingSubmission() ||
			!values.name ||
			errors.name ||
			!values.snapshotDate ||
			this.state.isUploading ||
			errors.snapshotDate
			? true
			: false;
	};

	setNameList = categorySchemaList => {
		const nameList = _uniq(_map(categorySchemaList, 'name')).sort(getSortAscFuncByField('name'));
		this.setState({nameList});
	};

	setControls = (isUploading, submission) => {
		// If the file is currently uploading OR has an ID OR name is not selected OR snapshotDate is not selected
		const isUploadDisabled = isUploading || this.isUploadDisabled() || this.state.fileId ? true : false;

		this.setState({
			uploadDisabled: isUploadDisabled,
			isReadOnly: isUploading || submission?.status?.name === 'Commit Complete',
		});
	};

	setFormValues = formValues => {
		const name = this.props.dataset ? this.props.dataset.name : _get(formValues, 'categorySchemaName', '');
		this.setState({
			formValues: {
				name: name,
				snapshotDate: _get(formValues, 'snapshotDate', '')
					? dateHelpers.shortDateToDate(formValues.snapshotDate)
					: new Date(),
				delimiter: _get(formValues, 'delimiter', 'comma'),
			},
			formErrors: {},
			refreshWarning: '',
			uploadDisabled: this.isUploadDisabled(),
		});
	};

	testNameDateUnique = () => {
		const {name, snapshotDate} = this.state.formValues;

		if (name && snapshotDate) {
			return this.props.testNameDateUniqueFunc(name, dateHelpers.dateToShortDate(snapshotDate)).then(results => {
				this.setState({
					refreshWarning:
						!results.isNameUnique && !results.isSnapshotDateUnique
							? 'File was previously submitted proceeding will refresh your data'
							: '',
				});
			});
		}
	};

	valueChanged = (name, value) => {
		const error = validators.validateSingle(value.value || value, constraints[name]);
		const formValues = {
			...this.state.formValues,
			[name]: value.value || value,
		};
		const formErrors = {
			...this.state.formErrors,
			[name]: (error && error[0]) || '',
		};
		const uploadDisabled = this.isUploadDisabled(formValues, formErrors);

		this.setState(
			{
				formValues,
				formErrors,
				refreshWarning: '',
				uploadDisabled,
			},
			() => {
				if (this.isExistingSubmission()) {
					this.props.updateSubmissionAndSchema(
						this.props.submission._id,
						this.state.formValues.name,
						dateHelpers.dateToShortDate(this.state.formValues.snapshotDate),
						this.state.formValues.delimiter
					);
				} else {
					this.testNameDateUnique();
				}
			}
		);
	};

	nameChange = val => {
		if (val && !_includes(this.state.nameList, val)) {
			// Make sure a a new val is both passed and not in list
			const newNameList = this.state.nameList.slice(0); // Duplicate state list
			newNameList.push(val); // Add new value
			this.setState({
				nameList: newNameList, // Update value on state
			});
		}
		this.valueChanged('name', val);
	};

	startUpload = () => {
		const error = validators.validate(this.state.formValues, constraints);
		if (error) {
			const newFormErrors = _reduce(
				error,
				(result, value, key) => {
					result[key] = value[0]; //eslint-disable-line prefer-destructuring
					return result;
				},
				{}
			);
			this.setState({
				formErrors: newFormErrors,
			});
		} else {
			this.uploader.params = {
				categorySchemaName: this.state.formValues.name,
				snapshotDate: dateHelpers.dateToShortDate(this.state.formValues.snapshotDate),
				delimiter: this.state.formValues.delimiter,
			};
			this.uploaderRef.current.triggerUpload();
		}
	};

	delimiterOptions = [{value: 'comma', label: 'Comma'}, {value: 'pipe', label: 'Pipe'}, {value: 'tab', label: 'Tab'}];

	datasetNameValidation = value => !value || value.match(/^[^/?%#]+$/g);

	render() {
		if (!this.props.submission) {
			return null;
		}
		const {collapsed} = this.state;
		return (
			<div className="submission-upload">
				<div className={`upload-error-panel ${!this.state.uploadError ? 'hidden' : ''}`}>
					{this.state.uploadError}
				</div>
				<CollapsiblePanel
					className="ki-panel"
					title={
						<div className="top-bar-breadcrumb">
							<KiProgressBar
								className={this.state.isUploading && !collapsed ? 'loading-ani' : 'loading-ani hidden'}
							/>
							<p className="submission-upload-header-content">
								Upload:
								<span>{this.props.submission.categorySchemaName}</span>
								<span>{this.props.submission.snapshotDate}</span>
								<span>{this.props.submission.filename}</span>
							</p>
						</div>
					}
					collapsed={collapsed}
					onCollapseToggleClick={() => this.setState({collapsed: !collapsed})}
				>
					<div className="submission-upload-body">
						<div className="submission-upload-controls">
							<div className="upload-body-section submission-category">
								{this.props.dataset._id ? (
									<React.Fragment>
										<label>Dataset</label>
										<div className="read-only-content">{this.props.dataset.name}</div>
									</React.Fragment>
								) : (
									<React.Fragment>
										<label>Create or Choose a Dataset</label>
										<SelectorWithAdd
											className="category-name-selector"
											options={this.state.nameList.map(name => ({
												value: name,
												label: name,
											}))}
											value={this.state.formValues.name}
											onChangeFunc={val => this.nameChange(val)}
											startingValue={this.state.formValues.name}
											disabled={this.props.dataset ? true : this.state.isReadOnly}
											sortOrder={'asc'}
											newOptionValidation={this.datasetNameValidation}
											newOptionErrorMessage={
												'Dataset name not valid. "/", "?", "%" , “#” are not allowed.'
											}
										/>
									</React.Fragment>
								)}
								<div className="submission-refresh-warning">{this.state.refreshWarning}</div>
								<span className="category-name-error">{this.state.formErrors.name}</span>
							</div>
							<div className="upload-body-section submission-snapshot-date">
								<label>Snapshot Date</label>
								<KiDatePicker
									error={this.state.formErrors.snapshotDate}
									onChange={val => this.valueChanged('snapshotDate', val)}
									onBlur={val => this.valueChanged('snapshotDate', val)}
									value={this.state.formValues.snapshotDate}
									disabled={this.state.isReadOnly}
								/>
							</div>
							<div className="upload-body-section submission-delimiter">
								<label>Delimiter</label>
								<Select
									value={this.delimiterOptions.find(
										opt => opt.value === this.state.formValues.delimiter
									)}
									classNamePrefix="aut-select"
									closeOnSelect={true}
									hint="Select..."
									isClearable={false}
									options={this.delimiterOptions}
									onChange={val => this.valueChanged('delimiter', val)}
									isDisabled={this.state.isReadOnly}
								/>
							</div>
							<div className="upload-body-section submission-file">
								<AbsUploader
									config={this.uploader}
									ref={this.uploaderRef}
									disabled={!!this.state.uploadError}
								/>
							</div>
						</div>
						<div className="submssion-upload-cta">
							<KiButton
								onMouseUp={this.startUpload}
								label="Start Upload"
								icon="cloud_upload"
								raised
								primary
								disabled={this.state.isUploading || this.state.uploadDisabled}
							/>
						</div>
					</div>
				</CollapsiblePanel>
			</div>
		);
	}
}

export default SubmissionUpload;
