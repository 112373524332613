import moment from 'moment';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {KiDatePicker, KiButton} from 'components';
import {KiIconCsv, KiIconLink} from 'components/KiIcons';
import {waterfallApi} from 'api';
import ScenarioFormModal, {PRICING_FULL_OPTIONS} from './ScenarioFormModal';
import {setScenarioDisplayMode} from '../../../actions';
import {SCENARIO_DISPLAY_MODES} from '../../../reducer';

const ReadOnlyControlPanel = props => {
	const [modalVisible, setModalVisible] = useState(false);
	const [statementDateValue, setStatementDateValue] = useState('');
	const [runButtonEnabled, setRunButtonEnabled] = useState(true);
	const [generateError, setGenerateError] = useState('');
	const runProjections = async scenario => {
		setRunButtonEnabled(false);
		setGenerateError('');
		try {
			await waterfallApi.runProjections({
				scenarioId: scenario._id,
				snapshotDate: moment(statementDateValue).format('YYYY-MM-DD'),
				modelId: scenario.model._id,
				fvId: scenario.collateral?.length === 1 ? scenario.collateral?.[0]?.fvId : undefined,
				datasetId: props.scenario.dataset.datasetId,
				replinesId: scenario.repline._id,
				call: scenario.call._id,
				callDate: scenario.callDate,
				collateral: scenario.collateral,
				filters: scenario.filters,
				pricing: scenario.pricing,
				pricingAssumptionId: scenario.pricing === 'all' ? scenario.pricingAssumptionId : undefined,
				pricingMapping: scenario.pricing === 'custom' ? scenario.pricingMapping : undefined,
			});
			setRunButtonEnabled(true);
			return props.requeryScenarioRunResults(scenario._id);
		} catch (error) {
			setGenerateError(`${error.message}`);
			setRunButtonEnabled(true);
			// failed pricing wiil still yield results
			return props.requeryScenarioRunResults(scenario._id);
		}
	};
	return (
		<div className="new-scenario-container">
			<section className="new-scenario-row first-row">
				<div className="cohort">
					<i
						title="Back"
						className={'material-icons'}
						onClick={() => {
							if (props.scenarioDisplayMode === SCENARIO_DISPLAY_MODES.RUN_REVIEW) {
								props.setScenarioDisplayMode(SCENARIO_DISPLAY_MODES.DETAIL);
							} else {
								props.setScenarioDisplayMode(SCENARIO_DISPLAY_MODES.LIST);
								props.setScenarioInfo({});
							}
						}}
					>
						arrow_back
					</i>
				</div>
				<div className="scenario-ropanel-headeritem">
					<div className="scenarioInfoPanel">{props.scenario?.name}</div>
				</div>
				<div className="scenario-ropanel-headeritem">
					<div className="scenarioInfoPanel">Dataset: {props.scenario?.dataset?.name}</div>
					<div className="scenarioInfoPanel">
						Collateral:{' '}
						{props.scenario?.collateral?.length === 0
							? 'None'
							: props.scenario?.collateral?.map?.(item => item.name).join(', ')}
					</div>
					<div className="scenarioInfoPanel">Filter: {props.scenario?.filters?.[0]?.name}</div>
					<div className="scenarioInfoPanel">Model: {props.scenario?.model?.name}</div>
					<div className="scenarioInfoPanel">Repline Set: {props.scenario?.repline?.name}</div>
					<div className="scenarioInfoPanel">
						Pricing: {PRICING_FULL_OPTIONS.find(item => item.value === props.scenario?.pricing)?.label}
					</div>
					<div className="scenarioInfoPanel">Call: {props.scenario.call?.name}</div>
				</div>
				<div className="cohort">
					<i title="Edit" className={'material-icons'} onClick={() => setModalVisible(true)}>
						mode_edit
					</i>
				</div>
				<div className="scenario-ropanel-headeritem">
					<div>Statement Date</div>
					<KiDatePicker value={statementDateValue} onChange={val => setStatementDateValue(val)} />
				</div>
				<div className="cohort">
					{generateError !== '' && (
						<div style={{textAlign: 'right', marginBottom: '10px'}}>
							<p className="text-error-red">{`Generate failed - ${generateError}`}</p>
						</div>
					)}
					<KiButton
						primary
						raised
						disabled={!runButtonEnabled}
						label={runButtonEnabled ? 'RUN' : 'Running...'}
						className="backButton"
						onClick={() => runProjections(props.scenario)}
					/>
				</div>
				{props.scenarioDisplayMode === SCENARIO_DISPLAY_MODES.RUN_REVIEW ? (
					<div>
						<a
							href={`web/projectionResults/${props.modelResults._id}?format=csv`}
							className="icon-link"
							title="Download CSV"
							download
						>
							<KiIconCsv />
						</a>
						<a
							href={`web/projectionResults/${props.modelResults._id}?format=json`}
							className="icon-link"
							title="Download JSON"
							download
						>
							<KiIconLink />
						</a>
					</div>
				) : (
					<div />
				)}
			</section>
			{modalVisible && (
				<ScenarioFormModal scenario={props.scenario} isActive={true} onClose={() => setModalVisible(false)} />
			)}
		</div>
	);
};

ReadOnlyControlPanel.propTypes = {
	scenario: PropTypes.object.isRequired,
	modelResults: PropTypes.object.isRequired,
	scenarioDisplayMode: PropTypes.string.isRequired,
	setScenarioDisplayMode: PropTypes.func.isRequired,
	setScenarioInfo: PropTypes.func.isRequired,
	requeryScenarioRunResults: PropTypes.func.isRequired,
};

ReadOnlyControlPanel.defaultProps = {};

const mapStateToProps = state => ({
	scenarioDisplayMode: state.forecasting.scenarioDisplayMode,
});

const mapDispatchToProps = {
	setScenarioDisplayMode,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReadOnlyControlPanel);
