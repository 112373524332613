import PropTypes from 'prop-types';
import React, {Component} from 'react';
import _get from 'lodash/get';
import styles from './ConditionOverview.theme.scss';

class ConditionOverviewRow extends Component {
	static propTypes = {
		id: PropTypes.string,
		caseStatements: PropTypes.array,
		then: PropTypes.object,
		onMoveUpClick: PropTypes.func,
		onMoveDownClick: PropTypes.func,
		onEditClick: PropTypes.func,
		onDeleteClick: PropTypes.func,
		selected: PropTypes.bool,
	};
	static defaultProps = {
		then: null,
		caseStatements: [],
	};

	calculateDisplayName = (caseStatements = []) => {
		let result = caseStatements.reduce((acc, cs) => {
			let caseStatementResult = _get(cs.operands, '0.value.displayName', _get(cs.operands, '0.value', ''));
			caseStatementResult += ' ';
			let operator = _get(cs, 'operator.value', '');
			if (operator === 'notIn') {
				operator = 'not in';
			}
			caseStatementResult += operator.toLowerCase();
			caseStatementResult += ' ';
			caseStatementResult += _get(cs.operands, '1.value.displayName', _get(cs.operands, '1.value', ''));
			caseStatementResult += ' ';
			caseStatementResult += _get(cs, 'logic.label', '').toLowerCase();
			caseStatementResult += ' ';
			return acc + caseStatementResult;
		}, '');
		if (this.props.then && this.props.then.value) {
			result += `then ${_get(this.props, 'then.value.displayName', this.props['then'].value.toString())}`;
		}
		return result;
	};

	render() {
		const {id, caseStatements, onMoveUpClick, onMoveDownClick, onEditClick, onDeleteClick, selected} = this.props;

		return (
			<div className={`${styles.row} ${selected ? styles.selectedRow : ''}`}>
				<div className={styles.display}>
					<span className={styles.type}>Condition</span>
					<span>{this.calculateDisplayName(caseStatements)}</span>
				</div>
				<div className={styles.rowActions}>
					<i title="Move Up" className="material-icons" onClick={() => onMoveUpClick(id)}>
						keyboard_arrow_up
					</i>
					<i title="Move Down" className="material-icons" onClick={() => onMoveDownClick(id)}>
						keyboard_arrow_down
					</i>
					<i title="Edit" className="material-icons" onClick={() => onEditClick(id)}>
						mode_edit
					</i>
					<i title="Delete" className="material-icons" onClick={() => onDeleteClick(id)}>
						delete
					</i>
				</div>
			</div>
		);
	}
}

class ConditionOverview extends Component {
	static propTypes = {
		conditions: PropTypes.array,
		elseValue: PropTypes.string,
		onMoveUpClick: PropTypes.func,
		onMoveDownClick: PropTypes.func,
		onEditClick: PropTypes.func,
		onDeleteClick: PropTypes.func,
		selectedId: PropTypes.string,
		resetForm: PropTypes.func,
	};

	static defaultProps = {
		conditions: [],
		onMoveUpClick: () => null,
		onMoveDownClick: () => null,
		onEditClick: () => null,
		onDeleteClick: () => null,
		resetForm: () => null,
	};

	state = {
		collapsed: false,
	};

	render() {
		const {
			elseValue = '',
			conditions = [],
			onMoveUpClick,
			onMoveDownClick,
			onEditClick,
			onDeleteClick,
			selectedId,
			resetForm,
		} = this.props;

		const {collapsed} = this.state;

		if (!conditions.length) {
			return null;
		}

		return (
			<article className={styles.root}>
				<header className={styles.header}>
					<div>Logic</div>
					<i
						className={`${styles.panelCollapseIcon} material-icons ${collapsed ? 'collapsed' : ''}`}
						onClick={() => this.setState(state => ({collapsed: !state.collapsed}))}
					>
						play_arrow
					</i>
				</header>
				{!collapsed && (
					<section className={styles.body}>
						{conditions.map((condition, idx) => (
							<ConditionOverviewRow
								selected={selectedId === condition.id}
								caseStatements={condition.caseStatements}
								then={condition.then}
								key={condition.id}
								position={idx}
								id={condition.id}
								onMoveUpClick={onMoveUpClick}
								onMoveDownClick={onMoveDownClick}
								onEditClick={selectedId === condition.id ? resetForm : onEditClick}
								onDeleteClick={onDeleteClick}
							/>
						))}
						{!!elseValue && (
							<div className={styles.row}>
								<div className={styles.display}>
									<span className={styles.type}>Else</span>
									<span>{elseValue}</span>
								</div>
							</div>
						)}
					</section>
				)}
			</article>
		);
	}
}

export default ConditionOverview;
