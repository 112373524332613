import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import stylesForm from '../common/Form.theme.scss';
import styles from './VectorForm.theme.scss';
import classNames from 'classnames';
import {useMergedState} from '../../../../utils/customHooks';
import NameForm from '../common/NameForm';
import KiSelect from '../../../../components/KiSelect';
import FormCloseSection from '../common/FormCloseSection';
import {seasoningOptions, vectorTypeOptions} from 'ki-common/options/assumptions';
import KiButton from '../../../../components/KiButton';
import KiInput from '../../../../components/KiInput';
import KiIconButton from '../../../../components/KiIconButton';
import {removeFromArray, updateInArray} from '../../../../utils/arrayUtils';
import {getVector, saveVector, getVectors} from '../../../../api/waterfallApi';
import KiMenu from '../../../../components/KiMenu';
import KiMenuItem from '../../../../components/KiMenu/KiMenuItem';
import FormBlockItemContainer from '../common/FormBlockItemContainer';
import ManualPeriodForm from './components/ManualPeriodForm';
import MultiplePeriodsForm from './components/MultiplePeriodsForm';
import PeriodPasteForm from './components/PeriodPasteForm';
import ImportPeriodForm from './components/ImportPeriodForm';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../../state/actions/Snackbar';

const VectorForm = ({setFlyoutMode, editItemId, isCopyMode, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [vectors, setVectors] = useState([]);
	const [formData, mergeFormData, setFormData] = useMergedState({
		name: '',
		type: '',
		seasoning: seasoningOptions[0].value,
		description: '',
		period: [],
	});
	const [validationErrors, mergeValidationErrors, setValidationErrors] = useMergedState({
		name: '',
		period: [],
	});
	const [visiblePeriodForm, setVisiblePeriodForm] = useState(null);
	const PERIOD_FORMS = {
		MANUAL: 'Manually',
		PASTE: 'Paste values',
		IMPORT: 'Import from file',
	};

	useEffect(() => {
		setIsLoading(true);
		getVectors().then(data => {
			setVectors(data);
			setIsLoading(false);
		});
	}, []);

	const resetValidationErrors = periodsNumber => {
		setValidationErrors({
			name: null,
			period: Array(periodsNumber).fill(null),
		});
	};

	useEffect(
		() => {
			if (editItemId) {
				getVector(editItemId).then(vector => {
					if (isCopyMode) {
						delete vector['_id'];
						vector.name = `${vector.name} copy`;
					}
					resetValidationErrors(vector.period ? vector.period.length : 0);
					setFormData({
						seasoning: seasoningOptions[0].value,
						description: '',
						period: [],
						...vector,
					});
				});
			}
		},
		[editItemId]
	);

	const isTypeRate = formData.type === vectorTypeOptions[0].value;

	const validateValue = value =>
		value ? (parseFloat(value) > 100 && isTypeRate ? 'Must be between 0-100' : null) : 'Please specify value';

	const addNewPeriods = addPeriods => {
		setFormData(data => {
			const newPeriods = [...data.period, ...addPeriods];
			newPeriods.sort((itemOne, itemTwo) => (parseInt(itemOne.month) > parseInt(itemTwo.month) ? 1 : -1));
			return {...data, period: newPeriods};
		});
	};

	useEffect(
		() => {
			mergeValidationErrors({
				period:
					formData.period && formData.period.length > 0
						? formData.period.map(item => validateValue(item.value))
						: [],
			});
		},
		[formData]
	);

	const onPeriodValueChange = (index, value) => {
		setFormData(data => ({
			...data,
			period: updateInArray(data.period, {...data.period[index], value}, index),
		}));
	};

	const removePeriod = index => {
		setFormData(data => ({
			...data,
			period: removeFromArray(data.period, index),
		}));
	};

	const isFormValid =
		validationErrors.name === null &&
		formData.type &&
		(validationErrors.period.length === 0 || validationErrors.period.filter(item => item !== null).length === 0);

	const onSubmit = () => {
		setIsLoading(true);
		saveVector(formData).then(() => {
			showSnackbar('Vector saved successfully');
			setFlyoutMode(FORECASTING_FLYOUT_MODE.VECTOR_LIST);
		});
	};

	return (
		<section className={stylesForm.section}>
			<FormCloseSection onClose={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.VECTOR_LIST)} />
			<form className={stylesForm.form}>
				<section className={stylesForm.scrollable}>
					<NameForm
						label="Vector"
						items={vectors}
						mergeFormData={mergeFormData}
						mergeValidationErrors={mergeValidationErrors}
						nameFormData={formData.name}
						nameValidationErrors={validationErrors.name}
						editItemId={!isCopyMode && editItemId ? editItemId : null}
					/>
					<div className="sidebar-form-section">
						<span className="form-instruction">Type</span>
						<KiSelect
							options={vectorTypeOptions}
							onChange={option => mergeFormData({type: option.value})}
							value={vectorTypeOptions.filter(o => o.value === formData.type)}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Apply Seasoning</span>
						<KiSelect
							options={seasoningOptions}
							onChange={option => mergeFormData({seasoning: option.value})}
							value={seasoningOptions.filter(o => o.value === formData.seasoning)}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Description</span>
						<KiInput
							className="substring-size-input"
							value={formData.description}
							onChange={description => mergeFormData({description})}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Periods</span>
						<div className={styles.periodsContainer}>
							{formData.period &&
								formData.period.length > 0 && (
									<div className={styles.periodList}>
										<div className={styles.periodsHeader}>
											<div>Month</div>
											<div>Value</div>
										</div>
										{formData.period.map((period, index) => (
											<div className={styles.period} key={index}>
												<KiInput
													className={classNames('substring-size-input', styles.readOnly)}
													type="number"
													value={period.month}
													placeholder="Month"
													readOnly
												/>
												<KiInput
													className="substring-size-input"
													onChange={value => onPeriodValueChange(index, value)}
													placeholder="Value"
													autoComplete="off"
													isNumberMasked={true}
													maskConfig={{allowNegative: false}}
													value={period.value}
													error={validationErrors.period[index]}
												/>
												<KiIconButton
													className={styles.actionButton}
													icon="remove_circle"
													onClick={() => removePeriod(index)}
												/>
											</div>
										))}
									</div>
								)}
							<FormBlockItemContainer
								title="Add period manually"
								isVisible={visiblePeriodForm === PERIOD_FORMS.MANUAL}
								onDelete={() => setVisiblePeriodForm(null)}
							>
								<ManualPeriodForm
									onPeriodsAdd={addNewPeriods}
									onCancel={() => setVisiblePeriodForm(null)}
									currentPeriods={formData.period}
									validateValue={validateValue}
								/>
							</FormBlockItemContainer>
							<FormBlockItemContainer
								title="Paste values"
								isVisible={visiblePeriodForm === PERIOD_FORMS.PASTE}
								onDelete={() => setVisiblePeriodForm(null)}
							>
								<MultiplePeriodsForm
									onPeriodsAdd={addNewPeriods}
									onCancel={() => setVisiblePeriodForm(null)}
									currentPeriods={formData.period}
									renderDataProvider={props => <PeriodPasteForm {...props} />}
									isValueRageRestricted={isTypeRate}
								/>
							</FormBlockItemContainer>
							<FormBlockItemContainer
								title="Import from CSV file"
								isVisible={visiblePeriodForm === PERIOD_FORMS.IMPORT}
								onDelete={() => setVisiblePeriodForm(null)}
							>
								<MultiplePeriodsForm
									onPeriodsAdd={addNewPeriods}
									onCancel={() => setVisiblePeriodForm(null)}
									currentPeriods={formData.period}
									renderDataProvider={props => <ImportPeriodForm {...props} />}
									isValueRageRestricted={isTypeRate}
								/>
							</FormBlockItemContainer>
						</div>
						{!visiblePeriodForm && (
							<div className={stylesForm.mainAddItem}>
								<KiMenu
									icon="add_circle"
									buttonId="addPeriodButton"
									className={stylesForm.addItemButton}
									title="Click to add Period"
									position="bottomLeft"
								>
									{Object.entries(PERIOD_FORMS).map(([key, item]) => (
										<KiMenuItem
											key={key}
											label={item}
											isHidden={formData[key]}
											onClick={() => setVisiblePeriodForm(item)}
										/>
									))}
								</KiMenu>
								<label htmlFor="addPeriodButton">Add Period</label>
								<div className={stylesForm.note}>Enter Month/Value for deltas only</div>
							</div>
						)}
					</div>
				</section>
				<div className="inline-column-form-buttons">
					<KiButton flat primary onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.VECTOR_LIST)}>
						Cancel
					</KiButton>
					<KiButton disabled={isLoading || !isFormValid} raised primary onClick={onSubmit}>
						Save
					</KiButton>
				</div>
			</form>
		</section>
	);
};

VectorForm.propTypes = {
	setFlyoutMode: PropTypes.func.isRequired,
	editItemId: PropTypes.string,
	isCopyMode: PropTypes.bool,
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(
	null,
	{showSnackbar}
)(VectorForm);
