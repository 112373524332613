import React, {useEffect} from 'react';
import {useParams, useLocation, useHistory} from 'react-router';
import KiButton from '../../../components/KiButton';
import KiSelect from '../../../components/KiSelect';
import KiDatePicker from '../../../components/KiDatePicker';
import PropTypes from 'prop-types';
import {setDateContext, setEndDate, setStartDate, setViewType} from '../actions';
import {connect} from 'react-redux';
import {DATE_CONTEXT, VIEW_TYPES} from './LedgerExploration';
import {submitLedger} from '../../../api/ledgersApi';
import {showSnackbar} from '../../../state/actions/Snackbar';
function LedgerFilteringHeader({
	viewType,
	setViewType,
	dateContext,
	setDateContext,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	showSnackbar,
}) {
	const VIEW_TYPE_OPTION_LIST = [VIEW_TYPES.ACCOUNT, VIEW_TYPES.BALANCE, VIEW_TYPES.JOURNAL];
	const DATE_CONTEXT_OPTION_LIST = [DATE_CONTEXT.POSTING_DATE, DATE_CONTEXT.EFFECTIVE_DATE];
	const SUBMITABLE_VIEW_TYPE_OPTION_LIST = [VIEW_TYPES.BALANCE, VIEW_TYPES.JOURNAL];

	const {id} = useParams();
	const history = useHistory();
	const query = new URLSearchParams(useLocation().search);

	useEffect(() => {
		const setInitialDateRange = () => {
			const queryStartDate = query.get('startDate');
			if (queryStartDate) {
				setStartDate(new Date(queryStartDate));
			}
			const queryEndDate = query.get('endDate');
			if (queryEndDate) {
				setEndDate(new Date(queryEndDate));
			}

			const viewTypeQuery = query.get('viewType');
			if (viewTypeQuery) {
				const selectedViewType = VIEW_TYPE_OPTION_LIST.find(option => option.value === viewTypeQuery);
				setViewType(selectedViewType);
			}

			const dateContextQuery = query.get('dateContext');
			if (dateContextQuery) {
				const selectedDateContext = DATE_CONTEXT_OPTION_LIST.find(option => option.value === dateContextQuery);
				setDateContext(selectedDateContext);
			}
		};

		// to avoid race condition
		setTimeout(setInitialDateRange);
	}, []);

	useEffect(
		() => {
			history.replace(
				`/ledgers/${id}/exploration?startDate=${startDate}&endDate=${endDate}&viewType=${
					viewType.value
				}&dateContext=${dateContext.value}`
			);
		},
		[startDate, endDate, viewType, dateContext]
	);

	const onSubmit = () => {
		submitLedger(id, startDate, endDate, dateContext.value)
			.then(() => showSnackbar('Ledger submitted for approval.'))
			.catch(err => showSnackbar(err.message));
	};

	return (
		<div className="horizontal-inputs-container">
			<div>
				<span className="theme-label">View Type</span>
				<KiSelect options={VIEW_TYPE_OPTION_LIST} onChange={opt => setViewType(opt)} value={viewType} />
			</div>
			<div>
				<span className="theme-label">Date Context</span>
				<KiSelect
					options={DATE_CONTEXT_OPTION_LIST}
					onChange={opt => setDateContext(opt)}
					value={dateContext}
				/>
			</div>
			<div>
				<span className="theme-label">Start date</span>
				<KiDatePicker
					strictParsing
					onChange={newDate => setStartDate(newDate)}
					value={startDate}
					className="datepicker"
				/>
			</div>
			<div>
				<div className="dateContainer">
					<span className="theme-label">End date</span>
					<KiDatePicker
						strictParsing
						onChange={newDate => setEndDate(newDate)}
						value={endDate}
						className="datepicker"
					/>
				</div>
			</div>
			{window.LEDGER_APPROVALS_ENABLED === 'true' &&
				SUBMITABLE_VIEW_TYPE_OPTION_LIST.includes(viewType) && (
					<div className="button-wrapper to-right">
						<KiButton primary onClick={onSubmit}>
							Submit
						</KiButton>
					</div>
				)}
		</div>
	);
}

LedgerFilteringHeader.propTypes = {
	viewType: PropTypes.object.isRequired,
	setViewType: PropTypes.func.isRequired,
	dateContext: PropTypes.object.isRequired,
	setDateContext: PropTypes.func.isRequired,
	startDate: PropTypes.string.isRequired,
	setStartDate: PropTypes.func.isRequired,
	endDate: PropTypes.string.isRequired,
	setEndDate: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	viewType: state.ledgerExploration.viewType,
	dateContext: state.ledgerExploration.dateContext,
	startDate: state.ledgerExploration.startDate,
	endDate: state.ledgerExploration.endDate,
});

const mapDispatchToProps = {
	setViewType,
	setDateContext,
	setStartDate,
	setEndDate,
	showSnackbar,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LedgerFilteringHeader);
