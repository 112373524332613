import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames/bind';
import styles from './KiRadioButton.theme.scss';

const cx = classNames.bind(styles);
export class KiRadioButton extends Component {
	static propTypes = {
		className: PropTypes.string,
		value: PropTypes.any,
		onChange: PropTypes.func,
		checked: PropTypes.bool,
		label: PropTypes.any,
	};

	static defaultProps = {
		className: '',
		label: '',
	};

	render() {
		const {className, value, onChange, label, checked} = this.props;

		return (
			<label
				className={cx('radioButton', {
					className: className,
				})}
			>
				<input type="radio" value={value} checked={checked} onChange={() => onChange(value)} />
				{label}
			</label>
		);
	}
}

export default KiRadioButton;
