// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Project imports
import KiModal2 from 'components/KiModal2';
import KiProgressBar from 'components/KiProgressBar';
import {getScenarioStatus} from 'api/fundingAnalysisApi';

// Local imports
import styles from './scenarioInformationOverlay.theme.scss';

function ScenarioInformationOverlay({isActive, setInformationOverlayActive, scenarioId}) {
	const [scenarioData, setScenarioData] = useState({messages: []});
	const [loadingMessages, setLoadingMessages] = useState(false);
	// Filter the entity options based on the selectedFV
	useEffect(
		() => {
			const fetchScenarioInformation = async id => {
				setLoadingMessages(true);
				try {
					const scenarioInfo = await getScenarioStatus(id);
					setScenarioData(scenarioInfo);
					setLoadingMessages(false);
				} catch (e) {
					setScenarioData(e.message);
					setLoadingMessages(false);
				}
			};
			if (scenarioId && isActive) {
				fetchScenarioInformation(scenarioId);
			}
		},
		[isActive]
	);

	return (
		<KiModal2
			active={isActive}
			actions={[{label: 'ok', onClick: () => setInformationOverlayActive(false)}]}
			panelStyles={{minHeight: '40%', maxHeight: '40%', minWidth: '60%', maxWidth: '60%'}}
			className={styles.scenarioInformationOverlay}
			header={'Scenario Information:'}
		>
			{loadingMessages && (
				<div>
					<KiProgressBar />
				</div>
			)}
			{!loadingMessages && (
				<div className={''}>
					{scenarioData.messages.map((m, i) => {
						switch (m.level) {
							case 'info':
								return (
									<div key={i} className={styles.message}>
										<span style={{color: 'green'}} className="material-icons">
											info
										</span>
										<p>{m.message}</p>
									</div>
								);
							case 'warning':
								return (
									<div key={i} className={styles.message}>
										<span style={{color: '#faa30c'}} className="material-icons">
											warning
										</span>
										<p>{m.message}</p>
									</div>
								);
							case 'error':
								return (
									<div key={i} className={styles.message}>
										<span style={{color: 'red'}} className="material-icons">
											error
										</span>
										<p>{m.message}</p>
									</div>
								);
							default:
								return null;
						}
					})}
				</div>
			)}
		</KiModal2>
	);
}

ScenarioInformationOverlay.propTypes = {
	isActive: PropTypes.bool.isRequired,
	scenarioId: PropTypes.string,
	setInformationOverlayActive: PropTypes.func,
};

ScenarioInformationOverlay.defaultProps = {
	isActive: false,
	setInformationOverlayActive: () => ({}),
};

export default ScenarioInformationOverlay;
