module.exports = [
	{
		name: 'balance',
		displayName: 'Collateral Balance',
		info: 'Balance (at the end) of current period',
		type: 'collateral',
	},
	{
		name: 'schedPrincipal',
		displayName: 'Scheduled Principal',
		info: 'Amount of the scheduled principal',
		type: 'collateral',
	},
	{
		name: 'prepayments',
		displayName: 'Prepayments',
		info: 'Amount of voluntary prepayment',
		type: 'collateral',
	},
	{
		name: 'defaults',
		displayName: 'Defaulted Balance',
		info: 'Amount of defaults brought forward to the end of liquidation period',
		type: 'collateral',
	},
	{
		name: 'losses',
		displayName: 'Losses',
		info: 'Amount of losses after property liquidation',
		type: 'collateral',
	},
	{
		name: 'prinRecoveries',
		displayName: 'Principal Recoveries',
		info: 'Principal recovered after liquidation',
		type: 'collateral',
	},
	{
		name: 'interest',
		displayName: 'Interest Collections',
		info: 'Actual interest paid',
		type: 'collateral',
	},
	{
		name: 'reinvestment',
		displayName: 'Reinvestment',
		info: 'Collateral reinvestment',
		type: 'collateral',
	},
	{
		name: 'cash',
		displayName: 'Total Collections',
		info:
			'Total amount of cash available for distribution by payment rules, can be calculated as sum of scheduled principal, interest, prepayment, and recovery; for HELOCs the amount of draw amount is subtracted.',
		type: 'collateral',
	},
	{
		name: 'poBalance',
		displayName: 'Balance - Principal Only',
		info: 'Sum of the balance portions of all discount loans in ratio strip POs',
		type: 'collateral',
	},
	{
		name: 'excessInterest',
		displayName: 'Excess Interest - Interest Only ',
		info: 'Excessive interest (for ratio strip IOettes)',
		type: 'collateral',
	},
	{
		name: 'prepayPenalty',
		displayName: 'Prepayment Penalty - CMBS',
		info:
			'Amount paid as a result of application of payment penalty rules (points, yield maintenance, etc), mostly for CMBS',
		type: 'collateral',
	},
	{
		name: 'negativeAmort',
		displayName: 'Negative Amortization',
		info:
			'Amount of negative amortization during given period, ether 0 or absolute value of negative scheduled principal',
		type: 'collateral',
	},
	{
		name: 'begBalInterest',
		displayName: 'Beginning Interest Balance',
		info: 'Interest calculated on the amount at the start of the period',
		type: 'collateral',
	},
	{
		name: 'poLosses',
		displayName: 'Losses - Principal Only',
		info: 'PO losses (for ratio strip POs)',
		type: 'collateral',
	},
	{
		name: 'drawAmount',
		displayName: 'Draw Amount - Heloc',
		info: 'Amount of additional money drawn during the period (for HELOC)',
		type: 'collateral',
	},
	{
		name: 'schedPAndI',
		displayName: 'Scheduled  Principal and Interest',
		info: 'Scheduled principal and interest',
		type: 'collateral',
	},
	{
		name: 'poSchedPrin',
		displayName: 'Scheduled  Principal (PO Strip)',
		info: 'Scheduled principal for PO strips',
		type: 'collateral',
	},
	{
		name: 'poPrepayments',
		displayName: 'Prepayments – Principal Only',
		info: 'Prepayments for PO strips',
		type: 'collateral',
	},
	{
		name: 'poPrinRecoveries',
		displayName: 'Principal Recoveries – Principal Only ',
		info: 'Principal recoveries for PO strips',
		type: 'collateral',
	},
	{
		name: 'performingBalance',
		displayName: 'Performing Balance',
		info: 'Performing balance',
		type: 'collateral',
	},
	{
		name: 'ioBalance',
		displayName: 'Interest Only Balance',
		info: 'Balance of IO strips',
		type: 'collateral',
	},
	{
		name: 'currency',
		displayName: 'Currency',
		info: 'Currency of the assets',
		type: 'forecast',
		datatype: 'string',
	},
	{
		name: 'origBalance',
		displayName: 'Original Balance',
		info: 'Original Balance',
		type: 'forecast',
	},
	{
		name: 'endingBalance',
		displayName: 'Ending Balance',
		info: 'Ending Balance',
		type: 'forecast',
	},
	{
		name: 'delinquentMonths',
		displayName: 'Delinquent Months',
		info: 'WA number of months delinquent',
		type: 'forecast',
	},
	{
		name: 'origination',
		displayName: 'Origination Date',
		info: 'WA Origination Date',
		type: 'forecast',
		datatype: 'date_long',
	},
	{
		name: 'maturity',
		displayName: 'Maturity Date',
		info: 'WA Maturity Date',
		type: 'forecast',
		datatype: 'date_long',
	},
	{
		name: 'coupon',
		displayName: 'WA Coupon',
		info: 'WA Coupon Rate of assets',
		type: 'forecast',
	},
	{
		name: 'servicing',
		displayName: 'Servicing Fee %',
		info: 'Servicing Fee Rate',
		type: 'forecast',
	},
	{
		name: 'origTerm',
		displayName: 'Original Term',
		info: 'WA Original Term',
		type: 'forecast',
	},
	{
		name: 'payPeriod',
		displayName: 'Payment Frequency',
		info: 'Collateral Payment Frequency',
		type: 'forecast',
		datatype: 'string',
	},
	{
		name: 'resetPeriodMonths',
		displayName: 'WA Reset Period',
		info: 'How often is a floating rate asset coupon recomputed',
		type: 'forecast',
	},
	{
		name: 'amort',
		displayName: 'WA Remaining Term',
		info: 'WA Remaining Term',
		type: 'forecast',
	},
	{
		name: 'accrualType',
		displayName: 'Accrual Type',
		info: 'Fixed / Floating',
		type: 'forecast',
		datatype: 'string',
	},
	{
		name: 'margin',
		displayName: 'Margin %',
		info: 'Margin rate for floating rate',
		type: 'forecast',
	},
	{
		name: 'couponIndex',
		displayName: 'Coupon Index',
		info: 'Index for floating rate',
		type: 'forecast',
		datatype: 'string',
	},
	{
		name: 'basis',
		displayName: 'Accrual Basis',
		info: '30/360, 30/365, ACT/360, ACT/365 or ACT/ACT',
		type: 'forecast',
		datatype: 'string',
	},
	{
		name: 'balloon',
		displayName: 'Balloon Term',
		info: 'Balloon Term',
		type: 'forecast',
	},
];
