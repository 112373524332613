//eslint-disable-next-line no-unused-vars
import {apiUrl, _checkAuth, _handleResponse, _getHeaders, _checkStatus} from 'api/apiUtils';

export const fetchTriggers = datasetId => {
	return fetch(`${apiUrl}/triggers/${datasetId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const addTrigger = trigger => {
	return fetch(`${apiUrl}/triggers`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(trigger),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const deleteTrigger = (triggerId, datasetId) => {
	return fetch(`${apiUrl}/triggers/${datasetId}/${triggerId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const updateTrigger = trigger => {
	return fetch(`${apiUrl}/triggers/${trigger._id}`, {
		credentials: 'include',
		method: 'PUT',
		headers: _getHeaders('PUT'),
		body: JSON.stringify(trigger),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export default {
	fetchTriggers,
	addTrigger,
	deleteTrigger,
	updateTrigger,
};
