import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from '../../AssociatedData.theme.scss';
import Select from 'react-select';
import KiInput from 'components/KiInput';
import validators from 'ki-common/validators';
import {dateTypes} from 'ki-common/enums';
import _get from 'lodash/get';

const constraints = validators.schemaColumns.getConstraints();

const MESSAGE_CODES = {
	dataTypeMismatchNumeric: 'Non-numeric format',
	dataTypeMismatchShortDate: 'Non-date format',
	dataTypeMismatchLongDate: 'Non-date format',
	dataTypeMismatchCharacter: 'Exceeds limit of 500 characters',
	columnNotFound: 'Column Not Found',
	newColumnFound: 'New Column Found',
};

const buildDateSelectOptions = isLongDate => {
	const options = [{value: '', label: 'Custom'}];
	dateTypes.forEach((value, key) => {
		if (isLongDate) {
			if (value.type === 'date_long') {
				options.push({value: key, label: value.label});
			}
		} else if (value.type === 'date_short') {
			options.push({value: key, label: value.label});
		}
	});
	return options;
};

const shortDateOptions = buildDateSelectOptions(false);
const longDateOptions = buildDateSelectOptions(true);
const getDefaultValueForAggregate = (fileType, dataType) => {
	if (fileType !== 'aggregate') return '';
	switch (dataType) {
		case 'numeric':
			return 'sum';
		default:
			return 'firstValid';
	}
};
export class SchemaColumnForm extends Component {
	static propTypes = {
		column: PropTypes.object,
		mappedColumns: PropTypes.array,
		setFocus: PropTypes.func,
		isFocused: PropTypes.bool,
		index: PropTypes.number,
		requiredColumns: PropTypes.array,
		updateSchemaColumn: PropTypes.func,
		requiredColumnsAreMapped: PropTypes.bool,
		fileType: PropTypes.string,
	};

	static defaultProps = {
		column: {},
		requiredColumns: [],
		requiredColumnsAreMapped: false,
	};

	state = {
		formValues: {
			id: this.props.column.id,
			displayName: this.props.column.displayName,
			mapType: this.props.column.mapType,
			mapColumn: this.props.column.mapColumn,
			dataType: this.props.column.dataType,
			defaultValue: this.props.column.defaultValue,
			format: this.determineFormat(this.props.column.dataType, this.props.column.format),
			customFormat: this.determineCustomFormat(this.props.column.dataType, this.props.column.format),
			aggregation:
				this.props.column.aggregation ||
				getDefaultValueForAggregate(this.props.fileType, this.props.column.dataType),
		},
		formErrors: {},
		formHasChanges: false,
	};

	mapCustomDateFormValues = formValues => {
		const dateTypeMatch = dateTypes.get(formValues.format);
		if (dateTypeMatch) {
			return formValues;
		}
		const newValues = Object.assign({}, formValues);
		newValues.customFormat = formValues.format;
		newValues.format = '';
		return newValues;
	};

	determineCustomFormat(dataType, format) {
		if (dataType === 'date_short' || dataType === 'date_long') {
			if (!format) return '';
			if (dataType === 'date_short') {
				return shortDateOptions.find(o => o.value === format) ? 'yyyy/MM' : format;
			}
			if (dataType === 'date_long') {
				return longDateOptions.find(o => o.value === format) ? 'yyyy/MM/dd' : format;
			}
		}
		return '';
	}

	determineFormat(dataType, format) {
		if (dataType === 'date_short' || dataType === 'date_long') {
			if (dataType === 'date_short') {
				return shortDateOptions.find(o => o.value === format) ? format : 'yyyy/MM';
			}
			if (dataType === 'date_long') {
				return longDateOptions.find(o => o.value === format) ? format : 'yyyy/MM/dd';
			}
		}
		return '';
	}

	handleDateFormatChange = newValue => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					format: newValue.value,
					defaultValue: this.deriveDefaultValue(this.state.formValues.dataType, newValue.value),
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	handleDateCustomFormatChange = newValue => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					customFormat: newValue,
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	handleDefaultValueChange = newValue => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					defaultValue: newValue,
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	handleAggregationChange = option => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					aggregation: option.value,
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	validateForm = () => {
		const errors = validators.validate(this.state.formValues, constraints);
		this.setState({
			...this.state,
			formErrors: errors || {},
		});
	};

	handleDataTypeChange = newValue => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					dataType: newValue.value,
					defaultValue: this.deriveDefaultValue(newValue.value, this.determineFormat(newValue.value)),
					format: this.determineFormat(newValue.value),
					aggregation: getDefaultValueForAggregate(this.props.fileType, newValue.value),
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	handleDisplayNameChange = newValue => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					displayName: newValue,
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	handleMapColumnChange = newValue => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					mapColumn: newValue.value,
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	handleMapTypeChange = newValue => {
		this.setState(
			{
				...this.state,
				formHasChanges: true,
				formValues: {
					...this.state.formValues,
					mapType: newValue.value,
					mapColumn: '',
					aggregation: '',
				},
			},
			() => {
				this.validateForm();
				this.props.updateSchemaColumn(this.state.formValues);
			}
		);
	};

	deriveDefaultValue = (dataType, customFormat) => {
		let defaultValue = '';
		if (customFormat) {
			const match = dateTypes.get(customFormat);
			if (match) {
				defaultValue = match.defaultValue;
			}
		}
		switch (dataType) {
			case 'numeric':
				defaultValue = '0';
				break;
			case 'date_long':
				defaultValue = defaultValue || '1900/01/01';
				break;
			case 'date_short':
				defaultValue = defaultValue || '1900/01';
				break;
		}
		return defaultValue;
	};

	mapTypeOptions = () => {
		return [
			{
				value: 'ignore',
				label: 'Ignore',
			},
			{
				value: 'source',
				label: 'Source',
			},
			{
				value: 'required',
				label: 'Required',
				isDisabled: this.props.requiredColumnsAreMapped,
			},
		];
	};

	mapColumnTypes = () => {
		return this.props.requiredColumns.map(c => ({
			value: c,
			label: c,
			isDisabled: this.state.formValues.mapColumn !== c && this.props.mappedColumns.includes(c),
		}));
	};

	getAggregationOption = (fileType, dataType, value) => {
		return (
			this.aggregationOptions(dataType).find(opt => opt.value === value) ||
			getDefaultValueForAggregate(fileType, dataType)
		);
	};

	aggregationOptions = dataType => {
		switch (dataType) {
			case 'numeric':
				return [
					{
						value: 'sum',
						label: 'SUM',
					},
					{
						value: 'min',
						label: 'MIN',
					},
					{
						value: 'max',
						label: 'MAX',
					},
					{
						value: 'avg',
						label: 'AVG',
					},
				];
			case 'string':
				return [
					{
						value: 'firstValid',
						label: 'First Valid',
					},
					{
						value: 'concatenate',
						label: 'Concatenate',
					},
				];
			default:
				// date
				return [
					{
						value: 'firstValid',
						label: 'First Valid',
					},
					{
						value: 'min',
						label: 'MIN',
					},
					{
						value: 'max',
						label: 'MAX',
					},
				];
		}
	};

	dataTypeOptions = [
		{
			value: 'numeric',
			label: 'Numeric',
		},
		{
			value: 'string',
			label: 'String',
		},
		{
			value: 'date_short',
			label: 'Short Date',
		},
		{
			value: 'date_long',
			label: 'Long Date',
		},
	];

	render() {
		const isMissingLabel = () => {
			return _get(this.props.column, 'messages', []).find(m => m.code === 'columnNotFound') ? ' Missing' : '';
		};

		const isNewLabel = () => {
			return this.props.column.isNew ? ' New' : '';
		};

		let messages = [];
		if (this.props.column.messages) {
			messages = this.props.column.messages.map(m => Object.assign({}, m, {displayName: MESSAGE_CODES[m.code]}));
		}

		return (
			<div
				onClick={() => this.props.setFocus(this.props.index)}
				className={`${styles.schemaColumnForm} ${messages.length ? styles.hasError : ''} ${
					this.props.isFocused ? styles.focused : ''
				}`}
			>
				<div className={styles.titleContainer}>
					<div className={styles.title}>
						<span className={styles.labelMsg}>{isNewLabel()}</span>
						<span className={styles.labelMsg}>{isMissingLabel()}</span>
						<span className={styles.columnName}>{this.props.column.id}</span>
					</div>
					<div className={styles.columnMessages}>
						{messages.map((m, i) => <span key={i}>{m.displayName}</span>)}
					</div>
				</div>
				<div className={styles.formContainer}>
					<div className={styles.form}>
						<div className={styles.fullWide}>
							<KiInput
								name="displayName"
								label="Display Name"
								type="text"
								value={this.state.formValues.displayName || ''}
								error={this.state.formErrors.displayName}
								onChange={this.handleDisplayNameChange}
							/>
						</div>
						<div className={styles.horizontal}>
							<div className={styles.selectWrapper}>
								<span className="theme-label">Map Type</span>
								<Select
									classNamePrefix="aut-select"
									value={this.mapTypeOptions().find(
										opt => opt.value === this.state.formValues.mapType
									)}
									isClearable={false}
									options={this.mapTypeOptions()}
									onChange={this.handleMapTypeChange}
									menuPortalTarget={document.body}
									styles={{menuPortal: base => ({...base, zIndex: 9999})}}
								/>
							</div>
							{this.state.formValues.mapType === 'required' && (
								<div className={styles.selectWrapper}>
									<span className="theme-label">Map Column</span>
									<Select
										classNamePrefix="aut-select"
										name="mapColumn"
										value={this.mapColumnTypes().find(
											o => o.value === this.state.formValues.mapColumn
										)}
										className="map-column-selector"
										//isDisabled={isMissing}
										isClearable={false}
										isSearchable={false}
										options={this.mapColumnTypes()}
										onChange={this.handleMapColumnChange}
										menuPortalTarget={document.body}
										styles={{menuPortal: base => ({...base, zIndex: 9999})}}
									/>
								</div>
							)}
						</div>
						<div className={styles.horizontal}>
							<div className={styles.selectWrapper}>
								<span className="theme-label">Data Type</span>
								<Select
									classNamePrefix="aut-select"
									value={this.dataTypeOptions.find(
										opt => opt.value === this.state.formValues.dataType
									)}
									isClearable={false}
									options={this.dataTypeOptions}
									onChange={this.handleDataTypeChange}
									menuPortalTarget={document.body}
									styles={{menuPortal: base => ({...base, zIndex: 9999})}}
								/>
							</div>
							<div className={styles.inputWrapper}>
								<KiInput
									name="defaultValue"
									label="Default Value"
									type="text"
									value={this.state.formValues.defaultValue || ''}
									error={this.state.formErrors.defaultValue}
									onChange={this.handleDefaultValueChange}
								/>
							</div>
						</div>
						{this.props.fileType === 'aggregate' &&
							this.state.formValues.mapType !== 'required' && (
								<div className={styles.horizontal}>
									<div className={styles.selectWrapper}>
										<span className="theme-label">Aggregation Method</span>
										<Select
											classNamePrefix="aut-select"
											value={this.getAggregationOption(
												this.props.fileType,
												this.state.formValues.dataType,
												this.state.formValues.aggregation
											)}
											isClearable={false}
											options={this.aggregationOptions(this.state.formValues.dataType)}
											onChange={this.handleAggregationChange}
											menuPortalTarget={document.body}
											styles={{menuPortal: base => ({...base, zIndex: 9999})}}
										/>
									</div>
								</div>
							)}
						<div className={styles.horizontal}>
							{(this.state.formValues.dataType === 'date_short' ||
								this.state.formValues.dataType === 'date_long') && (
								<div className={styles.selectWrapper}>
									<span className="theme-label">Date Format</span>
									{this.state.formValues.dataType === 'date_short' && (
										<Select
											classNamePrefix="aut-select"
											className="data-type-selector"
											value={shortDateOptions.find(o => o.value === this.state.formValues.format)}
											isClearable={false}
											options={shortDateOptions}
											onChange={this.handleDateFormatChange}
											menuPortalTarget={document.body}
											styles={{menuPortal: base => ({...base, zIndex: 9999})}}
										/>
									)}
									{this.state.formValues.dataType === 'date_long' && (
										<Select
											classNamePrefix="aut-select"
											className="data-type-selector custom-date-format-select"
											value={longDateOptions.find(
												ldo => ldo.value === this.state.formValues.format
											)}
											isClearable={false}
											options={longDateOptions}
											onChange={this.handleDateFormatChange}
											menuPortalTarget={document.body}
											styles={{menuPortal: base => ({...base, zIndex: 9999})}}
										/>
									)}
								</div>
							)}
							{(this.state.formValues.dataType === 'date_short' ||
								this.state.formValues.dataType === 'date_long') && (
								<div className={styles.inputWrapper}>
									{this.state.formValues.format === '' && (
										<KiInput
											className="custom-date-format-input"
											name="customFormat"
											type="text"
											value={this.state.formValues.customFormat || ''}
											onChange={this.handleDateCustomFormatChange}
											placeholder={'M,D,Y and special chars'}
											error={
												Object.prototype.hasOwnProperty.call(
													this.state.formErrors,
													'customFormat'
												) && this.state.formErrors.customFormat.length
													? this.state.formErrors.customFormat[0]
													: null
											}
										/>
									)}
								</div>
							)}
						</div>
					</div>
					<div className={styles.messageContainer}>
						<div className={styles.previewData}>
							{this.props.column.previewData.map((d, i) => <span key={i}>{d}</span>)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SchemaColumnForm;
