import {USER_LOGIN, USER_LOGOUT, USER_UPDATE} from './constants';

const initialState = {
	isLoading: false,
};

function User(state = initialState, action) {
	switch (action.type) {
		case USER_LOGIN:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, ...action.user, isLoading: false};
				case 'error':
					return initialState;
				default:
					return state;
			}

		case USER_UPDATE :
			return {...state, ...action.user};

		case USER_LOGOUT :
			return initialState;

		default:
			return state;
	}
}

export default User;
