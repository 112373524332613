const diff = require('deep-diff').diff;
const _get = require('lodash/get');

const getDiff = (oldObject, newObject) => {
	return diff(oldObject, newObject);
};

const falseyNotZeroValue = val => {
	if (val === null || val === undefined || val === {} || val === '') {
		return '-';
	}
	if (typeof val === 'boolean') {
		return val.toString();
	}
	return val;
};

const dotifyArrayPath = path => {
	if (!path) return '';
	return path.join().replace(/,/g, '.');
};

const transformGeneric = diff => {
	return diff.map(change => {
		let oldValue = change.lhs;
		let newValue = change.rhs;
		if (change.kind === 'A') {
			oldValue = change.item.lhs;
			newValue = change.item.rhs;
		}
		return {
			property: dotifyArrayPath(change.path),
			oldValue: falseyNotZeroValue(oldValue),
			newValue: falseyNotZeroValue(newValue),
		};
	});
};

const transformDiff = (action, diff) => {
	if (!diff) return []; // no reason to record diff
	if (_get(diff, '[0].kind', null) === 'N') {
		// todo fix this render.... not sure of the various
		// insert combos we might see so while we store everything
		// we won't render this sprint
		return [];
	}
	switch (action) {
		// todo add special case for alt action renderings as needed
		default:
			return transformGeneric(diff);
	}
};

module.exports = {
	getDiff,
	transformDiff,
	dotifyArrayPath,
};
