import {apiUrl} from 'api/apiUtils';
import analytics from 'analytics';

export const checkUserId = userId => {
	analytics.sendEvent('auth', 'check-unique-userId', `userId:${userId}`);
	return fetch(`${apiUrl}/login/${userId}`, {
		method: 'GET',
		mode: 'cors',
	}).then(response => {
		if (response.status !== 200) {
			return response.json().then(response => {
				analytics.sendErrorEvent(response.message, false);
				return Promise.reject(response);
			});
		}
		return response.json();
	});
};

export const login = (username, password) => {
	analytics.sendEvent('auth', 'login', `userId:${username}`);
	return fetch(`${apiUrl}/login`, {
		credentials: 'include',
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}),
		body: JSON.stringify({
			username: username,
			password: password,
		}),
	})
		.then(response => {
			if (response.status !== 200) {
				return response.json().then(response => {
					analytics.sendErrorEvent(response.message, false);
					return Promise.reject(response);
				});
			}
			return response.json();
		})
		.then(response => {
			if (response.user) {
				return response;
			}
		});
};

export const logout = () => {
	return fetch(`${apiUrl}/logout`, {
		credentials: 'include',
	}).then(response => {
		if (response.ok) {
			return Promise.resolve('jwt blacklisted');
		}
		return Promise.reject('logout failure, jwt still active');
	});
};

export default {
	login,
	logout,
};
