import PropTypes from 'prop-types';
import React from 'react';
import Pagination from 'react-js-pagination';
import KiFontIcon from 'components/KiFontIcon';

export const Pager = props => (
	<Pagination
		activePage={props.activePage}
		itemsCountPerPage={props.itemsCountPerPage}
		totalItemsCount={props.totalItemsCount}
		pageRangeDisplayed={props.pageRangeDisplayed}
		onChange={props.onChange}
		nextPageText={<KiFontIcon className="pager-icon">chevron_right</KiFontIcon>}
		lastPageText={<KiFontIcon className="pager-icon">last_page</KiFontIcon>}
		prevPageText={<KiFontIcon className="pager-icon">chevron_left</KiFontIcon>}
		firstPageText={<KiFontIcon className="pager-icon">first_page</KiFontIcon>}
	/>
);
Pager.propTypes = {
	activePage: PropTypes.number,
	itemsCountPerPage: PropTypes.number,
	totalItemsCount: PropTypes.number,
	pageRangeDisplayed: PropTypes.number,
	onChange: PropTypes.func,
};

export default Pager;
