const _ = require('lodash');
const addNumberSuffix = require('./stringHelpers').addNumberSuffix;

const ROW_TYPE_DISPLAY_NAME_MAP = new Map([
	['ccBalanceLimit', 'Balance Limit'],
	['ccConcentration', 'Concentration'],
	['ccWeightedAvg', 'Weighted Average'],
	['ccExclude', 'Exclusion'],
	['ccConcentrationLimit', 'Concentration Limit'],
]);

const CONCENTRATION_TYPE_MAP = new Map([
	['any', (constraint, dataColumn) => `${dataColumn}(Any)`],
	[
		'include',
		(constraint, dataColumn) => `${dataColumn} in (${_.get(constraint, 'concentrationIncludes', []).join(', ')})`,
	],
	[
		'top_nth_bucket',
		(constraint, dataColumn) => `Top ${addNumberSuffix(_.get(constraint, 'concentrationValue', ''))} ${dataColumn}`,
	],
	[
		'top_n_combined',
		(constraint, dataColumn) =>
			`Top (${_.get(constraint, 'concentrationMin', '')}-${_.get(
				constraint,
				'concentrationMax',
				''
			)}) ${dataColumn} combined`,
	],
]);

const getColumnById = (columnId, columnList) => {
	const cid = JSON.stringify(columnId);
	return _.find(columnList, col => {
		return cid === JSON.stringify(col._id);
	});
};

const getExcludesDescription = (constraint, maxVisible) => {
	const excludes = _.get(constraint, 'concentrationExcludes', []);
	if (excludes.length === 0) {
		return '';
	}
	const named = excludes.slice(0, maxVisible).join(', ');
	const more = excludes.length > maxVisible ? `, and ${excludes.length - maxVisible} more` : '';
	return ` Excluding ( ${named}${more} )`;
};

//eslint-disable-next-line complexity
const getDescription = (constraint = {}, columns = []) => {
	if (_.isEmpty(constraint)) {
		return 'Description Missing';
	}

	try {
		const {
			rowType = '',
			name = '',
			dataColumn: dataColumnId = '',
			logic,
			target = [],
			selection: selectionValue = '',
			excludeZeroBalance,
			weightedBy: weightedById = '',
			startDate = '',
			endDate = '',
			recurrenceType = '',
			value = '',
		} = constraint;

		let description = '';
		const rowTypeDisplay = `(${
			excludeZeroBalance ? 'Zero Balance Exclusion' : ROW_TYPE_DISPLAY_NAME_MAP.get(rowType) || rowType
		})`;
		// Cover the other types of Funding Vehicle Data entries
		if (!ROW_TYPE_DISPLAY_NAME_MAP.has(rowType)) {
			switch (rowType) {
				case 'calendar':
					return `${name} ${rowTypeDisplay}\n${recurrenceType} from ${startDate} to ${endDate}`;
				case 'calculation': {
					let calcValue = value;
					const calcColumn = getColumnById(value, columns);
					if (calcColumn) {
						calcValue = calcColumn.displayName;
					}
					// const isColumn = ObjectID.isValid(value);
					// if (isColumn) {
					// 	const calcColumn = getColumnById(value, columns);
					// 	calcValue = calcColumn.displayName;
					// }
					return `${name} ${rowTypeDisplay}\n${calcColumn ? 'Sum of ' : ''}${calcValue}`;
				}
				case 'legal':
				default:
					return `${name} ${rowTypeDisplay}\n${value}`;
			}
		}

		// If the dataColumn has an _id it is hydrated, no need to lookup
		const _id = _.get(dataColumnId, '_id', undefined);
		const dataColumn = _id ? dataColumnId : getColumnById(dataColumnId, columns);

		// const weightedBy =
		// 	!weightedById || !ObjectID.isValid(weightedById) ? weightedById : getColumnById(weightedById, columns);
		// const selection =
		// 	!selectionValue || !ObjectID.isValid(selectionValue) ? selectionValue : getColumnById(selectionValue, columns);
		const weightedBy = getColumnById(weightedById, columns) || weightedById;
		const selection = getColumnById(selectionValue, columns) || selectionValue;
		const logicFormatted = logic.split('_').join(' ');
		description += `${name}`;
		description += ` ${rowTypeDisplay}\n`;

		switch (constraint.rowType) {
			case 'ccBalanceLimit':
			case 'ccExclude': {
				description += dataColumn.displayName;
				const multiTarget = target.length > 1;
				description += ` ${logicFormatted} ${multiTarget ? '[' : ''}${target.join(', ')}${multiTarget ? ']' : ''}`;
				break;
			}

			case 'ccConcentration': {
				description += `${CONCENTRATION_TYPE_MAP.get(selection)(constraint, dataColumn.displayName)}`;
				description += `${getExcludesDescription(constraint, 3)}`;
				description += ` ${logicFormatted} ${target.join('-')}%`;
				description += ` wtd by ${weightedBy.displayName}`;
				break;
			}

			case 'ccConcentrationLimit': {
				description += `${CONCENTRATION_TYPE_MAP.get(selection)(constraint, dataColumn.displayName)}`;
				description += `${getExcludesDescription(constraint, 3)}`;
				description += ` ${logicFormatted} ${target.join('-')}`;
				description += ` wtd by ${weightedBy.displayName}`;
				break;
			}

			case 'ccWeightedAvg': {
				description += dataColumn.displayName;
				description += ` ${logicFormatted} ${target.join('-')}`;
				description += ` wtd by ${selection.displayName}`;
				break;
			}

			default: {
				return description;
			}
		}
		return description;
	} catch (err) {
		return 'Description Error';
	}
};

const generateObjectId = () => {
	const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
	const randomDigits = 'nnnnnnnnnnnnnnnn'.replace(/n/g, () => {
		return Math.floor(Math.random() * 16).toString(16);
	});

	return `${timestamp}${randomDigits.toLowerCase()}`;
};

module.exports = {
	getDescription,
	generateObjectId,
};
