const options = require('../options');
const {defaultDebtColumns, debtFVSetupCols, debtBorrowingBaseCols} = require('../options/debt');
const uuidv4 = require('uuid/v4');
const _set = require('lodash/set');
const _get = require('lodash/get');
const _hasIn = require('lodash/hasIn');
const _omit = require('lodash/omit');
const _find = require('lodash/find');
const _cloneDeep = require('lodash/cloneDeep');
const crypto = require('crypto');

const expressionBuilder = require('./expressionBuilder');

const DEFAULT_TYPE = options.comparisonValueTypes.find(s => s.value === 'constant');

const getConstantType = val => {
	if (/\d{4}-\d{1,2}-\d{1,2}/.test(val)) {
		return 'date_long';
	} else if (/^([+-]?\d+(\.\d+){0,1})$/.test(val)) {
		return 'numeric';
	} else if (val._id) {
		return 'column';
	}
	return 'string';
};

// {
// 	"op": "ratio",
// 	"left": {
// 		"type": "column",
// 		"value": "columnA",
// 		"aggregate": "sum"
// 	},
// 	"right": {
// 		"type": "column",
// 		"value": "columnB",
// 		"aggregate": "count"
// 	}
// }
const createRatioColumn = params => {
	return {
		op: 'ratio',
		left: {
			type: 'column',
			value: params.numeratorColumn._id,
			aggregate: params.numeratorOption.value,
		},
		right: {
			type: 'column',
			value: params.denominatorColumn._id,
			aggregate: params.denominatorOption.value,
		},
	};
};

const createConcatTextColumn = ({functionType, selectedColumns = []}) => {
	const op = functionType.value || functionType;
	const operandJson = {
		op: op,
		left: {
			type: _get(selectedColumns, '0').type.value,
			value: _get(selectedColumns, '0').value,
		},
	};
	let path = 'right';
	const columns = selectedColumns.slice(1);
	columns.forEach((col, index) => {
		if (index === columns.length - 1) {
			_set(operandJson, path, {
				type: col.type.value,
				value: col.value,
			});
		} else {
			const newNode = {
				op: _get(functionType, 'value', functionType),
				left: {
					type: col.type.value,
					value: col.value,
				},
				right: `col${index}`,
			};
			_set(operandJson, path, newNode);
		}
		path = path.concat('.right');
	});
	if (!operandJson.right) {
		operandJson.right = {
			type: 'string',
			value: null,
		};
	}
	return operandJson;
};

const createSubstringTextColumn = ({functionType, selectedColumns = [], size, position = null}) => {
	const op = functionType.value || functionType;
	const returnJson = {
		op: op,
		left: {
			type: 'column',
			value: _get(selectedColumns, '0'),
			size: size,
		},
		right: {
			type: 'string',
			value: null,
		},
	};
	if (position) {
		returnJson.left.position = position;
	}
	return returnJson;
};

const createCaseItem = val => {
	let type1, type2;
	const isType1SubAccountColumn = val.operands[0].type.value === 'subAccountColumn';
	const isType2SubAccountColumn = val.operands[1].type.value === 'subAccountColumn';

	if (val.operands[0].type.value === 'constant') {
		// Only for constants
		if (val.operator.value === 'in' || val.operator.value === 'notIn') {
			type1 = 'string';
		} else if (val.operands[1].type.value === 'column') {
			type1 = val.operands[1].value.dataType;
		} else {
			type1 = getConstantType(val.operands[0].value);
		}
	} else {
		type1 = val.operands[0].type.value;
	}

	if (val.operands[1].type.value === 'constant') {
		// Only for constants
		if (val.operator.value === 'in' || val.operator.value === 'notIn') {
			type2 = 'string';
		} else if (val.operands[0].type.value === 'column') {
			type2 = val.operands[0].value.dataType;
		} else {
			type2 = getConstantType(val.operands[1].value);
		}
	} else {
		type2 = val.operands[1].type.value;
	}

	// Get the values based on if the type is column, subcolumn, or constant
	const value1 =
		type1 === 'column'
			? val.operands[0].value._id
			: isType1SubAccountColumn
			? val.operands[0].value.id
			: val.operands[0].value;

	const value2 =
		type2 === 'column'
			? val.operands[1].value._id
			: isType2SubAccountColumn
			? val.operands[1].value.id
			: val.operands[1].value;

	return {
		op: val.operator.value,
		left: {
			type: type1,
			value: value1,
		},
		right: {
			type: type2,
			value: value2,
		},
	};
};

const createCaseRow = val => {
	const caseStatements = val.caseStatements;
	let statement = {
		op: _get(caseStatements[0], 'logic.value'),
		left: createCaseItem(caseStatements[0]),
	};
	caseStatements.shift();
	let path = 'right';
	let newNode;
	caseStatements.forEach(s => {
		newNode = createCaseItem(s);
		if (s.logic) {
			_set(statement, path, {
				op: s.logic.value,
				left: newNode,
			});
		} else {
			_set(statement, path, newNode);
		}
		path = path.concat('.right');
	});
	if (statement.left && !statement.right) {
		statement = Object.assign({}, statement.left);
	}
	return statement;
};

const createParentDataColumn = params => {
	let formula = {};

	const {aggregationType, parentColumn, assetColumn, calculateColumn} = params;

	formula = {
		op: 'parentData',
		left: {
			type: 'business-variables',
			value: {
				aggregationType,
				parentColumn: {
					type: 'column',
					value: parentColumn,
				},
				assetColumn: {
					type: 'column',
					value: assetColumn,
				},
				calculateColumn: {
					type: 'column',
					value: calculateColumn,
				},
			},
		},
		right: null,
	};

	if (!calculateColumn) {
		delete formula.left.value.calculateColumn;
	}

	return formula;
};

const createConditionalColumn = params => {
	const conditions = params.conditions;
	const elseIsSubAccountColumn = params.elseData.type.value === 'subAccountColumn';
	const trueIsSubAccountColumn = conditions[0].then.type.value === 'subAccountColumn';
	const elseValue = {
		type:
			params.elseData.type.value === 'constant' ? getConstantType(params.elseData.value) : params.elseData.type.value,
		value: elseIsSubAccountColumn ? params.elseData.value.id : params.elseData.value,
	};
	let formula = createCaseRow(conditions[0]);
	let path = 'extension';
	let caseStatement;
	let IfTrueValue;

	const thenValue =
		conditions[0].then.type.value === 'column'
			? conditions[0].then.value._id
			: trueIsSubAccountColumn
			? conditions[0].then.value.id
			: conditions[0].then.value;
	_set(formula, path + '.TrueColumn', {
		type:
			conditions[0].then.type.value === 'constant'
				? getConstantType(conditions[0].then.value)
				: conditions[0].then.type.value,
		value: thenValue,
	});
	_set(formula, path + '.FalseColumn', elseValue);

	conditions.shift();

	conditions.forEach((condition, idx) => {
		caseStatement = createCaseRow(condition);
		IfTrueValue = {
			type:
				condition.then.type.value === 'constant' ? getConstantType(condition.then.value) : condition.then.type.value,
			value: condition.then.value,
		};
		_set(formula, path + '.FalseColumn', caseStatement);
		if (idx !== conditions.length - 1) {
			_set(formula, path + '.FalseColumn.extension.TrueColumn', IfTrueValue);
		}
		path = path.concat('.FalseColumn.extension');
		if (idx === conditions.length - 1) {
			_set(formula, path + '.TrueColumn', IfTrueValue);
			_set(formula, path + '.FalseColumn', elseValue);
		}
	});

	if (formula.left && !formula.right) {
		formula = Object.assign({extension: formula.extension}, formula.left);
		return formula;
	}

	return formula;
};

const getAllColumns = (formula, cols = []) => {
	if (Array.isArray(formula)) {
		// Non binary tree formula
		const toReturn = formula.reduce((output, entry) => {
			switch (entry.type) {
				case 'column':
				case 'subAccountColumn':
					// Column & Child Columns
					output.push(entry.value);
					return output;
				case 'min':
				case 'max':
					// Min and Max Functions
					output.push(...entry.value);
					return output;
				default:
					return output;
			}
		}, []);
		return toReturn;
	}
	if (formula.type === 'column') {
		cols.push(formula.value._id ? formula.value._id : formula.value);
		if (formula.entityId) {
			cols.push(formula.entityId); // Usage can include non-column jumps
		}
	} else {
		if (formula.left) {
			getAllColumns(formula.left, cols);
		}
		if (formula.right) {
			getAllColumns(formula.right, cols);
		}
		if (_get(formula, 'extension.TrueColumn')) {
			getAllColumns(formula.extension.TrueColumn, cols);
		}
		if (_get(formula, 'extension.FalseColumn')) {
			getAllColumns(formula.extension.FalseColumn, cols);
		}
	}
	return cols;
};

// eslint-disable-next-line complexity
const flattenFormula = node => {
	if (node.left && (node.left.left || node.left.right)) {
		flattenFormula(node.left);
	}
	if (node.right && (node.right.left || node.right.right)) {
		flattenFormula(node.right);
	}

	if (_get(node, 'extension.FalseColumn.value.formula')) {
		flattenFormula(node.extension.FalseColumn.value.formula);
	}
	if (_get(node, 'extension.TrueColumn.value.formula')) {
		flattenFormula(node.extension.TrueColumn.value.formula);
	}

	/* 
		handle subschema related columns by stopping recursion and only passing
		the columnName value... this allows the BE to process in the correct order
	*/
	if (_get(node, 'left.value.schemaId')) {
		node.left.value = node.left.value.columnName;
	}
	if (_get(node, 'right.value.schemaId')) {
		node.right.value = node.right.value.columnName;
	}
	if (_get(node, 'extension.FalseColumn.value.schemaId')) {
		node.extension.FalseColumn.value = node.extension.FalseColumn.value.columnName;
	}
	if (_get(node, 'extension.TrueColumn.value.schemaId')) {
		node.extension.TrueColumn.value = node.extension.TrueColumn.value.columnName;
	}

	// end handle subschema related columns
	if (_get(node, 'left.value.formula')) {
		if (node.op === 'substringLeft' || node.op === 'substringRight') {
			node.left.value.formula.size = _get(node, 'left.size', 0);
		}
		if (node.op === 'dateParse') {
			flattenFormula(node.left);
		} else {
			let counter;
			if (node.op === 'dateDiff') {
				counter = node.left.counter;
			}
			node.left = node.left.value.formula;
			if (counter) {
				node.left.counter = counter;
			}
			flattenFormula(node.left);
		}
	}
	if (_get(node, 'right.value.formula')) {
		if (node.op === 'substringLeft' || node.op === 'substringRight') {
			node.right.value.formula.size = _get(node, 'right.size', 0);
		}
		let counter;
		if (node.op === 'dateDiff') {
			counter = node.right.counter;
		}
		node.right = node.right.value.formula;
		if (counter) {
			node.right.counter = counter;
		}
		flattenFormula(node.right);
	}
	return node;
};

const flattenAllColumns = node => {
	if (node.type === 'column') {
		if (node.parseType) {
			return node;
		}
		node.value = _get(node, 'value.columnName', null) || node.value;
	} else {
		if (node.left) {
			flattenAllColumns(node.left);
		}
		if (node.right) {
			flattenAllColumns(node.right);
		}
	}
	return node;
};

const getAllColumnsAndConstants = (formula, colData) => {
	if (formula.type && formula.value) {
		let type = options.comparisonValueTypes.find(opt => opt.value === formula.type);
		if (!type) {
			type = Array.isArray(formula.value)
				? options.comparisonValueTypes.find(opt => opt.value === 'function')
				: DEFAULT_TYPE;
			if (type.value === 'function') {
				formula.functionType = options.comparisonFunctionTypes.find(type => type.value === formula.type);
				formula.functionValues = formula.value;
			}
		}
		formula.type = type;
		formula.logic = options.algebraicTypes.find(opt => opt.value === formula.logic);
		colData.push(formula);
	} else {
		if (formula.left) {
			formula.left.logic = formula.logic || formula.op;
			delete formula.logic;
			getAllColumnsAndConstants(formula.left, colData, 'left');
		}
		if (formula.right) {
			formula.right.logic = formula.logic || formula.op;
			delete formula.logic;
			getAllColumnsAndConstants(formula.right, colData, 'right');
		}
	}
};

const parseCaseStatement = (formula, logic, schemaColumns) => {
	const leftOperand = {
		type: options.comparisonValueTypes.find(op => op.value === formula.left.type) || DEFAULT_TYPE,
		value:
			formula.left.type === 'subAccountColumn'
				? schemaColumns.find(c => c.id === formula.left.value)
				: formula.left.value,
	};
	const rightOperand = {
		type: options.comparisonValueTypes.find(op => op.value === formula.right.type) || DEFAULT_TYPE,
		value:
			formula.right.type === 'subAccountColumn'
				? schemaColumns.find(c => c.id === formula.right.value)
				: formula.right.value,
	};

	const operatorOption =
		options.booleanLogicNumeric.find(op => op.value === formula.op) ||
		options.booleanLogicText.find(op => op.value === formula.op);

	return {
		// id: uuidV4(),
		operands: [leftOperand, rightOperand],
		operator: operatorOption,
		logic: options.conditionalTypes.find(op => op.value === logic),
	};
};

const parseCondition = (formula, statements, op, schemaColumns) => {
	// returns a case statement
	// {
	// 	"id": "a53f3d4f-d767-4ef9-bf81-7cece9511992",
	// 	"operands": [{
	// 		type: "sometype",
	// 		value: "someValue"
	// 	}, {
	// 		type: "sometype",
	// 		value: "someValue"
	// 	}],
	// 	"operator": {
	// 		"value": "<>",
	// 		"label": "<>"
	// 	},
	// 	"logic": {
	// 		"label": "Or",
	// 		"value": "or"
	// 	}
	// }

	// if we are at the row level
	let localOp;
	if (
		options.booleanLogicNumeric.findIndex(op => op.value === formula.op) !== -1 ||
		options.booleanLogicText.findIndex(op => op.value === formula.op) !== -1
	) {
		statements.push(parseCaseStatement(formula, op, schemaColumns));
	} else {
		localOp = formula.op;
		delete formula.op;
		if (formula.left) {
			parseCondition(formula.left, statements, localOp, schemaColumns);
		}
		if (formula.right) {
			parseCondition(formula.right, statements, localOp, schemaColumns);
		}
	}
	return statements;
};

const parseConditionalColumn = (formula, schemaColumns = []) => {
	const conditions = [];
	if (formula.extension.FalseColumn.type === 'subAccountColumn') {
		//hydrate full column for UX
		formula.extension.FalseColumn.value = schemaColumns.find(c => c.id === formula.extension.FalseColumn.value);
	}
	if (formula.extension.TrueColumn.type === 'subAccountColumn') {
		//hydrate full column for UX
		formula.extension.TrueColumn.value = schemaColumns.find(c => c.id === formula.extension.TrueColumn.value);
	}

	const initialCondition = Object.assign({}, formula);
	delete initialCondition.extension;
	const condition = {
		id: uuidv4(),
		caseStatements: parseCondition(initialCondition, [], null, schemaColumns),
		then: {
			type: options.comparisonValueTypes.find(op => op.value === formula.extension.TrueColumn.type) || DEFAULT_TYPE,
			value: formula.extension.TrueColumn.value,
		},
	};
	conditions.push(condition);
	let path = 'extension.FalseColumn';

	while (_get(formula, path + '.op', null)) {
		const newCondition = Object.assign({}, _get(formula, path));
		// delete newCondition.extension;
		conditions.push({
			id: uuidv4(),
			caseStatements: parseCondition(newCondition, [], null, schemaColumns),
			then: {
				type:
					options.comparisonValueTypes.find(op => op.value === newCondition.extension.TrueColumn.type) || DEFAULT_TYPE,
				value: newCondition.extension.TrueColumn.value,
			},
		});
		path = path.concat('.extension.FalseColumn');
	}
	return {
		conditions: conditions,
		elseData: {
			type: options.comparisonValueTypes.find(op => op.value === _get(formula, path + '.type')) || DEFAULT_TYPE,
			value: _get(formula, path + '.value'),
		},
	};
};

const getAllColumnsConditional = formula => {
	let cols = [];
	const data = parseConditionalColumn(formula);
	data.conditions.forEach(cond => {
		const conditionCols = [];
		cond.caseStatements.forEach(statement => {
			if (statement.operands[0].type.value === 'column') {
				conditionCols.push(statement.operands[0].value);
			}
			if (statement.operands[1].type.value === 'column') {
				conditionCols.push(statement.operands[1].value);
			}
		});
		if (cond.then.type.value === 'column') {
			conditionCols.push(cond.then.value);
		}
		cols = [...cols, ...conditionCols];
	});
	if (data.elseData.type.value === 'column') {
		cols.push(data.elseData.value);
	}
	return cols;
};

const parseCalulatedColumn = (formula, onlyColumns = false) => {
	let type = options.comparisonValueTypes.find(opt => opt.value === formula.left.type);
	if (!type) {
		type = Array.isArray(formula.left.value)
			? options.comparisonValueTypes.find(opt => opt.value === 'function')
			: DEFAULT_TYPE;
	}

	const operandData = [
		{
			logic: options.algebraicTypes.find(opt => opt.value === formula.left.sign),
			type: type,
			value: formula.left.value,
			functionType: options.comparisonFunctionTypes.find(type => type.value === formula.left.type),
		},
	];
	if (type.value === 'function') {
		operandData[0].functionType = options.comparisonFunctionTypes.find(type => type.value === formula.left.type);
		operandData[0].functionValues = formula.left.value;
	}

	getAllColumnsAndConstants.call(this, _omit(formula, 'left'), operandData);
	if (onlyColumns) {
		return operandData.filter(operand => operand.type.value === 'column').map(operand => operand.value);
	}
	return operandData;
};

/* eslint-disable complexity */
/* eslint-disable no-case-declarations */
const nodeToString = (node, level = 0, path = '0') => {
	if (!node) {
		return '';
	}
	// NOTE - enabling the log lines should only be used while testing logic, in production ki-website will fail to build if
	// the logger is included since it has a hard disk file write operation included

	// const log = require('../logger').getLogger({project: 'ki-common', class: 'utils/calcColumns'});
	// (level === 0) && log.trace({path, node}, 'nodeToString');
	// log.info({path, node}, 'nodeToString');

	try {
		const nextLevel = level + 1;
		if (node.type) {
			// Leaf node logic
			// log.info({path}, 'Leaf Node');
			switch (node.type) {
				case 'column':
					if (_hasIn(node, 'value.formula')) {
						return nodeToString(node.value.formula, nextLevel, `${path}-${nextLevel}`); // eslint-disable-line
					}
					return node.value.displayName;
				case 'min':
				case 'max': // eslint-disable-line
					//const columns = leaf.value.map(col => col.displayName);
					const columns = node.value.map(col => nodeToString({type: 'column', value: col}));
					return `${node.type}(${columns.join(', ')})`;
				case 'constant':
					return `"${node.value}"`;
				case 'double':
				case 'long':
				default:
					return node.value;
			}
		} else {
			// Formula node logic
			// log.info({path}, 'Formula Node');

			// Summary Aggregate and Ratio
			if (node.columnType && node.columnType.toLowerCase() === 'aggregate') {
				// log.info('Aggregate');
				let toReturn;
				const switchTest = node.calculation ? node.calculation.toLowerCase() : null;
				switch (switchTest) {
					case 'ratio':
						const left = node.formula.left;
						const right = node.formula.right;
						toReturn = `(${left.aggregate.toUpperCase()} ${left.value} / ${right.aggregate.toUpperCase()} ${
							right.value
						})`;
						break;
					case 'wghtavg':
						toReturn = `AGGREGATE Average ${node.assetColumn.displayName} Weighted By ${node.calculateColumn.displayName}`;
						break;
					default:
						const calculation = _find(options.summaryAggregateColumnTypes, {
							value: node.calculation,
						});
						toReturn = `AGGREGATE ${calculation ? calculation.label : node.calculation} of ${
							node.assetColumn.displayName
						}`;
				}
				return toReturn;
			}

			// Left node value
			const leftVal = nodeToString(node.left, nextLevel, `${path}-${nextLevel}L`);
			// log.info({path, leftVal}, 'nodeToString leftVal');

			// Right node value
			const rightVal = nodeToString(node.right, nextLevel, `${path}-${nextLevel}R`);
			// log.info({path, rightVal}, 'nodeToString rightVal');

			let toReturn;
			const switchTest = node.op ? node.op.toLowerCase() : null;
			switch (switchTest) {
				case 'concatenate':
					toReturn = `Concatenate(${leftVal}, ${rightVal})`;
					break;
				case 'substringmid':
					toReturn = `Substring(${leftVal}, ${node.left.size} from position ${node.left.position})`;
					break;
				case 'substringleft':
					toReturn = `Substring(${leftVal}, Left ${node.left.size})`;
					break;
				case 'substringright':
					toReturn = `Substring(${leftVal}, Right ${node.left.size})`;
					break;
				case 'datediff':
					const counter = _find(options.dateCounters, {
						value: node.left.counter,
					});
					toReturn = `Date DIFF in ${counter ? counter.label : node.left.counter} FROM ${leftVal} TO ${rightVal}`;
					break;
				case 'dateparse':
					toReturn = `Date PARSE ${node.left.parseType} from ${leftVal}`;
					break;
				case 'dateadd':
					toReturn = `Date ADJUST (${leftVal} ${rightVal})`;
					break;
				default:
					// Formulaic string
					if (rightVal === null) {
						toReturn = `${leftVal}`; // String cast
					} else {
						toReturn = `${leftVal} ${node.op} ${rightVal}`;
					}
			}

			// Asset Data Add operation value
			if (node.addOp) {
				toReturn = `${node.addOp} ${node.value} ${node.addCounter}`;
			}

			// Conditional node logic
			if (node.extension) {
				const trueString = nodeToString(node.extension.TrueColumn, nextLevel, `${path}-${nextLevel}T`);
				const falseString = nodeToString(node.extension.FalseColumn, nextLevel, `${path}-${nextLevel}F`);
				toReturn = `IF (${toReturn}) THEN [${trueString}] ELSE [${falseString}]`;
			}

			// log.info({path, toReturn}, 'nodeToString toReturn');
			return toReturn;
		}
	} catch (err) {
		// log.error({err}, 'Error generating calculation string');
		return 'Error generating calculation string';
	}
};
/* eslint-enable complexity */
/* eslint-enable no-case-declarations */

const getNewColumnName = colName => {
	// Check if column name uses the current pattern
	const parts = colName.split('-');
	const hash = crypto
		.createHash('md5')
		.update(`${colName}${new Date()}`)
		.digest('hex');
	return `${parts[0]}-${hash}`;
};

// eslint-disable-next-line complexity
const embedAssetColumnsForFormula = (columns, initialNode, rawDebtInputColumns = []) => {
	if (!initialNode) {
		return initialNode;
	}

	// Only run this code one time per recursive call stack
	let debtInputColumns = rawDebtInputColumns;
	const columnType = _get(rawDebtInputColumns, '[0].columnType', '');
	if (rawDebtInputColumns && columnType !== 'debtInputFV' && columnType !== 'debtInputTranche') {
		debtInputColumns = rawDebtInputColumns.map(col => {
			const newCol = _cloneDeep(col);
			newCol.columnType = col.viewType === 'fundingVehicle' ? 'debtInputFV' : 'debtInputTranche';
			return newCol;
		});
	}

	const allColumns = [
		...columns,
		...defaultDebtColumns,
		...debtFVSetupCols,
		...debtBorrowingBaseCols,
		...debtInputColumns,
	];
	/*
		deepCloning to prevent mutation
		this'll prevent any unintended hydration so hydrated cols don't sneak into the db.
	 */
	const node = _cloneDeep(initialNode);
	if (
		node.type === 'column' ||
		options.comparisonFunctionTypes.find(t => t.value === node.type) ||
		options.textFunctions.find(t => t.value === node.type)
	) {
		if (Array.isArray(node.value)) {
			// array of column ids - used in min/max
			node.value = node.value.map(colId => {
				const colMatch = _cloneDeep(
					allColumns.find(c => c._id.toString() === _get(colId, '_id', colId || '').toString()) || {}
				);
				colMatch.columnName = colMatch.columnName || `${_get(colMatch, '_id', 'N/A')}`;
				return colMatch;
			});

			// TODO remove if they never decide to have offset for functions
			// if (node.type === 'min' || node.type === 'max') {
			// 	node.value = node.value.map(colId => {
			// 	    const colMatch = _cloneDeep(columns.find(c => c._id.toString() === _get(colId, '_id', colId || '').toString())) || {};
			// 	    colMatch.offset = parseInt(colMatch.offset) || 0;
			// 	    if (!isNaN(parseInt(colMatch.offset)) || !isNaN(parseInt(node.offset))) {
			// 	        const offset = 0 + parseInt(colMatch.offset) + parseInt(node.offset);
			// 			colMatch.originalColumnName = colMatch.columnName;
			// 	        colMatch.columnName = `${colMatch.columnName}_offset${offset < 0 ? 'Minus' : 'Plus'}${Math.abs(offset)}`;
			// 	        colMatch.offset = offset; // NOTE: this may need to change if both the parent and the asset have a value already
			// 	    }
			// 	    return colMatch;
			// 	});
			// } else {
			// 	node.value = node.value.map(
			// 		colId => columns.find(c => c._id.toString() === _get(colId, '_id', colId || '').toString()) || {}
			// 	);
			// }
		} else {
			const targetId = _get(node, 'value._id', node.value || '').toString();
			const match = _cloneDeep(allColumns.find(c => `${targetId}` === `${c._id}`) || {});
			if (!node.columnType) {
				if (node.isDebtInput) {
					node.columnType = 'debtInputFV';
				} else if (node.isDebt) {
					node.columnType = 'debt';
				}
			}
			switch (node.columnType) {
				case 'debtInputFV':
				case 'debtInputTranche': {
					const columnType = match.viewType === 'tranche' ? 'debtInputTranche' : 'debtInputFV';
					node.value = {
						columnType: columnType,
						datasetId: match.datasetId,
						dataType: match.dataType || 'numeric',
						displayFormat: match.displayFormat || '9,999.99',
						displayName: match.name,
						originalColumnName: match.name,
						columnName: match._id.toString(),
						defaultValue: match.defaultValue,
						offset: node.offset,
						accountId: match.accountId,
						_id: `${columnType}__${match.name}`,
					};

					if (!isNaN(parseInt(node.offset))) {
						node.value.columnName = `${node.value.columnName}_offset${node.offset < 0 ? 'Minus' : 'Plus'}${Math.abs(
							node.offset
						)}`;
					}
					break;
				}
				case 'fvSetup':
				case 'borrowingBase':
				case 'fvDate':
				case 'debtCalculation':
				case 'debt':
				default: {
					if (node.columnType === 'debt') {
						//legacy but migration would be impossibly difficult with all the nesting
						node.value = _cloneDeep(defaultDebtColumns.find(c => [c.columnName, c._id].includes(c.columnName)) || {});
					} else {
						node.value = match;
					}
					if (!node.value.columnName) {
						node.value.columnName = node.value.columnName || _get(node, 'value.assetColumn.columnName');
					}
					if (!isNaN(parseInt(node.offset))) {
						node.value.offset = node.offset;
						if (!node.value.originalColumnName) {
							node.value.originalColumnName = node.value.columnName || _get(node, 'value.assetColumn.columnName');
						}
						node.value.columnName = `${node.value._id}_offset${node.offset < 0 ? 'Minus' : 'Plus'}${Math.abs(
							node.offset
						)}`;
					}
				}
			}
		}
		if (_get(node, 'value.formula')) {
			if (Array.isArray(node.value.formula)) {
				node.value.formula = expressionBuilder.getExpressionTree(node.value.formula);
			}
			// hydrated deeper embedded formula so recurse
			node.value.formula = embedAssetColumnsForFormula(columns, node.value.formula, debtInputColumns);
		}
		delete node.columnType;
	} else {
		// if (!node.type && Array.isArray(node)) {
		// 	return node.map(op => embedAssetColumnsForFormula(columns, op.formula ? op.formula : op, debtInputColumns));
		// }
		if (node.type === 'business-variables') {
			Object.keys(node.value).forEach(key => {
				node.value[key] = embedAssetColumnsForFormula(columns, node.value[key], debtInputColumns);
			});
		}
		if (node.left) {
			node.left = embedAssetColumnsForFormula(columns, node.left, debtInputColumns);
		}
		if (node.right) {
			node.right = embedAssetColumnsForFormula(columns, node.right, debtInputColumns);
		}
		if (_get(node, 'extension.TrueColumn')) {
			node.extension.TrueColumn = embedAssetColumnsForFormula(columns, node.extension.TrueColumn, debtInputColumns);
		}
		if (_get(node, 'extension.FalseColumn')) {
			node.extension.FalseColumn = embedAssetColumnsForFormula(columns, node.extension.FalseColumn, debtInputColumns);
		}
	}
	delete node.offset;
	delete node.isDebtInput;
	delete node.isDebt;
	return node;
};

const embedAssetColumns = (allColumns, flipArrayToBinary, debtInputColumns = []) => {
	const columns = _cloneDeep(allColumns); //don't mutate please
	columns.forEach(col => {
		// embed cohort and aggregate asset columns
		if (col.assetColumnId) {
			col.assetColumn = columns.find(c => `${c._id}` === col.assetColumnId);
		}
		// embed aggregate weighted avg asset column
		if (col.calculateColumnId) {
			col.calculateColumn = columns.find(c => `${c._id}` === col.calculateColumnId);
		}
		// embed formula asset columns
		if (col.formula && col.calculation !== 'RATIO') {
			if (Array.isArray(col.formula) && flipArrayToBinary) {
				// if formula is array for parens purposes, convert to binary
				try {
					col.formula = expressionBuilder.getExpressionTree(col.formula);
				} catch (err) {
					//eslint-disable-next-line
					console.log('error parsing formula:', {columnName: col.columnName, formula: col.formula, error: err});
					throw err;
				}
			}
			col.formula = embedAssetColumnsForFormula(columns, col.formula, debtInputColumns);
		}
		// embed formulas asset columns
		if (col.formulas && Array.isArray(col.formulas) && col.formulas.length) {
			col.formulas = col.formulas.map(item => {
				if (Array.isArray(item.formula) && flipArrayToBinary) {
					item.formula = expressionBuilder.getExpressionTree(item.formula);
				}
				item.formula = embedAssetColumnsForFormula(columns, item.formula, debtInputColumns);
				return item;
			});
		}
	});
	return columns;
};

module.exports = {
	createRatioColumn,
	flattenAllColumns,
	flattenFormula,
	createConcatTextColumn,
	createSubstringTextColumn,
	createConditionalColumn,
	getAllColumnsConditional,
	parseConditionalColumn,
	parseCalulatedColumn,
	getAllColumns,
	getConstantType,
	nodeToString,
	getNewColumnName,
	embedAssetColumnsForFormula,
	embedAssetColumns,
	createParentDataColumn,
};
