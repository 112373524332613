// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiSelect from 'components/KiSelect';
import KiButton from 'components/KiButton';

// Externals
import {
	cardTypeOptions,
	getDefaultFundingAnalysisViewCard,
	getDefaultExplorerViewCard,
	getDefaultDebtCard,
	getDefaultProjectionsCard,
	getDefaultPaymentsCard,
	paymentCardSourceTypes,
} from 'ki-common/utils/cardUtil';

// Relative
import styles from './CardCreate.theme.scss';

const defaultCardType = (explorerView, fundingAnalysisView, debtView) => {
	if (fundingAnalysisView) return {label: 'Funding Analysis', value: 'fundingAnalysis'};
	if (debtView) return {label: 'Debt', value: 'debt'};
	return {label: 'Asset', value: 'explorer'};
};

export class CardCreate extends Component {
	static propTypes = {
		datasetId: PropTypes.string,
		user: PropTypes.object.isRequired,
		addCard: PropTypes.func.isRequired,
		closeModal: PropTypes.func.isRequired,
		explorerViews: PropTypes.array.isRequired,
		explorerView: PropTypes.string,
		debtViews: PropTypes.array.isRequired,
		debtView: PropTypes.string,
		fundingAnalysisViews: PropTypes.array.isRequired,
		fundingAnalysisView: PropTypes.string,
		projectionScenarios: PropTypes.array.isRequired,
		projectionScenario: PropTypes.string,
	};

	static defaultProps = {
		explorerViews: [],
		fundingAnalysisViews: [],
		debtViews: [],
		projectionScenarios: [],
	};

	state = {
		selectedCardType: defaultCardType(this.props.explorerView, this.props.fundingAnalysisView, this.props.debtView),
		selectedExplorerView: this.props.explorerViews.find(v => v._id === this.props.explorerView),
		selectedFundingAnalysisView: this.props.fundingAnalysisView
			? this.props.fundingAnalysisViews.find(v => (v._id = this.props.fundingAnalysisView))
			: this.props.fundingAnalysisViews[0],
		selectedDebtView: this.props.debtViews.find(v => v._id === this.props.debtView),
		selectedProjectionScenario: this.props.projectionScenarios.find(v => v._id === this.props.projectionScenario),
		selectedFundingVehicle: null,
		selectedDateContext: null,
		formCanSubmit: true,
		paymentReportSource: null,
	};

	onAddCard = () => {
		switch (this.state.selectedCardType.value) {
			case 'fundingAnalysis': {
				const defaultFundingAnalysisViewCard = getDefaultFundingAnalysisViewCard(
					this.state.selectedFundingAnalysisView,
					this.props.user
				);
				defaultFundingAnalysisViewCard.settings.useReportScenarioId = true;
				this.props.addCard(defaultFundingAnalysisViewCard);
				break;
			}
			case 'explorer': {
				this.props.addCard(getDefaultExplorerViewCard(this.state.selectedExplorerView, this.props.user));
				break;
			}
			case 'debt': {
				this.props.addCard(getDefaultDebtCard(this.state.selectedDebtView, this.props.user));
				break;
			}
			case 'projections': {
				this.props.addCard(
					getDefaultProjectionsCard(
						this.state.selectedProjectionScenario,
						this.props.user,
						this.props.datasetId
					)
				);
				break;
			}
			case 'payments': {
				this.props.addCard(
					getDefaultPaymentsCard(this.state.paymentReportSource.value, this.props.user, this.props.datasetId)
				);
				break;
			}
		}
	};

	onCancel = () => {
		this.props.closeModal();
	};

	formIsValid = () => {
		switch (this.state.selectedCardType.value) {
			case 'explorer': {
				if (this.state.selectedExplorerView) {
					return true;
				}
				return false;
			}
			case 'debt': {
				if (this.state.selectedDebtView) {
					return true;
				}
				return false;
			}
			case 'fundingAnalysis': {
				if (this.state.selectedFundingAnalysisView) {
					return true;
				}
				return false;
			}
			case 'projections': {
				if (this.state.selectedProjectionScenario) {
					return true;
				}
				return false;
			}
			case 'payments': {
				if (this.state.paymentReportSource) {
					return true;
				}
				return false;
			}
			default: {
				return false;
			}
		}
	};

	render() {
		return (
			<div className={styles.addCardForm}>
				<section className={styles.selectionCriteria}>
					<div>
						<span className="theme-label">Card Types</span>
						<KiSelect
							classNamePrefix="aut-select"
							value={this.state.selectedCardType}
							isClearable={false}
							options={cardTypeOptions}
							onChange={cardType => this.setState({selectedCardType: cardType})}
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
						/>
					</div>
					{this.state.selectedCardType.value === 'explorer' && (
						<div>
							<span className="theme-label">Asset Views:</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={this.state.selectedExplorerView}
								isClearable={false}
								options={this.props.explorerViews.filter(v => v.datasetId === this.props.datasetId)}
								onChange={explorerView => this.setState({selectedExplorerView: explorerView})}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
					)}
					{this.state.selectedCardType.value === 'fundingAnalysis' && (
						<div>
							<span className="theme-label">Funding Analysis Views:</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={this.state.selectedFundingAnalysisView}
								isClearable={false}
								options={this.props.fundingAnalysisViews}
								onChange={fundingAnalysisView =>
									this.setState({selectedFundingAnalysisView: fundingAnalysisView})
								}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
					)}
					{this.state.selectedCardType.value === 'debt' && (
						<div>
							<span className="theme-label">Debt Views:</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={this.state.selectedDebtView}
								isClearable={false}
								options={this.props.debtViews.filter(v => v.datasetId === this.props.datasetId)}
								onChange={debtView => this.setState({selectedDebtView: debtView})}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
					)}
					{this.state.selectedCardType.value === 'projections' && (
						<div>
							<span className="theme-label">Scenarios:</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={this.state.selectedProjectionScenario}
								isClearable={false}
								options={this.props.projectionScenarios}
								onChange={scen => this.setState({selectedProjectionScenario: scen})}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
					)}
					{this.state.selectedCardType.value === 'payments' && (
						<div>
							<span className="theme-label">Report Type:</span>
							<KiSelect
								classNamePrefix="aut-select"
								value={this.state.paymentReportSource} // TO DO:  add an option to selkect fv or scenario type and then pass to the "createdefaultcard" fx so we can set prompts appropriately
								isClearable={false}
								options={paymentCardSourceTypes}
								onChange={src => this.setState({paymentReportSource: src})}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
							/>
						</div>
					)}
				</section>
				<section>
					<div className={styles.buttonContainer}>
						<KiButton flat primary type="submit" label="Cancel" onClick={this.props.closeModal} />
						<KiButton
							type="submit"
							label="Next"
							raised
							primary
							disabled={!this.formIsValid()}
							onClick={this.onAddCard}
						/>
					</div>
				</section>
			</div>
		);
	}
}

export default CardCreate;
