// Globals
import React from 'react';
import PropTypes from 'prop-types';
import KiButton from 'components/KiButton';
import styles from './iconBlock.theme.scss';

function IconBlock({glyphyName}) {
	return (
		<div className={styles.main}>
			<KiButton neutral label={glyphyName} icon={glyphyName} />
		</div>
	);
}

IconBlock.propTypes = {
	glyphyName: PropTypes.string,
};

IconBlock.defaultProps = {
	glyphyName: '',
};

export default IconBlock;
