// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

// Externals
import KiList from 'components/KiList';
import KiListItem from 'components/KiList/KiListItem';
import SubmissionMessage from 'components/Submission/SubmissionMessage';
import SubmissionStatusAvatar from 'components/Submission/SubmissionStatusAvatar';
import {apiUrl} from 'api/apiUtils';
import {fetchSubmission as checkSubmission} from 'containers/submissions/actions';

export class SubmissionListItem extends Component {
	static defaultProps = {
		submission: {},
	};

	isActive = () => {
		const status = this.props.submission.status.name;
		return !!status.errorMessage || status === 'upload' || status === 'validate';
	};

	generateSubmissionLink = () => {
		const {_id} = this.props.submission;
		const origin = document.baseURI;
		/*
		let origin;
		if (window.location.origin) {
			origin = window.location.origin;
		}

		if (!window.location.origin) {
			const port = window.location.port ? `:${window.location.port}` : '';
			origin = `${window.location.protocol}//${window.location.hostname}${port}`;
		}
		*/
		return `${origin}${apiUrl}/submission/downloadSubmission/${_id}`;
	};

	render() {
		const {generateSubmissionLink, isActive} = this;
		const {submission} = this.props;

		return (
			<KiListItem
				className={isActive() ? 'submission-list-item submission-clickable' : 'submission-list-item'}
				selectable={false}
				itemContent={
					<div
						className="submission-list-item-wrapper"
						onClick={isActive() ? () => this.props.onSubmissionSelected(submission) : null}
					>
						<div className="submission-list-item-content">
							<div>
								<span className="title">Date:</span>
								<span>
									{moment(submission.createdDate).isValid() &&
										moment(submission.createdDate).format('YYYY-MM-DD LTS')}
								</span>
							</div>
							<div>
								<span className="title">File Name:</span>
								<span>{submission.filename}</span>
							</div>
							<div>
								<span className="title">Snapshot Date:</span>
								<span>{submission.snapshotDate}</span>
							</div>
							<div>
								<span className="title">Dataset: </span>
								<span>{submission.categorySchemaName}</span>
							</div>
							<div>
								<span className="title">Submitted By: </span>
								<span>{submission.submittedBy}</span>
							</div>
							<div>
								<span className="title download">
									<span className="download">
										<i className="material-icons">cloud_download</i>
										<a
											className="download-link"
											href={generateSubmissionLink()}
											download
											title={'Download Submission'}
										>
											Download
										</a>
									</span>
								</span>
							</div>
							<div className="submission-stages">
								<SubmissionStatusAvatar status={submission.status} />
							</div>
						</div>
						<div className="submission-list-item-message">
							<SubmissionMessage
								text={submission.status.errorMessage}
								className="submission-message-error"
								active={!!submission.status.errorMessage}
							/>
							<SubmissionMessage
								text={submission.status.progressMessage}
								className="submission-message-progress"
								active={!!submission.status.progressMessage}
							/>
						</div>
					</div>
				}
			/>
		);
	}
}

SubmissionListItem.propTypes = {
	onSubmissionSelected: PropTypes.func,
	submission: PropTypes.object,
};

export const SubmissionList = props => {
	const {submissions = [], checkSubmission, router} = props;
	const submissionClick = submission => {
		router.push(`/datasets/${props.datasetId}/submission/${submission._id}`);
	};
	return (
		<div className="submission-list">
			{submissions.length ? (
				<KiList className="checkbox-list ki-panel">
					{submissions.map(submission => (
						<SubmissionListItem
							key={submission._id}
							onSubmissionChecked={checkSubmission}
							onSubmissionSelected={submissionClick}
							submission={submission}
						/>
					))}
				</KiList>
			) : (
				<div className="no-list-items-container ki-panel">
					<div>Add submissions using the plus button above</div>
				</div>
			)}
		</div>
	);
};

SubmissionList.propTypes = {
	submissions: PropTypes.array,
	checkSubmission: PropTypes.func,
	router: PropTypes.object,
	datasetId: PropTypes.string,
};

const mapStateToProps = state => ({
	submission: state.submissions.selected,
});

export default connect(mapStateToProps, {checkSubmission})(SubmissionList);
export {SubmissionList as SubmissionListPure};
