import * as actionTypes from './actionTypes';

const initialState = {
	isLoading: false,
	defaultMappings: [],
	columns: [],
};

function BusinessFunctions(state = initialState, action) {
	switch (action.type) {
		case actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPING:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success': {
					return {
						...state,
						isLoading: false,
						defaultMappings: state.defaultMappings.reduce((acc, mapping) => {
							if (mapping._id === action.data._id) {
								return [...acc, action.data];
							}
							return [...acc, mapping];
						}),
					};
				}
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.BUSINESS_FUNCTIONS_FETCH_DEFAULT_MAPPINGS_BY_DATASET:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, defaultMappings: action.data};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.BUSINESS_FUNCTIONS_UPDATE_DEFAULT_MAPPINGS:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success': {
					return {
						...state,
						isLoading: false,
						defaultMappings: state.defaultMappings.reduce((acc, mapping) => {
							if (mapping._id === action.data._id) {
								return [...acc, action.data];
							}
							return [...acc, mapping];
						}, []),
					};
				}
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.BUSINESS_FUNCTIONS_FETCH_COLUMNS_BY_DATASETID:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, columns: action.data};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.CLEAR_BUSINESS_FUNCTION_COLUMNS_BY_DATASET_ID:
			return {...state, columns: []};

		default:
			return state;
	}
}

export default BusinessFunctions;
