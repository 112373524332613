import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './KiCardPayments.scss';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import _get from 'lodash/get';
import KiCardPaymentsFilters from './KiCardPaymentsFilters';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {formatData, defaultData, getDisplayTypes, fetchData} from './KiCardPaymentsUtils';
import {fetchScenarios} from 'api/fundingAnalysisApi';
//import {buildTitle} from 'ki-common/utils/ExplorerUtils';

function KiCardPayments(props) {
	const {view, card, fundingVehicles, scenarioList, fundingVehicleId, scenarioId, datasetColumns} = props;
	const displayTypes = getDisplayTypes();

	// set up state
	const [statementDate, setStatementDate] = useState(dateToShortDate(new Date()));
	const [displayType, setDisplayType] = useState(
		displayTypes.find(type => type.value === (card.settings.chartType || 'data'))
	);
	const [fundingVehicle, setFundingVehicle] = useState(
		fundingVehicles.find(
			fv => fv._id === ((card.settings.fundingVehicle ? card.settings.fundingVehicle : fundingVehicleId) || null)
		)
	);
	const [scenarioOption, setScenarioOption] = useState(
		scenarioList.find(s => s.value === ((card.settings.scenarioId ? card.settings.scenarioId : scenarioId) || null))
	);
	const [columnType, setColumnType] = useState({
		label: 'Amounts',
		value: 'number',
	});
	const [summaryColumn, setSummaryColumn] = useState();
	const [data, setData] = useState(defaultData);
	const [dateContextMessage, setDateContextMessage] = useState('');
	const [noDataMessage, setNoDataMessage] = useState('');
	const [reactTableLoading, setReactTableLoading] = useState(false);
	const [error, setError] = useState('');
	const [validFvs, setVaildFvs] = useState(fundingVehicles);

	/*
	if (_get(props, 'view.explorerData.timeseries')) {
		timeSeriesSummaryColumn = view.explorerData.timeseries.column;
	}
	*/
	useEffect(() => {
		if (card.settings.scenarioId) {
			fetchScenarios(card.datasetId).then(scenarioData => {
				const selectedScenario = scenarioData.find(s => s._id === card.settings.scenarioId);
				const targetFvs = selectedScenario
					? selectedScenario.fvSettings?.filter(f => f.fvType === 'target').map(f => f.fvId)
					: [];
				setVaildFvs(
					targetFvs.length > 0 ? fundingVehicles.filter(fv => targetFvs.includes(fv._id)) : fundingVehicles
				);
			});
		}
	}, []);

	// handle display changes and refetch data
	useEffect(
		() => {
			if (!card._id) {
				setError('Card ID required');
				return;
			}
			if (!statementDate) {
				return;
			}
			setError('');
			setReactTableLoading(true);
			setDateContextMessage('');
			setNoDataMessage('');
			setData(defaultData);
			// Looks like API doesn't care about pagination settings so anything can be send
			// TODO remove it from the request
			const pagingSortingSettings = {
				pageSize: 1000,
				pageNumber: 1,
				sort: [
					{
						id: '',
						desc: '',
					},
				],
			};
			fetchData(
				datasetColumns,
				view,
				card,
				statementDate,
				fundingVehicle,
				pagingSortingSettings,
				card.settings.scenarioId || null
			)
				.then(data => {
					setData(data || defaultData);
					// set new summary column
					/*
					if (data.columns.length && !card.settings.transpose) {
						setSummaryColumn(
							getSummaryColumn(
								card.settings.chartType,
								data,
								view.explorerData.tableType,
								{
									label: 'Amounts',
									value: 'number',
								},
								timeSeriesSummaryColumn,
								_get(view.explorerData, 'timeseries.column._id')
							)
						);
					}
					if (data && data.dateContextInfo.length) {
						setDateContextMessage(
							`Data results for ${data.dateContextInfo[0].calculatedDate} using ${
								data.dateContextInfo[0].name
							} date context`
						);
					}
					*/
					if (!data.columns.length) {
						setNoDataMessage(
							`No ${view?.snapshotType === 'blended' ? 'blended' : ''} data for ${
								data.dateContextInfo[0].calculatedDate
							} is available`
						);
					}
					setReactTableLoading(false);
				})
				.catch(err => {
					setReactTableLoading(false);
					setError(err.message);
				});
		},
		[card, displayType, fundingVehicle, statementDate, scenarioOption]
	);

	if (error) {
		return <div className="dataCardError">{`Error fetching data: ${error}`}</div>;
	}

	switch (displayType.value || 'data') {
		case 'data':
			return (
				<div className="table-container">
					<KiCardPaymentsFilters
						data={data}
						view={view}
						card={card}
						displayType={displayType}
						fundingVehicle={fundingVehicle}
						fundingVehicles={validFvs}
						statementDate={statementDate}
						setDisplayType={setDisplayType}
						setFundingVehicle={setFundingVehicle}
						setStatementDate={setStatementDate}
						summaryColumn={summaryColumn}
						setSummaryColumn={setSummaryColumn}
						columnType={columnType}
						setColumnType={setColumnType}
						scenarioList={props.scenarioList}
						setScenarioOption={setScenarioOption}
						scenarioId={_get(scenarioOption, 'value', 'assetSnapshot')}
					/>
					<div className="statusBar">{dateContextMessage}</div>
					<AbsTable
						isSortEnabled={true}
						isFilterEnabled={false}
						isLoading={reactTableLoading}
						data={formatData(data.rows, data.columns).transformedRows}
						columns={formatData(data.rows, data.columns).transformedColumns}
						noDataMessage={noDataMessage}
						showNoDataMessage={!!noDataMessage}
						isHeaderSticky={true}
					/>
				</div>
			);
		/*
		case 'pie':
		case 'column':
		case 'line':
		case '3d-column':
		case '3d-pie':
		case '3d-donut':
		case 'semi-circle-donut':
		case 'pie-gradient':
		case 'pie-monochrome':
		case 'stacked-column':
		case 'area':
		case 'line-data-labels':
			return (
				<div className="table-container predefined-card-table" style={{overflow: 'auto'}}>
					<KiCardPaymentsFilters
						data={data}
						view={view}
						card={card}
						displayType={displayType}
						fundingVehicle={fundingVehicle}
						fundingVehicles={fundingVehicles}
						statementDate={statementDate}
						setDisplayType={setDisplayType}
						setFundingVehicle={setFundingVehicle}
						setStatementDate={setStatementDate}
						summaryColumn={summaryColumn}
						setSummaryColumn={setSummaryColumn}
						columnType={columnType}
						setColumnType={setColumnType}
					/>
					<KiDataCardChart
						categories={data.rows.map(data => {
							return data.meta.bucket.value || `${data.meta.bucket.min} - ${data.meta.bucket.max}`;
						})}
						series={data.columns
							.slice(1)
							.map((col, index) => {
								if (
									getFormatStringType(col.displayFormat) !== columnType.value &&
									displayType.value !== 'pie' &&
									_get(view.explorerData, 'timeseries.column._id') !== 'all'
								) {
									return null;
								}
								return {
									id: col.id,
									name: buildTitle(col.displayName, col.calculation, col.calculateColum),
									data: data.rows.map(row => row.data[index + 1]),
								};
							})
							.filter(col => {
								if (summaryColumn && view.explorerData.tableType !== 'timeSeries') {
									return (
										col !== null && (col.id === summaryColumn._id || col.id === summaryColumn.id)
									);
								}
								return col !== null;
							})}
						chartTitle={card.name}
						chartType={displayType.value}
						xTitle={''}
						yTitle={''}
						height={'500'}
						loading={reactTableLoading}
					/>
				</div>
			);
			*/
	}
}

KiCardPayments.propTypes = {
	datasetColumns: PropTypes.array.isRequired,
	card: PropTypes.object.isRequired,
	view: PropTypes.object,
	fundingVehicles: PropTypes.array,
	scenarioList: PropTypes.array,
	fundingVehicleId: PropTypes.string,
	scenarioId: PropTypes.string,
};

KiCardPayments.defaultProps = {
	datasetColumns: [],
	card: {},
	fundingVehicles: [],
	scenarioList: [],
};

export default KiCardPayments;
