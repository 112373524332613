import {
	dashboardsApi,
	datasetDatesApi,
	fundingVehiclesApi,
	viewsApi,
	eligibilityViewsApi,
	debtExplorerApi,
	datasetsApi,
	explorerApi,
} from 'api';
import {defaultFundingVehicleCards} from 'ki-common/utils/fundingVehicleUtil';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {getDebtDates} from 'ki-common/utils/cardUtil';

export async function fetchCardMetaData(card, datasetId, statementDate = dateToShortDate(new Date())) {
	const dataset = await datasetsApi.fetchDataset(datasetId, false);
	const fundingVehicles = await fundingVehiclesApi.fetchFundingVehicleList(datasetId);
	const datasetCards = await dashboardsApi.fetchAllCards({datasetId: datasetId});
	const datasetDates = await datasetDatesApi.fetchDates(datasetId, true, true);
	const datasetDebtDates = await datasetDatesApi.fetchDates(datasetId, true, false, true);
	const datasetViews = await viewsApi.fetchViewsByDataset(datasetId);
	const datasetDebtViews = await debtExplorerApi.fetchBookmarks(datasetId);
	const eligibilityViews = await eligibilityViewsApi.fetchEAViewsByDatasetId(datasetId);

	let dateContext,
		assetDateContext,
		debtDateContext,
		view,
		fundingVehicle,
		cardTypeLabel,
		quickFilters = [],
		calcDate,
		fvDates = [];
	switch (card.settings.type) {
		case 'eligibilityAnalysis':
			fundingVehicle = null; // not relevant
			view = eligibilityViews.find(v => v._id === card.settings.eligibilityViewId);
			dateContext = datasetDates.find(d => d._id === view.dateContext);
			cardTypeLabel = 'Eligibility Analysis';
			break;
		case 'tabular':
			fundingVehicle = fundingVehicles.find(fv => fv._id === card.settings.fundingVehicle);
			view = datasetViews.find(v => v._id === card.bookmarkId);
			if (!view) {
				return {
					error: `Missing view (${card.bookmarkId})`,
				};
			}
			dateContext = datasetDates.find(d => d._id === view.explorerData.dateContext);
			cardTypeLabel = 'Asset Exploration';
			calcDate = statementDate;
			quickFilters = await explorerApi.fetchQuickFilters(
				datasetId,
				view.explorerData.quickFilters.scenarioId,
				view.explorerData.quickFilters.fundingVehicleId,
				statementDate,
				view.explorerData.quickFilters.scenarioType,
				dateContext._id,
				datasetDates,
				view.explorerData.isFixedDate,
				dataset.snapshots
			);
			break;
		case 'debtView':
			fundingVehicle = fundingVehicles.find(fv => fv._id === card.settings.fundingVehicle);
			view = datasetDebtViews.find(v => v._id === card.settings.debtBookmarkId);
			if (!view) {
				return {
					error: `Missing debt view (${card.settings.debtBookmarkId})`,
				};
			}
			fvDates = await fundingVehiclesApi.fetchFundingVehiclesWithDates(datasetId, statementDate);
			// debt has two date contexts, which will either be a groupId or default
			assetDateContext = datasetDebtDates.find(d => d.groupId == view.settings.assetDateContext);
			if (!assetDateContext) {
				assetDateContext = datasetDebtDates.find(d => d.groupId == 'reportDate'); //default
			}
			debtDateContext = datasetDebtDates.find(d => d.groupId == view.settings.debtDateContext);
			if (!debtDateContext) {
				debtDateContext = datasetDebtDates.find(d => d.groupId == 'collectionEndDate'); //default
			}
			cardTypeLabel = 'Debt Exploration';

			calcDate = getDebtDates(
				card.settings.fundingVehicle || fundingVehicles[0]._id,
				fvDates,
				debtDateContext.groupId,
				assetDateContext.groupId
			);
			// fetching scenario list thru asset method as it is the only way for now
			quickFilters = await explorerApi.fetchQuickFilters(
				datasetId,
				card.settings.scenarioId,
				card.settings.fundingVehicle,
				calcDate.assetMatch.value,
				'assetSnapshot', // not necessary for debt?
				assetDateContext._id, // using id because this is set up for asset not debt
				datasetDebtDates,
				false,
				dataset.snapshots
			);
			break;
		case 'borrowingBase':
		case 'assetsInBreach':
		case 'aggregateTests':
		case 'excessConcentration':
		case 'breaches':
		case 'compliance':
			fundingVehicle = fundingVehicles.find(fv => fv._id === card.fundingVehicleId);
			dateContext = datasetDates.find(d => d.groupId === card.settings.dateContext);
			cardTypeLabel = defaultFundingVehicleCards.find(o => o.type === card.settings.type).name;
			calcDate = statementDate;
			quickFilters = await explorerApi.fetchQuickFilters(
				datasetId,
				card.settings.scenarioId,
				card.settings.fundingVehicleId,
				statementDate,
				'assetSnapshot', // not necessary for predefined scenario list?
				dateContext._id,
				datasetDates,
				false,
				dataset.snapshots
			);
			break;
		case 'aggregatedPayment':
			fundingVehicle = fundingVehicles.find(fv => fv._id === card.settings.fundingVehicle);
			cardTypeLabel = 'Payment Exploration';
			calcDate = statementDate;
			quickFilters = null; // no scenario selector for pmt card
			break;
	}
	return {
		card: card,
		fundingVehicle: fundingVehicle,
		dateContext: dateContext,
		assetDateContext: assetDateContext,
		debtDateContext: debtDateContext,
		view: view,
		cardTypeLabel: cardTypeLabel,
		fundingVehicleList: fundingVehicles,
		scenarioList: quickFilters ? quickFilters.scenarioList : [],
		datasetCardsList: datasetCards,
		statementDate: statementDate,
		calcDate: calcDate,
	};
}

export default {
	fetchCardMetaData,
};
