import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import options from 'ki-common/options';
import PropTypes from 'prop-types';
import KiSelect from 'components/KiSelect';
import KiInput from 'components/KiInput';
import moment from 'moment';
import styles from './DateFormulaFields.theme.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export const generateDateParseExampleResult = (date, value = 'day') => {
	let result = moment(date, 'YYYY/MM/DD');
	switch (value) {
		case 'day':
			result = `0${result.date()}`.slice(-2);
			break;
		case 'month':
			result = `0${result.month() + 1}`.slice(-2);
			break;
		case 'year':
			result = result.year();
			break;
		case 'year-month':
			result = `${result.year()}-${`0${result.month() + 1}`.slice(-2)}`;
			break;
	}
	return `"${date}" → "${result}"`;
};

export function DateParseFormulaFields(props) {
	const [columnId, setColumnId] = useState(props.columnId);
	const [unitOfMeasurement, setUnitOfMeasurement] = useState(props.unitOfMeasurement);
	const [offset, setOffset] = useState(props.offset);
	useEffect(
		() => {
			props.onChange({
				isValid: !!unitOfMeasurement && !!columnId,
				formula: {
					op: 'dateParse',
					left: {
						type: 'column',
						parseType: unitOfMeasurement,
						...(parseInt(offset) && {offset}),
						value: columnId,
					},
					right: {
						type: 'string',
						value: null,
					},
				},
			});
		},
		[unitOfMeasurement, columnId, offset]
	);

	return (
		<div className={styles.root}>
			<div className={styles.dateAndOffsetContainer}>
				<div className={cx('dateColumnSelectWrapper', {hasOffset: !props.hideOffset})}>
					<span className="form-instruction">Column:</span>
					<KiSelect
						classNamePrefix="aut-select"
						value={props.dateColumns.find(column => column._id === columnId)}
						options={props.dateColumns}
						isClearable={false}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						onChange={opt => setColumnId(opt._id)}
					/>
				</div>
				{!props.hideOffset && (
					<div className="calculation-form-section">
						<span className="form-instruction">Offset:</span>
						<KiInput
							min={-99}
							max={99}
							className={styles.offsetInput}
							value={offset}
							type="number"
							onChange={number => setOffset(number)}
						/>
					</div>
				)}
			</div>
			<div className="calculation-form-section">
				<span className="form-instruction">Value:</span>
				<KiSelect
					classNamePrefix="aut-select"
					value={options.dateParseValues.find(opt => opt.value === unitOfMeasurement)}
					options={options.dateParseValues}
					isOptionDisabled={opt => opt.value === 'year-month' && !!props.hideYearMonthOption}
					isClearable={false}
					isSearchable={false}
					onChange={opt => setUnitOfMeasurement(opt.value)}
				/>
			</div>
			<div className="calculation-form-section">
				<span className="form-instruction">
					<span className="example">
						Example: {generateDateParseExampleResult('2010/10/31', unitOfMeasurement)}
					</span>
				</span>
			</div>
		</div>
	);
}
DateParseFormulaFields.propTypes = {
	dateColumns: PropTypes.array,
	columnId: PropTypes.string,
	unitOfMeasurement: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	hideYearMonthOption: PropTypes.bool,
	offset: PropTypes.string,
	hideOffset: PropTypes.bool,
};
DateParseFormulaFields.defaultProps = {
	dateColumns: [],
	unitOfMeasurement: 'day',
	hideYearMonthOption: false,
	offset: '0',
	hideOffset: false,
};

export const DateDiffFormulaFields = props => {
	const [startingDateId, setStartingDateId] = useState(props.startingDateId);
	const [endingDateId, setEndingDateId] = useState(props.endingDateId);
	const [startingDateOffset, setStartingDateOffset] = useState(props.startingDateOffset);
	const [endingDateOffset, setEndingDateOffset] = useState(props.endingDateOffset);
	const [unitOfMeasurement, setUnitOfMeasurement] = useState(props.unitOfMeasurement);
	useEffect(
		() => {
			props.onChange({
				isValid: !!unitOfMeasurement && !!endingDateId && !!startingDateId,
				formula: {
					op: 'dateDiff',
					left: {
						type: 'column',
						counter: unitOfMeasurement,
						...(parseInt(endingDateOffset) && {offset: endingDateOffset}),
						value: endingDateId,
					},
					right: {
						type: 'column',
						counter: unitOfMeasurement,
						...(parseInt(startingDateOffset) && {offset: startingDateOffset}),
						value: startingDateId,
					},
				},
			});
		},
		[unitOfMeasurement, endingDateId, startingDateId, startingDateOffset, endingDateOffset]
	);
	return (
		<div className={styles.root}>
			<div className={styles.dateAndOffsetContainer}>
				<div className={cx('dateColumnSelectWrapper', {hasOffset: !props.hideOffset})}>
					<span className="form-instruction">Earlier Date:</span>
					<KiSelect
						classNamePrefix="aut-select"
						value={props.dateColumns.find(col => col._id === startingDateId)}
						options={props.dateColumns}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						onChange={opt => setStartingDateId(opt._id)}
					/>
				</div>

				{!props.hideOffset && (
					<div className="calculation-form-section">
						<span className="form-instruction">Offset:</span>
						<KiInput
							min={-99}
							max={99}
							type="number"
							className={styles.offsetInput}
							name="startingDateOffset"
							value={startingDateOffset}
							onChange={val => setStartingDateOffset(val || '0')}
						/>
					</div>
				)}
			</div>

			<div className={styles.dateAndOffsetContainer}>
				<div className={cx('dateColumnSelectWrapper', {hasOffset: !props.hideOffset})}>
					<span className="form-instruction">Later Date:</span>
					<KiSelect
						classNamePrefix="aut-select"
						value={props.dateColumns.find(col => col._id === endingDateId)}
						options={props.dateColumns}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						onChange={opt => setEndingDateId(opt._id)}
					/>
				</div>
				{!props.hideOffset && (
					<div className="calculation-form-section">
						<span className="form-instruction">Offset:</span>
						<KiInput
							min={-99}
							max={99}
							type="number"
							className={styles.offsetInput}
							name="endingDateOffset"
							value={endingDateOffset}
							onChange={val => setEndingDateOffset(val || '0')}
						/>
					</div>
				)}
			</div>

			<div className="calculation-form-section">
				<span className="form-instruction">Counter:</span>
				<KiSelect
					classNamePrefix="aut-select"
					value={options.dateCounters.find(opt => opt.value === unitOfMeasurement)}
					isClearable={false}
					isSearchable={false}
					options={options.dateCounters}
					getOptionLabel={option => option.label}
					getOptionValue={option => option.value}
					onChange={opt => setUnitOfMeasurement(opt.value)}
				/>
			</div>
		</div>
	);
};

DateDiffFormulaFields.propTypes = {
	dateColumns: PropTypes.array,
	startingDateId: PropTypes.string,
	endingDateId: PropTypes.string,
	unitOfMeasurement: PropTypes.string,
	startingDateOffset: PropTypes.string,
	endingDateOffset: PropTypes.string,
	hideOffset: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};
DateDiffFormulaFields.defaultProps = {
	dateColumns: [],
	unitOfMeasurement: 'days',
	startingDateOffset: '0',
	endingDateOffset: '0',
	hideOffset: false,
};

export const DateAdjustmentFormulaFields = props => {
	const [columnId, setColumnId] = useState(props.columnId);
	const [adjustmentAmount, setAdjustmentAmount] = useState(props.adjustmentAmount);
	const [unitOfMeasurement, setUnitOfMeasurement] = useState(props.unitOfMeasurement);
	const [offset, setOffset] = useState(props.offset);
	const [direction, setDirection] = useState(props.direction);

	useEffect(
		() => {
			props.onChange({
				isValid: !!direction && !!columnId && !!unitOfMeasurement && !!adjustmentAmount,
				formula: {
					op: 'dateAdd',
					left: {
						addOp: direction,
						type: 'column',
						...(parseInt(offset) && {offset}),
						value: columnId,
					},
					right: {
						addOp: direction,
						addCounter: unitOfMeasurement,
						value: adjustmentAmount,
					},
				},
			});
		},
		[columnId, direction, unitOfMeasurement, adjustmentAmount, offset]
	);

	return (
		<div className={styles.root}>
			<div className={styles.dateAndOffsetContainer}>
				<div className={cx('dateColumnSelectWrapper', {hasOffset: !props.hideOffset})}>
					<span className="form-instruction">Column:</span>
					<KiSelect
						name="columnId"
						value={props.dateColumns.find(col => col._id === columnId)}
						options={props.dateColumns}
						isClearable={false}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						onChange={opt => setColumnId(opt._id)}
					/>
				</div>
				{!props.hideOffset && (
					<div className="calculation-form-section">
						<span className="form-instruction">Offset:</span>
						<KiInput
							min={-99}
							max={99}
							type="number"
							className={styles.offsetInput}
							name="offset"
							value={offset}
							onChange={val => setOffset(val || '0')}
						/>
					</div>
				)}
			</div>
			<div className={styles.dateAndOffsetContainer} style={props.hideOffset ? {} : {marginTop: '2rem'}}>
				<div className="calculation-form-section">
					<KiSelect
						name="direction"
						value={options.dateAddFunctionTypes.find(opt => opt.value === direction)}
						options={options.dateAddFunctionTypes}
						isClearable={false}
						isSearchable={false}
						onChange={opt => setDirection(opt.value)}
					/>
				</div>
				<div className="calculation-form-section">
					<KiInput
						min={-99}
						max={99}
						className={styles.adjustmentAmountInput}
						title="Value:"
						name="adjustmentAmount"
						value={adjustmentAmount}
						type="number"
						onChange={val => setAdjustmentAmount(val)}
					/>
				</div>
				<div className="calculation-form-section">
					<KiSelect
						name="unitOfMeasurement"
						value={options.dateAddCounters.find(opt => opt.value === unitOfMeasurement)}
						options={options.dateAddCounters}
						isClearable={false}
						isSearchable={false}
						onChange={opt => setUnitOfMeasurement(opt.value)}
					/>
				</div>
			</div>
		</div>
	);
};
DateAdjustmentFormulaFields.propTypes = {
	dateColumns: PropTypes.array,
	columnId: PropTypes.string,
	adjustmentAmount: PropTypes.string,
	unitOfMeasurement: PropTypes.any,
	direction: PropTypes.oneOf(['+', '-']),
	offset: PropTypes.string,
	hideOffset: PropTypes.bool,
	onChange: PropTypes.func,
};
DateAdjustmentFormulaFields.defaultProps = {
	dateColumns: [],
	adjustmentAmount: '1',
	unitOfMeasurement: _.get(options.dateCounters, '[0].value', 'days'),
	direction: '+',
	offset: '0',
	hideOffset: false,
};

export const DateFormulaFields = props => {
	switch (props.dateFunctionType) {
		case 'dateDiff':
			return (
				<DateDiffFormulaFields
					dateColumns={props.dateColumns}
					unitOfMeasurement={_.get(props.formula, 'right.counter')}
					startingDateId={_.get(props.formula, 'right.value._id', _.get(props.formula, 'right.value'))}
					startingDateOffset={_.get(
						props.formula,
						'right.value.offset',
						_.get(props.formula, 'right.offset', '0')
					)}
					endingDateId={_.get(props.formula, 'left.value._id', _.get(props.formula, 'left.value'))}
					endingDateOffset={_.get(
						props.formula,
						'left.value.offset',
						_.get(props.formula, 'left.offset', '0')
					)}
					hideOffset={!props.isDebt}
					onChange={props.onChange}
				/>
			);
		case 'dateParse':
			return (
				<DateParseFormulaFields
					dateColumns={props.dateColumns}
					unitOfMeasurement={_.get(props.formula, 'left.parseType')}
					columnId={_.get(props.formula, 'left.value._id', _.get(props.formula, 'left.value'))}
					offset={_.get(props.formula, 'left.value.offset', _.get(props.formula, 'left.offset', '0'))}
					hideOffset={!props.isDebt}
					onChange={props.onChange}
					hideYearMonthOption={!!props.isDebt}
				/>
			);
		case 'dateAdd':
			return (
				<DateAdjustmentFormulaFields
					dateColumns={props.dateColumns}
					unitOfMeasurement={_.get(props.formula, 'right.addCounter')}
					columnId={_.get(props.formula, 'left.value._id', _.get(props.formula, 'left.value'))}
					adjustmentAmount={_.get(props.formula, 'right.value')}
					direction={_.get(props.formula, 'left.addOp')}
					offset={_.get(props.formula, 'left.value.offset', _.get(props.formula, 'left.offset', '0'))}
					hideOffset={!props.isDebt}
					onChange={props.onChange}
				/>
			);
		default:
			return null;
	}
};

DateFormulaFields.propTypes = {
	dateFunctionType: PropTypes.oneOf(['dateDiff', 'dateParse', 'dateAdd']),
	dateColumns: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	formula: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	isDebt: PropTypes.bool,
};

DateFormulaFields.defaultProps = {
	formula: {
		op: 'dateDiff',
		left: {},
		right: {},
	},
	dateColumns: [],
	isDebt: false,
};

export default DateFormulaFields;
