import React from 'react';
import PropTypes from 'prop-types';
import styles from '../FlyoutManageViews.theme.scss';
import classNames from 'classnames';
import ViewLink from './ViewLink';
import {useHistory} from 'react-router-dom';

const ViewListItem = ({bookmark = {}, copyBookmark, deleteBookmark, currentViewId, user, isAdmin}) => {
	const isGlobal = bookmark.isGlobal;
	const isActive = bookmark._id === currentViewId ? true : false;
	const history = useHistory();

	const clickAction = bookmark => {
		return () => history.push(`/dataExplorer/${bookmark.datasetId}?bookmarkId=${bookmark._id}`);
	};

	return (
		<div className={styles.row}>
			<div className={styles.rowInfo}>
				<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
					<i title={isGlobal ? 'Global' : 'User'} className="material-icons">
						{isGlobal ? 'language' : 'person'}
					</i>
				</div>
				<div
					className={classNames({'list-icon-btn': true, 'active-text': isActive, 'link-text': true})}
					onClick={clickAction(bookmark)}
				>
					{bookmark.name}
				</div>
				{bookmark.isDefault && (
					<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
						<i title="Default" className="material-icons">
							home
						</i>
					</div>
				)}
				{bookmark.isFavorite && (
					<div className={classNames({'list-icon-btn': true, 'active-text': isActive, 'no-rollover': true})}>
						<i title="Favorite" className="material-icons">
							star
						</i>
					</div>
				)}
			</div>
			<div className={styles.rowActions}>
				<ViewLink
					isAdmin={isAdmin}
					allowAll={true}
					title="Copy"
					alt="Copy View"
					iconName="content_copy"
					onClick={() => copyBookmark(bookmark)}
					user={user}
					bookmark={bookmark}
				/>
				<ViewLink
					isAdmin={isAdmin}
					allowAll={false}
					title="Delete"
					alt="Delete View"
					iconName="delete"
					onClick={() => deleteBookmark(bookmark)}
					user={user}
					bookmark={bookmark}
				/>
			</div>
		</div>
	);
};

ViewListItem.propTypes = {
	bookmark: PropTypes.object,
	deleteBookmark: PropTypes.func,
	currentViewId: PropTypes.string,
	user: PropTypes.object,
	copyBookmark: PropTypes.func,
	isAdmin: PropTypes.bool,
};

export default ViewListItem;
