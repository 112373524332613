import React from 'react';
import PropTypes from 'prop-types';
import styles from './KiComponents.theme.scss';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import KiChip from 'components/KiChip';
import KiInput from 'components/KiInput';
import KiRadioGroup from 'components/KiRadioGroup';
import KiRadioButton from 'components/KiRadioButton';
import KiList from 'components/KiList';
import KiListItem from 'components/KiList/KiListItem';
import KiButton from 'components/KiButton';
import KiIconButton from 'components/KiIconButton';
import KiAppBar from 'components/KiAppBar';
import KiFontIcon from 'components/KiFontIcon';
import KiProgressBar from 'components/KiProgressBar';
import KiSnackbar from 'components/KiSnackbar';
import KiCheckbox from 'components/KiCheckbox';
import KiMenu from 'components/KiMenu';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import KiModal from 'components/KiModal';
import KiDatePicker from 'components/KiDatePicker';
import KiConfirmModal from 'components/KiConfirmModal';
import KiDnDList from 'components/KiDnDList';
import KiWizard from 'components/KiWizard';
import WizardStep from 'components/KiWizard/components/WizardStep';
import ScreenOne from 'components/KiWizard/TestContainers/TestStepOne';
import ScreenTwo from 'components/KiWizard/TestContainers/TestStepTwo';
import ScreenThree from 'components/KiWizard/TestContainers/TestStepThree';
import KiTwoPanelList from 'components/KiTwoPanelList';
/*
TODO enable later with code from kiFormulaBuilder.txt
import KiFormulaBuilder from 'components/KiFormulaBuilder';
import {columns} from './columns';
*/
import KiSelect from 'components/KiSelect';
import IconBlock from './iconBlock.js';

/* eslint-disable no-console */

const tabOnChangeExample = () => {
	console.log('this was triggered from a tab change');
};

const onMenuClickExample = () => {
	console.log('menu item clicked');
};

const dndItems = [
	{
		id: 1,
		content: 'first',
	},
	{
		id: 2,
		content: 'second',
	},
	{
		id: 3,
		content: 'third',
	},
	{
		id: 4,
		content: 'fourth',
	},
	{
		id: 5,
		content: 'fifth',
	},
	{
		id: 6,
		content: 'sixth',
	},
];

/*
TODO enable later with code from kiFormulaBuilder.txt
const fvList = [
	{
		_id: '5da75204ab6fd907599dc60f',
		name: 'MI_Test_1',
		pools: [],
		legalName: '',
		datasetId: 'bdc9b7c2-fca7-4b6d-8b26-ac5d783d4c26',
		datasetName: 'MI_Testing_SP',
	},
	{
		_id: '5da7520bab6fd907599dc62c',
		name: 'MI_Test_2',
		pools: [],
		legalName: '',
		datasetId: 'bdc9b7c2-fca7-4b6d-8b26-ac5d783d4c26',
		datasetName: 'MI_Testing_SP',
	},
	{
		_id: '5da75216ab6fd907599dc62e',
		name: 'MI_Test_3',
		pools: [],
		legalName: '',
		datasetId: 'bdc9b7c2-fca7-4b6d-8b26-ac5d783d4c26',
		datasetName: 'MI_Testing_SP',
	},
	{
		_id: '5da75246ab6fd907599dc633',
		name: 'MI_Test_4',
		pools: [],
		legalName: '',
		datasetId: 'bdc9b7c2-fca7-4b6d-8b26-ac5d783d4c26',
		datasetName: 'MI_Testing_SP',
	},
];
*/

const CustomItemContent = props => {
	const {content} = props;
	return (
		<div>
			<ul>
				<li>{content}</li>
				<li>{content}</li>
				<li>{content}</li>
			</ul>
		</div>
	);
};

CustomItemContent.propTypes = {
	content: PropTypes.string,
};

const OutputTypeOptions = [
	{
		label: 'string',
		value: 'string',
	},
	{
		label: 'numeric',
		value: 'numeric',
	},
	{
		label: 'date_long',
		value: 'date_long',
	},
	{
		label: 'boolean',
		value: 'boolean',
	},
];

const mainTestFormula = [
	{type: 'operator', value: '('},
	{type: 'operator', value: '('},
	{type: 'operator', value: '('},
	{type: 'column', value: '5da8d4947bb09dd635fd880b'},
	{type: 'operator', value: '+'},
	{type: 'constant', value: 'Y'},
	{type: 'operator', value: ')'},
	{type: 'operator', value: '*'},
	{type: 'constant', value: 'Z'},
	{type: 'operator', value: '=='},
	{type: 'constant', value: '10'},
	{type: 'operator', value: '||'},
	{type: 'constant', value: 'N'},
	{type: 'operator', value: '/'},
	{type: 'constant', value: 'M'},
	{type: 'operator', value: '<'},
	{type: 'constant', value: '20'},
	{type: 'operator', value: '&&'},
	{type: 'max', value: ['5da9f9d2272aa28023e2eeb2', '5da9f9d1272aa28023e2eeb1']},
	{type: 'operator', value: '=='},
	{type: 'constant', value: '30'},
	{type: 'operator', value: ')'},
	{type: 'operator', value: '?'},
	{type: 'constant', value: '11'},
	{type: 'operator', value: ':'},
	{type: 'constant', value: 'Y'},
	{type: 'operator', value: '=='},
	{type: 'constant', value: '40'},
	{type: 'operator', value: '&&'},
	{type: 'constant', value: 'Z'},
	{type: 'operator', value: '<'},
	{type: 'constant', value: '50'},
	{type: 'operator', value: '?'},
	{type: 'constant', value: '12'},
	{type: 'operator', value: ':'},
	{type: 'constant', value: '13'},
];

export class KiComponents extends React.Component {
	state = {
		radioValue: '',
		showSnackBar: false,
		checkboxes: {
			first: false,
			second: true,
		},
		formModalActive: false,
		confirmationModalActive: false,
		datepicker1: '2018-01-28',
		datepicker2: '01/28/2018',
		dndDefaultItems: JSON.parse(JSON.stringify(dndItems)),
		dndCustomItems: JSON.parse(JSON.stringify(dndItems)),
		dndHorizontalItems: JSON.parse(JSON.stringify(dndItems)),
		twoPanelListItems: JSON.parse(JSON.stringify(dndItems)),
		currentStep: 1,
		isKiFormulaBuilderActive: false,
		formulaFVArray: [],
		formulaArray: mainTestFormula,
		formulaOutput: 'numeric',
		isKiFormulaBuilderActive2: false,
		formulaArray2: [],
		kiSelectValue: '',
	};

	closeSnackbar = () => {
		this.setState({showSnackBar: false});
	};

	setCheckbox = (name, value) => {
		const state = this.state;
		state.checkboxes[name] = value;
		this.setState(state);
	};

	setStateItems = (stateName, items) => {
		const update = {};
		update[stateName] = items;
		this.setState(update);
	};

	renderCustomItemContent = content => {
		return <CustomItemContent content={content} />;
	};

	onNextHandler = wizardState => {
		console.log('Just went to next screen, we now have this state', wizardState);
		this.setState({currentStep: this.state.currentStep + 1});
	};

	onPreviousHandler = wizardState => {
		console.log('Just went to previous screen, we now have this state', wizardState);
		this.setState({currentStep: this.state.currentStep - 1});
	};

	render() {
		const {onNextHandler, onPreviousHandler} = this;
		return (
			<div className={styles.wrapper}>
				<h3>Ki Components</h3>
				<div>
					<h4>KiSelect</h4>
					<KiSelect
						isMulti={false}
						value={OutputTypeOptions.find(entry => entry.value === this.state.kiSelectValue)}
						options={OutputTypeOptions}
						getOptionLabel={opt => opt.label}
						getOptionValue={opt => opt.value}
						onChange={selection => {
							this.setState({kiSelectValue: selection.value});
						}}
					/>
					<p>With Style overrides</p>
					<KiSelect
						isMulti={true}
						value={OutputTypeOptions.find(entry => entry.value === this.state.kiSelectValue)}
						options={OutputTypeOptions}
						getOptionLabel={opt => opt.label}
						getOptionValue={opt => opt.value}
						onChange={selection => {
							this.setState({kiSelectValue: selection.value});
						}}
						styles={{
							container: base => ({
								...base,
								'max-width': '30rem',
							}),
						}}
					/>
				</div>
				<br />

				<div>
					<h4>KiTwoPanelList</h4>
					<KiTwoPanelList itemList={this.state.twoPanelListItems} />
				</div>

				<div>
					<h4>KiWizard</h4>
					<KiWizard
						currentStepNumber={this.state.currentStep}
						onNext={onNextHandler}
						onPrevious={onPreviousHandler}
						numberOfSteps={3}
						showSpinner={false}
					>
						<WizardStep
							cancelButtonLabel={'Cancel'}
							nextButtonLabel={'Continue'}
							onNextClick={onNextHandler}
							showBackButton={false}
							showCancelButton={true}
							showNextButton={true}
							showSpinner={true}
							title={'Step 1'}
							enableBackButton={false}
							disableNextButton={false}
						>
							<ScreenOne />
						</WizardStep>

						<WizardStep
							cancelButtonLabel={'Cancel'}
							nextButtonLabel={'Continue'}
							onNextClick={onNextHandler}
							showBackButton={true}
							showCancelButton={true}
							showNextButton={true}
							showSpinner={true}
							title={'Step 2'}
							enableBackButton={true}
							disableNextButton={false}
						>
							<ScreenTwo />
						</WizardStep>

						<WizardStep
							cancelButtonLabel={'Cancel'}
							nextButtonLabel={'Continue'}
							onNextClick={onNextHandler}
							showBackButton={true}
							showCancelButton={true}
							showNextButton={true}
							showSpinner={true}
							title={'Step 3'}
							enableBackButton={true}
							disableNextButton={false}
						>
							<ScreenThree />
						</WizardStep>
					</KiWizard>
				</div>

				<div>
					<h4>Default DnD List</h4>
					<KiDnDList
						items={this.state.dndDefaultItems}
						onReorder={items => this.setStateItems('dndDefaultItems', items)}
					/>
				</div>
				<div>
					<h4>Default DnD List Horizontal</h4>
					<KiDnDList
						isHorizontal={true}
						items={this.state.dndHorizontalItems}
						onReorder={items => this.setStateItems('dndHorizontalItems', items)}
					/>
				</div>
				<div>
					<h4>Custom Class DnD List</h4>
					<KiDnDList
						items={this.state.dndCustomItems}
						onReorder={items => this.setStateItems('dndCustomItems', items)}
						extendListClass={styles['dndCustomList']}
						extendItemClass={styles['dndCustomItem']}
						contentRenderFunc={this.renderCustomItemContent}
					/>
				</div>
				<ul>
					<li>
						<h4>KiTabs Test</h4>
						<KiTabs className="custom-style" index={1} onChange={tabOnChangeExample}>
							<KiTab title="Tab One">tab one content</KiTab>
							<KiTab title="Tab Two">
								<div>
									<span>nested children </span>
									<span>in this example</span>
								</div>
							</KiTab>
							<KiTab disabled={true} title="Tab Three">
								tab three content
							</KiTab>
						</KiTabs>
					</li>
					<li>
						<h4>KiChip</h4>
						<KiChip icon="priority_high" iconColor="#FFFFFF" iconBackgroundColor="#ccc">
							<span style={{textTransform: 'capitalize'}}>KiChip</span>
						</KiChip>
					</li>
					<li>
						<h4>KiInput</h4>
						<KiInput
							className="flibberty-jibbet"
							disabled={false}
							defaultValue="some text"
							error="Error Message"
							floating={true}
							label="KiInput Label"
							maxLength={10}
							onBlur={e => console.log('KiInput.onBlur', e.target.value)}
							onChange={value => console.log('KiInput.onChange', value)}
							onFocus={e => console.log('KiInput.onFocus', e.target.value)}
							onKeyPress={e => console.log('KiInput.onKeyPress', e.target.value)}
							required={true}
							type="text"
							isNumberMasked={false}
							maskConfig={null}
						/>
						<KiInput
							className="flibberty-jibbet"
							disabled={false}
							floating={true}
							label="KiInput Number"
							maxLength={10}
							type="text"
							isNumberMasked={true}
							maskConfig={null}
						/>
						<KiInput
							className="flibberty-jibbet"
							hint="This is your hint" // Will hide if value is not empty
							type="text"
						/>
					</li>
					<li>
						<h4>KiRadioGroup</h4>
						<p>Wrapper Class for KiRadioButton</p>
					</li>
					<li>
						<h4>KiMenu</h4>
						<div>With icons</div>
						<KiMenu icon="more_vert" position="topLeft">
							<KiMenuItem icon="face" label="Account" onClick={onMenuClickExample} />
							<KiMenuItem icon="explore" label="Explore" onClick={onMenuClickExample} />
							<KiMenuItem disabled={true} icon="extension" label="Explore" />
						</KiMenu>
						<div>With no icon</div>
						<KiMenu icon="keyboard_arrow_down">
							<KiMenuItem label="Item One" onClick={onMenuClickExample} />
							<KiMenuItem label="Item Two" onClick={onMenuClickExample} />
							<KiMenuItem disabled={true} label="Item Three" onClick={onMenuClickExample} />
						</KiMenu>
					</li>
					<li>
						<h4>KiRadioButton</h4>
						<KiRadioGroup
							value={this.state.radioValue}
							onChange={value => {
								this.setState({radioValue: value});
							}}
						>
							<KiRadioButton label="Option 1" value="one" />
							<KiRadioButton label="Option 2" value="two" />
							<KiRadioButton label="Option 3" value="three" />
						</KiRadioGroup>
					</li>
					<li>
						<h4>KiList</h4>
						<div>With children KiListItem</div>
						<KiList selectable>
							<KiListItem caption="Item One" icon="save" />
							<KiListItem caption="Item Two" icon="add" />
						</KiList>
						<div>With children div, not selectable</div>
						<KiList>
							<div>Item One</div>
							<div>Item Two</div>
							<div>Item Three</div>
						</KiList>
					</li>
					<li>
						<h4>KiIconButton</h4>
						<KiIconButton icon="cancel" className="cname" />
					</li>
					<li>
						<h4>KiAppBar</h4>
						<span>title prop only</span>
						<KiAppBar title="App Bar Title Only" className={styles.appBarTestClass} />
						<span>children prop only</span>
						<KiAppBar>
							<div>one</div>
							<div>two</div>
							<div>three</div>
							<div>four</div>
						</KiAppBar>
					</li>
					<li>
						<h4>KiFontIcon</h4>
						<KiFontIcon value="picture_in_picture" />
						<KiFontIcon>picture_in_picture</KiFontIcon>
					</li>
					<li>
						<h4>KiProgressBar</h4>
						<KiProgressBar />
					</li>
					<li>
						<h4>KiSnackBar</h4>
						<KiButton onClick={() => this.setState({showSnackBar: true})}>Show SnackBar</KiButton>
						<KiSnackbar
							className="snackbar"
							active={this.state.showSnackBar}
							label="this is my snackbar"
							onClick={this.closeSnackbar}
							type={'accept'}
							timeout={4000}
							onTimeout={this.closeSnackbar}
						/>
					</li>
				</ul>
				<ul>
					<h4>KiButton</h4>
					<li>
						<KiButton neutral flat>
							Button neutral flat
						</KiButton>
						<KiButton neutral raised>
							Button neutral raised
						</KiButton>
					</li>
					<li>
						<KiButton primary flat>
							Button primary flat
						</KiButton>
						<KiButton primary raised>
							Button primary raised
						</KiButton>
					</li>
					<li>
						<KiButton primary flat disabled={true}>
							Button primary flat disabled
						</KiButton>
						<KiButton primary raised disabled={true}>
							Button primary raised disabled
						</KiButton>
					</li>
					<li>
						<KiButton neutral flat label="A label" />
						<KiButton neutral flat label="A label w/ icon" icon="explore" />
					</li>
					<li>
						<KiButton neutral flat icon="explore" />
						<KiButton neutral raised icon="explore" />
						<KiButton primary flat icon="explore" />
						<KiButton primary raised icon="explore" />
					</li>
					<li>
						<KiButton neutral flat mini>
							nf mini
						</KiButton>
						<KiButton neutral raised mini>
							nr mini
						</KiButton>
					</li>
					<li>
						<KiButton primary flat mini>
							pf mini
						</KiButton>
						<KiButton primary raised mini>
							pr mini
						</KiButton>
					</li>
				</ul>
				<ul>
					<li>
						<h4>KiCheckbox</h4>
						<KiCheckbox
							checked={this.state.checkboxes.first}
							label="First"
							onChange={this.setCheckbox.bind(this, 'first')}
						/>
						<KiCheckbox
							checked={this.state.checkboxes.second}
							label="Second"
							onChange={this.setCheckbox.bind(this, 'second')}
						/>
					</li>
				</ul>
				<ul>
					<h4>KiModal</h4>
					<li>
						<p>Form mode</p>
						<KiButton onClick={() => this.setState({formModalActive: true})}>Show Form Modal</KiButton>
						<KiModal
							actions={[
								{label: 'Cancel', onClick: () => this.setState({formModalActive: false})},
								{label: 'Save', onClick: () => this.setState({formModalActive: false})},
							]}
							active={this.state.formModalActive}
							onClose={() => this.setState({formModalActive: false})}
							header="some big form header"
						>
							<h2>Big form contents</h2>
						</KiModal>
					</li>
					<li>
						<p>Confirmation mode</p>
						<KiButton onClick={() => this.setState({confirmationModalActive: true})}>
							Show Confirmation Modal
						</KiButton>
						<KiConfirmModal
							active={this.state.confirmationModalActive}
							acceptFunc={() => this.setState({confirmationModalActive: false})}
							rejectFunc={() => this.setState({confirmationModalActive: false})}
							acceptLabel="Delete"
							rejectLabel="Cancel"
							header="Delete Item"
							message="Are you sure?"
						/>
					</li>
				</ul>
				<ul>
					<li>
						<h4>KiDatePicker</h4>
						<KiDatePicker
							label="Date Picker Label 1"
							onBlur={val => this.setState({datepicker1: val})}
							onChange={val => this.setState({datepicker1: val})}
							value={this.state.datepicker1}
						/>
						<KiDatePicker
							label="Date Picker Label 2"
							dateFormat="MM/dd/yyyy"
							placeholderText="MM/DD/YYYY"
							onBlur={val => this.setState({datepicker2: val})}
							onChange={val => this.setState({datepicker2: val})}
							value={this.state.datepicker2}
						/>
					</li>
				</ul>
				<h4>Icons</h4>
				<div className={styles.icons}>
					<IconBlock glyphyName="3d_rotation" />
					<IconBlock glyphyName="ac_unit" />
					<IconBlock glyphyName="access_alarm" />
					<IconBlock glyphyName="access_alarms" />
					<IconBlock glyphyName="access_time" />
					<IconBlock glyphyName="accessibility" />
					<IconBlock glyphyName="accessible" />
					<IconBlock glyphyName="account_balance" />
					<IconBlock glyphyName="account_balance_wallet" />
					<IconBlock glyphyName="account_box" />
					<IconBlock glyphyName="account_circle" />
					<IconBlock glyphyName="adb" />
					<IconBlock glyphyName="add" />
					<IconBlock glyphyName="add_a_photo" />
					<IconBlock glyphyName="add_alarm" />
					<IconBlock glyphyName="add_alert" />
					<IconBlock glyphyName="add_box" />
					<IconBlock glyphyName="add_circle" />
					<IconBlock glyphyName="add_circle_outline" />
					<IconBlock glyphyName="add_location" />
					<IconBlock glyphyName="add_shopping_cart" />
					<IconBlock glyphyName="add_to_photos" />
					<IconBlock glyphyName="add_to_queue" />
					<IconBlock glyphyName="adjust" />
					<IconBlock glyphyName="airline_seat_flat" />
					<IconBlock glyphyName="airline_seat_flat_angled" />
					<IconBlock glyphyName="airline_seat_individual_suite" />
					<IconBlock glyphyName="airline_seat_legroom_extra" />
					<IconBlock glyphyName="airline_seat_legroom_normal" />
					<IconBlock glyphyName="airline_seat_legroom_reduced" />
					<IconBlock glyphyName="airline_seat_recline_extra" />
					<IconBlock glyphyName="airline_seat_recline_normal" />
					<IconBlock glyphyName="airplanemode_active" />
					<IconBlock glyphyName="airplanemode_inactive" />
					<IconBlock glyphyName="airplay" />
					<IconBlock glyphyName="airport_shuttle" />
					<IconBlock glyphyName="alarm" />
					<IconBlock glyphyName="alarm_add" />
					<IconBlock glyphyName="alarm_off" />
					<IconBlock glyphyName="alarm_on" />
					<IconBlock glyphyName="album" />
					<IconBlock glyphyName="all_inclusive" />
					<IconBlock glyphyName="all_out" />
					<IconBlock glyphyName="android" />
					<IconBlock glyphyName="announcement" />
					<IconBlock glyphyName="apps" />
					<IconBlock glyphyName="archive" />
					<IconBlock glyphyName="arrow_back" />
					<IconBlock glyphyName="arrow_downward" />
					<IconBlock glyphyName="arrow_drop_down" />
					<IconBlock glyphyName="arrow_drop_down_circle" />
					<IconBlock glyphyName="arrow_drop_up" />
					<IconBlock glyphyName="arrow_forward" />
					<IconBlock glyphyName="arrow_upward" />
					<IconBlock glyphyName="art_track" />
					<IconBlock glyphyName="aspect_ratio" />
					<IconBlock glyphyName="assessment" />
					<IconBlock glyphyName="assignment" />
					<IconBlock glyphyName="assignment_ind" />
					<IconBlock glyphyName="assignment_late" />
					<IconBlock glyphyName="assignment_return" />
					<IconBlock glyphyName="assignment_returned" />
					<IconBlock glyphyName="assignment_turned_in" />
					<IconBlock glyphyName="assistant" />
					<IconBlock glyphyName="assistant_photo" />
					<IconBlock glyphyName="attach_file" />
					<IconBlock glyphyName="attach_money" />
					<IconBlock glyphyName="attachment" />
					<IconBlock glyphyName="audiotrack" />
					<IconBlock glyphyName="autorenew" />
					<IconBlock glyphyName="av_timer" />
					<IconBlock glyphyName="backspace" />
					<IconBlock glyphyName="backup" />
					<IconBlock glyphyName="battery_alert" />
					<IconBlock glyphyName="battery_charging_full" />
					<IconBlock glyphyName="battery_full" />
					<IconBlock glyphyName="battery_std" />
					<IconBlock glyphyName="battery_unknown" />
					<IconBlock glyphyName="beach_access" />
					<IconBlock glyphyName="beenhere" />
					<IconBlock glyphyName="block" />
					<IconBlock glyphyName="bluetooth" />
					<IconBlock glyphyName="bluetooth_audio" />
					<IconBlock glyphyName="bluetooth_connected" />
					<IconBlock glyphyName="bluetooth_disabled" />
					<IconBlock glyphyName="bluetooth_searching" />
					<IconBlock glyphyName="blur_circular" />
					<IconBlock glyphyName="blur_linear" />
					<IconBlock glyphyName="blur_off" />
					<IconBlock glyphyName="blur_on" />
					<IconBlock glyphyName="book" />
					<IconBlock glyphyName="bookmark" />
					<IconBlock glyphyName="bookmark_border" />
					<IconBlock glyphyName="border_all" />
					<IconBlock glyphyName="border_bottom" />
					<IconBlock glyphyName="border_clear" />
					<IconBlock glyphyName="border_color" />
					<IconBlock glyphyName="border_horizontal" />
					<IconBlock glyphyName="border_inner" />
					<IconBlock glyphyName="border_left" />
					<IconBlock glyphyName="border_outer" />
					<IconBlock glyphyName="border_right" />
					<IconBlock glyphyName="border_style" />
					<IconBlock glyphyName="border_top" />
					<IconBlock glyphyName="border_vertical" />
					<IconBlock glyphyName="branding_watermark" />
					<IconBlock glyphyName="brightness_1" />
					<IconBlock glyphyName="brightness_2" />
					<IconBlock glyphyName="brightness_3" />
					<IconBlock glyphyName="brightness_4" />
					<IconBlock glyphyName="brightness_5" />
					<IconBlock glyphyName="brightness_6" />
					<IconBlock glyphyName="brightness_7" />
					<IconBlock glyphyName="brightness_auto" />
					<IconBlock glyphyName="brightness_high" />
					<IconBlock glyphyName="brightness_low" />
					<IconBlock glyphyName="brightness_medium" />
					<IconBlock glyphyName="broken_image" />
					<IconBlock glyphyName="brush" />
					<IconBlock glyphyName="bubble_chart" />
					<IconBlock glyphyName="bug_report" />
					<IconBlock glyphyName="build" />
					<IconBlock glyphyName="burst_mode" />
					<IconBlock glyphyName="business" />
					<IconBlock glyphyName="business_center" />
					<IconBlock glyphyName="cached" />
					<IconBlock glyphyName="cake" />
					<IconBlock glyphyName="call" />
					<IconBlock glyphyName="call_end" />
					<IconBlock glyphyName="call_made" />
					<IconBlock glyphyName="call_merge" />
					<IconBlock glyphyName="call_missed" />
					<IconBlock glyphyName="call_missed_outgoing" />
					<IconBlock glyphyName="call_received" />
					<IconBlock glyphyName="call_split" />
					<IconBlock glyphyName="call_to_action" />
					<IconBlock glyphyName="camera" />
					<IconBlock glyphyName="camera_alt" />
					<IconBlock glyphyName="camera_enhance" />
					<IconBlock glyphyName="camera_front" />
					<IconBlock glyphyName="camera_rear" />
					<IconBlock glyphyName="camera_roll" />
					<IconBlock glyphyName="cancel" />
					<IconBlock glyphyName="card_giftcard" />
					<IconBlock glyphyName="card_membership" />
					<IconBlock glyphyName="card_travel" />
					<IconBlock glyphyName="casino" />
					<IconBlock glyphyName="cast" />
					<IconBlock glyphyName="cast_connected" />
					<IconBlock glyphyName="center_focus_strong" />
					<IconBlock glyphyName="center_focus_weak" />
					<IconBlock glyphyName="change_history" />
					<IconBlock glyphyName="chat" />
					<IconBlock glyphyName="chat_bubble" />
					<IconBlock glyphyName="chat_bubble_outline" />
					<IconBlock glyphyName="check" />
					<IconBlock glyphyName="check_box" />
					<IconBlock glyphyName="check_box_outline_blank" />
					<IconBlock glyphyName="check_circle" />
					<IconBlock glyphyName="chevron_left" />
					<IconBlock glyphyName="chevron_right" />
					<IconBlock glyphyName="child_care" />
					<IconBlock glyphyName="child_friendly" />
					<IconBlock glyphyName="chrome_reader_mode" />
					<IconBlock glyphyName="class" />
					<IconBlock glyphyName="clear" />
					<IconBlock glyphyName="clear_all" />
					<IconBlock glyphyName="close" />
					<IconBlock glyphyName="closed_caption" />
					<IconBlock glyphyName="cloud" />
					<IconBlock glyphyName="cloud_circle" />
					<IconBlock glyphyName="cloud_done" />
					<IconBlock glyphyName="cloud_download" />
					<IconBlock glyphyName="cloud_off" />
					<IconBlock glyphyName="cloud_queue" />
					<IconBlock glyphyName="cloud_upload" />
					<IconBlock glyphyName="code" />
					<IconBlock glyphyName="collections" />
					<IconBlock glyphyName="collections_bookmark" />
					<IconBlock glyphyName="color_lens" />
					<IconBlock glyphyName="colorize" />
					<IconBlock glyphyName="comment" />
					<IconBlock glyphyName="compare" />
					<IconBlock glyphyName="compare_arrows" />
					<IconBlock glyphyName="computer" />
					<IconBlock glyphyName="confirmation_number" />
					<IconBlock glyphyName="contact_mail" />
					<IconBlock glyphyName="contact_phone" />
					<IconBlock glyphyName="contacts" />
					<IconBlock glyphyName="content_copy" />
					<IconBlock glyphyName="content_cut" />
					<IconBlock glyphyName="content_paste" />
					<IconBlock glyphyName="control_point" />
					<IconBlock glyphyName="control_point_duplicate" />
					<IconBlock glyphyName="copyright" />
					<IconBlock glyphyName="create" />
					<IconBlock glyphyName="create_new_folder" />
					<IconBlock glyphyName="credit_card" />
					<IconBlock glyphyName="crop" />
					<IconBlock glyphyName="crop_16_9" />
					<IconBlock glyphyName="crop_3_2" />
					<IconBlock glyphyName="crop_5_4" />
					<IconBlock glyphyName="crop_7_5" />
					<IconBlock glyphyName="crop_din" />
					<IconBlock glyphyName="crop_free" />
					<IconBlock glyphyName="crop_landscape" />
					<IconBlock glyphyName="crop_original" />
					<IconBlock glyphyName="crop_portrait" />
					<IconBlock glyphyName="crop_rotate" />
					<IconBlock glyphyName="crop_square" />
					<IconBlock glyphyName="dashboard" />
					<IconBlock glyphyName="data_usage" />
					<IconBlock glyphyName="date_range" />
					<IconBlock glyphyName="dehaze" />
					<IconBlock glyphyName="delete" />
					<IconBlock glyphyName="delete_forever" />
					<IconBlock glyphyName="delete_sweep" />
					<IconBlock glyphyName="description" />
					<IconBlock glyphyName="desktop_mac" />
					<IconBlock glyphyName="desktop_windows" />
					<IconBlock glyphyName="details" />
					<IconBlock glyphyName="developer_board" />
					<IconBlock glyphyName="developer_mode" />
					<IconBlock glyphyName="device_hub" />
					<IconBlock glyphyName="devices" />
					<IconBlock glyphyName="devices_other" />
					<IconBlock glyphyName="dialer_sip" />
					<IconBlock glyphyName="dialpad" />
					<IconBlock glyphyName="directions" />
					<IconBlock glyphyName="directions_bike" />
					<IconBlock glyphyName="directions_boat" />
					<IconBlock glyphyName="directions_bus" />
					<IconBlock glyphyName="directions_car" />
					<IconBlock glyphyName="directions_railway" />
					<IconBlock glyphyName="directions_run" />
					<IconBlock glyphyName="directions_subway" />
					<IconBlock glyphyName="directions_transit" />
					<IconBlock glyphyName="directions_walk" />
					<IconBlock glyphyName="disc_full" />
					<IconBlock glyphyName="dns" />
					<IconBlock glyphyName="do_not_disturb" />
					<IconBlock glyphyName="do_not_disturb_alt" />
					<IconBlock glyphyName="do_not_disturb_off" />
					<IconBlock glyphyName="do_not_disturb_on" />
					<IconBlock glyphyName="dock" />
					<IconBlock glyphyName="domain" />
					<IconBlock glyphyName="done" />
					<IconBlock glyphyName="done_all" />
					<IconBlock glyphyName="donut_large" />
					<IconBlock glyphyName="donut_small" />
					<IconBlock glyphyName="drafts" />
					<IconBlock glyphyName="drag_handle" />
					<IconBlock glyphyName="drive_eta" />
					<IconBlock glyphyName="dvr" />
					<IconBlock glyphyName="edit" />
					<IconBlock glyphyName="edit_location" />
					<IconBlock glyphyName="eject" />
					<IconBlock glyphyName="email" />
					<IconBlock glyphyName="enhanced_encryption" />
					<IconBlock glyphyName="equalizer" />
					<IconBlock glyphyName="error" />
					<IconBlock glyphyName="error_outline" />
					<IconBlock glyphyName="euro_symbol" />
					<IconBlock glyphyName="ev_station" />
					<IconBlock glyphyName="event" />
					<IconBlock glyphyName="event_available" />
					<IconBlock glyphyName="event_busy" />
					<IconBlock glyphyName="event_note" />
					<IconBlock glyphyName="event_seat" />
					<IconBlock glyphyName="exit_to_app" />
					<IconBlock glyphyName="expand_less" />
					<IconBlock glyphyName="expand_more" />
					<IconBlock glyphyName="explicit" />
					<IconBlock glyphyName="explore" />
					<IconBlock glyphyName="exposure" />
					<IconBlock glyphyName="exposure_neg_1" />
					<IconBlock glyphyName="exposure_neg_2" />
					<IconBlock glyphyName="exposure_plus_1" />
					<IconBlock glyphyName="exposure_plus_2" />
					<IconBlock glyphyName="exposure_zero" />
					<IconBlock glyphyName="extension" />
					<IconBlock glyphyName="face" />
					<IconBlock glyphyName="fast_forward" />
					<IconBlock glyphyName="fast_rewind" />
					<IconBlock glyphyName="favorite" />
					<IconBlock glyphyName="favorite_border" />
					<IconBlock glyphyName="featured_play_list" />
					<IconBlock glyphyName="featured_video" />
					<IconBlock glyphyName="feedback" />
					<IconBlock glyphyName="fiber_dvr" />
					<IconBlock glyphyName="fiber_manual_record" />
					<IconBlock glyphyName="fiber_new" />
					<IconBlock glyphyName="fiber_pin" />
					<IconBlock glyphyName="fiber_smart_record" />
					<IconBlock glyphyName="file_download" />
					<IconBlock glyphyName="file_upload" />
					<IconBlock glyphyName="filter" />
					<IconBlock glyphyName="filter_1" />
					<IconBlock glyphyName="filter_2" />
					<IconBlock glyphyName="filter_3" />
					<IconBlock glyphyName="filter_4" />
					<IconBlock glyphyName="filter_5" />
					<IconBlock glyphyName="filter_6" />
					<IconBlock glyphyName="filter_7" />
					<IconBlock glyphyName="filter_8" />
					<IconBlock glyphyName="filter_9" />
					<IconBlock glyphyName="filter_9_plus" />
					<IconBlock glyphyName="filter_b_and_w" />
					<IconBlock glyphyName="filter_center_focus" />
					<IconBlock glyphyName="filter_drama" />
					<IconBlock glyphyName="filter_frames" />
					<IconBlock glyphyName="filter_hdr" />
					<IconBlock glyphyName="filter_list" />
					<IconBlock glyphyName="filter_none" />
					<IconBlock glyphyName="filter_tilt_shift" />
					<IconBlock glyphyName="filter_vintage" />
					<IconBlock glyphyName="find_in_page" />
					<IconBlock glyphyName="find_replace" />
					<IconBlock glyphyName="fingerprint" />
					<IconBlock glyphyName="first_page" />
					<IconBlock glyphyName="fitness_center" />
					<IconBlock glyphyName="flag" />
					<IconBlock glyphyName="flare" />
					<IconBlock glyphyName="flash_auto" />
					<IconBlock glyphyName="flash_off" />
					<IconBlock glyphyName="flash_on" />
					<IconBlock glyphyName="flight" />
					<IconBlock glyphyName="flight_land" />
					<IconBlock glyphyName="flight_takeoff" />
					<IconBlock glyphyName="flip" />
					<IconBlock glyphyName="flip_to_back" />
					<IconBlock glyphyName="flip_to_front" />
					<IconBlock glyphyName="folder" />
					<IconBlock glyphyName="folder_open" />
					<IconBlock glyphyName="folder_shared" />
					<IconBlock glyphyName="folder_special" />
					<IconBlock glyphyName="font_download" />
					<IconBlock glyphyName="format_align_center" />
					<IconBlock glyphyName="format_align_justify" />
					<IconBlock glyphyName="format_align_left" />
					<IconBlock glyphyName="format_align_right" />
					<IconBlock glyphyName="format_bold" />
					<IconBlock glyphyName="format_clear" />
					<IconBlock glyphyName="format_color_fill" />
					<IconBlock glyphyName="format_color_reset" />
					<IconBlock glyphyName="format_color_text" />
					<IconBlock glyphyName="format_indent_decrease" />
					<IconBlock glyphyName="format_indent_increase" />
					<IconBlock glyphyName="format_italic" />
					<IconBlock glyphyName="format_line_spacing" />
					<IconBlock glyphyName="format_list_bulleted" />
					<IconBlock glyphyName="format_list_numbered" />
					<IconBlock glyphyName="format_paint" />
					<IconBlock glyphyName="format_quote" />
					<IconBlock glyphyName="format_shapes" />
					<IconBlock glyphyName="format_size" />
					<IconBlock glyphyName="format_strikethrough" />
					<IconBlock glyphyName="format_textdirection_l_to_r" />
					<IconBlock glyphyName="format_textdirection_r_to_l" />
					<IconBlock glyphyName="format_underlined" />
					<IconBlock glyphyName="forum" />
					<IconBlock glyphyName="forward" />
					<IconBlock glyphyName="forward_10" />
					<IconBlock glyphyName="forward_30" />
					<IconBlock glyphyName="forward_5" />
					<IconBlock glyphyName="free_breakfast" />
					<IconBlock glyphyName="fullscreen" />
					<IconBlock glyphyName="fullscreen_exit" />
					<IconBlock glyphyName="functions" />
					<IconBlock glyphyName="g_translate" />
					<IconBlock glyphyName="gamepad" />
					<IconBlock glyphyName="games" />
					<IconBlock glyphyName="gavel" />
					<IconBlock glyphyName="gesture" />
					<IconBlock glyphyName="get_app" />
					<IconBlock glyphyName="gif" />
					<IconBlock glyphyName="goat" />
					<IconBlock glyphyName="golf_course" />
					<IconBlock glyphyName="gps_fixed" />
					<IconBlock glyphyName="gps_not_fixed" />
					<IconBlock glyphyName="gps_off" />
					<IconBlock glyphyName="grade" />
					<IconBlock glyphyName="gradient" />
					<IconBlock glyphyName="grain" />
					<IconBlock glyphyName="graphic_eq" />
					<IconBlock glyphyName="grid_off" />
					<IconBlock glyphyName="grid_on" />
					<IconBlock glyphyName="group" />
					<IconBlock glyphyName="group_add" />
					<IconBlock glyphyName="group_work" />
					<IconBlock glyphyName="hd" />
					<IconBlock glyphyName="hdr_off" />
					<IconBlock glyphyName="hdr_on" />
					<IconBlock glyphyName="hdr_strong" />
					<IconBlock glyphyName="hdr_weak" />
					<IconBlock glyphyName="headset" />
					<IconBlock glyphyName="headset_mic" />
					<IconBlock glyphyName="healing" />
					<IconBlock glyphyName="hearing" />
					<IconBlock glyphyName="help" />
					<IconBlock glyphyName="help_outline" />
					<IconBlock glyphyName="high_quality" />
					<IconBlock glyphyName="highlight" />
					<IconBlock glyphyName="highlight_off" />
					<IconBlock glyphyName="history" />
					<IconBlock glyphyName="home" />
					<IconBlock glyphyName="hot_tub" />
					<IconBlock glyphyName="hotel" />
					<IconBlock glyphyName="hourglass_empty" />
					<IconBlock glyphyName="hourglass_full" />
					<IconBlock glyphyName="http" />
					<IconBlock glyphyName="https" />
					<IconBlock glyphyName="image" />
					<IconBlock glyphyName="image_aspect_ratio" />
					<IconBlock glyphyName="import_contacts" />
					<IconBlock glyphyName="import_export" />
					<IconBlock glyphyName="important_devices" />
					<IconBlock glyphyName="inbox" />
					<IconBlock glyphyName="indeterminate_check_box" />
					<IconBlock glyphyName="info" />
					<IconBlock glyphyName="info_outline" />
					<IconBlock glyphyName="input" />
					<IconBlock glyphyName="insert_chart" />
					<IconBlock glyphyName="insert_comment" />
					<IconBlock glyphyName="insert_drive_file" />
					<IconBlock glyphyName="insert_emoticon" />
					<IconBlock glyphyName="insert_invitation" />
					<IconBlock glyphyName="insert_link" />
					<IconBlock glyphyName="insert_photo" />
					<IconBlock glyphyName="invert_colors" />
					<IconBlock glyphyName="invert_colors_off" />
					<IconBlock glyphyName="iso" />
					<IconBlock glyphyName="keyboard" />
					<IconBlock glyphyName="keyboard_arrow_down" />
					<IconBlock glyphyName="keyboard_arrow_left" />
					<IconBlock glyphyName="keyboard_arrow_right" />
					<IconBlock glyphyName="keyboard_arrow_up" />
					<IconBlock glyphyName="keyboard_backspace" />
					<IconBlock glyphyName="keyboard_capslock" />
					<IconBlock glyphyName="keyboard_hide" />
					<IconBlock glyphyName="keyboard_return" />
					<IconBlock glyphyName="keyboard_tab" />
					<IconBlock glyphyName="keyboard_voice" />
					<IconBlock glyphyName="kitchen" />
					<IconBlock glyphyName="label" />
					<IconBlock glyphyName="label_outline" />
					<IconBlock glyphyName="landscape" />
					<IconBlock glyphyName="language" />
					<IconBlock glyphyName="laptop" />
					<IconBlock glyphyName="laptop_chromebook" />
					<IconBlock glyphyName="laptop_mac" />
					<IconBlock glyphyName="laptop_windows" />
					<IconBlock glyphyName="last_page" />
					<IconBlock glyphyName="launch" />
					<IconBlock glyphyName="layers" />
					<IconBlock glyphyName="layers_clear" />
					<IconBlock glyphyName="leak_add" />
					<IconBlock glyphyName="leak_remove" />
					<IconBlock glyphyName="lens" />
					<IconBlock glyphyName="library_add" />
					<IconBlock glyphyName="library_books" />
					<IconBlock glyphyName="library_music" />
					<IconBlock glyphyName="lightbulb_outline" />
					<IconBlock glyphyName="line_style" />
					<IconBlock glyphyName="line_weight" />
					<IconBlock glyphyName="linear_scale" />
					<IconBlock glyphyName="link" />
					<IconBlock glyphyName="linked_camera" />
					<IconBlock glyphyName="list" />
					<IconBlock glyphyName="live_help" />
					<IconBlock glyphyName="live_tv" />
					<IconBlock glyphyName="local_activity" />
					<IconBlock glyphyName="local_airport" />
					<IconBlock glyphyName="local_atm" />
					<IconBlock glyphyName="local_bar" />
					<IconBlock glyphyName="local_cafe" />
					<IconBlock glyphyName="local_car_wash" />
					<IconBlock glyphyName="local_convenience_store" />
					<IconBlock glyphyName="local_dining" />
					<IconBlock glyphyName="local_drink" />
					<IconBlock glyphyName="local_florist" />
					<IconBlock glyphyName="local_gas_station" />
					<IconBlock glyphyName="local_grocery_store" />
					<IconBlock glyphyName="local_hospital" />
					<IconBlock glyphyName="local_hotel" />
					<IconBlock glyphyName="local_laundry_service" />
					<IconBlock glyphyName="local_library" />
					<IconBlock glyphyName="local_mall" />
					<IconBlock glyphyName="local_movies" />
					<IconBlock glyphyName="local_offer" />
					<IconBlock glyphyName="local_parking" />
					<IconBlock glyphyName="local_pharmacy" />
					<IconBlock glyphyName="local_phone" />
					<IconBlock glyphyName="local_pizza" />
					<IconBlock glyphyName="local_play" />
					<IconBlock glyphyName="local_post_office" />
					<IconBlock glyphyName="local_printshop" />
					<IconBlock glyphyName="local_see" />
					<IconBlock glyphyName="local_shipping" />
					<IconBlock glyphyName="local_taxi" />
					<IconBlock glyphyName="location_city" />
					<IconBlock glyphyName="location_disabled" />
					<IconBlock glyphyName="location_off" />
					<IconBlock glyphyName="location_on" />
					<IconBlock glyphyName="location_searching" />
					<IconBlock glyphyName="lock" />
					<IconBlock glyphyName="lock_open" />
					<IconBlock glyphyName="lock_outline" />
					<IconBlock glyphyName="looks" />
					<IconBlock glyphyName="looks_3" />
					<IconBlock glyphyName="looks_4" />
					<IconBlock glyphyName="looks_5" />
					<IconBlock glyphyName="looks_6" />
					<IconBlock glyphyName="looks_one" />
					<IconBlock glyphyName="looks_two" />
					<IconBlock glyphyName="loop" />
					<IconBlock glyphyName="loupe" />
					<IconBlock glyphyName="low_priority" />
					<IconBlock glyphyName="loyalty" />
					<IconBlock glyphyName="mail" />
					<IconBlock glyphyName="mail_outline" />
					<IconBlock glyphyName="map" />
					<IconBlock glyphyName="markunread" />
					<IconBlock glyphyName="markunread_mailbox" />
					<IconBlock glyphyName="memory" />
					<IconBlock glyphyName="menu" />
					<IconBlock glyphyName="merge_type" />
					<IconBlock glyphyName="message" />
					<IconBlock glyphyName="mic" />
					<IconBlock glyphyName="mic_none" />
					<IconBlock glyphyName="mic_off" />
					<IconBlock glyphyName="mms" />
					<IconBlock glyphyName="mode_comment" />
					<IconBlock glyphyName="mode_edit" />
					<IconBlock glyphyName="monetization_on" />
					<IconBlock glyphyName="money_off" />
					<IconBlock glyphyName="monochrome_photos" />
					<IconBlock glyphyName="mood" />
					<IconBlock glyphyName="mood_bad" />
					<IconBlock glyphyName="more" />
					<IconBlock glyphyName="more_horiz" />
					<IconBlock glyphyName="more_vert" />
					<IconBlock glyphyName="motorcycle" />
					<IconBlock glyphyName="mouse" />
					<IconBlock glyphyName="move_to_inbox" />
					<IconBlock glyphyName="movie" />
					<IconBlock glyphyName="movie_creation" />
					<IconBlock glyphyName="movie_filter" />
					<IconBlock glyphyName="multiline_chart" />
					<IconBlock glyphyName="music_note" />
					<IconBlock glyphyName="music_video" />
					<IconBlock glyphyName="my_location" />
					<IconBlock glyphyName="nature" />
					<IconBlock glyphyName="nature_people" />
					<IconBlock glyphyName="navigate_before" />
					<IconBlock glyphyName="navigate_next" />
					<IconBlock glyphyName="navigation" />
					<IconBlock glyphyName="near_me" />
					<IconBlock glyphyName="network_cell" />
					<IconBlock glyphyName="network_check" />
					<IconBlock glyphyName="network_locked" />
					<IconBlock glyphyName="network_wifi" />
					<IconBlock glyphyName="new_releases" />
					<IconBlock glyphyName="next_week" />
					<IconBlock glyphyName="nfc" />
					<IconBlock glyphyName="no_encryption" />
					<IconBlock glyphyName="no_sim" />
					<IconBlock glyphyName="not_interested" />
					<IconBlock glyphyName="note" />
					<IconBlock glyphyName="note_add" />
					<IconBlock glyphyName="notifications" />
					<IconBlock glyphyName="notifications_active" />
					<IconBlock glyphyName="notifications_none" />
					<IconBlock glyphyName="notifications_off" />
					<IconBlock glyphyName="notifications_paused" />
					<IconBlock glyphyName="offline_pin" />
					<IconBlock glyphyName="ondemand_video" />
					<IconBlock glyphyName="opacity" />
					<IconBlock glyphyName="open_in_browser" />
					<IconBlock glyphyName="open_in_new" />
					<IconBlock glyphyName="open_with" />
					<IconBlock glyphyName="pages" />
					<IconBlock glyphyName="pageview" />
					<IconBlock glyphyName="palette" />
					<IconBlock glyphyName="pan_tool" />
					<IconBlock glyphyName="panorama" />
					<IconBlock glyphyName="panorama_fish_eye" />
					<IconBlock glyphyName="panorama_horizontal" />
					<IconBlock glyphyName="panorama_vertical" />
					<IconBlock glyphyName="panorama_wide_angle" />
					<IconBlock glyphyName="party_mode" />
					<IconBlock glyphyName="pause" />
					<IconBlock glyphyName="pause_circle_filled" />
					<IconBlock glyphyName="pause_circle_outline" />
					<IconBlock glyphyName="payment" />
					<IconBlock glyphyName="people" />
					<IconBlock glyphyName="people_outline" />
					<IconBlock glyphyName="perm_camera_mic" />
					<IconBlock glyphyName="perm_contact_calendar" />
					<IconBlock glyphyName="perm_data_setting" />
					<IconBlock glyphyName="perm_device_information" />
					<IconBlock glyphyName="perm_identity" />
					<IconBlock glyphyName="perm_media" />
					<IconBlock glyphyName="perm_phone_msg" />
					<IconBlock glyphyName="perm_scan_wifi" />
					<IconBlock glyphyName="person" />
					<IconBlock glyphyName="person_add" />
					<IconBlock glyphyName="person_outline" />
					<IconBlock glyphyName="person_pin" />
					<IconBlock glyphyName="person_pin_circle" />
					<IconBlock glyphyName="personal_video" />
					<IconBlock glyphyName="pets" />
					<IconBlock glyphyName="phone" />
					<IconBlock glyphyName="phone_android" />
					<IconBlock glyphyName="phone_bluetooth_speaker" />
					<IconBlock glyphyName="phone_forwarded" />
					<IconBlock glyphyName="phone_in_talk" />
					<IconBlock glyphyName="phone_iphone" />
					<IconBlock glyphyName="phone_locked" />
					<IconBlock glyphyName="phone_missed" />
					<IconBlock glyphyName="phone_paused" />
					<IconBlock glyphyName="phonelink" />
					<IconBlock glyphyName="phonelink_erase" />
					<IconBlock glyphyName="phonelink_lock" />
					<IconBlock glyphyName="phonelink_off" />
					<IconBlock glyphyName="phonelink_ring" />
					<IconBlock glyphyName="phonelink_setup" />
					<IconBlock glyphyName="photo" />
					<IconBlock glyphyName="photo_album" />
					<IconBlock glyphyName="photo_camera" />
					<IconBlock glyphyName="photo_filter" />
					<IconBlock glyphyName="photo_library" />
					<IconBlock glyphyName="photo_size_select_actual" />
					<IconBlock glyphyName="photo_size_select_large" />
					<IconBlock glyphyName="photo_size_select_small" />
					<IconBlock glyphyName="picture_as_pdf" />
					<IconBlock glyphyName="picture_in_picture" />
					<IconBlock glyphyName="picture_in_picture_alt" />
					<IconBlock glyphyName="pie_chart" />
					<IconBlock glyphyName="pie_chart_outlined" />
					<IconBlock glyphyName="pin_drop" />
					<IconBlock glyphyName="place" />
					<IconBlock glyphyName="play_arrow" />
					<IconBlock glyphyName="play_circle_filled" />
					<IconBlock glyphyName="play_circle_outline" />
					<IconBlock glyphyName="play_for_work" />
					<IconBlock glyphyName="playlist_add" />
					<IconBlock glyphyName="playlist_add_check" />
					<IconBlock glyphyName="playlist_play" />
					<IconBlock glyphyName="plus_one" />
					<IconBlock glyphyName="poll" />
					<IconBlock glyphyName="polymer" />
					<IconBlock glyphyName="pool" />
					<IconBlock glyphyName="portable_wifi_off" />
					<IconBlock glyphyName="portrait" />
					<IconBlock glyphyName="power" />
					<IconBlock glyphyName="power_input" />
					<IconBlock glyphyName="power_settings_new" />
					<IconBlock glyphyName="pregnant_woman" />
					<IconBlock glyphyName="present_to_all" />
					<IconBlock glyphyName="print" />
					<IconBlock glyphyName="priority_high" />
					<IconBlock glyphyName="public" />
					<IconBlock glyphyName="publish" />
					<IconBlock glyphyName="query_builder" />
					<IconBlock glyphyName="question_answer" />
					<IconBlock glyphyName="queue" />
					<IconBlock glyphyName="queue_music" />
					<IconBlock glyphyName="queue_play_next" />
					<IconBlock glyphyName="radio" />
					<IconBlock glyphyName="radio_button_checked" />
					<IconBlock glyphyName="radio_button_unchecked" />
					<IconBlock glyphyName="rate_review" />
					<IconBlock glyphyName="receipt" />
					<IconBlock glyphyName="recent_actors" />
					<IconBlock glyphyName="record_voice_over" />
					<IconBlock glyphyName="redeem" />
					<IconBlock glyphyName="redo" />
					<IconBlock glyphyName="refresh" />
					<IconBlock glyphyName="remove" />
					<IconBlock glyphyName="remove_circle" />
					<IconBlock glyphyName="remove_circle_outline" />
					<IconBlock glyphyName="remove_from_queue" />
					<IconBlock glyphyName="remove_red_eye" />
					<IconBlock glyphyName="remove_shopping_cart" />
					<IconBlock glyphyName="reorder" />
					<IconBlock glyphyName="repeat" />
					<IconBlock glyphyName="repeat_one" />
					<IconBlock glyphyName="replay" />
					<IconBlock glyphyName="replay_10" />
					<IconBlock glyphyName="replay_30" />
					<IconBlock glyphyName="replay_5" />
					<IconBlock glyphyName="reply" />
					<IconBlock glyphyName="reply_all" />
					<IconBlock glyphyName="report" />
					<IconBlock glyphyName="report_problem" />
					<IconBlock glyphyName="restaurant" />
					<IconBlock glyphyName="restaurant_menu" />
					<IconBlock glyphyName="restore" />
					<IconBlock glyphyName="restore_page" />
					<IconBlock glyphyName="ring_volume" />
					<IconBlock glyphyName="room" />
					<IconBlock glyphyName="room_service" />
					<IconBlock glyphyName="rotate_90_degrees_ccw" />
					<IconBlock glyphyName="rotate_left" />
					<IconBlock glyphyName="rotate_right" />
					<IconBlock glyphyName="rounded_corner" />
					<IconBlock glyphyName="router" />
					<IconBlock glyphyName="rowing" />
					<IconBlock glyphyName="rss_feed" />
					<IconBlock glyphyName="rv_hookup" />
					<IconBlock glyphyName="satellite" />
					<IconBlock glyphyName="save" />
					<IconBlock glyphyName="scanner" />
					<IconBlock glyphyName="schedule" />
					<IconBlock glyphyName="school" />
					<IconBlock glyphyName="screen_lock_landscape" />
					<IconBlock glyphyName="screen_lock_portrait" />
					<IconBlock glyphyName="screen_lock_rotation" />
					<IconBlock glyphyName="screen_rotation" />
					<IconBlock glyphyName="screen_share" />
					<IconBlock glyphyName="sd_card" />
					<IconBlock glyphyName="sd_storage" />
					<IconBlock glyphyName="search" />
					<IconBlock glyphyName="security" />
					<IconBlock glyphyName="select_all" />
					<IconBlock glyphyName="send" />
					<IconBlock glyphyName="sentiment_dissatisfied" />
					<IconBlock glyphyName="sentiment_neutral" />
					<IconBlock glyphyName="sentiment_satisfied" />
					<IconBlock glyphyName="sentiment_very_dissatisfied" />
					<IconBlock glyphyName="sentiment_very_satisfied" />
					<IconBlock glyphyName="settings" />
					<IconBlock glyphyName="settings_applications" />
					<IconBlock glyphyName="settings_backup_restore" />
					<IconBlock glyphyName="settings_bluetooth" />
					<IconBlock glyphyName="settings_brightness" />
					<IconBlock glyphyName="settings_cell" />
					<IconBlock glyphyName="settings_ethernet" />
					<IconBlock glyphyName="settings_input_antenna" />
					<IconBlock glyphyName="settings_input_component" />
					<IconBlock glyphyName="settings_input_composite" />
					<IconBlock glyphyName="settings_input_hdmi" />
					<IconBlock glyphyName="settings_input_svideo" />
					<IconBlock glyphyName="settings_overscan" />
					<IconBlock glyphyName="settings_phone" />
					<IconBlock glyphyName="settings_power" />
					<IconBlock glyphyName="settings_remote" />
					<IconBlock glyphyName="settings_system_daydream" />
					<IconBlock glyphyName="settings_voice" />
					<IconBlock glyphyName="share" />
					<IconBlock glyphyName="shop" />
					<IconBlock glyphyName="shop_two" />
					<IconBlock glyphyName="shopping_basket" />
					<IconBlock glyphyName="shopping_cart" />
					<IconBlock glyphyName="short_text" />
					<IconBlock glyphyName="show_chart" />
					<IconBlock glyphyName="shuffle" />
					<IconBlock glyphyName="signal_cellular_4_bar" />
					<IconBlock glyphyName="signal_cellular_connected_no_internet_4_bar" />
					<IconBlock glyphyName="signal_cellular_no_sim" />
					<IconBlock glyphyName="signal_cellular_null" />
					<IconBlock glyphyName="signal_cellular_off" />
					<IconBlock glyphyName="signal_wifi_4_bar" />
					<IconBlock glyphyName="signal_wifi_4_bar_lock" />
					<IconBlock glyphyName="signal_wifi_off" />
					<IconBlock glyphyName="sim_card" />
					<IconBlock glyphyName="sim_card_alert" />
					<IconBlock glyphyName="skip_next" />
					<IconBlock glyphyName="skip_previous" />
					<IconBlock glyphyName="slideshow" />
					<IconBlock glyphyName="slow_motion_video" />
					<IconBlock glyphyName="smartphone" />
					<IconBlock glyphyName="smoke_free" />
					<IconBlock glyphyName="smoking_rooms" />
					<IconBlock glyphyName="sms" />
					<IconBlock glyphyName="sms_failed" />
					<IconBlock glyphyName="snooze" />
					<IconBlock glyphyName="sort" />
					<IconBlock glyphyName="sort_by_alpha" />
					<IconBlock glyphyName="spa" />
					<IconBlock glyphyName="space_bar" />
					<IconBlock glyphyName="speaker" />
					<IconBlock glyphyName="speaker_group" />
					<IconBlock glyphyName="speaker_notes" />
					<IconBlock glyphyName="speaker_notes_off" />
					<IconBlock glyphyName="speaker_phone" />
					<IconBlock glyphyName="spellcheck" />
					<IconBlock glyphyName="star" />
					<IconBlock glyphyName="star_border" />
					<IconBlock glyphyName="star_half" />
					<IconBlock glyphyName="stars" />
					<IconBlock glyphyName="stay_current_landscape" />
					<IconBlock glyphyName="stay_current_portrait" />
					<IconBlock glyphyName="stay_primary_landscape" />
					<IconBlock glyphyName="stay_primary_portrait" />
					<IconBlock glyphyName="stop" />
					<IconBlock glyphyName="stop_screen_share" />
					<IconBlock glyphyName="storage" />
					<IconBlock glyphyName="store" />
					<IconBlock glyphyName="store_mall_directory" />
					<IconBlock glyphyName="straighten" />
					<IconBlock glyphyName="streetview" />
					<IconBlock glyphyName="strikethrough_s" />
					<IconBlock glyphyName="style" />
					<IconBlock glyphyName="subdirectory_arrow_left" />
					<IconBlock glyphyName="subdirectory_arrow_right" />
					<IconBlock glyphyName="subject" />
					<IconBlock glyphyName="subscriptions" />
					<IconBlock glyphyName="subtitles" />
					<IconBlock glyphyName="subway" />
					<IconBlock glyphyName="supervisor_account" />
					<IconBlock glyphyName="surround_sound" />
					<IconBlock glyphyName="swap_calls" />
					<IconBlock glyphyName="swap_horiz" />
					<IconBlock glyphyName="swap_vert" />
					<IconBlock glyphyName="swap_vertical_circle" />
					<IconBlock glyphyName="switch_camera" />
					<IconBlock glyphyName="switch_video" />
					<IconBlock glyphyName="sync" />
					<IconBlock glyphyName="sync_disabled" />
					<IconBlock glyphyName="sync_problem" />
					<IconBlock glyphyName="system_update" />
					<IconBlock glyphyName="system_update_alt" />
					<IconBlock glyphyName="tab" />
					<IconBlock glyphyName="tab_unselected" />
					<IconBlock glyphyName="tablet" />
					<IconBlock glyphyName="tablet_android" />
					<IconBlock glyphyName="tablet_mac" />
					<IconBlock glyphyName="tag_faces" />
					<IconBlock glyphyName="tap_and_play" />
					<IconBlock glyphyName="terrain" />
					<IconBlock glyphyName="text_fields" />
					<IconBlock glyphyName="text_format" />
					<IconBlock glyphyName="textsms" />
					<IconBlock glyphyName="texture" />
					<IconBlock glyphyName="theaters" />
					<IconBlock glyphyName="thumb_down" />
					<IconBlock glyphyName="thumb_up" />
					<IconBlock glyphyName="thumbs_up_down" />
					<IconBlock glyphyName="time_to_leave" />
					<IconBlock glyphyName="timelapse" />
					<IconBlock glyphyName="timeline" />
					<IconBlock glyphyName="timer" />
					<IconBlock glyphyName="timer_10" />
					<IconBlock glyphyName="timer_3" />
					<IconBlock glyphyName="timer_off" />
					<IconBlock glyphyName="title" />
					<IconBlock glyphyName="toc" />
					<IconBlock glyphyName="today" />
					<IconBlock glyphyName="toll" />
					<IconBlock glyphyName="tonality" />
					<IconBlock glyphyName="touch_app" />
					<IconBlock glyphyName="toys" />
					<IconBlock glyphyName="track_changes" />
					<IconBlock glyphyName="traffic" />
					<IconBlock glyphyName="train" />
					<IconBlock glyphyName="tram" />
					<IconBlock glyphyName="transfer_within_a_station" />
					<IconBlock glyphyName="transform" />
					<IconBlock glyphyName="translate" />
					<IconBlock glyphyName="trending_down" />
					<IconBlock glyphyName="trending_flat" />
					<IconBlock glyphyName="trending_up" />
					<IconBlock glyphyName="tune" />
					<IconBlock glyphyName="turned_in" />
					<IconBlock glyphyName="turned_in_not" />
					<IconBlock glyphyName="tv" />
					<IconBlock glyphyName="unarchive" />
					<IconBlock glyphyName="undo" />
					<IconBlock glyphyName="unfold_less" />
					<IconBlock glyphyName="unfold_more" />
					<IconBlock glyphyName="update" />
					<IconBlock glyphyName="usb" />
					<IconBlock glyphyName="verified_user" />
					<IconBlock glyphyName="vertical_align_bottom" />
					<IconBlock glyphyName="vertical_align_center" />
					<IconBlock glyphyName="vertical_align_top" />
					<IconBlock glyphyName="vibration" />
					<IconBlock glyphyName="video_call" />
					<IconBlock glyphyName="video_label" />
					<IconBlock glyphyName="video_library" />
					<IconBlock glyphyName="videocam" />
					<IconBlock glyphyName="videocam_off" />
					<IconBlock glyphyName="videogame_asset" />
					<IconBlock glyphyName="view_agenda" />
					<IconBlock glyphyName="view_array" />
					<IconBlock glyphyName="view_carousel" />
					<IconBlock glyphyName="view_column" />
					<IconBlock glyphyName="view_comfy" />
					<IconBlock glyphyName="view_compact" />
					<IconBlock glyphyName="view_day" />
					<IconBlock glyphyName="view_headline" />
					<IconBlock glyphyName="view_list" />
					<IconBlock glyphyName="view_module" />
					<IconBlock glyphyName="view_quilt" />
					<IconBlock glyphyName="view_stream" />
					<IconBlock glyphyName="view_week" />
					<IconBlock glyphyName="vignette" />
					<IconBlock glyphyName="visibility" />
					<IconBlock glyphyName="visibility_off" />
					<IconBlock glyphyName="voice_chat" />
					<IconBlock glyphyName="voicemail" />
					<IconBlock glyphyName="volume_down" />
					<IconBlock glyphyName="volume_mute" />
					<IconBlock glyphyName="volume_off" />
					<IconBlock glyphyName="volume_up" />
					<IconBlock glyphyName="vpn_key" />
					<IconBlock glyphyName="vpn_lock" />
					<IconBlock glyphyName="wallpaper" />
					<IconBlock glyphyName="warning" />
					<IconBlock glyphyName="watch" />
					<IconBlock glyphyName="watch_later" />
					<IconBlock glyphyName="wb_auto" />
					<IconBlock glyphyName="wb_cloudy" />
					<IconBlock glyphyName="wb_incandescent" />
					<IconBlock glyphyName="wb_iridescent" />
					<IconBlock glyphyName="wb_sunny" />
					<IconBlock glyphyName="wc" />
					<IconBlock glyphyName="web" />
					<IconBlock glyphyName="web_asset" />
					<IconBlock glyphyName="weekend" />
					<IconBlock glyphyName="whatshot" />
					<IconBlock glyphyName="widgets" />
					<IconBlock glyphyName="wifi" />
					<IconBlock glyphyName="wifi_lock" />
					<IconBlock glyphyName="wifi_tethering" />
					<IconBlock glyphyName="work" />
					<IconBlock glyphyName="wrap_text" />
					<IconBlock glyphyName="youtube_searched_for" />
					<IconBlock glyphyName="zoom_in" />
					<IconBlock glyphyName="zoom_out" />
					<IconBlock glyphyName="zoom_out_map" />
					<IconBlock glyphyName="3" />
					<IconBlock glyphyName="d" />
					<IconBlock glyphyName="_" />
					<IconBlock glyphyName="r" />
					<IconBlock glyphyName="o" />
					<IconBlock glyphyName="t" />
					<IconBlock glyphyName="a" />
					<IconBlock glyphyName="i" />
					<IconBlock glyphyName="n" />
					<IconBlock glyphyName="c" />
					<IconBlock glyphyName="u" />
					<IconBlock glyphyName="e" />
					<IconBlock glyphyName="s" />
					<IconBlock glyphyName="l" />
					<IconBlock glyphyName="m" />
					<IconBlock glyphyName="b" />
					<IconBlock glyphyName="y" />
					<IconBlock glyphyName="w" />
					<IconBlock glyphyName="x" />
					<IconBlock glyphyName="p" />
					<IconBlock glyphyName="h" />
					<IconBlock glyphyName="g" />
					<IconBlock glyphyName="q" />
					<IconBlock glyphyName="j" />
					<IconBlock glyphyName="f" />
					<IconBlock glyphyName="v" />
					<IconBlock glyphyName="k" />
					<IconBlock glyphyName="z" />
					<IconBlock glyphyName="1" />
					<IconBlock glyphyName="2" />
					<IconBlock glyphyName="4" />
					<IconBlock glyphyName="5" />
					<IconBlock glyphyName="6" />
					<IconBlock glyphyName="7" />
					<IconBlock glyphyName="9" />
					<IconBlock glyphyName="8" />
					<IconBlock glyphyName="0" />
				</div>
			</div>
		);
	}
}

export default KiComponents;
