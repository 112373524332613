import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import KiProgressBar from 'components/KiProgressBar';
import KiMenu from 'components/KiMenu';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import KiIconButton from 'components/KiIconButton';
import Select from 'react-select';
import KiConfirmModal from 'components/KiConfirmModal';
import DashboardManager from './components/dashboardManager';
import analytics from 'analytics';
import * as actions from './actions';
import {dashboardsApi} from 'api';
import KiCard from 'components/KiCard';
import {showSnackbar} from 'state/actions/Snackbar';
import _isEmpty from 'lodash/isEmpty';
import './dashboards.scss';
import _get from 'lodash/get';
import KiDataCardEditor from 'components/KiDataCardEditor';

class Dashboard extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		dashboards: PropTypes.array.isRequired,
		allCards: PropTypes.array,
		fetchDashboards: PropTypes.func,
		fetchCardsForDashboard: PropTypes.func,
		fetchAllCards: PropTypes.func,
		deleteDashboard: PropTypes.func,
		showSnackbar: PropTypes.func.isRequired,
		addDashboard: PropTypes.func.isRequired,
		updateCurrentCard: PropTypes.func.isRequired,
	};

	state = {
		currentDashboard: null,
		currentCard: null,
		currentCardData: {},
		currentSummaryColumn: {},
		chartColumns: [],
		currentCards: [],
		dashboardManagerActive: false,
		isRemoveConfirmActive: false,
		dashboardManagerMode: 'edit',
		isDeleteConfirmActive: false,
		loading: false,
		isCardDeleteConfirmActive: false,
		deleteTargetCard: null,
		currentSnapshotDates: [],
		currentBookmark: {},
		chartType: null,
		currentGranularity: [],
		isTimeSeries: false,
		showCardEditor: false,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Dashboard`;
		return this.loadPageData();
	}

	componentDidUpdate = prevProps => {
		if (prevProps.allCards !== this.props.allCards) {
			const filteredCards = this.props.allCards.filter(card => !_isEmpty(card.dashboards));
			const currentCard =
				this.state.currentCard && filteredCards.length
					? filteredCards.find(c => c._id === this.state.currentCard._id)
					: {};
			this.setState({
				allCards: this.props.allCards,
				currentCards: filteredCards,
				currentCard: currentCard,
			});
		}
	};

	loadPageData = () => {
		return Promise.all([this.props.fetchDashboards(), this.props.fetchAllCards()]).then(results => {
			return this.getDefaultDashboard(results[0]);
		});
	};

	getDefaultDashboard = (dashboards = this.props.dashboards) => {
		const {currentDashboard} = this.state;
		if (currentDashboard) {
			return this.loadDashboard(currentDashboard);
		}
		let foundDefault;
		dashboards.forEach(dashboard => {
			if (dashboard.isDefault) {
				foundDefault = true;
				return this.loadDashboard(dashboard);
			}
		});
		if (dashboards.length && !foundDefault) {
			return this.loadDashboard(dashboards[0]);
		}
	};

	showDashboardManager = (type = 'add') => {
		analytics.sendModalView('dashboard-manager');
		if (type === 'edit' && !this.state.currentDashboard.name) {
			return;
		}
		this.setState({
			dashboardManagerActive: true,
			dashboardManagerMode: type,
		});
	};

	hideDashboardManager = () => {
		this.setState({
			dashboardManagerActive: false,
		});
	};

	loadDashboard = dashboard => {
		this.setState({currentDashboard: dashboard || {}});

		if (!dashboard) {
			this.props.showSnackbar('Please create a dashboard first.');
			return;
		}

		return this.props
			.fetchCardsForDashboard(dashboard.gadgets || this.props.dashboards[0].gadgets)
			.then((cards = []) => {
				// TODO: Temporary solution. Currently, the dashboard is populating with IDs coming from a gadgets array.
				// When a card is deleted from a dashboard, it is deleted from the currentCards array, but not yet from gadgets.
				// Should clean up and choose to either keep gadgets or currentCards.
				const filteredCards = cards.filter(card => !_isEmpty(card.dashboards));

				this.setState({
					currentCards: filteredCards,
					currentCard: filteredCards.length ? filteredCards[0] : {},
					chartType: null,
				});
			});
	};

	loadCard = card => {
		this.setState({
			currentCard: card,
			chartType: null,
		});
	};

	deleteCard = () => {
		return dashboardsApi.deleteCard(this.state.deleteTargetCard._id.toString()).then(() => {
			this.hideCardDeleteConfirmModal();
			this.props.showSnackbar('Card successfully deleted');
			return this.loadPageData();
		});
	};

	deleteDashboard = () => {
		return this.props.deleteDashboard(this.state.currentDashboard._id).then(() => {
			this.getDefaultDashboard();
			this.hideDeleteConfirmModal();
			this.props.showSnackbar('Dashboard successfully deleted');
		});
	};

	addDashboard = dashboard => {
		return this.props.addDashboard(dashboard).then(() => {
			if (!this.state.currentDashboard) {
				return this.setState({
					currentDashboard: dashboard,
				});
			}
		});
	};

	updateDashboard = (dashboard, selectedCards) => {
		if (!selectedCards.length) {
			this.setState({
				currentCard: null,
				chartType: null,
			});
		}
		return dashboardsApi.updateDashboard(dashboard).then(() => {
			return this.setState({
				currentDashboard: dashboard,
				currentCards: selectedCards,
				currentCard: this.state.currentCard ? this.state.currentCard : selectedCards[0],
				chartType: null,
			});
		});
	};

	removeCardFromDashboard = () => {
		const {currentDashboard, currentCards, currentCard} = this.state;
		const updatedCards = currentCards.filter(card => card._id !== currentCard._id);

		const dashboardToUpdate = currentDashboard;
		dashboardToUpdate.gadgets = currentDashboard.gadgets.filter(gadget => gadget !== currentCard._id);

		return this.updateDashboard(
			{
				...currentDashboard,
				cards: updatedCards,
				gadgets: currentDashboard.gadgets.filter(gadget => gadget !== currentCard._id),
			},
			[]
		).then(() => {
			this.props.showSnackbar('Card successfully removed from dashboard');
			return this.setState({
				currentCards: updatedCards,
				currentCard: updatedCards.length ? updatedCards[0] : null,
				// Current dashbpard needs to be updated with removed gadgets
				currentDashboard: dashboardToUpdate,
				chartType: null,
				isRemoveConfirmActive: false,
			});
		});
	};

	showDeleteConfirmModal = () => {
		this.setState({isDeleteConfirmActive: true});
	};

	hideDeleteConfirmModal = () => {
		this.setState({isDeleteConfirmActive: false});
	};

	hideRemoveConfirmModal = () => {
		this.setState({isRemoveConfirmActive: false});
	};

	showCardDeleteConfirmModal = card => {
		this.setState({
			isCardDeleteConfirmActive: true,
			deleteTargetCard: card,
		});
	};

	hideCardDeleteConfirmModal = () => {
		this.setState({isCardDeleteConfirmActive: false});
	};

	nextDashboard = () => {
		analytics.sendEvent('dashboards', 'next-card');
		const {currentCards, currentCard} = this.state;
		const cardIndex = currentCards.findIndex(card => card._id === currentCard._id);
		this.setState({
			currentCard: currentCards[cardIndex === currentCards.length - 1 ? 0 : cardIndex + 1],
			chartType: null,
		});
	};

	previousDashboard = () => {
		analytics.sendEvent('dashboards', 'previous-card');
		const {currentCards, currentCard} = this.state;
		const cardIndex = currentCards.findIndex(card => card._id === currentCard._id);
		this.setState({
			currentCard: currentCards[cardIndex === 0 ? currentCards.length - 1 : cardIndex - 1],
			chartType: null,
		});
	};

	openCardEditor = () => {
		this.props.updateCurrentCard(this.state.currentCard);
		this.setState({showCardEditor: true});
	};

	closeCardEditor = () => {
		this.setState({showCardEditor: false});
	};

	render() {
		const {
			loading,
			currentDashboard,
			currentCards,
			currentCard,
			dashboardManagerActive,
			dashboardManagerMode,
		} = this.state;
		const {dashboards, showSnackbar} = this.props;

		return (
			<div className="dashboards">
				<KiAppBar title="Select Your Dashboard:" className="top-bar">
					<div className="dashboard-controls">
						<Select
							classNamePrefix="aut-select"
							placeholder="Dashboards"
							className="dashboard-selector"
							value={currentDashboard}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							onChange={currentDashboard => this.loadDashboard(currentDashboard)}
							isClearable={false}
							options={this.props.dashboards}
						/>
						<div className="right-controls">
							<KiIconButton
								icon="add_circle"
								inverse
								onClick={() => this.showDashboardManager()}
								title="Click to create a new dashboard."
							/>
							<KiMenu
								icon="more_vert"
								className="top-bar-menu"
								position="topRight"
								title={'Click to access the Dashboard Editor'}
							>
								<KiMenuItem
									disabled={dashboards.length === 0}
									icon="dashboard"
									label="Dashboard Editor"
									onClick={() => this.showDashboardManager('edit')}
								/>
							</KiMenu>
						</div>
					</div>
				</KiAppBar>
				<KiAppBar title="Gadgets/Cards:" className={currentCard ? 'card-controls' : 'hide'}>
					<div className="dashboard-controls">
						<div className="select-wrapper">
							<Select
								classNamePrefix="aut-select"
								className="card-selector"
								value={currentCard}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
								onChange={card => this.loadCard(card)}
								isClearable={false}
								options={currentCards}
							/>
						</div>
						<div className="right-controls">
							{_get(currentCard, 'settings.type', 'none') !== 'chart' &&
								_get(currentCard, 'settings.type', 'none') !== 'none' && (
									<KiIconButton
										className="remove-card-btn"
										icon="mode_edit"
										onClick={this.openCardEditor}
										title={'Click to edit the card'}
									/>
								)}
							<KiIconButton
								className="remove-card-btn"
								icon="delete"
								onClick={() => this.removeCardFromDashboard()}
								title={'Click to delete the card from the dashboard'}
							/>
							<KiIconButton
								disabled={currentCards.length > 1 ? false : true}
								className="arrow-button arrow-button-reverse"
								icon="play_arrow"
								onClick={() => this.previousDashboard()}
							/>
							<KiIconButton
								disabled={currentCards.length > 1 ? false : true}
								className="arrow-button"
								icon="play_arrow"
								onClick={() => this.nextDashboard()}
							/>
						</div>
					</div>
				</KiAppBar>
				<div
					className={
						!_isEmpty(currentDashboard) && !_isEmpty(currentCard) ? 'hide' : 'no-card-container ki-panel'
					}
				>
					<div>{`Create some ${
						currentDashboard ? 'gadgets' : 'dashboards and gadgets'
					} to view them here`}</div>
				</div>
				<div className={!_isEmpty(currentCard) ? 'ki-panel card-container' : 'hidden'}>
					<KiProgressBar className={loading ? 'card-loader loading-ani' : 'card-loader loading-ani hidden'} />
					{_get(currentCard, '_id') && (
						<div>
							<KiCard card={currentCard} cardId={currentCard._id} />
						</div>
					)}
				</div>
				<DashboardManager
					mode={dashboardManagerMode}
					currentDashboard={currentDashboard}
					currentCards={currentCards}
					active={dashboardManagerActive}
					updateDashboard={this.updateDashboard}
					addDashboard={this.addDashboard}
					loadPageData={this.loadPageData}
					closeModal={this.hideDashboardManager}
					allCards={this.props.allCards}
					showSnackbar={showSnackbar}
					showDeleteConfirmModal={this.showDeleteConfirmModal}
					showCardDeleteConfirmModal={this.showCardDeleteConfirmModal}
					dashboards={dashboards}
				/>

				<KiConfirmModal
					header="Delete Dashboards"
					message="Are you sure you want to delete this dashboard?"
					acceptFunc={this.deleteDashboard}
					rejectFunc={this.hideDeleteConfirmModal}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.isDeleteConfirmActive}
				/>

				<KiConfirmModal
					header="Remove Card From Dashboard"
					message="Are you sure you want to remove this card from the dashboard?"
					acceptFunc={this.removeCardFromDashboard}
					rejectFunc={this.hideRemoveConfirmModal}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.isRemoveConfirmActive}
				/>

				<KiConfirmModal
					header="Delete Card"
					message="Are you sure you want to delete this card?"
					acceptFunc={this.deleteCard}
					rejectFunc={this.hideCardDeleteConfirmModal}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.isCardDeleteConfirmActive}
				/>
				{this.state.currentCard &&
					this.state.currentCard.datasetId && (
						<KiDataCardEditor
							datasetId={this.state.currentCard.datasetId}
							show={this.state.showCardEditor}
							onClose={this.closeCardEditor}
							activeTab={1}
						/>
					)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	dashboards: state.dashboards.dashboards,
	currentCards: state.dashboards.currentCards,
	allCards: state.dashboards.allCards,
});

const mapDispatchToProps = dispatch => ({
	fetchDashboards: () => dispatch(actions.fetchDashboards()),
	fetchCardsForDashboard: ids => dispatch(actions.fetchCardsForDashboard(ids)),
	fetchAllCards: () => dispatch(actions.fetchAllCards()),
	deleteDashboard: dashboardId => dispatch(actions.deleteDashboard(dashboardId)),
	addDashboard: dashboard => dispatch(actions.addDashboard(dashboard)),
	showSnackbar: msg => dispatch(showSnackbar(msg)),
	updateCurrentCard: card => dispatch(actions.setCurrentCard(card)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
