import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import bfData from 'ki-common/businessFunctions';
import DefaultMappingRow from './DefaultMappingRow';
import {updateDefaultMapping} from '../../actions';
import {fetchAccounts} from 'containers/accounts/actions';
import columnServiceApi from 'api/columnServiceApi';
import styles from './defaultMappings.theme.scss';

export class DefaultMappings extends Component {
	static propTypes = {
		/* from constructor */
		selectedFunction: PropTypes.object,
		selectedVariable: PropTypes.object,
		/* redux state */
		accounts: PropTypes.array,
		dataset: PropTypes.object,
		defaultMappings: PropTypes.array,
		/* dispatches */
		fetchAccounts: PropTypes.func,
		updateDefaultMapping: PropTypes.func,
	};
	constructor(props) {
		super(props);
		this.state = {assetColumns: []};
	}

	componentDidMount() {
		if (!this.props.accounts || this.props.accounts.length === 0) {
			this.props.fetchAccounts();
		}
		columnServiceApi
			.getColumnsFromService(this.props.dataset.datasetId, {
				sources: {},
				options: {
					includeAllColumns: true,
					embedColumns: true,
				},
			})
			.then(result => {
				this.setState({assetColumns: result});
			});
	}

	render() {
		const {accounts, defaultMappings, updateDefaultMapping, selectedFunction, selectedVariable} = this.props;
		const hydratedMappings = _cloneDeep(defaultMappings).map(mapping => {
			if (accounts) {
				let user = _find(accounts, {userId: mapping.createdBy});
				mapping.createdBy = user ? `${user.firstName} ${user.lastName}` : mapping.createdBy;
				user = _find(accounts, {userId: mapping.modifiedBy});
				mapping.modifiedBy = user ? `${user.firstName} ${user.lastName}` : mapping.modifiedBy;
			}

			mapping.functionList = bfData.businessFunctionList.reduce((accumulator, curBusinessFunction) => {
				const varList = curBusinessFunction.requiredVariables.concat(curBusinessFunction.optionalVariables);
				if (varList.includes(mapping.name)) {
					accumulator.push(`${curBusinessFunction.displayName} (${curBusinessFunction.abbreviation})`);
				}
				return accumulator;
			}, []);

			return mapping;
		});
		const assetColumns = this.state.assetColumns.filter(
			col => col.columnType === 'asset' && col.columnFormType !== 'cumulative'
		);

		let mappingsToDisplay = hydratedMappings;
		// Filter by functions variable is used in
		mappingsToDisplay = !selectedFunction
			? mappingsToDisplay
			: mappingsToDisplay.filter(entry => {
					return entry.functionList.some(funcName => {
						return funcName.toLowerCase().includes(selectedFunction.displayName.toLowerCase());
					});
			  });
		// Filter by variable display name
		mappingsToDisplay = !selectedVariable
			? mappingsToDisplay
			: mappingsToDisplay.filter(entry => {
					return entry.displayName.toLowerCase().includes(selectedVariable.displayName.toLowerCase());
			  });
		// Order list alphabetically
		mappingsToDisplay = _sortBy(mappingsToDisplay, [entry => entry.displayName]);

		return (
			<section>
				<table className={styles.bfTable}>
					<thead>
						<tr>
							<th>
								<p>BUSINESS VARIABLES</p>
							</th>
							<th>
								<p>MAPPED ASSET COLUMN</p>
							</th>
							<th>
								<p>DATA TYPE</p>
							</th>
							<th>
								<p>CREATED</p>
							</th>
							<th>
								<p>LAST UPDATED</p>
							</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{mappingsToDisplay.map(entry => (
							<DefaultMappingRow
								key={entry._id}
								assetColumns={assetColumns}
								mapping={entry}
								updateFunction={updateDefaultMapping}
							/>
						))}
					</tbody>
				</table>
			</section>
		);
	}
}

const mapStateToProps = reduxState => ({
	accounts: reduxState.accounts.data,
	dataset: reduxState.datasetList.selected,
	defaultMappings: reduxState.businessFunctions.defaultMappings,
});

const mapDispatchToProps = {
	fetchAccounts,
	updateDefaultMapping,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DefaultMappings);
