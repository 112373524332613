import React, {useState, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from '../VectorForm.theme.scss';
import KiButton from '../../../../../components/KiButton';
import {AbsUploader} from '@moodysanalytics/cs-structured-ux-common';

const ImportPeriodForm = ({onChange, onAdd, onCancel, error}) => {
	const [uploadError, setUploadError] = useState(null);
	const [isFileSubmitted, setIsFileSubmitted] = useState(false);
	const uploaderRef = useRef();

	const uploader = useMemo(
		() => ({
			options: {
				validation: {
					allowedExtensions: ['csv'],
					acceptFiles: '.csv',
				},
			},
			callbacks: {
				onSubmit: (id, file) => {
					const reader = new FileReader();
					reader.onload = e => {
						const text = e.target.result;
						onChange(text);
						setUploadError(null);
						setIsFileSubmitted(true);
					};
					reader.readAsText(file);
				},
				onError: () => {
					setUploadError('CSV files accepted only');
				},
			},
		}),
		[]
	);

	const onAddClick = () => {
		onAdd();
		uploaderRef.current.cancel();
		setIsFileSubmitted(false);
	};

	return (
		<>
			<AbsUploader config={uploader} ref={uploaderRef} dropInfo="Drop CSV file here" />
			{(error || uploadError) && <div className={styles.errorMessage}>{uploadError || error}</div>}
			<div className="inline-column-form-buttons">
				<KiButton flat mini onClick={onCancel}>
					Cancel
				</KiButton>
				<KiButton primary mini onClick={onAddClick} disabled={!!error || !!uploadError || !isFileSubmitted}>
					Add
				</KiButton>
			</div>
		</>
	);
};

ImportPeriodForm.propTypes = {
	onChange: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	error: PropTypes.string,
};

export default ImportPeriodForm;
