import React from 'react';
import PropTypes from 'prop-types';
import {KiSelect} from 'components/KiSelect';
import KiModal2 from 'components/KiModal2';
import KiButton from 'components/KiButton';
import KiDatePicker from 'components/KiDatePicker';
import {booleanLogicNumeric} from 'ki-common/options';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import './ModalStyles.scss';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {ratingsApi} from 'api';
import {splitFundingVehicleSettingsByType} from 'ki-common/utils/fundingVehicleUtil';
import {fetchFundingVehicleSettings} from 'api/fundingVehiclesApi';

export const entityTypes = [
	{
		value: 'tranche',
		label: 'Tranche',
	},
	{
		value: 'counterparty',
		label: 'Counterparty',
	},
];

class TriggersModalRatings extends React.Component {
	static propTypes = {
		fundingVehicles: PropTypes.array,
		columns: PropTypes.array,
		toggleVisibility: PropTypes.func,
		active: PropTypes.bool,
		handleConfirm: PropTypes.func,
		usedFundingVehicles: PropTypes.array,
		selectedFormula: PropTypes.object,
		showSnackbar: PropTypes.func,
	};

	static defaultProps = {
		fundingVehicles: [],
		columns: [],
		usedFundingVehicles: [],
	};

	state = {
		selectedFundingVehicle: this.props.selectedFormula.fundingVehicle || null,
		operator: {
			value: null,
			label: '',
		},
		booleanColumns: [],
		targets: [],
		newTargetDate: null,
		newTargetValue: '',
		ratingOptions: [],
		selectedRating: null,
		agencyOptions: [],
		selectedAgency: this.props.selectedFormula.agencyId || null,
		selectedEntityOption: this.props.selectedFormula.calculation || null,
		entityOptions: [],
		selectedEntity: this.props.selectedFormula.entityType || null,
		isEditMode: this.props.selectedFormula && this.props.selectedFormula.fundingVehicle,
		oneTargetExistsMode: null,
	};

	async componentDidMount() {
		const booleanColumns = this.getValidColumnsByDataType(this.props.columns, ['boolean', 'numeric', 'ratings']);
		const agencyOptionsResp = await ratingsApi.fetchRatingsAgencies().catch(() => {
			this.props.showSnackbar('Failure to load indices');
		});

		const ratingsOptionsResp = await ratingsApi.fetchRatingsOptionsByAgency(this.props.selectedFormula.agencyId);
		const selectedFundingVehicle = this.props.fundingVehicles.find(
			fv => fv._id === this.props.selectedFormula.fundingVehicle
		);
		let entityOptions = [];
		if (selectedFundingVehicle) {
			entityOptions = await this.getEntityOptions(selectedFundingVehicle._id);
		}

		this.setState({
			selectedFundingVehicle,
			booleanColumns,
			targets: this.props.selectedFormula.threshold || [],
			selectedEntity: this.props.selectedFormula.entityType,
			selectedEntityOption: entityOptions.find(t => t._id === this.props.selectedFormula.calculation),
			operator: booleanLogicNumeric.find(o => o.value === this.props.selectedFormula.operator),
			agencyOptions: agencyOptionsResp || [],
			selectedAgency: this.props.selectedFormula.agencyId,
			ratingOptions: ratingsOptionsResp || [],
			entityOptions: entityOptions,
		});
	}

	updateSelectedAgency = async agencyId => {
		const resp = await ratingsApi.fetchRatingsOptionsByAgency(agencyId);
		this.setState({
			selectedAgency: agencyId,
			ratingOptions: resp || [],
			targets: [],
		});
	};

	getEntityOptions = async fundingVehicleId => {
		const res = await fetchFundingVehicleSettings(fundingVehicleId);
		const splitSettings = splitFundingVehicleSettingsByType(res);
		//If the chosen entity is tranche, filter for tranche options associated with the funding vehicle
		if (this.state.selectedEntity === 'tranche') {
			return splitSettings.debt || [];
		}
		//If the chosen entity is counterparty filter for tranche options associated with the funding vehicle
		if (this.state.selectedEntity === 'counterparty') {
			return splitSettings.counterparties || [];
		}
	};

	updateSelectedFundingVehicle = async fundingVehicle => {
		let entityList = [];
		entityList = await this.getEntityOptions(fundingVehicle._id);
		this.setState({
			entityOptions: entityList || [],
			selectedFundingVehicle: fundingVehicle,
		});
	};

	updateEntityType = async (entityType, fundingVehicle) => {
		this.state.selectedEntity = entityType.value;
		this.updateSelectedFundingVehicle(fundingVehicle);
	};

	getValidColumnsByDataType = (columns, dataTypes) => {
		const validCols = columns.filter(
			col =>
				['aggregate', 'debtInputFV', 'debtCalculation', 'fvSetup', 'borrowingBase', 'waterfall'].includes(
					col.columnType
				) &&
				dataTypes.includes(col.dataType) &&
				_get(this.props, 'columnToEdit._id', null) !== col._id
		);
		return validCols;
	};

	disableSave = () => {
		const {selectedFundingVehicle, selectedEntityOption, operator, targets} = this.state;
		return !(selectedFundingVehicle && selectedEntityOption && operator && targets.length);
	};

	handleConfirm = () => {
		const output = {
			fundingVehicle: this.state.selectedFundingVehicle._id,
			entityType: this.state.selectedEntity,
			calculation: this.state.selectedEntityOption._id,
			operator: this.state.operator.value,
			agencyId: this.state.selectedAgency,
			threshold: this.state.targets,
		};
		this.props.handleConfirm(output);
		this.props.toggleVisibility();
	};

	getUnusedFVOptions = () => {
		return this.props.fundingVehicles.filter(fv => {
			return !this.props.usedFundingVehicles.includes(fv._id);
		});
	};

	addTargetValues = () => {
		const newTarget = {
			date: dateToShortDate(this.state.newTargetDate),
			value: this.state.selectedRating.scale,
		};
		const newTargets = [...this.state.targets];
		newTargets.push(newTarget);
		this.setState({
			targets: newTargets,
			newTargetDate: null,
			index: this.state.selectedRating,
		});

		if (newTargets.length >= 0) {
			this.setState({
				oneTargetExistsMode:
					this.state.selectedFundingVehicle &&
					this.state.selectedEntityOption &&
					this.state.selectedEntity &&
					this.state.selectedAgency,
			});
		}
	};

	deleteTarget = index => {
		const targets = _cloneDeep(this.state.targets);
		targets.splice(index, 1);
		this.setState({
			targets: targets,
		});

		if (targets.length === 0) {
			this.setState({
				oneTargetExistsMode: !(
					this.state.selectedFundingVehicle &&
					this.state.selectedEntityOption &&
					this.state.selectedEntity &&
					this.state.selectedAgency
				),
			});
		}
	};

	getRatingName = value => {
		const rating = this.state.ratingOptions.find(o => o.scale === value);
		return rating ? rating.value : value;
	};

	render() {
		const {targets} = this.state;
		return (
			<KiModal2
				active={this.props.active}
				actions={[
					{label: 'Cancel', onClick: () => this.props.toggleVisibility()},
					{label: 'Apply', onClick: this.handleConfirm, disabled: this.disableSave()},
				]}
				panelStyles={{minHeight: '60rem', minWidth: '90rem'}}
				className="numeric-debt-formula-modal"
				header={`Create/Edit a Trigger`}
			>
				<div className="form-row" style={{width: '87rem'}}>
					<div className="flex-6" style={{marginRight: '3rem', width: '23rem'}}>
						<span className="form-instruction">Funding Vehicle</span>
						<KiSelect
							value={this.state.selectedFundingVehicle}
							options={this.getUnusedFVOptions()}
							getOptionLabel={opt => opt.name}
							getOptionValue={opt => opt._id}
							onChange={val => this.updateSelectedFundingVehicle(val)}
							isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
						/>
					</div>
					<div className="flex-6" style={{marginRight: '3rem', width: '23rem'}}>
						<span className="form-instruction">Entity</span>
						<KiSelect
							classNamePrefix="aut-select"
							value={entityTypes.filter(option => option.value === this.state.selectedEntity)}
							options={entityTypes}
							getOptionLabel={opt => opt.label}
							getOptionValue={opt => opt.value}
							isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
							onChange={val => this.updateEntityType(val, this.state.selectedFundingVehicle)}
						/>
					</div>
					{this.state.selectedEntity === 'tranche' && (
						<div className="flex-6" style={{marginRight: '3rem', width: '23rem'}}>
							<span className="form-instruction">Tranche</span>
							<KiSelect
								value={this.state.selectedEntityOption}
								options={this.state.entityOptions}
								getOptionLabel={opt => opt.name}
								getOptionValue={opt => opt._id}
								onChange={val => this.setState({selectedEntityOption: val})}
								isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
							/>
						</div>
					)}
					{this.state.selectedEntity === 'counterparty' && (
						<div className="flex-6" style={{marginRight: '3rem', width: '23rem'}}>
							<span className="form-instruction">Counterparty</span>
							<KiSelect
								value={this.state.selectedEntityOption}
								options={this.state.entityOptions}
								getOptionLabel={opt => opt.legalName}
								getOptionValue={opt => opt._id}
								onChange={val => this.setState({selectedEntityOption: val})}
								isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
							/>
						</div>
					)}
					<div className="flex-6" style={{marginRight: '3rem', width: '23rem'}}>
						<span className="form-instruction">Rating Agency</span>
						<KiSelect
							classNamePrefix="aut-select"
							value={this.state.agencyOptions.filter(option => option.id === this.state.selectedAgency)}
							options={this.state.agencyOptions}
							getOptionValue={opt => opt.id}
							getOptionLabel={opt => opt.description}
							onChange={option => this.updateSelectedAgency(option.id)}
							isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
						/>
					</div>
				</div>
				<div className="form-row">
					<div style={{marginRight: '2rem'}}>
						<KiDatePicker
							label="Target Date"
							onChange={val => this.setState({newTargetDate: val})}
							value={this.state.newTargetDate}
						/>
					</div>
					<div style={{marginRight: '2rem', width: '25rem'}}>
						<span className="form-instruction">Operator</span>
						<KiSelect
							classNamePrefix="aut-select"
							value={this.state.operator}
							isClearable={false}
							options={booleanLogicNumeric}
							onChange={selected => this.setState({operator: selected})}
							isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
						/>
					</div>
					<div style={{marginRight: '2rem', width: '25rem'}}>
						<span className="form-instruction">Rating</span>
						<KiSelect
							classNamePrefix="aut-select"
							value={this.state.ratingOptions.filter(option => option === this.state.selectedRating)}
							options={this.state.ratingOptions}
							getOptionValue={opt => opt.scale}
							getOptionLabel={opt => opt.value}
							onChange={option => this.setState({selectedRating: option})}
						/>
					</div>
					<div>
						<KiButton
							className="add-btn trigger-add-btn"
							style={{bottom: '-11px'}}
							onClick={this.addTargetValues}
							disabled={!this.state.newTargetDate || !this.state.selectedRating}
							icon="add"
						/>
					</div>
				</div>
				<div>
					<div>Existing Targets</div>
					<hr />
					{targets.map((target, index) => (
						<div className="list-row" key={index}>
							<span>{`${target.date} ${this.state.operator.label} ${this.getRatingName(
								target.value
							)}`}</span>
							<i
								className="material-icons"
								onClick={() => this.deleteTarget(index)}
								style={{cursor: 'pointer'}}
							>
								delete
							</i>
						</div>
					))}
				</div>
			</KiModal2>
		);
	}
}

export default TriggersModalRatings;
