import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchMappingsByDataset = datasetId => {
	return fetch(`${apiUrl}/datasetMappings/${datasetId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchMappingsByDatasetAndFundingVehicle = (datasetId, fundingVehicleId) => {
	return fetch(`${apiUrl}/datasetMappings/${datasetId}?fundingVehicleId=${fundingVehicleId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const addDatasetMapping = mapping => {
	return fetch(`${apiUrl}/datasetMappings`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(mapping),
	}).then(_handleResponse);
};

export const updateDatasetMapping = mapping => {
	return fetch(`${apiUrl}/datasetMappings`, {
		credentials: 'include',
		method: 'PUT',
		headers: _getHeaders('PUT'),
		body: JSON.stringify(mapping),
	}).then(_handleResponse);
};

export const deleteDatasetMapping = mappingId => {
	return fetch(`${apiUrl}/datasetMappings/${mappingId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export default {
	fetchMappingsByDataset,
	fetchMappingsByDatasetAndFundingVehicle,
	addDatasetMapping,
	updateDatasetMapping,
	deleteDatasetMapping,
};
