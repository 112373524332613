import {fundingVehiclesApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
import * as actionTypes from './actionTypes';

const fetchFundingVehiclePools = (datasetId, fundingVehicleId) => dispatch =>
	fundingVehiclesApi
		.getPoolsForFundingVehicle(datasetId, fundingVehicleId)
		.then(selectedPools => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_POOLS,
				status: 'success',
				selectedPools: selectedPools,
			});
			return selectedPools;
		})
		.catch(err =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_POOLS,
				status: 'error',
				msg: err,
			})
		);

const fetchFundingVehicle = id => dispatch =>
	fundingVehiclesApi
		.fetchFundingVehicle(id)
		.then(fundingVehicle => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_ONE,
				status: 'success',
				fundingVehicleSelected: fundingVehicle,
			});
			dispatch(fetchFundingVehiclePools(fundingVehicle.datasetId, id));
			return fundingVehicle;
		})
		.catch(err =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_ONE,
				status: 'error',
				msg: err,
			})
		);

const fetchFundingVehicleSettings = id => dispatch =>
	fundingVehiclesApi
		.fetchFundingVehicleSettings(id)
		.then(result =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_SETTINGS,
				status: 'success',
				selectedSettings: result,
			})
		)
		.catch(err =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_SETTINGS,
				status: 'error',
				msg: err,
			})
		);

const fetchFundingVehicleSettingsByDatasetId = id => dispatch =>
	fundingVehiclesApi
		.fetchFundingVehicleSettingsByDatasetId(id)
		.then(result => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_SETTINGS_BY_DATASETID,
				status: 'success',
				selectedSettings: result,
			});
		})
		.catch(err =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_SETTINGS_BY_DATASETID,
				status: 'error',
				msg: err,
			})
		);

const fetchFundingVehicleSetting = id => dispatch =>
	fundingVehiclesApi
		.fetchFundingVehicleSetting(id)
		.then(result =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_ONE_SETTING,
				status: 'success',
				setting: result,
			})
		)
		.catch(err =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_ONE_SETTING,
				status: 'error',
				msg: err,
			})
		);

const showErrorMsg = errorMsg => dispatch => {
	dispatch(showSnackbar(errorMsg));
};

const updateFundingVehicle = (fundingVehicle, displaySnackbar = true) => dispatch =>
	fundingVehiclesApi
		.updateFundingVehicle(fundingVehicle)
		.then(result => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_UPDATE_ONE,
				status: 'success',
				fundingVehicleSelected: result,
			});
			if (displaySnackbar) {
				dispatch(showSnackbar(`Updated ${result.name || 'setting'} successfully`));
			}
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
			return dispatch({
				type: actionTypes.FUNDING_VEHICLES_UPDATE_ONE,
				status: 'error',
			});
		});

const updateFundingVehicleSetting = (fundingVehicleSetting, update = true, isDelete) => dispatch =>
	fundingVehiclesApi
		.updateFundingVehicleSetting(fundingVehicleSetting)
		.then(() => {
			const updateTypeMessage = isDelete ? 'Deleted' : `Updated ${fundingVehicleSetting.name || 'setting'}`;
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SETTING_UPDATE_ONE,
				status: 'success',
			});
			dispatch(showSnackbar(`${updateTypeMessage} successfully.`));
			if (update) {
				return dispatch(fetchFundingVehicleSettings(fundingVehicleSetting.fundingVehicleId)).then(() => {
					if (fundingVehicleSetting.datasetId) {
						dispatch(fetchFundingVehicleSettingsByDatasetId(fundingVehicleSetting.datasetId));
					}
				});
			}
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
			return dispatch({
				type: actionTypes.FUNDING_VEHICLES_SETTING_UPDATE_ONE,
				status: 'error',
			});
		});

const addFundingVehicleSetting = fundingVehicleSetting => dispatch =>
	fundingVehiclesApi
		.addFundingVehicleSetting(fundingVehicleSetting)
		.then(result => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SETTING_ADD_ONE,
				status: 'success',
			});
			dispatch(showSnackbar(`Added ${fundingVehicleSetting.name || 'setting'} successfully.`));
			return dispatch(fetchFundingVehicleSettings(result.fundingVehicleId)).then(() => {
				if (result.datasetId) {
					dispatch(fetchFundingVehicleSettingsByDatasetId(result.datasetId));
				}
			});
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SETTING_ADD_ONE,
				status: 'error',
			});
			return fundingVehiclesApi.fetchFundingVehicle(fundingVehicleSetting.fundingVehicleId).then(result => {
				return dispatch(fetchFundingVehicleSettings(result.fundingVehicleId)).then(() => {
					if (result.datasetId) {
						dispatch(fetchFundingVehicleSettingsByDatasetId(result.datasetId));
					}
				});
			});
		});

const deleteFundingVehicleSettings = fundingVehicleSettings => dispatch => {
	const fundingVehicleSettingIds = fundingVehicleSettings.map(s => s._id);
	return fundingVehiclesApi
		.deleteFundingVehicleSettings(fundingVehicleSettingIds)
		.then(() => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SETTINGS_DELETE,
				status: 'success',
			});
			return dispatch(fetchFundingVehicleSettings(fundingVehicleSettings[0].fundingVehicleId));
		})
		.catch(err => {
			if (err.data) {
				// this means we had a partial delete due to dependencies found
				dispatch(fetchFundingVehicleSettings(fundingVehicleSettings[0].fundingVehicleId));
				return err.data;
			}
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SETTINGS_DELETE,
				status: 'error',
			});
			dispatch(showSnackbar(err.message));
		});
};

const fetchAllReportingDates = fvId => dispatch => {
	dispatch({type: actionTypes.FUNDING_VEHICLES_FETCH_ALL_REPORT_DATES, status: 'pending'});
	return fundingVehiclesApi
		.fetchAllReportingDates(fvId)
		.then(dates => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_ALL_REPORT_DATES,
				status: 'success',
				reportDates: dates,
			});
			return dates;
		})
		.catch(err =>
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_FETCH_ALL_REPORT_DATES,
				status: 'error',
				msg: err,
			})
		);
};

const toggleFvBlockModal = (isOpen = false) => ({
	type: actionTypes.FUNDING_VEHICLES_BLOCK_MODAL_TOGGLE,
	isOpen: isOpen,
});

const setCurrentBlock = block => dispatch => {
	dispatch({
		type: actionTypes.FUNDING_VEHICLES_SET_CURRENT_BLOCK,
		block: block,
	});
	dispatch({
		type: actionTypes.FUNDING_VEHICLES_BLOCK_MODAL_TOGGLE,
		isOpen: true,
	});
};

const addBlockForFundingVehicle = block => dispatch => {
	return fundingVehiclesApi
		.addBlock(block)
		.then(result => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SAVE_BLOCK,
				status: 'success',
				block: result,
			});
			return result;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SAVE_BLOCK,
				status: 'error',
				msg: err,
			});
		});
};

const deleteBlockForFundingVehicle = blockId => dispatch => {
	return fundingVehiclesApi
		.deleteBlock(blockId)
		.then(() => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_DELETE_BLOCK,
				status: 'success',
				blockId: blockId,
			});
			return blockId;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_DELETE_BLOCK,
				status: 'error',
				msg: err,
			});
		});
};

const fetchBlocksForFundingVehicle = fvId => dispatch => {
	return fundingVehiclesApi
		.fetchBlocksForFundingVehicle(fvId)
		.then(blocks => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_GET_BLOCK_LIST,
				status: 'success',
				blocks: blocks,
			});
			return blocks;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_GET_BLOCK_LIST,
				status: 'error',
				msg: err,
			});
		});
};

const fetchTracksForFundingVehicle = fvId => dispatch => {
	return fundingVehiclesApi
		.fetchTracksForFundingVehicle(fvId)
		.then(tracks => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_GET_TRACK_LIST,
				status: 'success',
				tracks: tracks,
			});
			return tracks;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_GET_TRACK_LIST,
				status: 'error',
				msg: err,
			});
		});
};

const addTrackForFundingVehicle = track => dispatch => {
	track.blocks = track.blocks.map(block => block._id);
	return fundingVehiclesApi
		.addTrack(track)
		.then(result => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SAVE_TRACK,
				status: 'success',
				track: result,
			});
			return result;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.FUNDING_VEHICLES_SAVE_TRACK,
				status: 'error',
				msg: err,
			});
		});
};

const addBlockToTrack = block => (dispatch, getState) => {
	dispatch({
		type: actionTypes.FUNDING_VEHICLES_ADD_BLOCK_TO_TRACK,
		block: block,
	});
	const {fundingVehicle} = getState();
	const trackToSave = Object.assign({}, fundingVehicle.structures.currentTrack);
	trackToSave.track = trackToSave.track.label;
	trackToSave.blocks = trackToSave.blocks.map(block => block._id);
	const updatedTrack = Object.assign({}, fundingVehicle.structures.currentTrack);
	// updatedTrack.blocks.push(block);
	return fundingVehiclesApi.addTrack(trackToSave).then(result => {
		// return setCurrentTrack(updatedTrack);
		updatedTrack._id = result._id;
		dispatch({
			type: actionTypes.FUNDING_VEHICLES_SET_CURRENT_TRACK,
			track: updatedTrack,
		});
	});
};

const removeBlockFromTrack = blockId => (dispatch, getState) => {
	const {fundingVehicle} = getState();
	const trackToSave = Object.assign({}, fundingVehicle.structures.currentTrack);
	const updatedTrack = Object.assign({}, fundingVehicle.structures.currentTrack);
	updatedTrack.blocks = updatedTrack.blocks.filter(block => block._id !== blockId);
	trackToSave.track = trackToSave.track.label;
	trackToSave.blocks = trackToSave.blocks.map(block => block._id).filter(id => id !== blockId);
	return fundingVehiclesApi.addTrack(trackToSave).then(() => {
		// return setCurrentTrack(updatedTrack)
		dispatch({
			type: actionTypes.FUNDING_VEHICLES_SET_CURRENT_TRACK,
			track: updatedTrack,
		});
	});
};

const setCurrentTrack = track => dispatch => {
	dispatch({
		type: actionTypes.FUNDING_VEHICLES_SET_CURRENT_TRACK,
		track: track,
	});
};

export {
	fetchFundingVehicle,
	updateFundingVehicle,
	updateFundingVehicleSetting,
	deleteFundingVehicleSettings,
	fetchFundingVehicleSettings,
	fetchFundingVehicleSettingsByDatasetId,
	addFundingVehicleSetting,
	fetchFundingVehicleSetting,
	fetchAllReportingDates,
	toggleFvBlockModal,
	setCurrentBlock,
	addBlockForFundingVehicle,
	fetchBlocksForFundingVehicle,
	deleteBlockForFundingVehicle,
	fetchTracksForFundingVehicle,
	addTrackForFundingVehicle,
	setCurrentTrack,
	addBlockToTrack,
	removeBlockFromTrack,
	showErrorMsg,
};
