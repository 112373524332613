import React from 'react';
import LedgerSubmitHeader from '../LedgerSubmitHeader';
import KiDatePicker from '../../../../components/KiDatePicker';
import {formatDate} from '../../../../utils/dateUtils';
import KiButton from '../../../../components/KiButton';
import './LedgerGeneratePosts.scss';
import PropTypes from 'prop-types';

function GeneratePostsHeader({ledgerCloseDate, actions, setPostDate, postDate, calculate, calculateDisabled}) {
	return (
		<section className="ki-panel">
			<LedgerSubmitHeader actions={actions}>
				<div className="horizontal-inputs-container">
					<div>
						<span className="theme-label">Post Date</span>
						<KiDatePicker onChange={date => setPostDate(date)} value={postDate} className="datepicker" />
					</div>
					<div className="button-wrapper">
						<KiButton raised primary onClick={calculate} disabled={calculateDisabled}>
							CALCULATE
						</KiButton>
					</div>
					<h2 className="additional-info">
						Ledger Close Date: <span>{formatDate(ledgerCloseDate)}</span>
					</h2>
				</div>
			</LedgerSubmitHeader>
		</section>
	);
}

GeneratePostsHeader.propTypes = {
	ledgerCloseDate: PropTypes.string,
	actions: PropTypes.arrayOf(PropTypes.object),
	postDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	setPostDate: PropTypes.func,
	calculate: PropTypes.func,
	calculateDisabled: PropTypes.bool.isRequired,
};

GeneratePostsHeader.defaultProps = {
	actions: [],
	postDate: '',
	calculateDisabled: true,
};

export default GeneratePostsHeader;
