// Globals
import React, {useContext} from 'react';
import PropTypes from 'prop-types';

// Website imports
import {KiInput} from '../../../../components/KiInput';
import {useMergedState} from '../../../../utils/customHooks';
import {removeFromArray} from '../../../../utils/arrayUtils';

// Local imports
import styles from './customBandsOdd.theme.scss';
import CohortBandsOddRow from './CohortBandsOddRow';
import {DataExplorerContext} from '../../DataExplorerContext';

const CohortBandsOdd = ({setFormErrors, formErrors}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark: {explorerData},
		setExplorerData,
	} = dataExplorerContext;

	const [newItem, setNewItem] = useMergedState({
		newStart: '',
		newEnd: '',
		newStep: '',
	});

	const validateInputRowComplete = () => {
		return newItem.newStart && newItem.newEnd && newItem.newStep;
	};

	const addRow = () => {
		const bandSize = Number(newItem.newEnd) - Number(newItem.newStart);
		if (bandSize < Number(newItem.newStep)) {
			setFormErrors({'custom-odd-step-new': 'greater than band'});
			return;
		} else if (bandSize / Number(newItem.newStep) > 1000) {
			setFormErrors({'custom-odd-step-new': '1000 steps max'});
			return;
		} else {
			setFormErrors({'custom-odd-step-new': ''});
		}

		setExplorerData(
			{
				bands: {
					...explorerData.bands,
					steps: [
						...explorerData.bands.steps,
						{
							start: Number(newItem.newStart),
							end: Number(newItem.newEnd),
							step: Number(newItem.newStep),
						},
					],
				},
			},
			true
		);
		setNewItem({
			newStart: '',
			newEnd: '',
			newStep: '',
		});
		setFormErrors({'custom-odd': ''});
	};

	const deleteRow = index => {
		if (explorerData.bands.steps.length === 1) {
			setFormErrors({'custom-odd': 'Required'});
		}
		setExplorerData(
			{
				bands: {...explorerData.bands, steps: removeFromArray(explorerData.bands.steps, index)},
			},
			true
		);
	};

	return (
		<div className={styles.customOddBands}>
			<div className={styles.insertRow}>
				<KiInput
					name="custom-odd-start-new"
					label="Starting Band"
					type="text"
					value={newItem.newStart}
					onChange={val => setNewItem({newStart: val})}
					error={formErrors[`custom-odd-start-new`]}
					isNumberMasked={true}
				/>
				<KiInput
					name="custom-odd-end-new"
					label="Ending Band"
					type="text"
					value={newItem.newEnd}
					onChange={val => setNewItem({newEnd: val})}
					error={formErrors[`custom-odd-end-new`]}
					isNumberMasked={true}
				/>
				<KiInput
					name="custom-odd-step-new"
					label="Granularity"
					type="text"
					value={newItem.newStep}
					onChange={val => setNewItem({newStep: val})}
					error={formErrors[`custom-odd-step-new`]}
					isNumberMasked={true}
				/>
				<i className={`material-icons ${validateInputRowComplete() ? '' : styles.disabled}`} onClick={addRow}>
					add_circle
				</i>
			</div>
			{explorerData.bands.steps.map((band, index) => {
				return (
					<div key={index} className={styles.editRow}>
						<div className={styles.number}>{index + 1}</div>
						<CohortBandsOddRow
							band={band}
							index={index}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
						/>
						<i className="material-icons" onClick={() => deleteRow(index)}>
							delete
						</i>
					</div>
				);
			})}
			<span className={styles.error}>{formErrors['custom-odd']}</span>
		</div>
	);
};

CohortBandsOdd.propTypes = {
	setFormErrors: PropTypes.func,
	formErrors: PropTypes.object,
};

export default CohortBandsOdd;
