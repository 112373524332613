import PropTypes from 'prop-types';
import React, {Component} from 'react';
import CollapsiblePanel from 'components/CollapsiblePanel';
import KiProgressBar from 'components/KiProgressBar';
import './SchemaResults.scss';

class SchemaResults extends Component {
	static propTypes = {
		errors: PropTypes.array,
		collapsed: PropTypes.bool,
		toggleCollapsed: PropTypes.func,
		status: PropTypes.object,
	};

	formatError = (error, idx) => {
		let msg = '';
		let type = 'error';
		switch (error.code) {
			case 'assetNumber': {
				type = 'warning';
				msg = `We found ${error.count} previously submitted entries in column '${
					error.id
				}', these will be refreshed on commit`;
				break;
			}
			case 'assetNumberMatch': {
				type = 'warning';
				msg = `We found ${error.count} previously submitted with the same balance in column '${
					error.id
				}', these will be refreshed on commit`;
				break;
			}
			case 'columnSpecialCharacters': {
				type = 'warning';
				msg = `Column '${error.id}' contains special characters or spaces and will be renamed`;
				break;
			}
			default:
				msg = `We found ${error.count} mismatches in column '${
					error.id
				}', these will be replaced with default values on commit`;
				break;
		}
		return (
			<p className={type} key={idx}>
				{msg}
			</p>
		);
	};

	render() {
		const {errors = [], collapsed, status = {}, toggleCollapsed} = this.props;

		const isLoading = status.name === 'validate' && !!status.progressMessage;
		const isSuccess = status.name === 'validate' && !!status.successMessage;
		const isError = status.name === 'validate' && !!status.errorMessage;

		return (
			<CollapsiblePanel
				className="submission-schema-results ki-panel"
				title="Results"
				collapsed={collapsed}
				onCollapseToggleClick={toggleCollapsed}
			>
				{isLoading && <KiProgressBar className={isLoading ? 'loading-ani' : 'loading-ani hidden'} />}
				<article className="schema-results-list">
					{!isLoading &&
						isSuccess && (
							<header className={errors.length ? '' : 'no-errors'}>{errors.length} errors found</header>
						)}
					{(isLoading || isError) && (
						<section className="status-message">{status.errorMessage || ''}</section>
					)}
					{isSuccess &&
						!!errors.length && (
							<section className="results">
								{errors.map((error, idx) => this.formatError(error, idx))}
							</section>
						)}
				</article>
			</CollapsiblePanel>
		);
	}
}

export default SchemaResults;
