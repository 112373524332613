import * as actionTypes from './actionTypes';
import {cloneDeep, set} from 'lodash';
import {isAccountValid, convertsAccountsToList} from './accountUtils';

const initialState = {
	accountList: [],
	originalAccountList: [],
};

const blankAccount = {
	active: false,
	type: '',
	description: '',
	code: '',
	visible: true,
	modified: false,
	valid: false,
	deleted: false,
	originalIndex: null,
};

const LedgerAccountConfiguration = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ACCOUNT_DATA: {
			return {
				...state,
				accountList: [...cloneDeep(action.newData)],
			};
		}
		case actionTypes.SET_ACCOUNT_DATA_FROM_ORIGINAL: {
			return {
				...state,
				accountList: [...cloneDeep(convertsAccountsToList(action.newData))],
			};
		}
		case actionTypes.SET_ACCOUNT_ORIGINAL:
			return {...state, originalAccountList: [...cloneDeep(convertsAccountsToList(action.newData))]};
		case actionTypes.ACCOUNT_UNDO: {
			Object.assign(state.accountList[action.index], state.originalAccountList[action.originalIndex]);
			return {...state, accountList: [...cloneDeep(state.accountList)]};
		}
		case actionTypes.ACCOUNT_UNDO_PROPERTY: {
			const {index, originalIndex, property} = action;
			const editedAccount = state.accountList[index];
			editedAccount[property] = state.originalAccountList[originalIndex][property];
			editedAccount.valid = isAccountValid(editedAccount);
			editedAccount.active = state.originalAccountList[originalIndex].active && editedAccount.valid;
			return {...state, accountList: [...cloneDeep(state.accountList)]};
		}
		case actionTypes.ACCOUNT_REMOVE: {
			state.accountList[action.index].deleted = true;
			return {...state, accountList: [...cloneDeep(state.accountList)]};
		}
		case actionTypes.ACCOUNT_ADD: {
			return {
				...state,
				accountList: [cloneDeep(blankAccount), ...cloneDeep(state.accountList)],
			};
		}
		case actionTypes.ACCOUNT_CHANGE: {
			const editedAccount = state.accountList[action.index];
			set(editedAccount, action.key, action.value);
			editedAccount.modified = action.originalIndex !== null;
			editedAccount.valid = isAccountValid(editedAccount);
			editedAccount.active = editedAccount.active && editedAccount.valid;
			return {...state, accountList: [...cloneDeep(state.accountList)]};
		}
		default:
			return state;
	}
};

export default LedgerAccountConfiguration;
