import KiChart from './KiChart';
const _set = require('lodash/set');

class KiColumnChart extends KiChart {
	buildParamConfig() {
		const config = this.params;
		const paramConfig = {
			chart: {
				type: 'column',
				height: config.height,
			},
			plotOptions: {
				column: {
					pointPadding: 0.2,
					borderWidth: 0,
				},
			},
			title: {
				text: config.chartTitle,
			},
			xAxis: {
				categories: config.categories,
				title: {
					text: config.xTitle,
				},
			},
			yAxis: {
				title: {
					text: config.yTitle,
				},
				height: '100%',
			},
			series: config.series,
		};

		switch (config.chartType) {
			case 'column':
				break;
			case '3d-column':
				_set(paramConfig, 'chart.options3d', {
					enabled: true,
					alpha: 15,
					beta: 15,
					depth: 50,
					viewDistance: 25,
				});
				_set(paramConfig, 'plotOptions.column.depth', 25);
				break;
		}
		return paramConfig;
	}
}

export default KiColumnChart;
