import {_getHeaders, _handleResponse, apiUrl} from 'api/apiUtils';

export const fetchJobResults = jobId => {
	return fetch(`${apiUrl}/jobs/${jobId}/result`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	})
		.then(_handleResponse)
		.then(response => response);
};
