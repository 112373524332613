import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import noop from 'lodash/noop';
import './ErrorSummary.scss';
import KiButton from 'components/KiButton';
import _groupBy from 'lodash/groupBy';

export const ErrorSummaryItem = props => {
	const {level, count, onClick = noop, text} = props;
	return (
		<div className={`error level-${level}`} onClick={level === 'warning' ? onClick : noop}>
			{`${count} ${text}`}
		</div>
	);
};

ErrorSummaryItem.propTypes = {
	text: PropTypes.string,
	count: PropTypes.number,
	level: PropTypes.oneOf(['error', 'warning']),
	onClick: PropTypes.func,
};

export const ErrorSummary = props => {
	const getErrorMessage = useCallback((type, count = 1) => {
		const isMultiple = count > 1;

		switch (type) {
			case 'newColumnFound':
				return `new column${isMultiple ? 's' : ''} found`;

			case 'columnNotFound':
				return `column${isMultiple ? 's' : ''} missing`;

			case 'dataTypeMismatchNumeric':
				return `numeric data type mismatch${count > 1 ? 'es' : ''}`;

			case 'dataTypeMismatchShortDate':
				return `shortDate data type mismatch${isMultiple ? 'es' : ''}`;

			case 'dataTypeMismatchLongDate':
				return `longDate data type mismatch${isMultiple ? 'es' : ''}`;

			case 'dataTypeMismatchCharacter':
				return `string data type mismatch${isMultiple ? 'es' : ''}`;

			case 'AssetIDNotFound':
				return 'required column "AssetID" not mapped';

			case 'BalanceNotFound':
				return 'required column "Balance" not mapped';

			case 'DealIDNotFound':
				return 'required column "DealID" not mapped';

			case 'emptyRequiredColumn':
				return `required column${isMultiple ? 's' : ''} entry incomplete`;

			case 'emptyOptionalColumn':
				return `optional column${isMultiple ? 's' : ''} entry incomplete`;

			default:
				return null;
		}
	});
	const getErrorHeader = useCallback(() => {
		const typeCounts = _groupBy(props.errors, error => error.level);

		const errorsCount = (typeCounts['error'] && typeCounts['error'].length) || 0;
		const warningCount = (typeCounts['warning'] && typeCounts['warning'].length) || 0;

		if (errorsCount === 0 && warningCount === 0) {
			return <header className="no-errors-found">0 errors found for the schema</header>;
		}

		return (
			<header>
				<span>
					{errorsCount} Errors {warningCount} Warnings
				</span>
				<KiButton onMouseUp={() => props.clearFilter()}>Show All Columns</KiButton>
			</header>
		);
	});

	const errors = _groupBy(props.errors, error => error.code);

	return (
		<div className="schema-error-panel">
			{getErrorHeader()}
			{!!props.errors.length && (
				<div className="schema-error-summary">
					{Object.keys(errors).map(errorType => (
						<ErrorSummaryItem
							key={errorType}
							text={getErrorMessage(errorType, errors[errorType].length) || 'Unknown Error'}
							count={errors[errorType].length}
							level={errors[errorType][0].level}
							onClick={() => props.setFilter(errors[errorType].map(e => e.columnId))}
						/>
					))}
				</div>
			)}
		</div>
	);
};

ErrorSummary.propTypes = {
	errors: PropTypes.array,
	setFilter: PropTypes.func,
	clearFilter: PropTypes.func,
};

export default ErrorSummary;
