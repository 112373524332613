/* eslint-disable no-use-before-define */
/*
	This is a rewrite to extend the moment-recur library by c-trimm https://github.com/c-trimm/moment-recur
	Enhanced to allow providing extra dates to include them in matching and date generation.
 */
const moment = require('moment');
const holidayCalendarsUtil = require('./holidayCalendars');
const Holidays = holidayCalendarsUtil.Holidays;
const _ = require('lodash');

// A dictionary used to match rule measures to rule types
const ruleTypes = {
	days: 'interval',
	weeks: 'interval',
	months: 'interval',
	years: 'interval',
	daysOfWeek: 'calendar',
	daysOfMonth: 'calendar',
	weeksOfMonth: 'calendar',
	weeksOfMonthByDay: 'calendar',
	weeksOfYear: 'calendar',
	monthsOfYear: 'calendar',
};

// a dictionary of plural and singular measures

// eslint-disable-next-line no-unused-vars
const measures = {
	days: 'day',
	weeks: 'week',
	months: 'month',
	years: 'year',
	daysOfWeek: 'dayOfWeek',
	daysOfMonth: 'dayOfMonth',
	weeksOfMonth: 'weekOfMonth',
	weeksOfMonthByDay: 'weekOfMonthByDay',
	weeksOfYear: 'weekOfYear',
	monthsOfYear: 'monthOfYear',
};

// Dictionary of unit types based on measures
const unitTypes = {
	daysOfMonth: 'date',
	daysOfWeek: 'day',
	weeksOfMonth: 'monthWeek',
	weeksOfMonthByDay: 'monthWeekByDay',
	weeksOfYear: 'week',
	monthsOfYear: 'month',
};

// Dictionary of ranges based on measures
const ranges = {
	daysOfMonth: {low: 1, high: 31},
	daysOfWeek: {low: 0, high: 6},
	weeksOfMonth: {low: 0, high: 4},
	weeksOfMonthByDay: {low: 0, high: 4},
	weeksOfYear: {low: 0, high: 52},
	monthsOfYear: {low: 0, high: 11},
};

const _convertWorkdaysObjToDaysArray = (workdays = {}) => {
	if (!workdays || _.isEmpty(workdays)) return [0, 1, 2, 3, 4, 5, 6];
	if (Array.isArray(workdays)) return workdays;
	const MAP = {
		sunday: 0,
		monday: 1,
		tuesday: 2,
		wednesday: 3,
		thursday: 4,
		friday: 5,
		saturday: 6,
	};
	return Object.entries(workdays).reduce((acc, [day, active]) => {
		if (active) {
			return [...acc, MAP[day]];
		}
		return acc;
	}, []);
};

/**
 * Determine if moment instance is on a holiday (if holiday calendar is set)
 * @param calendar
 * @returns {boolean}
 */
moment.fn.isHoliday = function(calendar = this.calendar) {
	if (calendar) {
		const holidays = new Holidays(
			holidayCalendarsUtil.createCustomData(calendar.holidays),
			'CUSTOM',
			undefined,
			undefined,
			{types: ['public']}
		);
		const isHolidayResult = holidays.isHoliday(moment(this.format('YYYY-MM-DD')).toDate());
		if (isHolidayResult) {
			if (isHolidayResult.disabled === true) {
				return false;
			}
			return isHolidayResult;
		}
	}
	return false;
};
/**
 * Determine if moment instance falls on a workday (if holiday calendar is set)
 * @param workdays
 * @returns {boolean}
 */
moment.fn.isWorkday = function(workdays = this.workdays) {
	// eslint-disable-next-line no-use-before-define
	return _convertWorkdaysObjToDaysArray(workdays).includes(this.day());
};

/**
 * Determine if moment instance falls on a business day (if holiday calendar is set)
 * @returns {boolean}
 */
moment.fn.isBusinessDay = function() {
	return !this.isHoliday(this.holidays) && !!this.isWorkday();
};

/**
 * Extends moment objects to have a snapToBusinessDay method
 * @param offset
 */
moment.fn.snapToBusinessDay = function(offset) {
	let iterations = Math.abs(offset) || 0,
		safeLimit = 69;
	iterations && this.snapToBusinessDay();
	while ((safeLimit-- && !this.isBusinessDay()) || iterations--) {
		this.add(Number(offset ? offset >= 0 : this.onHoliday === 'next') || -1, 'days');
	}
};

moment.recur = function(start, end) {
	// If we have an object, use it as a set of options
	if (start === Object(start) && !moment.isMoment(start)) {
		return new Recur(start);
	}

	// else, use the values passed
	return new Recur({start: start, end: end});
};

// Recur can also be created the following ways:
// moment().recur()
// moment().recur(options)
// moment().recur(start, end)
// moment(start).recur(end)
// moment().recur(end)
moment.fn.recur = function(start, end) {
	// If we have an object, use it as a set of options
	if (start === Object(start) && !moment.isMoment(start)) {
		// if we have no start date, use the moment
		if (typeof start.start === 'undefined') {
			start.start = this;
		}

		return new Recur(start);
	}

	// if there is no end value, use the start value as the end
	if (!end) {
		end = start;
		start = undefined;
	}

	// use the moment for the start value
	if (!start) {
		start = this;
	}

	return new Recur({start: start, end: end, moment: this});
};

// Plugin for calculating the week of the month of a date
moment.fn.monthWeek = function() {
	// First day of the first week of the month
	const week0 = this.clone()
		.startOf('month')
		.startOf('week');

	// First day of week
	const day0 = this.clone().startOf('week');

	return day0.diff(week0, 'weeks');
};

// Plugin for calculating the occurrence of the day of the week in the month.
// Similar to `moment().monthWeek()`, the return value is zero-indexed.
// A return value of 2 means the date is the 3rd occurrence of that day
// of the week in the month.
moment.fn.monthWeekByDay = function() {
	return Math.floor((this.date() - 1) / 7);
};

// Plugin for removing all time information from a given date
moment.fn.dateOnly = function() {
	if (this.tz && typeof moment.tz == 'function') {
		// return moment.tz(this.format('YYYY-MM-DD[T]00:00:00.00Z'), 'UTC');
		return moment.tz(this.format('YYYY-MM-DD'), 'UTC');
		// return moment.tz(this.format('YYYY-MM-DD'), 'UTC');
	} else {
		return this.hours(0)
			.minutes(0)
			.seconds(0)
			.milliseconds(0)
			.add(this.utcOffset(), 'minute')
			.utcOffset(0);
	}
};

// Interval object for creating and matching interval-based rules
const Interval = {
	create(units, measure) {
		for (const unit in units) {
			if (Object.prototype.hasOwnProperty.call(units, unit)) {
				if (parseInt(unit, 10) <= 0) {
					throw Error('Intervals must be greater than zero');
				}
			}
		}
		return {
			measure: measure.toLowerCase(),
			units: units,
		};
	},
	match(type, units, start, date) {
		// Get the difference between the start date and the provided date,
		// using the required measure based on the type of rule'
		let diff;
		if (date.isBefore(start)) {
			diff = start.diff(date, type, true);
		} else {
			diff = date.diff(start, type, true);
		}
		if (type == 'days') {
			// if we are dealing with days, we deal with whole days only.
			diff = parseInt(diff);
		}
		if (type === 'months') {
			// account for last days of months with less days than start (eg 31st vs 30th)
			const targetDay = start.date();
			const currentDay = date.date();
			const dateDays = date.daysInMonth();

			// console.log(`start: ${start.format('YYYY-MM-DD')}, test: ${date.format('YYYY-MM-DD')}, diff: ${diff}, daysInMonth: ${dateDays}`);

			if (targetDay > currentDay && currentDay === dateDays) {
				// Must round so that it goes to the closest matching number
				// parseInt can return 0 from 0.97
				// 0 % unit will always equal 0 and trigger a match
				diff = Math.round(diff);
			}
		}

		// Check to see if any of the units provided match the date
		for (let unit in units) {
			if (Object.prototype.hasOwnProperty.call(units, unit)) {
				unit = parseInt(unit, 10);

				// If the units divide evenly into the difference, we have a match
				if (diff % unit === 0) {
					return true;
				}
			}
		}

		return false;
	},
};

// Calendar object for creating and matching calendar-based rules
const Calendar = {
	/**
	 * check the range of calendar rules
	 * @param low
	 * @param high
	 * @param list
	 */
	checkRange: function(low, high, list) {
		list.forEach(v => {
			if (v < low || v > high) {
				throw Error('Value should be in range ' + low + ' to ' + high);
			}
		});
	},

	/**
	 * convert day and month names to numbers
	 * @param list
	 * @param nameType
	 * @returns {{}}
	 */
	namesToNumbers(list, nameType) {
		let unit, unitInt, unitNum;
		const newList = {};

		for (unit in list) {
			if (Object.prototype.hasOwnProperty.call(list, unit)) {
				unitInt = parseInt(unit, 10);

				if (isNaN(unitInt)) {
					unitInt = unit;
				}

				unitNum = moment()
					.set(nameType, unitInt)
					.get(nameType);
				newList[unitNum] = list[unit];
			}
		}
		return newList;
	},
	/**
	 * Create Calendar Rule
	 * @param list
	 * @param measure
	 * @returns {{measure: *, units: *}}
	 */
	create(list, measure) {
		const keys = [];

		// Convert day/month names to numbers, if needed
		if (measure === 'daysOfWeek') {
			list = this.namesToNumbers(list, 'days');
		}

		if (measure === 'monthsOfYear') {
			list = this.namesToNumbers(list, 'months');
		}

		for (const key in list) if (Object.prototype.hasOwnProperty.call(list, key)) keys.push(key);

		// Make sure the listed units are in the measure's range
		this.checkRange(ranges[measure].low, ranges[measure].high, keys);

		return {
			measure: measure,
			units: list,
		};
	},

	/**
	 * Match Calendar Rule
	 * @param measure
	 * @param list
	 * @param date
	 * @returns {boolean}
	 */
	match(measure, list, date) {
		// Get the unit type (i.e. date, day, week, monthWeek, weeks, months)
		const unitType = unitTypes[measure];

		// Get the unit based on the required measure of the date
		let unit = date[unitType]();

		// If the unit is in our list, return true, else return false
		if (list[unit]) {
			return true;
		}

		// match on end of month days
		if (
			unitType === 'date' &&
			unit ==
				date
					.add(1, 'months')
					.date(0)
					.format('D') &&
			unit < 31
		) {
			while (unit <= 31) {
				if (list[unit]) {
					return true;
				}
				unit++;
			}
		}

		return false;
	},
};

class Recur {
	constructor(options) {
		if (options.start) {
			this.start = moment(options.start).dateOnly();
		}

		if (options.end) {
			this.end = moment(options.end).dateOnly();
		}

		if (options.first) {
			this.first = moment(options.first).dateOnly();
		}

		if (options.last) {
			this.last = moment(options.last).dateOnly();
		}

		if (options.workdays) {
			this.workdays = options.workdays;
		}

		this.onHoliday = 'next';

		if (options.onHoliday) {
			this.onHoliday = options.onHoliday;
		}

		if (options.holidays) {
			if (options.holidays instanceof Holidays) {
				this.holidays = options.holidays;
			} else if (_.isPlainObject(options.holidays)) {
				this.holidays = new Holidays(holidayCalendarsUtil.createCustomData(options.holidays), 'CUSTOM');
			}
		}

		this.maxOccurrences = Infinity;
		if (parseInt(options.maxOccurrences) >= 1) {
			this.maxOccurrences = parseInt(options.maxOccurrences);
		}

		if (typeof options.static === 'boolean') {
			this.static = !!options.static;
		}

		this.onHoliday = options.onHoliday || 'next';

		// Our list of rules, all of which must match
		this.rules = options.rules || [];

		// Our list of exceptions. Match always fails on these dates.
		this.exceptions = [];
		const exceptions = options.exceptions || [];
		for (let i = 0; i < exceptions.length; i++) {
			this.except(exceptions[i]);
		}

		// Temporary units integer, array, or object. Does not get imported/exported.
		this.units = null;

		// Temporary measure type. Does not get imported/exported.
		this.measure = null;

		// Temporary from date for next/previous. Does not get imported/exported.
		this.from = null;

		// Temporary until date for next/previous. Does not get imported/exported.
		this.until = null;

		return this;
	}
	/**
	 * Attempts to set a rule
	 * @returns {Recur}
	 * @private
	 */
	_trigger() {
		let rule;
		const ruleType = ruleTypes[this.measure];

		// eslint-disable-next-line no-use-before-define
		if (!(this instanceof Recur)) {
			throw Error('Private method trigger() was called directly or not called as instance of Recur!');
		}

		// Make sure units and measure is defined and not null
		if (typeof this.units === 'undefined' || this.units === null || !this.measure) {
			return this;
		}

		// Error if we don't have a valid ruleType
		if (ruleType !== 'calendar' && ruleType !== 'interval') {
			throw Error('Invalid measure provided: ' + this.measure);
		}

		// Create the rule
		if (ruleType === 'interval') {
			if (!this.start) {
				throw Error('Must have a start date set to set an interval!');
			}

			rule = Interval.create(this.units, this.measure);
		}

		if (ruleType === 'calendar') {
			rule = Calendar.create(this.units, this.measure);
		}

		// Remove the temporary rule data
		this.units = null;
		this.measure = null;

		if (rule.measure === 'weeksOfMonthByDay' && !this.hasRule('daysOfWeek')) {
			throw Error('weeksOfMonthByDay must be combined with daysOfWeek');
		}

		// Remove existing rule based on measure
		for (let i = 0; i < this.rules.length; i++) {
			if (this.rules[i].measure === rule.measure) {
				this.rules.splice(i, 1);
			}
		}

		this.rules.push(rule);
		return this;
	}

	/**
	 * @param {moment} date
	 * @param {string} format
	 * @returns {moment}
	 * @private
	 */
	_copy(date, format) {
		if (format) {
			return date.format(format);
		}
		return date.clone();
	}

	// Private method to get next, previous or all occurrences
	// eslint-disable-next-line complexity
	_getOccurrences(num, format, type) {
		const dates = [];
		if (!(this instanceof Recur)) {
			throw Error('Private method trigger() was called directly or not called as instance of Recur!');
		}

		if (this.static) {
			if (!this.start) {
				throw Error('Cannot return static result without start date');
			}
			return [this._copy(this.start, format)];
		}

		if (!this.start && !this.from) {
			throw Error('Cannot get occurrences without start or from date.');
		}

		if (type === 'all' && !this.end) {
			throw Error('Cannot get all occurrences without an end date.');
		}

		if (!!this.end && this.start > this.end) {
			throw Error('Start date cannot be later than end date.');
		}

		// Return empty set if the caller doesn't want any for next/prev
		if (type !== 'all' && !(num > 0)) {
			return dates;
		}

		// Start from the from date, or first date if from date is not set, or finally the start date if neither is set.
		let currentDate = (this.from || this.first || this.start).clone();

		if (type === 'all') {
			if (this.first && currentDate.isSame(this.first)) {
				dates.push(this._copy(currentDate, format));
				currentDate = (this.from || this.start).clone();
			}
			if (this.matches(currentDate, false)) {
				// TODO: should this match start date in case of monthly end? || currentDate.isSame(this.start)
				dates.push(this._copy(currentDate, format));
			}
		}

		// protect from infinite loops, maxing out at ~10 years from the start date
		const MAX_ITERATIONS = 3650;
		let iterations = 0;

		// Get the next N dates, if num is null then infinite
		while (
			dates.length < (num === null ? (this.maxOccurrences === 1 ? 0 : this.maxOccurrences) : num) &&
			iterations < MAX_ITERATIONS
		) {
			iterations++;
			if (type === 'next' || type === 'all') {
				currentDate.add(1, 'day');
			} else {
				currentDate.subtract(1, 'day');
			}

			// Don't match outside the date if generating all dates within start/end
			let ignoreStartEnd = type !== 'all';

			if (this.first && this.start && this.start >= currentDate) {
				ignoreStartEnd = false;
			} else if (this.last && this.end && this.end <= currentDate) {
				ignoreStartEnd = false;
			}
			if (this.matches(currentDate, ignoreStartEnd)) {
				dates.push(this._copy(currentDate, format));
			}
			if (type === 'all' && currentDate >= this.end) {
				break;
			}
		}
		if (type === 'all' && this.last) {
			dates.push(this._copy(this.last, format));
		}

		return dates;
	}

	/**
	 * see if a date is within range of start/end
	 * @param start
	 * @param end
	 * @param date
	 * @returns {boolean}
	 * @private
	 */
	_inRange(start, end, date) {
		if (start && date.isBefore(start)) {
			return false;
		}
		return !(end && date.isAfter(end));
	}

	/**
	 * turn units into objects
	 * @param units
	 * @returns {{}}
	 * @private
	 */
	_unitsToObject(units) {
		let list = {};

		if (Object.prototype.toString.call(units) == '[object Array]') {
			units.forEach(v => {
				list[v] = true;
			});
		} else if (units === Object(units)) {
			list = units;
		} else if (
			Object.prototype.toString.call(units) == '[object Number]' ||
			Object.prototype.toString.call(units) == '[object String]'
		) {
			list[units] = true;
		} else {
			throw Error('Provide an array, object, string or number when passing units!');
		}

		return list;
	}

	// Private function to check if a date is an exception
	_isException(exceptions, date) {
		for (let i = 0, len = exceptions.length; i < len; i++) {
			if (moment(exceptions[i]).isSame(date)) {
				return true;
			}
		}
		return false;
	}

	// Private function to pluralize measure names for use with dictionaries.
	_pluralize(measure) {
		switch (measure) {
			case 'day':
				return 'days';

			case 'week':
				return 'weeks';

			case 'month':
				return 'months';

			case 'year':
				return 'years';

			case 'dayOfWeek':
				return 'daysOfWeek';

			case 'dayOfMonth':
				return 'daysOfMonth';

			case 'weekOfMonth':
				return 'weeksOfMonth';

			case 'weekOfMonthByDay':
				return 'weeksOfMonthByDay';

			case 'weekOfYear':
				return 'weeksOfYear';

			case 'monthOfYear':
				return 'monthsOfYear';

			default:
				return measure;
		}
	}

	/**
	 * See if all rules match
	 * @param rules
	 * @param date
	 * @param start
	 * @returns {boolean}
	 * @private
	 */
	_matchAllRules(rules, date, start) {
		let i, len, rule, type;

		for (i = 0, len = rules.length; i < len; i++) {
			rule = rules[i];
			type = ruleTypes[rule.measure];
			// console.log('_matchAllRules', rule, type);

			if (type === 'interval') {
				if (!Interval.match(rule.measure, rule.units, start, date)) {
					return false;
				}
			} else if (type === 'calendar') {
				if (!Calendar.match(rule.measure, rule.units, date)) {
					return false;
				}
			} else {
				return false;
			}
		}

		return true;
	}

	/**
	 * Create measure functions
	 * @param measure
	 * @returns {function(*=): this}
	 * @private
	 */
	_createMeasure(measure) {
		return function(units) {
			// noinspection JSPotentiallyInvalidUsageOfClassThis
			this.every.call(this, units, measure);
			return this;
		};
	}

	// Check if recurrence only returns a single value
	isStatic(isStatic) {
		if (typeof isStatic === 'boolean') {
			this.static = isStatic;
			return this;
		}

		return !!this.static;
	}

	// Get/Set start date
	startDate(date) {
		if (date === null) {
			this.start = null;
			return this;
		}

		if (date) {
			this.start = moment(date).dateOnly();
			return this;
		}

		return this.start;
	}
	// Get/Set end date
	endDate(date) {
		if (date === null) {
			this.end = null;
			return this;
		}

		if (date) {
			this.end = moment(date).dateOnly();
			return this;
		}

		return this.end;
	}

	// Get/Set end after
	endAfter(num) {
		if (num === null) {
			this.maxOccurrences = Infinity;
			return this;
		}

		if (parseInt(num) >= 1) {
			this.maxOccurrences = parseInt(num);
			if (this.maxOccurrences === 1) {
				this.endDate(
					this.startDate()
						.clone()
						.format('YYYY-MM-DD')
				);
			} else {
				this.endDate(this._getOccurrences(num - 1, 'YYYY-MM-DD', 'next').pop());
			}
			return this;
		}

		return this.maxOccurrences;
	}

	// Get/Set a temporary from date
	fromDate(date) {
		if (date === null) {
			this.from = null;
			return this;
		}

		if (date) {
			this.from = moment(date).dateOnly();
			return this;
		}

		return this.from;
	}

	untilDate(date) {
		if (date === null) {
			this.until = null;
			return this;
		}

		if (date) {
			this.until = moment(date).dateOnly();
			return this;
		}

		return this.until;
	}

	// Get/Set an additional start date outside of the recurrence range.
	firstDate(date) {
		if (date === null) {
			this.first = null;
			return this;
		}

		if (date) {
			this.first = moment(date).dateOnly();
			return this;
		}

		return this.first;
	}

	// Get/Set an additional end date outside of the recurrence range.
	lastDate(date) {
		if (date === null) {
			this.last = null;
			return this;
		}

		if (date) {
			this.last = moment(date).dateOnly();
			return this;
		}

		return this.last;
	}

	// Export the settings, rules, extra dates and exceptions of this recurring date
	save() {
		const data = {};

		if (this.first && moment(this.first).isValid()) {
			data.first = this.first.format();
		}

		if (this.start && moment(this.start).isValid()) {
			data.start = this.start.format();
		}

		if (this.end && moment(this.end).isValid()) {
			data.end = this.end.format();
		}

		if (this.last && moment(this.last).isValid()) {
			data.last = this.last.format();
		}

		if (this.holidays) {
			data.holidays = this.holidays;
			data.workdays = this.workdays;
			data.onHoliday = this.onHoliday;
		}

		data.static = !!this.static;

		data.exceptions = [];
		for (let i = 0, len = this.exceptions.length; i < len; i++) {
			data.exceptions.push(this.exceptions[i].format('L'));
		}

		data.rules = this.rules;

		return data;
	}

	// Return boolean value based on whether this date repeats (has rules or not)
	repeats() {
		return this.rules.length > 0;
	}

	// Set the units and, optionally, the measure
	every(units, measure) {
		if (typeof units !== 'undefined' && units !== null) {
			this.units = this._unitsToObject(units);
		}

		if (typeof measure !== 'undefined' && measure !== null) {
			this.measure = this._pluralize(measure);
		}

		return this._trigger();
	}

	// Creates an exception date to prevent matches, even if rules match
	except(date) {
		date = moment(date).dateOnly();
		this.exceptions.push(date);
		return this;
	}

	// Forgets rules (by passing measure) and exceptions (by passing date)
	forget(dateOrRule) {
		let i, len;
		let whatMoment = moment(dateOrRule);

		// If valid date, try to remove it from exceptions
		if (whatMoment.isValid()) {
			whatMoment = whatMoment.dateOnly(); // change to date only for perfect comparison
			for (i = 0, len = this.exceptions.length; i < len; i++) {
				if (whatMoment.isSame(this.exceptions[i])) {
					this.exceptions.splice(i, 1);
					return this;
				}
			}

			return this;
		}

		// Otherwise, try to remove it from the rules
		for (i = 0, len = this.rules.length; i < len; i++) {
			if (this.rules[i].measure === this._pluralize(dateOrRule)) {
				this.rules.splice(i, 1);
			}
		}
	}

	// Checks if a rule has been set on the chain
	hasRule(measure) {
		let i, len;
		for (i = 0, len = this.rules.length; i < len; i++) {
			if (this.rules[i].measure === this._pluralize(measure)) {
				return true;
			}
		}
		return false;
	}

	// Attempts to match a date to the rules
	matches(dateToMatch, ignoreStartEnd) {
		const date = moment(dateToMatch).dateOnly();

		if (!date.isValid()) {
			throw Error('Invalid date supplied to match method: ' + dateToMatch);
		}

		//TODO: should date.isSame(this.start) || date.isSame(this.end) be added? Does a full schedule for monthly end include the end date if it's not a month end?

		if ((this.first && date.isSame(this.first)) || (this.last && date.isSame(this.last))) {
			return true;
		}

		if (!ignoreStartEnd && !this._inRange(this.start, this.end, date)) {
			return false;
		}

		if (this._isException(this.exceptions, date)) {
			return false;
		}

		if (!this._matchAllRules(this.rules, date, this.start)) {
			return false;
		}

		// if we passed everything above, then this date matches
		return true;
	}

	// Get next N occurrences
	next(num, format) {
		return this._getOccurrences(num, format, 'next');
	}

	// Get previous N occurrences
	previous(num, format) {
		return this._getOccurrences(num, format, 'previous');
	}

	// Get all occurrences between start and end date
	all(format) {
		return this._getOccurrences(this.maxOccurrences || null, format, 'all');
	}
}

// Create the measure functions (days(), months(), daysOfMonth(), monthsOfYear(), etc.)
for (const measure in measures) {
	if (Object.prototype.hasOwnProperty.call(ruleTypes, measure)) {
		Recur.prototype[measure] = Recur.prototype[measures[measure]] = Recur.prototype._createMeasure.call(this, measure);
	}
}

module.exports = moment;
