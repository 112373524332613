export const moveInArray = (array, fromIndex, toIndex) => {
	const newArray = [...array];
	const fromItem = newArray[fromIndex];
	newArray.splice(fromIndex, 1);
	newArray.splice(toIndex, 0, fromItem);
	return newArray;
};

export const updateInArray = (array, item, index) => {
	return [...array.slice(0, index), item, ...array.slice(index + 1)];
};

export const removeFromArray = (array, index) => {
	return [...array.slice(0, index), ...array.slice(index + 1)];
};
