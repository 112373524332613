import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import KiDatePicker from 'components/KiDatePicker';
import KiButton from 'components/KiButton';

Scenarios.propTypes = {
	models: PropTypes.array.isRequired,
	onModelChange: PropTypes.func.isRequired,
	modelValue: PropTypes.object.isRequired,
	isLoadingDateOptions: PropTypes.bool.isRequired,
	datasetDates: PropTypes.array.isRequired,
	onDebtDateChange: PropTypes.func.isRequired,
	debtDateValue: PropTypes.object.isRequired,
	onAssetDateChange: PropTypes.func.isRequired,
	assetDateValue: PropTypes.object.isRequired,
	onSnapshotDateChange: PropTypes.func.isRequired,
	snapshotDateValue: PropTypes.string.isRequired,
	generateDisabled: PropTypes.bool.isRequired,
	onGenerate: PropTypes.func.isRequired,
};

function Scenarios({
	models,
	onModelChange,
	modelValue,
	isLoadingDateOptions,
	datasetDates,
	onDebtDateChange,
	debtDateValue,
	onAssetDateChange,
	assetDateValue,
	onSnapshotDateChange,
	snapshotDateValue,
	generateDisabled,
	onGenerate,
}) {
	return (
		<div className="model-scenerio-filters">
			<div className="debt-explorer-params">
				<section>
					<div className="param-label">Model</div>
					<Select
						classNamePrefix="aut-select"
						options={models}
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
						onChange={onModelChange}
						value={modelValue}
					/>
				</section>
				<section>
					<div className="param-label">Debt Date</div>
					<Select
						placeholder={isLoadingDateOptions ? 'Loading Dates...' : 'Select a date'}
						options={datasetDates}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.groupId}
						value={debtDateValue}
						onChange={onDebtDateChange}
					/>
				</section>
				<section>
					<div className="param-label">Asset Date</div>
					<Select
						placeholder={isLoadingDateOptions ? 'Loading Dates...' : 'Select a date'}
						options={datasetDates}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.groupId}
						value={assetDateValue}
						onChange={onAssetDateChange}
					/>
				</section>

				<section>
					<div>Statement Date</div>
					<KiDatePicker value={snapshotDateValue} onChange={onSnapshotDateChange} />
				</section>
				<section>
					<KiButton raised primary disabled={generateDisabled} onClick={onGenerate}>
						Generate
					</KiButton>
				</section>
			</div>
		</div>
	);
}

export default Scenarios;
