// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import _ from 'lodash';
// Project imports
// Website imports
import KiModal2 from 'components/KiModal2';
import FilterList from 'components/FlyoutManageViews/components/ViewForms/FilterList.js';
import {datasetFiltersApi} from 'api';
// Local imports
import styles from './filtersModalStyles.theme.scss';

function FiltersModal({isActive, setModalActive, scenarioFilters, onSaveFunc}) {
	// Browser State
	const {datasetId} = useParams();

	// Local State
	const [modalFilters, setModalFilters] = useState(scenarioFilters || []);
	const [allFilters, setAllFilters] = useState([]);
	const [formHasChanges, setFormHasChanges] = useState(false);

	const fetchFilters = async datasetId => {
		const filterList = await datasetFiltersApi.fetchMany(datasetId);
		setAllFilters(filterList);
	};
	const scenarioFiltersToModalFilters = () => {
		const hydratedFilters = scenarioFilters.map(sFilter => {
			return allFilters.find(element => `${element._id}` === sFilter._id);
		});
		setModalFilters(hydratedFilters);
	};

	const handleFormChanges = () => {
		setFormHasChanges(true);
	};

	const handleUpdate = newFilters => {
		setModalFilters(newFilters);
	};

	useEffect(
		() => {
			if (datasetId) {
				fetchFilters(datasetId);
			}
		},
		[isActive]
	);

	useEffect(
		() => {
			if (!modalFilters) {
				scenarioFiltersToModalFilters();
			}
		},
		[allFilters]
	);

	const onSaveModal = () => {
		const appliedFilters = _.cloneDeep(modalFilters);
		const filtersToSave = appliedFilters.map(filter => ({_id: filter._id, name: filter.name}));
		onSaveFunc(filtersToSave);
		setModalActive(false);
	};

	return (
		<KiModal2
			active={isActive}
			actions={[
				{
					label: 'Cancel',
					onClick: () => setModalActive(false),
				},
				{
					label: 'Save Filters',
					disabled: formHasChanges === false,
					onClick: () => onSaveModal(),
				},
			]}
			onClose={() => setModalActive(false)}
			header={`${'Select Filters for Scenario'}`}
		>
			<section className={styles.root}>
				<FilterList
					filters={modalFilters}
					allFilters={allFilters}
					updateCallBack={handleUpdate}
					setFormHasChanges={handleFormChanges}
				/>
			</section>
		</KiModal2>
	);
}

FiltersModal.propTypes = {
	isActive: PropTypes.bool,
	setModalActive: PropTypes.func,
	scenarioFilters: PropTypes.array,
	onSaveFunc: PropTypes.func,
};

FiltersModal.defaultProps = {
	isActive: false,
	allFilters: [],
};

export default FiltersModal;
