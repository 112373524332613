import {dataBookmarksApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
import * as actionTypes from './actionTypes';
import exploreRequestBuilder from 'ki-common/utils/exploreRequestBuilder';

const parseBandsNumeric = bands => {
	// BE wants numbers not strings
	const numericBands = Object.assign({}, bands);
	switch (bands.type) {
		case 'custom-even':
			delete numericBands.steps; // BE cannot handle empty values here
			numericBands.step = Number(numericBands.step);
			break;
		case 'custom-odd':
			delete numericBands.step; // BE cannot handle empty values here
			numericBands.steps = numericBands.steps.map(step => {
				return {
					start: Number(step.start),
					end: Number(step.end),
					step: Number(step.step),
				};
			});
			break;
		default:
			// BE cannot handle empty values here
			delete numericBands.step;
			delete numericBands.steps;
	}
	return numericBands;
};

const transformExplorerViewToViewForm = explorerView => {
	// this takes an explorer formatted view and flattens it
	// TODO one day we need to sync up explorer redux to look like this
	const view = Object.assign(
		{},
		{
			// housekeeping
			formErrors: {},
			// root
			createDate: explorerView.createDate,
			createdBy: explorerView.createdBy,
			datasetId: explorerView.datasetId,
			isDefault: explorerView.isDefault,
			isFavorite: explorerView.isFavorite,
			isGadget: explorerView.isGadget,
			dataTransferViewId: explorerView.dataTransferViewId,
			name: explorerView.name,
			tags: explorerView.tags,
			_id: explorerView._id,
			// root.explorerData
			breadcrumbs: explorerView.explorerData.breadcrumbs || [],
			bucket: explorerView.explorerData.bucket,
			columns: explorerView.explorerData.columns,
			filters: explorerView.explorerData.filters,
			pageNumber: explorerView.explorerData.pageNumber,
			groupBy: explorerView.explorerData.groupBy || '',
			maxRecords: explorerView.explorerData.maxRecords || null,
			statementDate: explorerView.explorerData.statementDate,
			snapshotType: explorerView.explorerData.snapshotType,
			dateContext: explorerView.explorerData.dateContext,
			isFixedDate: explorerView.explorerData.isFixedDate,
			//
			sortCalculation: explorerView.explorerData.sortCalculation,
			sortColumn: explorerView.explorerData.sortColumn,
			sortOrder: explorerView.explorerData.sortOrder,
			tableType: explorerView.explorerData.tableType,
			bands: explorerView.explorerData.bands || {type: 'default'},
			// root.explorerData.quickFilters
			fundingVehicleId: explorerView.explorerData.quickFilters.fundingVehicleId,
			hypoFundingVehicleId: explorerView.explorerData.quickFilters.hypoFundingVehicleId,
			hypoPoolId: explorerView.explorerData.quickFilters.hypoPoolId,
			poolId: explorerView.explorerData.quickFilters.poolId,
			scenarioId: explorerView.explorerData.quickFilters.scenarioId || 'assetSnapshot',
			scenarioType: explorerView.explorerData.quickFilters.scenarioType,
			startInclusive: explorerView.explorerData.startInclusive,
			granularity: explorerView.explorerData.granularity,
			dateRange: explorerView.explorerData.dateRange,
		}
	);
	if (explorerView.explorerData.timeseries) {
		// root.explorerData.timeseries
		view.timeseriesColumn = explorerView.explorerData.timeseries.column; // view.explorerData.columns[1] in BE request
		view.timeseriesPeriod = explorerView.explorerData.timeseries.period; // view.timeSeriesGranularity in BE request
		view.timeseriesRange = explorerView.explorerData.timeseries.range; // view.timeSeriesPeriodsDisplayed in BE request
	}
	return view;
};

const transformViewFormToExplorerView = viewForm => {
	// this takes view form and expands it to what mongo / explorer redux want
	const view = Object.assign(
		{},
		{
			createDate: viewForm.createDate,
			createdBy: viewForm.createdBy,
			datasetId: viewForm.datasetId,
			isDefault: viewForm.isDefault,
			isFavorite: viewForm.isFavorite,
			isGadget: viewForm.isGadget,
			dataTransferViewId: viewForm.dataTransferViewId,
			name: viewForm.name,
			tags: viewForm.tags,
			_id: viewForm._id,
			explorerData: {
				datasetId: viewForm.datasetId,
				breadcrumbs: viewForm.breadcrumbs || [],
				bucket: viewForm.bucket,
				columns: viewForm.columns,
				filters: viewForm.filters,
				pageNumber: viewForm.pageNumber,
				groupBy: viewForm.groupBy,
				maxRecords: viewForm.maxRecords,
				statementDate: viewForm.statementDate,
				snapshotType: viewForm.snapshotType,
				dateContext: viewForm.dateContext,
				isFixedDate: viewForm.isFixedDate,
				sortCalculation: viewForm.sortCalculation,
				sortColumn: viewForm.sortColumn,
				sortOrder: viewForm.sortOrder,
				tableType: viewForm.tableType,
				quickFilters: {
					fundingVehicleId: viewForm.fundingVehicleId,
					hypoFundingVehicleId: viewForm.hypoFundingVehicleId,
					hypoPoolId: viewForm.hypoPoolId,
					poolId: viewForm.poolId,
					scenarioId: viewForm.scenarioId || 'assetSnapshot',
					scenarioType: viewForm.scenarioType,
				},
				dateRange: viewForm.dateRange,
				bands: parseBandsNumeric(viewForm.bands),
				startInclusive: viewForm.startInclusive,
				granularity: viewForm.granularity,
			},
		}
	);
	if (viewForm.timeseriesColumn) {
		view.explorerData.timeseries = {
			column: viewForm.timeseriesColumn, // view.explorerData.columns[1] in BE request
			period: viewForm.timeseriesPeriod, // view.timeSeriesGranularity in BE request
			range: viewForm.timeseriesRange, // view.timeSeriesPeriodsDisplayed in BE request
		};
	}
	if (viewForm.bands.type === 'custom-even') {
		view.explorerData.granularity = viewForm.bands.step; // BE needs this duplicated here
	}
	return view;
};

const initializeView = dataset => (dispatch, getState) => {
	const {dataExplorer} = getState();
	dataBookmarksApi.generateBookmark(dataset).then(dataset => {
		const newView = transformExplorerViewToViewForm(
			dataset,
			dataExplorer.statementDate,
			dataExplorer.dateContext,
			dataExplorer.isFixedDate
		);
		newView.name = 'My New View';
		newView.isDefault = false;
		dispatch({type: actionTypes.VIEW_LOAD, view: newView});
	});
};

const loadView = view => dispatch => {
	dispatch({type: actionTypes.VIEW_LOAD, view: transformExplorerViewToViewForm(view)});
};

const saveView = () => (dispatch, getState) => {
	const {viewForm} = getState();
	return dataBookmarksApi
		.upsertBookmark(transformViewFormToExplorerView(viewForm))
		.then(result => {
			dispatch(showSnackbar(`Saved view "${viewForm.name}" successfully`));
			return result;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

const getExplorerView = () => (dispatch, getState) => {
	const {viewForm} = getState();
	return transformViewFormToExplorerView(viewForm);
};

const timeSeriesBreadcrumb = {
	id: 1,
	type: 'timeSeries',
	currValue: 1,
	options: [],
};

const addFormError = (name, value) => dispatch => {
	dispatch({type: actionTypes.VIEW_ERROR_SET, name: name, value: value});
};

const removeFormError = name => dispatch => {
	dispatch({type: actionTypes.VIEW_ERROR_REMOVE, name: name});
};

const updateCohortColumn = cohort => dispatch => {
	dispatch({type: actionTypes.VIEW_COHORTCOLUMN_SET, cohort: cohort});
};

const updateColumn = (index, column) => dispatch => {
	dispatch({type: actionTypes.VIEW_COLUMN_SET, index: index, column: column});
};

const updateCreateDate = createDate => dispatch => {
	dispatch({type: actionTypes.VIEW_CREATEDATE_SET, createDate: createDate});
};

const updateCreatedBy = createdBy => dispatch => {
	dispatch({type: actionTypes.VIEW_CREATEDBY_SET, createdBy: createdBy});
};

const updateDatasetId = datasetId => dispatch => {
	dispatch({type: actionTypes.VIEW_DATASETID_SET, datasetId: datasetId});
};

const updateName = name => dispatch => {
	dispatch({type: actionTypes.VIEW_NAME_SET, name: name});
};

const loadBreadcrumbs = breadcrumbs => dispatch => {
	dispatch({type: actionTypes.VIEW_BREADCRUMBS_LOAD, breadcrumbs: breadcrumbs});
};

const setBreadcrumb = breadcrumb => dispatch => {
	dispatch({type: actionTypes.VIEW_BREADCRUMBS_SET, breadcrumb: breadcrumb});
};

const clearBreadcrumbs = () => dispatch => {
	dispatch({type: actionTypes.VIEW_BREADCRUMBS_CLEAR});
};

const updateBreadcrumb = breadcrumb => dispatch => {
	dispatch({type: actionTypes.VIEW_BREADCRUMBS_UPDATE, breadcrumb: breadcrumb});
};

const deleteBreadcrumb = breadcrumb => dispatch => {
	dispatch({type: actionTypes.VIEW_BREADCRUMBS_DELETE, id: breadcrumb.id});
};

const updateTableType = newTableType => (dispatch, getState) => {
	const {tableType} = getState();
	if (newTableType === 'timeSeries') {
		dispatch(loadBreadcrumbs([timeSeriesBreadcrumb]));
	} else if (newTableType === 'cohort' && !tableType === 'cohort') {
		dispatch(clearBreadcrumbs());
	}
	dispatch({type: actionTypes.VIEW_TABLETYPE_SET, tableType: newTableType});
};

const updateIsFavorite = isFavorite => dispatch => {
	dispatch({type: actionTypes.VIEW_ISFAVORITE_SET, isFavorite: isFavorite});
};

const updateIsDefault = isDefault => dispatch => {
	dispatch({type: actionTypes.VIEW_ISDEFAULT_SET, isDefault: isDefault});
};

const updateIsGadget = isGadget => dispatch => {
	dispatch({type: actionTypes.VIEW_ISGADGET_SET, isGadget: isGadget});
};

const updateTags = tags => dispatch => {
	dispatch({type: actionTypes.VIEW_TAGS_SET, tags: tags});
};

const updateId = id => dispatch => {
	dispatch({type: actionTypes.VIEW_ID_SET, id: id});
};

const updateFilters = filters => dispatch => {
	dispatch({type: actionTypes.VIEW_FILTERS_SET, filters: filters});
};

const updateBucket = bucket => dispatch => {
	dispatch({type: actionTypes.VIEW_BUCKET_SET, bucket: bucket});
};

const updatePageNumber = pageNumber => dispatch => {
	dispatch({type: actionTypes.VIEW_PAGENUMBER_SET, pageNumber: pageNumber});
};

const updateGroupBy = groupBy => dispatch => {
	dispatch({type: actionTypes.VIEW_GROUPBY_SET, groupBy: groupBy});
};

const updateMaxRecords = maxRecords => dispatch => {
	dispatch({type: actionTypes.VIEW_MAXRECORDS_SET, maxRecords: maxRecords});
};

const updateStatmentDate = statementDate => dispatch => {
	dispatch({type: actionTypes.VIEW_STATEMENTDATE_SET, statementDate: statementDate});
};

const updateSnapshotType = snapshotType => dispatch => {
	dispatch({type: actionTypes.VIEW_SNAPSHOTTYPE_SET, snapshotType: snapshotType});
};

const updateDateContext = dateContext => dispatch => {
	dispatch({type: actionTypes.VIEW_DATECONTEXT_SET, dateContext: dateContext._id});
};

const updateIsFixedDate = isFixedDate => dispatch => {
	dispatch({type: actionTypes.VIEW_ISFIXEDDATE_SET, isFixedDate: isFixedDate});
};

const updateSortCalculation = sortCalculation => dispatch => {
	dispatch({type: actionTypes.VIEW_SORTCALCULATION_SET, sortCalculation: sortCalculation});
};

const updateSortColumn = sortColumn => dispatch => {
	dispatch({type: actionTypes.VIEW_SORTCOLUMN_SET, sortColumn: sortColumn});
};

const updateSortOrder = sortOrder => dispatch => {
	dispatch({type: actionTypes.VIEW_SORTORDER_SET, sortOrder: sortOrder});
};

const updateTimeseriesColumn = timeseriesColumn => dispatch => {
	dispatch({type: actionTypes.VIEW_TIMESERIESCOLUMN_SET, timeseriesColumn: timeseriesColumn});
};

const updateTimeseriesPeriod = timeseriesPeriod => dispatch => {
	dispatch({type: actionTypes.VIEW_TIMESERIESPERIOD_SET, timeseriesPeriod: timeseriesPeriod});
};

const updateTimeseriesRange = timeseriesRange => dispatch => {
	dispatch({type: actionTypes.VIEW_TIMESERIESRANGE_SET, timeseriesRange: timeseriesRange});
};

const updateFundingVehicleId = fundingVehicleId => dispatch => {
	dispatch({type: actionTypes.VIEW_FUNDINGVEHICLEID_SET, fundingVehicleId: fundingVehicleId});
};

const updateHypoFundingVehicleId = hypoFundingVehicleId => dispatch => {
	dispatch({type: actionTypes.VIEW_HYPOFUNDINGVEHICLEID_SET, hypoFundingVehicleId: hypoFundingVehicleId});
};

const updateHypoPoolId = hypoPoolId => dispatch => {
	dispatch({type: actionTypes.VIEW_HYPOPOOLID_SET, hypoPoolId: hypoPoolId});
};

const updatePoolId = poolId => dispatch => {
	dispatch({type: actionTypes.VIEW_POOLID_SET, poolId: poolId});
};

const updateScenarioId = scenarioId => dispatch => {
	dispatch({type: actionTypes.VIEW_SCENARIOID_SET, scenarioId: scenarioId});
};

const updateScenarioType = scenarioType => dispatch => {
	dispatch({type: actionTypes.VIEW_SCENARIOTYPE_SET, scenarioType: scenarioType});
};

const updateColumns = (columnsToSet, columnType) => (dispatch, getState) => {
	const {viewForm} = getState();
	const newColumns = exploreRequestBuilder.getNewColumnsByType(viewForm.columns, columnType, columnsToSet);
	dispatch({type: actionTypes.VIEW_COLUMNS_SET, columns: newColumns});
};

const updateBandsType = bandType => dispatch => {
	dispatch({type: actionTypes.VIEW_BANDSTYPE_SET, bandType: bandType});
};

const updateBandsStep = step => dispatch => {
	dispatch({type: actionTypes.VIEW_BANDSSTEP_SET, step: step});
};

const updateBandsSteps = steps => dispatch => {
	dispatch({type: actionTypes.VIEW_BANDSSTEPS_SET, steps: steps});
};

const addOneBandStep = step => dispatch => {
	dispatch({type: actionTypes.VIEW_BANDSSTEPS_ADD_ONE, step: step});
};

const updateOneBandStep = (index, step) => dispatch => {
	dispatch({type: actionTypes.VIEW_BANDSSTEPS_UPDATE_ONE, step: step, index: index});
};

const removeOneBandStep = index => dispatch => {
	dispatch({type: actionTypes.VIEW_BANDSSTEPS_REMOVE_ONE, index: index});
};

const updateGranularity = value => dispatch => {
	dispatch({type: actionTypes.VIEW_GRANULARITY_SET, granularity: value});
};

const updateDateRangeStart = value => dispatch => {
	dispatch({type: actionTypes.VIEW_DATERANGE_START_SET, start: value});
};

const updateDateRangeEnd = value => dispatch => {
	dispatch({type: actionTypes.VIEW_DATERANGE_END_SET, end: value});
};

const updateStartInclusive = startInclusive => dispatch => {
	dispatch({type: actionTypes.VIEW_STARTINCLUSIVE_SET, startInclusive: startInclusive});
};

const updateDataTransferViewId = dataTransferViewId => dispatch => {
	dispatch({type: actionTypes.VIEW_DATA_TRANSFER_VIEW_ID_SET, dataTransferViewId: dataTransferViewId});
};

const updateValue = obj => dispatch => {
	//eslint-disable-line complexity
	switch (obj.name) {
		case 'name':
			return dispatch(updateName(obj.value));
		case 'createDate':
			return dispatch(updateCreateDate(obj.value));
		case 'createdBy':
			return dispatch(updateCreatedBy(obj.value));
		case 'dataTransferViewId':
			return dispatch(updateDataTransferViewId(obj.value));
		case 'datasetId':
			return dispatch(updateDatasetId(obj.value));
		case 'isGadget':
			return dispatch(updateIsGadget(obj.value));
		case 'tableType':
			return dispatch(updateTableType(obj.value));
		case 'isFavorite':
			return dispatch(updateIsFavorite(obj.value));
		case 'isDefault':
			return dispatch(updateIsDefault(obj.value));
		case 'tags':
			return dispatch(updateTags(obj.value));
		case 'filters':
			return dispatch(updateFilters(obj.value));
		case 'id':
			return dispatch(updateId(obj.value));
		case 'breadcrumbs':
			return dispatch(loadBreadcrumbs(obj.value));
		case 'bucket':
			return dispatch(updateBucket(obj.value));
		case 'pageNumber':
			return dispatch(updatePageNumber(obj.value));
		case 'groupBy':
			return dispatch(updateGroupBy(obj.value));
		case 'maxRecords':
			return dispatch(updateMaxRecords(obj.value));
		case 'statementDate':
			return dispatch(updateStatmentDate(obj.value));
		case 'isFixedDate':
			return dispatch(updateIsFixedDate(obj.value));
		case 'dateContext':
			return dispatch(updateDateContext(obj.value));
		case 'snapshotType':
			return dispatch(updateSnapshotType(obj.value));
		case 'sortCalculation':
			return dispatch(updateSortCalculation(obj.value));
		case 'sortColumn':
			return dispatch(updateSortColumn(obj.value));
		case 'sortOrder':
			return dispatch(updateSortOrder(obj.value));
		case 'timeseriesColumn':
			return dispatch(updateTimeseriesColumn(obj.value));
		case 'timeseriesPeriod':
			return dispatch(updateTimeseriesPeriod(obj.value));
		case 'timeseriesRange':
			return dispatch(updateTimeseriesRange(obj.value));
		case 'fundingVehicleId':
			return dispatch(updateFundingVehicleId(obj.value));
		case 'hypoFundingVehicleId':
			return dispatch(updateHypoFundingVehicleId(obj.value));
		case 'hypoPoolId':
			return dispatch(updateHypoPoolId(obj.value));
		case 'poolId':
			return dispatch(updatePoolId(obj.value));
		case 'scenarioId':
			return dispatch(updateScenarioId(obj.value));
		case 'scenarioType':
			return dispatch(updateScenarioType(obj.value));
		case 'cohortColumn':
			return dispatch(updateCohortColumn(obj.value));
		case 'bandsType':
			return dispatch(updateBandsType(obj.value));
		case 'bandsStep':
			return dispatch(updateBandsStep(obj.value));
		case 'bandsSteps':
			return dispatch(updateBandsSteps(obj.value));
		case 'startInclusive':
			return dispatch(updateStartInclusive(obj.value));
		case 'granularity':
			return dispatch(updateGranularity(obj.value));
		case 'dateRangeStart':
			return dispatch(updateDateRangeStart(obj.value));
		case 'dateRangeEnd':
			return dispatch(updateDateRangeEnd(obj.value));
		default:
			return;
	}
};

export {
	addFormError,
	addOneBandStep,
	clearBreadcrumbs,
	deleteBreadcrumb,
	getExplorerView,
	initializeView,
	loadBreadcrumbs,
	loadView,
	removeFormError,
	removeOneBandStep,
	saveView,
	setBreadcrumb,
	updateBandsStep,
	updateBandsSteps,
	updateBandsType,
	updateBreadcrumb,
	updateBucket,
	updateCohortColumn,
	updateColumn,
	updateColumns,
	updateCreateDate,
	updateCreatedBy,
	updateDatasetId,
	updateFilters,
	updateFundingVehicleId,
	updateGranularity,
	updateDateRangeStart,
	updateDateRangeEnd,
	updateGroupBy,
	updateHypoFundingVehicleId,
	updateHypoPoolId,
	updateId,
	updateIsDefault,
	updateIsFavorite,
	updateIsGadget,
	updateMaxRecords,
	updateName,
	updateOneBandStep,
	updatePageNumber,
	updatePoolId,
	updateScenarioId,
	updateScenarioType,
	updateStatmentDate,
	updateSortCalculation,
	updateSortColumn,
	updateSortOrder,
	updateStartInclusive,
	updateTableType,
	updateTags,
	updateTimeseriesColumn,
	updateTimeseriesPeriod,
	updateTimeseriesRange,
	updateValue,
};
