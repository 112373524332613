// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';

// Externals
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';

export const LogicDropdown = props => {
	const {value = '', options = [], dataColumn, onChange = () => null, limitStringOptions} = props;
	const classBase = 'cc-logic-dropdown';
	const stringOptions = [
		{value: 'begins_with', label: 'Begins with'},
		{value: 'ends_with', label: 'Ends with'},
		{value: 'contains', label: 'Contains'},
	];

	const filterOptions = (options = [], dataColumn) => {
		if (_get(dataColumn, 'dataType') === 'string' && !limitStringOptions) {
			return [
				...options.filter(option => !['>', '<', '>=', '<=', 'between'].includes(option.value)),
				...stringOptions,
			];
		}

		if (_get(dataColumn, 'dataType') === 'string' && limitStringOptions) {
			return [...options.filter(option => !['>', '<', '>=', '<=', 'between'].includes(option.value))];
		}

		if (_get(dataColumn, 'dataType') === 'boolean') {
			return options.filter(option => ['=', '<>'].includes(option.value));
		}

		return options.filter(option => !['in', 'not_in'].includes(option.value));
	};

	return (
		<div>
			<label className={`${classBase}-label mock-react-toolkit`}>Logic</label>
			<TriggerFunctionSelector
				className={classBase}
				options={filterOptions(options, dataColumn)}
				startingValue={value}
				targetFunction={onChange}
			/>
		</div>
	);
};

LogicDropdown.propTypes = {
	value: PropTypes.string,
	dataColumn: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	options: PropTypes.array,
	limitStringOptions: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default LogicDropdown;
