import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiList from 'components/KiList';
import KiFontIcon from 'components/KiFontIcon';
import './CriteriaBlock.scss';
// Project Imports
import {datasetsCriteriaApi, columnServiceApi} from 'api';
import DatasetFilter from 'components/DatasetFilter';
import {SYNTHETIC_ASSET_COLUMNS} from 'ki-common/enums';
import {parseCalulatedColumn, getAllColumnsConditional} from 'ki-common/utils/calcColumns';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

export class CriteriaBlock extends Component {
	//eslint-disable-line react/prefer-stateless-function
	static propTypes = {
		datasetId: PropTypes.string,
		isVisible: PropTypes.bool,
		handleEditCriteriaClick: PropTypes.func,
	};

	state = {
		criteriaList: [],
		assetColumns: [],
	};

	componentDidMount() {
		this.refetchData();
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			this.refetchData();
		}
	}

	refetchData() {
		const datasetId = this.props.datasetId;
		if (this.props.isVisible && datasetId) {
			return columnServiceApi
				.getColumnsFromService(datasetId, {
					sources: {
						includeAssetColumns: true,
						includeAssetCalculations: true,
						includeDateColumns: true,
					},
				})
				.then((assetColumns = []) => {
					return columnServiceApi.getEligibleColumnIds(datasetId).then(eligibilityColumns => {
						const filteredCols = this.filterEligibilityStatusColumns(assetColumns, eligibilityColumns);
						this.setState({assetColumns: filteredCols}, () => this.fetchCriteriaList());
					});
				});
		}
	}

	filterEligibilityStatusColumns = (cols, eligibilityColumns) => {
		let allFormulaCols;
		return cols.filter(c => {
			if (eligibilityColumns.includes(c._id)) {
				return false;
			} else if (!!c.formula && !!c.formula.extension) {
				// parse conditional calc col
				allFormulaCols = getAllColumnsConditional(c.formula);
				return !allFormulaCols.find(col => col.columnName === 'EligibilityStatus');
			} else if (c.formula && c.formula.left) {
				// parse non-conditional calc col
				allFormulaCols = parseCalulatedColumn(c.formula, true);
				return !allFormulaCols.find(col => col.columnName === 'EligibilityStatus');
			} else if (c.formula && c.type === 'column') {
				// edge case where single constant is column definition
				// it doesn't look like you can actually pick eligibility status for a value in the ux but check here anyway
				return _get(c, 'formula.value.columnName') === 'EligibilityStatus';
			}
			if (c.columnName === 'ki_encumbrance_pool') {
				// temporary, allow encumbrance pool bc it's now in synthetic asset cols to block everywhere else
				return true;
			}
			// regular column but exclude system generated ones
			return !SYNTHETIC_ASSET_COLUMNS.has(c.columnName);
		});
	};

	fetchCriteriaList() {
		return datasetsCriteriaApi.fetchCritieraByDataset(this.props.datasetId).then(criteriaList => {
			this.setState({criteriaList});
		});
	}

	insertCriteriaFunc(criteria) {
		return datasetsCriteriaApi.addCriteria(criteria).then(() => this.fetchCriteriaList());
	}

	updateCriteriaFunc(criteria) {
		return datasetsCriteriaApi.updateCriteria(criteria).then(() => this.fetchCriteriaList());
	}

	deleteCriteriaFunc(criteriaId) {
		return datasetsCriteriaApi.deleteCriteria(criteriaId).then(() => this.fetchCriteriaList());
	}

	render() {
		if (!this.props.isVisible) {
			return null;
		}

		const existingNames = this.state.criteriaList.map(criteria => criteria.name);
		const {datasetId, handleEditCriteriaClick} = this.props;
		const criteriaList = [...this.state.criteriaList, {}];

		return (
			<div className="dataset-list-criteria-block">
				<section className="block-header">
					<p>Global Exclusions</p>
					<KiFontIcon
						value="close"
						className="close-icon"
						onClick={e => handleEditCriteriaClick(e, datasetId)}
					/>
				</section>
				<KiList className="criteria-list">
					{criteriaList.map(criteria => (
						<div key={criteria._id} className="criteria-row">
							<DatasetFilter
								datasetId={datasetId}
								existingFilter={criteria}
								existingNames={existingNames}
								cohortColumns={this.state.assetColumns}
								insertMethod={this.insertCriteriaFunc.bind(this)}
								updateMethod={this.updateCriteriaFunc.bind(this)}
								deleteMethod={this.deleteCriteriaFunc.bind(this)}
							/>
						</div>
					))}
				</KiList>
			</div>
		);
	}
}

export default CriteriaBlock;
