const currentStatusList = [
	'upload',
	'validate',
	'commit',
	'cancel',
];

module.exports = {
	currentStatusList,
};

