import {notificationsApi} from 'api';
import * as actionTypes from './actionTypes';
import socketManager from 'socket';

socketManager.on('updated', () => dispatch => {
	dispatch(fetchNotificationsForCurrentUser()); //eslint-disable-line no-use-before-define
}, 'notifications');

const fetchNotificationsForCurrentUser = () => dispatch => {
	dispatch({type: actionTypes.NOTIFICATIONS_FETCH_CURRENT_USER, status: 'pending'});

	return notificationsApi.fetchNotificationsForCurrentUser()
		.then(notifications => dispatch({type: actionTypes.NOTIFICATIONS_FETCH_CURRENT_USER, status: 'success', notifications}))
		.catch(() => dispatch({type: actionTypes.NOTIFICATIONS_FETCH_CURRENT_USER, status: 'error'}));
};

// Accepts a complete lists of notificatins and hijacks the NOTIFICATIONS_FETCH_CURRENT_USER reducer
const updateWithPassedNotifications = newNotifications => dispatch => {
	dispatch({type: actionTypes.NOTIFICATIONS_FETCH_CURRENT_USER, status: 'success', newNotifications});
};

const updateNotificationsByDatasetId = datasetId => dispatch => {
	return notificationsApi.updateNotificationsByDatasetId(datasetId)
		.then(notifications => dispatch({type: actionTypes.NOTIFICATIONS_UPDATE_DATASET, status: 'success', notifications}))
		.catch(() => dispatch({type: actionTypes.NOTIFICATIONS_UPDATE_DATASET, status: 'error'}));
};

/*
TODO
deleteNotificationById
deleteUnassignedPoolsNotificationByDatasetId
 */

export {
	fetchNotificationsForCurrentUser,
	updateWithPassedNotifications,
	updateNotificationsByDatasetId,
};
