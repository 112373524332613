import _ from 'lodash';
import {useState, useEffect, useRef} from 'react';

/*
Helper to allow for merged state objects
Usage:

const [myObject, setMyObject] = useMergedState({a:1, b:2, c: {
	d:3, e:4
}});

setMyObject({f:5});
// myObject = {a:1, b2: f:5};

setMyObject({c:{e:44}});
// myObject = {a:1, b2: f:5, c: {
	d:3, e:44
}};
 */
export const useMergedState = initState => {
	const [curState, setCurState] = useState(initState);

	// If an array is passed in to an array param
	// replace it, do not merge the arrays
	const customizer = (existingVal, sourceVal) => {
		if (_.isArray(existingVal) && _.isArray(sourceVal)) {
			return sourceVal;
		}
	};

	// Merge replace state
	const setMergedState = newState => {
		setCurState(presentState => _.mergeWith({}, presentState, newState, customizer));
	};

	// Hard replace entire state
	const setState = newState => {
		setCurState(newState);
	};
	return [curState, setMergedState, setState];
};

export const usePrevious = value => {
	const ref = useRef();
	useEffect(
		() => {
			ref.current = value;
		},
		[value]
	);

	return ref.current;
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClick = (ref, callback) => {
	useEffect(
		() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					callback();
				}
			}
			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[ref, callback]
	);
};

export default {
	useMergedState,
	usePrevious,
	useOutsideClick,
};
