import {waterfallApi} from 'api';
import * as actionTypes from './actionTypes';
// import {showSnackbar} from 'state/actions/Snackbar';

export const fetchTracks = datasetId => dispatch => {
	dispatch({type: actionTypes.WATERFALL_FETCH_TRACKS, status: 'pending'});
	return waterfallApi
		.getTracks(datasetId)
		.then(tracks => {
			dispatch({type: actionTypes.WATERFALL_FETCH_TRACKS, status: 'success', data: tracks});
			return tracks;
		})
		.catch(error => {
			dispatch({type: actionTypes.WATERFALL_FETCH_TRACKS, status: 'error', error: error});
		});
};

export const fetchModels = fundingVehicleId => dispatch => {
	dispatch({type: actionTypes.WATERFALL_FETCH_MODELS, status: 'pending'});
	return waterfallApi
		.getModels(fundingVehicleId)
		.then(models => {
			dispatch({type: actionTypes.WATERFALL_FETCH_MODELS, status: 'success', data: models});
			return models;
		})
		.catch(error => {
			dispatch({type: actionTypes.WATERFALL_FETCH_MODELS, status: 'error', error: error});
		});
};

export default {
	fetchTracks,
	fetchModels,
};
