// Globals
import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

// Project imports
import KiFontIcon from 'components/KiFontIcon';
import KiTooltip from 'components/KiTooltip';

// Local imports
import styles from './dateEntryItem.theme.scss';

// The modal control functions are being pased down so that we
// have a single modal for date controls at any given time
function DateEntryItem(props) {
	const {dateEntry, editDateEntry, deleteDateEntry} = props;

	// Redux state references
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const allFundingVehicles = useSelector(state => state.fundingVehicleList.data);
	const dates = useSelector(state => state.datasetDates.dates);

	const parentDate = dates.find(curDate => curDate.groupId === dateEntry.parentId);

	const getFundingVehiclesText = () => {
		return dateEntry.fundingVehicles
			.reduce((acc, fvId) => {
				if (fvId === 'unencumbered') {
					return [...acc, 'Unencumbered'];
				}
				if (fvId === 'default') {
					return [...acc, 'Default'];
				}
				const match = allFundingVehicles.find(pfv => pfv._id === fvId);
				if (match) {
					return [...acc, match.name];
				}
				return acc;
			}, [])
			.join(', ');
	};

	const isDefault = dateEntry.fundingVehicles.includes('default');

	return (
		<div className={styles.root}>
			{parentDate && (
				<div className={styles.linkSpace}>
					<KiTooltip message={`Linked to ${parentDate.name}`}>
						<i className="material-icons">link</i>
					</KiTooltip>
				</div>
			)}
			<div className={styles.fvNames}>{getFundingVehiclesText()}</div>
			<div className={styles.controls}>
				{!isAdmin && <KiFontIcon className="list-icon-btn" value="search" onClick={editDateEntry} />}
				{isAdmin && <KiFontIcon className="list-icon-btn" value="edit" onClick={editDateEntry} />}
				{isAdmin &&
					!isDefault && <KiFontIcon className="list-icon-btn" value="delete" onClick={deleteDateEntry} />}
				{isAdmin &&
					isDefault && <KiFontIcon className={`${styles.disabledIcon} list-icon-btn`} value="delete" />}
			</div>
		</div>
	);
}

DateEntryItem.propTypes = {
	dateEntry: PropTypes.shape({
		_id: PropTypes.string,
		fundingVehicles: PropTypes.arrayOf(PropTypes.string),
		dateType: PropTypes.oneOf(['static', 'range', 'recurring']),
		name: PropTypes.string,
		startDate: PropTypes.string,
		recurrenceType: PropTypes.oneOf(['none', 'daily', 'weekly', 'monthly', 'monthly_end']),
		recurrenceStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		range: PropTypes.oneOf(['endAfter', 'endBy', 'all']),
		offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		offsetType: PropTypes.oneOf(['daily', 'weekly', 'monthly']),
		calendar: PropTypes.string,
		businessDay: PropTypes.oneOf(['previous', 'next']),
		parentId: PropTypes.string,
	}),
	editDateEntry: PropTypes.func,
	deleteDateEntry: PropTypes.func,
};

export default DateEntryItem;
