import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from '../AssociatedData.theme.scss';
import _get from 'lodash/get';
import _map from 'lodash/map';

export class UploadResults extends Component {
	static propTypes = {
		resultData: PropTypes.object,
		fileName: PropTypes.string,
		snapshotDate: PropTypes.string.isRequired,
		associatedDataType: PropTypes.string,
	};

	static defaultProps = {
		resultData: {},
	};

	render() {
		const {resultData, fileName, snapshotDate} = this.props;
		if (!resultData) {
			return null;
		}
		return (
			<div className={styles.wrapperColumn}>
				<table className="ki-list-table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Snapshot Date</th>
							<th>Assets Matched</th>
							<th>Total Records</th>
							<th className={`${styles.unmatchedRecords}`}>Unmatched Records</th>
						</tr>
					</thead>
					<tbody>
						<tr className="ki-list-item">
							<td className="ki-list-item-name">
								<p>{fileName}</p>
							</td>
							<td>
								<p>{snapshotDate}</p>
							</td>
							<td>
								<p>
									{resultData.assetsMatched ||
										resultData.totalRecords - resultData.unmatchedRecords.count}
								</p>
							</td>
							<td>
								<p>{resultData.paymentRecords || resultData.totalRecords}</p>
							</td>
							<td className={`${styles.unmatchedRecords}`}>
								<p>
									{resultData.unmatchedPayments
										? resultData.unmatchedPayments.count
										: resultData.unmatchedRecords.count}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				{_get(resultData, 'unmatchedPayments.payments.length', null) > 0 ||
				_get(resultData, 'unmatchedRecords.ids.length', null) > 0 ? (
					<span>
						<p className={`${styles.unmatchedRecords} ${styles.unmatchedRecordsHeader}`}>
							Unmatched Records - showing only 100 total records.
						</p>
						<table className={`${styles.paymentFileTable} ki-list-table`}>
							<thead>
								<tr>
									<th>Loan ID</th>
									{this.props.associatedDataType === 'paymentFile' && <th>Payment Date</th>}
									{this.props.associatedDataType === 'paymentFile' && <th>Payment Amount</th>}
								</tr>
							</thead>
							<tbody>
								{this.props.associatedDataType === 'paymentFile'
									? resultData.unmatchedPayments.payments.map((payment, idx) => {
											return (
												<tr key={idx}>
													<td>
														<p>{payment.loanId}</p>
													</td>
													<td>
														<p>{payment.paymentDate}</p>
													</td>
													<td>
														<p>{payment.paymentAmount}</p>
													</td>
												</tr>
											);
									  })
									: _map(_get(resultData, 'unmatchedRecords.ids'), (loanId, idx) => {
											return (
												<tr key={idx}>
													<td>
														<p>{loanId}</p>
													</td>
												</tr>
											);
									  })}
							</tbody>
						</table>
					</span>
				) : null}
			</div>
		);
	}
}

export default UploadResults;
