/*
 Constraint Type: BalanceLimit
 */

const _defaultsDeep = require('lodash/defaultsDeep');
const ccBase = require('./ccBase');

const baseConstraints = ccBase.getConstraints();
const getConstraints = () => {
	return _defaultsDeep(
		{
			rowType: {
				presence: {allowEmpty: false},
				format: {
					pattern: '^ccBalanceLimit$',
					flag: 'i',
					message: 'rowType must be ccBalanceLimit',
				},
			},
			selection: {
				presence: false,
			},
		},
		baseConstraints
	);
};

module.exports = {
	getConstraints,
};
