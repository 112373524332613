export const ACCOUNTS_FETCH = 'ACCOUNTS_FETCH';
export const ACCOUNTS_ADD = 'ACCOUNTS_ADD';
export const ACCOUNTS_UPDATE = 'ACCOUNTS_UPDATE';
export const ACCOUNTS_REMOVE = 'ACCOUNTS_REMOVE';
export const ACCOUNTS_REMOVE_MANY = 'ACCOUNTS_REMOVE_MANY';

export const ACCOUNT_MODAL_OPEN = 'ACCOUNT_MODAL_OPEN';
export const ACCOUNT_MODAL_CLOSE = 'ACCOUNT_MODAL_CLOSE';
export const ACCOUNT_MODAL_VALIDATION_ERRORS_ADD = 'ACCOUNT_MODAL_VALIDATION_ERRORS_ADD';
export const ACCOUNT_MODAL_FORM_VALUE_UPDATED = 'ACCOUNT_MODAL_FORM_VALUE_UPDATED';

export const ACCOUNT_DEACTIVATE = 'ACCOUNT_DEACTIVATE';
export const ACCOUNT_ACTIVATE = 'ACCOUNT_ACTIVATE';
