// Globals
import React, {useRef, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

// Website imports
import KiList from 'components/KiList';

// Local imports
import {DataExplorerContext} from '../../DataExplorerContext';
import TimeSeriesLink from './TimeSeriesLink';
import SummaryLink from './SummaryLink';
import VisualizationLink from './VisualizationLink';
import GranularityMenu from './GranularityMenu';
import SummaryData from './SummaryData';
import styles from './ColumnMenu.theme.scss';

export const ColumnMenu = props => {
	const wrapperRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
	const [showSummary, setShowSummary] = useState(false);
	const dataExplorerContext = useContext(DataExplorerContext);
	const {appliedBookmark, allColumns, calculatedDateInfo} = dataExplorerContext;

	const toggleSummary = () => {
		setShowSummary(!showSummary);
	};

	// this handles opening the menu via clicking the displayName in parent, or click outside events closing
	useEffect(
		() => {
			const handleClick = e => {
				if (props.displayNameRef && e.target === props.displayNameRef.current) {
					// if you clicked the display name, toggle the visibility (on parent state)
					setIsVisible(!isVisible);
				} else if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
					// if you clicked anything else, close this menu
					setIsVisible(false);
					setShowSummary(false);
				}
			};
			document.addEventListener('click', handleClick);
			return () => {
				// clean up listener on unmount
				document.removeEventListener('click', handleClick);
			};
		},
		[wrapperRef, props.displayNameRef, isVisible]
	);

	if (!isVisible) {
		return null;
	}

	return (
		<section className={styles.list} ref={wrapperRef}>
			<KiList selectable>
				<TimeSeriesLink
					className={styles.item}
					col={props.col}
					tableType={props.tableType}
					handleClick={props.handleTimeSeriesColumnSelect}
					groupBy={props.groupBy}
					snapshotType={props.snapshotType}
				/>
				<VisualizationLink
					className={styles.item}
					show={props.showSummaryColumnVisualizationLink}
					handleClick={props.toggleSummaryColumnChart}
				/>
				<VisualizationLink
					className={styles.item}
					show={props.showTimeSeriesVisualizationLink}
					handleClick={props.toggleTimeSeriesChart}
				/>
				<GranularityMenu
					className={`${styles.item} ${styles.granularityMenu}`}
					col={props.col}
					handleClick={props.setGranularity}
					bands={props.bands}
				/>
				<SummaryLink handleClick={toggleSummary} />
				{showSummary && (
					// TODO check how this should work for encumbrance (calculatedDate) BLD-21849 created
					<SummaryData
						columnId={props.col.id}
						columnType={props.col.columnType}
						dataExplorer={appliedBookmark.explorerData}
						columnList={allColumns}
						snapshotDate={calculatedDateInfo?.calculatedDate}
					/>
				)}
			</KiList>
		</section>
	);
};

ColumnMenu.propTypes = {
	col: PropTypes.object.isRequired,
	toggleSummaryColumnChart: PropTypes.func.isRequired,
	toggleTimeSeriesChart: PropTypes.func.isRequired,
	showSummaryColumnVisualizationLink: PropTypes.bool,
	showTimeSeriesVisualizationLink: PropTypes.bool,
	handleTimeSeriesColumnSelect: PropTypes.func,
	setGranularity: PropTypes.func,
	tableType: PropTypes.string,
	bands: PropTypes.object,
	groupBy: PropTypes.any,
	snapshotType: PropTypes.string,
	displayNameRef: PropTypes.any,
};

export default ColumnMenu;
