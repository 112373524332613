export const VIEW_BANDSSTEPS_ADD_ONE = 'VIEW_BANDSSTEPS_ADD_ONE';
export const VIEW_BANDSSTEPS_REMOVE_ONE = 'VIEW_BANDSSTEPS_REMOVE_ONE';
export const VIEW_BANDSSTEPS_SET = 'VIEW_BANDSSTEPS_SET';
export const VIEW_BANDSSTEPS_UPDATE_ONE = 'VIEW_BANDSSTEPS_UPDATE_ONE';
export const VIEW_BANDSSTEP_SET = 'VIEW_BANDSSTEP_SET';
export const VIEW_BANDSTYPE_SET = 'VIEW_BANDSTYPE_SET';
export const VIEW_BREADCRUMBS_CLEAR = 'VIEW_BREADCRUMBS_CLEAR';
export const VIEW_BREADCRUMBS_DELETE = 'VIEW_BREADCRUMBS_DELETE';
export const VIEW_BREADCRUMBS_LOAD = 'VIEW_BREADCRUMBS_LOAD';
export const VIEW_BREADCRUMBS_SET = 'VIEW_BREADCRUMBS_SET';
export const VIEW_BREADCRUMBS_UPDATE = 'VIEW_BREADCRUMBS_UPDATE';
export const VIEW_BUCKET_SET = 'VIEW_BUCKET_SET';
export const VIEW_COHORTCOLUMN_SET = 'VIEW_COHORTCOLUMN_SET';
export const VIEW_COLUMNS_SET = 'VIEW_COLUMNS_SET';
export const VIEW_COLUMN_SET = 'VIEW_COLUMN_SET';
export const VIEW_CREATEDATE_SET = 'VIEW_CREATEDATE_SET';
export const VIEW_CREATEDBY_SET = 'VIEW_CREATEDBY_SET';
export const VIEW_DATA_TRANSFER_VIEW_ID_SET = 'VIEW_DATA_TRANSFER_VIEW_ID_SET';
export const VIEW_DATASETID_SET = 'VIEW_DATASETID_SET';
export const VIEW_DATERANGE_END_SET = 'VIEW_DATERANGE_END_SET';
export const VIEW_ERROR_REMOVE = 'VIEW_ERROR_REMOVE';
export const VIEW_ERROR_SET = 'VIEW_ERROR_SET';
export const VIEW_FILTERS_SET = 'VIEW_FILTERS_SET';
export const VIEW_FUNDINGVEHICLEID_SET = 'VIEW_FUNDINGVEHICLEID_SET';
export const VIEW_GRANULARITY_SET = 'VIEW_GRANULARITY_SET';
export const VIEW_GROUPBY_SET = 'VIEW_GROUPBY_SET';
export const VIEW_HYPOFUNDINGVEHICLEID_SET = 'VIEW_HYPOFUNDINGVEHICLEID_SET';
export const VIEW_HYPOPOOLID_SET = 'VIEW_HYPOPOOLID_SET';
export const VIEW_ID_SET = 'VIEW_ID_SET';
export const VIEW_ISDEFAULT_SET = 'VIEW_ISDEFAULT_SET';
export const VIEW_ISFAVORITE_SET = 'VIEW_ISFAVORITE_SET';
export const VIEW_ISGADGET_SET = 'VIEW_ISGADGET_SET';
export const VIEW_LOAD = 'VIEW_LOAD';
export const VIEW_MAXRECORDS_SET = 'VIEW_MAXRECORDS_SET';
export const VIEW_NAME_SET = 'VIEW_NAME_SET';
export const VIEW_PAGENUMBER_SET = 'VIEW_PAGENUMBER_SET';
export const VIEW_POOLID_SET = 'VIEW_POOLID_SET';
export const VIEW_SCENARIOID_SET = 'VIEW_SCENARIOID_SET';
export const VIEW_SCENARIOTYPE_SET = 'VIEW_SCENARIOTYPE_SET';
export const VIEW_SHOWMOSTRECENTSNAPSHOT_SET = 'VIEW_SHOWMOSTRECENTSNAPSHOT_SET';
export const VIEW_SNAPSHOTDATE_SET = 'VIEW_SNAPSHOTDATE_SET';
export const VIEW_SNAPSHOTGRANULARITY_SET = 'VIEW_SNAPSHOTGRANULARITY_SET';
export const VIEW_SORTCALCULATION_SET = 'VIEW_SORTCALCULATION_SET';
export const VIEW_SORTCOLUMN_SET = 'VIEW_SORTCOLUMN_SET';
export const VIEW_SORTORDER_SET = 'VIEW_SORTORDER_SET';
export const VIEW_STARTINCLUSIVE_SET = 'VIEW_STARTINCLUSIVE_SET';
export const VIEW_DATERANGE_START_SET = 'VIEW_DATERANGE_START_SET';
export const VIEW_TABLETYPE_SET = 'VIEW_TABLETYPE_SET';
export const VIEW_TAGS_SET = 'VIEW_TAGS_SET';
export const VIEW_TIMESERIESCOLUMN_SET = 'VIEW_TIMESERIESCOLUMN_SET';
export const VIEW_TIMESERIESPERIOD_SET = 'VIEW_TIMESERIESPERIOD_SET';
export const VIEW_TIMESERIESRANGE_SET = 'VIEW_TIMESERIESRANGE_SET';
export const VIEW_STATEMENTDATE_SET = 'VIEW_STATEMENTDATE_SET';
export const VIEW_DATECONTEXT_SET = 'VIEW_DATECONTEXT_SET';
export const VIEW_ISFIXEDDATE_SET = 'VIEW_ISFIXEDDATE_SET';
export const VIEW_SNAPSHOTTYPE_SET = 'VIEW_SNAPSHOTTYPE_SET';
