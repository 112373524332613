import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import LedgerAccountDetail from './accountDetail/LedgerAccountDetail';
import LedgerBalanceSummary from './balanceSummary/LedgerBalanceSummary';
import LedgerJournal from './journal/LedgerJournal';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import LedgerFilteringHeader from './LedgerFilteringHeader';
import LedgerTopBar from './LedgerTopBar';
import {resetState} from '../actions';

export const VIEW_TYPES = {
	ACCOUNT: {label: 'Account Detail', value: 'account'},
	BALANCE: {label: 'Balance Summary', value: 'balance'},
	JOURNAL: {label: 'Journal', value: 'journal'},
};

export const DATE_CONTEXT = {
	POSTING_DATE: {label: 'Posting Date', value: 'POSTING'},
	EFFECTIVE_DATE: {label: 'Effective Date', value: 'EFFECTIVE'},
};

function LedgerExploration({viewType, resetState}) {
	const {id} = useParams();

	useEffect(() => {
		resetState();
	}, []);

	const renderView = () => {
		switch (viewType) {
			case VIEW_TYPES.ACCOUNT:
				return <LedgerAccountDetail ledgerId={id} />;
			case VIEW_TYPES.BALANCE:
				return <LedgerBalanceSummary id={id} />;
			case VIEW_TYPES.JOURNAL:
				return <LedgerJournal id={id} />;
		}
	};

	return (
		<div className="ledger">
			<LedgerTopBar ledgerId={id} />
			<div className="ki-panel fixed">
				<LedgerFilteringHeader />
				{renderView()}
			</div>
		</div>
	);
}

LedgerExploration.propTypes = {
	viewType: PropTypes.object.isRequired,
	resetState: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	viewType: state.ledgerExploration.viewType,
});

export default connect(mapStateToProps, {resetState})(LedgerExploration);
