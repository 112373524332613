import * as actionTypes from './actionTypes';
import _ from 'lodash';

const initialState = {
	isLoading: false,
	selected: {
		columns: [],
	},
	data: [],
	columnList: [],
};

function DatasetList(state = initialState, action) {
	switch (action.type) {
		case actionTypes.DATASETS_FETCH:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, data: action.datasetList};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		case actionTypes.DATASETS_FETCH_ONE:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						data: !state.data.find(ds => action.dataset._id === ds._id)
							? [...state.data, action.dataset]
							: state.data.reduce((acc, ds) => {
									if (ds._id === action.dataset._id) {
										acc.push(action.dataset);
									} else {
										acc.push(ds);
									}
									return acc;
							  }, []),
						selected: {...initialState.selected, ...action.dataset},
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.DATASETS_SELECTED_SET:
			return {...state, selected: {...initialState.selected, ...action.dataset}};

		case actionTypes.DATASETS_SELECTED_CLEAR:
			return {...state, selected: null};

		case actionTypes.DATASETS_HISTORICAL_COLUMN_UPDATED:
			return {
				...state,
				columnList: state.columnList.reduce((acc, column) => {
					if (action.id === column._id) {
						return [...acc, Object.assign({}, column, action.changes)];
					}
					return [...acc, column._id === action.id ? {...column, ...action.changes} : column];
				}, []),
			};

		case actionTypes.DATASETS_GENERATE_AS_OF_DATE_COLUMNS:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						columnList: state.columnList.reduce((acc, column) => {
							if (action.columnIds.includes(column._id)) {
								return [...acc, {...column, status: 'generating'}];
							}
							return [...acc, column];
						}, []),
					};
				case 'error':
					return {
						...state,
						columnsList: state.columnList.reduce((acc, column) => {
							if (column.status === 'generating') {
								return [...acc, {...column, status: 'failed'}];
							}
							return [...acc, column];
						}, []),
					};
				default:
					return state;
			}

		case actionTypes.DATASETS_CHECK_AS_OF_DATE_COLUMN_MODIFICATIONS:
			switch (action.status) {
				case 'pending':
					return {
						...state,
						columnList: state.columnList.reduce((acc, column) => {
							if (action.columnId) {
								if (column._id === action.columnId) {
									return [...acc, {...column, pendingModificationCheck: true}];
								}
							} else if (column.asOfDateType) {
								return [...acc, {...column, pendingModificationCheck: true}];
							}
							return [...acc, column];
						}, []),
					};
				case 'success':
					return {
						...state,
						columnList: state.columnList.reduce((acc, column) => {
							const columnToReturn = _.omit(column, 'pendingModificationCheck');
							if (action.modifiedColumnIds.includes(column._id)) {
								return [
									...acc,
									{
										..._.omit(column, 'pendingModificationCheck'),
										hasModifications: true,
									},
								];
							}
							return [...acc, columnToReturn];
						}, []),
					};
				case 'error':
					return {
						...state,
						columnList: state.columnList.reduce((acc, column) => {
							if (action.columnId) {
								if (column._id === action.columnId) {
									return [...acc, _.omit(column, 'pendingModificationCheck')];
								}
								return column;
							} else if (column.asOfDateType) {
								return [...acc, _.omit(column, 'pendingModificationCheck')];
							}
							return [...acc, column];
						}, []),
					};
				default:
					return state;
			}

		case actionTypes.DATASETS_REQUERY_COLUMN_LIST:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						columnList: action.columns,
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		default:
			return state;
	}
}

export default DatasetList;
