import PropTypes from 'prop-types';
import React from 'react';
import KiChip from 'components/KiChip';

export const SubmissionStatusAvatar = props => {
	const status = props.status;
	let title = 'Upload',
		color = '#696969',
		icon = 'sync';

	title = status.name;
	// switch (status.name) {
	// 	case 'Commit Complete':
	// 		title = 'Commit';
	// 		break;
	// 	case 'Submit Complete':
	// 		title = 'Submit';
	// 		break;
	// 	case 'Transfer Complete':
	// 		title = 'Transfer';
	// 		break;
	// 	case 'upload':
	// 		title = 'Upload';
	// 		break;
	// 	case 'validate':
	// 		title = 'Validate';
	// 		break;
	// 	case 'commit':
	// 		title = 'Commit';
	// 		break;
	// 	case 'cancel':
	// 		title = 'Cancel';
	// 		icon = 'check';
	// 		break;
	// }

	if (status.color === 'yellow') {
		icon = 'sync';
		color = '#f36f2b';
	} else if (status.color === 'red') {
		icon = 'priority_high';
		color = '#c3062e';
	} else if (status.color === 'green') {
		icon = 'check';
		color = '#38761d';
	} else {
		icon = 'check';
		color = '#38761d';
	}

	return (
		<KiChip icon={icon} iconColor="#FFFFFF" iconBackgroundColor={color}>
			<span style={{textTransform: 'capitalize', fontSize: '1.25rem'}}>{title}</span>
		</KiChip>
	);
};

SubmissionStatusAvatar.propTypes = {
	status: PropTypes.object,
};

export default SubmissionStatusAvatar;
