const getConstraints = () => ({
	'firstName': {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 50,
			message: 'must be between 1 to 50 characters'
		}
	},
	'lastName': {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 50,
			message: 'must be between 1 to 50 characters'
		}
	},
	'userId': (value) => {
		if (value !== undefined) {
			return {
				presence: {allowEmpty: false},
				length: {
					minimum: 1,
					maximum: 50,
					message: 'must be between 1 to 50 characters'
				},
				format: {
					pattern: '(^[A-z])[a-z0-9.]+',
					flags: 'i',
					message: 'must start with a letter and can only contain a-z and 0-9'
				}
			};
		}
		return {
			presence: false
		};
	},
	'email': {
		presence: {allowEmpty: false},
		email: {
			message: 'not a valid email'
		}
	},
	'groups': {
		presence: {
			allowEmpty: false,
			message: 'must have at least one group'
		},
	},
	'password': (value) => {
		if (value) {
			return {
				length: {
					minimum: 8,
					maximum: 50,
					message: 'must be between 8 and 50 characters'
				},
				format: {
					pattern: '^(?=[^0-9])(?=.*[a-z]+.*)(?=.*[A-Z]+.*)(?=.*[0-9]+.*)(.{7,}[^0-9])$',
					message: 'Requires 1 uppercase, 1 lowercase, and 1 number not as first or last character'
				}
			};
		}
		return {
			presence: false,
		};
	},
});

module.exports = {
	getConstraints
};
