import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const KiFontIcon = ({children, className, value, ...other}) => {
	const classes = classnames(
		{'material-icons': typeof value === 'string' || typeof children === 'string'},
		className
	);
	return (
		<i className={classes} {...other}>
			{value}
			{children}
		</i>
	);
};

KiFontIcon.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

KiFontIcon.defaultProps = {
	className: '',
};

export default KiFontIcon;
export {KiFontIcon};
