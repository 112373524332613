// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import validators from 'ki-common/validators';
import ccWeightedAvg from './validators/ccWeightedAvg';

const constraints = ccWeightedAvg.getConstraints();

const WeightedAverageForm = props => {
	const {isSaveClick} = props;

	const [formError, setFormError] = useState('');
	const [dataOptions, setDataOptions] = useState([]);

	useEffect(
		() => {
			setDataOptions(
				ConstraintHelpers.getCohortColumns(
					props.dataColumns,
					props.constraintData?.dataColumn,
					true,
					props.isGroupBy,
					true,
					props.eligibleColumns
				)
			);
		},
		[props.dataColumns, props.constraintData?.dataColumn, props.isGroupBy, props.eligibleColumns]
	);

	useEffect(
		() => {
			props.updateFormHasError(!!formError);
		},
		[props.updateFormHasError, formError]
	);

	useEffect(
		() => {
			if (isSaveClick) {
				setFormError(validators.validate(props.constraintData, constraints));
			}
		},
		[props.constraintData, isSaveClick]
	);

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						isDisabled={props.isReadOnly}
						classNamePrefix="aut-select"
						value={props.constraintData.dataColumn}
						isLoading={props.loadingColumns}
						isClearable={false}
						options={dataOptions}
						onChange={dc => {
							props.setConstraintItem('dataColumn', dc);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						isDisabled={!props.constraintData.dataColumn || props.isReadOnly}
						classNamePrefix="aut-select"
						value={props.constraintData.logic}
						isClearable={false}
						options={options.weightedLogicOptions}
						onChange={l => {
							const keys = [];
							const values = [];
							keys.push('logic');
							values.push(l);
							keys.push('target');
							if (l.value === 'between') {
								values.push(['', '']);
							} else {
								values.push(['']);
							}
							props.setConstraintItem(keys, values);
						}}
					/>
				</div>
				{props.constraintData.logic?.value === 'between' && (
					<div
						className={styles.selectWrapper}
						style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
					>
						<KiInput
							disabled={props.isReadOnly}
							type="text"
							label="Min"
							value={props.constraintData.target[0]}
							isNumberMasked={true}
							onChange={t => {
								const newValue = [t, props.constraintData.target[1]];
								props.setConstraintItem('target', newValue);
							}}
							error={formError?.target}
						/>
						<KiInput
							disabled={props.isReadOnly}
							type="text"
							label="Max"
							value={props.constraintData.target[1]}
							isNumberMasked={true}
							onChange={t => {
								const newValue = [props.constraintData.target[0], t];
								props.setConstraintItem('target', newValue);
							}}
							error={formError?.target}
						/>
					</div>
				)}
				{props.constraintData.logic?.value !== 'between' && (
					<div className={styles.selectWrapper}>
						<KiInput
							disabled={props.isReadOnly}
							type="text"
							name="name"
							label={'Target'}
							isNumberMasked={true}
							value={props.constraintData.target && props.constraintData.target.toString()}
							onChange={t => {
								props.setConstraintItem('target', [t]);
							}}
							error={formError?.target}
						/>
					</div>
				)}
				<div className={styles.selectWrapper}>
					<span className="theme-label">Selection</span>
					<KiSelect
						isDisabled={props.isReadOnly}
						classNamePrefix="aut-select"
						value={props.constraintData.selection}
						isClearable={false}
						options={dataOptions}
						onChange={sel => {
							props.setConstraintItem('selection', sel);
						}}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
			</div>
		</div>
	);
};

WeightedAverageForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func.isRequired,
	dataColumns: PropTypes.array,
	eligibleColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	updateFormHasError: PropTypes.func.isRequired,
	isSaveClick: PropTypes.bool,
};

WeightedAverageForm.defaultProps = {
	constraintData: {},
	eligibleColumns: [],
};

export default WeightedAverageForm;
