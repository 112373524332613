import React from 'react';
import LedgerSubmitHeader from '../LedgerSubmitHeader';
import KiSelect from '../../../../components/KiSelect';
import {SELECT_CONFIGURE_ACTIONS} from './LedgerConfigure';
import PropTypes from 'prop-types';

function LedgerConfigureHeader({currentView, setView, buttonActions}) {
	return (
		<LedgerSubmitHeader actions={buttonActions}>
			<KiSelect
				className="select"
				options={SELECT_CONFIGURE_ACTIONS}
				value={currentView}
				onChange={value => setView(value)}
			/>
		</LedgerSubmitHeader>
	);
}

LedgerConfigureHeader.propTypes = {
	currentView: PropTypes.object.isRequired,
	setView: PropTypes.func.isRequired,
	buttonActions: PropTypes.arrayOf(PropTypes.object),
};

LedgerConfigureHeader.defaultProps = {
	buttonActions: [],
};

export default LedgerConfigureHeader;
