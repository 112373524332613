import * as actionTypes from './actionTypes';

const initialState = {
	calendars: [],
	isLoading: false,
};

export const Calendars = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CALENDARS_FETCH_ONE:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success': {
					return {
						...state,
						isLoading: false,
						calendars: !state.calendars.find(({_id}) => _id === action.calendar._id)
							? // calendar is new, add to the reducer
							  [...state.calendars, action.calendar]
							: // calendar was updated, replace the existing one
							  state.calendars.reduce((acc, calendar) => {
									if (calendar._id === action.calendar._id) {
										return [...acc, calendar];
									}
									return [...acc, calendar];
							  }, []),
					};
				}
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.CALENDARS_FETCH_ALL:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, calendars: action.calendars};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.CALENDARS_CREATE_ONE:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						calendars: [...state.calendars, action.calendar],
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.CALENDARS_DELETE_ONE:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						calendars: state.calendars.filter(({_id}) => _id !== action.id),
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.CALENDARS_UPDATE_ONE:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						calendars: state.calendars.reduce((acc, calendar) => {
							if (calendar._id === action.id) {
								return [...acc, {...calendar, ...action.calendar}];
							}
							return [...acc, calendar];
						}, []),
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		default:
			return state;
	}
};

export default Calendars;
