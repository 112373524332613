const _ = require('lodash');
const uuidv4 = require('uuid/v4');

const operatorOptions = Object.freeze([
	{
		label: '+',
		value: '+',
	},
	{
		label: '-',
		value: '-',
	},
	{
		label: '*',
		value: '*',
	},
	{
		label: '/',
		value: '/',
	},
	{
		label: '^',
		value: '^',
	},
]);
const operatorValues = operatorOptions.map(entry => entry.value);

const relationalOperatorOptions = Object.freeze([
	{
		label: '=',
		value: '==',
	},
	{
		label: '<>',
		value: '!=',
	},
	{
		label: '>',
		value: '>',
	},
	{
		label: '>=',
		value: '>=',
	},
	{
		label: '<',
		value: '<',
	},
	{
		label: '<=',
		value: '<=',
	},
]);
const relationalOperatorValues = relationalOperatorOptions.map(entry => entry.value);

const logicOptions = Object.freeze([
	{
		label: 'AND',
		value: '&&',
	},
	{
		label: 'OR',
		value: '||',
	},
]);
const logicValues = logicOptions.map(entry => entry.value);

// These are all the types of entrys that are technically values
const valueTypes = ['constant', 'min', 'max', 'column', 'subAccountColumn'];

// Mush all ) and ( together with similar brackets
// Mark each constant with the VALUE element type
// Surround all VALUE elements with paren on either side
const openParenRegex = /[(]/;
const closeParenRegex = /[)]/;
const newEmptyParen = () => {
	return {
		id: uuidv4(),
		type: 'operator',
		value: '',
		element: 'PAREN',
	};
};
const parseSimpleElements = formula => {
	const compressed = formula.reduce((acc, entry) => {
		const value = entry.value;
		const lastElement = acc[acc.length - 1];
		const lastValue = _.get(lastElement, 'value', '');
		if (openParenRegex.test(value)) {
			if (openParenRegex.test(lastValue)) {
				lastElement.value = `${lastValue}(`;
				return acc;
			} else {
				acc.push(
					Object.assign({}, entry, {
						id: uuidv4(),
						element: 'PAREN',
					})
				);
			}
		} else if (closeParenRegex.test(value)) {
			if (closeParenRegex.test(lastValue)) {
				lastElement.value = `${lastValue})`;
				return acc;
			} else {
				acc.push(
					Object.assign({}, entry, {
						id: uuidv4(),
						element: 'PAREN',
					})
				);
			}
		} else {
			acc.push(entry);
		}
		return acc;
	}, []);

	const output = compressed.reduce((acc, entry, idx, formula) => {
		if (valueTypes.includes(entry.type)) {
			const lastElement = _.get(acc, `[${acc.length - 1}]`, {});
			const nextElement = _.get(formula, `[${idx + 1}]`, {});

			// Confirm that the value is preceeded by a paren
			if (lastElement.element !== 'PAREN') {
				acc.push(newEmptyParen());
			}
			// Add the value
			acc.push(
				Object.assign({}, entry, {
					id: uuidv4(),
					element: 'VALUE',
				})
			);
			// Confirm that the value is succeeded by a paren
			if (nextElement.element !== 'PAREN') {
				acc.push(newEmptyParen());
			}
		} else {
			acc.push(entry);
		}
		return acc;
	}, []);
	return output;
};

const composeSimpleElements = framework => {
	const formula = framework.reduce((acc, entry) => {
		if (entry.element === 'PAREN') {
			// Throw out empty parens
			if (entry.value === '') {
				return acc;
			}
			const parens = entry.value.split(''); // Break apart
			parens.forEach(aParen => {
				acc.push({
					type: 'operator',
					value: aParen,
				});
			});
			return acc;
		}

		if (entry.element === 'VALUE') {
			acc.push(_.pick(entry, ['type', 'value', 'offset', 'columnType', 'entityId', 'dataType']));
			return acc;
		}

		acc.push(entry);
		return acc;
	}, []);
	return formula;
};

// Convert all the IF/THEN/ELSE logic into framework blocks
const parseFormulaCase = rawFormula => {
	const formula = _.cloneDeep(rawFormula); // Prevent mutation
	let position = 0;
	let framework = [
		{
			id: uuidv4(),
			formula: [],
		},
	];

	formula.forEach(entry => {
		if (entry.type === 'operator' && ['?', ':'].includes(entry.value)) {
			framework.push({id: uuidv4(), formula: []});
			position++;
		}
		framework[position].formula.push(entry);
	});
	framework = framework.map((block, index) => {
		const firstSymbol = _.get(block, 'formula.0.value', '');
		if (index === 0) {
			block.element = 'CASE';
			return block;
		}
		if (firstSymbol === '?') {
			block.element = 'THEN';
			block.formula.shift();
			return block;
		}
		if (firstSymbol === ':') {
			block.element = index === framework.length - 1 ? 'ELSE' : 'CASE';
			block.formula.shift();
			return block;
		}
		return block;
	});
	return framework;
};

const composeFormulaCase = rawFramework => {
	const framework = _.cloneDeep(rawFramework); // Prevent mutation
	let hasCase = false;
	const formula = framework.reduce((acc, entry) => {
		// The first case handle normally, the rest add an else operator before
		if (entry.element === 'CASE') {
			if (hasCase) {
				acc.push({
					type: 'operator',
					value: ':',
				});
			} else {
				hasCase = true;
			}
			acc = acc.concat(entry.formula);
		} else if (entry.element === 'THEN' || entry.element === 'ELSE') {
			// Add the operators for then / else
			acc.push({
				type: 'operator',
				value: entry.element === 'THEN' ? '?' : ':',
			});
			acc = acc.concat(entry.formula);
		} else {
			acc.push(entry);
		}
		return acc;
	}, []);
	return formula;
};

// Convert all the AND/OR logic into framework blocks
const parseLogicElements = rawCaseBlock => {
	const caseBlock = _.cloneDeep(rawCaseBlock); // Prevent mutation
	let rowPtr = 0;
	let rows = [
		{
			id: uuidv4(),
			formula: [],
		},
	];
	// Loop through and move all AND/OR statements into their own block
	caseBlock.formula.forEach(entry => {
		if (entry.type === 'operator' && ['&&', '||'].includes(entry.value)) {
			rows.push({id: uuidv4(), formula: []});
			rowPtr++;
		}
		rows[rowPtr].formula.push(entry);
	});

	// Add element tags to the blocks
	rows = rows.map((row, index) => {
		const firstSymbol = _.get(row, 'formula.0.value', '');
		if (index === 0) {
			row.element = 'LOGIC';
			return row;
		}
		if (['&&', '||'].includes(firstSymbol)) {
			row.element = firstSymbol === '&&' ? 'AND' : 'OR';
			row.formula.shift();
			return row;
		}
		return row;
	});

	caseBlock.framework = rows;
	delete caseBlock.formula;
	return caseBlock;
};

const composeLogicFormula = rawCaseBlock => {
	const caseBlock = _.cloneDeep(rawCaseBlock); // Prevent mutation

	const formula = caseBlock.framework.reduce((acc, elementEntry) => {
		if (elementEntry.element === 'LOGIC') {
			acc = acc.concat(elementEntry.formula);
		}
		if (elementEntry.element === 'AND') {
			acc.push({
				type: 'operator',
				value: '&&',
			});
			acc = acc.concat(elementEntry.formula);
		}
		if (elementEntry.element === 'OR') {
			acc.push({
				type: 'operator',
				value: '||',
			});
			acc = acc.concat(elementEntry.formula);
		}
		return acc;
	}, []);

	caseBlock.formula = formula;
	delete caseBlock.framework;
	return caseBlock;
};

const parseBlockLogic = rawFormula => {
	const formula = _.cloneDeep(rawFormula); // Prevent mutation
	const framework = formula.reduce((acc, entry) => {
		if (['CASE', 'THEN', 'ELSE'].includes(entry.element)) {
			acc.push(parseLogicElements(entry));
		} else {
			acc.push(entry);
		}
		return acc;
	}, []);
	return framework;
};

const composeBlockLogic = rawFramework => {
	const framework = _.cloneDeep(rawFramework); // Prevent mutation
	const formula = framework.reduce((acc, entry) => {
		if (['CASE', 'THEN', 'ELSE'].includes(entry.element)) {
			acc.push(composeLogicFormula(entry));
		} else {
			acc.push(entry);
		}
		return acc;
	}, []);
	return formula;
};

// Convert all the numeric formulas to values and operator blocks
const numericBlockFromFormula = (rawlogicBlock, isConditional = true) => {
	const logicBlock = _.cloneDeep(rawlogicBlock); // Prevent mutation
	let isOperatorBlock = false;
	const elements = logicBlock.formula.reduce((acc, entry, index) => {
		if (valueTypes.includes(entry.type)) {
			// Append to the OperatorValueBlock if 1
			if (isOperatorBlock) {
				const lastElement = acc[acc.length - 1];
				lastElement.formula.push(entry);
				return acc;
			}
			acc.push(entry);
			return acc;
		}

		// At this point there should only be on relational operator in a row
		// reset the operator block
		if (relationalOperatorValues.includes(entry.value)) {
			isOperatorBlock = false;
			entry.id = uuidv4();
			entry.element = 'RELATIONAL';
			acc.push(entry);
			return acc;
		}

		// If this is a numeric parse then the formula can start with and operator
		// There could also be a paren starting off with an operator after
		if (!isConditional && index < 1 && operatorValues.includes(entry.value)) {
			entry.id = uuidv4();
			entry.element = 'OPERATOR';
			acc.push(entry);
			return acc;
		}

		// You only ever see a direct operator when dealing with sets of
		// operator, value, (paren) inside a conditional
		if (operatorValues.includes(entry.value)) {
			entry.id = uuidv4();
			entry.element = 'OPERATOR';
			const newEntry = {
				id: uuidv4(),
				element: 'OPERATOR_BLOCK',
				formula: [entry],
			};
			isOperatorBlock = true;
			acc.push(newEntry);
			return acc;
		}

		// Once an operator block is found, all the rest of the elements
		// will either be part of that block, or a new block
		if (isOperatorBlock) {
			const lastElement = acc[acc.length - 1];
			lastElement.formula.push(entry);
			return acc;
		}

		acc.push(entry);
		return acc;
	}, []);
	logicBlock.framework = elements;
	delete logicBlock.formula;
	return logicBlock;
};

const numericFormulaFromBlock = rawLogicBlock => {
	const logicBlock = _.cloneDeep(rawLogicBlock); // Prevent mutation
	const formula = logicBlock.framework.reduce((acc, entry) => {
		if (entry.element === 'OPERATOR_BLOCK') {
			// Remove the OPERATOR_BLOCK wrapper
			acc = acc.concat(
				entry.formula.map(entry => {
					// Remove OPERATOR tags that were added
					if (entry.element === 'OPERATOR') {
						return {
							type: entry.type,
							value: entry.value,
						};
					}
					return entry;
				})
			);
		} else if (entry.element === 'RELATIONAL') {
			// This is the only other element added in the numericBlockFromFormula
			// function, so it is the only element that should be removed
			acc.push({
				type: entry.type,
				value: entry.value,
			});
		} else {
			acc.push(entry);
		}
		return acc;
	}, []);
	logicBlock.formula = formula;
	delete logicBlock.framework;
	return logicBlock;
};

const parseNumericElements = (rawFormula, isConditional = true) => {
	const formula = _.cloneDeep(rawFormula); // Prevent mutation
	let framework;
	if (isConditional) {
		// Conditional Formula
		framework = formula.map(caseEntry => {
			if (['CASE', 'THEN', 'ELSE'].includes(caseEntry.element)) {
				caseEntry.framework = caseEntry.framework.map(logicEntry => {
					if (['LOGIC', 'AND', 'OR'].includes(logicEntry.element)) {
						return numericBlockFromFormula(logicEntry, isConditional);
					} else {
						return logicEntry;
					}
				});
				return caseEntry;
			} else {
				return caseEntry;
			}
		});
	} else {
		// Numeric Formula
		const fakeLogicBlock = {
			element: 'LOGIC',
			formula,
		};
		return numericBlockFromFormula(fakeLogicBlock, isConditional);
	}

	return framework;
};

const composeNumericFormula = rawFramework => {
	const framework = _.cloneDeep(rawFramework); // Prevent mutation
	const formula = framework.map(caseEntry => {
		if (['CASE', 'THEN', 'ELSE'].includes(caseEntry.element)) {
			caseEntry.framework = caseEntry.framework.map(logicEntry => {
				if (['LOGIC', 'AND', 'OR'].includes(logicEntry.element)) {
					return numericFormulaFromBlock(logicEntry);
				} else {
					return logicEntry;
				}
			});
			return caseEntry;
		} else {
			return caseEntry;
		}
	});
	return formula;
};

/*
 * Strips the 'element' and 'id' tags from a framework array
 * used as last starge when converting to a formula
 */
const operatorFrameworkToFormula = framework => {
	return framework.map(element => {
		if (element.element === 'OPERATOR') {
			return {
				type: element.type,
				value: element.value,
			};
		}
		return element;
	});
};

const formulaToDisplayFramework = rawFormula => {
	const formula = _.cloneDeep(rawFormula); // Prevent mutation

	// Compress parens
	const parensFramework = parseSimpleElements(formula);

	// Parse all the if/then/else statements
	const caseFramework = parseFormulaCase(parensFramework);

	// Parse all the and/or statements
	const logicFramework = parseBlockLogic(caseFramework);

	// Parse all numeric sets
	const finalFramework = parseNumericElements(logicFramework);

	return finalFramework;
};

const displayFrameworkToFormula = rawFramework => {
	const framework = _.cloneDeep(rawFramework); // Prevent mutation

	// Compose numeric formulas
	const numericFormula = composeNumericFormula(framework);

	// Compose and/or statements
	const logicFormula = composeBlockLogic(numericFormula);

	// Compose it/then/else statements
	const caseFormula = composeFormulaCase(logicFormula);

	// Expand parenss
	const finalFormula = composeSimpleElements(caseFormula);

	return finalFormula;
};

const numericFormulaToDisplayFramework = rawFormula => {
	const formula = _.cloneDeep(rawFormula); // Prevent mutation

	// Compress parens
	const parensFramework = parseSimpleElements(formula);

	// Parse all numeric sets
	const finalFramework = parseNumericElements(parensFramework, false);

	return finalFramework.framework;
};

const displayFrameworkToNumericFormula = rawFramework => {
	const framework = _.cloneDeep(rawFramework); // Prevent mutation

	// Flatten the formula (all elements are low level)
	const numericFormula = framework.reduce((acc, entry) => {
		if (entry.element === 'OPERATOR_BLOCK') {
			acc = acc.concat(entry.formula);
		} else {
			acc.push(entry);
		}
		return acc;
	}, []);

	const formulaOperators = operatorFrameworkToFormula(numericFormula);

	// Expand parens
	const finalFormula = composeSimpleElements(formulaOperators);

	return finalFormula;
};

const getDataTypeFromValueElement = (entry, columns) => {
	const {type, value, entityId, dataType} = entry;
	if (type === 'constant') {
		if (dataType) {
			return dataType;
		}
		if (value === 'true' || value === 'false') {
			return 'boolean';
		}
		const regex = /^\d{4}-\d{2}(-\d{2}){0,1}$/;
		if (value.match(regex)) {
			return 'date_long';
		}
		if (!isNaN(parseFloat(value))) {
			return 'numeric';
		}
		// Do not send back string for ''
		if (value) {
			return 'string';
		}
		return '';
	}
	if (['column', 'subAccountColumn'].includes(type)) {
		// If this is one of the weird waterfall only columns the unique identifier
		// is the ENTITYID_COLUMNID
		const matchTarget = entityId ? `${entityId}_${value}` : value;
		const matchedColumn = columns.find(opt => `${opt._id}` === matchTarget);
		return _.get(matchedColumn, 'dataType', '');
	}
	if (['min', 'max'].includes(type)) {
		return 'numeric';
	}
	return '';
};

// Only returns the first matching type, does not resolve contacts
const getDataTypeFromFramework = (framework, columns) => {
	let dataType = '';
	framework.forEach(entry => {
		if (entry.element === 'VALUE') {
			dataType = dataType ? dataType : getDataTypeFromValueElement(entry, columns);
		}
		if (entry.element === 'OPERATOR_BLOCK') {
			const valueEntry = _.get(entry, 'formula[2]', {});
			dataType = dataType ? dataType : getDataTypeFromValueElement(valueEntry, columns);
		}
	});
	return dataType;
};

// replace every group of "left paren - some chars - right paren" with nothing
const balancedParens = str => {
	let mainStr = _.cloneDeep(str);
	let curStr;
	do {
		curStr = mainStr;
		mainStr = mainStr.replace(/\([^()]*\)/g, '');
	} while (curStr != mainStr);
	return !mainStr.match(/[()]/);
};

// Validate an array of elements and return an array of errors
const validateElementsArray = rawElements => {
	const errors = [];
	let parens = '';
	let blankValueFound = false;
	let invalidOffsetFound = false;
	const elements = rawElements.reduce((output, curValue) => {
		if (curValue.element === 'OPERATOR_BLOCK') {
			output = _.concat(output, ...curValue.formula);
		} else {
			output.push(curValue);
		}
		return output;
	}, []);

	elements.forEach(entry => {
		switch (entry.element) {
			case 'PAREN':
				if (entry.value) {
					parens = `${parens}${entry.value}`;
				}

				break;
			case 'VALUE':
				if (entry.value === '' && entry.dataType !== 'string') {
					blankValueFound = true;
				}
				if (_.has(entry, 'offset') && isNaN(parseInt(entry.offset))) {
					invalidOffsetFound = true;
				}
				break;
			default:
				break;
		}
	});

	if (blankValueFound) {
		errors.push('Not all values have been configured.');
	}

	if (!balancedParens(parens)) {
		errors.push('Parenthesis are not properly matched.');
	}

	if (invalidOffsetFound) {
		errors.push('Not all Offsets have been configured.');
	}

	return errors;
};

/**
 * Validate a Framework for errors before saving
 * @param  {[type]} framework 	An array of CASE/THEN/ELSE objects
 * @param  {boolean} [isConditional=true]
 * @return {[type]}           	An object of error arrays mapped by the CASE/THEN/ELSE ID
 */
const validateFramework = (framework, isConditional = true) => {
	if (isConditional) {
		const toReturn = {};
		framework.forEach(topElement => {
			// Flatten the entire subset of a block into a list of elements
			const elements = _.concat(...topElement.framework.map(logicEntry => logicEntry.framework));

			const errors = validateElementsArray(elements);

			if (errors.length > 0) {
				toReturn[topElement.id] = errors;
			}
		});
		return toReturn;
	} else {
		return validateElementsArray(framework);
	}
};

/** - Form
 * @param  {array[]} formula array
 * @param  {object[]} [columns=[]] - All the columns of any type that may be used
 * @param  {object[]} [entities=[]] - Tranches, Credit Supports, Fees, etc
 * @param  {object[]} [agencyRatings=[]]
 * @return {String} - Simple string representation of the formula
 */
const formulaToString = (formula, columns = [], entities = [], agencyRatings = []) => {
	// Sub Functions
	const getColumnName = id => {
		const match = columns.find(col => col._id === id);
		if (match && match.calculation) {
			return match.detailedDisplayName || `${match.displayName} (${match.calculation})`;
		}
		if (match && match.name) {
			return match.name;
		}
		return _.get(match, 'displayName', `COLUMN-${id}`);
	};

	const getEntityName = (id, columnId) => {
		const match = entities.find(entity => entity._id === id);
		const columnName = getColumnName(columnId);
		if (match) {
			return `${match.name} (${columnName})`;
		}
		return `ENTITY-${id} (${columnName})`;
	};

	const arrayToString = entries => {
		const strEntries = entries.map(entry => {
			switch (entry.type) {
				case 'operator':
				case 'constant':
					if (entry.dataType === 'rating') {
						const rating = agencyRatings.find(r => r.scale === entry.value);
						return rating ? `${rating.value}` : `${entry.value}`;
					} else {
						return `${entry.value}`;
					}
				case 'min':
				case 'max':
					// eslint-disable-next-line
					const columnNames = entry.value.map(id => getColumnName(id));
					return `${entry.type.toUpperCase()}(${columnNames.join(', ')})`;
				case 'column': {
					// sort by subtypes here
					let output = '';
					if (entry.entityId) {
						output = getEntityName(entry.entityId, entry.value);
					} else {
						output = getColumnName(entry.value);
					}
					if (parseInt(entry.offset)) {
						const offset = parseInt(entry.offset);
						output += ` (Offset ${offset > 0 ? '+' : ''}${offset} Period${Math.abs(offset) > 1 ? 's' : ''})`;
					}
					return output;
				}
				default:
					return '';
			}
		});
		return strEntries.join(' ');
	};

	const sections = formula.reduce(
		(accumulator, curVal) => {
			// On IF/THEN/ELSE create a new entry
			if (['?', ':'].includes(curVal.value)) {
				_.set(accumulator, `[${accumulator.length}]`, [curVal]);
			} else {
				const curSection = accumulator[accumulator.length - 1];
				curSection.push(curVal);
			}
			return accumulator;
		},
		[[]]
	);
	if (sections.length === 1) {
		return arrayToString(sections[0]);
	}
	const outputString = sections.reduce((output, curSection, index) => {
		// Test if this is the first element
		if (index === 0) {
			return output + 'CASE ' + arrayToString(curSection);
		}
		// Test for last section
		if (index === sections.length - 1) {
			return output + ' ELSE ' + arrayToString(curSection.slice(1));
		}

		if (curSection[0].value === '?') {
			return output + ' THEN ' + arrayToString(curSection.slice(1));
		} else if (curSection[0].value === ':') {
			return output + ' CASE ' + arrayToString(curSection.slice(1));
		}
		return output;
	}, '');
	return outputString;
};

module.exports = {
	operatorOptions,
	operatorValues,
	relationalOperatorOptions,
	relationalOperatorValues,
	logicOptions,
	logicValues,
	formulaToDisplayFramework,
	displayFrameworkToFormula,
	getDataTypeFromFramework,
	testHooks: {
		parseSimpleElements,
		composeSimpleElements,
		parseFormulaCase,
		composeFormulaCase,
		// Logic
		parseLogicElements,
		composeLogicFormula,
		parseBlockLogic,
		composeBlockLogic,
		// Numerics
		numericBlockFromFormula,
		numericFormulaFromBlock,
		parseNumericElements,
		composeNumericFormula,
		// Validation
		balancedParens,
	},
	validateFramework,
	numericFormulaToDisplayFramework,
	displayFrameworkToNumericFormula,
	formulaToString,
};
