import * as actionTypes from './actionTypes';

const setData = newData => {
	return dispatch => {
		dispatch({type: actionTypes.SET_ACCOUNT_DATA, newData});
	};
};

const setDataFromOriginal = newData => {
	return dispatch => {
		dispatch({type: actionTypes.SET_ACCOUNT_DATA_FROM_ORIGINAL, newData});
	};
};

const setOriginalData = newData => {
	return dispatch => {
		dispatch({type: actionTypes.SET_ACCOUNT_ORIGINAL, newData});
	};
};

const undoChanges = (index, originalIndex) => {
	return dispatch => {
		dispatch({type: actionTypes.ACCOUNT_UNDO, index, originalIndex});
	};
};

const undoPropertyChange = (index, property, originalIndex) => {
	return dispatch => {
		dispatch({type: actionTypes.ACCOUNT_UNDO_PROPERTY, index, property, originalIndex});
	};
};

const removeAccount = index => {
	return dispatch => {
		dispatch({type: actionTypes.ACCOUNT_REMOVE, index});
	};
};

const addAccount = () => {
	return dispatch => {
		dispatch({type: actionTypes.ACCOUNT_ADD});
	};
};

const changeAccount = (key, index, value, originalIndex) => {
	return dispatch => {
		dispatch({type: actionTypes.ACCOUNT_CHANGE, key, index, value, originalIndex});
	};
};

export {
	setData,
	setDataFromOriginal,
	setOriginalData,
	undoChanges,
	undoPropertyChange,
	removeAccount,
	addAccount,
	changeAccount,
};
