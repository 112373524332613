import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import manualIcon from '../icons/manualIcon';
import gearsIcon from '../icons/gearsIcon';

const ACTIONS = [
	{
		linkSuffix: 'manualEntry',
		title: 'Manual Entry',
		icon: manualIcon,
		className: 'wider',
	},
	{
		linkSuffix: 'generatePosts',
		title: 'Generate Posts',
		icon: gearsIcon,
	},
	{
		linkSuffix: 'configure',
		title: 'Configure',
		icon: 'build',
	},
];

const LedgerActions = ({id, match}) => {
	return (
		<div className="ledger-actions">
			{ACTIONS.map(({title, linkSuffix, icon, className}, i) => {
				const disabled = match.path.includes(linkSuffix);
				return (
					<Link
						key={i}
						title={title}
						to={`/ledgers/${id}/${linkSuffix}`}
						className={`${disabled ? 'disabled' : ''} ${className ? className : ''}`}
					>
						<i className="material-icons" dangerouslySetInnerHTML={{__html: icon}} />
					</Link>
				);
			})}
		</div>
	);
};

LedgerActions.propTypes = {
	id: PropTypes.string.isRequired,
	match: PropTypes.object,
};

export default withRouter(LedgerActions);
