import React from 'react';
import './KiStyleGuide.scss';
import {KiIconCsv, KiIconLink, KiIconMaxRecords, KiIconCard, KiAssociatedData} from 'components/KiIcons';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import KiChip from 'components/KiChip';
import KiInput from 'components/KiInput';
import KiRadioGroup from 'components/KiRadioGroup';
import KiRadioButton from 'components/KiRadioButton';
import KiList from 'components/KiList';
import KiListItem from 'components/KiList/KiListItem';
import KiButton from 'components/KiButton';
import KiAppBar from 'components/KiAppBar';
import KiProgressBar from 'components/KiProgressBar';
import KiCheckbox from 'components/KiCheckbox';
import KiMenu from 'components/KiMenu';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import KiModal from 'components/KiModal';
import KiDatePicker from 'components/KiDatePicker';
import KiConfirmModal from 'components/KiConfirmModal';
import Select from 'components/KiSelect';
/* eslint-disable no-console */

export class KiStyleGuide extends React.Component {
	state = {
		radioValue: '',
		showSnackBar: false,
		checkboxes: {
			first: false,
			second: true,
		},
		formModalActive: false,
		confirmationModalActive: false,
		currentStep: 1,
	};

	setCheckbox = (name, value) => {
		const state = this.state;
		state.checkboxes[name] = value;
		this.setState(state);
	};

	setStateItems = (stateName, items) => {
		const update = {};
		update[stateName] = items;
		this.setState(update);
	};

	render() {
		return (
			<div>
				<KiAppBar title="Ki Style Guide" className="style-guide-app-bar style-guide-nav-header h1-size">
					<KiMenu icon="more_vert" className="top-bar-menu" position="topRight">
						<KiMenuItem label="Color Pallete" onClick={() => ({})} />
						<KiMenuItem label="Fonts" onClick={() => ({})} />
						<KiMenuItem label="Typography" onClick={() => ({})} />
						<KiMenuItem label="Buttons" onClick={() => ({})} />
						<KiMenuItem label="Forms" onClick={() => ({})} />
						<KiMenuItem label="Controls" onClick={() => ({})} />
						<KiMenuItem label="Modals" onClick={() => ({})} />
					</KiMenu>
				</KiAppBar>
				<KiAppBar title="Color Pallete" className="style-guide-app-bar" />
				<div className="ki-panel">
					<ul className="style-guide-color-panel">
						<li>
							<span>
								Primary 1<br />#135E82
							</span>
							<div className="style-guide-color-swatch primary-bg-color-blue" />
						</li>
						<li>
							<span>
								Primary 2<br />#467527
							</span>
							<div className="style-guide-color-swatch primary-bg-color-green" />
						</li>
						<li>
							<span>
								Primary 3<br />#E98C03
							</span>
							<div className="style-guide-color-swatch primary-bg-color-orange" />
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>
								Secondary 1<br />#E3E9EE
							</span>
							<div className="style-guide-color-swatch secondary-bg-color-blue" />
						</li>
						<li>
							<span>
								Secondary 2<br />#EFEFEE
							</span>
							<div className="style-guide-color-swatch secondary-bg-color-white" />
						</li>
						<li>
							<span>
								Secondary 3<br />#0C5274
							</span>
							<div className="style-guide-color-swatch secondary-bg-color-dark-blue" />
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>
								Text Color 1<br />#FFFFFF
							</span>
							<div className="style-guide-color-swatch text-white" />
						</li>
						<li>
							<span>
								Text Color 2<br />#333333
							</span>
							<div className="style-guide-color-swatch text-dark-grey" />
						</li>
						<li>
							<span>
								Text Color 3<br />#CD0000
							</span>
							<div className="style-guide-color-swatch text-error-red" />
						</li>
						<li>
							<span>
								Text Color 4<br />#0B5D83
							</span>
							<div className="style-guide-color-swatch text-blue" />
						</li>
						<li>
							<span>
								Text Color 5<br />#E38F00
							</span>
							<div className="style-guide-color-swatch text-warning-orange" />
						</li>
					</ul>
				</div>
				<KiAppBar title="Fonts - Roboto" className="style-guide-app-bar" />
				<div className="ki-panel style-guide-typography">
					<ol className="h3-size">
						<li style={{fontFamily: 'Roboto', fontWeight: '100', fontStyle: 'normal'}}>
							Thin<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '100', fontStyle: 'italic'}}>
							Thin Italic<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '300', fontStyle: 'normal'}}>
							Light<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '300', fontStyle: 'italic'}}>
							Light Italic<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '400', fontStyle: 'normal'}}>
							Regular<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '400', fontStyle: 'italic'}}>
							Regular Italic<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '500', fontStyle: 'normal'}}>
							Medium<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '500', fontStyle: 'italic'}}>
							Medium Italic<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '700', fontStyle: 'normal'}}>
							Bold<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '700', fontStyle: 'italic'}}>
							Bold Italic<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '900', fontStyle: 'normal'}}>
							Black<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
						<li style={{fontFamily: 'Roboto', fontWeight: '900', fontStyle: 'italic'}}>
							Black Italic<br />ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 0123456789
						</li>
					</ol>
				</div>
				<KiAppBar title="Typography" className="style-guide-app-bar" />
				<div className="ki-panel">
					<ul>
						<li className="h1-size">Some H1 Heading Sized Text</li>
						<li className="h2-size">Some H2 Heading Sized Text</li>
						<li className="h3-size">Some H3 Heading Sized Text</li>
						<li className="p-large-size">Some Large Sized Paragraph Text</li>
						<li className="p-base-size">Some Base Sized Paragraph Text</li>
						<li className="p-fine-size">Some Fine Sized Paragraph Text</li>
					</ul>
				</div>
				<KiAppBar title="Iconography" className="style-guide-app-bar" />
				<div className="ki-panel style-guide-iconography">
					<ul className="style-guide-color-panel">
						<li>
							<span>Dashboard</span>
							<i className="material-icons">dashboard</i>
						</li>
						<li>
							<span>Dataset</span>
							<i className="material-icons">explore</i>
						</li>
						<li>
							<span>Report</span>
							<svg>
								<g transform="translate(1 3)">
									<g transform="translate(3 4)">
										<path d="M.84375 9.58985714C.609 9.81271429.41925 10.2512857.41925 10.5662857L.41925 14.6734286 2.9235 14.6734286 2.9235 8.18057143C2.9235 7.86557143 2.73375 7.78985714 2.499 8.01342857L.84375 9.58985714zM11.84475 5.69757143C11.61075 5.92042857 11.42025 6.35971429 11.42025 6.674L11.42025 14.6732857 13.9245 14.6732857 13.9245 4.28542857C13.9245 3.97042857 13.734 3.89542857 13.5 4.11828571L11.84475 5.69757143zM15.509775 2.20142857C15.275775 2.425 15.085275 2.86 15.085275 3.17571429L15.085275 14.6735714 17.414025 14.6735714 17.414025.962857143C17.414025.647142857 17.223525.572142857 16.989525.795L15.509775 2.20142857zM8.252925 9.11828571L8.000925 9.35828571C7.865925 9.48971429 7.751925 9.85185714 7.751925 10.1682857L7.751925 14.6732857 10.256925 14.6732857 10.256925 7.78185714C10.256925 7.46614286 10.066425 7.39114286 9.831675 7.61471429L8.252925 9.11828571zM4.512075 7.90807143C4.277325 7.6845 4.086825 7.7595 4.086825 8.0745L4.086825 14.6737857 6.591825 14.6737857 6.591825 10.4545C6.591825 10.1402143 6.410325 9.71235714 6.187575 9.50021429L5.786325 9.11807143 4.512075 7.90807143z" />
									</g>
									<path d="M1.33054615 16.6304889L.584238462 17.3890815C.367546154 17.6100444.194469231 17.988637.194469231 18.2328222L.194469231 18.6782667 2.7747 18.6782667 2.7747 15.7234148C2.7747 15.4137852 2.59885385 15.3384889 2.38216154 15.5594519L1.33054615 16.6304889zM17.3414769 6.40926296C17.4910154 6.56055926 17.6114769 6.50918889 17.6114769 6.29596667L17.6114769.762040741C17.6114769.367262963 17.2376308.0182259259 16.8506308.0182259259L11.4360923.0182259259C11.2256308.0182259259 11.1757846.141374074 11.3246308.293374074L13.2499385 2.25248519 12.4648615 3.04696667 8.82193846 6.75126296 6.89593846 4.79426296C6.74709231 4.64296667 6.50547692 4.64296667 6.35663077 4.79426296L1.00024615 10.2451519C.404861538 10.8496333.404861538 11.8320037 1.00024615 12.4350778L1.03693846 12.4751889C1.63301538 13.0796704 2.59809231 13.0796704 3.1914 12.4751889L6.62593846 8.9820037 8.55193846 10.9390037C8.70078462 11.0881889 8.9424 11.0881889 9.09124615 10.9390037L15.4438615 4.48252222 17.3414769 6.40926296z" />
								</g>
							</svg>
						</li>
						<li>
							<span>Funding</span>
							<i className="material-icons">account_balance</i>
						</li>
						<li>
							<span>Business Function</span>
							<i className="material-icons">map</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Filter</span>
							<svg viewBox="0 0 549.06 554.08">
								<path
									d="M246,380.78a34.19,34.19,0,0,1,9,23.14v252c0,15.16,18.3,22.86,29.13,12.2l70.29-80.55c9.41-11.29,14.59-16.87,14.59-28V404a34.46,34.46,0,0,1,9-23.14L579.73,162c15.11-16.42,3.48-43-18.87-43H63.21c-22.35,0-34,26.57-18.87,43Zm-33.38-6.34"
									transform="translate(-37.49 -118.96)"
								/>
							</svg>
						</li>
						<li>
							<span>Views</span>
							<svg viewBox="0 0 198.3 252.06">
								<path
									d="M411.64,12.28h-111A17.69,17.69,0,0,0,283,29.93v8.33h-8.33A17.69,17.69,0,0,0,257,55.92v8.34h-8.33A17.68,17.68,0,0,0,231,81.91V246.69a17.65,17.65,0,0,0,17.64,17.65h78a20.66,20.66,0,0,0,14.54-6l20.21-20h24.25A17.65,17.65,0,0,0,403.3,220.7v-8.34h8.34a17.65,17.65,0,0,0,17.65-17.65V29.93a17.68,17.68,0,0,0-17.66-17.65ZM363.38,212.71H342.95a17.65,17.65,0,0,0-17.66,17.66v20H248.65a3.73,3.73,0,0,1-3.71-3.71V81.91a3.73,3.73,0,0,1,3.71-3.72h111a3.71,3.71,0,0,1,3.71,3.7Zm26,8a3.73,3.73,0,0,1-3.72,3.71h-10a6.34,6.34,0,0,0,1.66-4.72V81.91a17.69,17.69,0,0,0-17.67-17.65H270.93V55.92a3.71,3.71,0,0,1,3.69-3.7h111a3.73,3.73,0,0,1,3.72,3.71Zm26-26a3.73,3.73,0,0,1-3.71,3.71H403.3V55.92a17.68,17.68,0,0,0-17.66-17.65H296.91V29.93a3.71,3.71,0,0,1,3.69-3.7h111a3.72,3.72,0,0,1,3.71,3.71Zm0,0"
									transform="translate(-231 -12.28)"
								/>
							</svg>
						</li>
						<li>
							<span>Calculations</span>
							<svg viewBox="0 0 259.4 259.4">
								<path
									d="M194.22,379.22H319.44V254H194.22ZM230,307.68H247.9V289.77a8.94,8.94,0,0,1,17.88,0v17.91h17.88a8.94,8.94,0,1,1,0,17.88H265.79v17.88a8.94,8.94,0,1,1-17.88,0V325.57H230a8.94,8.94,0,0,1,0-17.88Zm0,0"
									transform="translate(-194.22 -254)"
								/>
								<path
									d="M328.4,254V379.22H453.62V254Zm89.45,71.57H364.17a8.94,8.94,0,0,1,0-17.88h53.68a8.94,8.94,0,1,1,0,17.88Zm0,0"
									transform="translate(-194.22 -254)"
								/>
								<path
									d="M328.4,513.4H453.62V388.18H328.4Zm35.77-85h53.68a8.94,8.94,0,1,1,0,17.88H364.17a8.94,8.94,0,0,1,0-17.88Zm0,26.84h53.68a8.94,8.94,0,1,1,0,17.88H364.17a8.94,8.94,0,0,1,0-17.88Zm0,0"
									transform="translate(-194.22 -254)"
								/>
								<path
									d="M194.22,513.4H319.44V388.18H194.22Zm33.94-78.64a8.94,8.94,0,1,1,12.65-12.65l16,16,16.05-16a8.94,8.94,0,1,1,12.65,12.65l-16.05,16,16.05,16.05a8.95,8.95,0,0,1-6.33,15.27,8.84,8.84,0,0,1-6.31-2.62l-16.05-16.05-16,16.05a8.94,8.94,0,0,1-12.65-12.65l16-16.05Zm0,0"
									transform="translate(-194.22 -254)"
								/>
							</svg>
						</li>
						<li>
							<span>Global</span>
							<i className="material-icons">language</i>
						</li>
						<li>
							<span>Download CSV</span>
							<KiIconCsv />
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Download Pdf</span>
							<i className="material-icons">picture_as_pdf</i>
						</li>
						<li>
							<span>Copy Data Link</span>
							<KiIconLink />
						</li>
						<li>
							<span>Row Count</span>
							<KiIconMaxRecords />
						</li>
						<li>
							<span>Data Card</span>
							<KiIconCard />
						</li>
						<li>
							<span>Visualization</span>
							<i className="material-icons">show_chart</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Audit</span>
							<i className="material-icons">assignment_turned_in</i>
						</li>
						<li>
							<span>Maintenance</span>
							<i className="material-icons">build</i>
						</li>
						<li>
							<span>Notification</span>
							<i className="material-icons">notifications</i>
						</li>
						<li>
							<span>Dropdown Menu</span>
							<i className="material-icons">arrow_drop_down</i>
						</li>
						<li>
							<span>Inline Menu</span>
							<i className="material-icons">more_vert</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Add</span>
							<i className="material-icons">add_circle</i>
						</li>
						<li>
							<span>Profile</span>
							<i className="material-icons">person</i>
						</li>
						<li>
							<span>Admin</span>
							<i className="material-icons">people</i>
						</li>
						<li>
							<span>Logout</span>
							<i className="material-icons">exit_to_app</i>
						</li>
						<li>
							<span>Help</span>
							<i className="material-icons">help</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Delete</span>
							<i className="material-icons">delete</i>
						</li>
						<li>
							<span>Schema</span>
							<i className="material-icons">device_hub</i>
						</li>
						<li>
							<span>Blended Data</span>
							<i className="material-icons">all_inclusive</i>
						</li>
						<li>
							<span>Edit</span>
							<i className="material-icons">mode_edit</i>
						</li>
						<li>
							<span>Save</span>
							<i className="material-icons">save</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Settings</span>
							<i className="material-icons">settings</i>
						</li>
						<li>
							<span>Close/Cancel</span>
							<i className="material-icons">clear</i>
						</li>
						<li>
							<span>Lock</span>
							<i className="material-icons">lock_open</i>
						</li>
						<li>
							<span>Summary</span>
							<i className="material-icons">info_outline</i>
						</li>
						<li>
							<span>Time Series</span>
							<i className="material-icons">date_range</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Done</span>
							<i className="material-icons">check_circle</i>
						</li>
						<li>
							<span>Download</span>
							<i className="material-icons">cloud_download</i>
						</li>
						<li>
							<span>Upload</span>
							<i className="material-icons">cloud_upload</i>
						</li>
						<li>
							<span>Associated Data</span>
							<KiAssociatedData />
						</li>
						<li>
							<span>Processing</span>
							<i className="material-icons">sync</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Error</span>
							<i className="material-icons">priority_high</i>
						</li>
						<li>
							<span>Open</span>
							<i className="material-icons">keyboard_arrow_right</i>
						</li>
						<li>
							<span>Close</span>
							<i className="material-icons">keyboard_arrow_left</i>
						</li>
						<li>
							<span>Open Vertical</span>
							<i className="material-icons">keyboard_arrow_down</i>
						</li>
						<li>
							<span>Close Vertical</span>
							<i className="material-icons">keyboard_arrow_up</i>
						</li>
					</ul>
					<ul className="style-guide-color-panel">
						<li>
							<span>Copy</span>
							<i className="material-icons">content_copy</i>
						</li>
						<li>
							<span>Undo</span>
							<i className="material-icons">undo</i>
						</li>
					</ul>
				</div>
				<KiAppBar title="Buttons" className="style-guide-app-bar" />
				<div className="ki-panel style-guide-buttons">
					<span>Primary</span>
					<hr />
					<ul>
						<li>
							<KiButton primary flat>
								Close/Cancel
							</KiButton>
							<KiButton primary raised>
								Submit/Save
							</KiButton>
						</li>
						<li>
							<KiButton label="Icon" primary flat icon="explore" />
							<KiButton label="Icon" primary raised icon="explore" />
						</li>
					</ul>
					<span>Secondary</span>
					<hr />
					<ul>
						<li>
							<KiButton neutral flat>
								Close/Cancel
							</KiButton>
							<KiButton neutral raised>
								Submit/Save
							</KiButton>
						</li>
						<li>
							<KiButton label="Icon" neutral flat icon="explore" />
							<KiButton label="Icon" neutral raised icon="explore" />
						</li>
					</ul>
					<span>Labels</span>
					<hr />
					<ul>
						<li>
							<KiButton neutral flat label="A label" />
							<KiButton neutral flat label="Icon" icon="explore" />
						</li>
					</ul>
					<span>Disabled</span>
					<hr />
					<ul>
						<li>
							<KiButton primary flat disabled={true}>
								Close/Cancel Disabled
							</KiButton>
							<KiButton primary raised disabled={true}>
								Submit/Save Disabled
							</KiButton>
						</li>
					</ul>
				</div>
				<KiAppBar title="Controls" className="style-guide-app-bar" />
				<div className="ki-panel style-guide-controls">
					<span>Tabs</span>
					<hr />
					<ul>
						<li>
							<KiTabs className="custom-style" index={1}>
								<KiTab title="Tab One" />
								<KiTab title="Tab Two" />
								<KiTab disabled={true} title="Tab Three" />
							</KiTabs>
						</li>
					</ul>
					<span>Loaders</span>
					<hr />
					<ul>
						<li>
							<KiProgressBar />
						</li>
						<li>
							<div className="ldsSpinner">
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
							</div>
						</li>
					</ul>
					<span>Chips</span>
					<hr />
					<ul>
						<li>
							<KiChip icon="check_circle" iconColor="#FFFFFF" iconBackgroundColor="#38761d">
								<span style={{textTransform: 'capitalize'}}>Complete</span>
							</KiChip>
						</li>
						<li>
							<KiChip icon="sync" iconColor="#FFFFFF" iconBackgroundColor="#f36f2b">
								<span style={{textTransform: 'capitalize'}}>Processing</span>
							</KiChip>
						</li>
						<li>
							<KiChip icon="priority_high" iconColor="#FFFFFF" iconBackgroundColor="#c3062e	">
								<span style={{textTransform: 'capitalize'}}>Error</span>
							</KiChip>
						</li>
					</ul>
					<span>Select</span>
					<hr />
					<ul>
						<li>
							<Select
								classNamePrefix="aut-select"
								value={this.state.logic}
								isClearable={false}
								options={[
									{label: 'Option 1', value: 'option1'},
									{label: 'Option 2', value: 'option2'},
									{label: 'Option 3', value: 'option3'},
									{label: 'Option 4', value: 'option4'},
								]}
								onChange={selected => this.setState({logic: selected})}
							/>
						</li>
					</ul>
					<span>Multi Select</span>
					<hr />
					<ul>
						<li>
							<Select
								classNamePrefix="aut-select"
								value={this.state.logic2}
								isClearable={false}
								isMulti={true}
								options={[
									{label: 'Option 1', value: 'option1'},
									{label: 'Option 2', value: 'option2'},
									{label: 'Option 3', value: 'option3'},
									{label: 'Option 4', value: 'option4'},
								]}
								onChange={selected => this.setState({logic2: selected})}
							/>
						</li>
					</ul>
					<span>Input</span>
					<hr />
					<ul>
						<li>
							<KiInput
								label="KiInput Label"
								className="flibberty-jibbet"
								hint="This is your hint" // Will hide if value is not empty
								type="text"
							/>
							<br />
							<br />
							<br />
							<KiInput
								className="flibberty-jibbet"
								disabled={false}
								defaultValue="some text"
								error="Error Message"
								floating={true}
								label="KiInput Label"
								maxLength={10}
								onBlur={e => console.log('KiInput.onBlur', e.target.value)}
								onChange={value => console.log('KiInput.onChange', value)}
								onFocus={e => console.log('KiInput.onFocus', e.target.value)}
								onKeyPress={e => console.log('KiInput.onKeyPress', e.target.value)}
								required={true}
								type="text"
								isNumberMasked={false}
								maskConfig={null}
							/>
						</li>
					</ul>
					<span>Checkbox</span>
					<hr />
					<ul>
						<li>
							<KiCheckbox
								checked={this.state.checkboxes.first}
								label="First"
								onChange={this.setCheckbox.bind(this, 'first')}
							/>
							<KiCheckbox
								checked={this.state.checkboxes.second}
								label="Second"
								onChange={this.setCheckbox.bind(this, 'second')}
							/>
						</li>
					</ul>
					<span>Radio Button</span>
					<hr />
					<ul>
						<li>
							<KiRadioGroup
								value={this.state.radioValue}
								onChange={value => {
									this.setState({radioValue: value});
								}}
							>
								<KiRadioButton label="Option 1" value="one" />
								<KiRadioButton label="Option 2" value="two" />
								<KiRadioButton label="Option 3" value="three" />
							</KiRadioGroup>
						</li>
					</ul>
					<span>Date Picker</span>
					<hr />
					<ul>
						<li>
							<KiDatePicker
								label="Date Picker Label 1"
								onBlur={val => this.setState({datepicker1: val})}
								onChange={val => this.setState({datepicker1: val})}
								value={this.state.datepicker1}
							/>
							<KiDatePicker
								label="Date Picker Label 2"
								dateFormat="MM/dd/yyyy"
								placeholderText="MM/DD/YYYY"
								onBlur={val => this.setState({datepicker2: val})}
								onChange={val => this.setState({datepicker2: val})}
								value={this.state.datepicker2}
							/>
						</li>
					</ul>
					<span>Menus</span>
					<hr />
					<ul>
						<li>
							<KiMenu icon="more_vert" position="topLeft">
								<KiMenuItem icon="face" label="Account" />
								<KiMenuItem icon="explore" label="Explore" />
								<KiMenuItem disabled={true} icon="extension" label="Explore" />
							</KiMenu>
							<KiMenu icon="keyboard_arrow_down">
								<KiMenuItem label="Item One" />
								<KiMenuItem label="Item Two" />
								<KiMenuItem disabled={true} label="Item Three" />
							</KiMenu>
						</li>
					</ul>
					<span>List</span>
					<hr />
					<ul>
						<li>
							<KiList selectable>
								<KiListItem caption="Item One" />
								<KiListItem caption="Item Two" />
								<KiListItem caption="Item Three" disabled />
							</KiList>
						</li>
					</ul>
					<span>Modals</span>
					<hr />
					<ul>
						<li>
							<KiButton primary onClick={() => this.setState({formModalActive: true})}>
								Form Modal
							</KiButton>
							<KiModal
								actions={[
									{label: 'Cancel', onClick: () => this.setState({formModalActive: false})},
									{label: 'Save', onClick: () => this.setState({formModalActive: false})},
								]}
								active={this.state.formModalActive}
								onClose={() => this.setState({formModalActive: false})}
								header="some big form header"
							>
								<h2>Big form contents</h2>
							</KiModal>
							<KiButton primary onClick={() => this.setState({confirmationModalActive: true})}>
								Confirmation Modal
							</KiButton>
							<KiConfirmModal
								active={this.state.confirmationModalActive}
								acceptFunc={() => this.setState({confirmationModalActive: false})}
								rejectFunc={() => this.setState({confirmationModalActive: false})}
								acceptLabel="Delete"
								rejectLabel="Cancel"
								header="Delete Item"
								message="Are you sure?"
							/>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default KiStyleGuide;
