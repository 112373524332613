import KiChart from './KiChart';
const _get = require('lodash/get');
const _set = require('lodash/set');
const _defaultsDeep = require('lodash/defaultsDeep');

/*
Pie Charts need alternate series data
series: [{
	name: 'Balance',
	colorByPoint: true,
	data: [{
		name: 'Microsoft Internet Explorer',
		y: 56.33
	}, {
		name: 'Chrome',
		y: 24.03
	},
}]
 */

class KiPieChart extends KiChart {
	buildParamConfig() {
		const config = this.params;

		const originalData = _get(config, 'series[0].data', []);
		const newData = config.categories.map((val, index) => ({
			name: val,
			y: originalData[index],
		}));

		const series = [
			{
				name: _get(config, 'series[0].name', 'DEFAULT'),
				colorByPoint: true,
				data: newData,
			},
		];

		const paramConfig = {
			chart: {
				type: 'pie',
				height: config.height,
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
					},
				},
			},
			title: {
				text: config.chartTitle,
			},
			series,
		};

		switch (config.chartType) {
			case 'pie':
				_set(paramConfig, 'plotOptions.pie.showInLegend', true);
				_set(paramConfig, 'plotOptions.pie.dataLabels.enabled', false);
				break;
			case '3d-pie':
				_set(paramConfig, 'chart.options3d', {
					enabled: true,
					alpha: 45,
					beta: 0,
				});
				_set(paramConfig, 'plotOptions.pie.depth', 35);
				break;
			case '3d-donut':
				_set(paramConfig, 'chart.options3d', {
					enabled: true,
					alpha: 45,
				});
				_set(
					paramConfig,
					'plotOptions.pie',
					_defaultsDeep(
						{
							depth: 45,
							innerSize: 150,
						},
						paramConfig.plotOptions.pie
					)
				);
				break;
			case 'semi-circle-donut':
				_set(
					paramConfig,
					'plotOptions.pie',
					_defaultsDeep(
						{
							startAngle: -90,
							endAngle: 90,
							center: ['50%', '75%'],
							innerSize: 150,
							dataLabels: {
								distance: -50,
								style: {
									fontWeight: 'bold',
									color: 'white',
								},
							},
						},
						paramConfig.plotOptions.pie
					)
				);
				break;
			case 'pie-gradient':
				// Color changes happen at rendering level using Highcharts.setOptions
				_set(paramConfig, 'plotOptions.pie.colors', this.generateGradientColors());
				break;
			case 'pie-monochrome':
				_set(paramConfig, 'plotOptions.pie.colors', this.generateMonochromeColors());
				break;
		}

		return paramConfig;
	}
}

export default KiPieChart;
