import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';
import analytics from 'analytics';

export const fetchCritieraByDataset = datasetId => {
	analytics.sendEvent('global-criteria', 'get-by-id');
	return fetch(`${apiUrl}/datasetsCriteria/dataset/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const addCriteria = criteria => {
	analytics.sendEvent('global-criteria', 'create');
	return fetch(`${apiUrl}/datasetsCriteria/`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(criteria),
	}).then(_handleResponse);
};

export const updateCriteria = criteria => {
	analytics.sendEvent('global-criteria', 'update');
	return fetch(`${apiUrl}/datasetsCriteria/${criteria._id.toString()}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(criteria),
	}).then(_handleResponse);
};

export const deleteCriteria = criteriaId => {
	analytics.sendEvent('global-criteria', 'delete');
	return fetch(`${apiUrl}/datasetsCriteria/${criteriaId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const validateDatasetCriteriaName = (datasetId, name) => {
	analytics.sendEvent('global-criteria', 'check-unique-name');
	return fetch(`${apiUrl}/datasetsCriteria/dataset/${datasetId}/validateName/${name}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchCritieraByDataset,
	addCriteria,
	updateCriteria,
	deleteCriteria,
	validateDatasetCriteriaName,
};
