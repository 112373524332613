const getConstraints = () => ({
	'rowType': {
		presence: {allowEmpty: false},
	},
	'fundingVehicleId': {
		presence: {allowEmpty: false},
	},
	'name': {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 100,
			message: 'must be between 1 to 100 characters'
		}
	},
	'value': {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 100,
			message: 'must be no more than 100 characters'
		}
	},
});

module.exports = {
	getConstraints
};
