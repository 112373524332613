import React, {useState} from 'react';
import PropTypes from 'prop-types';
import KiCheckbox from '../../../../../components/KiCheckbox';
import KiSelect from '../../../../../components/KiSelect';
import KiInput from '../../../../../components/KiInput';
import KiButton from '../../../../../components/KiButton';
import './LedgerAccountsConfiguration.scss';
import {connect} from 'react-redux';
import {changeAccount, removeAccount, undoChanges, undoPropertyChange} from './actions';
import KiTooltip from '../../../../../components/KiTooltip';
import KiConfirmModal from '../../../../../components/KiConfirmModal';

const SELECT_VALUES = {
	ASSET: {
		label: 'Asset',
		value: 'ASSET',
	},
	LIABILITY: {
		label: 'Liability',
		value: 'LIABILITY',
	},
	EQUITY: {
		label: 'Equity',
		value: 'EQUITY',
	},
	INCOME: {
		label: 'Income',
		value: 'INCOME',
	},
	EXPENSE: {
		label: 'Expense',
		value: 'EXPENSE',
	},
};

const SELECT_VALUES_OPTIONS = [
	SELECT_VALUES.ASSET,
	SELECT_VALUES.LIABILITY,
	SELECT_VALUES.EQUITY,
	SELECT_VALUES.INCOME,
	SELECT_VALUES.EXPENSE,
];

const TYPES = {
	ACTIVE: 'active',
	TYPE: 'type',
	DESCRIPTION: 'description',
	CODE: 'code',
};

function AccountsConfigureItem({item, index, undoChanges, undoPropertyChange, removeAccount, changeAccount}) {
	const {_id, active, valid, modified, originalIndex, type, code, description, isUsed} = item;
	const [usedPropCleared, setUsedPropCleared] = useState(false);

	const onBlur = prop => {
		if (isUsed && item[prop].trim() === '') {
			setUsedPropCleared(prop);
		}
	};

	const onUsedPropRevert = () => {
		undoPropertyChange(index, usedPropCleared, originalIndex);
		setUsedPropCleared(false);
	};

	return (
		<tr className="scenario-list-item">
			<td className="active-select-item">
				<div className="active-with-info">
					<KiCheckbox
						className="select-item-checkbox"
						checked={active}
						disabled={!valid || isUsed}
						onChange={value => changeAccount(TYPES.ACTIVE, index, value, originalIndex)}
					/>
					{!valid && (
						<KiTooltip
							message="Configure completely to activate"
							maxWidth={400}
							direction="right"
							className="ledger-info warning"
						>
							<i className="material-icons">info</i>
						</KiTooltip>
					)}
				</div>
			</td>
			<td>
				<KiSelect
					className="select"
					value={type ? SELECT_VALUES[type] : ''}
					options={SELECT_VALUES_OPTIONS}
					onChange={value => changeAccount(TYPES.TYPE, index, value.value, originalIndex)}
				/>
			</td>
			<td>
				<KiInput
					type="text"
					name="description"
					value={description}
					onChange={value => changeAccount(TYPES.DESCRIPTION, index, value, originalIndex)}
					onBlur={() => onBlur(TYPES.DESCRIPTION)}
					placeholder="Account Description"
				/>
			</td>
			<td>
				<KiInput
					type="text"
					name="code"
					value={code}
					onChange={value => changeAccount(TYPES.CODE, index, value, originalIndex)}
					onBlur={() => onBlur(TYPES.CODE)}
					placeholder="Account Code"
				/>
			</td>
			<td className="align-right ledger-actions">
				<div className="button-wrapper">
					{_id && (
						<KiButton
							disabled={_id ? !modified : true}
							icon="undo"
							iconButton
							onClick={() => undoChanges(index, originalIndex)}
						/>
					)}
					<KiButton disabled={isUsed} icon="delete" iconButton onClick={() => removeAccount(index)} />
					{/* Putting modal here so that it does't break table html structure */}
					<KiConfirmModal
						message="The account is used for a template or manual entry and value cannot be left blank."
						acceptFunc={onUsedPropRevert}
						acceptLabel="Ok"
						active={usedPropCleared !== false}
					/>
				</div>
			</td>
		</tr>
	);
}

AccountsConfigureItem.propTypes = {
	item: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	undoChanges: PropTypes.func,
	undoPropertyChange: PropTypes.func,
	removeAccount: PropTypes.func,
	changeAccount: PropTypes.func,
};

export default connect(null, {undoChanges, undoPropertyChange, removeAccount, changeAccount})(AccountsConfigureItem);
