// Globals
import React from 'react';
import PropTypes from 'prop-types';

// Project imports

// Website imports
import KiChip from 'components/KiChip';

// Website api

// Local imports

/**
 * Generates a chip with the correct icons and hex colors based on color string
 * @param {string} options.color Color of the icon to be shown
 * @param {string} options.name  Text for the chip to be shown
 */
function LogStatusAvatar(props) {
	const {color, name} = props.status;
	let hexColor, icon;

	switch (color) {
		case 'yellow':
			icon = 'priority_high';
			hexColor = '#f36f2b';
			break;
		case 'green':
			icon = 'check';
			hexColor = '#38761d';
			break;
		case 'red':
			icon = 'priority_high';
			hexColor = '#c3062e';
			break;
		default:
			icon = 'sync';
			hexColor = '#195F80';
	}

	return (
		<KiChip icon={icon} iconColor="#FFFFFF" iconBackgroundColor={hexColor}>
			<span style={{textTransform: 'capitalize', fontSize: '1.25rem'}}>{name}</span>
		</KiChip>
	);
}

LogStatusAvatar.propTypes = {
	status: PropTypes.object,
};

LogStatusAvatar.defaultProps = {
	status: {
		color: 'yellow',
		name: 'N/A',
	},
};

export default LogStatusAvatar;
