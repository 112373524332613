/*
Constraint Type: Exclude (Inverted Filter)
Name: Is the display name of the Constraint entered by the user. This must be unique
Data Column: Is selected from a list of available columns in the dataset and reflects the column of data that will be constrained
Logic: can be one of the following mathematical operator used for the constraint =,<>,<,>,<=,>=,Between, in, not in, is null, is not null
Target Min: Numeric minimum required
Target Max: Numeric Max required
Selection: List of alfaNumeric values to be considered as part of a list
Flex: Default value the target is allowed to expand as a % below the min or above the max
Constraint: By default this is set to checked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
Covenant: By default this is set to unchecked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
 */

const _defaultsDeep = require('lodash/defaultsDeep');
const ccBase = require('./ccBase');

const baseContraints = ccBase.getConstraints();
const getConstraints = () => {
	return _defaultsDeep(
		{
			rowType: {
				presence: {allowEmpty: false},
				format: {
					pattern: '^ccExclude$',
					flags: 'g',
					message: 'rowType must be ccExclude',
				},
			},
			selection: {
				presence: false,
			},
			concentration: {
				presence: false,
			},
		},
		baseContraints
	);
};

module.exports = {
	getConstraints,
};
