import * as actionTypes from './actionTypes';
import {fundingVehiclesApi} from 'api';

function fetchFundingVehicleList(datasetId) {
	return dispatch => {
		dispatch({type: actionTypes.FUNDING_VEHICLE_LIST_FETCH, status: 'pending'});

		return fundingVehiclesApi
			.fetchFundingVehicleList(datasetId)
			.then(fundingVehicleList => {
				dispatch({
					type: actionTypes.FUNDING_VEHICLE_LIST_FETCH,
					status: 'success',
					fundingVehicleList,
				});
				return fundingVehicleList;
			})
			.catch(err => dispatch({type: actionTypes.FUNDING_VEHICLE_LIST_FETCH, status: 'error', msg: err}));
	};
}

export {fetchFundingVehicleList};
