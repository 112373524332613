const dateStringRegex = require('../../utils/dateHelpers').dateStringRegex;

const getConstraints = () => ({
	'name': {
		presence: {allowEmpty: false},
	},
	'snapshotDate': {
		presence: {allowEmpty: false},
		format: {
			pattern: dateStringRegex,
			flags: 'i',
		},
	},
});

module.exports = {
	getConstraints
};
