import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiTooltip from 'components/KiTooltip';
import calcColumns from 'ki-common/utils/calcColumns';
import {getSortAscFuncByField} from 'ki-common/utils/sorters';
import './ColumnList.scss';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import {columnServiceApi} from 'api';

const ViewLink = props => {
	const isGlobal = !props.col.createdBy;
	const isDisabled = props.isDisabled || (!props.isAdmin && isGlobal && !props.allowAll);
	const classNamesString = classNames({
		'material-icons': true,
		'list-icon-button': isDisabled,
		disabled: isDisabled,
	});

	const handleClick = (...args) => {
		if (!isDisabled) {
			return props.onClick(...args);
		}
	};

	return (
		<div className="list-icon-btn">
			<i title={props.title} alt={props.alt} className={classNamesString} onClick={handleClick}>
				{props.iconName}
			</i>
		</div>
	);
};

ViewLink.defaultProps = {
	onClick: () => null,
};

ViewLink.propTypes = {
	col: PropTypes.object,
	isAdmin: PropTypes.bool,
	allowAll: PropTypes.bool,
	title: PropTypes.string,
	alt: PropTypes.string,
	iconName: PropTypes.string,
	onClick: PropTypes.func,
	isDisabled: PropTypes.bool,
};

export class ColumnList extends Component {
	static propTypes = {
		columns: PropTypes.array.isRequired,
		copyColumn: PropTypes.func.isRequired,
		editColumn: PropTypes.func.isRequired,
		deleteColumn: PropTypes.func.isRequired,
		user: PropTypes.object,
		allColumns: PropTypes.array.isRequired,
		isDebt: PropTypes.bool,
		datasetId: PropTypes.string.isRequired,
	};

	state = {
		calculationPreview: 'calculating...',
	};

	setFormulaPreview = columnId => {
		return columnServiceApi
			.getColumnByIdFromService(this.props.datasetId, columnId, true)
			.then(col => {
				let formulaString = 'Unable to generate';
				const formulaArray = [];
				if (col.columnType === 'aggregate') {
					formulaString = calcColumns.nodeToString(col);
					if (col.calculation === 'RATIO') {
						const leftColId = col.formula.left.value;
						const leftCol = this.props.allColumns.find(element => {
							return element._id === leftColId;
						});
						formulaString = formulaString.replace(leftColId, leftCol.displayName);
						const rightColId = col.formula.right.value;
						const rightCol = this.props.allColumns.find(element => {
							return element._id === rightColId;
						});
						formulaString = formulaString.replace(rightColId, rightCol.displayName);
					}

					// Date and Text strings
				}
				if (col.columnFormType === 'cumulative') {
					const nestedColumn = col.asOfDateColumn;
					if (nestedColumn) {
						formulaString = `accumulate '${nestedColumn.displayName}' for ${col.cumulativeRange} periods`;
					}
				}
				if (col.formula) {
					formulaString = calcColumns.nodeToString(col.formula);
				}

				// Calculated numeric columns
				if (col.columnFormType === 'numeric' && !!col.formula && !col.formula.op) {
					col.formula.forEach(item => {
						// Add operator to formulaArray array
						if (item.type === 'min' || item.type === 'max') {
							formulaArray.push(item.type);
							// Map over values array
							item.value.forEach((column, index) => {
								const foundColumn = this.props.allColumns.find(cl => cl._id === column);

								if (index === 0) {
									formulaArray.push('(');
								}

								formulaArray.push(_get(foundColumn, 'displayName'));

								if (index !== item.value.length - 1) {
									formulaArray.push(', ');
								}

								if (index === item.value.length - 1) {
									formulaArray.push(')');
								}
							});
						}

						if (item.type === 'column') {
							const foundColumn = this.props.allColumns.find(cl => cl._id === item.value);
							formulaArray.push(_get(foundColumn, 'displayName', 'Missing Column'));
						}

						if (item.type === 'operator' || item.type === 'constant') {
							formulaArray.push(item.value);
						}
						formulaString = formulaArray.join('');
					});
				}

				if (_get(col, 'formula.op') === 'parentData') {
					const aggregateColumnOptionName = _get(col.formula, 'left.value.assetColumn.value.displayName');
					const calculateColumnName = _get(
						col.formula,
						'left.value.calculateColumn.value.displayName',
						'....'
					);
					const parentColumnName = _get(col.formula, 'left.value.parentColumn.value.displayName', '....');
					const aggregationType = _get(col.formula, 'left.value.aggregationType');
					if (aggregationType === 'WGHTAVG') {
						formulaString = `Weighted average (${aggregateColumnOptionName} by ${calculateColumnName}) by ${parentColumnName}`;
					} else if (aggregationType === 'MAX' || aggregationType === 'MIN') {
						formulaString = `${aggregationType} (${aggregateColumnOptionName}) by ${parentColumnName}`;
					} else {
						formulaString = `${aggregationType} (${aggregateColumnOptionName}) by ${parentColumnName}`;
					}
				}
				this.setState({calculationPreview: formulaString});
			})
			.catch(() => {
				this.setState({calculationPreview: 'Unable to generate'});
			});
	};

	resetFormulaPreview = () => {
		this.setState({calculationPreview: 'calculating...'});
	};

	render() {
		const {columns, copyColumn, editColumn, deleteColumn, user, isDebt} = this.props;
		if (isDebt && !columns.length) {
			return <p>No Columns Found</p>;
		}
		const isAdmin = user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		const systemNameRegex = /^ki*.*/;

		return (
			<section className="column-list">
				{columns.sort(getSortAscFuncByField('displayName')).map(col => {
					const isGlobal = col.createdBy && !col.isWaterfall ? false : true;
					const isDisabled = !!col.availableForDebt || systemNameRegex.test(col.columnName);

					return (
						<div
							key={col._id}
							className="column-list-item"
							onMouseEnter={() => this.setFormulaPreview(col._id)}
							onMouseLeave={this.resetFormulaPreview}
						>
							<div className="list-icon-btn icons-cell-left no-rollover">
								<i title={isGlobal ? 'Global' : 'User'} className="material-icons">
									{isGlobal ? 'language' : 'person'}
								</i>
							</div>
							<div className="column-name" title={col.displayName}>{`${col.displayName}${
								col.calculation || col.aggregation ? ` - ${col.calculation || col.aggregation}` : ''
							}`}</div>
							<div className="icons-cell-right">
								{!isDebt && (
									<KiTooltip message={this.state.calculationPreview} offsets={{left: 6}}>
										<i alt="Calculation" className="material-icons">
											info_outline
										</i>
									</KiTooltip>
								)}
								<ViewLink
									isAdmin={isAdmin}
									allowAll={!isDebt}
									title="Copy"
									alt="Copy Column"
									iconName="content_copy"
									onClick={() => copyColumn(col)}
									col={col}
								/>
								<ViewLink
									isAdmin={isAdmin}
									allowAll={!!isDebt}
									title="Edit"
									alt="Edit Column"
									iconName="mode_edit"
									onClick={() => editColumn(col)}
									col={col}
								/>
								<ViewLink
									isAdmin={isAdmin}
									isDisabled={isDisabled}
									allowAll={false}
									title="Delete"
									alt="Delete Column"
									iconName="delete"
									onClick={() => deleteColumn(col)}
									col={col}
								/>
							</div>
						</div>
					);
				})}
			</section>
		);
	}
}

export default ColumnList;
