import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import RootReducer from './reducers';

const loadInitialState = () => {
	return fetch(`web/me`, {credentials: 'include'})
		.then(response => {
			if (response.status !== 200) {
				return Promise.reject();
			}
			return response.json();
		})
		.then(user => {
			return {
				user,
			};
		})
		.catch(() => {
			return {
				user: {
					groups: [],
				},
			};
		});
};

// ======================================================
// Redux Dev Tools
// ======================================================
const enhancers = [];
if (window.DEV_TOOLS_ENABLED === 'true') {
	const {__REDUX_DEVTOOLS_EXTENSION__} = window;
	if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
		enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
	}
}

const createMainStore = () => {
	return loadInitialState().then(initialState => {
		const middleware = [thunk];

		const store = createStore(RootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
		if (module.hot) {
			// Enable Webpack hot module replacement for reducers
			module.hot.accept('./reducers', () => {
				const nextRootReducer = require('./reducers/index');
				store.replaceReducer(nextRootReducer);
			});
		}

		return store;
	});
};

export default createMainStore;
