import PropTypes from 'prop-types';
import React from 'react';
import KiListItem from 'components/KiList/KiListItem';

export const TimeSeriesLink = props => {
	if (
		props.col.columnType === 'aggregate' &&
		props.tableType !== 'timeSeries' &&
		!props.groupBy &&
		props.snapshotType === 'standard'
	) {
		return (
			<KiListItem
				className={props.className}
				caption="Time Series"
				icon="date_range"
				onClick={() => props.handleClick(props.col.columnName, props.col.calculation)}
			/>
		);
	}
	return null;
};

TimeSeriesLink.propTypes = {
	tableType: PropTypes.string,
	col: PropTypes.object,
	handleClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	groupBy: PropTypes.any,
	snapshotType: PropTypes.string,
};

export default TimeSeriesLink;
