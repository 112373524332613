export const valueTypeOptions = [
	{
		label: 'Column',
		value: 'column',
		isDebt: true,
		isWaterfall: true,
	},
	{
		label: 'Function',
		value: 'function',
		isDebt: true,
		isWaterfall: false,
	},
	{
		label: 'Text Constant',
		value: 'string',
		isDebt: true,
		isWaterfall: true,
	},
	{
		label: 'Numeric Constant',
		value: 'numeric',
		isDebt: true,
		isWaterfall: true,
	},
	{
		label: 'Date Constant',
		value: 'date_long',
		isDebt: true,
		isWaterfall: true,
	},
	{
		label: 'Boolean',
		value: 'boolean',
		isDebt: true,
		isWaterfall: true,
	},
	{
		label: 'Child Column',
		value: 'subAccountColumn',
		isDebt: false,
		isWaterfall: false,
	},
	{
		label: 'Integer Constant',
		value: 'posInt',
		isDebt: false,
		isWaterfall: true,
	},
	{
		label: 'Rating',
		value: 'rating',
		isDebt: true,
		isWaterfall: true,
	},
];

export default {
	valueTypeOptions,
};
