export const gearsIcon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 21 24">
  <defs>
    <clipPath id="clip-path">
      <path id="Path_13" data-name="Path 13" d="M2.789-69.221,1.767-67.683l.745.745L4.051-67.96l.557.231.365,1.811H6.027l.365-1.811.557-.231,1.539,1.022.745-.745L8.211-69.221l.231-.557,1.811-.365V-71.2l-1.811-.365-.231-.557,1.022-1.539L8.488-74.4,6.949-73.381l-.557-.231-.365-1.811H4.973l-.365,1.811-.557.231L2.513-74.4l-.745.745,1.022,1.539-.231.557L.748-71.2v1.054l1.811.365ZM5.5-71.8A1.126,1.126,0,0,1,6.626-70.67,1.126,1.126,0,0,1,5.5-69.544,1.126,1.126,0,0,1,4.374-70.67,1.126,1.126,0,0,1,5.5-71.8Z" transform="translate(-0.748 75.422)" fill="#195e83"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <path id="Path_15" data-name="Path 15" d="M7.617,3.327,8.653,1.792l-.766-.751L6.321,2.056l-.57-.234L5.368.006,4.291,0,3.929,1.812l-.567.228L1.784,1.007l-.757.742L2.08,3.3l-.232.556L0,4.207.006,5.263l1.852.376L2.1,6.2,1.061,7.733l.766.751L3.393,7.469l.57.234.383,1.816,1.077.006.363-1.812.567-.228L7.93,8.518l.757-.742L7.634,6.229l.232-.556,1.848-.355L9.708,4.262,7.856,3.886ZM4.863,5.89A1.155,1.155,0,0,1,3.707,4.756,1.123,1.123,0,0,1,4.85,3.635,1.155,1.155,0,0,1,6.007,4.769,1.123,1.123,0,0,1,4.863,5.89Z" transform="translate(0 0)" fill="#195e83"/>
    </clipPath>
    <clipPath id="clip-Web_1920_1">
      <rect width="24" height="24"/>
    </clipPath>
  </defs>
  <g id="Web_1920_1" data-name="Web 1920 – 1" clip-path="url(#clip-Web_1920_1)">
    <rect width="24" height="24" fill="none"/>
    <path id="file" d="M1473.867,2758H1460v23.728h20v-16.365Zm4.133,21.355h-16v-18.982h10v7.118h6Z" transform="translate(-1460 -2758)" fill="#195e83"/>
    <g id="Group_1" data-name="Group 1" transform="matrix(0.966, -0.259, 0.259, 0.966, 21.022, 79.016)">
      <g id="Group_45" data-name="Group 45" transform="matrix(0.986, -0.269, 0.259, 0.966, -2.6, -73.897)" clip-path="url(#clip-path-2)">
        <path id="Path_15" data-name="Path 15" d="M0,0,11.655.065l.065,11.429L.065,11.429Z" fill="#195e83"/>
      </g>
      <g id="Group_41" data-name="Group 41" transform="matrix(0.966, -0.259, 0.259, 0.966, 1.208, -66.297)" clip-path="url(#clip-path-2)">
        <path id="Path_14" data-name="Path 14" d="M0,0,11.655.065l.065,11.429L.065,11.429Z" transform="translate(-1.004 -0.985)" fill="#195e83"/>
      </g>
    </g>
  </g>
</svg>
`;

export default gearsIcon;
