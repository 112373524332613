import PropTypes from 'prop-types';
import React from 'react';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';

export const DataColumnDropdown = props => {
	const {value = '', error = '', onChange, dataTypeFilter = () => true, cohortColumns = []} = props;
	const classBase = 'cc-column-dropdown';

	const options = cohortColumns.reduce((acc, cc) => {
		if (dataTypeFilter(cc)) {
			return [
				...acc,
				{
					value: cc._id,
					label: cc.displayName,
				},
			];
		}
		return acc;
	}, []);

	return (
		<div>
			<label className={`${classBase}-label mock-react-toolkit`}>Data Column</label>
			<TriggerFunctionSelector
				className={classBase}
				targetFunction={val => onChange(val)}
				options={options}
				searchable={true}
				startingValue={value}
				sortOrder="asc"
			/>
			<span className="setting-error">{error}</span>
		</div>
	);
};

DataColumnDropdown.propTypes = {
	value: PropTypes.any,
	error: PropTypes.string,
	onChange: PropTypes.func,
	cohortColumns: PropTypes.array,
	dataTypeFilter: PropTypes.func,
};

export default DataColumnDropdown;
