import React, {Fragment, useEffect, useState} from 'react';
import '../LedgerConfigure.scss';
import './LedgerSetup.scss';
import {useParams} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formatDate, isDateValid} from '../../../../../utils/dateUtils';
import {fetchLedger, updateLedger} from '../../../../../api/ledgersApi';
import LedgerConfigureHeader from '../LedgerConfigureHeader';
import {CONFIGURE_ACTIONS} from '../LedgerConfigure';
import KiInput from '../../../../../components/KiInput';
import KiDatePicker from '../../../../../components/KiDatePicker';
import {showSnackbar} from '../../../../../state/actions/Snackbar';
import Loader from '../../../Loader';

function LedgerSetup({setView, showSnackbar}) {
	const [ledger, setLedger] = useState(null);
	const [originalLedger, setOriginalLedger] = useState(null);
	const [closeDate, setCloseDate] = useState(null);
	const [closeDateError, setCloseDateError] = useState('');
	const [isCloseDateValidOnBlur, setCloseDateValidOnBlur] = useState(true);
	const [exportCode, setExportCode] = useState('');
	const [isLoading, setLoading] = useState(true);
	const {id} = useParams();

	const setDefault = ledger => {
		setLedger(ledger);
		setExportCode(ledger.exportCode);
		setCloseDate(formatDate(ledger.closeDate));
	};

	const onSave = () => {
		setLoading(true);
		setCloseDateError('');

		if (isDateValid(closeDate) && isCloseDateValidOnBlur) {
			updateLedger(id, closeDate, exportCode).then(({data}) => {
				setOriginalLedger(data);
				setDefault(data);
				showSnackbar('All configuration changes have been saved.');
				setLoading(false);
			});
		} else {
			setCloseDateError('Close Date must be correct');
			showSnackbar('Please fill the fields correctly');
			setLoading(false);
		}
	};

	const BUTTON_ACTIONS = [
		{
			label: 'CANCEL',
			isFlat: true,
			onClick: () => {
				showSnackbar('All configuration changes have been cancelled.');
				setDefault(originalLedger);
			},
		},
		{
			label: 'SAVE',
			onClick: onSave,
		},
	];

	useEffect(() => {
		fetchLedger(id).then(ledger => {
			setOriginalLedger(ledger);
			setDefault(ledger);
			setLoading(false);
		});
	}, []);

	return (
		<Fragment>
			<section className="ki-panel">
				<LedgerConfigureHeader
					currentView={CONFIGURE_ACTIONS.SETUP}
					setView={setView}
					buttonActions={BUTTON_ACTIONS}
				/>
			</section>
			<section className="ki-panel ledger-configure-panel">
				<Loader isActive={isLoading}>
					<div className="ledger-configure-wrapper">
						<div className="setup">
							{ledger && (
								<Fragment>
									<div>
										<h5>Dataset</h5>
										<span>{ledger.datasetName}</span>
									</div>
									<div>
										<h5 className={!ledger.fundingVehicleName ? 'disabled' : ''}>
											Funding Vehicle
										</h5>
										<span>{ledger.fundingVehicleName && ledger.fundingVehicleName}</span>
									</div>
									<div>
										<h5>Ledger Export Code</h5>
										<KiInput
											value={exportCode ? exportCode : ''}
											onChange={value => setExportCode(value)}
										/>
									</div>
									<div>
										<h5>Close Date</h5>
										<KiDatePicker
											className="datepicker"
											value={closeDate}
											onChange={date => setCloseDate(formatDate(date))}
											onBlur={date => setCloseDateValidOnBlur(isDateValid(date))}
											error={closeDateError}
										/>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</Loader>
			</section>
		</Fragment>
	);
}

LedgerSetup.propTypes = {
	setView: PropTypes.func.isRequired,
};

LedgerSetup.propTypes = {
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(null, {showSnackbar})(LedgerSetup);
