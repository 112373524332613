import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {KiInput} from 'components/KiInput';
import {addFormError, removeFormError, addOneBandStep, updateOneBandStep, removeOneBandStep} from './actions';
import styles from './ViewForms.theme.scss';
import _uniqueId from 'lodash/uniqueId';
import CohortBandsOddRow from './CohortBandsOddRow';

export class CohortBandsOdd extends Component {
	static propTypes = {
		view: PropTypes.object,
		addOneBandStep: PropTypes.func.isRequired,
		removeOneBandStep: PropTypes.func.isRequired,
		addFormError: PropTypes.func.isRequired,
		removeFormError: PropTypes.func.isRequired,
		setFormHasChanges: PropTypes.func,
	};

	static defaultProps = {
		view: {},
	};

	state = {
		newStart: '',
		newEnd: '',
		newStep: '',
	};

	validateInputRowComplete = () => {
		return this.state.newStart && this.state.newEnd && this.state.newStep;
	};

	addRow = () => {
		const bandSize = Number(this.state.newEnd) - Number(this.state.newStart);
		if (bandSize < Number(this.state.newStep)) {
			this.props.addFormError('custom-odd-step-new', 'greater than band');
			return;
		} else if (bandSize / Number(this.state.newStep) > 1000) {
			this.props.addFormError('custom-odd-step-new', '1000 steps max');
			return;
		} else {
			this.props.removeFormError('custom-odd-step-new');
		}
		this.props.addOneBandStep({
			start: this.state.newStart,
			end: this.state.newEnd,
			step: this.state.newStep,
		});
		this.setState({newStart: ''});
		this.setState({newEnd: ''});
		this.setState({newStep: ''});
		this.props.removeFormError('custom-odd');
		this.props.setFormHasChanges();
	};

	deleteRow = index => {
		this.props.removeOneBandStep(index);
		if (this.props.view.bands.steps.length === 1) {
			this.props.addFormError('custom-odd', '');
		}
		this.props.setFormHasChanges();
	};

	render() {
		return (
			<div className={styles.customOddBands}>
				<div className={styles.insertRow}>
					<KiInput
						name="custom-odd-start-new"
						label="Starting Band"
						type="text"
						value={this.state.newStart}
						onChange={val => this.setState({newStart: val})}
						error={this.props.view.formErrors[`custom-odd-start-new`]}
						isNumberMasked={true}
					/>
					<KiInput
						name="custom-odd-end-new"
						label="Ending Band"
						type="text"
						value={this.state.newEnd}
						onChange={val => this.setState({newEnd: val})}
						error={this.props.view.formErrors[`custom-odd-end-new`]}
						isNumberMasked={true}
					/>
					<KiInput
						name="custom-odd-step-new"
						label="Granularity"
						type="text"
						value={this.state.newStep}
						onChange={val => this.setState({newStep: val})}
						error={this.props.view.formErrors[`custom-odd-step-new`]}
						isNumberMasked={true}
					/>
					<i
						className={`material-icons ${this.validateInputRowComplete() ? '' : styles.disabled}`}
						onClick={this.addRow}
					>
						add_circle
					</i>
				</div>
				{this.props.view.bands.steps.map((band, index) => {
					return (
						<div key={_uniqueId()} className={styles.editRow}>
							<div className={styles.number}>{index + 1}</div>
							<CohortBandsOddRow
								band={band}
								index={index}
								addFormError={this.props.addFormError}
								removeFormError={this.props.removeFormError}
								setFormHasChanges={this.props.setFormHasChanges}
							/>
							<i className="material-icons" onClick={() => this.deleteRow(index)}>
								delete
							</i>
						</div>
					);
				})}
				<span className={styles.error}>{this.props.view.formErrors['custom-odd']}</span>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	view: state.viewForm,
});

const mapDispatchToProps = () => ({
	addOneBandStep,
	updateOneBandStep,
	removeOneBandStep,
	addFormError,
	removeFormError,
});

export default connect(mapStateToProps, mapDispatchToProps())(CohortBandsOdd);
