import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ContextIcons from 'components/ContextSidebar/icons';
import {connect} from 'react-redux';
import ContextSidebar from '../../../components/ContextSidebar';
import EditViewMenu from './EditViewMenu';
import ManageViews from './ManageViews';
import FlyoutCalculations from 'components/FlyoutCalculations';
import {fetchDebtData} from '../actions';
import FlyoutDates from 'components/FlyoutDates';
import {KiIconCard} from 'components/KiIcons';
import FlyoutCardList from 'components/FlyoutCardList';
import ManageTriggers from 'containers/dealStructures/components/ManageTriggers';

class DebtContextSidebar extends Component {
	static propTypes = {
		user: PropTypes.object,
		fetchDebtData: PropTypes.func.isRequired,
		datasetId: PropTypes.string,
		bookmark: PropTypes.object,
	};

	render() {
		return (
			<ContextSidebar
				items={[
					{
						name: 'Edit View',
						icon: <ContextIcons.MaterialIcon name="settings" />,
						element: <EditViewMenu />,
					},
					{
						name: 'Views',
						icon: <ContextIcons.ViewsIcon />,
						element: <ManageViews />,
					},
					{
						name: 'Calculations',
						icon: <ContextIcons.CalculationsIcon />,
						element: <FlyoutCalculations user={this.props.user} reloadView={this.props.fetchDebtData} />,
					},
					{
						name: 'Manage Dates',
						icon: <ContextIcons.MaterialIcon name="date_range" />,
						element: <FlyoutDates />,
					},
					{
						name: 'Manage Cards',
						icon: <KiIconCard />,
						element: (
							<FlyoutCardList
								datasetId={this.props.datasetId}
								bookmark={this.props.bookmark}
								explorerViewType="debt"
							/>
						),
					},
					{
						name: 'Triggers',
						icon: <ContextIcons.MaterialIcon name="playlist_add_check" />,
						element: <ManageTriggers user={this.props.user} datasetId={this.props.datasetId} />,
					},
				]}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

export default connect(
	mapStateToProps,
	{fetchDebtData}
)(DebtContextSidebar);
