// Globals
import React, {useContext} from 'react';

// Website imports
import KiButton from '../../../components/KiButton';

// Local imports
import styles from './dataExplorerQuickFilters.theme.scss';
import {DataExplorerContext} from '../DataExplorerContext';
import {QuickFilterSettingContext} from '../contexts/QuickFilterSettingContext';
import Breadcrumbs from '../components/ExplorerBreadcrumbs';

export const DataExplorerQuickFilters = () => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark,
		bookmark: {explorerData},
		actions,
		hasChanges,
		renderState: {isLoading},
		applyChanges,
	} = dataExplorerContext;
	const quickFilterSettingContext = useContext(QuickFilterSettingContext);
	const {
		getViewTypeDropdown,
		getCohortDropdown,
		getGroupByDropdown,
		getStatementDateInput,
		getDateContextDropdown,
		getStartDateContextDropdown,
		getEndDateContextDropdown,
		getScenarioContextDropdown,
		getFundingVehicleDropdown,
		getPoolDropdown,
		getTimeSeriesColumnSelector,
		getTimeSeriesDisplayPeriodSelector,
		getTimeSeriesPeriodSelector,
	} = quickFilterSettingContext;

	return (
		<div className={styles.quickFilterContainer}>
			<section className={styles.quickFilterRow}>
				<div className={styles.filters}>
					<div className={styles.filterDropdown}>
						<span className={styles.themeLabel}>View Type</span>
						{getViewTypeDropdown()}
					</div>

					{explorerData.snapshotType !== 'encumbrance' && (
						<>
							<div className={styles.filterDropdown}>
								<span className={styles.themeLabel}>Cohort</span>
								{getCohortDropdown()}
							</div>
							<div className={styles.filterDropdown}>
								<span className={styles.themeLabel}>Group By</span>
								{getGroupByDropdown()}
							</div>
						</>
					)}

					<div className={styles.filterDropdown}>
						<span className={styles.themeLabel}>Statement Date</span>
						{getStatementDateInput(styles.explorerDatePicker)}
					</div>

					{!explorerData.isFixedDate &&
						explorerData.snapshotType !== 'encumbrance' && (
							<div className={styles.filterDropdown}>
								<span className={styles.themeLabel}>Date Context</span>
								{getDateContextDropdown()}
							</div>
						)}

					{explorerData.snapshotType === 'encumbrance' && (
						<>
							<div className={styles.filterDropdown}>
								<span className={styles.themeLabel}>Start Date Context</span>
								{getStartDateContextDropdown()}
							</div>
							<div className={styles.filterDropdown}>
								<span className={styles.themeLabel}>End Date Context</span>
								{getEndDateContextDropdown()}
							</div>
						</>
					)}

					<div className={styles.filterDropdown}>
						<span className={styles.themeLabel}>Scenario Context</span>
						{getScenarioContextDropdown(styles.filterDropdown)}
					</div>
					<div className={styles.filterDropdown}>
						<span className={styles.themeLabel}>Funding Vehicle</span>
						{getFundingVehicleDropdown(styles.filterDropdown)}
					</div>

					{explorerData.snapshotType !== 'encumbrance' && (
						<div className={styles.filterDropdown}>
							<span className={styles.themeLabel}>Pool</span>
							{getPoolDropdown(styles.filterDropdown)}
						</div>
					)}
					<div className="quick-filter-action">
						<KiButton label="Apply" primary disabled={!hasChanges || isLoading} onClick={applyChanges} />
					</div>
				</div>
			</section>
			<section className={styles.quickFilterRowTwo}>
				<Breadcrumbs
					data={bookmark.explorerData.breadcrumbs}
					onDelete={actions.deleteBreadcrumb}
					onChange={actions.updateBreadcrumb}
					disabled={isLoading}
				/>
				{bookmark.explorerData.tableType === 'timeSeries' && [
					getTimeSeriesColumnSelector(),
					getTimeSeriesDisplayPeriodSelector(),
					getTimeSeriesPeriodSelector(),
				]}
			</section>
		</div>
	);
};

DataExplorerQuickFilters.propTypes = {};

export default DataExplorerQuickFilters;
