import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiModal from 'components/KiModal';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import moment from 'moment';
import './dashboardManager.scss';
import _xorBy from 'lodash/xorBy';
import {withRouter} from 'react-router-dom';
import _isEqual from 'lodash/isEqual';

export class DashboardManager extends Component {
	static propTypes = {
		mode: PropTypes.string,
		active: PropTypes.bool,
		closeModal: PropTypes.func,
		currentDashboard: PropTypes.object,
		currentCards: PropTypes.array,
		allCards: PropTypes.array,
		addDashboard: PropTypes.func,
		updateDashboard: PropTypes.func,
		loadPageData: PropTypes.func,
		showDeleteConfirmModal: PropTypes.func,
		showSnackbar: PropTypes.func,
		dashboards: PropTypes.array,
	};

	state = {
		dashboardName: '',
		selectedCards: [],
		allCards: [],
		makeDefault: false,
		deleteDashboard: false,
		nameError: null,
	};

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			const {currentDashboard, allCards, currentCards} = this.props;
			if (this.props.mode === 'edit') {
				if (!currentDashboard || !currentDashboard.name) {
					return;
				}
				this.setState({
					nameError: null,
					dashboardName: currentDashboard.name,
					selectedCards: this.props.currentCards.slice() || [],
					allCards: _xorBy(allCards, currentCards || [], 'name'),
					makeDefault: currentDashboard.isDefault,
					deleteDashboard: false,
				});
			} else {
				this.setState({
					nameError: null,
					dashboardName: '',
					selectedCards: [],
					allCards: allCards,
					makeDefault: false,
					deleteDashboard: false,
				});
			}
		}
	}

	onNameChange = value => {
		const {mode, dashboards, currentDashboard} = this.props;
		let newNameError = null;
		if (!value) {
			newNameError = 'Name is required';
		} else if (
			(mode === 'edit' &&
				dashboards.find(dashboard => dashboard.name === value && dashboard._id !== currentDashboard._id)) ||
			(mode === 'add' && dashboards.find(dashboard => dashboard.name === value))
		) {
			newNameError = 'Name is not unique';
		}
		this.setState({dashboardName: value, nameError: newNameError});
	};

	submit = () => {
		const {selectedCards, makeDefault, deleteDashboard, dashboardName, nameError} = this.state;
		const {currentDashboard, updateDashboard, addDashboard, closeModal, showSnackbar, loadPageData} = this.props;
		if (!nameError) {
			const request = {
				name: dashboardName,
				lastModified: new Date(),
				isDefault: makeDefault,
				gadgets: selectedCards.map(card => card._id),
			};
			if (this.props.mode === 'edit') {
				request._id = currentDashboard._id;
				if (deleteDashboard) {
					closeModal();
					this.props.showDeleteConfirmModal();
				} else {
					return updateDashboard(request, selectedCards).then(() => {
						showSnackbar('Dashboard successfully updated');
						loadPageData();
						closeModal();
					});
				}
			} else {
				return addDashboard(request)
					.then(() => {
						showSnackbar('Dashboard successfully added');
						closeModal();
					})
					.catch(() => {
						showSnackbar('Dashboard adding error');
					});
			}
		}
	};

	removeCheckedCard = (value, card) => {
		let {selectedCards, allCards} = this.state;

		if (value) {
			selectedCards.push(card);
			allCards = allCards.filter(g => g.name !== card.name);
		} else {
			allCards.push(card);
			selectedCards = selectedCards.filter(g => g.name !== card.name);
		}
		this.setState({selectedCards, allCards});
	};

	getCardList = () => {
		if (!this.state.selectedCards.length && !this.state.allCards.length) {
			return <div>No Cards have been created!</div>;
		}
		return (
			<div className="card-list">
				<ul className="cards-selected">
					{this.state.selectedCards.map((card, idx) => {
						let usedText = `Used in ${card.dashboards.length} dashboards`;
						if (card.dashboards.length === 1) {
							usedText = usedText.slice(0, -1);
						}
						return (
							<li key={idx}>
								<div className="card-list-title">
									<KiCheckbox
										checked={true}
										label={card.name}
										onChange={val => this.removeCheckedCard(val, card)}
									/>
									<span>{`Created by:  ${card.createdBy} ${moment(card.createdAt).format(
										'YYYY/MM/DD'
									)} ${usedText}`}</span>
								</div>
							</li>
						);
					})}
				</ul>
				<ul className="cards-not-selected">
					{this.state.allCards.map((card, idx) => {
						let usedText = `Used in ${card.dashboards.length} dashboards`;
						if (card.dashboards.length === 1) {
							usedText = usedText.slice(0, -1);
						}
						return (
							<li key={idx}>
								<div className="card-list-title">
									<KiCheckbox
										checked={false}
										label={card.name}
										onChange={val => this.removeCheckedCard(val, card)}
									/>
									<span>{`Created by:  ${card.createdBy} ${moment(card.createdAt).format(
										'YYYY/MM/DD'
									)} ${usedText}`}</span>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	};

	getCardControls = () => {
		const {makeDefault, deleteDashboard} = this.state;
		if (this.props.mode === 'edit') {
			return (
				<div className="bottom-controls">
					<KiCheckbox
						checked={makeDefault}
						label="Make Default"
						onChange={val => this.setState({makeDefault: val})}
					/>
					<KiCheckbox
						checked={deleteDashboard}
						label="Delete Dashboard"
						onChange={val => this.setState({deleteDashboard: val})}
					/>
				</div>
			);
		} else {
			return (
				<div className="bottom-controls">
					<KiCheckbox
						checked={makeDefault}
						label="Make Default"
						onChange={val => this.setState({makeDefault: val})}
					/>
				</div>
			);
		}
	};

	render() {
		const {mode, active, closeModal} = this.props;
		const {dashboardName, nameError} = this.state;

		return (
			<div id="DashboardManager">
				<KiModal
					actions={[
						{label: 'Cancel', onClick: closeModal},
						{label: 'Save', onClick: this.submit, disabled: !dashboardName || !!nameError},
					]}
					active={active}
					header={`${mode === 'edit' ? 'Dashboard Editor' : 'Create a Dashboard'}`}
					className="ki-modal fv-modal"
					bodyClassName="dashboardModalBody"
					onClose={closeModal}
				>
					<KiInput
						type="text"
						className="dashboard-name"
						error={this.state.nameError}
						hint="Dashboard Name"
						floating={false}
						maxLength={50}
						value={dashboardName}
						onChange={this.onNameChange}
					/>
					<p
						className={
							this.state.allCards.length || this.state.selectedCards.length
								? 'cardLabel'
								: 'hidden cardLabel'
						}
					>
						Choose your Cards/Gadgets:
					</p>
					{this.getCardList()}
					{this.getCardControls()}
				</KiModal>
			</div>
		);
	}
}

export default withRouter(DashboardManager);
