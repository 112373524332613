// Globals
import React, {useState, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
// Project imports
import {showSnackbar} from 'state/actions/Snackbar';
import KiWizard from 'components/KiWizard';
import WizardStep from 'components/KiWizard/components/WizardStep';
// Local imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import {upsertModel, deleteModel} from 'api/fundingAnalysisApi';
import styles from '../fundingAnalysis.theme.scss';
import WizardStepOne from './WizardStepOne';
import WizardStepTwo from './WizardStepTwo';
import WizardStepThree from './WizardStepThree';

/**
 * [FundingAnalysis description]
 */
function FundingModelWizard() {
	const history = useHistory();
	const dispatch = useDispatch();
	const userId = useSelector(state => state.user.userId);
	const dataset = useSelector(state => state.datasetList.selected);
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	// const isLoading = useSelector(state => state.datasetDates.isLoading);

	const [wizardStep, setWizardStep] = useState(1);
	const [showSpinner, setShowSpinner] = useState(false);

	const saveFundingModel = (isPending = true) => {
		const modelToSave = {
			name: fundingAnalysisContext.name,
			createdBy: userId,
			datasetId: dataset.datasetId,
			isFractional: fundingAnalysisContext.isFractional,
			includePrevAssets: fundingAnalysisContext.includePrevAssets,
			isBlended: fundingAnalysisContext.isBlended,
			isTopoff: fundingAnalysisContext.isTopoff,
			groupBy: fundingAnalysisContext.groupBy,
			type: fundingAnalysisContext.type,
			optimization: fundingAnalysisContext.optimization,
			constraintGroups: fundingAnalysisContext.constraintGroups || [],
			fvSources: fundingAnalysisContext.fvSources.map(s => {
				return {
					fvId: s._id || s.fvId,
					fvName: s.fvName,
					priority: s.priority,
					isUnencumbered: s.isUnencumbered,
				};
			}),
			fvTargets: fundingAnalysisContext.fvTargets.map(s => {
				return {
					fvId: s._id || s.fvId,
					fvName: s.fvName,
					priority: s.priority,
					isUnencumbered: s.isUnencumbered,
				};
			}),
			isPending: isPending,
		};
		if (fundingAnalysisContext._id) {
			modelToSave._id = fundingAnalysisContext._id;
		}
		setShowSpinner(true);
		return upsertModel(_.cloneDeep(modelToSave))
			.then(res => {
				setShowSpinner(false);
				fundingAnalysisContext.setModelItem('_id', res._id.toString());
				if (wizardStep === 3) {
					history.push(`/fundingAnalysis/${dataset.datasetId}/models`);
					dispatch(showSnackbar('Model successfully created.'));
				}
			})
			.catch(err => {
				fundingAnalysisContext.addError(`${modelToSave.name}: ${err.message}`);
				// history.push(`/fundingAnalysis/${dataset.datasetId}/models`);
				// dispatch(showSnackbar("Error creating funding model. Please try again later."));
			});
	};

	const onStepCancel = async () => {
		//remove funding model that got saved between steps 1 and 2
		if (fundingAnalysisContext._id) {
			await deleteModel(fundingAnalysisContext._id);
		}
		fundingAnalysisContext.setModel({});
		history.push(`/fundingAnalysis/${dataset.datasetId}/models`);
	};

	const onStepNext = async () => {
		if (wizardStep === 1) {
			if (!fundingAnalysisContext._id) {
				return saveFundingModel().then(() => {
					setWizardStep(2);
				});
			} else {
				setWizardStep(2);
			}
		} else if (wizardStep === 2) {
			setWizardStep(3);
		} else if (wizardStep === 3) {
			saveFundingModel(false);
			fundingAnalysisContext.setStateItem('isCopy', false);
		}
	};

	const onStepBack = () => {
		if (wizardStep === 3) {
			setWizardStep(2);
		} else if (wizardStep === 2) {
			setWizardStep(1);
		}
	};

	const disableStep1NextButton = () => {
		if (!fundingAnalysisContext.name) {
			// fundingAnalysisContext.setValidation('nameError', 'Name is required');
			return true;
		}
		if (!fundingAnalysisContext.fvSources.length) {
			// fundingAnalysisContext.setValidation('showSourcesError', true);
			return true;
		}
		if (!fundingAnalysisContext.fvTargets.length) {
			// fundingAnalysisContext.setValidation('showTargetsError', true);
			return true;
		}
		if (fundingAnalysisContext.allModels.find(m => m.name == fundingAnalysisContext.name)) {
			return true;
		}
		return false;
	};

	const disableStep2NextButton = () => {
		if (!fundingAnalysisContext.groupBy || !fundingAnalysisContext.optimization) {
			return true;
		}
		return false;
	};

	return (
		<div className={styles.root}>
			<KiWizard
				currentStepNumber={wizardStep}
				onNext={onStepNext}
				onPrevious={onStepBack}
				numberOfSteps={3}
				showSpinner={showSpinner}
			>
				<WizardStep
					cancelButtonLabel={'Cancel'}
					nextButtonLabel={'Next'}
					onCancelClick={() => onStepCancel()}
					onNextClick={onStepNext}
					showBackButton={false}
					showCancelButton={true}
					showNextButton={true}
					title={'Name and Sources/Targets'}
					enableBackButton={false}
					disableNextButton={disableStep1NextButton()}
				>
					<WizardStepOne />
				</WizardStep>
				<WizardStep
					cancelButtonLabel={'Cancel'}
					nextButtonLabel={'Next'}
					onCancelClick={() => onStepCancel()}
					onNextClick={onStepNext}
					onBackClick={onStepBack}
					showBackButton={true}
					showCancelButton={true}
					showNextButton={true}
					title={'Options and Constraints'}
					enableBackButton={true}
					disableNextButton={disableStep2NextButton()}
				>
					<WizardStepTwo />
				</WizardStep>
				<WizardStep
					cancelButtonLabel={'Cancel'}
					nextButtonLabel={'Save'}
					onCancelClick={() => onStepCancel()}
					onNextClick={onStepNext}
					onBackClick={onStepBack}
					showBackButton={true}
					showCancelButton={true}
					showNextButton={true}
					title={'Review and Save'}
					enableBackButton={true}
				>
					<WizardStepThree />
				</WizardStep>
			</KiWizard>
		</div>
	);
}

export default FundingModelWizard;
