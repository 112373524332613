export const checkNameValid = (cards, name, id) => {
	const match = cards.find(c => c.name.trim() === name.trim() && c._id !== id);
	const isEmpty = name.trim().length === 0 ? true : false;
	if (match) return `Name already in use`;
	if (isEmpty) return `Name can't be blank`;
	return '';
};

export default {
	checkNameValid,
};
