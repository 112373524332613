// Globals
import React, {useState, useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';

// Project imports
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import KiIconButton from 'components/KiIconButton';
import KiList from 'components/KiList';
import KiListItem from 'components/KiList/KiListItem';
import {KiSelect} from 'components/KiSelect';
import {getConstraintGroups, upsertConstraintGroup} from 'api/fundingAnalysisApi';
import {getGroupByColumnsForDataset} from 'api/columnServiceApi';
import {showSnackbar} from 'state/actions/Snackbar';

// Local imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import ConstraintsModal from './ConstraintsModal';
import styles from './fundingModelWizard.theme.scss';

/**
 * [WizardStepTwo description]
 */
export const WizardStepTwo = () => {
	// Browser State
	const {datasetId} = useParams();

	// Redux State
	const dispatch = useDispatch();

	// Context State
	const fundingAnalysisContext = useContext(FundingAnalysisContext);

	// Local State
	const optimizationTypes = [
		{label: 'First Compliant', value: 'first'},
		{label: 'First Break', value: 'best'},
		{label: 'Minimize Excess', value: 'minimize_excess'},
	];
	const modelTypes = [
		{label: 'Balanced Funding', value: 'balanced_funding'},
		{label: 'Max Funding', value: 'max_funding'},
	];

	const [isConstraintModalActive, setIsConstraintModalActive] = useState(false);
	const [groupByOptions, setGroupByOptions] = useState([]);
	const [groupByEnabled, setGroupByEnabled] = useState(true);
	const [blendedEnabled, setBlendedEnabled] = useState(true);

	// On Mount
	useEffect(
		() => {
			async function fetchConstraintGroups() {
				const constraintGroups = await getConstraintGroups(fundingAnalysisContext._id);
				fundingAnalysisContext.setModelItem('constraintGroups', constraintGroups);
				// BLD-22218 - If hitting back from step 3, retain the optimization type
				fundingAnalysisContext.setModelItem(
					'optimization',
					fundingAnalysisContext.optimization || 'minimize_excess'
				);
			}
			fetchConstraintGroups();
		},
		[isConstraintModalActive]
	);

	useEffect(
		() => {
			const controller = new AbortController();
			async function fetchGroupByOptions() {
				const groupByCols = await getGroupByColumnsForDataset(datasetId, controller.signal);
				setGroupByOptions(groupByCols);
				if (!fundingAnalysisContext.groupBy) {
					const assetCol = groupByCols.find(c => c.isAssetNumberMap);
					fundingAnalysisContext.setModelItem('groupBy', assetCol._id);
				}
			}
			fetchGroupByOptions().catch(err => {
				if (err.name === 'AbortError') return null;
			});
			return () => controller.abort();
		},
		[] // Only on load
	);

	const checkForDrawdown = () => {
		const isSourceNotUnencumbered = fundingAnalysisContext.fvSources.find(fv => !fv.isUnencumbered);
		const isTartgetUnencumbered = fundingAnalysisContext.fvTargets.find(fv => fv.isUnencumbered);
		return !!(isSourceNotUnencumbered && isTartgetUnencumbered);
	};

	const onConstraintGroupSave = async groupToSave => {
		try {
			await upsertConstraintGroup(groupToSave);
			setIsConstraintModalActive(false);
			const allGroups = await getConstraintGroups(groupToSave.fundingModelId);
			fundingAnalysisContext.setModelItem('constraintGroups', allGroups);
		} catch (err) {
			dispatch(showSnackbar('Error saving Constraint Group'));
		}
	};

	useEffect(
		() => {
			async function setTopoffFlag() {
				const isDrawdown = checkForDrawdown();
				fundingAnalysisContext.setModelItem('isTopoff', fundingAnalysisContext.isTopoff && !isDrawdown);
			}
			setTopoffFlag();
		},
		[] // Only on load
	);

	return (
		<div className={styles.root}>
			<FundingAnalysisContext.Consumer>
				{fundingAnalysisContext => (
					<React.Fragment>
						<div className={styles.header}>
							<KiInput
								type="text"
								name="name"
								label="Model Name"
								error={''}
								value={fundingAnalysisContext.name}
								disabled={true}
								maxLength={100}
								className={styles.modelName}
							/>
						</div>
						<div className={styles.modelOptions}>
							<div className={styles.selectOptions}>
								<div className={styles.sectionHeader}>
									<p className={styles.detailSectionHeader}>BOOLEAN OPTIONS</p>
								</div>
								<div className={styles.sectionContent} style={{padding: '2rem'}}>
									{/*}
									<KiCheckbox
										checked={fundingAnalysisContext.isFractional}
										label="Fractional"
										onChange={val => fundingAnalysisContext.setModelItem('isFractional', val)}
									/>*/}
									<KiCheckbox
										checked={fundingAnalysisContext.includePrevAssets}
										label="Include Assets From Previous Submit"
										onChange={val => fundingAnalysisContext.setModelItem('includePrevAssets', val)}
									/>
									<KiCheckbox
										checked={fundingAnalysisContext.isBlended}
										label="Blended"
										disabled={!blendedEnabled}
										onChange={val => {
											setGroupByEnabled(!val);
											fundingAnalysisContext.setModelItem(
												'groupBy',
												groupByOptions.find(c => c.isAssetNumberMap)
											);
											fundingAnalysisContext.setModelItem('isBlended', val);
										}}
									/>
									<KiCheckbox
										checked={fundingAnalysisContext.isTopoff}
										label="Topoff"
										disabled={checkForDrawdown()}
										onChange={val => fundingAnalysisContext.setModelItem('isTopoff', val)}
									/>
								</div>
							</div>
							<div className={styles.selectOptions}>
								<div className={styles.sectionHeader}>
									<p className={styles.detailSectionHeader}>SELECT OPTIONS</p>
								</div>
								<div className={styles.sectionContent} style={{padding: '2rem'}}>
									<div style={{marginTop: '2rem'}} className={'theme-label'}>
										Type
									</div>
									<KiSelect
										isDisabled={fundingAnalysisContext.fvTargets.length > 1 || checkForDrawdown()}
										name={'cohortColumn'}
										options={
											fundingAnalysisContext.fvSources.length === 1 &&
											fundingAnalysisContext.fvSources[0].fvName === 'Unencumbered'
												? [modelTypes[1]]
												: modelTypes
										}
										getOptionValue={option => option.value}
										getOptionLabel={option => option.label}
										value={modelTypes.find(opt => opt.value === fundingAnalysisContext.type)}
										onChange={async val => {
											fundingAnalysisContext.setModelItem('type', val.value);
											//'best' is disabled for 'balanced_funding', so if it is already selected, select something else
											if (
												val.value === 'balanced_funding' &&
												fundingAnalysisContext.optimization === 'best'
											) {
												fundingAnalysisContext.setModelItem('optimization', 'first');
											}
										}}
									/>
									<div style={{marginTop: '2rem'}} className={'theme-label'}>
										Group By
									</div>
									<KiSelect
										name={'cohortColumn'}
										options={groupByOptions}
										isDisabled={!groupByEnabled}
										getOptionLabel={option => option.detailedDisplayName}
										getOptionValue={option => option._id}
										value={groupByOptions.find(c => c._id === fundingAnalysisContext.groupBy)}
										onChange={val => {
											if (!val.isAssetNumberMap) {
												setBlendedEnabled(false);
											} else {
												setBlendedEnabled(true);
											}
											fundingAnalysisContext.setModelItem('groupBy', val._id);
										}}
									/>
									<div style={{marginTop: '2rem'}} className={'theme-label'}>
										Optimization
										<KiIconButton
											style={{color: '#135e82'}}
											icon="info_filled"
											title={`First Compliant ⮕ formerly First Feasible\nFirst Break ⮕ formerly Best Feasible\n∙NEW∙ Minimize Excess ⮕ Best Compliant`}
										/>
									</div>
									<KiSelect
										name={'cohortColumn'}
										options={optimizationTypes}
										isOptionDisabled={opt =>
											fundingAnalysisContext.type === 'balanced_funding' && opt.value === 'best'
										}
										value={optimizationTypes.find(
											opt => opt.value === fundingAnalysisContext.optimization
										)}
										onChange={val => fundingAnalysisContext.setModelItem('optimization', val.value)}
									/>
								</div>
							</div>
							<div className={styles.selectOptions}>
								<div
									className={styles.sectionHeader}
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
										padding: '4.5px 10px',
									}}
								>
									<p className={styles.detailSectionHeader}>CONSTRAINT GROUPS</p>
									<KiIconButton
										style={{color: '#FFF'}}
										icon="add_circle"
										disabled={false} //TODO - get isAdmin
										title="Click to create a new Constraint Group."
										onClick={() => {
											fundingAnalysisContext.setStateItem('currentConstraintGroup', {
												constraintGroupName: '',
												constraints: [],
												isFVDefined: false,
											});
											setIsConstraintModalActive(true);
										}}
									/>
								</div>
								<div className={styles.sectionContent} style={{padding: '2rem'}}>
									<KiList className={styles.constraintsList}>
										{fundingAnalysisContext.constraintGroups.map(c => (
											<KiListItem
												key={c._id}
												itemContent={
													c.constraintGroupName === 'Baseline'
														? `${fundingAnalysisContext.name}_${c.constraintGroupName}`
														: c.constraintGroupName
												}
												onClick={() => {
													fundingAnalysisContext.setStateItem('currentConstraintGroup', c);
													setIsConstraintModalActive(true);
												}}
											/>
										))}
									</KiList>
								</div>
							</div>
						</div>
						<ConstraintsModal
							isActive={isConstraintModalActive}
							setModalActive={setIsConstraintModalActive}
							fundingVehicles={[
								...fundingAnalysisContext.fvSources.map(fv => {
									fv.fvType = 'source';
									return fv;
								}),
								...fundingAnalysisContext.fvTargets.map(fv => {
									fv.fvType = 'target';
									return fv;
								}),
							].filter(fv => !fv.isUnencumbered && fv.fvName !== 'Unencumbered')} //filter out isUnencumbered
							curConstraintGroup={fundingAnalysisContext.currentConstraintGroup}
							allConstraintGroups={fundingAnalysisContext.constraintGroups}
							fundingModel={fundingAnalysisContext.model}
							isReadOnly={
								!!fundingAnalysisContext.currentConstraintGroup.isFVDefined ||
								!!fundingAnalysisContext.currentConstraintGroup.isReadOnly
							}
							onSaveFunc={onConstraintGroupSave}
						/>
					</React.Fragment>
				)}
			</FundingAnalysisContext.Consumer>
		</div>
	);
};

export default WizardStepTwo;
