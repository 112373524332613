import * as actionTypes from './actionTypes';
import {showSnackbar} from 'state/actions/Snackbar';
import {debtExplorerApi, waterfallApi, fundingVehiclesApi, datasetDatesApi} from 'api';
import {determineStartAndEndDate, buildRequestFromView} from 'ki-common/utils/debtRequestBuilder';
import _ from 'lodash';
import {getDebtDateMsg} from 'ki-common/utils/cardUtil';
import systemColumns from 'ki-common/options/systemColumns';

export const resetChangeState = () => dispatch => {
	dispatch({type: actionTypes.RESET_CHANGE_STATE});
};

export const applyViewFilters = () => dispatch => {
	dispatch({type: actionTypes.DE_APPLY_VIEW_FILTERS});
};

export const fetchDebtData = (saveData = false) => (dispatch, getState) => {
	//eslint-disable-next-line no-unused-vars
	const {
		debtExploration: {view, data, updatedActivityDates},
		datasetMappings,
		datasetDates,
	} = getState();
	dispatch({type: actionTypes.FETCH_DEBT_EXPLORATION_DATA, status: 'pending'});
	dispatch(applyViewFilters());
	let requestBody;

	const hydratedDatasetDates = datasetDates.dates.map(datasetDate => {
		const mapMatch = datasetMappings.waterfall.find(mapping => mapping.defaultValue === datasetDate.groupId);
		return !mapMatch ? datasetDate : {...datasetDate, mapName: mapMatch.name};
	});

	if (saveData) {
		requestBody = buildRequestFromView(view, hydratedDatasetDates, data.rows, data.columns, updatedActivityDates);
	} else {
		requestBody = buildRequestFromView(view, hydratedDatasetDates);
	}

	const calculatedDatesRequest = requestBody.quickFilters.fundingVehicleId
		? [
				{
					groupId: requestBody.assetDateContext,
					statementDate: requestBody.snapshotDate,
					fundingVehicleId: requestBody.quickFilters.fundingVehicleId,
				},
				{
					groupId: requestBody.debtDateContext,
					statementDate: requestBody.snapshotDate,
					fundingVehicleId: requestBody.quickFilters.fundingVehicleId,
				},
		  ]
		: [];

	datasetDatesApi.getCalculatedDates(calculatedDatesRequest, view.datasetId).then(calculatedDates => {
		const msg = getDebtDateMsg(calculatedDates[0], calculatedDates[1]);
		dispatch({type: actionTypes.DE_DATE_CONTEXT_MSG_SET, data: msg});
	});

	fundingVehiclesApi.fetchFundingVehiclesWithDates(view.datasetId, view.settings.snapshotDate).then(list => {
		dispatch({type: actionTypes.DEBT_EXPLORER_SET_FUNDING_VEHICLE_LIST, data: list});
	});

	if (requestBody.viewType === 'waterfall') {
		requestBody.columns = requestBody.columns.filter(col => {
			return systemColumns.defaultWaterfallColumns.some(sysCol => {
				return sysCol._id === col.columnId;
			});
		});

		if (requestBody.columns && requestBody.columns.length === 0) {
			requestBody.columns = systemColumns.defaultWaterfallColumns.map(col => {
				col.columnId = col._id;
				return col;
			});
		}
	}

	return debtExplorerApi
		.fetchDebtExplore(requestBody)
		.then(data => {
			dispatch({type: actionTypes.FETCH_DEBT_EXPLORATION_DATA, status: 'success', data: data});
			dispatch(resetChangeState());
		})
		.catch(error => dispatch({type: actionTypes.FETCH_DEBT_EXPLORATION_DATA, status: 'error', error: error}));
};

export const updateManulaInputDebtData = (rowIndex, columnName, value) => (dispatch, getState) => {
	const {
		debtExploration: {data, updatedActivityDates},
	} = getState();
	const updatedData = _.cloneDeep(data.rows);
	const columnIndex = data.columns.findIndex(col => col.columnName === columnName);
	updatedData[rowIndex].data[columnIndex] = value;
	const updatedDates = _.cloneDeep(updatedActivityDates);
	updatedDates.push(updatedData[rowIndex].data[1]);
	dispatch({type: actionTypes.DEBT_EXPLORER_UPDATE_ACTIVITY_DATES, updatedActivityDates: _.uniq(updatedDates)});
	dispatch({type: actionTypes.DEBT_EXPLORER_UPDATE_ROW_DATA, data: updatedData});
};

export const fetchWaterfallData = (fvId, snapshotDate) => dispatch => {
	//eslint-disable-next-line no-unused-vars
	dispatch({type: actionTypes.FETCH_WATERFALL_EXPLORATION_DATA, status: 'pending'});
	return waterfallApi
		.runWaterfall(fvId, snapshotDate)
		.then(data => {
			dispatch({
				type: actionTypes.FETCH_WATERFALL_EXPLORATION_DATA,
				status: 'success',
				data: data.explorationResponses,
			});
		})
		.catch(error => dispatch({type: actionTypes.FETCH_WATERFALL_EXPLORATION_DATA, status: 'error', error: error}));
};

export const setFundingVehicle = fundingVehicle => dispatch => {
	dispatch({
		type: actionTypes.DEBT_EXPLORER_SET_FUNDING_VEHICLE,
		fundingVehicle: (fundingVehicle && fundingVehicle._id) || '',
	});
};

export const setSort = (columnId = '', order = 'asc', calculation = '') => dispatch => {
	dispatch({type: actionTypes.DE_SORT_SET, sortColumn: columnId, sortOrder: order, sortCalculation: calculation});
	dispatch(fetchDebtData());
};

export const applyChangesToView = (settings, viewColumns, name, isDefault, isFavorite, tags = [], fetchData = true) => (
	dispatch,
	getState
) => {
	const {
		debtExploration: {view},
	} = getState();
	dispatch({
		type: actionTypes.DE_APPLY_VIEW_CHANGES,
		settings: settings || view.settings,
		viewColumns: viewColumns || view.viewColumns,
		name: name || view.name,
		isDefault: !!isDefault,
		isFavorite: !!isFavorite,
		tags: tags || view.tags || [],
	});
	if ((settings || view.settings).viewType === 'activity') {
		// eslint-disable-next-line no-use-before-define
		dispatch(setDate((settings || view.settings).snapshotDate, false)); // should this be statementDate?
	}
	if (fetchData === true) {
		dispatch(fetchDebtData());
	}
};

export const setCohortColumn = (columnId, viewId, markChange = false) => (dispatch, getState) => {
	const {
		debtExploration: {currentBookmarkView},
	} = getState();
	dispatch(setFundingVehicle(null, false)); //reset top level fundingVehicleId (used for tranche)
	dispatch({type: actionTypes.DE_SORT_SET, sortColumn: '', sortOrder: '', sortCalculation: ''}); //reset all sorting props bc different view types have different columns
	const newViewColumns = _.cloneDeep(currentBookmarkView.viewColumns);
	if (viewId === 'activity') {
		newViewColumns.debt = newViewColumns.debt.filter(
			col => col.columnType === 'debtInputTranche' || col.columnName === 'name'
		);
		newViewColumns.creditSupport = newViewColumns.creditSupport.filter(
			col => col.columnType === 'debtInputCreditSupport' || col.columnName === 'name'
		);
		newViewColumns.fundingVehicle = newViewColumns.fundingVehicle.filter(
			(col, idx) =>
				!idx ||
				col.columnType === 'debtInputFV' ||
				col.columnType === 'debtInputTranche' ||
				col.columnName === 'kiFVName'
		);
		newViewColumns.fee = _.get(newViewColumns, 'fee', []).filter(
			col => col.columnType === 'debtInputFee' || col.columnName === 'name'
		);
		newViewColumns.trigger = _.get(newViewColumns, 'trigger', []).filter(
			col => col.columnType === 'debtInputTrigger' || col.columnName === 'name'
		);
	}
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_COHORT_COLUMN, columnId, markChange});
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_VIEW_TYPE, viewId});
	dispatch(applyChangesToView(null, newViewColumns, null, null, null, null, false));
};

export const setDate = (date, markChange = false) => (dispatch, getState) => {
	const {
		debtExploration: {
			view: {settings},
		},
		calendars: {calendars},
		datasetMappings,
		datasetDates,
	} = getState();

	const hydratedDatasetDates = datasetDates.dates.map(datasetDate => {
		const mapMatch = datasetMappings.waterfall.find(mapping => mapping.defaultValue === datasetDate.groupId);
		return !mapMatch ? datasetDate : {...datasetDate, mapName: mapMatch.name};
	});

	const selectedFundingVehicleId = _.get(settings, 'quickFilters.fundingVehicleId');
	const {startDate, endDate} = determineStartAndEndDate(
		_.get(settings, 'quickFilters.rangeDate', settings.debtDateContext || 'reportDate'),
		date,
		hydratedDatasetDates,
		selectedFundingVehicleId,
		settings,
		calendars
	);

	const dates = {
		reportDate: date,
		startDate,
		endDate,
	};

	dispatch({type: actionTypes.DEBT_EXPLORER_SET_DATE, dates, markChange});
};

export const setDateContext = dateContext => (dispatch, getState) => {
	const {
		debtExploration: {
			view: {settings},
		},
	} = getState();
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_DATE_CONTEXT, dateContext});
	dispatch(setDate(settings.snapshotDate));
};

export const setIsCustomDateRange = (isCustom, markChange = false) => (dispatch, getState) => {
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_CUSTOM_DATE_RANGE, isCustom, markChange});
	if (!isCustom) {
		setDate(getState().debtExploration.view.settings.snapshotDate);
	}
};

export const setCustomStartDate = (date, markChange = false) => dispatch => {
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_CUSTOM_START_DATE, date, markChange});
};

export const setCustomEndDate = (date, markChange = false) => dispatch => {
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_CUSTOM_END_DATE, date, markChange});
};

export const fetchDefaultView = datasetId => dispatch => {
	dispatch({type: actionTypes.DE_VIEW_DEFAULT_FETCH, status: 'pending'});
	return debtExplorerApi
		.fetchDefaultBookmark(datasetId)
		.then(defaultBookmark => {
			dispatch({type: actionTypes.DE_VIEW_DEFAULT_FETCH, status: 'success', data: defaultBookmark});
			return defaultBookmark;
		})
		.catch(err => {
			dispatch({type: actionTypes.DE_VIEW_DEFAULT_FETCH, status: 'error', error: err});
		});
};

export const saveBookmark = bookmark => dispatch => {
	if (bookmark) {
		return debtExplorerApi[bookmark._id ? 'saveBookmark' : 'addBookmark'](bookmark)
			.then(result => {
				if (!bookmark._id) {
					dispatch({type: actionTypes.DE_BOOKMARK_ADDED, data: result});
					dispatch(showSnackbar('Created view successfully.'));
				} else {
					dispatch({type: actionTypes.DE_BOOKMARK_UPDATED, data: result});
					dispatch(showSnackbar('Saved view successfully.'));
				}
				return result;
			})
			.catch(() => {
				dispatch(showSnackbar('Error occurred saving bookmark.'));
				return Promise.reject('Error saving bookmark.');
			});
	}
};

export const fetchBookmarks = datasetId => dispatch => {
	return debtExplorerApi
		.fetchBookmarks(datasetId)
		.then(bookmarks => {
			dispatch({type: actionTypes.DE_VIEWS_FETCHED, status: 'success', data: bookmarks});
		})
		.catch(err => dispatch({type: actionTypes.DE_VIEWS_FETCHED, status: 'error', error: err}));
};

export const fetchBookmark = (bookmarkId, fetchData = true) => dispatch => {
	dispatch({type: actionTypes.DE_VIEW_FETCH, status: 'pending'});
	return debtExplorerApi
		.fetchBookmark(bookmarkId)
		.then(bookmark => {
			if (!bookmark) {
				return Promise.reject();
			}
			dispatch({type: actionTypes.DE_VIEW_FETCH, status: 'success', data: bookmark});
			if (fetchData === true) {
				dispatch(fetchDebtData());
			}
			return bookmark;
		})
		.catch(err => dispatch({type: actionTypes.DE_VIEW_FETCH, status: 'error', error: err}));
};

export const deleteBookmark = bookmarkId => (dispatch, getState) => {
	const {
		debtExploration: {view},
	} = getState();
	const currentBookmarkId = view._id;
	return debtExplorerApi.deleteBookmark(bookmarkId).then(() => {
		dispatch({type: actionTypes.DE_BOOKMARK_DELETED, bookmarkId});
		dispatch(showSnackbar('Deleted view successfully.'));
		if (currentBookmarkId === bookmarkId) {
			dispatch(fetchDefaultView(view.datasetId));
		}
	});
};

export const setScenario = (scenarioId, markChange = false) => dispatch => {
	dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'scenarioType', value: scenarioId, markChange});
};

export const setEndDate = endDate => dispatch => {
	dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'activityStartDate', value: endDate});
	dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'activityEndDate', value: endDate});
};

export const setFundingVehicleQuickFilter = (fundingVehicleId, markChange = false) => (dispatch, getState) => {
	const {
		debtExploration: {
			view: {settings},
		},
	} = getState();
	dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'fundingVehicleId', value: fundingVehicleId, markChange});
	dispatch(setDate(settings.snapshotDate));
};

export const setRangeDate = (rangeDate, markChange = false) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.DE_QUICK_FILTER_SET,
		key: 'rangeDate',
		value: rangeDate ? rangeDate.groupId : null,
		markChange,
	});
	dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'rangeType', value: rangeDate ? 'range' : 'static'});
	dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'rangeOccurrences', value: 1});
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_CUSTOM_DATE_RANGE, isCustom: false});
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_CUSTOM_START_DATE, value: null});
	dispatch({type: actionTypes.DEBT_EXPLORER_SET_CUSTOM_END_DATE, value: null});
	dispatch(setDate(getState().debtExploration.view.settings.snapshotDate));
};

export const setRangeOccurrences = (occurrenceAmount, rangeType) => (dispatch, getState) => {
	dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'rangeOccurrences', value: occurrenceAmount});
	if (['recurring', 'range', 'static'].includes(rangeType)) {
		dispatch({type: actionTypes.DE_QUICK_FILTER_SET, key: 'rangeType', value: rangeType});
	}
	dispatch(setDate(getState().debtExploration.view.settings.snapshotDate));
};

/*
export const resetChangeState = () => dispatch => {
	dispatch({type: actionTypes.RESET_CHANGE_STATE});
};
*/

export default {
	updateManulaInputDebtData,
	setScenario,
	setEndDate,
	setFundingVehicleQuickFilter,
	setCohortColumn,
	setDate,
	setCustomStartDate,
	setCustomEndDate,
	setFundingVehicle,
	fetchDefaultView,
	fetchDebtData,
	applyChangesToView,
	saveBookmark,
	fetchBookmark,
	fetchBookmarks,
	deleteBookmark,
	setDateContext,
	setSort,
	fetchWaterfallData,
	setRangeDate,
	setRangeOccurrences,
	resetChangeState,
	applyViewFilters,
};
