// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import debtOptions from 'ki-common/options/debt';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

// Project imports
import KiSelect from 'components/KiSelect';
import KiModal2 from 'components/KiModal2';

// Local imports
import styles from './DebtForm.theme.scss';

function DebtFormCopyFormula({
	applyMethod,
	cancelMethod,
	isWaterfall,
	fvList,
	entityType,
	entityList, // Expected to be of all correct type but covering all FV's
	usedEntitiesByFV,
}) {
	const [isReady, setIsReady] = useState(false);
	const [selectedFV, setSelectedFV] = useState(null);
	const [selectedEntityIds, setSelectedEntityIds] = useState([]);
	const [entityOptions, setEntityOptions] = useState([]);

	// Filter the entity options based on the selectedFV
	useEffect(
		() => {
			// Reset entity selections every time FV is changed
			setSelectedEntityIds([]);

			// Filter to matching FV
			const fvEntities = entityList.filter(entity => `${entity.fundingVehicleId}` === `${selectedFV}`);
			const fvUsedEntities = _get(usedEntitiesByFV, selectedFV, []);

			// Filter to only unused entries
			const usableEntities = fvEntities.filter(({_id}) => !fvUsedEntities.includes(_id));

			// Build options
			const opts = [];

			// If 'all' is not used
			if (!fvUsedEntities.includes('all')) {
				// If none of the entities are used elsewhere
				if (fvUsedEntities.length === 0) {
					opts.push({name: 'All', _id: 'all'});
				}

				if (!selectedEntityIds.includes('all')) {
					opts.push(...usableEntities);
				}
			}

			setEntityOptions(opts);
		},
		[selectedFV]
	);

	// If debt AND entity is not FV they must also have a entity
	useEffect(
		() => {
			// User must have an FV at minimum
			if (!selectedFV) {
				return setIsReady(false);
			}

			if (!isWaterfall && entityType !== 'fundingVehicle' && _isEmpty(selectedEntityIds)) {
				return setIsReady(false);
			}

			setIsReady(true);
		},
		[selectedFV, selectedEntityIds]
	);

	const entityName = _get(debtOptions.entityOptions.find(opt => opt.value === entityType), 'label', entityType);

	const handleApply = () => {
		applyMethod(selectedFV, selectedEntityIds);
	};

	// If 'all' selected hide other options, if any other selected hide 'all'
	let entityOptionsAllLogic = _cloneDeep(entityOptions);
	if (selectedEntityIds.includes('all')) {
		entityOptionsAllLogic = entityOptions.filter(opt => opt._id === 'all');
	} else if (selectedEntityIds.length > 0) {
		entityOptionsAllLogic = entityOptions.filter(opt => opt._id !== 'all');
	}

	return (
		<KiModal2
			active={true}
			actions={[
				{label: 'Cancel', onClick: cancelMethod},
				{label: 'Apply', onClick: handleApply, disabled: !isReady},
			]}
			panelStyles={{minHeight: '40%', maxHeight: '40%', minWidth: '60%', maxWidth: '60%'}}
			className={styles.modal}
			header={'Copy Calculation'}
			onClose={cancelMethod}
		>
			<div className={styles.controls}>
				<div className={styles.fvControl}>
					<p>Select a new Funding Vehicle</p>
					<KiSelect
						isDisabled={false}
						isMulti={false}
						options={fvList}
						getOptionLabel={opt => opt.name}
						getOptionValue={opt => opt._id}
						onChange={fundingVehicle => setSelectedFV(`${fundingVehicle._id}`)}
					/>
					{!isWaterfall &&
						entityType !== 'fundingVehicle' && (
							<React.Fragment>
								<p>{entityName}</p>
								<KiSelect
									isDisabled={!selectedFV}
									isMulti={true}
									options={entityOptionsAllLogic}
									value={entityOptionsAllLogic.filter(entity =>
										selectedEntityIds.includes(`${entity._id}`)
									)}
									getOptionLabel={opt => opt.name}
									getOptionValue={opt => opt._id}
									onChange={entitySelections => {
										if (entitySelections) {
											setSelectedEntityIds(entitySelections.map(entity => `${entity._id}`));
										} else {
											setSelectedEntityIds([]);
										}
									}}
								/>
							</React.Fragment>
						)}
				</div>
			</div>
		</KiModal2>
	);
}

DebtFormCopyFormula.propTypes = {
	applyMethod: PropTypes.func.isRequired,
	cancelMethod: PropTypes.func.isRequired,
	isWaterfall: PropTypes.bool.isRequired,
	fvList: PropTypes.array.isRequired,
	entityType: PropTypes.string,
	entityList: PropTypes.array,
	usedEntitiesByFV: PropTypes.object,
};

DebtFormCopyFormula.defaultProps = {
	entityType: 'fundingVehicle',
	entityList: [],
	usedEntitiesByFV: {},
};

export default DebtFormCopyFormula;
