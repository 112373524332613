import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiInput from 'components/KiInput';
import KiButton from 'components/KiButton';
import Select from 'react-select';
import _get from 'lodash/get';
import KiCheckbox from 'components/KiCheckbox';
import options from 'ki-common/options';
import {KiCreatable} from 'components/KiSelect';

export class CumulativeForm extends Component {
	static propTypes = {
		columnList: PropTypes.array.isRequired,
		submitMethod: PropTypes.func.isRequired,
		existingTags: PropTypes.array.isRequired,
		columnToEdit: PropTypes.object,
		closeForm: PropTypes.func,
		isAdmin: PropTypes.bool,
		mode: PropTypes.string,
	};

	static defaultProps = {
		columnList: [],
		existingTags: [],
	};

	state = {
		displayName: '',
		tags: [],
		asOfDateColumn: null,
		cumulative: '',
		allCumulative: false,
		aggregationType: null,
		nameError: null,
		cumulativeError: null,
		isGlobal: this.props.columnToEdit && this.props.columnToEdit.createdBy === '' ? true : false,
	};

	componentDidMount() {
		const {columnToEdit} = this.props;
		if (columnToEdit) {
			this.populateColumnState();
		}
	}

	populateColumnState = () => {
		const {columnToEdit} = this.props;
		this.setState({
			displayName: columnToEdit.displayName,
			tags: columnToEdit.tags,
			isGlobal: this.state.isGlobal,
			asOfDateColumn: columnToEdit.asOfDateColumn,
			aggregationType: options.cumulativeAggregationTypes.find(opt => opt.value === columnToEdit.aggregation),
			allCumulative: columnToEdit.cumulativeRange === 'ALL' ? true : false,
			cumulative: columnToEdit.cumulativeRange !== 'ALL' ? columnToEdit.cumulativeRange : '',
			aggregateAsOfDateColumn: columnToEdit.aggregateAsOfDateColumn ? columnToEdit.aggregateAsOfDateColumn : null,
		});
	};

	setName = val => {
		this.setState({
			displayName: val,
			nameError: val.trim().length ? null : "Name can't be blank",
		});
	};

	setCumulative = value => {
		this.setState({cumulative: value, cumulativeError: value.includes('-') ? "Value can't be negative" : null});
	};

	isDuplicateNameError = () => {
		if (_get(this, 'props.columnToEdit.displayName') === this.state.displayName) {
			return;
		}
		const assetCols = this.props.columnList.filter(
			col => (!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset'
		);
		// Check if the name already exists in assetCols
		return assetCols.find(col => _get(col, 'displayName').trim() === this.state.displayName.trim());
	};
	onSubmit = () => {
		const {columnToEdit} = this.props;
		const {
			displayName,
			tags,
			isGlobal,
			asOfDateColumn,
			cumulative,
			allCumulative,
			aggregationType,
			aggregateAsOfDateColumn,
		} = this.state;
		this.setState({isSaving: true});
		if (!displayName.trim()) {
			this.setState({nameError: 'Name cant be blank', isSaving: false});
			return;
		}

		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		} else {
			this.setState({nameError: ''});
		}
		const columnForSaving = {
			_id: columnToEdit ? columnToEdit._id : null,
			columnName: displayName,
			displayName: displayName,
			displayFormat: 'number',
			tags: tags,
			dataType: 'numeric',
			columnType: 'asset',
			columnFormType: 'cumulative',
			isGlobal: isGlobal,
			asOfDateColumn: asOfDateColumn,
			aggregation: aggregationType.value,
			cumulativeRange: allCumulative ? 'ALL' : cumulative,
		};
		if (aggregationType.value === 'WGHTAVG') {
			columnForSaving.aggregateAsOfDateColumn = aggregateAsOfDateColumn;
		}
		return this.props.submitMethod(columnForSaving);
	};

	render() {
		const {columnList} = this.props;
		const {
			displayName,
			nameError,
			asOfDateColumn,
			cumulative,
			allCumulative,
			aggregationType,
			isSaving,
			cumulativeError,
		} = this.state;

		return (
			<form onSubmit={this.onSubmit} className="column-selector-form inline-column-form">
				<section className="calculation-name-tags">
					<KiInput label="Name" value={displayName} onChange={val => this.setName(val)} error={nameError} />
					<span className="form-instruction">Tags:</span>
					<KiCreatable
						classNamePrefix="aut-select"
						isMulti={true}
						options={this.props.existingTags.map(t => ({
							value: t,
							label: t,
						}))}
						value={this.state.tags.map(t => ({value: t, label: t}))}
						onChange={val => this.setState({tags: val.map(t => t.value)})}
						placeholder="Add some tags"
						noResultsText={false}
					/>
					<div className="calculation-form-section">
						<span className="form-instruction">Choose Historical Column:</span>
						<Select
							classNamePrefix="aut-select"
							value={asOfDateColumn}
							options={columnList.filter(c => c.dataType === 'numeric' && !!c.asOfDateType)}
							isClearable={false}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							onChange={value => this.setState({asOfDateColumn: value})}
						/>
					</div>
					<div className="calculation-form-section form-row">
						<span>
							<span className="form-instruction">Cumulative:</span>
							<KiInput
								type="number"
								className="substring-size-input"
								disabled={allCumulative}
								value={cumulative}
								onChange={this.setCumulative}
								error={cumulativeError}
							/>
						</span>
						<KiCheckbox
							checked={allCumulative}
							label="All"
							className="cumulative-checkbox"
							onChange={val => {
								this.setState({allCumulative: val});
								if (val) {
									this.setState({cumulative: ''});
								}
							}}
						/>
					</div>
					<div className="calculation-form-section">
						<span className="form-instruction">Aggregation Type:</span>
						<Select
							classNamePrefix="aut-select"
							value={aggregationType}
							options={options.cumulativeAggregationTypes}
							isClearable={false}
							isDisabled={(!cumulative && !allCumulative) || !asOfDateColumn}
							onChange={value => this.setState({aggregationType: value})}
						/>
					</div>
					{_get(aggregationType, 'value', null) === 'WGHTAVG' && (
						<div className="calculation-form-section">
							<span className="form-instruction">Select Column to Weight By</span>
							<Select
								classNamePrefix="aut-select"
								value={this.state.aggregateAsOfDateColumn}
								isClearable={false}
								options={columnList.filter(c => c.dataType === 'numeric' && !!c.asOfDateType)}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								onChange={opt => this.setState({aggregateAsOfDateColumn: opt})}
							/>
						</div>
					)}

					{this.props.mode === 'inline' &&
						this.props.isAdmin &&
						!_get(this, 'props.columnToEdit._id', null) && (
							<div className="calculation-form-section">
								<KiCheckbox
									name="isGlobal"
									checked={this.state.isGlobal}
									label="Make Global"
									onChange={val => this.setState({isGlobal: val})}
								/>
							</div>
						)}
				</section>
				<section className="format-and-save">
					<div className="inline-column-form-buttons">
						<KiButton flat primary onClick={() => this.props.closeForm()}>
							Cancel
						</KiButton>
						<KiButton
							disabled={
								!this.state.displayName.trim() ||
								!aggregationType ||
								isSaving ||
								!!this.state.cumulativeError
							}
							raised
							primary
							onClick={() => this.onSubmit()}
						>
							Save
						</KiButton>
					</div>
				</section>
			</form>
		);
	}
}

export default CumulativeForm;
