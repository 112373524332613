// Globals
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
// Project imports
import KiDnDList from 'components/KiDnDList';
// Local imports
import ViewColumnListItem from './ViewColumnListItem';
import styles from '../../fundingAnalysis.theme.scss';

/**
 * [ColumnSelection description]
 */
function ColumnSelection({bookmark, setBookmark, columnType, columns}) {
	const setBookmarkColumns = columns => {
		const update = {
			viewColumns: {},
		};
		update.viewColumns[columnType] = columns;
		setBookmark(update);
	};

	const handleDeleteColumn = index => {
		const columns = bookmark.viewColumns[columnType];
		columns.splice(index, 1);
		setBookmarkColumns(columns);
	};

	const handleAddColumn = col => {
		const columns = bookmark.viewColumns[columnType];
		columns.push(col);
		setBookmarkColumns(columns);
	};

	const handleUpdateColumn = (col, index) => {
		const columns = bookmark.viewColumns[columnType];
		columns[index] = col;
		setBookmarkColumns(columns);
	};

	const renderColumnItem = (column, index, nonDeletableName) => {
		return (
			<ViewColumnListItem
				handleDeleteColumn={handleDeleteColumn}
				column={column}
				index={index}
				nonDeletableName={nonDeletableName}
				handleUpdateColumn={handleUpdateColumn}
			/>
		);
	};

	const onColumnsReordered = orderedColumns => {
		if (columnType !== 'summary' || orderedColumns[0].content.columnName === 'kiFVName') {
			const columns = orderedColumns.map(block => {
				return block.content;
			});
			setBookmarkColumns(columns);
		}
	};

	const getNonDeletableName = type => {
		switch (type) {
			case 'scenario':
			case 'summary':
			case 'eligibility':
				return 'kiFVName';
			case 'compliance':
				return 'comp_test_name';
			case 'breaches':
				return 'br_ex_name';
		}
	};

	const nonDeletableName = getNonDeletableName(columnType);

	return (
		<React.Fragment>
			<div>
				<span className="form-instruction">Select Columns:</span>
				<Select
					classNamePrefix="aut-select"
					value={null}
					options={columns}
					onChange={val => handleAddColumn(val)}
					getOptionLabel={option => option.displayName}
					getOptionValue={option => option._id}
				/>
			</div>
			<div className={styles.columnList} style={{overflow: 'auto', margin: '10px 0'}}>
				<KiDnDList
					items={bookmark.viewColumns[columnType].map((column, index) => ({
						id: index,
						content: column,
						idx: index,
						isDragDisabled: columnType === 'summary' && column.columnName === 'kiFVName',
					}))}
					onReorder={onColumnsReordered}
					contentRenderFunc={(column, index) => renderColumnItem(column, index, nonDeletableName)}
					listClassName={'filterListDnd'}
					itemClassName={'filterItemDnd'}
					isCombineEnabled={false}
				/>
			</div>
		</React.Fragment>
	);
}

ColumnSelection.propTypes = {
	bookmark: PropTypes.object.isRequired,
	setBookmark: PropTypes.func.isRequired,
	columnType: PropTypes.string.isRequired,
	columns: PropTypes.array.isRequired,
};

ColumnSelection.defaultProps = {};

export default ColumnSelection;
