import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {KiInput} from 'components/KiInput';
import {updateOneBandStep} from './actions';
import styles from './ViewForms.theme.scss';

export class CohortBandsOddRow extends Component {
	static propTypes = {
		index: PropTypes.number,
		band: PropTypes.object,
		updateOneBandStep: PropTypes.func.isRequired,
		addFormError: PropTypes.func.isRequired,
		removeFormError: PropTypes.func.isRequired,
		view: PropTypes.object,
		setFormHasChanges: PropTypes.func,
	};

	static defaultProps = {
		band: {},
		view: {},
	};

	state = {
		start: this.props.band.start,
		end: this.props.band.end,
		step: this.props.band.step,
	};

	validateStepSize = () => {
		const bandSize = Number(this.state.end) - Number(this.state.start);
		if (bandSize < Number(this.state.step)) {
			this.props.addFormError(`step-${this.props.index}`, 'greater than band');
		} else if (bandSize / Number(this.state.step) > 1000) {
			this.props.addFormError(`step-${this.props.index}`, '1000 steps max');
		} else {
			this.props.removeFormError(`step-${this.props.index}`);
		}
	};

	updateBand = (value, name) => {
		this.validateStepSize();
		const step = Object.assign({}, {...this.props.band, [name]: value});
		this.props.updateOneBandStep(this.props.index, step);
		this.props.setFormHasChanges();
	};

	render() {
		return (
			<div className={styles.bandRow}>
				<KiInput
					name={`start-${this.props.index}`}
					type="text"
					value={this.state.start}
					error={this.props.view.formErrors[`start-${this.props.index}`]}
					className={styles.skinny}
					onChange={val => this.setState({start: val})}
					onBlur={val => this.updateBand(val.target.value, 'start')}
					isNumberMasked={true}
				/>
				<KiInput
					name={`end-${this.props.index}`}
					type="text"
					value={this.state.end}
					error={this.props.view.formErrors[`end-${this.props.index}`]}
					className={styles.skinny}
					onChange={val => this.setState({end: val})}
					onBlur={val => this.updateBand(val.target.value, 'end')}
					isNumberMasked={true}
				/>
				<KiInput
					name={`step-${this.props.index}`}
					type="text"
					value={this.state.step}
					error={this.props.view.formErrors[`step-${this.props.index}`]}
					className={styles.skinny}
					onChange={val => this.setState({step: val})}
					onBlur={val => this.updateBand(val.target.value, 'step')}
					isNumberMasked={true}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	view: state.viewForm,
});

const mapDispatchToProps = () => ({
	updateOneBandStep,
});

export default connect(mapStateToProps, mapDispatchToProps())(CohortBandsOddRow);
