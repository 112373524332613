// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';

// Relative
import styles from './kiOperatorPicker.theme.scss';

export class KiOperatorPicker extends Component {
	static propTypes = {
		options: PropTypes.array,
		onSelect: PropTypes.func,
	};

	// Prevents click from bubbling to thing below the selector
	handleClick = (event, option) => {
		event.stopPropagation();
		event.preventDefault();
		this.props.onSelect(option);
	};

	render() {
		const {options} = this.props;
		return (
			<div className={styles.operatorPicker}>
				<div className="operator-picker-buttons">
					{options.map(option => (
						<button key={option} onClick={e => this.handleClick(e, option)}>
							{option}
						</button>
					))}
				</div>
				<div className="operator-picker-bottom" />
			</div>
		);
	}
}

export default KiOperatorPicker;
