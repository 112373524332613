import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchCounterPartyRoles = () => {
	return fetch(`${apiUrl}/counterparties/roles`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const searchCounterParties = query => {
	if (!query) {
		return Promise.resolve([]);
	}
	return fetch(`${apiUrl}/counterparties?query=${query}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchCounterPartyRoles,
	searchCounterParties,
};
