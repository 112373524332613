import {calendarsApi} from 'api';
import * as actionTypes from './actionTypes';
import {showSnackbar} from 'state/actions/Snackbar';

export const fetchCalendar = calendarId => dispatch => {
	dispatch({type: actionTypes.CALENDARS_FETCH_ONE, status: 'pending'});
	return calendarsApi
		.fetchCalendar(calendarId)
		.then(calendar => {
			dispatch({type: actionTypes.CALENDARS_FETCH_ONE, status: 'success', calendar: calendar});
			return calendar;
		})
		.catch(error => {
			dispatch({type: actionTypes.CALENDARS_FETCH_ONE, status: 'error', error: error});
		});
};

export const fetchCalendars = params => dispatch => {
	dispatch({type: actionTypes.CALENDARS_FETCH_ALL, status: 'pending'});
	return calendarsApi
		.fetchCalendars(params)
		.then(calendars => {
			dispatch({type: actionTypes.CALENDARS_FETCH_ALL, status: 'success', calendars: calendars});
			return calendars;
		})
		.catch(error => {
			dispatch({type: actionTypes.CALENDARS_FETCH_ALL, status: 'error', error: error});
		});
};

export const createCalendar = calendar => dispatch => {
	dispatch({type: actionTypes.CALENDARS_CREATE_ONE, status: 'pending'});
	return calendarsApi
		.createCalendar(calendar)
		.then(calendarWithId => {
			dispatch({type: actionTypes.CALENDARS_CREATE_ONE, status: 'success', calendar: calendarWithId});
			dispatch(showSnackbar(`Saved ${calendar.name} successfully.`));
			return calendarWithId;
		})
		.catch(error => {
			dispatch({type: actionTypes.CALENDARS_CREATE_ONE, status: 'error', error: error});
		});
};

export const deleteCalendar = calendarId => dispatch => {
	dispatch({type: actionTypes.CALENDARS_DELETE_ONE, status: 'pending'});
	return calendarsApi
		.deleteCalendar(calendarId)
		.then(() => {
			dispatch(showSnackbar(`Calendar successfully deleted.`));
			dispatch({type: actionTypes.CALENDARS_DELETE_ONE, status: 'success', id: calendarId});
		})
		.catch(error => {
			dispatch({type: actionTypes.CALENDARS_DELETE_ONE, status: 'error', error: error});
		});
};

export const updateCalendar = (id, changes) => dispatch => {
	dispatch({type: actionTypes.CALENDARS_UPDATE_ONE, status: 'pending'});
	return calendarsApi
		.updateCalendar(id, changes)
		.then(calendar => {
			dispatch({type: actionTypes.CALENDARS_UPDATE_ONE, status: 'success', calendar: changes, id: id});
			dispatch(showSnackbar(`Calendar successfully updated.`));
			return calendar;
		})
		.catch(error => {
			dispatch({type: actionTypes.CALENDARS_UPDATE_ONE, status: 'error', error: error});
		});
};

export default {
	fetchCalendar,
	fetchCalendars,
	createCalendar,
	deleteCalendar,
	updateCalendar,
};
