import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import KiButton from '../../../components/KiButton';
import KiConfirmModal from '../../../components/KiConfirmModal';
//import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import KiDatePicker from '../../../components/KiDatePicker';
import KiCheckbox from '../../../components/KiCheckbox';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

import datasetHiddenSnapshotsApi from '../../../api/datasetHiddenSnapshotsApi';
import snapshotReprocessJobsApi from '../../../api/snapshotReprocessJobsApi';

const datasetHiddenSnapshotsForm = props => {
	const [dataset, setDataset] = useState({});
	const [isConfirmActive, setIsConfirmActive] = useState(false);
	const [checkpoints, setCheckpoints] = useState([]);
	const [restoreDate, setRestoreDate] = useState(null);
	const [onlyFAonLastSnapshot, setOnlyFAonLastSnapshot] = useState(false);
	const [selectedSnapshots, setSelectedSnapshots] = useState([]);

	/*
	const buttonDisabled = () => {
		return dataset.datasetId ? false : true;
	};
*/
	const selectDataset = async val => {
		setDataset(val);
		const cp = await datasetHiddenSnapshotsApi.getSnapshotsForDatasetId(val.datasetId);
		setCheckpoints(cp?.snapshots || []);
		setSelectedSnapshots([]);
	};

	const executeRollBack = async () => {
		try {
			await snapshotReprocessJobsApi.createJob(dataset.datasetId, onlyFAonLastSnapshot, selectedSnapshots);
			//console.log(`execute with ${dataset.datasetId} for ${selectedSnapshots} and last=${onlyFAonLastSnapshot}}`)
			props.showSnackbar('Restore job has been scheduled');
			setRestoreDate(null);
			setDataset({});
			setIsConfirmActive(false);
		} catch (error) {
			props.showSnackbar(`There was a problem scheduling the rollback job`);
		}
	};

	return (
		<div>
			<KiConfirmModal
				header="Reprocess Dates"
				message={`Are you sure you want to restore and reprocess the selected dates?  This is irreversable.`}
				acceptFunc={() => executeRollBack()}
				rejectFunc={() => setIsConfirmActive(false)}
				acceptLabel="Reprocess"
				rejectLabel="Cancel"
				active={isConfirmActive}
			/>
			<div className={'datasetCopyForm'}>
				<div className="dataset-selector-group">
					<span className="form-instruction">Choose Dataset:</span>
					<Select
						classNamePrefix="aut-select"
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
						isClearable={false}
						options={props.datasets}
						value={props.datasets.filter(x => dataset?.datasetId === x.datasetId)}
						onChange={val => {
							selectDataset(val);
						}}
					/>
				</div>
			</div>
			<div className={'checkpointsTable'}>
				<label>Reactivate Up To:</label>
				<KiDatePicker
					error={''}
					className="checkpointsDate"
					onChange={val => {
						const dateVal = dateToShortDate(val);
						setRestoreDate(dateVal);
						setSelectedSnapshots(
							checkpoints?.filter(c => c.snapshotDate <= dateVal).map(s => s.snapshotDate)
						);
					}}
					value={restoreDate}
				/>
				<KiButton
					raised
					primary
					label="GO"
					className={'run-button'}
					onClick={() => setIsConfirmActive(true)}
					disabled={selectedSnapshots.length == 0}
				/>
				<KiCheckbox
					checked={onlyFAonLastSnapshot}
					onChange={o => setOnlyFAonLastSnapshot(o)}
					label={`Funding Analysis Persistence only executed for Latest Snapshot in selected range`}
				/>
			</div>
			<div className={'checkpointsTable'}>
				<label>Hidden Snapshots:</label>
				<div className="snapshotTileArea">
					{checkpoints.map((s, ndx) => {
						return (
							<div
								key={`${s.snapshotDate}_${ndx}`}
								className={`snapshotDateTile  ${
									selectedSnapshots.includes(s.snapshotDate) ? 'selected' : ''
								}`}
							>
								{s.snapshotDate}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

datasetHiddenSnapshotsForm.propTypes = {
	datasets: PropTypes.array.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

datasetHiddenSnapshotsForm.defaultProps = {
	datasets: [],
};

export default datasetHiddenSnapshotsForm;
