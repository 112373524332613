import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiInput from 'components/KiInput';
import options from 'ki-common/options';
import calcColumns from 'ki-common/utils/calcColumns';
import stringHelpers from 'ki-common/utils/stringHelpers';
import KiButton from 'components/KiButton';
import {KiCreatable} from 'components/KiSelect';
import Select from 'react-select';
import _get from 'lodash/get';
import TextFormSubstringFields from './components/TextFormSubstringFields';
import TextFormConcatFields from './components/TextFormConcatFields';
import KiCheckbox from 'components/KiCheckbox';
import _isEqual from 'lodash/isEqual';

export class TextForm extends Component {
	static propTypes = {
		columnList: PropTypes.array.isRequired,
		submitMethod: PropTypes.func.isRequired,
		existingTags: PropTypes.array.isRequired,
		columnToEdit: PropTypes.object,
		closeForm: PropTypes.func,
		isAdmin: PropTypes.bool,
		reloadView: PropTypes.func,
		mode: PropTypes.string,
	};

	static defaultProps = {
		columnList: [],
		existingTags: [],
	};

	constructor(props) {
		super(props);
		const {columnToEdit, columnList} = this.props;
		const assetColumns = columnList.filter(
			col => col.columnType === 'asset' && col.dataType === 'string' && col._id !== _get(columnToEdit, '_id')
		);
		this.state = {
			displayName: '',
			tags: [],
			nameError: null,
			functionType: null,
			selectedColumns: [],
			delimiter: null,
			direction: 'left',
			size: 0,
			position: '',
			isGlobal: this.props.columnToEdit && this.props.columnToEdit.createdBy === '' ? true : false,
			assetColumns: assetColumns || [],
		};
	}

	componentDidMount() {
		const {columnToEdit} = this.props;
		if (columnToEdit) {
			this.populateColumnState();
		}
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(this.props.columnList, prevProps.columnList)) {
			this.setState({
				assetColumns: this.props.columnList.filter(
					col => col.columnType === 'asset' && col._id !== _get(this.props, 'columnToEdit._id')
				),
			});
		}
	}

	populateColumnState = () => {
		const {columnToEdit} = this.props;
		const calculationOperands = calcColumns.parseCalulatedColumn(columnToEdit.formula);
		const functionTypeVal = _get(columnToEdit, 'formula.op');
		this.setState({
			displayName: columnToEdit.displayName,
			tags: columnToEdit.tags,
			functionType: functionTypeVal,
			direction:
				functionTypeVal === 'substringRight' ? 'right' : functionTypeVal === 'substringLeft' ? 'left' : 'mid',
			size: _get(columnToEdit, 'formula.left.size', 0),
			position: _get(columnToEdit, 'formula.left.position', ''),
			selectedColumns: functionTypeVal === 'concatenate' ? calculationOperands : [calculationOperands[0].value],
		});
	};

	setName = val => {
		let nameError = null;

		if (!val.trim().length) {
			nameError = "Name can't be blank";
		} else if (!val.match(stringHelpers.alphanumericRegex)) {
			nameError = 'Name must be Alphanumeric!';
		}

		this.setState({
			displayName: val,
			nameError: nameError,
		});
	};
	isDuplicateNameError = () => {
		if (_get(this, 'props.columnToEdit.displayName') === this.state.displayName) {
			return;
		}

		const assetCols = this.props.columnList.filter(
			col => (!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset'
		);
		// Check if the name already exists in assetCols
		return assetCols.find(col => _get(col, 'displayName').trim() === this.state.displayName.trim());
	};
	getSaveAndFormatDisabled = () => {
		const {functionType, selectedColumns, displayName, direction, size, position} = this.state;

		if (!functionType) {
			return true;
		}
		if (_get(this.state, 'functionType.value', functionType) === 'concatenate') {
			return !selectedColumns.length || !displayName.trim();
		} else {
			return (
				!selectedColumns.length ||
				!direction ||
				!size ||
				!displayName.trim() ||
				(direction === 'mid' && !position)
			);
		}
	};

	onSubmit = () => {
		const {columnToEdit} = this.props;
		const {displayName, tags, functionType, selectedColumns, size, position, isGlobal} = this.state;
		this.setState({isSaving: true});
		if (!displayName.trim()) {
			this.setState({nameError: 'Name cant be blank', isSaving: false});
			return;
		}
		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		} else {
			this.setState({nameError: ''});
		}
		const columnForSaving = {
			_id: columnToEdit ? columnToEdit._id : null,
			columnName: displayName,
			displayName: displayName,
			tags: tags,
			dataType: 'string',
			columnType: 'asset',
			columnFormType: 'text',
			formula:
				_get(functionType, 'value', functionType) === 'concatenate'
					? calcColumns.createConcatTextColumn({
							functionType,
							selectedColumns,
					  })
					: calcColumns.createSubstringTextColumn({
							functionType,
							selectedColumns,
							size,
							position,
					  }),
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			isGlobal: isGlobal,
		};
		return this.props.submitMethod(columnForSaving);
	};

	getFunctionTypeSelectValue = functionType => {
		if (!functionType) {
			return functionType;
		}
		switch (_get(functionType, 'value', functionType)) {
			case 'substringLeft':
			case 'substringRight':
			case 'substringMid': {
				return 'substring';
			}
			default: {
				return 'concatenate';
			}
		}
	};

	render() {
		const {
			displayName,
			nameError,
			assetColumns,
			functionType,
			selectedColumns,
			delimiter,
			size,
			position,
			direction,
			isSaving,
		} = this.state;

		const isConcat = _get(this.state, 'functionType.value', this.state.functionType) === 'concatenate';

		return (
			<form onSubmit={this.onSubmit} className="column-selector-form inline-column-form">
				<section className="calculation-name-tags">
					<KiInput label="Name" value={displayName} onChange={val => this.setName(val)} error={nameError} />
					<span className="form-instruction">Tags:</span>
					<KiCreatable
						classNamePrefix="aut-select"
						isMulti={true}
						options={this.props.existingTags.map(t => ({
							value: t,
							label: t,
						}))}
						value={this.state.tags.map(t => ({value: t, label: t}))}
						onChange={val => this.setState({tags: val.map(t => t.value)})}
						placeholder="Add some tags"
						noResultsText={false}
					/>
					<div className="calculation-form-section">
						<span className="form-instruction">Function:</span>
						<Select
							classNamePrefix="aut-select"
							value={options.textFunctions.find(
								opt => opt.value === this.getFunctionTypeSelectValue(functionType)
							)}
							isClearable={false}
							options={options.textFunctions}
							onChange={selected => {
								this.setState({
									functionType:
										selected && selected.value.includes('substring')
											? {
													...selected,
													value:
														direction === 'right'
															? 'substringRight'
															: direction === 'left'
																? 'substringLeft'
																: 'substringMid',
											  }
											: selected,
									delimiter: selected && selected.value.includes('substring') ? null : delimiter,
									selectedColumns: [],
								});
							}}
						/>
					</div>

					<TextFormSubstringFields
						active={_get(functionType, 'value', functionType || '').includes('substring')}
						assetColumns={assetColumns}
						selectedColumns={selectedColumns}
						size={size}
						position={position}
						direction={direction}
						onColumnSelect={column => this.setState({selectedColumns: [column]})}
						onDirectionSelect={direction => {
							this.setState({
								direction: direction,
								functionType: {
									label: 'Substring',
									value:
										direction === 'right'
											? 'substringRight'
											: direction === 'left'
												? 'substringLeft'
												: 'substringMid',
								},
							});
						}}
						onSizeChange={size => this.setState({size: size})}
						onPositionChange={position => this.setState({position: position})}
					/>

					{isConcat && (
						<TextFormConcatFields
							assetColumns={assetColumns}
							selectedColumns={selectedColumns}
							onColumnsSelect={columns => this.setState({selectedColumns: columns})}
							reloadView={this.props.reloadView}
						/>
					)}
					{this.props.mode === 'inline' &&
						this.props.isAdmin &&
						!_get(this, 'props.columnToEdit._id', null) && (
							<div className="calculation-form-section">
								<KiCheckbox
									name="isGlobal"
									checked={this.state.isGlobal}
									label="Make Global"
									onChange={val => this.setState({isGlobal: val})}
								/>
							</div>
						)}
				</section>
				<section className="format-and-save">
					<div className="inline-column-form-buttons">
						<KiButton flat primary onClick={() => this.props.closeForm()}>
							Cancel
						</KiButton>
						<KiButton
							disabled={this.getSaveAndFormatDisabled() || isSaving}
							raised
							primary
							onClick={() => this.onSubmit()}
						>
							Save
						</KiButton>
					</div>
				</section>
			</form>
		);
	}
}

export default TextForm;
