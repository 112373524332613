import PropTypes from 'prop-types';
import React, {Component} from 'react';
import size from 'lodash/size';
import map from 'lodash/map';
import styles from '../wizard.theme.scss';

export class WizardHeader extends Component {
	static propTypes = {
		steps: PropTypes.array,
		percentageFilled: PropTypes.number,
		wizardScreenIndex: PropTypes.number,
	};

	static defaultProps = {
		steps: [],
	};

	determineStepColor = index => {
		if (index === this.props.wizardScreenIndex) {
			return styles.green;
		}

		if (index < this.props.wizardScreenIndex) {
			return styles.blue;
		}

		if (index > this.props.wizardScreenIndex) {
			return styles.grey;
		}
	};

	determineLeftPosition = index => {
		const {steps} = this.props;
		const stepsLength = size(steps);

		if (index === 0) {
			return 0;
		}

		if (index >= stepsLength - 1) {
			return 100;
		}

		return index / (stepsLength - 1) * 100;
	};

	render() {
		return (
			<div className={styles.header}>
				<div className={styles.progressBar}>
					<div className={styles.progressBarGrey} />
					<div className={styles.progressBarFilled} style={{width: `${this.props.percentageFilled}%`}} />
					<div className={styles.steps}>
						{map(this.props.steps, (screen, index) => (
							<div
								className={styles.step}
								key={screen.props.title}
								style={{left: `calc(${this.determineLeftPosition(index)}% - 75px)`}}
							>
								<span className={`${styles.stepTitle} ${this.determineStepColor(index)}`}>
									{screen.props.title}
								</span>
								<span className={styles.stepNumber}>
									<span className={`${styles.stepNumberInner} ${this.determineStepColor(index)}`}>
										{index + 1}
									</span>
								</span>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}
export default WizardHeader;
