import React from 'react';
import PropTypes from 'prop-types';
import BalanceTableItem from './BalanceTableItem';
import {ledgerCurrencyFormat} from '../../ledgerUtils';

function BalanceTable({balance: {accounts, totals}}) {
	return (
		<div className="scenario-list-box list-box-size-2">
			{accounts.length ? (
				<table className="scenario-list-table scenario-list-table-ledger">
					<thead>
						<tr>
							<th />
							<th />
							<th colSpan="2" className="align-center">
								Beginning Balance
							</th>
							<th colSpan="2" className="align-center">
								Ending Balance
							</th>
						</tr>
						<tr>
							<th className="align-right">Account Name</th>
							<th className="align-right">Account Code</th>
							<th className="align-right">Debit</th>
							<th className="align-right">Credit</th>
							<th className="align-right">Debit</th>
							<th className="align-right">Credit</th>
						</tr>
					</thead>
					<tbody>{accounts && accounts.map((item, i) => <BalanceTableItem item={item} key={i} />)}</tbody>
					<thead className="bottom-header">
						<tr>
							<th className="align-right">Totals</th>
							<th className="align-right">{ledgerCurrencyFormat(totals.beginning.debit)}</th>
							<th className="align-right">{ledgerCurrencyFormat(totals.beginning.credit)}</th>
							<th className="align-right">{ledgerCurrencyFormat(totals.ending.debit)}</th>
							<th className="align-right">{ledgerCurrencyFormat(totals.ending.credit)}</th>
						</tr>
					</thead>
				</table>
			) : (
				<div className="no-list-items-container">
					<div>No accounts found</div>
				</div>
			)}
		</div>
	);
}

BalanceTable.propTypes = {
	balance: PropTypes.object,
};

BalanceTable.defaultProps = {
	balance: {},
};

export default BalanceTable;
