import _ from 'lodash';
import moment from 'moment';

export const filterItemList = (searchTerm, list = [], fields) => {
	return list.filter(item => {
		let itemToText = '';
		fields.forEach(field => {
			itemToText += item[field];
		});
		return itemToText.toLowerCase().includes(searchTerm.toLowerCase());
	});
};

export const simpleTextFilter = (filter, row) => {
	const test = _.get(filter, 'value', '').toLowerCase();
	const rowValue = _.get(row, filter.id, '').toLowerCase();
	return rowValue.includes(test);
};

export const momentDateFilter = format => (filter, row) => {
	const test = _.get(filter, 'value', '').toLowerCase();
	const rowValue = moment(row[filter.id]).format(format);
	return rowValue.includes(test);
};
