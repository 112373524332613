// Globals
import React from 'react';
import PropTypes from 'prop-types';

// Project imports
import KiButton from 'components/KiButton';

// Local imports
import styles from './kiOverlay.theme.scss';

function KiOverlay({title, buttonTitle, buttonDisabled, content, onCancel, onOk}) {
	const handleCancelClick = () => {
		onCancel();
	};

	const handleConfirmClick = () => {
		onOk();
	};

	return (
		<div className={styles.root}>
			<div className={styles.title}>{title}</div>
			<div className={styles.form}>{content}</div>
			<div className={styles.formButtons}>
				<KiButton flat primary onClick={() => handleCancelClick()} label="Cancel" />
				<KiButton
					raised
					primary
					onClick={() => handleConfirmClick()}
					label={buttonTitle}
					disabled={buttonDisabled}
				/>
			</div>
		</div>
	);
}

KiOverlay.propTypes = {
	title: PropTypes.any,
	buttonTitle: PropTypes.string,
	buttonDisabled: PropTypes.bool,
	content: PropTypes.any,
	onCancel: PropTypes.func,
	onOk: PropTypes.func,
};

KiOverlay.defaultProps = {
	title: '',
	buttonTitle: 'OK',
	buttonDisabled: false,
	content: null,
	onOk: () => null,
};

export default KiOverlay;
