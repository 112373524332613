// Globals
import React from 'react';
import PropTypes from 'prop-types';

// Project imports
import KiSelect from 'components/KiSelect';

// Local imports
import styles from './KiFormulaBuilderComponent.theme.scss';

const options = [
	{
		label: '+',
		value: '+',
	},
	{
		label: '-',
		value: '-',
	},
	{
		label: '*',
		value: '*',
	},
	{
		label: '/',
		value: '/',
	},
	{
		label: '^',
		value: '^',
	},
];

function OperatorComponent({value, onChange}) {
	return (
		<div className={styles.formulaComponent}>
			<KiSelect
				value={options.find(opt => opt.value === value) || options.slice(0, 1)}
				isClearable={false}
				options={options}
				onChange={selected => onChange(selected.value)}
			/>
		</div>
	);
}

OperatorComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

OperatorComponent.defaultProps = {
	value: '',
	onChange: () => {
		return false;
	},
};

export default OperatorComponent;
