const getConstraints = () => ({
	datasetId: {
		presence: {allowEmpty: false},
	},
	reportDefinitionId: {
		presence: {allowEmpty: false},
	},
	parameters: {
		presence: {allowEmpty: false},
	},
	createdBy: {
		presence: {allowEmpty: false},
	},
	updatedBy: {
		presence: {allowEmpty: false},
	},
});

module.exports = {
	getConstraints,
};
