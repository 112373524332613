import {manualDebtInputsApi} from 'api';
import * as actionTypes from './actionTypes';

// export const DEBT_INPUT_TEMPLATES_FETCH_ALL = 'DEBT_INPUT_TEMPLATES_FETCH_ALL';
// export const DEBT_INPUT_TEMPLATES_ADD_ONE = 'DEBT_INPUT_TEMPLATES_ADD_ONE';
// export const DEBT_INPUT_TEMPLATES_UPDATE_ONE = 'DEBT_INPUT_TEMPLATES_UPDATE_ONE'

export const fetchAllTemplates = params => dispatch => {
	dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_FETCH_ALL, status: 'pending'});
	return manualDebtInputsApi
		.fetchTemplates(params)
		.then(templates => {
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_FETCH_ALL, status: 'success', templates: templates});
			return templates;
		})
		.catch(error => {
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_FETCH_ALL, status: 'error', error: error});
		});
};

export const createTemplate = (template, fetchTemplates = true) => dispatch => {
	dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_ADD_ONE, status: 'pending'});
	return manualDebtInputsApi
		.addTemplate(template)
		.then(template => {
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_ADD_ONE, status: 'success', template: template});
			if (fetchTemplates) {
				dispatch(fetchAllTemplates(template.datasetId));
			}
			return template;
		})
		.catch(error => {
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_ADD_ONE, status: 'error', error: error});
		});
};

export const updateTemplate = template => dispatch => {
	dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_UPDATE_ONE, status: 'pending'});
	return manualDebtInputsApi
		.addTemplate(template)
		.then(template => {
			dispatch(fetchAllTemplates(template.datasetId));
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_UPDATE_ONE, status: 'success', template: template});
			return template;
		})
		.catch(error => {
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_UPDATE_ONE, status: 'error', error: error});
		});
};

export const deleteTemplate = (datasetId, templateId) => dispatch => {
	dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_DELETE_ONE, status: 'pending'});
	return manualDebtInputsApi
		.deleteTemplate(datasetId, templateId)
		.then(template => {
			dispatch(fetchAllTemplates(datasetId));
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_DELETE_ONE, status: 'success', templateId: templateId});
			return template;
		})
		.catch(error => {
			dispatch({type: actionTypes.DEBT_INPUT_TEMPLATES_DELETE_ONE, status: 'error', error: error});
		});
};

export default {
	fetchAllTemplates,
	createTemplate,
	updateTemplate,
	deleteTemplate,
};
