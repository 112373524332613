const dateHelpers = require('./dateHelpers');
const moment = require('moment');
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const periods = [
	{
		value: 'daily',
		label: 'Daily',
	},
	{
		value: 'monthEnd',
		label: 'Month End',
	},
	{
		value: 'quarterEnd',
		label: 'Quarter End',
	},
];
const periodsMonthly = [
	{
		value: 3,
		label: 'Last 3 Month Ends',
	},
	{
		value: 6,
		label: 'Last 6 Month Ends',
	},
	{
		value: 12,
		label: 'Last 12 Month Ends',
	},
	{
		value: 'all',
		label: 'All Month Ends',
	},
];
const periodsQuarterly = [
	{
		value: 4,
		label: 'Last 4 Quarter Ends',
	},
	{
		value: 8,
		label: 'Last 8 Quarter Ends',
	},
	{
		value: 12,
		label: 'Last 12 Quarter Ends',
	},
	{
		value: 'all',
		label: 'All Quarter Ends',
	},
];
const getMonthsAndYears = (dates = []) => {
	const monthsAndYears = [];

	dates.forEach(date => {
		const snapshotDate = moment(date.snapshotDate);
		const monthYear = `${months[snapshotDate.month()]}-${snapshotDate.year()}`;
		if (!monthsAndYears.find(my => my.value === monthYear)) {
			monthsAndYears.push({
				label: monthYear,
				value: monthYear,
			});
		}
	});
	return monthsAndYears.sort((dateA, dateB) => (new Date(dateA.value) < new Date(dateB.value) ? -1 : 1));
};

const getDatesForMonth = (dates = [], month = '', year = '') => {
	return dates
		.filter(date => moment(date.snapshotDate).month() === month && moment(date.snapshotDate).year() === year)
		.sort((dateA, dateB) => moment(dateA.snapshotDate) < moment(dateB.snapshotDate));
};

const getMonthEndDates = (dates = []) => {
	const splitByMonths = {};
	const monthEndDates = [];
	dates.forEach(date => {
		const month = moment(date.snapshotDate).month() + 1;
		const year = moment(date.snapshotDate).year();
		if (!splitByMonths[`${month}-${year}`]) {
			splitByMonths[`${month}-${year}`] = [];
		}
		splitByMonths[`${month}-${year}`].push(date);
	});
	// at this point we have a multidimension array broken down by month
	const keys = Object.keys(splitByMonths);
	keys.forEach(key => {
		// Sort so that the later date is first in each array
		const sortedArray = splitByMonths[key].sort((dateA, dateB) => {
			const mDateA = moment(dateA.snapshotDate);
			const mDateB = moment(dateB.snapshotDate);
			if (mDateA < mDateB) return 1;
			if (mDateA > mDateB) return -1;
			return 0;
		});
		monthEndDates.push(sortedArray[0]);
	});
	return monthEndDates.sort((dateA, dateB) => moment(dateA.snapshotDate) < moment(dateB.snapshotDate));
};

const getQuarterEndDates = (dates = []) => {
	const splitByQuarters = {};
	const quarterEndDates = [];
	dates.forEach(date => {
		const quarter = moment(date.snapshotDate).quarter();
		const year = moment(date.snapshotDate).year();
		if (!splitByQuarters[`${quarter}-${year}`]) {
			splitByQuarters[`${quarter}-${year}`] = [];
		}
		splitByQuarters[`${quarter}-${year}`].push(date);
	});
	// at this point we have a multidimension array broken down by quarter
	const keys = Object.keys(splitByQuarters);
	keys.forEach(key => {
		const sortedArray = splitByQuarters[key].sort((dateA, dateB) => {
			return moment(dateA.snapshotDate) < moment(dateB.snapshotDate);
		});
		quarterEndDates.push(sortedArray[0]);
	});
	return quarterEndDates.sort((dateA, dateB) => moment(dateA.snapshotDate) < moment(dateB.snapshotDate));
};

const getLatestSnapshotDate = (snapshots = []) => {
	if (!snapshots.length) {
		return '';
	}
	const dateList = snapshots.map(d => {
		return {
			date: dateHelpers.shortDateToDate(d.snapshotDate),
			shortDate: d.snapshotDate,
		};
	});
	dateList.sort((a, b) => {
		return b.date - a.date;
	});
	return dateList[0].shortDate;
};

const getOldestSnapshotDate = (snapshots = []) => {
	if (!snapshots.length) {
		return '';
	}
	const dateList = snapshots.map(d => {
		return {
			date: dateHelpers.shortDateToDate(d.snapshotDate),
			shortDate: d.snapshotDate,
		};
	});
	dateList.sort((a, b) => {
		return a.date - b.date;
	});
	return dateList[0].shortDate;
};

module.exports = {
	periods,
	periodsMonthly,
	periodsQuarterly,
	getMonthsAndYears,
	getDatesForMonth,
	getMonthEndDates,
	getQuarterEndDates,
	getLatestSnapshotDate,
	getOldestSnapshotDate,
};
