// Globals
import React from 'react';
import PropTypes from 'prop-types';

// Project imports
import KiSelect from 'components/KiSelect';

// Local imports
import styles from './KiFormulaBuilderComponent.theme.scss';

const options = [
	{
		label: 'AND',
		value: '&&',
	},
	{
		label: 'OR',
		value: '||',
	},
];

function LogicComponent({value, onChange}) {
	return (
		<div className={styles.formulaComponent}>
			<KiSelect
				value={options.find(opt => opt.value === value) || options.slice(0, 1)}
				isClearable={false}
				options={options}
				onChange={selected => onChange(selected.value)}
			/>
		</div>
	);
}

LogicComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

LogicComponent.defaultProps = {
	value: '',
	onChange: () => {
		return false;
	},
};

export default LogicComponent;
