import React, {useState, cloneElement} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import KiSelect from '../../../../components/KiSelect';
import SecondSidebar from '../../../../components/SecondSidebar/SecondSidebar';

const SidebarCreationSelect = ({addLabel, value, options, onChange, onCreated, children, ...rest}) => {
	const [showCreationSidebar, setShowCreationSidebar] = useState(false);
	const allOptions = [{label: addLabel, value: '', isAdd: true}, ...options];
	const sidebar = document.getElementById('contextSidebar');

	const selectStyles = {
		option: (styles, {data}) => {
			return {
				...styles,
				fontStyle: data.isAdd ? 'italic' : 'normal',
			};
		},
	};

	const onItemChange = option => {
		if (option.value === '') {
			setShowCreationSidebar(true);
		}
		onChange(option);
	};

	const onNewItemSave = saved => {
		setShowCreationSidebar(false);
		onCreated(saved);
	};

	return (
		<>
			<KiSelect
				value={value || null}
				options={allOptions}
				onChange={onItemChange}
				styles={selectStyles}
				{...rest}
			/>
			{createPortal(
				<SecondSidebar
					isVisible={showCreationSidebar}
					title={addLabel}
					onClose={() => setShowCreationSidebar(false)}
				>
					{cloneElement(children, {
						onClose: () => setShowCreationSidebar(false),
						showClose: false,
						onSave: onNewItemSave,
					})}
				</SecondSidebar>,
				sidebar
			)}
		</>
	);
};

SidebarCreationSelect.propTypes = {
	addLabel: PropTypes.string.isRequired,
	value: PropTypes.object,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
	onCreated: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

export default SidebarCreationSelect;
