import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import KiInput from 'components/KiInput';
import Select from 'react-select';
import styles from './counterparties.theme.scss';
import KiButton from 'components/KiButton';
import {counterPartiesApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';

export function CounterpartiesForm(props) {
	const dispatch = useDispatch();
	const [roleOptions, setRoleOptions] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [savePending, setSavePending] = useState(false);

	useEffect(() => {
		counterPartiesApi
			.fetchCounterPartyRoles()
			.then(roleOptions => {
				setRoleOptions(roleOptions);
			})
			.catch(() => dispatch(showSnackbar('Failure to load counterparty roles')));
	}, []);

	useEffect(
		() => {
			if (props.counterparty.roles) {
				setSelectedRoles(
					roleOptions.filter(role => {
						return !!props.counterparty.roles.find(r => r.id === role.id);
					})
				);
			}
		},
		[props.counterparty]
	);

	const loadOptions = inputValue => {
		return counterPartiesApi
			.searchCounterParties(inputValue)
			.then(counterpartyOptions => {
				counterpartyOptions = _.differenceBy(counterpartyOptions, props.existingCounterparties, 'id');
				return counterpartyOptions.map(opt => ({value: opt.id, label: opt.legalName}));
			})
			.catch(() => dispatch(showSnackbar('Failure to load counterparty options')));
	};

	const handleSave = () => {
		const counterpartyToBeSaved = {
			_id: props.counterparty._id,
			LEI: props.counterparty.LEI,
			rowType: props.counterparty.rowType || 'counterparty',
			datasetId: props.datasetId,
			fundingVehicleId: props.fundingVehicleId,
			roles: selectedRoles.map(role => role.id),
		};
		setSavePending(true);
		return props.onSave(counterpartyToBeSaved).finally(() => {
			setSavePending(false);
		});
	};

	const handleInsert = newLEI => {
		const counterpartyToBeSaved = {
			LEI: newLEI || props.counterparty.LEI,
			rowType: props.counterparty.rowType || 'counterparty',
			datasetId: props.datasetId,
			fundingVehicleId: props.fundingVehicleId,
			roles: [],
		};
		counterpartyToBeSaved._id = newLEI ? null : props.counterparty._id;
		setSavePending(true);
		return props.onSave(counterpartyToBeSaved).finally(() => {
			setSavePending(false);
		});
	};

	const handleCancel = () => {
		props.history.goBack();
	};

	const displayAddress = address => {
		if (address === undefined) {
			return '';
		} else {
			return (
				<div>
					<div>{address.street}</div>
					{address.additionalAddress.map((a, index) => <div key={index.toString()}>{a}</div>)}
					<div>
						{address.city}, {address.state} {address.postalCode} {address.country}
					</div>
				</div>
			);
		}
	};

	const {counterparty} = props;

	return (
		<section>
			{counterparty._id === undefined && (
				<div className="flex-6" style={{marginLeft: '2rem', marginTop: '2rem', width: '23rem'}}>
					<span className="form-instruction">Counterparty</span>
					<AsyncSelect
						cacheOptions
						loadOptions={loadOptions}
						defaultOptions
						onChange={opt => handleInsert(opt.value)}
						placeholder="Start typing to search..."
					/>
				</div>
			)}
			{counterparty._id && (
				<div>
					<div className={'form-row'} style={{display: 'flex', flexDirection: 'row'}}>
						<span
							className={'input-row'}
							style={{marginRight: '2rem', width: '25rem', marginLeft: '2rem', display: 'inline-block'}}
						>
							<KiInput label="LEI" value={counterparty.LEI || ''} readOnly />
						</span>
					</div>
					<div className={'form-row'} style={{display: 'flex', flexDirection: 'row'}}>
						<span className={'input-row'} style={{marginRight: '2rem', width: '25rem', marginLeft: '2rem'}}>
							<label className={styles.label}>Roles</label>
							<Select
								classNamePrefix="aut-select"
								isMulti={true}
								isClearable={false}
								options={roleOptions.filter(role => !counterparty.roles.includes(role)) || []}
								value={selectedRoles}
								getOptionValue={opt => opt.id}
								getOptionLabel={opt => opt.role}
								onChange={opt => setSelectedRoles(opt)}
							/>
						</span>
					</div>
					<div className={'form-row'} style={{display: 'flex', flexDirection: 'row'}}>
						<span className={'input-row'} style={{marginRight: '2rem', width: '25rem', marginLeft: '2rem'}}>
							<KiInput label="Legal Name" value={counterparty.legalName || ''} readOnly />
						</span>
					</div>
					<div className={'form-row'} style={{display: 'flex', flexDirection: 'row'}}>
						<span className={'input-row'} style={{marginRight: '2rem', width: '25rem', marginLeft: '2rem'}}>
							<KiInput label="Other Name" value={counterparty.otherName || ''} readOnly />
						</span>
					</div>
					<div className={'form-row'}>
						<span
							className={'theme-label'}
							style={{
								fontSize: '1.2rem',
								top: '0.6rem',
								marginLeft: '2rem',
								marginBottom: '0.15rem',
								color: 'rgba(33, 33, 33, 0.26)',
							}}
						>
							Legal Address
						</span>
					</div>
					<div style={{fontSize: '1.6rem', top: '0.6rem', marginLeft: '2rem', marginBottom: '2rem'}}>
						<span>{displayAddress(counterparty.legalAddress)}</span>
					</div>
					<div className={'form-row'}>
						<span
							className={'theme-label'}
							style={{
								fontSize: '1.2rem',
								top: '0.6rem',
								marginLeft: '2rem',
								marginBottom: '0.15rem',
								color: 'rgba(33, 33, 33, 0.26)',
							}}
						>
							Headquarter Address
						</span>
					</div>
					<div style={{fontSize: '1.6rem', top: '0.6rem', marginLeft: '2rem', marginBottom: '1rem'}}>
						<span>{displayAddress(counterparty.headquarterAddress)}</span>
					</div>
					<div className={'form-row'} style={{display: 'flex', flexDirection: 'row'}}>
						<span className={'input-row'} style={{marginRight: '2rem', width: '25rem', marginLeft: '2rem'}}>
							<KiInput label="Email" value={counterparty.email || ''} readOnly />
						</span>
					</div>
					<div>
						<div className={'form-row'} style={{display: 'flex', flexDirection: 'row'}}>
							<span
								className={'input-row'}
								style={{marginRight: '2rem', width: '25rem', marginLeft: '2rem'}}
							>
								<KiInput
									label="Direct Parent"
									value={counterparty.directParent ? counterparty.directParent.legalName : ''}
									readOnly
								/>
							</span>
						</div>
					</div>
					<div>
						<div className={'form-row'} style={{display: 'flex', flexDirection: 'row'}}>
							<span
								className={'input-row'}
								style={{marginRight: '2rem', width: '25rem', marginLeft: '2rem'}}
							>
								<KiInput
									label="Ultimate Parent"
									value={counterparty.ultimateParent ? counterparty.ultimateParent.legalName : ''}
									readOnly
								/>
							</span>
						</div>
					</div>
				</div>
			)}
			{counterparty._id && (
				<footer className={styles.footer}>
					<KiButton flat primary onClick={handleCancel} label="Cancel" />
					<KiButton
						className={styles.saveBtn}
						primary
						raised
						label="Save"
						disabled={savePending || !selectedRoles}
						onMouseUp={handleSave}
					/>
				</footer>
			)}
		</section>
	);
}

CounterpartiesForm.propTypes = {
	onSave: PropTypes.func,
	fundingVehicleId: PropTypes.string.isRequired,
	datasetId: PropTypes.string,
	counterparty: PropTypes.object || undefined,
	history: PropTypes.object.isRequired,
	existingCounterparties: PropTypes.object,
};

CounterpartiesForm.defaultProps = {
	counterparty: {},
};

export default CounterpartiesForm;
