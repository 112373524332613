// Globals
import React from 'react';
import PropTypes from 'prop-types';

// Project imports
import KiSelect from 'components/KiSelect';

// Local imports
import styles from './KiFormulaBuilderComponent.theme.scss';

const options = [
	{
		label: '=',
		value: '==',
	},
	{
		label: '<>',
		value: '!=',
	},
	{
		label: '>',
		value: '>',
	},
	{
		label: '>=',
		value: '>=',
	},
	{
		label: '<',
		value: '<',
	},
	{
		label: '<=',
		value: '<=',
	},
];

const stringOptions = [
	{
		label: '=',
		value: '==',
	},
	{
		label: '<>',
		value: '!=',
	},
];

function RelationalOperatorComponent({value, onChange, dataType}) {
	return (
		<div className={styles.formulaComponent}>
			<KiSelect
				value={options.find(opt => opt.value === value) || options.slice(0, 1)}
				isClearable={false}
				options={dataType === 'string' ? stringOptions : options}
				onChange={selected => onChange(selected.value)}
			/>
		</div>
	);
}

RelationalOperatorComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	dataType: PropTypes.string,
};

RelationalOperatorComponent.defaultProps = {
	value: '',
	onChange: () => {
		return false;
	},
};

export default RelationalOperatorComponent;
