// Framework will be CASE, THEN, ELSE rows

// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _get from 'lodash/get';
import {logicOptions} from 'ki-common/utils/expressionUtil';
import uuidV4 from 'uuid/v4';

// Project imports
import KiButton from 'components/KiButton';
import KiIconButton from 'components/KiIconButton';

// Local imports
import LogicAndOrBlock from './LogicAndOrBlock';
import styles from './CaseThenElseBlock.theme.scss';
const cx = classNames.bind(styles);

const getValueFromLabel = label => {
	return _get(logicOptions.find(opt => opt.label === label), 'value', '&&');
};

const getLabelFromValue = value => {
	return _get(logicOptions.find(opt => opt.value === value), 'label', 'AND');
};

const getBlankLogicBlock = () => {
	return {
		id: uuidV4(),
		element: 'AND',
		framework: [
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
			{
				type: 'constant',
				value: '',
				id: uuidV4(),
				element: 'VALUE',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '==',
				element: 'RELATIONAL',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
			{
				id: uuidV4(),
				type: 'constant',
				value: '',
				element: 'VALUE',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
		],
	};
};

function CaseThenElseBlock({
	element,
	framework: propFramework,
	onChange,
	caseCount,
	onDeleteCase,
	errors,
	moveCaseUp,
	moveCaseDown,
}) {
	// Local State
	const [framework, setFramework] = useState(propFramework);

	// On prop.formulaArray change
	useEffect(
		() => {
			//const res = await columnServiceApi.getColumnsFromService(datasetId, params);
			setFramework(propFramework);
		},
		[propFramework]
	);

	const updateFramework = (id, newValue, paramName = 'framework') => {
		const index = framework.findIndex(entry => entry.id === id);
		if (index > -1) {
			const newFramework = _cloneDeep(framework);
			_set(newFramework, `[${index}].${paramName}`, newValue);
			setFramework(newFramework);
			onChange(newFramework);
		}
	};

	const deleteAndOrBlock = id => {
		const newFramework = framework.filter(entry => entry.id !== id);
		setFramework(newFramework);
		onChange(newFramework);
	};

	const renderCaseFrameworkElements = () => {
		return framework.map(entry => {
			return (
				<LogicAndOrBlock
					key={entry.id}
					id={entry.id}
					element={entry.element}
					logicValue={getValueFromLabel(entry.element)}
					framework={entry.framework}
					onChange={newFramework => {
						updateFramework(entry.id, newFramework);
					}}
					onDelete={() => deleteAndOrBlock(entry.id)}
					onElementChange={newValue => {
						updateFramework(entry.id, getLabelFromValue(newValue), 'element');
					}}
					caseBlock={element}
				/>
			);
		});
	};

	const handleAddLogicClick = () => {
		const newFramework = _cloneDeep(framework); // Prevent mutation
		newFramework.push(getBlankLogicBlock());
		setFramework(newFramework);
		onChange(newFramework);
	};

	if (!framework) {
		return <div>Cannot render block (001). Framework not found.</div>;
	}

	// THEN and ELSE blocks cannot add more logic rows
	return (
		<div className={cx('main', [`${element.toLowerCase()}`])}>
			<div className={styles.typeRow}>
				<span className={styles.label}>{element}</span>
				{element === 'CASE' && (
					<span className={styles.logicButton}>
						<KiButton raised primary onClick={handleAddLogicClick}>
							Add Logic
						</KiButton>
					</span>
				)}
				{element === 'CASE' &&
					caseCount > 1 && (
						<span className={styles.closeButtonContainer}>
							<KiIconButton icon="arrow_upward" onClick={moveCaseUp} className={styles.closeButton} />
							<KiIconButton icon="arrow_downward" onClick={moveCaseDown} className={styles.closeButton} />
							<KiIconButton icon="delete" onClick={onDeleteCase} className={styles.closeButton} />
						</span>
					)}
			</div>
			{errors.length > 0 && (
				<div className={styles.errors}>{errors.map((err, idx) => <p key={idx}>{err}</p>)}</div>
			)}
			<div>{renderCaseFrameworkElements()}</div>
		</div>
	);
}

CaseThenElseBlock.propTypes = {
	element: PropTypes.string,
	framework: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	caseCount: PropTypes.number,
	onDeleteCase: PropTypes.func.isRequired,
	errors: PropTypes.array,
	moveCaseUp: PropTypes.func.isRequired,
	moveCaseDown: PropTypes.func.isRequired,
};

CaseThenElseBlock.defaultProps = {
	value: '',
	onChange: () => false,
	caseCount: 1,
	onDeleteCase: () => false,
	errors: [],
	moveCaseUp: () => false,
	moveCaseDown: () => false,
};

export default CaseThenElseBlock;
