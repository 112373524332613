// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import uuidV4 from 'uuid/v4';

// Project imports
import ParensComponent from './ParensComponent';
import ValueComponent from './ValueComponent/';
import OperatorValueBlock from './OperatorValueBlock';
import OperatorComponent from './OperatorComponent';
import AddOperatorValueBlockButton from './AddOperatorValueBlockButton';

// Local imports
import styles from './NumericBlock.theme.scss';

const getOperatorValueBlock = () => {
	return {
		id: uuidV4(),
		element: 'OPERATOR_BLOCK',
		formula: [
			{
				id: uuidV4(),
				type: 'operator',
				value: '+',
				element: 'OPERATOR',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
			{
				type: 'constant',
				value: '',
				id: uuidV4(),
				element: 'VALUE',
			},
			{
				id: uuidV4(),
				type: 'operator',
				value: '',
				element: 'PAREN',
			},
		],
	};
};

function NumericBlock({framework: propFramework, errors, onChange}) {
	// Cannot change for numeric formulas
	const valueDataType = 'numeric';

	// Local State
	const [framework, setFramework] = useState(propFramework);

	// On prop.formulaArray change
	useEffect(
		() => {
			setFramework(propFramework);
		},
		[propFramework]
	);

	// Update a single field
	const updateFrameworkField = (id, field, value) => {
		const index = framework.findIndex(entry => entry.id === id);
		const newFramework = _cloneDeep(framework);
		if (index > -1) {
			_set(newFramework, `[${index}].${field}`, value);
			setFramework(newFramework);
		}
		onChange(newFramework);
	};

	// Update a value and type
	const updateFrameworkValue = (id, newEntry) => {
		const index = framework.findIndex(entry => entry.id === id);
		const newFramework = _cloneDeep(framework);
		if (index > -1) {
			newFramework[index] = newEntry;
			setFramework(newFramework);
		}
		onChange(newFramework);
	};

	const deleteOperatorValueblock = id => {
		const newFramework = framework.filter(entry => entry.id !== id);
		setFramework(newFramework);
		onChange(newFramework);
	};

	const addOperatorValueBlock = beforeKey => {
		const newFramework = _cloneDeep(framework);
		const newBlock = getOperatorValueBlock();
		if (beforeKey === null) {
			// Add at the end of the row
			newFramework.push(newBlock);
		} else {
			// Find that key and put the new block before it
			const index = framework.findIndex(entry => entry.id === beforeKey);
			newFramework.splice(index, 0, newBlock);
		}
		setFramework(newFramework);
		onChange(newFramework);
	};

	const renderLogicFrameworkElements = () => {
		const toReturn = framework.reduce((elements, entry) => {
			switch (entry.element) {
				case 'OPERATOR':
					elements.push(
						<OperatorComponent
							key={entry.id}
							value={entry.value}
							onChange={value => updateFrameworkField(entry.id, 'value', value)}
						/>
					);
					break;
				case 'PAREN':
					elements.push(
						<ParensComponent
							key={entry.id}
							value={entry.value}
							onChange={value => updateFrameworkField(entry.id, 'value', value)}
						/>
					);
					break;
				case 'VALUE':
					elements.push(
						<ValueComponent
							id={entry.id}
							key={entry.id}
							formulaEntry={entry}
							valueDataType={valueDataType}
							onChange={newEntry => updateFrameworkValue(entry.id, newEntry)}
							isConstantAllowed={true} // Constants are always allowed in value block pairs
						/>
					);
					break;
				case 'OPERATOR_BLOCK':
					elements.push(
						<OperatorValueBlock
							key={entry.id}
							formula={entry.formula}
							valueDataType={valueDataType}
							onChange={value => updateFrameworkField(entry.id, 'formula', value)}
							onDelete={() => deleteOperatorValueblock(entry.id)}
						/>
					);
					break;
				default:
					elements.push(<div key={entry.id}>{JSON.stringify(entry, null, 2)}</div>);
			}
			return elements;
		}, []);
		// add new operator block button (end of logic row)
		if (valueDataType === 'numeric') {
			toReturn.push(
				<AddOperatorValueBlockButton key={'ONLY_ADD_BLOCK'} onClick={() => addOperatorValueBlock(null)} />
			);
		}
		return toReturn;
	};

	if (!framework) {
		return <div>Cannot render block (002). Framework not found.</div>;
	}

	// If this is an AND/OR block add a dropdown to select type before
	// and add a closing button option to remove it
	return (
		<div className={styles.main}>
			{errors.length > 0 && (
				<div className={styles.errors}>{errors.map((err, idx) => <p key={idx}>{err}</p>)}</div>
			)}
			{renderLogicFrameworkElements()}
		</div>
	);
}

NumericBlock.propTypes = {
	framework: PropTypes.array,
	errors: PropTypes.array,
	onChange: PropTypes.func.isRequired,
};

NumericBlock.defaultProps = {
	errors: [],
	onChange: () => {
		return false;
	},
};

export default NumericBlock;
