import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const getPersistenceJobs = (datasetId, status) => {
	const url = new URL(`${apiUrl}/persistence`, document.baseURI);
	if (datasetId) url.searchParams.set('datasetId', datasetId);
	if (status) url.searchParams.set('status', status);
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	}).then(_handleResponse);
};
export const createPersistenceJob = (datasetId, snapshotDate, reason, type) => {
	const url = new URL(`${apiUrl}/persistence?runImmediately=true`, document.baseURI);
	return fetch(url, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId,
			snapshotDate,
			status: 'queued',
			reason: reason || 'submission',
			type: type,
		}),
	}).then(_handleResponse);
};

export const runJob = job => {
	const url = new URL(`${apiUrl}/persistence/${job._id}`, document.baseURI);
	return fetch(url, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(job),
	}).then(_handleResponse);
};

export const updateJob = job => {
	const url = new URL(`${apiUrl}/persistence/${job._id}`, document.baseURI);
	return fetch(url, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify({status: job.status}),
	}).then(_handleResponse);
};

export default {
	getPersistenceJobs,
	createPersistenceJob,
	runJob,
	updateJob,
};
