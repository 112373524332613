import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import {requeryColumnList} from 'containers/datasetList/actions';
import AssetFormDialog from './forms/AssetFormDialog';
import SummaryFormDialog from './forms/SummaryFormDialog';
import './columnpicker.scss';
import calcColumns from 'ki-common/utils/calcColumns';
import {connect} from 'react-redux';
import {showSnackbar} from 'state/actions/Snackbar';
import _get from 'lodash/get';
import _reject from 'lodash/reject';
import {fetchAllTemplates} from 'containers/debtInputMaintenance/actions';
import _sortBy from 'lodash/sortBy';
import {fetchFundingVehicleList} from '../../containers/fundingVehicleList/actions';
import {associatedDataApi} from 'api';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {defaultMinimalFieldColumnSpecification} from 'api/columnServiceApi';

const deriveDisplayFormatFromTemplate = dataType => {
	switch (dataType) {
		case 'numeric':
			return '9,999.99';
		case 'date':
		case 'date_long':
			return 'yyyy-MM-dd';
		case 'date_short':
			return 'yyyy-MM';
		case 'string':
			return 'string';
		default:
			return dataType;
	}
};

export class ColumnPicker extends Component {
	static propTypes = {
		disabled: PropTypes.bool,
		className: PropTypes.string,
		options: PropTypes.array,
		returnNewRows: PropTypes.func,
		dataset: PropTypes.object.isRequired,
		closeForm: PropTypes.func,
		columnToEdit: PropTypes.object,
		requeryColumnList: PropTypes.func,
		mode: PropTypes.string,
		title: PropTypes.string,
		user: PropTypes.object,
		reloadView: PropTypes.func,
		columnType: PropTypes.string,
		noNewColumn: PropTypes.bool,
		fundingVehicles: PropTypes.array,
		fetchAllTemplates: PropTypes.func,
		fetchAllFundingVehicles: PropTypes.func,
		templates: PropTypes.array,
		debtFVSetupCols: PropTypes.array,
		debtBorrowingBaseCols: PropTypes.array,
		formParams: PropTypes.object,
		currentView: PropTypes.object,
		columns: PropTypes.array,
	};

	static defaultProps = {
		mode: 'modal',
		dataset: {
			columns: [],
		},
		columns: [],
		options: [],
		reloadView: () => null,
		fundingVehicles: [],
		templates: [],
		debtFVSetupCols: [],
		debtBorrowingBaseCols: [],
		currentView: {
			explorerData: {
				columns: [],
			},
		},
	};

	state = {
		selectedValue: '',
		dialogActive: false,
		allColumns: [],
		existingTags: [],
		associatedSchemaColumns: [],
	};

	componentDidMount() {
		const datasetId = _get(this.props, 'match.params.datasetId', _get(this.props, 'dataset.datasetId')) || '';
		associatedDataApi
			.fetchAssociatedDataSchemasByDatasetId(datasetId)
			.then(schemaList => this.setState({schemaList: schemaList}));

		return this.props.requeryColumnList(datasetId).then(columns => {
			if (!columns) {
				return null;
			}
			this.props.fetchAllFundingVehicles(datasetId);
			this.props.fetchAllTemplates(datasetId);
			return this.setState({
				existingTags: Array.from(
					new Set(
						this.props.columns.reduce((acc, col) => {
							if (col.tags && col.tags.length) {
								return [...acc, ...col.tags];
							}
							return acc;
						}, [])
					)
				),
			});
		});
	}

	componentDidUpdate(prevProps) {
		// want this to update if we havent set a form type
		if (!_.isEqual(prevProps, this.props) || !this.state.selectedValue) {
			const {columnToEdit} = this.props;
			if (columnToEdit) {
				let formType = columnToEdit.dataType;

				if (_get(columnToEdit, 'formula.op') === 'parentData') {
					formType = 'parent_data';
				}

				if (columnToEdit.columnType === 'aggregate') {
					formType = _get(columnToEdit, 'assetColumn.dataType', 'numeric');
				}

				if (_get(columnToEdit, 'formula.op') !== 'parentData' && columnToEdit.columnType !== 'aggregate') {
					formType = columnToEdit.columnFormType;
				}

				if (columnToEdit.formulas) {
					formType = columnToEdit.columnFormType;
				}

				//TECH-DEBT - refactor form type field on mongo

				this.setState({
					selectedValue: formType,
					dialogActive: this.props.columnToEdit ? true : false,
				});
			}
		}
	}

	handleOnChange = option => {
		this.setState({
			selectedValue: option.value,
			dialogActive: true,
		});
	};

	determineCreatedBy = (columnData, user) => {
		// if there is a userId then use it (admin updating for example) otherwise use this usersId
		const isAdmin = user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		let userId = columnData.createdBy || user.userId;

		if (columnData.isGlobal && isAdmin) {
			// force global if the user is admin
			userId = '';
		}
		return userId;
	};

	handleAssetCalcColumnSave = columnDataOrig => {
		// child columns only need to persist the schemacolumnId not the object
		const columnData = _.cloneDeep(columnDataOrig);

		switch (this.state.selectedValue) {
			case 'numeric':
				delete columnData.calculationOperands;
				break;
			case 'conditional':
				if (columnData.columnType === 'debtCalculation') {
					break; // Formula already provided
				}
				columnData.formula = calcColumns.createConditionalColumn(columnData);
				delete columnData.conditions;
				delete columnData.elseData;
				break;
			case 'parent_data':
				columnData.formula = calcColumns.createParentDataColumn(columnData);
				delete columnData.parentColumn;
				delete columnData.aggregationType;
				delete columnData.assetColumn;
				delete columnData.calculateColumn;
				break;
			case 'child_data':
				delete columnData.childColumn;
				break;
		}

		columnData.datasetId = this.props.dataset.datasetId;
		columnData.createdBy = this.determineCreatedBy(columnData, this.props.user);
		delete columnData.isGlobal;
		// console.log('handleAssetCalcColumnSave', columnData, columnDataOrig);
		return this.props.returnNewRows([columnData]).then(() => {
			if (this.props.currentView.explorerData.columns.some(col => col._id === columnData._id)) {
				this.props.reloadView();
			}
		});
	};

	handleSummaryRatioColumnSave = column => {
		column.createdBy = this.determineCreatedBy(column, this.props.user);
		delete column.isGlobal;
		return this.props.returnNewRows([column]).then(() => {
			this.props.reloadView();
		});
	};

	handleSummaryAggregateColumnSave = columns => {
		if (columns && columns.length) {
			columns.forEach(c => {
				c.createdBy = this.determineCreatedBy(c, this.props.user);
				delete c.isGlobal;
			});
		}
		return this.props.returnNewRows(columns).then(() => {
			if (
				this.props.currentView.explorerData.columns.some(col =>
					columns.some(calcCol => calcCol._id === col._id)
				)
			) {
				this.props.reloadView();
			}
		});
	};

	closeModal = () => {
		this.setState({
			dialogActive: false,
			selectedValue: '',
		});
		this.props.closeForm();
	};

	// Keyword: CUSTOM_DEBT_COLUMNS
	getColumnList = columnType => {
		// don't show asset column for total as it is just a placeholder to have the cohort column called total
		let cols = _reject(this.props.columns, {
			columnName: 'Total',
			columnType: 'asset',
		});
		if (columnType === 'debtCalculation') {
			// If this is calculations on debt screen? add manual inputs, fv setup cols, and borrowing cols
			cols = cols.concat(this.props.templates, this.props.debtFVSetupCols, this.props.debtBorrowingBaseCols);
		}

		return _sortBy(cols, ({displayName = ''}) => displayName.toLowerCase());
	};

	getAggregationOptions = dataType => {
		switch (dataType) {
			case 'numeric':
				return [
					{
						value: 'SUM',
						label: 'SUM',
					},
					{
						value: 'MIN',
						label: 'MIN',
					},
					{
						value: 'MAX',
						label: 'MAX',
					},
					{
						value: 'AVG',
						label: 'AVG',
					},
				];
			case 'string':
				return [
					{
						value: 'FIRST_VALID',
						label: 'First Valid',
					},
					{
						value: 'CONCATENATE',
						label: 'Concatenate',
					},
				];
			default:
				// date
				return [
					{
						value: 'FIRST_VALID',
						label: 'First Valid',
					},
					{
						value: 'MIN',
						label: 'MIN',
					},
					{
						value: 'MAX',
						label: 'MAX',
					},
				];
		}
	};

	render() {
		if (!this.state.schemaList) {
			return null;
		}
		const {columnToEdit} = this.props;
		return (
			<div className="new-column-select">
				{!this.props.columnToEdit && (
					<Select
						classNamePrefix="aut-select"
						isClearable={false}
						isDisabled={this.props.disabled}
						className={this.props.className}
						options={this.props.options}
						value={this.props.options.find(o => o.value === this.state.selectedValue)}
						placeholder={this.props.title}
						onChange={this.handleOnChange}
					/>
				)}
				{['asset', 'debtCalculation'].includes(this.props.columnType) ||
				_.get(columnToEdit, 'columnType') === 'waterfallVariable' ? (
					<AssetFormDialog
						selectedValue={this.state.selectedValue}
						active={this.state.dialogActive}
						columnList={this.getColumnList(this.props.columnType)}
						columnToEdit={this.props.columnToEdit}
						saveSummaryAggregateColumn={this.handleSummaryAggregateColumnSave}
						saveCalcColumn={this.handleAssetCalcColumnSave}
						saveSummaryRatioColumn={this.handleSummaryRatioColumnSave}
						closeForm={this.closeModal}
						mode={this.props.mode}
						existingTags={this.state.existingTags}
						user={this.props.user}
						datasetId={this.props.dataset.datasetId}
						reloadView={this.props.reloadView}
						noNewColumn={this.props.noNewColumn}
						fundingVehicles={this.props.fundingVehicles}
						schemaList={this.state.schemaList}
						getAggregationOptions={this.getAggregationOptions}
						dataset={this.props.dataset}
						columnType={this.props.columnType}
						formParams={this.props.formParams}
						columns={this.props.columns}
					/>
				) : (
					<SummaryFormDialog
						selectedValue={this.state.selectedValue}
						active={this.state.dialogActive}
						columnList={this.getColumnList()}
						columnToEdit={this.props.columnToEdit}
						saveSummaryAggregateColumn={this.handleSummaryAggregateColumnSave}
						closeForm={this.closeModal}
						mode={this.props.mode}
						existingTags={this.state.existingTags}
						user={this.props.user}
						datasetId={this.props.dataset.datasetId}
						reloadView={this.props.reloadView}
						formParams={this.props.formParams}
					/>
				)}
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		dataset: state.datasetList.selected,
		fundingVehicles: state.fundingVehicleList.data.filter(
			fv => fv.datasetId === state.datasetList.selected.datasetId
		),
		templates: state.debtInputMaintenance.savedTemplates.map(st => ({
			_id: st._id,
			columnType: st.viewType === 'fundingVehicle' ? 'debtInputFV' : 'debtInputTranche',
			datasetId: state.datasetList.selected.datasetId,
			dataType: st.dataType,
			displayFormat: deriveDisplayFormatFromTemplate(st.dataType),
			displayName: st.name,
			defaultValue: st.defaultValue,
			columnName: st.name,
		})),
	};
};

const mapDispatchToProps = dispatch => ({
	showSnackbar: msg => dispatch(showSnackbar(msg)),
	//fetchDataset: datasetId => dispatch(fetchDataset(datasetId)),
	requeryColumnList: datasetId => dispatch(requeryColumnList(datasetId, defaultMinimalFieldColumnSpecification)),
	fetchAllFundingVehicles: datasetId => dispatch(fetchFundingVehicleList(datasetId)),
	fetchAllTemplates: datasetId => dispatch(fetchAllTemplates(datasetId)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ColumnPicker));
