import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiFontIcon from 'components/KiFontIcon';
import classNames from 'classnames/bind';
import styles from './KiButton.theme.scss';

const cx = classNames.bind(styles);
export class KiButton extends Component {
	static propTypes = {
		children: PropTypes.node,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		flat: PropTypes.bool,
		href: PropTypes.string,
		icon: PropTypes.string,
		iconClassName: PropTypes.string,
		iconButton: PropTypes.bool,
		inverse: PropTypes.bool,
		label: PropTypes.string,
		mini: PropTypes.bool,
		neutral: PropTypes.bool,
		onMouseLeave: PropTypes.func,
		onMouseUp: PropTypes.func,
		primary: PropTypes.bool,
		raised: PropTypes.bool,
		type: PropTypes.string,
		form: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		disabled: false,
		icon: null,
		iconButton: false,
		neutral: true,
		primary: false,
		raised: false,
		type: 'button',
	};

	handleMouseUp = event => {
		this.buttonNode.blur();
		if (this.props.onMouseUp) this.props.onMouseUp(event);
	};

	handleMouseLeave = event => {
		this.buttonNode.blur();
		if (this.props.onMouseLeave) this.props.onMouseLeave(event);
	};

	render() {
		const {
			accent, // eslint-disable-line
			children,
			className,
			flat, // eslint-disable-line
			href,
			icon,
			iconButton,
			iconClassName,
			inverse,
			label,
			mini,
			neutral, // eslint-disable-line
			primary, // eslint-disable-line
			raised, // eslint-disable-line
			type,
			...others
		} = this.props;

		const element = href ? 'a' : 'button';
		const level = primary ? 'primary' : 'neutral';
		const shape = flat ? 'flat' : 'raised'; // eslint-disable-line
		const mouseEvents = {
			onMouseUp: this.handleMouseUp,
			onMouseLeave: this.handleMouseLeave,
		};

		const classes = cx(
			'button',
			`${level}`,
			{[shape]: !iconButton},
			{mini: mini},
			{inverse: inverse},
			{primary: primary},
			{raised: raised},
			{'icon-button': iconButton},
			className
		);

		const props = {
			...others,
			...mouseEvents,
			href,
			ref: node => {
				this.buttonNode = node;
			},
			className: classes,
			disabled: this.props.disabled,
			type: !href ? type : null,
			form: this.props.form,
		};

		return React.createElement(
			element,
			props,
			icon ? <KiFontIcon value={icon} className={cx('icon', iconClassName)} /> : null,
			label,
			children
		);
	}
}
export default KiButton;
