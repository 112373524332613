import {
	GLOBAL_NAV_SHOW,
	GLOBAL_NAV_HIDE,
	CONTEXT_SIDEBAR_SHOW,
	CONTEXT_SIDEBAR_HIDE,
	CONTEXT_SIDEBAR_OPEN_PANEL,
	CONTEXT_SIDEBAR_CLOSE_PANEL,
	CARD_EDITOR_SHOW,
	CARD_EDITOR_HIDE,
} from 'state/actions/App';

const initialState = {
	kiVersion: window.ABS_SSO_ENABLED === 'true' ? 'ABS Suite Plus' : 'Ki 4.0',
	globalNavOpen: false,
	contextSidebarOpen: false,
	contextSidebarActivePanel: null,
	contextSidebarPanelConfig: {},
	cardEditorOpen: false,
};

function AppReducer(state = initialState, action) {
	switch (action.type) {
		case GLOBAL_NAV_SHOW:
			return {...state, globalNavOpen: true};

		case GLOBAL_NAV_HIDE:
			return {...state, globalNavOpen: false};

		case CONTEXT_SIDEBAR_SHOW:
			return {...state, contextSidebarOpen: true};

		case CONTEXT_SIDEBAR_HIDE:
			return {...state, contextSidebarOpen: false};

		case CONTEXT_SIDEBAR_OPEN_PANEL:
			return {
				...state,
				contextSidebarActivePanel: action.panelName,
				contextSidebarPanelConfig: action.panelConfig || {},
			};

		case CONTEXT_SIDEBAR_CLOSE_PANEL:
			return {...state, contextSidebarActivePanel: null};

		case CARD_EDITOR_SHOW:
			return {...state, cardEditorOpen: true};

		case CARD_EDITOR_HIDE:
			return {...state, cardEditorOpen: false};

		default:
			return state;
	}
}

export default AppReducer;
