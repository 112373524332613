// Global imports
import React, {createContext, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

// Project imports
import {showSnackbar} from 'state/actions/Snackbar';
import {USER_GROUPS, userGroupsIntersect} from 'utils/userGroupsUtil';
import {dataBookmarksApi} from 'api';

// Local imports
import {DataExplorerContext} from '../DataExplorerContext';

// Logger
import ReactLogger from 'utils/ReactLogger';
const logger = new ReactLogger({level: window.REACT_LOG_LEVEL});

/*
USAGE IN HOOKS
	import {QuickFilterSettingsContext} from './QuickFilterSettingsContext';

	const dataExplorerContext = useContext(QuickFilterSettingsContext);
	const name = dataExplorerContext.bookmark.name;
*/

// Main context
export const BookmarkApiContext = createContext();
BookmarkApiContext.displayName = 'BookmarkApiContext';
export default BookmarkApiContext;

// Main provider
export const BookmarkApiProvider = ({children}) => {
	// Redux State
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const userId = user.userId;
	const isAdmin = userGroupsIntersect(user.groups, [USER_GROUPS.SYSTEM_ADMINS]);

	// Context State
	const dataExplorerContext = useContext(DataExplorerContext);
	const {appliedBookmark, setAppliedBookmark, actions} = dataExplorerContext;
	const {setHasUnsavedChanges} = actions;

	// const dataset = useSelector(state => state.datasetList.selected);
	// TODO move all the bookmark lookup logic and fetchBookmarkList out of DataExplorerLayout and into this context

	const fetchAllBookmarks = async () => {
		logger.log('BookmarkApiProvider - fetchAllBookmarks');
	};

	const fetchBookmarkToLoad = async () => {
		logger.log('BookmarkApiProvider - fetchBookmarkToLoad');
	};

	const _getDataTransferViewId = (isGlobal, isStratification, existingDataTransferViewId) => {
		let dataTransferViewId = existingDataTransferViewId;
		if (!isGlobal) {
			dataTransferViewId = null;
		} else if (isStratification) {
			dataTransferViewId = 'encumbranceStratification';
		} else if (!isStratification && dataTransferViewId === 'encumbranceStratification') {
			// only set to null when it was already set to encumbrance (or else it won't clear)
			dataTransferViewId = null;
		}
		return dataTransferViewId;
	};

	const _upsertBookmark = bookmark => {
		delete bookmark.explorerData.data;
		delete bookmark.explorerData.isLoading;
		delete bookmark.explorerData.error;

		return dataBookmarksApi
			.upsertBookmark(bookmark)
			.then(result => {
				dispatch(showSnackbar(`Saved view "${bookmark.name}" successfully`));
				setHasUnsavedChanges(false);
				return result;
			})
			.catch(err => {
				dispatch(showSnackbar(err.message));
			});
	};

	const saveBookmark = ({
		name = `${appliedBookmark.name}_copy`,
		tags = appliedBookmark.tags,
		isDefault = appliedBookmark.isDefault,
		isFavorite = appliedBookmark.isFavorite,
		isGlobal = appliedBookmark.isGlobal,
		isStratification = false,
	}) => {
		// Only an admin can make a global bookmark
		const isGlobalParam = isGlobal && isAdmin;

		const dataTransferViewId = _getDataTransferViewId(
			isGlobalParam,
			isStratification,
			appliedBookmark.dataTransferViewId
		);

		const updatedBookmark = Object.assign({}, appliedBookmark, {
			name,
			tags,
			isDefault,
			isFavorite,
			isGlobal: isGlobalParam,
			dataTransferViewId,
		});

		setAppliedBookmark({
			name,
			tags,
			isDefault,
			isFavorite,
			isGlobal,
			dataTransferViewId,
		});
		return _upsertBookmark(updatedBookmark);
	};

	const _copyBookmark = newBookmark => {
		// Copied views should not have an _id to start
		delete newBookmark._id;

		// Cannot be system views inherently
		delete newBookmark.isSystem;

		// Copies will not be default funding options
		delete newBookmark.isDefaultFundingBreaches;
		delete newBookmark.isDefaultFundingExcess;

		return _upsertBookmark(newBookmark);
	};

	const copyBookmark = bookmarkToCopy => {
		const newBookmark = Object.assign({}, bookmarkToCopy, {
			name: `${bookmarkToCopy.name}_copy`,
			createDate: new Date(),
			createdBy: userId,
		});

		// Only an admin can make a global bookmark
		newBookmark.isGlobal = bookmarkToCopy.isGlobal && isAdmin;

		newBookmark.dataTransferViewId = _getDataTransferViewId(
			bookmarkToCopy.isGlobal,
			bookmarkToCopy.dataTransferViewId === 'encumbranceStratification',
			bookmarkToCopy.dataTransferViewId
		);

		return _copyBookmark(newBookmark);
	};

	const copyCurrentBookmark = ({
		name = `${appliedBookmark.name}_copy`,
		tags = appliedBookmark.tags,
		isDefault = false,
		isFavorite = false,
		isGlobal = false,
		isStratification = false,
	}) => {
		// Only an admin can make a global bookmark
		const isGlobalParam = isGlobal && isAdmin;

		const dataTransferViewId = _getDataTransferViewId(
			isGlobalParam,
			isStratification,
			appliedBookmark.dataTransferViewId
		);

		const newBookmark = Object.assign({}, appliedBookmark, {
			name,
			tags,
			isDefault,
			isFavorite,
			isGlobal: isGlobalParam,
			isGadget: false,
			createDate: new Date(),
			createdBy: userId,
			dataTransferViewId,
		});

		return _copyBookmark(newBookmark);
	};

	const deleteBookmark = async bookmarkId => {
		logger.log('BookmarkApiProvider - deleteBookmark', bookmarkId);
	};

	// -------------------
	// useEffect functions
	// -------------------

	// TODO placeholder
	useEffect(() => {
		logger.log('BookmarkApiContext');
	}, []);

	return (
		<BookmarkApiContext.Provider
			value={{
				fetchAllBookmarks,
				fetchBookmarkToLoad,
				saveBookmark,
				copyBookmark,
				copyCurrentBookmark,
				deleteBookmark,
			}}
		>
			{children}
		</BookmarkApiContext.Provider>
	);
};

BookmarkApiProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

BookmarkApiProvider.defaultProps = {};
