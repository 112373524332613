// Globals
import React, {useEffect, useState, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';

// Project imports
import KiButton from 'components/KiButton';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import {closeContextSidebarPanel} from 'state/actions/App';
import {getColumnsFromService} from 'api/columnServiceApi';

// Local imports
import {BookmarkContext} from '../../fundingAnalysisBookmark';
import ColumnSelection from './ColumnSelection';
import styles from '../../fundingAnalysis.theme.scss';
import ViewSettingsParameters from './ViewSettingsParameters';

function ViewSettings() {
	// Browser State
	const {datasetId} = useParams();

	// Redux State
	const dispatch = useDispatch();

	// Context State
	const bookmarkContext = useContext(BookmarkContext);
	const bookmark = bookmarkContext.bookmark;
	const setBookmark = bookmarkContext.setBookmark;

	// Local State
	const [allColumns, setAllColumns] = useState([]);
	const fetchAllColumns = async () => {
		const params = {
			sources: {
				includeCohortColumns: true,
				includeFundingAnalysisColumns: true,
			},
			options: {
				returnSingleArray: false,
			},
		};
		const columnRes = await getColumnsFromService(datasetId, params);
		setAllColumns(columnRes.fundingAnalysisCols);
	};
	useEffect(() => {
		fetchAllColumns();
	}, []);

	const onApplySettings = async () => {
		bookmarkContext.applyBookmark();
	};

	return (
		<div className={styles.tabRoot}>
			<KiTabs>
				<KiTab title="Parameters">
					<ViewSettingsParameters bookmark={bookmark} setBookmark={setBookmark} />
				</KiTab>
				{['scenario'].includes(bookmark.settings.tableType) && (
					<KiTab title="Scenario">
						<ColumnSelection
							bookmark={bookmark}
							setBookmark={setBookmark}
							columnType="scenario"
							columns={allColumns.filter(col => col.columnType === 'scenarioAnalysis')}
						/>
					</KiTab>
				)}
				{['scenario'].includes(bookmark.settings.tableType) && (
					<KiTab title="Compliance">
						<ColumnSelection
							bookmark={bookmark}
							setBookmark={setBookmark}
							columnType="compliance"
							columns={allColumns.filter(col => col.columnType === 'complianceAnalysis')}
						/>
					</KiTab>
				)}
				{['scenario'].includes(bookmark.settings.tableType) && (
					<KiTab title="Breaches">
						<ColumnSelection
							bookmark={bookmark}
							setBookmark={setBookmark}
							columnType="breaches"
							columns={allColumns.filter(col => col.columnType === 'breachesExclusionsAnalysis')}
						/>
					</KiTab>
				)}
				{['summary'].includes(bookmark.settings.tableType) && (
					<KiTab title="Summary">
						<ColumnSelection
							bookmark={bookmark}
							setBookmark={setBookmark}
							columnType="summary"
							columns={allColumns.filter(col => col.columnType === 'summaryAnalysis')}
						/>
					</KiTab>
				)}
				{['summary'].includes(bookmark.settings.tableType) && (
					<KiTab title="Compliance">
						<ColumnSelection
							bookmark={bookmark}
							setBookmark={setBookmark}
							columnType="compliance"
							columns={allColumns.filter(col => col.columnType === 'complianceAnalysis')}
						/>
					</KiTab>
				)}
				{['summary'].includes(bookmark.settings.tableType) && (
					<KiTab title="Breaches">
						<ColumnSelection
							bookmark={bookmark}
							setBookmark={setBookmark}
							columnType="breaches"
							columns={allColumns.filter(col => col.columnType === 'breachesExclusionsAnalysis')}
						/>
					</KiTab>
				)}
				{['eligibility'].includes(bookmark.settings.tableType) && (
					<KiTab title="Eligibility">
						<ColumnSelection
							bookmark={bookmark}
							setBookmark={setBookmark}
							columnType="eligibility"
							columns={allColumns.filter(col => col.columnType === 'eligibilityAnalysis')}
						/>
					</KiTab>
				)}
			</KiTabs>
			<footer className={styles.editViewFooter}>
				<KiButton
					flat
					onClick={() => {
						dispatch(closeContextSidebarPanel());
					}}
				>
					Close
				</KiButton>
				<KiButton
					primary
					onClick={() => {
						onApplySettings();
						dispatch(closeContextSidebarPanel());
					}}
				>
					Apply
				</KiButton>
			</footer>
		</div>
	);
}

ViewSettings.propTypes = {};

ViewSettings.defaultProps = {};

export default ViewSettings;
