/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

// Global imports
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import cn from 'classnames';
import {omit} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

// Project imports
import {reportsApi} from 'api';
import KiIconButton from 'components/KiIconButton';
import KiProgressBar from 'components/KiProgressBar';
import {showSnackbar} from 'state/actions/Snackbar';

// Local imports
import ReportExecutionModal from './ReportExecutionModal';
import styles from './ReportsList.theme.scss';

export const ReportsList = ({
	reportDefinitions,
	isLoading,
	error,
	onDelete,
	isAdmin,
	noDataMsg,
	onChangeReportDefinition,
}) => {
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const history = useHistory();
	const [reportDefinitionIdToExecute, setReportDefinitionIdToExecute] = useState(null);

	const handleExecuteReportDefinition = reportDefinition => async () => {
		setReportDefinitionIdToExecute(reportDefinition._id);
	};

	const handleCopyReportDefinition = reportDefinition => async () => {
		try {
			// BLD-22087:  _id used in nodeapi to copy templates: deleted there
			//const newReportDefinition = omit(reportDefinition, ['_id']);
			const newReportDefinition = reportDefinition;
			newReportDefinition.createdBy = user.userId;
			newReportDefinition.updatedBy = user.userId;
			newReportDefinition.name = `Copy of ${reportDefinition.name}`;
			await reportsApi.createReportDefinition(newReportDefinition);
			await onChangeReportDefinition();
			dispatch(showSnackbar(`Report copied`));
		} catch (error) {
			dispatch(showSnackbar(`Error copying report`));
		}
	};

	const handleEditReportDefinition = reportDefinition => async () => {
		history.push(`/reports/definition/${reportDefinition._id}`);
	};

	const handleDeleteReportDefinition = reportDefinition => async () => {
		onDelete(reportDefinition._id);
	};

	const columns = [
		{
			accessor: 'name',
			Header: 'Report Name',
			id: 1,
			minWidth: 180,
			Cell: ({value}) => {
				return <span className={styles.cell}>{value}</span>;
			},
		},
		{
			Header: 'Dataset',
			accessor: 'datasetName',
			id: 2,
			minWidth: 180,
			Cell: ({value}) => {
				return <span className={styles.cell}>{value}</span>;
			},
		},
		{
			Header: 'Funding Vehicle',
			accessor: 'fundingVehicleName',
			id: 3,
			minWidth: 180,
			Cell: ({value}) => {
				return <span className={styles.cell}>{value}</span>;
			},
		},
		{
			Header: 'Output Type',
			accessor: 'outputTypeName',
			width: 180,
			id: 4,
			Cell: ({value}) => {
				return <span className={styles.cell}>{value}</span>;
			},
		},
		{
			accessor: 'lastModified',
			Header: 'Last Modified',
			width: 260,
			id: 5,
			isSorted: true,
			Cell: ({value}) => {
				return <span className={cn(styles.cell, styles.lastModified)}>{value}</span>;
			},
		},
		{
			Header: 'Actions',
			id: 6,
			width: 180,
			Cell: ({row}) => {
				return (
					<div className={styles.actions}>
						<KiIconButton
							icon="play_arrow"
							className={styles.button}
							iconClassName={styles.icon}
							onClick={handleExecuteReportDefinition(row.original)}
						/>
						<KiIconButton
							icon="content_copy"
							className={styles.button}
							iconClassName={styles.icon}
							onClick={handleCopyReportDefinition(row.original)}
						/>
						<KiIconButton
							icon="edit"
							className={styles.button}
							iconClassName={styles.icon}
							onClick={handleEditReportDefinition(row.original)}
						/>
						{isAdmin ? (
							<>
								<KiIconButton
									icon="delete"
									className={styles.button}
									iconClassName={styles.icon}
									onClick={handleDeleteReportDefinition(row.original)}
								/>
							</>
						) : null}
					</div>
				);
			},
		},
	];

	return (
		<div>
			<AbsTable
				columns={columns}
				data={reportDefinitions}
				isSortEnabled={true}
				isFilterEnabled={true}
				isLoading={isLoading}
				noDataMessage={noDataMsg}
				showNoDataMessage={!error && !isLoading && reportDefinitions?.length === 0}
			/>
			{error && (
				<div className="no-list-items-container">
					<div>An error occurred loading your list. {error.message}</div>
				</div>
			)}
			{reportDefinitionIdToExecute ? (
				<ReportExecutionModal
					reportDefinition={reportDefinitions.find(
						reportDefinition => reportDefinition._id === reportDefinitionIdToExecute
					)}
					onExecute={async () => {
						setReportDefinitionIdToExecute(null);
						await onChangeReportDefinition();
					}}
					onCancel={() => {
						setReportDefinitionIdToExecute(null);
					}}
				/>
			) : null}
		</div>
	);
};

ReportsList.propTypes = {
	reportDefinitions: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	onDelete: PropTypes.func.isRequired,
	isAdmin: PropTypes.bool,
	noDataMsg: PropTypes.string.isRequired,
	onChangeReportDefinition: PropTypes.func.isRequired,
};

ReportsList.defaultProps = {
	reports: [],
	isLoading: false,
	error: null,
	noDataMsg: '',
};

export default ReportsList;
