import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiInput from 'components/KiInput';
import KiSelect, {KiCreatable} from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';
import styles from './ViewSettings.theme.scss';
import {debtViewTypes, debtCohortCols} from 'ki-common/options/debt';
import _ from 'lodash';
import {fetchDates} from 'api/datasetDatesApi';

const SYSTEM_DATE_COLUMN_NAME_SET = new Set([
	'Collection Period End Date',
	'Report Date',
	'Funding Distribution Date',
	'Funding Determination Date',
]);

class ViewSettings extends Component {
	static propTypes = {
		viewName: PropTypes.string,
		settings: PropTypes.object,
		cohortColumn: PropTypes.string,
		tags: PropTypes.array,
		isDefault: PropTypes.bool,
		isFavorite: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		viewType: PropTypes.string,
		assetDateContext: PropTypes.string,
		debtDateContext: PropTypes.string,
	};

	static defaultProps = {
		viewName: '',
		cohortColumn: '',
		tags: [],
		isDefault: false,
		isFavorite: false,
		settings: {},
		assetDateContext: 'collectionEndDate',
		debtDateContext: 'reportDate',
	};

	state = {
		datasetDates: [],
	};

	componentDidMount() {
		this.setState({isLoadingDateOptions: true});
		return fetchDates(this.props.settings.datasetId, true, false, true)
			.then(datasetDates =>
				this.setState({
					datasetDates: _.sortBy(datasetDates, datasetDate => datasetDate.name.toLowerCase()),
				})
			)
			.finally(() => this.setState({isLoadingDateOptions: false}));
	}

	getViewTypeOptions = cohortCol => {
		const cohortColumn = cohortCol || this.props.cohortColumn;
		const options = debtViewTypes;
		switch (cohortColumn) {
			case 'fundingVehicle':
				return options.filter(({value}) => ['summary', 'activity', 'waterfall'].includes(value));
			case 'fee':
			case 'trigger':
			case 'creditSupport':
			case 'debt':
				return options.filter(({value}) => !['transaction', 'waterfall', 'role'].includes(value));
			case 'pool':
				return options.filter(({value}) => value === 'summary');
			case 'trust':
				return options.filter(({value}) => !['transaction', 'activity', 'waterfall', 'role'].includes(value));
			case 'counterparty':
			case 'counterpartyRole':
				return options.filter(({value}) => ['summary', 'role'].includes(value));
			default:
				return options;
		}
	};

	handleEntityChange = value => {
		if (!value) return;
		this.props.onChange('cohortColumn', value);
		const availableViewTypeOptions = this.getViewTypeOptions(value);
		if (!availableViewTypeOptions.map(x => x.value).includes(this.props.viewType)) {
			this.props.onChange('viewType', availableViewTypeOptions[0].value);
		}
	};

	objectifyTags = (tags = []) => tags.map(tag => ({label: tag, value: tag}));

	render() {
		return (
			<form name={'DebtViewSettingsForm'} className={styles.root}>
				<div className={styles.body}>
					<KiInput
						name={'viewName'}
						value={this.props.viewName}
						label={'View Name'}
						onChange={val => this.props.onChange('name', val)}
					/>
					<KiCreatable
						isMulti
						name={'tags'}
						options={this.objectifyTags(this.props.tags)}
						placeholder={'Add Tags...'}
						value={this.objectifyTags(this.props.tags)}
						onChange={(val = []) => this.props.onChange('tags', val.map(x => x.value))}
					/>
					<br />
					<div className={'select-wrapper'}>
						<span className={'theme-label'}>Entity</span>
						<KiSelect
							name={'cohortColumn'}
							options={debtCohortCols}
							value={debtCohortCols.filter(
								option =>
									option.value ===
									(this.props.cohortColumn === 'counterpartyRole'
										? 'counterparty'
										: this.props.cohortColumn)
							)}
							onChange={val => this.handleEntityChange(val.value)}
						/>
					</div>
					<br />
					<div className={'select-wrapper'}>
						<span className={'theme-label'}>View Type</span>
						<KiSelect
							classNamePrefix="aut-select"
							value={debtViewTypes.find(col => col.value === this.props.viewType)}
							isClearable={false}
							onChange={val => this.props.onChange('viewType', val.value)}
							options={this.getViewTypeOptions()}
						/>
					</div>
					<br />
					<div className={'select-wrapper'}>
						<span className={'theme-label'}>Asset Date Context</span>
						<KiSelect
							getOptionLabel={o =>
								`${o.name}${SYSTEM_DATE_COLUMN_NAME_SET.has(o.name) ? ' (System)' : ''}`
							}
							isLoading={this.state.isLoadingDateOptions}
							loadingMessage={() => 'Loading dates...'}
							placeholder={this.state.isLoadingDateOptions ? 'Loading Dates...' : 'Select a date'}
							getOptionValue={date => date.groupId}
							name={'assetDateContext'}
							options={this.state.datasetDates}
							value={this.state.datasetDates.filter(
								dateColumn => dateColumn.groupId === this.props.assetDateContext
							)}
							onChange={val => this.props.onChange('assetDateContext', val.groupId)}
						/>
					</div>
					<br />
					<div className={'select-wrapper'}>
						<span className={'theme-label'}>Debt Date Context</span>
						<KiSelect
							getOptionLabel={o =>
								`${o.name}${SYSTEM_DATE_COLUMN_NAME_SET.has(o.name) ? ' (System)' : ''}`
							}
							isLoading={this.state.isLoadingDateOptions}
							loadingMessage={() => 'Loading dates...'}
							placeholder={this.state.isLoadingDateOptions ? 'Loading Dates...' : 'Select a date'}
							getOptionValue={date => date.groupId}
							name={'debtDateContext'}
							options={this.state.datasetDates}
							value={this.state.datasetDates.filter(
								dateColumn => dateColumn.groupId === this.props.debtDateContext
							)}
							onChange={val => this.props.onChange('debtDateContext', val.groupId)}
						/>
					</div>
				</div>
				<footer className={styles.footer}>
					<KiCheckbox
						checked={this.props.isDefault}
						onChange={this.props.onChange.bind(this, 'isDefault')}
						label={'Use as Default for Dataset'}
						name={'isDefaultChkBox'}
					/>

					<KiCheckbox
						checked={this.props.isFavorite}
						onChange={this.props.onChange.bind(this, 'isFavorite')}
						label={'Favorite'}
						name={'isFavoriteChkBox'}
					/>
				</footer>
			</form>
		);
	}
}

export default ViewSettings;
