import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const getModels = fundingVehicleId => {
	return fetch(`${apiUrl}/models/${fundingVehicleId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getTrackDependencies = trackId => {
	return fetch(`${apiUrl}/models/${trackId}/dependencies`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const saveModel = model => {
	return fetch(`${apiUrl}/models`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(model),
	}).then(_handleResponse);
};

export const deleteModel = modelId => {
	return fetch(`${apiUrl}/models/${modelId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getTracks = datasetId => {
	return fetch(`${apiUrl}/tracks/${datasetId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const saveTrack = track => {
	return fetch(`${apiUrl}/tracks`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(track),
	}).then(_handleResponse);
};

export const deleteTrack = trackId => {
	return fetch(`${apiUrl}/tracks/${trackId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getAssumption = id => {
	return fetch(`${apiUrl}/assumptions/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getAssumptions = () => {
	return fetch(`${apiUrl}/assumptions`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const saveAssumption = assumption => {
	return fetch(`${apiUrl}/assumptions`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(assumption),
	}).then(_handleResponse);
};

export const deleteAssumption = assumptionId => {
	return fetch(`${apiUrl}/assumptions/${assumptionId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getPricingAssumption = id => {
	return fetch(`${apiUrl}/pricingAssumptions/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getPricingAssumptions = () => {
	return fetch(`${apiUrl}/pricingAssumptions`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const savePricingAssumption = assumption => {
	return fetch(`${apiUrl}/pricingAssumptions`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(assumption),
	}).then(_handleResponse);
};

export const deletePricingAssumption = assumptionId => {
	return fetch(`${apiUrl}/pricingAssumptions/${assumptionId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getVectors = type => {
	const url = `${apiUrl}/vectors${type ? `?type=${type}` : ''}`;
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getVector = id => {
	return fetch(`${apiUrl}/vectors/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const saveVector = vector => {
	return fetch(`${apiUrl}/vectors`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(vector),
	}).then(_handleResponse);
};

export const deleteVector = vectorId => {
	return fetch(`${apiUrl}/vectors/${vectorId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getReplines = () => {
	return fetch(`${apiUrl}/replinesMappings`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getRepline = id => {
	return fetch(`${apiUrl}/replinesMappings/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const saveRepline = model => {
	return fetch(`${apiUrl}/replinesMappings`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(model),
	}).then(_handleResponse);
};

export const deleteRepline = replineId => {
	return fetch(`${apiUrl}/replinesMappings/${replineId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getNotionalPools = () => {
	return fetch(`${apiUrl}/notionalPools`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getNotionalPool = id => {
	return fetch(`${apiUrl}/notionalPools/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const saveNotionalPool = model => {
	return fetch(`${apiUrl}/notionalPools`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(model),
	}).then(_handleResponse);
};

export const deleteNotionalPool = id => {
	return fetch(`${apiUrl}/notionalPools/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getProjectionScenarios = () => {
	return fetch(`${apiUrl}/projectionScenarios`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getLockedProjectionScenarios = datasetId => {
	return fetch(`${apiUrl}/projectionResults/locked/${datasetId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const saveProjectionScenario = scenario => {
	return fetch(`${apiUrl}/projectionScenarios/${scenario._id}`, {
		credentials: 'include',
		method: 'PUT',
		headers: _getHeaders('PUT'),
		body: JSON.stringify(scenario),
	}).then(_handleResponse);
};

export const deleteProjectionScenario = id => {
	return fetch(`${apiUrl}/projectionScenarios/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const createProjectionScenario = scenario => {
	return fetch(`${apiUrl}/projectionScenarios`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(scenario),
	}).then(_handleResponse);
};

export const getProjectionResultsForScenarioId = scenarioId => {
	return fetch(`${apiUrl}/projectionResults/scenario/${scenarioId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const lockProjectionResults = projectionId => {
	return fetch(`${apiUrl}/projectionResults/lock/${projectionId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const unlockProjectionResults = projectionId => {
	return fetch(`${apiUrl}/projectionResults/unlock/${projectionId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getLockedProjectionResultsForScenarioId = scenarioId => {
	return fetch(`${apiUrl}/projectionResults/scenario/locked/${scenarioId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getLatestLockedProjectionResults = (scenarioId, statementDate, datasetId) => {
	return fetch(
		`${apiUrl}/projectionResults/locked?scenarioId=${scenarioId}&statementDate=${statementDate}&datasetId=${datasetId}`,
		{
			credentials: 'include',
			method: 'GET',
			headers: _getHeaders('GET'),
		}
	).then(_handleResponse);
};

export const getProjectionResults = (id, format) => {
	const url = `${apiUrl}/projectionResults/${id + (format ? `/format=${format}` : '')}`;
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const deleteProjectionResults = id => {
	return fetch(`${apiUrl}/projectionResults/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

const getDependencies = (entity, id) => {
	const url = `${apiUrl}/${entity}/dependencies/${id}`;
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getProjectionReportingDeps = id => getDependencies('projectionScenarios', id);
export const getReplineDeps = id => getDependencies('replinesMappings', id);
export const getAssumptionDeps = id => getDependencies('assumptions', id);
export const getPricingAssumptionDeps = id => getDependencies('pricingAssumptions', id);
export const getVectorDeps = id => getDependencies('vectors', id);
export const getNotionalPoolDeps = id => getDependencies('notionalPools', id);

export const getEntitiesForDatasetId = datasetid => {
	return fetch(`${apiUrl}/projectionScenarios/entities/${datasetid}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getReplinesForDatasetId = datasetid => {
	return fetch(`${apiUrl}/replinesMappings//dataset/${datasetid}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const runWaterfall = (
	fundingVehicleId,
	snapshotDate,
	assetDateId,
	debtDateId,
	modelId,
	assumptionId,
	vectorId
) => {
	return fetch(`${apiUrl}/runWaterfall/${fundingVehicleId}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			snapshotDate: snapshotDate,
			assetDateId: assetDateId,
			debtDateId: debtDateId,
			modelId: modelId,
			assumption: assumptionId,
			vector: vectorId,
		}),
	}).then(_handleResponse);
};

export const runProjections = body => {
	return fetch(`${apiUrl}/runWaterfall/projections`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(body),
	}).then(_handleResponse);
};

export const getTriggers = () => {
	return fetch(`${apiUrl}/triggers`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getWaterfallResults = fundingVehicleId => {
	return fetch(`${apiUrl}/waterfallResults/fv/${fundingVehicleId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getPublishedWaterfallResults = (fundingVehicleId, reportDate) => {
	return fetch(`${apiUrl}/waterfallResults/published/${fundingVehicleId}/${reportDate}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const persistWaterfallResults = scenarioResultsId => {
	return fetch(`${apiUrl}/waterfallResults/publish/${scenarioResultsId}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({}),
	}).then(_handleResponse);
};

export const deleteWaterfallResult = waterfallResultEntityId => {
	return fetch(`${apiUrl}/waterfallResults/${waterfallResultEntityId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export default {
	getModels,
	getTrackDependencies,
	saveModel,
	deleteModel,
	getTracks,
	saveTrack,
	deleteTrack,
	getAssumption,
	getAssumptions,
	saveAssumption,
	deleteAssumption,
	getPricingAssumption,
	getPricingAssumptions,
	savePricingAssumption,
	deletePricingAssumption,
	getPricingAssumptionDeps,
	getVectors,
	getVector,
	saveVector,
	deleteVector,
	getReplines,
	getRepline,
	saveRepline,
	deleteRepline,
	getProjectionScenarios,
	saveProjectionScenario,
	deleteProjectionScenario,
	createProjectionScenario,
	getProjectionResultsForScenarioId,
	getProjectionResults,
	deleteProjectionResults,
	getEntitiesForDatasetId,
	runWaterfall,
	runProjections,
	getTriggers,
	getWaterfallResults,
	getPublishedWaterfallResults,
	persistWaterfallResults,
	deleteWaterfallResult,
	getReplineDeps,
	getAssumptionDeps,
	getVectorDeps,
};
