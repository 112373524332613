import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {datasetDatesApi, datasetsApi, fundingAnalysisApi} from 'api';
import FundingAnalysisContext from 'containers/fundingAnalysis/fundingAnalysisContext';
import {KiSelect, KiDatePicker, KiIconButton} from 'components';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {getLatestSnapshotDate} from 'ki-common/utils/snapshotDates';
import {showSnackbar} from 'state/actions/Snackbar';

import styles from './fundingScenarioForm.theme.scss';

export const recurrenceTypeOptions = [
	{
		label: 'No Recurrence',
		value: 'none',
	},
	{
		label: 'Recurring',
		value: 'recurring',
	},
	{
		label: 'Recurring with Systematic Approvals',
		value: 'autoRecurring',
	},
];

/**
 * Update the recurrence variable and reset the snapshot and transfer date
 * @param {('none'|'autoRecurring'|'recurring')} recurrenceType
 * @returns {{snapshotDate: null, isAutoApproved: boolean, isRecurring: boolean, transferDate: null}}
 */
export const generateRecurrenceSettings = recurrenceType => ({
	transferDate: null,
	snapshotDate: null,
	isRecurring: recurrenceType !== 'none',
	isAutoApproved: recurrenceType === 'autoRecurring',
});

export const getMinDate = (snapshotDate, datasetSnapshots = []) => {
	const latestSnapshot = getLatestSnapshotDate(datasetSnapshots);
	if (snapshotDate === latestSnapshot) {
		return moment(latestSnapshot, 'YYYY-MM-DD').format('YYYY-MM-DD');
	}
	return moment(latestSnapshot, 'YYYY-MM-DD')
		.add(1, 'days')
		.format('YYYY-MM-DD');
};

export const getDateExclusions = (snapshotDate, datasetSnapshots = [], fundingModel = {}, allScenarios = []) => {
	/* if model.includePrevAssets then exclude the transfer dates of other scenarios using this model*/
	const latestSnapshot = getLatestSnapshotDate(datasetSnapshots);
	const exclusions = [];
	if (fundingModel?.includePrevAssets) {
		exclusions.push(
			...allScenarios.filter(s => ['submitted', 'approved'].includes(s.status)).map(s => s.transferDate)
		);
	}
	if (snapshotDate !== latestSnapshot) exclusions.push(latestSnapshot);
	return exclusions;
};

export const DateControls = props => {
	const {scenario, setScenario, setShowSpinner, replaceScenario, fundingModel} = props;
	const [selectedRecurrence, setSelectedRecurrence] = useState('');
	const {datasetId, scenarioId} = useParams();
	const history = useHistory();
	const location = useLocation();
	const isModificationForm = location.pathname.includes('modify');

	// Redux State
	const dispatch = useDispatch();
	const isUserAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	let showSaveTransferDateButton = scenario._id && fundingAnalysisContext.status !== 'invalid';
	// Note - this only disabled the transfer date update button, a normal user can still update
	// the transfer date and click re-solve or solve as new
	if (['submitted', 'approved', 'closed'].includes(scenario.status) && !isUserAdmin) {
		showSaveTransferDateButton = false;
	}
	const baseScenario = fundingAnalysisContext.allScenarios.find(s => s._id === scenario._id);
	const isSaveTransferDateButtonDisabled = scenario.transferDate === _.get(baseScenario, 'transferDate');

	const [datasetSnapshots, setDatasetSnapshots] = useState([]);
	const [datasetPortfolioDates, setDatasetPortfolioDates] = useState([]);

	useEffect(
		() => {
			// Force fetch of scenarios to use for date logic
			fundingAnalysisContext.fetchScenarios(datasetId);
		},
		[datasetId]
	);

	useEffect(
		() => {
			const fetchSnapshots = async () => {
				const result = await datasetsApi.getSnapshotsByDatasetId(datasetId);
				setDatasetSnapshots(result);
			};
			const fetchDatasetPortfolioDates = async () => {
				const portfolioDates = await datasetDatesApi.fetchPortfolioDates(datasetId);
				setDatasetPortfolioDates(portfolioDates.map(date => _.pick(date, ['groupId', 'name'])));
			};
			fetchSnapshots(datasetId);
			fetchDatasetPortfolioDates(datasetId, scenarioId);
		},
		[datasetId, scenarioId]
	);

	// Handle the logic around recurrence being two booleans and not a dropdown
	useEffect(
		() => {
			const recurrenceTypeMap = new Map(recurrenceTypeOptions.map(option => [option.value, option]));
			let selection = recurrenceTypeMap.get('none');
			if (scenario.isRecurring && scenario.isAutoApproved) {
				selection = recurrenceTypeMap.get('autoRecurring');
			} else if (scenario.isRecurring) {
				selection = recurrenceTypeMap.get('recurring');
			}
			setSelectedRecurrence(selection);
		},
		[scenario.isRecurring, scenario.isAutoApproved]
	);

	return (
		<React.Fragment>
			<div className={styles.recurringDate}>
				<span className="theme-label">Recurrence</span>
				<KiSelect
					value={selectedRecurrence}
					isDisabled={scenario._id}
					isClearable={false}
					options={recurrenceTypeOptions}
					onChange={selected => setScenario(generateRecurrenceSettings(selected?.value))}
				/>
			</div>

			{scenario.isRecurring && (
				<div className={styles.dateDropdown}>
					<span className="theme-label">Snapshot/Transfer Date</span>
					<KiSelect
						value={datasetPortfolioDates.filter(aDate => aDate.groupId === scenario.snapshotDate)}
						isClearable={false}
						options={datasetPortfolioDates}
						onChange={newVal =>
							setScenario({
								snapshotDate: newVal.groupId,
								transferDate: newVal.groupId,
							})
						}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.groupId}
					/>
				</div>
			)}
			{!scenario.isRecurring && (
				<React.Fragment>
					<div className={styles.datepicker}>
						<span className="theme-label">Snapshot Date</span>
						<KiDatePicker
							includeDates={datasetSnapshots.map(d => d.snapshotDate)}
							disabled={isModificationForm}
							onChange={val => {
								const shortDate = dateToShortDate(val);
								setScenario({
									snapshotDate: shortDate,
									transferDate: getMinDate(shortDate || scenario.snapshotDate, datasetSnapshots),
								});
							}}
							value={scenario.snapshotDate}
						/>
					</div>
					<div className={styles.datepicker}>
						<span className="theme-label">Transfer Date</span>
						<div className={styles.transferDatePicker}>
							<KiDatePicker
								minDate={getMinDate(scenario.snapshotDate, datasetSnapshots)}
								excludeDates={getDateExclusions(
									scenario.snapshotDate,
									datasetSnapshots,
									fundingModel,
									fundingAnalysisContext.allScenarios
								)}
								onChange={val => {
									if (!val) {
										const latestSnapshot = getLatestSnapshotDate(datasetSnapshots);
										setScenario({transferDate: latestSnapshot});
									} else {
										setScenario({transferDate: dateToShortDate(val)});
									}
								}}
								value={scenario.transferDate}
							/>
							{showSaveTransferDateButton && (
								<KiIconButton
									icon="save"
									disabled={isSaveTransferDateButtonDisabled}
									className={`${styles.saveViewIcon} list-icon-btn`}
									title="Save transfer date"
									onClick={async () => {
										try {
											setShowSpinner(true);
											await fundingAnalysisApi.updateScenarioTransferDate(
												scenarioId,
												scenario.transferDate
											);
											setShowSpinner(false);
											dispatch(showSnackbar(`Transfer Date successfully updated.`));
											const scenarioData = await fundingAnalysisApi.fetchScenario(scenarioId);
											fundingAnalysisContext.setScenario(scenarioData);
											replaceScenario(scenarioData);
											history.push(`/fundingAnalysis/${datasetId}/scenarios`);
										} catch (err) {
											setShowSpinner(false);
											fundingAnalysisContext.addError(
												`Scenario ${scenario.name}: ${err.message}`
											);
										}
									}}
								/>
							)}
						</div>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};
DateControls.propTypes = {
	scenario: PropTypes.object,
	setScenario: PropTypes.any.isRequired,
	setShowSpinner: PropTypes.any.isRequired,
	replaceScenario: PropTypes.func.isRequired,
	fundingModel: PropTypes.object,
};
DateControls.defaultProps = {
	scenario: {},
};
