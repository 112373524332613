import React from 'react';
import PropTypes from 'prop-types';
import KiCheckbox from '../../../../components/KiCheckbox';
import LedgerGeneratePostsTableItem from './LedgerGeneratePostsTableItem';
import {isAnyListItemVisible} from '../../ledgerUtils';

function LedgerGeneratePostsTable({data, toggleActive, toggleAll, areAllChecked, loading}) {
	return (
		<div className="scenario-list-box">
			<table className="scenario-list-table scenario-list-table-ledger">
				<thead>
					<tr className="scenario-list-item">
						<th className="select-item" />
						<th colSpan={4} className="border-right" />
						<th colSpan={4} className="align-center">
							Last Generation
						</th>
					</tr>
					<tr className="scenario-list-item">
						<th className="select-item">
							<KiCheckbox
								className="select-item-checkbox no-margin"
								checked={areAllChecked}
								onChange={toggleAll}
								title="Select list"
							/>
						</th>
						<th title="Post Code">Post Code</th>
						<th title="Post Description">Post Description</th>
						<th title="Effective Date Calendar">Effective Date Calendar</th>
						<th title="Calculated Effective Date" className="border-right">
							Calculated Effective Date
						</th>
						<th title="Generation Time">Generation Time</th>
						<th title="Posting Date">Posting Date</th>
						<th title="Effective Date">Effective Date</th>
						<th title="Status">Status</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 && isAnyListItemVisible(data) ? (
						data.map((item, index) => {
							return (
								item.visible && (
									<LedgerGeneratePostsTableItem
										key={index}
										item={item}
										index={index}
										toggleActive={toggleActive}
										loading={loading}
									/>
								)
							);
						})
					) : (
						<tr>
							<td>
								<div className="no-list-items-container">
									<div>
										{data.length > 0 ? 'No matching templates found.' : 'No active templates'}
									</div>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

LedgerGeneratePostsTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	toggleActive: PropTypes.func,
	toggleAll: PropTypes.func,
	areAllChecked: PropTypes.bool,
	loading: PropTypes.bool,
};

LedgerGeneratePostsTable.defaultProps = {
	data: [],
	loading: true,
};

export default LedgerGeneratePostsTable;
