// Global Imports
import PropTypes from 'prop-types';
import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';

// Local Imports
import {datasetsApi} from 'api';
import KiFontIcon from 'components/KiFontIcon';
import KiCheckbox from 'components/KiCheckbox';
import KiButton from 'components/KiButton';
import KiConfirmModal from 'components/KiConfirmModal';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchDatasetList, setSelectedDataset} from '../../actions';
import './SnapshotBlock.scss';

const SnapshotCheckboxList = props => {
	const [snapshots, setSnapshots] = useState([]);

	useEffect(
		() => {
			setSnapshots(_sortBy(props.snapshots.slice(), 'snapshotDate').reverse());
		},
		[props.snapshots]
	);

	return snapshots.map((snapshotObj, index) => {
		const isLatestButNotOnlySnapshot = !index && props.snapshots.length > 1;
		const isOnlyUnselectedSnapshot =
			props.selectedSnapshots.length === props.snapshots.length - 1 &&
			!props.selectedSnapshots.includes(snapshotObj.snapshotDate);
		const isDisabled = isLatestButNotOnlySnapshot && !(props.isSelectAllChecked || isOnlyUnselectedSnapshot);
		return (
			<div key={snapshotObj.snapshotDate} className="snapshot-entry">
				<div className="snapshot-checkbox">
					<KiCheckbox
						id={`Snapshot-Chkbox-${snapshotObj.snapshotDate}`}
						disabled={isDisabled}
						checked={props.selectedSnapshots.includes(snapshotObj.snapshotDate)}
						onChange={() => props.handleSnapshotChecked(snapshotObj.snapshotDate)}
					/>
				</div>
				<label
					htmlFor={`Snapshot-Chkbox-${snapshotObj.snapshotDate}`}
					className={isDisabled ? 'current-snapshot-text' : 'snapshot-text'}
				>
					{snapshotObj.snapshotDate} {isDisabled && `(Current, Not Deletable)`}
				</label>
			</div>
		);
	});
};

SnapshotCheckboxList.propTypes = {
	selectedSnapshots: PropTypes.array,
	snapshots: PropTypes.array,
	handleSnapshotChecked: PropTypes.func.isRequired,
	isSelectAllChecked: PropTypes.bool.isRequired,
};

SnapshotCheckboxList.defaultProps = {
	selectedSnapshots: [],
	snapshots: [],
	isSelectAllChecked: false,
};

export const ConfirmSnapshotMessage = props => {
	const {snapshotsToDelete, datasetSnapshots, name} = props;
	const multi = snapshotsToDelete.length > 1;
	let willDeleteDataset = false;
	if (datasetSnapshots) {
		willDeleteDataset = datasetSnapshots.length === snapshotsToDelete.length;
	}
	const msg = `Deleting ${multi ? 'these snapshots' : 'this snapshot'} will invalidate all Hypos run for ${
		multi ? 'these snapshot dates' : 'this snapshot date'
	}.`;
	const datasetMsg = `The dataset "${name}" will be deleted.`;
	return (
		<div>
			<div>{msg}</div>
			{willDeleteDataset && (
				<div>
					<br />
					{datasetMsg}
				</div>
			)}
			<ul>{snapshotsToDelete.map((snapshot, idx) => <li key={idx}>{snapshot}</li>)}</ul>
			<div>Are you sure you want to continue?</div>
		</div>
	);
};

ConfirmSnapshotMessage.propTypes = {
	snapshotsToDelete: PropTypes.array,
	datasetSnapshots: PropTypes.array,
	name: PropTypes.string,
};

ConfirmSnapshotMessage.defaultprops = {
	snapshotsToDelete: [],
	datasetSnapshots: [],
	name: '',
};

class SnapshotBlock extends Component {
	//eslint-disable-line react/prefer-stateless-function
	static propTypes = {
		datasetId: PropTypes.string,
		snapshots: PropTypes.array,
		name: PropTypes.string,
		isVisible: PropTypes.bool,
		fetchDatasetList: PropTypes.func,
		handleEditSnapshotsClick: PropTypes.func,
		showSnackbar: PropTypes.func,
	};

	state = {
		selectedSnapshots: [],
		deleteSnapshotsList: [],
		snapshotDeleteRejectedModalVisible: false,
		isDeleteSnapshotsConfirmActive: false,
		selectAll: false,
	};

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps.snapshots, this.props.snapshots)) {
			this.setState({selectedSnapshots: []});
		}
	}

	handleSnapshotChecked = snapshot => {
		const resultSet = new Set(this.state.selectedSnapshots);
		if (!resultSet.has(snapshot)) {
			resultSet.add(snapshot);
		} else {
			const snapshotDatesSorted = this.props.snapshots
				.map(x => x.snapshotDate)
				.sort()
				.reverse();
			if (snapshotDatesSorted.length > 1) {
				// if this dataset has multiple snapshots, remove the latest snapshot from the list of selected snapshots because it cannot be selected for deletion unless all snapshots are
				resultSet.delete(snapshotDatesSorted[0]);
			}
			resultSet.delete(snapshot);
		}
		this.setState({
			selectedSnapshots: Array.from(resultSet),
			// if the length of the filtered list is the same as the length of the snapshots list, then all are selected
			selectAll: resultSet.size === this.props.snapshots.length,
		});
	};

	toggleSelectAll = () => {
		this.setState({selectAll: !this.state.selectAll}, () => {
			if (this.state.selectAll) {
				this.setState({
					selectedSnapshots: this.props.snapshots
						.map(s => s.snapshotDate)
						.sort()
						.reverse(),
				});
			} else {
				this.setState({selectedSnapshots: []});
			}
		});
	};

	handleDeleteClick = () => {
		this.setState({
			isDeleteSnapshotsConfirmActive: true,
			deleteSnapshotsList: this.state.selectedSnapshots,
		});
	};

	handleDeleteSnapshots = () => {
		this.setState({pendingDelete: true}, () => {
			const dataset = {
				snapshots: this.props.snapshots,
				datasetId: this.props.datasetId,
				name: this.props.name,
			};
			if (this.state.deleteSnapshotsList.length === this.props.snapshots.length) {
				return this.handleDatasetDelete(dataset);
			}
			return datasetsApi
				.deleteDatasetSnapshots(dataset, this.state.deleteSnapshotsList)
				.then(res => {
					this.setState(
						{
							pendingDelete: false,
							isDeleteSnapshotsConfirmActive: false,
							deleteSnapshotsList: [],
						},
						() => {
							this.props.fetchDatasetList(true);
							this.props.showSnackbar(res.message);
						}
					);
				})
				.catch(() => {
					this.setState({pendingDelete: false});
				});
		});
	};

	handleDatasetDelete = dataset => {
		return datasetsApi
			.deleteDatasets([
				{
					id: dataset.datasetId,
					name: dataset.name,
				},
			])
			.then(res => {
				this.setState({
					pendingDelete: false,
					isDeleteSnapshotsConfirmActive: false,
					deleteSnapshotsList: [],
				});
				this.props.fetchDatasetList(true);
				this.props.showSnackbar(res.message);
			})
			.catch(() => {
				this.setState({pendingDelete: false});
			});
	};

	hideDeleteSnapshotsConfirm = () => {
		this.setState({
			isDeleteSnapshotsConfirmActive: false,
			deleteSnapshotsList: [],
		});
	};

	render() {
		if (!this.props.isVisible) {
			return null;
		}

		const {datasetId} = this.props;

		return (
			<div className="dataset-list-snapshot-block">
				<section className="block-header">
					<p>Snapshot Dates</p>
					<KiFontIcon
						value="close"
						className="close-icon"
						onClick={e => this.props.handleEditSnapshotsClick(e, datasetId)}
					/>
				</section>
				<section className="dataset-snapshot-list-container">
					<div key="all" className="snapshot-entry">
						<div className="snapshot-checkbox">
							<KiCheckbox
								label="Select All"
								checked={this.state.selectAll}
								onChange={() => this.toggleSelectAll()}
							/>
						</div>
					</div>
					<SnapshotCheckboxList
						snapshots={this.props.snapshots}
						selectedSnapshots={this.state.selectedSnapshots}
						handleSnapshotChecked={this.handleSnapshotChecked}
						isSelectAllChecked={this.state.selectAll}
					/>
				</section>
				<KiButton
					className="delete-snapshot-button"
					label="Delete"
					raised
					primary
					onClick={this.handleDeleteClick}
					disabled={!this.state.selectedSnapshots.length}
				/>
				<KiConfirmModal
					acceptLabel="Close"
					acceptFunc={() => this.setState({snapshotDeleteRejectedModalVisible: false})}
					active={this.state.snapshotDeleteRejectedModalVisible}
					header="Cannot Delete Snapshot"
					message="The selected snapshot is associated with a Pending scenario and cannot be deleted until it is transferred or rejected."
				/>
				<KiConfirmModal
					header={`Delete Dataset Snapshot${this.state.deleteSnapshotsList.length > 1 ? 's' : ''}`}
					message={
						<ConfirmSnapshotMessage
							snapshotsToDelete={this.state.deleteSnapshotsList}
							datasetSnapshots={this.props.snapshots}
							name={this.props.name}
						/>
					}
					acceptFunc={this.handleDeleteSnapshots}
					rejectFunc={this.hideDeleteSnapshotsConfirm}
					acceptLabel={this.state.pendingDelete ? 'Deleting...' : 'Delete'}
					rejectLabel="Cancel"
					acceptDisabled={this.state.pendingDelete}
					active={this.state.isDeleteSnapshotsConfirmActive}
				/>
			</div>
		);
	}
}

const mapDispatchToProps = {
	fetchDatasetList,
	setSelectedDataset,
	showSnackbar,
};

export default connect(
	null,
	mapDispatchToProps
)(SnapshotBlock);
