import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiButton from 'components/KiButton';
import {KiInput} from 'components/KiInput';
import {login} from './actions';
const logo = require('../../static/images/Ki-logo-white_40.png');
const noticesURL = require('../../static/pdfs/notices.pdf');
import {Redirect} from 'react-router-dom';

class Login extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		login: PropTypes.func.isRequired,
		location: PropTypes.object,
	};

	state = {
		username: '',
		password: '',
		loginStep: 1,
		redirectToReferrer: false,
		loginError: null,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Login`;
	}

	getError = () => {
		if (!this.state.loginError) {
			return null;
		}
		return (
			<section className="login-error-panel">
				<i className="material-icons">error</i>
				<p>{this.state.loginError}</p>
			</section>
		);
	};

	handleSubmitLogin = e => {
		e.preventDefault();
		const {login} = this.props;
		if (!this.state.password.length && !this.state.username.length) {
			this.setState({loginError: 'Please enter Username and Password.'});
			return;
		}
		if (!this.state.username.length) {
			this.setState({loginError: 'Please enter Username.'});
			return;
		}
		if (!this.state.password.length) {
			this.setState({loginError: 'Please enter Password.'});
			return;
		}
		login(this.state.username, this.state.password).then(result => {
			if (result.status === 'success') {
				this.setState({redirectToReferrer: true});
			} else {
				this.setState({loginError: result.message});
			}
		});
	};

	render() {
		const {from} = this.props.location.state || {from: '/fundingAnalysis'};
		const {redirectToReferrer} = this.state;

		if (redirectToReferrer) {
			return <Redirect to={from} />;
		}
		return (
			<React.Fragment>
				<div
					style={{
						display: 'flex',
						flexFlow: 'column',
						alignItems: 'center',
						height: '100vh',
						justifyContent: 'center',
					}}
				>
					<section className="login-panel" data-mf-replace="**REMOVED**">
						<h2>{'Welcome to'}</h2>
						<img className="logo" src={logo} />
						<h2>{'Please login'}</h2>
						<form onSubmit={this.handleSubmitLogin} method="post">
							<KiInput
								className="login-input"
								autoFocus
								name="username"
								maxLength={50}
								type="text"
								value={this.state.username}
								placeholder="User ID / Email"
								onChange={value => this.setState({username: value})}
							/>
							<KiInput
								className="login-input"
								name="password"
								type="password"
								maxLength={50}
								value={this.state.password}
								placeholder="Password"
								onChange={value => this.setState({password: value})}
							/>
							<KiButton className="btn" raised type="submit">
								Login
							</KiButton>
						</form>
						{window.ABS_SSO_ENABLED === 'true' && (
							<form action="web/login/oidc">
								<KiButton className="btn" raised type="submit">
									Login via ABS Suite Plus
								</KiButton>
							</form>
						)}
						<div className="third-party-link">
							<a href={noticesURL} target="_blank" rel="noopener noreferrer">
								Third Party Notices
							</a>
							<br />
							<a
								href="https://www.moodys.com/privatepolicy.aspx?lang=en&cy=global"
								target="_blank"
								rel="noopener noreferrer"
							>
								Privacy Policy
							</a>
						</div>
						{this.getError()}
					</section>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
});

export default connect(mapStateToProps, {login})(Login);
