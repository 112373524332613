import React from 'react';
import PropTypes from 'prop-types';
import {KiSelect} from 'components/KiSelect';
import KiModal2 from 'components/KiModal2';
import KiButton from 'components/KiButton';
import KiDatePicker from 'components/KiDatePicker';
import KiInput from 'components/KiInput';
import Select from 'react-select';
import {booleanLogicNumeric} from 'ki-common/options';
import _cloneDeep from 'lodash/cloneDeep';
import './ModalStyles.scss';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {format} from 'ki-common/utils/displayFormatter';

class TriggersModalNumeric extends React.Component {
	static propTypes = {
		fundingVehicles: PropTypes.array,
		columns: PropTypes.array,
		toggleVisibility: PropTypes.func,
		columnToEdit: PropTypes.object,
		active: PropTypes.bool,
		handleConfirm: PropTypes.func,
		usedFundingVehicles: PropTypes.array,
		selectedFormula: PropTypes.object,
	};

	static defaultProps = {
		fundingVehicle: [],
		calculation: null,
		logic: null,
		columns: [],
		usedFundingVehicles: [],
	};

	state = {
		selectedFundingVehicle: null,
		logic: {
			value: '<',
			label: '<',
		},
		booleanColumns: [],
		calculation: null,
		targets: [],
		newTargetDate: null,
		newTargetValue: '',
		isEditMode: this.props.selectedFormula && this.props.selectedFormula.fundingVehicle,
		oneTargetExistsMode: null,
	};

	componentDidMount() {
		const booleanColumns = this.getValidColumnsByDataType(this.props.columns, ['boolean', 'numeric']);
		// need to check on selectedFormula here
		this.setState({
			selectedFundingVehicle: this.props.fundingVehicles.find(
				fv => fv._id === this.props.selectedFormula.fundingVehicle
			),
			booleanColumns,
			targets: this.props.selectedFormula.threshold || [],
			calculation: booleanColumns.find(c => c._id === this.props.selectedFormula.calculation),
			logic: booleanLogicNumeric.find(o => o.value === this.props.selectedFormula.operator),
		});
	}

	// NOTE: at some point a user may be able to select an operand other than boolean and need access
	// to columns with some other data type
	getValidColumnsByDataType = (columns, dataTypes) => {
		const validCols = columns.filter(
			col =>
				['aggregate', 'debtInputFV', 'debtCalculation', 'fvSetup', 'borrowingBase', 'waterfall'].includes(
					col.columnType
				) &&
				dataTypes.includes(col.dataType) &&
				(this.props.columnToEdit?._id || null) !== col._id
		);
		return validCols;
	};

	disableSave = () => {
		const {selectedFundingVehicle, calculation, logic, targets} = this.state;
		return !(selectedFundingVehicle && calculation && logic && targets.length);
	};

	handleConfirm = () => {
		const output = {
			fundingVehicle: this.state.selectedFundingVehicle._id,
			calculation: this.state.calculation._id,
			operator: this.state.logic.value,
			threshold: this.state.targets,
		};
		this.props.handleConfirm(output);
		this.props.toggleVisibility();
	};

	getUnusedFVOptions = () => {
		return this.props.fundingVehicles.filter(fv => {
			return !this.props.usedFundingVehicles.includes(fv._id);
		});
	};

	addTargetValues = () => {
		const newTarget = {
			date: dateToShortDate(this.state.newTargetDate),
			value: this.state.newTargetValue,
		};
		const newTargets = [...this.state.targets];
		newTargets.push(newTarget);
		this.setState({
			targets: newTargets,
			newTargetDate: null,
			newTargetValue: '',
		});

		if (newTargets.length >= 0) {
			this.setState({
				oneTargetExistsMode: this.state.calculation && this.state.logic,
			});
		}
	};

	deleteTarget = index => {
		const targets = _cloneDeep(this.state.targets);
		targets.splice(index, 1);
		this.setState({
			targets: targets,
		});

		if (targets.length === 0) {
			this.setState({
				oneTargetExistsMode: !(this.state.calculation && this.state.logic),
			});
		}
	};

	render() {
		const {targets} = this.state;
		return (
			<KiModal2
				active={this.props.active}
				actions={[
					{label: 'Cancel', onClick: () => this.props.toggleVisibility()},
					{label: 'Apply', onClick: this.handleConfirm, disabled: this.disableSave()},
				]}
				panelStyles={{minHeight: '60rem', minWidth: '90rem'}}
				className="numeric-debt-formula-modal"
				header={`Create/Edit a Numeric Trigger Formula`}
			>
				<p>Funding Vehicle</p>
				<KiSelect
					value={this.state.selectedFundingVehicle}
					options={this.getUnusedFVOptions()}
					getOptionLabel={opt => opt.name}
					getOptionValue={opt => opt._id}
					onChange={val => this.setState({selectedFundingVehicle: val})}
				/>
				<div className="form-row">
					<div className="flex-6" style={{marginRight: '1rem'}}>
						<span className="form-instruction">Calculation:</span>
						<Select
							classNamePrefix="aut-select"
							value={this.state.calculation}
							options={this.state.booleanColumns}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							onChange={val => this.setState({calculation: val})}
							isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
						/>
					</div>
					<div className="flex-1">
						<span className="form-instruction">Logic:</span>
						<Select
							classNamePrefix="aut-select"
							className="standard-pad-right"
							value={this.state.logic}
							isClearable={false}
							options={booleanLogicNumeric}
							onChange={selected => this.setState({logic: selected})}
							isDisabled={!!this.state.isEditMode || this.state.oneTargetExistsMode}
						/>
					</div>
				</div>

				<div className="form-row">
					<div style={{marginRight: '2rem'}}>
						<KiDatePicker
							label="Target Date"
							onChange={val => this.setState({newTargetDate: val})}
							value={this.state.newTargetDate}
						/>
					</div>
					<div style={{marginRight: '2rem'}}>
						<KiInput
							label="Target Value"
							value={this.state.newTargetValue}
							onChange={val => this.setState({newTargetValue: val})}
							isNumberMasked={true}
						/>
					</div>
					<div>
						<KiButton
							className="add-btn trigger-add-btn"
							onClick={this.addTargetValues}
							disabled={!this.state.newTargetDate || !this.state.newTargetValue}
							icon="add"
						/>
					</div>
				</div>
				<div>
					<div>Existing Targets</div>
					<hr />
					{targets.map((target, index) => (
						<div className="list-row" key={index}>
							<span>{`${target.date}  :  ${format(Number(target.value), '9,999.99')}`}</span>
							<i
								className="material-icons"
								onClick={() => this.deleteTarget(index)}
								style={{cursor: 'pointer'}}
							>
								delete
							</i>
						</div>
					))}
				</div>
			</KiModal2>
		);
	}
}

export default TriggersModalNumeric;
