import React from 'react';
import PropTypes from 'prop-types';
import styles from './SecondSidebar.theme.scss';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

const SecondSidebar = ({isVisible, title, children, onClose}) => {
	return (
		<TransitionGroup>
			{isVisible && (
				<CSSTransition key={1} classNames="second-sidebar-animation" timeout={{enter: 500, exit: 500}}>
					<section className={styles.container}>
						<section className="context-sidebar-content">
							<div className="context-sidebar-panel">
								<div className="context-sidebar-panel-header">
									<p>{title}</p>
									<i className="material-icons" onClick={onClose}>
										clear
									</i>
								</div>
								<div className="ki-flyout-panel">{children}</div>
							</div>
						</section>
					</section>
				</CSSTransition>
			)}
		</TransitionGroup>
	);
};

SecondSidebar.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export default SecondSidebar;
