import PropTypes from 'prop-types';
import React, {Component} from 'react';
import options from 'ki-common/options';
import classNames from 'classnames';

import ColumnPicker from 'components/ColumnPicker';

import {datasetsApi} from 'api';

export class ColumnForms extends Component {
	static propTypes = {
		columnType: PropTypes.string.isRequired,
		isActive: PropTypes.bool.isRequired,
		datasetId: PropTypes.string.isRequired,
		fetchDataset: PropTypes.func.isRequired,
		columnToEdit: PropTypes.object,
		closeForm: PropTypes.func.isRequired,
		setColumnToEdit: PropTypes.func.isRequired,
		user: PropTypes.object,
		reloadView: PropTypes.func,
		showSnackbar: PropTypes.func,
		formParams: PropTypes.object,
		currentView: PropTypes.object,
		columns: PropTypes.array,
	};

	static defaultProps = {
		columns: [],
		columnType: 'asset',
		isActive: false,
		columnToEdit: null,
		formParams: {},
	};

	state = {
		calculationType: '',
	};

	setCalculationType = val => {
		this.setState({calculationType: val});
	};

	refreshColumns = () => {
		return this.props.fetchDataset(this.props.datasetId);
	};

	saveColumn = columnJson => {
		return datasetsApi
			.upsertColumns(columnJson, this.props.datasetId)
			.then(() => this.props.showSnackbar('Column successfully saved.'))
			.then(() => this.refreshColumns())
			.then(() => this.props.closeForm())
			.catch(() => {
				this.props.showSnackbar('Error saving column.');
			});
	};

	/**
	 * @param {string} columnType
	 * @param {boolean} [isWaterfall=false]
	 * @returns {string} a string of 'a(n) Asset/Waterfall/Debt/Summary'
	 */
	getCalculationType = (columnType, isWaterfall = false) => {
		if (columnType === 'asset') {
			return 'an Asset';
		}
		if (columnType === 'debtCalculation') {
			if (isWaterfall) {
				return 'a Waterfall';
			}
			return 'a Debt';
		}
		return 'a Summary';
	};

	/**
	 * @param {string} columnType
	 * @param {boolean} [isWaterfall=false]
	 * @returns {{label: string, value: string}[]}
	 */
	getColumnTypeOptions = (columnType, isWaterfall = false) => {
		switch (columnType) {
			case 'asset':
				return options.calculatedAssetColumnTypes.filter(c => c.value !== 'numericVariable');
			case 'debtCalculation':
				return options.calculatedAssetColumnTypes.filter(({value}) =>
					['numeric', 'conditional', isWaterfall ? 'numericVariable' : 'date'].includes(value)
				);
			default:
				return options.calculatedSummaryColumnTypes;
		}
	};

	render() {
		const {columnType, isActive, closeForm, user, formParams} = this.props;
		if (!isActive) {
			return null;
		}
		return (
			<div className="calculation-forms">
				<section
					className={classNames({
						'flyout-panel-section': true,
						active: isActive,
					})}
				>
					{!this.props.columnToEdit && (
						<p>{`Choose ${this.getCalculationType(
							columnType,
							formParams.isWaterfall
						)} Calculation Type`}</p>
					)}

					<ColumnPicker
						className="add-column-selector inline"
						options={this.getColumnTypeOptions(columnType, formParams.isWaterfall)}
						columnType={columnType}
						returnNewRows={this.saveColumn}
						columnToEdit={this.props.columnToEdit}
						setColumnToEdit={this.props.setColumnToEdit}
						closeForm={closeForm}
						mode="inline"
						user={user}
						reloadView={this.props.reloadView}
						formParams={formParams}
						currentView={this.props.currentView}
						columns={this.props.columns}
					/>
				</section>
			</div>
		);
	}
}

export default ColumnForms;
