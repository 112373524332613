const submissions = require('./submissions');
const audit = require('./audit');
const debt = require('./debt');
const systemColumns = require('./systemColumns');

const constraintRowTypes = [
	{
		label: 'Concentration',
		value: 'ccConcentration',
	},
	{
		label: 'Exclusion',
		value: 'ccExclude',
	},
	{
		label: 'Weighted Avg',
		value: 'ccWeightedAvg',
	},
	{
		label: 'Balance Limit',
		value: 'ccBalanceLimit',
	},
	{
		label: 'Concentration Limit',
		value: 'ccConcentrationLimit',
	},
];

const recurrenceTypeOptions = [
	{
		value: 'none',
		label: 'None',
	},
	{
		value: 'daily',
		label: 'Daily',
	},
	{
		value: 'daily_weekdays',
		label: 'Daily Weekdays',
	},
	{
		value: 'daily_weekend',
		label: 'Daily Weekend',
	},
	{
		value: 'weekly',
		label: 'Weekly',
	},
	{
		value: 'monthly',
		label: 'Monthly',
	},
	{
		value: 'annual',
		label: 'Annual',
	},
];

const fundingVehicleStatusOptions = [
	{
		key: 'notice',
		display: 'Notice',
		label: 'Noticed Funding Vehicles',
	},
	{
		key: 'active',
		display: 'Active',
		label: 'Active But Not Noticed Funding Vehicles',
	},
	{
		key: 'inactive',
		display: 'Inactive',
		label: 'Inactive Funding Vehicles',
	},
];

const poolMaintenanceStatusOptions = [
	{
		key: 'encumbered',
		display: 'Encumbered',
		label: 'Encumbered',
	},
	{
		key: 'unencumbered',
		display: 'Unencumbered',
		label: 'Unencumbered',
	},
	{
		key: 'inactive',
		display: 'Inactive',
		label: 'Inactive',
	},
	{
		key: 'unassigned',
		display: 'Unassigned',
		label: 'Unassigned',
	},
];

const assetColumnTypes = [
	{
		value: 'algebraic',
		label: 'Algebraic',
		type: 'asset',
	},
	{
		value: 'boolean',
		label: 'Boolean',
		type: 'asset',
	},
	{
		value: 'conditional',
		label: 'Conditional',
		type: 'asset',
	},
];

const summaryColumnTypes = [
	{
		value: 'aggregation',
		label: 'Aggregation',
		type: 'summary',
	},
	{
		value: 'ratio',
		label: 'Ratio',
		type: 'summary',
	},
];

const logic = [
	{
		value: '=',
		label: '=',
	},
	{
		value: '<>',
		label: '<>',
	},
	{
		value: '<',
		label: '<',
	},
	{
		value: '>',
		label: '>',
	},
	{
		value: '<=',
		label: '<=',
	},
	{
		value: '>=',
		label: '>=',
	},
	{
		value: 'between',
		label: 'Between',
	},
	{
		value: 'in',
		label: 'In',
	},
	{
		value: 'not_in',
		label: 'Not In',
	},
];

const numericOrDateLogic = [
	{
		value: '=',
		label: '=',
	},
	{
		value: '<>',
		label: '<>',
	},
	{
		value: '<',
		label: '<',
	},
	{
		value: '>',
		label: '>',
	},
	{
		value: '<=',
		label: '<=',
	},
	{
		value: '>=',
		label: '>=',
	},
];

const textLogic = [
	{
		value: '=',
		label: '=',
	},
	{
		value: 'in',
		label: 'In',
	},
	{
		value: 'not_in',
		label: 'Not In',
	},
];

const booleanLogicNumeric = [
	{
		value: '=',
		label: '=',
	},
	{
		value: '<>',
		label: '<>',
	},
	{
		value: '>',
		label: '>',
	},
	{
		value: '>=',
		label: '>=',
	},
	{
		value: '<',
		label: '<',
	},
	{
		value: '<=',
		label: '<=',
	},
];

const booleanLogicText = [
	{
		value: '=',
		label: '=',
	},
	{
		value: '<>',
		label: '<>',
	},
	{
		label: 'In (comma-separated list) ',
		value: 'in',
	},
	{
		label: 'Not In (comma-separated list)',
		value: 'notIn',
	},
	{
		label: 'Begins with',
		value: 'begins_with',
	},
	{
		label: 'Ends with',
		value: 'ends_with',
	},
	{
		label: 'Contains',
		value: 'contains',
	},
];

const numericFormats = [
	{
		label: '9,999',
		value: '9,999',
	},
	{
		label: '9,999.99',
		value: '9,999.99',
	},
	{
		label: '9,999.99999',
		value: '9,999.99999',
	},
	{
		label: '9,999.*',
		value: '9,999.*',
	},
	{
		label: '9,999%',
		value: '9,999%',
	},
	{
		label: '9,999.99%',
		value: '9,999.99%',
	},
	{
		label: '9,999.99999%',
		value: '9,999.99999%',
	},
	{
		label: '9,999.*%',
		value: '9,999.*%',
	},
];

const dateFormats = [
	{
		label: 'YYYY-MM-DD',
		value: 'YYYY-MM-DD',
	},
	{
		label: 'DD MMM YYYY',
		value: 'DD MMM YYYY',
	},
];

const shortDateFormats = [
	{
		label: 'YYYY-MM',
		value: 'yyyy-MM',
	},
	{
		label: 'YYYY MM',
		value: 'yyyy MM',
	},
];

const booleanFormats = [
	{
		label: 'True / False',
		value: 'true/false',
	},
	{
		label: 'Yes / No',
		value: 'yes/no',
	},
	{
		label: '1 / 0',
		value: '1/0',
	},
];

const textFormats = [
	{
		label: 'Text',
		value: 'text',
	},
];

const booleanConstants = [
	{
		label: 'True',
		value: 'true',
	},
	{
		label: 'False',
		value: 'false',
	},
];

const algebraicTypes = [
	{
		label: '+',
		value: '+',
	},
	{
		label: '-',
		value: '-',
	},
	{
		label: '*',
		value: '*',
	},
	{
		label: '/',
		value: '/',
	},
	{
		label: '^',
		value: '^',
	},
];

const comparisonValueTypes = [
	{
		label: 'Column',
		value: 'column',
	},
	{
		label: 'Constant',
		value: 'constant',
	},
	{
		label: 'Function',
		value: 'function',
	},
	{
		label: 'Text Constant',
		value: 'string',
	},
	{
		label: 'Numeric Constant',
		value: 'numeric',
	},
	{
		label: 'Date Constant',
		value: 'date_long',
	},
	{
		label: 'Child Column',
		value: 'subAccountColumn',
	},
];

const calcColumnsConditionTypes = [
	{
		label: 'Column',
		value: 'column',
	},
	{
		label: 'Text Constant',
		value: 'string',
	},
	{
		label: 'Numeric Constant',
		value: 'numeric',
	},
	{
		label: 'Date Constant',
		value: 'date_long',
	},
];

const comparisonFunctionTypes = [
	{
		label: 'Min',
		value: 'min',
	},
	{
		label: 'Max',
		value: 'max',
	},
];

const conditionalTypes = [
	{
		label: 'And',
		value: 'and',
	},
	{
		label: 'Or',
		value: 'or',
	},
];

const returnValueTypes = [
	{
		label: 'Number',
		value: 'number',
	},
	{
		label: 'Text',
		value: 'text',
	},
	{
		label: 'Boolean',
		value: 'boolean',
	},
];

const ratioOptions = [
	{
		label: 'Sum',
		value: 'sum',
	},
	{
		label: 'Average',
		value: 'average',
	},
];

const ccConcentrationSelection = [
	{
		value: 'max',
		label: 'Max',
	},
	{
		value: 'top_2_bucket',
		label: 'Top 2 Bucket',
	},
	{
		value: 'top_3_bucket',
		label: 'Top 3 Bucket',
	},
	{
		value: 'top_4_bucket',
		label: 'Top 4 Bucket',
	},
	{
		value: 'top_5_bucket',
		label: 'Top 5 Bucket',
	},
	{
		value: 'least',
		label: 'Least',
	},
	{
		value: 'top_5_combined',
		label: 'Top 5 Combined',
	},
	{
		value: 'top_10_combined',
		label: 'Top 10 Combined',
	},
	{
		value: 'range',
		label: 'Range',
	},
];

const optimizationTypes = [
	{
		value: 'funding',
		label: 'Funding',
	},
	{
		value: 'repurchase',
		label: 'Drawdown',
	},
	{
		value: 'drawdown_and_funding',
		label: 'Drawdown and Funding',
	},
	{
		value: 'top_off',
		label: 'Top-Off',
	},
	{
		value: 'drawdown_and_top_off',
		label: 'Drawdown and Top-Off',
	},
	{
		value: 'balanced_funding',
		label: 'Balanced Funding',
	},
	{
		value: 'balanced_and_top_off',
		label: 'Balanced Top-Off',
	},
];

const aggregateChartTypes = [
	{
		label: 'Pie With Legend',
		value: 'pie',
		type: 'pie',
	},
	{
		label: 'Line With Data Labels',
		value: 'line-data-labels',
		type: 'line',
	},
	{
		label: '3D Pie',
		value: '3d-pie',
		type: 'pie',
	},
	{
		label: '3D Donut',
		value: '3d-donut',
		type: 'pie',
	},
	{
		label: '3D Column',
		value: '3d-column',
		type: 'column',
	},
	{
		label: 'Semi Circle Donut',
		value: 'semi-circle-donut',
		type: 'pie',
	},
	{
		label: 'Basic Column',
		value: 'column',
		type: 'column',
	},
	{
		label: 'Basic Line',
		value: 'line',
		type: 'line',
	},
	{
		label: 'Pie With Gradient Fill',
		value: 'pie-gradient',
		type: 'pie',
	},
	{
		label: 'Pie With Monochrome Fill',
		value: 'pie-monochrome',
		type: 'pie',
	},
	{
		label: 'Stacked Bar',
		value: 'stacked-column',
		type: 'column',
	},
	{
		label: 'Area Chart',
		value: 'area',
		type: 'line',
	},
];

const timeSeriesChartTypes = [
	{
		label: 'Line With Data Labels',
		value: 'line-data-labels',
		type: 'line',
	},
	{
		label: '3D Column',
		value: '3d-column',
		type: 'column',
	},
	{
		label: 'Basic Column',
		value: 'column',
		type: 'column',
	},
	{
		label: 'Basic Line',
		value: 'line',
		type: 'line',
	},
	{
		label: 'Stacked Bar',
		value: 'stacked-column',
		type: 'column',
	},
	{
		label: 'Area Chart',
		value: 'area',
		type: 'line',
	},
];

const calculatedAssetColumnTypes = [
	{
		value: 'numeric',
		label: 'Numeric',
	},
	{
		value: 'text',
		label: 'Text',
	},
	{
		value: 'date',
		label: 'Date',
	},
	{
		value: 'conditional',
		label: 'Conditional',
	},
	{
		value: 'cumulative',
		label: 'Cumulative',
	},
	{
		value: 'parent_data',
		label: 'Parent Data',
	},
	{
		value: 'subAccountColumn',
		label: 'Child Data',
	},
	{
		value: 'numericVariable',
		label: 'Numeric Variable',
	},
];

const cumulativeAggregationTypes = [
	{
		label: 'Sum',
		value: 'SUM',
	},
	{
		label: 'Average',
		value: 'AVG',
	},
	{
		label: 'Weighted Average',
		value: 'WGHTAVG',
	},
	{
		label: 'Minimum',
		value: 'MIN',
	},
	{
		label: 'Maximum',
		value: 'MAX',
	},
];

const calculatedSummaryColumnTypes = [
	{
		label: 'Numeric',
		value: 'numeric',
	},
	{
		label: 'Date',
		value: 'date_long',
	},
	{
		label: 'Text',
		value: 'string',
	},
];

const summaryStringAggregateColumnTypes = [
	{
		label: 'Minimum',
		value: 'MIN',
	},
	{
		label: 'Maximum',
		value: 'MAX',
	},
	{
		label: 'First Valid Value',
		value: 'FIRST_VALID',
	},
];

const summaryDateAggregateColumnTypes = [
	{
		label: 'Minimum',
		value: 'MIN',
	},
	{
		label: 'Maximum',
		value: 'MAX',
	},
	{
		label: 'First Valid Value',
		value: 'FIRST_VALID',
	},
	{
		label: 'Weighted Average',
		value: 'WGHTAVG',
	},
];

const summaryAggregateColumnTypes = [
	{
		label: 'Sum',
		value: 'SUM',
	},
	{
		label: 'Ratio',
		value: 'RATIO',
	},
	{
		label: 'Sum Percent Total',
		value: 'SUM_PCT',
	},
	{
		label: 'Sum Percent Total (Portfolio)',
		value: 'SUM_PCT_TOTAL',
	},
	{
		label: 'Average',
		value: 'AVG',
	},
	{
		label: 'Weighted Average',
		value: 'WGHTAVG',
	},
	{
		label: 'Minimum',
		value: 'MIN',
	},
	{
		label: 'Maximum',
		value: 'MAX',
	},
];

const textFunctions = [
	{
		label: 'Concatenate',
		value: 'concatenate',
	},
	{
		label: 'Substring',
		value: 'substring',
	},
];

const dateFunctions = [
	{
		label: 'Date Diff',
		value: 'dateDiff',
	},
	{
		label: 'Parse Date',
		value: 'dateParse',
	},
	{
		label: 'Date Add',
		value: 'dateAdd',
	},
];

const dateParseValues = [
	{
		label: 'Day',
		value: 'day',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Year',
		value: 'year',
	},
	{
		label: 'Year-Month',
		value: 'year-month',
	},
];

const dateAddFunctionTypes = [
	{
		label: 'Add',
		value: '+',
	},
	{
		label: 'Subtract',
		value: '-',
	},
];

const dateAddCounters = [
	{
		label: 'Days',
		value: 'days',
	},
	{
		label: 'Months',
		value: 'months',
	},
	{
		label: 'Years',
		value: 'years',
	},
];

const textDelimiters = [
	{
		label: 'Space',
		value: 'space',
	},
	{
		label: 'Hyphen',
		value: 'hyphen',
	},
	{
		label: 'Period',
		value: 'period',
	},
	{
		label: 'Pipe',
		value: 'pipe',
	},
	{
		label: 'None',
		value: 'none',
	},
];

const dateCounters = [
	{
		label: 'Days',
		value: 'days',
	},
	{
		label: 'Months (Rounded)',
		value: 'monthsRounded',
	},
	{
		label: 'Months (Decimal)',
		value: 'monthsDecimal',
	},
];

const reportSectionTypes = [
	{
		label: 'View',
		value: 'view',
	},
	{
		label: 'Borrowing Base',
		value: 'borrowingBase',
	},
	{
		label: 'Assets In Breach',
		value: 'assetsInBreach',
	},
	{
		label: 'Excess Concentration',
		value: 'excessConcentration',
	},
	{
		label: 'Aggregate Tests',
		value: 'aggregateTests',
	},
	{
		label: 'Breaches',
		value: 'breaches',
	},
	{
		label: 'Compliance',
		value: 'compliance',
	},
];

const conditionalOutputDataTypes = [
	{
		label: 'Text',
		value: 'string',
	},
	{
		label: 'Numeric',
		value: 'numeric',
	},
	{
		label: 'Date',
		value: 'date_long',
	},
];

const defaultCalculationTypes = [
	{
		label: 'Advance Rate',
		value: 'advance_rate',
	},
	{
		label: 'Gross Borrowing Base',
		value: 'gross_borrowing_base',
	},
	{
		label: 'Evaluated Balance',
		value: 'evaluated_balance',
	},
	{
		label: 'Net Borrowing Base',
		value: 'net_borrowing_base',
	},
];

const appendDataOptions = [
	{
		label: 'Child File',
		value: 'aggregate',
	},
	{
		label: 'Peer File',
		value: 'appendData',
	},
	{
		label: 'Payment File',
		value: 'paymentFile',
	},
];

const fvDateRecurrenceOptions = [
	{
		label: 'None',
		value: 'none',
	},
	{
		label: 'Daily',
		value: 'daily',
	},
	{
		label: 'Weekly',
		value: 'weekly',
	},
	{
		label: 'Monthly',
		value: 'monthly',
	},
	{
		label: 'Monthly End',
		value: 'monthly_end',
	},
	{
		label: 'Quarterly',
		value: 'quarterly',
	},
	{
		label: 'Semi Annually',
		value: 'semi_annually',
	},
	{
		label: 'Annually',
		value: 'annually',
	},
];

const fvCalendarTypeOptions = [
	{
		label: 'Collection Start Date',
		value: 'collectionStart',
	},
	{
		label: 'Collection End Date',
		value: 'collectionEnd',
	},
	{
		label: 'Previous Month End Date',
		value: 'previousMonthEnd',
	},
	{
		label: 'Month End Date',
		value: 'monthEnd',
	},
	{
		label: 'Previous Day',
		value: 'previousDay',
	},
	{
		label: 'Call Date',
		value: 'call',
	},
	{
		label: 'Initial Funding Date',
		value: 'cutOff',
	},
	{
		label: 'Determination Date',
		value: 'determinationStandard',
	},
	{
		label: 'Funding Determination Date',
		value: 'determination',
	},
	{
		label: 'Distribution Date',
		value: 'distributionStandard',
	},
	{
		label: 'Funding Distribution Date',
		value: 'distribution',
	},
	{
		label: 'Previous Determination Date',
		value: 'previousDetermination',
	},
	{
		label: 'Previous Distribution Date',
		value: 'previousDistribution',
	},
	{
		label: 'Inactive Date',
		value: 'inactive',
	},
	{
		label: 'Maturity Date',
		value: 'maturity',
	},
	{
		label: 'Notice Date',
		value: 'notice',
	},
	{
		label: 'Pay Date',
		value: 'pay',
	},
	{
		label: 'Securitized Date',
		value: 'securitized',
	},
	{
		label: 'Reinvestment Period End Date',
		value: 'reinvestmentPeriodEnd',
	},
	{
		label: 'Settlement Date',
		value: 'settlement',
	},
	{
		label: 'Final Payout Date',
		value: 'finalPayout',
	},
	{
		label: 'First Optional Call Date',
		value: 'firstOptionalCall',
	},
	{
		label: 'Revolving Period End Date',
		value: 'revolvingPeriodEnd',
	},
	{
		label: 'Amortization Date',
		value: 'amortization',
	},
	{
		label: 'Deficiency Claim Date',
		value: 'deficiencyClaim',
	},
	{
		label: 'Funding Termination Date',
		value: 'fundingTermination',
	},
	{
		label: 'Record Date',
		value: 'record',
	},
	{
		label: 'Report Date',
		value: 'report',
	},
	{
		label: 'Servicing Transfer Date',
		value: 'servicingTransfer',
	},
	{
		label: 'Termination Date',
		value: 'termination',
	},
];

const accrualMethodOptions = [
	{
		label: '30/360',
		value: '30/360',
	},
	{
		label: '30/365',
		value: '30/365',
	},
	{
		label: 'Actual/360',
		value: 'ACT/360',
	},
	{
		label: 'Actual/365',
		value: 'ACT/365',
	},
	{
		label: 'Actual/Actual',
		value: 'ACT/ACT',
	},
];

const classTypes = [
	{
		label: 'A',
		value: 'A',
	},
	{
		label: 'B',
		value: 'B',
	},
	{
		label: 'C',
		value: 'C',
	},
	{
		label: 'D',
		value: 'D',
	},
	{
		label: 'E',
		value: 'E',
	},
	{
		label: 'F',
		value: 'F',
	},
	{
		label: 'G',
		value: 'G',
	},
	{
		label: 'H',
		value: 'H',
	},
	{
		label: 'I',
		value: 'I',
	},
	{
		label: 'J',
		value: 'J',
	},
	{
		label: 'K',
		value: 'K',
	},
	{
		label: 'L',
		value: 'L',
	},
	{
		label: 'M',
		value: 'M',
	},
	{
		label: 'N',
		value: 'N',
	},
	{
		label: 'O',
		value: 'O',
	},
	{
		label: 'P',
		value: 'P',
	},
	{
		label: 'Q',
		value: 'Q',
	},
	{
		label: 'R',
		value: 'R',
	},
	{
		label: 'S',
		value: 'S',
	},
	{
		label: 'T',
		value: 'T',
	},
	{
		label: 'U',
		value: 'U',
	},
	{
		label: 'V',
		value: 'V',
	},
	{
		label: 'W',
		value: 'W',
	},
	{
		label: 'Y',
		value: 'Y',
	},
	{
		label: 'Z',
		value: 'Z',
	},
];

const seniorityTypes = [
	{
		label: 'None',
		value: '',
	},
	{
		label: 'Senior',
		value: 'senior',
	},
	{
		label: 'Mezzanine',
		value: 'mezzanine',
	},
	{
		label: 'Junior',
		value: 'junior',
	},
	{
		label: 'Double A',
		value: 'double a',
	},
	{
		label: 'NIM',
		value: 'NIM',
	},
	{
		label: 'Residual',
		value: 'residual',
	},
	{
		label: 'IO PAC',
		value: 'IO PAC',
	},
	{
		label: 'IO PAC SUP',
		value: 'IO PAC Sup',
	},
	{
		label: 'Super Sr',
		value: 'super Sr',
	},
	{
		label: 'SS Support',
		value: 'SS support',
	},
	{
		label: 'Penalty',
		value: 'penalty',
	},
	{
		label: 'Other',
		value: 'other',
	},
];

const payTypes = [
	{
		label: 'None',
		value: '',
	},
	{
		label: 'Structured',
		value: 'structured',
	},
	{
		label: 'Equity Tranche',
		value: 'equity Tranche',
	},
	{
		label: 'Revolver',
		value: 'revolver',
	},
	{
		label: 'IO Tranche',
		value: 'IO Tranche',
	},
];

const balanceType = [
	{
		label: 'None',
		value: '',
	},
	{
		label: 'Actual',
		value: 'Actual',
	},
	{
		label: 'Notional',
		value: 'Notional',
	},
];

const selections = [
	{
		label: 'Top N Combined',
		value: 'top_n_combined',
	},
	{
		label: 'Top Nth Bucket',
		value: 'top_nth_bucket',
	},
	{
		label: 'Include',
		value: 'include',
	},
];

const weightedLogicOptions = [
	{
		label: '>=',
		value: '>=',
	},
	{
		label: '<=',
		value: '<=',
	},
	{
		label: 'Between',
		value: 'between',
	},
];

const concentrationLogicOptions = [
	{
		label: 'Min',
		value: '>=',
	},
	{
		label: 'Max',
		value: '<=',
	},
	{
		label: 'Between',
		value: 'between',
	},
];

const balanceLimitOptions = [
	{
		label: '>=',
		value: '>=',
	},
	{
		label: '<=',
		value: '<=',
	},
];

const explorationViewTypes = [
	{
		label: 'Asset',
		value: 'standard',
	},
	{
		label: 'Blended',
		value: 'blended',
	},
	{
		label: 'Encumbrance',
		value: 'encumbrance',
	},
];

module.exports = {
	fvCalendarTypeOptions,
	fvDateRecurrenceOptions,
	recurrenceTypeOptions,
	fundingVehicleStatusOptions,
	logic,
	numericOrDateLogic,
	textLogic,
	booleanLogicNumeric,
	booleanLogicText,
	numericFormats,
	dateFormats,
	shortDateFormats,
	booleanFormats,
	textFormats,
	assetColumnTypes,
	summaryColumnTypes,
	booleanConstants,
	algebraicTypes,
	comparisonValueTypes,
	comparisonFunctionTypes,
	conditionalTypes,
	returnValueTypes,
	ratioOptions,
	ccConcentrationSelection,
	submissions,
	constraintRowTypes,
	optimizationTypes,
	poolMaintenanceStatusOptions,
	aggregateChartTypes,
	timeSeriesChartTypes,
	calculatedAssetColumnTypes,
	cumulativeAggregationTypes,
	calculatedSummaryColumnTypes,
	summaryAggregateColumnTypes,
	summaryStringAggregateColumnTypes,
	summaryDateAggregateColumnTypes,
	textFunctions,
	dateFunctions,
	dateParseValues,
	textDelimiters,
	dateCounters,
	dateAddCounters,
	dateAddFunctionTypes,
	reportSectionTypes,
	conditionalOutputDataTypes,
	defaultCalculationTypes,
	calcColumnsConditionTypes,
	audit,
	appendDataOptions,
	accrualMethodOptions,
	debt,
	classTypes,
	seniorityTypes,
	payTypes,
	balanceType,
	systemColumns,
	selections,
	weightedLogicOptions,
	concentrationLogicOptions,
	balanceLimitOptions,
	explorationViewTypes,
};
