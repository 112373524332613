import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {showSnackbar} from 'state/actions/Snackbar';
import styles from './ReportLink.theme.scss';
import {apiUrl} from 'api/apiUtils';
import _uniqueId from 'lodash/uniqueId';
import {KiIconCsv, KiIconLink, KiIconXlsx, KiIconPdf} from 'components/KiIcons';
import classnames from 'classnames';

export class ReportLink extends Component {
	static propTypes = {
		showSnackbar: PropTypes.func.isRequired,
		title: PropTypes.string,
		reportDefinitionId: PropTypes.string,
		fundingVehicleId: PropTypes.string,
		scenarioId: PropTypes.string,
		statementDate: PropTypes.string,
		filename: PropTypes.string,
		jsonIcon: PropTypes.bool,
		csvIcon: PropTypes.bool,
		pdfIcon: PropTypes.bool,
		xlsxIcon: PropTypes.bool,
		disabled: PropTypes.bool,
		solutionId: PropTypes.string,
		scenarioType: PropTypes.string,
	};

	static defaultProps = {
		jsonIcon: true,
		csvIcon: false,
		xlsxIcon: true,
		disabled: false,
		pdfIcon: false,
	};

	generateReportLink = linkType => {
		const {
			reportDefinitionId,
			fundingVehicleId,
			scenarioId,
			filename,
			solutionId,
			statementDate,
			scenarioType,
		} = this.props;
		const origin = document.baseURI;
		/*
		if (window.location.origin) {
			origin = window.location.origin;
		} else {
			const port = window.location.port ? `:${window.location.port}` : '';
			origin = `${window.location.protocol}//${window.location.hostname}${port}`;
		}*/

		const params = [];
		if (fundingVehicleId) {
			params.push(`fundingVehicleId=${fundingVehicleId}`);
		}
		if (scenarioId) {
			params.push(`scenarioId=${scenarioId}`);
		}
		if (scenarioType) {
			params.push(`scenarioType=${scenarioType}`);
		}
		if (solutionId) {
			params.push(`solutionId=${solutionId}`);
		}
		if (statementDate) {
			params.push(`statementDate=${statementDate}`);
		}
		let url = `${origin}${apiUrl}/reports/`;
		switch (linkType) {
			case 'json': {
				url += `generate/${reportDefinitionId}`;
				break;
			}
			case 'csv': {
				url += `download/${reportDefinitionId}`;
				params.push(`outputFormat=csv`);
				params.push(`filename=${encodeURIComponent(filename)}`);
				break;
			}
			case 'pdf': {
				url += `download/${reportDefinitionId}`;
				params.push(`outputFormat=pdf`);
				params.push(`filename=${encodeURIComponent(filename)}`);
				break;
			}
			case 'xlsx': {
				url += `download/${reportDefinitionId}`;
				params.push(`outputFormat=xlsx`);
				params.push(`filename=${encodeURIComponent(filename)}`);
				break;
			}
		}
		params.push(`ver=2`);
		return params.length > 0 ? `${url}?${params.join('&')}` : url;
	};

	copyReportLinkToClipboard = id => {
		document.querySelector(`#${id}`).select();
		document.execCommand('copy');
		this.props.showSnackbar('Data link has been copied to the clipboard');
	};

	getClassNames = () => {
		return classnames(styles.container, {
			[styles.disabled]: this.props.disabled,
		});
	};

	getDataLink = () => {
		const id = _uniqueId('report-link-');
		return (
			<span className={styles.iconLink} onClick={() => this.copyReportLinkToClipboard(id)} title={'Data Link'}>
				<KiIconLink />
				<input className={styles.copyInput} id={id} value={this.generateReportLink('json')} readOnly={true} />
			</span>
		);
	};

	getCsvLink = () => {
		return (
			<a className={styles.iconLink} href={this.generateReportLink('csv')} title={'Download CSV'}>
				<KiIconCsv />
			</a>
		);
	};

	getPdfLink = () => {
		return (
			<a className={styles.iconLink} href={this.generateReportLink('pdf')} title={'Download PDF'}>
				<KiIconPdf />
			</a>
		);
	};

	getXlsxLink = () => {
		return (
			<a className={styles.iconLink} href={this.generateReportLink('xlsx')} title={'Download XLSX'}>
				<KiIconXlsx />
			</a>
		);
	};

	render() {
		const {title, jsonIcon, csvIcon, pdfIcon, xlsxIcon} = this.props;

		return (
			<div className={this.getClassNames()}>
				{jsonIcon && this.getDataLink()}
				{csvIcon && this.getCsvLink()}
				{pdfIcon && this.getPdfLink()}
				{xlsxIcon && this.getXlsxLink()}
				<span className={styles.title}>{title}</span>
			</div>
		);
	}
}

const mapDispatchToProps = () => ({
	showSnackbar,
});

export default connect(
	null,
	mapDispatchToProps()
)(ReportLink);
