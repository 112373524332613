import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import KiButton from '../../../components/KiButton';
import KiConfirmModal from '../../../components/KiConfirmModal';

import datasetCopyApi from '../../../api/datasetCopyApi';

const DeleteDatasetForm = props => {
	const [dataset, setDataset] = useState({});
	const [isDeleteConfirmActive, setIsDeleteConfirmActive] = useState(false);

	const executeDelete = () => {
		datasetCopyApi
			.deleteDs(dataset)
			.then(resp => {
				const successMessage =
					resp.message === ' "BE FAILED"'
						? `Dataset ${dataset.name} partially deleted`
						: `Deleted "${dataset.name}"`;
				props.showSnackbar(successMessage);
				props.fetchDatasetList();
				setDataset({});
			})
			.catch(() => {
				props.showSnackbar(`There was an issue deleting "${dataset.name}""`);
			});
		setIsDeleteConfirmActive(false);
	};

	const buttonDisabled = () => {
		return dataset.datasetId ? false : true;
	};

	return (
		<div>
			<KiConfirmModal
				header="Delete Dataset"
				message={`Are you sure you want to delete dataset "${dataset.name}"?  This is irreversable.`}
				acceptFunc={() => executeDelete()}
				rejectFunc={() => setIsDeleteConfirmActive(false)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={isDeleteConfirmActive}
			/>
			<div className={'datasetCopyForm'}>
				<div className="dataset-selector-group">
					<span className="form-instruction">Choose Dataset To Delete:</span>
					<Select
						classNamePrefix="aut-select"
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
						isClearable={false}
						options={props.datasets}
						value={props.datasets.filter(x => dataset?.datasetId === x.datasetId)}
						onChange={val => {
							setDataset(val);
						}}
					/>
				</div>
				<KiButton
					raised
					primary
					label="Delete"
					className={'run-button'}
					onClick={() => setIsDeleteConfirmActive(true)}
					disabled={buttonDisabled()}
				/>
			</div>
		</div>
	);
};

DeleteDatasetForm.propTypes = {
	datasets: PropTypes.array.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	fetchDatasetList: PropTypes.func.isRequired,
};

DeleteDatasetForm.defaultProps = {
	datasets: [],
};

export default DeleteDatasetForm;
