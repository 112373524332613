import React from 'react';
import PropTypes from 'prop-types';
import LedgerTableItem from './LedgerTableItem';
import KiTooltip from '../../../components/KiTooltip';
import AdminAvailable from '../../../components/Available/AdminAvailable';
import KiCheckbox from '../../../components/KiCheckbox';

const infoMessage = (
	<p>
		Separate ledgers are provided for each dataset and each<br />
		funding vehicle. These ledgers are created automatically<br />
		when the dataset or funding vehicle is created and hold the<br />
		applicable accounting entries.
	</p>
);

const LedgerTable = ({ledgers, active, setActive}) => {
	const onChecked = ({_id}) => {
		const exists = active.has(_id);
		if (exists) {
			active.delete(_id);
		} else {
			active.add(_id);
		}
		setActive(new Set(active));
	};

	const areAllChecked = () => {
		return active.size === ledgers.length;
	};

	const toggleCheckAll = () => {
		setActive(areAllChecked() ? new Set() : new Set(ledgers.map(({_id}) => _id)));
	};

	return (
		<div className="scenario-list-box list-box-size-1">
			<table className="scenario-list-table scenario-list-table-ledger">
				<thead>
					<tr>
						<th className="select-item">
							<AdminAvailable>
								<KiCheckbox
									className="select-item-checkbox no-margin"
									checked={areAllChecked()}
									onChange={() => toggleCheckAll()}
									title="Select List"
								/>
							</AdminAvailable>
						</th>
						<th>
							Ledger Name
							<KiTooltip message={infoMessage} maxWidth={400} direction="right" className="ledger-info">
								<i className="material-icons">info_outline</i>
							</KiTooltip>
						</th>
						<th>Period Close Date</th>
						<th>Latest Effective Date</th>
						<th>Latest Posting Date</th>
						<AdminAvailable>
							<th />
						</AdminAvailable>
					</tr>
				</thead>
				<tbody>
					{ledgers.map(ledger => (
						<LedgerTableItem
							checked={active.has(ledger._id)}
							item={ledger}
							key={ledger._id}
							onChecked={ledger => onChecked(ledger)}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
};

LedgerTable.propTypes = {
	ledgers: PropTypes.arrayOf(PropTypes.object),
	active: PropTypes.node.isRequired,
	setActive: PropTypes.func.isRequired,
};

LedgerTable.defaultProps = {
	ledgers: [],
};

export default LedgerTable;
