import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import {fetchDatasetList, fetchDataset} from 'containers/datasetList/actions';
import _ from 'lodash';
import CollateralMappings from './components/CollateralMappings';
import DateMappings from './components/DateMappings';
import {datasetsApi} from 'api';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import KiProgressBar from 'components/KiProgressBar';

export class Mappings extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		fetchDataset: PropTypes.func.isRequired,
		dataset: PropTypes.object,
		fetchFundingVehicleList: PropTypes.func,
		match: PropTypes.object,
		fetchBusinessFunctionColumnsByDatasetId: PropTypes.func,
		history: PropTypes.object,
	};

	static defaultProps = {
		dataset: {},
	};

	state = {
		selectedFunction: null,
		tabIndexSection: _.get(this.props, 'match.params.mapType') === 'dates' ? 1 : 0,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Dataset Collateral Mappings`;
		if (!window.WATERFALL_ENABLED === 'true') {
			this.setState({tabIndexSection: 1});
		}
		return Promise.all([
			this.props.fetchDataset(this.props.match.params.datasetId, false),
			this.props.fetchFundingVehicleList(),
		]);
	}

	componentDidUpdate() {
		document.title = `${this.props.app.kiVersion} - Dataset Collateral Mappings`;
	}

	saveColumn = col => {
		col.modifiedDate = new Date().toISOString();
		return datasetsApi.upsertColumns([col], this.props.dataset.datasetId).then(() => {
			this.props.fetchBusinessFunctionColumnsByDatasetId(this.props.dataset.datasetId);
		});
	};

	render() {
		const {tabIndexSection} = this.state;
		if (!this.props.dataset.datasetId) {
			return <KiProgressBar />;
		}
		return (
			<div className="container-wrapper">
				<div className="container-body">
					<header>
						<KiAppBar className="top-bar">
							<div className="top-bar-breadcrumb">
								<h1
									style={{cursor: 'pointer'}}
									className="link"
									onClick={() => this.props.history.push('/datasets')}
								>
									Datasets
								</h1>
								<h1>{` > ${this.props.dataset && this.props.dataset.name}`}</h1>
								<h1
									style={{cursor: 'pointer'}}
									className="link"
									onClick={() =>
										this.props.history.push(`/dealStructures/${this.props.dataset.datasetId}`)
									}
								>
									&nbsp;&gt;&nbsp;Dataset Mappings
								</h1>
							</div>
						</KiAppBar>
					</header>
					<div className="ki-panel">
						<KiTabs onChange={index => this.setState({tabIndexSection: index})} index={tabIndexSection}>
							{window.WATERFALL_ENABLED === 'true' && (
								<KiTab title="Collateral">
									<CollateralMappings />
								</KiTab>
							)}
							<KiTab title="Dates">
								<DateMappings datasetId={this.props.match.params.datasetId} />
							</KiTab>
							{window.WATERFALL_ENABLED === 'true' && <KiTab title="Net Borrowing" />}
						</KiTabs>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	fundingVehicles: state.fundingVehicleList.data,
	dataset: state.datasetList.selected,
});

const mapDispatchToProps = () => ({
	fetchDatasetList,
	fetchDataset,
	fetchFundingVehicleList,
});

export default connect(mapStateToProps, mapDispatchToProps())(Mappings);
