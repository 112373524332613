import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchRatingsAgencies = () => {
	return fetch(`${apiUrl}/ratingsAgencies`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchRatingsOptionsByAgency = agencyId => {
	return fetch(`${apiUrl}/ratings/${agencyId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchRatingsOptionsForAllAgencies = () => {
	return fetch(`${apiUrl}/ratings`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchRatingsAgencies,
	fetchRatingsOptionsByAgency,
	fetchRatingsOptionsForAllAgencies,
};
