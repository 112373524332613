import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {KiTabs, KiTab, KiButton} from 'components';
import Settings from './Settings';
import Cohort from './Cohort';
import AssetColumns from './AssetColumns';
import SummaryColumns from './SummaryColumns';
import FilterList from './FilterList';
import {initializeView, loadView, saveView, getExplorerView} from './actions';
import {closeContextSidebarPanel} from 'state/actions/App';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import './ViewForms.scss';

export class ManageViewForms extends Component {
	static propTypes = {
		applyView: PropTypes.func,
		closeContextSidebarPanel: PropTypes.func,
		currentViewId: PropTypes.string,
		dataset: PropTypes.object.isRequired,
		columnList: PropTypes.any.isRequired,
		getExplorerView: PropTypes.func.isRequired,
		initializeView: PropTypes.func.isRequired,
		isAdmin: PropTypes.bool,
		isCopy: PropTypes.bool,
		loadView: PropTypes.func.isRequired,
		reloadView: PropTypes.func,
		saveView: PropTypes.func.isRequired,
		selectedView: PropTypes.object,
		setViewHasChanges: PropTypes.func,
		tabIndex: PropTypes.number,
		user: PropTypes.object,
		view: PropTypes.object,
	};

	static defaultProps = {
		dataset: {},
		dateContextList: [],
		columnList: [],
	};

	state = {
		columns: [],
		scenarioId: null,
		fundingVehicleId: null,
		snapshotDate: null,
		scenarioType: null,
		formIsValid: true,
		formHasChanges: false,
	};

	componentDidMount() {
		const {selectedView, dataset} = this.props;
		if (selectedView) {
			this.props.loadView(selectedView);
		} else {
			this.props.initializeView(dataset, dataset.snapshots[dataset.snapshots.length - 1]);
		}
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps.view.formErrors, this.props.view.formErrors)) {
			if (Object.values(this.props.view.formErrors).length) {
				this.setState({formIsValid: false});
			} else {
				this.setState({formIsValid: true});
			}
		}
	}

	setFormIsValid = val => {
		this.setState({formIsValid: val});
	};

	setFormHasChanges = () => {
		this.setState({formHasChanges: true});
	};

	handleSaveView = () => {
		return this.props.saveView().then(() => {
			if (this.props.currentViewId === _get(this, 'props.selectedView._id', null)) {
				this.props.reloadView(this.props.selectedView._id);
			}
			this.props.closeContextSidebarPanel();
			if (this.props.setViewHasChanges) {
				this.props.setViewHasChanges(false);
			}
			this.setState({formHasChanges: false});
		});
	};

	handleApplyView = () => {
		this.props.applyView(this.props.getExplorerView());
		this.props.setViewHasChanges(true);
		this.setState({formHasChanges: false});
	};

	render() {
		const {dataset, selectedView, columnList} = this.props;
		const selectedViewIsActive =
			this.props.currentViewId === (this.props.selectedView && this.props.selectedView._id);

		return (
			<div className="context-sidebar-form">
				<KiTabs index={this.props.tabIndex}>
					<KiTab title="Settings">
						<Settings
							dataset={dataset}
							filters={dataset.filters}
							isCopy={this.props.isCopy}
							isAdmin={this.props.isAdmin}
							user={this.props.user}
							setFormHasChanges={this.setFormHasChanges}
						/>
					</KiTab>
					<KiTab title="Cohort">
						<Cohort dataset={dataset} columnList={columnList} setFormHasChanges={this.setFormHasChanges} />
					</KiTab>
					<KiTab title="Summary">
						<SummaryColumns
							dataset={dataset}
							selectedView={selectedView}
							setFormIsValid={this.setFormIsValid}
							setFormHasChanges={this.setFormHasChanges}
						/>
					</KiTab>
					<KiTab title="Asset">
						<AssetColumns
							dataset={dataset}
							selectedView={selectedView}
							setFormIsValid={this.setFormIsValid}
							setFormHasChanges={this.setFormHasChanges}
						/>
					</KiTab>
					<KiTab title="Filters">
						<FilterList
							filters={this.props.view.filters}
							allFilters={dataset.filters}
							setFormHasChanges={this.setFormHasChanges}
						/>
					</KiTab>
				</KiTabs>
				<section className="inline-column-form-buttons">
					<KiButton flat primary onClick={() => this.props.closeContextSidebarPanel()} label="Close" />
					<React.Fragment>
						{selectedViewIsActive && (
							<KiButton
								raised
								primary
								disabled={!this.state.formIsValid || !this.state.formHasChanges}
								onClick={this.handleApplyView}
								label="Apply"
							/>
						)}
						{!selectedViewIsActive && (
							<KiButton
								raised
								primary
								disabled={!this.state.formIsValid}
								onClick={this.handleSaveView}
								label="Save"
							/>
						)}
					</React.Fragment>
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	dataset: state.datasetList.selected,
	columnList: state.datasetList.columnList,
	view: state.viewForm,
});

const mapDispatchToProps = () => ({
	closeContextSidebarPanel,
	getExplorerView,
	initializeView,
	loadView,
	saveView,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(ManageViewForms);
