const Enum = require('enum');

const SubmissionStage = new Enum(['upload', 'stage', 'validate', 'submit', 'approve', 'rollback'], {ignoreCase: true});
const SubmissionStageState = new Enum(['not_started', 'in_process', 'error', 'complete'], {ignoreCase: true});
const FundingVehicleStatus = new Enum(['notice', 'active', 'inactive'], {ignoreCase: true});

const SYNTHETIC_ASSET_COLUMNS = new Set([
	'EligibilityStatus',
	'IneligibilityReasonCode',
	'Total',
	'kiPoolName',
	'kiPoolStatus',
	'kiFVName',
	'kiFvStatus',
	'kiHypoPoolName',
	'kiEncumbranceStatus',
	'ki_encumbrance_pool',
]);

const dateTypes = new Map();
//custom date
dateTypes.set('custom', {regex: '.*', label: 'Custom', type: ''});

// long dates
dateTypes.set('MM-dd-yyyy', {regex: '^\\d{2}-\\d{2}-\\d{4}$', label: 'MM-dd-yyyy', type: 'date_long'});
dateTypes.set('dd-MM-yyyy', {regex: '^\\d{2}-\\d{2}-\\d{4}$', label: 'dd-MM-yyyy', type: 'date_long'});
dateTypes.set('yyyy-MM-dd', {regex: '^\\d{4}-\\d{2}-\\d{2}$', label: 'yyyy-MM-dd', type: 'date_long'});

dateTypes.set('MM/dd/yyyy', {regex: '^\\d{2}/\\d{2}/\\d{4}$', label: 'MM/dd/yyyy', type: 'date_long'});
dateTypes.set('dd/MM/yyyy', {regex: '^\\d{2}/\\d{2}/\\d{4}$', label: 'dd/MM/yyyy', type: 'date_long'});
dateTypes.set('yyyy/MM/dd', {regex: '^\\d{4}/\\d{2}/\\d{2}$', label: 'yyyy/MM/dd', type: 'date_long'});

dateTypes.set('MMddyy', {regex: '^\\d{8}$', label: 'MMddyy', type: 'date_long'});
dateTypes.set('ddMMyy', {regex: '^\\d{8}$', label: 'ddMMyy', type: 'date_long'});
dateTypes.set('yyMMdd', {regex: '^\\d{8}$', label: 'yyMMdd', type: 'date_long'});

dateTypes.set('MMddyyyy', {regex: '^\\d{8}$', label: 'MMddyyyy', type: 'date_long'});
dateTypes.set('ddMMyyyy', {regex: '^\\d{8}$', label: 'ddMMyyyy', type: 'date_long'});
dateTypes.set('yyyyMMdd', {regex: '^\\d{8}$', label: 'yyyyMMdd', type: 'date_long'});

//short dates
dateTypes.set('yyyyMM', {regex: '^\\d{6}$', label: 'yyyyMM', type: 'date_short'});
dateTypes.set('yyMM', {regex: '^\\d{6}$', label: 'yyMM', type: 'date_short'});
dateTypes.set('MMyyyy', {regex: '^\\d{6}$', label: 'MMyyyy', type: 'date_short'});
dateTypes.set('MMyy', {regex: '^\\d{6}$', label: 'MMyy', type: 'date_short'});

dateTypes.set('yyyy-MM', {regex: '^\\d{4}-\\d{2}$', label: 'yyyy-MM', type: 'date_short'});
dateTypes.set('yy-MM', {regex: '^\\d{2}-\\d{2}$', label: 'yy-MM', type: 'date_short'});
dateTypes.set('MM-yyyy', {regex: '^\\d{2}-\\d{4}$', label: 'MM-yyyy', type: 'date_short'});
dateTypes.set('MM-yy', {regex: '^\\d{2}-\\d{2}$', label: 'MM-yy', type: 'date_short'});

dateTypes.set('yyyy/MM', {regex: '^\\d{4}/\\d{2}$', label: 'yyyy/MM', type: 'date_short'});
dateTypes.set('yy/MM', {regex: '^\\d{2}/\\d{2}$', label: 'yy/MM', type: 'date_short'});
dateTypes.set('MM/yyyy', {regex: '^\\d{2}/\\d{4}$', label: 'MM/yyyy', type: 'date_short'});
dateTypes.set('MM/yy', {regex: '^\\d{2}/\\d{2}$', label: 'MM/yy', type: 'date_short'});

module.exports = {
	SubmissionStage,
	SubmissionStageState,
	FundingVehicleStatus,
	SYNTHETIC_ASSET_COLUMNS,
	dateTypes,
};
