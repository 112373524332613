import PropTypes from 'prop-types';
import React, {Component} from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import _isNumber from 'lodash/isNumber';
import _get from 'lodash/get';
import './KiInput.scss';

export class KiInput extends Component {
	static propTypes = {
		className: PropTypes.string,
		disabled: PropTypes.bool,
		defaultValue: PropTypes.string,
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		floating: PropTypes.bool, // Will label float above input
		// Hint - shows up in black under the main input field IF error is empty
		hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		// Label with hide if a value has been entered and floating = false
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		maxLength: PropTypes.number,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		onFocus: PropTypes.func,
		onKeyPress: PropTypes.func,
		required: PropTypes.bool,
		type: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
		isNumberMasked: PropTypes.bool,
		maskConfig: PropTypes.object,
		sendReactEvent: PropTypes.bool,
		children: PropTypes.any,
	};

	static defaultProps = {
		hint: '',
		disabled: false,
		floating: true,
		required: false,
		role: 'input',
		type: 'text',
		className: '',
		isNumberMasked: false,
		maskConfig: null,
		sendReactEvent: false,
	};

	blur() {
		this.inputNode.blur();
	}

	focus() {
		this.inputNode.focus();
	}

	getClearValue = event => {
		if (this.props.isNumberMasked) {
			return event.target.value.replace(/[,%]/g, ''); // Replace commas and percentage symbols from mask for validation
		}
		return event.target.value;
	};

	getClearEvent = reactEvent => {
		if (this.props.sendReactEvent) {
			return reactEvent;
		}
		let value = reactEvent.target.value;
		if (this.props.isNumberMasked) {
			value = value.replace(/[,%]/g, ''); // Replace commas and percentage symbols from mask for validation
		}
		const event = reactEvent.nativeEvent;
		_set(event, 'target.value', value);
		return event;
	};

	handleChange = event => {
		const {onChange} = this.props;
		if (onChange) onChange(this.getClearValue(event), this.getClearEvent(event));
	};

	handleKeyPress = event => {
		const {onKeyPress} = this.props;
		if (onKeyPress) onKeyPress(this.getClearEvent(event));
	};

	handleFocus = event => {
		const {onFocus} = this.props;
		if (onFocus) onFocus(this.getClearEvent(event));
	};

	handleBlur = event => {
		const {onBlur} = this.props;
		if (onBlur) onBlur(this.getClearEvent(event));
	};

	getInputElement = (isNumberMasked, maskConfig, inputElementProps) => {
		const numberMask = createNumberMask({
			prefix: _get(maskConfig, 'prefix', ''),
			suffix: _get(maskConfig, 'suffix', ''),
			allowDecimal: _get(maskConfig, 'allowDecimal', true),
			thousandsSeparatorSymbol: _get(maskConfig, 'thousandsSymbol', ','),
			decimalSymbol: _get(maskConfig, 'decimalSymbol', '.'),
			decimalLimit: _get(maskConfig, 'decimalLimit', 6),
			requireDecimal: _get(maskConfig, 'requireDecimal', false),
			allowNegative: _get(maskConfig, 'allowNegative', true),
			allowLeadingZeroes: _get(maskConfig, 'allowLeadingZeroes', false),
			integerLimit: _get(maskConfig, 'integerLimit', false),
		});
		if (isNumberMasked) {
			return (
				<MaskedInput
					placeholderChar={'\u2000'}
					mask={numberMask}
					rawvalue={inputElementProps.value || ''}
					{...inputElementProps}
				/>
			);
		} else {
			return React.createElement('input', inputElementProps);
		}
	};

	render() {
		const {
			defaultValue,
			required,
			maxLength,
			hint, // not html prop
			error, // not html prop
			label: labelText, // not html prop
			disabled,
			type,
			floating, // not html prop
			value,
			isNumberMasked, // not html prop
			maskConfig, // not html prop
			sendReactEvent, //eslint-disable-line no-unused-vars
			children,
			...others
		} = this.props;
		const length = maxLength && value ? value.length : 0;
		const valuePresent =
			!_isEmpty(value) || _isNumber(value) || (!_isEmpty(defaultValue) || _isNumber(defaultValue));
		// Any prop explicitly called out above needs to be added after ...others below to
		// make sure that it will be applied to the input html element
		const inputElementProps = {
			...others,
			defaultValue,
			required,
			maxLength,
			disabled,
			type,
			value,
			onChange: this.handleChange,
			onKeyPress: this.handleKeyPress,
			onBlur: this.handleBlur,
			onFocus: this.handleFocus,
			ref: node => {
				this.inputNode = node;
			},
			className: classNames('inputElement', {filled: valuePresent}),
		};

		const containerClassName = classNames(`ki-input ${this.props.className}`, {
			disabled: disabled,
			errored: error,
			hidden: type === 'hidden',
		});

		const labelClassName = classNames('label', {
			fixed: !floating,
			filled: valuePresent,
		});

		return (
			<div className={containerClassName}>
				{this.getInputElement(isNumberMasked, maskConfig, inputElementProps)}
				<span className={'bar'} />
				{(!value || floating) && labelText ? (
					<label className={labelClassName}>
						{labelText}
						{required ? <span className={'required'}> * </span> : null}
					</label>
				) : null}
				{!error && hint ? <span className={'hint'}>{hint}</span> : null}
				{error ? <span className={'error'}>{error}</span> : null}
				{maxLength ? (
					<span className={'counter'}>
						{length}/{maxLength}
					</span>
				) : null}
				{children}
			</div>
		);
	}
}
export default KiInput;
