const alphanumericRegex = /^[a-z\d\-_\s]+$/i;

const addNumberSuffix = number => {
	const parsedNumber = parseInt(number);
	if (isNaN(parsedNumber)) {
		return number;
	}
	switch (parsedNumber) {
		case 1:
			return `${parsedNumber}st`;
		case 2:
			return `${parsedNumber}nd`;
		case 3:
			return `${parsedNumber}rd`;
		default:
			return `${parsedNumber}th`;
	}
};

module.exports = {
	alphanumericRegex,
	addNumberSuffix,
};
