// Globals
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import _ from 'lodash';

// Project imports
import KiIconButton from 'components/KiIconButton';
// Local imports
import styles from './fundingVehicleDragList.theme.scss';

const FundingVehicleDragList = props => {
	const {
		itemList,
		droppableId,
		dragEnabled,
		onStepGroupBroken,
		isCombineEnabled,
		isOrderEnabled,
		isBeingDragged,
	} = props;
	const [sortedItemList, setSortedItemList] = useState([]);

	const renderItem = (item, index) => {
		const draggableId = droppableId + (item._id || item.fvId);

		return (
			<Draggable
				key={draggableId}
				draggableId={draggableId}
				index={index}
				isDragDisabled={!dragEnabled}
				isCombineEnabled={isCombineEnabled}
			>
				{provided => {
					//snapshot param can be used here
					return (
						<div
							className={styles.fvListItem}
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
						>
							<p>{item.fvName}</p>
						</div>
					);
				}}
			</Draggable>
		);
	};

	useEffect(
		() => {
			setSortedItemList(_.orderBy(itemList, ['priority'], ['asc']));
		},
		[itemList]
	);

	const stepGroups = _.countBy(sortedItemList, 'priority');

	return (
		<div
			className={styles.root}
			style={{padding: '1rem', backgroundColor: `${isBeingDragged ? '#E3E9EE' : '#fff'}`}}
		>
			{isOrderEnabled && (
				<section className={styles.fvListCounts}>
					{Object.keys(stepGroups).map((key, index) => (
						<div
							className={styles.fvListCountItem}
							key={key}
							style={{
								justifyContent: 'center',
								height: `${46 * stepGroups[key] + 5 * (stepGroups[key] - 1)}px`,
							}}
						>
							{stepGroups[key] > 1 &&
								dragEnabled && (
									<KiIconButton
										icon="clear"
										onClick={() => onStepGroupBroken(index + 1, droppableId)}
									/>
								)}
							<div style={{paddingTop: `${stepGroups[key] > 1 ? '3rem' : '0'}`}}>{index + 1}</div>
						</div>
					))}
				</section>
			)}
			<section className={styles.fvList}>
				<Droppable droppableId={droppableId} direction="vertical" isCombineEnabled={isCombineEnabled}>
					{provided => (
						<div
							style={{minHeight: `${dragEnabled ? '330px' : '0'}`}}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{sortedItemList.map((item, index) => renderItem(item, index))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</section>
		</div>
	);
};

FundingVehicleDragList.propTypes = {
	itemList: PropTypes.array,
	droppableId: PropTypes.string,
	dragEnabled: PropTypes.bool,
	onStepGroupBroken: PropTypes.func,
	isCombineEnabled: PropTypes.bool,
	isOrderEnabled: PropTypes.bool,
	isBeingDragged: PropTypes.bool,
};

FundingVehicleDragList.defaultProps = {
	itemList: [],
	droppableId: 'droppable',
	dragEnabled: true,
	isCombineEnabled: true,
	isOrderEnabled: false,
	isBeingDragged: false,
};

export default FundingVehicleDragList;
