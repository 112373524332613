import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiTooltip from 'components/KiTooltip';
import {connect} from 'react-redux';
import _get from 'lodash/get';

export class ItemRow extends Component {
	static propTypes = {
		column: PropTypes.object,
		isGenerating: PropTypes.bool,
		isModified: PropTypes.bool,
		isAdmin: PropTypes.bool,
		dateColumns: PropTypes.array,
		toggleFormMode: PropTypes.func,
		checkForModifications: PropTypes.func,
		isPendingModificationCheck: PropTypes.bool,
		handleGenerateColumn: PropTypes.func.isRequired,
		isGenerationDisabled: PropTypes.bool,
	};
	static defaultProps = {
		isGenerating: false,
		isModified: false,
		isAdmin: false,
		isPendingModificationCheck: false,
		isGenerationDisabled: false,
		dateColumns: [],
		column: {},
	};

	descriptionForHistoricalColumn = historicalColumn => {
		const assetColumnDisplayName = _get(historicalColumn, 'assetColumn.displayName', 'Unknown Asset');
		const dateColumn = this.props.dateColumns.find(
			dc => historicalColumn.asOfDateType === dc.dsDateMapId || historicalColumn.asOfDateType === dc.columnName
		);
		const dateColumnDisplayName = _get(dateColumn, 'displayName', 'Unknown Date Column');
		return `${assetColumnDisplayName} for ${dateColumnDisplayName} with offset of ${historicalColumn.offset || 0}`;
	};

	render() {
		const {column, isPendingModificationCheck, isGenerating, isModified, isAdmin} = this.props;
		return (
			<div className="column-list-item">
				<div
					className="column-name"
					title={column.displayName}
					style={{flex: '1 1 auto', maxWidth: 'unset', whiteSpace: 'normal', wordBreak: 'break-word'}}
				>
					{column.displayName}
					{isPendingModificationCheck && <span style={{color: 'red'}}> (Inspecting...)</span>}
					{column.status === 'generating' && <span style={{color: 'red'}}> (Generating...)</span>}
					{column.status === 'failed' && <span style={{color: 'red'}}> (Failed to Generate)</span>}
					{!isPendingModificationCheck &&
						!isGenerating &&
						isModified && <span style={{color: 'red'}}>*</span>}
				</div>
				<div className="icons-cell-right">
					<div className="list-icon-btn">
						<KiTooltip message={this.descriptionForHistoricalColumn(column)}>
							<i className="material-icons">info_outline</i>
						</KiTooltip>
					</div>
					<div className="list-icon-btn">
						{isAdmin && (
							<i
								title={'Check Changes'}
								className={`material-icons ${
									isPendingModificationCheck || isGenerating ? 'disabled' : ''
								}`}
								onClick={this.props.checkForModifications}
							>
								autorenew
							</i>
						)}
					</div>
					<div className="list-icon-btn">
						{isAdmin && (
							<i
								title={'Generate'}
								className={`material-icons ${
									column.status === 'generating' || this.props.isGenerationDisabled ? 'disabled' : ''
								}`}
								onClick={
									!this.props.isGenerationDisabled ? this.props.handleGenerateColumn : () => null
								}
							>
								build
							</i>
						)}
					</div>
					<div className="list-icon-btn">
						{isAdmin && (
							<i
								title={'Edit'}
								className={`material-icons ${
									column.status === 'generating' || this.props.isGenerationDisabled ? 'disabled' : ''
								}`}
								onClick={() =>
									this.props.isGenerationDisabled ? null : this.props.toggleFormMode('edit', column)
								}
							>
								edit
							</i>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAdmin: state.user.groups.includes('SystemAdmins'),
		columns: state.datasetList.columnList,
		dateColumns: state.datasetList.columnList.filter(col => !!col.dsDateMapId),
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItemRow);
