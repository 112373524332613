import React from 'react';
import {format} from 'ki-common/utils/displayFormatter';

export const ledgerCurrencyFormat = number => {
	// without this it caused error in Ledger exploration balance view into search while search action
	number = Number(number);
	return number == false ? '' : format(number, 'currency');
};

export const filterNestedList = (searchTerm, list = [], fields) => {
	const lowercaseSearchTerm = searchTerm.toLowerCase();
	return list.filter(item => {
		let itemToText = '';
		fields.forEach(field => {
			if (typeof field === 'object') {
				Object.keys(field).forEach(key => {
					item[key].forEach(nestedItem => {
						field[key].forEach(nestedField => {
							itemToText += nestedItem[nestedField];
						});
					});
				});
			} else {
				itemToText += item[field];
			}
		});
		return itemToText.toLowerCase().includes(lowercaseSearchTerm);
	});
};

export const renderPostingItems = (postings, itemName, isNumber = false) => {
	return postings.map((posting, index) => {
		let item;
		const isMetaData = itemName.startsWith('metadata.'); //posting.metadata["account-code"]
		if (isMetaData) {
			const itemNameMetadata = itemName
				.split('metadata.')
				.filter(f => f)
				.toString();
			item = posting.metadata[itemNameMetadata];
		} else {
			item = posting[itemName];
		}
		return (
			<div key={index} title={item} className="line-height">
				{isNumber ? ledgerCurrencyFormat(item) : item}
			</div>
		);
	});
};

export const handleEnter = (e, callback) => {
	if (e.keyCode === 13) {
		callback();
	}
};

export const isAnyListItemVisible = list => {
	return list.length && list.filter(item => item.visible === true).length;
};

export const TRANSACTION_TYPE_DEBIT = {label: 'Debit', value: 'DEBIT'};
export const TRANSACTION_TYPE_CREDIT = {label: 'Credit', value: 'CREDIT'};
export const TRANSACTION_TYPE_OPTIONS = [TRANSACTION_TYPE_DEBIT, TRANSACTION_TYPE_CREDIT];
