import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';
import analytics from 'analytics';

export const fetchDashboards = () => {
	return fetch(`${apiUrl}/dashboards/`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const validateDashboardName = name => {
	return fetch(`${apiUrl}/dashboards/testUnique/${name}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchCardsForDashboard = ids => {
	return fetch(`${apiUrl}/gadgets/ids`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(ids),
	}).then(_handleResponse);
};

export const fetchAllCards = ({datasetId} = {}) => {
	let targetUrl = `${apiUrl}/gadgets`;
	if (datasetId) {
		targetUrl += `?datasetId=${datasetId}`;
	}
	return fetch(targetUrl, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const addDashboard = dashboard => {
	analytics.sendEvent('dashboards', 'create');
	return fetch(`${apiUrl}/dashboards/`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(dashboard),
	}).then(_handleResponse);
};

export const updateDashboard = dashboard => {
	analytics.sendEvent('dashboards', 'update');
	return fetch(`${apiUrl}/dashboards/${dashboard._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(dashboard),
	}).then(_handleResponse);
};

export const deleteDashboard = dashboardId => {
	analytics.sendEvent('dashboards', 'delete');
	return fetch(`${apiUrl}/dashboards/${dashboardId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const addCard = card => {
	analytics.sendEvent('dashboards', 'add-card');
	return fetch(`${apiUrl}/gadgets/`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(card),
	}).then(_handleResponse);
};

export const deleteCard = cardId => {
	analytics.sendEvent('dashboards', 'delete-card');
	return fetch(`${apiUrl}/gadgets/${cardId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const getCard = cardId => {
	analytics.sendEvent('dashboards', 'delete-card');
	return fetch(`${apiUrl}/gadgets/${cardId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getAllCardTags = datasetId => {
	const targetUrl = `${apiUrl}/gadgets/getAllCardTags/${datasetId}`;
	return fetch(targetUrl, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchDashboards,
	validateDashboardName,
	fetchCardsForDashboard,
	fetchAllCards,
	addDashboard,
	updateDashboard,
	deleteDashboard,
	addCard,
	deleteCard,
	getCard,
	getAllCardTags,
};
