export const DEBT_EXPLORER_SET_DATASET = 'DEBT_EXPLORER_SET_DATASET';
export const DEBT_EXPLORER_SET_COHORT_COLUMN = 'DEBT_EXPLORER_SET_COHORT_COLUMN';
export const DEBT_EXPLORER_SET_VIEW_TYPE = 'DEBT_EXPLORER_SET_VIEW_TYPE';
export const DEBT_EXPLORER_SET_DATE = 'DEBT_EXPLORER_SET_DATE';
export const DEBT_EXPLORER_SET_CUSTOM_START_DATE = 'DEBT_EXPLORER_SET_CUSTOM_START_DATE';
export const DEBT_EXPLORER_SET_CUSTOM_END_DATE = 'DEBT_EXPLORER_SET_CUSTOM_END_DATE';
export const DEBT_EXPLORER_SET_FUNDING_VEHICLE = 'DEBT_EXPLORER_SET_FUNDING_VEHICLE';
export const FETCH_DEBT_EXPLORATION_DATA = 'FETCH_DEBT_EXPLORATION_DATA';
export const DEBT_EXPLORER_SET_DATE_CONTEXT = 'DEBT_EXPLORER_SET_DATE_CONTEXT';
export const DEBT_EXPLORER_UPDATE_ROW_DATA = 'DEBT_EXPLORER_UPDATE_ROW_DATA';
export const DEBT_EXPLORER_UPDATE_ACTIVITY_DATES = 'DEBT_EXPLORER_UPDATE_ACTIVITY_DATES';
export const DEBT_EXPLORER_SET_CUSTOM_DATE_RANGE = 'DEBT_EXPLORER_SET_CUSTOM_DATE_RANGE';
export const DE_VIEW_FETCH = 'DE_VIEW_FETCH';
export const DE_BOOKMARK_ADDED = 'DE_BOOKMARK_ADDED';
export const DE_BOOKMARK_UPDATED = 'DE_BOOKMARK_UPDATED';
export const DE_VIEW_DEFAULT_FETCH = 'DE_VIEW_DEFAULT_FETCH';
export const DE_APPLY_VIEW_CHANGES = 'DE_APPLY_VIEW_CHANGES';
export const DE_BOOKMARK_DELETED = 'DE_BOOKMARK_DELETED';
export const DE_VIEWS_FETCHED = 'DE_VIEWS_FETCHED';
export const DE_APPLY_VIEW_FILTERS = 'DE_APPLY_VIEW_FILTERS';

export const DE_QUICK_FILTER_SET = 'DE_QUICK_FILTER_SET';
export const DE_SORT_SET = 'DE_SORT_SET';

export const DE_DATE_CONTEXT_MSG_SET = 'DE_DATE_CONTEXT_MSG_SET';

export const FETCH_WATERFALL_EXPLORATION_DATA = 'FETCH_WATERFALL_EXPLORATION_DATA';

export const DEBT_EXPLORER_SET_FUNDING_VEHICLE_LIST = 'DEBT_EXPLORER_SET_FUNDING_VEHICLE_LIST';

export const DEBT_EXPLORER_SET_STATEMENT_DATE = 'DEBT_EXPLORER_SET_STATEMENT_DATE';
export const RESET_CHANGE_STATE = 'RESET_CHANGE_STATE';
