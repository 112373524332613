import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './counterparties.theme.scss';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames/bind';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import KiMenu from 'components/KiMenu';
import KiDnDList from 'components/KiDnDList';
import {addFundingVehicleSetting, updateFundingVehicleSetting} from '../actions';
const cx = classNames.bind(styles);
import '../index.scss';
import CounterpartiesDeleteModal from './counterpartiesDeleteModal';
import CounterpartiesForm from './counterpartiesForm';

export class Counterparties extends Component {
	static propTypes = {
		counterparties: PropTypes.array,
		history: PropTypes.object,
		match: PropTypes.object,
		dataset: PropTypes.object,
		fundingVehicle: PropTypes.object,
		updateFundingVehicleSetting: PropTypes.func,
		addFundingVehicleSetting: PropTypes.func,
	};

	static defaultProps = {
		counterparties: [],
		waterfallCalcColumns: [],
		match: {
			params: {},
		},
	};

	state = {
		counterparties: [],
	};

	renderCounterpartyItem = counterparty => (
		<div
			key={counterparty._id}
			className={cx('listItem', {
				[styles.listItemSelected]: this.props.match.params.counterpartyId === counterparty._id,
			})}
			onMouseUp={() =>
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/counterparties/${counterparty._id}`
				)
			}
		>
			{counterparty.legalName}
		</div>
	);

	handleSaveCounterparty = counterparty => {
		if (counterparty._id && counterparty._id !== 'create') {
			return this.props.updateFundingVehicleSetting(counterparty).then(() => {
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/counterparties/${counterparty._id}`
				);
			});
		} else {
			return this.props.addFundingVehicleSetting(counterparty).then(() => {
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/counterparties`
				);
			});
		}
	};

	render() {
		const {
			history,
			match = {
				params: {},
			},
		} = this.props;

		const counterparties = this.props.counterparties.filter(
			cp => cp.fundingVehicleId === this.props.fundingVehicle._id
		);

		return (
			<div className={styles.root}>
				<div className={styles.listActions}>
					<i
						className="material-icons"
						onMouseUp={() => {
							history.push(
								`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
									match.params.id
								}/capitalStructure/counterparties/create`
							);
						}}
					>
						add_circle
					</i>

					<KiMenu icon="more_vert" position="topRight">
						<KiMenuItem
							icon="delete"
							disabled={!counterparties.length}
							label="Delete counterparties"
							onClick={() => this.setState({deleteModalActive: true})}
						/>
					</KiMenu>
				</div>
				<div className={styles.list} style={{minHeight: `${(counterparties.length + 1) * 47}px`}}>
					{!counterparties.length && <div className={styles.noRecordsFound}>No counterparties Found</div>}
					<KiDnDList
						items={counterparties.map((counterparty, index) => ({
							id: index,
							content: counterparty,
							idx: index,
						}))}
						contentRenderFunc={this.renderCounterpartyItem}
						listClassName={'debt-list-dnd'}
						itemClassName={'debtItemDnd'}
					/>
				</div>
				<div className={styles.detail}>
					<Route
						path={
							'/datasets/:datasetId/fundingVehicles/:id/capitalStructure/counterparties/:counterpartyId'
						}
						render={() => {
							if (
								this.props.match.params.counterpartyId &&
								this.props.match.params.counterpartyId !== 'create' &&
								!counterparties.find(d => d._id === this.props.match.params.counterpartyId)
							) {
								return (
									//Redirect to the most recent counterparty add to the list
									<Redirect
										to={`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
											match.params.id
										}/capitalStructure/counterparties/${
											counterparties[counterparties.length - 1]._id
										}`}
									/>
								);
							}
							const selectedCounterparty = counterparties.find(
								f => f._id === this.props.match.params.counterpartyId
							);
							return (
								<CounterpartiesForm
									onSave={counterparty => this.handleSaveCounterparty(counterparty)}
									fundingVehicleId={_.get(this.props.fundingVehicle, '_id')}
									datasetId={_.get(this.props.dataset, 'datasetId')}
									counterparty={selectedCounterparty}
									history={this.props.history}
									existingCounterparties={counterparties}
								/>
							);
						}}
					/>
				</div>
				<CounterpartiesDeleteModal
					title="Delete counterparties"
					active={this.state.deleteModalActive}
					counterparties={counterparties}
					onClose={() => this.setState({deleteModalActive: false})}
				/>
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	updateFundingVehicleSetting,
	addFundingVehicleSetting,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Counterparties);
