import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiAppBar from 'components/KiAppBar';
import styles from './calendars.theme.scss';
import {Route, NavLink, Link} from 'react-router-dom';
import CalendarDetail from './components/calendarDetails';
import {fetchCalendars} from './actions';
import {connect} from 'react-redux';
import KiMenu from '../../components/KiMenu';
import KiMenuItem from '../../components/KiMenu/KiMenuItem';

export class Calendars extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		calendars: PropTypes.array,
		dispatch: PropTypes.func,
		history: PropTypes.object,
	};

	static defaultProps = {
		calendars: [],
	};

	state = {
		location: '',
		state: '',
		region: '',
	};

	componentDidMount() {
		this.props.dispatch(fetchCalendars());
		document.title = `${this.props.app.kiVersion} - Holiday Calendars`;
	}

	render() {
		const {calendars} = this.props;
		return (
			<article className={styles.root}>
				<header>
					<KiAppBar className="top-bar">
						<div className="top-bar-breadcrumb">
							<h1 className="link" onClick={() => this.props.history.push('/calendars')}>
								Holiday Calendars
							</h1>
						</div>
						<Link to={'/calendars/new'}>
							<i className={'material-icons'} title="Click to create a new holiday calendar">
								add_circle
							</i>
						</Link>
					</KiAppBar>
				</header>
				<section className={styles.body}>
					<div className={styles.listActions}>
						<i className="material-icons" onMouseUp={() => history.push(`/calendars/new`)}>
							add_circle
						</i>

						<KiMenu icon="more_vert" position="topRight">
							<KiMenuItem
								icon="delete"
								disabled={!this.props.calendars.length}
								label="Delete Records..."
								onClick={() => this.setState({deleteModalActive: true})}
							/>
						</KiMenu>
					</div>
					<section className={styles.tempBody}>
						<div className={styles.list}>
							<div className={styles.listMain}>
								{!calendars.length && <div className={styles.noRecordsFound}>No Records Found</div>}
								{calendars.map(calendar => (
									<NavLink
										className={styles.listItem}
										activeClassName={styles.listItemSelected}
										key={calendar._id}
										to={`/calendars/${calendar._id}`}
									>
										{calendar.name}
									</NavLink>
								))}
							</div>
						</div>
						<Route path={'/calendars/:id'} component={CalendarDetail} />
					</section>
				</section>
				<footer />
			</article>
		);
	}
}

const mapStateToProps = state => {
	return {
		app: state.app,
		calendars: state.calendars.calendars,
	};
};

export default connect(mapStateToProps)(Calendars);
