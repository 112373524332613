import PropTypes from 'prop-types';
import React from 'react';
import styles from './KiMenuItem.theme.scss';
import KiFontIcon from 'components/KiFontIcon';
import cn from 'classnames';

export const KiMenuItem = ({
	className,
	icon,
	iconClassName,
	label,
	labelClassName,
	onClick,
	disabled,
	onSelect,
	isHidden,
}) => {
	const handleClick = event => {
		if (onClick && !disabled) {
			onClick(event);
			onSelect(event);
		}
	};

	if (isHidden) return null;

	const rootClassName = cn(
		styles.root,
		{
			[styles.disabled]: disabled,
		},
		className
	);

	return (
		<li className={rootClassName} onClick={handleClick}>
			{icon ? <KiFontIcon value={icon} className={cn(styles.icon, iconClassName)} /> : null}
			{label ? <span className={cn(styles.label, labelClassName)}>{label}</span> : null}
		</li>
	);
};

KiMenuItem.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	iconClassName: PropTypes.string,
	label: PropTypes.string,
	labelClassName: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	onSelect: PropTypes.func,
	isHidden: PropTypes.bool,
};

KiMenuItem.defaultProps = {
	className: '',
	label: '',
	disabled: false,
};

export default KiMenuItem;
