// Global import
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import _has from 'lodash/has';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {getCardTypeOptionBySettingType} from 'ki-common/utils/cardUtil';

// Project imports
import {fundingVehiclesApi, datasetDatesApi, cardApi} from 'api';
import {fetchBookmarks as fetchFundingAnalysisBookmarks} from 'api/fundingAnalysisApi';
import {getLockedProjectionScenarios} from 'api/waterfallApi';
import {fetchViewsByDataset} from 'components/FlyoutManageViews/actions';
import {showSnackbar} from 'state/actions/Snackbar';
import {setCurrentCard} from 'containers/dashboards/actions';
import {fetchBookmarks as fetchDebtBookmarks} from 'containers/debt/actions';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import KiModal from 'components/KiModal';
import KiCard from 'components/KiCard';
import KiButton from 'components/KiButton';

// Local imports
import CardSettings from './components/CardSettings';
import CardCreate from './components/CardCreate';
import styles from './KiDataCardEditor.theme.scss';

export class KiDataCardEditor extends Component {
	static propTypes = {
		show: PropTypes.bool,
		allCards: PropTypes.array,
		isAdmin: PropTypes.bool,
		currentCard: PropTypes.object,
		setCurrentCard: PropTypes.func,
		user: PropTypes.object,
		datasetId: PropTypes.string.isRequired,
		fetchViewsByDataset: PropTypes.func.isRequired,
		fetchDebtBookmarks: PropTypes.func.isRequired,
		explorerViews: PropTypes.array,
		debtViews: PropTypes.array,
		addCardToReport: PropTypes.func,
		explorerView: PropTypes.string,
		debtView: PropTypes.string,
		onClose: PropTypes.func.isRequired,
		activeTab: PropTypes.number,
		reportOutputType: PropTypes.string,
	};

	state = {
		fundingVehicles: [],
		dateContextList: [],
		modalState: 'edit', // edit or create
		currentCardMetaData: {},
		fundingAnalysisViews: [],
		projectionScenarios: [],
	};

	componentDidMount() {
		this.props.fetchViewsByDataset(this.props.user.userId, this.props.datasetId, this.props.isAdmin);
		fundingVehiclesApi.fetchFundingVehiclesByDatasetId(this.props.datasetId).then(fvs => {
			this.setState({fundingVehicles: fvs});
		});
		this.props.fetchDebtBookmarks(this.props.datasetId);
		datasetDatesApi.fetchDates(this.props.datasetId).then(dates => {
			this.setState({dateContextList: dates});
		});
		fetchFundingAnalysisBookmarks(this.props.datasetId).then(fundingAnalysisViews => {
			this.setState({fundingAnalysisViews});
		});
		getLockedProjectionScenarios(this.props.datasetId).then(projectionScenarios => {
			this.setState({projectionScenarios});
		});
	}

	componentDidUpdate(prevProps) {
		const {user, datasetId, isAdmin, fetchViewsByDataset, currentCard} = this.props;
		if (datasetId !== prevProps.datasetId) {
			fetchViewsByDataset(user.userId, datasetId, isAdmin);
		}
		if (currentCard) {
			if (this.state.modalState !== 'edit') {
				this.setState({
					modalState: 'edit',
				});
			}
			if (_get(this.props, 'currentCard._id') !== _get(prevProps, 'currentCard._id')) {
				cardApi.fetchCardMetaData(currentCard, datasetId).then(metaData => {
					this.setState({
						currentCardMetaData: metaData,
					});
				});
			}
		} else if (this.state.modalState !== 'create') {
			this.setState({
				modalState: 'create',
			});
		}
	}

	addCard = card => {
		this.props.setCurrentCard(card);
	};

	onClickAddCardToReport = () => {
		this.props.addCardToReport(this.props.currentCard._id, this.props.currentCard.name);
		this.props.onClose();
	};

	render() {
		const {show} = this.props;
		const {modalState} = this.state;
		const isCreate = _get(this.props, 'currentCard._id') ? false : true;
		const cardTitle = isCreate
			? 'Create Card'
			: `${this.props.currentCard.name} (${
					getCardTypeOptionBySettingType(this.props.currentCard.settings.type).label
			  })`;

		const activeTab = isCreate ? 1 : this.props.activeTab;
		const viewUrl = card => {
			const pathName = location.pathname;
			const debtPath = card.settings.debtBookmarkId
				? `/datasets/${card.datasetId}/debt?bookmarkId=${card.settings.debtBookmarkId}`
				: `/datasets/${card.datasetId}/debt`;
			const assetPath = `/dataExplorer/${card.datasetId}?bookmarkId=${card.bookmarkId}`;
			const fundingAnalysisPath = `/fundingAnalysis/${card.datasetId}/bookmark/${
				card.settings.fundingAnalysisBookmarkId
			}`;
			switch (card.settings.type) {
				case 'debtView':
					return debtPath.includes(pathName) ? '' : debtPath;
				case 'fundingAnalysisView':
					return fundingAnalysisPath.includes(pathName) ? '' : fundingAnalysisPath;
				default:
					return assetPath.includes(pathName) ? '' : assetPath;
			}
		};

		return (
			<KiModal
				active={show}
				header={cardTitle}
				className="ki-modal"
				bodyClassName={styles.modalBody}
				onClose={this.props.onClose}
				showCloseIcon={true}
			>
				{modalState === 'create' && (
					<CardCreate
						datasetId={this.props.datasetId}
						user={this.props.user}
						addCard={this.addCard}
						closeModal={this.props.onClose}
						explorerViews={this.props.explorerViews}
						explorerView={this.props.explorerView}
						debtViews={this.props.debtViews}
						debtView={this.props.debtView}
						fundingAnalysisViews={this.state.fundingAnalysisViews}
						projectionScenarios={this.state.projectionScenarios}
					/>
				)}
				{modalState === 'edit' && (
					<KiTabs index={activeTab}>
						{!isCreate && (
							<KiTab title="Preview">
								<KiCard
									fundingVehicles={this.state.fundingVehicles}
									scenarioList={this.state.currentCardMetaData.scenarioList}
									card={this.props.currentCard}
								/>
								{this.props.addCardToReport && (
									<div className={styles.buttonContainer}>
										<KiButton
											flat
											primary
											type="submit"
											label="Add card to report"
											onClick={this.onClickAddCardToReport}
										/>
									</div>
								)}
							</KiTab>
						)}
						{this.props.isAdmin &&
							this.props.currentCard && (
								<KiTab title="Settings">
									<CardSettings
										card={this.props.currentCard}
										existingTags={Array.from(
											new Set(
												this.props.allCards.reduce((acc, card) => {
													if (card.tags && card.tags.length) {
														return [...acc, ...card.tags];
													}
													return acc;
												}, [])
											)
										)}
										closeModal={this.props.onClose}
										isDeleteDisabled={isCreate}
										reportOutputType={this.props.reportOutputType}
									/>
									{!isCreate &&
										!['projections', 'aggregatedPayment'].includes(
											_get(this.props.currentCard, 'settings.type')
										) &&
										viewUrl(this.props.currentCard) && (
											<Link
												to={viewUrl(this.props.currentCard)}
												className={styles.viewLink}
												target="_blank"
												rel="noopener noreferrer"
											>
												Go to view
											</Link>
										)}
								</KiTab>
							)}
					</KiTabs>
				)}
			</KiModal>
		);
	}
}

const mapStateToProps = state => ({
	allCards: state.dashboards.allCards,
	isAdmin: state.user.groups.includes('SystemAdmins'),
	currentCard: state.dashboards.currentCard,
	user: state.user,
	explorerViews: _get(state, 'dataBookmarks.allBookmarks', []).filter(view => !view.isGadget),
	debtViews: (state.debtExploration.bookmarks || []).filter(bookmark => _has(bookmark, 'viewColumns')),
});

const mapDispatchToProps = {
	setCurrentCard,
	fetchViewsByDataset,
	fetchDebtBookmarks,
	showSnackbar,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(KiDataCardEditor));
