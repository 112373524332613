import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './AsOfDateColumns.theme.scss';
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import {connect} from 'react-redux';
import {datasetsApi, columnServiceApi} from 'api';
import {getSortAscFuncByField} from 'ki-common/utils/sorters';
import stringHelpers from 'ki-common/utils/stringHelpers';
import {fetchDates} from 'components/FlyoutDates/actions';
import uuidV4 from 'uuid/v4';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import {Link} from 'react-router-dom';

export class AddAsOfDateColumns extends Component {
	static propTypes = {
		onExit: PropTypes.func.isRequired,
		datasetId: PropTypes.string.isRequired,
		assetColumns: PropTypes.array.isRequired,
		dateColumns: PropTypes.array,
		fetchDates: PropTypes.func,
		fetchDataset: PropTypes.func,
		column: PropTypes.object,
		columns: PropTypes.array,
	};

	static defaultProps = {
		dateColumns: [],
		assetColumns: [],
		datasetDates: [],
	};

	state = {
		selectedColumn:
			this.props.assetColumns.find(col => col._id === _get(this.props, 'column.assetColumnId')) || null,
		dateType: _get(this.props, 'column.asOfDateType') || null,
		displayName: _get(this.props, 'column.displayName') || '',
		offset: _get(this.props, 'column.offset') || 0,
	};

	componentDidMount() {
		this.props.fetchDates(this.props.datasetId);
	}

	componentDidUpdate(prevProps) {
		if (this.props.datasetId && this.props.datasetId !== prevProps.datasetId) {
			this.props.fetchDates(prevProps.datasetId);
		}
	}

	generateDisplayName = (column, dateType) => {
		if (!column || !dateType) {
			return '';
		}
		const dateTypeOption = this.props.dateColumns.find(c => c.columnName === dateType);
		return `${column.displayName || column.columnName} For ${dateTypeOption.displayName}`;
	};

	searchByDisplayName = (option, searchText = '') => {
		return option.data.displayName.toLowerCase().includes(searchText.toLowerCase());
	};

	handleSave = async e => {
		e.preventDefault();
		e.stopPropagation();

		if (!this.state.displayName.match(stringHelpers.alphanumericRegex)) {
			this.setState({nameError: 'Name must be Alphanumeric!', isSaving: false});
			return;
		}
		if (
			this.props.columns.find(
				column => column.displayName === this.state.displayName && column._id !== this.props.column?._id
			)
		) {
			this.setState({nameError: 'Name must be unique', isSaving: false});
			return;
		}

		this.setState({saveDisabled: true, nameError: null});

		let columnToSave;
		if (this.props.column) {
			columnToSave = Object.assign(_cloneDeep(this.props.column), {
				assetColumnId: this.state.selectedColumn._id,
				asOfDateType: this.state.dateType,
				displayName: this.state.displayName,
				offset: Number(this.state.offset),
			});
		} else {
			columnToSave = Object.assign({}, this.state.selectedColumn, {
				_id: undefined,
				columnName: `ki_${uuidV4().replace(/-/g, '')}`, // spark doesn't like dashes as col names
				assetColumnId: this.state.selectedColumn._id,
				asOfDateType: this.state.dateType,
				displayName: this.state.displayName,
				offset: Number(this.state.offset),
			});
		}
		delete columnToSave.formula;
		delete columnToSave.assetColumn; // if editing this may be hydrated but no need to persist

		if (!columnToSave) {
			this.setState({saveDisabled: false});
			return Promise.reject('No column found');
		}
		try {
			await datasetsApi.upsertColumns([columnToSave], this.props.datasetId);
			await this.props.fetchDataset(this.props.datasetId);
			this.setState({saveDisabled: false});
			await this.props.onExit();
		} catch (err) {
			this.setState({saveDisabled: false});
		}
	};

	changeSelectedColumn = columnId => {
		columnServiceApi.getColumnByIdFromService(this.props.datasetId, columnId, false).then(col => {
			delete col.detailedDisplayName;
			delete col.htmlDisplayName;
			this.setState({
				selectedColumn: col,
				displayName: this.state.displayName || this.generateDisplayName(col, this.state.dateType),
			});
		});
	};

	render() {
		const {onExit, assetColumns, dateColumns} = this.props;

		return (
			<div className={styles.addView}>
				<div className={styles.addViewBody}>
					<KiInput
						name="name"
						label="Display Name"
						type="text"
						value={this.state.displayName}
						error={this.state.nameError}
						onChange={val => this.setState({displayName: val, nameError: null})}
					/>
					<div className={styles.formRow}>
						<p>Column</p>
						<KiSelect
							name={'selectedColumn'}
							value={this.state.selectedColumn}
							defaultValue={[]}
							getOptionValue={o => o._id}
							getOptionLabel={o => o.displayName}
							isMulti={false}
							placeholder="Select Column"
							onChange={col => this.changeSelectedColumn(col._id)}
							options={assetColumns}
							isOptionDisabled={() => false}
						/>
					</div>
					<div className={styles.formRow}>
						<p>As Of Date</p>
						<KiSelect
							name="dateType"
							placeholder="Select Date Type..."
							getOptionValue={o => o.columnName}
							getOptionLabel={o => (
								<span>
									{o.displayName}
									{!o.dsDateMapId && (
										<Link
											style={{color: '#CCC', textDecoration: 'underline'}}
											target={'_blank'}
											to={`/mappings/${o.datasetId}/dates`}
										>
											&nbsp;(Unmapped)
										</Link>
									)}
								</span>
							)}
							filterOption={this.searchByDisplayName}
							value={dateColumns.find(col => col.columnName === this.state.dateType)}
							isOptionDisabled={dateColumn => !dateColumn.dsDateMapId}
							onChange={option => {
								this.setState({
									dateType: option.columnName,
									displayName:
										this.state.displayName ||
										this.generateDisplayName(this.state.selectedColumn, option.columnName),
								});
							}}
							options={dateColumns}
						/>
					</div>
					<KiInput
						name="offset"
						label="Offset"
						type="number"
						value={this.state.offset || 0}
						onChange={val => this.setState({offset: val})}
					/>
				</div>
				<footer>
					<KiButton flat label={'Cancel'} onMouseUp={onExit} />
					<KiButton
						disabled={
							this.state.saveDisabled ||
							!this.state.selectedColumn ||
							!this.state.dateType ||
							!this.state.displayName
						}
						raised
						onMouseUp={this.handleSave}
						primary
						label="Save"
					/>
				</footer>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		datasetDates: state.datasetDates.dates.filter(x => x.datasetId === ownProps.datasetId),
		dateColumns: state.datasetList.columnList.filter(
			col =>
				col.columnType === 'asset' &&
				(!!col.dsDateMapId ||
					['kiCollectionEndDate', 'kiReportDate', 'kiDistributionDate', 'kiDeterminationDate'].includes(
						col.columnName
					))
		),
		assetColumns: state.datasetList.columnList
			.filter(col => {
				return (
					col.columnType === 'asset' &&
					!col.asOfDateType &&
					col.columnFormType !== 'cumulative' &&
					col.columnName.indexOf('ki') !== 0 &&
					!['EligibilityStatus', 'IneligibilityReasonCode', 'Total'].includes(col.columnName)
				);
			})
			.slice()
			.sort(getSortAscFuncByField('displayName')),
		datasetId: state.datasetList.selected.datasetId,
	};
};

const mapDispatchToProps = {
	fetchDates,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAsOfDateColumns);
