import PropTypes from 'prop-types';
import React from 'react';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';
import './quickFilter.scss';

export const QuickFilter = props => {
	const params = {
		options: props.options,
		targetFunction: props.targetFunction,
		className: 'quick-filter-select',
		startingValue: props.selectedValue,
		searchable: true,
		backspaceRemoves: false,
		disabled: props.disabled,
	};

	return (
		<div className={`select-wrapper ${props.className}`}>
			{props.header && <h5>{props.header}</h5>}
			<span className="theme-label">{props.title}</span>
			<TriggerFunctionSelector {...params} />
		</div>
	);
};

QuickFilter.propTypes = {
	selectedValue: PropTypes.string,
	targetFunction: PropTypes.func,
	title: PropTypes.string,
	options: PropTypes.array,
	className: PropTypes.string,
	header: PropTypes.string,
	disabled: PropTypes.bool,
};

export default QuickFilter;
