// Dependencies
import React, {Component} from 'react';

class TestStepTwo extends Component {
	render() {
		return <div className="wrapper">This is step two</div>;
	}
}

export default TestStepTwo;
