// Lib Imports
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';
import _ from 'lodash';
// App Imports
import {format} from 'ki-common/utils/displayFormatter';
import * as api from 'api/waterfallMappingApi';
import KiFontIcon from 'components/KiFontIcon';
import KiTooltip from 'components/KiTooltip';
import {fetchDates} from 'components/FlyoutDates/actions';
import {showSnackbar} from 'state/actions/Snackbar';
// Local Import
import dateMapListDefaults from '../dateMapList';
import '../mappings.scss';

export class DateMappings extends Component {
	static propTypes = {
		user: PropTypes.object,
		datasetId: PropTypes.string.isRequired,
		dates: PropTypes.array,
		kiVersion: PropTypes.string,
		fetchDates: PropTypes.func,
		showSnackbar: PropTypes.func,
	};

	static defaultProps = {
		kiVersion: 'Ki',
		dates: [],
	};

	state = {
		currentMappings: [],
		savedMappings: [],
		options: [],
	};

	componentDidMount() {
		document.title = `${this.props.kiVersion} - Dataset Date Mappings`;
		const promises = [
			api.fetchMappingsByDataset(this.props.datasetId),
			this.props.fetchDates(this.props.datasetId),
		];

		return Promise.all(promises).then(results => {
			const dateMapList = _.cloneDeep(dateMapListDefaults);
			const dateMappings = results[0].filter(m => m.type === 'date');
			dateMappings.forEach(mapping => {
				const index = dateMapList.findIndex(m => m.name === mapping.name);
				if (index > -1) {
					dateMapList[index] = mapping;
				}
			});
			this.setState(
				{
					currentMappings: dateMapList,
					savedMappings: _cloneDeep(dateMapList),
				},
				this.setOptionsFromProps
			);
		});
	}

	componentDidUpdate(prevProps) {
		document.title = `${this.props.kiVersion} - Dataset Date Mappings`;
		if (!_.isEqual(this.props.dates, prevProps.dates)) {
			this.setOptionsFromProps();
		}
	}

	setOptionsFromProps = () => {
		const options = this.props.dates.map(date => ({
			label: date.name,
			value: date.groupId,
		}));
		this.setState({options: _uniqBy(options, 'label')});
	};

	setDefault = (val, index) => {
		const updatedMappings = [...this.state.currentMappings];
		updatedMappings[index].defaultValue = val;
		updatedMappings[index].isDirty = true;
		this.setState({currentMappings: updatedMappings});
	};

	handleSave = index => {
		const {user, datasetId} = this.props;
		const mappingToSave = {...this.state.currentMappings[index]};
		delete mappingToSave.isDirty;
		mappingToSave.datasetId = datasetId;
		mappingToSave.type = 'date';
		if (this.state.currentMappings[index]._id) {
			if (mappingToSave._id === 'new') {
				mappingToSave.createdDate = new Date();
				mappingToSave.createdBy = user.userId;
				delete mappingToSave._id;
			}
			mappingToSave.modifiedDate = new Date();
			mappingToSave.modifiedBy = user.userId;
			return api.addDatasetMapping(mappingToSave).then(() => {
				this.props.showSnackbar('Mapping saved successfully.');
				const mappings = [...this.state.currentMappings];
				mappings[index] = mappingToSave;
				this.setState({currentMappings: mappings});
			});
		} else {
			mappingToSave.createdDate = new Date();
			mappingToSave.createdBy = user.userId;
			return api.addDatasetMapping(mappingToSave).then(res => {
				this.props.showSnackbar('Mapping saved successfully.');
				mappingToSave._id = res.upserted[0]._id;
				const mappings = [...this.state.currentMappings];
				mappings[index] = mappingToSave;
				this.setState({currentMappings: mappings});
			});
		}
	};

	handleUndo = index => {
		const {currentMappings, savedMappings} = this.state;
		const updatedMappings = [...currentMappings];
		updatedMappings[index] = {...savedMappings[index]};
		delete updatedMappings[index].isDirty;
		this.setState({currentMappings: updatedMappings});
	};

	getDateMapInfo = (mapping = {}) => {
		if (mapping && mapping.info !== 'TBD') {
			return mapping.info;
		}
		switch (mapping && mapping.name) {
			case 'determination':
			case 'distribution':
				return 'Must be mapped in order to blend data in Ki for warehouse reporting.';
			case 'collectionEnd':
			case 'report':
				return 'Must be mapped in order to use debt exploration.';
			default:
				return null;
		}
	};

	render() {
		const {currentMappings, options} = this.state;

		return (
			<section>
				<table className="dataset-mapping-list">
					<thead>
						<tr>
							<th>
								<p>SYSTEM DATES</p>
							</th>
							<th>
								<p>CALENDAR DATE</p>
							</th>
							<th>
								<p>MAPPED</p>
							</th>
							<th style={{width: '100%'}}>
								<p>LAST UPDATED</p>
							</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{currentMappings.map((mapping, index) => {
							return (
								<tr key={index}>
									<td style={{display: 'flex', alignItems: 'center', textAlign: 'left'}}>
										{mapping.displayName}&nbsp;
										<KiTooltip
											className="collateral-map-tooltip"
											message={this.getDateMapInfo(mapping)}
										>
											<i alt="Calculation" className="material-icons">
												info
											</i>
										</KiTooltip>
									</td>
									<td>
										<Select
											classNamePrefix="aut-select"
											getOptionLabel={option => option.label}
											getOptionValue={option => option.value}
											isClearable={true}
											options={options}
											value={
												options.find(option => {
													return option.value === mapping.defaultValue;
												}) || null
											}
											className="ki-form-select-horizontal"
											onChange={option => this.setDefault(_get(option, 'value', null), index)}
										/>
									</td>
									<td>
										<p>
											{mapping.createdBy || ''} {format(mapping.createdDate, 'YYYY-MM-DD')}
										</p>
									</td>
									<td>
										<p>
											{mapping.modifiedBy || ''} {format(mapping.modifiedDate, 'YYYY-MM-DD')}
										</p>
									</td>
									<td style={{verticalAlign: 'middle'}}>
										<KiFontIcon
											value="undo"
											onClick={!mapping.isDirty ? () => void 0 : () => this.handleUndo(index)}
											className={`icon ki-icon-rollover ${
												!mapping.isDirty ? 'ki-icon-disabled' : ''
											}`}
										/>
										<KiFontIcon
											value="save"
											onClick={!mapping.isDirty ? () => void 0 : () => this.handleSave(index)}
											className={`icon ki-icon-rollover ${
												!mapping.isDirty ? 'ki-icon-disabled' : ''
											}`}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	dates: state.datasetDates.dates,
	kiVersion: state.app.kiVersion,
});

const mapDispatchToProps = dispatch => ({
	showSnackbar: msg => dispatch(showSnackbar(msg)),
	fetchDates: datasetId => dispatch(fetchDates(datasetId)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DateMappings);
