// Global Imports
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import _ from 'lodash';

// Project Imports
import validators from 'ki-common/validators';
import options from 'ki-common/options';

// Local Imports
import {fetchScenarios} from 'api/fundingAnalysisApi';
import KiConfirmModal from 'components/KiConfirmModal';
import KiInput from 'components/KiInput';
import KiIconButton from 'components/KiIconButton';
import LogicDropdown from './LogicDropdown';
import TargetCell from './TargetCell';
import DataColumnDropdown from './DataColumnDropdown';
import './DatasetFilter.scss';

export class DatsetFilter extends Component {
	static propTypes = {
		datasetId: PropTypes.string,
		existingFilter: PropTypes.object,
		existingNames: PropTypes.array,
		cohortColumns: PropTypes.array,
		insertMethod: PropTypes.func,
		updateMethod: PropTypes.func,
		deleteMethod: PropTypes.func,
	};

	static defaultProps = {
		cohortColumns: [],
	};

	constructor(props) {
		super(props);
		// in lieu of calling set formvalues
		this.state = {
			formValues: {
				name: props.existingFilter.name || '',
				dataColumn: props.existingFilter.dataColumn || '',
				logic: props.existingFilter.logic || '=',
				target: props.existingFilter.target || [],
				selection: props.existingFilter.selection || '',
			},
			formErrors: {},
			showButtons: false,
			submitDisabled: true,
			showDeleteConfirm: false,
			scenarioInvalidationWarningModalActive: false,
			scenarioInvalidationFn: () => null,
		};
	}

	componentDidUpdate(prevProps) {
		if (!_.isEqual(this.props.existingFilter, prevProps.existingFilter)) {
			this.setFormValues(this.props.existingFilter);
		}
	}

	getConstraints = name => {
		const constraints = validators.datasetsCriteria.getConstraints();
		const dataColumn = this.state.formValues.dataColumn
			? this.props.cohortColumns.find(c => c._id === this.state.formValues.dataColumn)
			: {};
		constraints.target.targetArray = {
			dataType: dataColumn.dataType,
		};
		if (!name) {
			return constraints;
		}
		if (constraints['name']) {
			return constraints[name];
		}
		return {};
	};

	getFormType = () => (this.props.existingFilter && this.props.existingFilter._id ? 'update' : 'insert');

	// Form logic
	setFormValues = formValues => {
		this.setState({
			formValues: {
				name: formValues.name || '',
				dataColumn: formValues.dataColumn || '',
				logic: formValues.logic || '=',
				target: formValues.target || [],
				selection: formValues.selection || '',
			},
			formErrors: {},
			showButtons: false,
			submitDisabled: true,
		});
	};

	validateFields = () => {
		const constraints = this.getConstraints();
		let validations = validators.validate(this.state.formValues, constraints);

		if (
			this.state.formValues.name !== this.props.existingFilter.name &&
			this.props.existingNames.includes(this.state.formValues.name)
		) {
			validations = {...validations, name: ['Name must be unique']};
		}

		this.setState(state => {
			const nextState = {
				...state,
				submitDisabled: !!validations,
			};
			if (state.formErrors.target && ['is_null', 'is_not_null'].includes(state.formValues.logic)) {
				delete state.formErrors.target;
			}
			return nextState;
		});
		return !validations;
	};

	validateField = (name, value) => {
		let error = validators.validateSingle(value, this.getConstraints(name));

		// If no other errors, and is name field, and has changed from original value
		if (name === 'name' && !error && value !== this.props.existingFilter.name) {
			error = this.props.existingNames.includes(value) ? ['Name must be unique'] : error;
		}

		this.setState(state => {
			const formErrors = {...state.formErrors};
			if (error && error[0]) {
				formErrors[name] = error[0];
			} else if (formErrors['name']) {
				delete formErrors[name];
			}
			return {
				...state,
				formErrors,
			};
		});
		return error;
	};

	valueBlurred = e => this.validateField(e.target.name, e.target.value);

	valueChanged = (name, value) => {
		this.setState(
			state => {
				const nextState = {
					...state,
					formValues: {
						...state.formValues,
						[name]: value,
					},
					showButtons: true,
				};
				if (name === 'logic') {
					nextState.formValues.target = ['is_null', 'is_not_null'].includes(value) ? [''] : '';
				}
				return nextState;
			},
			() => {
				this.validateField(name, value);
				this.validateFields();
			}
		);
	};

	/**
	 * onFieldError
	 * @param  {string} name  Name of field
	 * @param  {string} error Related error message
	 */
	onFieldError = (name, error) => {
		this.setState(state => {
			const formErrors = {...state.formErrors};
			if (error) {
				formErrors[name] = error;
			} else if (formErrors['name']) {
				delete formErrors[name];
			}
			return {
				...state,
				// Disable submit if an field has an error
				submitDisabled: error ? true : false,
				formErrors,
			};
		});
	};

	resetForm = () => this.setFormValues(this.props.existingFilter);

	submit = e => {
		e.preventDefault();
		const isValid = this.validateFields();
		if (isValid) {
			const formValues = {...this.props.existingFilter, ...this.state.formValues};
			return fetchScenarios(this.props.datasetId).then((allScenarios = []) => {
				// Get only the scenarios that could be affected
				const scenarios = allScenarios.filter(scenario =>
					['hypo', 'submitted', 'approved'].includes(scenario.status)
				);
				if (scenarios.length) {
					this.setState({
						scenarioInvalidationWarningModalActive: true,
						scenarioInvalidationFn: () => {
							if (this.getFormType() === 'insert') {
								formValues.datasetId = this.props.datasetId;
								return this.props.insertMethod(formValues).then(insertResult => {
									this.setState({
										scenarioInvalidationWarningModalActive: false,
										scenarioInvalidationFn: () => null,
										showButtons: false,
									});
									return insertResult;
								});
							} else {
								return this.props.updateMethod(formValues).then(updateResult => {
									this.setState({
										scenarioInvalidationWarningModalActive: false,
										scenarioInvalidationFn: () => null,
										showButtons: false,
									});
									return updateResult;
								});
							}
						},
					});
				} else {
					if (this.getFormType() === 'insert') {
						formValues.datasetId = this.props.datasetId;
						this.props.insertMethod(formValues);
					} else {
						this.props.updateMethod(formValues);
					}
					this.setState({
						showButtons: false,
					});
				}
			});
		}
	};

	showDeleteConfirmToggle = () => {
		this.setState({showDeleteConfirm: !this.state.showDeleteConfirm});
	};

	handleDeleteConfirm = () => {
		return this.props
			.deleteMethod(this.props.existingFilter._id.toString())
			.then(() => this.setState({showDeleteConfirm: false}));
	};

	render() {
		// console.log('DatsetFilter.props', this.props);
		// console.log('DatsetFilter.state', this.state);
		return (
			<form className="dataset-filter-form cells" onSubmit={this.submit}>
				<div className="double">
					<KiInput
						type="text"
						name="name"
						error={this.state.formErrors.name}
						value={this.state.formValues.name}
						onChange={val => this.valueChanged('name', val)}
						onBlur={this.valueBlurred}
						maxLength={100}
						label="Name"
					/>
				</div>
				<div className="select-wrapper double">
					<DataColumnDropdown
						value={this.state.formValues.dataColumn}
						onChange={val => this.valueChanged('dataColumn', val)}
						cohortColumns={this.props.cohortColumns}
						dataTypeFilter={this.isValidDataType}
					/>
				</div>
				<div className="select-wrapper double cell-logic">
					<LogicDropdown
						onChange={val => this.valueChanged('logic', val)}
						options={options.logic}
						limitStringOptions={true}
						dataColumn={
							!!this.state.formValues.dataColumn &&
							this.props.cohortColumns.find(c => c._id === this.state.formValues.dataColumn)
						}
						value={this.state.formValues.logic}
					/>
				</div>
				<TargetCell
					logic={this.state.formValues.logic}
					dataColumn={
						!!this.state.formValues.dataColumn &&
						this.props.cohortColumns.find(c => c._id === this.state.formValues.dataColumn)
					}
					onChange={val => this.valueChanged('target', val)}
					onError={err => this.onFieldError('target', err)}
					value={this.state.formValues.target}
				/>
				<div className="setting-save">
					{this.state.showButtons && <KiIconButton icon="undo" onClick={this.resetForm} />}
					{this.state.showButtons && (
						<KiIconButton
							icon={this.getFormType() === 'update' ? 'save' : 'add'}
							disabled={this.state.submitDisabled}
							onClick={this.submit}
						/>
					)}
					{!this.state.showButtons && this.getFormType() === 'update' && (
						<KiIconButton icon="delete" onClick={this.showDeleteConfirmToggle} />
					)}
				</div>
				<KiConfirmModal
					header="Delete Ineligibility Critieria"
					message={`Are you sure you want to delete the criteria "${this.props.existingFilter.name}"?`}
					acceptFunc={() => {
						return fetchScenarios(this.props.datasetId).then((allScenarios = []) => {
							// Get only the scenarios that could be affected
							const scenarios = allScenarios.filter(scenario =>
								['hypo', 'submitted', 'approved'].includes(scenario.status)
							);
							if (scenarios.length) {
								this.setState({
									scenarioInvalidationWarningModalActive: true,
									scenarioInvalidationFn: () =>
										this.handleDeleteConfirm().then(() => {
											this.setState({
												scenarioInvalidationWarningModalActive: false,
												scenarioInvalidationFn: () => null,
												showDeleteConfirm: false,
											});
										}),
								});
							} else {
								return this.handleDeleteConfirm();
							}
						});
					}}
					rejectFunc={this.showDeleteConfirmToggle}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.showDeleteConfirm}
				/>
				<KiConfirmModal
					message="This action will make the scenario status invalid. Do you want to continue?"
					active={this.state.scenarioInvalidationWarningModalActive}
					acceptLabel="Confirm"
					rejectLabel="Cancel"
					acceptFunc={this.state.scenarioInvalidationFn}
					rejectFunc={() => {
						this.setState({
							scenarioInvalidationWarningModalActive: false,
							scenarioInvalidationFn: () => null,
						});
					}}
					className="ki-modal scenario-fv-selector-invalidation-modal"
				/>
			</form>
		);
	}
}

export default DatsetFilter;
