// Globals
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports
import KiModal2 from 'components/KiModal2';

// Local imports
import styles from './logInformationOverlay.theme.scss';

const getFileTypeDisplayName = (fileType = '') => {
	switch (fileType.toLowerCase()) {
		case 'peer':
			return 'Peer File';
		case 'child':
			return 'Child File';
		case 'payment':
			return 'Payment File';
		case 'subaccount':
			return 'Subaccount File ';
		default:
			return 'Submission File';
	}
};

function LogInformationOverlay({isActive, setInformationOverlayActive, statusData, detailData = {}}) {
	// Filter the entity options based on the selectedFV
	useEffect(
		() => {
			// console.log(isActive, ' isActive');
			// console.log(messageData, ' messageData');
		},
		[isActive]
	);

	const fileName = _.get(detailData, 'fileName', null);
	const fileType = getFileTypeDisplayName(_.get(detailData, 'fileType', ''));
	const messages = _.get(detailData, 'loadWarnings', null);

	const {reportName, scenarioContext, outputType} = detailData;

	return (
		<KiModal2
			active={isActive}
			actions={[{label: 'ok', onClick: () => setInformationOverlayActive(false)}]}
			panelStyles={{minHeight: '40%', maxHeight: '40%', minWidth: '60%', maxWidth: '60%'}}
			className={styles.LogInformationOverlay}
			header={'Log Details:'}
		>
			{fileName && (
				<div>
					<p>{`${fileName} (${fileType})`}</p>
					<br />
				</div>
			)}
			{reportName && (
				<div>
					<p>{`Report Name: ${reportName}`}</p>
					<p>{`Scenario Context: ${scenarioContext}`}</p>
					<p>{`Output Type: ${outputType}`}</p>
					<br />
				</div>
			)}

			<div className={styles.logInformationOverlay}>
				{statusData.successMessage &&
					statusData.successMessage.length && (
						<div className={styles.message}>
							<span style={{color: 'green'}} className="material-icons">
								info
							</span>
							<p>{statusData.successMessage}</p>
						</div>
					)}
				{statusData.progressMessage &&
					statusData.progressMessage.length && (
						<div className={styles.message}>
							<span style={{color: '#faa30c'}} className="material-icons">
								warning
							</span>
							<p>{statusData.progressMessage}</p>
						</div>
					)}
				{statusData.warningMessage &&
					statusData.warningMessage.length && (
						<div className={styles.message}>
							<span style={{color: '#f36f2b'}} className="material-icons">
								error
							</span>
							<p>{statusData.warningMessage}</p>
						</div>
					)}
				{statusData.errorMessage &&
					statusData.errorMessage.length && (
						<div className={styles.message}>
							<span style={{color: 'red'}} className="material-icons">
								error
							</span>
							<p>{statusData.errorMessage}</p>
						</div>
					)}
			</div>
			{messages &&
				Array.isArray(messages) &&
				messages.length > 0 && (
					<div className={styles.loadMessagesOuter}>
						<p>Load Messages:</p>
						<div className={styles.logInformationOverlay}>
							<div className={styles.messagesList}>
								{messages.filter(w => w.message).map((m, ndx) => {
									return <p key={`msg_${ndx}`}>{m.message}</p>;
								})}
							</div>
						</div>
					</div>
				)}
		</KiModal2>
	);
}

LogInformationOverlay.propTypes = {
	isActive: PropTypes.bool.isRequired,
	statusData: PropTypes.object.isRequired,
	detailData: PropTypes.object.isRequired,
	setInformationOverlayActive: PropTypes.func,
};

LogInformationOverlay.defaultProps = {
	isActive: false,
	messageData: {},
	setInformationOverlayActive: () => ({}),
};

export default LogInformationOverlay;
