const _ = require('lodash');

const getColumnTypeDisplayName = (columnType, isWaterfall = false) => {
	switch (columnType) {
		case 'asset':
			return 'Asset';
		case 'cohort':
			return 'Cohort';
		case 'aggregate':
			return 'Aggregate';
		case 'trancheAggregate':
		case 'creditSupportAggregate':
		case 'feeAggregate':
		case 'triggerAggregate':
		case 'poolAggregate':
			return 'Entity Aggregate';
		case 'fvSetup':
		case 'trancheSetup':
		case 'creditSupportSetup':
		case 'feeSetup':
		case 'triggerSetup':
			return 'Setup';
		case 'fvDate':
			return 'FV Date';
		case 'debtCalculation':
			if (isWaterfall) return 'Waterfall Calculation';
			return 'Debt Calculation';
		case 'debtInputFV':
		case 'debtInputTranche':
		case 'debtInputCreditSupport':
		case 'debtInputFee':
		case 'debtInputTrigger':
			return 'Debt Input';
		case 'borrowingBase':
			return 'Borrowing Base';
		// From systemColumns options
		case 'collateral':
		case 'tranche':
		case 'creditSupport':
		case 'fee':
			return 'Waterfall';
		case 'triggerCalculation':
			return 'Trigger';
		case 'systemVariable':
			return 'System Variable';
		case 'absIndex':
			return 'Index';
		case 'waterfallVariable':
			return 'Numeric Variable';
		case 'absRating':
			return 'Rating';
		default:
			return columnType;
	}
};

const getColumnTypeName = column => {
	if (column.columnType === 'formula' && column.formula) {
		return 'Asset Calculation';
	}
	return getColumnTypeDisplayName(column.columnType);
};

const getDataTypeDisplayName = dataType => {
	switch (dataType) {
		case 'numeric':
			return 'Numeric';
		case 'date_short':
			return 'Short Date';
		case 'date_long':
			return 'Long Date';
		case 'string':
			return 'Text';
		case 'boolean':
			return 'Boolean';
		case 'posInt':
			return 'Integer';
		case 'rating':
			return 'Rating';
		default:
			return dataType;
	}
};

const getEntityDisplayName = entity => {
	switch (entity) {
		case 'trust':
			return 'Trust';
		case 'fundingVehicle':
			return 'Funding Vehicle';
		case 'tranche':
		case 'debt':
			return 'Tranche';
		case 'creditSupport':
			return 'Account';
		case 'pool':
			return 'Pool';
		case 'trigger':
			return 'Trigger';
		case 'fee':
			return 'Fee';
		case 'global':
			return 'Global';
		default:
			return entity;
	}
};

const CALC_LABELS_MAP = new Map([
	['AVG', 'Avg'],
	['SUM', 'Sum'],
	['SUM_PCT', 'Sum %'],
	['SUM_PCT_TOTAL', 'Sum %'],
	['COUNT', 'Count'],
	['COUNT_PCT', 'Count %'],
	['WGHTAVG', 'Wtd Avg'],
	['MIN', 'Min'],
	['MAX', 'Max'],
	['RATIO', 'Ratio'],
	['FIRST_VALID', 'First Valid'],
]);

const AGGREGATE_LEVEL_COLUMN_ID_MAP = new Map([['fundingVehicle', 'FV'], ['trust', 'TRUST']]);

const generateDisplayCalc = (calculation = '', aggregateLevel = '') => {
	// Get the display value of the calculation, failover to startcase of calculation
	const calc = CALC_LABELS_MAP.get(calculation) || _.startCase(calculation);
	if (AGGREGATE_LEVEL_COLUMN_ID_MAP.has(aggregateLevel)) {
		return `(${AGGREGATE_LEVEL_COLUMN_ID_MAP.get(aggregateLevel)} ${calc})`;
	}
	return `(${calc})`;
};

module.exports = {
	getColumnTypeDisplayName,
	getColumnTypeName,
	getDataTypeDisplayName,
	getEntityDisplayName,
	AGGREGATE_LEVEL_COLUMN_ID_MAP,
	generateDisplayCalc,
	CALC_LABELS_MAP,
};
