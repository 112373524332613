import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const copyInternal = (oldDatasetId, newName) => {
	return fetch(`${apiUrl}/datasetClone/internal`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId: oldDatasetId,
			name: newName,
		}),
	}).then(_handleResponse);
};

export const deleteDs = dataset => {
	return fetch(`${apiUrl}/datasetClone/reset`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId: dataset.datasetId,
			name: dataset.category,
		}),
	}).then(_handleResponse);
};

export default {
	copyInternal,
	deleteDs,
};
