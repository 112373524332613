import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

export const GroupBySelector = props => {
	if (!props.columns) return null;

	const options = (props.columns || []).filter(c => c.columnType === 'cohort' && c._id !== props.assetCohortColumnId);

	const getValue = () => {
		if (props.selectedColumn && props.selectedColumn._id) {
			return options.find(o => o._id === props.selectedColumn._id);
		}
		return options.filter(o => o._id === props.selectedColumn);
	};

	return (
		<Select
			classNamePrefix="aut-select"
			className="cohort-selector"
			value={getValue()}
			isClearable={true}
			isDisabled={props.isDisabled || false}
			getOptionLabel={option => option.displayName}
			getOptionValue={option => option._id}
			options={_.sortBy(options, option => _.get(option, 'displayName', '').toLowerCase())}
			placeholder="Asset ID"
			onChange={option => {
				props.targetFunction(option);
			}}
		/>
	);
};

GroupBySelector.propTypes = {
	selectedColumn: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // on a clear it goes to empty string instead of null
	columns: PropTypes.array,
	targetFunction: PropTypes.func,
	assetCohortColumnId: PropTypes.string.isRequired, // this is the 'null' value, i.e. the asset cohort id which means don't groupBy
	isDisabled: PropTypes.bool,
};

GroupBySelector.defaultProps = {columns: [], options: []};

export default GroupBySelector;
