// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';

// Project imports
import options from 'ki-common/options';

// Website imports
import {useMergedState} from 'utils/customHooks';
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';

// Local imports
import styles from './constraintFormStyles.theme.scss';
import BalanceLimitForm from './BalanceLimitForm';
import ConcentrationLimitForm from './ConcentrationLimitForm';
import ConcentrationForm from './ConcentrationForm';
import WeightedAverageForm from './WeightedAverageForm';
import ExclusionForm from './ExclusionForm';
import {closeMenuOnScroll} from '../../utils/menuOnScroll';

function ConstraintForm({
	constraintData,
	fundingVehicleId,
	fvConstraints,
	allColumns,
	eligibleColumns,
	areColumnsLoading,
	onSaveMethod,
	onDeleteMethod,
	isGroupBy,
	isReadOnly,
	isFundingAnalysisConstraint,
}) {
	const convertConstraintForEdit = rawConstraint => {
		const toReturn = _.cloneDeep(rawConstraint);
		toReturn.dataColumn = allColumns.find(col => col._id === rawConstraint.dataColumn);
		toReturn.logic = options.logic.find(l => l.value === rawConstraint.logic);
		switch (rawConstraint.rowType) {
			case 'ccConcentration':
			case 'ccConcentrationLimit':
				toReturn.selection = options.selections.find(col => col.value === rawConstraint.selection);
				toReturn.weightedBy = allColumns.find(col => col._id === rawConstraint.weightedBy);
				break;
			case 'ccWeightedAvg':
				toReturn.selection = allColumns.find(col => col._id === rawConstraint.selection);
				break;
		}
		return toReturn;
	};

	const convertConstraintForSave = newConstraint => {
		const toReturn = _.cloneDeep(newConstraint);
		toReturn.dataColumn = newConstraint.dataColumn._id;
		toReturn.logic = newConstraint.logic.value;
		switch (newConstraint.rowType) {
			case 'ccConcentration':
			case 'ccConcentrationLimit':
				toReturn.selection = newConstraint.selection.value;
				toReturn.weightedBy = newConstraint.weightedBy._id;
				break;
			case 'ccWeightedAvg':
				toReturn.selection = newConstraint.selection._id;
				break;
		}
		return toReturn;
	};

	const _getFullConstraint = (original = {}) => {
		return Object.assign(
			{
				_id: null,
				name: '',
				rowType: 'ccExclude',
				fundingVehicleId: fundingVehicleId,
				applyConstraint: true,
				applyCovenant: false,
				constraintType: 'both',
				dataColumn: null,
				logic: null,
				includeInEligibility: true,
			},
			original
		);
	};

	const getResetConstraint = () => {
		return convertConstraintForEdit(_getFullConstraint(constraintData));
	};

	const getNewConstraint = () => {
		return convertConstraintForEdit(_getFullConstraint());
	};

	// const getEmptyErrors = () => {
	// 	return {
	// 		name: '',
	// 		rowType: '',
	// 		constraintType: '',
	// 		dataColumn: '',
	// 		logic: '',
	// 		targets: [''], // can have min/max as 0/1
	// 		concentrationValue: '',
	// 		concentrationMin: '',
	// 		concentrationMax: '',
	// 		weightedBy: ''
	// 	}
	// }

	// Local State
	const [constraint, setConstraint, resetConstraint] = useMergedState(getResetConstraint());
	//const [errors, setErrors, resetErrors] = useMergedState(getEmptyErrors());
	const [nameError, setNameError] = useState('');
	const [formHasError, setFormHasError] = useState();
	const [isSaveClick, setIsSaveClick] = useState(false);
	const [isFormUpdated, setIsFormUpdated] = useState(false);

	// Reload Constraint Locally if a new one is passed to the component
	useEffect(
		() => {
			setIsFormUpdated(false);
			setNameError(false);
			setIsSaveClick(false);
			if (constraintData) {
				resetConstraint(getResetConstraint());
			} else {
				resetConstraint(getNewConstraint());
			}
		},
		[constraintData]
	);

	const isFormValid = () => {
		//TODO - build in the insane amount of logic from the old files for validation
		const nc = {...constraint};
		// if (!nc.name || !nc.dataColumn || !nc.logic || !nc.target) {
		if (!nc.name || formHasError) {
			return false;
		}
		switch (_.get(constraint, 'rowType')) {
			// case 'ccConcentration':
			// case 'ccConcentrationLimit':
			// 	return nc.concentrationMax && nc.concentrationMin && nc.selection && nc.weightedBy;
			case 'ccWeightedAvg':
				return nc.selection;
			case 'ccBalanceLimit':
				return true;
			default:
				return true;
		}
	};

	const handleDeleteClick = () => {
		setNameError('');
		setIsFormUpdated(false);
		onDeleteMethod(constraint._id);
		resetConstraint(getNewConstraint());
	};

	const handleSaveClick = () => {
		if (isFormValid()) {
			const saveReady = convertConstraintForSave(constraint);
			onSaveMethod(saveReady);
		}
		setIsSaveClick(true);
		setIsFormUpdated(false);
	};

	const handleUndoClick = () => {
		setNameError('');
		setIsFormUpdated(false);
		resetConstraint(getResetConstraint());
	};

	const updateConstraintValues = (keys, values) => {
		const update = {};
		if (Array.isArray(keys)) {
			keys.forEach((key, index) => {
				update[key] = values[index];
			});
		} else {
			update[keys] = values;
		}
		setConstraint(update);
		setIsFormUpdated(true);
	};

	const updateFormHasError = value => {
		if (!constraint.name) {
			setFormHasError(true);
		} else {
			setFormHasError(value);
		}
	};

	const headerText = isReadOnly ? 'View Constraint' : constraint._id ? 'Edit Constraint' : 'Add Constraint';
	const formType = _.get(constraint, 'rowType', 'ccExclude');
	return (
		<>
			<div className={styles.headerRow}>
				<p className={styles.constraintHeader}>{headerText}</p>
				{!isReadOnly && (
					<KiButton
						iconButton
						icon="delete"
						title="Delete Constraint"
						disabled={!constraint._id}
						onMouseUp={() => handleDeleteClick()}
					/>
				)}
				{!isReadOnly && (
					<KiButton
						iconButton
						icon="undo"
						title="Undo changes"
						disabled={!isFormUpdated}
						onMouseUp={() => handleUndoClick()}
					/>
				)}
				{!isReadOnly && (
					<KiButton
						className={styles.constraintControls}
						iconButton
						title="Save constraint"
						icon="save"
						disabled={!!nameError || !isFormUpdated || formHasError}
						onMouseUp={() => handleSaveClick()}
					/>
				)}
			</div>

			<div className={styles.constraintFormBody}>
				<KiInput
					type="text"
					name="name"
					label="Constraint Name"
					disabled={isReadOnly}
					value={constraint.name}
					className={styles.constraintInput}
					onChange={newName => {
						const targetConst = fvConstraints.find(c => c.name === newName);
						if (targetConst && (targetConst._id !== constraint._id || targetConst.id !== constraint.id)) {
							setNameError('Name must be unique');
						} else {
							setNameError('');
						}
						setConstraint({
							name: newName,
						});
						setIsFormUpdated(true);
					}}
					error={nameError}
				/>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Type</span>
					<Select
						isDisabled={isReadOnly || constraint._id}
						classNamePrefix="aut-select"
						value={options.constraintRowTypes.find(opt => opt.value === formType)}
						isClearable={false}
						options={options.constraintRowTypes}
						closeMenuOnScroll={e => closeMenuOnScroll(e)}
						onChange={option => {
							setConstraint({
								rowType: option.value,
								dataColumn: null,
								logic: null,
								target: '',
								concentrationIncludes: [],
								concentrationExcludes: [],
								includeInEligibility: option.value === 'ccExclude' && !isGroupBy,
							});
							setIsFormUpdated(true);
						}}
					/>
				</div>
				{formType === 'ccExclude' && (
					<ExclusionForm
						constraintData={constraint}
						setConstraintItem={updateConstraintValues}
						dataColumns={allColumns}
						eligibleColumns={eligibleColumns}
						loadingColumns={areColumnsLoading}
						isGroupBy={isGroupBy}
						isReadOnly={isReadOnly}
						isFundingAnalysisConstraint={isFundingAnalysisConstraint}
						updateFormHasError={updateFormHasError}
					/>
				)}
				{formType === 'ccConcentration' && (
					<ConcentrationForm
						constraintData={constraint}
						setConstraintItem={updateConstraintValues}
						dataColumns={allColumns}
						eligibleColumns={eligibleColumns}
						loadingColumns={areColumnsLoading}
						isGroupBy={isGroupBy}
						isReadOnly={isReadOnly}
						updateFormHasError={updateFormHasError}
						isSaveClick={isSaveClick}
					/>
				)}
				{formType === 'ccWeightedAvg' && (
					<WeightedAverageForm
						constraintData={constraint}
						setConstraintItem={updateConstraintValues}
						dataColumns={allColumns}
						eligibleColumns={eligibleColumns}
						loadingColumns={areColumnsLoading}
						isGroupBy={isGroupBy}
						isReadOnly={isReadOnly}
						updateFormHasError={updateFormHasError}
						isSaveClick={isSaveClick}
					/>
				)}
				{formType === 'ccBalanceLimit' && (
					<BalanceLimitForm
						constraintData={constraint}
						setConstraintItem={updateConstraintValues}
						dataColumns={allColumns}
						loadingColumns={areColumnsLoading}
						isGroupBy={isGroupBy}
						isReadOnly={isReadOnly}
						updateFormHasError={updateFormHasError}
						isSaveClick={isSaveClick}
					/>
				)}
				{formType === 'ccConcentrationLimit' && (
					<ConcentrationLimitForm
						constraintData={constraint}
						setConstraintItem={updateConstraintValues}
						dataColumns={allColumns}
						eligibleColumns={eligibleColumns}
						loadingColumns={areColumnsLoading}
						isGroupBy={isGroupBy}
						isReadOnly={isReadOnly}
						updateFormHasError={updateFormHasError}
						isSaveClick={isSaveClick}
					/>
				)}
			</div>
		</>
	);
}

ConstraintForm.propTypes = {
	constraintData: PropTypes.object.isRequired,
	fundingVehicleId: PropTypes.string.isRequired,
	fvConstraints: PropTypes.array,
	allColumns: PropTypes.array.isRequired,
	eligibleColumns: PropTypes.array.isRequired,
	areColumnsLoading: PropTypes.bool,
	onSaveMethod: PropTypes.func,
	onDeleteMethod: PropTypes.func,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	isFundingAnalysisConstraint: PropTypes.bool,
};

ConstraintForm.defaultProps = {
	fvConstraints: [],
	areColumnsLoading: false,
	onSaveMethod: () => {
		return false;
	},
	onDeleteMethod: () => {
		return false;
	},
	isGroupBy: false,
	isReadOnly: false,
	isFundingAnalysisConstraint: false,
};

export default ConstraintForm;
