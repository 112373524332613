const options = require('../../options');

const getConstraints = () => ({
	'name': {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 100,
			message: 'must be between 1 to 100 characters',
		},
	},
	'dataColumn': {
		presence: {allowEmpty: false},
	},
	'logic': {
		presence: {allowEmpty: false},
		inclusion: {
			within: options.logic.map(({value}) => value),
			message: 'must be a valid logic value',
		},
	},
	'target': {
		presence: {allowEmpty: false},
		targetArray: true
	},
});

module.exports = {
	getConstraints
};