import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import KiButton from 'components/KiButton';
import _get from 'lodash/get';
import {KiCreatable} from 'components/KiSelect';

export class ChildDataForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		columnList: PropTypes.array.isRequired,
		closeForm: PropTypes.func.isRequired,
		isAdmin: PropTypes.bool,
		columnToEdit: PropTypes.object,
		existingTags: PropTypes.array,
		schemaList: PropTypes.array.isRequired,
		getAggregationOptions: PropTypes.func,
		datasetId: PropTypes.string,
		mode: PropTypes.string,
	};

	static defaultProps = {
		existingTags: [],
	};

	state = {
		name: '',
		tags: [],
		nameError: null,
		childColumn: null,
		selectedAggregation: null,
		aggregationOptions: [],
		isGlobal: this.props.columnToEdit && this.props.columnToEdit.createdBy === '' ? true : false,
	};

	componentDidMount() {
		const {columnToEdit, schemaList, getAggregationOptions} = this.props;
		const selectedSchema = schemaList.find(s => s._id === (columnToEdit ? columnToEdit.schemaId : null));
		const aggregationColumnOption = selectedSchema
			? getAggregationOptions(selectedSchema.schemaColumns.find(c => c.id === columnToEdit.value).dataType)
			: [];
		this.setState(
			{
				schemaList: schemaList,
				selectedSchema: selectedSchema,
				aggregationOptions: getAggregationOptions('numeric'),
				isGlobal: columnToEdit && !columnToEdit.createdBy ? true : false,
			},
			() => {
				if (columnToEdit) {
					this.setState({
						selectedAggregation: aggregationColumnOption.find(o => o.value === columnToEdit.aggregation),
						aggregationOptions: aggregationColumnOption,
						name: columnToEdit.displayName,
						childColumn: selectedSchema.schemaColumns.find(c => c.id === columnToEdit.value),
					});
				}
			}
		);
	}
	handleChildColumnChange = option => {
		this.setState({aggregationOptions: this.props.getAggregationOptions(option.dataType)});
		this.setState({childColumn: option});
		this.setState({selectedAggregation: null});
	};

	handleSelectedAggregationChange = option => {
		this.setState({
			selectedAggregation: option,
		});
	};

	setName = val => {
		this.setState({
			name: val,
			nameError: val.length ? null : "Name can't be blank",
		});
	};

	isDuplicateNameError = () => {
		const assetCols = this.props.columnList.filter(
			col => ((!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset') || col.schemaId
		);

		return assetCols.find(
			col => _get(col, 'displayName') === this.state.name && col._id !== this.props.columnToEdit._id
		);
	};

	getSaveAndFormatDisabled = () => {
		const {selectedAggregation, childColumn, name} = this.state;

		if (!name || !childColumn || !selectedAggregation) {
			return true;
		}

		return false;
	};

	onSubmit = () => {
		const {columnToEdit, datasetId} = this.props;
		const {name, selectedAggregation, tags, isGlobal, childColumn, selectedSchema} = this.state;
		this.setState({isSaving: true});
		if (!this.state.name) {
			this.setState({nameError: "Name can't be blank", isSaving: false});
			return;
		}
		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		}

		if (!this.isDuplicateNameError()) {
			this.setState({nameError: ''});
		}

		const column = {
			_id: columnToEdit ? columnToEdit._id : null,
			datasetId: datasetId,
			columnName: name,
			displayName: name,
			dataType: childColumn.dataType,
			columnType: 'asset',
			columnFormType: 'subAccountColumn',
			schemaId: selectedSchema._id,
			value: childColumn.displayName,
			aggregation: selectedAggregation.value,
			displayFormat: this.deriveDisplayFormatFromTemplate(childColumn.dataType),
			tags,
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			isGlobal,
		};

		return this.props.submitMethod(column);
	};

	handleSchemaChange = val => {
		this.setState({selectedSchema: val});
		this.setState({childColumn: null});
		this.setState({selectedAggregation: null});
	};
	deriveDisplayFormatFromTemplate = dataType => {
		switch (dataType) {
			case 'numeric':
				return '9,999.99';
			case 'date':
			case 'date_long':
				return 'yyyy-MM-dd';
			case 'date_short':
				return 'yyyy-MM';
			case 'string':
				return 'string';
			default:
				return dataType;
		}
	};
	render() {
		const {selectedSchema, aggregationOptions, schemaList, childColumn, isSaving} = this.state;

		return (
			<section className="column-selector-form inline-column-form">
				<div>
					<KiInput
						label="Name"
						value={this.state.name}
						onChange={val => this.setName(val)}
						error={this.state.nameError}
					/>
					<section className="calculation-form-section">
						<span className="form-instruction">Tags:</span>
						<KiCreatable
							classNamePrefix="aut-select"
							isMulti={true}
							options={this.props.existingTags.map(t => ({
								value: t,
								label: t,
							}))}
							value={this.state.tags.map(t => ({value: t, label: t}))}
							onChange={val => this.setState({tags: val.map(t => t.value)})}
							placeholder="Add some tags"
							noResultsText={false}
						/>
					</section>
					<section className="calculation-form-section">
						<span className="form-instruction">Choose a Schema:</span>
						<Select
							classNamePrefix="aut-select"
							options={schemaList}
							value={selectedSchema}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							isClearable={false}
							onChange={selected => this.handleSchemaChange(selected)}
						/>
					</section>
					{selectedSchema && (
						<section className="calculation-form-section">
							<span className="form-instruction">Select Child Column</span>
							<Select
								classNamePrefix="aut-select"
								value={childColumn}
								isClearable={false}
								options={selectedSchema.schemaColumns}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option.id}
								onChange={this.handleChildColumnChange}
							/>
						</section>
					)}

					{childColumn && (
						<section className="calculation-form-section">
							<span className="form-instruction">Select an Aggregation Type</span>
							<Select
								classNamePrefix="aut-select"
								value={this.state.selectedAggregation}
								isClearable={false}
								options={aggregationOptions}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
								onChange={selected => this.handleSelectedAggregationChange(selected)}
							/>
						</section>
					)}
					{this.props.mode === 'inline' &&
						this.props.isAdmin &&
						!_get(this, 'props.columnToEdit._id', null) && (
							<section className="calculation-form-section">
								<KiCheckbox
									name="isGlobal"
									checked={this.state.isGlobal}
									label="Make Global"
									onChange={val => this.setState({isGlobal: val})}
								/>
							</section>
						)}
				</div>
				<section className="format-and-save">
					<div className="inline-column-form-buttons">
						<KiButton flat primary onClick={() => this.props.closeForm()}>
							Cancel
						</KiButton>
						<KiButton
							disabled={this.getSaveAndFormatDisabled() || isSaving}
							raised
							primary
							onClick={() => this.onSubmit()}
						>
							Save
						</KiButton>
					</div>
				</section>
			</section>
		);
	}
}

export default ChildDataForm;
