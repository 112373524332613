import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

import {reportsApi} from 'api';
import {getConstraintGroups, upsertConstraintGroup} from 'api/fundingAnalysisApi';
import {KiFontIcon, KiInput, KiSelect, KiTooltip, ContextIcons} from 'components';
import {showSnackbar} from 'state/actions/Snackbar';
import ConstraintsModal from '../ConstraintsModal';
import FiltersModal from '../FiltersModal';
import {DateControls} from './DateControls';
import {LastSolvedConstraintGroupModal} from './LastSolvedConstraintsGroupModal';
import FundingAnalysisContext from '../../fundingAnalysisContext';

import styles from './fundingScenarioForm.theme.scss';

const ConstraintSelectOptionComponent = props => {
	const handleSettingsClick = e => {
		e.preventDefault();
		e.stopPropagation();
		props.handleEdit(props.option);
	};
	const handleViewClick = e => {
		e.preventDefault();
		e.stopPropagation();
		props.handleView(props.option);
	};
	const isEditEnabled = !props.disabled && !props.option.isFVDefined && !props.option.isReadOnly;
	const isViewEnabled = !props.disabled;
	return (
		<div className={styles.constraintSelectOptionLabel}>
			<div className={styles.constraintSelectOptionLabelText}>{props.option?.constraintGroupName}</div>
			<div
				className={styles.constraintSelectOptionLabelActions}
				style={{display: isViewEnabled || isEditEnabled ? 'inherit' : 'none'}}
			>
				{isEditEnabled && <KiFontIcon value="edit" onClick={handleSettingsClick} />}
				{isViewEnabled && <KiFontIcon value="visibility" onClick={handleViewClick} />}
			</div>
		</div>
	);
};

ConstraintSelectOptionComponent.propTypes = {
	option: PropTypes.shape({
		constraintGroupName: PropTypes.string,
		constraints: PropTypes.arrayOf(PropTypes.object),
		isFVDefined: PropTypes.bool,
		isReadOnly: PropTypes.bool,
	}),
	disabled: PropTypes.bool,
	handleEdit: PropTypes.func.isRequired,
	handleView: PropTypes.func.isRequired,
};

export const FormControls = props => {
	const {datasetId} = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const isModificationForm = location.pathname.includes('modify');
	const isUserAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));

	const [isLastSolvedConstraintGroupModalActive, setIsLastSolvedConstraintGroupModalActive] = useState(false);
	const [isPreviewConstraintModalActive, setIsPreviewConstraintModalActive] = useState(false);
	const [constraintGroupPreview, setConstraintGroupPreview] = useState(false);
	const [isFiltersModalActive, setIsFiltersModalActive] = useState(false);
	const [isConstraintModalActive, setIsConstraintModalActive] = useState(false);
	const [reportGroups, setReportGroups] = useState([]);

	const onFilterSave = async filtersToSave => {
		try {
			props.setScenario({filters: filtersToSave});
			// fundingAnalysisContext.setScenarioItem('filters', filtersToSave);
			dispatch(showSnackbar('Filters saved'));
		} catch (e) {
			dispatch(showSnackbar('Error saving filters'));
		}
	};

	const handleClickVisibility = constraintGroup => {
		setConstraintGroupPreview(constraintGroup);
		setIsPreviewConstraintModalActive(true);
	};
	const handleClickEditConstraint = constraintGroup => {
		props.setActiveConstraintGroup(constraintGroup);
		setIsConstraintModalActive(true);
	};

	const selectFundingModel = fundingModel => {
		if (fundingModel._id !== props.scenario?.fundingModelId) {
			props.setScenario({
				fundingModelId: fundingModel._id,
				fvSettings: [], // For the fundingModel useEffect to load new FV settings
			});
			props.setFundingModel(fundingModel);
			fundingAnalysisContext.setModel(fundingModel);
		}
	};

	const onModalSave = async groupToSave => {
		try {
			const newGroup = await upsertConstraintGroup(groupToSave);
			setIsConstraintModalActive(false);
			const allGroups = await getConstraintGroups(groupToSave.fundingModelId);
			props.setModelConstraintGroups(allGroups);
			props.setActiveConstraintGroup(newGroup);
			props.setScenario({constraintGroupId: newGroup._id});
			// fundingAnalysisContext.setModelItem('constraintGroupId', newGroup._id);
			dispatch(showSnackbar('Constraint Group saved'));
		} catch (err) {
			dispatch(showSnackbar('Error saving Constraint Group'));
		}
	};

	useEffect(
		() => {
			if (datasetId) {
				const controller = new AbortController();
				reportsApi
					.fetchReportGroupsByDatasetId(datasetId, controller.signal)
					.then(result => setReportGroups(result))
					.catch(err => {
						if (err.name === 'AbortError') return null;
						setReportGroups([]);
					});
				return () => controller.abort();
			}
		},
		[datasetId]
	);

	const filterCount = props.scenario?.filters?.length ?? 0;
	return (
		<React.Fragment>
			<section className={styles.nonTableOptions}>
				<div className={styles.topRowOptions}>
					<div className={styles.selectWrapper}>
						<KiInput
							type="text"
							className={styles.nameInput}
							name="name"
							label="Name"
							value={props.scenario.name}
							disabled={isModificationForm}
							onChange={val => {
								if (
									fundingAnalysisContext.allScenarios?.find?.(
										m => m.name === val && m._id !== props.scenario._id
									)
								) {
									props.setNameError('Name must be unique');
								} else if (!val) {
									props.setNameError('Name is required');
								} else {
									props.setNameError('');
								}
								props.setScenario({name: val});
							}}
							error={props.nameError}
						/>
					</div>
					{!props.scenario.isBorrowingBase && (
						<div className={styles.selectWrapper}>
							<span className="theme-label">Funding Model</span>
							<Select
								classNamePrefix="aut-select"
								value={fundingAnalysisContext.allModels.find(
									m => m._id === props.scenario.fundingModelId
								)}
								isClearable={false}
								isDisabled={isModificationForm}
								placeholder={
									props.scenario.lastSolvedModel?.name
										? `${props.scenario.lastSolvedModel.name} (deleted)`
										: 'Select Funding Model'
								}
								options={fundingAnalysisContext.allModels}
								onChange={val => selectFundingModel(val)}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
							/>
						</div>
					)}
					{!props.scenario.isBorrowingBase && (
						<div className={styles.selectWrapper}>
							<KiInput
								isNumberMasked={true}
								className={styles.scenarioLimitInput}
								type="text"
								name="ScenarioLimit"
								label="Scenario Limit"
								value={props.scenario.scenarioLimit}
								disabled={isModificationForm}
								onChange={val => props.setScenario({scenarioLimit: val})}
								error={''}
							>
								<KiTooltip
									className={styles.limitTooltip}
									maxWidth={320}
									message={'Blank means maximum possible amount'}
								>
									<KiFontIcon className="list-icon-btn" value="info" />
								</KiTooltip>
							</KiInput>
						</div>
					)}
					<div className={classNames(styles.selectWrapper, styles.constraintSelectWrapper)}>
						<span className="theme-label">Constraint Group</span>
						<Select
							isDisabled={!props.scenario.fundingModelId}
							classNamePrefix="aut-select"
							value={props.modelConstraintGroups.find(c => c._id === props.scenario.constraintGroupId)}
							isClearable={false}
							placeholder={
								props.scenario.lastSolvedConstraintGroup?.name
									? `${props.scenario.lastSolvedConstraintGroup?.name} (deleted)`
									: 'Select a constraint group'
							}
							options={props.modelConstraintGroups}
							onChange={val => {
								props.setActiveConstraintGroup(val || null);
								props.setScenario({constraintGroupId: val?._id || null});
							}}
							styles={{
								singleValue: baseStyles => ({
									...baseStyles,
									width: '100%',
									maxWidth: 'calc(100% - 2rem)',
								}),
							}}
							getOptionLabel={option => option.constraintGroupName}
							formatOptionLabel={option => (
								<ConstraintSelectOptionComponent
									option={option}
									disabled={!props.scenario.fundingModelId}
									handleView={handleClickVisibility}
									handleEdit={handleClickEditConstraint}
								/>
							)}
							getOptionValue={option => option._id}
						/>
					</div>
					{!!props.scenario.lastSolvedConstraintGroup?.constraints?.length && (
						<KiFontIcon
							className={styles.lastSolvedConstraintGroupIcon}
							onClick={() => setIsLastSolvedConstraintGroupModalActive(true)}
							value="history"
							title="View Constraints Used In Previous Solve"
						/>
					)}
					{!props.scenario.isBorrowingBase && (
						<div
							className={styles.filterIndicator}
							onClick={() => setIsFiltersModalActive(true)}
							title={`${filterCount} Filters Applied`}
						>
							<div className={styles.svgWrapper}>
								<ContextIcons.FiltersIcon alt={`${filterCount} Filters Applied`} />
							</div>
							<div className={styles.filterCount}>{filterCount}</div>
						</div>
					)}
				</div>
				{!props.scenario.isBorrowingBase && (
					<div className={styles.middleRowOptions}>
						<DateControls
							scenario={props.scenario}
							fundingModel={props.fundingModel}
							isUserAdmin={isUserAdmin}
							setScenario={props.setScenario}
							isModificationForm={isModificationForm}
							setShowSpinner={props.setShowSpinner}
							replaceScenario={props.replaceScenario}
						/>
						<div className={styles.reportDropdown}>
							<span className="theme-label">Report Group</span>
							<KiSelect
								isClearable={true}
								value={reportGroups.find(rg => rg._id === props.scenario.reportGroupId)}
								options={reportGroups}
								getOptionLabel={rg => rg.name}
								getOptionValue={rg => rg._id}
								onChange={reportGroup => props.setScenario({reportGroupId: reportGroup?._id || null})}
							/>
						</div>
					</div>
				)}
			</section>
			{!!isConstraintModalActive && (
				<ConstraintsModal
					isActive={isConstraintModalActive}
					setModalActive={setIsConstraintModalActive}
					curConstraintGroup={props.activeConstraintGroup}
					allConstraintGroups={props.modelConstraintGroups}
					fundingVehicles={props.scenario.fvSettings.filter(fv => fv.fvName !== 'Unencumbered')} //filter out isUnencumbered
					fundingModel={props.fundingModel}
					isReadOnly={false}
					onSaveFunc={onModalSave}
				/>
			)}
			{!!isPreviewConstraintModalActive && (
				<ConstraintsModal
					isActive={isPreviewConstraintModalActive}
					setModalActive={setIsPreviewConstraintModalActive}
					curConstraintGroup={constraintGroupPreview}
					allConstraintGroups={props.modelConstraintGroups}
					fundingVehicles={props.scenario.fvSettings.filter(fv => fv.fvName !== 'Unencumbered')} //filter out isUnencumbered
					fundingModel={props.fundingModel}
					isReadOnly={true}
					onSaveFunc={() => null}
				/>
			)}
			{!!isFiltersModalActive && (
				<FiltersModal
					isActive={isFiltersModalActive}
					setModalActive={setIsFiltersModalActive}
					scenarioFilters={props.scenario.filters}
					onSaveFunc={onFilterSave}
				/>
			)}
			<LastSolvedConstraintGroupModal
				isActive={isLastSolvedConstraintGroupModalActive}
				setModalActive={setIsLastSolvedConstraintGroupModalActive}
				lastSolvedConstraintGroup={props.scenario.lastSolvedConstraintGroup}
				datasetId={datasetId}
			/>
		</React.Fragment>
	);
};
FormControls.propTypes = {
	scenario: PropTypes.object.isRequired,
	setScenario: PropTypes.func.isRequired,
	nameError: PropTypes.string,
	setNameError: PropTypes.func.isRequired,
	fundingModel: PropTypes.object.isRequired,
	modelConstraintGroups: PropTypes.any.isRequired,
	activeConstraintGroup: PropTypes.object,
	setActiveConstraintGroup: PropTypes.func.isRequired,
	setShowSpinner: PropTypes.func.isRequired,
	replaceScenario: PropTypes.func.isRequired,
	setModelConstraintGroups: PropTypes.func.isRequired,
	setFundingModel: PropTypes.func.isRequired,
};
FormControls.defaultProps = {
	nameError: '',
	scenario: {},
	modelConstraintGroups: [],
};
