const mongoObjectIDStringRegex = require('../../utils/testUtils').mongoObjectIDStringRegex;
const testUtils = require('../../utils/testUtils');

const getConstraints = () => ({
	_id: {
		presence: false,
		format: {
			pattern: mongoObjectIDStringRegex,
			flags: 'i',
			message: '_id should be a valid string of a mongo ObjectID',
		},
	},
	name: {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 100,
			message: 'must be between 1 to 100 characters',
		},
	},
	createdBy: {
		presence: {allowEmpty: false},
	},
	createdAt: {
		presence: {allowEmpty: false},
		format: {
			pattern: testUtils.ISO8601_DATE_PATTERN,
			message: 'must be in ISO8601 format',
		},
		datetime: true,
	},
});

module.exports = {
	getConstraints,
};
