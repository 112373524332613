const getConstraints = () => ({
	newScenarioName: {
		presence: {allowEmpty: false},
	},
	modificationType: {
		presence: {allowEmpty: false},
	},
});

module.exports = {
	getConstraints,
};
