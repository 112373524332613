import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesForm from './Form.theme.scss';
import KiInput from '../../../../components/KiInput';

const NameForm = ({
	label,
	items,
	editItemId,
	mergeValidationErrors,
	mergeFormData,
	nameFormData,
	nameValidationErrors,
}) => {
	const [hasNameChanged, setHasNameChanged] = useState(false);
	const validateName = name => {
		const trimmedName = name.trim();
		if (trimmedName) {
			if (items.find(item => item.name === trimmedName && item._id !== editItemId)) {
				mergeValidationErrors({name: 'Name already in use'});
				return;
			}
		} else {
			mergeValidationErrors({name: `${label} Name is required.`});
			return;
		}
		mergeValidationErrors({name: null});
	};

	const onNameChange = name => {
		setHasNameChanged(true);
		mergeFormData({name});
	};

	useEffect(
		() => {
			if (nameFormData || hasNameChanged) {
				validateName(nameFormData);
			}
		},
		[nameFormData]
	);

	return (
		<div className={classNames('sidebar-form-section', stylesForm.noMarginTop)}>
			<span className="form-instruction">{label} Name</span>
			<KiInput
				className="substring-size-input"
				value={nameFormData}
				onChange={v => onNameChange(v)}
				error={nameValidationErrors}
			/>
		</div>
	);
};

NameForm.propTypes = {
	label: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	editItemId: PropTypes.string,
	mergeValidationErrors: PropTypes.func.isRequired,
	mergeFormData: PropTypes.func.isRequired,
	nameFormData: PropTypes.string.isRequired,
	nameValidationErrors: PropTypes.string,
};

export default NameForm;
