import PropTypes from 'prop-types';
import React from 'react';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';
import _get from 'lodash/get';
import {buildTitle} from 'ki-common/utils/explorerUtils';

export const TimeSeriesColumnSelector = props => {
	const handleChange = value => {
		props.targetFunction(value);
	};

	const params = {
		options: [],
		className: 'snapshot-selector',
		targetFunction: handleChange,
		startingValue: _get(props, 'view.timeseriesColumn._id', _get(props, 'explorerRequest.timeseries.column._id')),
		visible: props.visible || true,
		disabled: props.disabled,
	};

	const getOptions = columns => {
		const options = columns.filter(col => col.columnType === 'aggregate').map(col => ({
			value: col._id,
			label: buildTitle(col.displayName, col.calculation, col.calculateColumn),
		}));
		options.unshift({value: 'all', label: 'All'});
		return options;
	};

	// called from explorer
	if (props.explorerRequest && props.explorerRequest.columns.length) {
		params.options = getOptions(props.explorerRequest.columns);
	}
	// called from view settings
	if (props.view && props.view.columns) {
		params.options = getOptions(props.view.columns);
	}

	const classNames = props.visible ? 'time-series-selector' : 'time-series-selector no-display';

	return (
		<div className={classNames}>
			<span className="theme-label form-instruction">Summary Column</span>
			<TriggerFunctionSelector {...params} />
		</div>
	);
};

TimeSeriesColumnSelector.propTypes = {
	dataset: PropTypes.object,
	explorerRequest: PropTypes.object, //eslint-disable-line react/no-unused-prop-types
	targetFunction: PropTypes.func,
	visible: PropTypes.bool,
	disabled: PropTypes.bool,
	view: PropTypes.object,
};

export default TimeSeriesColumnSelector;
