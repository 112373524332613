import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ManageColumns} from './components/ManageColumns';
import {ColumnForms} from './components/ColumnForms';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchDataset, requeryColumnList} from 'containers/datasetList/actions';
import {fetchAccounts} from 'containers/accounts/actions';
import {defaultMinimalFieldColumnSpecification} from 'api/columnServiceApi';

import KiIconButton from 'components/KiIconButton';
import classNames from 'classnames';
import './Calculations.scss';

export class Calculations extends Component {
	static propTypes = {
		datasetId: PropTypes.string.isRequired,
		showSnackbar: PropTypes.func.isRequired,
		fetchAccounts: PropTypes.func.isRequired,
		requeryColumnList: PropTypes.func.isRequired,
		userList: PropTypes.array,
		user: PropTypes.object,
		reloadView: PropTypes.func,
		currentView: PropTypes.object,
		columnList: PropTypes.array.isRequired,
	};

	static defaultProps = {
		columns: [],
		datasetId: '',
		showSnackbar: () => ({}),
	};

	state = {
		mode: 'manage',
		columnToEdit: null,
		columns: [],
	};

	componentDidMount() {
		const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		if (!this.props.userList.length && isAdmin) {
			this.props.fetchAccounts();
		}
		this.requeryColumns();
	}

	setMode = (mode, type, params) => {
		this.setState({mode: mode, columnType: type, formParams: params});
	};

	setColumnToEdit = col => {
		this.setState({columnToEdit: col, mode: 'add'});
	};

	closeForm = () => {
		this.setMode('manage');
		this.setState({columnToEdit: null});
	};

	requeryColumns = () => {
		// This condition protects the requery from happening for non-performance enhanced parents
		// that still send the columns with the dataset.
		//if(this.props.columns.length==0){
		this.props.requeryColumnList(this.props.datasetId, defaultMinimalFieldColumnSpecification);
		//}
	};

	fetchDatasetAndRequeryColumns = () => {
		this.requeryColumns();
		//return this.props.fetchDataset(this.props.datasetId, false);
	};

	render() {
		const {datasetId, showSnackbar} = this.props;
		//const columns = this.props.columns.length > 0 ? this.props.columns : this.props.columnList;
		const columns = this.props.columnList;
		const {mode, columnType, columnToEdit, formParams} = this.state;
		if (!datasetId) {
			return (
				<div className="calculation-flyout-root">
					<p className="flyout-panel-title">No Dataset Selected</p>
				</div>
			);
		}
		return (
			<div className="calculation-flyout-root">
				<header className="flyout-panel-title">MANAGE COLUMNS</header>
				<KiIconButton
					className={classNames({
						'close-btn': true,
						hidden: mode === 'manage',
					})}
					icon="close"
					onClick={() => {
						this.closeForm();
					}}
				/>
				<ManageColumns
					setPanelMode={this.setMode}
					columns={columns}
					datasetId={datasetId}
					isActive={mode === 'manage'}
					showSnackbar={showSnackbar}
					fetchDataset={this.fetchDatasetAndRequeryColumns}
					setColumnToEdit={this.setColumnToEdit}
					setMode={this.setMode}
					user={this.props.user}
					userList={this.props.userList}
				/>
				<ColumnForms
					setPanelMode={this.setMode}
					columns={columns}
					isActive={mode === 'add'}
					datasetId={datasetId}
					showSnackbar={showSnackbar}
					fetchDataset={this.fetchDatasetAndRequeryColumns}
					columnToEdit={columnToEdit}
					closeForm={this.closeForm}
					columnType={columnType}
					setColumnToEdit={this.setColumnToEdit}
					user={this.props.user}
					reloadView={this.props.reloadView}
					formParams={formParams}
					currentView={this.props.currentView}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	columns: state.datasetList.selected.columns,
	datasetId: state.datasetList.selected.datasetId,
	userList: state.accounts.data,
	columnList: state.datasetList.columnList,
});

const mapDispatchToProps = dispatch => ({
	showSnackbar: msg => dispatch(showSnackbar(msg)),
	fetchDataset: id => dispatch(fetchDataset(id, false)),
	fetchAccounts: () => dispatch(fetchAccounts()),
	requeryColumnList: (datasetId, columnSpecification) => {
		dispatch(requeryColumnList(datasetId, columnSpecification));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Calculations);
