import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import {showSnackbar} from 'state/actions/Snackbar';
import KiButton from 'components/KiButton';
import SubmissionUpload from './components/SubmissionUpload';
import SchemaEditor from './components/SchemaEditor';
import SchemaResults from './components/SchemaResults';
import KiConfirmModal from 'components/KiConfirmModal';
import _isMatch from 'lodash/isMatch';
import {submissionsApi, categorySchemasApi} from 'api';
import uuidV4 from 'uuid/v4';
import {
	fetchSubmission,
	resetSubmission,
	fetchCategorySchema,
	fetchCategorySchemas,
	updateColumn,
	validateSchema,
	validateSchemaColumn,
	updateSubmissionAndSchema,
} from './actions';
import SubmissionStatusAvatar from 'components/Submission/SubmissionStatusAvatar';
import SubmissionMessage from 'components/Submission/SubmissionMessage';
import {fetchDataset} from 'containers/datasetList/actions';

const REQUIRED_COLUMNS = ['AssetID', 'Balance'];
const REQUIRED_OPTIONAL_COLUMNS = ['DealID'];

class Submission extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		submission: PropTypes.object,
		// eslint-disable-next-line
		routeParams: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		fetchSubmission: PropTypes.func.isRequired,
		fetchCategorySchema: PropTypes.func.isRequired,
		fetchCategorySchemas: PropTypes.func.isRequired,
		categorySchemas: PropTypes.array.isRequired,
		resetSubmission: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired,
		updateColumn: PropTypes.func,
		validateSchemaColumn: PropTypes.func,
		showSnackbar: PropTypes.func,
		updateSubmissionAndSchema: PropTypes.func,
		params: PropTypes.object,
		dataset: PropTypes.object,
		fetchDataset: PropTypes.func.isRequired,
	};

	static defaultProps = {
		dataset: {},
	};

	state = {
		showCancelSubmissionModal: false,
		editorCollapsed: false,
		resultsCollapsed: false,
		categorySchema: {
			columns: [],
		},
		schemaErrors: [],
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Submission`;
		const {fetchSubmission, match, fetchCategorySchema, fetchCategorySchemas} = this.props;
		const isNewDataset = this.props.match.path === '/datasets/create';
		if (!this.props.dataset._id && !isNewDataset) {
			const {datasetId} = this.props.match.params;
			this.props.fetchDataset(datasetId, false);
		}
		fetchCategorySchemas();

		if (match && match.params.submissionId && match.params.submissionId !== 'upload') {
			fetchSubmission(match.params.submissionId).then(submission => {
				if (submission) {
					fetchCategorySchema().then(() => this.getSchemaErrors());
				}
			});
		} else {
			this.props.resetSubmission();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.submissionId !== prevProps.match.params.submissionId) {
			if (this.props.submission && this.props.submission.categorySchemaName) {
				document.title = `${this.props.app.kiVersion} - ${this.props.submission.categorySchemaName}`;
			}
			const {fetchCategorySchema} = this.props;

			this.props.fetchCategorySchemas();
			if (this.props.match.params.submissionId !== 'upload') {
				this.props.fetchSubmission(this.props.match.params.submissionId).then(submission => {
					if (submission) {
						fetchCategorySchema().then(() => this.getSchemaErrors());
					}
				});
			} else {
				this.props.resetSubmission();
			}
		}
	}

	componentWillUnmount() {
		this.props.resetSubmission();
	}

	validateSubmissionData = () => {
		this.setState(
			{
				editorCollapsed: true,
				resultsCollapsed: false,
			},
			() => submissionsApi.validateFullSubmission(this.props.submission._id)
		);
	};

	getSchemaErrors = () => {
		const {
			submission: {
				categorySchema: {columns = []},
			},
		} = this.props;

		const requiredColumnErrors = REQUIRED_COLUMNS.reduce((errors, requiredColumn) => {
			if (
				!columns.find(
					c => c.mapColumn === requiredColumn && !(c.messages || []).find(m => m.code === 'columnNotFound')
				)
			) {
				errors.push({
					id: uuidV4(),
					code: `${requiredColumn}NotFound`, // This makes the type of the column show up
					level: 'error',
					count: 1,
				});
			}
			if (columns.find(c => c.mapType === 'required' && !c.mapColumn)) {
				errors.push({
					id: uuidV4(),
					code: 'emptyRequiredColumn',
					level: 'error',
					count: 1,
				});
			}
			return errors;
		}, []);

		const requiredOptionalColumnErrors = REQUIRED_OPTIONAL_COLUMNS.reduce(errors => {
			if (columns.find(c => c.mapColumn === 'DiscountRate') && !columns.find(c => c.mapColumn === 'DealID')) {
				// Found DiscountRate but not DealID set
				// DealID is needed when DiscountRate is set
				errors.push({
					id: uuidV4(),
					code: `DealIDNotFound`,
					level: 'error',
					count: 1,
				});
			}
			if (columns.find(c => c.mapType === 'optional' && !c.mapColumn)) {
				errors.push({
					id: uuidV4(),
					code: 'emptyOptionalColumn',
					level: 'error',
					count: 1,
				});
			}
			return errors;
		}, []);

		// Flatten all column.message arrays.
		const flattenedErrors = columns.reduce(
			(acc, {id, messages = []}) => [...acc, ...messages.map(m => ({columnId: id, ...m}))],
			[]
		);

		const allErrors = [...requiredColumnErrors, ...flattenedErrors, ...requiredOptionalColumnErrors];
		this.setState({
			schemaErrors: allErrors,
		});
		return allErrors;
	};

	handleCommitSubmission = () => {
		const url = this.props.dataset._id
			? `/datasets/${this.props.dataset.datasetId}/logs`
			: `/datasets/create/${this.props.submission.categorySchemaName}`;
		submissionsApi.commitSubmission(this.props.submission._id).then(() => this.props.history.push(url));
	};

	cancelSubmission = () => {
		this.showCancelSubmissionToggle();
	};

	handleCancelSubmission = () => {
		this.showCancelSubmissionToggle();
		const status = {
			name: 'cancel',
			progressMessage: null,
			errorMessage: null,
		};

		if (this.props.match.params.datasetId !== 'create') {
			return submissionsApi
				.updateSubmissionStatus(this.props.submission._id, status)
				.then(() => this.props.history.push(`/datasets/${this.props.dataset.datasetId}/submissions`));
		}
		this.props.history.push(`/datasets`);
	};

	showCancelSubmissionToggle = () => {
		this.setState({
			showCancelSubmissionModal: !this.state.showCancelSubmissionModal,
		});
	};

	toggleEditorVisibility = () => {
		this.setState({
			editorCollapsed: !this.state.editorCollapsed,
		});
	};

	toggleResultsVisibility = () => {
		this.setState({
			resultsCollapsed: !this.state.resultsCollapsed,
		});
	};

	handleColumnSave = changes => {
		if (!changes || !changes.id) {
			return;
		}

		const {
			updateColumn,
			validateSchemaColumn,
			submission: {
				categorySchema: {columns = []},
			},
		} = this.props;

		const column = columns.find(c => c.id === changes.id);

		if (changes.customFormat) {
			changes.format = changes.customFormat;
			delete changes.customFormat;
		}

		if (column && !_isMatch(column, changes)) {
			validateSchemaColumn(changes).then(result => {
				if (column.defaultValue === changes.defaultValue) {
					//use the validation result if they weren't updating defaultValue
					changes.defaultValue = result.defaultValue;
				}
				changes.previewData = result.previewData;
				updateColumn(changes).then(() => {
					this.getSchemaErrors();
				});
			});
		}
	};

	isCommitDisabled = () => {
		const {
			submission: {_id, status},
		} = this.props;

		if (!_id) {
			return true;
		}
		if (!status || !status.name) {
			return true;
		}
		if (
			status.progressMessage ||
			status.errorMessage ||
			!['readyToCommit', 'Validation Complete'].includes(status.successMessage)
		) {
			return true;
		}
		if (status.name === 'commit' && status.successMessage) {
			return true;
		}
		if (status.name === 'Commit Complete') {
			return true;
		}
		if (this.state.schemaErrors.find(error => error.level === 'error')) {
			return true;
		}
		return false;
	};

	isCancelDisabled = () => {
		const {
			submission: {_id, status},
		} = this.props;

		if (!_id) {
			return true;
		}
		if (!status || !status.name) {
			return true;
		}
		if (status.progressMessage) {
			return true;
		}
		if (status.name === 'commit' && status.successMessage) {
			return true;
		}
		return false;
	};

	render() {
		const {
			match: {params: routeParams},
			history,
			dataset,
			fetchSubmission,
			categorySchemas = [],
			submission: {snapshotDate = '', categorySchema = {}, categorySchemaName = '', status},
		} = this.props;

		const commitDisabled = this.isCommitDisabled();
		const cancelDisabled = this.isCancelDisabled();

		return (
			<div className="submission">
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						{dataset.name && (
							<React.Fragment>
								<h1
									className="link"
									onClick={() => this.props.history.push('/datasets')}
								>{`Datasets`}</h1>
								<h1>{` > ${dataset.name || ''}`}</h1>
								<h1
									className="link"
									onClick={() => history.push(`/datasets/${dataset.datasetId}/submissions`)}
								>{` > Submissions > `}</h1>
								<h1 className="header-submission-name" title={categorySchemaName}>
									{routeParams.id === 'upload' ? 'New Submission' : `${snapshotDate}`}
								</h1>
							</React.Fragment>
						)}
						{!dataset.name && (
							<React.Fragment>
								<h1
									className="link"
									onClick={() => this.props.history.push('/datasets')}
								>{`Datasets`}</h1>
								<h1>{` > Create`}</h1>
							</React.Fragment>
						)}
					</div>
					<div className="top-bar-buttons">
						<KiButton
							label="Cancel"
							flat
							primary
							onClick={this.cancelSubmission}
							disabled={cancelDisabled}
							title="Click to cancel a submission."
						/>
						<KiButton
							label="Commit"
							raised
							primary
							onClick={this.handleCommitSubmission}
							disabled={commitDisabled}
							title="Click to commit a submission."
						/>
						<SubmissionStatusAvatar status={this.props.submission.status} />
					</div>
				</KiAppBar>
				<SubmissionMessage
					text={status.errorMessage}
					className="submission-message-error"
					active={!!status.errorMessage}
				/>
				<SubmissionMessage
					text={status.progressMessage}
					className="submission-message-progress"
					active={!!status.progressMessage}
				/>
				<SubmissionUpload
					fetchSubmission={fetchSubmission}
					routeToNewSubmission={(submissionId, datasetId) =>
						this.props.history.push(`/datasets/${datasetId}/submission/${submissionId}`)
					}
					submission={this.props.submission}
					categorySchemaList={categorySchemas}
					showSnackbar={this.props.showSnackbar}
					isSubmissionUploading={!!(status.name === 'upload' && status.progressMessage)}
					testNameDateUniqueFunc={categorySchemasApi.testUnique}
					updateSubmissionAndSchema={this.props.updateSubmissionAndSchema}
					params={routeParams}
					dataset={dataset}
				/>
				<SchemaEditor
					collapsed={categorySchema.columns.length ? this.state.editorCollapsed : true}
					toggleCollapsed={categorySchema.columns.length ? this.toggleEditorVisibility : () => null}
					schemaColumns={categorySchema.columns}
					errors={this.state.schemaErrors}
					saveColumn={this.handleColumnSave}
					validateDisabled={commitDisabled}
					isReadOnly={this.props.submission.status.name === 'Commit Complete'}
					validateFullSubmission={this.validateSubmissionData}
				/>
				<SchemaResults
					collapsed={status.name === 'validate' ? this.state.resultsCollapsed : true}
					toggleCollapsed={status.name === 'validate' ? this.toggleResultsVisibility : () => null}
					errors={status.results || []}
					status={status}
				/>
				<KiConfirmModal
					header="Cancel Submission"
					message={<h2>Are you sure you want to cancel?</h2>}
					acceptFunc={this.handleCancelSubmission}
					rejectFunc={this.showCancelSubmissionToggle}
					acceptLabel="Yes"
					rejectLabel="No"
					active={this.state.showCancelSubmissionModal}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	app: state.app,
	submission: state.submission,
	categorySchemas: state.categorySchemas,
	dataset: state.datasetList.data.find(d => d.datasetId === props.match.params.datasetId),
});

const mapDispatchToProps = {
	showSnackbar,
	fetchCategorySchema,
	fetchCategorySchemas,
	fetchSubmission,
	resetSubmission,
	updateColumn,
	validateSchema,
	validateSchemaColumn,
	updateSubmissionAndSchema,
	fetchDataset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Submission);
