import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchAssociatedDataByDatasetId = datasetId => {
	return fetch(`${apiUrl}/associatedData/dataset/${datasetId}`, {
		method: 'GET',
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchAssociatedDataSchemasByDatasetId = datasetId => {
	return fetch(`${apiUrl}/associatedData/schema/dataset/${datasetId}`, {
		method: 'GET',
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchAssociatedDataSchemaById = id => {
	return fetch(`${apiUrl}/associatedData/schema/${id}`, {
		method: 'GET',
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const updateAssociatedDataSchema = schema => {
	return fetch(`${apiUrl}/associatedData/schema`, {
		credentials: 'include',
		method: 'PUT',
		headers: _getHeaders('PUT'),
		body: JSON.stringify(schema),
	}).then(_handleResponse);
};

export const createAssociatedDataSchema = schema => {
	return fetch(`${apiUrl}/associatedData/schema`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(schema),
	}).then(_handleResponse);
};

export const deleteAssociatedData = associatedData => {
	return fetch(`${apiUrl}/associatedData/${associatedData.fileUploadId}`, {
		method: 'DELETE',
		credentials: 'include',
		headers: _getHeaders(),
		body: JSON.stringify(associatedData),
	}).then(_handleResponse);
};

export const preCommitData = associatedData => {
	return fetch(`${apiUrl}/associatedData/preCommitData`, {
		method: 'POST',
		credentials: 'include',
		headers: _getHeaders(),
		body: JSON.stringify(associatedData),
	}).then(_handleResponse);
};

export const commitAssociatedData = id => {
	return fetch(`${apiUrl}/associatedData/camunda/commitAssociatedData/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const resultSummaryAppendData = associatedData => {
	return fetch(`${apiUrl}/associatedData/resultSummaryAppendData`, {
		method: 'POST',
		credentials: 'include',
		headers: _getHeaders(),
		body: JSON.stringify(associatedData),
	}).then(_handleResponse);
};

export const schemaValidation = associatedData => {
	return fetch(`${apiUrl}/associatedData/schemaValidation`, {
		method: 'POST',
		credentials: 'include',
		headers: _getHeaders(),
		body: JSON.stringify(associatedData),
	}).then(_handleResponse);
};

export const dataValidation = associatedData => {
	return fetch(`${apiUrl}/associatedData/dataValidation`, {
		method: 'POST',
		credentials: 'include',
		headers: _getHeaders(),
		body: JSON.stringify(associatedData),
	}).then(_handleResponse);
};

export default {
	fetchAssociatedDataByDatasetId,
	fetchAssociatedDataSchemasByDatasetId,
	fetchAssociatedDataSchemaById,
	deleteAssociatedData,
	preCommitData,
	commitAssociatedData,
	resultSummaryAppendData,
	schemaValidation,
	dataValidation,
	updateAssociatedDataSchema,
	createAssociatedDataSchema,
};
