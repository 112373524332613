import {waterfallApi} from 'api';
import * as actionTypes from './actionTypes';

export const fetchProjectionScenarios = () => dispatch => {
	dispatch({type: actionTypes.FORECASTING_FETCH_SCENARIOS, status: 'pending'});
	return waterfallApi
		.getProjectionScenarios()
		.then(projectionScenarios => {
			dispatch({type: actionTypes.FORECASTING_FETCH_SCENARIOS, status: 'success', data: projectionScenarios});
			return projectionScenarios;
		})
		.catch(error => {
			dispatch({type: actionTypes.FORECASTING_FETCH_SCENARIOS, status: 'error', error: error});
		});
};

export const addProjectionScenario = scenario => dispatch => {
	dispatch({type: actionTypes.FORECASTING_ADD_PROJECTION_SCENARIO, status: 'pending'});
	return waterfallApi
		.createProjectionScenario(scenario)
		.then(result => {
			dispatch({type: actionTypes.FORECASTING_ADD_PROJECTION_SCENARIO, status: 'success', data: result});
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Scenario Added'}});
			return result;
		})
		.catch(error => {
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Error Occurred Adding Scenario'}, error: error});
		});
};

export const updateProjectionScenario = scenario => dispatch => {
	dispatch({type: actionTypes.FORECASTING_UPDATE_PROJECTION_SCENARIO, status: 'pending'});
	return waterfallApi
		.saveProjectionScenario(scenario)
		.then(result => {
			dispatch({type: actionTypes.FORECASTING_UPDATE_PROJECTION_SCENARIO, status: 'success', data: result});
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Scenario Updated'}});
			return result;
		})
		.catch(error => {
			dispatch({type: actionTypes.FORECASTING_UPDATE_PROJECTION_SCENARIO, status: 'error', error: error});
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Error Updating Scenario'}, error: error});
		});
};

export const deleteProjectionScenario = scenarioId => dispatch => {
	dispatch({type: actionTypes.FORECASTING_DELETE_PROJECTION_SCENARIO, status: 'pending'});
	return waterfallApi
		.deleteProjectionScenario(scenarioId)
		.then(() => {
			dispatch({type: actionTypes.FORECASTING_DELETE_PROJECTION_SCENARIO, status: 'success', data: scenarioId});
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Deleted Scenario'}});
			return scenarioId;
		})
		.catch(error => {
			dispatch({type: actionTypes.FORECASTING_DELETE_PROJECTION_SCENARIO, status: 'error', error: error});
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Error Deleting Scenario'}, error: error});
		});
};

// It is not needed when display mode is switched with url
export const setScenarioDisplayMode = mode => dispatch => {
	dispatch({type: actionTypes.FORECASTING_SCENARIO_DISPLAY_MODE, mode});
};

export default {
	fetchProjectionScenarios,
	addProjectionScenario,
	updateProjectionScenario,
	deleteProjectionScenario,
	setScenarioDisplayMode,
};
