// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// Project imports
import options from 'ki-common/options';

// Website imports
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import KiSelect from 'components/KiSelect';

// Local imports
import ConstraintHelpers from './constraintHelpers';
import styles from './constraintFormStyles.theme.scss';
import ccBalanceLimit from './validators/ccBalanceLimit';
import validators from 'ki-common/validators';

const constraints = ccBalanceLimit.getConstraints();

const BalanceLimitForm = props => {
	const {isSaveClick} = props;

	const [formError, setFormError] = useState('');
	const [columnOptions, setColumnOptions] = useState([]);

	const isDateValue = ['date_long', 'date_short'].includes(props.constraintData.dataColumn?.dataType);

	useEffect(
		() => {
			setColumnOptions(
				ConstraintHelpers.getCohortColumns(
					props.dataColumns,
					props.constraintData.dataColumn,
					true, // isNew
					props.isGroupBy,
					true // onlyNumeric,
				)
			);
		},
		[props.dataColumns, props.constraintData.dataColumn, props.isGroupBy]
	);

	useEffect(
		() => {
			props.updateFormHasError(!!formError);
		},
		[props.updateFormHasError, formError]
	);

	useEffect(
		() => {
			if (isSaveClick) {
				setFormError(validators.validate(props.constraintData, constraints));
			}
		},
		[props.constraintData, isSaveClick]
	);

	return (
		<div className={styles.root}>
			<div className={styles.constraintFormBody}>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Data Column</span>
					<KiSelect
						isDisabled={props.isReadOnly}
						classNamePrefix="aut-select"
						value={props.constraintData.dataColumn}
						isLoading={props.loadingColumns}
						isClearable={false}
						options={columnOptions}
						onChange={dc => props.setConstraintItem('dataColumn', dc)}
						getOptionLabel={option => option.detailedDisplayName}
						getOptionValue={option => option._id}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<span className="theme-label">Logic</span>
					<KiSelect
						isDisabled={!props.constraintData.dataColumn || props.isReadOnly}
						classNamePrefix="aut-select"
						value={props.constraintData.logic}
						isClearable={false}
						options={options.balanceLimitOptions}
						onChange={l => props.setConstraintItem('logic', l)}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<KiInput
						disabled={props.isReadOnly}
						type="text"
						name="name"
						label={isDateValue ? 'YYYY-MM-DD' : 'Target'}
						isNumberMasked={!isDateValue}
						value={props.constraintData.target && props.constraintData.target.toString()}
						onChange={t => props.setConstraintItem('target', [t])}
						error={formError?.target}
					/>
				</div>
				<div className={styles.selectWrapper}>
					<KiCheckbox
						disabled={props.isReadOnly}
						checked={props.constraintData.excludeZeroBalance}
						label="Exclude zero balance"
						onChange={val => props.setConstraintItem('excludeZeroBalance', val)}
					/>
				</div>
			</div>
		</div>
	);
};

BalanceLimitForm.propTypes = {
	constraintData: PropTypes.object,
	setConstraintItem: PropTypes.func.isRequired,
	dataColumns: PropTypes.array,
	loadingColumns: PropTypes.bool,
	isGroupBy: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	updateFormHasError: PropTypes.func.isRequired,
	isSaveClick: PropTypes.bool,
};

BalanceLimitForm.defaultProps = {
	constraintData: {},
	dataColumns: [],
};

export default BalanceLimitForm;
