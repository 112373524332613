import PropTypes from 'prop-types';
import React from 'react';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';

export const CohortSelector = props => {
	if (!props.columns) return null;
	const columnType = props.isGroupBy ? 'aggregate' : 'cohort';
	const firstCohortColumn = props.columns.find(c => c.columnType === columnType);
	const params = {
		options: [],
		className: 'cohort-selector',
		targetFunction: props.targetFunction,
		startingValue: props.selectedColumn || firstCohortColumn?._id,
		sortOrder: 'asc',
		searchable: true,
		backspaceRemoves: false,
		matchProp: 'label',
		disabled: props.disabled || false,
	};

	params.options = (props.columns || []).filter(c => c.columnType === columnType).map(columnData => ({
		value: columnData._id,
		label: props.isGroupBy ? `${columnData.displayName} - ${columnData.calculation}` : columnData.displayName,
	}));
	return <TriggerFunctionSelector {...params} />;
};

CohortSelector.propTypes = {
	selectedColumn: PropTypes.string,
	columns: PropTypes.array,
	targetFunction: PropTypes.func,
	isGroupBy: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default CohortSelector;
