// Dependencies
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

// Externals
import '../columnpicker.scss';
import KiButton from 'components/KiButton';
import KiCheckbox from 'components/KiCheckbox';
import KiInput from 'components/KiInput';
import {KiCreatable} from 'components/KiSelect';
import options from 'ki-common/options';
import stringHelpers from 'ki-common/utils/stringHelpers';
import MiniCreator from '../MiniCreator';
import NumericOperandForm from './components/NumericOperandForm';
import NumericOperandPreview from './components/NumericOperandPreview';
import NumericOperandList from './components/NumericOperandList';

export class NumericForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		columnList: PropTypes.array.isRequired,
		existingTags: PropTypes.array.isRequired,
		columnToEdit: PropTypes.object,
		mode: PropTypes.string,
		closeForm: PropTypes.func,
		isAdmin: PropTypes.bool,
		reloadView: PropTypes.func,
		noNewColumn: PropTypes.bool,
		schemaList: PropTypes.array.isRequired,
		getAggregationOptions: PropTypes.func,
	};

	static defaultProps = {
		existingTags: [],
	};

	state = {
		calculationOperands: [],
		closingGroup: [],
		constant: '',
		constantError: null,
		displayFormat: null,
		displayName: '',
		editingOperandId: null,
		functionType: null,
		functionValues: [],
		isGlobal: this.props.columnToEdit && this.props.columnToEdit.createdBy === '',
		logic: {label: '+', value: '+'},
		nameError: null,
		numericColumns: [],
		openingGroup: [],
		openedOperatorPicker: false,
		operandType: options.comparisonValueTypes[0],
		operandValue: null,
		selectedOperatorColumn: null,
		selectedOperatorRow: null,
		showOperatorPicker: true,
		tags: [],
		rowsToRender: [],
		formulaPreview: '',
		aggregation: '',
		associatedSchemaColumns: [],
		aggregationOptions: [],
		selectedSchema: null,
	};

	componentDidMount() {
		const {columnToEdit, columnList} = this.props;
		const numericAssetColumns = columnList.filter(
			col =>
				col.columnType === 'asset' &&
				col.dataType === 'numeric' &&
				col._id !== _get(columnToEdit, '_id') &&
				(_get(columnToEdit, 'schemaId', null) !== null ? !col.isSubaccount : true)
		);
		this.setState({numericColumns: numericAssetColumns});
		const aggregationOptions = this.props.getAggregationOptions('numeric');
		this.setState({aggregationOptions: aggregationOptions});
		this.setSchemaState(this.props.schemaList, _get(this.props, 'columnToEdit.schemaId'));
		if (columnToEdit) {
			this.setState(() => {
				let stateChanges = {
					numericColumns: numericAssetColumns,
				};
				if (!_isEmpty(columnToEdit)) {
					stateChanges = {
						displayName: columnToEdit.displayName,
						displayFormat: options.numericFormats.find(opt => opt.value === columnToEdit.displayFormat),
						tags: columnToEdit.tags,
						calculationOperands: this.prePopulateFormulaTable(columnToEdit, this.props),
						aggregation: aggregationOptions.find(arg => arg.value === columnToEdit.aggregation),
					};
				}
				return stateChanges;
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps.columnList, this.props.columnList)) {
			const numericAssetColumns = this.props.columnList.filter(
				col =>
					col.columnType === 'asset' &&
					col.dataType === 'numeric' &&
					col._id !== _get(this.props, 'columnToEdit._id') &&
					(this.state.selectedSchema !== null ? !col.isSubaccount : true)
			);

			this.setState({numericColumns: numericAssetColumns});
		}
		if (!_isEqual(prevProps.schemaList, this.props.schemaList)) {
			this.setSchemaState(this.props.schemaList, _get(prevProps, 'columnToEdit.schemaId'));
			//this.setState({calculationOperands: this.prePopulateFormulaTable(columnToEdit, nextProps)});
		}
	}

	// This method is only run when editing a formula and reformats the formula array
	// to be compatible with formula rows
	prePopulateFormulaTable = (columnToEdit, nextProps) => {
		const formula = columnToEdit.formula;
		if (!formula) {
			return [];
		}
		const operatorArray = ['+', '/', '-', '*', '^'];
		const formattedRows = [];
		let rowIndex = 0;

		// if the first item in the formula is not an operator add one
		// this can occur in some formulas that were migrated... all new formulas should be ok
		// this will also persist and fix the column going forward if saved
		if (formula && formula[0]) {
			if (formula[0].type !== 'operator') {
				formula.unshift({type: 'operator', value: '+'});
			}
		}

		// Reformat formula to be a nested array
		for (let i = 0; i < formula.length; i++) {
			const formulaItem = formula[i];

			// When we have a new logical operator (ie. +, -, *, /) make a new row
			if (operatorArray.includes(formulaItem.value)) {
				// Keep index at 0 if first operator is detected
				if (i !== 0) {
					rowIndex += 1;
				}

				formattedRows.push({
					// Can't use i for id since not all items make a new row
					id: rowIndex,
					logic: '',
					value: undefined,
					closingGroup: [],
					openingGroup: [],
				});
			}

			// Ensure last item in formattedRows is populated with values in rowToPopulate
			const rowToPopulate = formattedRows[formattedRows.length - 1];
			// Populate formulaItem column
			if (formulaItem.type !== 'operator' && formulaItem.type !== 'min' && formulaItem.type !== 'max') {
				//Populate if column
				const foundColumn = this.props.columnList.find(column => column._id === formulaItem.value);
				rowToPopulate.value = foundColumn;
				rowToPopulate.type = {label: 'Column', value: 'column'};
				rowToPopulate.functionType = null;

				// Populate if constant
				if (!foundColumn) {
					rowToPopulate.value = formulaItem.value;
					rowToPopulate.type = {label: 'Numeric Constant', value: 'numeric'};
				}
			}
			if (formulaItem.type === 'subAccountColumn') {
				let foundColumnDetail = null;
				if (nextProps.schemaList.length > 0) {
					const schemaDetail = nextProps.schemaList.find(c => c._id === columnToEdit.schemaId);
					if (schemaDetail) {
						foundColumnDetail = schemaDetail.schemaColumns.find(col => col.id === formulaItem.value);
						rowToPopulate.value = foundColumnDetail;
						rowToPopulate.functionType = null;
						rowToPopulate.type = {label: 'Child Column', value: 'subAccountColumn'};
					}
				}
				if (!foundColumnDetail) {
					rowToPopulate.value = formulaItem.value;
					rowToPopulate.type = {label: 'Numeric Constant', value: 'numeric'};
				}
			}

			if (formulaItem.type === 'min') {
				// type: "min"
				// value: (2) ["5c3f7a2c082b6b3d7adffbf8", "5c3f7a2c082b6b3d7adffbe9"]
				const IDs = formulaItem.value.map(formulaItem => formulaItem);
				const columns = IDs.map(id => this.props.columnList.find(column => column._id === id));

				rowToPopulate.value = columns;
				rowToPopulate.functionType = {label: 'Min', value: 'min'};
				rowToPopulate.type = {label: 'Function', value: 'function'};
			}

			if (formulaItem.type === 'max') {
				const IDs = formulaItem.value.map(formulaItem => formulaItem);

				// type: "max"
				// value: (2) ["5c3f7a2c082b6b3d7adffbf8", "5c3f7a2c082b6b3d7adffbe9"]
				const columns = IDs.map(id => this.props.columnList.find(column => column._id === id));

				rowToPopulate.value = columns;
				rowToPopulate.functionType = {label: 'Max', value: 'max'};
				rowToPopulate.type = {label: 'Function', value: 'function'};
			}

			if (formulaItem.value === '(') {
				rowToPopulate.openingGroup.push({
					type: 'operator',
					value: formulaItem.value,
				});
			}

			if (formulaItem.value === ')') {
				rowToPopulate.closingGroup.push({
					type: 'operator',
					value: formulaItem.value,
				});
			}

			// Update logic string value
			if (['+', '-', '/', '*', '^'].includes(formulaItem.value)) {
				rowToPopulate.logic = {
					type: 'operator',
					value: formulaItem.value,
				};
			}
		}

		return formattedRows;
	};

	setName = val => {
		let nameError = null;

		if (!val.trim().length) {
			nameError = "Name can't be blank";
		} else if (!val.match(stringHelpers.alphanumericRegex)) {
			nameError = 'Name must be Alphanumeric!';
		}

		this.setState({
			displayName: val,
			nameError: nameError,
		});
	};

	isBalancedParentheses = arr => {
		return !arr.reduce((previous, current) => {
			if (current === '(') {
				return ++previous;
			}
			if (current === ')') {
				return --previous;
			}
			return previous;
		}, 0);
	};

	disableSave = () => {
		const {calculationOperands, displayName, selectedSchema, aggregation, isSaving} = this.state;
		// Check if all rows contain a logical operator.
		const validLogic = calculationOperands
			.map(operand => ['+', '-', '/', '*', '^'].includes(_get(operand, 'logic.value')))
			.every(operand => operand);

		const bracesArray = [];

		calculationOperands.forEach(operand => {
			operand.openingGroup.forEach(openingParens => {
				if (_get(openingParens, 'value')) {
					bracesArray.push(_get(operand, 'openingGroup[0].value'));
				}
			});

			operand.closingGroup.forEach(closingParens => {
				if (_get(closingParens, 'value')) {
					bracesArray.push(_get(operand, 'closingGroup[0].value'));
				}
			});
		});

		// Check of parentheses are balanced, each closing is preceded by an opening
		const balancedParentheses = this.isBalancedParentheses(bracesArray);

		// if a schema is selected an aggregation must also be
		const validSchema = selectedSchema ? !!aggregation : true;

		// Disable save button if there are no calculationOperands, no displayName, no valid logic, and no even number of opening and closing parentheses.
		return (
			_isEmpty(calculationOperands) ||
			!displayName.trim() ||
			!validLogic ||
			!balancedParentheses ||
			!validSchema ||
			isSaving
		);
	};

	isDuplicateNameError = () => {
		if (_get(this, 'props.columnToEdit.displayName') === this.state.displayName) {
			return;
		}

		const assetCols = this.props.columnList.filter(
			col => (!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset'
		);
		// Check if the name already exists in assetCols
		return assetCols.find(col => _get(col, 'displayName').trim() === this.state.displayName.trim());
	};

	onSubmit = () => {
		const {columnToEdit, submitMethod} = this.props;
		const {
			displayName,
			tags,
			calculationOperands,
			displayFormat,
			isGlobal,
			aggregation,
			selectedSchema,
		} = this.state;

		this.setState({isSaving: true});

		if (!displayName.trim()) {
			this.setState({nameError: 'Name cant be blank', isSaving: false});
			return;
		}

		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		}

		if (!this.isDuplicateNameError()) {
			this.setState({nameError: ''});
		}

		const formulaArray = [];
		let operandToAdd;

		// Format formula to match the expected pattern.
		this.state.calculationOperands.forEach(operand => {
			if (_get(operand, 'type.value') === 'numeric') {
				operandToAdd = {type: 'constant', value: operand.value};
			}

			// When editing an existing calculation, and editing a column, constants were overridden. This should fix.
			if (_get(operand, 'value.type') === 'constant') {
				operandToAdd = {type: 'constant', value: _get(operand, 'value.value')};
			}

			if (_get(operand, 'type.value') === 'column') {
				operandToAdd = {type: 'column', value: _get(operand, 'value._id')};
			}

			if (_get(operand, 'type.value') === 'subAccountColumn') {
				operandToAdd = {type: 'subAccountColumn', value: _get(operand, 'value.id')};
			}
			//functionType.value === 'min' or 'max'
			// Expected {type: 'min', value: [_id, _id, _id]}
			if (_get(operand, 'type.value') === 'function') {
				const columnArray = [];
				// Push ids to array
				operand.value.map(column => columnArray.push(column._id)._id);
				operandToAdd = {type: _get(operand.functionType, 'value'), value: columnArray};
			}

			formulaArray.push({type: 'operator', value: operand.logic.value});
			operand.openingGroup.map(parens => formulaArray.push(parens));
			formulaArray.push(operandToAdd);
			operand.closingGroup.map(parens => formulaArray.push(parens));
		});

		const columnForSaving = {
			_id: columnToEdit ? columnToEdit._id : null,
			calculationOperands,
			columnFormType: 'numeric',
			columnName: displayName,
			columnType: 'asset',
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			dataType: 'numeric',
			schemaId: selectedSchema ? selectedSchema._id : null,
			aggregation: aggregation ? aggregation.value : null,
			displayFormat: _get(displayFormat, 'value', options.numericFormats[0].value),
			displayName,
			formula: formulaArray,
			isGlobal: isGlobal,
			tags,
		};

		if (!displayName.trim()) {
			this.setState({nameError: "Name can't be blank", isSaving: false});
			return;
		}

		return submitMethod(columnForSaving);
	};

	// If true, it is disabled
	disableAdd = () => {
		const {functionValues, operandValue, constant, constantError} = this.state;
		// Disable save button when functionValues is empty, if OperandValue is empty, f there is no constant, or if there is a constantError.
		return _get(functionValues, 'length') === 0 && !operandValue && (!constant || constantError);
	};

	hideOperatorPicker = () => {
		if (this.state.openedOperatorPicker) {
			this.setState({
				showOperatorPicker: false,
				openedOperatorPicker: false,
			});
		}
	};
	handleSchemaChange = val => {
		this.setSchemaState(this.props.schemaList, val ? val._id : null);
		const numericAssetColumns = this.props.columnList.filter(
			col =>
				col.columnType === 'asset' &&
				col.dataType === 'numeric' &&
				col._id !== _get(this.props, 'columnToEdit._id') &&
				(val !== null ? !col.isSubaccount : true)
		);

		this.setState({numericColumns: numericAssetColumns, calculationOperands: []});
	};

	setAggregation = val => {
		this.setState({aggregation: val});
	};

	setSchemaState = (schemaList, schemaId) => {
		const selectedSchema = schemaList.find(s => s._id === schemaId) || null;
		this.setState({
			associatedSchemaColumns: selectedSchema
				? selectedSchema.schemaColumns.filter(col => _get(col, 'dataType') === 'numeric')
				: [],
			selectedSchema: selectedSchema || null,
		});
	};

	render() {
		const {displayName, nameError, selectedSchema, aggregation, aggregationOptions} = this.state;

		return (
			<Fragment>
				<form className="column-selector-form inline-column-form" onClick={this.hideOperatorPicker}>
					<div>
						<section className="calculation-name-tags">
							<KiInput
								label="Name"
								value={displayName}
								onChange={val => this.setName(val)}
								error={nameError}
							/>
							<span className="form-instruction">Tags:</span>
							<KiCreatable
								classNamePrefix="aut-select"
								isMulti={true}
								options={this.props.existingTags.map(t => ({
									value: t,
									label: t,
								}))}
								value={this.state.tags.map(t => ({
									value: t,
									label: t,
								}))}
								onChange={val => this.setState({tags: val.map(t => t.value)})}
								placeholder="Add some tags"
							/>
							<div className="calculation-form-section">
								<span className="form-instruction">Choose a Schema:</span>
								<Select
									classNamePrefix="aut-select"
									options={this.props.schemaList}
									value={selectedSchema}
									getOptionLabel={option => option.name}
									getOptionValue={option => option._id}
									onChange={selected => this.handleSchemaChange(selected)}
									onBlur={() => this.setState({schemaError: ''})}
								/>
							</div>
							{selectedSchema &&
								selectedSchema._id && (
									<div className="calculation-form-section">
										<span className="form-instruction">Aggregation Type:</span>
										<Select
											classNamePrefix="aut-select"
											options={aggregationOptions}
											isClearable={true}
											value={aggregation}
											onChange={val => this.setAggregation(val)}
										/>
									</div>
								)}

							<div className="calculation-form-section">
								<span className="form-instruction">Format:</span>
								<Select
									classNamePrefix="aut-select"
									value={this.state.displayFormat}
									isClearable={false}
									options={options.numericFormats}
									onChange={selected => this.setState({displayFormat: selected})}
								/>
							</div>
						</section>
						<NumericOperandForm
							setParentState={this.setState.bind(this)}
							state={this.state}
							calculationOperands={this.state.calculationOperands}
							disableAdd={this.disableAdd()}
							noNewColumn={!!this.props.noNewColumn}
							columnToEdit={this.props.columnToEdit}
							includeSubAccountOperand={!!selectedSchema}
						/>
						<NumericOperandPreview
							calculationOperands={this.state.calculationOperands}
							columnList={this.props.columnList}
							selectedSchema={selectedSchema}
						/>

						<NumericOperandList
							onDragEnd={(calculationOperands = []) =>
								this.setState({
									calculationOperands,
									formulaPreview: [],
								})
							}
							calculationOperands={this.state.calculationOperands}
							selectOperatorInputField={(idx, column) => {
								this.setState({
									openedOperatorPicker: true,
									showOperatorPicker: true,
									selectedOperatorRow: idx,
									selectedOperatorColumn: column,
								});
							}}
							selectNewOperator={(calculationOperands = []) => {
								this.setState({
									showOperatorPicker: false,
									calculationOperands,
								});
							}}
							editOperand={state => this.setState(state)}
							deleteOperand={(calculationOperands = []) => {
								this.setState({
									calculationOperands: calculationOperands,
									selectedOperatorColumn: null,
									selectedOperatorRow: null,
								});
							}}
							selectedOperatorColumn={this.state.selectedOperatorColumn}
							selectedOperatorRow={this.state.selectedOperatorRow}
							showOperatorPicker={this.state.showOperatorPicker}
						/>
					</div>
					{this.props.mode === 'inline' &&
						this.props.isAdmin &&
						!_get(this, 'props.columnToEdit._id', null) && (
							<div className="calculation-form-section">
								<KiCheckbox
									name="isGlobal"
									checked={this.state.isGlobal}
									label="Make Global"
									onChange={val => this.setState({isGlobal: val})}
								/>
							</div>
						)}
					<section className="format-and-save">
						<div className="inline-column-form-buttons">
							<KiButton flat primary onClick={() => this.props.closeForm()}>
								Cancel
							</KiButton>
							<KiButton disabled={this.disableSave()} raised primary onClick={() => this.onSubmit()}>
								Save
							</KiButton>
						</div>
					</section>
				</form>
				{_get(this.state, 'operandValue._id') === 'newColumn' && (
					<MiniCreator
						noNewColumn={true}
						columnType={'numeric'}
						onCancel={() => this.setState({operandValue: {}})}
						onSave={newId => {
							const column = this.props.columnList.find(c => newId === c._id);
							if (column) {
								this.setState({operandValue: column});
							}
						}}
						reloadView={this.props.reloadView}
					/>
				)}
			</Fragment>
		);
	}
}

export default NumericForm;
