import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const getSnapshotsForDatasetId = datasetId => {
	return fetch(`${apiUrl}/datasetHiddenSnapshots/${datasetId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

/*
export const hideSnapshots = (datasetId, snapshotDate) => {
	return fetch(`${apiUrl}/datasetHiddenSnapshots/hide`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId,
			snapshotDate,
		}),
	}).then(_handleResponse);
};

export const unhideSnapshots = (datasetId, snapshotDate) => {
	return fetch(`${apiUrl}/datasetHiddenSnapshots/unhide`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId,
			snapshotDate,
		}),
	}).then(_handleResponse);
};
*/
export const previewDependencies = (datasetId, scenarioId) => {
	const url = scenarioId
		? `${apiUrl}/datasetHiddenSnapshots/dependencies/${datasetId}/${scenarioId}`
		: `${apiUrl}/datasetHiddenSnapshots/dependencies/${datasetId}`;
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const extract = (datasetId, scenarioId) => {
	const url = `${apiUrl}/datasetHiddenSnapshots/dependencies/${datasetId}/${scenarioId}`;
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const rollbackScenario = (datasetId, scenarioId, transferDate) => {
	const url = `${apiUrl}/datasetHiddenSnapshots/rollback`;
	return fetch(url, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId,
			scenarioId,
			transferDate,
		}),
	}).then(_handleResponse);
};

export default {
	getSnapshotsForDatasetId,
	previewDependencies,
	extract,
	rollbackScenario,
};
