import React from 'react';
import PropTypes from 'prop-types';
import styles from './KiCardFilters.theme.scss';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import Select from 'react-select';
import KiDatePicker from 'components/KiDatePicker';

function KiCardForecastingFilters(props) {
	const {
		entities,
		entity,
		subEntity,
		subEntities,
		statementDate,
		setSubEntity,
		setStatementDate,
		setEntityView,
	} = props;

	return (
		<section className={styles.cardFilters}>
			<div className={styles.double}>
				<span className="form-instruction">Entity:</span>
				<Select
					classNamePrefix="aut-select"
					value={entities.find(e => e.value === entity) || null}
					isClearable={false}
					options={entities}
					onChange={option => setEntityView(option.value)}
				/>
			</div>
			<div className={styles.double}>
				{subEntities.length > 0 ? (
					<div>
						<span className="form-instruction">Sub Entity:</span>
						<Select
							classNamePrefix="aut-select"
							isSearchable={true}
							options={subEntities}
							value={subEntity}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.value}
							onChange={option => setSubEntity(option)}
							isDisabled={subEntities.length === 0}
						/>
					</div>
				) : (
					<div />
				)}
			</div>
			<div className={styles.single}>
				<span className="form-instruction">Statement Date</span>
				<KiDatePicker
					className={styles.filterDatePicker}
					value={statementDate}
					onChange={val => setStatementDate(dateToShortDate(val))}
				/>
			</div>
		</section>
	);
}

KiCardForecastingFilters.propTypes = {
	entities: PropTypes.array,
	entity: PropTypes.string,
	subEntities: PropTypes.array,
	subEntity: PropTypes.object,
	statementDate: PropTypes.string,
	setSubEntity: PropTypes.func,
	setStatementDate: PropTypes.func,
	setEntityView: PropTypes.func,
};

KiCardForecastingFilters.defaultProps = {
	statementDate: dateToShortDate(new Date()),
	scenarioList: [],
	entities: [],
	entity: '',
	subEntities: [],
	subEntity: '',
};

export default KiCardForecastingFilters;
