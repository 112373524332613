import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import KiTabs from '../../../components/KiTabs';
import KiTab from '../../../components/KiTabs/KiTab';
import ViewSettings from './ViewSettings';
import ColumnSelection from './ColumnSelection';
import KiButton from '../../../components/KiButton';
import styles from './ContextSidebar.theme.scss';
import _pick from 'lodash/pick';
import {
	applyChangesToView,
	setFundingVehicleQuickFilter,
	fetchDebtData,
	setCohortColumn,
	setScenario,
} from '../actions';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';

class EditViewMenu extends React.Component {
	static propTypes = {
		view: PropTypes.object,
		tags: PropTypes.array,
		viewColumns: PropTypes.shape({
			trust: PropTypes.array,
			fundingVehicle: PropTypes.array,
			pool: PropTypes.array,
			debt: PropTypes.array,
			creditSupport: PropTypes.array,
			fee: PropTypes.array,
			trigger: PropTypes.array,
			waterfall: PropTypes.array,
			counterparty: PropTypes.array,
			counterpartyRole: PropTypes.array,
		}),
		settings: PropTypes.shape({
			cohortColumn: PropTypes.string,
			viewType: PropTypes.string,
			quickFilters: PropTypes.shape({
				scenarioType: PropTypes.string,
			}),
		}),
		applyChangesToView: PropTypes.func,
		fundingVehicleList: PropTypes.array,
		setFundingVehicleQuickFilter: PropTypes.func,
		setCohortColumn: PropTypes.func,
		fetchDebtData: PropTypes.func,
		closeFlyoutPanel: PropTypes.func,
		match: PropTypes.object,
		setScenario: PropTypes.func,
	};

	static defaultProps = {
		view: {},
		tags: [],
		settings: {},
		viewColumns: {
			trust: [],
			fundingVehicle: [],
			pool: [],
			debt: [],
			creditSupport: [],
			fee: [],
			trigger: [],
			waterfall: [],
			counterparty: [],
			counterpartyRole: [],
		},
		fundingVehicleList: [],
	};

	constructor(props) {
		super(props);
		this.state = {
			name: props.view.name,
			isDefault: !!props.view.isDefault,
			isFavorite: !!props.view.isFavorite,
			isGlobal: !!props.view.isGlobal,
			settings: props.settings,
			trust: props.viewColumns.trust,
			fundingVehicle: props.viewColumns.fundingVehicle,
			pool: props.viewColumns.pool,
			debt: props.viewColumns.debt,
			creditSupport: props.viewColumns.creditSupport,
			fee: props.viewColumns.fee,
			trigger: props.viewColumns.trigger,
			waterfall: props.viewColumns.waterfall,
			counterparty: props.viewColumns.counterparty,
			counterpartyRole: props.viewColumns.counterpartyRole,
			tags: props.tags,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.viewColumns !== this.props.viewColumns) {
			this.setState({
				trust: this.props.viewColumns.trust,
				fundingVehicle: this.props.viewColumns.fundingVehicle,
				pool: this.props.viewColumns.pool,
				debt: this.props.viewColumns.debt,
				creditSupport: this.props.viewColumns.creditSupport,
				fee: this.props.viewColumns.fee,
				trigger: this.props.viewColumns.trigger,
			});
		}
	}

	applyChangesToState = (sectionKey, value) => {
		return this.setState({
			[sectionKey]: value,
		});
	};

	applySettingChangeToState = (key, value) => {
		if (['name', 'isFavorite', 'isDefault', 'isGlobal', 'tags'].includes(key)) {
			return this.setState({[key]: value});
		}
		return this.setState({
			settings: {
				...this.state.settings,
				[key]: value,
			},
		});
	};

	handleCancel = () => {
		this.setState(
			{
				settings: this.props.settings,
				name: this.props.view.name,
				isDefault: !!this.props.view.isDefault,
				isFavorite: !!this.props.view.isFavorite,
				isGlobal: !!this.props.view.isGlobal,
				trust: this.props.viewColumns.trust,
				fundingVehicle: this.props.viewColumns.fundingVehicle,
				pool: this.props.viewColumns.pool,
				debt: this.props.viewColumns.trust,
				tags: this.props.tags,
				creditSupport: this.props.viewColumns.creditSupport,
				fee: this.props.viewColumns.fee,
				trigger: this.props.viewColumns.trigger,
			},
			() => this.props.closeFlyoutPanel()
		);
	};

	applyChangesToView = () => {
		const changingViewType = this.state.settings.viewType !== this.props.settings.viewType;
		this.props.applyChangesToView(
			this.state.settings,
			_pick(this.state, [
				'trust',
				'fundingVehicle',
				'pool',
				'debt',
				'creditSupport',
				'fee',
				'trigger',
				'waterfall',
				'counterparty',
				'counterpartyRole',
			]),
			this.state.name,
			!!this.state.isDefault,
			!!this.state.isFavorite,
			this.state.tags || [],
			false
		);
		if (changingViewType) {
			// if changing view type to activity, you must set a funding vehicle id
			if (
				this.state.settings.viewType === 'activity' &&
				!this.state.settings.fundingVehicle &&
				_.has(this.props.fundingVehicleList, '0._id')
			) {
				this.props.setFundingVehicleQuickFilter(this.props.fundingVehicleList[0]._id, false);
			} else {
				// if new view type isn't activity, then clear the fv filter to show all fvs
				this.props.setFundingVehicleQuickFilter(null, false);
			}
			// set the cohort column + view type to set the correct columns for the view
			const newCohrt =
				this.state.settings.viewType === 'role'
					? 'counterpartyRole'
					: this.state.settings.cohortColumn === 'counterpartyRole'
						? 'counterparty'
						: this.state.settings.cohortColumn;
			this.props.setCohortColumn(newCohrt || 'fundingVehicle', this.state.settings.viewType, false);
		}
		// If scenarioType is lastCommitted and you're no longer in pool transaction, revert back to lastApproved.
		const {cohortColumn, viewType, quickFilters: {scenarioType} = {}} = this.state.settings;
		if (scenarioType === 'lastCommitted' && (cohortColumn !== 'pool' || viewType !== 'transaction')) {
			this.props.setScenario('lastApproved');
		} else if (scenarioType === 'lastApproved' && (cohortColumn === 'pool' && viewType === 'transaction')) {
			this.props.setScenario('lastCommitted');
		}
		// fetch the data now that all filters are set
		this.props.fetchDebtData();

		this.props.closeFlyoutPanel();
	};

	render() {
		return (
			<div className={styles.editViewWrapper}>
				<p style={{textTransform: 'uppercase'}} className={'flyout-panel-title'}>
					Manage Views
				</p>
				<KiTabs>
					<KiTab title="Settings">
						<ViewSettings
							settings={this.props.settings}
							key="settings"
							viewType={this.state.settings.viewType}
							assetDateContext={this.state.settings.assetDateContext}
							debtDateContext={this.state.settings.debtDateContext}
							cohortColumn={this.state.settings.cohortColumn}
							viewName={this.state.name}
							tags={this.state.tags}
							isFavorite={this.state.isFavorite}
							isDefault={this.state.isDefault}
							onChange={this.applySettingChangeToState}
						/>
					</KiTab>
					<KiTab title="Trust">
						<ColumnSelection
							settings={this.props.settings}
							key="trust"
							viewType="trust"
							bookmarkColumns={this.state.trust}
							onChange={this.applyChangesToState.bind(this, 'trust')}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Funding Vehicle">
						<ColumnSelection
							settings={this.props.settings}
							key="fundingVehicle"
							viewType={'fundingVehicle'}
							bookmarkColumns={this.state.fundingVehicle}
							onChange={this.applyChangesToState.bind(this, 'fundingVehicle')}
							onChangeViewType={this.applySettingChangeToState}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Pool">
						<ColumnSelection
							settings={this.props.settings}
							key="pool"
							viewType="pool"
							bookmarkColumns={this.state.pool}
							onChange={this.applyChangesToState.bind(this, 'pool')}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Tranche">
						<ColumnSelection
							settings={this.props.settings}
							key="debt"
							viewType="debt"
							bookmarkColumns={this.state.debt}
							onChange={this.applyChangesToState.bind(this, 'debt')}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Accounts">
						<ColumnSelection
							settings={this.props.settings}
							key="creditSupport"
							viewType="creditSupport"
							bookmarkColumns={this.state.creditSupport}
							onChange={this.applyChangesToState.bind(this, 'creditSupport')}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Fee">
						<ColumnSelection
							settings={this.props.settings}
							key="fee"
							viewType="fee"
							bookmarkColumns={this.state.fee}
							onChange={this.applyChangesToState.bind(this, 'fee')}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Trigger">
						<ColumnSelection
							settings={this.props.settings}
							key="trigger"
							viewType="trigger"
							bookmarkColumns={this.state.trigger}
							onChange={this.applyChangesToState.bind(this, 'trigger')}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Waterfall">
						<ColumnSelection
							settings={this.props.settings}
							key="waterfall"
							viewType={'waterfall'}
							bookmarkColumns={this.state.waterfall}
							onChange={this.applyChangesToState.bind(this, 'waterfall')}
							onChangeViewType={this.applySettingChangeToState}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
					<KiTab title="Counterparties">
						<ColumnSelection
							settings={this.props.settings}
							key="counterparty"
							viewType="counterparty"
							bookmarkColumns={
								this.state.settings.viewType === 'role'
									? this.state.counterpartyRole
									: this.state.counterparty
							}
							onChange={this.applyChangesToState.bind(
								this,
								this.state.settings.viewType === 'role' ? 'counterpartyRole' : 'counterparty'
							)}
							datasetId={this.props.match.params.datasetId}
						/>
					</KiTab>
				</KiTabs>
				<footer className={styles.editViewFooter}>
					<KiButton flat onClick={this.handleCancel}>
						Cancel
					</KiButton>
					<KiButton primary onClick={this.applyChangesToView}>
						Apply
					</KiButton>
				</footer>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		view: state.debtExploration.view,
		tags: state.debtExploration.view.tags,
		settings: state.debtExploration.view.settings,
		viewColumns: state.debtExploration.view.viewColumns,
		fundingVehicleList: state.fundingVehicleList.data.filter(
			fv => fv.datasetId === _.get(state, 'debtExploration.view.datasetId', '')
		),
	};
};

export default connect(
	mapStateToProps,
	{
		applyChangesToView,
		setCohortColumn,
		setFundingVehicleQuickFilter,
		fetchDebtData,
		setScenario,
	}
)(withRouter(EditViewMenu));
