const _get = require('lodash/get');

const settingTypes = [
	'borrowingBase',
	'assetsInBreach',
	'breaches',
	'excessConcentration',
	'aggregateTests',
	'compliance',
	'tabular',
	'fundingAnalysis',
	'chart',
	'debtView',
];

const cardTypeOptions = [
	{label: 'Asset', value: 'explorer'},
	{label: 'Funding', value: 'fundingAnalysis'},
	{label: 'Debt', value: 'debt'},
	{label: 'Forecast', value: 'projections'},
	{label: 'Payments', value: 'payments'},
];

const paymentCardSourceTypes = [{label: 'Funding Vehicle', value: 'fv'}, {label: 'Scenario', value: 'scenario'}];

const getCardTypeOptionBySettingType = settingType => {
	switch (settingType) {
		case 'debtView':
			return cardTypeOptions.find(o => o.value === 'debt');
		case 'fundingAnalysisView':
			return cardTypeOptions.find(o => o.value === 'fundingAnalysis');
		case 'projections':
			return cardTypeOptions.find(o => o.value === 'projections');
		case 'aggregatedPayment':
			return cardTypeOptions.find(o => o.value === 'payments');
		default:
			return cardTypeOptions.find(o => o.value === 'explorer');
	}
};

const getDefaultExplorerViewCard = (view, user) => {
	return {
		_id: null,
		name: `${view.name}`,
		bookmarkId: view._id,
		datasetId: view.datasetId,
		createdBy: `${user.firstName} ${user.lastName}`,
		createdAt: new Date(),
		settings: {
			type: 'tabular',
			chartType: 'data',
			snapshotDate: _get(view, 'explorerData.snapshotDate', ''),
			showMostRecentSnapshot: _get(view, 'explorerData.showMostRecentSnapshot', false),
			numberOfRows: _get(view, 'explorerData.maxRecords', null),
			sort: {
				id: '',
				desc: '',
			},
		},
	};
};

const getDefaultFundingAnalysisViewCard = (view, user, defaultToReport = false) => {
	return {
		_id: null,
		name: `${view.name}`,
		datasetId: view.datasetId,
		createdBy: `${user.firstName} ${user.lastName}`,
		createdAt: new Date().toISOString(),
		settings: {
			type: 'fundingAnalysisView',
			chartType: 'data',
			fundingAnalysisBookmarkId: view._id.toString(),
			tableType: view.settings.tableType,
			viewType: view.settings.viewType,
			fundingVehicleIds: view.settings.fundingVehicleIds,
			useReportFundingVehicle: defaultToReport,
			scenarioId: view.settings.scenarioId,
			useReportScenarioId: defaultToReport,
			scenarioType: view.settings.scenarioType,
			dateContext: view.settings.dateContext,
			constraintGroup: view.settings.constraintGroup,
		},
	};
};

const getDefaultDebtCard = (view, user) => {
	return {
		_id: null,
		name: `${view.name}`,
		datasetId: view.datasetId,
		createdBy: `${user.firstName} ${user.lastName}`,
		createdAt: new Date(),
		settings: {
			type: 'debtView',
			chartType: 'data',
			debtBookmarkId: view._id,
		},
	};
};

const getDefaultProjectionsCard = (view, user, datasetId) => {
	return {
		_id: null,
		name: `${view.name}`,
		datasetId: datasetId,
		createdBy: `${user.firstName} ${user.lastName}`,
		createdAt: new Date(),
		settings: {
			type: 'projections',
			chartType: 'data',
			scenarioId: view._id,
		},
	};
};

const getDefaultPaymentsCard = (paymentReportSource, user, datasetId) => {
	return {
		_id: null,
		name: ``,
		datasetId: datasetId,
		createdBy: `${user.firstName} ${user.lastName}`,
		createdAt: new Date(),
		settings: {
			paymentReportSource,
			type: 'aggregatedPayment',
			chartType: 'data',
			sort: {
				id: '',
				desc: '',
			},
		},
	};
};

const getDebtDates = (
	fundingVehicleId,
	fundingVehicleDates,
	debtDateContext = 'reportDate',
	assetDateContext = 'collectionEndDate'
) => {
	try {
		if (!fundingVehicleDates.length) return;
		const fv = fundingVehicleDates.find(fv => fv._id === fundingVehicleId);
		const debtFVName = fundingVehicleDates[0].dates.find(d => d.id === debtDateContext).name;
		const assetFVName = fundingVehicleDates[0].dates.find(d => d.id === assetDateContext).name;
		if (fv) {
			const assetMatch = fv.dates.find(d => d.id === assetDateContext);
			const debtMatch = fv.dates.find(d => d.id === debtDateContext);
			return {
				debtFVName: debtFVName,
				assetFVName: assetFVName,
				assetMatch: assetMatch,
				debtMatch: debtMatch,
			};
		}
		return {
			debtFVName: debtFVName,
			assetFVName: assetFVName,
			assetMatch: {value: ''},
			debtMatch: {value: ''},
		};
	} catch (err) {
		return {
			debtFVName: '',
			assetFVName: '',
			assetMatch: {value: ''},
			debtMatch: {value: ''},
		};
	}
};

const getDebtDateMsg = (assetDateContextInfo, debtDateContextInfo) => {
	let assetDateMsg, debtDateMsg;
	if (!assetDateContextInfo || !debtDateContextInfo) return '';
	if (assetDateContextInfo.mappedName) {
		assetDateMsg = `${assetDateContextInfo.mappedName} ${assetDateContextInfo.calculatedDate} (${assetDateContextInfo.name})`;
	} else {
		assetDateMsg = `Data results for Asset Date Context of ${assetDateContextInfo.name} ${assetDateContextInfo.calculatedDate}`;
	}
	if (debtDateContextInfo.mappedName) {
		debtDateMsg = `${debtDateContextInfo.mappedName} ${debtDateContextInfo.calculatedDate} (${debtDateContextInfo.name})`;
	} else {
		debtDateMsg = `Debt Date Context of ${debtDateContextInfo.name} ${debtDateContextInfo.calculatedDate}`;
	}
	return `${assetDateMsg} and ${debtDateMsg}`;
};

module.exports = {
	settingTypes,
	cardTypeOptions,
	paymentCardSourceTypes,
	getCardTypeOptionBySettingType,
	getDefaultExplorerViewCard,
	getDefaultFundingAnalysisViewCard,
	getDefaultDebtCard,
	getDefaultProjectionsCard,
	getDefaultPaymentsCard,
	getDebtDates,
	getDebtDateMsg,
};
