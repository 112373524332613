/* eslint-disable no-unused-vars */

// Global imports
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';

// Project imports
import {reportsApi} from 'api';
import KiInput from 'components/KiInput';
import KiModal from 'components/KiModal';
import KiSelect from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';
import {showSnackbar} from 'state/actions/Snackbar';
import {useMergedState} from '../../../utils/customHooks';

// Local imports
import styles from './NewReportGroupModal.theme.scss';

const VALIDATION_MESSAGES = {
	NAME_REQUIRED: 'Name is required',
	NAME_UNIQUE: 'Name must be unique',
	DATASET_ID: 'Dataset is required',
};

const NewReportGroupModal = ({datasets, onSave, onCancel, reportGroup = null}) => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const [formData, mergeFormData, setFormData] = useMergedState({
		name: reportGroup?.name || '',
		datasetId: reportGroup?.datasetId || '',
		isDefault: reportGroup?.isDefault || false,
	});
	const [validationErrors, mergeValidationErrors, setValidationErrors] = useMergedState({
		name: '',
		datasetId: '',
	});
	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);

	const validateRequired = (fieldValue, fieldName, message) => {
		if (fieldValue) {
			mergeValidationErrors({[fieldName]: null});
		} else {
			mergeValidationErrors({[fieldName]: message});
		}
	};

	const handleNameChange = name => {
		validateRequired(name, 'name', VALIDATION_MESSAGES.NAME_REQUIRED);
		mergeFormData({
			name,
		});
	};

	const handleDatasetChange = datasetId => {
		validateRequired(datasetId, 'datasetId', VALIDATION_MESSAGES.DATASET_ID);
		mergeFormData({
			datasetId,
		});
	};

	const handleIsDefaultChange = isDefault => {
		mergeFormData({
			isDefault,
		});
	};

	const isFormValid = Object.keys(validationErrors)
		.map(key => {
			const error = validationErrors[key];
			if (error === null) {
				return true;
			}
			return false;
		})
		.reduce((previousValue, currentValue) => previousValue && currentValue);

	const handleSubmit = async e => {
		e.preventDefault();
		if (!isFormValid) return false;

		try {
			setIsSaveButtonEnabled(false);
			if (formData.name != reportGroup?.name) {
				const isNameUnique = await reportsApi.checkIfReportGroupNameIsUnique(formData.datasetId, formData.name);
				if (!isNameUnique) {
					mergeValidationErrors({name: VALIDATION_MESSAGES.NAME_UNIQUE});
					setIsSaveButtonEnabled(true);
					return;
				}
			}

			if (reportGroup) {
				// Update existing report group
				const updatedReportGroup = {
					...reportGroup,
					...formData,
				};
				await reportsApi.updateReportGroup(updatedReportGroup);
			} else {
				// Create new report group
				const newReportGroup = {
					...formData,
					createdBy: user.userId,
					updatedBy: user.userId,
				};
				await reportsApi.createReportGroup(newReportGroup);
			}

			dispatch(showSnackbar(`Report group saved`));
			setIsSaveButtonEnabled(true);
			await onSave();
		} catch (error) {
			setIsSaveButtonEnabled(true);
			dispatch(showSnackbar(`Error saving report group`));
		}
	};

	// If this is an edit, run the validations early to enable the save button
	useEffect(
		() => {
			if (reportGroup) {
				validateRequired(formData.name, 'name', VALIDATION_MESSAGES.NAME_REQUIRED);
				validateRequired(formData.datasetId, 'datasetId', VALIDATION_MESSAGES.DATASET_ID);
			}
		},
		[reportGroup]
	);

	return (
		<KiModal
			active={true}
			header={reportGroup ? 'Update Report Group' : 'New Report Group'}
			actions={[
				{
					label: 'Cancel',
					onClick: () => {
						onCancel();
					},
				},
				{
					label: 'Save',
					type: 'submit',
					form: 'newReportGroup',
					disabled: !(isFormValid && isSaveButtonEnabled),
				},
			]}
			panelStyles={{minWidth: '20rem'}}
		>
			<form id="newReportGroup" onSubmit={handleSubmit}>
				<div className={styles.formGroup}>
					<KiInput
						name="name"
						label="Report Group Name"
						type="text"
						value={formData.name}
						onChange={handleNameChange}
						error={validationErrors.name}
					/>
				</div>

				<div className={styles.formGroup}>
					<div className={cn(styles.selectWrapper)}>
						<label className={cn('theme-label', styles.label)}>Dataset</label>
						<KiSelect
							classNamePrefix="aut-select"
							value={datasets.find(item => item.datasetId === formData.datasetId)}
							isClearable={false}
							isDisabled={reportGroup !== null}
							options={datasets}
							onChange={value => handleDatasetChange(value.datasetId)}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.datasetId}
							error={validationErrors.datasetId}
						/>
					</div>
				</div>

				<div className={styles.formGroup}>
					<KiCheckbox
						name="isDefault"
						checked={formData.isDefault}
						label="Set as Default"
						onChange={value => handleIsDefaultChange(value)}
						className={styles.checkbox}
						labelClassName={styles.label}
					/>
				</div>
			</form>
		</KiModal>
	);
};

NewReportGroupModal.propTypes = {
	datasets: PropTypes.array.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	reportGroup: PropTypes.object,
};

export default NewReportGroupModal;
