import {waterfallMappingApi} from 'api';
import * as actionTypes from './actionTypes';

const fetchWaterfallMappingsByDatasetId = datasetId => dispatch => {
	dispatch({type: actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET, status: 'pending'});
	return waterfallMappingApi
		.fetchMappingsByDataset(datasetId)
		.then(mappings => {
			dispatch({
				type: actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET,
				status: 'success',
				data: mappings,
			});
			return mappings;
		})
		.catch(e =>
			dispatch({
				type: actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET,
				status: 'error',
				error: e,
			})
		);
};

const fetchWaterfallMappingsByDatasetIdAndFvId = (datasetId, fvId) => dispatch => {
	dispatch({type: actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET_FV, status: 'pending'});
	return waterfallMappingApi
		.fetchMappingsByDatasetAndFundingVehicle(datasetId, fvId)
		.then(mappings => {
			dispatch({
				type: actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET_FV,
				status: 'success',
				data: mappings,
			});
			return mappings;
		})
		.catch(e =>
			dispatch({
				type: actionTypes.FETCH_WATERFALL_MAPPINGS_BY_DATASET_FV,
				status: 'error',
				error: e,
			})
		);
};

export {fetchWaterfallMappingsByDatasetId, fetchWaterfallMappingsByDatasetIdAndFvId};
