import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames/bind';
import styles from './KiCheckbox.theme.scss';

const cx = classNames.bind(styles);

const Check = ({checked, children, onMouseDown, style, title}) => (
	<div className={cx('check', {checked: checked})} onMouseDown={onMouseDown} style={style} title={title}>
		{children}
	</div>
);

Check.propTypes = {
	checked: PropTypes.bool,
	children: PropTypes.any,
	onMouseDown: PropTypes.func,
	style: PropTypes.object,
	title: PropTypes.string,
};

export class KiCheckbox extends Component {
	static propTypes = {
		checked: PropTypes.bool,
		children: PropTypes.node,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		labelClassName: PropTypes.string,
		name: PropTypes.string,
		onChange: PropTypes.func,
		onMouseEnter: PropTypes.func,
		onMouseLeave: PropTypes.func,
		style: PropTypes.object,
		title: PropTypes.string,
	};

	static defaultProps = {
		checked: false,
		className: '',
		disabled: false,
	};

	handleToggle = event => {
		if (event.pageX !== 0 && event.pageY !== 0) this.blur();
		if (!this.props.disabled && this.props.onChange) {
			this.props.onChange(!this.props.checked, event);
		}
	};

	blur() {
		this.inputNode && this.inputNode.blur();
	}

	focus() {
		this.inputNode && this.inputNode.focus();
	}

	render() {
		const {
			checked,
			children,
			disabled,
			label,
			labelClassName,
			name,
			style,
			title,
			onChange, // eslint-disable-line
			onMouseEnter,
			onMouseLeave,
			...others
		} = this.props;

		const className = cx('checkbox', {disabled: this.props.disabled}, this.props.className);

		return (
			<label className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				<input
					{...others}
					checked={checked}
					className={cx('input')}
					disabled={disabled}
					name={name}
					onChange={() => null}
					onClick={this.handleToggle}
					ref={node => {
						this.inputNode = node;
					}}
					type="checkbox"
				/>
				<Check checked={checked} disabled={disabled} style={style} title={title} />
				{label ? <span className={cx('label', labelClassName)}>{label}</span> : null}
				{children}
			</label>
		);
	}
}
export default KiCheckbox;
