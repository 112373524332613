import KiChart from './KiChart';

class KiStackedColumnChart extends KiChart {
	buildParamConfig() {
		const paramConfig = {
			chart: {
				type: 'column',
				height: this.params.height,
			},
			plotOptions: {
				column: {
					stacking: 'normal',
				},
			},
			title: {
				text: this.params.chartTitle,
			},
			xAxis: {
				categories: this.params.categories,
				title: {
					text: this.params.xTitle,
				},
			},
			yAxis: {
				title: {
					text: this.params.yTitle,
				},
				stackLabels: {
					enabled: true,
					style: {
						fontWeight: 'bold',
						color: 'gray',
					},
				},
			},
			legend: this.params.legend || {},
			series: this.params.series,
		};
		return paramConfig;
	}
}

export default KiStackedColumnChart;
