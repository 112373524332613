import * as actionTypes from './actionTypes';

const initialState = {
	_id: null,
	fileUploadId: null,
	createdDate: null,
	submittedBy: null,
	filename: '',
	categorySchemaName: '',
	snapshotDate: '',
	categorySchemaId: null,
	categorySchema: {
		columns: [],
		lastUpdated: '',
		name: '',
		snapshotDate: '',
	},
	status: {
		name: '',
		progressMessage: '',
		statusMessage: '',
	},
};

function Submission(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SUBMISSION_FETCH:
			return {
				...state,
				...action.payload
			};

		case actionTypes.SUBMISSION_RESET:
			return initialState;

		case actionTypes.SUBMISSION_SET_SCHEMA_COLUMNS:
			return {
				...state,
				categorySchema: {
					...state.categorySchema,
					columns: action.payload
				}
			};

		case actionTypes.SUBMISSION_UPDATE_SCHEMA_COLUMN:
			return {
				...state,
				categorySchema: {
					...state.categorySchema,
					columns: [
						...state.categorySchema.columns.slice(0, action.payload.idx),
						{
							...action.payload.column,
							messages: state.categorySchema.columns[action.payload.idx].messages || [],
							isNew: state.categorySchema.columns[action.payload.idx].isNew || false,
							previewData: state.categorySchema.columns[action.payload.idx].previewData || []
						},
						...state.categorySchema.columns.slice(action.payload.idx + 1)
					]
				}
			};

		case actionTypes.SUBMISSION_VALIDATE_SCHEMA_COLUMN:
			return {
				...state,
				categorySchema: {
					...state.categorySchema,
					columns: [
						...state.categorySchema.columns.slice(0, action.payload.idx),
						{
							...state.categorySchema.columns[action.payload.idx],
							messages: action.payload.column.messages || [],
							isNew: action.payload.column.isNew || false,
							previewData: action.payload.column.previewData || []
						},
						...state.categorySchema.columns.slice(action.payload.idx + 1)
					]
				}
			};

		default:
			return state;
	}
}

const initialStateCategorySchemas = [];

export const CategorySchemas = (state = initialStateCategorySchemas, action) => {
	switch (action.type) {
		case actionTypes.CATEGORY_SCHEMAS_FETCHED:
			return action.payload;
		default:
			return state;
	}
};

export default Submission;
