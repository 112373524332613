// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports
import KiIconButton from 'components/KiIconButton';
import KiSelect from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';

// Local imports
//import styles from './KiColumnSelectModal.theme.scss';

function DebtInputAccountFormRow({
	debtColumns,
	accountColumn,
	formValues,
	setAccountColumnActivity,
	onSaveNewMapping,
	onDeleteAccountColumn,
}) {
	// Target Column
	const getTargetCol = () => debtColumns.find(t => t._id === accountColumn);
	const [targetCol, setTargetCol] = useState(getTargetCol());

	// Mapped Debt Calc
	const [mappedDebtCalc, setMappedDebtCalc] = useState(_.get(targetCol, 'sourceId', null));

	// Mapped Input
	const checkMappedInput = () => targetCol && 'sourceId' in targetCol && formValues.editTemplate === false;
	const [isMappedInput, setIsMappedInput] = useState(checkMappedInput());

	useEffect(
		() => {
			const target = getTargetCol();
			setTargetCol(target);
			setMappedDebtCalc(_.get(target, 'sourceId'));
			setIsMappedInput(checkMappedInput());
		},
		[accountColumn, debtColumns]
	);

	return (
		<section>
			<div className="account-section-column-row">
				{formValues.editTemplate !== false && (
					<KiCheckbox
						className={'debt-input-form-active-check'}
						onChange={val => setAccountColumnActivity(targetCol, val)}
						checked={targetCol && targetCol.isActive}
						label={'Active'}
					/>
				)}
				<KiSelect
					isClearable={!!isMappedInput}
					isDisabled={!isMappedInput}
					getOptionLabel={option => option.displayName || option.name}
					getOptionValue={option => option._id}
					options={
						isMappedInput
							? debtColumns.filter(
									c =>
										!!c.formulas &&
										c.calcEntityLevel === formValues.viewType &&
										c.dataType === 'numeric'
							  )
							: debtColumns.filter(col => col.accountId === formValues._id && col.dataType === 'numeric')
					}
					value={isMappedInput ? debtColumns.find(c => c._id === mappedDebtCalc) : targetCol}
					onChange={val => {
						setMappedDebtCalc((val && val._id) || null);
					}}
					className="account-section-column-select"
					placeholder="Select Calculation..."
				/>
				{formValues.editTemplate !== false && (
					<KiIconButton icon="delete" disabled={false} onClick={() => onDeleteAccountColumn(targetCol)} />
				)}
				{isMappedInput && (
					<KiIconButton
						icon="save"
						disabled={mappedDebtCalc === targetCol.sourceId}
						onClick={() => {
							const targetMapping = Object.assign({}, targetCol);
							targetMapping.sourceId = mappedDebtCalc;
							targetMapping.accountId = formValues._id;
							targetMapping.isAccount = true;
							setMappedDebtCalc(null);
							onSaveNewMapping(targetMapping);
						}}
					/>
				)}
			</div>
		</section>
	);
}

DebtInputAccountFormRow.propTypes = {
	debtColumns: PropTypes.array.isRequired,
	accountColumn: PropTypes.string.isRequired,
	formValues: PropTypes.object.isRequired,
	setAccountColumnActivity: PropTypes.func.isRequired,
	onSaveNewMapping: PropTypes.func.isRequired,
	onDeleteAccountColumn: PropTypes.func.isRequired,
};

DebtInputAccountFormRow.defaultProps = {};

export default DebtInputAccountFormRow;
