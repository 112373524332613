import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import styles from './creditSupportsForm.theme.scss';
import KiButton from 'components/KiButton';
import {accrualMethodOptions as basisOptions} from 'ki-common/options';
import _ from 'lodash';
import {columnServiceApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';

export const FEE_TYPES = [
	{
		value: 'Total Pool',
		label: 'Total Pool',
	},
	{
		value: 'Fixed Amount',
		label: 'Fixed',
	},
	{
		value: 'Pool Int',
		label: 'Pool Interest',
	},
	{
		value: 'Total Bonds',
		label: 'Total Bonds',
	},
	{
		value: 'Custom',
		label: 'Custom',
	},
];

export const getNameId = (feeType, feeNumber) =>
	['F_', _.get(FEE_TYPES.find(x => x.value === feeType), 'label', '').replace(/\s/gi, ''), '_', feeNumber].join('');

export const RATE_TYPE_OPTIONS = [{value: 'fixed', label: 'Fixed'}, {value: 'floating', label: 'Floating'}];

export function FeesForm(props) {
	const dispatch = useDispatch();
	const [name, setName] = useState(props.name);
	const [type, setType] = useState(props.type);
	const [rateType, setRateType] = useState(props.rateType);
	const [rateAmount, setRateAmount] = useState(props.rateAmount);
	const [margin, setMargin] = useState(props.margin);
	const [rateIndices, setRateIndices] = useState([]);
	const [rateIndex, setRateIndex] = useState(props.rateIndex);
	const [value, setValue] = useState(props.value);
	const [basis, setBasis] = useState(props.basis);
	const [calculation, setCalculation] = useState(props.calculation);
	const [savePending, setSavePending] = useState(false);

	useEffect(() => {
		columnServiceApi
			.getIndexColumnsFromService(props.datasetId)
			.then(indices => {
				setRateIndices(indices);
			})
			.catch(() => dispatch(showSnackbar('Failure to load indices')));
	}, []);

	useEffect(
		() => {
			setName(props.name);
			setType(props.type);
			setRateType(props.rateType);
			setRateAmount((props.rateAmount * 100).toString());
			setMargin((props.margin * 100).toString());
			setRateIndex(props.rateIndex);
			setBasis(props.basis);
			setValue(props.value);
			setCalculation(props.calculation);
		},
		[props.feeId]
	);

	const handleSave = () => {
		const feeToBeSaved = {
			_id: props.feeId,
			datasetId: props.datasetId,
			fundingVehicleId: props.fundingVehicleId,
			rowType: 'fee',
			name,
			nameId: getNameId(type, props.getFeeNumber(props.feeId, type)),
			type,
			feeNumber: props.getFeeNumber(props.feeId, type),
			rateType,
			rateAmount: (rateType === 'fixed' && (rateAmount / 100).toFixed(rateAmount.length).toString()) || '0.0',
			margin: (rateType === 'floating' && (margin / 100).toFixed(rateAmount.length).toString()) || '0.0',
			rateIndex,
			basis,
			value,
			calculation,
		};
		if (type !== 'Custom') {
			delete feeToBeSaved.calculation;
		}
		setSavePending(true);
		return props.onSave(feeToBeSaved).finally(() => {
			setSavePending(false);
			setName('');
			setType(FEE_TYPES[0].valu);
			setRateType(RATE_TYPE_OPTIONS[0].value);
			setRateAmount('0.0');
			setMargin('0.0');
			setRateIndex('');
			setBasis(basisOptions[0].value);
			setValue('');
		});
	};

	const handleCancel = () => {
		props.history.goBack();
	};

	return (
		<section className={styles.root}>
			<KiInput
				label="Fee Name"
				onChange={val => setName(val)}
				value={name || ''}
				error={props.name !== name && !props.isNameUnique(name) && 'Name in use'}
			/>
			<label className={styles.label}>Fee Type</label>
			<KiSelect
				className={styles.supportSelect}
				options={FEE_TYPES}
				value={FEE_TYPES.filter(x => x.value === type)}
				label={'Fee Type'}
				onChange={opt => setType(opt.value)}
			/>
			<KiInput label="Fee #" value={getNameId(type, props.getFeeNumber(props.feeId, type))} readOnly />
			{type !== 'Custom' && (
				<KiInput
					sendReactEvent={true}
					isNumberMasked={true}
					maskConfig={{includeThousandsSeparator: true}}
					label="Value"
					value={value}
					onChange={val => setValue(val)}
				/>
			)}
			<div className={styles.rateRowItem}>
				<label className={styles.label}>Basis</label>
				<KiSelect
					className={styles.supportSelect}
					options={basisOptions}
					value={basisOptions.filter(x => x.value === basis)}
					label={'Basis'}
					onChange={opt => setBasis(opt.value)}
				/>
			</div>
			{type === 'Custom' && (
				<React.Fragment>
					<label className={styles.label}>Fee Formula</label>
					<KiSelect
						getOptionValue={opt => opt._id}
						getOptionLabel={opt => opt.displayName}
						options={props.availableTargets}
						value={props.availableTargets.filter(x => x._id === calculation)}
						onChange={opt => setCalculation(opt._id)}
					/>
				</React.Fragment>
			)}
			<div className={styles.rateRow}>
				<div className={styles.rateRowItem}>
					<label className={styles.label}>Deferred Rate Type</label>
					<KiSelect
						options={RATE_TYPE_OPTIONS}
						value={RATE_TYPE_OPTIONS.filter(x => x.value === rateType)}
						onChange={opt => setRateType(opt.value)}
					/>
				</div>
				{rateType === 'fixed' && (
					<KiInput
						label="Deferred Rate"
						value={rateAmount}
						onChange={val => setRateAmount(val)}
						isNumberMasked={true}
						maskConfig={{suffix: '%'}}
						sendReactEvent={true}
					/>
				)}
				{rateType === 'floating' && (
					<React.Fragment>
						<KiInput
							label="Deferred Margin"
							value={margin}
							onChange={val => setMargin(val)}
							isNumberMasked={true}
							maskConfig={{suffix: '%'}}
							sendReactEvent={true}
						/>
						<div className={styles.rateRowItem}>
							<label className={styles.label}>Deferred Index</label>
							<KiSelect
								options={rateIndices}
								getOptionValue={opt => opt._id}
								getOptionLabel={opt => opt.displayName}
								value={rateIndices.filter(x => x._id === rateIndex)}
								onChange={opt => setRateIndex(opt._id)}
							/>
						</div>
					</React.Fragment>
				)}
			</div>
			<footer className={styles.footer}>
				<KiButton flat primary onClick={handleCancel} label="Cancel" />
				<KiButton
					className={styles.saveBtn}
					primary
					raised
					label="Save"
					disabled={
						savePending ||
						!name ||
						!props.isNameUnique(name) ||
						(isNaN(parseFloat(value)) && type !== 'Custom') ||
						!type
					}
					onMouseUp={handleSave}
				/>
			</footer>
		</section>
	);
}

FeesForm.propTypes = {
	onSave: PropTypes.func,
	fundingVehicleId: PropTypes.string.isRequired,
	datasetId: PropTypes.string,
	feeId: PropTypes.string,
	name: PropTypes.string,
	rateType: PropTypes.string,
	rateAmount: PropTypes.string,
	margin: PropTypes.string,
	basis: PropTypes.string,
	rateIndex: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
	calculation: PropTypes.string,
	availableTargets: PropTypes.arrayOf(PropTypes.object),
	getFeeNumber: PropTypes.func.isRequired,
	isNameUnique: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

FeesForm.defaultProps = {
	name: '',
	type: FEE_TYPES[0].value,
	rateType: RATE_TYPE_OPTIONS[0].value,
	rateAmount: '0.0',
	rateIndex: '',
	margin: '0.0',
	basis: basisOptions[0].value,
	value: '',
};

export default FeesForm;
