import React from 'react';
import PropTypes from 'prop-types';
import KiButton from 'components/KiButton';

const TableActions = ({actions, item}) => {
	return (
		actions.length > 0 && (
			<td className={`align-right ledger-actions fixed-width-actions-${actions.length}`}>
				{actions.map(({title, onClick, icon}, i) => (
					<KiButton key={i} icon={icon} title={title} onMouseUp={() => onClick(item)} iconButton />
				))}
			</td>
		)
	);
};

TableActions.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.object).isRequired,
	item: PropTypes.object.isRequired,
};

export default TableActions;
