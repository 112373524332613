export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE';

export const hideSnackbar = () => {
	return {type: SNACKBAR_HIDE};
};

/**
 * Action to display the snackbar.
 * @param {string|object} [options]
 * @returns {{options: {msg: string}, type: string}|{options, type: string}}
 */
export const showSnackbar = options => {
	if (typeof options === 'string') {
		return {type: SNACKBAR_SHOW, options: {msg: options}};
	}
	return {type: SNACKBAR_SHOW, options: options};
};
