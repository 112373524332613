import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {fetchCalendar, createCalendar, updateCalendar, deleteCalendar} from '../actions';
import KiInput from 'components/KiInput';
import Workdays from './workdays';
import styles from './calendarDetails.theme.scss';
import KiCheckbox from 'components/KiCheckbox';
import KiButton from 'components/KiButton';
import KiSelect from 'components/KiSelect';
import KiConfirmModal from 'components/KiConfirmModal';
import _isEqual from 'lodash/isEqual';
import {Holidays, DefaultHolidays, defaultHolidayOptions, createCustomData} from 'ki-common/utils/holidayCalendars';

export class CalendarDetail extends Component {
	static propTypes = {
		match: PropTypes.object,
		calendar: PropTypes.object,
		calendars: PropTypes.array,
		history: PropTypes.object,
		fetchCalendar: PropTypes.func,
		updateCalendar: PropTypes.func,
		createCalendar: PropTypes.func,
		deleteCalendar: PropTypes.func,
		isLoading: PropTypes.bool,
	};

	static defaultProps = {
		calendar: {
			id: '',
			name: 'New Calendar',
			holidays: {},
			workdays: {
				sunday: false,
				monday: true,
				tuesday: true,
				wednesday: true,
				thursday: true,
				friday: true,
				saturday: false,
			},
			baseCalendars: [],
		},
		calendars: [],
	};

	constructor(props) {
		super(props);
		this.holidays = new Holidays({});
	}

	state = {
		previewYear: moment().year(),
		workdays: this.props.calendar.workdays || {
			sunday: false,
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			saturday: false,
		},
		name: this.props.calendar.name || '',
		baseCalendars: this.props.calendar.baseCalendars || [],
		calendarDeletionModalActive: false,
	};

	componentDidMount() {
		if (this.props.match.params.id !== 'new') {
			this.props
				.fetchCalendar(this.props.match.params.id)
				.then(calendar => {
					this.holidays.init('CUSTOM', undefined, undefined, {
						data: createCustomData(calendar.holidays),
						types: ['public'],
						languages: ['en'],
					});
					return this.setState({
						name: calendar.name,
						workdays: calendar.workdays,
						baseCalendars: calendar.baseCalendars,
					});
				})
				.catch(() => {
					return this.props.history.push('/calendars/new');
				});
		}
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			if (
				this.props.match.params.id &&
				prevProps.match.params.id !== this.props.match.params.id &&
				this.props.match.params.id !== 'new'
			) {
				this.props.fetchCalendar(this.props.match.params.id).then(calendar => {
					this.holidays.init('CUSTOM', undefined, undefined, {
						data: createCustomData(calendar.holidays),
						types: ['public'],
						languages: ['en'],
					});
					this.setState({
						name: calendar.name,
						workdays: calendar.workdays,
						baseCalendars: calendar.baseCalendars,
					});
				});
			} else if (
				this.props.match.params.id !== prevProps.match.params.id &&
				this.props.match.params.id === 'new'
			) {
				this.holidays.init({});
				this.setState({
					name: 'New Calendar',
					workdays: {
						sunday: false,
						monday: true,
						tuesday: true,
						wednesday: true,
						thursday: true,
						friday: true,
						saturday: false,
					},
					previewYear: moment().year(),
					baseCalendars: [],
				});
			} else if (!_isEqual(prevProps.calendar, this.props.calendar)) {
				this.setState({
					name: this.props.calendar.name,
					workdays: this.props.calendar.workdays,
					baseCalendars: this.props.calendar.baseCalendars,
				});
			}
		}
	}

	handleActiveChanged = (date, isActive) => {
		this.holidays.setDisabledByRule(date.rule, isActive);
		this.forceUpdate(); //doesn't change state so force rerender to call .getHolidays() in render again
	};

	handleSave = () => {
		const data = {
			name: this.state.name.trim(),
			workdays: this.state.workdays,
			holidays: this.holidays.getRules(),
			baseCalendars: this.state.baseCalendars,
		};
		return this.props.calendar._id
			? this.props.updateCalendar(this.props.calendar._id, data)
			: this.props.createCalendar(data).then(({_id}) => this.props.history.push(`/calendars/${_id}`));
	};

	handleChangeBase = calendars => {
		if (!calendars || !calendars.length) {
			this.holidays.init('');
			return this.setState({
				baseCalendars: [],
			});
		}
		const days = calendars.reduce(
			(acc, calendar) => ({...acc, ...(DefaultHolidays.__data.getRules(calendar.value) || {})}),
			{}
		);

		this.holidays.init('CUSTOM', undefined, undefined, {
			data: createCustomData(days),
			types: ['public'],
			languages: ['en'],
		});
		this.setState({
			baseCalendars: calendars.map(({value}) => value),
		});
	};

	handleUndoChanges = () => {
		this.holidays.init('CUSTOM', undefined, undefined, {
			data: createCustomData(this.props.calendar.holidays),
			types: ['public'],
			languages: ['en'],
		});
		return this.setState({
			name: this.props.calendar.name,
			workdays: this.props.calendar.workdays,
			baseCalendars: this.props.calendar.baseCalendars,
		});
	};

	handleDelete = () => {
		if (!this.props.calendar.default) {
			return this.props.deleteCalendar(this.props.calendar._id).then(() => this.props.history.push('/calendars'));
		}
	};

	isMatch = () => {
		return _isEqual(this.props.calendar, {
			...this.props.calendar,
			name: this.state.name,
			holidays: this.holidays.getRules(),
			baseCalendars: this.state.baseCalendars,
			workdays: this.state.workdays,
		});
	};

	handlePreviewYearChange = val => {
		this.setState({
			previewYear: parseInt(val) || '',
		});
	};

	getHolidayName = (holiday = {}) => {
		if (typeof holiday.name === 'object') {
			if (holiday.name.en) {
				return holiday.name.en;
			}
			const keys = Object.keys(holiday.name);
			return holiday.name[keys[0]];
		}
		return holiday.name;
	};

	getNameErrors = () => {
		if (this.props.isLoading) {
			return undefined;
		}
		if (this.state.name === this.props.calendar.name) {
			return undefined;
		}
		if (!this.state.name.trim().length) {
			return 'Required';
		}
		if (this.props.calendars.find(c => c.name.trim() === this.state.name.trim())) {
			return 'Name in use';
		}
	};
	render() {
		const holidaysForYear = this.holidays.getHolidays(this.state.previewYear, 'en');
		return (
			<section className={styles.root}>
				<header />
				<section className={styles.mainSection}>
					<div className={styles.topRow}>
						<KiInput
							className={styles.calendarName}
							label="Calendar Name"
							onChange={val => this.setState({name: val})}
							value={this.state.name}
							error={this.getNameErrors()}
						/>
						<KiSelect
							isMulti={true}
							className={styles.calendarSelector}
							options={defaultHolidayOptions}
							value={defaultHolidayOptions.filter(({value}) => this.state.baseCalendars.includes(value))}
							onChange={this.handleChangeBase}
							placeholder={'Calendars'}
						/>
						<KiInput
							className={styles.previewYearInput}
							type={'number'}
							value={this.state.previewYear}
							onChange={this.handlePreviewYearChange}
							label={'Preview Year'}
						/>
						<i
							className={`material-icons ${this.isMatch() ? 'disabled' : ''}`}
							onMouseUp={this.handleUndoChanges}
							title="Click to undo the change"
						>
							undo
						</i>
						{this.props.calendar._id && (
							<i
								className={`material-icons ${this.props.calendar.default ? 'disabled' : ''}`}
								onMouseUp={() => this.setState({calendarDeletionModalActive: true})}
								title="Click to delete the holiday calendar"
							>
								delete
							</i>
						)}
					</div>
					<div className={styles.workdaysWrapper}>
						<label>Work Days</label>
						<Workdays workdays={this.state.workdays} onChange={workdays => this.setState({workdays})} />
					</div>
					<div className={styles.holidayListWrapper}>
						<label>Holidays</label>
						<div className={styles.holidayList}>
							{!holidaysForYear.length && (
								<div className={styles.holidayListEmpty}>Select calendar(s) to set holidays.</div>
							)}
							{holidaysForYear.map((holiday, idx) => (
								<div key={idx} className={styles.holidayListItem}>
									<div className={styles.holidayListItemDate}>
										{moment(holiday.date).format('dddd, MMMM Do')}
									</div>
									<div className={styles.holidayListItemName}>{this.getHolidayName(holiday)}</div>
									<div className={styles.holidayListItemActive}>
										<KiCheckbox
											label={'Active'}
											checked={!holiday.disabled}
											onChange={isActive => this.handleActiveChanged(holiday, !isActive)}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
					<footer
						className={
							(!this.isMatch() &&
								holidaysForYear.length &&
								!this.getNameErrors() &&
								!this.props.isLoading &&
								styles.footerVisible) ||
							undefined
						}
					>
						<KiButton flat primary label={'Cancel'} onMouseUp={this.handleUndoChanges} />
						&nbsp;
						<KiButton raised primary label={'Save'} onMouseUp={this.handleSave} />
					</footer>
				</section>
				<KiConfirmModal
					header="Delete Calendar"
					acceptFunc={this.handleDelete}
					rejectFunc={() => this.setState({calendarDeletionModalActive: false})}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.calendarDeletionModalActive}
					className="ki-modal"
					message={`Are you sure you wish to delete ${this.props.calendar.name || 'this calendar'}?`}
				/>
			</section>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		calendar:
			ownProps.match.params.id === 'new'
				? undefined
				: state.calendars.calendars.find(({_id}) => _id === ownProps.match.params.id),
		calendars: state.calendars.calendars,
		isLoading: state.calendars.isLoading,
	};
};

export default connect(mapStateToProps, {fetchCalendar, createCalendar, updateCalendar, deleteCalendar})(
	CalendarDetail
);
