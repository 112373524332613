// Globals
import React from 'react';
import {useSelector} from 'react-redux';

// Local imports
import {DataExplorerProvider} from './DataExplorerContext';
import {QuickFilterSettingProvider} from './contexts/QuickFilterSettingContext';
import {BookmarkApiProvider} from './contexts/BookmarkApiContext';
import DataExplorerLayout from './DataExplorerLayout';

function DataExplorerIndex() {
	const app = useSelector(state => state.app);
	document.title = `${app.kiVersion} - Data Exploration`;

	// Wrap the DataExplorer with the context
	return (
		<DataExplorerProvider>
			<QuickFilterSettingProvider>
				<BookmarkApiProvider>
					<div className="container-wrapper">
						<DataExplorerLayout />
					</div>
				</BookmarkApiProvider>
			</QuickFilterSettingProvider>
		</DataExplorerProvider>
	);
}

DataExplorerIndex.propTypes = {};

export default DataExplorerIndex;
