import React from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import {components as SelectComponents} from 'react-select';
import KiSelect from 'components/KiSelect';
import styles from './RateSelector.theme.scss';
import _ from 'lodash';

const isRateValid = value => {
	if (['', null, undefined].includes(value)) return false;
	const number = Number(value);
	if (isNaN(number)) return false;
	return number >= 0 && number <= 100;
};

export const ControlWithLabel = props => {
	return (
		<React.Fragment>
			<div className={styles.controlLabel}>{props.selectProps.label}</div>
			<SelectComponents.Control {...props} />
		</React.Fragment>
	);
};
ControlWithLabel.propTypes = {
	selectProps: PropTypes.object,
};

export const RateSelector = props => {
	const rateOption = {label: `${props.rateAmount}%`, value: props.rateAmount};
	const vectorOption = props.vectors.filter(opt => opt.value === props.vectorId);
	const selectedValue = isRateValid(props.rateAmount) ? rateOption : vectorOption;

	const handleChange = (option, {action}) => {
		const result = {
			rateAmount: null,
			vectorId: null,
		};
		switch (action) {
			case 'select-option':
				// user selected a vector
				result.vectorId = option.value;
				break;
			case 'create-option':
				// user input a rate
				result.rateAmount = option.value;
				break;
		}
		return props.onRateChange(result.rateAmount, result.vectorId);
	};
	const displayTypeSelector = Array.isArray(props.types) && !!props.types.length;
	return (
		<div className={styles.root}>
			<Creatable
				placeholder={props.placeholder}
				classNamePrefix="aut-select"
				components={{
					Control: ControlWithLabel,
				}}
				styles={{
					container: provided => ({
						...provided,
						flex: '5 1 20rem',
						minWidth: '14rem',
						...(displayTypeSelector && {marginRight: '1rem'}),
					}),
				}}
				filterOption={(opt, string = '') =>
					Boolean(opt?.data?.__isNew__ || opt?.label.toLowerCase().startsWith(string.toLowerCase()))
				}
				getOptionLabel={opt => opt.label}
				options={_.sortBy(props.vectors, 'label')}
				onChange={handleChange}
				formatCreateLabel={val => `Set Rate to ${val}%`}
				menuIsOpen={props.readOnly ? false : undefined}
				isSearchable={props.readOnly ? false : undefined}
				menuPosition="fixed"
				isValidNewOption={value => isRateValid(value)}
				label={props.rateLabel}
				value={selectedValue}
			/>
			{displayTypeSelector && (
				<KiSelect
					components={{
						Control: ControlWithLabel,
					}}
					styles={{
						container: provided => ({
							...provided,
							flex: '1 1 10rem',
						}),
					}}
					readOnly={props.readOnly}
					label={props.typeLabel}
					classNamePrefix="aut-select"
					options={props.types}
					onChange={opt => props.onTypeChange(opt?.value)}
					value={props.types.filter(type => type.value === props.type)}
				/>
			)}
		</div>
	);
};

RateSelector.propTypes = {
	readOnly: PropTypes.bool,
	onRateChange: PropTypes.func.isRequired,
	onTypeChange: PropTypes.func,
	vectors: PropTypes.array.isRequired,
	types: PropTypes.array,
	rateAmount: PropTypes.string,
	vectorId: PropTypes.string,
	type: PropTypes.string,
	rateLabel: PropTypes.string,
	typeLabel: PropTypes.string,
	placeholder: PropTypes.string,
};

RateSelector.defaultProps = {
	readOnly: false,
	vectors: [],
	types: [],
	rateAmount: null,
	vectorId: null,
	placeholder: 'Enter rate or choose',
	rateLabel: 'Rate',
	typeLabel: 'Type',
};

export default RateSelector;
