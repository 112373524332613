// Globals
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// Project imports
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import options from 'ki-common/options';
// Local imports
import styles from 'containers/debt/components/ColumnSelection.theme.scss';
/**
 * [ViewColumnListItem description]
 */
function ViewColumnListItem({handleDeleteColumn, handleUpdateColumn, column, index, nonDeletableName}) {
	const DATE_LONG_OPTIONS = [
		{
			label: 'YYYY-MM-DD',
			value: 'yyyy-MM-dd',
		},
		{
			label: 'DD MMM YYYY',
			value: 'DD MMM YYYY',
		},
	];

	const DATE_SHORT_OPTIONS = [
		{
			label: 'YYYY-MM',
			value: 'yyyy-MM',
		},
	];

	const getFormatOptions = dataType => {
		switch (dataType) {
			case 'numeric':
				return options.numericFormats;
			case 'date':
			case 'date_long':
				return DATE_LONG_OPTIONS;
			case 'date_short':
				return DATE_SHORT_OPTIONS;
			case 'string':
				return [{value: 'string', label: 'Text'}];
			default:
				return [];
		}
	};

	return (
		<form name={'columnSelectionItem'} className={styles.columnListItem} style={{margin: '0'}}>
			<header className={styles.columnListItemHeader}>
				<div className={styles.itemTitle} title={column.displayName}>
					{column.displayName}
				</div>
				{nonDeletableName !== column.columnName && (
					<div className={styles.itemActions}>
						<i title={'Remove'} className={'material-icons'} onClick={() => handleDeleteColumn(index)}>
							clear
						</i>
					</div>
				)}
			</header>
			<section className={styles.columnListItemBody}>
				<div className={styles.dataType} style={{padding: '5px 0'}}>
					{column.dataType}
				</div>
				<div className={'select-wrapper'}>
					<label className={'theme-label'}>Format:</label>
					<KiSelect
						menuPosition={'absolute'}
						getOptionValue={option => option.label}
						options={getFormatOptions(column.dataType)}
						value={getFormatOptions(column.dataType).find(
							c => !!column.displayFormat && c.value.toLowerCase() === column.displayFormat.toLowerCase()
						)}
						onChange={val => {
							const updatedCol = _.cloneDeep(column);
							updatedCol.displayFormat = val.value;
							handleUpdateColumn(updatedCol, index);
						}}
					/>
				</div>
				<KiInput
					label={'Display Name'}
					value={column.displayName}
					onChange={val => {
						const updatedCol = _.cloneDeep(column);
						updatedCol.displayName = val;
						handleUpdateColumn(updatedCol, index);
					}}
				/>
			</section>
		</form>
	);
}

ViewColumnListItem.propTypes = {
	handleDeleteColumn: PropTypes.func,
	handleUpdateColumn: PropTypes.func,
	nonDeletableName: PropTypes.string,
	column: PropTypes.object,
	index: PropTypes.number,
};

ViewColumnListItem.defaultProps = {
	column: {},
};

export default ViewColumnListItem;
