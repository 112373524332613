import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiCheckbox from '../../../components/KiCheckbox';
import styles from './workdays.theme.scss';

export class Workdays extends Component {
	static propTypes = {
		workdays: PropTypes.object,
		className: PropTypes.string,
		checkboxClassName: PropTypes.string,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		workdays: {
			sunday: false,
			monday: true,
			tuesday: true,
			wednesday: true,
			thursday: true,
			friday: true,
			saturday: false,
		},
		className: '',
		checkboxClassName: '',
	};

	handleChange = (active, {target: {name}}) => {
		return this.props.onChange({
			...this.props.workdays,
			[name]: !!active,
		});
	};

	render() {
		return (
			<div className={`${styles.root} ${this.props.className}`}>
				<KiCheckbox
					name={'sunday'}
					label={'Sun'}
					className={this.props.checkboxClassName}
					onChange={this.handleChange}
					checked={this.props.workdays.sunday}
				/>
				<KiCheckbox
					name={'monday'}
					label={'Mon'}
					className={this.props.checkboxClassName}
					onChange={this.handleChange}
					checked={this.props.workdays.monday}
				/>
				<KiCheckbox
					name={'tuesday'}
					label={'Tues'}
					className={this.props.checkboxClassName}
					onChange={this.handleChange}
					checked={this.props.workdays.tuesday}
				/>
				<KiCheckbox
					name={'wednesday'}
					label={'Weds'}
					className={this.props.checkboxClassName}
					onChange={this.handleChange}
					checked={this.props.workdays.wednesday}
				/>
				<KiCheckbox
					name={'thursday'}
					label={'Thurs'}
					className={this.props.checkboxClassName}
					onChange={this.handleChange}
					checked={this.props.workdays.thursday}
				/>
				<KiCheckbox
					name={'friday'}
					label={'Fri'}
					className={this.props.checkboxClassName}
					onChange={this.handleChange}
					checked={this.props.workdays.friday}
				/>
				<KiCheckbox
					name={'saturday'}
					label={'Sat'}
					className={this.props.checkboxClassName}
					onChange={this.handleChange}
					checked={this.props.workdays.saturday}
				/>
			</div>
		);
	}
}

export default Workdays;
