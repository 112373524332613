import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const MultiplePeriodsForm = ({onPeriodsAdd, onCancel, currentPeriods, renderDataProvider, isValueRageRestricted}) => {
	const [values, setValues] = useState([]);
	const [error, setError] = useState(null);

	const validateData = newValues => {
		const monthsToAdd = [];
		const isValid = !newValues.some((item, index) => {
			const {month, value} = item;
			if (!month || month.match(/^\d+$/) === null) {
				setError(`Month in line ${index + 1} is not an integer`);
				return true;
			}
			if (!value || value.match(/^(\d+.)?\d+$/) === null) {
				setError(`Value in line ${index + 1} is not a correct number`);
				return true;
			}
			if (parseFloat(value) > 100 && isValueRageRestricted) {
				setError(`Value in line ${index + 1} must me between 0-100`);
				return true;
			}
			if (monthsToAdd.includes(month)) {
				setError(`Month in line ${index + 1} is a duplicate`);
				return true;
			} else {
				monthsToAdd.push(month);
			}

			const currentMonths = currentPeriods.map(period => period.month);
			return newValues.some((period, index) => {
				if (currentMonths.includes(period.month)) {
					setError(`Month in line ${index + 1} is added already`);
					return true;
				}
				return false;
			});
		});
		if (isValid) {
			setError(null);
		}
	};

	useEffect(
		() => {
			validateData(values);
		},
		[currentPeriods]
	);

	const onChange = text => {
		text = text.trim();
		if (text) {
			const values = text.split(/\r?\n/).map(item => {
				const splitterIndex = item.search(/\t|,| |;/);
				if (splitterIndex !== -1) {
					return {month: item.slice(0, splitterIndex), value: item.slice(splitterIndex + 1)};
				} else {
					return {month: item, value: ''};
				}
			});
			setValues(values);
			validateData(values);
		} else {
			setValues([]);
			setError(null);
		}
	};

	const onAdd = () => {
		onPeriodsAdd(values);
		setValues([]);
	};

	const renderProps = {
		error,
		onChange,
		onAdd,
		onCancel,
	};

	return <>{renderDataProvider(renderProps)}</>;
};

MultiplePeriodsForm.propTypes = {
	onPeriodsAdd: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	currentPeriods: PropTypes.array.isRequired,
	renderDataProvider: PropTypes.func.isRequired,
	isValueRageRestricted: PropTypes.bool.isRequired,
};

export default MultiplePeriodsForm;
