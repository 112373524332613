import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import stylesForm from '../common/Form.theme.scss';
import FormCloseSection from '../common/FormCloseSection';
import {FORECASTING_FLYOUT_MODE} from '../common/FlyoutSwitch';
import NameForm from '../common/NameForm';
import KiSelect from '../../../../components/KiSelect';
import {useMergedState} from '../../../../utils/customHooks';
import {getPricingAssumption, getPricingAssumptions, savePricingAssumption} from '../../../../api/waterfallApi';
import {inputOptions as INPUT_OPTIONS, methodList as METHOD_OPTIONS} from 'ki-common/options/assumptions';
import KiButton from '../../../../components/KiButton';
import KiInput from '../../../../components/KiInput';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../../state/actions/Snackbar';

const PricingAssumptionForm = ({setFlyoutMode, editItemId, showSnackbar}) => {
	const [formData, mergeFormData] = useMergedState({
		name: '',
		method: '',
		input: '',
		midpoint: '100',
		stepSize: '100',
	});
	const [validationErrors, mergeValidationErrors] = useMergedState({
		name: '',
		method: '',
		input: '',
		midpoint: null,
		stepSize: null,
	});
	const [pricingAssumptions, setPricingAssumptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		getPricingAssumptions().then(assumptions => {
			setPricingAssumptions(assumptions);
			setIsLoading(false);
		});
	}, []);

	const onChange = change => {
		mergeFormData(change);
		const updatedErrors = {};
		Object.entries(change).forEach(([key, value]) => {
			updatedErrors[key] = value ? null : 'Field is required';
		});
		mergeValidationErrors(updatedErrors);
	};

	useEffect(
		() => {
			if (editItemId) {
				getPricingAssumption(editItemId).then(item => {
					mergeFormData(item);
					onChange(item);
				});
			}
		},
		[editItemId]
	);

	const isFormValid = !Object.entries(validationErrors)
		.map(([, value]) => value !== null)
		.includes(true);

	const onSubmit = () => {
		setIsLoading(true);
		savePricingAssumption(formData).then(() => {
			setIsLoading(false);
			showSnackbar('Pricing Assumption saved successfully');
		});
		setFlyoutMode(FORECASTING_FLYOUT_MODE.PRICING_ASSUMPTION_LIST);
	};

	return (
		<section className={stylesForm.section}>
			<FormCloseSection onClose={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.PRICING_ASSUMPTION_LIST)} />
			<form className={stylesForm.form}>
				<section className={stylesForm.scrollable}>
					<NameForm
						label="Pricing Assumption"
						items={pricingAssumptions}
						mergeFormData={mergeFormData}
						mergeValidationErrors={mergeValidationErrors}
						nameFormData={formData.name}
						nameValidationErrors={validationErrors.name}
						editItemId={editItemId}
					/>
					<div className="sidebar-form-section">
						<span className="form-instruction">Method</span>
						<KiSelect
							options={METHOD_OPTIONS}
							onChange={opt => onChange({method: opt?.value ?? null})}
							value={METHOD_OPTIONS.filter(opt => opt.value === formData.method)}
							errorMessage={validationErrors?.method}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Input</span>
						<KiSelect
							options={INPUT_OPTIONS}
							onChange={opt => onChange({input: opt?.value ?? null})}
							value={INPUT_OPTIONS.filter(opt => opt.value === formData.input)}
							errorMessage={validationErrors?.input}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Midpoint</span>
						<KiInput
							className="substring-size-input"
							onChange={midpoint => onChange({midpoint})}
							autoComplete="off"
							isNumberMasked={true}
							maskConfig={{allowNegative: false}}
							value={formData.midpoint}
							error={validationErrors?.midpoint}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Step Size (bps)</span>
						<KiInput
							className="substring-size-input"
							onChange={stepSize => onChange({stepSize})}
							autoComplete="off"
							isNumberMasked={true}
							maskConfig={{allowNegative: false}}
							value={formData.stepSize}
							error={validationErrors?.stepSize}
						/>
					</div>
				</section>
				<div className="inline-column-form-buttons">
					<KiButton
						flat
						primary
						onClick={() => setFlyoutMode(FORECASTING_FLYOUT_MODE.PRICING_ASSUMPTION_LIST)}
					>
						Cancel
					</KiButton>
					<KiButton disabled={isLoading || !isFormValid} raised primary onClick={onSubmit}>
						Save
					</KiButton>
				</div>
			</form>
		</section>
	);
};

PricingAssumptionForm.propTypes = {
	setFlyoutMode: PropTypes.func.isRequired,
	editItemId: PropTypes.string,
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(null, {showSnackbar})(PricingAssumptionForm);
