import React from 'react';
import PropTypes from 'prop-types';
import './ledgers.scss';
import KiProgressBar from '../../components/KiProgressBar';

function Loader({isActive, loaderContent, children}) {
	return isActive ? loaderContent ? loaderContent() : <KiProgressBar /> : children;
}

Loader.propTypes = {
	isActive: PropTypes.bool.isRequired,
	loaderContent: PropTypes.func,
	children: PropTypes.node,
};

export default Loader;
