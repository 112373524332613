import PropTypes from 'prop-types';
import React from 'react';
import styles from './KiAppBar.theme.scss';

export const KiAppBar = ({className, title, children}) => {
	return (
		<header className={`${styles.kiAppBar} ${className}`}>
			{title ? typeof title === 'string' ? <h1>{title}</h1> : title : null}
			{children && children}
		</header>
	);
};

KiAppBar.propTypes = {
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	children: PropTypes.node,
};

KiAppBar.defaultProps = {
	className: '',
	title: '',
};

export default KiAppBar;
