// Globals
import React, {useEffect, useState, useContext} from 'react';
import {Route, Switch, useHistory, useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

// Project imports
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import KiAppBar from 'components/KiAppBar';
import KiIconButton from 'components/KiIconButton';
import KiFontIcon from 'components/KiFontIcon';
import ContextSidebar from 'components/ContextSidebar';
import ContextIcons from 'components/ContextSidebar/icons';
import {KiIconCard, KiIconCsv} from 'components/KiIcons';
import FlyoutDates from 'components/FlyoutDates';
import FlyoutCardList from 'components/FlyoutCardList';
import FlyoutCalculations from 'components/FlyoutCalculations';
import {_getHeaders, apiUrl} from 'api/apiUtils';

// Action imports
import {fetchDatasetList, fetchDataset} from 'containers/datasetList/actions';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {useMergedState} from 'utils/customHooks';

// Local imports
import FundingAnalysisContext from './fundingAnalysisContext';
import {BookmarkContext} from './fundingAnalysisBookmark';
import SaveBookmarkMenu from './components/SaveBookmarkMenu';
import FundingExploration from './components/fundingExploration';
import FundingModelDetail from './components/FundingModelDetail';
import FundingModelsList from './components/FundingModelsList';
import FundingModelWizard from './components/FundingModelWizard';
import FundingScenariosList from './components/FundingScenariosList';
import {FundingScenarioForm} from './components/FundingScenarioForm';
import ViewSettings from './components/flyoutForms/ViewSettings';
import ViewList from './components/flyoutForms/ViewList';
import FlyoutFilters from 'components/FlyoutFilters/index';
import styles from './fundingAnalysis.theme.scss';

function FundingPageLayout() {
	// Browser State
	const location = useLocation();
	const history = useHistory();
	const {datasetId, bookmarkId, scenarioId} = useParams();

	// why is this taken from search params instead of router path params?
	const scenarioIdSearchParam = new URLSearchParams(location.search).get('scenarioId');

	// Redux State
	const dispatch = useDispatch();
	const app = useSelector(state => state.app);
	const selectedDataset = useSelector(state => state.datasetList.selected);
	const user = useSelector(state => state.user);

	// Context State
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const tabIndex = fundingAnalysisContext.tabIndex;
	const setContextTabIndex = val => {
		fundingAnalysisContext.setStateItem('tabIndex', val);
	};

	const bookmarkContext = useContext(BookmarkContext);
	const bookmark = bookmarkContext.bookmark;

	// Local State
	const [saveDialogOpen, setSaveDialogOpen] = useState(false);
	const [datasetList, setDatasetList] = useState([]);
	const [datasetBlock, setDatasetBlock] = useMergedState({
		isLoading: true,
		dataset: {},
		datasetName: '',
	});
	const [loadingCSVIcon, setLoadingCSVIcon] = useState(false);

	// On Mount
	useEffect(() => {
		document.title = `${app.kiVersion} - Funding Analysis`;
		async function fetchDatasetsData() {
			const listResponse = await dispatch(fetchDatasetList());
			const fetchedDatasetList = listResponse.datasetList;
			setDatasetList(fetchedDatasetList);

			const currentDatasetId = datasetId || selectedDataset?.datasetId || fetchedDatasetList[0].datasetId;
			const fetchedDataset = await dispatch(fetchDataset(currentDatasetId));
			setDatasetBlock({
				isLoading: false,
				dataset: fetchedDataset,
				datasetName: fetchedDataset.name,
			});

			dispatch(fetchFundingVehicleList(currentDatasetId));

			//check for url dataset id here instead of loading 0 by default
			if (!datasetId) {
				history.push(`/fundingAnalysis/${currentDatasetId}`);
			}
		}

		// Set current tab based on URL path
		if (location.pathname.indexOf('/models') !== -1 && tabIndex !== 1) {
			setContextTabIndex(1);
		} else if (location.pathname.indexOf('/scenarios') !== -1 && tabIndex !== 2) {
			setContextTabIndex(2);
		}

		// RJH 22.05.03:  changed from 'dataset.Columns' below as columns do not seem to be used
		if (!datasetList.length || !selectedDataset.category) {
			fetchDatasetsData();
		} else {
			history.push(`/fundingAnalysis/${datasetId || datasetList?.[0].datasetId}`);
		}
	}, []);

	// If URL changes
	useEffect(
		() => {
			// This must be initialized here so that the flyout settings are loaded
			if (datasetId) {
				bookmarkContext.initializeBookmark(datasetId, bookmarkId, scenarioIdSearchParam || scenarioId);
			}
		},
		[datasetId, bookmarkId]
	);

	const onDatasetChange = async datasetId => {
		if (tabIndex == 1) {
			history.push(`/fundingAnalysis/${datasetId}/models`);
		} else if (tabIndex === 2) {
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} else {
			history.push(`/fundingAnalysis/${datasetId}`);
		}
		const fetchedDataset = await dispatch(fetchDataset(datasetId));
		setDatasetBlock({
			isLoading: false,
			dataset: fetchedDataset,
			datasetName: fetchedDataset.name,
		});

		dispatch(fetchFundingVehicleList(datasetId));
	};

	const onExportCurrentView = () => {
		setLoadingCSVIcon(true);
		const {appliedBookmark} = bookmarkContext;
		const tempBookmark = _.cloneDeep(appliedBookmark);
		delete tempBookmark._id;
		tempBookmark.isDefault = false;
		tempBookmark.isFavorite = false;
		tempBookmark.isReportOnly = true;

		return fetch(`${apiUrl}/fundingOptimization/bookmarks/exportCSV`, {
			credentials: 'include',
			method: 'POST',
			headers: _getHeaders('POST'),
			body: JSON.stringify(tempBookmark),
		})
			.then(res => {
				return res.blob();
			})
			.then(data => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(data);
				link.download = window.REPORT_FILENAME_HIDE_DATETIME
					? `${tempBookmark.name}.csv`
					: `${tempBookmark.name}_${new Date().getTime()}.csv`;
				link.click();
				setLoadingCSVIcon(false);
			});
	};

	const renderAppBar = () => {
		return (
			<header>
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1>Funding Analysis</h1>
						<h1>{` > ${datasetBlock.datasetName || '...'}`}</h1>
						{tabIndex === 0 && (
							<React.Fragment>
								<h1>{` > Analysis`}</h1>
								<h1>{` > ${bookmark.name}`}</h1>
							</React.Fragment>
						)}
						{tabIndex === 1 && <h1>{` > Models`}</h1>}
						{tabIndex === 2 && <h1>{` > Scenarios`}</h1>}
					</div>
					<div className={styles.datasetSelector}>
						<p>Dataset:</p>
						<Select
							value={datasetBlock.dataset._id ? datasetBlock.dataset : null}
							isClearable={false}
							options={datasetList}
							isLoading={datasetBlock.isLoading}
							onChange={val => {
								return onDatasetChange(val.datasetId);
							}}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
						/>
					</div>
					{tabIndex === 0 && (
						<div
							className={styles.selectWrapper}
							style={{
								marginLeft: '5px',
								marginRight: '5px',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
							}}
						>
							<div
								className={`${styles.downloadCsvIcon}`}
								title="CSV Extract"
								onClick={() => onExportCurrentView()}
							>
								{loadingCSVIcon ? (
									<CircularProgress size={24} className={styles.csvProgress} />
								) : (
									<KiIconCsv />
								)}
							</div>
							<KiFontIcon
								value="save"
								className={`${styles.saveViewIcon} list-icon-btn`}
								title="Save current view"
								onClick={() => setSaveDialogOpen(true)}
							/>
						</div>
					)}
					{[1, 2].includes(tabIndex) && (
						<KiIconButton
							icon="add_circle"
							inverse
							disabled={
								(location.pathname.includes('/models') && !!window.BORROWING_BASE_WOA_ENABLED) ||
								!!scenarioId ||
								location.pathname === `/fundingAnalysis/${datasetId}/models/create` ||
								location.pathname === `/fundingAnalysis/${datasetId}/scenarios/create`
							} //TODO - get isAdmin
							title={
								tabIndex === 1
									? 'Click to create a new Funding Model.'
									: 'Click to create a new Scenario.'
							}
							onClick={() => {
								if (tabIndex === 2) {
									fundingAnalysisContext.clearScenario();
									history.push(`/fundingAnalysis/${datasetId}/scenarios/create`);
								} else {
									fundingAnalysisContext.setModel({});
									history.push(`/fundingAnalysis/${datasetId}/models/create`);
									setContextTabIndex(1);
								}
							}}
						/>
					)}
					{saveDialogOpen && (
						<div className={`${styles.saveDialog}`}>
							<SaveBookmarkMenu onCancel={() => setSaveDialogOpen(false)} />
						</div>
					)}
				</KiAppBar>
			</header>
		);
	};

	const renderTabsPanel = () => {
		return (
			<div className={'ki-panel'}>
				{!!fundingAnalysisContext.errors.length && (
					<div className={styles.errorPanel}>
						<section>
							{fundingAnalysisContext.errors.map((e, index) => (
								<p style={{whiteSpace: 'pre-wrap'}} key={index}>
									{e}
								</p>
							))}
						</section>
						<KiIconButton
							iconButton
							title="Clear Errors"
							icon="cancel"
							onMouseUp={() => fundingAnalysisContext.clearErrors()}
						/>
					</div>
				)}
				<KiTabs
					className={styles.tabContainer}
					index={tabIndex}
					onChange={index => {
						switch (index) {
							case 0:
								history.push(`/fundingAnalysis/${datasetId}`);
								break;
							case 1:
								// Clear old bookmark state if tab is switched
								bookmarkContext.resetBookmarkContext();
								history.push(`/fundingAnalysis/${datasetId}/models`);
								break;
							case 2:
								// Clear old bookmark state if tab is switched
								bookmarkContext.resetBookmarkContext();
								history.push(`/fundingAnalysis/${datasetId}/scenarios`);
								break;
						}
						setContextTabIndex(index);
					}}
				>
					<KiTab title="Analysis">
						<Switch>
							<Route
								path={'/fundingAnalysis/:datasetId/bookmark/:bookmarkId'}
								render={() => {
									return <FundingExploration />;
								}}
							/>
							<Route path={'/fundingAnalysis/:datasetId'} render={() => <FundingExploration />} />
						</Switch>
					</KiTab>
					<KiTab title="Models">
						<Switch>
							<Route
								path={'/fundingAnalysis/:datasetId/models/:fundingModelId/detail'}
								render={() => <FundingModelDetail />}
							/>
							<Route
								path={'/fundingAnalysis/:datasetId/models/create'}
								render={() => <FundingModelWizard />}
							/>
							<Route path={'/fundingAnalysis/:datasetId/models'} render={() => <FundingModelsList />} />
						</Switch>
					</KiTab>
					<KiTab title="Scenarios">
						<Switch>
							<Route
								exact
								path="/fundingAnalysis/:datasetId/scenarios"
								component={FundingScenariosList}
							/>
							<Route
								exact
								path="/fundingAnalysis/:datasetId/scenarios/create"
								component={FundingScenarioForm}
							/>
							<Route
								path="/fundingAnalysis/:datasetId/scenarios/:scenarioId/:action?"
								component={FundingScenarioForm}
							/>
						</Switch>
					</KiTab>
				</KiTabs>
			</div>
		);
	};

	return (
		<React.Fragment>
			<article className="funding-vehicle-container container-body">
				{renderAppBar()}
				{renderTabsPanel()}
			</article>
			<ContextSidebar
				items={[
					{
						name: 'View Settings',
						icon: <ContextIcons.MaterialIcon name="settings" />,
						element: <ViewSettings />,
					},
					{
						name: 'Manage Views',
						icon: <ContextIcons.ViewsIcon />,
						element: <ViewList />,
					},
					{
						name: 'Manage Filters',
						icon: <ContextIcons.FiltersIcon />,
						element: <FlyoutFilters datasetId={datasetId} user={user} bookmarkId={bookmark._id} />,
					},
					{
						name: 'Calculations',
						icon: <ContextIcons.CalculationsIcon />,
						element: <FlyoutCalculations user={user} />, //reloadView={()=({})}
					},
					{
						name: 'Manage Dates',
						icon: <ContextIcons.MaterialIcon name="date_range" />,
						element: <FlyoutDates />,
					},
					{
						name: 'Manage Cards',
						icon: <KiIconCard />,
						element: (
							<FlyoutCardList
								datasetId={datasetId}
								bookmark={bookmark}
								explorerViewType="fundingAnalysis"
							/>
						),
					},
				]}
			/>
		</React.Fragment>
	);
}

export default FundingPageLayout;
