import * as actionTypes from './actionTypes';
import {formatDate} from '../../utils/dateUtils';

const setViewType = viewType => {
	return dispatch => {
		dispatch({type: actionTypes.VIEW_TYPE_CHANGE, viewType});
	};
};

const setDateContext = dateContext => {
	return dispatch => {
		dispatch({type: actionTypes.DATE_CONTEXT_CHANGE, dateContext});
	};
};

const setAccountView = accountId => {
	return dispatch => {
		dispatch({type: actionTypes.ACCOUNT_VIEW, accountId});
	};
};

const setStartDate = startDate => {
	startDate = formatDate(startDate);
	return dispatch => {
		dispatch({type: actionTypes.START_DATE_CHANGE, startDate});
	};
};

const setEndDate = endDate => {
	endDate = formatDate(endDate);
	return dispatch => {
		dispatch({type: actionTypes.END_DATE_CHANGE, endDate});
	};
};

const resetState = () => {
	return dispatch => {
		dispatch({type: actionTypes.RESET_STATE});
	};
};

export {setViewType, setDateContext, setAccountView, setStartDate, setEndDate, resetState};
