import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

// body should be an object with sources, options, and filters sections
/*
{
	sources: {},
	options: {},
	filters: {}
}
 */

export const defaultMinimalFieldColumnSpecification = {
	sources: {},
	filter: {},
	filters: {},
	options: {
		includeAllColumns: true,
		embedColumns: true,
		queriedFields: [
			'_id',
			'aggregation',
			'asOfDateType',
			'assetColumnId',
			'assetColumn.displayName',
			'availableForDebt',
			'calcEntityLevel',
			'calculation',
			'columnFormType',
			'columnName',
			'columnType',
			'createdBy',
			'dataType',
			'displayName',
			'dsDateMapId',
			'formula.op',
			'hasModifications',
			'isWaterfall',
			'offset',
			'pendingModificationCheck',
			'status',
			'tags',
			'isSubaccount',
		],
		presentFields: ['formula', 'formulas', 'asOfDateColumn'],
	},
};

export const getColumnsFromService = (datasetId, body) => {
	return fetch(`${apiUrl}/columnService/getColumns/${datasetId}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(body),
	}).then(_handleResponse);
};

export const getUsageFromService = (datasetId, columnId) => {
	return fetch(`${apiUrl}/columnService/getColumnUsage/${datasetId}/${columnId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getMultiUsageFromService = (datasetId, columnIds) => {
	return fetch(`${apiUrl}/columnService/getMultiUsageFromService/${datasetId}?columnIds=${columnIds}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getEligibleColumnIds = datasetId => {
	return fetch(`${apiUrl}/columnService/getEligibleColumnIds/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getIndexColumnsFromService = datasetId => {
	return getColumnsFromService(datasetId, {sources: {includeAbsIndexColumns: true}});
};

export const getRatingColumnsFromService = datasetId => {
	return getColumnsFromService(datasetId, {sources: {includeAbsRatingColumns: true}});
};

export const getGroupByColumnsForDataset = (datasetId, signal) => {
	return fetch(`${apiUrl}/columnService/getGroupByColumnsForDataset/${datasetId}`, {
		signal,
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getMappedColumnsForDataset = datasetId => {
	return fetch(`${apiUrl}/columnService/getMappedColumnsForDataset/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const getColumnByIdFromService = (datasetId, columnId, hydrate = true) => {
	return fetch(`${apiUrl}/columnService/getColumnById/${datasetId}/${columnId}?hydrate=${hydrate}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	getColumnsFromService,
	getMultiUsageFromService,
	getEligibleColumnIds,
	getUsageFromService,
	getIndexColumnsFromService,
	getRatingColumnsFromService,
	getGroupByColumnsForDataset,
	getMappedColumnsForDataset,
	getColumnByIdFromService,
};
