// Globals
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import options from 'ki-common/options';

// Project imports
import KiSelect from 'components/KiSelect';
import KiColumnSelectModal from 'components/KiColumnSelectModal';

// Local imports
import FormulaBuilderContext from '../../FormulaBuilderContext';
import styles from './ValueComponent.theme.scss';

function FunctionValue({entry, onFunctionChange, onValueChange}) {
	// =============
	// Context State
	// =============
	const formulaBuilderContext = useContext(FormulaBuilderContext);
	const allColumns = formulaBuilderContext.allColumns;

	// =========
	// Main Code
	// =========
	const selectOptions = allColumns.filter(col => col.dataType === 'numeric');
	const {value} = entry;

	return (
		<React.Fragment>
			<div className={styles.valueFormElement}>
				<KiSelect
					classNamePrefix="aut-select"
					value={options.comparisonFunctionTypes.find(opt => opt.value === entry.type)}
					options={options.comparisonFunctionTypes}
					onChange={selected => onFunctionChange(selected.value)}
				/>
			</div>
			<div className={styles.functionColumnSelector}>
				<KiColumnSelectModal
					isMultiselect={true}
					columnList={selectOptions}
					value={value}
					onSelect={values => onValueChange(values ? values.map(val => `${val._id}`) : values)}
					modalColumns={{
						showEntityType: true,
						showEntityName: false,
					}}
				/>
			</div>
		</React.Fragment>
	);
}

FunctionValue.propTypes = {
	entry: PropTypes.object,
	onFunctionChange: PropTypes.func.isRequired,
	onValueChange: PropTypes.func.isRequired,
};

FunctionValue.defaultProps = {
	entry: PropTypes.object,
};

export default FunctionValue;
