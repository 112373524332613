import React from 'react';
import PropTypes from 'prop-types';

const ListActionsCell = ({actions}) => {
	return (
		<div className="icons-cell-right">
			{actions.map((action, index) => {
				const {icon, title, callback} = action;
				return (
					<span className="list-icon-btn" key={index}>
						<i className="material-icons" onMouseUp={callback} title={title}>
							{icon}
						</i>
					</span>
				);
			})}
		</div>
	);
};

ListActionsCell.propTypes = {
	actions: PropTypes.arrayOf(
		PropTypes.shape({icon: PropTypes.string, title: PropTypes.string, callback: PropTypes.func})
	).isRequired,
};

export default ListActionsCell;
