import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';
import analytics from 'analytics';

export const deleteDatasets = datasetIds => {
	analytics.sendEvent('datasets', 'delete', datasetIds.toString());
	return fetch(`${apiUrl}/datasets/`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
		body: JSON.stringify(datasetIds),
	}).then(_handleResponse);
};

export const deleteDatasetSnapshots = (dataset, snapshots) => {
	if (dataset.snapshots.length === snapshots.length) {
		return deleteDatasets([
			{
				id: dataset.datasetId,
				name: dataset.name,
			},
		]);
	}
	analytics.sendEvent('datasets', 'delete-snapshots', dataset.datasetId, snapshots);
	return fetch(`${apiUrl}/datasets/${dataset.datasetId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
		body: JSON.stringify({
			datasetName: dataset.name,
			snapshots: snapshots,
		}),
	}).then(_handleResponse);
};

export const fetchDatasetList = (includeEmpty = false) => {
	return fetch(`${apiUrl}/datasets?includeEmpty=${includeEmpty}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

/**
 * fetch a dataset
 * @param {string} id
 * @param {boolean} [embedColumns=true]
 * @param {boolean} [flipArrayToBinary=false]
 * @returns {Promise<{}>}
 */
export const fetchDataset = (id, embedColumns = true, flipArrayToBinary = false) => {
	//TODO: should embedColumns really default to true? it can become very expensive if they're not needed
	const url = new URL(`${apiUrl}/datasets/${id}`, document.baseURI);
	if (embedColumns) url.searchParams.set('embedColumns', 'true');
	if (flipArrayToBinary) {
		url.searchParams.set('flipArrayToBinary', 'true');
	} else {
		url.searchParams.set('flipArrayToBinary', 'false');
	}
	return fetch(url, {credentials: 'include', headers: _getHeaders()}).then(res => _handleResponse(res));
};

export const fetchDatasetByCategoryName = name => {
	// OLD URL=	`${apiUrl}/datasets?categoryName=${name}&embedColumns=true&flipArrayToBinary=false`
	return fetch(`${apiUrl}/datasets?categoryName=${name}&embedColumns=false&flipArrayToBinary=false`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const validateColumnName = (columnName, datasetId) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/columns/validate/${columnName}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const upsertColumns = (column, datasetId) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/columns`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(column),
	}).then(_handleResponse);
};

export const deleteColumn = (columnId, datasetId) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/columns/${columnId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const fetchBusinessFunctionColumns = datasetId => {
	return fetch(`${apiUrl}/datasets/${datasetId}/businessFunctionColumns/`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const generateAsOfDateColumns = (datasetId, columnIds = []) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/asOfDates`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(columnIds),
	}).then(response => {
		if (response.ok) {
			return Promise.resolve();
		} else {
			return response.text().then(errorText => Promise.reject(errorText));
		}
	});
};

export const fetchAsOfDateColumnsByDatasetId = datasetId => {
	return fetch(`${apiUrl}/datasets/${datasetId}/asOfDates?hideDataContextId=true`, {
		credentials: 'include',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const checkForAsOfDateColumnModifications = (datasetId, columnId) => {
	const url = new URL(`${apiUrl}/datasets/${datasetId}/asOfDates/checkForModifications`, document.baseURI);
	if (columnId) url.searchParams.set('columnId', columnId);
	return fetch(url, {
		credentials: 'include',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getLatestSnapshotByDatasetId = datasetId => {
	return fetch(`${apiUrl}/datasets/${datasetId}/latestSnapshot`, {
		credentials: 'include',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const getSnapshotsByDatasetId = datasetId => {
	return fetch(`${apiUrl}/datasets/${datasetId}/snapshots`, {
		credentials: 'include',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export default {
	deleteDatasets,
	deleteDatasetSnapshots,
	fetchDatasetList,
	fetchDataset,
	validateColumnName,
	upsertColumns,
	deleteColumn,
	fetchBusinessFunctionColumns,
	generateAsOfDateColumns,
	fetchAsOfDateColumnsByDatasetId,
	checkForAsOfDateColumnModifications,
	getLatestSnapshotByDatasetId,
	getSnapshotsByDatasetId,
};
