import * as actionTypes from './actionTypes';

const initialState = {
	selectedFilter: 'all',
	schemaErrors: [],
	validationErrors: [],
	schemaColumns: [],
	allFormsValid: true,
};

const AssociatedDataSchema = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_FILTER:
			return {
				...state,
				selectedFilter: action.selectedFilter,
			};

		case actionTypes.SCHEMA_COLUMNS_LOAD_ALL:
			return {
				...state,
				schemaColumns: action.schemaColumns,
			};

		case actionTypes.SCHEMA_COLUMNS_UPDATE_ONE:
			return {
				...state,
				schemaColumns: state.schemaColumns.reduce((acc, col) => {
					if (col.id === action.schemaColumn.id) {
						return [...acc, {...col, ...action.schemaColumn}];
					}
					return [...acc, col];
				}, []),
			};

		case actionTypes.SCHEMA_ERRORS_LOAD_ALL:
			return {
				...state,
				schemaErrors: action.schemaErrors,
			};

		case actionTypes.VALIDATION_ERRORS_LOAD_ALL:
			return {
				...state,
				validationErrors: action.validationErrors,
			};

		default:
			return state;
	}
};

export default AssociatedDataSchema;
