import {Manager} from 'socket.io-client';
const manager = new Manager({
	transports: ['websocket'],
	autoConnect: false,
	reconnectionAttempts: 50,
	path: `${new URL(document.baseURI).pathname}web/socket.io`,
});

export default {
	pendingSubs: [],
	socket: manager.socket('/'),
	namespaces: {
		scenarios: manager.socket('/scenarios'),
		notifications: manager.socket('/notifications'),
		messages: manager.socket('/messages'),
	},
	initialize(store) {
		this.store = store;
		this.socket.open();

		this.namespaces.scenarios.open();
		this.namespaces.notifications.open();
		this.namespaces.messages.open();
		this.pendingSubs.forEach(({event, cb = () => null, namespace}) => {
			this.on(event, cb, namespace);
		});
	},
	on(event, cb, namespace) {
		if (!this.socket) {
			const pendingSub = {
				event,
				cb,
			};
			if (namespace) {
				pendingSub.namespace = namespace;
			}
			this.pendingSubs.push(pendingSub);
		} else if (namespace) {
			this.namespaces[namespace].on(event, (...args) => {
				const result = cb(...args);
				return result instanceof Function ? result(this.store.dispatch, this.store.getState) : result;
			});
		} else {
			this.socket.on(event, (...args) => {
				const result = cb(...args);
				return result instanceof Function ? result(this.store.dispatch, this.store.getState) : result;
			});
		}
	},
	emit(msg, data) {
		this.socket.emit(msg, data);
	},
	emitIn(namespace, msg, data) {
		this.namespaces[namespace].emit(msg, data);
	},
	off(event, fn) {
		this.socket.off(event, fn);
	},
	selfDestruct() {
		this.namespaces.scenarios.close();
		this.namespaces.notifications.close();
		this.namespaces.messages.close();
		this.socket.close();
	},
};
