import React from 'react';
import PropTypes from 'prop-types';
import {ledgerCurrencyFormat} from '../../ledgerUtils';
import {connect} from 'react-redux';
import {setAccountView} from '../../actions';

function BalanceTableItem({item, setAccountView}) {
	const {account, beginning, ending} = item;
	return (
		<tr className="scenario-list-item">
			<td className="align-right">
				<span className="clickable" onClick={() => setAccountView(account)}>
					{account}
				</span>
			</td>
			<td className="align-right">{item['account-code']}</td>
			<td className="align-right">{ledgerCurrencyFormat(beginning.debit)}</td>
			<td className="align-right">{ledgerCurrencyFormat(beginning.credit)}</td>
			<td className="align-right">{ledgerCurrencyFormat(ending.debit)}</td>
			<td className="align-right">{ledgerCurrencyFormat(ending.credit)}</td>
		</tr>
	);
}

BalanceTableItem.propTypes = {
	item: PropTypes.object.isRequired,
	setAccountView: PropTypes.func.isRequired,
};

export default connect(null, {setAccountView})(BalanceTableItem);
