import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FormBlockItemContainer from '../common/FormBlockItemContainer';
import classNames from 'classnames';
import stylesForm from '../common/Form.theme.scss';
import KiSelect from '../../../../components/KiSelect';
import {notionalPoolSectionRateTypeOptions} from 'ki-common/options/assumptions';
import {accrualMethodOptions} from 'ki-common/options';
import KiInput from '../../../../components/KiInput';

const NotionalPoolSectionForm = ({group, index, vectors, onDelete, onSectionChange, indexColumns}) => {
	const [balanceVectorOptions, setBalanceVectorOptions] = useState([]);

	const isGroupValid = groupToValidate =>
		!Object.entries(groupToValidate)
			.map(([, value]) => !!value)
			.includes(false) && groupToValidate.servicing <= 100;

	const onChange = (name, value) => {
		const newGroup = {...group, [name]: value};
		onSectionChange(newGroup, index, isGroupValid(newGroup));
	};

	const onRateTypeChange = value => {
		const newGroup = group;

		switch (value) {
			case notionalPoolSectionRateTypeOptions[0].value:
				delete newGroup.indexVectorId;
				delete newGroup.margin;
				delete newGroup.resetPeriodMonths;
				newGroup.rateType = value;
				newGroup.coupon = '';
				break;
			case notionalPoolSectionRateTypeOptions[1].value:
				delete newGroup.coupon;
				newGroup.rateType = value;
				newGroup.indexVectorId = '';
				newGroup.margin = '';
				newGroup.resetPeriodMonths = '';
				break;
		}
		onSectionChange(newGroup, index, isGroupValid(newGroup));
	};

	const createVectorOptionsForType = type => {
		return vectors.filter(vector => vector.type === type).map(vector => ({label: vector.name, value: vector._id}));
	};

	useEffect(
		() => {
			//setrateIndexOptions(createVectorOptionsForType('rate'));
			setBalanceVectorOptions(createVectorOptionsForType('balance'));
		},
		[vectors]
	);

	return (
		<FormBlockItemContainer title={`Group ${index + 1}`} onDelete={() => onDelete(index)} isVisible={true}>
			<div className={classNames('sidebar-form-section', stylesForm.multiItemsSection)}>
				<div>
					<span className="form-instruction">Term</span>
					<KiInput
						className="substring-size-input"
						type="text"
						value={group.term}
						onChange={value => onChange('term', value)}
						isNumberMasked={true}
					/>
				</div>
				<div>
					<span className="form-instruction">Servicing %</span>
					<KiInput
						className="substring-size-input"
						type="text"
						value={group.servicing}
						onChange={value => onChange('servicing', value)}
						isNumberMasked={true}
						maskConfig={{
							allowNegative: false,
						}}
						error={group.servicing > 100 && 'Must be between 0-100'}
					/>
				</div>
				<div>
					<span className="form-instruction">Accrual Basis</span>
					<KiSelect
						value={accrualMethodOptions.find(option => option.value === group.basis) || ''}
						options={accrualMethodOptions}
						onChange={o => onChange('basis', o.value)}
					/>
				</div>
			</div>
			<div className={classNames('sidebar-form-section', stylesForm.multiItemsSection)}>
				<div>
					<span className="form-instruction">Rate Type</span>
					<KiSelect
						value={notionalPoolSectionRateTypeOptions.find(option => option.value === group.rateType) || ''}
						options={notionalPoolSectionRateTypeOptions}
						onChange={o => onRateTypeChange(o.value)}
					/>
				</div>
				{!group.rateType && (
					<>
						<div />
						<div />
					</>
				)}
				{group.rateType === notionalPoolSectionRateTypeOptions[0].value && (
					<>
						<div>
							<span className="form-instruction">Coupon</span>
							<KiInput
								className="substring-size-input"
								type="text"
								value={group.coupon}
								onChange={value => onChange('coupon', value)}
								isNumberMasked={true}
							/>
						</div>
						<div />
					</>
				)}
				{group.rateType === notionalPoolSectionRateTypeOptions[1].value && (
					<>
						<div>
							<span className="form-instruction">Index</span>
							<KiSelect
								value={indexColumns.find(option => option.value === group.indexVectorId)}
								options={indexColumns}
								onChange={o => onChange('indexVectorId', o.value)}
							/>
						</div>
						<div>
							<span className="form-instruction">Margin</span>
							<KiInput
								className="substring-size-input"
								type="text"
								value={group.margin}
								onChange={value => onChange('margin', value)}
								isNumberMasked={true}
							/>
						</div>
					</>
				)}
			</div>
			<div className={classNames('sidebar-form-section', stylesForm.multiItemsSection)}>
				<div>
					<span className="form-instruction">Notional Amount</span>
					<KiSelect
						className="substring-size-input notional-vector-selector"
						value={balanceVectorOptions.find(option => option.value === group.notionalAmountVectorId)}
						options={balanceVectorOptions}
						onChange={o => onChange('notionalAmountVectorId', o.value)}
					/>
				</div>
				{group.rateType === notionalPoolSectionRateTypeOptions[1].value && (
					<div>
						<span className="form-instruction">Reset Period</span>
						<KiInput
							className="substring-size-input"
							type="text"
							value={group.resetPeriodMonths}
							onChange={value => onChange('resetPeriodMonths', value)}
							isNumberMasked={true}
							maskConfig={{
								allowNegative: false,
							}}
						/>
					</div>
				)}
			</div>
		</FormBlockItemContainer>
	);
};

NotionalPoolSectionForm.propTypes = {
	group: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	vectors: PropTypes.arrayOf(PropTypes.object).isRequired,
	onDelete: PropTypes.func.isRequired,
	onSectionChange: PropTypes.func.isRequired,
	indexColumns: PropTypes.array.isRequired,
};

export default NotionalPoolSectionForm;
