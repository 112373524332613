import PropTypes from 'prop-types';
import React from 'react';
import KiListItem from 'components/KiList/KiListItem';
import KiList from 'components/KiList';
import styles from './ColumnMenu.theme.scss';

export const GranularityMenu = props => {
	if (props.col.granularityOptions && props.col.granularityOptions.length) {
		const menuItems = props.col.granularityOptions.map(option => {
			let selected = props.col.granularity === option.value;
			if (props.bands.type !== 'default') {
				selected = props.bands.type === option.value;
			}
			const className = selected ? 'granularity-selected granularity-menu-item' : 'granularity-menu-item';
			return (
				<KiListItem
					key={option.value}
					caption={option.label}
					icon={selected ? 'done' : 'none'}
					className={className}
					onClick={() => (selected ? null : props.handleClick(option.value))}
				/>
			);
		});
		return (
			<div>
				<div className={styles.granularityTitle}>Granularity</div>
				<KiList>{menuItems}</KiList>
			</div>
		);
	}
	return null;
};

GranularityMenu.propTypes = {
	col: PropTypes.object,
	handleClick: PropTypes.func,
	bands: PropTypes.object,
};

export default GranularityMenu;
