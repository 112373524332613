import * as actionTypes from './actionTypes';

const initialState = {
	isLoading: false,
	error: null,
	data: [],
	eligiblePools: [],
};

const FundingVehicleList = (state = initialState, action) => {
	//eslint-disable-line complexity
	switch (action.type) {
		case actionTypes.FUNDING_VEHICLE_LIST_FETCH:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						data: action.fundingVehicleList,
						isLoading: false,
						eligiblePools: [],
					};
				case 'error':
					return {...state, error: action.msg, isLoading: false};

				default:
					return state;
			}

		default:
			return state;
	}
};

export default FundingVehicleList;
