import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './maxRecords.theme.scss';
import KiInput from 'components/KiInput';
import KiButton from 'components/KiButton';

export class MaxRecords extends Component {
	static propTypes = {
		visible: PropTypes.bool,
		cancel: PropTypes.func,
		save: PropTypes.func,
		maxRecords: PropTypes.string,
	};

	static defaultProps = {
		visible: false,
		maxRecords: '',
	};

	state = {
		maxRecords: this.props.maxRecords || '',
	};

	render() {
		if (!this.props.visible) {
			return null;
		}
		return (
			<div className={styles.maxRecordsForm}>
				<div className={styles.title}>Number of Rows</div>
				<KiInput
					value={this.state.maxRecords}
					onChange={val => this.setState({maxRecords: val})}
					isNumberMasked={true}
					maskConfig={{
						allowDecimal: false,
						allowNegative: false,
					}}
				/>
				<div className={styles.formButtons}>
					<KiButton flat primary onClick={this.props.cancel} label="Cancel" />
					<KiButton raised primary onClick={() => this.props.save(this.state.maxRecords)} label="Apply" />
				</div>
			</div>
		);
	}
}
export default MaxRecords;
