const testUtils = require('../../utils/testUtils');

const getConstraints = () => ({
	'createdDate': {
		presence: {allowEmpty: false},
		format: {
			pattern: testUtils.ISO8601_DATE_PATTERN,
			message: 'must be in ISO8601 format'
		},
		datetime: true
	},
	'submittedBy': {
		presence: {allowEmpty: false},
		format: {
			pattern: '[A-Za-z0-9 .()]+',
			flags: 'g',
			message: 'can only contain alphanumerics and .()'
		}
	},
	'approvedBy': {
		format: {
			pattern: '[a-zA-Z0-9 .()]+',
			flags: 'g',
			message: 'can only contain alphanumerics and .()'
		}
	},
});

module.exports = {
	getConstraints
};
