import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiInput from 'components/KiInput';
import KiIconButton from 'components/KiIconButton';
import Select from 'components/KiSelect';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import styles from './calculation.theme.scss';
import {defaultCalculationTypes} from 'ki-common/options';
import _sortBy from 'lodash/sortBy';

export const DEFAULT_CALCS_MAP = new Map([
	['advance_rate', 'Advance Rate'],
	['gross_borrowing_base', 'Gross Borrowing Base'],
	['evaluated_balance', 'Evaluated Balance'],
	['net_borrowing_base', 'Net Borrowing Base'],
]);

const columnSourceTypes = [
	{
		label: 'Asset',
		value: 'asset',
	},
	{
		label: 'Debt',
		value: 'debt',
	},
];

const debtOnlyCalculations = ['net_borrowing_base'];
const assetOnlyCalculations = ['evaluated_balance'];

export class Calculation extends Component {
	static defaultProps = {
		tbCohortColumns: [],
	};

	static propTypes = {
		setting: PropTypes.object,
		settings: PropTypes.array,
		updateMethod: PropTypes.func,
		insertMethod: PropTypes.func,
		formType: PropTypes.string,
		fundingVehicleId: PropTypes.string,
		showDeleteConfirmModal: PropTypes.func.isRequired,
		tbCohortColumns: PropTypes.array,
		debtCalcColumns: PropTypes.array,
	};

	state = {
		name: _get(this.props, 'setting.name', ''),
		value: _get(this.props, 'setting.value', ''),
		columnSourceType: _get(this.props, 'setting.columnSourceType', ''),
		formErrors: {},
		showButtons: false,
		submitDisabled: true,
	};

	componentDidUpdate(prevProps) {
		if (!_isEqual(this.props.setting, prevProps.setting)) {
			this.setState({
				name: _get(this.props, 'setting.name', ''),
				value: _get(this.props, 'setting.value', ''),
				columnSourceType: _get(this.props, 'setting.columnSourceType', ''),
			});
		}
	}

	clearForm = () => {
		this.setState({
			name: '',
			value: '',
			columnSourceType: '',
		});
	};

	resetForm = () => {
		this.setState({
			name: this.props.setting.name,
			value: this.props.setting.value,
			columnSourceType: this.props.setting.columnSourceType,
		});
	};

	submit = e => {
		e.preventDefault();
		if (this.props.formType === 'insert') {
			this.props.insertMethod({
				...this.props.setting,
				fundingVehicleId: this.props.fundingVehicleId,
				name: this.state.name,
				value: this.state.value,
				columnSourceType: this.state.columnSourceType,
			});
			this.clearForm(); // After adding new clear the insert row
		} else {
			this.props.updateMethod({
				...this.props.setting,
				name: this.state.name,
				value: this.state.value,
				columnSourceType: this.state.columnSourceType,
			});
		}
	};

	render() {
		if (!this.props.setting) {
			return null;
		}

		const {tbCohortColumns, debtCalcColumns} = this.props;
		const isInsert = this.props.formType === 'insert';
		const isDirty = !_isEqual(
			_pick(this.props.setting, ['name', 'value', 'columnSourceType']),
			_pick(this.state, ['name', 'value', 'columnSourceType'])
		);

		// Certain calculations can only use debt calc columns for their mapping
		let possibleColumnTypes = columnSourceTypes;
		if (debtOnlyCalculations.includes(this.state.name)) {
			possibleColumnTypes = columnSourceTypes.filter(c => c.value === 'debt');
		}
		if (assetOnlyCalculations.includes(this.state.name)) {
			possibleColumnTypes = columnSourceTypes.filter(c => c.value === 'asset');
		}

		// Remove already used names
		const nameOptions = defaultCalculationTypes.filter(c => !this.props.settings.find(s => s.name === c.value));

		// Get the matching option for the current values
		const nameValue = defaultCalculationTypes.find(option => option.value === this.state.name) || '';
		const columnSourceTypeValue = columnSourceTypes.find(col => col.value === this.state.columnSourceType) || '';

		// Select asset or debt columns for use mapping and organize by name
		let associatedColumnOptions = [];
		if (this.state.columnSourceType) {
			const columnOptions = this.state.columnSourceType === 'asset' ? tbCohortColumns : debtCalcColumns;
			associatedColumnOptions = _sortBy(columnOptions.filter(c => c.dataType === 'numeric'), cc =>
				(cc.displayName || '').toLowerCase()
			);
		}

		return (
			<form onSubmit={this.submit} className={`fv-settings-form ${styles.root}`}>
				<section className="fv-settings-form-fields">
					<div className="cell">
						<div className="select-wrapper">
							<span className="theme-label">Name</span>
							{isInsert ? (
								<Select
									value={nameValue}
									getOptionLabel={option => option.label}
									getOptionValue={option => option.value}
									options={nameOptions}
									placeholder="Select calculation..."
									autosize={false}
									openOnFocus={true}
									onChange={option => {
										this.setState({name: _get(option, 'value', '')});
									}}
								/>
							) : (
								<KiInput
									className={styles.constantInput}
									value={_get(nameValue, 'label', this.state.name)}
									disabled={true}
								/>
							)}
						</div>
					</div>
					<div className="cell">
						<div className="select-wrapper">
							<span className="theme-label">Column Type</span>
							<Select
								classNamePrefix="aut-select"
								ref={e => (this.columnSelectorRef = e)}
								value={columnSourceTypeValue}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
								options={possibleColumnTypes}
								placeholder="Select type..."
								autosize={false}
								openOnFocus={true}
								onChange={option => {
									this.setState({
										columnSourceType: option.value,
										value: '',
									});
								}}
							/>
						</div>
					</div>
					<div className="cell">
						<div className="select-wrapper">
							<span className="theme-label">Associated Calculation</span>
							<Select
								classNamePrefix="aut-select"
								ref={e => (this.columnSelectorRef = e)}
								value={associatedColumnOptions.filter(col => col._id === this.state.value)}
								getOptionLabel={option => option.displayName}
								getOptionValue={option => option._id}
								options={associatedColumnOptions}
								placeholder="Select column..."
								autosize={false}
								openOnFocus={true}
								onChange={option => {
									if (!option) {
										this.setState({value: null});
									} else {
										this.setState({value: option._id});
									}
								}}
							/>
						</div>
					</div>
				</section>
				<div className="setting-save">
					{isDirty && !isInsert && <KiIconButton icon="undo" onClick={this.resetForm} />}
					{isDirty && (
						<KiIconButton
							disabled={!this.state.name || this.state.value === ''}
							className={isDirty || isInsert ? '' : 'hidden'}
							icon={this.props.formType === 'update' ? 'save' : 'add'}
							onClick={this.submit}
						/>
					)}
					{!isInsert && <KiIconButton icon="delete" onClick={this.props.showDeleteConfirmModal} />}
				</div>
			</form>
		);
	}
}

export default Calculation;
