import {dataBookmarksApi, viewsApi} from 'api';
import * as actionTypes from './actionTypes';
import {showSnackbar} from 'state/actions/Snackbar';

export const fetchViewsByDataset = (userId, datasetId, isAdmin) => dispatch => {
	dispatch({type: actionTypes.DATA_BOOKMARKS_FETCH, status: 'pending'});
	if (isAdmin) {
		return viewsApi
			.fetchViewsByDataset(datasetId)
			.then((bookmarks = []) => {
				dispatch({
					type: actionTypes.DATA_BOOKMARKS_FETCH,
					status: 'success',
					payload: bookmarks,
				});
				return bookmarks;
			})
			.catch(() => dispatch({type: actionTypes.DATA_BOOKMARKS_FETCH, status: 'error'}));
	}

	return dataBookmarksApi
		.fetchBookmarksByUserId(userId, datasetId)
		.then((bookmarks = []) => {
			dispatch({
				type: actionTypes.DATA_BOOKMARKS_FETCH,
				status: 'success',
				payload: bookmarks,
			});
			return bookmarks;
		})
		.catch(() => dispatch({type: actionTypes.DATA_BOOKMARKS_FETCH, status: 'error'}));
};

export const deleteBookmark = bookmarkId => dispatch =>
	dataBookmarksApi.deleteBookmark(bookmarkId).then(result => {
		dispatch(showSnackbar('Deleted view(s) successfully'));
		dispatch({
			type: actionTypes.DATA_BOOKMARKS_REMOVE,
			payload: bookmarkId,
		});
		return result;
	});

export default {
	fetchViewsByDataset,
	deleteBookmark,
};
