import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from '../../AssociatedData.theme.scss';
import KiButtonSpinner from 'components/KiButtonSpinner';
import Select from 'react-select';
import SchemaErrorSummary from './SchemaErrorSummary';
import {setFilter} from './actions';

export class ValidationSummary extends Component {
	static propTypes = {
		schemaMessages: PropTypes.array,
		validationMessages: PropTypes.array,
		setFilter: PropTypes.func,
		selectedFilter: PropTypes.string,
		validateData: PropTypes.func,
		enableValidateButton: PropTypes.bool,
		dataValidating: PropTypes.bool,
	};

	static defaultProps = {
		schemaMessages: [],
		validationMessages: [],
		enableValidateButton: false,
		dataValidating: false,
	};

	handleValidateSchema = () => {
		this.props.validateData();
	};

	filterOptions = [
		{
			label: 'All Columns',
			value: 'all',
		},
		{
			label: 'New Columns',
			value: 'new',
		},
		{
			label: 'Exceptions',
			value: 'exceptions',
		},
	];

	handleFilterChange = option => {
		this.props.setFilter(option.value);
	};

	render() {
		return (
			<div className={styles.schemaValidationSummary}>
				<div className={styles.filter}>
					<Select
						classNamePrefix="aut-select"
						value={this.filterOptions.find(f => f.value === this.props.selectedFilter)}
						isClearable={false}
						options={this.filterOptions}
						onChange={this.handleFilterChange}
					/>
				</div>
				<div className={styles.validations}>
					<div>
						<h3>Schema Errors</h3>
						<SchemaErrorSummary errors={this.props.schemaMessages} />
					</div>
					<div>
						<h3>Validation Errors</h3>
						<SchemaErrorSummary errors={this.props.validationMessages} />
					</div>
				</div>
				<div className={styles.validateBtn}>
					<KiButtonSpinner
						showSpinner={this.props.dataValidating}
						primary={true}
						raised
						disabled={!this.props.enableValidateButton}
						className={styles.nextButton}
						onClick={this.handleValidateSchema}
						title="Click to validate entire file"
						label="Validate"
					/>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = () => ({
	setFilter,
});

const mapStateToProps = state => ({
	selectedFilter: state.associatedDataSchema.selectedFilter,
});

export default connect(mapStateToProps, mapDispatchToProps())(ValidationSummary);
