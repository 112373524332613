export const datasetCopyIcon = `<svg width="24px" height="18px" viewBox="0 0 24 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<title>DatasetCopy</title>
<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="DatasetСopy_blue" transform="translate(-4.000000, -7.000000)" fill="#135E82" fill-rule="nonzero">
        <path d="M19,7 C23.9705627,7 28,11.0294373 28,16 C28,20.9705627 23.9705627,25 19,25 C14.0294373,25 10,20.9705627 10,16 C10,11.0294373 14.0294373,7 19,7 Z M10.0004159,7.51197015 L9.99977517,9.55736653 C7.57509089,10.6884274 5.89473684,13.1479798 5.89473684,16 C5.89473684,18.8520202 7.57509089,21.3115726 9.99977517,22.4426335 L10.0004159,24.4880298 C6.50462151,23.252638 4,19.9188119 4,16 C4,12.0811881 6.50462151,8.74736195 10.0004159,7.51197015 Z M23.7768135,10.9917726 L23.7163201,11.0127657 L18.4655751,13.84009 C17.8529494,14.1699653 17.3383246,14.6531734 16.9708846,15.2405303 L16.84009,15.4655751 L14.0127657,20.7163201 C13.9808913,20.7755154 13.9808913,20.8467648 14.0127657,20.9059601 C14.0546598,20.9837634 14.1406657,21.0226122 14.2231865,21.0082274 L14.2836799,20.9872343 L19.5344249,18.15991 C20.1470506,17.8300347 20.6616754,17.3468266 21.0291154,16.7594697 L21.15991,16.5344249 L23.9872343,11.2836799 C24.0191087,11.2244846 24.0191087,11.1532352 23.9872343,11.0940399 C23.9453402,11.0162366 23.8593343,10.9773878 23.7768135,10.9917726 Z M20.0606602,14.9393398 C20.6464466,15.5251263 20.6464466,16.4748737 20.0606602,17.0606602 C19.4748737,17.6464466 18.5251263,17.6464466 17.9393398,17.0606602 C17.3535534,16.4748737 17.3535534,15.5251263 17.9393398,14.9393398 C18.5251263,14.3535534 19.4748737,14.3535534 20.0606602,14.9393398 Z" id="DatasetCopy"></path>
    </g>
</g>
</svg>
`;

/*
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<g>
    <path fill="none" d="M0 0h24v24H0z"/>
    <path d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"/>
</g>
</svg>
*/
