import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchViewsByDataset = datasetId => {
	return fetch(`${apiUrl}/views/dataset/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	fetchViewsByDataset,
};
