import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './KiCardDebt.scss';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import {buildCalculation} from 'ki-common/utils/explorerUtils';
import _ from 'lodash';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {datasetDatesApi, debtExplorerApi, waterfallMappingApi} from 'api';
import {buildRequestFromViewAndCard} from 'ki-common/utils/debtRequestBuilder';
import KiCardFilters from 'components/KiCard/KiCardFilters';
import HeaderCell from './components/HeaderCell';
import TableCell from './components/TableCell';
import {getDebtDateMsg} from 'ki-common/utils/cardUtil';
import debtUtils from 'ki-common/utils/debtUtils';

const debtScenarioContexts = [
	{
		label: 'Start of Day',
		value: 'assetSnapshot',
	},
	{
		label: 'Last Locked',
		value: 'lastCommitted',
	},
	{
		label: 'End of Day',
		value: 'lastApproved',
	},
];

// Returns object {columns: [], rows: []}
const getTableColumnsAndRows = ({rows = [], columns = [], columnInfo = []}, transpose, viewType) => {
	let transformedRows = [...rows];
	let transformedColumns = viewType === 'activity' || viewType === 'waterfall' ? [...columns] : [...columnInfo];
	if (transpose) {
		transformedRows = transformedColumns
			.map((colInfo = {}, colIdx) => ({
				data: [
					`${colInfo.displayName}${buildCalculation(colInfo.calculation)}`,
					...rows.map(row => row.data[colIdx]),
				],
			}))
			.slice(1);
		if (transformedColumns.length) {
			transformedColumns = [
				`${transformedColumns[0].displayName}${buildCalculation(transformedColumns[0].calculation)}`,
				...rows.map(row => row.data[0]),
			];
		}
	}
	const getHeaderCell = col => {
		const title = transpose ? col : `${col.displayName}${buildCalculation(col.calculation)}`;
		return <HeaderCell title={title} />;
	};
	return {
		rows: transformedRows,
		columns: transformedColumns.map((col, idx) => ({
			minWidth: 120,
			maxWidth: 180,
			Header: getHeaderCell(col),
			id: `${col.id}-${idx}`,
			accessor: rowData => rowData.data[idx],
			// eslint-disable-next-line react/display-name
			Cell: row => (
				<TableCell column={!transpose ? col : idx ? columns[row.index + 1] : col} cellValue={row.value} />
			),
		})),
	};
};

async function fetchData(card, statementDate, fundingVehicleId, transpose, view, scenarioId) {
	// eslint-disable-line
	try {
		if (view.settings.viewType === 'waterfall' && !fundingVehicleId)
			throw new Error('Waterfall data requires a funding vehicle be selected');
		const mappings = await waterfallMappingApi.fetchMappingsByDataset(card.datasetId);
		const dateGroups = await datasetDatesApi.fetchDates(card.datasetId, true, false).then(result =>
			result.map(dateGroup => {
				const mapMatch = mappings.find(mapping => mapping.defaultValue === dateGroup.groupId);
				return !mapMatch ? dateGroup : {...dateGroup, mapName: mapMatch.name};
			})
		);
		const debtRequestBody = buildRequestFromViewAndCard(
			view,
			{
				...card,
				settings: {
					...card.settings,
					fundingVehicleId, //let fv filter get the last call
					//scenarioId: scenarioId, we don't pass scenario id from card preview yet
					scenarioType: scenarioId,
				},
			},
			dateGroups,
			statementDate
		);
		const data = await debtExplorerApi.fetchDebtExplore(debtRequestBody);
		return getTableColumnsAndRows(data, transpose, view.settings.viewType);
	} catch (err) {
		return Promise.reject(err);
	}
}

async function fetchCalculatedDates(assetDateContextId, debtDateContextId, fundingVehicleId, statementDate, datasetId) {
	if (!fundingVehicleId) return Promise.resolve([]);
	try {
		const request = [
			{groupId: assetDateContextId, statementDate: statementDate, fundingVehicleId: fundingVehicleId},
			{groupId: debtDateContextId, statementDate: statementDate, fundingVehicleId: fundingVehicleId},
		];
		return await datasetDatesApi.getCalculatedDates(request, datasetId);
	} catch (err) {
		return Promise.reject(err);
	}
}

function KiCardDebt(props) {
	const {card, view, fundingVehicles, fundingVehicleId, scenarioParentId} = props;
	// set up state
	const requiresFVSelection = ['range', 'recurring'].includes(_.get(props, 'view.quickFilters.rangeType'));
	const [data, setData] = useState({rows: [], columns: []});
	const [statementDate, setStatementDate] = useState(dateToShortDate(new Date()));
	const [fundingVehicle, setFundingVehicle] = useState(
		fundingVehicles.find(
			fv => fv._id === ((card.settings.fundingVehicle ? card.settings.fundingVehicle : fundingVehicleId) || null)
		) || (requiresFVSelection && fundingVehicles.length ? fundingVehicles[0] : undefined)
	);
	const [reactTableLoading, setReactTableLoading] = useState(false);
	const [error, setError] = useState('');
	const [dateContextMessage, setDateContextMessage] = useState('');

	const [scenarioId, setScenarioId] = useState(
		debtScenarioContexts.find(
			s =>
				s.value ===
				((card.settings.scenarioId ? card.settings.scenarioId : scenarioParentId) || 'assetSnapshot')
		)
	);

	useEffect(
		() => {
			setError('');
			setReactTableLoading(true);
			fetchData(
				card,
				statementDate,
				_.get(fundingVehicle, '_id', ''),
				_.get(card, 'settings.transpose', false),
				view,
				_.get(scenarioId, 'value', null)
			)
				.then(result => setData(result))
				.catch(err => setError(err.message || err))
				.finally(() => setReactTableLoading(false));

			fetchCalculatedDates(
				view.settings.assetDateContext,
				view.settings.debtDateContext,
				_.get(fundingVehicle, '_id', ''),
				statementDate,
				card.datasetId
			).then(calculatedDates => {
				setDateContextMessage(getDebtDateMsg(calculatedDates[0], calculatedDates[1]));
			});
		},
		[statementDate, fundingVehicle, scenarioId]
	);

	return (
		<div className="table-container" style={{overflow: 'auto'}}>
			<KiCardFilters
				fundingVehicle={fundingVehicle}
				fundingVehicles={fundingVehicles}
				statementDate={statementDate}
				setFundingVehicle={setFundingVehicle}
				setStatementDate={setStatementDate}
				isFundingVehicleSelectClearable={!requiresFVSelection}
				scenarioList={debtUtils.getAvailableScenarioContextOptions(
					debtScenarioContexts,
					_.get(view, 'settings.cohortColumn', ''),
					_.get(view, 'settings.viewType', '')
				)}
				setScenarioId={setScenarioId}
				scenarioId={scenarioId}
			/>
			{!!error && <div className="dataCardError">{`Error fetching data: ${error}`}</div>}
			<div className="debt-data-message">{dateContextMessage}</div>
			<AbsTable
				isSortEnabled={true}
				isFilterEnabled={false}
				data={data.rows}
				isLoading={reactTableLoading}
				columns={data.columns}
			/>
		</div>
	);
}

KiCardDebt.propTypes = {
	view: PropTypes.object,
	card: PropTypes.object,
	fundingVehicles: PropTypes.array,
	fundingVehicleId: PropTypes.string,
	scenarioParentId: PropTypes.string,
};

KiCardDebt.defaultProps = {
	view: {},
	card: {
		settings: {
			statementDate: new Date(),
			fundingVehicleId: '',
			transpose: false,
		},
	},
	fundingVehicles: [],
};

export default KiCardDebt;
