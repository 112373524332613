/* eslint-disable react/prop-types */
// Global imports
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import moment from 'moment';

// Project imports
import {reportsApi} from 'api';
import KiAppBar from 'components/KiAppBar';
import KiIconButton from 'components/KiIconButton';
import KiConfirmModal from 'components/KiConfirmModal';
import KiSelect from 'components/KiSelect';
import {showSnackbar} from 'state/actions/Snackbar';
import {fetchDatasetList, setSelectedDataset as setActionSelectedDataset} from 'containers/datasetList/actions';

// Local imports
import ReportList from './components/ReportsList';
import ReportGroups from './components/ReportGroups';
import ReportExecutionModal from './components/ReportExecutionModal';
import styles from './reports.theme.scss';
import {getFundingVehicles, getOutputTypes, getReportGroups} from './helpers';

const outputTypes = getOutputTypes();

const ReportsContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {reportGroupId} = useParams();

	// redux state references
	const isAdmin = useSelector(
		state =>
			!!state.user.absSuiteProfile?.capital_markets_roles?.includes('admin') ||
			(!window.ABS_SSO_ENABLED && state.user.groups.includes('SystemAdmins'))
	);
	const kiVersion = useSelector(state => state.app.kiVersion);
	const stateDataset = useSelector(state => state.datasetList.selected);

	// local state
	const [datasets, setDatasets] = useState([]);
	const [selectedDataset, setSelectedDataset] = useState(null);
	const [fundingVehicles, setFundingVehicles] = useState([]);
	const [reportDefinitions, setReportDefinitions] = useState([]);
	const [populatedReportDefinitions, setPopulatedReportDefinitions] = useState([]);
	const [reportDefinitionIdToDelete, setReportDefinitionIdToDelete] = useState(null);
	const [isReportGroupExecutionModalActive, setIsReportGroupExecutionModalActive] = useState(false);
	const [rawReportGroups, setRawReportGroups] = useState([]);
	const [reportGroups, setReportGroups] = useState([]);
	const [activeReportGroupId, setActiveReportGroupId] = useState(reportGroupId);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const populateReportDefinitions = () => {
		return reportDefinitions.map(reportDefinition => {
			const datasetName =
				datasets.find(dataset => dataset.datasetId === reportDefinition.settings.datasetId)?.name || '';
			const fundingVehicleName =
				fundingVehicles.find(
					fundingVehicle => fundingVehicle._id === reportDefinition.settings.fundingVehicleId
				)?.name || '';
			const outputTypeName =
				outputTypes.find(outputType => outputType.value === reportDefinition.settings.outputType)?.label || '';
			const lastModified = `Updated by ${reportDefinition.updatedBy} on ${moment(
				reportDefinition.updatedDate
			).format('YYYY-MM-DD HH:mm')}`;
			return {
				...reportDefinition,
				datasetName,
				fundingVehicleName,
				outputTypeName,
				lastModified,
			};
		});
	};

	const fetchReportDefinitions = async () => {
		setIsLoading(true);
		setReportDefinitions(await reportsApi.fetchReportDefinitionsByReportGroupId(activeReportGroupId));
		setIsLoading(false);
	};

	const getRawReportGroups = async () => {
		const rawReportGroupsList = await getReportGroups();
		setRawReportGroups(rawReportGroupsList);
		return rawReportGroupsList;
	};

	const filterReportGroups = () => {
		const filteredReportGroups = selectedDataset
			? rawReportGroups.filter(rg => rg.datasetId === selectedDataset.datasetId)
			: [];
		setReportGroups(filteredReportGroups);
	};

	// if state is not loaded, fetch data
	useEffect(() => {
		document.title = `${kiVersion} - Reports`;

		(async () => {
			try {
				setIsLoading(true);
				const datasetsResult = await dispatch(fetchDatasetList());
				const rawDatasets = datasetsResult.datasetList;
				setDatasets(rawDatasets);

				const reportResults = await getRawReportGroups();
				const initialDatasetId = reportGroupId
					? reportResults.find(item => item._id === reportGroupId)?.datasetId
					: stateDataset?.datasetId;

				const initialDataset = initialDatasetId
					? rawDatasets.find(item => item.datasetId === initialDatasetId)
					: rawDatasets[0];
				setSelectedDataset(initialDataset);

				const rawFundingVehicles = await getFundingVehicles();
				setFundingVehicles(rawFundingVehicles);

				setActiveReportGroupId(reportGroupId);

				setIsLoading(false);
			} catch (error) {
				setError(error.message);
			}
		})();
	}, []);

	useEffect(
		() => {
			if (selectedDataset) {
				setActiveReportGroupId(null);
				setReportDefinitions([]);
				setPopulatedReportDefinitions([]);
			}
			filterReportGroups();
		},
		[selectedDataset]
	);

	useEffect(
		() => {
			filterReportGroups();
		},
		[rawReportGroups]
	);

	useEffect(
		() => {
			if (reportGroups.length) {
				setActiveReportGroupId(reportGroups[0]._id);
			}
		},
		[reportGroups]
	);

	useEffect(
		() => {
			if (activeReportGroupId) {
				(async () => {
					setReportDefinitions([]);
					await fetchReportDefinitions();
				})();
				history.push(`/reports/${activeReportGroupId}`);
			} else {
				history.push(`/reports`);
			}
		},
		[activeReportGroupId]
	);

	useEffect(
		() => {
			setPopulatedReportDefinitions(populateReportDefinitions());
		},
		[reportDefinitions, datasets, fundingVehicles]
	);

	const handleAddReportDefinition = () => {
		history.push('/reports/definition');
	};

	const handleDeleteReportDefinition = async () => {
		await reportsApi.deleteReportDefinition(reportDefinitionIdToDelete);
		setReportDefinitionIdToDelete(null);
		dispatch(showSnackbar(`Report deleted`));

		await fetchReportDefinitions();
	};

	const onDatasetChange = dataset => {
		setSelectedDataset(dataset);
		dispatch(setActionSelectedDataset(dataset));
	};

	if (!datasets.length) return null;

	return (
		<div className={styles.reportsList}>
			<KiAppBar title={`Reports`} className="top-bar">
				<div className={styles.datasetSelector}>
					<p>Dataset:</p>
					<KiSelect
						value={selectedDataset}
						isClearable={false}
						options={datasets}
						isLoading={isLoading}
						onChange={onDatasetChange}
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
					/>
				</div>
				{isAdmin && (
					<KiIconButton
						icon="add_circle"
						inverse
						onClick={handleAddReportDefinition}
						title="Click to create a new report."
					/>
				)}
			</KiAppBar>
			<div className="ki-panel">
				<div className={styles.actions}>
					<ReportGroups
						datasets={datasets}
						reportGroups={reportGroups}
						activeReportGroupId={activeReportGroupId}
						onChangeReportGroupId={id => {
							setActiveReportGroupId(id);
						}}
						onSaveReportGroup={async () => {
							await getRawReportGroups();
						}}
					/>
					<div className={styles.groupActions}>
						<span className="theme-label">Group Actions</span>
						<div>
							<KiIconButton
								icon="play_arrow"
								className={styles.button}
								iconClassName={styles.icon}
								onClick={() => {
									setIsReportGroupExecutionModalActive(true);
								}}
								disabled={!reportDefinitions.length}
							/>
						</div>
					</div>
				</div>
				<ReportList
					reportDefinitions={populatedReportDefinitions}
					isLoading={isLoading}
					error={error}
					onDelete={setReportDefinitionIdToDelete}
					isAdmin={isAdmin}
					noDataMsg={
						populatedReportDefinitions.length === 0
							? 'Add reports using the plus button above'
							: 'No reports found'
					}
					onChangeReportDefinition={async () => {
						await fetchReportDefinitions();
					}}
				/>
			</div>
			<KiConfirmModal
				header="Delete Report"
				message={<span>Are you sure you want to delete the report?</span>}
				acceptFunc={async () => {
					await handleDeleteReportDefinition(reportDefinitionIdToDelete);
				}}
				rejectFunc={() => setReportDefinitionIdToDelete(null)}
				acceptLabel="Delete"
				rejectLabel="Cancel"
				active={!!reportDefinitionIdToDelete}
			/>
			{isReportGroupExecutionModalActive ? (
				<ReportExecutionModal
					reportGroup={reportGroups.find(reportGroup => reportGroup._id === activeReportGroupId)}
					onExecute={async () => {
						setIsReportGroupExecutionModalActive(false);
					}}
					onCancel={() => {
						setIsReportGroupExecutionModalActive(false);
					}}
					onChangeReportGroup={async () => {
						const rawReportGroups = await getReportGroups();
						setReportGroups(rawReportGroups);
					}}
				/>
			) : null}
		</div>
	);
};

export default ReportsContainer;
