import React, {Fragment, useEffect, useState} from 'react';
import AccountDetailTable from './AccountDetailTable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchAccountDetailsForLedger, fetchLedgerAccounts} from '../../../../api/ledgersApi';
import KiSelect from '../../../../components/KiSelect';
import {setAccountView} from '../../actions';
import fetchWithJob from '../../../../socket/fetchWithJob';
import KiInput from '../../../../components/KiInput';
import {filterNestedList, handleEnter} from '../../ledgerUtils';
import KiButton from '../../../../components/KiButton';
import Loader from '../../Loader';
import _ from 'lodash';

function LedgerAccountDetail({ledgerId, accountId, dateContext, startDate, endDate, setAccountView}) {
	const [account, setAccount] = useState(null);
	const [accountList, setAccountList] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredAccountList, setFilteredAccountList] = useState([]);
	const [isFiltered, setIsFiltered] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');

	//hey logical disconnect here, need to just pipe it over with what i need

	useEffect(
		() => {
			async function fetchData() {
				setLoading(true);
				const account = await fetchWithJob(() =>
					fetchAccountDetailsForLedger(ledgerId, accountId, startDate, endDate, dateContext.value)
				);
				setAccount(account);
				const accountList = await fetchWithJob(() => fetchLedgerAccounts(ledgerId));
				const accountsOptions = accountList.accounts.map(item => {
					return {label: item, value: item};
				});
				setAccountList(accountsOptions);
				setLoading(false);
			}
			if (accountId) {
				fetchData();
			}
		},
		[accountId, dateContext, startDate, endDate]
	);

	useEffect(() => {
		fetchWithJob(() => fetchLedgerAccounts(ledgerId)).then(data => {
			const {accounts} = data;
			if (!_.get(data, 'accounts')) {
				setLoading(false);
				setErrorMsg('No accounts found');
				return;
			}
			if (!accountId) {
				setAccountView(data.accounts[0]);
			}
			const accountsOptions = accounts.map(item => ({label: item, value: item}));
			setAccountList(accountsOptions);
		});
	}, []);

	const filterItems = () => {
		setIsFiltered(searchTerm !== '');

		if (account) {
			setFilteredAccountList(
				filterNestedList(searchTerm, account['postItems'], [
					'description',
					'postCode',
					{postings: ['lineItem']},
				])
			);
		}
	};

	useEffect(
		() => {
			if (!searchTerm) {
				filterItems();
			}
		},
		[searchTerm]
	);

	useEffect(
		() => {
			filterItems();
		},
		[account]
	);

	return (
		<Loader isActive={isLoading}>
			{account ? (
				<Fragment>
					<div className="horizontal-inputs-container">
						<div>
							<span className="theme-label">Account</span>
							<KiSelect
								options={accountList}
								onChange={({value}) => setAccountView(value)}
								value={{label: accountId, value: accountId}}
							/>
						</div>
						<div className="long-wrapper">
							<KiInput
								label="Search by Post Code, Description or Line Item"
								value={searchTerm}
								onChange={value => setSearchTerm(value)}
								onKeyDown={e => handleEnter(e, filterItems)}
								className="search-input"
								maxLength={100}
							/>
						</div>
						<div className="button-wrapper">
							<KiButton primary onClick={() => filterItems()}>
								Search
							</KiButton>
						</div>
					</div>
					{account.postItems.length > 0 ? (
						<AccountDetailTable
							balance={account.balance}
							filteredAccounts={filteredAccountList}
							isFiltered={isFiltered}
						/>
					) : (
						<div className="no-list-items-container">
							<div>No post items found</div>
						</div>
					)}
				</Fragment>
			) : (
				<div className="no-list-items-container">
					{errorMsg ? <div>{errorMsg}</div> : <div>No post items found</div>}
				</div>
			)}
		</Loader>
	);
}

LedgerAccountDetail.propTypes = {
	ledgerId: PropTypes.string.isRequired,
	accountId: PropTypes.string,
	dateContext: PropTypes.object.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	setAccountView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	accountId: state.ledgerExploration.accountId,
	dateContext: state.ledgerExploration.dateContext,
	startDate: state.ledgerExploration.startDate,
	endDate: state.ledgerExploration.endDate,
});

export default connect(mapStateToProps, {setAccountView})(LedgerAccountDetail);
