import ledgerSocket from '../socket/ledgerSocket';
import {fetchJobResults} from '../api/jobsApi';

const turnOffJobSockets = jobId => {
	ledgerSocket.off(`${jobId}.failed`);
	ledgerSocket.off(`${jobId}.completed`);
};

// Pass an API function that returns jobId to get results of a scheduled job
const fetchWithJob = triggerJobCall => {
	return new Promise((resolve, reject) => {
		triggerJobCall().then(jobId => {
			// Status change to failed
			ledgerSocket.on(
				`${jobId}.failed`,
				data => {
					turnOffJobSockets(jobId);
					// TODO handle failed job
					//eslint-disable-next-line no-console
					console.log(`failed`, data);
					reject(data);
				},
				'jobs'
			);

			// Status change to complete
			ledgerSocket.on(
				`${jobId}.completed`,
				() => {
					turnOffJobSockets(jobId);
					fetchJobResults(jobId).then(data => {
						resolve(data);
					});
				},
				'jobs'
			);

			ledgerSocket.emitIn('jobs', 'register', jobId);
		});
	});
};

export default fetchWithJob;
