import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiModal from 'components/KiModal';
import {closeModal, addAccount, updateAccount} from 'containers/accounts/actions';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import _without from 'lodash/without';
import _union from 'lodash/union';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _toPairs from 'lodash/toPairs';
import _reduce from 'lodash/reduce';
import _isEqual from 'lodash/isEqual';
import validators from 'ki-common/validators';
import './AccountModal.scss';
import {USER_GROUP_LIST} from '../../../../utils/userGroupsUtil';

const constraints = validators.accounts.getConstraints();
const passwordFormConstraints = {
	password: {
		presence: {allowEmpty: false},
		length: {
			minimum: 8,
			maximum: 50,
			message: 'must be between 8 to 50 characters',
		},
		format: {
			pattern: '^(?=[^0-9])(?=.*[a-z]+.*)(?=.*[A-Z]+.*)(?=.*[0-9]+.*)(.{7,}[^0-9])$',
			message: 'Requires 1 uppercase, 1 lowercase, and 1 number not as first or last character',
		},
	},
	confirmPassword: {
		presence: {allowEmpty: false},
		equality: {
			attribute: 'password',
			message: 'must match the new password',
		},
	},
};

export class AccountModal extends Component {
	static propTypes = {
		active: PropTypes.bool,
		account: PropTypes.object,
		closeModal: PropTypes.func.isRequired,
		addAccount: PropTypes.func.isRequired,
		updateAccount: PropTypes.func.isRequired,
	};

	state = {
		formValues: {
			userId: '',
			email: '',
			firstName: '',
			lastName: '',
			groups: [],
			apiKey: '',
		},
		formErrors: {},
		passwordValues: {
			password: '',
			confirmPassword: '',
		},
		passwordErrors: {},
	};

	componentDidMount() {
		this.setFormValues(this.props.account);
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(this.props.account, prevProps.account)) {
			this.setFormValues(this.props.account);
		}
	}

	setFormValues = formValues => {
		this.setState({
			formValues: {
				userId: _get(formValues, 'userId', ''),
				password: _get(formValues, 'password', ''),
				email: _get(formValues, 'email', ''),
				firstName: _get(formValues, 'firstName', ''),
				lastName: _get(formValues, 'lastName', ''),
				groups: _get(formValues, 'groups', []),
				apiKey: _get(formValues, 'apiKey', ''),
			},
			formErrors: {},
			passwordValues: {
				password: '',
				confirmPassword: '',
			},
			passwordErrors: {},
		});
	};

	validateField = (name, value) => {
		const error = validators.validateSingle(value, constraints[name]);
		this.setState({
			formErrors: {...this.state.formErrors, [name]: _get(error, '[0]', '')},
		});
	};

	valueChanged = (name, value) => {
		this.setState(
			{
				formValues: {...this.state.formValues, [name]: value},
			},
			() => {
				this.validateField(name, value);
			}
		);
	};

	// Must validate entire form rather than single element for password to password field comparisons to work
	passwordChanged = (name, value) => {
		const values = {...this.state.passwordValues, [name]: value};
		const errors = validators.validate(values, passwordFormConstraints);
		const passwordValues = {
			...this.state.passwordValues,
			[name]: value,
		};
		const passwordErrors = {
			...this.state.formErrors,
			[name]: _get(errors, `${name}[0]`, ''),
		};
		this.setState({
			passwordValues,
			passwordErrors,
		});
	};

	submit = e => {
		e.preventDefault();
		// Normal Fields
		const formValues = {...this.props.account, ...this.state.formValues};
		const errorObj = validators.validate(formValues, constraints);

		// Password
		const {passwordValues} = this.state;
		let passwordErrorObj = null;

		// new account OR field filled out THEN validate and map for update
		if (!this.props.account || passwordValues.password) {
			passwordErrorObj = validators.validate(passwordValues, passwordFormConstraints);
			formValues.password = passwordValues.password;
		}

		if (errorObj || passwordErrorObj) {
			const {formErrors} = this.state;
			_toPairs(errorObj).forEach(([key, value]) => {
				_set(formErrors, key, value[0]);
			});
			const passwordErrors = _reduce(
				passwordErrorObj,
				(result, value, key) => {
					result[key] = value[0]; //eslint-disable-line prefer-destructuring
					return result;
				},
				{}
			);
			this.setState({
				formErrors,
				passwordErrors,
			});
		} else if (this.props.account) {
			this.props.updateAccount(formValues).then(res => {
				if (res && res.validationError) {
					this.setState({formErrors: res.validationError});
				}
			});
		} else {
			this.props.addAccount(formValues).then(res => {
				if (res && res.validationError) {
					this.setState({formErrors: res.validationError});
				}
			});
		}
	};

	render() {
		const {active = false, account, closeModal} = this.props;
		const {groups} = this.state.formValues;
		return (
			<KiModal
				actions={[{label: 'Cancel', onClick: closeModal}, {label: 'Save', onClick: this.submit}]}
				active={active}
				onClose={closeModal}
				header={`${!account ? 'Create' : 'Modify'} Account`}
				confirmation={true}
				className="account-form"
			>
				<form>
					<section>
						<KiInput
							name="userId"
							label="User ID"
							type="text"
							value={this.state.formValues.userId}
							error={this.state.formErrors.userId}
							onChange={val => this.valueChanged('userId', val)}
							readOnly={this.props.account}
						/>
						<KiInput
							name="email"
							label="Email"
							type="text"
							value={this.state.formValues.email}
							error={this.state.formErrors.email}
							onChange={val => this.valueChanged('email', val)}
						/>
						<KiInput
							name="firstName"
							label="First Name"
							type="text"
							value={this.state.formValues.firstName}
							error={this.state.formErrors.firstName}
							onChange={val => this.valueChanged('firstName', val)}
						/>
						<KiInput
							name="lastName"
							label="Last Name"
							type="text"
							value={this.state.formValues.lastName}
							error={this.state.formErrors.lastName}
							onChange={val => this.valueChanged('lastName', val)}
						/>
					</section>
					<section>
						<p className="form-label">Groups:</p>
						<div style={{color: '#de3226'}}>{this.state.formErrors.groups}</div>
						<div className="user-groups">
							{USER_GROUP_LIST.map((group, index) => (
								<KiCheckbox
									key={index}
									checked={groups.includes(group.name)}
									label={group.label}
									onChange={val =>
										this.valueChanged(
											'groups',
											val ? _union(groups, [group.name]) : _without(groups, group.name)
										)
									}
								/>
							))}
						</div>
					</section>
					<section className="password-inputs">
						<KiInput
							name="password"
							label="Password"
							type="password"
							value={this.state.passwordValues.password}
							error={this.state.passwordErrors.password}
							onChange={val => this.passwordChanged('password', val)}
						/>
						<KiInput
							name="confirmPassword"
							label="Confirm Password"
							type="password"
							value={this.state.passwordValues.confirmPassword}
							error={this.state.passwordErrors.confirmPassword}
							onChange={val => this.passwordChanged('confirmPassword', val)}
						/>
					</section>
					{this.state.formValues.apiKey && (
						<section>
							<h4>API Key</h4>
							<KiInput
								readOnly
								name="apiKey"
								type="text"
								className="api-key-input"
								value={this.state.formValues.apiKey}
							/>
						</section>
					)}
				</form>
			</KiModal>
		);
	}
}

const mapStateToProps = state => ({
	active: state.accounts.accountModalActive,
	account: state.accounts.selected,
});

export default connect(mapStateToProps, {closeModal, addAccount, updateAccount})(AccountModal);
