import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiModal from 'components/KiModal';
import KiCheckbox from 'components/KiCheckbox';
import {deleteFundingVehicleSettings, updateFundingVehicleSetting} from '../actions';
import {connect} from 'react-redux';
import styles from './debtDeleteModal.theme.scss';
import _differenceBy from 'lodash/differenceBy';
import _countBy from 'lodash/countBy';
import {fundingVehiclesApi} from 'api';
import DeletionDependencyErrors from './deletionDependencyErrors';

export class DebtDeleteModal extends Component {
	static propTypes = {
		active: PropTypes.bool,
		debts: PropTypes.array,
		deleteFundingVehicleSettings: PropTypes.func.isRequired,
		updateFundingVehicleSetting: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		title: PropTypes.string,
		nameKey: PropTypes.string,
	};

	static defaultProps = {
		active: false,
		debts: [],
	};

	state = {
		selected: [],
		dependencies: [],
	};

	handleCheckboxClick = debt => {
		this.setState(({selected}) => {
			const isPresent = selected.find(s => s._id === debt._id);
			return {
				selected: isPresent ? selected.filter(s => s._id !== debt._id) : [...selected, debt],
			};
		});
	};

	handleDelete = () => {
		fundingVehiclesApi
			.getFundingVehicleSettingsDependencies('debt', {
				datasetId: this.state.selected[0].datasetId,
				fundingVehicleId: this.state.selected[0].fundingVehicleId,
				entityIds: this.state.selected.map(e => e._id),
			})
			.then(results => {
				if (
					results.dependencies.filter(d => Array.isArray(d.dependencies) && d.dependencies.length > 0)
						.length > 0
				) {
					this.setState({
						dependencies: results.dependencies.filter(
							d => Array.isArray(d.dependencies) && d.dependencies.length > 0
						),
					});
				} else {
					const nonDeletedDebts = _differenceBy(this.props.debts, this.state.selected, '_id');
					const classIdCount = _countBy(nonDeletedDebts, 'classId');

					for (const [key] of Object.entries(classIdCount)) {
						classIdCount[key] = 0;
					}
					nonDeletedDebts.forEach(debt => {
						classIdCount[debt.classId]++;
						debt.trancheNumber = classIdCount[debt.classId];
						debt.nameId = `T_${debt.classId}_${classIdCount[debt.classId]}`;
						// TODO add ability to update many settings at once
						this.props.updateFundingVehicleSetting({_id: debt._id, ...debt}, false, true);
					});
					this.props.deleteFundingVehicleSettings(this.state.selected).then(() => this.onCancel());
				}
			});
	};

	handleCheckAll = () => {
		this.setState({
			selected: this.state.selected.length === this.props.debts.length ? [] : this.props.debts,
		});
	};

	onCancel = () => {
		this.setState({dependencies: []});
		this.props.onClose();
	};

	render() {
		const {debts} = this.props;

		return (
			<KiModal
				actions={[
					{
						label: 'Cancel',
						onClick: () => this.onCancel(),
					},
					{
						label: 'Delete',
						onClick: this.handleDelete,
						disabled: !this.state.selected.length,
					},
				]}
				active={!!this.props.debts.length && this.props.active}
				header={this.props.title || 'Delete Tranches'}
				className="ki-modal fv-modal"
			>
				<section className={styles.body}>
					{debts.map(debt => (
						<div key={debt._id} className={styles.checkboxWrapper}>
							<KiCheckbox
								className={styles.checkbox}
								label={debt[this.props.nameKey || 'name']}
								checked={!!this.state.selected.find(selected => selected._id === debt._id)}
								onChange={() => this.handleCheckboxClick(debt)}
							/>
						</div>
					))}
				</section>
				{this.state.dependencies.length > 0 && (
					<DeletionDependencyErrors dependencies={this.state.dependencies} />
				)}
			</KiModal>
		);
	}
}

export default connect(null, {deleteFundingVehicleSettings, updateFundingVehicleSetting})(DebtDeleteModal);
