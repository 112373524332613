// Dependencies
import React, {Component} from 'react';

class TestStepOne extends Component {
	render() {
		return <div className="wrapper">This is step one</div>;
	}
}

export default TestStepOne;
