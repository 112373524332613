import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

const DebtBreadCrumb = ({options, type, value, onClick}) => {
	if (!options || !value || !type) {
		return null;
	}
	let label;
	switch (type) {
		case 'viewType': {
			label = _get(options.find(col => col.value === value), 'label', 'cohort');
			break;
		}
		case 'fundingVehicle': {
			label = _get(options.find(({_id}) => _id === value), 'name', '');
			break;
		}
		default:
			label = '';
	}
	return <a onClick={() => (onClick ? onClick() : null)}>{label}</a>;
};
DebtBreadCrumb.propTypes = {
	type: PropTypes.oneOf(['viewType', 'fundingVehicle']).isRequired,
	options: PropTypes.array.isRequired,
	value: PropTypes.string,
	onClick: PropTypes.func,
};

export default DebtBreadCrumb;
