export const GLOBAL_NAV_SHOW = 'GLOBAL_NAV_SHOW';
export const GLOBAL_NAV_HIDE = 'GLOBAL_NAV_HIDE';

export const CONTEXT_SIDEBAR_SHOW = 'CONTEXT_SIDEBAR_SHOW';
export const CONTEXT_SIDEBAR_HIDE = 'CONTEXT_SIDEBAR_HIDE';
export const CONTEXT_SIDEBAR_OPEN_PANEL = 'CONTEXT_SIDEBAR_OPEN_PANEL';
export const CONTEXT_SIDEBAR_CLOSE_PANEL = 'CONTEXT_SIDEBAR_CLOSE_PANEL';

export const CARD_EDITOR_SHOW = 'CARD_EDITOR_SHOW';
export const CARD_EDITOR_HIDE = 'CARD_EDITOR_HIDE';

export function showGlobalNav() {
	return {type: GLOBAL_NAV_SHOW};
}

export function hideGlobalNav() {
	return {type: GLOBAL_NAV_HIDE};
}

const showContextSidebar = () => dispatch => {
	dispatch({type: CONTEXT_SIDEBAR_SHOW});
};

const hideContextSidebar = () => dispatch => {
	dispatch({type: CONTEXT_SIDEBAR_HIDE});
};

const openContextSidebarPanel = (panelName, panelConfig) => dispatch => {
	dispatch({type: CONTEXT_SIDEBAR_OPEN_PANEL, panelName, panelConfig});
};

const closeContextSidebarPanel = () => dispatch => {
	dispatch({type: CONTEXT_SIDEBAR_CLOSE_PANEL});
};

const showCardEditor = () => dispatch => {
	dispatch({type: CARD_EDITOR_SHOW});
};

const hideCardEditor = () => dispatch => {
	dispatch({type: CARD_EDITOR_HIDE});
};

export {
	showContextSidebar,
	hideContextSidebar,
	openContextSidebarPanel,
	closeContextSidebarPanel,
	showCardEditor,
	hideCardEditor,
};
