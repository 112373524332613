// Globals
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import validators from 'ki-common/validators';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import _pick from 'lodash/pick';
import _set from 'lodash/set';
import _isEqual from 'lodash/isEqual';
import _toPairs from 'lodash/toPairs';
import _uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';

// Project imports
import KiInput from 'components/KiInput';
import KiButton from 'components/KiButton';
import KiDatePicker from 'components/KiDatePicker';
import KiCheckbox from 'components/KiCheckbox';
import GroupBySelector from 'components/GroupBySelector';
import {checkUniqueNamePerDatasetId, checkUniqueDealIdPerDatasetId} from 'api/fundingVehiclesApi';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {datasetDatesApi} from 'api';

// Local imports
import styles from './form.theme.scss';

const CURRENCY_OPTIONS = [
	{
		label: 'USD',
		value: 'USD',
	},
	{
		label: 'GBP',
		value: 'GBP',
	},
	{
		label: 'Euro',
		value: 'EUR',
	},
	{
		label: 'AUD',
		value: 'AUD',
	},
];

const RETENTION_OPTIONS = [
	{
		label: 'No',
		value: 'no',
	},
	{
		label: 'Yes - Unknown Type',
		value: 'yesUnknownType',
	},
	{
		label: 'Yes - Residual',
		value: 'yesResidual',
	},
	{
		label: 'Yes - Vertical Slice',
		value: 'yesVerticalSlice',
	},
];

const DESIGNATION_OPTIONS = [
	{
		label: '144-A',
		value: '144A',
	},
	{
		label: 'Public',
		value: 'public',
	},
	{
		label: 'Private',
		value: 'private',
	},
];

const constraints = validators.fundingVehicles.fundingVehicle.getConstraints();
export class FundingVehicleForm extends Component {
	static propTypes = {
		fundingVehicle: PropTypes.object,
		updateMethod: PropTypes.func,
		cohortColumns: PropTypes.array.isRequired,
		assetCohortColumnId: PropTypes.string,
		onGroupByChanged: PropTypes.func,
		allFundingVehicles: PropTypes.array,
		fetchFundingVehicleList: PropTypes.func,
		fundingVehiclePools: PropTypes.array,
		showErrorMsg: PropTypes.func,
	};

	constructor(props) {
		super(props);
		if (!props.allFundingVehicles.length) {
			this.props.fetchFundingVehicleList();
		}
	}

	state = {
		formValues: {
			name: this.props.fundingVehicle.name || '',
			trustName: this.props.fundingVehicle.trustName || '',
			legalName: this.props.fundingVehicle.legalName || '',
			warehouseLimitMin: this.props.fundingVehicle.warehouseLimitMin || '',
			warehouseLimit: this.props.fundingVehicle.warehouseLimit || '',
			amountBorrowed: this.props.fundingVehicle.amountBorrowed || '',
			groupBy: this.props.fundingVehicle.groupBy || '',
			id: this.props.fundingVehicle.id || '',
			dealId: this.props.fundingVehicle.dealId ? this.props.fundingVehicle.dealId : this.props.fundingVehicle._id,
			currency: this.props.fundingVehicle.currency || '',
			minRetentionReq: this.props.fundingVehicle.minRetentionReq || '',
			retentionType: this.props.fundingVehicle.retentionType || '',
			designation: this.props.fundingVehicle.designation || '',
			startDate: this.props.fundingVehicle.startDate || null,
			endDate: this.props.fundingVehicle.endDate || null,
			fvCutoffDate: this.props.fundingVehicle.fvCutoffDate || null,
			isActive: this.props.fundingVehicle.isActive !== 'undefined' ? this.props.fundingVehicle.isActive : true,
			isBlended: this.props.fundingVehicle.isBlended || false,
		},
		formErrors: {},
		submitDisabled: false,
		isDisabled: this.props.fundingVehicle.isUnencumbered || false,
		currentMappings: [],
	};

	componentDidMount() {
		datasetDatesApi.fetchMappedDates(this.props.fundingVehicle.datasetId).then(result => {
			this.setState({currentMappings: result});
		});
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			this.setFormValues(this.props.fundingVehicle);
		}
	}

	setFormValues = formValues => {
		this.setState({
			formValues: {
				name: formValues.name || '',
				trustName: formValues.trustName || '',
				legalName: formValues.legalName || '',
				warehouseLimitMin: formValues.warehouseLimitMin || '',
				warehouseLimit: formValues.warehouseLimit || '',
				amountBorrowed: formValues.amountBorrowed || '',
				groupBy: formValues.groupBy || '',
				id: formValues.id || '',
				dealId: formValues.dealId || '',
				currency: formValues.currency || '',
				minRetentionReq: formValues.minRetentionReq || '',
				retentionType: formValues.retentionType || '',
				designation: formValues.designation || '',
				startDate: formValues.startDate || null,
				endDate: formValues.endDate || null,
				fvCutoffDate: formValues.fvCutoffDate || null,
				isActive:
					this.props.fundingVehicle.isActive !== 'undefined' ? this.props.fundingVehicle.isActive : true,
				isBlended: formValues.isBlended || false,
			},
			formErrors: {},
			submitDisabled: false,
			isDisabled: this.props.fundingVehicle.isUnencumbered || false,
		});
	};

	validateField = (name, value) => {
		const error = validators.validateSingle(value, constraints[name]);
		this.setState({
			formErrors: {
				...this.state.formErrors,
				[name]: (error && error[0]) || '',
			},
			submitDisabled: !!error,
		});
	};

	handleBlendedCheckbox = (name, value) => {
		const hasDeterminationDate = () => {
			if (this.state.currentMappings.length && this.state.currentMappings.some(obj => obj.type === 'date')) {
				const determinationDate = this.state.currentMappings.find(obj => obj.name === 'determination');
				if (determinationDate) {
					return determinationDate.defaultValue !== null;
				}
			}
			return false;
		};

		const hasDistributionDate = () => {
			if (this.state.currentMappings.length && this.state.currentMappings.some(obj => obj.type === 'date')) {
				const distributionDate = this.state.currentMappings.find(obj => obj.name === 'distribution');
				if (distributionDate) {
					return distributionDate.defaultValue !== null;
				}
			}
			return false;
		};

		if (hasDeterminationDate() && hasDistributionDate()) {
			this.valueChanged(name, value);
		} else {
			this.props.showErrorMsg('Can not apply Blending to Funding Vehicle without required date mappings');
		}
	};

	valueChanged = (name, value) => {
		if (name === 'groupBy') {
			this.props.onGroupByChanged(value);
		}
		return this.setState(
			{
				formValues: {
					...this.state.formValues,
					[name]: value,
				},
			},
			() => {
				this.validateField(name, value);
			}
		);
	};

	validateSubset = subsetName => {
		const error = validators.validatePatch(_pick(this.state.formValues, subsetName), constraints);

		// This will force reset an entire subset after it is validated
		const {formErrors} = this.state;
		formErrors[subsetName] = {};
		_toPairs(error).forEach(([key, value]) => {
			_set(formErrors, key, value[0]);
		});
		this.setState({
			formErrors: formErrors,
			submitDisabled: !!error,
		});
	};

	subsetChanged = (subsetName, name, value) => {
		const subset = this.state.formValues[subsetName];
		subset[name] = value;
		this.setState(
			{
				formValues: {...this.state.formValues, [subsetName]: subset},
			},
			() => {
				this.validateSubset(subsetName);
			}
		);
	};

	trustNameChanged = value => {
		this.setState({
			formValues: {
				...this.state.formValues,
				trustName: value,
			},
		});
	};

	resetForm = () => this.setFormValues(this.props.fundingVehicle);

	submit = e => {
		e.preventDefault();
		const formValues = {...this.props.fundingVehicle, ...this.state.formValues};
		const error = validators.validate(formValues, constraints);
		if (error) {
			const {formErrors} = this.state;
			_toPairs(error).forEach(([key, value]) => {
				_set(formErrors, key, value[0]);
			});
			this.setState({
				formErrors: formErrors,
				submitDisabled: true,
			});
		} else if (this.props.fundingVehicle.name !== this.state.formValues.name) {
			checkUniqueNamePerDatasetId(this.props.fundingVehicle.datasetId, this.state.formValues.name)
				.then(() => {
					this.setState({formErrors: {...this.state.formErrors, name: ''}});
					this.props.updateMethod(formValues);
				})
				.catch(() => {
					this.setState({
						formErrors: {
							...this.state.formErrors,
							name: 'Name already in use',
						},
					});
				});
		} else {
			// Checks if DealId is unique before saving
			checkUniqueDealIdPerDatasetId(
				this.props.fundingVehicle.datasetId,
				this.state.formValues.dealId,
				this.props.fundingVehicle._id
			)
				.then(() => {
					this.setState({formErrors: {...this.state.formErrors, dealId: ''}});
					this.props.updateMethod(formValues);
				})
				.catch(response => {
					if (response.status === 409) {
						this.setState({
							formErrors: {
								...this.state.formErrors,
								dealId: 'DealId Is Already In Use',
							},
						});
					} else {
						this.setState({
							formErrors: {
								...this.state.formErrors,
								dealId: 'Error has occurred while checking deal ID uniqueness',
							},
						});
					}
				});
		}
	};

	render() {
		if (!this.props.fundingVehicle) {
			return null;
		}
		return (
			<form onSubmit={this.submit} className={styles.root}>
				<div className={styles.row}>
					<div className={styles.fundingVehicleDetails}>
						<KiInput
							type="text"
							name="name"
							label="Funding Vehicle Name"
							disabled={this.props.fundingVehicle.isUnencumbered}
							error={this.state.formErrors.name}
							value={this.state.formValues.name}
							onChange={val => this.valueChanged('name', val)}
							maxLength={100}
						/>
						<KiInput
							type="text"
							name="legalName"
							label="Legal Name"
							disabled={this.props.fundingVehicle.isUnencumbered}
							error={this.state.formErrors.legalName}
							value={this.state.formValues.legalName}
							onChange={val => this.valueChanged('legalName', val)}
							maxLength={100}
						/>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>*Trust Name</div>
							<CreatableSelect
								className="cc-target-dropdown double"
								isMulti={false}
								placeholder="Trust Name"
								isClearable={true}
								noResultsText={false}
								options={_uniqBy(
									this.props.allFundingVehicles.filter(fv => !!fv.trustName).map(fv => ({
										value: fv.trustName,
										label: fv.trustName,
									})),
									'value'
								)}
								value={
									this.state.formValues.trustName
										? {
												value: this.state.formValues.trustName,
												label: this.state.formValues.trustName,
										  }
										: null
								}
								onChange={value => this.trustNameChanged(_get(value, 'label', ''))}
								isDisabled={this.props.fundingVehicle.isUnencumbered}
							/>
						</div>
						<div className={styles.staticDateSelectWrapper}>
							<label className={styles.themeLabel}>*Start Date</label>
							<KiDatePicker
								minDate={new Date('1899-12-31')}
								maxDate={new Date('2099-12-31')}
								className={styles.startDatePicker}
								onChange={val => this.valueChanged('startDate', dateToShortDate(val))}
								value={this.state.formValues.startDate}
								popperClassName={styles.datePickerPopper}
								disabled={this.props.fundingVehicle.isUnencumbered}
							/>
							<label className={styles.themeLabel}>*End Date</label>
							<KiDatePicker
								disabled={!this.state.formValues.startDate || this.props.fundingVehicle.isUnencumbered}
								minDate={new Date(this.state.formValues.startDate)}
								maxDate={new Date('2099-12-31')}
								className={styles.startDatePicker}
								onChange={val => this.valueChanged('endDate', dateToShortDate(val))}
								value={this.state.formValues.endDate}
								popperClassName={styles.datePickerPopper}
							/>
							<label className={styles.themeLabel}>Cutoff Date</label>
							<KiDatePicker
								disabled={!this.state.formValues.startDate || this.props.fundingVehicle.isUnencumbered}
								minDate={new Date('1899-12-31')}
								maxDate={new Date('2099-12-31')}
								className={styles.startDatePicker}
								onChange={val => this.valueChanged('fvCutoffDate', dateToShortDate(val))}
								value={this.state.formValues.fvCutoffDate}
								popperClassName={styles.datePickerPopper}
							/>
						</div>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>Dataset</div>
							<Select
								classNamePrefix="aut-select"
								value={[
									{
										label: this.props.fundingVehicle.datasetName,
										value: this.props.fundingVehicle.datasetId,
									},
								]}
								isDisabled={true}
								isClearable={false}
								options={[
									{
										label: this.props.fundingVehicle.datasetName,
										value: this.props.fundingVehicle.datasetId,
									},
								]}
							/>
						</div>
						<KiInput
							label="Actual Amount Borrowed"
							type="string"
							className={styles.wideInput}
							value={this.state.formValues.amountBorrowed}
							onChange={val => this.valueChanged('amountBorrowed', val)}
							error={this.state.formErrors.amountBorrowed}
							isNumberMasked={true}
							disabled={this.props.fundingVehicle.isUnencumbered}
						/>
						<KiInput
							className={styles.wideInput}
							label="Minimum Facility Limit"
							type="string"
							value={this.state.formValues.warehouseLimitMin}
							onChange={val => this.valueChanged('warehouseLimitMin', val || '')}
							error={this.state.formErrors.warehouseLimitMin}
							isNumberMasked={true}
							disabled={this.props.fundingVehicle.isUnencumbered}
						/>
						<KiInput
							className={styles.wideInput}
							label="Maximum Facility Limit"
							type="string"
							value={this.state.formValues.warehouseLimit || ''}
							onChange={val => this.valueChanged('warehouseLimit', val || '')}
							error={this.state.formErrors.warehouseLimit}
							isNumberMasked={true}
							disabled={this.props.fundingVehicle.isUnencumbered}
						/>
						<label className={styles.themeLabel}>
							* Trust Name, Currency, Start Date, and End Date are required to use waterfall module.
						</label>
					</div>
					<div className={styles.funding}>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>Pools</div>
							<div>{this.props.fundingVehiclePools.map(pool => pool.name).join(', ')}</div>
						</div>
						<KiInput
							label={'ID'}
							type="string"
							value={this.state.formValues.id || ''}
							onChange={val => this.valueChanged('id', val || '')}
							error={this.state.formErrors.id}
							disabled={this.props.fundingVehicle.isUnencumbered}
						/>
						<KiInput
							label={'Deal ID'}
							type="string"
							value={this.state.formValues.dealId}
							onChange={val => this.valueChanged('dealId', val || '')}
							error={this.state.formErrors.dealId}
							disabled={this.props.fundingVehicle.isUnencumbered}
						/>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>*Currency</div>
							<Select
								classNamePrefix="aut-select"
								value={CURRENCY_OPTIONS.filter(
									option => option.value === this.state.formValues.currency
								)}
								isClearable={true}
								options={CURRENCY_OPTIONS}
								onChange={option => this.valueChanged('currency', (option && option.value) || '')}
								isDisabled={this.props.fundingVehicle.isUnencumbered}
							/>
						</div>
						<KiInput
							label={'Min. Retention Requirement'}
							type="string"
							value={this.state.formValues.minRetentionReq || ''}
							onChange={val => this.valueChanged('minRetentionReq', val || '')}
							error={this.state.formErrors.minRetentionReq}
							isNumberMasked={true}
							disabled={this.props.fundingVehicle.isUnencumbered}
						/>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>Retention Type</div>
							<Select
								classNamePrefix="aut-select"
								value={RETENTION_OPTIONS.filter(
									option => option.value === this.state.formValues.retentionType
								)}
								isClearable={false}
								options={RETENTION_OPTIONS}
								onChange={option => this.valueChanged('retentionType', (option && option.value) || '')}
								isDisabled={this.props.fundingVehicle.isUnencumbered}
							/>
						</div>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>Designation</div>
							<Select
								classNamePrefix="aut-select"
								value={DESIGNATION_OPTIONS.filter(
									option => option.value === this.state.formValues.designation
								)}
								isClearable={false}
								options={DESIGNATION_OPTIONS}
								onChange={option => this.valueChanged('designation', (option && option.value) || '')}
								isDisabled={this.props.fundingVehicle.isUnencumbered}
							/>
						</div>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>Group By</div>
							<GroupBySelector
								selectedColumn={this.state.formValues.groupBy}
								columns={this.props.cohortColumns.filter(col => col.displayName !== 'Encumbrance Pool')}
								targetFunction={val => this.valueChanged('groupBy', val === null ? val : val._id)}
								assetCohortColumnId={this.props.assetCohortColumnId}
								isDisabled={this.props.fundingVehicle.isUnencumbered}
							/>
						</div>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>Active</div>
							<KiCheckbox
								className={styles.checkboxRow}
								style={{border: '1px solid #CCC'}}
								label="Set Funding Vehicle as Active for calculating Funding Analysis Summary"
								onChange={val => this.valueChanged('isActive', val)}
								checked={this.state.formValues.isActive}
								disabled={this.state.isDisabled}
							/>
						</div>
						<div className={styles.selectWrapper}>
							<div className={styles.themeLabel}>Blended</div>
							<KiCheckbox
								className={styles.checkboxRow}
								style={{border: '1px solid #CCC'}}
								label="Set Funding Vehicle as Blended for calculating Funding Analysis Summary"
								onChange={val => this.handleBlendedCheckbox('isBlended', val)}
								checked={this.state.formValues.isBlended}
								disabled={this.state.isDisabled}
							/>
						</div>
					</div>
				</div>
				<div className={styles.actionRow}>
					<KiButton flat primary onClick={this.resetForm} label="Cancel" />
					<KiButton
						type="submit"
						disabled={this.state.submitDisabled || this.props.fundingVehicle.isUnencumbered}
						label="Save"
						raised
						primary
					/>
				</div>
			</form>
		);
	}
}

const mapStateToProps = state => ({
	allFundingVehicles: state.fundingVehicleList.data,
});

export default connect(
	mapStateToProps,
	{fetchFundingVehicleList}
)(FundingVehicleForm);
