const _get = require('lodash/get');
const dateStringRegex = require('./dateHelpers').dateStringRegex;
const dateTestRe = new RegExp(dateStringRegex);

const calcOptions = [
	{label: 'Avg', value: 'AVG'},
	{label: 'Sum', value: 'SUM'},
	{label: 'Sum %', value: 'SUM_PCT'},
	{label: 'Sum %', value: 'SUM_PCT_TOTAL'},
	{label: 'Count', value: 'COUNT'},
	{label: 'Count %', value: 'COUNT_PCT'},
	{label: 'Wtd Avg', value: 'WGHTAVG'},
	{label: 'Min', value: 'MIN'},
	{label: 'Max', value: 'MAX'},
	{label: 'Ratio', value: 'RATIO'},
	{label: 'First Valid', value: 'FIRST_VALID'},
];

const buildTitle = (displayName, calculation) => {
	let title = displayName;
	if (calculation) {
		const calcTitle = calcOptions.find(opt => opt.value === calculation);
		title = `${title} ${calcTitle.label || calculation}`;
	}
	return title;
};

const buildCalculation = calculation => {
	let title = '';
	if (calculation) {
		const calcTitle = calcOptions.find(opt => opt.value === calculation);
		title = `${title} ${calcTitle.label || calculation}`;
	}
	return title;
};

// assetSnapshot = Start Of Day, lastCommitted = Pending, hypo = Hypo, lastApproved = End Of Day
const determineScenarioType = scenarioId => {
	if (scenarioId === 'assetSnapshot' || scenarioId === 'lastApproved') {
		return scenarioId;
	}
	if (dateTestRe.test(scenarioId)) {
		return 'lastCommitted';
	}
	return 'hypo';
};

const getDefaultColumnIds = (snapshots, columns, snapshotDate) => {
	const currentSnapshot = snapshots.find(s => s.snapshotDate === snapshotDate) || snapshots[snapshots.length - 1];
	const assetNumberMap = currentSnapshot.assetNumberMap;
	const assetColumn = columns.find(c => c.columnName === assetNumberMap);
	const assetCohortColumn = columns.find(
		c => c.assetColumnId === _get(assetColumn, '_id', 'No assetColumnId found') && c.columnType === 'cohort'
	);
	const balanceMap = currentSnapshot.balanceMap;
	const balanceAssetColumn = columns.find(c => c.columnName === balanceMap) || {};
	const balanceAggColumn = columns.find(
		c => c.columnType === 'aggregate' && c.assetColumnId === balanceAssetColumn._id && c.calculation === 'SUM'
	);
	const balanceCohortColumn = columns.find(
		c => c.columnType === 'cohort' && c.assetColumnId === balanceAssetColumn._id
	);
	// Not sure why the below columns aren't being found for the new unencumberedFv..
	return {
		assetColumnId: _get(assetColumn, '_id', 'No assetColumnId found'),
		assetCohortColumnId: _get(assetCohortColumn, '_id', 'No assetCohortColumnId found'),
		balanceAssetColumnId: _get(balanceAssetColumn, '_id', 'No balanceAssetColumnId found'),
		balanceCohortColumnId: _get(balanceCohortColumn, '_id', 'No balanceCohortColumnId found'),
		balanceAggregateColumnId: _get(balanceAggColumn, '_id', 'No balanceAggColumnId found'),
	};
};

module.exports = {
	calcOptions,
	buildTitle,
	buildCalculation,
	determineScenarioType,
	getDefaultColumnIds,
};
