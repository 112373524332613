import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from '../VectorForm.theme.scss';
import KiInput from '../../../../../components/KiInput';
import KiButton from '../../../../../components/KiButton';
import {useMergedState} from '../../../../../utils/customHooks';

const ManualPeriodForm = ({onPeriodsAdd, onCancel, currentPeriods, validateValue}) => {
	const [formData, mergeFormData] = useMergedState({
		month: '',
		value: '',
	});
	const [validationErrors, mergeValidationErrors] = useMergedState({
		month: '',
		value: '',
	});

	const onMonthChange = month => {
		mergeFormData({month});
		mergeValidationErrors({
			month: month
				? currentPeriods && currentPeriods.length && currentPeriods.find(item => item.month === month)
					? 'Month added already'
					: null
				: 'Please specify month',
		});
	};

	const onValueChange = value => {
		mergeFormData({value});
		mergeValidationErrors({value: validateValue(value)});
	};

	const onPeriodAdd = () => {
		onPeriodsAdd([formData]);
		mergeFormData({month: '', value: ''});
		mergeValidationErrors({month: '', value: ''});
	};

	const isValid = validationErrors.month === null && validationErrors.value === null;

	return (
		<>
			<div className={classNames(styles.period, styles.newPeriod)}>
				<KiInput
					className="substring-size-input"
					onChange={onMonthChange}
					placeholder="Month"
					autoComplete="off"
					isNumberMasked={true}
					maskConfig={{allowNegative: false, allowDecimal: false, decimalLimit: 0}}
					value={formData.month}
					error={validationErrors.month}
				/>
				<KiInput
					className="substring-size-input"
					onChange={onValueChange}
					placeholder="Value"
					autoComplete="off"
					isNumberMasked={true}
					maskConfig={{allowNegative: false}}
					value={formData.value}
					error={validationErrors.value}
				/>
			</div>
			<div className="inline-column-form-buttons">
				<KiButton flat mini onClick={onCancel}>
					Cancel
				</KiButton>
				<KiButton primary mini onClick={onPeriodAdd} disabled={!isValid}>
					Add
				</KiButton>
			</div>
		</>
	);
};

ManualPeriodForm.propTypes = {
	onPeriodsAdd: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	currentPeriods: PropTypes.array.isRequired,
	validateValue: PropTypes.func.isRequired,
};

export default ManualPeriodForm;
