import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import FlyoutConfirmPanel from '../FlyoutConfirmPanel';
import {fetchViewsByDataset, deleteBookmark} from './actions';
import Select from 'react-select';
import styles from './FlyoutManageViews.theme.scss';
import _uniqueId from 'lodash/uniqueId';
import ManageViewForms from './components/ViewForms';
import _ from 'lodash';
import {USER_GROUPS} from '../../utils/userGroupsUtil';
import ViewListItem from './components/ViewListItem';
import {fetchAccounts} from '../../containers/accounts/actions';
import {useMergedState} from '../../utils/customHooks';
import ManageExplorationViewForms from '../../containers/dataExplorer/components/ManageExplorationViewForms';

const customViewSort = userId => {
	return (a, b) => {
		// eslint-disable-line
		// If is default then it is greatest
		if (a.isDefault && !b.isDefault) {
			return -1; // a is first
		}
		if (!a.isDefault && b.isDefault) {
			return 1; // b is first
		}

		// Favorites are ranked over non favorites
		if (a.isFavorite && !b.isFavorite) {
			return -1; // a is first
		}

		if (!a.isFavorite && b.isFavorite) {
			return 1; // b is first
		}

		// User bookmarks before global bookmarks
		const aIsUser = a.createdBy === userId ? true : false;
		const bIsUser = b.createdBy === userId ? true : false;
		if (aIsUser && !bIsUser) {
			return -1; // a is first
		}

		if (!aIsUser && bIsUser) {
			return 1; // b is first
		}

		if (a.isFavorite && b.isFavorite) {
			// Favorites are ordered by chronological order, most recent at top
			const valueOne = new Date(a.createDate);
			const valueTwo = new Date(b.createDate);
			if (valueOne === valueTwo) {
				return 0;
			}
			return valueOne < valueTwo ? -1 : 1;
		} else {
			// Alphabetical order
			const valueOne = a.name.toLowerCase();
			const valueTwo = b.name.toLowerCase();
			if (valueOne === valueTwo) {
				return 0;
			}
			return valueOne < valueTwo ? -1 : 1;
		}
	};
};

const FlyoutManageViews = ({
	currentViewId,
	currentView,
	explorerView,
	reloadView,
	applyView,
	setViewHasChanges,
	isDataExploration = false,
}) => {
	const dispatch = useDispatch();

	const user = useSelector(state => state.user);
	const userList = useSelector(state => state.accounts.data);
	const bookmarkList = useSelector(state => state.dataBookmarks.userBookmarks);
	const datasetId = useSelector(state => state.datasetList.selected.datasetId);
	const dataset = useSelector(state => state.datasetList.selected);
	const contextSidebarPanelConfig = useSelector(state => state.app.contextSidebarPanelConfig);

	const [panelState, mergePanelState] = useMergedState({
		panelMode: null,
		selectedBookmark: null,
		showConfirmDelete: false,
		noDeleteReasons: null,
		isCopy: false,
	});
	const {panelMode, selectedBookmark, showConfirmDelete, noDeleteReasons, isCopy} = panelState;

	const isAdmin = user.groups.includes(USER_GROUPS.SYSTEM_ADMINS);

	const [selectedUserId, setSelectedUserId] = useState(isAdmin ? 'global' : user.userId);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchedBookmarks, setSearchedBookmarks] = useState([]);

	const handleEditBookmarkClick = (bookmark, explorerView) => {
		// a bit of history is in order here.... at one point you could edit any view in the
		// side tray, however currently they only want you to navigate to the view and then have
		// the explorer and side tray perfectly synced... so we are using dataExplorer redux instead
		// of the default saved bookmark settings
		const explorerData = Object.assign({}, explorerView);
		delete explorerData.data;
		mergePanelState({
			panelMode: 'edit',
			selectedBookmark: Object.assign({}, bookmark, {explorerData: explorerData}),
			isCopy: false,
		});
	};

	useEffect(() => {
		// TODO perform a simple request instead of redux
		dispatch(fetchViewsByDataset(user.userId, datasetId, user.groups.includes('SystemAdmins')));
		if (!userList.length && isAdmin) {
			dispatch(fetchAccounts());
		}
	}, []);

	const hydrateViewColumns = view => {
		// we need additional metadata on cols to display ux, stripped back out on save to mongo
		view.explorerData.columns = view.explorerData.columns.map(viewCol => {
			const match = dataset.columns.find(col => col._id === viewCol.columnId);
			return Object.assign({}, match, {displayName: viewCol.displayName}, {displayFormat: viewCol.displayFormat});
		});
		return view;
	};

	useEffect(
		() => {
			setSearchedBookmarks(bookmarkList.map(b => hydrateViewColumns(b)));
		},
		[bookmarkList]
	);

	useEffect(
		() => {
			if (!currentView) {
				mergePanelState({
					panelMode: '',
					selectedBookmark: null,
				});
			} else {
				handleEditBookmarkClick(currentView, explorerView);
			}
		},
		[currentView]
	);

	const closeDeleteConfirmation = () => {
		mergePanelState({
			showConfirmDelete: false,
			selectedBookmark: null,
		});
	};

	const handleDeleteBookmark = () => {
		const _id = selectedBookmark._id;
		return dispatch(deleteBookmark(_id))
			.then(() => {
				closeDeleteConfirmation();
				if (currentViewId && _id === currentViewId) {
					return reloadView();
				}
			})
			.catch(e => {
				// dependencies were found for view, cannot delete
				mergePanelState({
					noDeleteReasons: e,
					showConfirmDelete: false,
				});
			});
	};

	const cancelNoDeleteReasons = () => {
		mergePanelState({
			noDeleteReasons: null,
			selectedBookmark: null,
		});
	};

	if (showConfirmDelete) {
		return (
			<FlyoutConfirmPanel
				header={'Delete'}
				message={`Are you sure you want to delete the following view? ${_get(selectedBookmark, 'name', '')}`}
				acceptFunc={handleDeleteBookmark}
				rejectFunc={closeDeleteConfirmation}
				acceptLabel={'DELETE'}
				rejectLabel={'CANCEL'}
			/>
		);
	}

	if (noDeleteReasons) {
		return (
			<FlyoutConfirmPanel
				header={'Delete'}
				message={
					<span>
						View <span className="bold">&quot;{selectedBookmark.name}&quot;</span> cannot be deleted because
						it is associated with the following:
					</span>
				}
				rejectFunc={cancelNoDeleteReasons}
				rejectLabel={'CLOSE'}
				hideAccept={true}
				reasonList={noDeleteReasons.data.dependencies}
			/>
		);
	}

	const handleCopyBookmarkClick = bookmark => {
		const bookmarkCopy = Object.assign({}, bookmark);
		bookmarkCopy.name = `Copy of ${bookmark.name}`;
		delete bookmarkCopy._id;
		const {filters} = dataset;
		bookmarkCopy.explorerData.filters = _get(bookmarkCopy, 'explorerData.filters', []).map(filterId => {
			return filters.find(f => f._id === filterId);
		});
		mergePanelState({
			panelMode: 'edit',
			isCopy: true,
			selectedBookmark: bookmarkCopy,
		});
	};

	const handleDeleteBookmarkClick = bookmark => {
		mergePanelState({
			showConfirmDelete: true,
			selectedBookmark: bookmark,
		});
	};

	const userListOptions = _.sortBy(userList, u => `${u.lastName}${u.firstName}`.toLowerCase()).map(u => {
		return {value: u.userId, label: `${u.firstName} ${u.lastName}`};
	});
	userListOptions.unshift({value: 'global', label: 'Global Only'});
	userListOptions.unshift({value: '', label: 'All Users'});

	const filterQuery = bookmark => {
		switch (selectedUserId) {
			case '':
				return !bookmark.isGlobal;
			case 'global':
				return bookmark.isGlobal;
			default:
				if (!isAdmin) {
					//show both global and owned views
					return bookmark.createdBy === selectedUserId || !bookmark.createdBy;
				} else {
					//show only selected user views
					return bookmark.createdBy === selectedUserId;
				}
		}
	};

	const filteredBookmarks = searchedBookmarks
		.filter(bookmark => bookmark.datasetId === datasetId)
		.filter(bookmark => filterQuery(bookmark))
		.sort(customViewSort(user.userId));

	const onSearch = event => {
		const searchTerm = _get(event, 'target.value', '');
		const searchTermCaseless = searchTerm.toUpperCase();
		setSearchTerm(searchTerm);
		setSearchedBookmarks(
			bookmarkList.filter(bookmark => {
				const tags = _get(bookmark, 'tags', []);
				return (
					bookmark.name.toUpperCase().includes(searchTermCaseless.toUpperCase()) ||
					tags.some(element => element.toUpperCase() === searchTermCaseless)
				);
			})
		);
	};

	return (
		<div className="context-sidebar-panel-flex">
			<header className="flyout-panel-title">MANAGE VIEWS</header>
			{!panelMode && (
				<div>
					<section className="flyout-panel-search">
						{isAdmin && (
							<div className="flyout-panel-user-search">
								<p>Filter</p>
								<Select
									classNamePrefix="aut-select"
									className={styles.picker}
									closeOnSelect={true}
									hint="Select a User"
									isClearable={false}
									options={userListOptions}
									onChange={o => setSelectedUserId(o.value)}
									value={userListOptions.find(o => o.value === selectedUserId)}
								/>
							</div>
						)}
						<p>Search a View</p>
						<input placeholder="Name or Tag" value={searchTerm} onChange={onSearch} />
					</section>
					{filteredBookmarks.length ? (
						<section className={styles.listContainer}>
							{filteredBookmarks.map(bookmark => {
								return (
									<ViewListItem
										key={_uniqueId()}
										bookmark={bookmark}
										copyBookmark={handleCopyBookmarkClick}
										deleteBookmark={handleDeleteBookmarkClick}
										currentViewId={currentViewId}
										user={user}
										isAdmin={isAdmin}
									/>
								);
							})}
						</section>
					) : (
						<p>No Views found</p>
					)}
				</div>
			)}
			{panelMode &&
				(isDataExploration ? (
					<ManageExplorationViewForms isCopy={isCopy} tabIndex={contextSidebarPanelConfig.tabIndex} />
				) : (
					<ManageViewForms
						panelMode={panelMode}
						selectedView={selectedBookmark}
						isCopy={isCopy}
						isAdmin={isAdmin}
						currentViewId={currentViewId}
						reloadView={reloadView}
						applyView={applyView}
						setViewHasChanges={setViewHasChanges}
						user={user}
						tabIndex={contextSidebarPanelConfig.tabIndex}
					/>
				))}
		</div>
	);
};

// TODO  check what's needed
FlyoutManageViews.propTypes = {
	currentView: PropTypes.object,
	currentViewId: PropTypes.string,
	explorerView: PropTypes.object,
	reloadView: PropTypes.func,
	applyView: PropTypes.func,
	setViewHasChanges: PropTypes.func,
	isDataExploration: PropTypes.bool,
};

export default FlyoutManageViews;
