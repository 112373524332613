import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {getDates, getSingleDate} from 'ki-common/utils/asOfDateUtil';
import KiDatePicker from 'components/KiDatePicker';
import styles from './dateDetailsForm.theme.scss';

class PreviewResultPane extends Component {
	static propTypes = {
		dateConfig: PropTypes.object,
	};

	static defaultProps = {
		dateConfig: {
			dateType: 'static',
			startDate: new Date().toISOString().slice(0, 10),
			recurrenceType: 'none',
			recurrenceStep: 1,
			calendar: null,
			businessDay: 'next',
			parentId: null,
		},
	};

	constructor(props, context) {
		super(props, context);
		this.previewRefs = {};
	}

	state = {
		previewDates: getDates(this.props.dateConfig),
	};

	componentDidUpdate(prevProps) {
		if (this.props.dateConfig && !_.isEqual(this.props.dateConfig, prevProps.dateConfig)) {
			let previewDates = [];
			if (this.props.dateConfig.recurrenceType !== 'daily') {
				try {
					previewDates = getDates(this.props.dateConfig);
				} catch (e) {
					previewDates = [];
				}
			}
			return this.setState({
				previewDateInput: '',
				previewDate: null,
				previewDates,
			});
		}
	}
	render() {
		return (
			<aside className={styles.previewContainer}>
				{this.state.previewDates.length > 0 ? (
					<>
						<KiDatePicker
							value={this.state.previewDateInput}
							onChange={datePicked => {
								if (!datePicked) return;
								const dateString = moment(datePicked).format('YYYY-MM-DD');
								const previewDate = getSingleDate(this.props.dateConfig, dateString);
								this.setState(
									{
										previewDateInput: dateString,
										previewDate: previewDate,
									},
									() => {
										if (this.previewRefs[previewDate]) {
											this.previewRefs[previewDate].scrollIntoView({
												behavior: 'smooth',
												block: 'nearest',
											});
										}
									}
								);
							}}
							label={
								<span>
									Preview For Date
									<i
										className="material-icons"
										title="Preview results do not account for holidays, parent settings, bound by dates, fv/pool encumbrance, and additional logic for distribution/determination dates."
										style={{
											pointerEvents: 'all',
											color: 'orange',
											fontSize: '2rem',
											cursor: 'default',
										}}
									>
										info
									</i>
								</span>
							}
						/>
						<section className={styles.previewDates}>
							{!!this.props.dateConfig.startDate &&
								this.state.previewDates.reduce((acc, resultDate, idx, previewDates) => {
									const isResultMatch = this.state.previewDate === resultDate;
									const tooManyDates = previewDates.length > 100;
									if (tooManyDates) {
										if (!isResultMatch && idx > 49 && idx < previewDates.length - 50) return acc;
										if (idx === 49 && !previewDates.includes(this.state.previewDate)) {
											return [...acc, <div key="hiddenResults">...</div>];
										}
									}
									return [
										...acc,
										...((isResultMatch && tooManyDates && [<div key="beforeMatch">...</div>]) ||
											[]),
										<p
											key={resultDate}
											ref={el => (this.previewRefs[resultDate] = el)}
											style={{padding: '0', letterSpacing: '.2px'}}
											className={resultDate === this.state.previewDate ? styles.previewMatch : ''}
										>
											{this.props.dateConfig.dateType === 'range' &&
												(idx === 0
													? `${this.props.dateConfig.startDate} - `
													: `${moment(this.state.previewDates[idx - 1])
															.add(1, 'day')
															.format('YYYY-MM-DD')} - ` || null)}
											{resultDate}
										</p>,
										...((isResultMatch && tooManyDates && [<div key="afterMatch">...</div>]) || []),
									];
								}, [])}
						</section>
					</>
				) : (
					<div className={styles.previewInfo}>Preview will not display for Recurring Day(s)</div>
				)}
			</aside>
		);
	}
}

export default PreviewResultPane;
