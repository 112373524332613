import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {KiSelect} from 'components/KiSelect';
import KiModal2 from 'components/KiModal2';
import KiButton from 'components/KiButton';
import KiOperatorPicker from 'components/KiOperatorPicker';
import Select from 'react-select';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import './ModalStyles.scss';

const FormulaPreview = ({operands = []}) => {
	const formulaItems = operands.reduce(
		(acc, operand, idx) => [
			...acc,
			!idx ? <span key={idx}>IF </span> : <span key={`label-${idx}`}> {_get(operand, 'logic.label', '')} </span>,
			...Array(operand.openingGroup.length).fill(' ( '),
			` ${_get(operand, 'column.name', 'UNKNOWN')} `,
			...Array(operand.closingGroup.length).fill(' ) '),
		],
		[]
	);
	return (
		<table className="formula-preview">
			<thead>
				<tr>
					<th>Preview</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						{formulaItems}
						<span> THEN</span> true
					</td>
				</tr>
				<tr>
					<td>
						<span>ELSE </span> false
					</td>
				</tr>
			</tbody>
		</table>
	);
};

FormulaPreview.propTypes = {
	operands: PropTypes.array,
};

FormulaPreview.defaultProps = {
	operands: [],
};

const TriggerModalFormulaRow = props => {
	const {
		index,
		operand,
		selectedOperatorRow,
		selectedOperatorColumn,
		showOperatorPicker,
		showParenSelector,
		onSelectParen,
		calculationOperands,
		editOperand,
		deleteOperand,
	} = props;
	return (
		<tr key={index}>
			<td
				style={{
					overflow: 'initial',
					position: 'relative',
					cursor: 'pointer',
					width: '60px',
					textAlign: 'center',
				}}
			>
				<div onClick={() => showParenSelector(index, 'openingGroup')}>
					{!_isEmpty(operand.openingGroup)
						? operand.openingGroup.map(parenthesis => parenthesis.value)
						: '.........'}
				</div>
				{selectedOperatorRow === index &&
					selectedOperatorColumn === 'openingGroup' &&
					showOperatorPicker && (
						<KiOperatorPicker
							options={['(', 'CLR']}
							onSelect={option => onSelectParen(option, index, 'openingGroup')}
						/>
					)}
			</td>
			<td>{_get(operand, 'column.name', 'UNKNOWN')}</td>
			<td
				style={{
					overflow: 'initial',
					position: 'relative',
					cursor: 'pointer',
					width: '60px',
					textAlign: 'center',
				}}
			>
				<div onClick={() => showParenSelector(index, 'closingGroup')}>
					{!_isEmpty(operand.closingGroup)
						? operand.closingGroup.map(parenthesis => parenthesis.value)
						: '.........'}
				</div>
				{selectedOperatorRow === index &&
					selectedOperatorColumn === 'closingGroup' &&
					showOperatorPicker && (
						<KiOperatorPicker
							options={[')', 'CLR']}
							onSelect={option => onSelectParen(option, index, 'closingGroup')}
						/>
					)}
			</td>
			<td>{_get(calculationOperands[index], 'logic.label', '')}</td>
			<td style={{textAlign: 'right'}}>
				<i
					style={{marginRight: '10px'}}
					title="Edit"
					className="material-icons"
					onClick={() => editOperand(operand, index)}
				>
					mode_edit
				</i>
				<i title="Delete" className="material-icons" onClick={() => deleteOperand(index)}>
					delete
				</i>
			</td>
		</tr>
	);
};

TriggerModalFormulaRow.propTypes = {
	index: PropTypes.number.isRequired,
	operand: PropTypes.any.isRequired,
	selectedOperatorRow: PropTypes.object,
	selectedOperatorColumn: PropTypes.object,
	showOperatorPicker: PropTypes.bool,
	calculationOperands: PropTypes.array.isRequired,
	editOperand: PropTypes.func.isRequired,
	deleteOperand: PropTypes.func.isRequired,
	showParenSelector: PropTypes.func.isRequired,
	onSelectParen: PropTypes.func.isRequired,
};

TriggerModalFormulaRow.defaultProps = {
	showOperatorPicker: false,
	calculationOperands: [],
};

class TriggersModal extends React.Component {
	static propTypes = {
		selectedFormula: PropTypes.object,
		fundingVehicles: PropTypes.array,
		prePopulateFormulaTable: PropTypes.func.isRequired,
		toggleVisibility: PropTypes.func,
		columnToEdit: PropTypes.object,
		active: PropTypes.bool,
		handleConfirm: PropTypes.func,
		usedFundingVehicles: PropTypes.array,
		triggersList: PropTypes.array,
		fetchAllReportingDates: PropTypes.func,
	};

	static defaultProps = {
		selectedFormula: {
			fundingVehicle: null,
			period: 'all',
			formula: [],
		},
		fundingVehicles: [],
		formulas: [],
		usedFundingVehicles: [],
		reportDates: [],
		triggersList: [],
	};

	state = {
		selectedFundingVehicle:
			this.props.fundingVehicles.find(fv => fv._id === this.props.selectedFormula.fundingVehicle) || null,
		selectedPeriod: {
			label: (this.props.selectedFormula.period === 'all' ? 'All' : this.props.selectedFormula.period) || 'All',
			value: this.props.selectedFormula.period || 'all',
		},
		calculationOperands: this.props.prePopulateFormulaTable(this.props.selectedFormula.formula),
		logic: {label: 'AND', value: 'and'},
		operandValue: null,
		editingOperand: -1,
		reportDates: [],
	};

	disableSave = () => {
		const {selectedFundingVehicle, selectedPeriod, calculationOperands} = this.state;
		let openingGroup = 0,
			closingGroup = 0;
		calculationOperands.forEach(operand => {
			openingGroup += operand.openingGroup.length;
			closingGroup += operand.closingGroup.length;
		});
		return !(
			selectedFundingVehicle &&
			selectedPeriod &&
			calculationOperands.length &&
			openingGroup === closingGroup
		);
	};

	handleConfirm = () => {
		const formulaArray = [];
		let operandToAdd;
		// Format formula to match the expected pattern.
		this.state.calculationOperands.forEach((operand, index) => {
			if (index === 0 && !!operand.logic) {
				delete operand.logic;
			}
			operandToAdd = {
				type: 'column',
				value: operand.column._id,
			};
			if (_get(operand, 'logic.value', null)) {
				formulaArray.push({type: 'operator', value: operand.logic.value});
			}
			operand.openingGroup.map(parens => formulaArray.push(parens));
			formulaArray.push(operandToAdd);
			operand.closingGroup.map(parens => formulaArray.push(parens));
		});

		const output = {
			formula: formulaArray,
			fundingVehicle: this.state.selectedFundingVehicle._id,
			dateType: 'reportDate',
			period: this.state.selectedPeriod.value,
		};
		this.props.handleConfirm(output);
		this.props.toggleVisibility();
	};

	handleFVChange = fv => {
		return this.props.fetchAllReportingDates(fv._id).then(results => {
			this.setState({
				reportDates: results.length
					? results.map(result => ({
							label: result,
							value: result,
					  }))
					: [],
				selectedFundingVehicle: fv,
			});
		});
	};

	addOperand = () => {
		this.setState(state => {
			const {calculationOperands, editingOperand, operandValue, logic} = state;
			const idx = editingOperand < 0 ? calculationOperands.length : editingOperand;
			return {
				calculationOperands: [
					...calculationOperands.slice(0, idx),
					{
						openingGroup: _get(calculationOperands, `[${editingOperand}].openingGroup`, []),
						logic: editingOperand > 0 ? null : logic,
						column: operandValue,
						closingGroup: _get(calculationOperands, `[${editingOperand}].closingGroup`, []),
					},
					...calculationOperands.slice(idx + 1),
				],
				operandValue: null,
				editingOperand: -1,
			};
		});
	};

	editOperand = (operand, index) => {
		this.setState({
			logic: operand.logic,
			operandValue: operand.column,
			editingOperand: index,
		});
	};

	deleteOperand = index => {
		this.setState(state => ({
			calculationOperands: state.calculationOperands.filter((op, idx) => idx !== index),
		}));
	};

	showParenSelector = (index, type) => {
		this.setState({
			showOperatorPicker: true,
			selectedOperatorRow: index,
			selectedOperatorColumn: type,
		});
	};

	onSelectParen = (option, idx, column) =>
		this.setState(state => {
			const changes = {};
			if (['openingGroup', 'closingGroup'].includes(column)) {
				if (option === 'CLR') {
					// Clear out value of logic cell
					// Clear out the correct group column
					changes[column] = [];
				} else if (['(', ')'].includes(option)) {
					// Push parenthesis to their respective arrays
					changes[column] = [
						...state.calculationOperands[idx][column],
						{
							type: 'operator',
							value: option,
						},
					];
				}
			}
			return {
				showOperatorPicker: false,
				calculationOperands: [
					...state.calculationOperands.slice(0, idx),
					{
						...state.calculationOperands[idx],
						...changes,
					},
					...state.calculationOperands.slice(idx + 1),
				],
			};
		});

	isTriggerCircularReference = trigger => {
		const currentTriggerId = _get(this.props.columnToEdit, '_id');
		if (trigger._id === currentTriggerId) return true;
		if (trigger.type === 'boolean') {
			return trigger.formulas.some(formulaEntry =>
				formulaEntry.formula.find(op => op.type === 'column' && op.value === currentTriggerId)
			);
		}
		return false;
	};

	render() {
		const {selectedOperatorRow, selectedOperatorColumn, showOperatorPicker} = this.state;
		return (
			<KiModal2
				active={this.props.active}
				actions={[
					{label: 'Cancel', onClick: () => this.props.toggleVisibility()},
					{label: 'Apply', onClick: this.handleConfirm, disabled: this.disableSave()},
				]}
				panelStyles={{minHeight: '60rem', minWidth: '90rem'}}
				className="numeric-debt-formula-modal"
				header={`Create/Edit a Boolean Trigger Formula`}
			>
				<p>Funding Vehicles</p>
				<KiSelect
					value={this.state.selectedFundingVehicle}
					isOptionDisabled={fv =>
						fv._id !== this.props.selectedFormula.fundingVehicle &&
						this.props.usedFundingVehicles.includes(fv._id)
					}
					options={this.props.fundingVehicles}
					getOptionLabel={opt => opt.name}
					getOptionValue={opt => opt._id}
					onChange={this.handleFVChange}
				/>
				<section className="date-selectors">
					<div className="select-wrapper" style={{marginLeft: 0}}>
						<span className={'form-instruction'}>Date Type</span>
						<Select
							classNamePrefix="aut-select"
							value={{label: 'Report Date', value: 'reportDate'}}
							isClearable={false}
							options={[{label: 'Report Date', value: 'reportDate'}]}
							onChange={null}
						/>
					</div>
					<div className="select-wrapper">
						<span className={'form-instruction'}>Period</span>
						<Select
							classNamePrefix="aut-select"
							value={this.state.selectedPeriod}
							isClearable={false}
							options={[{label: 'All', value: 'all'}, ...this.state.reportDates]}
							onChange={val => this.setState({selectedPeriod: val})}
						/>
					</div>
				</section>
				<div className="form-row">
					{!!this.state.calculationOperands.length && (
						<div className="flex-1">
							<span className="form-instruction">Logic:</span>
							<Select
								classNamePrefix="aut-select"
								className="standard-pad-right"
								value={this.state.logic}
								isClearable={false}
								options={[{label: 'AND', value: 'and'}, {label: 'OR', value: 'or'}]}
								onChange={selected => this.setState({logic: selected})}
							/>
						</div>
					)}
					<div className="flex-6">
						<span className="form-instruction">Operand:</span>
						<Select
							classNamePrefix="aut-select"
							value={{label: 'Boolean', value: 'boolean'}}
							isClearable={false}
							options={[{label: 'Boolean', value: 'boolean'}]}
							onChange={null}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="flex-6" style={{marginRight: '1rem'}}>
						<Select
							classNamePrefix="aut-select"
							value={this.state.operandValue}
							isOptionDisabled={this.isTriggerCircularReference}
							options={this.props.triggersList}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							onChange={val => this.setState({operandValue: val})}
						/>
					</div>
					<div>
						<KiButton
							className="add-btn trigger-add-btn"
							onClick={this.addOperand}
							disabled={!this.state.operandValue}
							icon={this.state.editingOperand < 0 ? 'add' : 'save'}
						/>
					</div>
				</div>
				{!!this.state.calculationOperands.length && (
					<Fragment>
						<div className="form-row">
							<FormulaPreview operands={this.state.calculationOperands} />
						</div>
						<div className="form-row">
							<table className="formula-preview">
								<thead>
									<tr>
										<th>Group</th>
										<th>Operator</th>
										<th>Group</th>
										<th>Logic</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{this.state.calculationOperands.map((operand, index) => (
										<TriggerModalFormulaRow
											key={index}
											operand={operand}
											index={index}
											selectedOperatorColumn={selectedOperatorColumn}
											selectedOperatorRow={selectedOperatorRow}
											calculationOperands={this.state.calculationOperands}
											deleteOperand={this.deleteOperand}
											editOperand={this.editOperand}
											showOperatorPicker={showOperatorPicker}
											showParenSelector={this.showParenSelector}
											onSelectParen={this.onSelectParen}
										/>
									))}
								</tbody>
							</table>
						</div>
					</Fragment>
				)}
			</KiModal2>
		);
	}
}

export default TriggersModal;
