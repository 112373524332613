import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export function fetchEAViewsByDatasetId(datasetId) {
	return fetch(`${apiUrl}/eligibilityViews/dataset/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
}

export default {
	fetchEAViewsByDatasetId,
};
