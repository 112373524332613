import {apiUrl, _checkAuth, _handleResponse, _getHeaders, _checkStatus} from 'api/apiUtils';
import analytics from 'analytics';
import exploreRequestBuilder from 'ki-common/utils/exploreRequestBuilder';
import _get from 'lodash/get';
import {datasetDatesApi} from 'api';
import {debtScenarioContexts} from 'ki-common/options/debt';

export const fetchQuickFilters = async (
	datasetId,
	scenarioId,
	fundingVehicleId,
	statementDate,
	scenarioType,
	dateContext,
	dateContextList = [],
	isFixedDate = false
) => {
	let calcDate = statementDate;

	// Figure out what date to pass as the snapshot date based on statementDate and dateContext
	if (dateContext) {
		const dateContextMatch = dateContextList.find(d => d._id === dateContext);
		const calculatedDatesRequest = [
			{
				groupId: dateContextMatch.groupId,
				statementDate: statementDate,
				fundingVehicleId: fundingVehicleId,
				isFixedDate: isFixedDate,
			},
		];
		const calculatedDateInfo = await datasetDatesApi.getCalculatedDates(calculatedDatesRequest, datasetId);

		if (calculatedDateInfo.length) {
			calcDate = calculatedDateInfo[0].calculatedDate;
		}
	}

	let path = `${apiUrl}/explore/quickFilterLists/?datasetId=${datasetId}&snapshotDate=${calcDate}`;
	if (scenarioId === 'assetSnapshot' || scenarioId === 'lastCommitted' || scenarioId === 'lastApproved') {
		scenarioId = ''; //these are placeholder values from the UI
	}
	if (scenarioId) {
		path += `&scenarioId=${scenarioId}`;
	}
	if (fundingVehicleId) {
		path += `&fundingVehicleId=${fundingVehicleId}`;
	}
	if (scenarioType) {
		path += `&scenarioType=${scenarioType}`;
	}
	return fetch(path, {
		credentials: 'include',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(response => response.json())
		.then(({fundingVehicleList = [], poolList = [], scenarioList = [], transferDates = []}) => {
			return {
				fundingVehicleList: [{value: '', label: 'All'}, ...fundingVehicleList],
				poolList: [{value: '', label: 'All'}, ...poolList],
				scenarioList: [
					...debtScenarioContexts,
					{
						label: 'Hypo',
						options: scenarioList,
					},
					{
						label: 'Pending',
						options: transferDates.map(tDate => ({
							label: tDate,
							value: tDate, // actual transferDate
						})),
					},
				],
				encumbranceStatusList: [
					{value: '', label: 'All'},
					{value: 'unencumbered', label: 'Unencumbered'},
					{value: 'encumbered', label: 'Encumbered'},
					{value: 'pendingPledged', label: 'Pending Pledged'},
					{value: 'pendingRepurchase', label: 'Pending Drawdown'},
					{value: 'unassigned', label: 'Unassigned'},
				],
				eligibilityList: [
					{value: '', label: 'All'},
					{value: 'eligible', label: 'Eligible'},
					{value: 'ineligible', label: 'Ineligible'},
				],
			};
		});
};

// This method is only used in ReportDisplay.js as of 2021-03-25
export const fetchScenarioList = async (datasetId, fundingVehicleId, statementDate, scenarioType) => {
	const quickFilters = await fetchQuickFilters(
		datasetId,
		null, // scenarioId
		fundingVehicleId,
		statementDate,
		scenarioType,
		null // dateContext
	);

	return quickFilters.scenarioList;
};

export const fetchPoolByIdentifier = (datasetId, poolIdentifier) => {
	const path = `${apiUrl}/pools/datasetId/${datasetId}/identifier/${encodeURI(poolIdentifier)}`;
	return fetch(path, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchColumnSummary = async (columnId, dataExplorer, datasetColumns, snapshotDate = null) => {
	const parsedRequest = exploreRequestBuilder.buildRequestFromDataExplorerState(dataExplorer, false, datasetColumns);
	const path = `${apiUrl}/explore/columnSummary/${columnId}`;

	if (parsedRequest.columns[1]._id === 'all') {
		parsedRequest.columns[0] = datasetColumns.find(col => _get(col, 'assetColumn.columnName') === 'kiSnapshotDate');
		parsedRequest.columns[1] = dataExplorer.columns.find(col => col._id === columnId);
	}

	// If the parsedRequest does not pass a valid snapshot date in the request will
	// fail when sent to the BE. Add the passed snapshot date if provided
	if (snapshotDate) {
		parsedRequest.snapshotDate = snapshotDate;
	}
	return fetch(path, {
		credentials: 'include',
		mode: 'cors',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(parsedRequest),
	}).then(_handleResponse);
};

export function fetchExplore(dataExplorer, datasetColumns) {
	analytics.sendEvent('explorer', 'fetch');

	const parsedRequest = exploreRequestBuilder.buildRequestFromDataExplorerState(dataExplorer, false, datasetColumns);
	const ts = new Date().getTime();
	return fetch(`${apiUrl}/explore`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(parsedRequest),
	})
		.then(response => {
			analytics.sendTimingEvent('explorer-timing', 'fetch', new Date().getTime() - ts);
			return response;
		})
		.then(_checkAuth)
		.then(_checkStatus);
}

export default {
	fetchQuickFilters,
	fetchPoolByIdentifier,
	fetchColumnSummary,
	fetchExplore,
	fetchScenarioList,
};
