import React from 'react';
import Available from './index';
import {USER_GROUPS} from '../../utils/userGroupsUtil';
import PropTypes from 'prop-types';

function AdminAvailable({children}) {
	return <Available groups={[USER_GROUPS.SYSTEM_ADMINS]}>{children}</Available>;
}

AdminAvailable.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AdminAvailable;
