const _cloneDeep = require('lodash/cloneDeep');
const ccConcentration = require('./ccConcentration');

const baseContraints = ccConcentration.getConstraints();
const getConstraints = () => {
	const constraints = _cloneDeep(baseContraints);
	constraints.rowType.format = {
		pattern: '^ccConcentrationLimit',
		flag: 'i',
		message: 'rowType must be ccConcentrationLimit',
	};
	delete constraints.target.targetArrayConcentration;
	constraints.target.targetArrayConcentrationNonPercent = true;
	return constraints;
};

module.exports = {
	getConstraints,
};
