import React, {useEffect, useState} from 'react';
import './LedgerTemplateConfiguration.scss';
import PropTypes from 'prop-types';
import {CONFIGURE_ACTIONS} from '../LedgerConfigure';
import LedgerConfigureHeader from '../LedgerConfigureHeader';
import TemplateItem from './TemplateItem';
import {useParams} from 'react-router';
import {
	getTemplates,
	addTemplate,
	getDateContexts,
	getAmounts,
	getAccounts,
	setPureTemplates,
	setLoading,
	setIsVehicleLedger,
	getEntities,
	searchTemplates,
} from './actions';
import {connect} from 'react-redux';
import KiIconButton from '../../../../../components/KiIconButton';
import {updateTemplates} from '../../../../../api/ledgersApi';
import {showSnackbar} from '../../../../../state/actions/Snackbar';
import {convertTemplatesForSave} from './templateUtils';
import Loader from '../../../Loader';
import KiInput from '../../../../../components/KiInput';

function LedgerTemplateConfiguration({
	ledger,
	setView,
	templateList,
	getTemplates,
	addTemplate,
	getDateContexts,
	getAmounts,
	getAccounts,
	getEntities,
	setPureTemplates,
	showSnackbar,
	loading,
	setLoading,
	isVehicleLedger,
	setIsVehicleLedger,
	searchTemplates,
}) {
	const {id} = useParams();
	const [searchTerm, setSearchTerm] = useState('');

	const onSave = () => {
		setLoading(true);
		updateTemplates(id, convertTemplatesForSave(templateList, isVehicleLedger))
			.then(({templates}) => {
				setSearchTerm('');
				setPureTemplates(templates);
				setLoading(false);
				showSnackbar('All template configuration changes have been saved.');
			})
			.catch(() => {
				setLoading(false);
				showSnackbar(`Save failed. One or more templates are invalid`);
				getTemplates(id);
			});
	};

	const onCancel = () => {
		setLoading(true);
		getTemplates(id).then(() => {
			setSearchTerm('');
			setLoading(false);
			showSnackbar('All template configuration changes have been cancelled.');
		});
	};

	const BUTTON_ACTIONS = [
		{
			label: 'CANCEL',
			isFlat: true,
			onClick: onCancel,
		},
		{
			label: 'SAVE',
			onClick: onSave,
		},
	];

	useEffect(() => {
		getTemplates(id);
	}, []);

	useEffect(
		() => {
			if (ledger && ledger._id) {
				const checkVehicleLedger = ledger.fundingVehicleId !== null;
				setIsVehicleLedger(checkVehicleLedger);
				if (checkVehicleLedger) {
					getEntities(ledger);
				}
				getDateContexts(ledger.datasetId, checkVehicleLedger);
				getAmounts(ledger.datasetId, checkVehicleLedger);
				getAccounts(ledger._id);
			}
		},
		[ledger]
	);

	const isAnyTemplateVisible = () => {
		return (
			templateList &&
			templateList.length &&
			templateList.some(template => !template.deleted) &&
			templateList.some(template => template.visible)
		);
	};

	useEffect(
		() => {
			searchTemplates(searchTerm);
		},
		[searchTerm]
	);

	return (
		<div>
			<section className="ki-panel">
				<LedgerConfigureHeader
					currentView={CONFIGURE_ACTIONS.TEMPLATES}
					setView={setView}
					buttonActions={BUTTON_ACTIONS}
				/>
			</section>
			<section className="ki-panel ledger-templates">
				<Loader isActive={loading}>
					<div className="ledger-template-subheader">
						<div className="search-box">
							<div>
								<KiInput
									label="Search"
									value={searchTerm}
									onChange={val => setSearchTerm(val)}
									maxLength={100}
								/>
							</div>
						</div>
						<div className="add-template">
							<KiIconButton icon="add_circle" disabled={searchTerm !== ''} onClick={addTemplate} />
						</div>
					</div>
					{isAnyTemplateVisible() ? (
						templateList.map(
							(template, index) =>
								!template.deleted &&
								template.visible && (
									<TemplateItem
										template={template}
										key={index}
										index={index}
										fvId={ledger.fundingVehicleId}
									/>
								)
						)
					) : (
						<div className="no-list-items-container">
							<div>No templates{searchTerm !== '' ? ' found' : ''}</div>
						</div>
					)}
				</Loader>
			</section>
		</div>
	);
}

const mapStateToProps = ({ledgerTemplateConfiguration}) => ({
	templateList: ledgerTemplateConfiguration.templateList,
	loading: ledgerTemplateConfiguration.loading,
	isVehicleLedger: ledgerTemplateConfiguration.isVehicleLedger,
});

LedgerTemplateConfiguration.propTypes = {
	ledger: PropTypes.object.isRequired,
	setView: PropTypes.func.isRequired,
	templateList: PropTypes.arrayOf(PropTypes.object).isRequired,
	getTemplates: PropTypes.func.isRequired,
	getDateContexts: PropTypes.func.isRequired,
	getAmounts: PropTypes.func.isRequired,
	getAccounts: PropTypes.func.isRequired,
	getEntities: PropTypes.func.isRequired,
	addTemplate: PropTypes.func.isRequired,
	setPureTemplates: PropTypes.func.isRequired,
	searchTemplates: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	setLoading: PropTypes.func.isRequired,
	isVehicleLedger: PropTypes.bool.isRequired,
	setIsVehicleLedger: PropTypes.func.isRequired,
};

export default connect(
	mapStateToProps,
	{
		getTemplates,
		addTemplate,
		getDateContexts,
		getAmounts,
		getAccounts,
		getEntities,
		setPureTemplates,
		searchTemplates,
		showSnackbar,
		setLoading,
		setIsVehicleLedger,
	}
)(LedgerTemplateConfiguration);
