// Globals
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports

// Website imports
import KiModal from 'components/KiModal';
// import KiTabs from 'components/KiTabs';
// import KiTab from 'components/KiTabs/KiTab';
import KiButton from 'components/KiButton';

// Website api
import {getOrderRecurringScenarios, setOrderRecurringScenarios} from 'api/fundingAnalysisApi';
import {showSnackbar} from 'state/actions/Snackbar';

// Local imports
import styles from './scenarioPriorityModal.theme.scss';

function ScenarioPriorityModal({isActive, closeFunction}) {
	// Browser State
	const {datasetId} = useParams();

	// Redux State
	const dispatch = useDispatch();

	// Local State
	const [recurring, setRecurring] = useState([]);
	const [recurringApproved, setRecurringApproved] = useState([]);
	//const [tabIndex, setTabIndex] = useState(0);

	const fetchRecurringScenarios = async () => {
		try {
			const scenarios = await getOrderRecurringScenarios(datasetId);
			setRecurring(scenarios.recurring);
			setRecurringApproved(scenarios.recurringApproved);
		} catch (e) {
			//console.log('error fetching scenarios', e)
			setRecurring([]);
			setRecurringApproved([]);
		}
	};

	// On active status change
	useEffect(() => {
		fetchRecurringScenarios();
	}, []);

	useEffect(
		() => {
			if (isActive) {
				fetchRecurringScenarios();
			} else {
				setRecurring([]);
				setRecurringApproved([]);
			}
		},
		[isActive]
	);

	const moveElementInArray = (oldIndex, newIndex, targetArray, updateFunc) => {
		if (newIndex < 0 || newIndex === targetArray.length) {
			return targetArray;
		}

		const cloneArray = _.cloneDeep(targetArray); // Prevent mutation
		cloneArray.splice(newIndex, 0, cloneArray.splice(oldIndex, 1)[0]);
		updateFunc(cloneArray);
	};

	const handleSavePriorityOrder = async () => {
		// Convert array order to priorityOrderNum value
		const recurringOrdered = recurring.map((entry, idx) => {
			entry.priorityOrderNum = idx;
			return entry;
		});
		// Convert array order to priorityOrderNum value
		const recurringApprovedOrdered = recurringApproved.map((entry, idx) => {
			entry.priorityOrderNum = idx;
			return entry;
		});
		await setOrderRecurringScenarios(datasetId, recurringOrdered, recurringApprovedOrdered);
		dispatch(showSnackbar('Recurring Scenario Priority has been successfully updated'));
		closeFunction();
	};

	const getRowsToRender = (scenarioList, updateArrayFunc) => {
		const rows = scenarioList.map((entry, idx) => {
			const upFunc =
				idx === 0
					? null
					: () => {
							moveElementInArray(idx, idx - 1, scenarioList, updateArrayFunc);
					  };
			const downFunc =
				idx === scenarioList.length - 1
					? null
					: () => {
							moveElementInArray(idx, idx + 1, scenarioList, updateArrayFunc);
					  };
			return (
				<li key={entry._id} className={styles.scenarioList}>
					<KiButton
						className={styles.scenarioControl}
						title="Move Up"
						icon="keyboard_arrow_up"
						iconButton={true}
						raised
						primary
						onClick={upFunc}
					/>
					<KiButton
						className={styles.scenarioControl}
						title="Move Up"
						icon="keyboard_arrow_down"
						iconButton={true}
						raised
						primary
						onClick={downFunc}
					/>
					<div className={styles.scenarioName}>{entry.name}</div>
				</li>
			);
		});

		//console.log('getRowsToRender', rows);
		return rows;
	};

	if (!isActive) {
		return null;
	}

	/*
	Code to add ordering for manual approvals

	<KiTabs className={styles.tabContainer} index={tabIndex} onChange={index => setTabIndex(index)}>
		<KiTab title="Recurring with Manual Approvals">
			<ul>{getRowsToRender(recurring, setRecurring)}</ul>
		</KiTab>
		<KiTab title="Recurring with Systematic Approvals">
			<ul>{getRowsToRender(recurringApproved, setRecurringApproved)}</ul>
		</KiTab>
	</KiTabs>
	 */

	return (
		<KiModal
			active={isActive}
			actions={[
				{
					label: 'Cancel',
					onClick: () => closeFunction(),
				},
				{
					label: 'Save Priority Order',
					onClick: () => handleSavePriorityOrder(),
				},
			]}
			onClose={() => closeFunction()}
			header={'Recurring Scenarios with Systematic Approvals Priority'}
		>
			<ul>{getRowsToRender(recurringApproved, setRecurringApproved)}</ul>
		</KiModal>
	);
}

ScenarioPriorityModal.propTypes = {
	isActive: PropTypes.bool,
	closeFunction: PropTypes.func,
};

ScenarioPriorityModal.defaultProps = {
	isActive: false,
};

export default ScenarioPriorityModal;
