import KiColumnChart from './column.js';
import KiPieChart from './pie.js';
import KiLineChart from './line.js';
import KiAreaChart from './area.js';
import KiStackedColumnChart from './stackedColumn.js';

const _isEmpty = require('lodash/isEmpty');

/**
 * Generates a chart config based on the passed in values
 * @param  {string} chartType  Supported chart type (line, column, pie, stacked-column, area)
 * @param  {array}  categories Labels for data points (snapshot dates, buckets, months, etc)
 * @param  {array}  series     [{name: string, data: array of numbers}]
 * @param  {string} chartTitle Lable for the chart as a whole
 * @param  {string} xTitle     Label for xAxis
 * @param  {string} yTitle     Label for yAxis
 * @return {object}            Full highchart configuration
 */
const getChartConfig = (chartType, categories, series, chartTitle, xTitle, yTitle, legend, height) => {
	const params = {
		categories,
		series,
		chartTitle,
		xTitle,
		yTitle,
		height,
	};

	if (_isEmpty(categories)) {
		return {};
	}
	switch (chartType) {
		case 'column':
		case '3d-column':
			return new KiColumnChart({...params, chartType}).getConfig();
		case 'pie':
		case '3d-pie':
		case '3d-donut':
		case 'semi-circle-donut':
		case 'pie-gradient':
		case 'pie-monochrome':
			return new KiPieChart({...params, chartType}).getConfig();
		case 'stacked-column':
			return new KiStackedColumnChart(params).getConfig();
		case 'area':
			return new KiAreaChart(params).getConfig();
		case 'line-data-labels':
			return new KiLineChart({...params, dataLabels: true}).getConfig();
	}
	return new KiLineChart(params).getConfig();
};

const getSummaryChartData = (rows, columns, type, title) => {
	const categories = rows.map(data => {
		const value = data.meta.bucket.value; //eslint-disable-line prefer-destructuring
		const bucket = `${data.meta.bucket.min} - ${data.meta.bucket.max}`;
		return value || bucket;
	});
	return {
		chartType: type || 'column',
		categories,
		series: rows.map((row, index) => {
			return {
				name: columns[index].columnName,
				data: row.data,
			};
		}),
		chartTitle: title,
		xTitle: '',
		yTitle: '',
	};
};

export default {
	getChartConfig,
	getSummaryChartData,
};
