import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './debt.theme.scss';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames/bind';
import DebtForm from './debtForm';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import KiMenu from 'components/KiMenu';
import KiDnDList from 'components/KiDnDList';
import DebtDeleteModal from './debtDeleteModal';
import {updateFundingVehicleSetting} from '../actions';
import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
const cx = classNames.bind(styles);
import '../index.scss';

export class Debt extends Component {
	static propTypes = {
		debts: PropTypes.array,
		history: PropTypes.object,
		match: PropTypes.object,
		dataset: PropTypes.object,
		fundingVehicle: PropTypes.object,
		updateFundingVehicleSetting: PropTypes.func,
	};

	static defaultProps = {
		debts: [],
		match: {},
	};

	state = {
		debts: [],
	};

	componentDidMount() {
		this.setState({debts: _orderBy(_cloneDeep(this.props.debts), ['trancheNumber'], ['asc'])});
	}

	componentDidUpdate() {
		const stateDebts = this.state.debts;
		const propDebts = _orderBy(_cloneDeep(this.props.debts), ['classId', 'trancheNumber'], ['asc', 'asc']);
		if (stateDebts.length !== propDebts.length) {
			this.setState({debts: propDebts});
			return;
		}
		propDebts.forEach((debt, index) => {
			if (!_isEqual(debt, stateDebts[index])) {
				this.setState({debts: propDebts});
				return;
			}
		});
	}

	renderDebtItem = debt => {
		return (
			<div
				key={debt._id}
				className={cx('listItem', {[styles.listItemSelected]: this.props.match.params.debtId === debt._id})}
				onMouseUp={() =>
					this.props.history.push(
						`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
							this.props.match.params.id
						}/capitalStructure/debts/${debt._id}`
					)
				}
			>
				{debt.name}
			</div>
		);
	};

	onDebtsReordered = (debtsDnd, sourceIndex, destIndex) => {
		const debts = debtsDnd.map(debt => {
			return debt.content;
		});
		const targetClassId = debts[destIndex].classId;
		let targetClassCount = 0;

		debts.forEach(debt => {
			if (debt.classId === targetClassId) {
				targetClassCount++;
				debt.trancheNumber = targetClassCount;
				debt.nameId = `T_${debt.classId}_${targetClassCount}`;
				// TODO add ability to update many settings at once
				this.props.updateFundingVehicleSetting({_id: debt._id, ...debt});
			}
		});

		this.setState({debts: _orderBy(debts, ['classId', 'trancheNumber'], ['asc', 'asc'])});
	};

	render() {
		const {
			history,
			match = {
				params: {},
			},
		} = this.props;

		return (
			<div className={styles.root}>
				<div className={styles.listActions}>
					<i
						className="material-icons"
						onMouseUp={() => {
							history.push(
								`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
									match.params.id
								}/capitalStructure/debts/create`
							);
						}}
					>
						add_circle
					</i>

					<KiMenu icon="more_vert" position="topRight">
						<KiMenuItem
							icon="delete"
							disabled={!this.state.debts.length}
							label="Delete Records..."
							onClick={() => this.setState({deleteModalActive: true})}
						/>
					</KiMenu>
				</div>
				<div className={styles.list} style={{minHeight: `${(this.state.debts.length + 1) * 47}px`}}>
					{!this.state.debts.length && <div className={styles.noRecordsFound}>No Records Found</div>}
					<KiDnDList
						items={this.state.debts.map((debt, index) => ({
							id: index,
							content: debt,
							idx: index,
						}))}
						onReorder={this.onDebtsReordered}
						contentRenderFunc={this.renderDebtItem}
						listClassName={'debt-list-dnd'}
						itemClassName={'debtItemDnd'}
					/>
				</div>
				<div className={styles.detail}>
					<Route
						path={'/datasets/:datasetId/fundingVehicles/:id/capitalStructure/debts/:debtId'}
						render={() => {
							if (
								this.props.match.params.debtId &&
								this.props.match.params.debtId !== 'create' &&
								!this.state.debts.find(d => d._id === this.props.match.params.debtId)
							) {
								return (
									<Redirect
										to={`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
											match.params.id
										}/capitalStructure/debts`}
									/>
								);
							}
							return <DebtForm {...this.props} />;
						}}
					/>
				</div>
				<DebtDeleteModal
					active={this.state.deleteModalActive}
					debts={this.state.debts}
					onClose={() => this.setState({deleteModalActive: false})}
				/>
			</div>
		);
	}
}

const mapDispatchToProps = () => ({
	updateFundingVehicleSetting,
});

export default connect(
	null,
	mapDispatchToProps()
)(Debt);
