export default [
	{
		name: 'determination',
		displayName: 'Funding Determination Date',
		info: 'Must be mapped in order to blend data in Ki for warehouse reporting.',
	},
	{
		name: 'distribution',
		displayName: 'Funding Distribution Date',
		info: 'Must be mapped in order to blend data in Ki for warehouse reporting.',
	},
	{
		name: 'collectionEnd',
		displayName: 'Collection Period End Date',
		info: 'Must be mapped in order to use debt exploration.',
	},
	{
		name: 'report',
		displayName: 'Report Date',
		info: 'Must be mapped in order to use debt exploration.',
	},
];
