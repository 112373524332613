import React from 'react';
import PropTypes from 'prop-types';
import {format} from 'ki-common/utils/displayFormatter';
import options from 'ki-common/options';
import _ from 'lodash';

function TableCell(props) {
	const {column, cellValue} = props;
	const displayFormat = column.displayFormat || 'string';
	let formattedCellValue = _.clone(cellValue);
	if (column.columnType === 'debt' && column.columnName === 'accrualMethod') {
		const optionMatch = options.accrualMethodOptions.find(({value}) => value === cellValue);
		if (optionMatch && optionMatch.label) {
			formattedCellValue = optionMatch.label;
		}
	} else if (column.dataType === 'numeric') {
		formattedCellValue = format(parseFloat(cellValue), displayFormat);
	} else {
		formattedCellValue = format(cellValue, displayFormat);
	}

	return <div className="table-cell">{formattedCellValue}</div>;
}

TableCell.displayName = 'TableCell';

TableCell.propTypes = {
	column: PropTypes.object,
	cellValue: PropTypes.any,
};

TableCell.defaultProps = {
	column: {},
	cellValue: null,
};

export default TableCell;
