import * as actionTypes from './actionTypes';
import {cloneDeep, set} from 'lodash';
import {convertTemplatesToList, filterTemplateList, isTemplateValid} from './templateUtils';

const initialState = {
	pureTemplates: [],
	templateList: [],
	originalTemplateList: [],
	dateContextOptions: [],
	amountOptions: [],
	accountOptions: [],
	entityTypeOptions: [],
	entityNameOptions: [],
	loading: true,
	isVehicleLedger: false,
};

const createBlankTemplateLine = isVehicleLedger => {
	const templateLine = {
		description: '',
		account: '',
		dateContext: '',
		amount: '',
		entryType: '',
	};

	if (isVehicleLedger) {
		templateLine.entityType = '';
		templateLine.entityName = '';
	}

	return templateLine;
};

const createBlankTemplate = isVehicleLedger => ({
	valid: false,
	deleted: false,
	active: false,
	code: '',
	description: '',
	effectiveDate: '',
	lineItems: [createBlankTemplateLine(isVehicleLedger), createBlankTemplateLine(isVehicleLedger)],
	visible: true,
	modified: false,
	originalIndex: null,
});

const LedgerTemplateConfiguration = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PURE_TEMPLATES: {
			return {
				...state,
				pureTemplates: action.templates,
			};
		}
		case actionTypes.SET_TEMPLATE_LISTS: {
			const templates = action.templates ? action.templates : state.pureTemplates;
			const dateContextOptions = action.dateContextOptions ? action.dateContextOptions : state.dateContextOptions;
			const amountOptions = action.amountOptions ? action.amountOptions : state.amountOptions;
			const accountOptions = action.accountOptions ? action.accountOptions : state.accountOptions;
			const entityTypeOptions = action.entityTypeOptions ? action.entityTypeOptions : state.entityTypeOptions;
			const entityNameOptions = action.entityNameOptions ? action.entityNameOptions : state.entityNameOptions;

			const convertedTemplates = convertTemplatesToList(
				templates,
				state.isVehicleLedger,
				dateContextOptions,
				amountOptions,
				accountOptions,
				entityTypeOptions,
				entityNameOptions
			);
			return {
				...state,
				loading: false,
				templateList: [...cloneDeep(convertedTemplates)],
				originalTemplateList: [...cloneDeep(convertedTemplates)],
			};
		}
		case actionTypes.SET_DATE_CONTEXTS: {
			return {
				...state,
				dateContextOptions: action.dataContextOptions,
			};
		}
		case actionTypes.SET_AMOUNTS: {
			return {
				...state,
				amountOptions: action.amountOptions,
			};
		}
		case actionTypes.SET_ACCOUNTS: {
			return {
				...state,
				accountOptions: action.accountOptions,
			};
		}
		case actionTypes.SET_ENTITIES: {
			return {
				...state,
				entityTypeOptions: action.entityTypeOptions,
				entityNameOptions: action.entityNameOptions,
			};
		}
		case actionTypes.CHANGE_TEMPLATE: {
			const newTemplateList = cloneDeep(state.templateList);
			const editedTemplate = newTemplateList[action.index];
			set(editedTemplate, action.key, action.value);
			if (action.key.includes('entityType')) {
				// since we changed the entityType clear the amount and entity name
				set(editedTemplate, action.key.replace('entityType', 'entityName'), '');
				set(editedTemplate, action.key.replace('entityType', 'amount'), '');
			}
			editedTemplate.modified = true;
			editedTemplate.valid = isTemplateValid(editedTemplate, state.isVehicleLedger);
			editedTemplate.active = editedTemplate.active && editedTemplate.valid;
			return {...state, templateList: newTemplateList};
		}
		case actionTypes.ADD_TEMPLATE_LINE: {
			const newTemplateList = cloneDeep(state.templateList);
			const changedTemplate = newTemplateList[action.index];
			changedTemplate.lineItems.unshift(createBlankTemplateLine(state.isVehicleLedger));
			changedTemplate.active = false;
			changedTemplate.valid = false;
			changedTemplate.modified = true;
			return {
				...state,
				templateList: newTemplateList,
			};
		}
		case actionTypes.ADD_TEMPLATE: {
			return {
				...state,
				templateList: [
					createBlankTemplate(state.isVehicleLedger, state.isVehicleLedger),
					...cloneDeep(state.templateList),
				],
			};
		}
		case actionTypes.UNDO_TEMPLATE: {
			const newTemplateList = cloneDeep(state.templateList);
			newTemplateList[action.index] = cloneDeep(state.originalTemplateList[action.originalIndex]);
			return {
				...state,
				templateList: newTemplateList,
			};
		}
		case actionTypes.REMOVE_TEMPLATE: {
			const newTemplateList = cloneDeep(state.templateList);
			if (newTemplateList[action.index]._id) {
				newTemplateList[action.index].deleted = true;
			} else {
				newTemplateList.splice(action.index, 1);
			}
			return {
				...state,
				templateList: newTemplateList,
			};
		}
		case actionTypes.REMOVE_TEMPLATE_LINE: {
			const newTemplateList = cloneDeep(state.templateList);
			newTemplateList[action.index].lineItems.splice(action.lineIndex, 1);
			newTemplateList[action.index].valid = isTemplateValid(newTemplateList[action.index], state.isVehicleLedger);
			newTemplateList[action.index].modified = true;
			return {
				...state,
				templateList: newTemplateList,
			};
		}
		case actionTypes.LOADING: {
			return {
				...state,
				loading: action.loading,
			};
		}
		case actionTypes.SET_IS_VEHICLE_LEDGER: {
			return {
				...state,
				isVehicleLedger: action.isVehicleLedger,
			};
		}
		case actionTypes.SEARCH_TEMPLATES: {
			const {searchTerm} = action;
			if (searchTerm === '') {
				return {
					...state,
					templateList: cloneDeep(state.templateList).map(template => {
						template.visible = true;
						return template;
					}),
				};
			} else {
				return {
					...state,
					templateList: filterTemplateList(cloneDeep(state.templateList), searchTerm),
				};
			}
		}
		default:
			return state;
	}
};

export default LedgerTemplateConfiguration;
