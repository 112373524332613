import React from 'react';
import PropTypes from 'prop-types';
import AccountDetailTableItem from './AccountDetailTableItem';
import {ledgerCurrencyFormat} from '../../ledgerUtils';

function AccountDetailTable({balance, filteredAccounts, isFiltered}) {
	return (
		<div className="scenario-list-box list-box-size-2">
			{filteredAccounts.length ? (
				<table className="scenario-list-table scenario-list-table-ledger accounts">
					<thead className="summary-top">
						<colgroup>
							<col span="2" className="fixed-width-date" />
							<col className="fixed-width" />
							<col span="2" />
							<col span="2" className="fixed-width" />
						</colgroup>
						<tr className={isFiltered ? 'not-active with-border' : 'with-border'}>
							<th colSpan="5">Beginning Balance</th>
							<th className="align-right">{ledgerCurrencyFormat(balance.beginning.debit)}</th>
							<th className="align-right">{ledgerCurrencyFormat(balance.beginning.credit)}</th>
						</tr>
						<tr>
							<th>Posting Date</th>
							<th>Effective Date</th>
							<th>Post Code</th>
							<th>Description</th>
							<th>Account Code</th>
							<th>Line Item</th>
							<th className="align-right">Debit</th>
							<th className="align-right">Credit</th>
						</tr>
					</thead>
					<tbody>{filteredAccounts.map((item, i) => <AccountDetailTableItem item={item} key={i} />)}</tbody>
					<thead className="bottom-header">
						<tr className={isFiltered ? 'not-active' : ''}>
							<th colSpan="5">Ending Balance</th>
							<th className="fixed-width align-right">{ledgerCurrencyFormat(balance.ending.debit)}</th>
							<th className="fixed-width align-right">{ledgerCurrencyFormat(balance.ending.credit)}</th>
						</tr>
					</thead>
				</table>
			) : (
				<div className="no-list-items-container">
					<div>No post items found</div>
				</div>
			)}
		</div>
	);
}

AccountDetailTable.propTypes = {
	balance: PropTypes.object,
	filteredAccounts: PropTypes.array,
	isFiltered: PropTypes.bool,
};

AccountDetailTable.defaultProps = {
	balance: {},
	filteredAccounts: [],
	isFiltered: false,
};

export default AccountDetailTable;
