// Globals
import React, {useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

// Website imports
import styles from '../../../components/FlyoutManageViews/FlyoutManageViews.theme.scss';
import KiCheckbox from '../../../components/KiCheckbox';
import KiSelect from '../../../components/KiSelect';

// Local imports
import {DataExplorerContext} from '../DataExplorerContext';
import {QuickFilterSettingContext} from '../contexts/QuickFilterSettingContext';

export const DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_OPTIONS = [
	{value: 'encumbranceAssetLevel', isDisabled: true, label: 'Encumbrance Asset Level'},
	{value: 'encumbrancePoolSummary', isDisabled: true, label: 'Encumbrance Pool Summary'},
	{value: 'encumbranceStratification', label: 'Encumbrance Stratification'},
];

export const Settings = ({isCopy}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark,
		bookmark: {explorerData},
		actions,
		setBookmark,
		setExplorerData,
	} = dataExplorerContext;

	const quickFilterSettingContext = useContext(QuickFilterSettingContext);
	const {
		getViewTypeDropdown,
		getExplorationTypeDropdown,
		getNameInput,
		getTagsInput,
		getStatementDateInput,
		getDateContextDropdown,
		getStartDateContextDropdown,
		getEndDateContextDropdown,
		getScenarioContextDropdown,
		getFundingVehicleDropdown,
		getPoolDropdown,
		getTimeSeriesColumnSelector,
		getTimeSeriesDisplayPeriodSelector,
		getTimeSeriesPeriodSelector,
	} = quickFilterSettingContext;

	const user = useSelector(state => state.user);

	useEffect(
		() => {
			if (isCopy || !bookmark._id) {
				setBookmark({createdBy: user.userId});
			}
			// sort the view form setting columns so we can index correctly
			const unsortedAssetColumns = explorerData.columns.filter(c => c.columnType === 'asset');
			actions.setColumns(unsortedAssetColumns, 'asset', false, false);
		},
		[bookmark._id]
	);

	return (
		<div className={styles.settingsRoot}>
			<div className={styles.form}>
				{getNameInput()}
				{getTagsInput()}
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">View Type:</span>
				{getViewTypeDropdown()}
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">Exploration Type:</span>
				{getExplorationTypeDropdown()}
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">Date:</span>
				{getStatementDateInput()}
			</div>
			{explorerData.snapshotType !== 'encumbrance' && (
				<div className="sidebar-form-section">
					<KiCheckbox
						name="isDefault"
						checked={explorerData.isFixedDate}
						label="Static Date"
						onChange={val => setExplorerData({isFixedDate: val}, true)}
					/>
				</div>
			)}
			{!explorerData.isFixedDate &&
				explorerData.snapshotType !== 'encumbrance' && (
					<div className="sidebar-form-section">
						<span className="form-instruction">Date Context:</span>
						{getDateContextDropdown()}
					</div>
				)}
			{explorerData.snapshotType === 'encumbrance' && (
				<>
					<div className="sidebar-form-section">
						<span className="form-instruction">Start Date Context:</span>
						{getStartDateContextDropdown()}
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">End Date Context:</span>
						{getEndDateContextDropdown()}
					</div>
				</>
			)}
			{explorerData.tableType === 'timeSeries' && [
				<div className="sidebar-form-section" key={1}>
					{getTimeSeriesColumnSelector()}
				</div>,
				<div className="sidebar-form-section" key={2}>
					{getTimeSeriesDisplayPeriodSelector()}
				</div>,
				<div className="sidebar-form-section" key={3}>
					{getTimeSeriesPeriodSelector()}
				</div>,
			]}

			<div className="sidebar-form-section">
				<span className="form-instruction">Scenario Context:</span>
				{getScenarioContextDropdown()}
			</div>

			<div className="sidebar-form-section">
				<span className="form-instruction">Funding Vehicle:</span>
				{getFundingVehicleDropdown()}
			</div>

			{explorerData.snapshotType !== 'encumbrance' && (
				<div className="sidebar-form-section">
					<span className="form-instruction">Pool:</span>
					{getPoolDropdown()}
				</div>
			)}

			<div hidden={!bookmark.isGlobal || !bookmark.dataTransferViewId} className="sidebar-form-section">
				<span className="form-instruction">Data Transfer View Type:</span>
				<KiSelect
					options={DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_OPTIONS}
					value={DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_OPTIONS.filter(
						x => x.value === bookmark.dataTransferViewId
					)}
					onChange={option => setBookmark({dataTransferViewId: option.value})}
					placeholder="N/A"
					isDisabled={
						true
						// !view.isGlobal ||
						// !this.props.isAdmin ||
						// (view.dataTransferViewId && view.dataTransferViewId !== 'encumbranceStratification')
					}
					isClearable={bookmark.dataTransferViewId === 'encumbranceStratification'}
				/>
			</div>
		</div>
	);
};

Settings.propTypes = {
	isCopy: PropTypes.bool,
};

export default Settings;
