const defaultDebtColumns = [
	{
		_id: 'tranche__trancheName',
		columnName: 'name',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'Name',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__cusip',
		columnName: 'cusip',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'Cusip',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__isin',
		columnName: 'isin',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'ISIN',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__classId',
		columnName: 'classId',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'Class',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__trancheNumber',
		columnName: 'trancheNumber',
		columnType: 'trancheSetup',
		dataType: 'numeric',
		displayName: 'Tranche Number',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche__nameId',
		columnName: 'nameId',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'System Name',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__seniority',
		columnName: 'seniority',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'Seniority',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__payType',
		columnName: 'payType',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'Pay Type',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__originalCoupon',
		columnName: 'originalCoupon',
		columnType: 'trancheSetup',
		dataType: 'numeric',
		displayName: 'Original Coupon',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche__balanceType',
		columnName: 'balanceType',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'Balance Type',
		displayFormat: 'string',
	},
	{
		_id: 'tranche__payDate',
		columnName: 'payDate',
		columnType: 'trancheSetup',
		dataType: 'date_long',
		displayName: 'Pay Date',
		displayFormat: 'yyyy-MM-dd',
	},
	{
		_id: 'tranche__rate',
		columnName: 'rate',
		columnType: 'trancheSetup',
		dataType: 'numeric',
		displayName: 'Rate',
		displayFormat: '9,999.*%',
	},
	{
		_id: 'tranche__margin',
		columnName: 'margin',
		columnType: 'trancheSetup',
		dataType: 'numeric',
		displayName: 'Margin',
		displayFormat: '9,999.*%',
	},
	{
		_id: 'tranche__accrualMethod',
		columnName: 'accrualMethod',
		columnType: 'trancheSetup',
		dataType: 'string',
		displayName: 'Accrual Method',
		displayFormat: 'string',
	},
];

const defaultFeeColumns = [
	{
		_id: 'fee__feeName',
		columnName: 'name',
		columnType: 'feeSetup',
		dataType: 'string',
		displayName: 'Name',
		displayFormat: 'string',
	},
	{
		_id: 'fee__feeNameId',
		columnName: 'nameId',
		columnType: 'feeSetup',
		dataType: 'string',
		displayName: 'System Name',
		displayFormat: 'string',
	},
	{
		_id: 'fee__type',
		columnName: 'type',
		columnType: 'feeSetup',
		dataType: 'string',
		displayName: 'Type',
		displayFormat: 'string',
	},
	{
		_id: 'fee__rateType',
		columnName: 'rateType',
		columnType: 'feeSetup',
		dataType: 'string',
		displayName: 'Deferred Rate Type',
		displayFormat: 'string',
	},
	{
		_id: 'fee__rateAmount',
		columnName: 'rateAmount',
		columnType: 'feeSetup',
		dataType: 'numeric',
		displayName: 'Deferred Rate',
		displayFormat: '9,999.*',
	},
	{
		_id: 'fee__margin',
		columnName: 'margin',
		columnType: 'feeSetup',
		dataType: 'numeric',
		displayName: 'Deferred Margin',
		displayFormat: '9,999.*%',
	},
	{
		_id: 'fee__rateIndex',
		columnName: 'rateIndex',
		columnType: 'feeSetup',
		dataType: 'string',
		displayName: 'Deferred Index',
		displayFormat: 'string',
	},
	{
		_id: 'fee__value',
		columnName: 'value',
		columnType: 'feeSetup',
		dataType: 'numeric',
		displayName: 'Value',
		displayFormat: '9,999.*',
	},
	{
		_id: 'fee__basis',
		columnName: 'basis',
		columnType: 'feeSetup',
		dataType: 'string',
		displayName: 'Basis',
		displayFormat: 'string',
	},
];

const defaultCounterpartyColumns = [
	{
		_id: 'counterparty__legalName',
		columnName: 'legalName',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Name',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__LEI',
		columnName: 'LEI',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'LEI',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__otherName',
		columnName: 'otherName',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Other Name',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__legalAddress',
		columnName: 'legalAddress',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Legal Address',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__headquarterAddress',
		columnName: 'headquarterAddress',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Headquarter Address',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__directParent',
		columnName: 'directParent',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Direct Parent',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__ultimateParent',
		columnName: 'ultimateParent',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Ultimate Parent',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__email',
		columnName: 'email',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Email',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__role',
		columnName: 'role',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Role',
		displayFormat: 'string',
	},
];

const defaultCounterpartyRoleColumns = [
	{
		_id: 'kiCPRole',
		columnName: 'kiCPRole',
		columnType: 'cohort',
		dataType: 'string',
		displayName: 'Role',
		displayFormat: 'string',
		detailedDisplayName: 'Role',
		htmlDisplayName: '<b>Counterparty</b> Role',
	},
	{
		_id: 'counterparty__LEI',
		columnName: 'LEI',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'LEI',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__legalName',
		columnName: 'legalName',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Name',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__otherName',
		columnName: 'otherName',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Other Name',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__legalAddress',
		columnName: 'legalAddress',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Legal Address',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__headquarterAddress',
		columnName: 'headquarterAddress',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Headquarter Address',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__directParent',
		columnName: 'directParent',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Direct Parent',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__ultimateParent',
		columnName: 'ultimateParent',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Ultimate Parent',
		displayFormat: 'string',
	},
	{
		_id: 'counterparty__email',
		columnName: 'email',
		columnType: 'counterpartySetup',
		dataType: 'string',
		displayName: 'Email',
		displayFormat: 'string',
	},
];

const defaultCreditSupportColumns = [
	{
		_id: 'creditSupport__creditSupportName',
		columnName: 'name',
		columnType: 'creditSupportSetup',
		dataType: 'string',
		displayName: 'Name',
		displayFormat: 'string',
	},
	{
		_id: 'creditSupport__nameId',
		columnName: 'nameId',
		columnType: 'creditSupportSetup',
		dataType: 'string',
		displayName: 'System Name',
		displayFormat: 'string',
	},
	{
		_id: 'creditSupport__type',
		columnName: 'supportType',
		columnType: 'creditSupportSetup',
		dataType: 'string',
		displayName: 'Type',
		displayFormat: 'string',
	},
	{
		_id: 'creditSupport__rateType',
		columnName: 'rateType',
		columnType: 'creditSupportSetup',
		dataType: 'string',
		displayName: 'Rate Type',
		displayFormat: 'string',
	},
	{
		_id: 'creditSupport__rateAmount',
		columnName: 'rateAmount',
		columnType: 'creditSupportSetup',
		dataType: 'numeric',
		displayName: 'Rate',
		displayFormat: '9,999.*%',
	},
	{
		_id: 'creditSupport__accrualMethod',
		columnName: 'accrualMethod',
		columnType: 'creditSupportSetup',
		dataType: 'string',
		displayName: 'Accrual Method',
		displayFormat: 'string',
	},
	{
		_id: 'creditSupport__floatingIndex',
		columnName: 'floatingIndex',
		columnType: 'creditSupportSetup',
		dataType: 'string',
		displayName: 'floatingIndex',
		displayFormat: 'string',
	},
	{
		_id: 'creditSupport__margin',
		columnName: 'margin',
		columnType: 'creditSupportSetup',
		dataType: 'numeric',
		displayName: 'Margin',
		displayFormat: '9,999.*%',
	},
	{
		_id: 'creditSupport__insurance',
		columnName: 'insurance',
		columnType: 'creditSupportSetup',
		dataType: 'string',
		displayName: 'Defaulted',
		displayFormat: 'string',
	},
];

const defaultTriggerColumns = [
	{
		_id: 'trigger__triggerName',
		columnName: 'name',
		columnType: 'triggerSetup',
		dataType: 'string',
		displayName: 'Name',
		displayFormat: 'string',
	},
	{
		_id: 'trigger__comparisonType',
		columnName: 'comparisonType',
		columnType: 'triggerSetup',
		dataType: 'string',
		displayName: 'Comparison Operator',
		displayFormat: 'string',
	},
	{
		_id: 'trigger__notes',
		columnName: 'notes',
		columnType: 'triggerSetup',
		dataType: 'string',
		displayName: 'Notes',
		displayFormat: 'string',
	},
];

const defaultWaterfallColumns = [
	{
		displayName: 'Date',
		displayFormat: 'string',
		columnName: 'dt',
		columnType: 'asset',
		_id: 'dt',
		sortOrder: '',
		dataType: 'string',
	},
	{
		displayName: 'Step Number',
		displayFormat: 'string',
		columnName: 'StepID',
		columnType: 'asset',
		_id: 'StepID',
		sortOrder: '',
		dataType: 'string',
	},
	{
		displayName: 'Entity',
		displayFormat: 'string',
		columnName: 'to',
		columnType: 'asset',
		_id: 'to',
		sortOrder: '',
		dataType: 'string',
	},
	{
		displayName: 'Amount Due',
		displayFormat: '9,999.99',
		columnName: 'vd',
		columnType: 'asset',
		_id: 'vd',
		sortOrder: '',
		dataType: 'numeric',
	},
	{
		displayName: 'Amount Paid',
		displayFormat: '9,999.99',
		columnName: 'v',
		columnType: 'asset',
		_id: 'v',
		sortOrder: '',
		dataType: 'numeric',
	},
];

const debtFVSetupCols = [
	{
		_id: 'ff0000000000000000000001',
		columnName: 'legalName',
		columnType: 'fvSetup',
		dataType: 'string',
		displayName: 'Legal Name',
		displayFormat: 'string',
	},
	{
		_id: 'ff0000000000000000000002',
		columnName: 'amountBorrowed',
		columnType: 'fvSetup',
		dataType: 'numeric',
		displayName: 'Actual Amount Borrowed',
		displayFormat: '9,999.99',
	},
	{
		_id: 'ff0000000000000000000003',
		columnName: 'warehouseLimitMin',
		columnType: 'fvSetup',
		dataType: 'numeric',
		displayName: 'Minimum Facility Limit',
		displayFormat: '9,999.99',
	},
	{
		_id: 'ff0000000000000000000004',
		columnName: 'warehouseLimit',
		columnType: 'fvSetup',
		dataType: 'numeric',
		displayName: 'Maximum Facility Limit',
		displayFormat: '9,999.99',
	},
	{
		_id: 'ff0000000000000000000005',
		columnName: 'minRetentionReq',
		columnType: 'fvSetup',
		dataType: 'numeric',
		displayName: 'Minimum Retention Requirement',
		displayFormat: '9,999.99',
	},
	{
		_id: 'ff0000000000000000000006',
		columnName: 'startDate',
		columnType: 'fvSetup',
		dataType: 'date_long',
		displayName: 'Start Date',
		displayFormat: 'yyyy-MM-dd',
	},
	{
		_id: 'ff0000000000000000000007',
		columnName: 'endDate',
		columnType: 'fvSetup',
		dataType: 'date_long',
		displayName: 'End Date',
		displayFormat: 'yyyy-MM-dd',
	},
	{
		_id: 'ff0000000000000000000008',
		columnName: 'trustName',
		columnType: 'fvSetup',
		dataType: 'string',
		displayName: 'Trust Name',
		displayFormat: 'string',
	},
	{
		_id: 'ff0000000000000000000009',
		columnName: 'fvCutoffDate',
		columnType: 'fvSetup',
		dataType: 'date_long',
		displayName: 'Cutoff Date',
		displayFormat: 'yyyy-MM-dd',
	},
];

const debtBorrowingBaseCols = [
	{
		_id: 'bb0000000000000000000001',
		columnName: 'assetsInBreachCount',
		columnType: 'borrowingBase',
		dataType: 'numeric',
		displayName: 'Breach Count',
		displayFormat: '9,999',
	},
	{
		_id: 'bb0000000000000000000002',
		columnName: 'assetsInBreachBalance',
		columnType: 'borrowingBase',
		dataType: 'numeric',
		displayName: 'Breach Balance',
		displayFormat: '9,999.99',
	},
	{
		_id: 'bb0000000000000000000003',
		columnName: 'excessConcentration',
		columnType: 'borrowingBase',
		dataType: 'numeric',
		displayName: 'Excess Concentration',
		displayFormat: '9,999.99',
	},
];

// For waterfall formulas only
const collateralOptions = [
	{
		_id: 'collateral_balance_beg',
		columnName: 'balance_beg',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Beginning Balance',
		detailedDisplayName: 'Beginning Balance',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_balance_end',
		columnName: 'balance_end',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Ending Balance',
		detailedDisplayName: 'Ending Balance',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_net_int',
		columnName: 'net_int',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Interest',
		detailedDisplayName: 'Interest',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_all_prin',
		columnName: 'all_prin',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Principal',
		detailedDisplayName: 'Principal',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_wac',
		columnName: 'wac',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'WAC',
		detailedDisplayName: 'WAC',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_cum_def',
		columnName: 'cum_def',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Cumulative Defaults',
		detailedDisplayName: 'Cumulative Defaults',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_cum_loss',
		columnName: 'cum_loss',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Cumulative Losses',
		detailedDisplayName: 'Cumulative Losses',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_defaults',
		columnName: 'defaults',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Defaults',
		detailedDisplayName: 'Defaults',
		displayFormat: '9,999.99',
	},
	{
		_id: 'collateral_cutoff_balance',
		columnName: 'cutoff_balance',
		columnType: 'collateral',
		dataType: 'numeric',
		displayName: 'Cut-off Balance',
		detailedDisplayName: 'Cut-off Balance',
		displayFormat: '9,999.99',
	},
];

// For waterfall formulas only
const trancheOptions = [
	{
		_id: 'tranche_loss',
		columnName: 'loss',
		columnType: 'tranche',
		dataType: 'numeric',
		displayName: 'Loss',
		detailedDisplayName: 'Loss',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche_balance',
		columnName: 'balance',
		columnType: 'tranche',
		dataType: 'numeric',
		displayName: 'Balance',
		detailedDisplayName: 'Balance',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche_dueint',
		columnName: 'dueint',
		columnType: 'tranche',
		dataType: 'numeric',
		displayName: 'Interest Due',
		detailedDisplayName: 'Interest Due',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche_duearrears',
		columnName: 'duearrears',
		columnType: 'tranche',
		dataType: 'numeric',
		displayName: 'Arrears Due',
		detailedDisplayName: 'Arrears Due',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche_dueintarrears',
		columnName: 'dueintarrears',
		columnType: 'tranche',
		dataType: 'numeric',
		displayName: 'Interest Arrears Due',
		detailedDisplayName: 'Interest Arrears Due',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche_coupon',
		columnName: 'coupon',
		columnType: 'tranche',
		dataType: 'numeric',
		displayName: 'Coupon',
		detailedDisplayName: 'Coupon',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche_margin',
		columnName: 'margin',
		columnType: 'tranche',
		dataType: 'numeric',
		displayName: 'Margin',
		detailedDisplayName: 'Margin',
		displayFormat: '9,999.99',
	},
	{
		_id: 'tranche_dates_final_maturity',
		columnName: 'dates_final_maturity',
		columnType: 'tranche',
		dataType: 'date_long',
		displayName: 'Final Maturity Date',
		detailedDisplayName: 'Final Maturity Date',
		displayFormat: 'yyyy-MM-dd',
	},
];

// For waterfall formulas only
const creditSupportOptions = [
	{
		_id: 'creditSupport_balance',
		columnName: 'balance',
		columnType: 'creditSupport',
		dataType: 'numeric',
		displayName: 'Balance',
		detailedDisplayName: 'Balance',
		displayFormat: '9,999.99',
		typesAllowed: ['Reserve', 'Insurance', 'Liq Fac', 'Collection'],
	},
	{
		_id: 'creditSupport_target',
		columnName: 'target',
		columnType: 'creditSupport',
		dataType: 'numeric',
		displayName: 'Target',
		detailedDisplayName: 'Target',
		displayFormat: '9,999.99',
		typesAllowed: ['Reserve'],
	},
	{
		_id: 'creditSupport_limit',
		columnName: 'limit',
		columnType: 'creditSupport',
		dataType: 'numeric',
		displayName: 'Limit',
		detailedDisplayName: 'Limit',
		displayFormat: '9,999.99',
		typesAllowed: ['Liq Fac'],
	},
	{
		_id: 'creditSupport_deferred',
		columnName: 'deferred',
		columnType: 'creditSupport',
		dataType: 'numeric',
		displayName: 'Deferred',
		detailedDisplayName: 'Deferred',
		displayFormat: '9,999.99',
		typesAllowed: ['Insurance', 'Liq Fac'],
	},
];

// For waterfall formulas only
const feeOptions = [
	{
		_id: 'fee_due',
		columnName: 'due',
		columnType: 'fee',
		dataType: 'numeric',
		displayName: 'Due',
		detailedDisplayName: 'Due',
		displayFormat: '9,999.99',
	},
	{
		_id: 'fee_paid',
		columnName: 'paid',
		columnType: 'fee',
		dataType: 'numeric',
		displayName: 'Paid',
		detailedDisplayName: 'Paid',
		displayFormat: '9,999.99',
	},
	{
		_id: 'fee_deferred',
		columnName: 'deferred',
		columnType: 'fee',
		dataType: 'numeric',
		displayName: 'Deferred',
		detailedDisplayName: 'Deferred',
		displayFormat: '9,999.99',
	},
];

const _paymentReportDetailColumns = [
	{
		_id: 'pa0000000000000000000001',
		columnName: 'period',
		columnType: 'payment',
		dataType: 'numeric',
		defaultValue: '0',
		displayFormat: '9,999',
		displayName: 'Period',
	},
	{
		_id: 'pa0000000000000000000002',
		columnName: 'paymentDate',
		columnType: 'payment',
		dataType: 'date_long',
		defaultValue: '01/01/1900',
		displayFormat: 'longDate',
		displayName: 'Payment Date',
	},
	{
		_id: 'pa0000000000000000000003',
		columnName: 'scheduledPayment',
		columnType: 'payment',
		dataType: 'numeric',
		defaultValue: '0',
		displayFormat: 'number',
		displayName: 'Scheduled Payment',
	},
	{
		_id: 'pa0000000000000000000004',
		columnName: 'delinquencyPayment',
		columnType: 'payment',
		dataType: 'numeric',
		defaultValue: '0',
		displayFormat: 'number',
		displayName: 'Delinquency Payment',
	},
	{
		_id: 'pa0000000000000000000005',
		columnName: 'payaheadPayment',
		columnType: 'payment',
		dataType: 'numeric',
		defaultValue: '0',
		displayFormat: 'number',
		displayName: 'Payahead Payment',
	},
	{
		_id: 'pa0000000000000000000006',
		columnName: 'totalPayment',
		columnType: 'payment',
		dataType: 'numeric',
		defaultValue: '0',
		displayFormat: 'number',
		displayName: 'Aggregate Payment Array',
	},
];

const defaultAggregatePaymentReportColumns = [
	{
		_id: 'pa0000000000000000000000',
		columnName: 'FV',
		columnType: 'payment',
		dataType: 'string',
		defaultValue: '',
		displayFormat: 'string',
		displayName: 'FV',
	},
].concat(_paymentReportDetailColumns);

const defaultPaymentReportColumns = [
	{
		_id: 'pa0000000000000000000000',
		columnName: 'FV',
		columnType: 'payment',
		dataType: 'string',
		defaultValue: '',
		displayFormat: 'string',
		displayName: 'FV',
	},
].concat(_paymentReportDetailColumns);

/*
Fake ID patterns in use
ff0000000000000000000000 - funding vehicle entries
pa0000000000000000000000 - payment array entries
 */

module.exports = {
	defaultDebtColumns,
	defaultCreditSupportColumns,
	defaultFeeColumns,
	defaultCounterpartyColumns,
	defaultCounterpartyRoleColumns,
	defaultTriggerColumns,
	defaultWaterfallColumns,
	debtFVSetupCols,
	debtBorrowingBaseCols,
	collateralOptions,
	trancheOptions,
	creditSupportOptions,
	feeOptions,
	defaultPaymentReportColumns,
	defaultAggregatePaymentReportColumns,
};
