const _defaultsDeep = require('lodash/defaultsDeep');

const baselineConstraints = {
	rowType: {
		presence: {allowEmpty: false}
	},
	fundingVehicleId: {
		presence: {allowEmpty: false}
	},
	name: {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 100,
			message: 'must be between 1 to 100 characters'
		}
	},
	value: {
		presence: {allowEmpty: false}
	}
};

const websiteConstraints = {};

const mongoConstraints = {};

const getConstraints = (options) => {
	if (options && options.isMongo) {
		return _defaultsDeep({}, mongoConstraints, baselineConstraints);
	}
	return _defaultsDeep({}, websiteConstraints, baselineConstraints);
};

module.exports = {
	getConstraints
};
