import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

const NumericOperandPreview = props => {
	const {calculationOperands = [], columnList, selectedSchema} = props;
	if (!calculationOperands.length || !columnList.length) {
		return null;
	}

	const formatColumnDisplayName = (column = {}) => {
		if (column.columnType === 'aggregate') {
			return `${column.displayName} (${column.calculation})`;
		}
		return column.displayName;
	};

	const formulaPreview = calculationOperands.map((operand, idx) => {
		const formulaPreview = [];
		// Add operator to formulaPreview array
		if (!!idx || operand.logic.value !== '+') {
			//if first operand and is a plus, a plus sign is redundant, so don't print
			formulaPreview.push(' ', operand.logic.value, ' ');
		}

		// Push opening parentheses to formulaPreview array
		if (_get(operand, 'openingGroup')) {
			operand.openingGroup.forEach(opening => {
				formulaPreview.push(opening.value);
			});
		}

		if (_get(operand, 'type.value') === 'numeric') {
			formulaPreview.push(_get(operand, 'value'));
		}

		// If the operand is a function, list all column names (when creating a function)
		if (_get(operand, 'functionType')) {
			formulaPreview.push(_get(operand, 'functionType.value', '').toUpperCase());

			operand.value.forEach((value, index) => {
				// Group array of columns
				if (index === 0) {
					formulaPreview.push('(');
				}

				if (_get(value, 'displayName')) {
					formulaPreview.push(formatColumnDisplayName(value));
				}

				if (index !== operand.value.length - 1) {
					formulaPreview.push(', ');
				}

				if (index === operand.value.length - 1) {
					formulaPreview.push(')');
				}
			});
		}

		// Display columnName
		if (_get(operand, 'type.value') === 'column') {
			const foundColumn = columnList.find(
				column =>
					column.columnType === 'debt'
						? column.columnName === operand.value.columnName
						: column._id === operand.value._id
			);

			if (foundColumn) {
				formulaPreview.push(formatColumnDisplayName(foundColumn));
			} else {
				formulaPreview.push('Unknown Column');
			}
		}
		if (_get(operand, 'type.value') === 'subAccountColumn') {
			const match = selectedSchema
				? selectedSchema.schemaColumns.find(column => column.id === operand.value.id)
				: null;
			match ? formulaPreview.push(formatColumnDisplayName(match)) : formulaPreview.push('Unknown Column');
		}

		// Push closing parentheses to formulaPreview array
		if (_get(operand, 'closingGroup')) {
			operand.closingGroup.forEach(closing => {
				formulaPreview.push(closing.value);
			});
		}
		return <div key={idx}>{formulaPreview}</div>;
	});

	return (
		<table className="formula-preview">
			<thead>
				<tr>
					<th>Preview</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{formulaPreview}</td>
				</tr>
			</tbody>
		</table>
	);
};

NumericOperandPreview.propTypes = {
	calculationOperands: PropTypes.array,
	columnList: PropTypes.array,
	selectedSchema: PropTypes.object,
};

NumericOperandPreview.defaultProps = {
	calculationOperands: [],
	columnList: [],
	selectedSchema: null,
};
export default NumericOperandPreview;
