import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiFontIcon from 'components/KiFontIcon';
import KiAppBar from 'components/KiAppBar';
import KiCheckbox from 'components/KiCheckbox';
import {updateAccount, changePassword, generateApiKey} from 'containers/accounts/actions';
import KiConfirmModal from 'components/KiConfirmModal';
import {updateUser} from 'containers/login/actions';
import validators from 'ki-common/validators';
import _set from 'lodash/set';
import _toPairs from 'lodash/toPairs';
import _get from 'lodash/get';
import _without from 'lodash/without';
import _union from 'lodash/union';
import _reduce from 'lodash/reduce';
import './myProfile.scss';
import {USER_GROUP_LIST, USER_GROUPS, userGroupsIntersect} from '../../utils/userGroupsUtil';

const constraints = validators.accounts.getConstraints();
const passwordFormConstraints = {
	currentPassword: {
		presence: {allowEmpty: false},
	},
	newPassword: {
		presence: {allowEmpty: false},
		length: {
			minimum: 8,
			maximum: 50,
			message: 'must be between 8 and 50 characters',
		},
		format: {
			pattern: '^(?=[^0-9])(?=.*[a-z]+.*)(?=.*[A-Z]+.*)(?=.*[0-9]+.*)(.{7,}[^0-9])$',
			message: 'Requires 1 uppercase, 1 lowercase, and 1 number not as first or last character',
		},
	},
	confirmPassword: {
		presence: {allowEmpty: false},
		equality: {
			attribute: 'newPassword',
			message: 'must match the new password',
		},
	},
};

class MyProfile extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		user: PropTypes.object,
		updateAccount: PropTypes.func,
		changePassword: PropTypes.func,
		updateUser: PropTypes.func,
		generateApiKey: PropTypes.func,
	};

	state = {
		formValues: {
			userId: '',
			password: '',
			email: '',
			firstName: '',
			lastName: '',
			groups: [],
			apiKey: '',
		},
		formErrors: {},
		passwordValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		passwordErrors: {},
		isPasswordSubmitDisabled: true,
		showReplaceApiKeyModal: false,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - My Profile`;
		this.setFormValues(this.props.user);
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.user) != JSON.stringify(this.props.user)) {
			this.setFormValues(this.props.user);
		}
	}

	setFormValues = formValues => {
		this.setState({
			formValues: {
				userId: _get(formValues, 'userId', ''),
				email: _get(formValues, 'email', ''),
				firstName: _get(formValues, 'firstName', ''),
				lastName: _get(formValues, 'lastName', ''),
				groups: _get(formValues, 'groups', []),
				apiKey: _get(formValues, 'apiKey', ''),
			},
			showReplaceApiKeyModal: false,
		});
	};

	validateField = (name, value) => {
		const error = validators.validateSingle(value, constraints[name]);
		this.setState({
			formErrors: {...this.state.formErrors, [name]: _get(error, '[0]', '')},
		});
	};

	valueChanged = (name, value) => {
		this.setState(
			{
				formValues: {...this.state.formValues, [name]: value},
			},
			() => {
				this.validateField(name, value);
			}
		);
	};

	onUpdateProfileClick = () => {
		const formValues = {...this.props.user, ...this.state.formValues};
		const error = validators.validate(formValues, constraints);
		if (error) {
			const {formErrors} = this.state;
			_toPairs(error).forEach(([key, value]) => {
				_set(formErrors, key, value[0]);
			});
			this.setState({formErrors: formErrors});
		} else {
			return this.props.updateAccount(formValues).then(res => {
				return this.props.updateUser(res);
			});
		}
	};

	updateSubmitDisabled = () => {
		const errors = validators.validate(this.state.passwordValues, passwordFormConstraints);
		this.setState({isPasswordSubmitDisabled: errors ? true : false});
	};

	// Must validate entire form rather than single element for password to password field comparisons to work
	passwordChanged = (name, value) => {
		const values = {...this.state.passwordValues, [name]: value};
		const errors = validators.validate(values, passwordFormConstraints);
		const passwordValues = {
			...this.state.passwordValues,
			[name]: value,
		};

		this.setState(
			{
				passwordValues,
				passwordErrors: errors,
			},
			() => {
				this.updateSubmitDisabled();
			}
		);
	};

	onChangePasswordClick = () => {
		const {passwordValues} = this.state;

		const error = validators.validate(passwordValues, passwordFormConstraints);
		if (error) {
			const newPasswordErrors = _reduce(
				error,
				(result, value, key) => {
					result[key] = value[0]; //eslint-disable-line prefer-destructuring
					return result;
				},
				{}
			);
			this.setState({
				passwordErrors: newPasswordErrors,
				passwordSubmitDisabled: true,
			});
		} else {
			this.props.changePassword(
				this.props.user._id.toString(),
				passwordValues.currentPassword,
				passwordValues.newPassword
			);
			this.setState({
				passwordValues: {
					currentPassword: '',
					newPassword: '',
					confirmPassword: '',
				},
				passwordErrors: {},
			});
		}
	};

	handleReplaceApiKeyModal = () => {
		return this.props.generateApiKey(this.props.user.userId).then(res => {
			res.token = this.props.user.token;
			return this.props.updateUser(res);
		});
	};

	showReplaceApiKeyModal = () => {
		this.setState({showReplaceApiKeyModal: true});
	};

	hideReplaceApiKeyModal = () => {
		this.setState({showReplaceApiKeyModal: false});
	};

	getUserGroup = groups => {
		if (groups.includes('SystemAdmins')) {
			return 'System Admin';
		} else if (groups.includes('UserAdmins')) {
			return 'User Admin';
		}
		return 'User';
	};

	render() {
		const {user} = this.props;
		const {groups} = this.state.formValues;
		return (
			<div className="profile-panel">
				<KiAppBar title="My Profile" />
				<section className="ki-panel">
					<div className="profile-form">
						<span className="icon-box">
							<KiFontIcon value="account_circle" />
						</span>
						<form>
							<h5 className="user-role">User Role: {this.getUserGroup(groups)}</h5>
							<section>
								<KiInput
									name="userId"
									label="User ID (not editable)"
									type="text"
									disabled={true}
									value={this.state.formValues.userId}
									error={this.state.formErrors.userId}
									onChange={val => this.valueChanged('userId', val)}
								/>
								<KiInput
									name="email"
									label="Email"
									type="text"
									value={this.state.formValues.email}
									error={this.state.formErrors.email}
									onChange={val => this.valueChanged('email', val)}
								/>
								<KiInput
									name="firstName"
									label="First Name"
									type="text"
									value={this.state.formValues.firstName}
									error={this.state.formErrors.firstName}
									onChange={val => this.valueChanged('firstName', val)}
								/>
								<KiInput
									name="lastName"
									label="Last Name"
									type="text"
									value={this.state.formValues.lastName}
									error={this.state.formErrors.lastName}
									onChange={val => this.valueChanged('lastName', val)}
								/>
							</section>
							{userGroupsIntersect(user.groups, [USER_GROUPS.SYSTEM_ADMINS, USER_GROUPS.USER_ADMINS]) && (
								<div>
									<p>Groups:</p>
									<div style={{color: '#de3226'}}>{this.state.formErrors.groups}</div>
									<section className="user-groups">
										{USER_GROUP_LIST.map((group, index) => (
											<KiCheckbox
												key={index}
												checked={groups.includes(group.name)}
												label={group.label}
												onChange={val =>
													this.valueChanged(
														'groups',
														val
															? _union(groups, [group.name])
															: _without(groups, group.name)
													)
												}
											/>
										))}
									</section>
								</div>
							)}
							<KiButton
								raised
								primary
								label="Update Profile"
								className="update-button"
								onMouseUp={this.onUpdateProfileClick}
							/>
						</form>
					</div>
					<br />
					<div className="profile-form">
						<span className="icon-box">
							<KiFontIcon className="spacer" value="account_circle" />
						</span>
						<form>
							<section className="password-inputs">
								<KiInput
									name="currentPassword"
									label="Current Password"
									type="password"
									value={this.state.passwordValues.currentPassword}
									error={
										this.state.passwordValues.currentPassword &&
										_get(this.state.passwordErrors, 'currentPassword[0]', undefined)
									}
									onChange={val => this.passwordChanged('currentPassword', val)}
								/>
								<KiInput
									name="newPassword"
									label="New Password"
									type="password"
									value={this.state.passwordValues.newPassword}
									error={
										this.state.passwordValues.newPassword &&
										_get(this.state.passwordErrors, 'newPassword[0]', undefined)
									}
									onChange={val => this.passwordChanged('newPassword', val)}
								/>
								<KiInput
									name="confirmPassword"
									label="Confirm Password"
									type="password"
									value={this.state.passwordValues.confirmPassword}
									error={
										this.state.passwordValues.confirmPassword &&
										_get(this.state.passwordErrors, 'confirmPassword[0]', undefined)
									}
									onChange={val => this.passwordChanged('confirmPassword', val)}
								/>
							</section>
							<KiButton
								primary
								raised
								label="Change Password"
								className="change-password-button"
								onMouseUp={this.onChangePasswordClick}
								disabled={this.state.isPasswordSubmitDisabled}
							/>
						</form>
					</div>
					<br />
					{user.groups.indexOf('API') > -1 && (
						<div className="profile-form">
							<span className="icon-box">
								<KiFontIcon className="spacer" value="account_circle" />
							</span>
							<form>
								<div>API Key:</div>
								<section className="api-key">
									<KiInput
										readOnly
										name="apiKey"
										type="text"
										className="api-key-input"
										value={this.state.formValues.apiKey}
									/>
								</section>
								<KiButton
									primary
									raised
									label={this.state.formValues.apiKey ? 'Replace API Key' : 'Generate API Key'}
									className="api-button"
									onMouseUp={() => {
										this.state.formValues.apiKey
											? this.showReplaceApiKeyModal()
											: this.handleReplaceApiKeyModal();
									}}
								/>
							</form>
						</div>
					)}
				</section>
				<KiConfirmModal
					header="Replace API Key"
					message="Are you sure you want to replace the API Key?"
					acceptFunc={this.handleReplaceApiKeyModal}
					rejectFunc={this.hideReplaceApiKeyModal}
					acceptLabel="Replace"
					rejectLabel="Cancel"
					active={this.state.showReplaceApiKeyModal}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	user: state.user,
});

export default connect(mapStateToProps, {updateAccount, changePassword, updateUser, generateApiKey})(MyProfile);
