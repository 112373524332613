import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import KiAppBar from '../../../components/KiAppBar';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './LedgerTopBar.scss';
import {fetchLedger} from '../../../api/ledgersApi';
import LedgerActions from './LedgerActions';
import AdminAvailable from '../../../components/Available/AdminAvailable';
import Loader from '../Loader';

function LedgerTopBar({ledgerId, existingLedger, section}) {
	const [ledger, setLedger] = useState(null);
	const linkToExploration = `/ledgers/${ledgerId}/exploration`;
	const isItExplorationPage = linkToExploration === window.location.pathname;
	const kiVersion = useSelector(state => state.app.kiVersion);

	useEffect(() => {
		document.title = `${kiVersion} - Ledgers`;
	}, []);

	useEffect(
		() => {
			// Ledger can be requested by id or given from the other component if it's requested there
			if (existingLedger === undefined) {
				fetchLedger(ledgerId).then(ledger => {
					setLedger(ledger);
				});
			} else {
				setLedger(existingLedger);
			}
		},
		[ledgerId, existingLedger, section]
	);

	return (
		<KiAppBar className="top-bar ledger-top-bar">
			<div className="top-bar-breadcrumb">
				<Loader isActive={!(ledger && ledger.name)}>
					<h1>
						<Link to={'/ledgers'}>Ledgers</Link>
					</h1>
					<h1>
						&nbsp;&gt;&nbsp;
						{isItExplorationPage ? (
							ledger && ledger.name
						) : (
							<Link to={linkToExploration}>{ledger && ledger.name}</Link>
						)}
					</h1>
					{section && <h1> &gt; {section}</h1>}
				</Loader>
			</div>
			<div>
				{ledger && ledger._id ? (
					<AdminAvailable>
						<LedgerActions id={ledger._id} />
					</AdminAvailable>
				) : null}
			</div>
		</KiAppBar>
	);
}

LedgerTopBar.propTypes = {
	ledgerId: PropTypes.string,
	existingLedger: PropTypes.object,
	section: PropTypes.string,
};

export default LedgerTopBar;
