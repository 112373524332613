import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {fetchAccounts} from './actions';
import AccountList from './components/AccountList';
import './accounts.scss';

class Accounts extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		accounts: PropTypes.array,
		fetchAccounts: PropTypes.func,
		user: PropTypes.object,
	};

	constructor(props) {
		super(props);
		document.title = `${this.props.app.kiVersion} - Account Management`;
	}

	componentDidMount() {
		this.props.fetchAccounts();
	}

	render() {
		const {accounts = [], user} = this.props;
		if (!user.groups.includes('SystemAdmins') && !user.groups.includes('UserAdmins')) {
			return <div>You are not authorized to view this page</div>;
		}
		return (
			<article id="Accounts" data-mf-replace="**REMOVED**">
				<section style={{display: 'flex', flexFlow: 'column nowrap', flex: 1}}>
					<AccountList accounts={accounts} />
				</section>
			</article>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	accounts: state.accounts.data,
	user: state.user,
});

export default connect(mapStateToProps, {fetchAccounts})(Accounts);
