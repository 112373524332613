import PropTypes from 'prop-types';
import React from 'react';
import Select from 'components/KiSelect';
import {getSortAscFuncByField, getSortDescFuncByField} from 'ki-common/utils/sorters';
import './TriggerFunctionSelector.scss';

/*
options must be passed in the following format
var options = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' },
    {
    	label: 'group one',
    	options: [
    		{ value: 'three', label: 'Three' },
    		{ value: 'four', label: 'Four' },
		]
    }
];
 */

export const TriggerFunctionSelector = props => {
	const {options = [], sortOrder, startingValue = null, disabled, ...otherProps} = props;
	switch (sortOrder) {
		case 'asc':
			options.sort(getSortAscFuncByField('label'));
			break;
		case 'desc':
			options.sort(getSortDescFuncByField('label'));
			options.reverse();
			break;
		default:
	}

	return (
		<Select
			classNamePrefix="aut-select"
			// The flatMap converts sub groups into a direct list so that we can return the reference to the
			// correct option that should be selected.
			value={options.flatMap(x => x.options || x).filter(opt => opt.value === startingValue)}
			className="selector"
			isDisabled={!!disabled}
			isClearable={false}
			options={options}
			placeholder="Select..."
			onChange={option => {
				props.targetFunction(option.value);
			}}
			{...otherProps}
		/>
	);
};

TriggerFunctionSelector.defaultProps = {
	options: [],
};

TriggerFunctionSelector.propTypes = {
	options: PropTypes.array.isRequired,
	targetFunction: PropTypes.func.isRequired,
	startingValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	sortOrder: PropTypes.string,
	disabled: PropTypes.bool,
};

export default TriggerFunctionSelector;
