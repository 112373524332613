import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import AdminAvailable from '../../../components/Available/AdminAvailable';
import KiCheckbox from '../../../components/KiCheckbox';
import LedgerActions from './LedgerActions';
import {formatDate} from '../../../utils/dateUtils';

const LedgerTableItem = ({item, checked, onChecked}) => {
	const {name, _id, closeDate, latestEffectiveDate, latestPostingDate} = item;
	return (
		<tr className="scenario-list-item">
			<td className="select-item">
				<AdminAvailable>
					<KiCheckbox className="select-item-checkbox" checked={checked} onChange={() => onChecked(item)} />
				</AdminAvailable>
			</td>
			<td>
				<Link to={`/ledgers/${_id}/exploration`} title={item ? name : ''}>
					{item && name}
				</Link>
			</td>
			<td>{closeDate && formatDate(closeDate)}</td>
			<td>{latestEffectiveDate && formatDate(latestEffectiveDate)}</td>
			<td>{latestPostingDate && formatDate(latestPostingDate)}</td>
			<AdminAvailable>
				{_id && (
					<td className="align-right">
						<LedgerActions id={_id} />
					</td>
				)}
			</AdminAvailable>
		</tr>
	);
};

LedgerTableItem.propTypes = {
	item: PropTypes.object.isRequired,
	checked: PropTypes.bool.isRequired,
	onChecked: PropTypes.func.isRequired,
};

export default LedgerTableItem;
