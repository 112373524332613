import React from 'react';
import PropTypes from 'prop-types';
import KiIconButton from '../../../../components/KiIconButton';

const FormCloseSection = ({onClose}) => {
	return (
		<div>
			<KiIconButton className="close-btn" icon="close" onClick={onClose} />
		</div>
	);
};

FormCloseSection.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default FormCloseSection;
