// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import _toLower from 'lodash/toLower';
import {Link} from 'react-router-dom';
// Externals
import {KiInput, KiList, KiIconButton, KiAppBar, KiProgressBar} from 'components';
import {showSnackbar} from 'state/actions/Snackbar';
// Relative
import './datasetList.scss';
import {fetchDatasetList, setSelectedDataset} from './actions';
import DatasetListItem from './components/DatasetListItem';

export class DatasetList extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		datasetList: PropTypes.array,
		fetchDatasetList: PropTypes.func.isRequired,
		setSelectedDataset: PropTypes.func,
		history: PropTypes.object,
		isLoading: PropTypes.bool,
		user: PropTypes.object,
	};
	static defaultProps = {
		isLoading: false,
	};

	constructor(props) {
		super(props);
		document.title = `${this.props.app.kiVersion} - Datasets`;
	}

	state = {
		filteredDatasetList: this.props.datasetList,
		searchTerm: '',
	};

	componentDidMount() {
		this.props.fetchDatasetList(true);
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps.datasetList, this.props.datasetList)) {
			this.setState({filteredDatasetList: this.props.datasetList});
		}
	}

	handleEligibilityAnalysisClick = (event, dataset) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.setSelectedDataset(dataset);
		this.props.history.push(`/fundingAnalysis/${dataset.datasetId}`);
	};

	// Filter datasets, case insensitive
	onSearchTermChange = value => {
		const filteredDatasetList = this.props.datasetList.filter(dataset =>
			_includes(_toLower(dataset.name), _toLower(value))
		);
		this.setState({
			filteredDatasetList,
			searchTerm: value,
		});
	};

	getLatestSubmission = dataset => {
		if (!dataset.snapshots.length) {
			return 'Commit Failed';
		}
		return dataset.snapshots
			.map(snapshot => snapshot.snapshotDate)
			.sort()
			.slice(-1);
	};

	showNoItems = () => !this.props.isLoading && !this.props.datasetList?.length;

	render() {
		return (
			<section className="dataset-list">
				<KiAppBar title="Datasets" className="top-bar">
					{this.props.user.groups.find(u => u === 'SystemAdmins') && (
						<Link to={'/datasets/create'}>
							<KiIconButton icon="add_circle" inverse title="Click to create a new dataset." />
						</Link>
					)}
				</KiAppBar>
				<div className={`ki-panel ${this.showNoItems() ? 'no-list-items-container' : ''}`}>
					{this.props.isLoading && <KiProgressBar />}
					<KiInput
						className="search-input"
						onChange={this.onSearchTermChange}
						label="Dataset Search"
						value={this.state.searchTerm}
					/>
					{this.showNoItems() && <div>Upload submissions to view their datasets here</div>}
					<KiList className="dataset-items">
						{this.state.filteredDatasetList.map(dataset => (
							<DatasetListItem
								key={dataset.datasetId}
								handleEligibilityAnalysisClick={e => this.handleEligibilityAnalysisClick(e, dataset)}
								latestSubmission={this.getLatestSubmission(dataset)}
								datasetId={dataset.datasetId}
								snapshots={dataset.snapshots}
								name={dataset.name}
								category={dataset.category}
								user={this.props.user}
							/>
						))}
					</KiList>
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	isLoading: state.datasetList.isLoading,
	datasetList: state.datasetList.data,
	user: state.user,
});

export default connect(
	mapStateToProps,
	{fetchDatasetList, setSelectedDataset, showSnackbar}
)(DatasetList);
