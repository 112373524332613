import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import moment from 'moment';

const DATE_FORMAT_YMD = 'YYYY-MM-DD';
const DATE_FORMAT_YMDHMS = 'YYYY-MM-DD HH:mm:ss';
const DATEPICKER_FORMAT_YMD = 'yyyy-MM-dd';

const formatDate = date => moment(date).format(DATE_FORMAT_YMD);
const formatDateWithHours = date => moment(date).format(DATE_FORMAT_YMDHMS);

const isDateValid = date => moment(date).isValid();

const compareDates = (laterDate, earlierDate) => {
	return new Date(laterDate).getTime() >= new Date(earlierDate).getTime();
};

const getToday = () => {
	return dateToShortDate(new Date());
};

const getTomorrowDate = (today = new Date()) => {
	return new Date(moment(today).add(1, 'days'));
};

const getFirstDayOfMonth = (date = new Date()) => {
	date = new Date(date);
	return dateToShortDate(new Date(date.getFullYear(), date.getMonth(), 1));
};

const getLastDayOfMonth = (date = new Date()) => {
	date = new Date(date);
	return dateToShortDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
};

const getFirstDayBeforeMonths = (subtractedMonths, date = new Date()) => {
	date = new Date(getFirstDayOfMonth(moment(new Date(date)).subtract(subtractedMonths, 'months')));
	return dateToShortDate(date);
};

export {
	DATE_FORMAT_YMD,
	DATEPICKER_FORMAT_YMD,
	getToday,
	getTomorrowDate,
	getFirstDayOfMonth,
	getLastDayOfMonth,
	getFirstDayBeforeMonths,
	formatDate,
	formatDateWithHours,
	isDateValid,
	compareDates,
};
