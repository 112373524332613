import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/KiSelect';
import options from 'ki-common/options';
import KiIconButton from 'components/KiIconButton';
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import _get from 'lodash/get';
import {getAllColumns} from 'ki-common/utils/calcColumns';

class NumericOperandForm extends React.Component {
	static propTypes = {
		setParentState: PropTypes.func,
		state: PropTypes.object,
		noNewColumn: PropTypes.bool,
		allowedOperandTypes: PropTypes.array,
		columnToEdit: PropTypes.object,
		includeSubAccountOperand: PropTypes.bool,
	};

	static defaultProps = {
		allowedOperandTypes: ['column', 'numeric', 'function'],
	};

	setConstant = val => {
		this.props.setParentState({
			constant: val,
			constantError:
				!/^[+-]?((\.\d+)|(\d+(\.\d+)?))$/.test(val) && val !== '' ? 'Only numeric values allowed' : null,
		});
	};

	addOperand = () => {
		let {calculationOperands} = this.props.state;
		const {
			logic,
			operandType,
			operandValue,
			constant,
			functionType,
			functionValues,
			editingOperandId,
			openingGroup,
			closingGroup,
		} = this.props.state;

		const operand = constant.length ? constant : operandValue || functionValues;

		const newOperand = {
			id: editingOperandId !== null ? editingOperandId : calculationOperands.length,
			logic,
			openingGroup,
			closingGroup,
			type: operandType,
			functionValues: [],
			value: operand,
			functionType,
		};

		let editOperandIndex;
		if (editingOperandId !== null) {
			editOperandIndex = calculationOperands.findIndex(operand => operand.id === editingOperandId);
			calculationOperands[editOperandIndex] = newOperand;
		} else {
			calculationOperands = [...calculationOperands, newOperand];
		}
		return this.props.setParentState({
			calculationOperands,
			operandValue: null,
			constant: '',
			functionType: null,
			functionValues: [],
			editingOperandId: null,
		});
	};

	formatColumnDisplayName = (column = {}) => {
		if (column.columnType === 'aggregate') {
			return `${column.displayName} (${column.calculation})`;
		}
		return column.displayName;
	};

	isAddOperandDisabled = operandType => {
		switch (operandType) {
			case 'column':
				if (_get(this.props.state.operandValue, '_id', 'newColumn') === 'newColumn') {
					return true;
				}
				return !this.props.state.operandValue;
			case 'numeric':
				return !this.props.state.constant;
			case 'function':
				return !_get(this.props, 'state.functionValues', []).length;
			case 'subAccountColumn':
				return !this.props.state.operandValue;
			default:
				return true;
		}
	};

	getPermittedColumns = () => {
		const {columnToEdit} = this.props;
		if (columnToEdit && columnToEdit._id) {
			return this.props.state.numericColumns.filter(col => {
				let allColsInFormula;
				if (col.formula) {
					// get an array of the columns contained in the formula
					allColsInFormula = getAllColumns(col.formula);
					if (allColsInFormula.includes(columnToEdit._id)) {
						return false;
					}
				}
				if (col.formulas) {
					let matchFound = false;
					col.formulas.forEach(formula => {
						allColsInFormula = getAllColumns(formula.formula);
						if (allColsInFormula.includes(columnToEdit._id)) {
							matchFound = true;
						}
					});
					if (matchFound) {
						return false;
					}
				}
				return true;
			});
		}

		return this.props.state.numericColumns;
	};
	getOperandValues() {
		const operandToAdd = {
			label: 'Child Column',
			value: 'subAccountColumn',
		};
		const operants = options.comparisonValueTypes.filter(type =>
			this.props.allowedOperandTypes.includes(type.value)
		);
		if (this.props.includeSubAccountOperand) {
			operants.push(operandToAdd);
		}
		return operants;
	}
	render() {
		let columnOptions = this.getPermittedColumns();
		const childColumns = this.props.state.associatedSchemaColumns;

		// If we have an operand value equal to newColumn, we show MiniCreator. This component should not render teh option to add
		// a new column.
		if (!this.props.noNewColumn) {
			columnOptions = [
				{
					_id: 'newColumn',
					displayName: 'Create New...',
					className: 'option-bold-italic',
				},
				...columnOptions,
			];
		}
		return (
			<section className="operand-selection form-instruction-box">
				{/* logic type, operand type and operand value */}
				<div className="form-row">
					<div className="flex-1">
						<span className="form-instruction">Logic:</span>
						<Select
							classNamePrefix="aut-select"
							className="standard-pad-right"
							value={this.props.state.logic}
							isClearable={false}
							options={
								!this.props.state.calculationOperands.length || this.props.state.editingOperandId === 0
									? options.algebraicTypes.filter(
											op => op.value !== '*' && op.value !== '/' && op.value !== '^'
									  )
									: options.algebraicTypes
							}
							onChange={selected => this.props.setParentState({logic: selected})}
						/>
					</div>
					<div className="flex-6">
						<span className="form-instruction">Operand:</span>
						<Select
							classNamePrefix="aut-select"
							value={this.props.state.operandType}
							isClearable={false}
							options={this.getOperandValues()}
							onChange={val =>
								this.props.setParentState({
									operandType: val,
									operandValue: null,
									constant: '',
									functionValues: [],
									functionType: null,
								})
							}
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="flex-6">
						{(() => {
							switch (_get(this.props, 'state.operandType.value')) {
								case 'column':
									return (
										<Select
											classNamePrefix="aut-select"
											value={this.props.state.operandValue}
											options={columnOptions}
											getOptionLabel={option => this.formatColumnDisplayName(option)}
											getOptionValue={option => option._id || option.columnName}
											onChange={val =>
												this.props.setParentState({
													operandValue: val,
												})
											}
										/>
									);
								case 'numeric':
									return (
										<KiInput
											label="Numeric Constant"
											value={this.props.state.constant}
											onChange={val => this.setConstant(val)}
											error={this.props.state.constantError}
											isNumberMasked={true}
											hint="Percents should be entered as decimals"
										/>
									);
								case 'function':
									return (
										<Select
											classNamePrefix="aut-select"
											value={this.props.state.functionType}
											options={options.comparisonFunctionTypes}
											getOptionValue={option => option.id}
											onChange={val =>
												this.props.setParentState({
													functionType: val,
												})
											}
										/>
									);
								case 'subAccountColumn':
									return (
										<Select
											classNamePrefix="aut-select"
											options={childColumns}
											value={this.props.state.operandValue}
											getOptionLabel={option => option.displayName}
											getOptionValue={option => option.id}
											onChange={val =>
												this.props.setParentState({
													operandValue: val,
												})
											}
										/>
									);
							}
						})()}
					</div>
					{_get(this.props, 'state.operandType.value') !== 'function' ? (
						<div>
							<KiButton
								className="add-btn"
								onClick={this.addOperand}
								disabled={this.isAddOperandDisabled(_get(this.props, 'state.operandType.value'))}
								icon={isNaN(parseInt(this.props.state.editingOperandId)) ? 'add' : 'save'}
							/>
						</div>
					) : null}
				</div>

				{_get(this.props, 'state.operandType.value') === 'function' && this.props.state.functionType ? (
					<div className="form-row">
						<div className="flex-6">
							<Select
								classNamePrefix="aut-select"
								isMulti={true}
								value={this.props.state.functionValues}
								options={this.props.state.numericColumns || []}
								isClearable={false}
								getOptionLabel={option => this.formatColumnDisplayName(option)}
								getOptionValue={option => option._id}
								onChange={values => this.props.setParentState({functionValues: values})}
							/>
						</div>

						<KiButton
							className="add-btn"
							onClick={this.addOperand}
							disabled={this.props.state.functionValues && this.props.state.functionValues.length === 0}
							icon={isNaN(parseInt(this.props.state.editingOperandId)) ? 'add' : 'save'}
						/>

						<KiIconButton
							className="cancel-btn"
							icon="cancel"
							onClick={() =>
								this.props.setParentState({
									functionType: null,
									functionValues: [],
									editingOperandId: null,
								})
							}
						/>
					</div>
				) : null}
			</section>
		);
	}
}

export default NumericOperandForm;
