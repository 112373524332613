import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import KiIconButton from '../../../../components/KiIconButton';
import stylesForm from '../common/Form.theme.scss';
import {connect} from 'react-redux';
import KiButton from '../../../../components/KiButton';
import KiInput from '../../../../components/KiInput';
import {useMergedState} from '../../../../utils/customHooks';
import KiDnDList from '../../../../components/KiDnDList';
import {moveInArray, removeFromArray, updateInArray} from '../../../../utils/arrayUtils';
import {getNotionalPool, getNotionalPools, getVectors, saveNotionalPool} from '../../../../api/waterfallApi';
import {showSnackbar} from 'state/actions/Snackbar';
import NameForm from '../common/NameForm';
import FormCloseSection from '../common/FormCloseSection';
import NotionalPoolSectionForm from './NotionalPoolSectionForm';
import {getIndexColumnsFromService} from '../../../../api/columnServiceApi';
/* eslint-disable react/display-name */

const NotionalPoolForm = ({datasets, editItemId, onClose, onSave = onClose, showSnackbar, showClose = true}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [notionalPools, setNotionalPools] = useState([]);
	const [vectors, setVectors] = useState([]);
	const [indexColumns, setIndexColumns] = useState([]);
	const [formData, mergeFormData, setFormData] = useMergedState({
		name: '',
		description: '',
		groups: [],
	});
	const [validationErrors, mergeValidationErrors, setValidationErrors] = useMergedState({
		name: '',
		groups: [],
	});

	useEffect(() => {
		setIsLoading(true);
		Promise.all([getNotionalPools(), getVectors()]).then(([notionalPoolsData, vectorData]) => {
			setNotionalPools(notionalPoolsData);
			setVectors(vectorData);
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		//We are picking the first dataset since all ABS Suite rates types are present in every Ki dataset (it doesn't matter)
		if (datasets.length > 0) {
			getIndexColumnsFromService(datasets[0]._id).then(results => {
				setIndexColumns(results.map(opt => ({label: opt.name, value: opt._id})));
			});
		}
	}, []);

	const resetValidation = item => {
		setValidationErrors({
			name: null,
			groups: Array(item.groups ? item.groups.length : 0).fill(null),
		});
	};

	useEffect(
		() => {
			if (editItemId) {
				getNotionalPool(editItemId).then(item => {
					mergeFormData(item);
					resetValidation(item);
				});
			}
		},
		[editItemId]
	);

	const onSectionsReordered = (items, fromIndex, toIndex) => {
		setFormData(data => ({...data, groups: moveInArray(data.groups, fromIndex, toIndex)}));
		setValidationErrors(errors => ({
			...errors,
			groups: moveInArray(errors.groups, fromIndex, toIndex),
		}));
	};

	const onSectionDelete = index => {
		setFormData(data => ({...data, groups: removeFromArray(data.groups, index)}));
		setValidationErrors(errors => ({
			...errors,
			groups: removeFromArray(errors.groups, index),
		}));
	};

	const onSectionChange = (amortizationGroup, index, isValid) => {
		setFormData(data => ({
			...data,
			groups: updateInArray(data.groups, amortizationGroup, index),
		}));
		setValidationErrors(errors => ({
			...errors,
			groups: updateInArray(errors.groups, isValid, index),
		}));
	};

	const renderSectionItem = (item, index) => {
		return (
			<NotionalPoolSectionForm
				group={item}
				index={index}
				vectors={vectors}
				indexColumns={indexColumns}
				onSectionChange={onSectionChange}
				onDelete={onSectionDelete}
			/>
		);
	};

	const addNewSection = () => {
		setFormData(data => ({
			...data,
			groups: [
				...(data.groups || []),
				{
					term: '',
					rateType: '',
					servicing: '',
					basis: '',
					notionalAmountVectorId: '',
				},
			],
		}));
		setValidationErrors(errors => ({...errors, groups: [...errors.groups, false]}));
	};

	const isFormValid = Object.keys(validationErrors)
		.map(key => {
			const error = validationErrors[key];
			if (error === null) {
				return true;
			} else if (Array.isArray(error)) {
				return error.length !== 0 && !error.includes(false);
			}

			return false;
		})
		.reduce((acc, cur) => acc && cur);

	const onSubmit = () => {
		setIsLoading(true);
		saveNotionalPool(formData).then(saved => {
			showSnackbar('Notional pool saved successfully');
			onSave(saved);
		});
	};

	return (
		<section className={stylesForm.section}>
			{showClose && <FormCloseSection onClose={onClose} />}
			<form className={stylesForm.form}>
				<section className={stylesForm.scrollable}>
					<NameForm
						label="Notional Pool"
						items={notionalPools}
						mergeFormData={mergeFormData}
						mergeValidationErrors={mergeValidationErrors}
						nameFormData={formData.name}
						nameValidationErrors={validationErrors.name}
						editItemId={editItemId}
					/>
					<div className="sidebar-form-section">
						<span className="form-instruction">Description</span>
						<KiInput
							className="substring-size-input"
							value={formData.description}
							onChange={description => mergeFormData({description})}
							error={validationErrors.description}
						/>
					</div>
					<div className="sidebar-form-section">
						<span className="form-instruction">Groups</span>
						{formData.groups && (
							<KiDnDList
								items={formData.groups.map((amortizationGroup, index) => ({
									content: amortizationGroup,
									id: index,
								}))}
								onReorder={onSectionsReordered}
								contentRenderFunc={renderSectionItem}
								isCombineEnabled={false}
								extendItemClass={stylesForm.dndItem}
							/>
						)}
						<div className={stylesForm.mainAddItem}>
							<KiIconButton
								icon="add_circle"
								id="addItemButton"
								className={stylesForm.addItemButton}
								onClick={addNewSection}
								title="Click to add Group"
							/>
							<label htmlFor="addItemButton">Add Group</label>
						</div>
					</div>
				</section>
				<div className="inline-column-form-buttons">
					<KiButton flat primary onClick={onClose}>
						Cancel
					</KiButton>
					<KiButton disabled={isLoading || !isFormValid} raised primary onClick={onSubmit}>
						Save
					</KiButton>
				</div>
			</form>
		</section>
	);
};

NotionalPoolForm.propTypes = {
	editItemId: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	showClose: PropTypes.bool,
	onSave: PropTypes.func,
	showSnackbar: PropTypes.func,
	datasets: PropTypes.array,
};

const mapDispatchToProps = {
	showSnackbar,
};

const mapStateToProps = state => ({
	datasets: state.datasetList.data,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotionalPoolForm);
