// Globals
import React, {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

// Website imports
import FilterList from 'components/FlyoutManageViews/components/ViewForms/FilterList';
import {KiTabs, KiTab, KiButton} from 'components';
import {closeContextSidebarPanel} from 'state/actions/App';

// Local imports
import Settings from './Settings';
import Cohort from './Cohort/Cohort';
import SummaryColumns from './SummaryColumns/SummaryColumns';
import AssetColumns from './AssetColumns/AssetColumns';
import {DataExplorerContext} from '../DataExplorerContext';

export const ManageExplorationViewForms = ({isCopy, tabIndex}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark: {explorerData},
		setExplorerData,
		applyChanges,
		hasChanges,
	} = dataExplorerContext;
	const dispatch = useDispatch();

	const {selected: dataset, columnList} = useSelector(state => state.datasetList);

	const [formErrors, setFormErrors] = useState({});
	// Used for components that are not rewritten
	const [isSubcomponentFormValid, setIsSubcomponentFormValid] = useState(true);

	const hasAnyError = Object.entries(formErrors)
		.map(([, value]) => !!value)
		.includes(true);

	return (
		<div className="context-sidebar-form">
			<KiTabs index={tabIndex}>
				<KiTab title="Settings">
					<Settings isCopy={isCopy} />
				</KiTab>
				<KiTab title="Cohort">
					<Cohort
						dataset={dataset}
						columnList={columnList}
						setFormErrors={setFormErrors}
						formErrors={formErrors}
					/>
				</KiTab>
				<KiTab title="Summary">
					<SummaryColumns setFormIsValid={setIsSubcomponentFormValid} />
				</KiTab>
				<KiTab title="Asset">
					<AssetColumns setFormIsValid={setIsSubcomponentFormValid} />
				</KiTab>
				<KiTab title="Filters">
					<FilterList
						filters={explorerData.filters}
						allFilters={dataset.filters}
						updateCallBack={filters => setExplorerData({filters}, true)}
						setFormHasChanges={() => void 0}
					/>
				</KiTab>
			</KiTabs>
			<section className="inline-column-form-buttons">
				<KiButton flat primary onClick={() => dispatch(closeContextSidebarPanel())} label="Close" />
				<React.Fragment>
					{/* TODO can selected view be not active? is there edit mode? */}
					<KiButton
						raised
						primary
						disabled={hasAnyError || !hasChanges || !isSubcomponentFormValid}
						onClick={applyChanges}
						label="Apply"
					/>
				</React.Fragment>
			</section>
		</div>
	);
};

ManageExplorationViewForms.propTypes = {
	isCopy: PropTypes.bool,
	tabIndex: PropTypes.number,
};

export default ManageExplorationViewForms;
