// Globals
import React, {useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
// Project imports

// Local imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import styles from '../fundingAnalysis.theme.scss';
import FundingModelDetailContents from './FundingModelDetailContents';
/**
 * [FundingAnalysis description]
 */
export const WizardStepThree = () => {
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const allFundingVehicles = useSelector(state => state.fundingVehicleList.data);

	const isFVUnencumbered = fv => fv.isUnencumbered || fv.fvName === 'Unencumbered';
	// On Mount
	useEffect(() => {
		const {fvSources, fvTargets} = fundingAnalysisContext;
		if (
			fvSources.length &&
			!fvTargets.find(isFVUnencumbered) &&
			(fvSources.length > 1 || !fvSources.find(isFVUnencumbered))
		) {
			//TODO use flag here instead of name
			let fvToPush = _.cloneDeep(allFundingVehicles.find(fv => fv.isUnencumbered));
			fvToPush = {
				fvId: fvToPush._id,
				fvName: fvToPush.name,
				isUnencumbered: true,
			};
			const maxPriority = _.maxBy(fvTargets, o => o.priority).priority;
			fvToPush.priority = maxPriority + 1;
			fvTargets.push(fvToPush); //push unEncumbered fv
			fundingAnalysisContext.setModelItem('targetFvs', fvTargets);
		}
	}, []);
	return (
		<div className={styles.root}>
			<FundingModelDetailContents dragEnabled={false} />
		</div>
	);
};

export default WizardStepThree;
