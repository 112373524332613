import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from './FunctionMappingFilters.theme.scss';
import bfData from 'ki-common/businessFunctions';
import _omit from 'lodash/omit';

export class FunctionMappingFilters extends Component {
	static propTypes = {
		datasets: PropTypes.array.isRequired,
		setDataset: PropTypes.func.isRequired,
		setFunction: PropTypes.func,
		setVariable: PropTypes.func,
	};

	static defaultProps = {
		datasets: [],
	};

	render() {
		const {setDataset, setFunction, setVariable} = this.props;
		return (
			<div className={styles.filters}>
				<div className={styles.themeControl}>
					<span className="theme-label">Dataset:</span>
					<Select
						classNamePrefix="aut-select"
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
						isClearable={false}
						options={this.props.datasets}
						onChange={val => {
							setDataset(val);
						}}
					/>
				</div>
				<div className={styles.themeControl}>
					<span className="theme-label">Business Functions:</span>
					<Select
						classNamePrefix="aut-select"
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option.opType}
						isClearable={true}
						options={bfData.businessFunctionList}
						onChange={val => {
							setFunction(val);
						}}
					/>
				</div>
				<div className={styles.themeControl}>
					<span className="theme-label">Business Variables:</span>
					<Select
						classNamePrefix="aut-select"
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option.name}
						isClearable={true}
						options={bfData.businessVariableList.map(v => _omit(v, ['options']))}
						onChange={val => {
							setVariable(val);
						}}
					/>
				</div>
			</div>
		);
	}
}

export default FunctionMappingFilters;
