// Globals
import React from 'react';
import PropTypes from 'prop-types';

// Project imports
import KiIconButton from 'components/KiButton';

// Local imports
import styles from './KiFormulaBuilderComponent.theme.scss';

function AddOperatorValueBlockButton({onClick}) {
	return <KiIconButton raised primary className={styles.addOperatorValueBlock} icon="add" onClick={onClick} />;
}

AddOperatorValueBlockButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};

AddOperatorValueBlockButton.defaultProps = {
	onChange: () => {
		return false;
	},
};

export default AddOperatorValueBlockButton;
