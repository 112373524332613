import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';

export class WizardStep extends Component {
	static propTypes = {
		children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	};

	render() {
		return <Fragment>{this.props.children}</Fragment>;
	}
}
export default WizardStep;
