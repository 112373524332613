// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';

// Externals
import CohortSelector from 'components/CohortSelector';
import GroupBySelector from 'components/GroupBySelector';
import KiRadioButton from 'components/KiRadioButton';
import KiRadioGroup from 'components/KiRadioGroup';
import options from 'ki-common/options';
import {KiInput} from 'components/KiInput';
import {getDefaultColumnIds} from 'ki-common/utils/explorerUtils';

// Relative
import CohortBands from './CohortBands';
import CohortBandsDate from './CohortBandsDate';

import {updateBandsStep, updateValue, addFormError, removeFormError} from './actions';

export class Cohort extends Component {
	static propTypes = {
		view: PropTypes.object,
		dataset: PropTypes.object,
		columnList: PropTypes.array,
		updateValue: PropTypes.func.isRequired,
		addFormError: PropTypes.func.isRequired,
		removeFormError: PropTypes.func.isRequired,
		setFormHasChanges: PropTypes.func,
	};

	static defaultProps = {
		dataset: {},
		view: {},
		columnList: [],
	};

	handleSetCohortDisplayName = displayName => {
		const newCohortColumn = {...this.props.view.columns[0], displayName};
		this.props.updateValue({name: 'cohortColumn', value: newCohortColumn});

		if (displayName === '') {
			this.props.addFormError('cohortDisplayName', 'Required');
		} else {
			this.props.removeFormError('cohortDisplayName');
		}
		this.props.setFormHasChanges();
	};

	handleSetCohortColumn = columnId => {
		const newCohortColumn = this.props.columnList.find(c => c._id === columnId);
		this.props.updateValue({name: 'cohortColumn', value: newCohortColumn});
		this.props.updateValue({name: 'granularity', value: ''});
		this.props.updateValue({name: 'dateRangeStart', value: ''});
		this.props.updateValue({name: 'dateRangeEnd', value: ''});
		this.props.setFormHasChanges();
	};

	handleSetGroupBy = columnId => {
		const defaultColumnIds = getDefaultColumnIds(
			this.props.dataset.snapshots,
			this.props.columnList,
			this.props.view.snapshotDate
		);
		if (!columnId || defaultColumnIds.assetColumnId === columnId) {
			this.props.updateValue({name: 'groupBy', value: ''});
			this.handleSetCohortColumn(defaultColumnIds.balanceCohortColumnId);
		} else {
			this.props.updateValue({name: 'groupBy', value: columnId});
			this.handleSetCohortColumn(defaultColumnIds.balanceAggregateColumnId);
		}
		this.props.setFormHasChanges();
	};

	handleSetCohortDisplayFormat = option => {
		const newCohortColumn = {...this.props.view.columns[0], displayFormat: option.value};
		this.props.updateValue({name: 'cohortColumn', value: newCohortColumn});
		this.props.setFormHasChanges();
	};

	determineFormatType = dataType => {
		switch (dataType) {
			case 'numeric':
				return options.numericFormats;
			case 'date_long':
				return options.dateFormats;
			case 'date_short':
				return options.shortDateFormats;
			default:
				return options.textFormats;
		}
	};

	render() {
		const {view} = this.props;
		const cohortDataType = view.columns[0].dataType;

		const formatTypeOptions = this.determineFormatType(cohortDataType);

		return (
			<div className="cohort-form">
				<div className="sidebar-form-section">
					<span className="form-instruction">Cohort</span>
					<CohortSelector
						selectedColumn={view.columns[0]._id}
						columns={this.props.columnList}
						targetFunction={this.handleSetCohortColumn}
						isGroupBy={!!view.groupBy}
					/>
				</div>
				<div className="sidebar-form-section">
					<span className="form-instruction">Group By</span>
					<GroupBySelector
						selectedColumn={view.groupBy}
						columns={this.props.columnList}
						targetFunction={this.handleSetGroupBy}
						assetCohortColumnId={
							getDefaultColumnIds(this.props.dataset.snapshots, this.props.columnList, view.snapshotDate)
								.assetCohortColumnId
						}
					/>
				</div>
				<div className="sidebar-form-section">
					<span className="form-instruction">Format</span>
					<Select
						classNamePrefix="aut-select"
						value={
							cohortDataType === 'string'
								? formatTypeOptions.find(opt => opt.value === options.textFormats[0])
								: formatTypeOptions.find(opt => opt.value === view.columns[0].displayFormat)
						}
						isClearable={false}
						options={formatTypeOptions}
						onChange={this.handleSetCohortDisplayFormat}
					/>
				</div>
				<div className="sidebar-form-section">
					<KiInput
						name="cohortDisplayName"
						label="Display Name"
						type="text"
						value={view.columns[0].displayName}
						error={view.formErrors.cohortDisplayName}
						onChange={this.handleSetCohortDisplayName}
					/>
				</div>

				{cohortDataType === 'numeric' && (
					<div className="sidebar-form-section">
						<CohortBands setFormHasChanges={this.props.setFormHasChanges} />
					</div>
				)}

				{(cohortDataType === 'date_long' || cohortDataType === 'date_short') && (
					<CohortBandsDate setFormHasChanges={this.props.setFormHasChanges} />
				)}

				{cohortDataType === 'numeric' && (
					<div className="sidebar-form-section">
						<span className="form-instruction">Interval Boundaries</span>
						<KiRadioGroup
							value={view.startInclusive ? 'start' : 'end'}
							onChange={value => {
								this.props.updateValue({
									name: 'startInclusive',
									value: value === 'start' ? true : false,
								});
								this.props.setFormHasChanges();
							}}
						>
							<KiRadioButton label="Start Inclusive" value="start" />
							<KiRadioButton label="End Inclusive" value="end" />
						</KiRadioGroup>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	view: state.viewForm,
});

const mapDispatchToProps = () => ({
	addFormError,
	removeFormError,
	updateBandsStep,
	updateValue,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(Cohort);
