import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import KiButton from 'components/KiButton';
import styles from './FlyoutConfirmPanel.theme.scss';
import _uniqueId from 'lodash/uniqueId';

export const FlyoutConfirmPanel = props => {
	const {
		header = 'Confirm Choice',
		message = 'Are you sure?',
		acceptFunc = () => null,
		rejectFunc = () => null,
		acceptLabel = 'Accept',
		rejectLabel = 'Cancel',
		hideAccept = false,
		reasonList = [],
	} = props;

	return (
		<div className={classNames('flyout-confirm-panel', styles.main)}>
			<p className={classNames('flyout-panel-title', styles.header)}>{header}</p>
			<p>{message instanceof Function ? message() : message}</p>
			{!!reasonList.length && (
				<ul className={styles.reasonList}>
					{reasonList.map(r => {
						return (
							<li key={_uniqueId()}>
								<div className={styles.sectionTitle}>{r.displayName}</div>
								{r.items.map((item, idx) => {
									return (
										<span className={styles.sectionItem} key={_uniqueId()}>{`${item.name}${
											r.items.length - 1 === idx ? '' : ', '
										}`}</span>
									);
								})}
							</li>
						);
					})}
				</ul>
			)}
			<div className={styles.buttonRow}>
				{!!rejectLabel && <KiButton className={styles.rejectButton} label={rejectLabel} onClick={rejectFunc} />}
				{!hideAccept && <KiButton className={styles.acceptButton} label={acceptLabel} onClick={acceptFunc} />}
			</div>
		</div>
	);
};

FlyoutConfirmPanel.propTypes = {
	header: PropTypes.string,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
	acceptFunc: PropTypes.func,
	rejectFunc: PropTypes.func,
	acceptLabel: PropTypes.string,
	rejectLabel: PropTypes.string,
	hideAccept: PropTypes.bool,
	reasonList: PropTypes.array,
};

export default FlyoutConfirmPanel;
