import PropTypes from 'prop-types';
import React from 'react';
import NumericForm from './NumericForm';
import NumericVariableForm from './NumericVariableForm';
import TextForm from './TextForm';
import DateForm from './DateForm';
import ParentDataForm from './ParentDataForm';
import ConditionalForm from './ConditionalForm';
import CumulativeForm from './CumulativeForm';
import ChildDataForm from './ChildDataForm';
import DebtForm from './DebtForm';
const _get = require('lodash/get');

const getAggregationOptions = dataType => {
	switch (dataType) {
		case 'numeric':
			return [
				{
					value: 'SUM',
					label: 'SUM',
				},
				{
					value: 'MIN',
					label: 'MIN',
				},
				{
					value: 'MAX',
					label: 'MAX',
				},
				{
					value: 'AVG',
					label: 'AVG',
				},
			];
		case 'string':
			return [
				{
					value: 'FIRST_VALID',
					label: 'First Valid',
				},
				{
					value: 'CONCATENATE',
					label: 'Concatenate',
				},
			];
		default:
			// date
			return [
				{
					value: 'FIRST_VALID',
					label: 'First Valid',
				},
				{
					value: 'MIN',
					label: 'MIN',
				},
				{
					value: 'MAX',
					label: 'MAX',
				},
			];
	}
};

const AssetFormDialog = props => {
	const isAdmin = props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
	switch (props.selectedValue) {
		case 'numeric':
			if (props.columnType === 'debtCalculation') {
				return (
					<DebtForm
						isConditional={false}
						isWaterfall={_get(props.formParams, 'isWaterfall', false)}
						isAdmin={isAdmin}
						columnToEdit={props.columnToEdit}
						columnList={props.columnList}
						existingTags={props.existingTags}
						fundingVehicles={props.fundingVehicles}
						datasetId={props.datasetId}
						submitMethod={props.saveCalcColumn}
						closeForm={props.closeForm}
						columns={props.columns}
					/>
				);
			}
			return (
				<NumericForm
					active={props.active}
					submitMethod={props.saveCalcColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
					noNewColumn={props.noNewColumn}
					getSchemaList={props.getSchemaList} //eslint-disable-line
					schemaList={props.schemaList}
					getAggregationOptions={props.getAggregationOptions}
					dataset={props.dataset}
				/>
			);
		case 'numericVariable':
			return (
				<NumericVariableForm
					submitMethod={props.saveCalcColumn}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					isAdmin={isAdmin}
					dataset={props.dataset}
					existingTags={props.existingTags}
					columnList={props.columnList}
				/>
			);
		case 'text':
			return (
				<TextForm
					active={props.active}
					submitMethod={props.saveCalcColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
				/>
			);
		case 'date': {
			if (props.columnType === 'debtCalculation') {
				return (
					<DebtForm
						isDate={true}
						isConditional={false}
						isWaterfall={_get(props.formParams, 'isWaterfall', false)}
						isAdmin={isAdmin}
						columnToEdit={props.columnToEdit}
						columnList={props.columnList.filter(
							c =>
								['asset', 'fvDate'].includes(c.columnType) &&
								['date_long', 'date_short'].includes(c.dataType)
						)}
						existingTags={props.existingTags}
						fundingVehicles={props.fundingVehicles}
						datasetId={props.datasetId}
						submitMethod={props.saveCalcColumn}
						closeForm={props.closeForm}
						columns={props.columns}
					/>
				);
			}
			return (
				<DateForm
					active={props.active}
					submitMethod={props.saveCalcColumn}
					columnList={props.columnList.filter(
						col => col.columnType === 'asset' && ['date_long', 'date_short'].includes(col.dataType)
					)}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
				/>
			);
		}
		case 'conditional':
			if (props.columnType === 'debtCalculation') {
				return (
					<DebtForm
						isConditional={true}
						isWaterfall={_get(props.formParams, 'isWaterfall', false)}
						isAdmin={isAdmin}
						columnToEdit={props.columnToEdit}
						columnList={props.columnList}
						existingTags={props.existingTags}
						fundingVehicles={props.fundingVehicles}
						datasetId={props.datasetId}
						submitMethod={props.saveCalcColumn}
						closeForm={props.closeForm}
						columns={props.columns}
					/>
				);
			}
			return (
				<ConditionalForm
					active={props.active}
					submitMethod={props.saveCalcColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
					datasetId={props.datasetId}
					getAggregationOptions={getAggregationOptions}
					schemaList={props.schemaList}
				/>
			);
		case 'cumulative':
			return (
				<CumulativeForm
					active={props.active}
					submitMethod={props.saveCalcColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
				/>
			);
		case 'parent_data':
			return (
				<ParentDataForm
					active={props.active}
					datasetId={props.datasetId}
					submitMethod={props.saveCalcColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
				/>
			);
		case 'subAccountColumn':
			return (
				<ChildDataForm
					active={props.active}
					datasetId={props.datasetId}
					submitMethod={props.saveCalcColumn}
					columnList={props.columnList}
					closeForm={props.closeForm}
					columnToEdit={props.columnToEdit}
					mode={props.mode}
					existingTags={props.existingTags}
					isAdmin={isAdmin}
					reloadView={props.reloadView}
					schemaList={props.schemaList}
					getAggregationOptions={props.getAggregationOptions}
					dataset={props.dataset}
				/>
			);
		default:
			return null;
	}
};

AssetFormDialog.defaultProps = {
	fundingVehicles: [],
	selectedValue: 'numeric',
	schemaList: [],
	columns: [],
};

AssetFormDialog.propTypes = {
	selectedValue: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	saveCalcColumn: PropTypes.func.isRequired,
	columnList: PropTypes.array.isRequired,
	schemaList: PropTypes.array.isRequired,
	closeForm: PropTypes.func.isRequired,
	columnToEdit: PropTypes.object,
	mode: PropTypes.string,
	user: PropTypes.object,
	datasetId: PropTypes.string,
	reloadView: PropTypes.func,
	existingTags: PropTypes.array,
	noNewColumn: PropTypes.any,
	getAggregationOptions: PropTypes.func,
	dataset: PropTypes.object,
	columnType: PropTypes.string,
	fundingVehicles: PropTypes.array,
	formParams: PropTypes.object,
	columns: PropTypes.array,
};

export default AssetFormDialog;
