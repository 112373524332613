// Globals
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import _ from 'lodash';
import Select from 'react-select';
import cx from 'classnames';
import {useDispatch} from 'react-redux';

// Project imports
import {generateObjectId} from 'ki-common/utils/constraintUtil';

// Website imports
import {useMergedState} from 'utils/customHooks';
import {getColumnsFromService, getMappedColumnsForDataset, getEligibleColumnIds} from 'api/columnServiceApi';
import {KiButton, KiCheckbox, KiModal, KiInput, KiDnDList, KiConfirmModal} from 'components';
import ConstraintForm from 'components/ConstraintForm';
import {showSnackbar} from 'state/actions/Snackbar';

// Local imports
import styles from './constraintsModalStyles.theme.scss';

const ConstraintListItem = props => {
	const classnames = cx(styles.constraintListItem, {
		[`${styles.constraintListItemSelected}`]: !!props.isActive,
		[`${styles.constraintListItemReadOnly}`]: !!props.isReadOnly,
	});
	return (
		<div className={classnames} onMouseUp={() => props.onEditConstraint(props.constraint)}>
			<div>{props.index + 1}. </div>
			<KiCheckbox
				checked={!props.constraint.isDisabled}
				label={''}
				disabled={props.isReadOnly}
				onChange={val => {
					const enabledConstraints = props.allConstraints.map(c => {
						if (c._id === props.constraint._id) {
							return {...c, isDisabled: !val};
						}
						return c;
					});
					props.setAllConstraints(enabledConstraints);
					props.setFundingVehicle(props.fundingVehicle);
				}}
			/>
			<div className={styles.constraintName}>{props.constraint.name}</div>
		</div>
	);
};
ConstraintListItem.propTypes = {
	index: PropTypes.number,
	constraint: PropTypes.object,
	allConstraints: PropTypes.arrayOf(PropTypes.object),
	onEditConstraint: PropTypes.func,
	setFundingVehicle: PropTypes.func,
	setAllConstraints: PropTypes.func,
	fundingVehicle: PropTypes.any,
	isReadOnly: PropTypes.bool,
	isActive: PropTypes.bool,
};
ConstraintListItem.defaultProps = {
	allConstraints: [],
	constraint: {},
	isReadOnly: false,
	isActive: false,
};

export const ConstraintsModal = props => {
	const {
		isActive,
		setModalActive,
		fundingVehicles,
		curConstraintGroup,
		allConstraintGroups,
		fundingModel,
		isReadOnly,
		onSaveFunc,
	} = props;
	// Browser State
	const {datasetId} = useParams();
	const dispatch = useDispatch();

	// Redux State
	//const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));

	// Local State
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [fundingVehicle, setFundingVehicle] = useState(fundingVehicles[0]);
	const [allConstraints, setAllConstraints] = useState([]);
	const [fvConstraints, setFvConstraints] = useState([]);
	const [saveConfirmationDialogActive, setSaveConfirmationDialogActive] = useState(false);
	const [columnState, setColumnState] = useMergedState({
		areLoading: false,
		allColumns: [],
		eligibleColumns: [],
	});
	const selectAllChkBoxRef = useRef();
	const [isGroupBy, setIsGroupBy] = useState(false);
	const [activeConstraint, setActiveConstraint] = useState({});

	const calculateObjectiveOptions = async () => {
		const mappedCols = await getMappedColumnsForDataset(datasetId);
		const {assetColumnId: assetId, _id: cohortId} = mappedCols.assetCol;

		if (fundingModel.groupBy && fundingModel.groupBy !== assetId && fundingModel.groupBy !== cohortId) {
			setIsGroupBy(true);
		} else {
			setIsGroupBy(false);
		}

		const allColumns = await getColumnsFromService(datasetId, {
			sources: {
				includeAssetColumns: true,
				includeAssetCalculations: true,
				includeBusinessFunctions: true,
				includeHistoricalColumns: true,
				includeDateColumns: true,
				includeAggregateColumns: true,
			},
			filters: {
				includeSyntheticColumns: false,
			},
		});

		const eligibleColumns = await getEligibleColumnIds(datasetId);

		setColumnState({
			areLoading: false,
			allColumns,
			eligibleColumns,
		});
	};

	useEffect(
		() => {
			const onModelLoad = async () => {
				if (isActive) {
					setColumnState({
						areLoading: true,
						allColumns: [],
						eligibleColumns: [],
					});
					await calculateObjectiveOptions();
				} else {
					setColumnState({
						areLoading: false,
						allColumns: [],
						eligibleColumns: [],
					});
				}
			};
			onModelLoad();

			setNameError('');
			setName(curConstraintGroup.constraintGroupName);
			setAllConstraints(_.cloneDeep(curConstraintGroup.constraints));
			setFundingVehicle(fundingVehicles[0]);
		},
		[isActive]
	);

	useEffect(
		() => {
			if (fundingVehicle) {
				const currentFvConstraints = _.cloneDeep(allConstraints || []).filter(c => {
					if (c.fundingVehicleId !== fundingVehicle.fvId) return false;
					switch (c.constraintType) {
						case 'both':
							return true;
						case 'repurchase':
							return fundingVehicle.fvType === (fundingModel.isBorrowingBase ? 'target' : 'source');
						case 'funding':
							return fundingVehicle.fvType === (fundingModel.isBorrowingBase ? 'source' : 'target');
						default:
							return false;
					}
				});
				setFvConstraints(currentFvConstraints);
			}
		},
		[fundingVehicle, allConstraints]
	);

	// Load the selected constraint for edit
	const onEditConstraint = constraint => {
		setActiveConstraint(constraint);
	};

	// Change the selected constraint to a new constraint
	const clearConstraintForm = () => {
		setActiveConstraint({});
	};

	const onDeleteConstraint = constraintId => {
		const enabledConstraints = _.cloneDeep(allConstraints);
		const idx = enabledConstraints.findIndex(c => c._id === constraintId);
		enabledConstraints.splice(idx, 1);
		setAllConstraints(enabledConstraints);
		clearConstraintForm();
	};

	const onSaveModal = () => {
		const ac = _.cloneDeep(allConstraints).map(c => {
			c.ignore = c.isDisabled;
			return c;
		});
		const groupToSave = {
			constraintGroupName: name?.trim(),
			fundingModelId: fundingModel._id,
			constraints: ac,
			isFVDefined: false,
		};
		if (curConstraintGroup._id) {
			groupToSave._id = curConstraintGroup._id;
		}
		return onSaveFunc(groupToSave).catch(error => {
			const opts = {
				msg: `Failed to Save "${groupToSave.constraintGroupName}" Constraint Group. Reason: ${error.message ||
					'Unknown'}`,
				className: styles.snackbarError,
			};
			dispatch(showSnackbar(opts));
		});
	};
	const handleSelectAllToggle = checked => {
		setAllConstraints(allConstraints.map(constraint => ({...constraint, isDisabled: !checked})));
		setFundingVehicle(fundingVehicle);
	};

	const onSaveNewConstraint = constraint => {
		const newConstraint = _.cloneDeep(constraint);
		const existingAllConstraints = _.cloneDeep(allConstraints);

		if (newConstraint._id) {
			newConstraint.ignore = newConstraint.isDisabled;
			const idx = existingAllConstraints.findIndex(c => c._id === newConstraint._id);
			existingAllConstraints[idx] = newConstraint;
		} else {
			newConstraint._id = generateObjectId();
			newConstraint.isDisabled = false;
			newConstraint.ignore = false;
			existingAllConstraints.push(newConstraint);
		}

		setAllConstraints(existingAllConstraints);
		setActiveConstraint({});
	};
	useEffect(
		() => {
			// set indeterminate if not all constraints have the same value for isDisabled.
			// this can't be done via html, only DOM manipulation
			if (selectAllChkBoxRef?.current?.inputNode) {
				selectAllChkBoxRef.current.inputNode.indeterminate = !fvConstraints.every(
					c => c.isDisabled === fvConstraints?.[0].isDisabled
				);
			}
		},
		[fvConstraints]
	);

	return (
		<KiModal
			active={isActive}
			actions={[
				{
					label: 'Cancel',
					onClick: () => setModalActive(false),
				},
				{
					label: 'Save Constraint Group',
					disabled: !name || !!nameError || isReadOnly || columnState.areLoading,
					onClick: () => (curConstraintGroup?._id ? setSaveConfirmationDialogActive(true) : onSaveModal()),
				},
			]}
			bodyClassName={styles.modalBody}
			onClose={() => setModalActive(false)}
			header={`${isReadOnly ? '' : 'Configure '}Constraints for: ${fundingModel.name}`}
		>
			<div className={styles.root}>
				{/*Left Column Panel*/}
				<section className={styles.leftColumn}>
					<div className={styles.selectWrapper}>
						<KiInput
							disabled={isReadOnly}
							type="text"
							name="name"
							autoFocus
							label="Constraint Group Name"
							value={name}
							onChange={val => {
								if (
									allConstraintGroups
										.filter(cg => cg._id !== curConstraintGroup._id)
										.find(cg => cg.constraintGroupName === val)
								) {
									setNameError('Name must be unique');
								} else {
									setNameError('');
								}
								setName(val);
							}}
							error={nameError}
						/>
					</div>
					<div className={styles.selectWrapper}>
						<span className="theme-label">Scenario Funding Vehicles</span>
						<Select
							classNamePrefix="aut-select"
							value={fundingVehicle}
							isClearable={false}
							options={fundingVehicles}
							onChange={val => {
								clearConstraintForm();
								setFundingVehicle(val);
							}}
							getOptionLabel={option => option.fvName}
							getOptionValue={option => option.fvId}
						/>
					</div>
					<div className={styles.constraintList}>
						<div className={styles.constraintListHeader}>
							<div className={styles.constraintListHeaderLeft}>
								{!!fvConstraints.length && <div style={{marginRight: '1rem'}}>#&nbsp;</div>}
								<KiCheckbox
									style={{visibility: fvConstraints.length ? 'visible' : 'hidden'}}
									ref={selectAllChkBoxRef}
									label={fvConstraints.length ? 'Select All' : ''}
									labelClassName={styles.selectAllLabel}
									disabled={isReadOnly}
									checked={fvConstraints.every(c => !c.isDisabled)}
									onChange={handleSelectAllToggle}
								/>
							</div>
							<KiButton
								mini
								primary
								disabled={isReadOnly}
								label="Add Constraint"
								onMouseUp={() => clearConstraintForm()}
							/>
						</div>
						<hr />
						<div className={styles.exclusionsAndConstraintsWrapper}>
							<h6 className={styles.constraintListSectionTitle}>Exclusions</h6>
							{!fvConstraints.filter(c => c.rowType === 'ccExclude').length && <div>None</div>}
							<KiDnDList
								items={fvConstraints.filter(c => c.rowType === 'ccExclude').map(fvConstraint => ({
									content: fvConstraint,
									id: fvConstraint._id,
									isDragDisabled: !!isReadOnly,
								}))}
								onReorder={items =>
									setAllConstraints([
										...items.map(item => item.content),
										...fvConstraints.filter(c => c.rowType !== 'ccExclude'),
									])
								}
								extendItemClass={styles.dndItem}
								contentRenderFunc={(item, index) => (
									<ConstraintListItem
										allConstraints={allConstraints}
										setAllConstraints={setAllConstraints}
										setFundingVehicle={setFundingVehicle}
										onEditConstraint={onEditConstraint}
										fundingVehicle={fundingVehicle}
										isReadOnly={isReadOnly}
										isActive={item._id === activeConstraint?._id}
										constraint={item}
										index={index}
									/>
								)}
							/>
							<hr />
							<h6 className={styles.constraintListSectionTitle}>Compliance</h6>
							{!fvConstraints.filter(c => c.rowType !== 'ccExclude').length && <div>None</div>}
							<KiDnDList
								items={fvConstraints.filter(c => c.rowType !== 'ccExclude').map(fvConstraint => ({
									content: fvConstraint,
									id: fvConstraint._id,
									isDragDisabled: !!isReadOnly,
								}))}
								onReorder={items =>
									setAllConstraints([
										...fvConstraints.filter(c => c.rowType === 'ccExclude'),
										...items.map(item => item.content),
									])
								}
								extendItemClass={styles.dndItem}
								contentRenderFunc={(item, index) => (
									<ConstraintListItem
										allConstraints={allConstraints}
										setAllConstraints={setAllConstraints}
										setFundingVehicle={setFundingVehicle}
										onEditConstraint={onEditConstraint}
										fundingVehicle={fundingVehicle}
										isReadOnly={isReadOnly}
										isActive={item._id === activeConstraint?._id}
										constraint={item}
										index={index + fvConstraints.filter(c => c.rowType === 'ccExclude').length}
									/>
								)}
							/>
						</div>
					</div>
				</section>
				{/*Right Column Panel*/}
				<section className={styles.rightColumn}>
					<ConstraintForm
						constraintData={activeConstraint}
						fundingVehicleId={fundingVehicle?.fvId}
						fvConstraints={fvConstraints}
						allColumns={columnState.allColumns}
						eligibleColumns={columnState.eligibleColumns}
						onSaveMethod={onSaveNewConstraint}
						onDeleteMethod={onDeleteConstraint}
						isReadOnly={isReadOnly}
						isGroupBy={isGroupBy}
						isFundingAnalysisConstraint={true}
					/>
				</section>
			</div>
			<KiConfirmModal
				message="Are you certain you wish to modify this Constraint Group?"
				active={saveConfirmationDialogActive}
				acceptFunc={onSaveModal}
				acceptLabel="Save"
				rejectFunc={() => setSaveConfirmationDialogActive(false)}
			/>
		</KiModal>
	);
};

ConstraintsModal.propTypes = {
	isActive: PropTypes.bool,
	setModalActive: PropTypes.func,
	curConstraintGroup: PropTypes.object,
	allConstraintGroups: PropTypes.array,
	fundingVehicles: PropTypes.array,
	fundingModel: PropTypes.object.isRequired,
	isReadOnly: PropTypes.bool,
	onSaveFunc: PropTypes.func,
};

ConstraintsModal.defaultProps = {
	isActive: false,
	curConstraintGroup: {
		constraintGroupName: '',
		constraints: [],
		isFVDefined: false,
	},
	allConstraintGroups: [],
};

export default ConstraintsModal;
