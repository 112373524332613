// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import KiCheckbox from 'components/KiCheckbox';
import {KiSelect} from 'components/KiSelect';
import KiIconButton from 'components/KiIconButton';
// Externals
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiDnDList from 'components/KiDnDList';
import KiConfirmModal from 'components/KiConfirmModal';

import '../../index.scss';

export class ModelForm extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		dataset: PropTypes.object,
		fundingVehicle: PropTypes.object,
		submitMethod: PropTypes.func.isRequired,
		modelToEdit: PropTypes.object,
		closeForm: PropTypes.func,
		addTrack: PropTypes.func,
		addTrigger: PropTypes.func,
		tracks: PropTypes.array.isRequired,
		modelsList: PropTypes.array.isRequired,
		isDefault: PropTypes.bool,
		triggers: PropTypes.array.isRequired,
		isReadOnly: PropTypes.bool,
		// isAdmin: PropTypes.bool,
	};

	static defaultProps = {};

	state = {
		name: '',
		nameError: null,
		isDefault: false,
		trackToAdd: {},
		tracks: [],
		trackToDelete: null,
		isDeleteConfirmActive: false,
	};

	componentDidMount() {
		const {modelToEdit} = this.props;
		if (!_isEmpty(modelToEdit)) {
			this.setState({
				name: modelToEdit.name,
				isDefault: modelToEdit.isDefault,
				tracks: modelToEdit.tracks,
			});
		} else {
			this.setState({
				isDefault: this.props.isDefault,
			});
		}
	}

	isDuplicateNameError = () => {
		if (_get(this, 'props.modelToEdit.name') === this.state.name) {
			return;
		}
		return this.props.modelsList.find(model => _get(model, 'name').trim() === this.state.name.trim());
	};

	setName = val => {
		this.setState({
			name: val,
			nameError: val.trim().length ? null : "Name can't be blank",
		});
	};

	updateTrackToAdd = (type, value) => {
		const trackToAdd = {
			...this.state.trackToAdd,
			[type]: value,
		};
		this.setState({trackToAdd: trackToAdd});
	};

	onDefaultTrackChange = (value, index) => {
		const tracks = [...this.state.tracks];
		if (value) {
			tracks.forEach(track => {
				track.isDefault = false;
			});
		}
		tracks[index].isDefault = value;
		tracks[index].triggerId = null;
		this.orderTracks(tracks);
	};

	onAddedTrackChange = (value, index) => {
		const tracks = [...this.state.tracks];
		tracks[index]._id = value;
		this.setState({tracks: tracks});
	};

	addTrack = () => {
		const tracks = [...this.state.tracks];
		const trackToAdd = {...this.state.trackToAdd};
		if (trackToAdd.isDefault) {
			tracks.forEach(track => {
				track.isDefault = false;
			});
		} else {
			trackToAdd.isDefault = false;
		}
		trackToAdd.blocks = [];
		tracks.push(trackToAdd);
		this.setState({
			tracks: tracks,
			trackToAdd: {},
		});
		this.orderTracks(tracks);
	};

	deleteTrackWarning = index => {
		this.setState({
			isDeleteConfirmActive: true,
			trackToDelete: index,
		});
	};

	onDeleteTrack = () => {
		const index = this.state.trackToDelete;
		const tracks = [...this.state.tracks];
		tracks.splice(index, 1);
		this.setState({
			tracks: tracks,
			isDeleteConfirmActive: false,
			trackToDelete: null,
		});
	};

	onSubmit = () => {
		const {modelToEdit} = this.props;
		if (this.isDuplicateNameError()) {
			this.setState({
				nameError: 'Name must be unique',
			});
			return;
		}
		this.setState({nameError: null});
		const modelForSaving = {
			name: this.state.name,
			createdBy: this.props.user.userId,
			lastUpdated: new Date(),
			datasetId: this.props.dataset.datasetId,
			fundingVehicleId: this.props.fundingVehicle._id,
			isDefault: this.state.isDefault,
			tracks: this.state.tracks,
		};
		if (modelToEdit._id) {
			modelForSaving._id = modelToEdit._id;
		}
		this.props.submitMethod(modelForSaving);
		this.props.closeForm();
	};

	onCancel = () => {
		this.props.closeForm();
	};

	onTracsReorder = items => {
		const tracks = items.map(item => {
			return item.track;
		});

		this.orderTracks(tracks);
	};

	orderTracks = unorderedTracks => {
		const tracks = unorderedTracks.filter(track => {
			return !track.isDefault;
		});

		const defaltTrack = unorderedTracks.find(track => {
			return track.isDefault;
		});

		if (defaltTrack) {
			tracks.push(defaltTrack);
		}

		this.setState({
			tracks: tracks,
		});
	};

	onAddedTriggerChange = (value, index) => {
		const tracks = [...this.state.tracks];
		tracks[index].triggerId = value._id;
		this.setState({tracks: tracks});
	};

	getTrackToDeleteName = () => {
		if (this.state.tracks.length && this.state.trackToDelete !== null) {
			return this.state.tracks[this.state.trackToDelete || 0].name;
		}
	};

	availableTriggers = triggers => {
		const items = triggers.filter(
			trigger => !this.state.tracks.filter(track => track.triggerId === trigger._id).length > 0
		);
		items.unshift({custom: true, text: 'Add New', onClick: this.props.addTrigger});
		items.unshift({displayName: 'Select...', _id: ''});
		return items;
	};

	trackOptions = () => {
		const items = this.props.tracks.filter(t => !this.state.tracks.map(t => t._id).includes(t._id));
		items.unshift({custom: true, text: 'Add New', onClick: this.props.addTrack});
		return items;
	};

	render() {
		// const {isAdmin} = this.props;
		// const {nameError} = this.state;

		const triggers = this.props.triggers;
		const hasDefaultModel =
			this.props.modelsList.find(item => item.isDefault && item._id !== this.props.modelToEdit._id) ||
			this.state.isDefault;
		const hasDefaultTrack = this.state.tracks.filter(item => item.isDefault).length > 0;
		const hasModelName = this.state.name.trim().length > 0;
		const isValid =
			hasDefaultModel &&
			hasDefaultTrack &&
			hasModelName &&
			!this.state.tracks.find(t => !t.isDefault && !t.triggerId);
		return (
			<section className="new-model-form" style={{marginTop: '2rem'}}>
				<div className="new-model-form-name">
					<KiInput
						className="new-model-name"
						name="modelName"
						label="Model Name"
						type="text"
						value={this.state.name || this.props.modelToEdit.name}
						error={this.state.nameError}
						onChange={name => this.setState({name: name})}
					/>
					<KiCheckbox
						label="Default Model"
						checked={this.state.isDefault}
						onChange={checked => this.setState({isDefault: checked})}
					/>
				</div>
				<div className="new-model-form-track">
					<label>Select Track:</label>
					<KiSelect
						className="track-selector"
						isDisabled={false}
						options={this.trackOptions()}
						getOptionLabel={option => option.name}
						getOptionValue={option => option._id}
						value={this.props.tracks.find(t => t._id === this.state.trackToAdd._id) || null}
						onChange={val => this.updateTrackToAdd('_id', val._id)}
					/>
					<KiCheckbox
						className="track-default-checkbox"
						label="Default Track"
						checked={this.state.trackToAdd.isDefault}
						onChange={val => this.updateTrackToAdd('isDefault', val)}
					/>
					<KiIconButton
						disabled={!this.state.trackToAdd._id}
						icon="add_circle"
						onClick={() => this.addTrack()}
					/>
				</div>
				<KiDnDList
					items={this.state.tracks.map((track, index) => ({
						id: index,
						track: track,
						isDragDisabled: track.isDefault,
						content: (
							<div
								key={index}
								className={`new-model-form-track new-model-form-track-draggable ${
									track.isDefault ? 'default-track' : ''
								}`}
							>
								<label>Select Track:</label>
								<KiSelect
									className="track-selector"
									isDisabled={false}
									options={this.props.tracks.filter(
										t => !this.state.tracks.map(t => t._id).includes(t._id)
									)}
									getOptionLabel={option => option.name}
									getOptionValue={option => option._id}
									value={this.props.tracks.find(t => t._id === track._id)}
									onChange={val => this.onAddedTrackChange(val._id, index)}
								/>
								{!track.isDefault &&
									triggers && (
										<React.Fragment>
											<span>Trigger: </span>
											<KiSelect
												className="track-selector"
												isDisabled={false}
												options={this.availableTriggers(triggers)}
												getOptionLabel={option => option.name}
												getOptionValue={option => option._id}
												value={triggers.find(t => t._id === track.triggerId)}
												onChange={val => this.onAddedTriggerChange(val, index)}
											/>
										</React.Fragment>
									)}
								<KiCheckbox
									className="track-default-checkbox"
									label="Default Track"
									checked={track.isDefault}
									onChange={checked => this.onDefaultTrackChange(checked, index)}
								/>
								<i
									title="Delete"
									className={'material-icons track-form-delete'}
									onClick={() => this.deleteTrackWarning(index)}
								>
									delete
								</i>
							</div>
						),
						idx: index,
					}))}
					listClassName="order-track-wrapper"
					itemClassName="order-track-no-style"
					onReorder={items => this.onTracsReorder(items)}
				/>
				<section
					className="new-model-form-buttons"
					style={{paddingRight: 0, marginTop: '5rem', paddingTop: '2rem', borderTop: '1px solid #ccc'}}
					align="right"
				>
					<KiButton
						flat
						primary
						onClick={() => {
							this.setState({
								name: '',
								isDefault: false,
								tracks: [],
							});
							this.onCancel();
						}}
						label="Cancel"
					/>
					<KiButton
						raised
						primary
						disabled={!isValid || this.props.isReadOnly}
						onClick={this.onSubmit}
						label="Save"
					/>
				</section>
				<KiConfirmModal
					header="Delete Track from Model"
					message={`Are you sure you want to delete Track: ${this.getTrackToDeleteName()} from this model?`}
					acceptFunc={() => this.onDeleteTrack()}
					rejectFunc={() => this.setState({isDeleteConfirmActive: false})}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.isDeleteConfirmActive}
				/>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	user: state.user,
	dataset: state.datasetList.selected,
	fundingVehicle: state.fundingVehicle.selected,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps())(ModelForm);
