import * as actionTypes from './actionTypes';

const initialState = {
	dashboards: [],
	currentCards: [],
	allCards: [],
};

export const Dashboards = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DASHBOARDS_LOADED:
			return {
				...state,
				dashboards: action.payload,
			};
		case actionTypes.DASHBOARD_DELETED:
			return {
				...state,
				dashboards: state.dashboards.filter(db => db._id !== action.payload),
			};
		case actionTypes.ALL_CARDS_LOADED:
			return {
				...state,
				allCards: action.payload,
			};
		case actionTypes.CURRENT_CARD_UPDATED:
			return {
				...state,
				currentCard: action.payload,
			};
		case actionTypes.CARD_DELETED:
			return {
				...state,
				allCards: state.allCards.filter(card => card._id !== action._id),
			};
		case actionTypes.CARD_ADDED:
			return {
				...state,
				allCards: [action.card, ...state.allCards],
			};
		default:
			return state;
	}
};

export default Dashboards;
