import React from 'react';
import PropTypes from 'prop-types';
import {formatDate} from '../../../../utils/dateUtils';
import {renderPostingItems} from '../../ledgerUtils';
import TableActions from '../TableActions';
import KiTooltip from 'components/KiTooltip';

function ManualEntryTableItem({item, actions}) {
	const {postingDate, effectiveDate, postCode, description, postings, valid} = item;

	return (
		<tr className="scenario-list-item">
			<td className="fixed-width">
				{postCode}
				{!valid ? (
					<KiTooltip
						message="Must correct before commit"
						maxWidth={400}
						direction="right"
						className="ledger-info warning"
					>
						<i className="material-icons">info</i>
					</KiTooltip>
				) : (
					''
				)}
			</td>
			<td className="fixed-width-date">{formatDate(postingDate)}</td>
			<td className="fixed-width-date">{formatDate(effectiveDate)}</td>
			<td className="cell-ellipsis" title={description}>
				{description}
			</td>
			<td className="cell-ellipsis">{renderPostingItems(postings, 'lineItem')}</td>
			<td className="fixed-width align-right">{renderPostingItems(postings, 'account')}</td>
			<td className="fixed-width align-right">{renderPostingItems(postings, 'debit', true)}</td>
			<td className="fixed-width align-right">{renderPostingItems(postings, 'credit', true)}</td>
			<TableActions actions={actions} item={item} />
		</tr>
	);
}

ManualEntryTableItem.propTypes = {
	item: PropTypes.object.isRequired,
	actions: PropTypes.arrayOf(PropTypes.object),
};

ManualEntryTableItem.defaultProps = {
	actions: [],
};

export default ManualEntryTableItem;
