import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from '../../AssociatedData.theme.scss';
import SchemaColumnForm from './SchemaColumnForm';
import ValidationSummary from './ValidationSummary';
import {loadAllColumns, updateSchemaColumn, loadAllSchemaErrors, loadAllValidationErrors} from './actions';
import {associatedDataApi} from 'api';
import _get from 'lodash/get';

export class Schema extends Component {
	static propTypes = {
		originalColumnList: PropTypes.array.isRequired,
		requiredColumns: PropTypes.array.isRequired,
		schemaColumns: PropTypes.array,
		updateSchemaColumn: PropTypes.func,
		selectedFilter: PropTypes.string,
		fileUploadId: PropTypes.string,
		fileType: PropTypes.string,
		schemaErrors: PropTypes.array,
		validationErrors: PropTypes.array,
		loadAllSchemaErrors: PropTypes.func,
		loadAllValidationErrors: PropTypes.func,
		showSnackbar: PropTypes.func,
	};

	static defaultProps = {
		schemaColumns: [],
		originalColumnList: [],
		requiredColumns: [],
		schemaErrors: [],
		validationErrors: [],
	};

	state = {
		focused: null,
		mappedColumns: [],
		dataValidating: false,
	};

	componentDidMount() {
		this.getSchemaErrors();
	}

	handleUpdateSchemaColumn = schemaColumn => {
		const data = {
			fileUploadId: this.props.fileUploadId,
			fileType: this.props.fileType,
			schemaColumns: [schemaColumn],
		};
		return associatedDataApi
			.schemaValidation(data)
			.then(result => {
				const messages = _get(result, '[0].messages', []);
				this.props.updateSchemaColumn({...schemaColumn, messages: messages});
				this.getSchemaErrors();
			})
			.catch(err => {
				this.props.showSnackbar(err.message);
			});
	};

	handleDataValidation = () => {
		const data = {
			fileUploadId: this.props.fileUploadId,
			fileType: this.props.fileType,
			schemaColumns: this.props.schemaColumns,
		};
		this.setState({dataValidating: true});
		return associatedDataApi
			.dataValidation(data)
			.then(result => {
				this.props.loadAllValidationErrors(result);
				this.setState({dataValidating: false});
			})
			.catch(err => {
				this.props.showSnackbar(err.message);
				this.setState({dataValidating: false});
			});
	};

	setFocus = index => {
		this.setState({focused: index});
	};

	getFilteredColumns = () => {
		switch (this.props.selectedFilter) {
			case 'new':
				return this.props.schemaColumns.filter(c => c.isNew === true);
			case 'exceptions':
				return this.props.schemaColumns.filter(c => _get(c, 'messages', []).find(m => m.level === 'warning'));
			default:
				return this.props.schemaColumns;
		}
	};

	requiredColumnsAreMapped = () => {
		const mappedColumns = this.props.schemaColumns.filter(c => c.mapColumn);
		return mappedColumns.length === this.props.requiredColumns.length;
	};

	getSchemaErrors = () => {
		if (!this.props.originalColumnList.length) return [];
		const requiredColumnErrors = this.props.requiredColumns.reduce((errors, requiredColumn) => {
			if (!this.props.schemaColumns.find(c => c.mapColumn === requiredColumn)) {
				errors.push({
					code: `${requiredColumn}NotFound`,
					level: 'error',
					count: 1,
				});
			}
			return errors;
		}, []);

		const flattenedErrors = this.props.schemaColumns.reduce(
			(acc, {id, messages = []}) => [...acc, ...messages.map(m => ({columnId: id, ...m}))],
			[]
		);
		const allErrors = [...requiredColumnErrors, ...flattenedErrors];
		this.props.loadAllSchemaErrors(allErrors);
	};

	render() {
		return (
			<div>
				<ValidationSummary
					schemaMessages={this.props.schemaErrors}
					validationMessages={this.props.validationErrors}
					validateData={this.handleDataValidation}
					enableValidateButton={this.requiredColumnsAreMapped()}
					dataValidating={this.state.dataValidating}
				/>
				<div className={styles.schemaList}>
					<h3>{this.props.schemaColumns.length} columns found</h3>
					<div className={styles.tileSlider}>
						{this.getFilteredColumns().map((column, index) => {
							return (
								<SchemaColumnForm
									key={index}
									column={column}
									mappedColumns={this.state.mappedColumns}
									setFocus={this.setFocus}
									isFocused={index === this.state.focused}
									index={index}
									requiredColumns={this.props.requiredColumns}
									updateSchemaColumn={this.handleUpdateSchemaColumn}
									requiredColumnsAreMapped={this.requiredColumnsAreMapped()}
									fileType={this.props.fileType}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	schemaColumns: state.associatedDataSchema.schemaColumns,
	selectedFilter: state.associatedDataSchema.selectedFilter,
	schemaErrors: state.associatedDataSchema.schemaErrors,
	validationErrors: state.associatedDataSchema.validationErrors,
});

const mapDispatchToProps = () => ({
	loadAllColumns,
	updateSchemaColumn,
	loadAllSchemaErrors,
	loadAllValidationErrors,
});

export default connect(mapStateToProps, mapDispatchToProps())(Schema);
