// Globals
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports

// Website imports
import KiIconButton from 'components/KiIconButton';

// Local imports
import styles from './ErrorDisplayCard.theme.scss';

function ErrorDisplayCard({error}) {
	// Local State
	const [messages, setMessages] = useState([]);
	const [showErrors, setShowErrors] = useState(false);

	const prevError = useRef();
	useEffect(
		() => {
			if (error !== null) {
				if (!_.isEqual(prevError, error) && Object.keys(error).length > 0) {
					// Set new prevErrors value and show errors
					prevError.current = error;
					if (error.data && Array.isArray(error.data)) {
						setMessages(error.data);
					} else {
						setMessages([error.message]);
					}
					setShowErrors(true);
				}
			}
		},
		[error]
	);

	// Prevent render if showErrors is false
	if (!showErrors) {
		return <span />;
	}

	return (
		<div className={styles.errorPanel}>
			<section>{messages.map((e, index) => <p key={index}>{e}</p>)}</section>
			<KiIconButton iconButton title="Clear Errors" icon="cancel" onMouseUp={() => setShowErrors(false)} />
		</div>
	);
}

ErrorDisplayCard.propTypes = {
	error: PropTypes.object,
};

ErrorDisplayCard.defaultProps = {
	error: {},
};

export default ErrorDisplayCard;
