// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';

// Relative
import Calculation from './settings/calculation';
import {
	fetchFundingVehicleSettings,
	updateFundingVehicleSetting,
	deleteFundingVehicleSettings,
	addFundingVehicleSetting,
} from './actions';

// Externals
import KiConfirmModal from 'components/KiConfirmModal';
import options from 'ki-common/options';

export const ConfirmMessage = props => {
	const {setting, action = 'alter'} = props;
	if (!setting) return null;
	const displayName = options.defaultCalculationTypes.find(type => type.value === setting.name);

	return (
		<div>
			<h2>{`Are you sure you want to ${action} the following setting?`}</h2>
			<ul>
				<li key={setting._id}>{_get(displayName, 'label') || setting.name}</li>
			</ul>
		</div>
	);
};
ConfirmMessage.propTypes = {
	setting: PropTypes.object,
	action: PropTypes.string,
};

export class FundingVehicleSettings extends Component {
	static defaultProps = {
		settings: [],
		tbCohortColumns: [],
		type: '',
		readOnly: false,
		allowedConstraintTypes: [],
	};

	static propTypes = {
		settings: PropTypes.array,
		type: PropTypes.string,
		readOnly: PropTypes.bool,
		fundingVehicleId: PropTypes.string,
		updateFundingVehicleSetting: PropTypes.func,
		deleteFundingVehicleSettings: PropTypes.func,
		addFundingVehicleSetting: PropTypes.func,
		fetchFundingVehicleSettings: PropTypes.func,
		tbCohortColumns: PropTypes.array,
		allowedConstraintTypes: PropTypes.array,
		debtCalcColumns: PropTypes.array,
	};

	state = {
		newConstraintRowType: 'ccConcentration',
		isDeleteConfirmActive: false,
	};

	componentDidMount() {
		/*
		this is to sync borrowing base Balance and Advance Rate with gross_borrowing_base and advance_rate settings
		if they are changed in borrowing base settings, and then the user navigates to the calculations tab, the values aren't updated
		so this triggers a re-fetch of the settings to get the latest values on mount (which fires when calc tab is switched to)
		 */
		if (this.props.fundingVehicleId) {
			this.props.fetchFundingVehicleSettings(this.props.fundingVehicleId);
		}
	}

	// Delete functions
	showDeleteConfirmModal = setting => {
		this.setState({
			selectedSetting: setting,
			isDeleteConfirmActive: true,
		});
	};

	hideDeleteConfirmModal = () => {
		this.setState({
			selectedSetting: null,
			isDeleteConfirmActive: false,
		});
	};

	deleteSelectedRows = () => {
		this.props.deleteFundingVehicleSettings([this.state.selectedSetting]);
		this.setState({isDeleteConfirmActive: false});
	};

	setNewConstraintRowType = rowType => {
		this.setState({newConstraintRowType: rowType});
	};

	renderForm = (setting, formType = 'update') => {
		switch (setting.rowType) {
			case 'calculation': {
				return (
					<Calculation
						key={setting._id}
						setting={setting}
						settings={this.props.settings}
						formType={formType}
						fundingVehicleId={this.props.fundingVehicleId}
						updateMethod={this.props.updateFundingVehicleSetting}
						showDeleteConfirmModal={() => this.showDeleteConfirmModal(setting)}
						insertMethod={this.props.addFundingVehicleSetting}
						tbCohortColumns={this.props.tbCohortColumns.filter(c => c.columnType === 'asset')}
						debtCalcColumns={this.props.debtCalcColumns}
					/>
				);
			}
		}
	};

	renderInsertRow = rowType => {
		if (rowType === 'constraints') {
			const newConstraintRowSetting = {
				rowType:
					this.props.allowedConstraintTypes.length &&
					!this.props.allowedConstraintTypes.includes(this.state.newConstraintRowType)
						? this.props.allowedConstraintTypes[0]
						: this.state.newConstraintRowType,
				name: '',
				fundingVehicleId: this.props.fundingVehicleId,
				applyConstraint: true,
				applyCovenant: false,
			};
			return this.renderForm(newConstraintRowSetting, 'insert');
		}
		return this.renderForm({rowType: rowType}, 'insert');
	};

	render() {
		const {isDeleteConfirmActive} = this.state;
		return (
			<div className="fv-settings-tab">
				<ul className={`fv-settings-list ${this.props.type}`}>
					{!this.props.readOnly && this.renderInsertRow(this.props.type)}
					{this.props.settings.map(setting => this.renderForm(setting))}
				</ul>
				<KiConfirmModal
					header="Delete Selected Settings"
					message={<ConfirmMessage setting={this.state.selectedSetting} action="delete" />}
					acceptFunc={this.deleteSelectedRows}
					rejectFunc={this.hideDeleteConfirmModal}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={isDeleteConfirmActive}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	fundingVehicleId: state.fundingVehicle.selected._id,
	//debtCalcColumns: state.datasetList.selected.columns.filter(c => c.columnType === 'debtCalculation') || [],
});

const mapDispatchToProps = () => ({
	updateFundingVehicleSetting,
	deleteFundingVehicleSettings,
	addFundingVehicleSetting,
	fetchFundingVehicleSettings,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(FundingVehicleSettings);
