import React from 'react';
import PropTypes from 'prop-types';
import FormBlockItemContainer from '../../common/FormBlockItemContainer';
import styles from './Collateral.theme.scss';
import {
	defaultType as DEFAULT_TYPE_OPTIONS,
	lossType as LOSS_TYPE_OPTIONS,
	prePay as PREPAY_TYPE_OPTIONS,
} from 'ki-common/options/assumptions';
import KiInput from 'components/KiInput';
import RateSelector from './RateSelector';

const Collateral = props => {
	const handleDelete = () => {
		props.onChange({
			collateralState: false,
			prepayments: {
				rate: null,
				vectorId: null,
				type: null,
			},
			default: {
				rate: null,
				vectorId: null,
				type: null,
			},
			loss: {
				rate: null,
				vectorId: null,
				type: null,
				recovDelayMonths: '0',
				prinLossPctNonPerf: '0',
				liqPeriod: '0',
			},
		});
	};

	return (
		<FormBlockItemContainer
			className={styles.collateralContainer}
			readOnly={props.readOnly}
			isVisible={props.isVisible}
			onDelete={handleDelete}
			title="Collateral"
		>
			<RateSelector
				readOnly={props.readOnly}
				vectors={props.vectors}
				rateAmount={props.prepayments.rate}
				vectorId={props.prepayments.vectorId?.value || props.prepayments.vectorId}
				onRateChange={(rate, vectorId) =>
					props.onChange(assumption => ({
						prepayments: {
							...assumption.prepayments,
							rate: rate,
							vectorId: vectorId,
						},
					}))
				}
				rateLabel="Prepay Rate"
				typeLabel="Prepay Type"
				onTypeChange={type =>
					props.onChange(assumption => ({
						...assumption,
						prepayments: {
							...assumption.prepayments,
							type: type,
						},
					}))
				}
				types={PREPAY_TYPE_OPTIONS}
				type={props.prepayments.type?.value || props.prepayments.type}
			/>
			<RateSelector
				readOnly={props.readOnly}
				vectors={props.vectors}
				rateAmount={props.default.rate}
				vectorId={props.default.vectorId?.value || props.default.vectorId}
				onRateChange={(rate, vectorId) =>
					props.onChange(assumption => ({
						default: {
							...assumption.default,
							rate: rate,
							vectorId: vectorId,
						},
					}))
				}
				rateLabel="Default Rate"
				typeLabel="Default Type"
				onTypeChange={type =>
					props.onChange(assumption => ({
						default: {
							...assumption.default,
							type: type,
						},
					}))
				}
				types={DEFAULT_TYPE_OPTIONS}
				type={props.default.type?.value || props.default.type}
			/>

			<RateSelector
				readOnly={props.readOnly}
				vectors={props.vectors}
				rateAmount={props.loss.rate}
				vectorId={props.loss.vectorId?.value || props.loss.vectorId}
				onRateChange={(rate, vectorId) =>
					props.onChange(assumption => ({
						loss: {
							...assumption.loss,
							rate: rate,
							vectorId: vectorId,
						},
					}))
				}
				rateLabel="Loss Severity Rate"
				typeLabel="Loss Type"
				onTypeChange={type =>
					props.onChange(assumption => ({
						loss: {
							...assumption.loss,
							type: type,
						},
					}))
				}
				types={LOSS_TYPE_OPTIONS}
				type={props.loss.type?.value || props.loss.type}
			/>
			<div className="sidebar-form-section">
				<span className="form-instruction">Recovery Lag (months)</span>
				<KiInput
					className="substring-size-input"
					readOnly={props.readOnly}
					autoComplete="off"
					isNumberMasked={true}
					maskConfig={{allowNegative: false}}
					error={props.validationErrors?.loss?.recovDelayMonths}
					onChange={val =>
						props.onChange(assumption => ({
							loss: {
								...assumption.loss,
								recovDelayMonths: val,
							},
						}))
					}
					value={props.loss.recovDelayMonths}
				/>
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">Principal Loss (Non Perf)%</span>
				<KiInput
					className="substring-size-input"
					readOnly={props.readOnly}
					autoComplete="off"
					isNumberMasked={true}
					maskConfig={{allowNegative: false}}
					onChange={val =>
						props.onChange(assumption => ({
							loss: {
								...assumption.loss,
								prinLossPctNonPerf: val,
							},
						}))
					}
					error={props.validationErrors?.loss?.prinLossPctNonPerf}
					value={props.loss.prinLossPctNonPerf}
				/>
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">Liquidation (months)</span>
				<KiInput
					className="substring-size-input"
					readOnly={props.readOnly}
					autoComplete="off"
					isNumberMasked={true}
					maskConfig={{allowNegative: false}}
					onChange={val =>
						props.onChange(assumption => ({
							loss: {
								...assumption.loss,
								liqPeriod: val,
							},
						}))
					}
					error={props.validationErrors?.loss?.liqPeriod}
					value={props.loss.liqPeriod}
				/>
			</div>
		</FormBlockItemContainer>
	);
};

Collateral.propTypes = {
	isVisible: PropTypes.bool,
	readOnly: PropTypes.bool,
	prepayments: PropTypes.shape({
		rate: PropTypes.string,
		vectorId: PropTypes.string,
		type: PropTypes.string,
	}),
	default: PropTypes.shape({
		rate: PropTypes.string,
		vectorId: PropTypes.string,
		type: PropTypes.string,
	}),
	loss: PropTypes.shape({
		rate: PropTypes.string,
		vectorId: PropTypes.string,
		type: PropTypes.string,
		prinLossPctNonPerf: PropTypes.string,
		recovDelayMonths: PropTypes.string,
		liqPeriod: PropTypes.string,
	}),
	vectors: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	validationErrors: PropTypes.object,
};

Collateral.defaultProps = {
	readOnly: false,
	isVisible: false,
	prepayments: {
		rate: null,
		vectorId: null,
		type: PREPAY_TYPE_OPTIONS[0].value,
	},
	default: {
		rate: null,
		vectorId: null,
		type: DEFAULT_TYPE_OPTIONS[0].value,
	},
	loss: {
		rate: null,
		vectorId: null,
		type: LOSS_TYPE_OPTIONS[0].value,
		recovDelayMonths: '0',
		prinLossPctNonPerf: '0',
		liqPeriod: '0',
	},
	vectors: [],
};

export default Collateral;
