const testUtils = require('../../utils/testUtils');
const options = require('../../options');
/*
 Constraint Type: ANY
 Name: Is the display name of the Constraint entered by the user. This must be unique
 Data Column: Is selected from a list of available columns in the dataset and reflects the column of data that will be constrained
 Logic: can be one of the following mathematical operator used for the constraint =,<>,<,>,<=,>=,Between, in, not in, is null, is not null
 Target Min: Numeric minimum required
 Target Max: Numeric Max required
 Flex: Default value the target is allowed to expand as a % below the min or above the max
 Constraint: By default this is set to checked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
 Covenant: By default this is set to unchecked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
 */

const getConstraints = () => ({
	'fundingVehicleId': {
		presence: false,
		format: {
			pattern: testUtils.mongoObjectIDStringRegex,
			flags: 'i',
			message: 'fundingVehicleId must be a valid mongo ObjectID string',
		},
	},
	'name': {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 100,
			message: 'must be between 1 to 100 characters',
		},
	},
	'dataColumn': {
		presence: {allowEmpty: false},
	},
	'logic': {
		presence: {allowEmpty: false},
		inclusion: {
			within: options.logic.map(({value}) => value),
			message: 'must be a valid logic value',
		},
	},
	'target': {
		presence: {allowEmpty: false},
		targetArray: true // Custom validator
	},
	'applyConstraint': {
		presence: {allowEmpty: false},
		inclusion: {
			within: [true, false],
			message: 'must be a boolean',
		},
	},
	'applyCovenant': {
		presence: {allowEmpty: false},
		inclusion: {
			within: [true, false],
			message: 'must be a boolean',
		},
	},
});

module.exports = {
	getConstraints
};