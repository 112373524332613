import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const addNotification = notification => {
	return fetch(`${apiUrl}/notifications/`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(notification),
	}).then(_handleResponse);
};

export const fetchNotificationsForCurrentUser = () => {
	return fetch(`${apiUrl}/notifications/`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchNotificationById = id => {
	return fetch(`${apiUrl}/notifications/${id}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const deleteNotificationById = id => {
	return fetch(`${apiUrl}/notifications/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const addUnassignedPoolsNotification = notification => {
	return fetch(`${apiUrl}/notifications/unassignedpools/`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(notification),
	}).then(_handleResponse);
};

export const deleteUnassignedPoolsNotificationByDatasetId = datasetId => {
	return fetch(`${apiUrl}/notifications/unassignedpools/${datasetId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const updateNotificationsByDatasetId = datasetId => {
	return fetch(`${apiUrl}/notifications/unassignedpools/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export default {
	addNotification,
	fetchNotificationsForCurrentUser,
	fetchNotificationById,
	deleteNotificationById,
	addUnassignedPoolsNotification,
	deleteUnassignedPoolsNotificationByDatasetId,
	updateNotificationsByDatasetId,
};
