import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import KiMenu from 'components/KiMenu';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import {logout} from 'containers/login/actions';
import {NavLink as Link} from 'react-router-dom';
const helpURL = '/help/Ki%20Training%20Exercises.htm';
const logo = require('static/images/Ki-logo-white_40.png');

function TopNav(props) {
	const {user, router, notificationList} = props;

	const handleLink = path => {
		router.push(path);
	};

	const notificationClass = notificationList.length > 0 ? 'material-icons active' : 'material-icons inactive';

	return (
		<div id="top-nav">
			<img alt="logo" className="logo" src={logo} />
			<div className="top-nav-right">
				<span className="notifications">
					<Link to={'/notifications'}>
						<i className={notificationClass} title="Click to view your notifications.">
							notifications
						</i>
					</Link>
				</span>
				<span className="name">{`${user.firstName} ${user.lastName}`}</span>
				<KiMenu className="profile-menu top-bar-menu" icon="arrow_drop_down" position="topRight">
					<KiMenuItem icon="person" label="My Profile" onClick={() => handleLink('/myProfile')} />
					{(user.groups.indexOf('SystemAdmins') > -1 || user.groups.indexOf('UserAdmins') > -1) && (
						<KiMenuItem icon="people" label="Account Management" onClick={() => handleLink('/accounts')} />
					)}
					<KiMenuItem icon="help" label="Help" onClick={() => window.open(helpURL, 'help')} />
					<KiMenuItem icon="exit_to_app" label="Logout" onClick={() => window.open(`web/logout`, '_self')} />
				</KiMenu>
			</div>
		</div>
	);
}

TopNav.defaultProps = {
	user: {
		firstName: '',
		lastName: '',
		groups: [],
	},
};

TopNav.propTypes = {
	user: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		groups: PropTypes.array,
	}),
	router: PropTypes.object.isRequired,
	notificationList: PropTypes.array.isRequired,
};

export default connect(
	null,
	{logout}
)(TopNav);
