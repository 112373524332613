import {datasetsApi, columnServiceApi} from 'api';
import * as actionTypes from './actionTypes';
import {showSnackbar} from '../../state/actions/Snackbar';

import socketManager from '../../socket';

socketManager.on('historical column updated', (id, changes = {}) => dispatch => {
	dispatch({type: actionTypes.DATASETS_HISTORICAL_COLUMN_UPDATED, id, changes});
});
const fetchDatasetList = (includeEmpty = false) => {
	return dispatch => {
		dispatch({type: actionTypes.DATASETS_FETCH, status: 'pending'});

		return datasetsApi
			.fetchDatasetList(includeEmpty)
			.then(datasetList =>
				dispatch({type: actionTypes.DATASETS_FETCH, status: 'success', datasetList: datasetList})
			)
			.catch(() => dispatch({type: actionTypes.DATASETS_FETCH, status: 'error'}));
	};
};

const fetchDataset = (datasetId, embedAssetColumns, flipArrayToBinary) => dispatch => {
	dispatch({type: actionTypes.DATASETS_FETCH_ONE, status: 'pending'});
	return datasetsApi
		.fetchDataset(datasetId, embedAssetColumns, flipArrayToBinary)
		.then(dataset => {
			dispatch({type: actionTypes.DATASETS_FETCH_ONE, status: 'success', dataset: dataset});
			dispatch({type: actionTypes.DATASETS_SELECTED_SET, dataset});

			return dataset;
		})
		.catch(() => dispatch({type: actionTypes.DATASETS_FETCH_ONE, status: 'error'}));
};

const fetchDatasetByCategoryName = name => dispatch => {
	dispatch({type: actionTypes.DATASETS_FETCH_ONE, status: 'pending'});
	return datasetsApi
		.fetchDatasetByCategoryName(name)
		.then(dataset => {
			dispatch({type: actionTypes.DATASETS_FETCH_ONE, status: 'success', dataset: dataset});
			dispatch({type: actionTypes.DATASETS_SELECTED_SET, dataset});

			return dataset;
		})
		.catch(() => dispatch({type: actionTypes.DATASETS_FETCH_ONE, status: 'error'}));
};

const generateAsOfDateColumnsForDataset = (columnIds = []) => (dispatch, getState) => {
	const state = getState();
	const datasetName = state.datasetList.selected.name || '';
	const datasetId = state.datasetList.selected.datasetId;
	dispatch({type: actionTypes.DATASETS_GENERATE_AS_OF_DATE_COLUMNS, status: 'pending', columnIds});
	return datasetsApi.generateAsOfDateColumns(datasetId, columnIds).catch(err => {
		dispatch(showSnackbar(`Error Generating Historical Columns for Dataset ${datasetName}`));
		dispatch({type: actionTypes.DATASETS_GENERATE_AS_OF_DATE_COLUMNS, status: 'error', columnIds});
		return Promise.reject(err);
	});
};

const checkForAsOfDateColumnModifications = (datasetId, columnId) => dispatch => {
	dispatch({
		type: actionTypes.DATASETS_CHECK_AS_OF_DATE_COLUMN_MODIFICATIONS,
		status: 'pending',
		datasetId,
		columnId,
	});

	return datasetsApi
		.checkForAsOfDateColumnModifications(datasetId, columnId)
		.then(result => {
			dispatch({
				type: actionTypes.DATASETS_CHECK_AS_OF_DATE_COLUMN_MODIFICATIONS,
				status: 'success',
				modifiedColumnIds: result,
			});
			return result;
		})
		.catch(err => {
			dispatch({
				type: actionTypes.DATASETS_CHECK_AS_OF_DATE_COLUMN_MODIFICATIONS,
				status: 'error',
				datasetId,
				columnId,
			});
			return Promise.reject(err);
		});
};

const setSelectedDataset = dataset => ({type: actionTypes.DATASETS_SELECTED_SET, dataset});

const clearSelectedDataset = () => ({type: actionTypes.DATASETS_SELECTED_CLEAR});

const requeryColumnList = (datasetId, columnSpecification) => dispatch => {
	dispatch({type: actionTypes.DATASETS_REQUERY_COLUMN_LIST, status: 'pending'});
	return columnServiceApi
		.getColumnsFromService(datasetId, columnSpecification)
		.then(columns => {
			dispatch({type: actionTypes.DATASETS_REQUERY_COLUMN_LIST, status: 'success', columns});
			return columns;
		})
		.catch(() => dispatch({type: actionTypes.DATASETS_REQUERY_COLUMN_LIST, status: 'error'}));
};

export {
	fetchDataset,
	fetchDatasetByCategoryName,
	fetchDatasetList,
	setSelectedDataset,
	generateAsOfDateColumnsForDataset,
	clearSelectedDataset,
	checkForAsOfDateColumnModifications,
	requeryColumnList,
};
