import {SUBMISSIONS_FETCH} from './constants';
import {submissionsApi} from 'api';

import socketManager from '../../socket';

socketManager.on('submission', submissionId => dispatch => {
	dispatch(fetchSubmission(submissionId)); //eslint-disable-line no-use-before-define
});
const fetchSubmission = submissionId => dispatch => {
	return submissionsApi
		.fetchSubmission(submissionId)
		.then(submission => dispatch({type: 'SUBMISSIONS_FETCH_ONE', payload: submission}));
};

const fetchSubmissions = () => dispatch => {
	dispatch({type: SUBMISSIONS_FETCH, status: 'pending'});
	return submissionsApi
		.fetchSubmissions()
		.then(submissions => dispatch({type: SUBMISSIONS_FETCH, status: 'success', submissions: submissions}))
		.catch(err => dispatch({type: SUBMISSIONS_FETCH, status: 'error', msg: err}));
};

export {fetchSubmission, fetchSubmissions};
