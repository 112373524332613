import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames/bind';
import styles from './KiRadioGroup.theme.scss';

const cx = classNames.bind(styles);
export class KiRadioGroup extends Component {
	static propTypes = {
		className: PropTypes.string,
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired,
		children: PropTypes.node,
	};

	static defaultProps = {
		className: '',
	};

	render() {
		const {className, onChange} = this.props;

		return (
			<div
				className={`${className} ${cx('radioGroup', {
					className: className,
				})}`}
			>
				{this.props.children.map(child => {
					const props = {
						onChange,
						checked: child.props.value === this.props.value,
						...child.props,
					};
					return Object.assign({}, child, {props});
				})}
			</div>
		);
	}
}

export default KiRadioGroup;
