import * as actionTypes from './actionTypes';
import {fetchLedgerAccountsMapping, fetchTemplates} from '../../../../../api/ledgersApi';
import {fetchPortfolioDates, fetchDates} from '../../../../../api/datasetDatesApi';
import {getColumnsFromService} from '../../../../../api/columnServiceApi';
import {fetchFundingVehicleSettingsByDatasetId} from '../../../../../api/fundingVehiclesApi';
import debtOptions from 'ki-common/options/debt';

const setLoading = loading => dispatch => {
	dispatch({type: actionTypes.LOADING, loading});
};

const setIsVehicleLedger = isVehicleLedger => dispatch => {
	dispatch({type: actionTypes.SET_IS_VEHICLE_LEDGER, isVehicleLedger});
};

const setPureTemplates = templates => {
	return dispatch => {
		dispatch({type: actionTypes.SET_PURE_TEMPLATES, templates});
		dispatch({type: actionTypes.SET_TEMPLATE_LISTS, templates});
	};
};

const getTemplates = ledgerId => dispatch => {
	dispatch({type: actionTypes.LOADING, loading: true});
	return fetchTemplates(ledgerId, false).then(templates => {
		dispatch(setPureTemplates(templates));
	});
};

const setDateContexts = dateContexts => {
	return dispatch => {
		const dataContextOptions = dateContexts.map(dateContext => ({
			value: dateContext.groupId,
			label: dateContext.name,
		}));
		dispatch({type: actionTypes.SET_DATE_CONTEXTS, dataContextOptions});
		dispatch({type: actionTypes.SET_TEMPLATE_LISTS, dataContextOptions});
	};
};

const getDateContexts = (datasetId, isVehicleLedger) => dispatch => {
	dispatch({type: actionTypes.LOADING, loading: true});
	if (isVehicleLedger) {
		fetchDates(datasetId, true, false, true).then(dateContexts => {
			dispatch(setDateContexts(dateContexts));
		});
	} else {
		fetchPortfolioDates(datasetId).then(dateContexts => {
			dispatch(setDateContexts(dateContexts));
		});
	}
};

const setAmounts = amounts => {
	return dispatch => {
		const amountOptions = amounts.map(amount => ({
			value: amount._id,
			label: amount.detailedDisplayName,
			entityType: amount.entityType,
		}));
		dispatch({type: actionTypes.SET_AMOUNTS, amountOptions});
		dispatch({type: actionTypes.SET_TEMPLATE_LISTS, amountOptions});
	};
};

const getAmounts = (datasetId, isVehicleLedger) => dispatch => {
	dispatch({type: actionTypes.LOADING, loading: true});
	const body = isVehicleLedger
		? {
				sources: {
					includeDebtCalculations: true,
				},
				filters: {dataTypes: ['numeric']},
		  }
		: {
				sources: {includeAggregateColumns: true},
				filters: {dataTypes: ['numeric']},
		  };
	getColumnsFromService(datasetId, body).then(amounts => {
		dispatch(setAmounts(amounts));
	});
};

const setAccounts = accounts => {
	return dispatch => {
		const accountOptions = accounts.map(account => ({
			value: account._id,
			label: account.description,
		}));
		dispatch({type: actionTypes.SET_ACCOUNTS, accountOptions});
		dispatch({type: actionTypes.SET_TEMPLATE_LISTS, accountOptions});
	};
};

const getAccounts = ledgerId => dispatch => {
	dispatch({type: actionTypes.LOADING, loading: true});
	fetchLedgerAccountsMapping(ledgerId).then(accounts => {
		dispatch(setAccounts(accounts));
	});
};

const SPECIAL_ENTITY_TYPES = {
	FUNDING_VEHICLE: 'fundingVehicle',
	DEBT: 'debt',
	TRANCHE: 'tranche',
};

const setEntities = (entities, ledger) => {
	return dispatch => {
		// Funding vehicle option added after conversation with Inga as a solution to GL-533
		const hardcodedEntityTypeOptions = debtOptions.entityOptions.filter(
			option => option.value !== SPECIAL_ENTITY_TYPES.FUNDING_VEHICLE
		);
		const entityTypeOptions = [
			debtOptions.entityOptions.find(option => option.value === SPECIAL_ENTITY_TYPES.FUNDING_VEHICLE),
		];
		const entityNameOptions = [
			{
				label: ledger.fundingVehicleName,
				value: ledger.fundingVehicleId,
				rowType: SPECIAL_ENTITY_TYPES.FUNDING_VEHICLE,
			},
		];
		entities.forEach(entity => {
			// This mapping needs to be done according to Matthew's answer on slack proj-ki-ledger chanel on 27.04.2020
			// Couldn't find any const defined in ki-common so just simply mapping strings
			const rowType =
				entity.rowType === SPECIAL_ENTITY_TYPES.DEBT ? SPECIAL_ENTITY_TYPES.TRANCHE : entity.rowType;

			entityNameOptions.push({
				value: entity._id,
				label: entity.name,
				fvId: entity.fundingVehicleId,
				rowType: rowType,
			});

			if (
				!entityTypeOptions.some(type => type.value === rowType) &&
				hardcodedEntityTypeOptions.some(type => type.value === rowType)
			) {
				entityTypeOptions.push(hardcodedEntityTypeOptions.find(type => type.value === rowType));
			}
		});
		dispatch({type: actionTypes.SET_ENTITIES, entityTypeOptions, entityNameOptions});
		dispatch({type: actionTypes.SET_TEMPLATE_LISTS, entityTypeOptions, entityNameOptions});
	};
};

const getEntities = ledger => dispatch => {
	dispatch({type: actionTypes.LOADING, loading: true});
	fetchFundingVehicleSettingsByDatasetId(ledger.datasetId).then(entities => {
		dispatch(setEntities(entities, ledger));
	});
};

const changeTemplate = (key, index, value, originalIndex) => {
	return dispatch => {
		dispatch({type: actionTypes.CHANGE_TEMPLATE, key, index, value, originalIndex});
	};
};

const addTemplateLine = index => {
	return dispatch => {
		dispatch({type: actionTypes.ADD_TEMPLATE_LINE, index});
	};
};

const addTemplate = () => {
	return dispatch => {
		dispatch({type: actionTypes.ADD_TEMPLATE});
	};
};

const undoTemplate = (index, originalIndex) => {
	return dispatch => {
		dispatch({type: actionTypes.UNDO_TEMPLATE, index, originalIndex});
	};
};

const removeTemplate = index => {
	return dispatch => {
		dispatch({type: actionTypes.REMOVE_TEMPLATE, index});
	};
};

const removeTemplateLine = (index, lineIndex) => {
	return dispatch => {
		dispatch({type: actionTypes.REMOVE_TEMPLATE_LINE, index, lineIndex});
	};
};

const searchTemplates = searchTerm => {
	return dispatch => {
		dispatch({type: actionTypes.SEARCH_TEMPLATES, searchTerm});
	};
};

export {
	getTemplates,
	setPureTemplates,
	getDateContexts,
	getAmounts,
	getAccounts,
	getEntities,
	changeTemplate,
	addTemplate,
	addTemplateLine,
	undoTemplate,
	removeTemplate,
	removeTemplateLine,
	setLoading,
	setIsVehicleLedger,
	searchTemplates,
};
