const getConstraints = () => ({
	'name': {
		presence: {allowEmpty: false},
	},
	snapshotDate: () => {
		return {
			isJavascriptDate: {
				message: 'is required'
			}
		};
	}
});

module.exports = {
	getConstraints
};
