import React, {Fragment, useEffect, useState} from 'react';
import {fetchJournalForLedger} from '../../../../api/ledgersApi';
import PropTypes from 'prop-types';
import JournalTable from './JournalTable';
import {connect} from 'react-redux';
import KiInput from '../../../../components/KiInput';
import {filterNestedList, handleEnter} from '../../ledgerUtils';
import fetchWithJob from '../../../../socket/fetchWithJob';
import isEmpty from 'lodash/isEmpty';
import KiButton from '../../../../components/KiButton';
import Loader from '../../Loader';

function LedgerJournal({id, startDate, endDate, dateContext}) {
	const [journalData, setJournalData] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredJournalList, setFilteredJournalList] = useState([]);
	const [isLoading, setLoading] = useState(true);

	const filterItems = () => {
		if (journalData) {
			setFilteredJournalList(
				filterNestedList(searchTerm, journalData, [
					'description',
					'postCode',
					{postings: ['account', 'lineItem']},
				])
			);
		}
	};

	useEffect(
		() => {
			setLoading(true);
			fetchWithJob(() => fetchJournalForLedger(id, startDate, endDate, dateContext.value)).then(journal => {
				setJournalData(journal);
				setLoading(false);
			});
		},
		[id, startDate, endDate, dateContext]
	);

	useEffect(
		() => {
			if (!searchTerm) {
				filterItems();
			}
		},
		[searchTerm]
	);

	useEffect(
		() => {
			filterItems();
		},
		[journalData]
	);

	return (
		<Loader isActive={isLoading}>
			{!isEmpty(journalData) ? (
				<Fragment>
					<div className="horizontal-inputs-container">
						<div className="long-wrapper">
							<KiInput
								label="Search by Post Code, Description, Account or Line Item "
								value={searchTerm}
								onChange={value => setSearchTerm(value)}
								onKeyDown={e => handleEnter(e, filterItems)}
								className="search-input"
								maxLength={100}
							/>
						</div>
						<div className="button-wrapper">
							<KiButton primary onClick={() => filterItems()}>
								Search
							</KiButton>
						</div>
					</div>
					<JournalTable journal={filteredJournalList} />
				</Fragment>
			) : (
				<div className="no-list-items-container">
					<div>No posts found</div>
				</div>
			)}
		</Loader>
	);
}

LedgerJournal.propTypes = {
	id: PropTypes.string.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	dateContext: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	dateContext: state.ledgerExploration.dateContext,
	startDate: state.ledgerExploration.startDate,
	endDate: state.ledgerExploration.endDate,
});

export default connect(mapStateToProps)(LedgerJournal);
