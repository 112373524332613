import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Pager from 'components/Pager';
import {connect} from 'react-redux';
import _chunk from 'lodash/chunk';
import {fetchSubmissions} from './actions';
import SubmissionList from './components/SubmissionList';
import KiAppBar from 'components/KiAppBar';
import KiIconButton from 'components/KiIconButton';
import {fetchDatasetByCategoryName} from 'containers/datasetList/actions';

export class Submissions extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		submissions: PropTypes.array,
		fetchSubmissions: PropTypes.func.isRequired,
		fetchDatasetByCategoryName: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired,
		dataset: PropTypes.object,
		//categorySchemaName: PropTypes.string,
	};

	static contextTypes = {
		store: PropTypes.object,
	};

	static defaultProps = {
		dataset: {},
	};

	state = {
		pageSize: 10,
		pageNumber: 1,
		submissionsToDisplay: [],
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Submissions`;
		// This code will only come into play on the very first submission of a dataset
		// We will have the submission.categorySchemaName passed in here
		if (!this.props.dataset._id) {
			const {categoryName} = this.props.match.params;
			this.props.fetchDatasetByCategoryName(categoryName);
		}

		this.props.fetchSubmissions();
	}

	setPageNumber = val => {
		this.setState({pageNumber: val});
	};

	render() {
		const {history, submissions, dataset} = this.props;
		const {pageNumber, pageSize} = this.state;
		const searchTerm = dataset.name ? dataset.name : this.props.match.params.datasetId;
		let submissionsToDisplay = submissions.filter(s => s.categorySchemaName === searchTerm);

		// Limit by paging
		const countForPaging = submissionsToDisplay.length;
		if (countForPaging > pageSize) {
			submissionsToDisplay = _chunk(submissionsToDisplay, pageSize)[pageNumber - 1];
		}
		return (
			<div className="submissions">
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1 className="link" onClick={() => this.props.history.push('/datasets')}>{`Datasets`}</h1>
						<h1>{` > ${searchTerm || ''}`}</h1>
						<h1>{` > Submissions`}</h1>
					</div>
					{dataset._id && (
						<KiIconButton
							icon="add_circle"
							inverse
							onClick={() =>
								history.push(`/datasets/${this.props.match.params.datasetId}/submission/upload`)
							}
							title="Click to create a new submission."
						/>
					)}
				</KiAppBar>
				<SubmissionList
					submissions={submissionsToDisplay}
					router={history}
					datasetId={this.props.match.params.datasetId}
				/>
				<section className="funding-vehicles-paging">
					{countForPaging > pageSize && (
						<Pager
							totalItemsCount={countForPaging || 0}
							activePage={pageNumber || 1}
							itemsCountPerPage={pageSize}
							onChange={this.setPageNumber}
							pageRangeDisplayed={5}
						/>
					)}
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	app: state.app,
	submissions: state.submissions.data,
	isLoading: state.submissions.isLoading,
	user: state.user,
	dataset: state.datasetList.data.find(d => d.datasetId === props.match.params.datasetId),
});

export default connect(mapStateToProps, {fetchSubmissions, fetchDatasetByCategoryName})(Submissions);
