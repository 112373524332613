import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './kiSnackbar.theme.scss';

export class KiSnackbar extends Component {
	static propTypes = {
		className: PropTypes.string,
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		active: PropTypes.bool,
		timeout: PropTypes.number,
		onTimeout: PropTypes.func,
		onClick: PropTypes.func,
		autoClose: PropTypes.bool,
	};

	static defaultProps = {
		className: '',
		active: false,
		timeout: 4000,
		autoClose: true,
	};

	startTimer = () => {
		setTimeout(() => {
			this.props.onTimeout();
		}, this.props.timeout);
	};

	render() {
		if (this.props.active && this.props.autoClose) {
			this.startTimer();
		}
		const {label} = this.props;
		const divStyle = {
			marginBottom: '5px',
		};
		let innerValue = label;
		if (Array.isArray(label)) {
			innerValue = label.map((msg, idx) => (
				<div key={idx} style={divStyle}>
					{msg}
				</div>
			));
		}
		return (
			<div
				className={`${styles.kiSnackbar} ${this.props.className} ${this.props.active ? styles.active : ''}`}
				onClick={this.props.onClick}
			>
				<span>{innerValue}</span>
			</div>
		);
	}
}
export default KiSnackbar;
