import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {KiInput} from 'components/KiInput';
import KiButton from 'components/KiButton';
import FlyoutConfirmPanel from 'components/FlyoutConfirmPanel';
import {waterfallApi} from 'api';
import {fetchTracks, fetchModels} from 'containers/dealStructures/actions';
import {showSnackbar} from 'state/actions/Snackbar';
import 'components/FlyoutCalculations/components/ColumnList/ColumnList.scss';

export class FlyoutManageTracks extends Component {
	static propTypes = {
		dataset: PropTypes.object.isRequired,
		fetchTracks: PropTypes.func.isRequired,
		fetchModels: PropTypes.func.isRequired,
		tracks: PropTypes.array.isRequired,
		showSnackbar: PropTypes.func.isRequired,
		fundingVehicle: PropTypes.object.isRequired,
		panelMode: PropTypes.any, //TODO: set correct proptypes for this, also if it's required set a default
	};

	state = {
		panelMode: this.props.panelMode,
		searchTerm: '',
		trackName: '',
		trackNameError: null,
		currentTrack: null,
		trackToDelete: null,
		showDeleteConfirm: false,
		noDeleteReasons: null,
	};

	componentDidMount() {
		return this.props.fetchTracks(this.props.dataset.datasetId);
	}

	onSaveTrack = () => {
		if (
			this.state.panelMode === 'add' &&
			this.props.tracks.map(track => track.name.toUpperCase()).includes(this.state.trackName.toUpperCase())
		) {
			this.setState({
				trackNameError: 'Track name must be unique.',
			});
			return;
		}

		const trackForSaving = {
			datasetId: this.props.dataset.datasetId,
			name: this.state.trackName,
		};
		if (this.state.currentTrack) {
			trackForSaving._id = this.state.currentTrack._id;
		}
		return waterfallApi.saveTrack(trackForSaving).then(() => {
			this.setState({
				panelMode: null,
				trackName: '',
				trackNameError: null,
				currentTrack: null,
			});
			this.props.showSnackbar('Track successfully saved.');
			this.props.fetchTracks(this.props.dataset.datasetId);
			this.props.fetchModels(this.props.fundingVehicle._id);
		});
	};

	onEditTrack = track => {
		this.setState({
			currentTrack: track,
			panelMode: 'edit',
			trackName: track.name,
		});
	};

	deleteTrackWarning = track => {
		return waterfallApi.getTrackDependencies(track._id).then(results => {
			this.setState({
				trackToDelete: track,
				showDeleteConfirm: results.length ? false : true,
				noDeleteReasons: results.length ? results : null,
			});
		});
	};

	onDeleteTrack = () => {
		return waterfallApi.deleteTrack(this.state.trackToDelete._id).then(() => {
			this.props.showSnackbar('Track successfully deleted.');
			this.props.fetchTracks(this.props.dataset.datasetId);
			this.props.fetchModels(this.props.fundingVehicle._id);
			this.setState({
				trackToDelete: null,
				showDeleteConfirm: false,
				noDeleteReasons: null,
			});
		});
	};

	setTrackName = name => {
		this.setState({
			trackName: name,
			trackNameError: name.match(/^[\w\-\s]+$/) && name ? null : 'name must be alphanumeric',
		});
	};

	render() {
		const filteredTracks = this.props.tracks.filter(track =>
			track.name.toUpperCase().includes(this.state.searchTerm.toUpperCase())
		);

		return (
			<div className="context-sidebar-panel-flex">
				<header className="flyout-panel-title">MANAGE TRACKS</header>
				{this.state.showDeleteConfirm && (
					<FlyoutConfirmPanel
						header={'Delete Track'}
						message={`Remove ${this.state.trackToDelete.name}`}
						acceptFunc={() => this.onDeleteTrack()}
						rejectFunc={() => this.setState({showDeleteConfirm: false})}
						acceptLabel={'Delete'}
						rejectLabel={'Cancel'}
					/>
				)}
				{this.state.noDeleteReasons && (
					<FlyoutConfirmPanel
						header={'Delete'}
						message={
							<span>
								Track <span className="bold">&quot;{this.state.trackToDelete.name}&quot;</span> cannot
								be deleted because it is associated with the following Models:
							</span>
						}
						rejectFunc={() => this.setState({noDeleteReasons: null})}
						rejectLabel={'CLOSE'}
						hideAccept={true}
						reasonList={this.state.noDeleteReasons}
					/>
				)}
				{!this.state.panelMode && (
					<div>
						<div className="cta-icon-btn" onClick={() => this.setState({panelMode: 'add', isCopy: false})}>
							<i className="material-icons">add_circle</i>
							<p>Add a Track</p>
						</div>
						<section className="flyout-panel-search">
							<p>Search Tracks</p>
							<input
								placeholder="Name or Tag"
								value={this.state.searchTerm}
								onChange={e => this.setState({searchTerm: e.target.value})}
							/>
						</section>
						<div className="column-list">
							{filteredTracks.map(track => {
								return (
									<div key={track._id} className="column-list-item">
										<div className="list-icon-btn no-rollover">
											<div className="column-name">{track.name}</div>
										</div>
										<div className="icons-cell-right">
											<div className="list-icon-btn">
												<i
													title="Edit Track"
													alt="Edit Track"
													className="material-icons"
													onClick={() => this.onEditTrack(track)}
												>
													mode_edit
												</i>
											</div>
											<div className="list-icon-btn">
												<i
													title="Delete Track"
													alt="Delete Track"
													className="material-icons"
													onClick={() => this.deleteTrackWarning(track)}
												>
													delete
												</i>
											</div>
										</div>
									</div>
								);
							})}
							{!this.props.tracks.length && <p>No Tracks found</p>}
						</div>
					</div>
				)}
				{this.state.panelMode && (
					<React.Fragment>
						<div className="sidebar-form-section">
							<KiInput
								name="trackName"
								label="Track Name"
								type="text"
								value={this.state.trackName}
								error={this.state.trackNameError}
								onChange={name => this.setTrackName(name)}
							/>
						</div>
						<section className="inline-column-form-buttons">
							<KiButton
								flat
								primary
								onClick={() =>
									this.setState({
										panelMode: null,
										trackName: '',
										trackNameError: null,
										currentTrack: null,
									})
								}
								label="Cancel"
							/>
							<KiButton
								raised
								primary
								disabled={!!(!this.state.trackName.length || this.state.trackNameError)}
								onClick={this.onSaveTrack}
								label="Save"
							/>
						</section>
					</React.Fragment>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	dataset: state.datasetList.selected,
	fundingVehicle: state.fundingVehicle.selected,
	tracks: state.waterfall.tracks,
});

const mapDispatchToProps = {
	fetchTracks,
	fetchModels,
	showSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlyoutManageTracks);
