import {KiModal2} from 'components';
import styles from './LastSolvedConstraintGroupModal.theme.scss';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const LastSolvedConstraintGroupModal = props => {
	const grouped = _.groupBy(props.lastSolvedConstraintGroup?.constraints, 'fvName');
	const indexed = Object.values(grouped).reduce((acc, x) => {
		const indexedArray = x.map((y, idx) => ({...y, idx: idx + 1}));
		return [...acc, ...indexedArray];
	}, []);
	const showNames = !!indexed.find(x => x.name);
	const showTypes = !!indexed.find(x => x.type);
	return (
		<KiModal2
			className={styles.root}
			active={props.isActive}
			actions={[{label: 'Close', onClick: () => props.setModalActive(false)}]}
			panelClass={styles.modalPanel}
			header={`Last Solved Constraint Group: ${props.lastSolvedConstraintGroup?.name}`}
			onClose={() => props.setModalActive(false)}
		>
			<table>
				<thead>
					<tr>
						<th>Funding Vehicle</th>
						<th>Priority</th>
						{showNames && <th>Name</th>}
						{showTypes && <th>Type</th>}
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					{indexed.map(constraint => (
						<tr key={constraint._id}>
							<td>{constraint.fvName}</td>
							<td className={styles.indexCell}>#&nbsp;{constraint.idx}</td>
							{showNames && <td>{constraint.name}</td>}
							{showTypes && <td>{constraint.type}</td>}
							<td>{constraint.description}</td>
						</tr>
					))}
				</tbody>
			</table>
		</KiModal2>
	);
};
LastSolvedConstraintGroupModal.propTypes = {
	lastSolvedConstraintGroup: PropTypes.object,
	setModalActive: PropTypes.func.isRequired,
	isActive: PropTypes.bool,
};
LastSolvedConstraintGroupModal.defaultProps = {
	lastSolvedConstraintGroup: {},
	isActive: false,
};
