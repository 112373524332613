import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
export * as icons from './icons';
import {
	showContextSidebar,
	hideContextSidebar,
	openContextSidebarPanel,
	closeContextSidebarPanel,
} from 'state/actions/App';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

const ContextSidebarIcon = ({isActive, name, icon, clickHandler, isDisabled}) => {
	return (
		<div
			className={classNames({
				'ki-nav-item': true,
				'ki-nav-item-sidebar': true,
				active: isActive,
				'ki-sidebar-icon-disabled': isDisabled,
			})}
			onClick={clickHandler}
		>
			{icon}
			<div className="tooltip">{name.toUpperCase()}</div>
		</div>
	);
};
ContextSidebarIcon.propTypes = {
	isActive: PropTypes.bool,
	name: PropTypes.string,
	icon: PropTypes.element,
	clickHandler: PropTypes.func,
	isDisabled: PropTypes.bool,
};

export class ContextSidebar extends Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
		showContextSidebar: PropTypes.func.isRequired,
		hideContextSidebar: PropTypes.func.isRequired,
		openContextSidebarPanel: PropTypes.func.isRequired,
		closeContextSidebarPanel: PropTypes.func.isRequired,
		contextSidebarActivePanel: PropTypes.string,
		contextSidebarPanelConfig: PropTypes.object,
	};

	componentDidMount() {
		this.props.showContextSidebar();
	}

	componentWillUnmount = () => {
		document.removeEventListener('mousedown', this.handleClickOutside);
		this.props.hideContextSidebar();
		this.props.closeContextSidebarPanel();
	};

	setWrapperRef = node => {
		this.wrapperRef = node;
	};

	handleClickOutside = event => {
		if (this.props.contextSidebarActivePanel) {
			// Workaround because select boxes are absolutely positioned and "outside" the current element
			const classList = event.target.classList || [];
			if (
				this.wrapperRef &&
				!this.wrapperRef.contains(event.target) &&
				!classList.toString().includes('Select-')
			) {
				this.props.closeContextSidebarPanel();
			}
		}
	};

	render() {
		const {items, contextSidebarActivePanel = {}} = this.props;
		const isViewOpen = !!contextSidebarActivePanel;
		const activeItem = items.find(i => i.name === contextSidebarActivePanel);
		return (
			<div
				ref={this.setWrapperRef}
				id="contextSidebar"
				className={classNames({
					'context-sidebar-container': true,
				})}
			>
				<TransitionGroup>
					{!!activeItem &&
						!!activeItem.element && (
							<CSSTransition
								key={1}
								classNames="context-sidebar-panel-animation"
								timeout={{enter: 500, exit: 500}}
							>
								<section className="context-sidebar-content">
									<div className="context-sidebar-panel">
										<div className="context-sidebar-panel-header">
											<p>{activeItem.name}</p>
											<i className="material-icons" onClick={this.props.closeContextSidebarPanel}>
												clear
											</i>
										</div>
										<div className="ki-flyout-panel">
											{React.cloneElement(activeItem.element, {
												closeFlyoutPanel: this.props.closeContextSidebarPanel,
												panelConfig: this.props.contextSidebarPanelConfig,
											})}
										</div>
									</div>
								</section>
							</CSSTransition>
						)}
				</TransitionGroup>
				<section className="context-sidebar-menu">
					<div>
						{items.map(item => {
							return (
								<ContextSidebarIcon
									key={`${item.name}_icon`}
									isActive={contextSidebarActivePanel === item.name}
									isDisabled={item.isDisabled}
									name={item.name}
									icon={item.icon}
									clickHandler={() => {
										contextSidebarActivePanel === item.name
											? this.props.closeContextSidebarPanel()
											: this.props.openContextSidebarPanel(item.name);
									}}
								/>
							);
						})}
					</div>
					{isViewOpen && (
						<div className="top-arrows" onClick={this.props.closeContextSidebarPanel}>
							<i className="material-icons">keyboard_arrow_right</i>
						</div>
					)}
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		contextSidebarActivePanel: state.app.contextSidebarActivePanel,
		contextSidebarPanelConfig: state.app.contextSidebarPanelConfig,
	};
};

const mapDispatchToProps = () => ({
	showContextSidebar,
	hideContextSidebar,
	openContextSidebarPanel,
	closeContextSidebarPanel,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(ContextSidebar);
