import PropTypes from 'prop-types';
import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const ViewsIcon = () => {
	return (
		<svg viewBox="0 0 198.3 252.06" className="ki-sidebar-icon">
			<path
				d="M411.64,12.28h-111A17.69,17.69,0,0,0,283,29.93v8.33h-8.33A17.69,17.69,0,0,0,257,55.92v8.34h-8.33A17.68,17.68,0,0,0,231,81.91V246.69a17.65,17.65,0,0,0,17.64,17.65h78a20.66,20.66,0,0,0,14.54-6l20.21-20h24.25A17.65,17.65,0,0,0,403.3,220.7v-8.34h8.34a17.65,17.65,0,0,0,17.65-17.65V29.93a17.68,17.68,0,0,0-17.66-17.65ZM363.38,212.71H342.95a17.65,17.65,0,0,0-17.66,17.66v20H248.65a3.73,3.73,0,0,1-3.71-3.71V81.91a3.73,3.73,0,0,1,3.71-3.72h111a3.71,3.71,0,0,1,3.71,3.7Zm26,8a3.73,3.73,0,0,1-3.72,3.71h-10a6.34,6.34,0,0,0,1.66-4.72V81.91a17.69,17.69,0,0,0-17.67-17.65H270.93V55.92a3.71,3.71,0,0,1,3.69-3.7h111a3.73,3.73,0,0,1,3.72,3.71Zm26-26a3.73,3.73,0,0,1-3.71,3.71H403.3V55.92a17.68,17.68,0,0,0-17.66-17.65H296.91V29.93a3.71,3.71,0,0,1,3.69-3.7h111a3.72,3.72,0,0,1,3.71,3.71Zm0,0"
				transform="translate(-231 -12.28)"
			/>
		</svg>
	);
};

export const CardsIcon = () => {
	return (
		<svg viewBox="0 0 32 32">
			<path
				d="M27,15 L27,23 L27,23 C27,24.1045695 26.1045695,25 25,25 L7,25 C5.8954305,25 5,24.1045695 5,23 L5,15 C5,14.4477153 5.44771525,14 6,14 C6.55228475,14 7,14.4477153 7,15 L7,23 L7,23 L25,23 L25,15 C25,14.4477153 25.4477153,14 26,14 C26.5522847,14 27,14.4477153 27,15 Z"
				id="Path"
				fill="#000000"
				fillRule="nonzero"
			/>
			<line
				x1="9.5"
				y1="19.5"
				x2="22.5"
				y2="19.5"
				id="Path-9"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<line
				x1="9.5"
				y1="16"
				x2="22.5"
				y2="16"
				id="Path-9-Copy"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<line
				x1="9.5"
				y1="12.5"
				x2="22.5"
				y2="12.5"
				id="Path-9-Copy-2"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<line
				x1="9.5"
				y1="9"
				x2="22.5"
				y2="9"
				id="Path-9-Copy-3"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export const CalculationsIcon = () => {
	return (
		<svg viewBox="0 0 259.4 259.4" className="ki-sidebar-icon">
			<path
				d="M194.22,379.22H319.44V254H194.22ZM230,307.68H247.9V289.77a8.94,8.94,0,0,1,17.88,0v17.91h17.88a8.94,8.94,0,1,1,0,17.88H265.79v17.88a8.94,8.94,0,1,1-17.88,0V325.57H230a8.94,8.94,0,0,1,0-17.88Zm0,0"
				transform="translate(-194.22 -254)"
			/>
			<path
				d="M328.4,254V379.22H453.62V254Zm89.45,71.57H364.17a8.94,8.94,0,0,1,0-17.88h53.68a8.94,8.94,0,1,1,0,17.88Zm0,0"
				transform="translate(-194.22 -254)"
			/>
			<path
				d="M328.4,513.4H453.62V388.18H328.4Zm35.77-85h53.68a8.94,8.94,0,1,1,0,17.88H364.17a8.94,8.94,0,0,1,0-17.88Zm0,26.84h53.68a8.94,8.94,0,1,1,0,17.88H364.17a8.94,8.94,0,0,1,0-17.88Zm0,0"
				transform="translate(-194.22 -254)"
			/>
			<path
				d="M194.22,513.4H319.44V388.18H194.22Zm33.94-78.64a8.94,8.94,0,1,1,12.65-12.65l16,16,16.05-16a8.94,8.94,0,1,1,12.65,12.65l-16.05,16,16.05,16.05a8.95,8.95,0,0,1-6.33,15.27,8.84,8.84,0,0,1-6.31-2.62l-16.05-16.05-16,16.05a8.94,8.94,0,0,1-12.65-12.65l16-16.05Zm0,0"
				transform="translate(-194.22 -254)"
			/>
		</svg>
	);
};

export const FiltersIcon = () => {
	return (
		<svg viewBox="0 0 549.06 554.08" className="ki-sidebar-icon">
			<path
				d="M246,380.78a34.19,34.19,0,0,1,9,23.14v252c0,15.16,18.3,22.86,29.13,12.2l70.29-80.55c9.41-11.29,14.59-16.87,14.59-28V404a34.46,34.46,0,0,1,9-23.14L579.73,162c15.11-16.42,3.48-43-18.87-43H63.21c-22.35,0-34,26.57-18.87,43Zm-33.38-6.34"
				transform="translate(-37.49 -118.96)"
			/>
		</svg>
	);
};

export const ViewSettingsIcon = () => {
	return (
		<svg viewBox="0 0 26 26" className="ki-sidebar-icon">
			<path d="M 4.1875 0 C 3.585938 0 3.1875 0.398438 3.1875 1 L 3.1875 25 C 3.1875 25.601563 3.585938 26 4.1875 26 L 16.1875 26 C 16.789063 26 17.1875 25.601563 17.1875 25 L 17.1875 21.59375 C 16.488281 21.394531 15.789063 21.210938 15.1875 20.8125 L 15.1875 24 L 5.1875 24 L 5.1875 2 L 15.1875 2 L 15.1875 5.1875 C 15.789063 4.886719 16.488281 4.601563 17.1875 4.5 L 17.1875 1 C 17.1875 0.398438 16.789063 0 16.1875 0 Z M 19.1875 6.1875 C 15.386719 6.1875 12.40625 9.199219 12.40625 13 C 12.40625 16.800781 15.488281 19.8125 19.1875 19.8125 C 22.886719 19.8125 26 16.699219 26 13 C 26 9.300781 22.988281 6.1875 19.1875 6.1875 Z M 18.1875 9 L 20.1875 9 L 20.1875 12 L 23.1875 12 L 23.1875 14 L 20.1875 14 L 20.1875 17 L 18.1875 17 L 18.1875 14 L 15.1875 14 L 15.1875 12 L 18.1875 12 Z " />
		</svg>
	);
};

export const MaterialIcon = ({name, isDisabled}) => {
	return (
		<i
			className={`${
				isDisabled
					? 'material-icons ki-sidebar-icon ki-sidebar-icon-disabled'
					: 'material-icons ki-sidebar-icon'
			}`}
		>
			{name}
		</i>
	);
};

export const AdjustmentsIcon = () => {
	return (
		<svg viewBox="0 0 25.975 25.975" className="ki-sidebar-icon">
			<path d="M9,14h5.764c1.165-1.019,2.625-1.703,4.236-1.905V12H9V14z" />
			<rect x="9" y="8" width="10" height="2" />
			<path
				d="M12.289,22H3c-0.551,0-1-0.449-1-1V4h20v8.289c0.71,0.184,1.381,0.462,2,0.823V3
			   c0-1.657-1.344-3-3-3H3C1.344,0,0,1.343,0,3v18c0,1.656,1.344,3,3,3h10.112C12.751,23.381,12.474,22.71,12.289,22z"
			/>
			<path d="M9,16v2h3.289c0.185-0.71,0.462-1.381,0.823-2H9z" />
			<rect x="5" y="12" width="2" height="2" />
			<rect x="5" y="8" width="2" height="2" />
			<rect x="5" y="16" width="2" height="2" />
			<path
				d="M20,14.025c-3.3,0-5.975,2.675-5.975,5.975c0,3.301,2.675,5.975,5.975,5.975
			   s5.975-2.674,5.975-5.975C25.975,16.7,23.3,14.025,20,14.025z M23.535,22.121l-1.414,1.414L20,21.414l-2.121,2.121l-1.414-1.414
			   L18.586,20l-2.121-2.121l1.414-1.414L20,18.586l2.121-2.121l1.414,1.414L21.414,20L23.535,22.121z"
			/>
		</svg>
	);
};

export const Tracks = () => {
	return (
		<svg
			width="600.000000pt"
			height="600.000000pt"
			viewBox="0 0 600.000000 600.000000"
			preserveAspectRatio="xMidYMid meet"
			className="ki-sidebar-icon"
		>
			<g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)" stroke="none">
				<path
					d="M1725 5976 c377 -122 767 -357 1025 -618 719 -726 628 -1755 -264
			-2998 -491 -685 -1295 -1504 -2249 -2289 -42 -35 -77 -65 -77 -67 0 -2 378 -4
			840 -4 l839 0 97 107 c233 258 516 612 748 937 633 883 1023 1717 1137 2431
			26 166 36 498 20 645 -49 433 -213 778 -515 1089 -317 324 -739 562 -1331 747
			-128 40 -148 44 -240 43 l-100 -1 70 -22z"
				/>
				<path
					d="M2422 5956 c301 -123 600 -325 843 -570 375 -377 586 -823 656 -1381
			28 -227 11 -603 -41 -895 -150 -839 -646 -1858 -1418 -2918 l-141 -192 840 0
			840 0 14 32 c34 84 142 414 200 613 193 664 312 1300 355 1900 13 191 13 675
			0 836 -65 766 -275 1343 -654 1795 -305 364 -735 633 -1252 784 -122 36 -149
			40 -243 40 l-106 0 107 -44z"
				/>
				<path
					d="M3086 5951 c54 -26 153 -84 219 -129 527 -353 939 -923 1170 -1617
			332 -996 323 -2231 -26 -3710 -27 -116 -66 -274 -88 -352 l-38 -143 753 0
			c579 0 755 3 760 12 12 20 8 845 -5 1048 -71 1110 -266 2017 -600 2798 -168
			392 -380 755 -623 1067 -106 136 -349 390 -483 505 -250 214 -559 407 -846
			529 -91 39 -102 41 -194 41 l-98 0 99 -49z"
				/>
			</g>
		</svg>
	);
};

export const KiDateManagement = () => {
	return (
		<SvgIcon viewBox="5 5 30 30">
			<path d="M17.6590298,14 L21.3187283,15.5164141 C21.8015681,15.7164812 22.1742528,16.115641 22.3407646,16.6110549 C22.5136186,17.1253381 22.5783493,17.669789 22.5308976,18.2102647 L22.5308976,18.2102647 L22.3521558,20.2461359 L26.3567222,24.2507023 C27.2144259,25.108406 27.2144259,26.4990185 26.3567222,27.3567222 C25.4990185,28.2144259 24.108406,28.2144259 23.2507023,27.3567222 L23.2507023,27.3567222 L19.2461359,23.3521558 L17.2102647,23.5308976 C16.669789,23.5783493 16.1253381,23.5136186 15.6110549,23.3407646 C15.115641,23.1742528 14.7164812,22.8015681 14.5164141,22.3187283 L14.5164141,22.3187283 L13,18.6590298 L13.8478491,17.8111808 L16.2708866,20.2342183 L18.3382205,19.3382205 L19.2387054,17.2663995 L16.8156678,14.843362 L17.6590298,14 Z M21.5,4 C22.3284271,4 23,4.67157288 23,5.5 L23,6 L25,6 C26.1045695,6 27,6.8954305 27,8 L27,19 C27,19.5522847 26.5522847,20 26,20 C25.4477153,20 25,19.5522847 25,19 L25,12 L7,12 L7,25 L17,25 C17.5522847,25 18,25.4477153 18,26 C18,26.5522847 17.5522847,27 17,27 L7,27 C5.8954305,27 5,26.1045695 5,25 L5,8 C5,6.8954305 5.8954305,6 7,6 L9,6 L9,5.5 C9,4.67157288 9.67157288,4 10.5,4 C11.3284271,4 12,4.67157288 12,5.5 L12,6 L20,6 L20,5.5 C20,4.67157288 20.6715729,4 21.5,4 Z" />
		</SvgIcon>
	);
};

export const ReplinesIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 5 32 32">
			<path d="M27,8 L27,15 L20,15 L23.22,11.78 C22.14,10.69 20.66,10 19,10 C15.69,10 13,12.69 13,16 C13,19.31 15.69,22 19,22 C21.61,22 23.83,20.33 24.65,18 L24.65,18 L26.73,18 C25.84,21.45 22.73,24 19,24 C14.58,24 11.01,20.42 11.01,16 C11.01,11.58 14.58,8 19,8 C21.21,8 23.2,8.9 24.65,10.35 L24.65,10.35 L27,8 Z M11.8020125,8.66479094 C10.0595951,10.6116447 9,13.1820815 9,16 C9,18.8184055 10.0599614,21.3892438 11.8029943,23.3356248 C8.97595473,22.1018415 7,19.2816752 7,16 C7,12.7187107 8.97548999,9.89882187 11.8020125,8.66479094 Z M7.80201247,8.66479094 C6.05959513,10.6116447 5,13.1820815 5,16 C5,18.8184055 6.05996142,21.3892438 7.80299433,23.3356248 C4.97595473,22.1018415 3,19.2816752 3,16 C3,12.7187107 4.97548999,9.89882187 7.80201247,8.66479094 Z" />
		</svg>
	);
};

export const VectorsIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 5 32 32">
			<g fillRule="evenodd">
				<path
					fillRule="nonzero"
					d="M10,6 L10,8 L6,8 L6,24 L10,24 L10,26 L5,26 C4.48716416,26 4.06449284,25.6139598 4.00672773,25.1166211 L4,25 L4,7 C4,6.48716416 4.38604019,6.06449284 4.88337887,6.00672773 L5,6 L10,6 Z"
				/>
				<path
					fillRule="nonzero"
					d="M28,6 L28,8 L24,8 L24,24 L28,24 L28,26 L23,26 C22.4871642,26 22.0644928,25.6139598 22.0067277,25.1166211 L22,25 L22,7 C22,6.48716416 22.3860402,6.06449284 22.8833789,6.00672773 L23,6 L28,6 Z"
					transform="matrix(-1 0 0 1 50 0)"
				/>
				<circle cx="13" cy="20.5" r="1.5" transform="rotate(90 13 20.5)" />
				<circle cx="19" cy="20.5" r="1.5" transform="rotate(90 19 20.5)" />
			</g>
		</svg>
	);
};

export const AssumptionsIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 3 32 32">
			<path d="M17.28,24.1 L17.28,25.92 C17.28,26.6269245 16.7069245,27.2 16,27.2 L15.99,27.2 C15.2830755,27.2 14.71,26.6269245 14.71,25.92 L14.71,24.1 L17.28,24.1 Z M21.9929833,20.8933448 L25.0171717,23.87399 L24.031205,24.8614939 L21.0070167,21.8808488 L21.9929833,20.8933448 Z M10.0050253,20.8953154 L10.9949747,21.8788781 L7.99497475,24.8595233 L7.00502525,23.8759606 L10.0050253,20.8953154 Z M16,11.9483871 C18.4814286,11.9483871 20.5,13.9539355 20.5,16.4193548 C20.5,18.9741935 17.2857143,20.8903226 17.2857143,22.8064516 L14.7142857,22.8064516 C14.7142857,20.8903226 11.5,18.9741935 11.5,16.4193548 C11.5,13.9539355 13.5185714,11.9483871 16,11.9483871 Z M28,15.2270968 L28,16.6180645 L24,16.6180645 L24,15.2270968 L28,15.2270968 Z M8,15.2270968 L8,16.6180645 L4,16.6180645 L4,15.2270968 L8,15.2270968 Z M24.031205,6.98366737 L25.0171717,7.97117134 L21.9929833,10.9518165 L21.0070167,9.96431253 L24.031205,6.98366737 Z M7.99497475,6.98563799 L10.9949747,9.96628315 L10.0050253,10.9498459 L7.00502525,7.96920072 L7.99497475,6.98563799 Z M16.7,4 L16.7,8.96774194 L15.3,8.96774194 L15.3,4 L16.7,4 Z" />
		</svg>
	);
};

export const PricingAssumptionsIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 3 32 32">
			<path d="M7.322 9.515c3.282-4.41 9.442-5.702 14.261-2.806 2.684 1.612 4.397 4.209 4.993 7.045h0l3.27-.815-3.348 5.572-5.572-3.348 3.538-.882c-.463-2.3-1.835-4.414-3.998-5.714-1.092-.656-2.273-1.043-3.463-1.178l-.001 2.629.406.005c1.771.428 2.697 1.718 2.826 3.152l.012.188h-1.954c-.053-1.096-.637-1.841-2.21-1.841-1.494 0-2.387.666-2.387 1.622 0 .833.645 1.367 2.652 1.885s4.155 1.367 4.155 3.857c0 1.797-1.37 2.787-3.094 3.112h0l-.406-.003.001 2.606c2.149-.254 4.185-1.306 5.633-3.037l.172-.213 1.876 1.127c-3.282 4.41-9.442 5.702-14.261 2.806-2.684-1.612-4.397-4.209-4.993-7.045h0l-3.27.815 3.348-5.572 5.572 3.348-3.538.882c.463 2.3 1.835 4.414 3.998 5.714 1.092.656 2.273 1.043 3.464 1.179l-.001-2.624-.246-.001c-1.632-.346-3.035-1.356-3.235-3.139l-.018-.218h1.945c.097 1.034.813 1.841 2.635 1.841 1.954 0 2.387-.964 2.387-1.569 0-.815-.442-1.587-2.652-2.112-2.467-.587-4.155-1.595-4.155-3.62 0-1.692 1.379-2.796 3.094-3.164h0l.246.002.001-2.604c-2.149.254-4.186 1.306-5.634 3.037l-.172.213-1.876-1.127z" />
		</svg>
	);
};

export const NotionalPoolsIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="4 3 32 32">
			<path d="M3.98302768,4 L4.15930047,4.00509269 C5.67578152,4.09295962 6.89006806,5.30724616 6.97793499,6.82372721 L6.98302768,7 L6.98302768,8.999 L13.9990277,8.999 L14,7 L13.9932723,6.88337887 C13.9399506,6.42429701 13.575703,6.06004937 13.1166211,6.00672773 L13,6 L12,6 L12,4 L13,4 L13.1762728,4.00509269 C14.6927538,4.09295962 15.9070404,5.30724616 15.9949073,6.82372721 L16,7 L16,19 L14,19 L13.9990277,15.999 L6.98302768,15.999 L6.98302768,19 L4.98302768,19 L4.98302768,7 L4.97629995,6.88337887 C4.92297831,6.42429701 4.55873067,6.06004937 4.0996488,6.00672773 L3.98302768,6 L2.98302768,6 L2.98302768,4 L3.98302768,4 Z M13.9990277,10.999 L6.98302768,10.999 L6.98302768,13.999 L13.9990277,13.999 L13.9990277,10.999 Z" />
			<path d="M27.5678476,8.63793384 L29.4321524,9.36206616 L28.4990731,11.7454204 L28.4073704,11.9752076 C27.0894078,15.2695161 25.925424,17.7723129 24.3681458,20.4963092 C22.4205257,23.9030931 20.5695572,24.9745342 17.7263486,23.2686091 C17.5713778,23.1756266 16.9214338,22.7206171 16.7512332,22.6123849 C16.1135494,22.2068756 15.5642022,22 15,22 C14.711106,22 14.4245906,22.1519315 13.7799737,22.657764 L13.624695,22.7808688 C12.5055254,23.6762046 11.9388833,24 11,24 C10.1237089,24 9.57168131,23.7179382 8.59204586,22.9523888 L8.37530495,22.7808688 C7.61947464,22.1762046 7.31111666,22 7,22 C6.71110596,22 6.42459058,22.1519315 5.7799737,22.657764 L5.62469505,22.7808688 C4.58013667,23.6165155 4.01689129,23.9542871 3.18308746,23.9956093 L3,24 L3,22 C3.28889404,22 3.57540942,21.8480685 4.2200263,21.342236 L4.37530495,21.2191312 C5.49447464,20.3237954 6.06111666,20 7,20 C7.87629112,20 8.42831869,20.2820618 9.40795414,21.0476112 L9.62469505,21.2191312 C10.3805254,21.8237954 10.6888833,22 11,22 C11.288894,22 11.5754094,21.8480685 12.2200263,21.342236 L12.375305,21.2191312 C13.4944746,20.3237954 14.0611167,20 15,20 C16.0090351,20 16.894864,20.3335894 17.8244388,20.924715 L18.7158987,21.5282107 L18.7553401,21.5536232 C20.3750265,22.525435 21.1661636,22.0674819 22.6318542,19.5036908 C24.0371215,17.0455924 25.1123021,14.7713071 26.2951814,11.8650498 L27.0273403,10.0260631 L27.5678476,8.63793384 Z" />
			<path d="M28.7928932,19.2928932 L30.2071068,20.7071068 L30.0568059,20.870837 C29.5370596,21.4506888 27.9418233,23.2894693 27.535316,23.7459257 L27.1434968,24.1828015 C26.6054027,24.7782026 26.1288391,25.2853745 25.7071068,25.7071068 C23.5511096,27.863104 22.5411868,28.5 20.4717395,28.5 C19.6522763,28.5 19.0370793,28.2523303 17.7033021,27.5166436 L17.4445727,27.3729247 C16.2770098,26.7204041 15.7724361,26.5 15.3931158,26.5 C15.098656,26.5 14.7721319,26.6475748 14.1007173,27.0944999 L13.320776,27.6240767 C12.3483693,28.2676139 11.7964589,28.5 11,28.5 C10.1237089,28.5 9.57168131,28.2179382 8.59204586,27.4523888 L8.37530495,27.2808688 C7.61947464,26.6762046 7.31111666,26.5 7,26.5 C6.71110596,26.5 6.42459058,26.6519315 5.7799737,27.157764 L5.62469505,27.2808688 C4.58013667,28.1165155 4.01689129,28.4542871 3.18308746,28.4956093 L3,28.5 L3,26.5 C3.28889404,26.5 3.57540942,26.3480685 4.2200263,25.842236 L4.37530495,25.7191312 C5.49447464,24.8237954 6.06111666,24.5 7,24.5 C7.87629112,24.5 8.42831869,24.7820618 9.40795414,25.5476112 L9.62469505,25.7191312 C10.3805254,26.3237954 10.6888833,26.5 11,26.5 C11.2944598,26.5 11.6209839,26.3524252 12.2923985,25.9055001 L13.0723398,25.3759233 C14.0447465,24.7323861 14.5966569,24.5 15.3931158,24.5 C16.212579,24.5 16.827776,24.7476697 18.1615532,25.4833564 L18.4202826,25.6270753 C19.5878455,26.2795959 20.0924192,26.5 20.4717395,26.5 C21.7962585,26.5 22.4339438,26.1312629 24.0815586,24.5030447 L24.2928932,24.2928932 C24.6217622,23.9640242 24.9924092,23.5730921 25.4062094,23.1206455 L26.1850312,22.2535492 C26.6562652,21.7174693 27.7912695,20.4116354 28.4056203,19.7196133 L28.7928932,19.2928932 Z" />
		</svg>
	);
};

MaterialIcon.propTypes = {
	name: PropTypes.string,
	isDisabled: PropTypes.bool,
};

export default {
	ViewsIcon,
	CardsIcon,
	CalculationsIcon,
	FiltersIcon,
	MaterialIcon,
	ViewSettingsIcon,
	AdjustmentsIcon,
	Tracks,
	KiDateManagement,
	ReplinesIcon,
	VectorsIcon,
	AssumptionsIcon,
	PricingAssumptionsIcon,
	NotionalPoolsIcon,
};
