// Lib Imports
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _get from 'lodash/get';
import _ from 'lodash';

// App Imports
import {fetchDataset} from 'containers/datasetList/actions';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {fetchAllCards, setCurrentCard} from 'containers/dashboards/actions';
import {fetchWaterfallMappingsByDatasetId} from 'containers/mappings/actions';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {fetchAllTemplates} from 'containers/debtInputMaintenance/actions';

// Local Import
import {
	fetchBookmarks,
	fetchDefaultView,
	fetchBookmark,
	setCohortColumn,
	setDate,
	setFundingVehicle,
	setSort,
	updateManulaInputDebtData,
	fetchDebtData,
} from './actions';
import ContextSidebar from './components/ContextSidebar';
import styles from './debt.theme.scss';
import DebtResultsTable from './components/DebtResultsTable';
import DebtFilters from './components/DebtFilters';
import DebtHeader from './components/DebtHeader';
import _isEqual from 'lodash/isEqual';

export class Debt extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		dataset: PropTypes.object.isRequired,
		setCohortColumn: PropTypes.func,
		setDate: PropTypes.func,
		fetchFundingVehicleList: PropTypes.func,
		fetchWaterfallMappingsByDatasetId: PropTypes.func,
		fetchAllTemplates: PropTypes.func,
		fundingVehicleList: PropTypes.array,
		debtExploration: PropTypes.object.isRequired,
		match: PropTypes.object,
		fetchDefaultView: PropTypes.func,
		location: PropTypes.object,
		fetchDataset: PropTypes.func,
		fetchDebtData: PropTypes.func,
		fetchBookmark: PropTypes.func,
		fetchBookmarks: PropTypes.func.isRequired,
		setSort: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired,
		updateManulaInputDebtData: PropTypes.func.isRequired,
		allTemplates: PropTypes.array,
	};

	static defaultProps = {
		fundingVehicleList: [],
	};

	state = {
		searchTerm: '',
		saveDialogOpen: false,
		bookmark: {
			explorerData: {
				quickFilters: {},
			},
		},
		showCardEditor: false,
		outputOptValue: '',
		dataRows: [],
		dataColumnInfo: [],
	};

	componentDidMount() {
		const {
			match,
			location: {search},
		} = this.props;
		document.title = `${this.props.app.kiVersion} - Debt`;
		this.props.fetchFundingVehicleList(match.params.datasetId);
		if (match.params.datasetId) {
			const datasetId = match.params.datasetId;
			const bookmarkId = search && search.startsWith('?bookmarkId=') && search.substr(12, 24);
			this.props.fetchDataset();
			this.props.fetchBookmarks(datasetId);
			const getBookmark = async bookmarkId => {
				let bookmark;
				if (bookmarkId) {
					bookmark = await this.props.fetchBookmark(bookmarkId, false).catch(() => null);
				}
				return bookmark || this.props.fetchDefaultView(datasetId, false);
			};
			return getBookmark(bookmarkId).then(
				bookmark =>
					!bookmark.error && this.loadFilterConfigs(datasetId, _.get(bookmark, 'settings.snapshotDate'))
			);
		}
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			const datasetId =
				this.props.match.params.datasetId !== prevProps.match.params.datasetId &&
				this.props.match.params.datasetId;
			const bookmarkId =
				this.props.location.search.startsWith('?bookmarkId=') &&
				prevProps.location.search !== this.props.location.search &&
				this.props.location.search.substr(12, 24);
			const getBookmark = async bookmarkId => {
				let bookmark;
				if (bookmarkId) {
					bookmark = await this.props.fetchBookmark(bookmarkId, false).catch(() => null);
				}
				return bookmark || this.props.fetchDefaultView(datasetId, false);
			};
			if (datasetId) {
				this.props.fetchDataset(datasetId, false);
				this.props.fetchBookmarks(datasetId);
			}
			if (datasetId || bookmarkId) {
				return getBookmark(bookmarkId).then(() =>
					this.loadFilterConfigs(datasetId, dateToShortDate(new Date()))
				);
			}
		}
	}

	loadFilterConfigs = (datasetId, snapshotDate = dateToShortDate(new Date())) => {
		if (!datasetId) {
			this.props.setDate(snapshotDate);
			return this.props.fetchDebtData();
		}
		return Promise.all([
			this.props.fetchWaterfallMappingsByDatasetId(datasetId),
			this.props.fetchAllTemplates(datasetId),
		]).then(() => {
			this.props.setDate(snapshotDate);
			this.props.fetchDebtData();
		});
	};

	drillDownFundingVehicle = fvName => {
		const {fundingVehicleList, match} = this.props;
		const targetFV = fundingVehicleList.find(fv => fv.name === fvName && fv.datasetId === match.params.datasetId);
		this.props.history.push(`/dealStructures/${targetFV.datasetId}/${targetFV._id}`);
	};

	setOutputOption = outputOpt => {
		if (this.props.debtExploration.waterfallData.length) {
			this.setState({outputOptValue: outputOpt});
		}
	};

	setOutputItem = outputItem => {
		this.setState({outputItemValue: outputItem});
	};

	render() {
		const {
			match: {params = {}},
			dataset,
			debtExploration: {
				view,
				view: {settings},
				error,
				isLoading,
				tableDataDirty,
				data,
			},
			fundingVehicleList,
			setCohortColumn,
			setDate,
			setSort,
		} = this.props;

		if (dataset.datasetId !== params.datasetId) {
			return null;
		}

		let dataRows,
			dataColumns,
			targetIndex = null;
		const waterfallData = this.props.debtExploration.waterfallData;
		if (this.state.outputItemValue) {
			targetIndex = waterfallData.findIndex(data => data.name === this.state.outputItemValue);
		} else if (this.state.outputOptValue) {
			targetIndex = waterfallData.findIndex(data => data.tag === this.state.outputOptValue);
		}

		if (targetIndex !== null && targetIndex >= 0) {
			dataColumns = waterfallData[targetIndex].response.columns;
			dataRows = waterfallData[targetIndex].response.rows;
		} else {
			dataRows = data.rows;
			dataColumns = data.columnInfo || data.columns;
		}

		return (
			<div className={styles.root}>
				<div className={styles.containerBody}>
					<DebtHeader match={this.props.match} history={this.props.history} />
					<div className={styles.mainPanel}>
						<DebtFilters
							datasetId={params.datasetId}
							setCohortColumn={setCohortColumn}
							setOutputOption={this.setOutputOption}
							setOutputItem={this.setOutputItem}
							setDate={setDate}
							fundingVehicleList={fundingVehicleList}
							settings={settings}
							searchTerm={this.state.searchTerm}
							setSearchTerm={input => this.setState({searchTerm: input})}
							waterfallData={waterfallData}
							tableDataDirty={tableDataDirty}
							isLoading={isLoading}
						/>
						<div className={styles.dataResults}>{this.props.debtExploration.dateContextMessage}</div>
						<DebtResultsTable
							error={error}
							isLoading={isLoading}
							searchTerm={this.state.searchTerm}
							dataRows={dataRows}
							dataColumns={dataColumns}
							settings={settings}
							drillDownFundingVehicle={this.drillDownFundingVehicle}
							onSortChange={setSort}
							updateManulaInputDebtData={this.props.updateManulaInputDebtData}
							allTemplates={this.props.allTemplates}
							tableDataDirty={this.props.debtExploration.tableDataDirty}
						/>
					</div>
				</div>
				<ContextSidebar datasetId={params.datasetId} bookmark={view} />
			</div>
		);
	}
}
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchDataset: _id => dispatch(fetchDataset(_id || ownProps.match.params.datasetId, false)),
		fetchBookmarks: _id => dispatch(fetchBookmarks(_id || ownProps.match.params.datasetId)),
		fetchDefaultView: (_id, fetchData) =>
			dispatch(fetchDefaultView(_id || ownProps.match.params.datasetId, fetchData)),
		setDate: (date, fetchData) => dispatch(setDate(date, fetchData)),
		...bindActionCreators(
			{
				fetchDebtData,
				setCohortColumn,
				setFundingVehicle,
				fetchFundingVehicleList,
				fetchWaterfallMappingsByDatasetId,
				fetchBookmark,
				fetchAllTemplates,
				fetchAllCards,
				setCurrentCard,
				setSort,
				updateManulaInputDebtData,
			},
			dispatch
		),
	};
};

const mapStateToProps = (state, ownProps) => ({
	app: state.app,
	allTemplates: state.debtInputMaintenance.savedTemplates,
	dataset: state.datasetList.selected,
	debtExploration: state.debtExploration,
	fundingVehicleList: state.fundingVehicleList.data.filter(
		fv => fv.datasetId === _get(ownProps, 'match.params.datasetId', '') && !fv.isUnencumbered
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Debt);
