import PropTypes from 'prop-types';
import React, {Component} from 'react';
import _get from 'lodash/get';
import {connect} from 'react-redux';
import {showSnackbar} from 'state/actions/Snackbar';
import FlyoutConfirmPanel from 'components/FlyoutConfirmPanel';
import BlocksModal from './modals/BlocksModal';
import 'components/FlyoutCalculations/components/ColumnList/ColumnList.scss';
import './ModelSettings.scss';
import {
	fetchBlocksForFundingVehicle,
	setCurrentBlock,
	deleteBlockForFundingVehicle,
	addBlockToTrack,
	fetchTracksForFundingVehicle,
} from 'containers/fundingVehicle/actions';
import {getSortAscFuncByField} from 'ki-common/utils/sorters';

export class ManageBlocks extends Component {
	static propTypes = {
		dataset: PropTypes.object,
		waterfallColumns: PropTypes.array.isRequired,
		showSnackbar: PropTypes.func,
		setCurrentBlock: PropTypes.func.isRequired,
		addBlockToTrack: PropTypes.func,
		fundingVehicle: PropTypes.object,
		allBlocks: PropTypes.array,
		fetchBlocksForFundingVehicle: PropTypes.func,
		deleteBlockForFundingVehicle: PropTypes.func,
		fetchTracksForFundingVehicle: PropTypes.func,
		addBlockOpen: PropTypes.bool,
	};

	static defaultProps = {
		allBlocks: [],
		waterfallColumns: [],
	};

	state = {
		searchTerm: '',
		allBlocks: this.props.allBlocks,
		showDeleteConfirm: false,
		currentBlock: null,
		blockOverlayActive: this.props.addBlockOpen,
	};

	componentDidMount() {
		if (this.props.fundingVehicle) {
			return this.props.fetchBlocksForFundingVehicle(this.props.fundingVehicle._id);
		}
	}

	onUpdate = () => {
		if (this.props.fundingVehicle) {
			this.props.fetchBlocksForFundingVehicle(this.props.fundingVehicle._id).then(blocks => {
				this.setState({allBlocks: blocks});
			});
		}
	};

	onSearch = event => {
		const searchTerm = _get(event, 'target.value', '');
		const searchTermCaseless = searchTerm.toUpperCase();
		this.setState({
			searchTerm: searchTerm,
			allBlocks: this.props.allBlocks.filter(block => {
				return block.name.toUpperCase().includes(searchTermCaseless.toUpperCase());
			}),
		});
	};

	createBlock = () => {
		this.setState({
			currentBlock: null,
			blockOverlayActive: true,
		});
	};

	addBlockToTrack = block => {
		this.props.addBlockToTrack(block);
	};

	// copyBlock = block => {
	// 	this.props.setCurrentBlock({...block, _id: null, name: `${block.name}_copy`});
	// };

	// editBlock = block => {
	// 	this.props.setCurrentBlock(block);
	// };

	copyBlock = block => {
		this.setState({
			currentBlock: {...block, _id: null, name: `${block.name}_copy`},
			blockOverlayActive: true,
		});
	};

	editBlock = block => {
		this.setState({
			currentBlock: block,
			blockOverlayActive: true,
		});
	};

	deleteBlock = () => {
		const {currentBlock} = this.state;
		return this.props
			.deleteBlockForFundingVehicle(currentBlock._id)
			.then(() => {
				this.props.fetchBlocksForFundingVehicle(this.props.fundingVehicle._id);
				this.props.fetchTracksForFundingVehicle(this.props.fundingVehicle._id);
				this.props.showSnackbar(`Block ${currentBlock.name} successfully deleted.`);
				this.setState({
					showDeleteConfirm: false,
				});
				this.onUpdate();
			})
			.catch(() => {
				this.props.showSnackbar('Error deleting block.');
			});
	};

	render() {
		return (
			<div className="calculation-flyout-root">
				<header className="flyout-panel-title">MANAGE BLOCKS</header>
				{this.state.showDeleteConfirm && (
					<FlyoutConfirmPanel
						header={'Delete Block'}
						message={`Are you sure you want to delete the block ${
							this.state.currentBlock.name
						} from all tracks?`}
						acceptFunc={() => this.deleteBlock()}
						rejectFunc={() => this.setState({showDeleteConfirm: false})}
						acceptLabel={'Delete'}
						rejectLabel={'Cancel'}
					/>
				)}
				<React.Fragment>
					<div className="cta-icon-btn" onClick={() => this.createBlock()}>
						<i className="material-icons">add_circle</i>
						<p>Add a Block</p>
					</div>
					<section className="flyout-panel-search">
						<p>Search Blocks</p>
						<input
							placeholder="Name or Tag"
							value={this.state.searchTerm}
							onChange={e => this.onSearch(e)}
						/>
					</section>
					<div className="column-list">
						{this.state.allBlocks.map(block => {
							return (
								<div key={block._id} className="column-list-item">
									<div className="list-icon-btn no-rollover">
										<div className="column-name">{block.name}</div>
									</div>
									<div className="icons-cell-right">
										<div className="list-icon-btn">
											<i
												title="Copy Block"
												alt="Copy Block"
												className="material-icons"
												onClick={() => this.copyBlock(block)}
											>
												content_copy
											</i>
										</div>
										<div className="list-icon-btn">
											<i
												title="Edit Block"
												alt="Edit Block"
												className="material-icons"
												onClick={() => this.editBlock(block)}
											>
												mode_edit
											</i>
										</div>
										<div className="list-icon-btn">
											<i
												title="Delete Block"
												alt="Delete Block"
												className="material-icons"
												onClick={() => {
													this.setState({
														currentBlock: block,
														showDeleteConfirm: true,
													});
												}}
											>
												delete
											</i>
										</div>
									</div>
								</div>
							);
						})}
					</div>
					{this.props.allBlocks === 0 && <p>No Blocks found</p>}
				</React.Fragment>
				{this.state.blockOverlayActive && (
					<BlocksModal
						{...this.props}
						dataset={this.props.dataset}
						waterfallColumns={this.props.waterfallColumns}
						allBlocks={this.props.allBlocks}
						active={this.state.blockOverlayActive}
						currentBlock={this.state.currentBlock}
						onCancel={() => this.setState({currentBlock: null, blockOverlayActive: false})}
						onUpdate={this.onUpdate}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	dataset: state.datasetList.selected,
	fundingVehicle: state.fundingVehicle.selected,
	allBlocks: state.fundingVehicle.structures.allBlocks.sort(getSortAscFuncByField('name')),
});

const mapDispatchToProps = () => ({
	showSnackbar,
	setCurrentBlock,
	fetchBlocksForFundingVehicle,
	deleteBlockForFundingVehicle,
	addBlockToTrack,
	fetchTracksForFundingVehicle,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(ManageBlocks);
