import PropTypes from 'prop-types';
import React, {useState, useEffect, useCallback} from 'react';
import KiFontIcon from 'components/KiFontIcon';
import './SchemaBlock.scss';
import {categorySchemasApi} from 'api';

export const SchemaBlock = props => {
	const [schema, setSchema] = useState(null);
	const fetchSchema = useCallback(
		async (category, snapshots) => {
			// fetchCategorySchemaBySnapshotDate expects a whole dataset object, but only needs category and snapshots array
			if (!category || !snapshots?.length) return;
			setSchema(await categorySchemasApi.fetchCategorySchemaBySnapshotDate({category, snapshots}));
		},
		[setSchema]
	);

	useEffect(
		() => {
			if (props.isVisible) {
				fetchSchema(props.category, props.snapshots)
					// eslint-disable-next-line no-console
					.catch(e => console.error(e));
			}
		},
		[fetchSchema, props.isVisible, props.category, props.snapshots]
	);

	if (!props.isVisible || !schema?.columns?.length) return null;
	return (
		<div className="dataset-list-schema-block">
			<section className="block-header">
				<p>Schema ({schema?.columns?.length} Columns)</p>
				<KiFontIcon value="close" className="close-icon" onClick={() => props.handleEditSchemaClick()} />
			</section>
			<section className="dataset-schema-list-container">
				<table>
					<thead>
						<tr className="schema-item header">
							<th>Display Name</th>
							<th>Map Column</th>
							<th>ID</th>
							<th>Map Type</th>
							<th>Data Type</th>
							<th className="expando-column" />
						</tr>
					</thead>
					<tbody>
						{schema?.columns?.map(column => (
							<tr key={column.id} className="schema-item">
								<td>{column.displayName}</td>
								<td>{column.mapColumn}</td>
								<td>{column.id}</td>
								<td>{column.mapType}</td>
								<td>{column.dataType}</td>
								<td className="expando-column" />
							</tr>
						))}
					</tbody>
				</table>
			</section>
		</div>
	);
};

SchemaBlock.propTypes = {
	category: PropTypes.string,
	snapshots: PropTypes.array,
	isVisible: PropTypes.bool,
	handleEditSchemaClick: PropTypes.func,
};

SchemaBlock.defaultProps = {
	snapshots: [],
	isVisible: false,
};

export default SchemaBlock;
