import {SNACKBAR_SHOW, SNACKBAR_HIDE} from 'state/actions/Snackbar';

const initialState = {
	action: '',
	type: 'accept',
	msg: '',
	active: false,
	autoClose: true,
	className: '',
};

function Snackbar(state = initialState, action) {
	switch (action.type) {
		case SNACKBAR_SHOW:
			return {...state, msg: '', ...action.options, active: true};

		case SNACKBAR_HIDE:
			return initialState;

		default:
			return state;
	}
}

export default Snackbar;
