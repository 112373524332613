import React from 'react';
import PropTypes from 'prop-types';
import styles from './DebtFormulasList.theme.scss';
import _ from 'lodash';
import expressionUtil from 'ki-common/utils/expressionUtil';
import calcColumnUtils from 'ki-common/utils/calcColumns';
import systemColumns from 'ki-common/options/systemColumns';
import KiProgressBar from 'components/KiProgressBar';

class DebtFormulasList extends React.Component {
	static propTypes = {
		fundingVehicles: PropTypes.array,
		columns: PropTypes.array,
		entities: PropTypes.array, // Tranches, Credit Supports, Fees, Triggers
		entityType: PropTypes.string,
		onCopy: PropTypes.func,
		onEdit: PropTypes.func,
		onDelete: PropTypes.func.isRequired,
		formulas: PropTypes.array,
		agencyRatings: PropTypes.array,
		isAdmin: PropTypes.bool,
		isWaterfall: PropTypes.bool,
		triggersList: PropTypes.array,
		calcEntityLevel: PropTypes.string,
		isDate: PropTypes.bool,
	};

	static defaultProps = {
		fundingVehicles: [],
		columns: [],
		entities: [],
		formulas: [],
		agencyRatings: [],
		triggersList: [],
		isAdmin: false,
		isWaterfall: false,
		isDate: false,
	};

	/**
	 * @param {{op: string, left: object, right: object}} formula - node form of debt calc, expecting op, left right
	 * @param {object[]} [columns = []] - columns used to match against potential selections for date calc values
	 * @returns {string} user-readable text explaining the date calc formula for the provided node
	 */
	getDateCalcText = (formula, columns = []) => {
		// workaround for debt date calcs because they store formulas in their binary tree node format instead of formula arrays
		const hydratedNode = calcColumnUtils.embedAssetColumnsForFormula(columns, _.cloneDeep(formula));
		/*
			TODO: get rid of this workaround if possible, embedAssets clears out offset on the nodes.
			have to check both left.value.offset and left.offset because if coming from the editor it's on the node (and saved that way)
			but if coming from hydrated columns, the offset is on the column
		 */
		const leftOffset = parseInt(_.get(formula, 'left.value.offset', _.get(formula, 'left.offset')));
		const rightOffset = parseInt(_.get(formula, 'right.value.offset', _.get(formula, 'right.offset')));
		if (leftOffset && _.get(hydratedNode, 'left.value.displayName')) {
			// we append offset info on display name so the nodeToString function doesn't need changes for this one edge case.
			hydratedNode.left.value.displayName += ` (Offset ${leftOffset > 0 ? '+' : ''}${leftOffset} Period${
				Math.abs(leftOffset) !== 1 ? 's' : ''
			})`;
		}
		if (rightOffset && _.get(hydratedNode, 'right.value.displayName')) {
			hydratedNode.right.value.displayName += ` (Offset ${rightOffset > 0 ? '+' : ''}${rightOffset} Period${
				Math.abs(rightOffset) !== 1 ? 's' : ''
			})`;
		}

		return calcColumnUtils.nodeToString(hydratedNode);
	};

	getCalculationText = formula => {
		if (this.props.isDate) {
			return this.getDateCalcText(formula, this.props.columns);
		}
		const colsWithOptions = _.concat(
			this.props.columns,
			systemColumns.trancheOptions,
			systemColumns.feeOptions,
			systemColumns.collateralOptions,
			systemColumns.creditSupportOptions
		);
		return expressionUtil.formulaToString(
			formula,
			[...colsWithOptions, ...this.props.triggersList],
			this.props.entities,
			this.props.agencyRatings
		);
	};

	getFVName = value => {
		const match = this.props.fundingVehicles.find(fv => fv._id === value);
		if (match) {
			return match.name || '';
		}
		return value;
	};

	getEntityNames = ids => {
		if (ids.length === 1 && ids[0] === 'all') {
			return 'All';
		}
		const toReturn = this.props.entities
			.filter(entity => ids.includes(entity._id))
			.map(match => match.name || '')
			.join(', ');
		return toReturn;
	};

	renderControls = (i, idx) => {
		return (
			<td style={{padding: '.5rem 1rem .5rem 0', verticalAlign: 'middle'}}>
				<div style={{display: 'flex', justifyContent: 'flex-end'}}>
					{!this.props.isWaterfall && (
						<i
							className="material-icons"
							style={{cursor: 'pointer'}}
							onClick={() => this.props.onCopy(i, idx)}
						>
							content_copy
						</i>
					)}
					<i className="material-icons" style={{cursor: 'pointer'}} onClick={() => this.props.onEdit(i, idx)}>
						edit
					</i>
					<i className="material-icons" style={{cursor: 'pointer'}} onClick={() => this.props.onDelete(idx)}>
						delete
					</i>
				</div>
			</td>
		);
	};

	render() {
		const {isAdmin, columns, entities, formulas} = this.props;
		const showEntity = this.props.entityType !== 'fundingVehicle';
		// Prevent the formulas from displaying until we have valid data to populate the ID's
		if (formulas.length && (_.isEmpty(columns) || (showEntity && !entities)) && !!this.props.calcEntityLevel) {
			return (
				<div style={{flex: '1 1 auto', width: '100%'}}>
					<KiProgressBar />
				</div>
			);
		}

		return (
			<React.Fragment>
				{isAdmin && (
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<div
							style={{padding: '1rem 0'}}
							className={`cta-icon-btn`}
							onClick={() => this.props.onEdit({formula: [], fundingVehicles: []})}
						>
							<i className="material-icons">add_circle</i>
							<p>Add a Formula</p>
						</div>
					</div>
				)}
				<div style={{flex: '1 1 auto', width: '100%'}}>
					<table>
						<thead style={{borderBottom: '1px solid #ccc'}}>
							<tr>
								<th style={{textAlign: 'left', padding: '.5rem 0'}}>
									<br />Calculation
								</th>
								<th style={{textAlign: 'left', padding: '.5rem 0'}}>
									Funding<br />Vehicle
								</th>
								{showEntity && <th style={{textAlign: 'left', padding: '.5rem 0'}}>Entity</th>}
								{isAdmin && <th>&nbsp;</th>}
							</tr>
						</thead>
						<tbody>
							{this.props.formulas.map((i, idx) => {
								return (
									<tr key={idx} style={{borderBottom: '1px solid #ccc'}}>
										<td className={styles.formulaTd}>{this.getCalculationText(i.formula)}</td>
										<td className={styles.fvTd}>
											{Array.isArray(i.fundingVehicles)
												? this.getFVName(_.get(i, 'fundingVehicles[0]', ''))
												: this.getFVName(_.get(i, 'fundingVehicle', ''))}
										</td>
										{showEntity && (
											<td className={styles.fvTd}>
												{this.getEntityNames(_.get(i, 'entityIds', []))}
											</td>
										)}
										{isAdmin && this.renderControls(i, idx)}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</React.Fragment>
		);
	}
}

export default DebtFormulasList;
