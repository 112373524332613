import ReactGA from 'react-ga';

// category: (String) Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
// action: (String) Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
// label: (String) Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
// value: (Int) Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.
export const sendEvent = (category, action, label, value) => {
	const args = {
		category: category,
		action: action
	};
	if (label) {
		args.label = label;
	}
	if (value) {
		args.value = value;
	}
	ReactGA.event(args);
};

// category: (String) Required. A string for categorizing all user timing variables into logical groups.
// var:	(String) Required. Name of the variable being recorded.
// value: (Int) Required. Number of milliseconds elapsed time to report.
// label: (String) Optional. It can improved visibility in user timing reports.
export const sendTimingEvent = (category, variable, value, label) => {
	const args = {
		category: category,
		variable: variable,
		value: value,
	};

	if (label) {
		args.label = label;
	}
	ReactGA.timing(args);
};

// label: (String) Required. Description of where the outbound link points to. Either as a URL, or a string
export const sendOutboundLinkEvent = label => {
	ReactGA.outboundLink({
		label: label
	});
};

// description: (String) Optional. Description of what happened.
// fatal: (String) Optional. Set to true if it was a fatal exception.
export const sendErrorEvent = (error, fatal=false) => {
	ReactGA.exception({
		description: error,
		fatal: fatal
	});
};

// modalName: (String) E.g. 'login', 'read-terms-and-conditions'
export const sendModalView = modalName => {
	ReactGA.modalview(modalName);
};

export const ga = (...args) => {
	ReactGA.ga(...args);
};

export default {
	sendEvent,
	sendTimingEvent,
	sendOutboundLinkEvent,
	sendErrorEvent,
	sendModalView,
	ga,
};
