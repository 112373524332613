import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ColumnList} from '../ColumnList';
import KiTabs from 'components/KiTabs';
import KiTab from 'components/KiTabs/KiTab';
import {datasetsApi, columnServiceApi} from 'api';
import Select from 'react-select';
import FlyoutConfirmPanel from 'components/FlyoutConfirmPanel';
import _get from 'lodash/get';
import _ from 'lodash';
// import './ColumnList.scss';
import AsOfDateColumns from '../AsOfDateColumns';
import debtOptions from 'ki-common/options/debt';

export class ManageColumns extends Component {
	static propTypes = {
		columns: PropTypes.array.isRequired,
		datasetId: PropTypes.string.isRequired,
		setPanelMode: PropTypes.func.isRequired,
		isActive: PropTypes.bool.isRequired,
		showSnackbar: PropTypes.func.isRequired,
		fetchDataset: PropTypes.func.isRequired,
		setColumnToEdit: PropTypes.func,
		setMode: PropTypes.func.isRequired,
		user: PropTypes.object,
		userList: PropTypes.array,
	};

	static defaultProps = {
		columns: [],
		datasetId: '',
		isActive: false,
		setPanelMode: () => ({}),
		showSnackbar: () => ({}),
	};

	constructor(props) {
		super(props);
		const {columns, user} = this.props;
		this.state = {
			selectedUserId: user.groups.includes('SystemAdmins') ? 'global' : user.userId,
			columns: columns,
			tabIndex: 0,
			searchTerm: '',
			showDeleteConfirm: false,
			colDependents: [],
			noDeleteReasons: false,
			currentCalculation: null,
			debtCalcEntitySelection: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (!_.isEqual(prevProps, this.props)) {
			this.setState({
				columns: this.props.columns,
			});
			if (!this.props.isActive) {
				this.setState({searchTerm: ''});
			}
		}
	}

	handleTabChange = value => {
		this.setState({
			tabIndex: value,
			columns: this.props.columns,
			searchTerm: '',
		});
	};

	onSearch = e => {
		const {columns} = this.props;
		const searchTerm = e.target.value;
		this.setState({
			searchTerm: searchTerm,
			columns: columns.filter(col => {
				return (
					col.displayName.toUpperCase().includes(searchTerm.toUpperCase()) ||
					(col.tags &&
						col.tags.some(t => t.toUpperCase && t.toUpperCase().includes(searchTerm.toUpperCase())))
				);
			}),
		});
	};

	onCopy = (calc, params) => {
		const modeType = calc.formulas ? 'debtCalculation' : calc.columnType === 'asset' ? 'asset' : 'summary';
		const fullyHydrateColumn = calc.columnFormType === 'conditional';
		columnServiceApi
			.getColumnByIdFromService(this.props.datasetId, calc._id, fullyHydrateColumn)
			.then(hydratedCalc => {
				delete hydratedCalc.detailedDisplayName;
				delete hydratedCalc.htmlDisplayName;
				const calcClone = {
					...hydratedCalc,
					_id: null,
					original_id: hydratedCalc._id,
					displayName: `Copy of ${hydratedCalc.displayName}`,
				};
				this.props.setMode('add', modeType, params);
				this.props.setColumnToEdit(calcClone);
			});
	};

	onEdit = (calc, params) => {
		let modeType = 'summary';
		const fullyHydrateColumn = calc.columnFormType === 'conditional';
		columnServiceApi
			.getColumnByIdFromService(this.props.datasetId, calc._id, fullyHydrateColumn)
			.then(hydratedCalc => {
				delete hydratedCalc.detailedDisplayName;
				delete hydratedCalc.htmlDisplayName;
				if (hydratedCalc.formulas) {
					modeType = 'debtCalculation';
				} else if (hydratedCalc.columnType === 'asset' || hydratedCalc.columnType === 'debtCalculation') {
					modeType = 'asset';
				}
				this.props.setMode('add', modeType, params);
				this.props.setColumnToEdit(hydratedCalc);
			});
	};

	showDeleteConfirmToggle = column => {
		this.setState({
			deleteMessage: <span>Remove {column.displayName}?</span>,
			currentColumn: column,
			showDeleteConfirm: true,
			currentCalculation: column,
		});
	};

	cancelNoDeleteReasons = () => {
		this.setState({
			noDeleteReasons: null,
		});
	};

	onDelete = () => {
		const {currentCalculation} = this.state;
		const {datasetId, showSnackbar, fetchDataset} = this.props;
		return datasetsApi
			.deleteColumn(currentCalculation._id, datasetId)
			.then(() => {
				showSnackbar('Column successfully deleted.');
				this.setState({showDeleteConfirm: false});
				return fetchDataset(datasetId);
			})
			.catch(err => {
				if (err.data && err.data.dependencies) {
					// dependencies were found for view, cannot delete
					this.setState({
						noDeleteReasons: err,
						showDeleteConfirm: false,
					});
					showSnackbar('Error deleting column.');
				}
			});
	};

	colQuery = col => {
		const {user} = this.props;
		const {selectedUserId} = this.state;
		const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		switch (selectedUserId) {
			case '':
				return !!col.createdBy;
			case 'global':
				return !col.createdBy;
			default:
				if (!isAdmin) {
					//show both global and owned cols
					return col.createdBy === user.userId || !col.createdBy;
				} else {
					//show only selected user cols
					return selectedUserId ? col.createdBy === selectedUserId : true;
				}
		}
	};

	customColSort = userId => {
		return (a, b) => {
			// User before global
			const aIsUser = a.createdBy === userId ? true : false;
			const bIsUser = b.createdBy === userId ? true : false;
			if (aIsUser && !bIsUser) {
				return -1; // a is first
			}

			if (!aIsUser && bIsUser) {
				return 1; // b is first
			}

			// Alphabetical order
			const valueOne = a.displayName.toLowerCase();
			const valueTwo = b.displayName.toLowerCase();
			if (valueOne === valueTwo) {
				return 0;
			}
			return valueOne < valueTwo ? -1 : 1;
		};
	};

	getFilteredCols = cols => {
		return cols
			.filter(col => this.colQuery(col))
			.filter(col => _get(col, 'formula.op', '').startsWith('bf_') === false)
			.sort(this.customColSort(this.props.user.userId));
	};

	render() {
		const {setPanelMode, isActive, user, userList} = this.props;
		if (!isActive) {
			return null;
		}
		const {columns, searchTerm, debtCalcEntitySelection} = this.state;
		const assetCols = columns.filter(
			col =>
				(!col.formulas && (!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset') ||
				col.columnFormType === 'subAccountColumn'
		);
		const summaryCols = columns.filter(
			col => (col.columnType === 'aggregate' || col.columnType === 'ratio') && col.calculation
		);

		let debtCalcCols = columns.filter(col => col.columnType === 'debtCalculation' && !col.isWaterfall);
		if (debtCalcEntitySelection) {
			debtCalcCols = debtCalcCols.filter(col => col.calcEntityLevel === debtCalcEntitySelection);
		}
		const waterfallCalcCols = columns.filter(
			col => col.isWaterfall && ['debtCalculation', 'waterfallVariable'].includes(col.columnType)
		);

		const isAdmin = user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		const userListOptions = _.sortBy(userList, u => `${u.lastName}${u.firstName}`.toLowerCase()).map(u => {
			return {value: u.userId, label: `${u.firstName} ${u.lastName}`};
		});
		userListOptions.unshift({value: 'global', label: 'Global Only'});
		userListOptions.unshift({value: '', label: 'All Users'});

		return (
			<section className={`flyout-panel-section${isActive ? ' active' : ''}`}>
				{this.state.showDeleteConfirm && (
					<FlyoutConfirmPanel
						header={'Delete Column'}
						message={this.state.deleteMessage || ''}
						acceptFunc={() => this.onDelete()}
						rejectFunc={() => this.setState({showDeleteConfirm: false})}
						acceptLabel={'Delete'}
						rejectLabel={'Cancel'}
					/>
				)}
				{this.state.noDeleteReasons && (
					<FlyoutConfirmPanel
						header={'Delete'}
						message={
							<span>
								Column{' '}
								<span className="bold">&quot;{this.state.currentCalculation.displayName}&quot;</span>{' '}
								cannot be deleted because it is associated with the following:
							</span>
						}
						rejectFunc={this.cancelNoDeleteReasons}
						rejectLabel={'CLOSE'}
						hideAccept={true}
						reasonList={this.state.noDeleteReasons.data.dependencies}
					/>
				)}
				<KiTabs index={this.state.tabIndex} onChange={this.handleTabChange}>
					<KiTab title="Asset">
						<div className="cta-icon-btn" onClick={() => setPanelMode('add', 'asset')}>
							<i className="material-icons">add_circle</i>
							<p>Add a Calculation</p>
						</div>
						<section className="flyout-panel-search">
							{isAdmin && (
								<div className="flyout-panel-user-search">
									<p>Filter</p>
									<Select
										classNamePrefix="aut-select"
										closeOnSelect={true}
										hint="Select a User"
										isClearable={false}
										options={userListOptions}
										onChange={opt =>
											this.setState({
												selectedUserId: opt.value,
											})
										}
										value={userListOptions.find(o => o.value === this.state.selectedUserId)}
									/>
								</div>
							)}
							<p>Search Columns</p>
							<input placeholder="Name or Tag" value={searchTerm} onChange={e => this.onSearch(e)} />
						</section>
						{this.getFilteredCols(assetCols).length ? (
							<ColumnList
								datasetId={this.props.datasetId}
								columns={this.getFilteredCols(assetCols)}
								user={user}
								copyColumn={this.onCopy}
								editColumn={this.onEdit}
								deleteColumn={this.showDeleteConfirmToggle}
								allColumns={this.props.columns}
							/>
						) : (
							<p>No Columns found</p>
						)}
					</KiTab>
					<KiTab title="Summary">
						<div className="cta-icon-btn" onClick={() => setPanelMode('add', 'summary')}>
							<i className="material-icons">add_circle</i>
							<p>Add a Calculation</p>
						</div>
						<section className="flyout-panel-search">
							{isAdmin && (
								<div className="flyout-panel-user-search">
									<p>Filter</p>
									<Select
										classNamePrefix="aut-select"
										closeOnSelect={true}
										hint="Select a User"
										isClearable={false}
										options={userListOptions}
										onChange={opt =>
											this.setState({
												selectedUserId: opt.value,
											})
										}
										value={userListOptions.find(o => o.value === this.state.selectedUserId)}
									/>
								</div>
							)}
							<p>Search Columns</p>
							<input placeholder="Name or Tag" value={searchTerm} onChange={e => this.onSearch(e)} />
						</section>
						{this.getFilteredCols(summaryCols).length ? (
							<ColumnList
								datasetId={this.props.datasetId}
								columns={this.getFilteredCols(summaryCols)}
								user={user}
								copyColumn={this.onCopy}
								editColumn={this.onEdit}
								deleteColumn={this.showDeleteConfirmToggle}
								allColumns={this.props.columns}
							/>
						) : (
							<p>No Columns found</p>
						)}
					</KiTab>
					<KiTab title="Historical">
						<AsOfDateColumns
							allColumns={this.props.columns}
							columns={this.props.columns.filter(c => c.columnType === 'asset' && !!c.asOfDateType)}
							datasetId={this.props.datasetId}
							fetchDataset={this.props.fetchDataset}
						/>
					</KiTab>
					<KiTab title="Debt">
						{isAdmin && (
							<div className="cta-icon-btn" onClick={() => setPanelMode('add', 'debtCalculation')}>
								<i className="material-icons">add_circle</i>
								<p>Add a Calculation</p>
							</div>
						)}
						<section className="flyout-panel-search">
							<div className="flyout-panel-user-search">
								<p>Filter by Entity</p>
								<Select
									classNamePrefix="aut-select"
									closeOnSelect={true}
									isClearable={true}
									options={debtOptions.entityOptions}
									value={debtOptions.entityOptions.find(
										opt => opt.value === this.state.debtCalcEntitySelection
									)}
									onChange={opt => {
										if (opt) {
											this.setState({
												debtCalcEntitySelection: opt.value,
											});
										} else {
											this.setState({
												debtCalcEntitySelection: null,
											});
										}
									}}
								/>
							</div>
							<p>Search Columns</p>
							<input placeholder="Name or Tag" value={searchTerm} onChange={e => this.onSearch(e)} />
						</section>
						<ColumnList
							datasetId={this.props.datasetId}
							columns={debtCalcCols}
							user={user}
							copyColumn={this.onCopy}
							editColumn={this.onEdit}
							deleteColumn={this.showDeleteConfirmToggle}
							allColumns={this.props.columns}
							isDebt={true}
						/>
					</KiTab>
					{window.WATERFALL_ENABLED === 'true' && (
						<KiTab title="Waterfall">
							{isAdmin && (
								<div
									className="cta-icon-btn"
									onClick={() => setPanelMode('add', 'debtCalculation', {isWaterfall: true})}
								>
									<i className="material-icons">add_circle</i>
									<p>Add a Calculation</p>
								</div>
							)}
							<section className="flyout-panel-search">
								<p>Search Columns</p>
								<input placeholder="Name or Tag" value={searchTerm} onChange={e => this.onSearch(e)} />
							</section>
							<ColumnList
								datasetId={this.props.datasetId}
								columns={waterfallCalcCols}
								user={user}
								copyColumn={calc => this.onCopy(calc, {isWaterfall: true})}
								editColumn={calc => this.onEdit(calc, {isWaterfall: true})}
								deleteColumn={this.showDeleteConfirmToggle}
								allColumns={this.props.columns}
								isDebt={true}
							/>
						</KiTab>
					)}
				</KiTabs>
			</section>
		);
	}
}

export default ManageColumns;
