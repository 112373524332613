// Global imports
import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Select from 'react-select';
import _get from 'lodash/get';
import _flatMap from 'lodash/flatMap';
import {dateToShortDate, shortDateToDate} from 'ki-common/utils/dateHelpers';

// Project imports
import KiProgressBar from 'components/KiProgressBar';
import KiAppBar from 'components/KiAppBar';
import KiDatePicker from 'components/KiDatePicker';
import ReportLink from 'components/ReportLink';
import KiModal from 'components/KiModal';
import KiCardPreview from 'components/KiCardPreview';
import KiCardForecasting from 'components/KiCardForecasting';

// Local imports
import CardSummary from './components/CardSummary';
import {getReport, getDisplayCards, getFundingVehicles, getScenarios} from './helpers';
import styles from './reports.theme.scss';

function ReportDisplay() {
	const history = useHistory();
	const {id} = useParams();

	const [report, setReport] = useState({name: ''});
	const [fundingVehicleList, setFundingVehicleList] = useState([]);
	const [error, setError] = useState(null);
	const [statementDate, setStatementDate] = useState(dateToShortDate(new Date()));
	const [fundingVehicleParameter, setFundingVehicleParameter] = useState(null);
	const [displayCards, setDisplayCards] = useState(null);
	const [selectedCard, setSelectedCard] = useState(null);
	const [scenarioList, setScenarioList] = useState([]);
	const [scenarioParameter, setScenarioParameter] = useState(null);

	function pullSubOptions(entry) {
		if (entry.options && entry.options.length) {
			return [...entry.options];
		}
		if (entry.options) {
			return [];
		}
		return [entry];
	}

	function getScenarioValue(listOptions, value) {
		const options = _flatMap(listOptions, pullSubOptions);
		const matched = options.find(entry => entry.value === value);
		return matched ? matched : listOptions[0];
	}

	async function updateBasedOnStatementDate() {
		// Update funding vehicle list
		const fundingVehicleList = await getFundingVehicles(report.settings.datasetId, statementDate);
		setFundingVehicleList(fundingVehicleList);
		setFundingVehicleParameter(fundingVehicleList.find(fv => fv._id === report.settings.fundingVehicleId));

		// Update display cards
		const displayCards = await getDisplayCards(
			report,
			statementDate,
			_get(fundingVehicleParameter, '_id', null) || report.settings.fundingVehicleId
		);
		setDisplayCards(displayCards);

		// Since we dont have a date context adjust the statement date by -1 day
		const statementJsDate = shortDateToDate(statementDate);
		statementJsDate.setDate(statementJsDate.getDate() - 1); // Method sets day of month

		// Update scenario context
		const list = await getScenarios(
			report.settings.datasetId,
			fundingVehicleParameter,
			dateToShortDate(statementJsDate)
		);
		setScenarioList(list);
		const value = getScenarioValue(list, _get(scenarioParameter, 'value', report.settings.scenarioType));
		setScenarioParameter(value);
	}

	useEffect(() => {
		(async () => {
			try {
				const report = await getReport(id);
				setReport(report);
			} catch (err) {
				console.log(err); // eslint-disable-line
				setError('There was a problem loading the report');
			}
		})();
	}, []);

	useEffect(
		() => {
			if (report.settings && statementDate) {
				updateBasedOnStatementDate();
			}
		},
		[report]
	);

	useEffect(
		() => {
			if (report.settings && statementDate) {
				updateBasedOnStatementDate();
			}
		},
		[statementDate]
	);

	const onStatementDateChanged = async val => {
		setScenarioList([]);
		setStatementDate(dateToShortDate(val));
	};

	const onFundingVehicleChanged = async val => {
		setFundingVehicleParameter(val);
		const displayCards = await getDisplayCards(report, statementDate, val ? val._id : null);
		setDisplayCards(displayCards);
	};

	const getReportForPreview = () => {
		if (!report.settings) {
			return {};
		}

		const liveSettings = {};
		if (statementDate) {
			liveSettings.statementDate = statementDate;
		}

		if (fundingVehicleParameter) {
			liveSettings.fundingVehicleId = fundingVehicleParameter._id;
		}

		const scenarioValue = _get(scenarioParameter, 'value', null);
		if (scenarioValue) {
			liveSettings.scenarioId = scenarioValue;
			liveSettings.scenarioType = scenarioValue;
		}

		report.settings = Object.assign(report.settings, liveSettings);
		return report;
	};

	return (
		<div className={styles.reportDisplay}>
			<KiAppBar>
				<div className="top-bar-breadcrumb">
					<h1
						className="link"
						onClick={() => {
							if (report.settings.reportGroupId) {
								history.push(`/reports/${report.settings.reportGroupId}`);
							} else {
								history.push(`/reports`);
							}
						}}
					>
						Reports
					</h1>
					<h1>{` > ${report.name}`}</h1>
				</div>
			</KiAppBar>
			{error ? (
				<div className="ki-panel">
					<div className={styles.errorMsg}>{error}</div>
				</div>
			) : !displayCards ? (
				<div className="ki-panel">
					<KiProgressBar />
				</div>
			) : (
				<div className="ki-panel">
					<header className={styles.controls}>
						<div>
							<span className="form-instruction">Statement Date</span>
							<KiDatePicker
								onChange={newDate => onStatementDateChanged(newDate)}
								value={statementDate}
								className={styles.datepicker}
								disabled={scenarioList.length === 0}
							/>
						</div>
						<div className={styles.double}>
							<span className="form-instruction">Funding Vehicle</span>
							<Select
								classNamePrefix="aut-select"
								isClearable={true}
								isSearchable={true}
								options={fundingVehicleList}
								value={fundingVehicleParameter}
								getOptionLabel={option => option.name}
								getOptionValue={option => option._id}
								onChange={option => onFundingVehicleChanged(option)}
								isDisabled={scenarioList.length === 0}
							/>
						</div>
						<div className={styles.double}>
							<span className="form-instruction">Scenario Context</span>
							<Select
								placeholder={!scenarioList.length ? 'loading...' : ''}
								classNamePrefix="aut-select"
								closeOnSelect={true}
								isClearable={false}
								onChange={val => setScenarioParameter(val)}
								options={scenarioList}
								value={scenarioParameter}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
							/>
						</div>
						<ReportLink
							title=""
							reportDefinitionId={report._id}
							filename={report.name}
							fundingVehicleId={_get(fundingVehicleParameter, '_id')}
							statementDate={statementDate}
							jsonIcon={true}
							csvIcon={true}
							pdfIcon={true}
							scenarioType={_get(scenarioParameter, 'value')}
						/>
					</header>
					<div className={styles.cardList}>
						{report.sections.map(section => {
							const displayCard = displayCards.find(c => c.section._id === section._id);
							return (
								<CardSummary
									key={section._id}
									section={section}
									displayCard={displayCard}
									setSelectedCard={setSelectedCard}
									statementDate={statementDate}
									fundingVehicleList={fundingVehicleList}
								/>
							);
						})}
					</div>
				</div>
			)}
			<KiModal
				active={!!selectedCard}
				header={_get(selectedCard, 'name')}
				className="ki-modal"
				bodyClassName={styles.modalBody}
				onClose={() => setSelectedCard(null)}
				showCloseIcon={true}
			>
				{!!selectedCard &&
					_get(selectedCard, 'settings.type') !== 'projections' && (
						<KiCardPreview card={selectedCard} reportDefinition={getReportForPreview()} />
					)}
				{!!selectedCard &&
					_get(selectedCard, 'settings.type') === 'projections' && <KiCardForecasting card={selectedCard} />}
			</KiModal>
		</div>
	);
}

export default ReportDisplay;
