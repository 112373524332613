import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import ColumnSettingList from './ColumnSettingList';
import _get from 'lodash/get';
import {getColumnsFromService} from '../../../../api/columnServiceApi';

export class AssetColumns extends Component {
	static propTypes = {
		dataset: PropTypes.object.isRequired,
		view: PropTypes.object,
		setFormIsValid: PropTypes.func.isRequired,
		setFormHasChanges: PropTypes.func,
	};

	static defaultProps = {
		dataset: {},
		view: {},
	};

	state = {
		columns: [],
	};

	componentDidMount() {
		getColumnsFromService(this.props.dataset.datasetId, {
			sources: {
				includeAggregateColumns: true,
			},
			options: {
				embedColumns: true,
			},
		}).then(columns => this.setState({columns}));
	}

	render() {
		const {view, setFormIsValid} = this.props;
		return (
			<ColumnSettingList
				setFormIsValid={setFormIsValid}
				tableType={view.tableType}
				settingType="aggregate"
				columns={this.state.columns}
				allSettings={_get(view, 'columns', [])}
				settingList={_get(view, 'columns', []).filter(col => col.columnType === 'aggregate')}
				timeSeriesColumn={_get(view, 'timeseriesColumn._id')}
				setFormHasChanges={this.props.setFormHasChanges}
			/>
		);
	}
}

const mapStateToProps = state => ({
	view: state.viewForm,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps())(AssetColumns);
