const moment = require('moment');

const formatNumber = (rawData, fractionDigits, showPercent) => {
	const data = rawData === '-0.00' ? '0.00' : rawData;
	if (data === '') return;
	if (fractionDigits === '*') {
		const right = data.toString().split('.')[1];
		const leftBase = data.toString().split('.')[0];
		const left = Number(leftBase)
			.toLocaleString(undefined)
			.split('.')[0];

		if (right) {
			return `${left}.${right}${showPercent ? '%' : ''}`;
		}
		return `${left}${showPercent ? '%' : ''}`;
	}
	return `${data.toLocaleString(undefined, {
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	})}${showPercent ? '%' : ''}`;
};

//eslint-disable-next-line complexity
const format = (data, displayFormat) => {
	if (data === undefined || data === null) return '';
	const boolTestData = data.toString().toLowerCase();
	switch (displayFormat) {
		case '9,999':
			//thousands separators, no decimal places
			return formatNumber(data, 0, false);
		case '9,999.99':
			//thousands separators, two decimal places
			return formatNumber(data, 2, false);
		case '9,999.99999':
			//thousands separators, five decimal places
			return formatNumber(data, 5, false);
		case '9,999.*':
			//thousands separators, full precision
			return formatNumber(data, '*', false);
		case '9,999%':
			//percent, thousands separators, no decimal places
			return formatNumber(data, 0, true);
		case '9,999.99%':
			//percent, thousands separators, two decimal places
			return formatNumber(data, 2, true);
		case '9,999.99999%':
			//percent, thousands separators, five decimal places
			return formatNumber(data, 5, true);
		case '9,999.*%':
			//percent, thousands separators, full precision
			return formatNumber(data, '*', true);
		case 'YYYY-MM-DD':
			//ex. 2017-12-25
			return moment(data).format('YYYY-MM-DD');
		case 'DD MMM YYYY':
			//ex. 25 Dec 2017
			return moment(data).format('DD MMM YYYY');
		case 'yyyy-MM':
			return moment(data).format('YYYY-MM');
		case 'yyyy MM':
			return moment(data).format('YYYY MM');
		case 'true/false':
			//bool with true false
			if (boolTestData === 'true' || boolTestData === 'yes' || boolTestData === '1') {
				return 'True';
			} else {
				return 'False';
			}
		case 'yes/no':
			//bool with yes no
			if (boolTestData === 'true' || boolTestData === 'yes' || boolTestData === '1') {
				return 'Yes';
			} else {
				return 'No';
			}
		case '1/0':
			//bool with 1 OR 0
			if (boolTestData === 'true' || boolTestData === 'yes' || boolTestData === '1') {
				return '1';
			} else {
				return '0';
			}
		case 'number':
			//BE sets the wrong type, handle here with a default
			return formatNumber(data, 0, false);
		case 'percent':
			//BE sets the wrong type, handle here with a default
			return formatNumber(data, 2, true);
		case 'currency':
			//BE sets the wrong type, handle here with a default
			return formatNumber(data, 2, false);
		case 'longDate':
			//BE sets the wrong type, handle here with a default
			if (moment(data).format('YYYY-MM-DD') !== 'Invalid date') {
				return moment(data).format('YYYY-MM-DD');
			}
			return data;
		case 'shortDate':
			return moment(data).format('YYYY-MM');
		case 'highPrecisionPercent':
			return formatNumber(data, 6, true);
		default:
			return data.toString();
	}
};

const getFormatStringType = displayFormat => {
	switch (displayFormat) {
		case 'number':
		case 'currency':
		case '9,999':
		case '9,999.99':
		case '9,999.99999':
		case '9,999.*':
			return 'number';

		case 'percent':
		case '9,999%':
		case '9,999.99%':
		case '9,999.99999%':
		case '9,999.*%':
			return 'percent';

		default:
			return 'other';
	}
};

module.exports = {
	format,
	getFormatStringType,
};
