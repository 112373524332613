import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import classnames from 'classnames';
import styles from './KiDnDList.theme.scss';

/**
 *
 */
export class KiDnDList extends Component {
	static propTypes = {
		isHorizontal: PropTypes.bool,
		listClassName: PropTypes.string, // overwrite list class
		extendListClass: PropTypes.string, // add to list class
		itemClassName: PropTypes.string, // overwrite item class
		extendItemClass: PropTypes.string, // extend item class
		isCombineEnabled: PropTypes.bool,
		/**
		 * List of items to render, minmum required object is below
		 * {
		 * 		id: 1,
		 * 		content: 'text|object'
		 * }
		 */
		items: PropTypes.array,
		/**
		 * Callback with the reordered list of items
		 */
		onReorder: PropTypes.func,
		onCombine: PropTypes.func,
		/**
		 * Callback to render individual items. The contents of the
		 * item.content field are passed as the parameter
		 */
		contentRenderFunc: PropTypes.func,
		droppableId: PropTypes.string,
	};

	static defaultProps = {
		isHorizontal: false,
		listClassName: '',
		extendListClass: '',
		itemClassName: '',
		extendItemClass: '',
		isCombineEnabled: false,
		items: '',
		onReorder: () => {
			return;
		},
		onCombine: () => ({}),
		contentRenderFunc: content => {
			return <div>{content}</div>;
		},
	};

	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	onDragEnd = result => {
		if (!result.destination && !result.combine) {
			return;
		}
		if (result.combine) {
			// const items = this.reorder(this.props.items, result.source.index, parseInt(result.combine.draggableId));
			// send items, source item index, destination item index
			this.props.onCombine(this.props.items, result.source.index, parseInt(result.combine.draggableId));
		} else {
			const items = this.reorder(this.props.items, result.source.index, result.destination.index);
			this.props.onReorder(items, result.source.index, result.destination.index);
		}
	};

	render() {
		const {
			isHorizontal,
			items,
			listClassName,
			itemClassName,
			extendListClass,
			isCombineEnabled,
			extendItemClass,
		} = this.props;

		// configure className for list and items
		const listClasses = listClassName
			? listClassName
			: classnames({
					[`${styles['default-list-horizontal']}`]: isHorizontal,
					[`${styles['default-list-vertical']}`]: !isHorizontal,
					[`${extendListClass}`]: extendListClass,
			  });
		const itemClasses = itemClassName
			? itemClassName
			: classnames({
					[`${styles['default-item-horizontal']}`]: isHorizontal,
					[`${styles['default-item-vertical']}`]: !isHorizontal,
					[`${extendItemClass}`]: extendItemClass,
			  });

		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable
					isCombineEnabled={isCombineEnabled}
					droppableId={this.props.droppableId || "droppable"}
					direction={isHorizontal ? 'horizontal' : 'vertical'}
				>
					{provided => (
						<div ref={provided.innerRef} className={listClasses}>
							{items.map((item, index) => (
								<Draggable
									key={item.id}
									draggableId={item.id.toString()}
									isDragDisabled={item.isDragDisabled}
									index={index}
								>
									{(provided, snapshot) => {
										return (
											<span
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												className={classnames(itemClasses, {
													[`${styles['item-dragging']}`]: snapshot.isDragging,
												})}
											>
												{this.props.contentRenderFunc(item.content, index)}
											</span>
										);
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}
export default KiDnDList;
