import * as actionTypes from './actionTypes';

const setFilter = selectedFilter => dispatch => {
	dispatch({type: actionTypes.SET_FILTER, selectedFilter});
};

const loadAllColumns = schemaColumns => dispatch => {
	dispatch({type: actionTypes.SCHEMA_COLUMNS_LOAD_ALL, schemaColumns});
};

const updateSchemaColumn = schemaColumn => dispatch => {
	dispatch({type: actionTypes.SCHEMA_COLUMNS_UPDATE_ONE, schemaColumn});
};

const loadAllSchemaErrors = schemaErrors => dispatch => {
	dispatch({type: actionTypes.SCHEMA_ERRORS_LOAD_ALL, schemaErrors});
};

const loadAllValidationErrors = validationErrors => dispatch => {
	dispatch({type: actionTypes.VALIDATION_ERRORS_LOAD_ALL, validationErrors});
};

export {setFilter, loadAllColumns, updateSchemaColumn, loadAllSchemaErrors, loadAllValidationErrors};
