import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './kiProgressBar.theme.scss';

export class KiProgressBar extends Component {
	static propTypes = {
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
	};

	render() {
		return (
			<div className={`${styles.kiProgressBar} ${this.props.className}`}>
				<div className={styles.animationContainer}>
					<div className={styles.shaft1} />
					<div className={styles.shaft2} />
					<div className={styles.shaft3} />
					<div className={styles.shaft4} />
					<div className={styles.shaft5} />
					<div className={styles.shaft6} />
					<div className={styles.shaft7} />
					<div className={styles.shaft8} />
				</div>
			</div>
		);
	}
}
export default KiProgressBar;
