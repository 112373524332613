import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ScenarioDetailsTable from './components/ScenarioDetailsTable';
import ScenarioListTable from './components/ScenarioListTable';
import ScenarioFormRunResults from './components/ScenariosFormRunResults';
import ReadOnlyControlPanel from './components/ReadOnlyControlPanel';
import {fetchProjectionScenarios} from 'containers/forecasting/actions';
import {waterfallApi} from 'api';
import KiConfirmModal from 'components/KiConfirmModal';
import styles from './index.theme.scss';
import {deleteProjectionScenario, setScenarioDisplayMode} from '../../actions';
import {SCENARIO_DISPLAY_MODES} from '../../reducer';

function ScenariosForm(props) {
	const [scenario, setScenario] = useState({});
	const [modelResults, setModelResults] = useState({});
	const [confirmationModalActive, setConfirmationModalActive] = useState(false);
	const [deleteReferentialMsgActive, setDeleteReferentialMsgActive] = useState(false);
	const [scenarioDeleteKey, setScenarioDeleteKey] = useState(-1);
	const [isLoading, setIsLoading] = useState(false);
	const [scenarioRunHistory, setScenarioRunHistory] = useState([]);
	const [scenarioReportingDeps, setScenarioReportingDeps] = useState([]);

	useEffect(() => {
		props.setScenarioDisplayMode(SCENARIO_DISPLAY_MODES.LIST);
	}, []);

	useEffect(
		() => {
			if (scenario?._id) {
				setScenario(props.scenarios.find(x => x._id === scenario._id));
				setModelResults({});
			}
		},
		[props.scenarios]
	);

	const getRunResults = run => {
		if (run?.original._id) {
			waterfallApi.getProjectionResults(run.original._id).then(res => {
				setModelResults(res);
				props.setScenarioDisplayMode(SCENARIO_DISPLAY_MODES.RUN_REVIEW);
			});
		}
	};

	const requeryScenarioRunResults = async scenarioId => {
		setIsLoading(true);
		const runs = await waterfallApi.getProjectionResultsForScenarioId(scenarioId);
		setScenarioRunHistory(runs);
		setIsLoading(false);
	};

	if (props.scenarioDisplayMode === SCENARIO_DISPLAY_MODES.LIST) {
		return (
			<div className={styles.root}>
				<ScenarioListTable
					selectScenario={s => {
						setScenario(props.scenarios.find(x => x._id === s?._id));
						requeryScenarioRunResults(s?._id);
						props.setScenarioDisplayMode(SCENARIO_DISPLAY_MODES.DETAIL);
					}}
					showDeleteConfirmation={_id => {
						setScenarioDeleteKey(_id);
						setScenario(props.scenarios.find(x => x._id === _id));
						setConfirmationModalActive(true);
					}}
				/>
				<KiConfirmModal
					active={confirmationModalActive}
					acceptFunc={() => {
						setConfirmationModalActive(false);
						// BLD 21272: allow deletion of orphans
						if (!props.datasets.map(d => d.datasetId).includes(scenario.dataset.datasetId)) {
							props.deleteProjectionScenario(scenarioDeleteKey).then(() => undefined);
						} else {
							waterfallApi.getProjectionReportingDeps(scenarioDeleteKey).then(result => {
								if (Array.isArray(result) && result.length > 0) {
									setScenarioReportingDeps(result);
									setDeleteReferentialMsgActive(true);
								} else {
									props.deleteProjectionScenario(scenarioDeleteKey).then(() => undefined);
								}
							});
						}
					}}
					rejectFunc={() => setConfirmationModalActive(false)}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					header="Delete Scenario"
					message="Are you sure?"
				/>
				<KiConfirmModal
					active={deleteReferentialMsgActive}
					acceptDisabled={true}
					rejectFunc={() => setDeleteReferentialMsgActive(false)}
					rejectLabel="OK"
					header="Cannot delete"
					message="The scenario could not be deleted because it is used in the following cards"
				>
					{scenarioReportingDeps.map(c => {
						return <li key={c._id}>{c.name}</li>;
					})}
				</KiConfirmModal>
			</div>
		);
	}

	return (
		<div className={styles.root}>
			<ReadOnlyControlPanel
				scenario={scenario}
				setScenarioInfo={s => setScenario(props.scenarios.find(x => x._id === s?._id))}
				requeryScenarioRunResults={requeryScenarioRunResults}
				modelResults={modelResults}
			/>
			{props.scenarioDisplayMode === SCENARIO_DISPLAY_MODES.DETAIL && (
				<ScenarioDetailsTable
					getRunResults={getRunResults}
					requeryScenarioRunResults={requeryScenarioRunResults}
					scenarioRunHistory={scenarioRunHistory}
					scenario={scenario}
					isLoading={isLoading}
				/>
			)}
			{props.scenarioDisplayMode === SCENARIO_DISPLAY_MODES.RUN_REVIEW && (
				<ScenarioFormRunResults selectedScenarios={modelResults} />
			)}
		</div>
	);
}

ScenariosForm.propTypes = {
	scenarios: PropTypes.array,
	deleteProjectionScenario: PropTypes.func.isRequired,
	scenarioDisplayMode: PropTypes.string.isRequired,
	setScenarioDisplayMode: PropTypes.func.isRequired,
	datasets: PropTypes.array,
};

ScenariosForm.defaultProps = {
	scenarios: [],
	datasets: [],
};

const mapStateToProps = state => ({
	app: state.app,
	user: state.user,
	datasets: state.datasetList.data,
	scenarios: state.forecasting.projectionScenarios,
	scenarioDisplayMode: state.forecasting.scenarioDisplayMode,
});

const mapDispatchToProps = () => ({
	deleteProjectionScenario,
	fetchProjectionScenarios,
	setScenarioDisplayMode,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(ScenariosForm);
