import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import {fetchDatasetList, setSelectedDataset} from 'containers/datasetList/actions';
import styles from './associatedDataList.theme.scss';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import KiIconButton from 'components/KiIconButton';
import {fetchAssociatedDataList, deleteAssociatedData} from './actions';
import KiProgressBar from 'components/KiProgressBar';
import options from 'ki-common/options';

export class AssociatedDataList extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		dataset: PropTypes.object,
		fetchDatasetList: PropTypes.func,
		setSelectedDataset: PropTypes.func,
		match: PropTypes.object.isRequired,
		history: PropTypes.object,
		fetchAssociatedDataList: PropTypes.func,
		associatedDataList: PropTypes.array.isRequired,
		deleteAssociatedData: PropTypes.func, //eslint-disable-line react/no-unused-prop-types
		isLoading: PropTypes.bool,
	};

	static defaultProps = {};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Associated Data List`;
		const targetDatasetId = this.props.match.params.datasetId;
		if (!this.props.dataset.datasetId !== targetDatasetId) {
			return Promise.all([
				this.props.fetchDatasetList(),
				this.props.fetchAssociatedDataList(targetDatasetId),
			]).then(results => {
				this.props.setSelectedDataset(results[0].datasetList.find(d => d.datasetId === targetDatasetId));
			});
		}
	}

	getFileTypeLabel = type => {
		if (type === 'subaccount') {
			type = 'aggregate'; // for now they display the same
		}
		const match = options.appendDataOptions.find(t => t.value === type);
		return match.label;
	};

	render() {
		if (!this.props.dataset) return null;

		const {dataset} = this.props;
		const targetDatasetId = this.props.match.params.datasetId;

		return (
			<section className={styles.associatedDataList}>
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1 className="link" onClick={() => this.props.history.push('/datasets')}>{`Datasets`}</h1>
						<h1>{` > ${dataset.name || ''}`}</h1>
						<h1>{` > Associated Data`}</h1>
					</div>
					<KiIconButton
						icon="add_circle"
						inverse
						onClick={() => this.props.history.push(`/associatedData/${targetDatasetId}`)}
						title="Click to upload an associated data file."
					/>
				</KiAppBar>
				{this.props.isLoading && <KiProgressBar className={styles.loader} />}
				<section className="ki-panel">
					{this.props.associatedDataList.length ? (
						<table className="ki-list-table">
							<thead>
								<tr>
									<th>Name</th>
									<th>Data File Type</th>
									<th>Snapshot Date</th>
									<th>Schema</th>
									<th>Assets Matched</th>
									<th>Total Records</th>
									<th>Unmatched Records</th>
									<th>Last Updated</th>
								</tr>
							</thead>
							<tbody>
								{this.props.associatedDataList.map((item, index) => (
									<tr className="ki-list-item" key={index}>
										<td className="ki-list-item-name">{item.fileName}</td>
										<td>{this.getFileTypeLabel(item.fileType)}</td>
										<td>{item.snapshotDate}</td>
										<td>{item.subaccountName}</td>
										<td>{item.assetsMatched}</td>
										<td>{item.totalRecords}</td>
										<td>{item.unmatchedRecordsCount}</td>
										<td>
											{`${item.lastUpdatedBy} ${dateToShortDate(new Date(item.lastUpdated))}`}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<div style={{textAlign: 'center'}}>Add Associated Data files using the plus button above</div>
					)}
				</section>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		app: state.app,
		dataset: state.datasetList.selected,
		associatedDataList: state.associatedDataList.data,
		user: state.user,
		isLoading: state.associatedDataList.isLoading,
	};
};

const mapDispatchToProps = () => ({
	fetchDatasetList,
	setSelectedDataset,
	fetchAssociatedDataList,
	deleteAssociatedData,
});

export default connect(mapStateToProps, mapDispatchToProps())(AssociatedDataList);
