import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchCategorySchema = id => {
	return fetch(`${apiUrl}/categorySchemas/${id}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchCategorySchemaBySnapshotDate = dataset => {
	return fetch(
		`${apiUrl}/categorySchemas/name/${dataset.category}/snapshotDate/${
			dataset.snapshots[dataset.snapshots.length - 1].snapshotDate
		}`,
		{
			credentials: 'include',
			headers: _getHeaders(),
		}
	).then(_handleResponse);
};

export const fetchCategorySchemaList = () => {
	return fetch(`${apiUrl}/categorySchemas`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const updateColumn = (schemaId, schemaColumn) => {
	if (!schemaColumn.id) {
		return Promise.reject(new Error("Missing required 'id' property on schema column"));
	}
	return fetch(`${apiUrl}/categorySchemas/${schemaId}/columns`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(schemaColumn),
	}).then(_handleResponse);
};

export const validateSchema = (submissionId, columns = []) => {
	return fetch(`${apiUrl}/categorySchemas/${submissionId}/validate`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(columns),
	}).then(_handleResponse);
};

export const testUnique = (schemaName, schemaSnapshotDate) => {
	return fetch(`${apiUrl}/categorySchemas/testUnique`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			name: schemaName,
			snapshotDate: schemaSnapshotDate,
		}),
	}).then(_handleResponse);
};

export default {
	fetchCategorySchema,
	fetchCategorySchemaBySnapshotDate,
	fetchCategorySchemaList,
	updateColumn,
	validateSchema,
};
