import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import LedgerTopBar from '../LedgerTopBar';
import './LedgerConfigure.scss';
import LedgerSetup from './Setup/LedgerSetup';
import LedgerAccountsConfiguration from './Account/LedgerAccountConfiguration';
import LedgerTemplatesConfiguration from './Template/LedgerTemplateConfiguration';
import {fetchLedger} from '../../../../api/ledgersApi';

export const CONFIGURE_ACTIONS = {
	SETUP: {label: 'Ledger Setup', value: 'setup'},
	ACCOUNTS: {label: 'Accounts', value: 'accounts'},
	TEMPLATES: {label: 'Templates', value: 'templates'},
};

export const SELECT_CONFIGURE_ACTIONS = [
	CONFIGURE_ACTIONS.SETUP,
	CONFIGURE_ACTIONS.ACCOUNTS,
	CONFIGURE_ACTIONS.TEMPLATES,
];

function LedgerConfigure() {
	const [ledger, setLedger] = useState({});
	const [currentView, setCurrentView] = useState(CONFIGURE_ACTIONS.SETUP);
	const {id} = useParams();

	const getLedger = () => {
		fetchLedger(id).then(ledger => {
			setLedger(ledger);
		});
	};

	useEffect(() => {
		getLedger();
	}, []);

	const renderView = () => {
		switch (currentView) {
			case CONFIGURE_ACTIONS.SETUP:
				return <LedgerSetup setView={setCurrentView} />;
			case CONFIGURE_ACTIONS.ACCOUNTS:
				return <LedgerAccountsConfiguration setView={setCurrentView} />;
			case CONFIGURE_ACTIONS.TEMPLATES:
				return <LedgerTemplatesConfiguration setView={setCurrentView} ledger={ledger} />;
		}
	};

	return (
		<div className="ledger">
			<LedgerTopBar ledgerId={id} existingLedger={ledger} section="Configure" />
			{renderView()}
		</div>
	);
}

export default LedgerConfigure;
