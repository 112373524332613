import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './FilterList.theme.scss';
import FlyoutConfirmPanel from '../../FlyoutConfirmPanel';
import _get from 'lodash/get';
import Select from 'components/KiSelect';
import _ from 'lodash';

const customFilterSort = userId => {
	return (a, b) => {
		// User before global
		const aIsUser = a.userId === userId;
		const bIsUser = b.userId === userId;
		if (aIsUser && !bIsUser) {
			return -1; // a is first
		}

		if (!aIsUser && bIsUser) {
			return 1; // b is first
		}

		// Alphabetical order
		const valueOne = a.name.toLowerCase();
		const valueTwo = b.name.toLowerCase();
		if (valueOne === valueTwo) {
			return 0;
		}
		return valueOne < valueTwo ? -1 : 1;
	};
};

const ViewLink = props => {
	const isGlobal = !props.filter.userId;
	const isDisabled = !props.isAdmin && isGlobal && !props.allowAll;
	return isDisabled ? (
		<div className="list-icon-btn">
			<i title={props.title} alt={props.alt} className="material-icons disabled">
				{props.iconName}
			</i>
		</div>
	) : (
		<div className="list-icon-btn">
			<i title={props.title} alt={props.alt} className="material-icons" onClick={props.onClick}>
				{props.iconName}
			</i>
		</div>
	);
};

ViewLink.propTypes = {
	filter: PropTypes.object,
	isAdmin: PropTypes.bool,
	allowAll: PropTypes.bool,
	iconName: PropTypes.string,
	title: PropTypes.string,
	alt: PropTypes.string,
	onClick: PropTypes.func,
};

export class FilterListRow extends Component {
	static propTypes = {
		filter: PropTypes.object,
		editFilter: PropTypes.func,
		removeFilter: PropTypes.func,
		cloneFilter: PropTypes.func,
		isAdmin: PropTypes.bool,
	};
	render() {
		const {filter = {}, editFilter, removeFilter, cloneFilter, isAdmin} = this.props;

		return (
			<div className={styles.rowWrapper}>
				<div className={`${styles.row} icons-cell-right`}>
					<div className={`${styles.rowInfo} list-icon-btn no-rollover`}>
						<i className="material-icons list-icon-btn no-rollover">
							{filter.userId ? 'person' : 'language'}
						</i>
						<div className="column-name" title={(filter.tags || []).join('\n')}>
							{filter.name}
						</div>
					</div>
					<div className={styles.rowActions}>
						<ViewLink
							isAdmin={isAdmin}
							allowAll={true}
							title="Copy"
							alt="Copy Filter"
							iconName="content_copy"
							onClick={() => cloneFilter(filter)}
							filter={filter}
						/>
						<ViewLink
							isAdmin={isAdmin}
							allowAll={false}
							title="Edit"
							alt="Edit Filter"
							iconName="mode_edit"
							onClick={() => editFilter(filter)}
							filter={filter}
						/>
						<ViewLink
							isAdmin={isAdmin}
							allowAll={false}
							title="Delete"
							alt="Delete Filter"
							iconName="delete"
							onClick={() => removeFilter(filter)}
							filter={filter}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export class FilterList extends Component {
	static propTypes = {
		filters: PropTypes.array,
		active: PropTypes.bool,
		editFilter: PropTypes.func,
		removeFilter: PropTypes.func,
		cloneFilter: PropTypes.func,
		onNewFilterClick: PropTypes.func,
		user: PropTypes.object,
		userList: PropTypes.array,
		showSnackbar: PropTypes.func,
	};

	static defaultProps = {
		filters: [],
		active: false,
	};

	state = {
		searchTerm: '',
		showConfirmDelete: false,
		filterToDelete: null,
		noDeleteReasons: null,
		selectedUserId: this.props.user.groups.includes('SystemAdmins') ? 'global' : this.props.user.userId,
	};

	handleDelete = filterId => {
		this.props
			.removeFilter(filterId)
			.then(() => {
				this.setState({showConfirmDelete: false, filterToDelete: null});
			})
			.catch(e => {
				if (e.data && e.data.dependencies) {
					// dependencies were found for view, cannot delete
					this.setState({
						noDeleteReasons: e,
						showConfirmDelete: false,
					});
				} else {
					this.props.showSnackbar('Error deleting column.');
				}
			});
	};

	cancelNoDeleteReasons = () => {
		this.setState({
			noDeleteReasons: null,
			filterToDelete: null,
		});
	};

	changeSelectedUser = option => {
		this.setState({selectedUserId: option.value});
	};

	render() {
		const {active} = this.props;
		const {showConfirmDelete} = this.state;
		if (!active) {
			return null;
		}
		if (showConfirmDelete) {
			return (
				<FlyoutConfirmPanel
					header={'Delete'}
					message={`Are you sure you want to delete the following filter? ${_get(
						this.state.filterToDelete,
						'name',
						''
					)}`}
					acceptFunc={() => this.handleDelete(_get(this.state.filterToDelete, '_id'))}
					rejectFunc={() =>
						this.setState({
							showConfirmDelete: false,
							filterToDelete: null,
						})
					}
					acceptLabel={'DELETE'}
					rejectLabel={'CANCEL'}
				/>
			);
		}
		if (this.state.noDeleteReasons) {
			return (
				<FlyoutConfirmPanel
					header={'Delete'}
					message={
						<span>
							Filter <span className="bold">&quot;{this.state.filterToDelete.name}&quot;</span> cannot be
							deleted because it is associated with the following:
						</span>
					}
					rejectFunc={this.cancelNoDeleteReasons}
					rejectLabel={'CLOSE'}
					hideAccept={true}
					reasonList={this.state.noDeleteReasons.data.dependencies}
				/>
			);
		}

		const isAdmin = this.props.user.groups.findIndex(g => g === 'SystemAdmins') >= 0;
		const userListOptions = _.sortBy(this.props.userList, u => `${u.lastName}${u.firstName}`.toLowerCase()).map(
			u => {
				return {value: u.userId, label: `${u.firstName} ${u.lastName}`};
			}
		);
		userListOptions.unshift({value: 'global', label: 'Global Only'});
		userListOptions.unshift({value: '', label: 'All Users'});

		const filterQuery = filter => {
			if (this.state.searchTerm) {
				const searchableStrings = [filter.name, ...(filter.tags || [])];
				const regex = new RegExp(this.state.searchTerm, 'gi');
				if (!searchableStrings.some(str => regex.test(str))) {
					return false;
				}
			}
			switch (this.state.selectedUserId) {
				case '':
					return !!filter.userId;
				case 'global':
					return !filter.userId;
				default:
					if (!isAdmin) {
						//show both global and owned filters
						return filter.userId === this.state.selectedUserId || !filter.userId;
					} else {
						//show only selected user filters
						return filter.userId === this.state.selectedUserId;
					}
			}
		};

		const filteredFilters = this.props.filters
			.filter(filter => filterQuery(filter))
			.sort(customFilterSort(this.props.user.userId));

		return (
			<React.Fragment>
				<div className={`${styles.addFilterRow} cta-icon-btn`}>
					<i className="material-icons" title="Add Filter" onClick={this.props.onNewFilterClick}>
						add_circle
					</i>
					<p onClick={this.props.onNewFilterClick}>Add a Filter</p>
				</div>
				<section className={styles.list}>
					<div className="flyout-panel-search">
						{isAdmin && (
							<div className="flyout-panel-user-search">
								<p>Filter</p>
								<Select
									classNamePrefix="aut-select"
									className={styles.picker}
									closeOnSelect={true}
									value={userListOptions.find(opt => opt.value === this.state.selectedUserId)}
									hint="Select a User"
									isClearable={false}
									options={userListOptions}
									onChange={this.changeSelectedUser}
								/>
							</div>
						)}
						<p>Search Filters</p>
						<input
							type="text"
							placeholder="Name or Tag"
							value={this.state.searchTerm}
							onChange={e => this.setState({searchTerm: _get(e, 'target.value', '')})}
						/>
					</div>
					{filteredFilters.length ? (
						<div className={styles.listRowWrapper}>
							{filteredFilters.map(filter => {
								return (
									<FilterListRow
										key={filter._id}
										filter={filter}
										editFilter={this.props.editFilter}
										removeFilter={filterToDelete =>
											this.setState({
												showConfirmDelete: true,
												filterToDelete,
											})
										}
										cloneFilter={this.props.cloneFilter}
										isAdmin={isAdmin}
										user={this.props.user}
									/>
								);
							})}
						</div>
					) : (
						<p>No Filters found</p>
					)}
				</section>
			</React.Fragment>
		);
	}
}

export default FilterList;
