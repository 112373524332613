import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiInput from 'components/KiInput';
import KiIconButton from 'components/KiIconButton';
import KiSelect from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';
import {debtInputDataTypes} from 'ki-common/options/debt';
import dateHelpers from 'ki-common/utils/dateHelpers';
import stringHelpers from 'ki-common/utils/stringHelpers';
import _ from 'lodash';
import _get from 'lodash/get';
import KiConfirmModal from 'components/KiConfirmModal';
import DebtInputAccountForm from './DebtInputAccountForm';
import {columnServiceApi} from 'api';

const DEBT_VIEW_TYPES = [
	{
		value: 'fundingVehicle',
		label: 'Funding Vehicle',
	},
	{
		value: 'tranche',
		label: 'Tranche',
	},
	{
		value: 'creditSupport',
		label: 'Accounts',
	},
	{
		value: 'fee',
		label: 'Fee',
	},
	{
		value: 'trigger',
		label: 'Trigger',
	},
];

const DEBT_RESET_TYPES = [
	{
		value: 'cumulative',
		label: 'Cumulative',
	},
	{
		value: 'periodicReset',
		label: 'Periodic Reset',
	},
];

class DebtInputForm extends Component {
	static propTypes = {
		formValues: PropTypes.shape({
			_id: PropTypes.string,
			name: PropTypes.string,
			dataType: PropTypes.string,
			viewType: PropTypes.string,
			isAccount: PropTypes.bool,
			resetType: PropTypes.any,
			resetDateContext: PropTypes.any,
			accountColumns: PropTypes.array,
			defaultValue: PropTypes.any,
			fundingVehicles: PropTypes.array,
			addAccountColumn: PropTypes.any,
			removeAccountColumn: PropTypes.any,
			editTemplate: PropTypes.any,
			isTrancheAccountTemplate: PropTypes.any,
		}).isRequired,
		onSave: PropTypes.func.isRequired,
		fundingVehicles: PropTypes.array.isRequired,
		templateNames: PropTypes.array,
		deleteTemplate: PropTypes.func,
		allSavedTemplates: PropTypes.array,
		datasetDates: PropTypes.array,
		datasetId: PropTypes.string.isRequired,
	};

	static defaultProps = {
		formValues: {},
		templateNames: [],
	};

	state = {
		showButtons: false,
		unsavedValues: {},
		valueError: null,
		isDeleteConfirmActive: false,
		accountColumnToDelete: null,
		availableColumns: [],
	};

	componentDidMount() {
		const {formValues} = this.props;
		if (!formValues._id) {
			this.setState({
				unsavedValues: {
					...this.state.unsavedValues,
					isStatic: false,
					isActive: true,
				},
			});
		} else {
			this.updateColumns();
		}
	}

	componentDidUpdate(prevProps) {
		if (!_.isEqual(this.props, prevProps)) {
			this.setState(state => ({
				unsavedValues: {
					accountColumns:
						_.get(state, 'unsavedValues.accountColumns') || this.props.formValues.accountColumns,
					isActive: true,
				},
			}));
			if (!_.isEqual(this.props.formValues, prevProps.formValues)) {
				this.updateColumns();
			}
		}
	}

	updateColumns = () => {
		const {formValues} = this.props;
		const viewType = this.state.unsavedValues.viewType || formValues.viewType;
		const entityTypes = ['fundingVehicle'];
		if (viewType && viewType !== 'fundingVehicle') {
			entityTypes.push(viewType);
		}

		const params = {
			sources: {
				includeDebtCalculations: {entityTypes},
				includeDebtInputs: {entityTypes},
			},
			filters: {
				dataTypes: ['numeric'],
			},
		};

		// Only do a circular check if we have an ID base
		if (formValues._id) {
			_.set(params, 'options.circularCheckId', formValues._id);
		}
		columnServiceApi.getColumnsFromService(this.props.datasetId, params).then(res => {
			this.setState({availableColumns: res});
		});
	};

	onChange = (name, val) => {
		const newValues = Object.assign(_.cloneDeep(this.props.formValues), this.state.unsavedValues, {[name]: val});
		if (newValues.fundingVehicles && newValues.fundingVehicles.length && newValues.fundingVehicles[0]._id) {
			newValues.fundingVehicles = newValues.fundingVehicles.map(fv => fv._id);
			if (newValues.fundingVehicles.includes('all')) {
				newValues.fundingVehicles = ['all'];
			}
		} else if (!newValues.fundingVehicles) {
			newValues.fundingVehicles = [];
		}
		if (name === 'addAccountColumn') {
			if (!newValues.accountColumns) {
				newValues.accountColumns = [];
			}
			newValues.accountColumns.push(val);
			delete newValues.addAccountColumn;
		}
		if (name === 'removeAccountColumn') {
			newValues.accountColumns.splice(newValues.accountColumns.indexOf(val._id), 1);
			delete newValues.removeAccountColumn;
		}
		if (name === 'editAccountColumn') {
			newValues.accountColumns[newValues.accountColumns.indexOf(val)] = val;
			delete newValues.editAccountColumn;
		}
		if (name === 'isAccount') {
			newValues.isStatic = true;
			if (newValues.isAccount) {
				newValues.dataType = 'numeric';
			}
		}
		if (name === 'resetType') {
			newValues.resetDateContext = null;
		}
		if (name === 'defaultValue') {
			switch (this.state.unsavedValues.dataType || this.props.formValues.dataType) {
				case 'date_long':
					if (!val.match(dateHelpers.dateStringRegex)) {
						this.setState({valueError: 'Must be: YYYY-MM-DD'});
					} else {
						this.setState({valueError: null});
					}
					break;
				case 'date_short':
					if (!val.match(dateHelpers.shortDateStringRegex)) {
						this.setState({valueError: 'Must be: YYYY-MM'});
					} else {
						this.setState({valueError: null});
					}
					break;
				case 'numeric':
					if (isNaN(val)) {
						this.setState({valueError: 'Must be numeric'});
					} else {
						this.setState({valueError: null});
					}
					break;
			}
		}

		if (name === 'name') {
			newValues.name = newValues.name.length ? newValues.name : ' ';
			this.setState({
				nameError: val.match(stringHelpers.alphanumericRegex) ? null : 'Must be Alphanumeric',
			});
		}
		this.setState(
			{
				unsavedValues: newValues,
				showButtons: !!(newValues.name && newValues.dataType),
			},
			() => {
				if (name === 'dataType') {
					switch (val) {
						case 'numeric':
							this.onChange('defaultValue', '0.00');
							break;
						case 'date_short':
							this.onChange('defaultValue', '1900-01');
							break;
						case 'date_long':
							this.onChange('defaultValue', '1900-01-01');
							break;
						case 'string':
							this.onChange('defaultValue', '');
							break;
					}
				}
			}
		);
	};

	onSave = () => {
		const updatedValues = Object.assign({}, this.props.formValues, this.state.unsavedValues);
		// TODO can this be done some other way
		// eslint-disable-next-line
		if (!updatedValues.hasOwnProperty('isStatic')) {
			updatedValues.isStatic = false;
		}
		this.props.onSave(updatedValues);
		this.setState({
			showButtons: false,
		});
		if (!updatedValues._id) {
			this.setState({
				unsavedValues: {},
			});
		}
	};

	onSaveNewAccountCol = values => {
		const updatedValues = Object.assign(
			{},
			_.cloneDeep(this.props.formValues),
			_.cloneDeep(this.state.unsavedValues)
		);
		updatedValues.name = `${values.newAccountColumnName}`;
		updatedValues.isStatic = false;
		updatedValues.isNewMiddleColumn = true;
		updatedValues.dataType = 'numeric';
		if (this.props.formValues._id) {
			updatedValues.accountId = this.props.formValues._id;
		}
		delete updatedValues._id;
		delete updatedValues.fundingVehicles;
		delete updatedValues.beginningBalanceCol;
		delete updatedValues.endingBalanceCol;
		delete updatedValues.resetDateContext;
		delete updatedValues.resetType;
		delete updatedValues.accountColumns;
		this.props.onSave(updatedValues).then(() => this.updateColumns());
	};

	onSaveNewMapping = template => {
		const toSave = _.omit(
			{
				...template,
				isActive: true,
			},
			[
				'columnType',
				'displayFormat',
				'displayName',
				'columnName',
				'entityType',
				'detailedDisplayName',
				'htmlDisplayName',
			]
		);
		return this.props.onSave(toSave);
	};

	rollBackChanges = () => {
		const resetValues = Object.assign({}, this.props.formValues);
		this.setState({
			showButtons: false,
			unsavedValues: resetValues,
		});
	};

	getBooleanValue = type => {
		const {unsavedValues} = this.state;
		const {formValues} = this.props;
		if (typeof unsavedValues[type] === 'boolean') {
			return unsavedValues[type];
		} else if (typeof formValues[type] === 'boolean') {
			return formValues[type];
		} else if (typeof unsavedValues[type] === 'undefined' && typeof formValues[type] === 'undefined') {
			// if undefined should assume false
			return false;
		}
		return type !== 'isAccount';
	};

	getMaxLength = () => {
		const {unsavedValues} = this.state;
		const {formValues} = this.props;
		if (unsavedValues.dataType === 'numeric' || unsavedValues.dataType === 'string') {
			return 100;
		}
		if (formValues.dataType === 'numeric' || formValues.dataType === 'string') {
			return 100;
		}
	};

	deleteTemplate = () => {
		this.props.deleteTemplate(this.props.formValues._id);
	};

	confirmAccountColumnDelete = () => {
		this.onChange('removeAccountColumn', this.state.accountColumnToDelete);
		this.setState({isDeleteConfirmActive: false});
	};

	onDeleteAccountColumn = col => {
		this.setState({
			isDeleteConfirmActive: true,
			accountColumnToDelete: col,
		});
	};

	setAccountColumnActivity = (col, value) => {
		const updatedValues = Object.assign({}, this.props.allSavedTemplates.find(t => t._id === col._id), {
			isActive: value,
		});
		this.props.onSave(updatedValues);
	};

	render() {
		const {formValues, fundingVehicles, templateNames} = this.props;
		const {showButtons, unsavedValues, valueError, nameError} = this.state;
		const fvsWithAll = [{name: 'All', _id: 'all'}, ...fundingVehicles];
		const nameInUse = formValues.name !== unsavedValues.name && templateNames.includes(unsavedValues.name);
		const getDebtInputDataTypes = () => {
			if (this.getBooleanValue('isAccount')) {
				return debtInputDataTypes.filter(t => t.value === 'numeric');
			}
			return debtInputDataTypes;
		};
		return (
			<div>
				<form name={'DebtInputForm'} className="debt-input-form">
					<div>
						<KiCheckbox
							disabled={(formValues._id && !formValues.editTemplate) || false}
							className={'debt-input-form-static-check'}
							onChange={val => this.onChange('isActive', val)}
							checked={this.getBooleanValue('isActive')}
							label={'Active'}
						/>
					</div>
					<div>
						<KiInput
							name={'fieldName'}
							maxLength={100}
							value={
								unsavedValues.name && unsavedValues.name.length
									? unsavedValues.name
									: formValues.name || ''
							}
							label={'Debt Input Name'}
							onChange={val => this.onChange('name', val)}
							error={(nameInUse && 'Name in use') || nameError || null}
							disabled={!!formValues._id}
						/>
					</div>
					<div>
						<KiCheckbox
							className={'debt-input-form-static-check'}
							onChange={val => this.onChange('isAccount', val)}
							checked={this.getBooleanValue('isAccount')}
							disabled={(formValues._id && !formValues.editTemplate) || false}
							label={'Register'}
						/>
					</div>
					{(unsavedValues.isAccount || formValues.isAccount) && (
						<div className={'select-wrapper'}>
							<span className={'theme-label'}>Reset Type</span>
							<KiSelect
								options={DEBT_RESET_TYPES}
								value={
									DEBT_RESET_TYPES.find(
										option =>
											option.value ===
											(unsavedValues.resetType ? unsavedValues.resetType : formValues.resetType)
									) || DEBT_RESET_TYPES[0]
								}
								onChange={val => this.onChange('resetType', val.value)}
								isDisabled={(formValues._id && !formValues.editTemplate) || false}
							/>
						</div>
					)}
					{(unsavedValues.isAccount || formValues.isAccount) &&
						(unsavedValues.resetType === 'periodicReset' || formValues.resetType === 'periodicReset') && (
							<div className={'select-wrapper'}>
								<span className={'theme-label'}>Date Context</span>
								<KiSelect
									options={this.props.datasetDates}
									getOptionLabel={option => option.name}
									getOptionValue={option => option._id}
									isDisabled={(formValues._id && !formValues.editTemplate) || false}
									value={
										this.props.datasetDates.find(
											option =>
												option.groupId ===
												(unsavedValues.resetDateContext
													? unsavedValues.resetDateContext
													: formValues.resetDateContext)
										) || null
									}
									onChange={val => this.onChange('resetDateContext', val.groupId)}
								/>
							</div>
						)}
					<div className={'select-wrapper'}>
						<span className={'theme-label'}>Data Type</span>
						<KiSelect
							isDisabled={!!formValues._id}
							name={'dataType'}
							options={getDebtInputDataTypes()}
							value={
								debtInputDataTypes.find(
									option =>
										option.value ===
										(unsavedValues.dataType ? unsavedValues.dataType : formValues.dataType)
								) || null
							}
							onChange={val => this.onChange('dataType', val.value)}
						/>
					</div>
					{!unsavedValues.isAccount &&
						!formValues.isAccount && (
							<div>
								<KiInput
									name={'defaultValue'}
									maxLength={this.getMaxLength()}
									value={unsavedValues.defaultValue || formValues.defaultValue || ''}
									label={'Default Value'}
									disabled={!unsavedValues.dataType && !formValues.dataType}
									onChange={val => this.onChange('defaultValue', val)}
									error={valueError}
								/>
							</div>
						)}
					<div className={'select-wrapper debt-input-form-fv-select'}>
						<span className={'theme-label'}>Funding Vehicle(s)</span>
						<KiSelect
							isClearable={true}
							isMulti={true}
							name={'viewType'}
							defaultValue={['all']}
							options={fvsWithAll}
							isDisabled={(formValues._id && !formValues.editTemplate) || false}
							value={(unsavedValues.fundingVehicles
								? unsavedValues.fundingVehicles
								: formValues.fundingVehicles || []
							).map(fvId => fvsWithAll.find(fv => fv._id === fvId))}
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							onChange={values => this.onChange('fundingVehicles', values)}
						/>
					</div>
					<div className={'select-wrapper'}>
						<span className={'theme-label'}>Entity</span>
						<KiSelect
							isDisabled={!!formValues._id}
							name={'debtView'}
							isOptionDisabled={opt => !['fundingVehicle', 'tranche'].includes(opt.value)}
							options={DEBT_VIEW_TYPES}
							defaultValue={{
								value: 'fundingVehicle',
								label: 'Funding Vehicle',
							}}
							value={
								DEBT_VIEW_TYPES.find(
									option =>
										option.value ===
										(unsavedValues.viewType ? unsavedValues.viewType : formValues.viewType)
								) || {
									value: 'fundingVehicle',
									label: 'Funding Vehicle',
								}
							}
							onChange={val => this.onChange('viewType', val.value)}
						/>
					</div>
					{!unsavedValues.isAccount &&
						!formValues.isAccount && (
							<div className="empty-column-placeholder">
								<KiCheckbox
									className={'debt-input-form-static-check'}
									checked={this.getBooleanValue('isStatic')}
									onChange={val => this.onChange('isStatic', val)}
									label={'Static'}
									disabled={(formValues._id && !formValues.editTemplate) || false}
								/>
							</div>
						)}
					<div className="empty-column-placeholder">
						{(
							DEBT_VIEW_TYPES.find(
								option =>
									option.value ===
									(unsavedValues.viewType ? unsavedValues.viewType : formValues.viewType)
							) || {
								value: 'fundingVehicle',
								label: 'Funding Vehicle',
							}
						).value === 'fundingVehicle' && (
							<KiCheckbox
								className={'debt-input-form-static-check'}
								checked={this.getBooleanValue('isAsset')}
								onChange={val => this.onChange('isAsset', val)}
								label={'Asset'}
								disabled={(formValues._id && !formValues.editTemplate) || false}
							/>
						)}
					</div>
					<div className="save-controls">
						{showButtons && <KiIconButton icon="undo" onClick={this.rollBackChanges} />}
						{showButtons && (
							<KiIconButton
								icon={formValues._id ? 'save' : 'add'}
								disabled={
									((unsavedValues.resetType === 'periodicReset' ||
										formValues.resetType === 'periodicReset') &&
										(!unsavedValues.resetDateContext && !formValues.resetDateContext)) ||
									!showButtons ||
									nameInUse ||
									valueError ||
									nameError ||
									_get(unsavedValues, 'name', '').trim().length === 0 ||
									(_get(unsavedValues, 'fundingVehicles', []) &&
										_get(unsavedValues, 'fundingVehicles', []).length === 0)
								}
								onClick={this.onSave}
							/>
						)}
						{!showButtons &&
							formValues._id &&
							formValues.editTemplate !== false && (
								<KiIconButton icon="delete" onClick={this.deleteTemplate} />
							)}
					</div>
				</form>
				{(unsavedValues.isAccount || formValues.isAccount) &&
					this.state.availableColumns.length > 0 && (
						<DebtInputAccountForm
							formValues={this.props.formValues}
							unsavedValues={this.state.unsavedValues}
							onSaveNewAccountCol={this.onSaveNewAccountCol}
							debtColumns={this.state.availableColumns}
							allSavedTemplates={this.props.allSavedTemplates}
							onChange={this.onChange}
							setAccountColumnActivity={this.setAccountColumnActivity}
							templateNames={this.props.templateNames}
							onDeleteAccountColumn={this.onDeleteAccountColumn}
							onSaveNewMapping={this.onSaveNewMapping}
						/>
					)}
				<KiConfirmModal
					header="Delete Register Column"
					message={`Are you sure you want to delete?`}
					acceptFunc={this.confirmAccountColumnDelete}
					rejectFunc={() => this.setState({isDeleteConfirmActive: false})}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={this.state.isDeleteConfirmActive}
				/>
			</div>
		);
	}
}

export default DebtInputForm;
