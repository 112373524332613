import {SUBMISSIONS_FETCH, SUBMISSIONS_SELECT} from './constants';

const initialState = {
	isLoading: false,
	checked: [],
	data: [],
};

const SubmissionsList = (state = initialState, action) => {
	switch (action.type) {
		case 'SUBMISSIONS_FETCH_ONE': {
			return {
				...state,
				data: state.data.find(s => s._id === action.payload._id)
					? state.data.reduce((acc, submission) => {
							if (submission._id === action.payload._id) {
								return [...acc, action.payload];
							}
							return [...acc, submission];
					  }, [])
					: [action.payload, ...state.data],
				// if selected existed and is still present, update it to match latest data
				selected:
					(state.selected &&
						action.submissions.find(
							s => s._id === state.selected._id
						)) ||
					null,
				//remove from checked if submission was removed
				checked: state.checked.filter(s =>
					action.submissions.map(x => x._id).includes(s._id)
				),
				isLoading: false,
			};
		}
		case SUBMISSIONS_FETCH:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						data: action.submissions,
						// if selected existed and is still present, update it to match latest data
						selected:
							(state.selected &&
								action.submissions.find(
									s => s._id === state.selected._id
								)) ||
							null,
						//remove from checked if submission was removed
						checked: state.checked.filter(s =>
							action.submissions.map(x => x._id).includes(s._id)
						),
						isLoading: false,
					};
				case 'error':
					return {...state, error: action.msg, isLoading: false};
				default:
					return state;
			}

		case SUBMISSIONS_SELECT:
			return {...state, selected: action.submission};

		default:
			return state;
	}
};

export default SubmissionsList;
