const _defaultsDeep = require('lodash/defaultsDeep');
import Highcharts from 'highcharts';

class KiChart {
	constructor(params) {
		this.params = params;
	}

	// Override this with per chart type logic
	buildParamConfig() {
		return {};
	}

	generateMonochromeColors() {
		const colors = [];
		const base = this.baseChartConfig.colors[0];
		let i;
		for (i = 0; i < 10; i += 1) {
			// Start out with a darkened base color (negative brighten), and end
			// up with a much brighter color
			colors.push(
				Highcharts.Color(base) // eslint-disable-line
					.brighten((i - 3) / 7)
					.get()
			);
		}
		return colors;
	}

	generateGradientColors() {
		const colors = this.baseChartConfig.colors.map(color => {
			// Negative brighten param darkens
			return {
				radialGradient: {
					cx: 0.5,
					cy: 0.3,
					r: 0.7,
				},
				stops: [
					[0, color],
					[
						1,
						Highcharts.Color(color) // eslint-disable-line
							.brighten(-0.3)
							.get('rgb'),
					],
				],
			};
		});
		return colors;
	}

	baseChartConfig = {
		chart: {
			borderWidth: 0,
		},
		xAxis: {
			crosshair: true,
		},
		yAxis: {
			gridLineColor: '#ffffff',
		},
		tooltip: {
			shared: false,
		},
		credits: {
			enabled: false,
		},
		colors: [
			'#135E82',
			'#0092E6',
			'#FFBE00',
			'#1F83BD',
			'#FFB400',
			'#0F00EE',
			'#FF9100',
			'#0A4B82',
			'#FF8100',
			'#00A9FF',
			'#FFB800',
			'#005281',
			'#E79705',
			'#1B15FF',
			'#FFA600',
			'#1B5CAB',
			'#FF8400',
			'#00B1FF',
			'#CD8D00',
			'#0068A4',
			'#D16600',
			'#3842FF',
			'#E18E07',
		],
	};

	getConfig() {
		const chartConfig = _defaultsDeep({}, this.buildParamConfig(), this.baseChartConfig);
		// console.log('getConfig()', JSON.stringify(chartConfig, null, 2));
		return chartConfig;
	}
}

export default KiChart;
