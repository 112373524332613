import PropTypes from 'prop-types';
import React from 'react';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';
import _get from 'lodash/get';

export const TimeSeriesDisplayPeriodSelector = props => {
	const params = {
		options: [
			{value: 'last_3_periods', label: 'Last 3 periods'},
			{value: 'last_6_periods', label: 'Last 6 periods'},
			{value: 'last_12_periods', label: 'Last 12 periods'},
			{value: 'last_24_periods', label: 'Last 24 periods'},
			{value: 'all_periods', label: 'All periods'},
		],
		className: 'snapshot-selector',
		targetFunction: props.targetFunction,
		startingValue: _get(props, 'view.timeseriesRange', _get(props, 'explorerRequest.timeseries.range')),
		visible: props.visible || true,
	};
	const classNames = props.visible ? 'time-series-selector' : 'time-series-selector no-display';

	return (
		<div className={classNames}>
			<span className="theme-label form-instruction">Range</span>
			<TriggerFunctionSelector {...params} />
		</div>
	);
};

TimeSeriesDisplayPeriodSelector.propTypes = {
	dataset: PropTypes.object,
	explorerRequest: PropTypes.object, //eslint-disable-line react/no-unused-prop-types
	targetFunction: PropTypes.func,
	visible: PropTypes.bool,
	view: PropTypes.object,
	disabled: PropTypes.bool,
};

export default TimeSeriesDisplayPeriodSelector;
