import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiButton from 'components/KiButton';
import Select from 'react-select';
import options from 'ki-common/options';
import _get from 'lodash/get';
import ColConstantFuncPicker from './ColConstFuncPicker';
import _isEqual from 'lodash/isEqual';

export class CondCaseStatementRow extends Component {
	static propTypes = {
		columns: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		condition: PropTypes.object,
		reloadView: PropTypes.func,
		index: PropTypes.number,
		schemaColumns: PropTypes.array,
		hideNewColumnOption: PropTypes.bool,
	};

	static defaultProps = {
		columns: [],
		condition: {},
		schemaColumns: [],
	};

	state = {
		id: null,
		leftParamType: null,
		leftParam: null,
		rightParamType: null,
		rightParam: null,
		operator: null,
		logic: null,
	};

	componentDidMount() {
		if (this.props.condition) {
			this.setRowValues(this.props.condition);
		}
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props) && this.props.condition) {
			this.setRowValues(this.props.condition);
		}
	}

	setRowValues = values => {
		this.setState({
			id: values.id,
			leftParamType: values.operands[0].type,
			leftParam: values.operands[0].value,
			rightParamType: values.operands[1].type,
			rightParam: values.operands[1].value,
			operator: values.operator,
			logic: values.logic,
		});
	};

	setLeftParam = (type, val) => {
		if (!val && this.props.index > 0 && this.state.id) {
			this.props.onDelete(this.state.id);
		} else {
			this.setState(
				{
					leftParamType: type,
					leftParam: val,
				},
				() => this.onChange({type, val})
			);
		}
		if (!type && !val) {
			this.setRightParam(null, null);
			this.setOperator(null);
			this.setLogic(null);
		}
	};

	setRightParam = (type, val) => {
		const {operator} = this.state;
		type =
			operator && ['in', 'notIn'].includes(operator.value)
				? options.comparisonValueTypes.find(op => op.value === 'constant')
				: type;
		this.setState(
			{
				rightParamType: type,
				rightParam: val,
			},
			() => this.onChange({type, val})
		);
	};

	setOperator = val => {
		this.setState(
			{
				operator: val,
			},
			() => this.onChange({...val})
		);
	};

	setLogic = val => {
		this.setState(
			{
				logic: val,
			},
			() => this.onChange({...val})
		);
	};

	onChange = () => {
		const {operator, logic, leftParamType, leftParam, rightParamType, rightParam} = this.state;
		const rowData = {
			id: this.state.id || null,
			operands: [
				{
					type: leftParamType,
					value: leftParam,
				},
				{
					type: rightParamType,
					value: rightParam,
				},
			],
			operator: operator,
			logic: logic,
		};
		this.props.onChange(rowData);
	};

	getOperatorOptions = () => {
		const {leftParamType, leftParam} = this.state;
		const value = _get(leftParamType, 'value', null);
		const target =
			value === 'column' || value === 'subAccountColumn'
				? _get(leftParam, 'dataType')
				: _get(leftParamType, 'value');
		switch (target) {
			case 'string':
				return options.booleanLogicText;
			case 'date_long':
			case 'numeric':
			default:
				return options.booleanLogicNumeric;
		}
	};

	render() {
		const {columns, onDelete, index, schemaColumns} = this.props;
		const {id, operator, logic, leftParamType, leftParam, rightParamType, rightParam} = this.state;
		const filterColumns = (param, cols, dataType, paramType) => {
			if (cols && param && dataType !== 'constant') {
				if (dataType && (paramType === 'column' || paramType === 'subAccountColumn')) {
					return cols.filter(col => col.dataType === dataType);
				}
				return cols.filter(col => col.dataType === paramType);
			}
			return cols || [];
		};
		return (
			<div className="conditional-row-cont">
				<div className="conditional-row">
					<ColConstantFuncPicker
						onChange={this.setLeftParam}
						columns={filterColumns(
							rightParam,
							columns,
							_get(rightParam, 'dataType', null),
							_get(rightParamType, 'value', null)
						)}
						hideNewColumnOption={this.props.hideNewColumnOption}
						type={leftParamType}
						dataType={_get(rightParam, 'dataType', null) || _get(rightParamType, 'value', null)}
						value={leftParam}
						log="LeftParam"
						reloadView={this.props.reloadView}
						schemaColumns={filterColumns(
							rightParam,
							schemaColumns,
							_get(rightParam, 'dataType', null),
							_get(rightParamType, 'value', null)
						)}
					/>
					<div style={{minWidth: '9rem'}} className={leftParam ? '' : 'hidden'}>
						<span className="form-instruction">Operator:</span>
						<Select
							classNamePrefix="aut-select"
							value={operator}
							isClearable={false}
							options={this.getOperatorOptions()}
							onChange={selected => this.setOperator(selected)}
						/>
					</div>
				</div>
				{leftParam ? (
					<div className="conditional-row">
						<ColConstantFuncPicker
							onChange={this.setRightParam}
							hideNewColumnOption={this.props.hideNewColumnOption}
							columns={filterColumns(
								leftParam,
								columns,
								_get(leftParam, 'dataType', null),
								_get(leftParamType, 'value', null)
							)}
							type={
								operator && ['in', 'notIn'].includes(operator.value)
									? options.comparisonValueTypes.find(op => op.value === 'constant')
									: rightParamType
							}
							hideCancelBtn={operator && ['in', 'notIn'].includes(operator.value) ? true : false}
							dataType={_get(leftParam, 'dataType', null) || _get(leftParamType, 'value', null)}
							value={rightParam}
							log="RightParam"
							reloadView={this.props.reloadView}
							schemaColumns={filterColumns(
								leftParam,
								schemaColumns,
								_get(leftParam, 'dataType', null),
								_get(leftParamType, 'value', null)
							)}
						/>
						<div style={{minWidth: '9rem'}}>
							<span className="form-instruction">Logic:</span>
							<Select
								classNamePrefix="aut-select"
								value={logic}
								isClearable={true}
								options={options.conditionalTypes}
								onChange={selected => this.setLogic(selected)}
							/>
						</div>
						{id && index !== 0 ? (
							<KiButton
								raised
								primary
								style={{marginTop: '2rem'}}
								onClick={() => onDelete(this.state.id)}
							>
								Remove Statement
							</KiButton>
						) : null}
					</div>
				) : null}
			</div>
		);
	}
}

export default CondCaseStatementRow;
