// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import _get from 'lodash/get';
import {AbsUploader, ABSUPLOADER_STATUSES} from '@moodysanalytics/cs-structured-ux-common';

// Externals
import KiSelect from 'components/KiSelect';
import KiButton from 'components/KiButton';
import KiCheckbox from 'components/KiCheckbox';
import {apiUrl} from 'api/apiUtils';
import {reportsApi} from 'api';

// Relative
import styles from '../reports.theme.scss';

export class ReportOutputForm extends Component {
	static propTypes = {
		report: PropTypes.object,
		user: PropTypes.object.isRequired,
		onReportTemplateSaved: PropTypes.func,
	};

	static defaultProps = {
		datasetList: [],
		fundingVehicleList: [],
		snapshotDateList: [],
		viewList: [],
		report: {
			name: '',
			datasetId: '',
			snapshot: {
				snapshotDate: '',
				showMostRecent: true,
				snapshotGranularity: 'monthEnd',
			},
			fundingVehicleIds: [],
			sections: [],
			createdBy: '',
			updatedBy: '',
		},
	};

	constructor(props) {
		super(props);
		this.uploaderRef = React.createRef();
		this.uploader = {
			options: {
				validation: {
					allowedExtensions: ['jrxml', 'xlsx'],
				},
				chunkingEnabled: true,
				chunkSize: parseInt(window.TEMPLATE_CHUNKSIZE),
				endpoint: `${apiUrl}/reports/${this.state.selectedTemplateType.value}/template/${
					this.props.report._id
				}`,
			},
			callbacks: {
				onSubmit: (id, file) => {
					this.setState({templateName: file.name});
				},
				onStatusChange: (id, oldStatus, newStatus) => {
					this.setState({uploadStatus: newStatus});
					if (newStatus === ABSUPLOADER_STATUSES.SUBMITTED) {
						this.setState({enableUploadButton: true});
					}
					if (newStatus === ABSUPLOADER_STATUSES.UPLOAD_SUCCESSFUL) {
						this.setState({enableUploadButton: false});
						// upload successful save off the template to the report
						reportsApi
							.updateReportTemplateName(
								this.props.report._id,
								this.state.templateName,
								this.state.selectedTemplateType.value
							)
							.then(() => {
								this.setState({excelUserTemplateName: this.state.templateName});
								this.props.onReportTemplateSaved();
							})
							.catch(error => {
								this.setState({errorMsg: error.msg});
							});
					}
				},
				onUploadChunkSuccess: (id, partIndex, totalParts) => {
					if (partIndex === totalParts - 1) {
						this.setState({enableUploadButton: true});
					}
				},
				onError: (id, name, errorReason, response) => {
					const msg = response?.message || errorReason;
					this.setState({enableUploadButton: false});
					this.setState({errorMsg: msg});
				},
			},
		};
	}

	state = {
		selectedDatasetId: null,
		viewOptionTypes: [],
		showUploadTemplate: true,
		enableUploadButton: false,
		selectedTemplateType: {label: 'Excel', value: 'excel'},
		protectDataTab: _get(this.props.report, 'templates.excel.protectDataTab', false),
		excelUserTemplateName: _get(this.props.report, 'templates.excel.files[0].fileName', null),
	};

	generateTemplateLink = (useUserTemplate, templateType) => {
		const params = [];

		const origin = document.baseURI;
		/*let origin;
		if (window.location.origin) {
			origin = window.location.origin;
		}

		if (!window.location.origin) {
			const port = window.location.port ? `:${window.location.port}` : '';
			origin = `${window.location.protocol}//${window.location.hostname}${port}`;
		}
		*/
		const url = `${origin}${apiUrl}/reports/${templateType}/template/${this.props.report._id}`;

		params.push(`ver=2`);
		if (useUserTemplate) {
			params.push(`userTemplate=true`);
		} else {
			params.push(`defaultTemplate=true`);
		}
		return params.length > 0 ? `${url}?${params.join('&')}` : url;
	};

	startUpload = () => {
		this.uploader.params = {
			reportDefinitionId: this.props.report._id,
			templateName: this.state.templateName,
			templateType: this.state.selectedTemplateType,
			userId: this.props.user.userId,
		};
		this.uploaderRef.current.triggerUpload();
	};

	onClickProtectDataTab = () => {
		this.setState({protectDataTab: !this.state.protectDataTab});
		reportsApi.updateReportTemplateProtectDataTab(this.props.report._id, !this.state.protectDataTab).then(() => {
			this.props.onReportTemplateSaved();
		});
	};

	render() {
		const {excelUserTemplateName} = this.state;

		return (
			<div className={styles.reportDefinitionFormOutput}>
				<span className={styles.title}>Templates</span>
				<div className={styles.templateInfoWrapper}>
					<span className={styles.smallerTitle}>Excel</span>
					<div className={styles.templateContainer}>
						{!excelUserTemplateName && <span className={styles.label}>No template uploaded</span>}
						{excelUserTemplateName && (
							<a className={styles.templateLink} href={this.generateTemplateLink(true, 'excel')}>
								{excelUserTemplateName}
							</a>
						)}
						<KiCheckbox
							name="hideTabs"
							checked={this.state.protectDataTab}
							label="Hide / Lock tabs"
							onChange={val => this.onClickProtectDataTab(val)}
						/>
					</div>
				</div>
				<span className={styles.title}>Upload</span>
				<AbsUploader config={this.uploader} ref={this.uploaderRef} />
				{this.state.errorMsg && <span className={styles.error}>{this.state.errorMsg}</span>}
				<div className={styles.outputForm}>
					<KiSelect
						classNamePrefix="aut-select"
						value={this.state.selectedTemplateType}
						isClearable={false}
						options={[{label: 'Excel', value: 'excel'}]}
						onChange={templateType => this.setState({selectedTemplateType: templateType})}
						getOptionLabel={option => option.label}
						getOptionValue={option => option.value}
					/>
					<KiButton
						onMouseUp={this.startUpload}
						className={styles.uploadButton}
						label="Upload"
						icon="cloud_upload"
						raised
						primary
						disabled={!this.state.enableUploadButton}
					/>
				</div>
			</div>
		);
	}
}

export default ReportOutputForm;
