// Global imports
import React, {createContext, useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Project imports
import exploreRequestBuilder from 'ki-common/utils/exploreRequestBuilder';
import {determineScenarioType, getDefaultColumnIds} from 'ki-common/utils/explorerUtils';
import {useMergedState} from 'utils/customHooks';
import {updateInArray} from 'utils/arrayUtils';
import {showSnackbar} from 'state/actions/Snackbar';
import {loadView} from 'components/FlyoutManageViews/components/ViewForms/actions';
import {dataBookmarksApi, explorerApi} from 'api';

// Logger
import ReactLogger from 'utils/ReactLogger';
const logger = new ReactLogger({level: window.REACT_LOG_LEVEL});

/*
USAGE IN HOOKS
	import {DataExplorerContext} from './DataExplorerContext';

	const dataExplorerContext = useContext(DataExplorerContext);
	const name = dataExplorerContext.bookmark.name;


USAGE NO HOOKS
	import {DataExplorerConsumer} from './DataExplorerContext';

	withContext = () => (
		<DataExplorerConsumer>
			{state => <div>{explorerState.bookmark.name}</div>}
		</DataExplorerConsumer>
	);
 */

// Main context
export const DataExplorerContext = createContext();
DataExplorerContext.displayName = 'DataExplorerContext';
export default DataExplorerContext;

// Non-hook context
export const DataExplorerConsumer = DataExplorerContext.Consumer;

const findProfileDateContextByName = (dateContextList, dateContextName = 'Latest Snapshot') => {
	const match = dateContextList.find(dateContext => {
		return (
			dateContext.name == dateContextName && dateContext.isPortfolioDate === true && dateContext.readOnly === true
		);
	});
	if (!match) {
		return dateContextList[0];
	}
	return match;
};

// Default State
const defaultState = {
	name: '',
	datasetId: '',
	createdBy: '',
	updatedBy: '',
	createDate: '',
	tags: [],
	isDefault: false,
	isGadget: false,
	isGlobal: false,
	explorerData: _.cloneDeep(exploreRequestBuilder.defaultState),
};

/* eslint-disable no-use-before-define */
// Main provider
export const DataExplorerProvider = ({children}) => {
	const dispatch = useDispatch();
	const queryParams = new URLSearchParams(location.search);

	// Mergable active bookmark to recieve changes
	const [bookmark, setBookmark, resetBookmark] = useMergedState(_.cloneDeep(defaultState));

	// The applied bookmark is used to actually make the fetch request
	// It starts empty and is populated by loading, applying, etc
	const [appliedBookmark, setAppliedBookmark, resetAppliedBookmark] = useMergedState({});

	const setBothBookmarks = changes => {
		setBookmark(changes);
		setAppliedBookmark(changes);
		setHasUnsavedChanges(true);
	};

	// Holds the fetched data for rendering
	const [renderState, setRenderState] = useState({
		isLoading: true,
		error: null,
		data: null, // columns, echoRequestJson, pageNumber, pageSize, rows, showTotals, snapshotDate, tableType, totalNumberOfRows
		requestedStatementDate: null,
	});

	// General state
	const [allColumns, setAllColumns] = useState([]); // All columns the user could select?
	const [dateContextList, setDateContextList] = useState([]);
	const [calculatedDateInfo, setCalculatedDateInfo] = useState({}); // { name: '', calculatedDate: ''} or {startName: '', startCalculatedDate: '', endName: '', endCalculatedDate: ''}
	const [hasChanges, setHasChanges] = useState(false); // Used to enable/disable apply button
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Used to show the * after bookmark name
	const [quickFilterLists, setQuickFilterLists] = useState({
		fundingVehicles: [],
		pools: [],
		scenarios: [],
	});
	const [originalViewName, setOriginalViewName] = useState(''); // used for name duplication checks

	const selectedDataset = useSelector(state => state.datasetList.selected);
	const columnList = useSelector(state => state.datasetList.columnList);

	const setBookmarkAndMarkChanged = param => {
		setBookmark(param);
		setHasChanges(true);
	};

	// Quick access to explorer data state
	const explorerState = bookmark.explorerData;
	const setExplorerData = (param, markChanges = false) => {
		setBookmark({explorerData: param});
		if (markChanges) {
			setHasChanges(true);
		}
	};

	// This method should never be called directly, instead it should be triggered anytime an update is made
	// to the appliedBookmark (pagination, copy on load, apply clicked etc) since the appliedBookmark represents
	// the most recently used values to fetch data with
	const fetchExplorerData = async () => {
		setRenderState({
			data: null,
			error: null,
			isLoading: true,
			requestedStatementDate: '',
		});

		const curExplorerState = appliedBookmark.explorerData;
		logger.log('fetchExplorerData');
		// eslint-disable-next-line no-console
		// console.dir(curExplorerState);
		if (curExplorerState.tableType === 'timeSeries') {
			// catch missing timeseries columns and revert to cohort
			const match = curExplorerState.columns.find(c => c._id === curExplorerState.timeseries?.column?._id);
			if (!match && curExplorerState.timeseries?.column?._id != 'all') {
				setExplorerData({
					breadcrumbs: [],
					tableType: 'cohort',
				});
			}
		}

		return explorerApi
			.fetchExplore(curExplorerState, allColumns)
			.then(data => {
				let parsedRequest = {};
				if (typeof data?.echoRequestJson === 'string') {
					try {
						parsedRequest = JSON.parse(data.echoRequestJson);
					} catch (err) {
						//eslint-disable-next-line no-console
						console.log(err?.message || err);
					}
				}
				setCalculatedDateInfo({
					name:
						dateContextList?.find?.(dc => dc?._id === curExplorerState.dateContext)?.name || 'Date Context',
					startName:
						dateContextList?.find?.(dc => dc?._id === curExplorerState.startDateContext)?.name ||
						'Start Date Context',
					endName:
						dateContextList?.find?.(dc => dc?._id === curExplorerState.endDateContext)?.name ||
						'End Date Context',
					calculatedDate: parsedRequest.snapshotDate,
					startCalculatedDate: parsedRequest.startDate,
					endCalculatedDate: parsedRequest.endDate,
				});
				setRenderState({
					data: data,
					error: null,
					isLoading: false,
					requestedStatementDate: curExplorerState.statementDate,
				});
			})
			.catch(error =>
				setRenderState({
					data: null,
					error,
					isLoading: false,
					requestedStatementDate: '',
				})
			);
	};

	const _setQuickFilterPool = (bookmarkWithParams, poolId) => {
		// set quick filter pool ID
		bookmarkWithParams.explorerData.quickFilters.poolId = poolId;
		const pool = quickFilterLists.pools.find(pool => pool.value === poolId);
		// left option to check label for any change that the poolList may not have the isHypo field.;
		const isHypoPool = !!(pool.isHypo || pool.label.match(/\((hypo|committed|closed)\)/i));
		// Set quick filter hypo pool ID
		bookmarkWithParams.explorerData.quickFilters.hypoPoolId = isHypoPool && poolId ? poolId : '';
		if (!bookmarkWithParams.explorerData.quickFilters.fundingVehicleId) {
			// Set quick filter funding vehicle ID if unset
			bookmarkWithParams.explorerData.quickFilters.fundingVehicleId = pool.fundingVehicleId;
		}
	};

	// TODO this should probably be moved to the BookmarkApiContext
	// This method is making direct changes because they should happen before the bookmark is used
	// to fetch data for the first time. Many duplications with direct set quick filter methods.
	const _loadQueryParams = bookmarkWithoutParams => {
		const bookmarkWithParams = Object.assign({}, bookmarkWithoutParams); // Prevent mutation
		const statementDate = queryParams.get('statementDate');
		if (statementDate) {
			bookmarkWithParams.explorerData.statementDate = statementDate;
		}

		const queryFundingVehicle = queryParams.get('fundingVehicleId');
		if (queryFundingVehicle) {
			bookmarkWithParams.explorerData.quickFilters.fundingVehicleId = queryFundingVehicle;
			bookmarkWithParams.explorerData.quickFilters.poolId = '';
		}

		const queryPool = queryParams.get('poolId');
		if (queryPool) {
			_setQuickFilterPool(bookmarkWithParams, queryPool);
			// This also sets hypoPoolId
			// This also sets fundingVehicleId if not found
		}

		const queryHypoPool = queryParams.get('hypoPoolId');
		if (queryHypoPool) {
			_setQuickFilterPool(bookmarkWithParams, queryHypoPool);

			// I think all these are set in _setQuickFilterPool
			// setQuickFilterHypoPool(queryHypoPool);
			// _setDefaultFundingVehicleForPool(queryHypoPool);
			// setQuickFilterPool(queryHypoPool);

			const queryHypoFundingVehicle = queryParams.get('hypoFundingVehicleId');
			// explore from solver wants hypo
			bookmarkWithParams.explorerData.quickFilters.hypoFundingVehicleId = queryHypoFundingVehicle;
		}

		const queryScenario = queryParams.get('scenarioId');
		if (queryScenario && queryScenario !== 'null') {
			const scenarioType = determineScenarioType(queryScenario);
			bookmarkWithParams.explorerData.quickFilters.scenarioId = queryScenario;
			bookmarkWithParams.explorerData.quickFilters.scenarioType = scenarioType;
		}

		const snapshotType = queryParams.get('snapshotType');
		if (snapshotType) {
			bookmarkWithParams.explorerData.snapshotType = snapshotType === 'blended' ? 'blended' : 'standard';
		}

		return bookmarkWithParams;
	};

	// Load a new bookmark, replacing the current one, update applied
	/*
	 	quickFilterLists = quickFilterLists is passed so that the bookmark can be loaded with the latest quick filter lists
	 	from a direct fetch without having to rely on the useState hook update of setQuickFilterLists. Due to the beyond 
		stupid nature of the react lifecycle hooks, the quickFilterLists state is not updated in the loadBookmark method call
		even if log timing would indicate otherwise. Hence this work around.
	*/
	const loadBookmark = (rawBookmark, quickFilterLists = quickFilterLists) => {
		logger.log('loadBookmark');

		// Basically the merge should only overwrite if not empty
		const emptyMerge = (target, source) => (!_.isEmpty(source) ? source : _.isEmpty(target) ? source : target);

		// Have some base default values to prevent errors, defaults to the first value found in order
		// It is broken out to allow for shallow copies due to the nature of Object.assign
		const preLoadBookmark = Object.assign({}, rawBookmark);
		preLoadBookmark.explorerData = _.merge(
			{},
			{
				statementDate: '', // Logic for this is handled in DataExplorerLayout
				dateContext: '',
				startDateContext: findProfileDateContextByName(dateContextList, 'Start of Month')._id,
				endDateContext: findProfileDateContextByName(dateContextList, 'Month End')._id,
				isFixedDate: false,
				sortColumn: '',
				sortCalculation: '',
				groupBy: '',
				maxRecords: '',
				snapshotType: 'standard',
				granularity: '',
				filters: [],
				timeseries: {},
				isLoading: false,
				error: null,
				breadcrumbs: [],
				transpose: false,
				showTotals: false,
				data: null,
				bands: exploreRequestBuilder.defaultState.bands,
			},
			rawBookmark.explorerData
		);
		preLoadBookmark.explorerData.bucket = _.merge(
			{},
			{
				min: '',
				max: '',
				value: '',
			},
			rawBookmark.explorerData.bucket
		);
		// Only override these defaults if a value exists
		preLoadBookmark.explorerData.quickFilters = _.mergeWith(
			{},
			{
				scenarioId: 'assetSnapshot',
				scenarioType: 'assetSnapshot',
				fundingVehicleId: '',
				poolId: '',
				hypoFundingVehicleId: '',
				hypoPoolId: '',
			},
			rawBookmark.explorerData.quickFilters,
			emptyMerge
		);

		// BLD-22408 If a hypoPool is selected then it should also override the poolId
		const {poolId, hypoPoolId} = preLoadBookmark.explorerData.quickFilters;
		if (hypoPoolId && !poolId) {
			preLoadBookmark.explorerData.quickFilters.poolId = hypoPoolId;
		}
		const found = quickFilterLists.pools.find(x => x.value === preLoadBookmark.explorerData.quickFilters.poolId);

		if (!found) {
			preLoadBookmark.explorerData.quickFilters.poolId = '';
			preLoadBookmark.explorerData.quickFilters.hypoPoolId = '';
		}

		// Not sure why we do this, just copying the code
		if (preLoadBookmark.explorerData.granularity) {
			preLoadBookmark.explorerData.granularity = preLoadBookmark.explorerData.granularity.toString();
		}

		// handle missing custom buckets
		if (!preLoadBookmark.explorerData.columns[0].bands) {
			preLoadBookmark.explorerData.columns[0].bands = {type: 'default'};
		}

		// handle time series breadcrumb orphan
		if (
			_.get(bookmark, 'explorerData.breadcrumbs[0].type', null) === 'timeSeries' &&
			preLoadBookmark.explorerData.tableType !== 'timeSeries'
		) {
			preLoadBookmark.explorerData.breadcrumbs = [];
		}

		// if timeseries column is selected, get latest display format from bookmark's matching summary column
		const tsColId = _.get(preLoadBookmark.explorerData, 'timeseries.column._id');
		if (tsColId && tsColId !== 'all') {
			const match = preLoadBookmark.explorerData.columns.find(col => col._id === tsColId);
			if (match) {
				Object.assign(preLoadBookmark.explorerData.timeseries.column, _.pick(match, ['displayFormat']));
			}
		}

		// to correct a bug where explorerData.timeseries was being saved as an empty array if invalid
		if (Array.isArray(preLoadBookmark.explorerData.timeseries)) {
			preLoadBookmark.explorerData.timeseries = {};
		}

		const preLoadBookmarkWithParams = _loadQueryParams(preLoadBookmark);

		//logger.log(JSON.stringify(bookmark, null, 2));
		resetBookmark(preLoadBookmarkWithParams);
		resetAppliedBookmark(preLoadBookmarkWithParams);
		setOriginalViewName(preLoadBookmarkWithParams.name);
		dispatch(loadView(preLoadBookmarkWithParams));
		setHasChanges(false);
	};

	// Apply changes made to a live bookmark and update applied
	const applyChanges = () => {
		logger.log('applyChanges');
		// Validation check
		if (!bookmark.name || !bookmark.name.trim()) {
			dispatch(showSnackbar(`Cannot apply changes while name is invalid`));
			return false;
		}
		resetAppliedBookmark(bookmark);
		setHasChanges(false);
		setHasUnsavedChanges(true);
	};

	// Use this any time changes need to be made and applied in one go,
	// such as paging changes, etc
	const setExplorerDataAndFetch = changes => {
		logger.log('setExplorerDataAndFetch', changes);
		setBothBookmarks({explorerData: changes});
	};

	// End render data fetch methods
	// ===============================

	// =============================
	// Tag QFS means the quick filter / settings combination will fix this
	// =============================

	const getSortColumnChanges = (sortColumn = '', sortOrder = 'asc', sortCalculation = '') => {
		const changes = {
			sortColumn,
			sortCalculation,
			sortOrder,
		};
		return changes;
	};

	const fetchQuickFiltersForBookmark = async (
		datasetId,
		scenarioId,
		fundingVehicleId,
		statementDate,
		scenarioType,
		dateContext,
		dateContextList,
		isFixedDate
	) => {
		const results = await explorerApi.fetchQuickFilters(
			datasetId,
			scenarioId,
			fundingVehicleId,
			statementDate,
			scenarioType,
			dateContext,
			dateContextList,
			isFixedDate
		);
		return {
			fundingVehicles: results.fundingVehicleList,
			pools: results.poolList,
			scenarios: results.scenarioList,
		};
	};

	const updateQuickFilters = (
		datasetId,
		scenarioId,
		fundingVehicleId,
		statementDate,
		scenarioType,
		dateContext,
		dateContextList,
		isFixedDate
	) => {
		fetchQuickFiltersForBookmark(
			datasetId,
			scenarioId,
			fundingVehicleId,
			statementDate,
			scenarioType,
			dateContext,
			dateContextList,
			isFixedDate
		)
			.then((quickFilterLists = {}) => setQuickFilterLists(quickFilterLists))
			.catch(err => {
				logger.error({err}, 'Error pre-fetching quick filters for bookmark');
			});
	};

	const debouncedUpdateQuickFilters = useCallback(_.debounce((...args) => updateQuickFilters(...args), 500), []);

	// TODO check if pool change filters pools by fvId
	useEffect(
		() => {
			if (
				bookmark.datasetId &&
				(!explorerState.dateContext ||
					(dateContextList.length && dateContextList[0].datasetId === bookmark.datasetId))
			) {
				debouncedUpdateQuickFilters(
					bookmark.datasetId,
					explorerState.quickFilters.scenarioId,
					explorerState.quickFilters.fundingVehicleId,
					explorerState.statementDate,
					explorerState.quickFilters.scenarioType,
					explorerState.dateContext,
					dateContextList,
					explorerState.isFixedDate,
					selectedDataset
				);
			}
		},
		[
			bookmark.datasetId,
			explorerState.quickFilters.scenarioId,
			explorerState.quickFilters.fundingVehicleId,
			explorerState.statementDate,
			explorerState.quickFilters.scenarioType,
			explorerState.dateContext,
			dateContextList,
			explorerState.isFixedDate,
			selectedDataset,
		]
	);

	const setSortColumn = (columnId, order, calculation) => {
		const singleCommit = getSortColumnChanges(columnId, order, calculation);
		setBothBookmarks({explorerData: singleCommit});
	};

	const setStatementDate = statementDate => {
		setExplorerData({
			statementDate,
			pageNumber: 1,
		});
		setHasChanges(true);
		// QFS dispatch(updateSidetrayPageNumber(1));
	};

	const setTableType = tableType => {
		let timeSeriesChanges = {};
		if (tableType !== 'timeSeries') {
			timeSeriesChanges = setTimeSeriesData({columnData: {id: null}}, true);
		}
		const changes = _.merge(
			{
				tableType,
			},
			timeSeriesChanges
		);
		setExplorerData(changes, true);
	};

	const setDatasetId = datasetId => {
		setExplorerData({
			datasetId,
			explorerData: {
				datasetId,
			},
		});
	};

	const setCohortColumn = (columnId, returnChanges = false) => {
		const cohortColumn = allColumns.find(column => column._id === columnId);
		const changes = {
			columns: [cohortColumn, ...explorerState.columns.slice(1)],
			pageNumber: 1,
		};
		let breadcrumbObj = {};
		if (explorerState.tableType !== 'timeSeries') {
			breadcrumbObj = clearBreadcrumbs();
			changes.tableType = 'cohort';
		}
		const sortColObj = getSortColumnChanges(cohortColumn._id, 'asc', null);
		const bucketObj = setBucket('', '', '', true);
		const granularityObj = setGranularity(null, true);

		_.merge(changes, breadcrumbObj, sortColObj, bucketObj, granularityObj);
		if (returnChanges) {
			return changes;
		} else {
			setExplorerData(changes);
			setHasChanges(true);
		}
	};

	const setCohortData = data => {
		const cohortColumn = {...explorerState.columns[0], ...data};
		const newState = {
			columns: [cohortColumn, ...explorerState.columns.slice(1)],
		};
		setExplorerData(newState, true);
	};

	const setGroupBy = columnId => {
		const defaultColumnIds = getDefaultColumnIds(selectedDataset.snapshots, columnList, explorerState.snapshotDate);
		if (!columnId || defaultColumnIds.assetColumnId === columnId) {
			setExplorerData({groupBy: ''}, true);
			setCohortColumn(defaultColumnIds.balanceCohortColumnId);
		} else {
			setExplorerData({groupBy: columnId}, true);
			setCohortColumn(defaultColumnIds.balanceAggregateColumnId);
		}
	};

	// TODO rename add breadcrumb
	const setBreadcrumb = (breadcrumb, returnChanges = false) => {
		const changes = {breadcrumbs: [...explorerState.breadcrumbs, breadcrumb]};
		if (returnChanges) {
			return changes;
		} else {
			setExplorerData(changes);
		}
	};

	// TODO rename set breadcrumbs
	const loadBreadcrumbs = breadcrumbList => {
		setExplorerData({breadcrumbs: breadcrumbList});
		// QFS dispatch(loadSidetrayBreadcrumbs(breadcrumbList));
	};

	const clearBreadcrumbs = (returnChanges = false) => {
		const changes = {breadcrumbs: []};
		if (returnChanges) {
			return changes;
		} else {
			setExplorerData(changes);
		}
	};

	const updateBreadcrumb = breadcrumb => {
		// eslint-disable-next-line no-use-before-define
		setBucket(breadcrumb.bucket.min, breadcrumb.bucket.max, breadcrumb.bucket.value);
		const idx = explorerState.breadcrumbs.findIndex(b => b.id === breadcrumb.id);
		const bc = explorerState.breadcrumbs[idx];
		bc.currValue = breadcrumb.value;
		setExplorerData(
			{
				breadcrumbs: [
					...explorerState.breadcrumbs.slice(0, idx),
					bc,
					...explorerState.breadcrumbs.slice(idx + 1).filter(bc => bc.type === 'asset'),
				],
			},
			true
		);
		// QFS dispatch(updateSidetrayBreadcrumb(breadcrumb));
	};

	const deleteBreadcrumb = breadcrumb => {
		let bucket;
		const {id} = breadcrumb;
		const idx = explorerState.breadcrumbs.findIndex(b => b.id === id);
		// if we are dealing with the first breadcrumb
		if (idx === 0) {
			bucket = {
				min: '',
				max: '',
				value: '',
			};
		} else {
			const targetBreadcrumb = explorerState.breadcrumbs[idx - 1];
			bucket =
				(targetBreadcrumb.options[targetBreadcrumb.currValue] &&
					targetBreadcrumb.options[targetBreadcrumb.currValue].bucket) ||
				{}; //eslint-disable-line prefer-destructuring
		}
		setExplorerData(
			{
				tableType: 'cohort',
				pageNumber: 1,
				breadcrumbs: [...explorerState.breadcrumbs.slice(0, idx)],
			},
			true
		);
		// QFS dispatch(updateSidetrayPageNumber(1));
		// QFS dispatch(deleteSidetrayBreadcrumb(breadcrumb));
		// eslint-disable-next-line no-use-before-define
		setBucket(bucket.min, bucket.max, bucket.value);
		if (breadcrumb.prevGranularity) {
			// eslint-disable-next-line no-use-before-define
			setGranularity(breadcrumb.prevGranularity);
		}
	};

	const setBucket = (min, max, value, returnChanges = false) => {
		const granularityArray = [
			'daily',
			'weekly',
			'weeklyFixed',
			'biWeekly',
			'biWeeklyFixed',
			'fortnightly',
			'monthly',
			'quarterly',
			'semiAnnually',
			'annually',
		];
		if (!value && explorerState.tableType === 'asset' && explorerState.columns[0].dataType === 'string') {
			// BE cannot handle empty string in this situation so pass pre-set value
			value = 'emptyString';
		}

		if (explorerState.tableType === 'asset' && granularityArray.includes(explorerState.columns[0].granularity)) {
			if (value.includes('Prior')) {
				value = '';
				min = '';
				max = explorerState.columns[0].dateRange.start;
			}
			if (value.includes('Post')) {
				value = '';
				min = explorerState.columns[0].dateRange.end;
				max = '';
			}
			// dispatch(setStartInclusive(false));
		}
		const changes = {
			bucket: {
				min,
				max,
				value,
			},
		};
		if (returnChanges) {
			return changes;
		} else {
			setExplorerData(changes);
		}
	};

	// Calling this without returnChanges=true will re-fetch page data
	const setGranularity = (granularity, returnChanges = false) => {
		const defaultBands = Object.assign({}, {type: 'default'});
		// custom even won't work because the action.granularity is 'custom-even' instead of a number
		const customEvenBands = Object.assign({}, {type: 'custom-even'}, {step: Number(granularity)});
		const changes = {
			pageNumber: 1,
			granularity: granularity,
			bands: granularity === 'custom-even' ? customEvenBands : defaultBands,
		};
		if (returnChanges) {
			return changes;
		} else {
			setExplorerData(changes);
			setHasChanges(true);
		}
	};

	// TODO separate setGranularity into setGranularity and getGranularityChanges then see
	// how many times an external setGranularity is actually being called
	const setGranularityAndFetch = granularity => {
		const singleCommit = setGranularity(granularity, true);
		setBothBookmarks({explorerData: singleCommit});
		setHasChanges(true);
	};

	// This method replaces setTimeSeriesColumn, setTimeSeriesRange, and setTimeSeriesPeriod
	const setTimeSeriesData = ({columnData, range, period}, returnChanges = false) => {
		const changes = {
			pageNumber: 1,
			timeseries: {},
		};
		if (range) changes.timeseries.range = range;
		if (period) changes.timeseries.period = period;
		let cohortColumnObj = {};
		if (columnData) {
			const column = allColumns.find(column => column._id === columnData.id) || {_id: columnData.id};
			changes.timeseries.column = column;
			if (columnData.id === 'all') {
				const snapshotDateColumn = allColumns.find(
					column => _.get(column, 'assetColumn.columnName') === 'kiSnapshotDate'
				)._id;
				cohortColumnObj = setCohortColumn(snapshotDateColumn, true);
			}
		}
		_.merge(changes, cohortColumnObj);
		if (returnChanges) {
			return changes;
		} else {
			setExplorerData(changes, true);
		}
	};

	//setDateContext call setExplorerData({dateContext: 1})
	//setIsFixedDate call setExplorerData({isFixedDate: true})
	//setFilters call setExplorerData({filters: value})
	//setStartInclusive call setExplorerData({startInclusive: false})

	const setSnapshotType = showBlended => {
		const snapshotType = showBlended ? 'blended' : 'standard';
		setExplorerData({snapshotType});
	};

	const loadTimeSeries = timeseries => {
		setExplorerData({
			timeseries,
			pageNumber: 1,
		});
	};

	const setQuickFilterScenario = scenarioId => {
		const scenarioType = determineScenarioType(scenarioId);
		setExplorerData(
			{
				quickFilters: {
					scenarioId,
					scenarioType,
					// fundingVehicleId: '',
					// poolId: '',
					// hypoFundingVehicleId: '',
					// hypoPoolId: '',
				},
			},
			true
		);
	};

	const setQuickFilterScenarioType = scenarioType => {
		setExplorerData({quickFilters: {scenarioType}}, true);
	};

	const setQuickFilterFundingVehicle = fundingVehicleId => {
		setExplorerData({quickFilters: {fundingVehicleId, poolId: ''}}, true);
	};

	const setQuickFilterPool = poolId => {
		setExplorerData({quickFilters: {poolId}}, true);
		const pool = _.get(quickFilterLists, 'pools', []).find(pool => pool.value === poolId);
		const isHypoPool = !!(pool.isHypo || pool.label.match(/\((hypo|committed|closed)\)/i)); // left option to check label for any change that the poolList may not have the isHypo field.;
		setQuickFilterHypoPool((isHypoPool && poolId) || '');
		if (!explorerState.quickFilters.fundingVehicleId) {
			const {fundingVehicleId} = pool;
			setExplorerData({quickFilters: {fundingVehicleId}});
		}
	};

	const setQuickFilterHypoPool = hypoPoolId => {
		setExplorerData({quickFilters: {hypoPoolId}}, true);
	};

	const setQuickFilterHypoFundingVehicle = hypoFundingVehicleId => {
		setExplorerData({quickFilters: {hypoFundingVehicleId}}, true);
	};

	const setColumns = (columnsToSet, columnType, returnChanges = false, markChanges = true) => {
		const newColumns = exploreRequestBuilder.getNewColumnsByType(explorerState.columns, columnType, columnsToSet);
		const changes = {columns: newColumns};
		if (returnChanges) {
			return changes;
		} else {
			setExplorerData({columns: newColumns}, markChanges);
		}
	};

	const updateColumn = (index, column) => {
		const newColumns = updateInArray(explorerState.columns, column, index);
		setExplorerData({columns: newColumns}, true);
	};

	const drillIntoAsset = (bucket, breadcrumb) => {
		const singleCommit = {tableType: 'asset'};
		const existingAssetColumns = explorerState.columns.filter(col => col.columnType === 'asset');
		const columnsObj = setColumns(existingAssetColumns, 'asset', true);
		const assetBreadcrumb = {
			id: 1,
			type: 'asset',
			currValue: 1,
			options: [],
		};
		const breadcrumbObj = {breadcrumbs: [...explorerState.breadcrumbs, breadcrumb, assetBreadcrumb]};
		const bucketObj = setBucket(bucket.min, bucket.max, bucket.value, true);
		_.merge(singleCommit, columnsObj, breadcrumbObj, bucketObj);
		logger.log('drillIntoAsset', singleCommit);
		setBothBookmarks({explorerData: singleCommit});
		setHasChanges(true);
	};

	const drillIntoBucket = (bucket, breadcrumb, granularity) => {
		const singleCommit = {};
		const breadcrumbObj = setBreadcrumb(breadcrumb, true);
		const granularityObj = setGranularity(granularity, true);
		const bucketObj = setBucket(bucket.min, bucket.max, bucket.value, true);
		_.merge(singleCommit, breadcrumbObj, granularityObj, bucketObj);
		logger.log('drillIntoBucket', singleCommit);
		setBothBookmarks({explorerData: singleCommit});
		setHasChanges(true);
	};

	// timeSeriesData = {columnData, range, period}
	const drillIntoTimeSeries = timeSeriesData => {
		const timeSeriesBreadcrumb = {
			id: 1,
			type: 'timeSeries',
			currValue: 1,
			options: [],
		};
		const singleCommit = {tableType: 'timeSeries'};
		const timeSeriesChanges = setTimeSeriesData(timeSeriesData, true);
		const breadcrumbChanges = setBreadcrumb(timeSeriesBreadcrumb, true);
		_.merge(singleCommit, timeSeriesChanges, breadcrumbChanges);
		logger.log('drillIntoTimeSeries', singleCommit);
		setBothBookmarks({explorerData: singleCommit});
		setHasChanges(true);
	};

	const saveBookmark = bookmark => {
		delete bookmark.explorerData.data;
		delete bookmark.explorerData.isLoading;
		delete bookmark.explorerData.error;
		return dataBookmarksApi
			.upsertBookmark(bookmark)
			.then(result => {
				// TODO useDispatch to show snackbar?
				// TODO should be applied
				dispatch(showSnackbar(`Saved view "${bookmark.name}" successfully`));
				setHasUnsavedChanges(false);
				return result;
			})
			.catch(err => {
				// TODO useDispatch to show snackbar?
				dispatch(showSnackbar(err.message));
			});
	};

	const copyBookmark = bookmark => {
		bookmark.datasetId = explorerState.datasetId;
		bookmark.createDate = new Date();
		bookmark.explorerData = Object.assign({}, explorerState);
		delete bookmark.explorerData.data;
		bookmark.isGadget = false;
		bookmark.tags = _.get(bookmark, 'tags', []).filter(tag => tag.length !== 0);

		return dataBookmarksApi
			.upsertBookmark(bookmark)
			.then(result => {
				// TODO useDispatch to show snackbar?
				dispatch(showSnackbar(`Saved view "${bookmark.name}" successfully`));
				setHasUnsavedChanges(false);
				return result;
			})
			.catch(err => {
				// TODO useDispatch to show snackbar?
				dispatch(showSnackbar(err.message));
			});
	};

	// -------------------
	// useEffect functions
	// -------------------
	useEffect(
		() => {
			// dateContextList is updated on dataset change, dateContextList needs to be related to the same dataset as bookmark
			if (
				!_.isEmpty(appliedBookmark) &&
				(dateContextList.length && dateContextList[0].datasetId === appliedBookmark.datasetId)
			) {
				// logger.log('useEffect [appliedBookmark]');
				// const toPrint = _.cloneDeep(appliedBookmark);
				// delete toPrint.explorerData.columns;
				// logger.log(JSON.stringify(toPrint, null, 2));
				fetchExplorerData();
			}
		},
		[appliedBookmark, dateContextList]
	);

	useEffect(
		() => {
			if (!_.isEmpty(renderState.data)) {
				// logger.log('useEffect [renderState.data]');
				// const toPrint = _.cloneDeep(appliedBookmark);
				// delete toPrint.explorerData.columns;
				//logger.log(JSON.stringify(renderState.data, null, 2));
			}
		},
		[renderState.data]
	);

	return (
		<DataExplorerContext.Provider
			value={{
				bookmark,
				appliedBookmark,
				calculatedDateInfo,
				renderState,

				setBookmark,
				setBookmarkAndMarkChanged,
				setAppliedBookmark: setBothBookmarks,
				setExplorerData,
				setExplorerDataAndFetch,
				hasChanges,
				hasUnsavedChanges,
				applyChanges,
				dateContextList,
				allColumns,
				setAllColumns,
				quickFilterLists,
				setQuickFilterLists,
				setDateContextList,
				originalViewName,
				setOriginalViewName,
				fetchQuickFiltersForBookmark,

				actions: {
					drillIntoTimeSeries,
					drillIntoBucket,
					setSortColumn,
					setStatementDate,
					setTableType,
					setDatasetId,
					setCohortColumn,
					setCohortData,
					setGroupBy,
					setBreadcrumb,
					loadBreadcrumbs,
					clearBreadcrumbs,
					updateBreadcrumb,
					deleteBreadcrumb,
					setBucket,
					setGranularity,
					setGranularityAndFetch,
					setTimeSeriesData,
					setSnapshotType,
					loadTimeSeries,
					setQuickFilterScenario,
					setQuickFilterScenarioType,
					setQuickFilterFundingVehicle,
					setQuickFilterPool,
					setQuickFilterHypoPool,
					setQuickFilterHypoFundingVehicle,
					setColumns,
					updateColumn,
					drillIntoAsset,
					loadBookmark,
					saveBookmark,
					copyBookmark,
					setHasUnsavedChanges,
				},
			}}
		>
			{children}
		</DataExplorerContext.Provider>
	);
};

DataExplorerProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

DataExplorerProvider.defaultProps = {};
