import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import CriteriaBlock from '../CriteriaBlock';
import SchemaBlock from '../SchemaBlock';
import SnapshotBlock from '../SnapshotBlock';
import ContextIcons from 'components/ContextSidebar/icons';
import KiFontIcon from 'components/KiFontIcon';
import KiIcons from 'components/KiIcons';
import eligibilityIcon from 'static/svgs/eligibility_analysis.svg';
import './DatasetListItem.scss';

export const DatasetListItem = props => {
	const {snapshots, datasetId} = props;
	const [criteriaEditVisible, setCriteriaEditVisible] = useState(false);
	const [schemaEditVisible, setSchemaEditVisible] = useState(false);
	const [snapshotBlockVisible, setSnapshotBlockVisible] = useState(false);

	return (
		<div className={`dataset-list-item ${criteriaEditVisible ? 'active' : ''}`}>
			<div className="dataset-wrapper">
				<div className="dataset-title">{props.name}</div>

				<div className="dataset-list-row-container">
					<div className="dataset-information">
						<h2 className="dataset-block-header">Information</h2>
						<hr className="light-grey-divider" />
						<p>Last Submission: {props.latestSubmission}</p>
					</div>

					<div className="dataset-explore">
						<h2 className="dataset-block-header">Explore</h2>
						<hr className="light-grey-divider" />

						<Link
							className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
							to={`/dataExplorer/${datasetId}`}
						>
							<KiFontIcon value="explore" />
							<p>Summary / Asset / Time Series</p>
						</Link>

						<Link
							className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
							data-automation="ds-list-link-eligibility-analysis"
							to={`/fundingAnalysis/${datasetId}`}
							onClick={props.handleEligibilityAnalysisClick}
						>
							<img alt="" src={eligibilityIcon} className="dataset-icon" />
							<p>Funding Analysis</p>
						</Link>

						<Link
							to={`/datasets/${datasetId}/debt`}
							className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
							data-automation="ds-list-link-debt"
						>
							<KiFontIcon value="trending_down" />
							<p>Debt</p>
						</Link>
						<span
							className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'} ${
								schemaEditVisible ? 'hover' : ''
							}`}
							data-automation="ds-list-link-view-schema"
							onClick={() => setSchemaEditVisible(!schemaEditVisible)}
						>
							<KiFontIcon value="device_hub" />
							<p>View Schema</p>
						</span>
						<Link
							className="icon-span"
							to={`/datasets/${datasetId}/logs`}
							data-automation="ds-list-link-logs"
						>
							<KiFontIcon value="reorder" />
							<p>Logs</p>
						</Link>
					</div>
					{!props.user.groups.find(u => u === 'SystemAdmins') ? (
						<div className="dataset-manage">
							<h2 className="dataset-block-header">Manage</h2>
							<hr className="light-grey-divider" />
							<div className="dataset-row">
								<div className="dataset-column" />
							</div>
						</div>
					) : (
						<div className="dataset-manage">
							<h2 className="dataset-block-header">Manage</h2>
							<hr className="light-grey-divider" />
							<div className="dataset-row">
								<div className="dataset-column">
									<span
										className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'} ${
											snapshotBlockVisible ? 'hover' : ''
										}`}
										onClick={() => setSnapshotBlockVisible(!snapshotBlockVisible)}
										data-automation="ds-list-link-delete-snapshot"
									>
										<KiFontIcon value="delete" />
										<p>Delete Snapshot</p>
									</span>
									<Link
										className="icon-span"
										to={`/datasets/${datasetId}/submission/upload`}
										data-automation="ds-list-link-submissions"
									>
										<KiFontIcon value="cloud_upload" />
										<p>Upload</p>
									</Link>
									<Link
										className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
										to={`/associatedDataList/${datasetId}`}
										data-automation="ds-list-link-associated-data"
									>
										<KiFontIcon value={<KiIcons.KiAssociatedData />} />
										<p>Associated Data</p>
									</Link>
									{window.WATERFALL_ENABLED === 'true' && (
										<Link
											className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
											to={`/dealStructures/${datasetId}`}
											data-automation="ds-list-link-deal-structures"
										>
											<KiFontIcon value="more" />
											<p>Deal Structures</p>
										</Link>
									)}
								</div>

								<div className="dataset-column">
									<Link
										className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
										to={`/poolMaintenance/${datasetId}`}
										data-automation="ds-list-link-pool-maintenance"
									>
										<KiFontIcon value="build" />
										<p>Pool Maintenance</p>
									</Link>

									<span
										className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'} ${
											criteriaEditVisible ? 'hover' : ''
										}`}
										data-automation="ds-list-link-global-exclusions"
										onClick={() => setCriteriaEditVisible(!criteriaEditVisible)}
									>
										<KiFontIcon
											value={<ContextIcons.FiltersIcon />}
											onClick={() => setCriteriaEditVisible(!criteriaEditVisible)}
										/>
										<p>Global Exclusions</p>
									</span>

									<Link
										className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
										to={`/debtInputMaintenance/${datasetId}`}
										data-automation="ds-list-link-debt-inputs"
									>
										<KiFontIcon value="keyboard" />
										<p>Debt Inputs</p>
									</Link>
									<Link
										className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
										to={`/mappings/${datasetId}`}
										data-automation="ds-list-link-mappings"
									>
										<KiFontIcon value="edit_location" />
										<p>Mappings</p>
									</Link>
									<Link
										className={`${snapshots.length ? 'icon-span' : 'icon-span-disabled'}`}
										to={`/datasets/${datasetId}/fundingVehicles`}
										data-automation="ds-list-link-funding-vehicles"
									>
										<KiFontIcon value="build" />
										<p>Funding Vehicles</p>
									</Link>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<CriteriaBlock
				datasetId={props.datasetId}
				isVisible={criteriaEditVisible}
				handleEditCriteriaClick={() => setCriteriaEditVisible(!criteriaEditVisible)}
			/>
			<SnapshotBlock
				datasetId={props.datasetId}
				snapshots={props.snapshots}
				name={props.name}
				isVisible={snapshotBlockVisible}
				handleEditSnapshotsClick={() => setSnapshotBlockVisible(!snapshotBlockVisible)}
			/>
			<SchemaBlock
				category={props.category}
				snapshots={props.snapshots}
				isVisible={schemaEditVisible}
				handleEditSchemaClick={() => setSchemaEditVisible(!schemaEditVisible)}
			/>
		</div>
	);
};

DatasetListItem.propTypes = {
	user: PropTypes.shape({
		groups: PropTypes.arrayOf(PropTypes.string),
	}),
	handleEligibilityAnalysisClick: PropTypes.func.isRequired,
	latestSubmission: PropTypes.any,
	datasetId: PropTypes.string,
	name: PropTypes.string,
	snapshots: PropTypes.array,
	category: PropTypes.string,
};
DatasetListItem.defaultProps = {
	snapshots: [],
};

export default DatasetListItem;
