import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiModal from 'components/KiModal';
import KiCheckbox from 'components/KiCheckbox';
import {deleteFundingVehicleSettings, updateFundingVehicleSetting} from '../actions';
import {connect} from 'react-redux';
import styles from './creditSupportsDeleteModal.theme.scss';
import _differenceBy from 'lodash/differenceBy';
import _countBy from 'lodash/countBy';
import {getNameId} from './feesForm';
import {fundingVehiclesApi} from 'api';
import DeletionDependencyErrors from './deletionDependencyErrors';

export class FeesDeleteModal extends Component {
	static propTypes = {
		active: PropTypes.bool,
		fees: PropTypes.array,
		deleteFundingVehicleSettings: PropTypes.func.isRequired,
		updateFundingVehicleSetting: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		title: PropTypes.string,
		nameKey: PropTypes.string,
	};

	static defaultProps = {
		active: false,
		fees: [],
	};

	state = {
		selected: [],
		dependencies: [],
	};

	handleCheckboxClick = fee => {
		this.setState(({selected}) => {
			const isPresent = selected.find(s => s._id === fee._id);
			return {
				selected: isPresent ? selected.filter(s => s._id !== fee._id) : [...selected, fee],
				dependencies: [],
			};
		});
	};

	handleDelete = () => {
		//check to see if there are dependencies on the items to be deleted
		if (this.state.selected.length > 0) {
			fundingVehiclesApi
				.getFundingVehicleSettingsDependencies('fee', {
					datasetId: this.state.selected[0].datasetId,
					fundingVehicleId: this.state.selected[0].fundingVehicleId,
					entityIds: this.state.selected.map(e => e._id),
				})
				.then(results => {
					if (
						results.dependencies.filter(d => Array.isArray(d.dependencies) && d.dependencies.length > 0)
							.length > 0
					) {
						this.setState({
							dependencies: results.dependencies.filter(
								d => Array.isArray(d.dependencies) && d.dependencies.length > 0
							),
						});
					} else {
						const nonDeletedFees = _differenceBy(this.props.fees, this.state.selected, '_id');
						const feeTypeCount = _countBy(nonDeletedFees, 'type');

						for (const [key] of Object.entries(feeTypeCount)) {
							feeTypeCount[key] = 0;
						}

						nonDeletedFees.forEach(fee => {
							feeTypeCount[fee.type]++;
							fee.feeNumber = feeTypeCount[fee.type];
							fee.nameId = getNameId(fee.type, fee.feeNumber);
							// TODO add ability to update many settings at once
							this.props.updateFundingVehicleSetting({_id: fee._id, ...fee}, true, true);
						});
						this.props.deleteFundingVehicleSettings(this.state.selected).then(() => this.props.onClose());
					}
				});
		}
	};

	handleCheckAll = () => {
		this.setState({
			selected: this.state.selected.length === this.props.fees.length ? [] : this.props.fees,
			dependencies: [],
		});
	};

	render() {
		const {fees} = this.props;
		return (
			<KiModal
				actions={[
					{
						label: 'Cancel',
						onClick: () => {
							this.setState({dependencies: []});
							this.props.onClose();
						},
					},
					{
						label: 'Delete',
						onClick: this.handleDelete,
						disabled: !this.state.selected.length,
					},
				]}
				active={!!this.props.fees.length && this.props.active}
				header={this.props.title || 'Delete Accounts'}
				className="ki-modal fv-modal"
			>
				<section className={styles.body}>
					{fees.map(fee => (
						<div key={fee._id} className={styles.checkboxWrapper}>
							<KiCheckbox
								className={styles.checkbox}
								label={fee[this.props.nameKey || 'name']}
								checked={!!this.state.selected.find(selected => selected._id === fee._id)}
								onChange={() => this.handleCheckboxClick(fee)}
							/>
						</div>
					))}
				</section>
				{this.state.dependencies.length > 0 && (
					<DeletionDependencyErrors dependencies={this.state.dependencies} />
				)}
			</KiModal>
		);
	}
}

export default connect(null, {deleteFundingVehicleSettings, updateFundingVehicleSetting})(FeesDeleteModal);
