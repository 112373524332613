import {dashboardsApi} from 'api';
import * as actionTypes from './actionTypes';
import {showSnackbar} from 'state/actions/Snackbar';

export const fetchDashboards = () => dispatch => {
	return dashboardsApi
		.fetchDashboards()
		.then(dashboards => {
			dispatch({type: actionTypes.DASHBOARDS_LOADED, payload: dashboards});
			return dashboards;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

export const fetchCardsForDashboard = ids => dispatch => {
	return dashboardsApi
		.fetchCardsForDashboard(ids)
		.then(cards => {
			dispatch({type: actionTypes.CURRENT_CARDS_LOADED, payload: cards});
			return cards;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

export const fetchAllCards = datasetId => dispatch => {
	return dashboardsApi
		.fetchAllCards({datasetId: datasetId})
		.then(cards => {
			dispatch({type: actionTypes.ALL_CARDS_LOADED, payload: cards});
			return cards;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

export const addDashboard = dashboard => dispatch => {
	return dashboardsApi
		.validateDashboardName(encodeURI(dashboard.name))
		.catch(err => {
			dispatch(showSnackbar(err.message));
		})
		.then(() => {
			dashboardsApi.addDashboard(dashboard);
		})
		.then(result => {
			dispatch(showSnackbar('Added dashboard successfully'));
			dispatch({type: actionTypes.DASHBOARD_ADDED, payload: result});
			dispatch(fetchDashboards());
			return result;
		});
};

export const updateDashboard = dashboard => dispatch => {
	return dashboardsApi
		.updateDashboard(dashboard)
		.then(result => {
			dispatch(showSnackbar(`Updated ${dashboard.name} successfully`));
			dispatch({type: actionTypes.DASHBOARD_UPDATED, payload: result});
			dispatch(fetchDashboards());
			return result;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

export const deleteDashboard = dashboardId => dispatch => {
	return dashboardsApi
		.deleteDashboard(dashboardId)
		.then(result => {
			dispatch(showSnackbar('Deleted dashboard successfully'));
			dispatch({type: actionTypes.DASHBOARD_DELETED, payload: dashboardId});
			dispatch(fetchDashboards());
			return result;
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

export const setCurrentCard = card => dispatch => {
	dispatch({type: actionTypes.CURRENT_CARD_UPDATED, payload: card});
};

export const deleteCard = (card = {}) => dispatch => {
	return dashboardsApi
		.deleteCard(card._id)
		.then(deleteResult => {
			dispatch({type: actionTypes.CARD_DELETED, _id: card._id});
			return deleteResult;
		})
		.then(deleteResult => {
			const cardLabel = card.name ? `card "${card.name}"` : 'card';
			dispatch(showSnackbar(`Deleted ${cardLabel} successfully.`));
			return deleteResult;
		})
		.catch(err => {
			//dispatch(showSnackbar(err.message));
			return err;
		});
};

export const addCard = (card = {}) => dispatch => {
	// TODO validate card name unique here?
	// return dashboardsApi.fetchAllCards({datasetId: card.datasetId}).then(cardList => {
	return dashboardsApi.fetchAllCards({datasetId: card.datasetId}).then(() => {
		return dashboardsApi
			.addCard(card)
			.then(result => {
				dispatch({type: actionTypes.CARD_ADDED, card: result});
				const cardLabel = card.name ? `card "${card.name}"` : 'card';
				dispatch(showSnackbar(`Created ${cardLabel} successfully.`));
				return result;
			})
			.catch(err => {
				dispatch(showSnackbar(err.message));
				return err;
			});
	});
};

export default {
	fetchDashboards,
	fetchCardsForDashboard,
	fetchAllCards,
	addDashboard,
	updateDashboard,
	setCurrentCard,
	deleteCard,
	addCard,
};
