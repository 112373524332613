const prePay = [
	{
		label: 'CPR',
		value: 'CPR',
	},
	{
		label: 'ABS',
		value: 'ABS',
	},
	{
		label: 'SMM',
		value: 'SMM',
	},
	{
		label: 'PCT',
		value: 'PCT',
	},
];

const defaultType = [
	{
		label: 'CDR',
		value: 'CDR',
	},
	{
		label: 'MDR',
		value: 'MDR',
	},
	{
		label: 'PCT',
		value: 'PCT',
	},
];

const callOptions = [
	{
		label: 'Maturity',
		value: 'Maturity',
	},
	{
		label: 'User Defined',
		value: 'User Defined',
	},
];

const indexList = [
	{
		label: 'US 1 Month LIBOR',
		value: 'US_1_Month_LIBOR',
	},
	{
		label: 'US 3 Month LIBOR',
		value: 'US_3_Month_LIBOR',
	},
	{
		label: '1 Month GBP LIBOR',
		value: '1 Month GBP LIBOR',
	},
	{
		label: '3 Month GBP LIBOR',
		value: '3 Month GBP LIBOR',
	},
];

const lossType = [
	{
		label: 'Loss %',
		value: 'Loss %',
	},
];

const methodList = [
	{
		label: 'Step Size',
		value: 'Step Size',
	},
];

const cashFlowOptions = [
	{
		label: 'Default',
		value: 'Default',
	},
	{
		label: 'Prepay',
		value: 'Prepay',
	},
	{
		label: 'Severity',
		value: 'Severity',
	},
];

const inputOptions = [
	{
		label: 'Price',
		value: 'Price',
	},
	{
		label: 'Yield / DM',
		value: 'Yield / DM',
	},
];

const rateOptions = [
	{
		label: 'Constant',
		value: 'constant',
	},
	{
		label: 'Vector',
		value: 'vector',
	},
];

const vectorTypeOptions = [
	{
		label: 'Rate',
		value: 'rate',
	},
	{
		label: 'Balance',
		value: 'balance',
	},
];

const notionalPoolSectionRateTypeOptions = [
	{
		label: 'Fixed',
		value: 'fixed',
	},
	{
		label: 'Floating',
		value: 'floating',
	},
];

const seasoningOptions = [
	{
		label: 'None',
		value: 'None',
	},
	{
		label: 'Asset',
		value: 'Asset',
	},
	{
		label: 'Deal',
		value: 'Deal',
	},
];

const delinquencyOptions = [
	{
		label: '%',
		value: '%',
	},
	{
		label: 'RAMP',
		value: 'ramp',
	},
	{
		label: 'Vector',
		value: 'vector',
	},
];

const delinquencyServicerAdvancesOptions = [
	{
		label: 'Based on Defaults',
		value: 'Based on Defaults',
	},
	{
		label: 'Based on Delinquencies',
		value: 'Based on Delinquencies',
	},
];

module.exports = {
	prePay,
	cashFlowOptions,
	methodList,
	lossType,
	indexList,
	callOptions,
	defaultType,
	inputOptions,
	rateOptions,
	vectorTypeOptions,
	notionalPoolSectionRateTypeOptions,
	seasoningOptions,
	delinquencyOptions,
	delinquencyServicerAdvancesOptions,
};
