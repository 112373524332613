const _ = require('lodash');
const _get = require('lodash/get');
const _omit = require('lodash/omit');
const {dateToShortDate} = require('./dateHelpers');
const moment = require('moment');
const {getSingleDate, getDates} = require('./asOfDateUtil');

// eslint-disable-next-line complexity
const determineStartAndEndDate = (
	dateContext,
	statementDate = new Date().toISOString().slice(0, 10),
	dateGroups = [],
	fundingVehicleId,
	settings = {},
	calendars = []
) => {
	let startDate = new Date().toISOString().slice(0, 10);
	let endDate = new Date().toISOString().slice(0, 10);

	if (settings.isCustomDateRange) {
		return {
			startDate: settings.start_date || startDate,
			endDate: settings.end_date || endDate,
		};
	}
	/*
		TODO: standardize system date references
		collectionEnd vs collectionPeriod vs collectionEndDate vs kiCollectionEndDate is massively confusing
	 */
	const dateConfig = dateGroups.find(
		date =>
			date.groupId === dateContext ||
			(date.mapName === 'report' && ['reportDate', 'kiReportDate'].includes(dateContext)) ||
			(date.mapName === 'collectionEnd' &&
				['kiCollectionEndDate', 'collectionEndDate', 'collectionPeriod'].includes(dateContext))
	);

	if (dateConfig) {
		try {
			const matchingEntry =
				dateConfig.entries.find(entry => entry.fundingVehicles.includes(fundingVehicleId)) ||
				dateConfig.entries.find(entry => entry.fundingVehicles.includes('default'));
			if (_.has(matchingEntry, 'calendar')) {
				const match = calendars.find(calendar => `${calendar._id}` === `${matchingEntry.calendar}`);
				if (match) matchingEntry.calendar = match;
			}
			const dayBeforeStatementDate = moment(statementDate, 'YYYY-MM-DD')
				.subtract(1, 'day')
				.format('YYYY-MM-DD');
			const rangeOccurrences = parseInt(_get(settings, 'quickFilters.rangeOccurrences', 1));
			const allOccurrences = rangeOccurrences === -1;
			const offset = -rangeOccurrences || -1;
			if (!matchingEntry || allOccurrences) {
				return {
					startDate: _get(matchingEntry, 'startDate', dayBeforeStatementDate),
					endDate: dayBeforeStatementDate,
				};
			}
			switch (matchingEntry.dateType) {
				case 'static': {
					return {
						startDate: matchingEntry.startDate,
						endDate: matchingEntry.startDate,
					};
				}
				case 'recurring': {
					const allDateResults = _.initial(getDates(matchingEntry) || []);
					const occurrencesExceedAvailableResults = -offset > allDateResults.length;
					return {
						startDate: occurrencesExceedAvailableResults ? _.first(allDateResults) : _.nth(allDateResults, offset),
						endDate: _.last(allDateResults),
					};
				}
				case 'range': {
					const endOfPreviousPeriod = getSingleDate(
						Object.assign({}, matchingEntry, {offset: offset + 1}),
						statementDate
					);
					const adjustedDateEntry = Object.assign({}, matchingEntry, {
						offset: endOfPreviousPeriod >= statementDate ? -rangeOccurrences - 1 : -rangeOccurrences,
					});
					return {
						startDate: getSingleDate(adjustedDateEntry, statementDate, null, 1) || matchingEntry.startDate,
						endDate: getSingleDate(matchingEntry, statementDate) || statementDate,
					};
				}
				default:
					return {
						startDate: dayBeforeStatementDate,
						endDate: dayBeforeStatementDate,
					};
			}
		} catch (err) {
			startDate = statementDate;
			endDate = startDate;
		}
	}
	return {
		startDate: startDate || new Date().toISOString().slice(0, 10),
		endDate: endDate || new Date().toISOString().slice(0, 10),
	};
};

const buildRequestFromView = (
	view,
	hydratedDates = [],
	dataRows = null,
	dataColumns = null,
	updatedActivityDates = null,
	calendars = []
) => {
	const {viewColumns, settings} = view;
	const requestBody = {
		tableType: settings.cohortColumn === 'debt' && settings.fundingVehicle ? 'debt' : 'cohort',
		dateContext: settings.dateContext || 'reportDate',
		assetDateContext: settings.assetDateContext || 'collectionEndDate',
		debtDateContext: settings.debtDateContext || 'reportDate',
		snapshotDate: settings.snapshotDate,
		datasetId: settings.datasetId,
		cohortColumn: settings.cohortColumn,
		viewType: settings.viewType,
		fundingVehicleId: _get(settings, 'fundingVehicle._id', settings.fundingVehicle),
		sortOrder: settings.sortOrder || 'asc',
		sortColumn: settings.sortColumn || '',
		sortCalculation: settings.sortCalculation || '',
		columns:
			settings.cohortColumn === 'pool' && settings.viewType === 'transaction'
				? viewColumns['poolActivity']
				: settings.viewType === 'waterfall'
				? viewColumns['waterfall']
				: viewColumns[settings.cohortColumn],
		// TODO: determine if activityStartDate and activityEndDate can be removed now
		activityStartDate: settings.activityEndDate,
		activityEndDate: settings.activityEndDate,
		start_date: settings.start_date,
		end_date: settings.end_date,
		quickFilters: {
			scenarioType: _get(settings, 'quickFilters.scenarioType') || 'lastApproved',
			scenarioId: _get(settings, 'quickFilters.scenarioId', null),
			hypoPoolId: _get(settings, 'quickFilters.hypoPoolId', null),
			fundingVehicleId:
				_get(settings, 'quickFilters.fundingVehicleId') ||
				_get(settings, 'fundingVehicle._id', settings.fundingVehicle),
			poolId: _get(settings, 'quickFilters.poolId', null),
			rangeType: _get(settings, 'quickFilters.rangeType', 'static'),
			rangeDate: _get(settings, 'quickFilters.rangeDate', null),
			rangeOccurrences: parseInt(_get(settings, 'quickFilters.rangeOccurrences')) || 1,
		},
	};

	if (settings.viewType === 'waterfall') {
		requestBody.columns.forEach(col => {
			col._id = `${col.columnId}`;
		});
	} else {
		requestBody.columns.forEach(col => {
			if (!col._id) {
				col._id = `${col.columnType}__${col.columnName}`;
			}
		});
	}

	if (dataRows) {
		requestBody.columns = dataColumns;
		requestBody.rows = dataRows;
		requestBody.updatedActivityDates = updatedActivityDates;
	}

	if (hydratedDates.length) {
		const {startDate, endDate} = determineStartAndEndDate(
			_get(requestBody, 'quickFilters.rangeDate', requestBody.debtDateContext),
			requestBody.snapshotDate,
			hydratedDates,
			_get(requestBody, 'quickFilters.fundingVehicleId', requestBody.fundingVehicleId),
			settings,
			calendars
		);
		requestBody.start_date = startDate;
		requestBody.end_date = endDate;
	}

	return settings.cohortColumn === 'pool' && settings.viewType === 'transaction'
		? _omit(requestBody, ['start_date', 'end_date'])
		: _omit(requestBody, ['activityStartDate', 'activityEndDate']);
};

const buildRequestFromViewAndCard = (view, card, dateGroups = [], statementDate, calendars = []) => {
	const requestBody = buildRequestFromView(view, dateGroups, null, null, null, calendars);
	// override start end dates based on card settings
	/*
		activity views only use debt data as of writing this (2019-12-18)
		so we can just pass debtDateContext into the determination method
	 */

	const {startDate, endDate} = determineStartAndEndDate(
		_get(view, 'settings.quickFilters.rangeDate', view.settings.debtDateContext || view.settings.dateContext),
		statementDate || dateToShortDate(new Date()),
		dateGroups,
		_get(view.settings, 'quickFilters.fundingVehicleId', view.settings.fundingVehicleId),
		view.settings,
		calendars
	);

	// As per BLD-12252 this should only use the current date, unless being passed through a report
	requestBody.snapshotDate = statementDate || dateToShortDate(new Date());
	requestBody.dateContext = view.settings.dateContext;
	// leave the fvId if it's a debt activity view and the card doesn't have an fvId to override with
	if (requestBody.viewType !== 'activity' || card.settings.fundingVehicleId) {
		requestBody.quickFilters.fundingVehicleId = card.settings.fundingVehicleId;
	}
	requestBody.quickFilters.scenarioType = card.settings.scenarioType || 'assetSnapshot';
	requestBody.quickFilters.scenarioId = card.settings.scenarioId;
	requestBody.start_date = startDate;
	requestBody.end_date = endDate;

	return requestBody;
};

module.exports = {
	buildRequestFromView,
	buildRequestFromViewAndCard,
	determineStartAndEndDate,
};
