import React from 'react';
import KiButton from '../../../components/KiButton';
import PropTypes from 'prop-types';
import './LedgerSubmitHeader.scss';

function LedgerSubmitHeader({children, actions}) {
	return (
		<div className="ledger-submit-header">
			{children}
			<div>
				{actions.map(({isFlat = false, label, onClick, disabled = false}, i) => {
					return (
						<KiButton disabled={disabled} flat={isFlat} primary onClick={onClick && onClick} key={i}>
							{label && label}
						</KiButton>
					);
				})}
			</div>
		</div>
	);
}

LedgerSubmitHeader.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.object),
	children: PropTypes.node,
};

LedgerSubmitHeader.defaultProps = {
	actions: [],
};

export default LedgerSubmitHeader;
