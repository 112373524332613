import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import styles from './auditList.theme.scss';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';
import {auditApi} from 'api';
import moment from 'moment';
import {actionTypes} from 'ki-common/options/audit';
import DiffList from './components/DiffList';
import {transformDiff} from 'ki-common/utils/diffHelpers';
import Select from 'react-select';
import classNames from 'classnames';

export class Audit extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
	};

	state = {
		auditList: [],
		fromDate: moment()
			.subtract(7, 'days')
			.format('YYYY-MM-DD'),
		toDate: null,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Audit`;
		auditApi
			.fetchAuditListByDateRange(this.getDate(this.state.fromDate), this.getDate(this.state.toDate))
			.then(res => {
				this.setState({auditList: res});
			});
	}

	onFromDateChange(option) {
		this.setState({fromDate: option.value});
		auditApi.fetchAuditListByDateRange(this.getDate(option.value), this.getDate(this.state.toDate)).then(res => {
			this.setState({auditList: res});
		});
	}

	getDate = date => {
		if (!date) {
			return undefined;
		}
		return new Date(date);
	};

	datePickerOptions = [
		{
			value: moment()
				.subtract(7, 'days')
				.format('YYYY-MM-DD'),
			label: 'Last 7 Days',
		},
		{
			value: moment()
				.subtract(30, 'days')
				.format('YYYY-MM-DD'),
			label: 'Last 30 Days',
		},
		{
			value: moment()
				.subtract(3, 'months')
				.format('YYYY-MM-DD'),
			label: 'Last 3 Months',
		},
		{
			value: moment()
				.subtract(6, 'months')
				.format('YYYY-MM-DD'),
			label: 'Last 6 Months',
		},
		{
			value: moment()
				.subtract(12, 'months')
				.format('YYYY-MM-DD'),
			label: 'Last 12 Months',
		},
	];

	getActionName = action => {
		const match = actionTypes.find(o => o.value === action);
		if (match && match.label) {
			return match.label;
		}
		return action;
	};

	transformRows = () => {
		return this.state.auditList.map(r => ({
			timestamp: moment(r.timestamp).format('YYYY-MM-DD HH:mm:ss'),
			userid: r.userid || '-',
			entity: r.entity,
			entityName: r.entityName,
			action: this.getActionName(r.action),
			changes: <DiffList list={transformDiff(r.action, r.diff)} />,
		}));
	};

	transformColumns = () => {
		return [
			{
				Header: 'Change Date / Time',
				accessor: 'timestamp',
				width: 120,
			},
			{
				Header: 'User',
				accessor: 'userid',
			},
			{
				Header: 'Entity',
				accessor: 'entity',
			},
			{
				Header: 'Name',
				accessor: 'entityName',
			},
			{
				Header: 'Action',
				accessor: 'action',
			},
			{
				Header: 'Changes',
				accessor: 'changes',
				minWidth: 350,
				className: 'audit-diff-column',
			},
		];
	};

	render() {
		return (
			<section className={styles.auditList}>
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1>{`Audit`}</h1>
					</div>
				</KiAppBar>
				<div className={classNames('ki-panel', styles.auditPanel)}>
					<div className={styles.fromDatePicker}>
						<Select
							classNamePrefix="aut-select"
							isClearable={false}
							isSearchable={false}
							options={this.datePickerOptions}
							value={
								this.datePickerOptions.find(opt => opt.value === this.state.fromDate) ||
								this.datePickerOptions[0]
							}
							onChange={option => this.onFromDateChange(option)}
						/>
					</div>
					<AbsTable
						isPaginationEnabled={true}
						isSortEnabled={true}
						isFilterEnabled={true}
						data={this.transformRows()}
						columns={this.transformColumns()}
						paginationConfig={{defaultPageSize: 100}}
						isHeaderSticky={true}
					/>
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
});

export default connect(
	mapStateToProps,
	{}
)(Audit);
