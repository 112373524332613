// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';

// Externals
import KiDatePicker from 'components/KiDatePicker';
import dateHelpers from 'ki-common/utils/dateHelpers';

// Relative
import {updateValue} from './actions';
import './ViewForms.scss';

export class CohortBandsDate extends Component {
	static propTypes = {
		granularity: PropTypes.string,
		dateRange: PropTypes.object,
		setFormHasChanges: PropTypes.func,
		updateValue: PropTypes.func.isRequired,
	};

	static defaultProps = {
		granularity: '',
		dateRange: {
			start: '',
			end: '',
		},
	};

	handleStartDateChange = value => {
		this.props.updateValue({name: 'dateRangeStart', value: value});
		this.props.setFormHasChanges();
	};

	handleEndDateChange = value => {
		this.props.updateValue({name: 'dateRangeEnd', value: value});
		this.props.setFormHasChanges();
	};

	handleSetGranularity = option => {
		this.props.updateValue({name: 'granularity', value: option.value});
		this.props.setFormHasChanges();
	};

	render() {
		const granularityOptions = [
			{
				label: 'daily',
				value: 'daily',
			},
			{
				label: 'weekly',
				value: 'weekly',
			},
			{
				label: 'weeklyFixed',
				value: 'weeklyFixed',
			},
			{
				label: 'biWeekly',
				value: 'biWeekly',
			},
			{
				label: 'biWeeklyFixed',
				value: 'biWeeklyFixed',
			},
			{
				label: 'fortnightly',
				value: 'fortnightly',
			},
			{
				label: 'monthly',
				value: 'monthly',
			},
			{
				label: 'quarterly',
				value: 'quarterly',
			},
			{
				label: 'semiAnnually',
				value: 'semiAnnually',
			},
			{
				label: 'annually',
				value: 'annually',
			},
		];

		return (
			<div className="cohort-bands">
				<span className="form-instruction">Granularity Type</span>
				<Select
					classNamePrefix="aut-select"
					isClearable={false}
					options={granularityOptions}
					value={granularityOptions.find(opt => opt.value === this.props.granularity)}
					onChange={this.handleSetGranularity}
				/>

				<div className="group-inputs">
					<KiDatePicker
						label="Start Date"
						onChange={val => this.handleStartDateChange(dateHelpers.dateToShortDate(val))}
						value={this.props.dateRange.start}
						popperContainer={null}
					/>

					<KiDatePicker
						label="End Date"
						onChange={val => this.handleEndDateChange(dateHelpers.dateToShortDate(val))}
						value={this.props.dateRange.end}
						popperContainer={null}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	granularity: state.viewForm.granularity,
	dateRange: state.viewForm.dateRange,
});

const mapDispatchToProps = () => ({
	updateValue,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(CohortBandsDate);
