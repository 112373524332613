import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiModal from 'components/KiModal';
import KiDataCardChart from 'components/KiDataCardChart';
import TriggerFunctionSelector from 'components/TriggerFunctionSelector';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import './DialogChart.scss';

export class DialogChart extends Component {
	static propTypes = {
		active: PropTypes.bool,
		closeDialogFunc: PropTypes.func,
		chartData: PropTypes.object,
		supportedChartTypes: PropTypes.array,
	};

	state = {
		chartType: 'column',
	};

	componentDidMount() {
		return true;
	}

	componentDidUpdate(prevProps) {
		if (!_isEqual(prevProps, this.props)) {
			const chartType = _get(this.props, 'chartData.chartType');
			if (chartType) {
				this.setState({chartType});
			}
		}
	}

	setChartType = newChartType => {
		this.setState({
			chartType: newChartType,
		});
	};

	render() {
		if (!this.props.chartData || !this.props.active) {
			return null;
		}

		const dataCardChartConfig = {
			settings: {
				type: 'chart',
			},
			chartType: this.state.chartType,
			categories: this.props.chartData.categories,
			series: this.props.chartData.series,
			chartTitle: this.props.chartData.chartTitle,
			xTitle: this.props.chartData.xTitle,
			yTitle: this.props.chartData.yTitle,
			legend: this.props.chartData.legend,
			height: '50%',
		};

		return (
			<KiModal
				actions={[{label: 'Close', onClick: () => this.props.closeDialogFunc()}]}
				active={this.props.active || false}
				className="ki-modal dialog-chart-container"
				onClose={this.props.closeDialogFunc}
			>
				<div className="dialog-chart">
					<div className="visual-options">
						<TriggerFunctionSelector
							options={this.props.supportedChartTypes}
							className={'dialog-chart-type-selector'}
							targetFunction={this.setChartType}
							startingValue={this.state.chartType}
							sortOrder={'asc'}
						/>
					</div>
					<KiDataCardChart {...dataCardChartConfig} />
				</div>
			</KiModal>
		);
	}
}

export default DialogChart;
