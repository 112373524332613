const dateStringRegex = '^\\d{4}-\\d{2}-\\d{2}$';
const shortDateStringRegex = '^\\d{4}-\\d{2}$';

const moment = require('moment');

const shortDateToDate = dateString => {
	if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
		const split = dateString.split('-');
		return new Date(Number(split[0]), Number(split[1] - 1), Number(split[2]));
	} else {
		throw 'Expected date to be in format of YYYY-MM-DD';
	}
};

const dateToShortDate = date => {
	if (!date) return '';
	const pad = number => (number <= 9 ? `0${number}`.slice(-2) : number.toString());
	if (date instanceof Date) {
		return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
	} else {
		throw 'Expected date to be a javascript date';
	}
};

const convertPossibleDateStringToExpectedFormat = (input = '') => {
	/*
		This is a band-aid for converting some weird formats (MM/YYYY and MM/DD/YYYY) requested for user input, to what solver needs as "YYYY-MM-DD"
	 */
	if (typeof input !== 'string') {
		// if it's not a string just send it back before throwing exceptions.
		return input;
	}
	const uglyShortDate = input.match(/^(\d{1,2})\/(\d{4})$/);
	const uglyLongDate = input.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
	if (uglyShortDate) {
		return moment(input, ['MM/YYYY', 'M/YYYY']).format('YYYY-MM-DD');
	}
	if (uglyLongDate) {
		return moment(input, ['MM/DD/YYYY', 'M/DD/YYYY', 'MM/D/YYYY', 'M/D/YYYY']).format('YYYY-MM-DD');
	}
	return input;
};

const millisecondsToHumanReadable = milliseconds => {
	const delim = ':';
	const sign = milliseconds < 0 ? '-' : '';
	const pad0 = val => (val < 10 ? `0${val}` : val);
	const hours = Math.floor((Math.abs(milliseconds) / (1000 * 60 * 60)) % 60);
	const minutes = Math.floor((Math.abs(milliseconds) / (1000 * 60)) % 60);
	const seconds = Math.floor((Math.abs(milliseconds) / 1000) % 60);
	return `${sign}${pad0(hours)}${delim}${pad0(minutes)}${delim}${pad0(seconds)}`;
};

module.exports = {
	dateStringRegex,
	shortDateStringRegex,
	shortDateToDate,
	dateToShortDate,
	convertPossibleDateStringToExpectedFormat,
	millisecondsToHumanReadable,
};
