import * as actionTypes from './actionTypes';

const initialState = {
	isLoading: false,
	data: [],
};

function NotificationList(state = initialState, action) {
	switch (action.type) {
		case actionTypes.NOTIFICATIONS_FETCH_CURRENT_USER:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					// return {...state, isLoading: false, data: action.notifications};
					return {...state, isLoading: false, data: []}; // TODO all notifications are currently unassigned pools and thus invalid
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		default:
			return state;
	}
}

export default NotificationList;
