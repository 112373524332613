import * as actionTypes from './actionTypes';

export const SCENARIO_DISPLAY_MODES = {
	LIST: 'LIST',
	DETAIL: 'DETAIL',
	RUN_REVIEW: 'RUN_REVIEW',
};

const initialState = {
	isLoading: false,
	projectionScenarios: [],
	scenarioDisplayMode: SCENARIO_DISPLAY_MODES.LIST,
};

export const Forecasting = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FORECASTING_FETCH_SCENARIOS:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, projectionScenarios: action.data};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.FORECASTING_ADD_PROJECTION_SCENARIO:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						projectionScenarios: [...state.projectionScenarios, action.data],
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.FORECASTING_UPDATE_PROJECTION_SCENARIO:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						projectionScenarios: state.projectionScenarios.reduce((acc, projectionScenario) => {
							if (projectionScenario._id === action.data._id) {
								return [...acc, action.data];
							}
							return [...acc, projectionScenario];
						}, []),
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.FORECASTING_DELETE_PROJECTION_SCENARIO:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						projectionScenarios: state.projectionScenarios.filter(x => x._id !== action.data),
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}
		case actionTypes.FORECASTING_SCENARIO_DISPLAY_MODE:
			return {
				...state,
				scenarioDisplayMode: action.mode,
			};
		default:
			return state;
	}
};

export default Forecasting;
