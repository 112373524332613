import React from 'react';
import PropTypes from 'prop-types';

const NewItemSection = ({label, onClick}) => {
	return (
		<div className="cta-icon-btn" onClick={onClick}>
			<i className="material-icons">add_circle</i>
			<p>New {label}</p>
		</div>
	);
};

NewItemSection.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default NewItemSection;
