const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
const SET_ACCOUNT_ORIGINAL = 'SET_ACCOUNT_ORIGINAL';
const ACCOUNT_UNDO = 'ACCOUNT_UNDO';
const ACCOUNT_UNDO_PROPERTY = 'ACCOUNT_UNDO_PROPERTY';
const ACCOUNT_REMOVE = 'ACCOUNT_REMOVE';
const ACCOUNT_ADD = 'ACCOUNT_ADD';
const ACCOUNT_CHANGE = 'ACCOUNT_CHANGE';
const SET_ACCOUNT_DATA_FROM_ORIGINAL = 'SET_ACCOUNT_DATA_FROM_ORIGINAL';

export {
	SET_ACCOUNT_DATA,
	SET_ACCOUNT_ORIGINAL,
	ACCOUNT_UNDO,
	ACCOUNT_UNDO_PROPERTY,
	ACCOUNT_REMOVE,
	ACCOUNT_ADD,
	ACCOUNT_CHANGE,
	SET_ACCOUNT_DATA_FROM_ORIGINAL,
};
