import React from 'react';
import PropTypes from 'prop-types';

import options from 'ki-common/options';

import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import styles from './ColumnSelection.theme.scss';

const DATE_LONG_OPTIONS = [
	{
		label: 'YYYY-MM-DD',
		value: 'yyyy-MM-dd',
	},
	{
		label: 'DD MMM YYYY',
		value: 'DD MMM YYYY',
	},
];

const DATE_SHORT_OPTIONS = [
	{
		label: 'YYYY-MM',
		value: 'yyyy-MM',
	},
];

class ColumnSelectionItem extends React.Component {
	static propTypes = {
		columnName: PropTypes.string,
		displayName: PropTypes.string,
		handleDelete: PropTypes.func,
		calculation: PropTypes.string,
		dataType: PropTypes.string,
		displayFormat: PropTypes.string,
		handleChange: PropTypes.func,
		index: PropTypes.number,
		isLast: PropTypes.bool,
		moveDown: PropTypes.func,
		moveUp: PropTypes.func,
		aggregateLevel: PropTypes.string,
	};

	getFormatOptions = dataType => {
		switch (dataType) {
			case 'numeric':
				return options.numericFormats;
			case 'date':
			case 'date_long':
				return DATE_LONG_OPTIONS;
			case 'date_short':
				return DATE_SHORT_OPTIONS;
			case 'string':
				return [{value: 'string', label: 'Text'}];
			default:
				return [];
		}
	};

	getDataTypeLabel = (dataType, aggregateLevel) => {
		// Better, but doesn't match with data exploration, so leaving commented out.
		// const calcMatch = calcOptions.find(calcOpt => calcOpt.value === dataType);
		// if (calcMatch) {
		// 	return calcMatch.label;
		// }
		let label = '';
		if (aggregateLevel) {
			label += `${aggregateLevel}.`;
		}
		switch (dataType) {
			case 'shortDate':
			case 'date_short':
				label += 'Short Date';
				break;
			case 'longDate':
			case 'date_long':
				label += 'Long Date';
				break;
			default:
				label += (dataType || '').replace('_', ' ');
		}
		return label;
	};

	render() {
		return (
			<form name={'columnSelectionItem'} className={styles.columnListItem}>
				<header className={styles.columnListItemHeader}>
					<div className={styles.itemTitle} title={this.props.columnName}>
						{this.props.columnName}
					</div>
					{this.props.index !== 0 && (
						<div className={styles.itemActions}>
							{!this.props.isLast && (
								<i title={'Move Down'} className={'material-icons'} onClick={this.props.moveDown}>
									keyboard_arrow_down
								</i>
							)}
							{this.props.index > 1 && (
								<i title={'Move Up'} className={'material-icons'} onClick={this.props.moveUp}>
									keyboard_arrow_up
								</i>
							)}
							<i title={'Remove'} className={'material-icons'} onClick={this.props.handleDelete}>
								clear
							</i>
						</div>
					)}
				</header>
				<section className={styles.columnListItemBody}>
					<div className={styles.dataType}>
						{this.getDataTypeLabel(
							this.props.calculation || this.props.dataType,
							this.props.aggregateLevel
						)}
					</div>
					<br />
					<div className={'select-wrapper'}>
						<label className={'theme-label'}>Format:</label>
						<KiSelect
							menuPortalTarget={null}
							menuPosition={'absolute'}
							getOptionValue={option => option.label}
							options={this.getFormatOptions(this.props.dataType)}
							value={this.getFormatOptions(this.props.dataType).find(
								c =>
									!!this.props.displayFormat &&
									c.value.toLowerCase() === this.props.displayFormat.toLowerCase()
							)}
							onChange={val => this.props.handleChange('displayFormat', val.label)}
						/>
					</div>
					<br />
					<KiInput
						label={'Display Name'}
						value={this.props.displayName}
						onChange={val => this.props.handleChange('displayName', val)}
					/>
				</section>
			</form>
		);
	}
}

export default ColumnSelectionItem;
