import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './KiTab.theme.scss';

export class KiTab extends Component {
	static propTypes = {
		children: PropTypes.node,
		active: PropTypes.bool,
		style: PropTypes.object,
	};

	render() {
		return (
			<div className={`${styles.wrapper} ${this.props.active ? styles.active : ''}`} style={this.props.style}>
				{this.props.children}
			</div>
		);
	}
}
export default KiTab;
