import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import styles from './FilterDetail.theme.scss';
import LogicDropdown from '../../DatasetFilter/LogicDropdown';
import TargetCell from '../../DatasetFilter/TargetCell';
import CreatableSelect from 'react-select/creatable';
import Select from 'components/KiSelect';
import options from 'ki-common/options';
import KiCheckbox from 'components/KiCheckbox';
import _ from 'lodash';

export class FilterDetail extends Component {
	static propTypes = {
		active: PropTypes.bool,
		assetColumns: PropTypes.array,
		filter: PropTypes.object,
		onCreateFilter: PropTypes.func,
		onUpdateFilter: PropTypes.func,
		onModeChange: PropTypes.func,
		datasetId: PropTypes.string,
		existingTags: PropTypes.array,
		user: PropTypes.object,
		isAdmin: PropTypes.bool,
		mode: PropTypes.string,
	};

	static defaultProps = {
		existingTags: [],
		assetColumns: [],
		isAdmin: false,
	};

	constructor(props) {
		super(props);
		if (props.filter) {
			this.state = {
				name: props.filter.name,
				tags: props.filter.tags,
				logic: props.filter.logic,
				target: props.filter.target,
				dataColumn: props.assetColumns.find(c => c._id === props.filter.dataColumn),
				isGlobal: false,
			};
		}
	}

	state = {
		name: '',
		tags: [],
		target: [],
		logic: '=',
		selection: '',
		dataColumn: {},
		targetError: false,
		isGlobal: false,
	};

	componentDidUpdate(prevProps) {
		const {filter, assetColumns} = this.props;
		if (_.isEqual(prevProps, this.props)) {
			return;
		}
		if (!filter) {
			this.setState({
				name: '',
				tags: [],
				target: [],
				logic: '=',
				selection: '',
				dataColumn: undefined,
				isGlobal: false,
			});
		} else {
			this.setState({
				name: filter.name,
				tags: filter.tags,
				logic: filter.logic,
				target: filter.target,
				dataColumn: assetColumns.find(c => c._id === filter.dataColumn),
				isGlobal: false,
			});
		}
	}

	isSaveEnabled = () => {
		if (!this.state.name) return false;
		if (!this.state.logic) return false;
		if (!this.state.target) return false;
		if (Array.isArray(this.state.target)) {
			if (!this.state.target.length) return false;
		}
		if (!this.state.dataColumn) return false;
		return true;
	};

	handleSave = event => {
		event.preventDefault();
		// Prevent save if target field has error
		if (this.state.targetError || !this.isSaveEnabled()) {
			return false;
		}
		if (this.props.filter && this.props.filter._id) {
			this.props.onUpdateFilter({
				_id: this.props.filter._id,
				name: this.state.name,
				tags: this.state.tags,
				logic: this.state.logic,
				target: this.state.target,
				dataColumn: this.state.dataColumn ? this.state.dataColumn._id : undefined,
				userId: this.props.filter.userId,
			});
		} else {
			this.props.onCreateFilter({
				name: this.state.name,
				tags: this.state.tags,
				logic: this.state.logic,
				target: this.state.target,
				dataColumn: this.state.dataColumn ? this.state.dataColumn._id : undefined,
				datasetId: this.props.datasetId,
				userId: this.state.isGlobal ? '' : this.props.user.userId,
			});
		}
	};

	handleDataColumnChange = val => {
		this.setState({
			dataColumn: val,
			logic: '=',
			target: '',
		});
	};

	handleLogicChange = val => {
		this.setState({
			logic: val,
			target: ['is_null', 'is_not_null'].includes(val) ? [] : '',
		});
	};

	render() {
		const {assetColumns = []} = this.props;

		if (!this.props.active) {
			return null;
		}
		return (
			<form className="context-sidebar-form" onSubmit={this.handleSave}>
				<section className={styles.createFilterBody}>
					<KiInput
						required
						className={styles.inputRow}
						type="text"
						pattern="^\S.*"
						placeholder="Name"
						value={this.state.name}
						onChange={val => this.setState({name: val})}
					/>
					<div className={styles.selectHeader}>Tags</div>
					<CreatableSelect
						isMulti={true}
						options={this.props.existingTags.map(t => ({value: t, label: t}))}
						value={this.state.tags.map(t => ({value: t, label: t}))}
						onChange={val => this.setState({tags: val.map(t => t.value)})}
						placeholder="Add some tags"
						noResultsText={false}
					/>
					<div className={styles.selectHeader}>Data Column</div>
					<Select
						required
						classNamePrefix="aut-select"
						options={assetColumns}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						value={this.state.dataColumn}
						isClearable={false}
						onChange={this.handleDataColumnChange}
					/>
					<LogicDropdown
						required
						options={options.logic}
						value={this.state.logic}
						onChange={this.handleLogicChange}
						dataColumn={this.state.dataColumn}
					/>
					<TargetCell
						required
						onChange={val => this.setState({target: val})}
						value={this.state.target}
						dataColumn={this.state.dataColumn}
						logic={this.state.logic}
						onError={err => this.setState({targetError: err ? err : false})}
					/>
					{this.props.mode === 'create' && this.props.isAdmin && (
						<KiCheckbox
							name="isGlobal"
							checked={this.state.isGlobal}
							label="Make Global"
							onChange={val => this.setState({isGlobal: val})}
						/>
					)}
				</section>
				<section className="inline-column-form-buttons">
					<KiButton flat primary onClick={() => this.props.onModeChange('view')} label="Cancel" />
					<KiButton raised primary type="submit" label="Save" disabled={!this.isSaveEnabled()} />
				</section>
			</form>
		);
	}
}

export default FilterDetail;
