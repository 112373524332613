import PropTypes from 'prop-types';
import React, {Component} from 'react';
import chartConfigHelper from './chartConfigHelper';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);

export class KiDataCardChart extends Component {
	static propTypes = {
		chartType: PropTypes.string,
		categories: PropTypes.array,
		series: PropTypes.array,
		chartTitle: PropTypes.string,
		xTitle: PropTypes.string,
		yTitle: PropTypes.string,
		legend: PropTypes.object,
		height: PropTypes.string,
		loading: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.highchart = React.createRef();
	}

	componentDidUpdate() {
		if (this.props.loading && this.highchart.current) {
			this.highchart.current.chart.showLoading();
		} else if (this.highchart.current) {
			this.highchart.current.chart.hideLoading();
		}
	}

	render() {
		const chartConfig = chartConfigHelper.getChartConfig(
			this.props.chartType,
			this.props.categories,
			this.props.series,
			this.props.chartTitle,
			this.props.xTitle,
			this.props.yTitle,
			this.props.legend,
			this.props.height
		);
		return <HighchartsReact highcharts={Highcharts} options={chartConfig} ref={this.highchart} />;
	}
}

export default KiDataCardChart;
