import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiIconButton from 'components/KiIconButton';
import Select from 'components/KiSelect';
import options from 'ki-common/options';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import MiniCreator from '../../MiniCreator';
import KiDatePicker from 'components/KiDatePicker';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import KiInput from 'components/KiInput';

// if(x < y) then
// x & y are terms
// a term can be a constant or a column

export class ColConstFuncPicker extends Component {
	static propTypes = {
		columns: PropTypes.array.isRequired,
		schemaColumns: PropTypes.array,
		onChange: PropTypes.func.isRequired,
		type: PropTypes.object,
		value: PropTypes.any,
		outputDataType: PropTypes.string, // If set this is an output term and should be limited
		hideCancelBtn: PropTypes.bool,
		dataType: PropTypes.string, // If set then this is an input term and should be limited
		log: PropTypes.string,
		reloadView: PropTypes.func,
		hideNewColumnOption: PropTypes.bool,
	};

	static defaultProps = {
		type: null,
		value: null,
		outputDataType: null,
		dataType: null,
		schemaColumns: [],
	};

	state = {
		type: this.props.type,
		value: this.props.value,
		func: null,
		funcColumns: [],
		showMiniCreator: false,
		conditionType: null,
		log: `${this.props.log}::${new Date().getTime()}`,
	};

	componentDidUpdate(prevProps) {
		if (!_isEqual([prevProps.value, prevProps.type], [this.props.value, this.props.type])) {
			const {type, value} = this.props;
			const stateUpdate = {
				type,
				value,
			};

			// Backwards compatibility
			if (_get(type, 'value', null) === 'constant') {
				stateUpdate.type = {
					label: 'Text Constant',
					value: 'string',
				};
			}

			// If the value exists try to figure out the condition type
			if (value) {
				stateUpdate.conditionType = options.calcColumnsConditionTypes.find(
					option => option.value === stateUpdate.type.value
				);
				// if (typeof value === 'object') {
				// 	stateUpdate.conditionType = options.calcColumnsConditionTypes.find(option => option.value === 'colunm');
				// } else if (/^([0-9]+(\.[0-9]+){0,1})$/.test(value)) {
				// 	stateUpdate.conditionType = options.calcColumnsConditionTypes.find(option => option.value === 'numeric');
				// } else if (/\d{4}-\d{1,2}-\d{1,2}/.test(value)) {
				// 	stateUpdate.conditionType = options.calcColumnsConditionTypes.find(option => option.value === 'date_long');
				// } else {
				// 	stateUpdate.conditionType = options.calcColumnsConditionTypes.find(option => option.value === 'string');
				// }
			}

			if (!type && !value) {
				stateUpdate.conditionType = null;
			}

			this.setState(stateUpdate);
		}

		if (prevProps.outputDataType !== this.props.outputDataType) {
			this.setState({
				value: null,
				conditionType: null,
			});
		}
	}

	setValue = val => {
		const {type} = this.state;
		this.setState(
			{
				value: val,
				showMiniCreator: false,
			},
			() => this.props.onChange(type, val, this.state.conditionType)
		);
	};

	setConstant = e => {
		const {type} = this.state;
		const val = typeof e === 'object' ? _get(e, 'target.value', '') : e; // KiInput returns the value, others return an event
		this.setState(
			{
				value: val,
				showMiniCreator: false,
			},
			() => this.props.onChange(type, val, this.state.conditionType)
		);
	};

	setDateConstant = val => {
		const {type} = this.state;
		this.setState(
			{
				value: dateToShortDate(val),
				showMiniCreator: false,
			},
			() => this.props.onChange(type, dateToShortDate(val), this.state.conditionType)
		);
	};

	setSchemaColumn = schemaColumn => {
		const {type} = this.state;
		this.setState(
			{
				value: schemaColumn,
				showMiniCreator: false,
			},
			() => this.props.onChange(type, schemaColumn, this.state.conditionType)
		);
	};

	getConstantError = () => {
		if (this.props.outputDataType === 'numeric' && isNaN(this.state.value)) {
			return 'Only numeric values allowed';
		}
		return '';
	};

	getColumnError = () => {
		const {value} = this.state;
		const {outputDataType} = this.props;
		if (!value || !outputDataType) {
			return '';
		}
		if (outputDataType === 'date_long' && value.dataType !== 'date_long') {
			return 'Only date columns allowed';
		}
		return value.dataType === outputDataType
			? ''
			: outputDataType === 'string'
				? 'Only text columns allowed'
				: 'Only numeric columns allowed';
	};

	deleteValue = () => {
		this.setState(
			{
				type: null,
				conditionType: null,
				value: null,
				constantError: '',
				showMiniCreator: false,
			},
			() => this.props.onChange(null, null)
		);
	};

	searchByDisplayName = (option, searchText = '') => {
		return option.data.displayName.toLowerCase().includes(searchText.toLowerCase());
	};

	getConditionalField = (columns = [], hideCancelBtn, conditionType, value, type) => {
		const matchType = _get(conditionType, 'value', null) || _get(type, 'value', null);
		const colOptions = [
			{
				_id: 'newColumn',
				displayName: 'Create New...',
				className: 'option-bold-italic',
			},
			...columns,
		];
		if (this.props.hideNewColumnOption) {
			colOptions.shift();
		}
		switch (matchType) {
			case 'column':
				return (
					<div style={{width: '100%'}}>
						<span className="form-instruction">Column:</span>
						<div className="picker-type">
							<Select
								classNamePrefix="aut-select"
								className="select-width-100"
								default="Select Column"
								filterOption={this.searchByDisplayName}
								value={
									!(value && value.length)
										? colOptions.find(opt => opt._id === _get(value, '_id'))
										: null
								}
								options={colOptions}
								getOptionLabel={option => (
									<div>
										{option.displayName}
										{!!option.calculation && ` (${option.calculation})`}
									</div>
								)}
								getOptionValue={option => option._id}
								onChange={val => {
									if (val && val._id === 'newColumn') {
										this.setState({showMiniCreator: true});
									} else {
										this.setValue(val);
									}
								}}
							/>
							<KiIconButton icon="cancel" onClick={() => this.deleteValue()} />
						</div>
						<p style={{color: '#de3226'}}>{this.getColumnError()}</p>
					</div>
				);
			case 'subAccountColumn':
				return (
					<div style={{width: '100%'}}>
						<span className="form-instruction">Child Column:</span>
						<div className="picker-type">
							<Select
								classNamePrefix="aut-select"
								className="select-width-100"
								value={this.props.schemaColumns.find(s => s.id === _get(value, 'id', value))}
								options={this.props.schemaColumns}
								getOptionLabel={option => (
									<div>
										{option.displayName}
										{!!option.calculation && ` (${option.calculation})`}
									</div>
								)}
								getOptionValue={option => option.id}
								onChange={s => this.setSchemaColumn(s)}
							/>
							<KiIconButton icon="cancel" onClick={() => this.deleteValue()} />
						</div>
					</div>
				);
			case 'string':
				return (
					<div style={{width: '100%'}}>
						<span className="form-instruction">Text Constant:</span>
						<div className="picker-type" style={{minHeight: '3rem'}}>
							<KiInput
								placeholder="Enter Constant"
								value={value && value.length ? value : ''}
								onChange={e => this.setConstant(e)}
								autoFocus
							/>
							{!hideCancelBtn && <KiIconButton icon="cancel" onClick={() => this.deleteValue()} />}
						</div>
						<p style={{color: '#de3226'}}>{this.getConstantError()}</p>
					</div>
				);
			case 'numeric':
				return (
					<div style={{width: '100%'}}>
						<span className="form-instruction">Numeric Constant:</span>
						<div className="picker-type" style={{minHeight: '3rem'}}>
							<KiInput
								placeholder="Enter Constant"
								value={value && value.length ? value : ''}
								onChange={e => this.setConstant(e)}
								isNumberMasked={true}
								autoFocus
								hint="Percents should be entered as decimals"
							/>
							{!hideCancelBtn && <KiIconButton icon="cancel" onClick={() => this.deleteValue()} />}
						</div>
						<p style={{color: '#de3226'}}>{this.getConstantError()}</p>
					</div>
				);
			case 'date_long':
				return (
					<div style={{width: '100%'}}>
						<span className="form-instruction">Date Constant:</span>
						<div className="picker-type">
							<KiDatePicker onChange={this.setDateConstant} value={value} />
							<span>{this.getConstantError()}</span>
							<KiIconButton icon="cancel" onClick={() => this.deleteValue()} />
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	render() {
		const {columns = [], dataType, outputDataType, schemaColumns} = this.props;
		const subAccountColumnOption = {label: 'Child Column', value: 'subAccountColumn'};
		const {hideCancelBtn} = this.props;
		const {value} = this.state;
		let {type, conditionType} = this.state;
		const isSubaccount = Array.isArray(schemaColumns) && schemaColumns.length > 0;

		// Backwards compatibility
		if (_get(type, 'value', null) === 'constant') {
			type = {
				label: 'Text Constant',
				value: 'string',
			};
		}

		if (value && !conditionType) {
			conditionType = options.calcColumnsConditionTypes.find(option => option.value === type.value);
		}

		let termOptions = isSubaccount
			? [...options.calcColumnsConditionTypes, subAccountColumnOption]
			: [...options.calcColumnsConditionTypes];

		if (outputDataType) {
			// Output Type
			termOptions = options.calcColumnsConditionTypes.filter(
				col => col.value === outputDataType || col.value === 'column'
			);
			if (isSubaccount) {
				termOptions.push(subAccountColumnOption);
			}
		} else if (dataType) {
			// Term Type
			termOptions = options.calcColumnsConditionTypes.filter(
				col => col.value === dataType || col.value === 'column'
			);
			if (isSubaccount) {
				termOptions.push(subAccountColumnOption);
			}
		}

		return (
			<div style={{display: 'flex', flexGrow: 1}}>
				{!type ? (
					<div style={{width: '100%'}}>
						<span className="form-instruction">Type:</span>
						<Select
							classNamePrefix="aut-select"
							value={termOptions.find(opt => opt.value === type)}
							options={termOptions}
							isClearable={false}
							onChange={selected => {
								this.setState({
									type: selected,
									conditionType: selected,
									value: null,
								});
							}}
						/>
					</div>
				) : null}
				{this.getConditionalField(columns, hideCancelBtn, conditionType, value, type, dataType)}
				{this.state.showMiniCreator && (
					<MiniCreator
						columnType={dataType}
						onCancel={() => this.setState({showMiniCreator: false})}
						onSave={newId => {
							const column = columns.find(c => newId === c._id);
							if (column) {
								this.setValue(column);
							}
						}}
						reloadView={this.props.reloadView}
					/>
				)}
			</div>
		);
	}
}

export default ColConstFuncPicker;
