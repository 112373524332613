import React, {useEffect, useState} from 'react';
import KiSelect from '../../../components/KiSelect';
import KiDatePicker from '../../../components/KiDatePicker';
import KiModal from '../../../components/KiModal';
import {DATE_CONTEXT} from './LedgerExploration';
import PropTypes from 'prop-types';
import {
	compareDates,
	formatDate,
	getFirstDayBeforeMonths,
	getFirstDayOfMonth,
	getLastDayOfMonth,
	isDateValid,
} from '../../../utils/dateUtils';
import {getGenerateLedgerExportURL} from '../../../api/ledgersApi';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../state/actions/Snackbar';
import {copyToClipboard} from '../../../utils/copyUtil';

const DATE_CONTEXT_OPTIONS = [DATE_CONTEXT.EFFECTIVE_DATE, DATE_CONTEXT.POSTING_DATE];
const DATEPICKER_OPTIONS = {
	START: 'START',
	END: 'END',
};
const EXPORT_METHODS = {
	EXPORT: 'export',
	DOWNLOAD: 'download',
};

function LedgerExportModal({isActive, setModalActive, ledgers, showSnackbar}) {
	const [startDate, setStartDate] = useState(getFirstDayBeforeMonths(1));
	const [isStartDateValid, setStartDateValid] = useState(true);
	const [endDate, setEndDate] = useState(getLastDayOfMonth(getFirstDayBeforeMonths(1)));
	const [isEndDateValid, setEndDateValid] = useState(true);
	const [dateContext, setDateContext] = useState(DATE_CONTEXT_OPTIONS[0]);
	const [startDateError, setStartDateError] = useState('');
	const [endDateError, setEndDateError] = useState('');
	const [disableSubmit, setDisableSubmit] = useState(false);
	const DATEPICKER_ERR_MESSAGE = 'Please fill fields correctly';

	const handleSetError = (action, statement) => {
		if (action === DATEPICKER_OPTIONS.START) {
			setStartDateError(statement ? DATEPICKER_ERR_MESSAGE : '');
		} else if (action === DATEPICKER_OPTIONS.END) {
			setEndDateError(statement ? DATEPICKER_ERR_MESSAGE : '');
		}
	};

	const exportPosts = () => {
		const statement = isStartDateValid && isEndDateValid && isDateValid(startDate) && isDateValid(endDate);
		if (statement) {
			const url = getGenerateLedgerExportURL(
				dateContext.value,
				startDate,
				endDate,
				Array.from(ledgers),
				EXPORT_METHODS.DOWNLOAD
			);
			location.replace(url);
			setModalActive(false);
			showSnackbar('Ledger export completed');
		}
	};

	const copyURL = e => {
		e.preventDefault();
		const url = getGenerateLedgerExportURL(
			dateContext.value,
			startDate,
			endDate,
			Array.from(ledgers),
			EXPORT_METHODS.EXPORT
		);
		copyToClipboard(url);
		showSnackbar('Export data link copied to your clipboard.');
	};

	const MODAL_ACTIONS_EXPORT = [
		{
			label: 'Cancel',
			onClick: () => setModalActive(false),
		},
		{
			label: 'Export',
			disabled: disableSubmit,
			onClick: exportPosts,
		},
	];

	useEffect(
		() => {
			const isValid = isStartDateValid && isEndDateValid;
			setDisableSubmit(!isValid);
			handleSetError(DATEPICKER_OPTIONS.START, !isStartDateValid);
			handleSetError(DATEPICKER_OPTIONS.END, !isEndDateValid);
		},
		[isStartDateValid, isEndDateValid]
	);

	const onStartDateChanged = date => {
		const currentStartDate = date || startDate;
		if (compareDates(currentStartDate, endDate)) {
			setEndDate(currentStartDate);
		}
	};

	const onEndDateChanged = date => {
		const currentEndDate = date || endDate;
		if (compareDates(startDate, currentEndDate) && startDate !== currentEndDate) {
			setStartDate(getFirstDayOfMonth(currentEndDate));
		}
	};

	return (
		<KiModal
			active={isActive}
			actions={MODAL_ACTIONS_EXPORT}
			onClose={() => setModalActive(false)}
			header="Export"
			className="export-modal"
		>
			<KiSelect
				className="select"
				options={DATE_CONTEXT_OPTIONS}
				value={dateContext}
				label="Date Context"
				onChange={opt => setDateContext(opt)}
			/>
			<KiDatePicker
				label="Start Date"
				value={startDate}
				className="datepicker"
				onChange={date => {
					setStartDate(formatDate(date));
					setStartDateValid(isDateValid(date));
				}}
				onBlur={() => onStartDateChanged()}
				onSelect={v => onStartDateChanged(v)}
				error={startDateError}
			/>
			<KiDatePicker
				label="End Date"
				value={endDate}
				className="datepicker"
				onChange={date => {
					setEndDate(formatDate(date));
					setEndDateValid(isDateValid(date));
				}}
				onBlur={() => onEndDateChanged()}
				onSelect={v => onEndDateChanged(v)}
				error={endDateError}
			/>
			<a href="#" onClick={e => copyURL(e)}>
				Copy Data Link
			</a>
		</KiModal>
	);
}

LedgerExportModal.propTypes = {
	isActive: PropTypes.bool.isRequired,
	setModalActive: PropTypes.func.isRequired,
	ledgers: PropTypes.object.isRequired,
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(null, {showSnackbar})(LedgerExportModal);
