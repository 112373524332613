import React from 'react';
import PropTypes from 'prop-types';
import AccountsConfigureItem from './AccountsConfigureItem';
import KiIconButton from '../../../../../components/KiIconButton';
import './LedgerAccountsConfiguration.scss';
import {connect} from 'react-redux';
import {addAccount} from './actions';
import {isAnyListItemVisible} from '../../../ledgerUtils';

function AccountsConfigureTable({accountList, searchActive, addAccount}) {
	const isDeletedAccountsEqualAccounts = () => {
		return accountList.filter(acc => acc.deleted).length === accountList.length;
	};

	return (
		<div className="scenario-list-box list-box-size-1">
			<table className="scenario-list-table scenario-list-table-ledger">
				<thead>
					<tr>
						<th className="active-select-item">Active</th>
						<th>Account Type</th>
						<th>Account Description</th>
						<th>Account Code</th>
						<th className="align-right ledger-actions">
							<KiIconButton
								icon="add_circle"
								inverse
								onClick={addAccount}
								title="Click to create a new account."
								className="add-button"
								disabled={searchActive}
							/>
						</th>
					</tr>
				</thead>
				<tbody>
					{accountList.length > 0 &&
					!isDeletedAccountsEqualAccounts() &&
					isAnyListItemVisible(accountList) ? (
						accountList.map((account, index) => {
							return !account.deleted && account.visible ? (
								<AccountsConfigureItem item={account} index={index} key={index} />
							) : null;
						})
					) : (
						<tr>
							<td>
								<div className="no-list-items-container">
									<div>
										No accounts{accountList.length > 0 && !isDeletedAccountsEqualAccounts()
											? ' found'
											: ''}
									</div>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

AccountsConfigureTable.propTypes = {
	accountList: PropTypes.arrayOf(PropTypes.object),
	searchActive: PropTypes.bool.isRequired,
	addAccount: PropTypes.func,
};

const mapStateToProps = ({ledgerAccountConfiguration}) => ({
	accountList: ledgerAccountConfiguration.accountList,
});

export default connect(mapStateToProps, {addAccount})(AccountsConfigureTable);
