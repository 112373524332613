import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router';
import {fetchLedgerAccountsMapping, updateAccounts} from '../../../../../api/ledgersApi';
import LedgerSubmitHeader from '../../LedgerSubmitHeader';
import KiSelect from '../../../../../components/KiSelect';
import KiInput from '../../../../../components/KiInput';
import AccountsConfigureTable from './AccountsConfigureTable';
import {CONFIGURE_ACTIONS, SELECT_CONFIGURE_ACTIONS} from '../LedgerConfigure';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../../../state/actions/Snackbar';
import './LedgerAccountsConfiguration.scss';
import {setData, setDataFromOriginal, setOriginalData} from './actions';
import Loader from '../../../Loader';
import {ACCOUNTS_IN_USE_ERROR_CODE, convertAccountsForSave, filterAccountList} from './accountUtils';
import get from 'lodash/get';

function LedgerAccountConfiguration({
	setView,
	showSnackbar,
	setData,
	setDataFromOriginal,
	setOriginalData,
	accountList,
}) {
	const [searchTerm, setSearchTerm] = useState('');
	const [isLoading, setLoading] = useState(true);
	const {id} = useParams();

	const getAccounts = () => {
		return fetchLedgerAccountsMapping(id, false, true).then(accounts => {
			setDataFromOriginal(accounts);
			setOriginalData(accounts);
			setLoading(false);
		});
	};

	const onSave = () => {
		setSearchTerm('');
		setLoading(true);

		updateAccounts(id, convertAccountsForSave(accountList))
			.then(({accounts}) => {
				setDataFromOriginal(accounts);
				setOriginalData(accounts);
				showSnackbar('All account configuration changes have been saved.');
				setLoading(false);
			})
			.catch(error => {
				if (error.status == 400 && get(error, 'data.error.errorCode') === ACCOUNTS_IN_USE_ERROR_CODE) {
					getAccounts();
					showSnackbar(
						'Some of accounts has been used for a template or manual entry in the mean time and cannot be deleted. The list has been reloaded.'
					);
				}
			});
	};

	const onCancel = () => {
		setLoading(true);
		setSearchTerm('');
		getAccounts().then(() => showSnackbar('All account configuration changes have been cancelled.'));
	};

	const BUTTON_ACTIONS = [
		{
			label: 'CANCEL',
			isFlat: true,
			onClick: onCancel,
		},
		{
			label: 'SAVE',
			onClick: onSave,
		},
	];

	useEffect(
		() => {
			if (searchTerm !== '') {
				setData(filterAccountList(searchTerm, accountList));
			} else {
				accountList.forEach(item => (item.visible = true));
				setData(accountList);
			}
		},
		[searchTerm]
	);

	useEffect(() => {
		getAccounts();
	}, []);

	return (
		<Fragment>
			<section className="ki-panel">
				<LedgerSubmitHeader actions={BUTTON_ACTIONS}>
					<KiSelect
						className="select"
						options={SELECT_CONFIGURE_ACTIONS}
						value={CONFIGURE_ACTIONS.ACCOUNTS}
						onChange={value => setView(value)}
					/>
				</LedgerSubmitHeader>
			</section>
			<section className="ki-panel ledger-configure-panel">
				<div className="accounts">
					<Loader isActive={isLoading}>
						<div className="search-box">
							<div>
								<KiInput
									label="Search"
									name="ledgerListFilter"
									value={searchTerm}
									onChange={val => setSearchTerm(val)}
									maxLength={100}
								/>
							</div>
						</div>
						<div className="accounts-wrapper">
							<AccountsConfigureTable searchActive={searchTerm !== ''} />
						</div>
					</Loader>
				</div>
			</section>
		</Fragment>
	);
}

LedgerAccountConfiguration.propTypes = {
	setView: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	setData: PropTypes.func,
	setDataFromOriginal: PropTypes.func,
	setOriginalData: PropTypes.func,
	accountList: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({ledgerAccountConfiguration}) => ({
	accountList: ledgerAccountConfiguration.accountList,
});

export default connect(mapStateToProps, {setData, setDataFromOriginal, setOriginalData, showSnackbar})(
	LedgerAccountConfiguration
);
