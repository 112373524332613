import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import ManualEntryLineItem from './ManualEntryLineItem';
import {convertFormDataToEntry} from './manulEntryUtils';
import {saveManualEntry} from '../../../../api/ledgersApi';
import set from 'lodash/set';
import ManualEntryPostItem from './ManualEntryPostItem';
import {initialPosting} from './LedgerManualEntry';
import {TRANSACTION_TYPE_DEBIT} from '../../ledgerUtils';

function ManualEntryForm({formData, accountOptions, ledger, afterSave, setFormData, undoChanges}) {
	const [entryDebit, setEntryDebit] = useState(0);
	const [entryCredit, setEntryCredit] = useState(0);

	const onFormItemChange = (key, value) => {
		const newFormData = Object.assign({}, formData);
		set(newFormData, key, value);
		setFormData(newFormData);
	};

	const calculateEntryBalance = () => {
		// try 0.1 + 0.2 in JS and you will know why ;)
		const multiplier = 100;
		let debit = 0;
		let credit = 0;
		formData.postings.forEach(posting => {
			if (posting.type.value === TRANSACTION_TYPE_DEBIT.value) {
				debit += Number(posting.amount) * multiplier;
			} else {
				credit += Number(posting.amount) * multiplier;
			}
		});
		setEntryDebit(debit / multiplier);
		setEntryCredit(credit / multiplier);
	};

	useEffect(
		() => {
			calculateEntryBalance();
		},
		[formData]
	);

	const canEntryBeSaved = () => {
		const areLineItemsValid = formData.postings.reduce(
			(acc, posting) => acc && posting.lineItem !== '' && posting.account !== '' && posting.amount !== '',
			true
		);
		return areLineItemsValid && !Object.values(formData).some(i => i === '') && entryDebit === entryCredit;
	};

	const addLineItem = () => {
		const newFormData = cloneDeep(formData);
		newFormData.postings.push(cloneDeep(initialPosting));
		setFormData(newFormData);
	};

	const deleteLineItem = index => {
		const newFormData = Object.assign({}, formData);
		delete newFormData.postings.splice(index, 1);
		setFormData(newFormData);
	};

	const onSave = () => {
		const saveFormData = convertFormDataToEntry(formData);
		saveManualEntry(ledger._id, saveFormData).then(() => {
			undoChanges();
			afterSave();
		});
	};

	const renderLineItem = (posting, index) => (
		<ManualEntryLineItem
			key={index}
			deleteDisabled={formData.postings.length <= 2}
			onItemChange={(fieldName, value) => onFormItemChange(`postings[${index}].${fieldName}`, value)}
			onItemDelete={() => deleteLineItem(index)}
			item={posting}
			accountOptions={accountOptions}
		/>
	);

	return (
		<form className="boxed-container">
			{ledger && ledger.closeDate ? (
				<Fragment>
					<ManualEntryPostItem
						formData={formData}
						undoChanges={undoChanges}
						onSave={onSave}
						onFormItemChange={onFormItemChange}
						canEntryBeSaved={canEntryBeSaved}
						debit={entryDebit}
						credit={entryCredit}
						ledgerCloseDate={ledger.closeDate}
						addLineItem={addLineItem}
					/>
					{formData.postings.map(renderLineItem)}
				</Fragment>
			) : null}
		</form>
	);
}

ManualEntryForm.propTypes = {
	formData: PropTypes.object.isRequired,
	accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	ledger: PropTypes.object.isRequired,
	afterSave: PropTypes.func.isRequired,
	setFormData: PropTypes.func.isRequired,
	undoChanges: PropTypes.func.isRequired,
};

export default ManualEntryForm;
