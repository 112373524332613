import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
// importing named export so that update functions can be passed
import {ColumnSettingList} from 'components/FlyoutManageViews/components/ViewForms/ColumnSettingList';
import _get from 'lodash/get';
import {getColumnsFromService} from 'api/columnServiceApi';
import {DataExplorerContext} from '../../DataExplorerContext';

export const AssetColumns = ({setFormIsValid}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark: {explorerData},
		actions,
	} = dataExplorerContext;

	const dataset = useSelector(state => state.datasetList.selected);

	const [columns, setColumns] = useState([]);

	useEffect(() => {
		getColumnsFromService(dataset.datasetId, {
			sources: {
				includeAssetColumns: true,
				includeAssetCalculations: true,
				includeDateColumns: true,
				includeBusinessFunctions: true,
				includeHistoricalColumns: true,
			},
		}).then(columnsData => setColumns(columnsData.filter(col => !col.asOfDateType || col.poolMappingsHash)));
	}, []);

	return (
		<ColumnSettingList
			setFormIsValid={setFormIsValid}
			tableType={explorerData.tableType}
			settingType="asset"
			columns={columns}
			allSettings={_get(explorerData, 'columns', [])}
			settingList={_get(explorerData, 'columns', []).filter(col => col.columnType === 'asset')}
			// This function doesn't need to do anything because now changes are recognised in context itself
			setFormHasChanges={() => void 0}
			updateColumns={actions.setColumns}
			updateColumn={actions.updateColumn}
		/>
	);
};

AssetColumns.propTypes = {
	setFormIsValid: PropTypes.func.isRequired,
};

export default AssetColumns;
