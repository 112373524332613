// Global imports
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {AbsTable} from '@moodysanalytics/cs-structured-ux-common';

// Project imports
import {reportsApi} from 'api';
import KiProgressBar from 'components/KiProgressBar';

// Local imports
import './KiCardPreview.scss';
import HeaderCell from './HeaderCell';
import TableCell from './TableCell';

// TODO make this work correctly if needed (will require passing in full columns)
const buildCalculation = calculation => {
	return calculation ? calculation : '';
};

function KiCardPreview(props) {
	const {card, reportDefinition} = props;
	const [data, setData] = useState({rows: [], columns: []});
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [isBorrowingBase, setIsBorrowingBase] = useState(false);

	const getPreviewReportDefinition = card => {
		const newSections = [
			{
				_id: card._id,
				type: 'card',
				title: card.name,
			},
		];
		if (_.isEmpty(reportDefinition)) {
			return {
				name: 'KiCardPreviewReport',
				settings: {
					datasetId: card.datasetId,
				},
				sections: newSections,
			};
		} else {
			const previewReportDefinition = _.cloneDeep(reportDefinition);
			previewReportDefinition.sections = newSections;
			setIsBorrowingBase(previewReportDefinition.isBorrowingBase ? true : false);
			return previewReportDefinition;
		}
	};

	// handle general load for all cards when card id changes
	useEffect(
		() => {
			// reject invalid config
			if (!props.card) {
				return;
			}
			setError('');
			async function getData() {
				const previewReportDefinition = getPreviewReportDefinition(card);
				const previewReport = await reportsApi.fetchReportPreview(previewReportDefinition);
				const sectionData = _.get(previewReport, 'sections.0.data', {});
				const columns = sectionData.columns || sectionData.columnInfo;
				const {rows} = sectionData;
				if (rows && columns) {
					const getHeaderCell = col => {
						const title = `${col.displayName} ${buildCalculation(col.calculation)}`;
						return <HeaderCell title={title} />;
					};

					setData({
						rows,
						columns: columns.map((col, idx) => ({
							minWidth: 120,
							maxWidth: 180,
							Header: getHeaderCell(col),
							id: `${col.id}-${idx}`,
							accessor: rowData => rowData.data[idx],
							// eslint-disable-next-line react/display-name
							Cell: row => <TableCell column={col} cellValue={row.value} />,
						})),
					});
				} else {
					setError('Problem loading preview data');
				}
			}
			// call fetch
			getData()
				.then(() => {
					setLoading(false);
				})
				.catch(err => {
					setLoading(false);
					setError(err.message);
				});
		},
		[props.card]
	);

	return (
		<React.Fragment>
			{loading && <KiProgressBar />}
			{error && <div className="dataCardError">{`Error fetching data: ${error}`}</div>}
			{!loading &&
				!error && (
					<div className="table-container preview-card-table" style={{overflow: 'auto'}}>
						<AbsTable
							isSortEnabled={isBorrowingBase ? false : true}
							isFilterEnabled={false}
							columns={data.columns}
							data={data.rows}
						/>
					</div>
				)}
		</React.Fragment>
	);
}

KiCardPreview.propTypes = {
	card: PropTypes.object,
	reportDefinition: PropTypes.object,
};

KiCardPreview.defaultProps = {
	card: {},
	reportDefinition: null,
};

export default KiCardPreview;
