const getConstraints = () => ({
	rowType: {
		presence: {allowEmpty: false},
	},
	fundingVehicleId: {
		presence: {allowEmpty: false},
	},
});

module.exports = {
	getConstraints,
};