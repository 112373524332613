// Globals
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import _ from 'lodash';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

// Project imports
import KiDatePicker from 'components/KiDatePicker';
import KiModal2 from 'components/KiModal2';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';
import KiButton from 'components/KiButton';
import KiButtonGroup from 'components/KiButtonGroup';
import KiFontIcon from 'components/KiFontIcon';
import {KiPortfolioDate} from 'components/KiIcons';

// Action imports
import {createDate, createEntry, updateEntry} from '../actions';
import {showSnackbar} from 'state/actions/Snackbar';

// Local imports
import PreviewResultPane from './PreviewResultPane';
import styles from './dateDetailsForm.theme.scss';

const RESERVED_NAMES = [
	'Collection Period End Date',
	'Report Date',
	'Funding Determination Date',
	'Funding Distribution Date',
];

const DS_DATE_TYPES = [
	{label: 'One Time/Static', value: 'static'},
	{label: 'Range', value: 'range'},
	{label: 'Recurring', value: 'recurring'},
];

const BUSINESS_DAY_OPTIONS = [{label: 'Previous', value: 'previous'}, {label: 'Next', value: 'next'}];

const BOUND_BY_OPTIONS = [{value: '<', label: 'Must be before'}, {value: '>', label: 'Must be after'}];

const RECURRENCE_TYPE_OPTIONS = [
	{label: 'Day(s)', value: 'daily'},
	{label: 'Week(s)', value: 'weekly'},
	{label: 'Month(s)', value: 'monthly'},
	{label: 'Month End', value: 'monthly_end'},
];

const START_DATE_OVERRIDE_OPTIONS_MAP = [
	{label: 'FV Start Date', value: 'startDate'},
	{label: 'FV Cutoff Date', value: 'fvCutoffDate'},
];

const END_DATE_OVERRIDE_OPTIONS_MAP = [{label: 'FV End Date', value: 'endDate'}];

const DEFAULT_DATE_OBJ = {
	_id: undefined,
	dateType: 'recurring',
	fundingVehicles: [],
	name: '',
	startDate: new Date().toISOString().slice(0, 10),
	recurrenceType: 'monthly',
	recurrenceStep: 1,
	startDateOverride: null,
	endDateOverride: null,
	endDate: null,
	range: 'all',
	offset: 0,
	calendar: null,
	useCalendar: false,
	businessDay: 'next',
	parentId: null,
	boundByDateId: null,
	boundByOperator: '<',
};

export const DateOptionLabel = props => {
	const hasParentsOnEntries = props.entries.some(entry => entry.parentId);
	const hasBoundDateSelectionsOnEntries = props.entries.some(entry => entry.boundByDateId);
	return (
		<div style={{display: 'inline-flex', alignItems: 'center'}}>
			<span style={{marginRight: '.5rem'}}>{props.name}</span>
			{props.isPortfolioDate && (
				<KiPortfolioDate title="Portfolio Date" style={{filter: 'saturate(0) opacity(0.4)'}} />
			)}
			{hasParentsOnEntries && (
				<KiFontIcon
					style={{cursor: 'default'}}
					title="Child of another date"
					alt="Child"
					value={'supervisor_account'}
				/>
			)}
			{hasBoundDateSelectionsOnEntries && (
				<KiFontIcon title="Bound to other dates" style={{cursor: 'default'}} alt="Bound" value="link" />
			)}
		</div>
	);
};
DateOptionLabel.propTypes = {
	name: PropTypes.string,
	isPortfolioDate: PropTypes.bool,
	entries: PropTypes.arrayOf(PropTypes.object),
};
DateOptionLabel.defaultProps = {
	name: '',
	isPortfolioDate: false,
	entries: [],
};

export class DateDetailsForm extends Component {
	static propTypes = {
		// From Redux
		createDate: PropTypes.func,
		createEntry: PropTypes.func,
		updateEntry: PropTypes.func,
		showSnackbar: PropTypes.func,
		fundingVehicles: PropTypes.array,
		datasetDates: PropTypes.array,
		holidayCalendars: PropTypes.array,
		isAdmin: PropTypes.bool,

		// Params
		datasetId: PropTypes.string.isRequired, //eslint-disable-line react/no-unused-prop-types
		dateId: PropTypes.string.isRequired, //eslint-disable-line react/no-unused-prop-types
		dateName: PropTypes.string,
		dateEntry: PropTypes.shape({
			_id: PropTypes.string,
			fundingVehicles: PropTypes.arrayOf(PropTypes.string),
			dateType: PropTypes.oneOf(['static', 'range', 'recurring']),
			name: PropTypes.string,
			startDate: PropTypes.string,
			startDateOverride: PropTypes.string,
			endDate: PropTypes.string,
			endDateOverride: PropTypes.string,
			recurrenceType: PropTypes.oneOf(['none', 'daily', 'weekly', 'monthly', 'monthly_end']),
			recurrenceStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			range: PropTypes.oneOf(['endAfter', 'endBy', 'all']),
			offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			offsetType: PropTypes.oneOf(['daily', 'weekly', 'monthly']),
			calendar: PropTypes.string,
			useCalendar: PropTypes.bool,
			businessDay: PropTypes.oneOf(['previous', 'next']),
			parentId: PropTypes.string,
			boundByDateId: PropTypes.string,
			boundByOperator: PropTypes.string,
		}),
		onConfirm: PropTypes.func,
		onCancel: PropTypes.func.isRequired,
		isNewDefault: PropTypes.bool,
		isPortfolioDate: PropTypes.bool,
		isLatestSnapshot: PropTypes.bool,
		readOnly: PropTypes.bool,
		snapshotList: PropTypes.array,
	};

	static defaultProps = {
		datasetDates: [],
		holidayCalendars: [],
		date: {
			name: '',
		},
		dateEntry: {
			dateType: 'static',
			startDate: null,
			fundingVehicles: [],
			useCalendar: false,
			calendar: null,
		},
		isPortfolioDate: false,
		readOnly: false,
	};

	state = {
		dateEntry: {
			fundingVehicles: [],
		},
		showParentSelection: !!this.props.isPortfolioDate,
		showBoundBySelection: !!this.props.isPortfolioDate,
		pendingSave: false,
		isDefault: false,
	};

	componentDidMount() {
		const initialDate = _.defaultsDeep(this.props.dateEntry, DEFAULT_DATE_OBJ);
		if (this.props.isNewDefault) {
			initialDate.fundingVehicles = ['default'];
		}
		this.setState({
			isPortfolioDate: !!this.props.isPortfolioDate,
			dateEntry: initialDate,
			showParentSelection: !!initialDate.parentId,
			showBoundBySelection: !!initialDate.boundByDateId,
			isDefault: _.isEqual(this.props.dateEntry.fundingVehicles, ['default']),
		});
	}

	validateForm = () => {
		const {dateEntry = {}, dateName} = this.state;
		if (!dateName && this.props.isNewDefault) {
			return 'Date name is required';
		}
		if (RESERVED_NAMES.includes(dateName)) {
			return `${dateName} is reserved.`;
		}
		if (
			dateName &&
			this.props.isNewDefault &&
			this.props.datasetDates
				.filter(dd => dd._id !== this.props.dateId)
				.map(x => x.name)
				.includes(this.state.dateName)
		) {
			return 'Name already in use';
		}
		if (!dateEntry.parentId && !dateEntry.startDate) {
			return 'No start date selected';
		}
		if (dateEntry.fundingVehicles.length === 0) {
			return 'No funding vehicle(s) selected';
		}
		switch (!dateEntry.parentId && dateEntry.dateType) {
			case 'recurring': {
				if (!dateEntry.recurrenceStep) {
					return 'No repeat amount selected';
				}
				if (!dateEntry.recurrenceType) {
					return 'No recurrence type selected';
				}
				break;
			}
			case 'range': {
				if (!dateEntry.recurrenceStep) {
					return 'No repeat amount selected';
				}
				if (!dateEntry.recurrenceType) {
					return 'No recurrence type selected';
				}
				break;
			}
			case 'static':
			default: {
				break;
			}
		}
		if (!!this.state.showParentSelection && !this.state.dateEntry.parentId) {
			return 'no parent selected';
		}
		return null;
	};

	handleModalConfirm = () => {
		if (this.props.readOnly) {
			return this.setState({pendingSave: false}, () => this.props.onCancel());
		}
		const validationError = this.validateForm();
		if (validationError) {
			return this.props.showSnackbar(validationError);
		}

		const isNewDate = !this.props.dateId;
		const isNewEntry = !isNewDate && !this.props.dateEntry._id;

		// Only recurring and range dates need this
		if (
			(isNewDate || isNewEntry) &&
			!this.state.dateEntry.endDate &&
			this.state.dateEntry.dateType !== 'static' &&
			!this.state.dateEntry.parentId
		) {
			return this.props.showSnackbar('End of Schedule is required for new dates');
		}
		return this.setState({pendingSave: true}, () => {
			if (isNewDate) {
				// New Date Group
				const entry = _.omit(this.state.dateEntry, '_id');
				const dateToSend = {
					name: this.state.dateName,
					datasetId: this.props.datasetId,
					entries: [entry],
					isPortfolioDate: !!this.state.isPortfolioDate,
				};
				return this.props
					.createDate(dateToSend)
					.then(newDate => this.setState({pendingSave: false}, () => this.props.onConfirm(newDate)))
					.catch(() => this.setState({pendingSave: false}, () => this.props.onCancel()));
			} else if (isNewEntry) {
				// New Date Entry
				return this.props
					.createEntry(_.omit(this.state.dateEntry, '_id'))
					.then(() => this.setState({pendingSave: false}, () => this.props.onConfirm()))
					.catch(() => this.setState({pendingSave: false}, () => this.props.onCancel()));
			} else {
				// Update Date Entry
				return this.props
					.updateEntry(this.state.dateEntry)
					.then(() => this.setState({pendingSave: false}, () => this.props.onConfirm()))
					.catch(() => this.setState({pendingSave: false}, () => this.props.onCancel()));
			}
		});
	};

	setFormValue = (key, value) => {
		let valueToSet = _.clone(value);
		const extras = {};
		if (key === 'offset') {
			valueToSet = isNaN(parseInt(value)) ? 0 : parseInt(value);
		} else if (key === 'recurrenceStep') {
			valueToSet = Math.abs(parseInt(value)) || '';
		} else if (
			key === 'fundingVehicles' &&
			(!Array.isArray(value) || value.length !== 1 || ['default'].includes(value[0]))
		) {
			Object.assign(extras, {
				startDateOverride: null,
				endDateOverride: null,
			});
		}
		this.setState(state => ({
			dateEntry: {
				...state.dateEntry,
				[key]: valueToSet,
				...extras,
			},
		}));
	};

	getFundingVehicleOptions = () => {
		const {fundingVehicles, isNewDefault} = this.props;
		const fundingVehicleOptions = _.cloneDeep(fundingVehicles);
		if (isNewDefault) {
			fundingVehicleOptions.unshift({name: 'Default', _id: 'default'});
		}
		return fundingVehicleOptions;
	};

	render() {
		const {datasetDates, holidayCalendars, isNewDefault, isAdmin, isLatestSnapshot, readOnly} = this.props;
		const {isDefault} = this.state;
		const fundingVehicleOptions = this.getFundingVehicleOptions();
		const periodicDateList = [
			{value: 1, label: '1'},
			{value: 2, label: '2'},
			{value: 3, label: '3'},
			{value: 4, label: '4'},
			{value: 6, label: '6'},
			{value: 12, label: '12'},
		];
		// Get the date group object and calculate any FV's already used
		const selectedDate = datasetDates.find(dd => dd._id === this.props.dateId);
		const usedFVs = selectedDate
			? selectedDate.entries.reduce((used, curEntry) => {
					return used.concat(curEntry.fundingVehicles);
			  }, [])
			: [];

		const enableStartAndEndOverrides =
			_.get(this.state, 'dateEntry.fundingVehicles', []).length === 1 &&
			!['default'].includes(this.state.dateEntry.fundingVehicles[0]);
		return (
			<KiModal2
				active={true}
				actions={[
					{label: 'Cancel', onClick: this.props.onCancel},
					{
						label: readOnly ? 'Ok' : 'Apply',
						disabled: !!this.validateForm() || !!this.state.pendingSave || !isAdmin,
						onClick: this.handleModalConfirm,
					},
				]}
				onClose={() => null}
				panelStyles={{minHeight: '40rem', minWidth: '40rem', overflow: 'visible'}}
				className={styles.modal}
				header={`Date Configuration - ${isNewDefault ? 'NEW DATE' : this.props.dateName}`}
			>
				<div className={styles.mainWrapper}>
					{!this.props.dateId && (
						<section>
							<KiInput
								label="Name"
								name="name"
								value={_.get(this.state, 'dateName', '')}
								onChange={val => {
									this.setState({
										...this.state,
										dateName: val,
									});
								}}
								error={
									(datasetDates || [])
										.map(x => x.name)
										.concat(RESERVED_NAMES)
										.includes(this.state.dateName) && 'Name In Use'
								}
							/>
						</section>
					)}
					<KiButtonGroup
						disabled={!!this.props.dateId}
						style={{marginBottom: '1rem', overflow: 'visible'}}
						value={!!this.state.isPortfolioDate}
						onChange={isPortfolioDate => {
							this.setState({isPortfolioDate: isPortfolioDate});
							if (isPortfolioDate) {
								this.setFormValue('dateType', 'recurring');
								this.setFormValue('fundingVehicles', ['default']);
								this.setFormValue('recurrenceType', 'daily');
								this.setState({isDefault: true});
							}
						}}
					>
						<KiButton value={false} label="Funding Vehicle" />
						<KiButton value={true} label="Portfolio" />
					</KiButtonGroup>
					<section className={styles.firstRow}>
						{!this.state.isPortfolioDate && (
							<div className={styles.defaultCheckboxRow}>
								<KiCheckbox
									name="isDefault"
									disabled={!isAdmin || !isNewDefault}
									style={{marginBottom: 0}}
									label={'Default Date'}
									checked={isDefault}
									onChange={checked => {
										if (checked) {
											this.setState({isDefault: true});
											this.setFormValue('fundingVehicles', ['default']);
										} else {
											this.setState({isDefault: false});
											this.setFormValue('fundingVehicles', []);
										}
									}}
								/>
							</div>
						)}
						{!this.state.isPortfolioDate && (
							<div className={styles.fundingVehicleSelectorWrapper}>
								<label>Funding Vehicle(s)</label>
								<KiSelect
									name="fundingVehicles"
									isMulti
									isDisabled={!isAdmin || isDefault}
									options={this.props.fundingVehicles}
									getOptionLabel={option => {
										if (usedFVs.includes(option._id)) {
											return `${option.name} (In Use)`;
										}
										return option.name;
									}}
									getOptionValue={option => option._id}
									isOptionDisabled={option => usedFVs.includes(option._id)}
									value={fundingVehicleOptions.filter(fv =>
										_.get(this.state, 'dateEntry.fundingVehicles', []).includes(fv._id)
									)}
									onChange={val =>
										this.setFormValue('fundingVehicles', _.sortBy(val, 'name').map(x => x._id))
									}
								/>
							</div>
						)}
						{!this.state.showParentSelection &&
							!readOnly && (
								<div className={styles.holidayCheckboxWrapper}>
									<KiCheckbox
										name="useCalendar"
										disabled={!isAdmin || this.state.dateEntry.parentId}
										style={{marginBottom: 0}}
										label={'Ignore Holidays'}
										checked={!this.state.dateEntry.useCalendar}
										onChange={checked => {
											if (checked) {
												this.setFormValue('calendar', null);
												this.setFormValue('useCalendar', false);
											} else {
												this.setFormValue('useCalendar', true);
												this.setFormValue(
													'calendar',
													_.get(this.props, 'holidayCalendars[0]._id')
												);
											}
										}}
									/>
								</div>
							)}
						{!this.state.dateEntry.parentId &&
							!!this.state.dateEntry.useCalendar && (
								<React.Fragment>
									<div className={styles.holidayCalendarSelectorWrapper}>
										<label>Holiday Calendar</label>
										<KiSelect
											name="calendar"
											isDisabled={!isAdmin || readOnly}
											isClearable={false}
											placeholder={'Ignored'}
											options={holidayCalendars}
											getOptionLabel={option => option.name}
											getOptionValue={option => option._id}
											onChange={opt => {
												this.setFormValue('calendar', _.get(opt, '_id', null));
											}}
											value={holidayCalendars.find(
												hc => hc._id === this.state.dateEntry.calendar
											)}
										/>
									</div>
									<div className={styles.businessDaySelectorWrapper}>
										<label>Business Day</label>
										<KiSelect
											name="businessDay"
											isDisabled={!isAdmin || readOnly}
											options={BUSINESS_DAY_OPTIONS}
											value={BUSINESS_DAY_OPTIONS.find(
												({value}) => value === this.state.dateEntry.businessDay
											)}
											onChange={opt => {
												if (opt) {
													this.setFormValue('businessDay', _.get(opt, 'value'));
												}
											}}
										/>
									</div>
								</React.Fragment>
							)}
					</section>
					{!this.state.isPortfolioDate && (
						<section className={styles.checkboxRow}>
							<section className={styles.childDateSelectionWrapper}>
								<KiCheckbox
									name="isChild"
									disabled={!isAdmin || this.state.showBoundBySelection}
									label="Make This A Child"
									checked={this.state.showParentSelection}
									onChange={checked => {
										this.setState({showParentSelection: checked}, () => {
											if (!checked) {
												this.setFormValue('parentId', null);
												this.setFormValue('offset', 0);
											} else {
												this.setFormValue('calendar', null);
												this.setFormValue('useCalendar', false);
											}
										});
									}}
								/>
								{!!this.state.showParentSelection && (
									<div className={styles.parentSelectionSelectorWrapper}>
										<label>Parent Date</label>
										<KiSelect
											name="parentId"
											isDisabled={!isAdmin || readOnly}
											options={this.props.datasetDates}
											isOptionDisabled={opt =>
												!!opt.isPortfolioDate ||
												this.props.dateId === opt._id ||
												opt.entries.some(entry => entry.parentId || entry.boundByDateId)
											}
											formatOptionLabel={opt => (
												<DateOptionLabel
													name={opt.name}
													entries={opt.entries}
													isPortfolioDate={opt.isPortfolioDate}
												/>
											)}
											getOptionLabel={opt => opt && opt.name}
											getOptionValue={opt => opt && opt.groupId}
											value={this.props.datasetDates.filter(
												x => x.groupId === this.state.dateEntry.parentId
											)}
											onChange={opt => {
												if (opt) {
													this.setFormValue('parentId', opt.groupId);
													this.setFormValue('dateType', null);
													this.setFormValue('calendar', null);
													this.setFormValue('useCalendar', false);
												} else {
													this.setFormValue('parentId', null);
												}
											}}
										/>
									</div>
								)}
							</section>
							<section className={styles.boundByCheckboxSection} title="Only works with Recurring dates">
								<KiCheckbox
									name="boundDate"
									disabled={
										!isAdmin ||
										this.state.showParentSelection ||
										this.state.dateEntry.dateType != 'recurring' ||
										readOnly
									}
									label="Bound By Date"
									checked={this.state.showBoundBySelection}
									onChange={checked => {
										this.setState({showBoundBySelection: checked}, () => {
											if (!checked) {
												this.setFormValue('boundByDateId', null);
											}
										});
									}}
								/>
							</section>
						</section>
					)}
					<section className={styles.definitionWrapper}>
						{!this.state.showParentSelection && (
							<div className={styles.definitionTypeSelectWrapper}>
								<label>Type</label>
								<KiSelect
									name="dateType"
									isDisabled={!isAdmin || this.state.isPortfolioDate}
									label={'Type'}
									options={DS_DATE_TYPES}
									isOptionDisabled={option =>
										this.state.showBoundBySelection && option.value != 'recurring'
									}
									value={DS_DATE_TYPES.find(x => x.value === this.state.dateEntry.dateType)}
									onChange={val => {
										if (val.value === 'static') {
											this.setFormValue('recurrenceType', 'none');
										} else if (
											[undefined, null, 'none', 'static'].includes(
												this.state.dateEntry.recurrenceType
											)
										) {
											this.setFormValue('startDate', '');
											this.setFormValue('recurrenceType', 'daily');
										}
										this.setFormValue('dateType', val.value);
									}}
								/>
							</div>
						)}
						{!this.state.showParentSelection &&
							this.state.dateEntry.dateType === 'static' && (
								<div className={styles.staticDateSelectWrapper}>
									<label>Date</label>
									<KiDatePicker
										name="startDateStatic"
										disabled={!isAdmin || readOnly}
										minDate={new Date('1899-12-31')}
										maxDate={new Date('2099-12-31')}
										className={styles.startDatePicker}
										onChange={val => this.setFormValue('startDate', dateToShortDate(val))}
										value={this.state.dateEntry.startDate}
										popperClassName={styles.datePickerPopper}
									/>
								</div>
							)}
						{!this.state.showParentSelection &&
							['range', 'recurring'].includes(this.state.dateEntry.dateType) &&
							this.state.dateEntry.recurrenceType !== 'monthly_end' && (
								<div className={styles.rangeRecurringInputWrapper}>
									<label>Repeat Every</label>
									<KiInput
										name="recurrenceStep"
										disabled={!isAdmin || readOnly}
										min={1}
										step={1}
										type={'number'}
										className={styles.repeatInput}
										onChange={val => {
											this.setFormValue('recurrenceStep', val);
										}}
										value={this.state.dateEntry.recurrenceStep}
									/>
								</div>
							)}
						{!this.state.showParentSelection &&
							['range', 'recurring'].includes(this.state.dateEntry.dateType) &&
							this.state.dateEntry.recurrenceType === 'monthly_end' && (
								<div className={styles.rangeRecurringDateMonthEndWrapper}>
									<label>Repeat Every</label>
									<KiSelect
										name="recurrenceStepMonthEnd"
										isDisabled={!isAdmin || readOnly}
										options={periodicDateList}
										onChange={option => {
											this.setFormValue('recurrenceStep', option.value);
										}}
										value={periodicDateList.find(
											d => d.value === this.state.dateEntry.recurrenceStep
										)}
									/>
								</div>
							)}
						{!this.state.showParentSelection &&
							['range', 'recurring'].includes(this.state.dateEntry.dateType) && (
								<div className={styles.rangeRecurringDateTypeWrapper}>
									<label>&nbsp;</label>
									<KiSelect
										name="recurrenceType"
										isDisabled={!isAdmin || readOnly}
										options={RECURRENCE_TYPE_OPTIONS}
										onChange={val => {
											if (val) {
												if (
													_.get(val, 'value') === 'daily' &&
													moment(this.state.dateEntry.startDate, 'YYYY-MM-DD').isBefore(
														'1999-12-31'
													)
												) {
													this.setFormValue('startDate', '');
												}
												this.setFormValue('recurrenceType', val.value);
												if (_.get(val, 'value') === 'monthly_end') {
													this.setFormValue('recurrenceStep', 1);
												}
											}
										}}
										value={RECURRENCE_TYPE_OPTIONS.find(
											x => x.value === this.state.dateEntry.recurrenceType
										)}
									/>
								</div>
							)}
					</section>
					<section className={styles.rangeRecurringStartEndRow}>
						{!this.state.showParentSelection &&
							['range', 'recurring'].includes(this.state.dateEntry.dateType) && (
								<div className={styles.definitionTypeSelectWrapper}>
									<label htmlFor="startDateOverride">Start Date</label>
									<KiSelect
										name="startDateOverride"
										onChange={opt =>
											this.setFormValue('startDateOverride', _.get(opt, 'value', null))
										}
										isClearable={true}
										placeholder={'None'}
										value={START_DATE_OVERRIDE_OPTIONS_MAP.filter(
											({value}) => value === this.state.dateEntry.startDateOverride
										)}
										isDisabled={!isAdmin || readOnly || !enableStartAndEndOverrides}
										className={styles.startDatePicker}
										options={START_DATE_OVERRIDE_OPTIONS_MAP}
									/>
								</div>
							)}
						{!this.state.showParentSelection &&
							['range', 'recurring'].includes(this.state.dateEntry.dateType) && (
								<div className={styles.rangeRecurringStartDate}>
									<label>Start of Schedule</label>
									<KiDatePicker
										name="startDate"
										disabled={!isAdmin || readOnly}
										minDate={
											this.state.dateEntry.recurrenceType === 'daily'
												? new Date('1999-12-31')
												: new Date('1900-01-01')
										}
										maxDate={new Date('2099-12-31')}
										className={styles.startDatePicker}
										onChange={val => this.setFormValue('startDate', dateToShortDate(val))}
										value={this.state.dateEntry.startDate}
										popperClassName={styles.datePickerPopper}
									/>
								</div>
							)}
					</section>
					<section className={styles.rangeRecurringStartEndRow}>
						{!!this.state.showParentSelection && (
							<div className={styles.rangeRecurringOffset}>
								<label>Offset from Parent</label>
								<KiInput
									name="offset"
									disabled={!isAdmin || readOnly}
									type={'number'}
									step={1}
									className={styles.repeatInput}
									value={this.state.dateEntry.offset === 0 ? '' : this.state.dateEntry.offset}
									onChange={value => {
										if (this.state.showParentSelection) {
											this.setFormValue('recurrenceType', 'daily');
										}
										this.setFormValue('offset', value);
									}}
								/>
								Days
							</div>
						)}
						{!this.state.showParentSelection &&
							['range', 'recurring'].includes(this.state.dateEntry.dateType) && (
								<div className={styles.definitionTypeSelectWrapper}>
									<label htmlFor="endDateOverride">End Date</label>
									<KiSelect
										name="endDateOverride"
										onChange={opt =>
											this.setFormValue('endDateOverride', _.get(opt, 'value', null))
										}
										isClearable={true}
										placeholder={'None'}
										value={END_DATE_OVERRIDE_OPTIONS_MAP.filter(
											({value}) => value === this.state.dateEntry.endDateOverride
										)}
										isDisabled={!isAdmin || readOnly || !enableStartAndEndOverrides}
										className={styles.startDatePicker}
										options={END_DATE_OVERRIDE_OPTIONS_MAP}
									/>
								</div>
							)}
						{!this.state.showParentSelection &&
							['range', 'recurring'].includes(this.state.dateEntry.dateType) && (
								<div className={styles.rangeRecurringStartDate}>
									<label>End of Schedule</label>
									<KiDatePicker
										name="endDate"
										disabled={!isAdmin || readOnly}
										maxDate={
											this.state.dateEntry.recurrenceType === 'daily'
												? new Date('2039-12-31')
												: new Date('2099-12-31')
										}
										className={styles.startDatePicker}
										onChange={val => {
											this.setFormValue('endDate', dateToShortDate(val));
										}}
										value={this.state.dateEntry.endDate}
										popperClassName={styles.datePickerPopper}
									/>
								</div>
							)}
					</section>
					{!!this.state.showBoundBySelection && (
						<section className={styles.boundByEndRow}>
							<div className={styles.definitionTypeSelectWrapper}>
								<KiSelect
									name="boundByOperator"
									isDisabled={!isAdmin || readOnly}
									options={BOUND_BY_OPTIONS}
									value={BOUND_BY_OPTIONS.find(x => x.value === this.state.dateEntry.boundByOperator)}
									onChange={opt => this.setFormValue('boundByOperator', _.get(opt, 'value'))}
								/>
							</div>
							<div className={styles.parentSelectionSelectorWrapper}>
								<KiSelect
									name="boundByDateId"
									isDisabled={!isAdmin || readOnly}
									options={this.props.datasetDates}
									isOptionDisabled={opt =>
										!!opt.isPortfolioDate ||
										this.props.dateId === opt._id ||
										opt.entries.some(entry => entry.parentId || entry.boundByDateId)
									}
									formatOptionLabel={opt => (
										<DateOptionLabel
											name={opt.name}
											entries={opt.entries}
											isPortfolioDate={opt.isPortfolioDate}
										/>
									)}
									getOptionLabel={opt => opt.name}
									getOptionValue={option => option.groupId}
									value={this.props.datasetDates.filter(
										x => x.groupId === this.state.dateEntry.boundByDateId
									)}
									onChange={opt => this.setFormValue('boundByDateId', (opt && opt.groupId) || null)}
								/>
							</div>
						</section>
					)}
				</div>
				{!isLatestSnapshot && <PreviewResultPane dateConfig={this.state.dateEntry} />}
				{isLatestSnapshot && (
					<aside className={styles.snapshotList}>
						<section className={styles.previewDates}>
							<div className={styles.snapshotTitle}>Available Snapshots</div>
							{this.props.snapshotList.map(s => {
								return (
									<p key={s.snapshotDate} className={styles.snapshotDate}>
										{s.snapshotDate}
									</p>
								);
							})}
						</section>
					</aside>
				)}
			</KiModal2>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		fundingVehicles: state.fundingVehicleList.data.filter(fv => fv.datasetId === ownProps.datasetId),
		datasetDates: state.datasetDates.dates.filter(date => date.datasetId === ownProps.datasetId),
		holidayCalendars: state.calendars.calendars,
		isAdmin: state.user.groups.includes('SystemAdmins'),
		snapshotList: _.get(state, 'datasetList.selected.snapshots', []).sort(
			(a, b) => (a.snapshotDate < b.snapshotDate ? 1 : -1)
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	createDate: date => dispatch(createDate(ownProps.datasetId, date)),
	createEntry: dateEntry => dispatch(createEntry(ownProps.datasetId, ownProps.dateId, dateEntry)),
	updateEntry: dateEntry => dispatch(updateEntry(ownProps.datasetId, ownProps.dateId, dateEntry._id, dateEntry)),
	showSnackbar: msg => dispatch(showSnackbar(msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateDetailsForm);
