import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _defaultsDeep from 'lodash/defaultsDeep';
import _cloneDeep from 'lodash/cloneDeep';

import KiButton from 'components/KiButton';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import styles from './cardForm.theme.scss';
import {checkNameValid} from '../KiDataCardEditorUtils';
import {fetchBookmark} from 'api/debtExplorerApi';
import debtUtils from 'ki-common/utils/debtUtils';
import {KiCreatable} from '../../KiSelect';
import KiProgressBar from 'components/KiProgressBar';

export class DebtCardForm extends Component {
	static propTypes = {
		existingTags: PropTypes.array.isRequired,
		card: PropTypes.object,
		setSavedStatus: PropTypes.func,
		saveCard: PropTypes.func.isRequired,
		onFlyoutClose: PropTypes.func,
		onDialogClose: PropTypes.func,
		mode: PropTypes.string,
		deleteCard: PropTypes.func,
		fundingVehicles: PropTypes.array,
		allCards: PropTypes.array,
		scenarioList: PropTypes.array,
		isDeleteDisabled: PropTypes.bool,
		isSaveDisabled: PropTypes.bool,
		reportOutputType: PropTypes.string,
	};

	static defaultProps = {
		card: {},
		existingTags: [],
		fundingVehicles: [],
		allCards: [],
		scenarioList: [],
		isDeleteDisabled: false,
		isSaveDisabled: false,
	};

	//TODO: makes separate comps to not hardwire for tabular card
	state = {
		isLoading: true,
		nameError: null,
		card: {
			name: '',
			tags: [],
			settings: {
				type: 'debtView',
				chartType: 'data',
			},
		},
	};

	componentDidMount() {
		this.setState(
			{
				card: _cloneDeep(this.props.card),
			},
			() => {
				this.setState({
					nameError: checkNameValid(this.props.allCards, this.props.card.name, this.props.card._id),
				});
			}
		);
		const debtBookmarkId = _get(this.props, 'card.settings.debtBookmarkId');
		if (debtBookmarkId) {
			fetchBookmark(debtBookmarkId).then(debtBookmark => {
				this.setState({debtBookmark, isLoading: false});
			});
		} else {
			//forcast cards (which are a type of debt card), require no bookmark
			this.setState({isLoading: false});
		}
	}

	checkNameValid = (name, id) => {
		const match = this.props.allCards.find(c => c.name.trim() === name.trim() && c._id !== id);
		const isEmpty = name.trim().length === 0 ? true : false;
		if (match) {
			this.setState({nameError: 'Name already in use'});
		} else if (isEmpty) {
			this.setState({nameError: "Name can't be blank"});
		} else {
			this.setState({nameError: ''});
		}
	};

	setCardValue = (key, value) => {
		const cardState = Object.assign({}, this.state.card);
		_set(cardState, key, value);
		this.setState({card: cardState});
		this.props.setSavedStatus(false);
	};

	setName = val => {
		this.setState({nameError: checkNameValid(this.props.allCards, val, this.state.card._id)});
		this.setCardValue('name', val);
	};

	saveCard = () => {
		const {card} = this.state;
		// Object.assign blows away settings sub-object
		const saveCardRequest = _defaultsDeep({}, card, {
			datasetId: card.datasetId,
			settings: {
				transpose: _get(card, 'settings.transpose', false),
				fundingVehicle: card.settings.fundingVehicle,
				scenarioId: card.settings.scenarioId,
				cardOutputType: this.props.reportOutputType,
			},
		});
		return this.props.saveCard(saveCardRequest);
	};

	close = () => {
		if (this.props.mode === 'dialog') {
			return this.props.onDialogClose();
		}
		this.props.onFlyoutClose();
	};

	deleteCard = () => {
		this.props.deleteCard();
	};

	fundingVehicleOverrideInvalid = () => {
		return (
			(this.state.debtBookmark?.settings?.viewType === 'waterfall' &&
				this.state.card.settings.fundingVehicle === null) ||
			(this.state.card.settings?.type === 'aggregatedPayment' && !this.state.card.settings.fundingVehicle)
		);
	};

	render() {
		const {card, nameError, debtBookmark, isLoading} = this.state;
		return isLoading ? (
			<KiProgressBar />
		) : (
			<div className="context-sidebar-panel-flex column-selector-form">
				<div>
					{!this.props.mode === 'dialog' && <header className="flyout-panel-title">MANAGE CARDS</header>}
					<section className={this.props.mode === 'dialog' ? styles.cardFormDialog : ''}>
						<div className={styles.column}>
							{debtBookmark && (
								<div className={styles.infoSection}>
									<span className="form-instruction">View Name</span>
									{debtBookmark.name}
								</div>
							)}
							<div className={styles.formInputSection}>
								<KiInput
									label="Name"
									value={card.name}
									onChange={val => this.setName(val)}
									error={nameError}
								/>
							</div>
							<div className={styles.formSelectSection}>
								<span className="form-instruction">Display Type:</span>
								<Select
									classNamePrefix="aut-select"
									value={{
										label: 'Data',
										value: 'data',
										type: 'data',
									}}
									isClearable={false}
									isSearchable={false}
									options={[
										{
											label: 'Data',
											value: 'data',
											type: 'data',
										},
									]}
									isDisabled={this.props.reportOutputType == 'csvExtract'}
								/>
							</div>
							<div className={styles.checkboxContainer}>
								<div className="calculation-form-section debt-date-transpose">
									<KiCheckbox
										name="Transpose"
										checked={_get(card, 'settings.transpose', false)}
										label="Transpose"
										disabled={
											card.settings.chartType !== 'data' ||
											['projections', 'aggregatedPayment'].includes(
												_get(card, 'settings.type')
											) ||
											this.props.reportOutputType == 'csvExtract'
										}
										onChange={val => this.setCardValue('settings.transpose', val)}
									/>
								</div>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.formSelectSection}>
								<span className="form-instruction">Tags:</span>
								<KiCreatable
									isMulti={true}
									options={this.props.existingTags.map(t => ({
										value: t,
										label: t,
									}))}
									value={_get(card, 'tags', []).map(t => ({value: t, label: t}))}
									onChange={val => this.setCardValue('tags', val.map(t => t.value))}
									placeholder="Add some tags"
									noResultsText={false}
								/>
							</div>
							<div className={styles.formSelectSection}>
								<span className="form-instruction">Funding Vehicle Override:</span>
								<Select
									classNamePrefix="aut-select"
									value={this.props.fundingVehicles.find(f => f._id === card.settings.fundingVehicle)}
									isClearable={true}
									placeholder={'Report Parameter'}
									options={this.props.fundingVehicles}
									getOptionLabel={option => option.name}
									isDisabled={_get(card, 'settings.type') === 'projections'}
									getOptionValue={option => option._id}
									onChange={val => {
										this.setCardValue('settings.fundingVehicle', _get(val, '_id', null));
									}}
								/>
								<div style={{height: '1.5rem'}}>
									<span className="dataCardError">
										{this.fundingVehicleOverrideInvalid() ? `Required for waterfall views` : ``}
									</span>
								</div>
							</div>
							<div className={styles.formSelectSection}>
								<span className="form-instruction">Scenario Context Override:</span>
								<Select
									classNamePrefix="aut-select"
									value={debtUtils
										.getAvailableScenarioContextOptions(
											this.props.scenarioList,
											_get(this.state, 'debtBookmark.settings.cohortColumn', ''),
											_get(this.state, 'debtBookmark.settings.viewType', '')
										)
										.find(option => option.value === card.settings.scenarioId)}
									isClearable={true}
									placeholder={'Report Parameter'}
									isDisabled={_get(card, 'settings.type') === 'projections'}
									isOptionDisabled={option => {
										if (option && option.value === 'lastCommitted') {
											const {viewType, cohortColumn} = _get(
												this.state,
												'debtBookmark.settings',
												{}
											);
											return cohortColumn !== 'pool' || viewType !== 'transaction';
										}
										return false;
									}}
									options={debtUtils.getAvailableScenarioContextOptions(
										this.props.scenarioList,
										_get(this.state, 'debtBookmark.settings.cohortColumn', ''),
										_get(this.state, 'debtBookmark.settings.viewType', '')
									)}
									getOptionLabel={option => option.label}
									getOptionValue={option => option.value}
									onChange={val => {
										this.setCardValue('settings.scenarioId', _get(val, 'value', null));
									}}
								/>
							</div>
						</div>
					</section>
				</div>
				<div>
					{this.props.reportOutputType == 'csvExtract' && (
						<p>NOTE: Some options have been disabled for Extract output type</p>
					)}
				</div>
				<section className="inline-column-form-buttons">
					{this.props.mode === 'dialog' && (
						<div className={styles.deleteContainer}>
							<KiButton
								disabled={!this.props.isDeleteDisabled && this.props.deleteCard ? false : true}
								flat
								primary
								onClick={() => this.deleteCard()}
								label="Delete"
							/>
						</div>
					)}
					<KiButton flat primary onClick={() => this.close()} label="Cancel" />
					<KiButton
						raised
						primary
						disabled={
							!!this.state.nameError || this.props.isSaveDisabled || this.fundingVehicleOverrideInvalid()
						}
						onClick={() => this.saveCard()}
						label="Save"
					/>
				</section>
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(DebtCardForm);
