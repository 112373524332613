import * as actionTypes from './actionTypes';
import {datasetDatesApi} from 'api';
import _get from 'lodash/get';

export const fetchDates = (datasetId, includeSystemDates, includePortfolioDates) => dispatch => {
	dispatch({type: actionTypes.DD_FETCH_DATES, status: 'pending'});
	return datasetDatesApi
		.fetchDates(datasetId, includeSystemDates, includePortfolioDates)
		.then(data => {
			dispatch({type: actionTypes.DD_FETCH_DATES, status: 'success', data: data});
			return data;
		})
		.catch(error => dispatch({type: actionTypes.DD_FETCH_DATES, status: 'error', error: error}));
};

export const fetchMappedDates = datasetId => dispatch => {
	return datasetDatesApi.fetchMappedDates(datasetId).then(data => {
		dispatch({type: actionTypes.DD_FETCH_MAPPED_DATES, status: 'success', data: data});
	});
};

export const createDate = (datasetId, date) => dispatch => {
	dispatch({type: actionTypes.DD_CREATE_DATE, status: 'pending'});
	return datasetDatesApi
		.createDate(datasetId, date)
		.then(data => {
			dispatch({type: actionTypes.DD_CREATE_DATE, status: 'success', data: data});
			return data;
		})
		.catch(error => dispatch({type: actionTypes.DD_CREATE_DATE, status: 'error', error: error}));
};

export const updateDate = (datasetId, id, changes) => dispatch => {
	dispatch({type: actionTypes.DD_UPDATE_DATE, status: 'pending'});
	return datasetDatesApi
		.updateDate(datasetId, id, changes)
		.then(data => {
			dispatch({type: actionTypes.DD_UPDATE_DATE, status: 'success', data: data});
			return data;
		})
		.catch(error => dispatch({type: actionTypes.DD_UPDATE_DATE, status: 'error', error: error}));
};

export const deleteDate = (datasetId, id) => dispatch => {
	dispatch({type: actionTypes.DD_DELETE_DATE, status: 'pending'});
	return datasetDatesApi
		.deleteDate(datasetId, id)
		.then(response => {
			const isUsed = _get(response, 'isUsed', false);
			if (isUsed) {
				dispatch({type: actionTypes.DD_DELETE_DATE, status: 'inUse', data: response});
				return response;
			} else {
				dispatch({type: actionTypes.DD_DELETE_DATE, status: 'success', data: id});
				return id;
			}
		})
		.catch(error => {
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Error occurred deleting date.'}});
			dispatch({type: actionTypes.DD_DELETE_DATE, status: 'error', error: error});
			return Promise.reject(error);
		});
};

// Dataset Date entries
export const createEntry = (datasetId, dateId, entry) => dispatch => {
	dispatch({type: actionTypes.DD_CREATE_DATE_ENTRY, status: 'pending'});
	return datasetDatesApi
		.createEntry(datasetId, dateId, entry)
		.then(data => {
			dispatch({type: actionTypes.DD_CREATE_DATE_ENTRY, status: 'success', data: data});
			dispatch(fetchDates(datasetId, false, true));
			return data;
		})
		.catch(error => dispatch({type: actionTypes.DD_CREATE_DATE_ENTRY, status: 'error', error: error}));
};

export const updateEntry = (datasetId, dateId, entryId, changes) => dispatch => {
	dispatch({type: actionTypes.DD_UPDATE_DATE_ENTRY, status: 'pending'});
	return datasetDatesApi
		.updateEntry(datasetId, dateId, entryId, changes)
		.then(data => {
			dispatch({type: actionTypes.DD_UPDATE_DATE_ENTRY, status: 'success', data: data});
			dispatch(fetchDates(datasetId, false, true));
			return data;
		})
		.catch(error => dispatch({type: actionTypes.DD_UPDATE_DATE_ENTRY, status: 'error', error: error}));
};

export const deleteEntry = (datasetId, dateId, entryId) => dispatch => {
	dispatch({type: actionTypes.DD_DELETE_DATE_ENTRY, status: 'pending'});
	return datasetDatesApi
		.deleteEntry(datasetId, dateId, entryId)
		.then(response => {
			dispatch({type: actionTypes.DD_DELETE_DATE_ENTRY, status: 'success', data: entryId});
			dispatch(fetchDates(datasetId, false, true));
			return response;
		})
		.catch(error => {
			dispatch({type: 'SNACKBAR_SHOW', options: {msg: 'Error occurred deleting date entry.'}});
			dispatch({type: actionTypes.DD_DELETE_DATE_ENTRY, status: 'error', error: error});
			return Promise.reject(error);
		});
};

export default {
	fetchDates,
	createDate,
	updateDate,
	deleteDate,
	createEntry,
	updateEntry,
	deleteEntry,
	fetchMappedDates,
};
