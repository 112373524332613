// Globals
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import options from 'ki-common/options';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import _ from 'lodash';

// Project imports
import KiSelect from 'components/KiSelect';
import KiInput from 'components/KiInput';
import KiDatePicker from 'components/KiDatePicker';
import ColumnValue from './ColumnValue';
import FunctionValue from './FunctionValue';
import {useMergedState} from 'utils/customHooks';

// Local imports
import FormulaBuilderContext from '../../FormulaBuilderContext';
//import CaseThenElseBlockContext from '../CaseThenElseBlockContext';
import styles from './ValueComponent.theme.scss';
import valueOptions from './valueOptions';
import {ratingsApi} from 'api';

/*
TODO
subAccountColumn
child columns require the user to select a schema and an aggregation function (SUM, AVG, etc) for use
child columns are only uses in non-debt conditional builders
for child columns to work they must be selected in the side bar and passed into the component
*/

/*
					MAPPINGS
Value 				Formula 			Column
Type 				Type 				Data Type
---------- 			----------			----------
column 				column 				MATCHED
function 			min, max			MATCHED
string 				constant 			string
numeric 			constant 			numeric
date_long 			constant 			date_long
boolean 			constant 			boolean
subAccountColum 	subAccountColumn	MATCHED
*/

// Change entry type to a valueComponentType
const valueTypeFromFormulaEntry = entry => {
	const {type, value, dataType} = entry;
	switch (type) {
		case 'min':
		case 'max':
			return 'function';
		case 'constant': {
			if (dataType) {
				return dataType;
			}
			if (value === 'true' || value === 'false') {
				return 'boolean';
			}
			const regex = /^\d{4}-\d{2}(-\d{2}){0,1}$/;
			if (value.match(regex)) {
				return 'date_long';
			}
			if (!isNaN(parseFloat(value))) {
				return 'numeric';
			}
			return 'string';
		}
		case 'column':
		case 'subAccountColumn':
		default:
			return type;
	}
};

/**
 * @formulaEntry {object}	Must have type and value
 * @onChange {function}		Accepts back 2 parameters (value, formulaType)
 * @valueDataType {string}	Matches data type. One of ('string','numeric','date_long','boolean','')
 */
function ValueComponent({
	id, // eslint-disable-line
	formulaEntry,
	onChange,
	valueDataType,
	lockValueTypes,
	isConstantAllowed,
	agencyId,
}) {
	// Context State
	const formulaBuilderContext = useContext(FormulaBuilderContext);
	const isDebtFormula = formulaBuilderContext.isDebtFormula;
	const isWaterfall = formulaBuilderContext.isWaterfall;
	// ===========
	// Local State
	// ===========

	const [ratingOptions, setRatingOptions] = useState([]);

	// The locally up to date copy of the entry with modifications
	const [localEntry, setLocalEntry, replaceLocalEntry] = useMergedState(formulaEntry);
	useEffect(
		() => {
			// // Only update if the forulaEntry has changed
			// if (!_isEqual(localEntry, formulaEntry)) {

			// }
			replaceLocalEntry(formulaEntry);
		},
		[formulaEntry]
	);

	// List of all the types available for this value component
	const getValueTypeOptions = () => {
		// Shortcut the logic and return this specified set of options
		if (lockValueTypes.length > 0) {
			return valueOptions.valueTypeOptions.filter(opt => lockValueTypes.includes(opt.value));
		}

		// Narrow down by form type (debt vs waterfall)
		const optionsToReturn = valueOptions.valueTypeOptions.filter(opt => {
			return (isDebtFormula && opt.isDebt === isDebtFormula) || (isWaterfall && opt.isWaterfall === isWaterfall);
		});

		// Build an exclusion list
		// Start with all the constants
		let excludeList = ['string', 'numeric', 'date_long', 'boolean', 'posInt'];

		// Narrow down by valueDataType
		if (isConstantAllowed) {
			excludeList = excludeList.filter(type => type !== valueDataType);
		}

		// If not numeric then function should be excluded
		if (valueDataType && valueDataType !== 'numeric') {
			excludeList.push('function');
		}

		// Integer special rule
		if (valueDataType && valueDataType === 'posInt' && isConstantAllowed) {
			excludeList.push('column'); // No columns on the right
		}

		// Rating special rule
		if (valueDataType && valueDataType === 'rating' && isConstantAllowed) {
			excludeList.push('column'); // No columns on the right
		} else {
			excludeList.push('rating'); // no one else gets it
		}

		return optionsToReturn.filter(opt => !excludeList.includes(opt.value));
	};
	const [valueTypeOptions, setValueTypeOptions] = useState(getValueTypeOptions());

	const getRatingOptionsByAgency = async () => {
		try {
			const list = await ratingsApi.fetchRatingsOptionsByAgency(agencyId);
			setRatingOptions(list);
		} catch (err) {
			throw Error('Error loading ratings for agency');
		}
	};

	// Update the list of possible types for the value
	useEffect(
		() => {
			setValueTypeOptions(getValueTypeOptions());
		},
		[valueDataType]
	);

	useEffect(
		() => {
			if (valueDataType === 'rating') {
				getRatingOptionsByAgency();
			}
		},
		[agencyId]
	);

	const [valueComponentType, setValueComponentType] = useState('');
	// The currently selected type of this value component
	const getValueComponentType = () => {
		const currentValueType = valueTypeFromFormulaEntry(localEntry);
		const option = valueTypeOptions.find(opt => opt.value === currentValueType);
		return _.get(option, 'value', valueComponentType);
	};
	// Update the currently selected type
	useEffect(
		() => {
			setValueComponentType(getValueComponentType());
		},
		[valueDataType]
	);

	// =========
	// Main Code
	// =========

	// Value, Offset, and Param are called directly here. Changes passed up to the formula
	// will be passed back in as the new state for this component and replace the localEntry
	const updateFormulaEntry = (field, newValue) => {
		// LOGGING console.log(`updateFormulaEntry field: ${field}, newValue: ${newValue}`);
		const newEntry = _.cloneDeep(localEntry);
		// Edge case for collateral selections where value and param are set at the same time
		if (field === 'param' && localEntry.columnType === 'collateral') {
			newEntry.value = newValue;
		}
		newEntry[field] = newValue;
		onChange(newEntry);
	};

	// This is used when changing something that should not update the formula
	// such as a function type, specific fee, specific tranche, etc
	const updateLocalEntry = (field, newValue) => {
		// LOGGING console.log(`updateLocalEntry field: ${field}, newValue: ${newValue}`);
		// If the user has already selected a field and wants to change the entity
		if (localEntry.value) {
			onChange(Object.assign({}, localEntry, {[field]: newValue}));
		} else {
			setLocalEntry({[field]: newValue});
		}
	};

	const updateFormulaColumnEntry = newColumn => {
		//LOGGING console.log(`updateFormulaColumnEntry newColumn: ${JSON.stringify(newColumn, null, 2)}`);
		const newEntry = _.cloneDeep(localEntry);
		newEntry.value = newColumn._id;
		newEntry.columnType = newColumn.columnType;
		if (_.has(newColumn, 'entityId')) {
			newEntry.entityId = newColumn.entityId;
		} else {
			delete newEntry.entityId;
		}
		if (!isWaterfall && newEntry.columnType === 'aggregate') {
			newEntry.offset = localEntry.offset || 0;
		} else {
			delete newEntry.offset;
		}
		// LOGGING console.log('updateFormulaColumnEntry newEntry', JSON.stringify(newEntry, null, 2));
		onChange(newEntry);
	};

	// Main selector for the value was set to any non-column option OR column selected was cleared
	const clearFormulaColumnEntry = newValueType => {
		// LOGGING console.log(`clearFormulaColumnEntry columnType: ${columnType}`);
		const newEntry = _.cloneDeep(localEntry);
		newEntry.type = 'column';
		newEntry.value = '';
		newEntry.columnType = '';
		delete newEntry.entityId;
		if (!isWaterfall && newEntry.columnType === 'aggregate') {
			newEntry.offset = localEntry.offset || 0;
		} else {
			delete newEntry.offset;
		}
		onChange(newEntry);
		setValueComponentType(newValueType);
	};

	// Main selector for the value was set to any non-column option
	const resetFormulaEntryType = (newType, newValueType) => {
		// LOGGING console.log(`resetFormulaEntryType newType: ${newType}`);
		const newEntry = _.cloneDeep(localEntry);
		newEntry.type = newType;
		newEntry.value = '';
		newEntry.dataType = newType === 'constant' ? newValueType : '';
		delete newEntry.columnType;
		delete newEntry.offset;
		onChange(newEntry);
		// Delay call long enough for entry update to occur preventing column picker
		// from trying to open with an invalid configuration
		setTimeout(() => {
			setValueComponentType(newValueType);
		}, 250);
	};

	// Update the type of the entry and if it is a column type updated the columnType as well
	const updateLocalValueType = newValueType => {
		// LOGGING console.log(`updateLocalValueType newValueType: ${newValueType}`);
		switch (newValueType) {
			case 'column':
			case 'subAccountColumn':
			case 'fundingVehicle':
			case 'debtColumn':
			case 'collateral':
			case 'tranche':
			case 'fee':
			case 'creditSupport':
			case 'trigger':
				clearFormulaColumnEntry(newValueType);
				break;
			case 'numeric':
			case 'string':
			case 'date_long':
			case 'boolean':
			case 'posInt':
				resetFormulaEntryType('constant', newValueType);
				break;
			case 'function':
				resetFormulaEntryType('min', newValueType); // Default to min function
				break;
			case 'rating':
				resetFormulaEntryType('constant', newValueType);
				break;
			default:
				resetFormulaEntryType('', newValueType);
		}
	};

	const getValueFormElement = () => {
		// LOGGING console.log('getValueFormElement - valueComponentType', valueComponentType);
		switch (valueComponentType) {
			case 'column':
			case 'subAccountColumn':
			case 'fundingVehicle':
			case 'debtColumn':
			case 'collateral':
			case 'tranche':
			case 'fee':
			case 'creditSupport':
			case 'trigger':
				return (
					<ColumnValue
						entry={localEntry}
						valueDataType={valueDataType}
						onValueChange={selectedColumn => {
							if (selectedColumn) {
								updateFormulaColumnEntry(selectedColumn);
							} else {
								clearFormulaColumnEntry();
							}
						}}
						onOffsetChange={val => updateFormulaEntry('offset', val || 0)}
					/>
				);
			case 'function':
				return (
					<FunctionValue
						entry={localEntry}
						onFunctionChange={val => updateLocalEntry('type', val)}
						onValueChange={newValue => updateFormulaEntry('value', newValue)}
					/>
				);
			case 'numeric':
				return (
					<div className={styles.valueFormElement}>
						<KiInput
							label="Numeric Constant"
							value={localEntry.value}
							isNumberMasked={true}
							// An undefined value makes the input uncontrolled
							onBlur={val => updateFormulaEntry('value', val.target.value || '')}
						/>
					</div>
				);
			case 'posInt':
				return (
					<div className={styles.valueFormElement}>
						<KiInput
							label="Integer Constant"
							value={localEntry.value}
							isNumberMasked={true}
							maskConfig={{
								allowDecimal: false,
								decimalLimit: 0,
								allowNegative: false,
							}}
							// An undefined value makes the input uncontrolled
							onBlur={val => updateFormulaEntry('value', val.target.value || '')}
						/>
					</div>
				);
			case 'string':
				return (
					<div className={styles.valueFormElement}>
						<KiInput
							label="Text Constant"
							value={localEntry.value}
							// An undefined value makes the input uncontrolled
							onChange={val => updateLocalEntry('value', val || '')}
							onBlur={val => updateFormulaEntry('value', val.target.value || '')}
						/>
					</div>
				);
			case 'date_long':
				return (
					<div className={styles.valueFormElement}>
						<KiDatePicker
							value={localEntry.value}
							onChange={val => updateFormulaEntry('value', dateToShortDate(val))}
						/>
					</div>
				);
			case 'boolean':
				return (
					<div className={styles.valueFormElement}>
						<div className={styles.formulaComponent}>
							<KiSelect
								value={options.booleanConstants.find(opt => opt.value === localEntry.value)}
								options={options.booleanConstants}
								onChange={selected => updateFormulaEntry('value', selected.value)}
							/>
						</div>
					</div>
				);
			case 'rating':
				return (
					<div className={styles.valueFormElement}>
						<div className={styles.formulaComponent}>
							<KiSelect
								value={ratingOptions.find(opt => opt.scale === localEntry.value)}
								getOptionLabel={option => option.value}
								getOptionValue={option => option.scale}
								options={ratingOptions}
								onChange={selected => updateFormulaEntry('value', selected.scale)}
							/>
						</div>
					</div>
				);
			// TODO add this when ported to regular formulas
			// case 'subAccountColumn':
			default:
				return <div />;
		}
	};

	return (
		<div className={styles.value}>
			<div className={styles.valueType}>
				<KiSelect
					value={valueTypeOptions.find(opt => opt.value === valueComponentType)}
					isClearable={false}
					options={valueTypeOptions}
					onChange={selected => updateLocalValueType(selected.value)}
				/>
			</div>
			{getValueFormElement()}
		</div>
	);
}

ValueComponent.propTypes = {
	id: PropTypes.string,
	formulaEntry: PropTypes.shape({
		id: PropTypes.string,
		element: PropTypes.string,
		type: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		columnType: PropTypes.string,
		offset: PropTypes.number,
		param: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	valueDataType: PropTypes.oneOf(['string', 'numeric', 'date_long', 'boolean', 'posInt', 'rating', '']),
	lockValueTypes: PropTypes.arrayOf(PropTypes.oneOf(valueOptions.valueTypeOptions.map(opt => opt.value))),
	isConstantAllowed: PropTypes.bool,
	agencyId: PropTypes.string,
};

ValueComponent.defaultProps = {
	isDebtFormula: false,
	formulaEntry: {},
	onChange: () => {
		return false;
	},
	valueDataType: '',
	lockValueTypes: [],
	isConstantAllowed: false,
	agencyId: '',
};

export default ValueComponent;
