import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {version} from '../../../package.json';
import {systemApi} from 'api';

export class SystemInfo extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
	};

	state = {
		versions: {
			kiCommonVer: 'N/A',
			kiMongoServiceVer: 'N/A',
			kiNodeApiVer: 'N/A',
			kiNodeApiBackendVer: 'N/A',
			kiReportServiceVer: 'N/A',
			kiSolverServiceVer: 'N/A',
			kiNodeReportsVer: 'N/A',
			kiWebsite: version,
		},
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - System Information`;
		return this.loadPageData();
	}

	loadPageData = () => {
		return systemApi.fetchVersions().then(versions => {
			versions.kiWebsite = version;
			this.setState({versions});
		});
	};

	render() {
		const {versions} = this.state;

		return (
			<div className="system-info">
				<section className="versions">
					<table>
						<tr>
							<th>Component</th>
							<th>Version</th>
						</tr>
						<tr>
							<td>Ki Libraries (ki-common)</td>
							<td>{versions.kiCommonVer}</td>
						</tr>
						<tr>
							<td>Data Layer (ki-data-layer)</td>
							<td>{versions.kiDataLayer}</td>
						</tr>
						<tr>
							<td>Report Service (ki-report-service)</td>
							<td>{versions.kiReportServiceVer}</td>
						</tr>
						<tr>
							<td>Server (ki-node-api)</td>
							<td>{versions.kiNodeApiVer}</td>
						</tr>
						<tr>
							<td>Report Server (ki-node-reports)</td>
							<td>{versions.kiNodeReportsVer}</td>
						</tr>
						<tr>
							<td>Website (ki-website)</td>
							<td>{versions.kiWebsite}</td>
						</tr>
					</table>
				</section>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	user: state.user,
});

export default connect(mapStateToProps, {})(SystemInfo);
