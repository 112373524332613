import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from '../VectorForm.theme.scss';
import KiButton from '../../../../../components/KiButton';

const PeriodPasteForm = ({onChange, onAdd, onCancel, error}) => {
	const [textareaValue, setTextareaValue] = useState('');

	const onTextChange = text => {
		setTextareaValue(text);
		onChange(text);
	};

	const onAddClick = () => {
		setTextareaValue('');
		onAdd();
	};

	return (
		<>
			<textarea
				className={styles.pasteArea}
				onChange={e => onTextChange(e.target.value)}
				value={textareaValue}
				rows="4"
			/>
			<div className={styles.errorMessage}>{error}</div>
			<div className={styles.info}>
				<i
					className="material-icons"
					title="Paste month,value per line. Example:&#010;1,5&#010;3,5.25"
				>
					info_circle
				</i>
			</div>
			<div className="inline-column-form-buttons">
				<KiButton flat mini onClick={onCancel}>
					Cancel
				</KiButton>
				<KiButton primary mini onClick={onAddClick} disabled={!!error || textareaValue === ''}>
					Add
				</KiButton>
			</div>
		</>
	);
};

PeriodPasteForm.propTypes = {
	onChange: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	error: PropTypes.string,
};

export default PeriodPasteForm;
