import {accountsApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
import analytics from 'analytics';
import * as actionTypes from './actionTypes';
// import socket from '../../socket';

// socket.on('account added', fetchAccounts); // CALLING THIS WAY REQUIRES THE FUNCTION BE DEFINED BEFORE THIS LINE
// socket.on('account added', data => (dispatch, getState) => dispatch(fetchAccounts()));
// socket.on('account added', () => dispatch => {
// 	dispatch(fetchAccounts());
// });

const openModal = (account = null) => dispatch => {
	analytics.sendModalView('account-edit');
	dispatch({type: actionTypes.ACCOUNT_MODAL_OPEN, selected: account});
};

const closeModal = () => dispatch => {
	dispatch({type: actionTypes.ACCOUNT_MODAL_CLOSE});
};

// Accounts
const fetchAccounts = () => dispatch => {
	dispatch({type: actionTypes.ACCOUNTS_FETCH, status: 'pending'});

	return accountsApi
		.fetchAccounts()
		.then((accounts = []) => dispatch({type: actionTypes.ACCOUNTS_FETCH, status: 'success', accounts: accounts}))
		.catch(() => dispatch({type: actionTypes.ACCOUNTS_FETCH, status: 'error'}));
};

const parseValidationError = error => {
	let toReturn = null;
	if (error && error.message) {
		try {
			toReturn = JSON.parse(error.message);
		} catch (e) {} //eslint-disable-line
	}
	return toReturn;
};

const addAccount = (account = null) => dispatch => {
	dispatch({type: actionTypes.ACCOUNTS_ADD, status: 'pending'});
	account.isActive = false;
	return accountsApi
		.createAccount(account)
		.then(account => {
			dispatch(showSnackbar(`Created ${account.userId} successfully`));
			dispatch(closeModal());
			dispatch(fetchAccounts());
			return account;
		})
		.catch(err => {
			const validationError = parseValidationError(err);
			if (!validationError) {
				dispatch(showSnackbar(err.message));
			}
			return dispatch({type: actionTypes.ACCOUNTS_ADD, status: 'error', validationError});
		});
};

const updateAccount = account => dispatch => {
	dispatch({type: actionTypes.ACCOUNTS_UPDATE, status: 'pending'});

	return accountsApi
		.updateAccount(account)
		.then(updatedAccount => {
			dispatch(showSnackbar(`Updated ${updatedAccount.userId} successfully`));
			dispatch(closeModal());
			dispatch(fetchAccounts());
			return updatedAccount;
		})
		.catch(err => {
			const validationError = parseValidationError(err);
			if (!validationError) {
				dispatch(showSnackbar(err.message));
			}
			return dispatch({type: actionTypes.ACCOUNTS_UPDATE, status: 'error', validationError});
		});
};

const removeAccounts = (userIds = []) => dispatch => {
	dispatch({type: actionTypes.ACCOUNTS_REMOVE_MANY, status: 'pending', userIds: userIds});

	return accountsApi
		.removeAccounts(userIds)
		.then(response => {
			dispatch(showSnackbar(`Account${response.ids.length > 1 ? 's' : ''} successfully deleted`));
			return dispatch({type: actionTypes.ACCOUNTS_REMOVE_MANY, status: 'success', removedIds: response.ids});
		})
		.catch(() => dispatch({type: actionTypes.ACCOUNTS_REMOVE_MANY, status: 'error'}));
};

const changePassword = (_id, curPassword, newPassword) => dispatch => {
	return accountsApi
		.changePassword(_id, curPassword, newPassword)
		.then(() => {
			dispatch(showSnackbar('Changed password successfully'));
		})
		.catch(err => {
			dispatch(showSnackbar(err.message));
		});
};

const deactivateAccounts = (userIds = []) => dispatch => {
	dispatch({type: actionTypes.ACCOUNT_DEACTIVATE, status: 'pending', userIds});

	return accountsApi
		.deactivateAccounts(userIds)
		.then(response => {
			dispatch(showSnackbar(`Account${response.ids.length > 1 ? 's' : ''} successfully deactivated`));
			return dispatch({type: actionTypes.ACCOUNT_DEACTIVATE, status: 'success'});
		})
		.catch(() => dispatch({type: actionTypes.ACCOUNT_DEACTIVATE, status: 'error'}));
};

const activateAccounts = (userIds = []) => dispatch => {
	dispatch({type: actionTypes.ACCOUNT_ACTIVATE, status: 'pending', userIds});

	return accountsApi
		.activateAccounts(userIds)
		.then(response => {
			dispatch(showSnackbar(`Account${response.ids.length > 1 ? 's' : ''} successfully activated`));
			dispatch(fetchAccounts());
			return dispatch({type: actionTypes.ACCOUNT_ACTIVATE, status: 'success'});
		})
		.catch(() => dispatch({type: actionTypes.ACCOUNT_ACTIVATE, status: 'error'}));
};

const generateApiKey = userId => dispatch => {
	return accountsApi
		.generateApiKey(userId)
		.then(updatedAccount => {
			dispatch(fetchAccounts());
			return updatedAccount;
		})
		.catch(err => {
			if (!err.message) {
				dispatch(showSnackbar(err.message));
			}
		});
};

export {
	fetchAccounts,
	addAccount,
	updateAccount,
	removeAccounts,
	openModal,
	closeModal,
	changePassword,
	deactivateAccounts,
	activateAccounts,
	generateApiKey,
};
