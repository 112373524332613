import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import './SelectorWithAdd.scss';
import {debounce} from 'lodash';

/*
options must be passed in the following format
var options = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' }
];
 */

const sortOptionsAsc = (a, b) => {
	if (a.label.toLowerCase() < b.label.toLowerCase()) {
		return -1;
	}
	if (a.label.toLowerCase() > b.label.toLowerCase()) {
		return 1;
	}
	return 0;
};

export const SelectorWithAdd = props => {
	const {options, onChangeFunc, newOptionValidation, newOptionErrorMessage} = props;
	const [isNewOptionValid, setIsNewOptionValid] = useState(true);

	const debounceSetIsNewOptionValid = useCallback(debounce(setIsNewOptionValid, 500), []);

	switch (props.sortOrder) {
		case 'asc':
			options.sort(sortOptionsAsc);
			break;
		case 'desc':
			options.sort(sortOptionsAsc);
			options.reverse();
			break;
		default:
	}
	return (
		<>
			<CreatableSelect
				classNamePrefix="aut-select"
				className={props.className || 'selector'}
				options={options}
				onChange={option => {
					onChangeFunc(option.value);
				}}
				disabled={props.disabled || false}
				maxMenuHeight={250}
				value={options.find(o => o.value === props.value)}
				{...(newOptionValidation
					? {
							isValidNewOption: value => {
								const isValid = newOptionValidation(value);
								debounceSetIsNewOptionValid(isValid);
								return isValid;
							},
					  }
					: {})}
				menuPortalTarget={document.body}
				styles={{menuPortal: base => ({...base, zIndex: 9999})}}
			/>
			{!isNewOptionValid && <div className="dataset-name-error">{newOptionErrorMessage}</div>}
		</>
	);
};

SelectorWithAdd.propTypes = {
	options: PropTypes.array.isRequired,
	onChangeFunc: PropTypes.func.isRequired, //
	className: PropTypes.string,
	sortOrder: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	newOptionValidation: PropTypes.func,
	newOptionErrorMessage: PropTypes.string,
};

SelectorWithAdd.defaultProps = {
	autosize: false,
};

export default SelectorWithAdd;
