import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const createJob = (datasetId, onlyFAonLastSnapshot, snapshots) => {
	return fetch(`${apiUrl}/snapshotReprocessJobs`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId,
			onlyFAonLastSnapshot,
			snapshots,
		}),
	}).then(_handleResponse);
};

export default {
	createJob,
};
