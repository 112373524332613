import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import KiAppBar from 'components/KiAppBar';
import moment from 'moment';
import {fetchNotificationsForCurrentUser} from './actions';
import './notificationList.scss';

export class NotificationList extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		notificationList: PropTypes.array,
		fetchNotificationsForCurrentUser: PropTypes.func,
		history: PropTypes.object.isRequired,
	};

	componentDidMount() {
		document.title = `${this.props.app.kiVersion} - Notifications`;
		this.props.fetchNotificationsForCurrentUser();
	}

	handleLinkClick = (event, notification) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.history.push(notification.link);
	};

	render() {
		return (
			<section className="notification-list">
				<KiAppBar title="Notifications" />
				<div className={this.props.notificationList.length ? 'hide' : 'ki-panel no-list-items-container'}>
					<div>No notifications found</div>
				</div>
				<div className={this.props.notificationList.length ? 'ki-panel' : 'hidden'}>
					<div className="notification-list-inner">
						<table width="100%">
							<tbody>
								{this.props.notificationList.map((notification, idx) => {
									const timestamp = moment(notification.createDate);
									return (
										<tr
											key={idx}
											className="notification-list-item clickable"
											onClick={e => this.handleLinkClick(e, notification)}
											title="Click to view the Pool Maintenance window."
										>
											<td width="70%">{notification.message}</td>
											<td width="15%">{notification.datasetName}</td>
											<td width="15%">{timestamp.fromNow()}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	notificationList: state.notificationList.data,
});

/*
	TODO tech debt - add action and reducer tests
	2017-08-10 mwurzberger
 */

export default connect(mapStateToProps, {fetchNotificationsForCurrentUser})(NotificationList);
