import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './fees.theme.scss';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames/bind';
import KiMenuItem from 'components/KiMenu/KiMenuItem';
import KiMenu from 'components/KiMenu';
import KiDnDList from 'components/KiDnDList';
import FeesForm, {FEE_TYPES} from './feesForm';
import {addFundingVehicleSetting, updateFundingVehicleSetting} from '../actions';
const cx = classNames.bind(styles);
import '../index.scss';
import FeesDeleteModal from './feesDeleteModal';

export class Fees extends Component {
	static propTypes = {
		fees: PropTypes.array,
		history: PropTypes.object,
		match: PropTypes.object,
		dataset: PropTypes.object,
		fundingVehicle: PropTypes.object,
		waterfallCalcColumns: PropTypes.arrayOf(PropTypes.object),
		updateFundingVehicleSetting: PropTypes.func,
		addFundingVehicleSetting: PropTypes.func,
	};

	static defaultProps = {
		fees: [],
		waterfallCalcColumns: [],
		match: {
			params: {},
		},
	};

	state = {
		fees: [],
	};

	componentDidMount() {
		this.setState({
			fees: _.orderBy(_.cloneDeep(this.props.fees), ['type', 'feeNumber'], ['asc', 'asc']).filter(
				fee =>
					this.props.fundingVehicle &&
					this.props.fundingVehicle._id &&
					[this.props.fundingVehicle._id, 'all'].includes(fee.fundingVehicleId)
			),
		});
	}

	componentDidUpdate() {
		const stateFees = this.state.fees;
		const propFees = _.orderBy(_.cloneDeep(this.props.fees), ['type', 'feeNumber'], ['asc', 'asc']).filter(
			fee =>
				this.props.fundingVehicle &&
				this.props.fundingVehicle._id &&
				fee.fundingVehicleId === this.props.fundingVehicle._id
		);
		if (propFees.length > stateFees.length) {
			propFees.forEach((fee, index) => {
				if (!_.isEqual(fee, stateFees[index])) {
					this.setState({fees: propFees});
				}
			});
		} else {
			stateFees.forEach((fee, index) => {
				if (!_.isEqual(fee, propFees[index])) {
					this.setState({fees: propFees});
				}
			});
		}
	}

	renderFeeItem = fee => (
		<div
			key={fee._id}
			className={cx('listItem', {
				[styles.listItemSelected]: this.props.match.params.feeId === fee._id,
			})}
			onMouseUp={() =>
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/fees/${fee._id}`
				)
			}
		>
			{fee.name}
		</div>
	);

	onFeesReordered = (feesDnd, sourceIndex, destIndex) => {
		const fees = feesDnd.map(fee => {
			return fee.content;
		});
		const targetClassId = fees[destIndex].type;
		let targetClassCount = 0;

		fees.forEach(fee => {
			if (fee.type === targetClassId) {
				targetClassCount++;
				fee.feeNumber = targetClassCount;
				fee.nameId = `F_${_.get(FEE_TYPES.find(x => x.value === fee.type), 'label', '').replace(
					/\s/gi,
					''
				)}_${targetClassCount}`;
				// TODO add ability to update many settings at once
				this.props.updateFundingVehicleSetting({_id: fee._id, ...fee});
			}
		});

		this.setState({fees: _.orderBy(fees, ['type', 'feeNumber'], ['asc', 'asc'])});
	};

	handleSaveFee(fee) {
		if (fee._id && fee._id !== 'create') {
			return this.props.updateFundingVehicleSetting(fee).then(() => {
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/fees/create`
				);
			});
		} else {
			return this.props.addFundingVehicleSetting(fee).then(() => {
				this.props.history.push(
					`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
						this.props.match.params.id
					}/capitalStructure/fees/create`
				);
			});
		}
	}

	getFeeNumber = (_id, feeType = 'Fixed') => {
		const savedFee = this.props.fees.find(fee => fee._id === _id);
		const numOfFeesForType = this.state.fees.filter(fee => fee.type === feeType).length;
		if (savedFee && savedFee.type === feeType) {
			return savedFee.feeNumber;
		}
		return numOfFeesForType + 1;
	};

	render() {
		const {
			fees,
			history,
			match = {
				params: {},
			},
		} = this.props;

		return (
			<div className={styles.root}>
				<div className={styles.listActions}>
					<i
						className="material-icons"
						onMouseUp={() => {
							history.push(
								`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
									match.params.id
								}/capitalStructure/fees/create`
							);
						}}
					>
						add_circle
					</i>

					<KiMenu icon="more_vert" position="topRight">
						<KiMenuItem
							icon="delete"
							disabled={!fees.length}
							label="Delete Fees"
							onClick={() => this.setState({deleteModalActive: true})}
						/>
					</KiMenu>
				</div>
				<div className={styles.list} style={{minHeight: `${(fees.length + 1) * 47}px`}}>
					{!fees.length && <div className={styles.noRecordsFound}>No Fees Found</div>}
					<KiDnDList
						items={this.state.fees.map((fee, index) => ({
							id: index,
							content: fee,
							idx: index,
						}))}
						onReorder={this.onFeesReordered}
						contentRenderFunc={this.renderFeeItem}
						listClassName={'debt-list-dnd'}
						itemClassName={'debtItemDnd'}
					/>
				</div>
				<div className={styles.detail}>
					<Route
						path={'/datasets/:datasetId/fundingVehicles/:id/capitalStructure/fees/:feeId'}
						render={() => {
							if (
								this.props.match.params.feeId &&
								this.props.match.params.feeId !== 'create' &&
								!fees.find(d => d._id === this.props.match.params.feeId)
							) {
								return (
									<Redirect
										to={`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
											match.params.id
										}/capitalStructure/fees`}
									/>
								);
							}
							const {
								_id: feeId,
								name,
								nameId,
								type,
								calculation,
								value,
								rateType,
								rateIndex,
								rateAmount,
								margin,
								basis,
								// datasetId,
								// fundingVehicleId,
							} =
								fees.find(f => f._id === this.props.match.params.feeId) || {};
							return (
								<FeesForm
									onSave={fee => this.handleSaveFee(fee)}
									fundingVehicleId={_.get(this.props.fundingVehicle, '_id')}
									datasetId={_.get(this.props.dataset, 'datasetId')}
									feeId={feeId}
									name={name}
									basis={basis}
									calculation={calculation}
									rateAmount={rateAmount}
									margin={margin}
									rateIndex={rateIndex}
									rateType={rateType}
									type={type}
									value={value}
									nameId={nameId}
									availableTargets={this.props.waterfallCalcColumns}
									getFeeNumber={this.getFeeNumber}
									isNameUnique={newName =>
										name === newName ||
										!fees.find(
											fee =>
												fee.name === newName &&
												fee.fundingVehicleId === _.get(this.props.fundingVehicle, '_id')
										)
									}
									history={this.props.history}
								/>
							);
						}}
					/>
				</div>
				<FeesDeleteModal
					title="Delete Fees"
					active={this.state.deleteModalActive}
					fees={this.state.fees}
					onClose={() => this.setState({deleteModalActive: false})}
				/>
			</div>
		);
	}
}

const mapStateToProps = () => ({
	/*
	waterfallCalcColumns: _.sortBy(
		state.datasetList.selected.columns.filter(col => col.isWaterfall && col.dataType !== 'boolean'),
		col => col.displayName.toLowerCase()
	),
	*/
});

const mapDispatchToProps = {
	updateFundingVehicleSetting,
	addFundingVehicleSetting,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Fees);
