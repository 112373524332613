import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiInput from 'components/KiInput';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import validators from 'ki-common/validators';
import {KiCreatable} from 'components/KiSelect';

const constraints = {
	target0: {
		format: {
			pattern: '',
			flags: 'i',
			message: 'invalid date format',
		},
	},
	target1: {
		format: {
			pattern: '',
			flags: 'i',
			message: 'invalid date format',
		},
	},
};

const shortDateConstraint = JSON.parse(JSON.stringify(constraints));
const shortDateRegex = /^(\d{4})-(0[1-9]|1[0-2])/;
shortDateConstraint.target0.format.pattern = shortDateRegex;
shortDateConstraint.target1.format.pattern = shortDateRegex;

const longDateConstraint = JSON.parse(JSON.stringify(constraints));
const longDateRegex = /^(\d{4})-(0[1-9]|1[0-2])-([0-3][0-9])/;
longDateConstraint.target0.format.pattern = longDateRegex;
longDateConstraint.target1.format.pattern = longDateRegex;

export class TargetCell extends Component {
	static propTypes = {
		logic: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		// eslint-disable-next-line react/no-unused-prop-types
		value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
		error: PropTypes.string,
		dataColumn: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
		required: PropTypes.any,
		onError: PropTypes.func,
	};

	static defaultProps = {
		required: false,
	};

	constructor(props) {
		super(props);
		this.state = this.getStateFromProps(props);
	}

	state = {
		formValues: {
			target0: '',
			target1: '',
		},
		formErrors: {
			target0: '',
			target1: '',
		},
		logic: '=',
	};

	// This has to set the logic because otherwise a race condition can be entered between
	// when the value is updated vs when the logic is updated and the numberMask can fail
	componentWillReceiveProps(nextProps) {
		if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
			return this.setState(this.getStateFromProps(nextProps));
		}
	}

	getStateFromProps(props) {
		let target0 = '';
		let target1 = '';
		if (props.logic === 'between') {
			// array mapping
			switch (props.value.length) {
				case 1:
					target0 = props.value[0];
					break;
				case 2:
					target0 = props.value[0];
					target1 = props.value[1];
					break;
			}
		} else if (['in', 'not_in'].includes(props.logic)) {
			// return entire array
			target0 = props.value;
		} else {
			// basic input
			target0 = (props && props.value[0]) || '';
		}

		const newState = JSON.parse(JSON.stringify(this.state));
		newState.formValues = {
			target0,
			target1,
		};
		newState.logic = props.logic;
		return newState;
	}

	getPlaceholder = (dataColumn, defaultText) => {
		switch (dataColumn && dataColumn.displayFormat) {
			case 'shortDate':
				return 'YYYY-MM';
			case 'longDate':
				return 'YYYY-MM-DD';
			case 'string':
				// due to inconsistencies some dates live here
				if (dataColumn.dataType === 'date_long') {
					return 'YYYY-MM-DD';
				}
				if (dataColumn.dataType === 'date_short') {
					return 'YYYY-MM';
				}
				return defaultText || '';
			default:
				return defaultText || '';
		}
	};

	getConstraints = () => {
		const displayFormat = _get(this.props, 'dataColumn.displayFormat', '');
		const dataType = _get(this.props, 'dataColumn.dataType', '');
		switch (displayFormat) {
			case 'shortDate':
				return shortDateConstraint;
			case 'longDate':
				return longDateConstraint;
			case 'string':
				// due to inconsistencies some dates live here
				if (dataType === 'date_long') {
					return longDateConstraint;
				}
				if (dataType === 'date_short') {
					return shortDateConstraint;
				}
				return {};
			default:
				return {};
		}
	};

	validateField = (name, value) => {
		const constraints = this.getConstraints();
		const error = validators.validateSingle(value, constraints[name]);
		this.setState(
			{
				formErrors: {...this.state.formErrors, [name]: _get(error, '[0]', '')},
			},
			() => {
				if (!error) {
					this.props.onError && this.props.onError();
				} else {
					this.props.onError && this.props.onError(error);
				}
			}
		);
	};

	valueChanged = (name, value) => {
		this.setState(
			{
				formValues: {...this.state.formValues, [name]: value},
			},
			() => {
				this.validateField(name, value);
				this.handleOnBlur();
			}
		);
	};

	handleOnBlur = () => {
		// If the target is a between then an array is the expected return value
		const {target0, target1} = this.state.formValues;
		if (this.props.logic === 'between') {
			this.props.onChange([target0, target1]);
		} else {
			this.props.onChange(Array.isArray(target0) ? target0 : [target0]);
		}
	};

	getKiCreatableValue = () => {
		const targetValueArray = _get(this.state, 'formValues.target0', []);
		if (!targetValueArray) return [];
		if (!Array.isArray(targetValueArray)) return [{label: targetValueArray, value: targetValueArray}];
		if (targetValueArray.length === 0) return [];
		return targetValueArray.map(curVal => ({
			label: curVal,
			value: curVal,
		}));
	};

	handleKiCreatableChange = (values = []) => {
		let toSet;
		if (!values) {
			// No value set to empty string
			toSet = '';
		} else if (!Array.isArray(values)) {
			// If value is single {label,value} object
			toSet = values.value;
		} else {
			// If value is array of objects, convert to just array of values
			toSet = values.map(entry => {
				return entry.value;
			});
		}
		this.valueChanged('target0', toSet);
	};

	// Functions to handle the multiline input used for pasting a column
	// of data from a CSV file
	getTextAreaValue = () => {
		const targetValueArray = _get(this.state, 'formValues.target0', []);
		//non-array values were crashing when trying to call .reduce
		if (!Array.isArray(targetValueArray)) return String(targetValueArray || '');
		if (targetValueArray.length === 0) return '';
		return targetValueArray.reduce((output, curValue, idx) => {
			const useValue = _get(curValue, 'value', curValue); // Parse value or return directly
			return `${output}${idx > 0 ? '\r\n' : ''}${useValue}`;
		}, '');
	};

	handleTextAreaChange = event => {
		const rawText = event.target.value;
		const valueArray = rawText ? _uniq(rawText.split(/\r?\n/)) : [];
		this.valueChanged('target0', valueArray);
	};

	// TODO fix className="double" being a thing
	render() {
		const {logic} = this.state;
		const {dataColumn, required, error} = this.props;
		const {formValues, formErrors} = this.state;
		const columnDataType = _get(dataColumn, 'dataType', '');
		const smallLabelStyle = {
			fontSize: '1.2rem',
			paddingTop: '1rem',
			color: 'gray',
			paddingBottom: '0.5rem',
		};

		if (logic === 'between') {
			const {target0} = formValues;
			const minimumValueForMax =
				required && columnDataType === 'numeric' && target0 !== '' ? (parseFloat(target0) || 0) + 1 : undefined;
			return (
				<div className="double">
					<div style={{display: 'flex', alignItems: 'center'}}>
						<KiInput
							type="text"
							required={required}
							name="targetMin"
							label={this.getPlaceholder(dataColumn, 'Min')}
							isNumberMasked={dataColumn && dataColumn.dataType === 'numeric'}
							value={formValues.target0}
							error={formErrors.target0}
							onChange={val => this.valueChanged('target0', val)}
							onBlur={this.handleOnBlur}
						/>
						<div style={{padding: '0 1rem'}}>-</div>
						<KiInput
							type="text"
							required={required}
							name="targetMax"
							min={minimumValueForMax}
							label={this.getPlaceholder(dataColumn, 'Max')}
							isNumberMasked={dataColumn && dataColumn.dataType === 'numeric'}
							value={formValues.target1}
							error={formErrors.target1}
							onChange={val => this.valueChanged('target1', val)}
							onBlur={this.handleOnBlur}
						/>
					</div>
					<span className="setting-error">{error}</span>
				</div>
			);
		} else if (['in', 'not_in'].includes(logic)) {
			const kiCreatableValue = this.getKiCreatableValue();
			const textareaValues = this.getTextAreaValue();
			return (
				<div className="cc-cell-select double" style={{padding: '2rem 0'}}>
					<label className="mock-react-toolkit" style={smallLabelStyle}>
						Target
					</label>
					<div style={smallLabelStyle}>Enter Single Tags</div>
					<KiCreatable
						className="cc-target-dropdown double"
						isMulti={true}
						type="text"
						required={required}
						arrowRenderer={() => null}
						placeholder={this.getPlaceholder(dataColumn, 'Enter a value...')}
						clearable={false}
						noResultsText={false}
						value={kiCreatableValue}
						onChange={this.handleKiCreatableChange}
						onBlur={this.handleOnBlur}
					/>
					<div style={smallLabelStyle}>Enter List (one item per row)</div>
					<textarea
						rows="5"
						cols="30"
						value={textareaValues}
						style={{width: '100%'}}
						onChange={this.handleTextAreaChange}
						onBlur={this.handleOnBlur}
					/>
					<span className="setting-error">{error || formErrors.target0}</span>
				</div>
			);
		} else {
			const isDisabled = ['is_null', 'is_not_null'].includes(logic);
			if (isDisabled) {
				return null;
			}
			return (
				<div className="double">
					<KiInput
						type="text"
						required={required}
						name="target"
						disabled={isDisabled}
						label={this.getPlaceholder(dataColumn, 'Target')}
						isNumberMasked={dataColumn && dataColumn.dataType === 'numeric'}
						value={formValues.target0}
						error={formErrors.target0}
						onChange={val => this.valueChanged('target0', val)}
						onBlur={this.handleOnBlur}
					/>
				</div>
			);
		}
	}
}

export default TargetCell;
