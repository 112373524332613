import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import KiInput from 'components/KiInput';
import KiCheckbox from 'components/KiCheckbox';
import options from 'ki-common/options';
import stringHelpers from 'ki-common/utils/stringHelpers';
import KiButton from 'components/KiButton';
import _get from 'lodash/get';
import * as columnServiceApi from '../../../api/columnServiceApi';

function StringSummaryForm(props) {
	const [name, setName] = useState(_get(props, 'columnToEdit.displayName', ''));
	const [tags, setTags] = useState(_get(props, 'columnToEdit.tags', []));
	const [nameError, setNameError] = useState(null);
	const [aggregationType, setAggregationType] = useState(_get(props, 'columnToEdit.calculation', ''));
	const [assetOption, setAssetOption] = useState(_get(props, 'columnToEdit.assetColumn', ''));
	const [calculateColumn, setCalculateColumn] = useState(_get(props, 'columnToEdit.calculateColumn', ''));
	const [availableForDebt, setAvailableForDebt] = useState(_get(props, 'columnToEdit.availableForDebt', false));
	const [isGlobal, setIsGlobal] = useState(
		props.columnToEdit && !_get(props, 'columnToEdit.createdBy') ? true : false
	);
	const [isSaving, setIsSaving] = useState(false);

	const stringColumns = props.columnList.filter(
		c =>
			props.type === 'string'
				? c.dataType === props.type && c.columnType === 'asset' && c._id !== _get(props.columnToEdit, '_id')
				: (c.dataType === 'date_long' || c.dataType === 'date_short') &&
				  c.columnType === 'asset' &&
				  c._id !== _get(props.columnToEdit, '_id')
	);
	const numericAndDateColumns = props.columnList.filter(
		c =>
			(c.dataType === 'numeric' || c.dataType === 'date_long' || c.dataType === 'date_short') &&
			c.columnType === 'asset' &&
			c._id !== _get(props.columnToEdit, '_id')
	);

	const fetchAndSetAssetOption = columnId => {
		columnServiceApi.getColumnByIdFromService(props.datasetId, columnId, true).then(opt => {
			delete opt.detailedDisplayName;
			delete opt.htmlDisplayName;
			setAssetOption(opt);
		});
	};

	const fetchAndSetCalculateColumn = columnId => {
		columnServiceApi.getColumnByIdFromService(props.datasetId, columnId, true).then(opt => {
			delete opt.detailedDisplayName;
			delete opt.htmlDisplayName;
			setCalculateColumn(opt);
		});
	};
	const numericColumns = props.columnList.filter(
		c => c.dataType === 'numeric' && c.columnType === 'asset' && c._id !== _get(props.columnToEdit, '_id')
	);

	const aggregationOptions =
		props.type === 'string' ? options.summaryStringAggregateColumnTypes : options.summaryDateAggregateColumnTypes;

	const onNameChange = val => {
		let nameError = '';

		if (!val.trim().length) {
			nameError = "Name can't be blank";
		} else if (!val.match(stringHelpers.alphanumericRegex)) {
			nameError = 'Name must be Alphanumeric!';
		}

		setNameError(nameError);
		setName(val);
	};

	const showAggregateBy =
		(!['date_short', 'date_long'].includes(props.type) && ['MIN', 'MAX'].includes(aggregationType)) ||
		(['date_short', 'date_long'].includes(props.type) && ['WGHTAVG'].includes(aggregationType));

	const getSaveAndFormatDisabled = () => {
		return (
			!name ||
			(showAggregateBy && !calculateColumn) ||
			!aggregationType ||
			!assetOption ||
			(!calculateColumn && assetOption.dataType === 'string' && aggregationType !== 'FIRST_VALID')
		);
	};

	const onSubmit = () => {
		const {columnToEdit, datasetId, type} = props;
		setIsSaving(true);
		if (name.length === null) {
			setNameError("Name can't be blank");
			setIsSaving(false);
			return;
		}

		if (!name.match(stringHelpers.alphanumericRegex)) {
			setNameError('Name must be Alphanumeric!');
			setIsSaving(false);
			return;
		}

		const column = {
			_id: columnToEdit ? columnToEdit._id : null,
			datasetId: datasetId,
			columnName: name,
			displayName: name,
			displayFormat: type === 'string' ? 'text' : options.dateFormats[0].value,
			dataType: type,
			columnType: 'aggregate',
			calculation: aggregationType,
			tags: tags,
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			assetColumnId: assetOption._id,
			assetColumn: assetOption,
			availableForDebt: !!availableForDebt,
			isGlobal: isGlobal,
		};

		if (
			(type === 'string' && ['MIN', 'MAX'].includes(aggregationType)) ||
			(['date_short', 'date_long'].includes(props.type) && ['WGHTAVG'].includes(aggregationType))
		) {
			column.calculateColumnId = calculateColumn._id;
			column.calculateColumn = calculateColumn;
		}
		return props.submitMethod([column]);
	};

	const isExposeToDebtCheckboxDisabled = (isAdmin, columnToEdit) => {
		if (!isAdmin) return true; //not an admin
		if (_get(columnToEdit, '_id')) {
			// existing column
			return !!columnToEdit.availableForDebt || !!columnToEdit.createdBy;
		}
		return false;
	};

	return (
		<section className="column-selector-form inline-column-form">
			<div>
				<KiInput label="Name" value={name} onChange={val => onNameChange(val)} error={nameError} />
				<section className="calculation-form-section">
					<span className="form-instruction">Tags:</span>
					<CreatableSelect
						classNamePrefix="aut-select"
						isMulti={true}
						options={props.existingTags.map(t => ({
							value: t,
							label: t,
						}))}
						value={tags.map(t => ({value: t, label: t}))}
						onChange={val => setTags(val.map(t => t.value))}
						placeholder="Add some tags"
						noResultsText={false}
					/>
				</section>

				<section className="calculation-form-section">
					<span className="form-instruction">Select a Column to Aggregate</span>
					<Select
						classNamePrefix="aut-select"
						value={assetOption}
						isClearable={false}
						options={stringColumns}
						getOptionLabel={option => option.displayName}
						getOptionValue={option => option._id}
						onChange={opt => fetchAndSetAssetOption(opt._id)}
					/>
				</section>

				<section className="calculation-form-section">
					<span className="form-instruction">Select Aggregation Type</span>
					<Select
						classNamePrefix="aut-select"
						value={aggregationOptions.find(opt => opt.value === aggregationType)}
						isClearable={false}
						isDisabled={!!_get(props, 'columnToEdit._id', null)}
						options={aggregationOptions}
						onChange={opt => setAggregationType(opt.value)}
					/>
				</section>
				{showAggregateBy && (
					<section className="calculation-form-section">
						<span className="form-instruction">Select a Column to Aggregate by</span>
						<Select
							classNamePrefix="aut-select"
							value={calculateColumn}
							isClearable={false}
							options={
								['date_short', 'date_long'].includes(props.type)
									? numericColumns
									: numericAndDateColumns
							}
							getOptionLabel={option => option.displayName}
							getOptionValue={option => option._id}
							onChange={opt => fetchAndSetCalculateColumn(opt._id)}
						/>
					</section>
				)}
				<section className="calculation-form-section">
					<KiCheckbox
						name="availableForDebt"
						checked={!!availableForDebt}
						label="Expose to Debt"
						disabled={isExposeToDebtCheckboxDisabled(props.isAdmin, props.columnToEdit)}
						onChange={val => {
							val && setIsGlobal(!!val);
							return setAvailableForDebt(!!val);
						}}
					/>
					<KiCheckbox
						name="isGlobal"
						checked={isGlobal}
						disabled={!props.isAdmin || !!_get(props, 'columnToEdit._id', null)}
						label="Make Global"
						onChange={val => {
							setAvailableForDebt(!!val);
							return setIsGlobal(!!val);
						}}
					/>
				</section>
			</div>
			<section className="format-and-save">
				<div className="inline-column-form-buttons">
					<KiButton flat primary onClick={() => props.closeForm()}>
						Cancel
					</KiButton>
					<KiButton
						disabled={getSaveAndFormatDisabled() || isSaving}
						raised
						primary
						onClick={() => onSubmit()}
					>
						Save
					</KiButton>
				</div>
			</section>
		</section>
	);
}

StringSummaryForm.propTypes = {
	type: PropTypes.string.isRequired,
	submitMethod: PropTypes.func.isRequired,
	columnList: PropTypes.array.isRequired,
	closeForm: PropTypes.func.isRequired,
	isAdmin: PropTypes.bool,
	columnToEdit: PropTypes.object,
	existingTags: PropTypes.array,
	datasetId: PropTypes.string,
};

StringSummaryForm.defaultProps = {
	existingTags: [],
	stringColumns: [],
	numericAndDateColumns: [],
	tags: [],
};

export default StringSummaryForm;
