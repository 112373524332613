import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from '../AssociatedData.theme.scss';
import options from 'ki-common/options';
import Select from 'react-select';

export class FileTypePicker extends Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		resultData: {},
	};

	state = {
		associatedDataType: null,
	};

	render() {
		return (
			<div className={styles.selectWrapper}>
				<div className="column">
					<span className={styles.label}>Data Type</span>
					<Select
						className={styles.selectWide}
						classNamePrefix="aut-select"
						value={this.state.associatedDataType}
						isClearable={false}
						options={options.appendDataOptions}
						onChange={option => {
							this.props.onChange(option.value);
							this.setState({associatedDataType: option});
						}}
					/>
				</div>
			</div>
		);
	}
}

export default FileTypePicker;
