import * as actionTypes from './actionTypes';

const initialState = {
	savedTemplates: [],
	isLoading: false,
};

// export const DEBT_INPUT_TEMPLATES_FETCH_ALL = 'DEBT_INPUT_TEMPLATES_FETCH_ALL';
// export const DEBT_INPUT_TEMPLATES_UPSERT_ONE = 'DEBT_INPUT_TEMPLATES_UPSERT_ONE';

export const DebtInputMaintenance = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DEBT_INPUT_TEMPLATES_FETCH_ALL:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {...state, isLoading: false, savedTemplates: action.templates};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.DEBT_INPUT_TEMPLATES_ADD_ONE:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						savedTemplates: [...state.savedTemplates, action.template],
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		case actionTypes.DEBT_INPUT_TEMPLATES_UPDATE_ONE:
			switch (action.status) {
				case 'pending':
					return {...state, isLoading: true};
				case 'success':
					return {
						...state,
						isLoading: false,
						savedTemplates: state.savedTemplates.reduce((acc, template) => {
							if (template._id === action._id) {
								return [...acc, {...template, ...action.template}];
							}
							return [...acc, template];
						}, []),
					};
				case 'error':
					return {...state, isLoading: false};
				default:
					return state;
			}

		default:
			return state;
	}
};

export default DebtInputMaintenance;
