import React from 'react';
import styles from './creditSupportsDeleteModal.theme.scss';
import PropTypes from 'prop-types';

const DeletionDependencyErrors = ({dependencies}) => {
	return (
		<section className={styles.inUseMessage}>
			<span>Could not delete due to the following dependencies:</span>
			{dependencies.map(d => {
				return (
					<div className={styles.dependencyList} key={d.name}>
						{d.name}
						<ol>
							{d.dependencies.map(i => {
								return (
									<li className={styles.dependencyItem} key={`${i.name}_${d.name}`}>
										{' '}
										{i.name} ({i.type})
									</li>
								);
							})}
						</ol>
					</div>
				);
			})}
		</section>
	);
};

DeletionDependencyErrors.propTypes = {
	dependencies: PropTypes.array,
};

DeletionDependencyErrors.defaultProps = {
	dependencies: [],
};

export default DeletionDependencyErrors;
