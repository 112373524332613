import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames/bind';
import styles from './KiChip.theme.scss';

const cx = classNames.bind(styles);
export class KiChip extends Component {
	static propTypes = {
		className: PropTypes.string,
		icon: PropTypes.string,
		iconBackgroundColor: PropTypes.string,
		iconColor: PropTypes.string,
		children: PropTypes.node,
	};

	static defaultProps = {
		className: '',
		icon: null,
		iconBackgroundColor: null,
		iconColor: null,
		content: '',
	};

	render() {
		const {className, icon, iconBackgroundColor, iconColor} = this.props;

		const iconStyle = {};
		if (iconBackgroundColor) {
			iconStyle.backgroundColor = iconBackgroundColor;
		}
		if (iconColor) {
			iconStyle.color = iconColor;
		}

		return (
			<div
				className={cx('chip', {
					'has-avatar': icon,
					className: className,
				})}
			>
				{icon ? (
					<div className={styles.avatar} style={iconStyle}>
						<i className="material-icons">{icon}</i>
					</div>
				) : null}
				{this.props.children}
			</div>
		);
	}
}
export default KiChip;
