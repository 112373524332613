import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchReports = () => {
	return fetch(`${apiUrl}/reportDefinitions/`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchReportDefinitionsByReportGroupId = reportGroupId => {
	return fetch(`${apiUrl}/reportDefinitions/reportGroupId/${reportGroupId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchReportDefinition = id => {
	return fetch(`${apiUrl}/reportDefinitions/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const checkIfReportDefinitionNameIsUnique = (reportGroupId, name) => {
	return fetch(`${apiUrl}/reportDefinitions/reportGroupId/${reportGroupId}/isNameUnique`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({name}),
	}).then(_handleResponse);
};

export const updateReportDefinition = reportDefinition => {
	return fetch(`${apiUrl}/reportDefinitions/${reportDefinition._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(reportDefinition),
	}).then(_handleResponse);
};

export const createReportDefinition = reportDefinition => {
	return fetch(`${apiUrl}/reportDefinitions`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(reportDefinition),
	}).then(_handleResponse);
};

export const saveReportDefinition = reportDefinition => {
	if (reportDefinition._id) {
		return updateReportDefinition(reportDefinition);
	}
	return createReportDefinition(reportDefinition);
};

export const deleteReportDefinition = id => {
	return fetch(`${apiUrl}/reportDefinitions/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const updateReportTemplateName = (reportDefinitionId, templateName, templateType) => {
	return fetch(`${apiUrl}/reportDefinitions/${reportDefinitionId}/templateName`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify({templateName: templateName, templateType: templateType}),
	}).then(_handleResponse);
};

export const updateReportTemplateProtectDataTab = (reportDefinitionId, protectDataTab) => {
	return fetch(`${apiUrl}/reportDefinitions/${reportDefinitionId}/protectDataTab`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify({protectDataTab: protectDataTab}),
	}).then(_handleResponse);
};

export const fetchReportPreview = reportDefinition => {
	return fetch(`${apiUrl}/reports/preview`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(reportDefinition),
	}).then(_handleResponse);
};

export const fetchReportPreviewById = id => {
	return fetch(`${apiUrl}/reports/preview//${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchReportGroups = () => {
	return fetch(`${apiUrl}/reportGroups`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchReportGroupsByDatasetId = (datasetId, signal) => {
	return fetch(`${apiUrl}/reportGroups/datasetId/${datasetId}`, {
		signal,
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchReportGroup = id => {
	return fetch(`${apiUrl}/reportGroups/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const checkIfReportGroupNameIsUnique = (datasetId, name) => {
	return fetch(`${apiUrl}/reportGroups/isNameUnique`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({datasetId, name}),
	}).then(_handleResponse);
};

export const createReportGroup = reportGroup => {
	return fetch(`${apiUrl}/reportGroups`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(reportGroup),
	}).then(_handleResponse);
};

export const updateReportGroup = reportGroup => {
	return fetch(`${apiUrl}/reportGroups/${reportGroup._id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(reportGroup),
	}).then(_handleResponse);
};

export const deleteReportGroup = id => {
	return fetch(`${apiUrl}/reportGroups/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchIndividualReports = () => {
	return fetch(`${apiUrl}/individualReports`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const fetchIndividualReport = id => {
	return fetch(`${apiUrl}/individualReports/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const createIndividualReport = individualReport => {
	return fetch(`${apiUrl}/individualReports`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(individualReport),
	}).then(_handleResponse);
};

export const createIndividualReportsByReportGroupId = (reportGroupId, individualReport) => {
	return fetch(`${apiUrl}/individualReports/reportGroupId/${reportGroupId}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(individualReport),
	}).then(_handleResponse);
};

export const cancelIndividualReport = individualReportId => {
	return fetch(`${apiUrl}/individualReports/${individualReportId}/cancel`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export const deleteIndividualReport = id => {
	return fetch(`${apiUrl}/individualReports/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('GET'),
	}).then(_handleResponse);
};

export default {
	fetchReports,
	fetchReportDefinition,
	checkIfReportDefinitionNameIsUnique,
	saveReportDefinition,
	deleteReportDefinition,
	updateReportTemplateName,
	updateReportTemplateProtectDataTab,
	fetchReportPreview,
	fetchReportPreviewById,
	fetchReportGroups,
	fetchReportGroup,
	checkIfReportGroupNameIsUnique,
	createReportGroup,
	updateReportGroup,
	deleteReportGroup,
	fetchIndividualReports,
	fetchIndividualReport,
	createIndividualReport,
	createIndividualReportsByReportGroupId,
	cancelIndividualReport,
	deleteIndividualReport,
};
