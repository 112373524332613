/*
 Constraint Type: ANY
 Name: Is the display name of the Constraint entered by the user. This must be unique
 Data Column: Is selected from a list of available columns in the dataset and reflects the column of data that will be constrained
 Logic: can be one of the following mathematical operator used for the constraint =,<>,<,>,<=,>=,Between, in, not in, is null, is not null
 Target Min: Numeric minimum required
 Target Max: Numeric Max required
 Flex: Default value the target is allowed to expand as a % below the min or above the max
 Constraint: By default this is set to checked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
 Covenant: By default this is set to unchecked.  Since this is intended to be a library over time some constraints may not apply changing them to a unchecked will not include them in the default solver list going forward
 */

const getConstraints = () => ({
	dataColumn: {
		presence: {allowEmpty: false},
	},
	logic: {
		presence: {allowEmpty: false},
	},
	target: {
		presence: {allowEmpty: false},
		targetArray: true, // Custom validator
	},
});

module.exports = {
	getConstraints,
};
