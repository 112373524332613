import React, {useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import _ from 'lodash';

import {fundingAnalysisApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';
import FundingAnalysisContext from 'containers/fundingAnalysis/fundingAnalysisContext';
import {KiButton} from 'components';

const CancelButton = () => {
	const history = useHistory();
	const {datasetId} = useParams();
	return (
		<KiButton
			label="CANCEL"
			type="submit"
			iconButton={false}
			primary
			onClick={() => history.push(`/fundingAnalysis/${datasetId}/scenarios`)}
		/>
	);
};

export const Actions = props => {
	const {datasetId, action} = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const isModificationForm = action === 'modify';

	const isSolveDisabled = useMemo(
		() => {
			// TODO make sure this handles transferDate changes properly for modifications
			const transferDateChangeUnsaved =
				props.scenario._id && props.scenario.transferDate !== props.originalScenario.transferDate;
			/*
				only let an unsaved transferDate change prevent solve on non-recurring scenarios
				as recurring don't have a separate save button

				additionally, compare it from to the originalScenario (prior to unsaved changes)
				(previously it was compared to a match in allScenarios, but that requires fetching every one,
				even if you skipped the scenario list and only loaded data for the current scenario)
			 */
			// each item below should be truthy, or else solve will be disabled
			return !(
				props.scenario.name &&
				(props.scenario.isRecurring || !transferDateChangeUnsaved) &&
				props.modelConstraintGroups.find(cg => cg._id === props.scenario.constraintGroupId) &&
				props.scenario.fundingModelId &&
				props.scenario.constraintGroupId &&
				props.scenario.snapshotDate &&
				props.scenario.transferDate &&
				!props.nameError &&
				!props.areActionsLocked &&
				!Object.keys(props.inputErrors).length
			);
		},
		[
			props.scenario,
			props.originalScenario,
			props.nameError,
			props.areActionsLocked,
			props.inputErrors,
			props.modelConstraintGroups,
		]
	);

	// Various calls will override that specific variable, if not passed it should use
	// whatever the current setting is
	const onSave = async (shouldSkipNextRecurrence = props.scenario.shouldSkipNextRecurrence) => {
		try {
			props.setShowSpinner(true);
			const scenarioToSave = _.cloneDeep(props.scenario);
			scenarioToSave.datasetId = datasetId;
			delete scenarioToSave.modification; // Should not have a modification on recurrence
			scenarioToSave.shouldSkipNextRecurrence = shouldSkipNextRecurrence;
			await fundingAnalysisApi.upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario saved successfully.`));
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			//console.log('onSolve - error', err)
			fundingAnalysisContext.addError(`${props.scenario.name}: ${err.message}`);
			props.setShowSpinner(false);
		}
	};

	const onSolve = async (solveAsNew = false) => {
		const scenarioToSave = _.cloneDeep(props.scenario);
		scenarioToSave.datasetId = datasetId;
		delete scenarioToSave._id;
		delete scenarioToSave.modification;
		if (solveAsNew) {
			scenarioToSave.name = `${scenarioToSave.name}_resolve`;
		}
		try {
			props.setActionsLocked(true);
			props.setShowSpinner(true);
			const scenarioRes = await fundingAnalysisApi.upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario created successfully.`));
			await fundingAnalysisApi.optimizeScenario(scenarioRes._id);
			props.setShowSpinner(false);
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			//console.log('onSolve - error', err)
			fundingAnalysisContext.addError(`${props.scenario.name}: ${err.message}`);
			props.setShowSpinner(false);
		}
	};

	const onModifyResolve = async () => {
		props.originalScenario.modification = _.cloneDeep(
			_.pick(props.scenario, ['constraintGroupId', 'transferDate', 'fvSettings', 'filters'])
		);
		const scenarioToSave = _.cloneDeep(props.originalScenario);
		try {
			props.setShowSpinner(true);
			await fundingAnalysisApi.upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario updated successfully.`));
			await fundingAnalysisApi.optimizeScenario(scenarioToSave._id);
			props.setShowSpinner(false);
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			fundingAnalysisContext.addError(`${props.scenario.name}: ${err.message}`);
			props.setShowSpinner(false);
		}
	};
	const onResolve = async () => {
		const scenarioToSave = _.cloneDeep(props.scenario);
		// This must be overridden with an empty object or else mongoose
		// will leave the old values there instead of deleting
		scenarioToSave.modification = {};
		try {
			props.setActionsLocked(true);
			props.setShowSpinner(true);
			await fundingAnalysisApi.upsertScenario(scenarioToSave);
			dispatch(showSnackbar(`Scenario re-started successfully.`));
			await fundingAnalysisApi.optimizeScenario(scenarioToSave._id);
			props.setShowSpinner(false);
			history.push(`/fundingAnalysis/${datasetId}/scenarios`);
		} catch (err) {
			//TODO - parse error message
			fundingAnalysisContext.addError(`${props.scenario.name}: ${err.message}`);
			props.setShowSpinner(false);
		}
	};
	if (props.scenario.isRecurring) {
		return (
			<React.Fragment>
				<CancelButton />
				{props.scenario._id &&
					!props.scenario.shouldSkipNextRecurrence && (
						<KiButton
							disabled={isSolveDisabled}
							label="Cancel Next"
							type="submit"
							iconButton={false}
							primary
							onClick={() => onSave(true)}
						/>
					)}
				{props.scenario._id &&
					props.scenario.shouldSkipNextRecurrence && (
						<KiButton
							disabled={isSolveDisabled}
							label="Resume Next"
							type="submit"
							iconButton={false}
							primary
							onClick={() => onSave(false)}
						/>
					)}
				<KiButton
					disabled={isSolveDisabled}
					label="SAVE"
					type="submit"
					iconButton={false}
					primary
					onClick={() => onSave()}
				/>
			</React.Fragment>
		);
	}

	// Modification controls
	if (isModificationForm) {
		if (props.scenario._id) {
			return (
				<React.Fragment>
					<CancelButton />
					<KiButton
						disabled={
							isSolveDisabled ||
							props.scenario.modificationStatus === 'success' ||
							!props.scenario.fvSettings.map(fv => fv.isUsableByMod === true).includes(true)
						}
						label="MODIFY SCENARIO"
						type="submit"
						iconButton={false}
						primary
						onClick={() => onModifyResolve()}
					/>
				</React.Fragment>
			);
		}

		return <KiButton disabled={true} label="INVALID SCENARIO" type="submit" iconButton={false} primary />;
	}

	// Default controls
	if (props.scenario._id) {
		return (
			<React.Fragment>
				<CancelButton />
				<KiButton
					disabled={isSolveDisabled}
					label="SOLVE AS NEW"
					type="submit"
					iconButton={false}
					primary
					onClick={() => onSolve(true)}
				/>
				{!['submitted', 'approved', 'closed'].includes(props.scenario.status) && (
					<KiButton
						disabled={isSolveDisabled}
						label="RE-SOLVE"
						type="submit"
						iconButton={false}
						primary
						onClick={() => onResolve()}
					/>
				)}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<CancelButton />
			<KiButton
				disabled={isSolveDisabled}
				label="SOLVE"
				type="submit"
				iconButton={false}
				primary
				onClick={() => onSolve()}
			/>
		</React.Fragment>
	);
};
Actions.propTypes = {
	scenario: PropTypes.object,
	modelConstraintGroups: PropTypes.array,
	nameError: PropTypes.string,
	areActionsLocked: PropTypes.bool,
	inputErrors: PropTypes.object,
	setActionsLocked: PropTypes.func.isRequired,
	setShowSpinner: PropTypes.func.isRequired,
	originalScenario: PropTypes.object,
};
Actions.defaultProps = {
	scenario: {},
	modelConstraintGroups: [],
	nameError: '',
	areActionsLocked: false,
};
