export const FUNDING_VEHICLES_FETCH_ONE = 'FUNDING_VEHICLES_FETCH_ONE';
export const FUNDING_VEHICLES_UPDATE_ONE = 'FUNDING_VEHICLES_UPDATE_ONE';
export const FUNDING_VEHICLES_SETTING_UPDATE_ONE = 'FUNDING_VEHICLES_SETTING_UPDATE_ONE';
export const FUNDING_VEHICLES_FETCH_POOLS = 'FUNDING_VEHICLES_FETCH_POOLS';
export const FUNDING_VEHICLES_DELETE = 'FUNDING_VEHICLES_DELETE';
export const FUNDING_VEHICLES_SETTINGS_DELETE = 'FUNDING_VEHICLES_SETTINGS_DELETE';
export const FUNDING_VEHICLES_DEACTIVATE = 'FUNDING_VEHICLES_DEACTIVATE';
export const FUNDING_VEHICLES_FETCH_SETTINGS = 'FUNDING_VEHICLES_FETCH_SETTINGS';
export const FUNDING_VEHICLES_FETCH_ONE_SETTING = 'FUNDING_VEHICLES_FETCH_ONE_SETTING';
export const FUNDING_VEHICLE_MODAL_OPEN = 'FUNDING_VEHICLE_MODAL_OPEN';
export const FUNDING_VEHICLE_MODAL_CLOSE = 'FUNDING_VEHICLE_MODAL_CLOSE';
export const FUNDING_VEHICLES_SETTING_ADD_ONE = 'FUNDING_VEHICLES_SETTING_ADD_ONE';
export const FUNDING_VEHICLE_DATASET_COLUMNS_FETCHED = 'FUNDING_VEHICLE_DATASET_COLUMNS_FETCHED';
export const FUNDING_VEHICLES_FETCH_SETTINGS_BY_DATASETID = 'FUNDING_VEHICLES_FETCH_SETTINGS_BY_DATASETID';
export const FUNDING_VEHICLES_FETCH_ALL_REPORT_DATES = 'FUNDING_VEHICLES_FETCH_ALL_REPORT_DATES';

export const FUNDING_VEHICLES_GET_TRACK_LIST = 'FUNDING_VEHICLES_GET_TRACK_LIST';
export const FUNDING_VEHICLES_SAVE_TRACK = 'FUNDING_VEHICLES_SAVE_TRACK';
export const FUNDING_VEHICLES_SET_CURRENT_TRACK = 'FUNDING_VEHICLES_SET_CURRENT_TRACK';
export const FUNDING_VEHICLES_ADD_BLOCK_TO_TRACK = 'FUNDING_VEHICLES_ADD_BLOCK_TO_TRACK';

export const FUNDING_VEHICLES_BLOCK_MODAL_TOGGLE = 'FUNDING_VEHICLES_BLOCK_MODAL_TOGGLE';
export const FUNDING_VEHICLES_SET_CURRENT_BLOCK = 'FUNDING_VEHICLES_SET_CURRENT_BLOCK';
export const FUNDING_VEHICLES_SAVE_BLOCK = 'FUNDING_VEHICLES_SAVE_BLOCK';
export const FUNDING_VEHICLES_GET_BLOCK_LIST = 'FUNDING_VEHICLES_GET_BLOCK_LIST';
export const FUNDING_VEHICLES_DELETE_BLOCK = 'FUNDING_VEHICLES_DELETE_BLOCK';
export const FUNDING_VEHICLES_COPY_BLOCK = 'FUNDING_VEHICLES_COPY_BLOCK';
