import React from 'react';
import PropTypes from 'prop-types';
import styles from './DebtFormulasList.theme.scss';
import {ratingsApi} from 'api';

class DebtFormulasListRating extends React.Component {
	static propTypes = {
		fundingVehicles: PropTypes.array,
		onEdit: PropTypes.func,
		onDelete: PropTypes.func.isRequired,
		formulas: PropTypes.array,
		isAdmin: PropTypes.bool,
	};

	static defaultProps = {
		fundingVehicles: [],
		formulas: [],
		isAdmin: false,
	};

	state = {
		ratingsOptions: [],
	};

	async componentDidMount() {
		const ratingsOptionsResp = await ratingsApi.fetchRatingsOptionsForAllAgencies();
		this.setState({
			ratingsOptions: ratingsOptionsResp || [],
		});
	}

	getCalculationText = formula => {
		let s = ``;
		formula.threshold.forEach(t => {
			s = s.concat(`[${t.date}: ${this.getRatingName(t.value, formula.agencyId)}: ${formula.operator}], `);
		});
		return s;
	};

	getFVName = value => {
		const match = this.props.fundingVehicles.find(fv => fv._id === value);
		if (match) {
			return match.name || '';
		}
		return value;
	};

	getRatingName = (value, agencyId) => {
		const rating = this.state.ratingsOptions.find(o => o.scale === value && o.agencyId == agencyId);
		return rating ? rating.value : value;
	};

	render() {
		const {isAdmin} = this.props;
		return (
			<React.Fragment>
				{isAdmin && (
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<div
							style={{padding: '1rem 0'}}
							className={`cta-icon-btn`}
							onClick={() => this.props.onEdit({formula: [], fundingVehicles: []})}
						>
							<i className="material-icons">add_circle</i>
							<p>Add a Formula</p>
						</div>
					</div>
				)}
				<div style={{flex: '1 1 auto', width: '100%'}}>
					<table>
						<thead style={{borderBottom: '1px solid #ccc'}}>
							<tr>
								<th style={{textAlign: 'left', padding: '.5rem 0'}}>Targets</th>
								<th style={{textAlign: 'left', padding: '.5rem 0'}}>Funding Vehicle</th>
								{isAdmin && <th>&nbsp;</th>}
							</tr>
						</thead>
						<tbody>
							{this.props.formulas.map((i, idx) => {
								return (
									<tr key={idx} style={{borderBottom: '1px solid #ccc'}}>
										<td className={styles.formulaTd}>{this.getCalculationText(i)}</td>
										<td className={styles.fvTd}>{this.getFVName(i.fundingVehicle)}</td>
										{isAdmin && (
											<td style={{padding: '.5rem 1rem .5rem 0', verticalAlign: 'middle'}}>
												<div style={{display: 'flex', justifyContent: 'flex-end'}}>
													<i
														className="material-icons"
														style={{cursor: 'pointer'}}
														onClick={() => this.props.onEdit(i, idx)}
													>
														edit
													</i>
													<i
														className="material-icons"
														style={{cursor: 'pointer'}}
														onClick={() => this.props.onDelete(idx)}
													>
														delete
													</i>
												</div>
											</td>
										)}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</React.Fragment>
		);
	}
}

export default DebtFormulasListRating;
