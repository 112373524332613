import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import {connect} from 'react-redux';
import stylesForm from '../common/Form.theme.scss';
import {useMergedState} from 'utils/customHooks';
import NameForm from '../common/NameForm';
import {KiButton, KiMenu, KiMenuItem} from 'components';
import {ASSUMPTION_PROP_TO_TYPE} from './AssumptionList';
import {Cashflow, Collateral, InterestRate, Delinquency} from './AssumptionItems';
import {
	defaultType as DEFAULT_TYPE_OPTIONS,
	lossType as LOSS_TYPE_OPTIONS,
	prePay as PRE_PAY_OPTIONS,
	delinquencyOptions as DELINQUENCY_OPTIONS,
} from 'ki-common/options/assumptions';
import {getVectors, getAssumption, getAssumptions, saveAssumption} from 'api/waterfallApi';
import {getIndexColumnsFromService} from 'api/columnServiceApi';
import FormCloseSection from '../common/FormCloseSection';
import {
	getDelinquencyStartingStructure,
	getDelinquencyValidationStartingStructure,
} from './AssumptionItems/Delinquency';
import {showSnackbar} from 'state/actions/Snackbar';

const AssumptionForm = ({onClose, editItemId, datasets, showClose = true, onSave = onClose, showSnackbar}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [formData, mergeFormData, setFormData] = useMergedState({
		name: '',

		cashFlowState: false,
		firstLoss: {
			runMode: null,
		},
		generalAssumptions: {
			pastSettlesDate: null,
		},

		collateralState: false,
		default: {
			rate: null,
			vectorId: null,
			type: DEFAULT_TYPE_OPTIONS[0].value,
		},
		prepayments: {
			rate: null,
			vectorId: null,
			type: PRE_PAY_OPTIONS[0].value,
		},
		loss: {
			rate: null,
			vectorId: null,
			type: LOSS_TYPE_OPTIONS[0].value,
			recovDelayMonths: '0',
			prinLossPctNonPerf: '0',
			liqPeriod: '0',
		},

		interestRateState: false,
		rateAssumptions: [
			{
				indexName: null,
				rate: null,
				vectorId: null,
			},
		],

		delinquencyState: false,
		delinquency: getDelinquencyStartingStructure(),
	});
	const [validationErrors, mergeValidationErrors] = useMergedState({
		name: '',
		//collateral
		default: {
			rate: '',
			vectorId: '',
			type: null,
		},
		prepayments: {
			rate: '',
			vectorId: '',
			type: null,
		},
		loss: {
			rate: '',
			vectorId: '',
			type: null,
			recovDelayMonths: null,
			prinLossPctNonPerf: null,
			liqPeriod: null,
		},

		delinquency: getDelinquencyValidationStartingStructure(),
	});

	const [indexColumns, setIndexColumns] = useState([]);
	const [assumptions, setAssumptions] = useState([]);
	const [vectors, setVectors] = useState([]);

	const max100Validator = value =>
		value ? (parseFloat(value) > 100 ? 'Must be between 0-100' : null) : 'Field is required';
	const requiredNoMessage = value => (value ? null : '');

	const additionalValidation = {
		loss: {
			liqPeriod: max100Validator,
		},
		delinquency: {
			delinqRate: {
				rate: max100Validator,
				vectorId: value =>
					formData.delinquency.delinqRate.type === DELINQUENCY_OPTIONS[2].value && !value
						? 'Field is required'
						: null,
			},
			servicerAdvance: {
				rate: max100Validator,
				base: requiredNoMessage,
				type: requiredNoMessage,
				vectorId: value =>
					formData.delinquency.servicerAdvance.type === DELINQUENCY_OPTIONS[2].value && !value
						? 'Field is required'
						: null,
			},
		},
	};

	useEffect(() => {
		setIsLoading(true);
		Promise.all([getAssumptions(), getVectors('rate')]).then(([assumptionData, vectorData]) => {
			setAssumptions(assumptionData);
			setVectors(vectorData);
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		//We are picking the first dataset since all ABS Suite rates types are present in every Ki dataset (it doesn't matter)
		if (datasets.length > 0) {
			getIndexColumnsFromService(datasets[0]._id).then(results =>
				setIndexColumns(results.map(opt => ({label: opt.name, value: opt._id})))
			);
		}
	}, []);

	useEffect(
		() => {
			if (editItemId) {
				getAssumption(editItemId).then(item => {
					setFormData(data => ({...data, ...item}));
				});
			}
		},
		[editItemId]
	);

	const isAnyTypeAdded = Object.keys(ASSUMPTION_PROP_TO_TYPE)
		.map(key => formData[key])
		.includes(true);

	const hasObjectValidationError = object =>
		Object.entries(object)
			.map(item => item[1] !== null)
			.includes(true);

	const isFormValid = () => {
		if (validationErrors.name === null && isAnyTypeAdded) {
			if (
				formData.collateralState &&
				['default', 'prepayments', 'loss']
					.map(item => {
						const {rate, vectorId, ...rest} = validationErrors[item];
						return (rate !== null && vectorId !== null) || hasObjectValidationError(rest);
					})
					.includes(true)
			) {
				return false;
			}

			// For interest rates we don't need to display errors, so validationErrors don't need to be updated
			if (
				formData.interestRateState &&
				(formData.rateAssumptions.length === 0 ||
					formData.rateAssumptions.map(item => !item.rate && !item.vectorId).includes(true))
			) {
				return false;
			}

			const {delinqRate, nonPerfLoansToDef, servicerAdvance} = formData.delinquency;
			// At lest one of the sections needs to be valid
			if (
				formData.delinquencyState &&
				// None of sections has basic fields set
				((!delinqRate.type && !nonPerfLoansToDef.flag && !servicerAdvance.base) ||
					//Any of sections is partially valid
					((delinqRate.type && hasObjectValidationError(validationErrors.delinquency.delinqRate)) ||
						((servicerAdvance.base || servicerAdvance.type) &&
							hasObjectValidationError(validationErrors.delinquency.servicerAdvance)) ||
						(nonPerfLoansToDef.flag &&
							hasObjectValidationError(validationErrors.delinquency.nonPerfLoansToDef))))
			) {
				return false;
			}

			return true;
		}
		return false;
	};

	const isAddButtonVisible = Object.keys(ASSUMPTION_PROP_TO_TYPE)
		.map(key => formData[key])
		.includes(false);

	const onSubmit = () => {
		setIsLoading(true);
		saveAssumption(formData).then(saved => {
			showSnackbar('Assumption saved successfully');
			onSave(saved);
		});
	};

	const checkValidation = (data, errorObject, validationObject = additionalValidation) => {
		Object.entries(data).forEach(([key, value]) => {
			if (errorObject && Object.prototype.hasOwnProperty.call(errorObject, key)) {
				if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
					checkValidation(value, errorObject[key], validationObject ? validationObject[key] : null);
				} else if (validationObject && validationObject[key]) {
					errorObject[key] = validationObject[key](value);
				} else {
					const isValid = (value && Array.isArray(value) === false) || (Array.isArray(value) && value.length);
					errorObject[key] = isValid ? null : 'Field is required';
				}
			}
		});
	};

	const onTypeAdd = type => {
		mergeFormData({[type]: true});
	};

	const onAssumptionChange = change => {
		let dataToMerge = change;
		if (typeof change === 'function') {
			dataToMerge = change(formData);
		}
		mergeFormData(dataToMerge);
	};

	useEffect(
		() => {
			const newValidationErrors = _cloneDeep(validationErrors);
			// Name has it's own validation
			delete newValidationErrors['name'];
			const clonedFormData = _cloneDeep(formData);
			delete clonedFormData['name'];
			checkValidation(clonedFormData, newValidationErrors);
			mergeValidationErrors(newValidationErrors);
		},
		[formData]
	);

	return (
		<section className={stylesForm.section}>
			{showClose && <FormCloseSection onClose={onClose} />}
			<form className={stylesForm.form}>
				<section className={stylesForm.scrollable}>
					<NameForm
						label="Assumption"
						items={assumptions}
						mergeFormData={mergeFormData}
						mergeValidationErrors={mergeValidationErrors}
						nameFormData={formData.name}
						nameValidationErrors={validationErrors.name}
						editItemId={editItemId}
					/>
					<Cashflow
						isVisible={formData.cashFlowState}
						settleDate={formData.generalAssumptions?.pastSettlesDate}
						cashFlow={formData.firstLoss?.runMode}
						onChange={onAssumptionChange}
					/>
					<Collateral
						isVisible={formData.collateralState}
						prepayments={formData.prepayments}
						default={formData.default}
						loss={formData.loss}
						onChange={onAssumptionChange}
						vectors={vectors.map(x => ({label: x.name, value: x._id}))}
						validationErrors={validationErrors}
					/>
					<InterestRate
						isVisible={formData.interestRateState}
						indexList={indexColumns}
						rateAssumptions={formData.rateAssumptions}
						vectors={vectors}
						onChange={onAssumptionChange}
					/>
					<Delinquency
						isVisible={formData.delinquencyState}
						validationErrors={validationErrors.delinquency}
						delinquency={formData.delinquency}
						vectors={vectors}
						onChange={onAssumptionChange}
					/>
					<div className={stylesForm.mainAddItem}>
						<KiMenu
							isHidden={!isAddButtonVisible}
							icon="add_circle"
							position={isAnyTypeAdded ? 'bottomLeft' : 'topLeft'}
							buttonId="addTypeButton"
							className={stylesForm.addItemButton}
						>
							{Object.entries(ASSUMPTION_PROP_TO_TYPE).map(([key, item]) => (
								<KiMenuItem
									key={key}
									label={item}
									isHidden={formData[key]}
									onClick={() => onTypeAdd(key)}
								/>
							))}
						</KiMenu>
						{isAddButtonVisible && <label htmlFor="addTypeButton">Add Assumption Type element</label>}
						{!isAnyTypeAdded && <div className={stylesForm.note}>It&apos;s not active when empty</div>}
					</div>
				</section>
				<div className="inline-column-form-buttons">
					<KiButton flat primary onClick={onClose}>
						Cancel
					</KiButton>
					<KiButton disabled={isLoading || !isFormValid()} raised primary onClick={onSubmit}>
						Save
					</KiButton>
				</div>
			</form>
		</section>
	);
};

AssumptionForm.propTypes = {
	onClose: PropTypes.func.isRequired,
	editItemId: PropTypes.string,
	datasets: PropTypes.array,
	showClose: PropTypes.bool,
	onSave: PropTypes.func,
	showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	datasets: state.datasetList.data,
});

export default connect(
	mapStateToProps,
	{showSnackbar}
)(AssumptionForm);
