import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchDefaultMappingById = mappingId => {
	return fetch(`${apiUrl}/businessFunctions/${mappingId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchDefaultMappingsByDatasetId = datasetId => {
	return fetch(`${apiUrl}/businessFunctions/datasetId/${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const updateDefaultMapping = (mappingId, mappingObj) => {
	return fetch(`${apiUrl}/businessFunctions/${mappingId}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(mappingObj),
	}).then(_handleResponse);
};

export default {
	fetchDefaultMappingById,
	fetchDefaultMappingsByDatasetId,
	updateDefaultMapping,
};
