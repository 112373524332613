import React, {Fragment, useEffect, useState} from 'react';
import KiAppBar from '../../components/KiAppBar';
import PropTypes from 'prop-types';
import KiInput from '../../components/KiInput';
import {fetchLedgers, updateLedgerPeriodDate} from '../../api/ledgersApi';
import LedgerTable from './components/LedgerTable';
import {filterItemList} from '../../utils/filteringUtil';
import {connect} from 'react-redux';
import {resetState} from './actions';
import './ledgers.scss';
import KiMenu from '../../components/KiMenu';
import KiMenuItem from '../../components/KiMenu/KiMenuItem';
import {KiIconCsv} from '../../components/KiIcons';
import AdminAvailable from '../../components/Available/AdminAvailable';
import KiDatePicker from '../../components/KiDatePicker';
import {showSnackbar} from '../../state/actions/Snackbar';
import KiModal from '../../components/KiModal';
import Loader from './Loader';
import LedgerExportModal from './components/LedgerExportModal';

function Ledgers({resetState, showSnackbar, app}) {
	const [ledgersList, setLedgersList] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredLedgersList, setFilteredLedgersList] = useState([]);
	const [newPeriodDate, setNewPeriodCloseDate] = useState(null);
	const [isPeriodModalActive, setPeriodModalActive] = useState(false);
	const [isExportModalActive, setExportModalActive] = useState(false);
	const [checked, setChecked] = useState(new Set());
	const [isLoading, setLoading] = useState(true);

	const submitClosePeriod = () => {
		setLoading(true);
		updateLedgerPeriodDate(Array.from(checked), newPeriodDate).then(res => {
			setLedgersList(res.data);
			showSnackbar('Close period update completed.');
			setLoading(false);
		});
		setPeriodModalActive(false);
	};

	useEffect(() => {
		document.title = `${app.kiVersion} - Ledgers`;
		resetState();
		fetchLedgers().then(ledgers => {
			setLedgersList(ledgers);
			setLoading(false);
		});
	}, []);

	useEffect(
		() => {
			setChecked(new Set());
		},
		[searchTerm]
	);

	useEffect(
		() => {
			setFilteredLedgersList(filterItemList(searchTerm, ledgersList, ['datasetName', 'fundingVehicleName']));
		},
		[ledgersList, searchTerm]
	);

	const MODAL_ACTIONS_DATE = [
		{
			label: 'Cancel',
			onClick: () => setPeriodModalActive(false),
		},
		{
			label: 'Confirm',
			disabled: !newPeriodDate,
			onClick: submitClosePeriod,
		},
	];

	return (
		<div className="ledger">
			<header>
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1>Ledgers</h1>
					</div>
					<AdminAvailable>
						<div className="right-controls">
							<KiMenu
								icon="more_vert"
								className="top-bar-menu"
								position="topRight"
								title={'Open ledger action'}
							>
								<KiMenuItem
									disabled={!checked.size}
									icon="book"
									label="Close Period"
									onClick={() => setPeriodModalActive(true)}
								/>
								<KiMenuItem
									disabled={!checked.size}
									icon={<KiIconCsv />}
									label="Export Posts"
									onClick={() => setExportModalActive(true)}
								/>
							</KiMenu>
						</div>
						<KiModal
							active={isPeriodModalActive}
							actions={MODAL_ACTIONS_DATE}
							onClose={() => setPeriodModalActive(false)}
							header="Close Period"
						>
							<KiDatePicker
								label="Close Date"
								onChange={date => setNewPeriodCloseDate(date)}
								value={newPeriodDate}
								className="datepicker"
							/>
							<span>Selected ledgers currently closed on or past this date will not be affected</span>
						</KiModal>
						<LedgerExportModal
							isActive={isExportModalActive}
							setModalActive={setExportModalActive}
							ledgers={checked}
						/>
					</AdminAvailable>
				</KiAppBar>
			</header>
			<section className="ki-panel fixed">
				<Loader isActive={isLoading}>
					<Fragment>
						{ledgersList.length > 0 && (
							<div className="search-box">
								<div>
									<KiInput
										label="Search by Ledger Name"
										name="ledgerListFilter"
										value={searchTerm}
										onChange={val => setSearchTerm(val)}
										maxLength={100}
										className="searchInput"
									/>
								</div>
							</div>
						)}
						{filteredLedgersList.length ? (
							<LedgerTable ledgers={filteredLedgersList} active={checked} setActive={setChecked} />
						) : (
							<div className="no-list-items-container">
								<div>No ledgers found</div>
							</div>
						)}
					</Fragment>
				</Loader>
			</section>
			<footer />
		</div>
	);
}

Ledgers.propTypes = {
	resetState: PropTypes.func.isRequired,
	showSnackbar: PropTypes.func.isRequired,
	app: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	app: state.app,
});

export default connect(mapStateToProps, {resetState, showSnackbar})(Ledgers);
