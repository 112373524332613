import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select, {components as ReactSelectComponents} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './KiSelect.scss';
import {closeMenuOnScroll} from '../../utils/menuOnScroll';

const CustomOption = props => {
	const {data, innerRef, innerProps} = props;
	return data.custom ? (
		<div ref={innerRef} {...innerProps} style={{padding: '10px'}}>
			<a style={{display: 'block', cursor: 'pointer', fontWeight: 'bold'}} onClick={data.onClick}>
				{data.text}
			</a>
		</div>
	) : (
		<ReactSelectComponents.Option {...props} />
	);
};

CustomOption.propTypes = {
	data: PropTypes.object,
	innerRef: PropTypes.func,
	innerProps: PropTypes.object,
};
// KiSelect component uses closeMenuOnScroll inside
export class KiSelect extends Component {
	static propTypes = {
		menuPosition: PropTypes.string,
		menuPortalTarget: PropTypes.any,
		classNamePrefix: PropTypes.string,
		styles: PropTypes.object,
		errorMessage: PropTypes.string,
		components: PropTypes.object,
		readOnly: PropTypes.bool,
		selectRef: PropTypes.shape({current: PropTypes.object}),
	};

	static defaultProps = {
		menuPosition: 'fixed',
		menuPortalTarget: document.body,
		classNamePrefix: 'aut-select',
		styles: {},
		components: {},
		readOnly: false,
	};

	getRenderedStyles = (styles = {}) => ({
		...styles,
		menuPortal: base => ({...base, zIndex: 99999, ...styles.menuPortal}),
	});

	render() {
		const {styles, components, selectRef, ...rest} = this.props;

		return (
			<React.Fragment>
				<Select
					components={{Option: CustomOption, ...components}}
					closeMenuOnScroll={e => closeMenuOnScroll(e)}
					styles={this.getRenderedStyles(styles)}
					ref={selectRef}
					{...rest}
					{...this.props.readOnly && {
						menuIsOpen: false,
						isSearchable: false,
						isClearable: false,
					}}
				/>
				{this.props.errorMessage && <span className="ki-select-error"> {this.props.errorMessage} </span>}
			</React.Fragment>
		);
	}
}

export class KiCreatable extends KiSelect {
	getOnChange = () => {
		const newOnChange = values => {
			if (values === null) {
				this.props.onChange([]);
			} else {
				this.props.onChange(values);
			}
		};
		return newOnChange;
	};

	render() {
		const {styles, ...rest} = this.props;

		return <CreatableSelect styles={this.getRenderedStyles(styles)} {...rest} onChange={this.getOnChange()} />;
	}
}

export {KiCreatable as Creatable};
export default KiSelect;
