import PropTypes from 'prop-types';
import React from 'react';
import {getDescription} from 'ki-common/utils/constraintUtil';

export const ConstraintListItemView = props => {
	const description = getDescription(props.item, props.columns);
	// The pre tag lets the /n in description render correctly

	return (
		<span
			style={{
				whiteSpace: 'pre-wrap',
			}}
			title={description}
		>
			{description}
		</span>
	);
};

ConstraintListItemView.propTypes = {
	item: PropTypes.object,
	columns: PropTypes.array,
};

export default ConstraintListItemView;
