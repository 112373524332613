// Dependencies
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
// Relative
import styles from './wizard.theme.scss';
import WizardHeader from './components/WizardHeader';
import WizardFooter from './components/WizardFooter';

class KiWizard extends Component {
	static propTypes = {
		currentStepNumber: PropTypes.number,
		children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
		numberOfSteps: PropTypes.number,
		showSpinner: PropTypes.bool,
	};

	render() {
		const {children, currentStepNumber} = this.props;
		let percentageFilled = (currentStepNumber - 1) / (this.props.numberOfSteps - 1) * 100;

		if (currentStepNumber - 1 === 0) {
			percentageFilled = 0;
		}

		if (currentStepNumber >= this.props.numberOfSteps) {
			percentageFilled = 100;
		}

		// if we use a conditional statement to remove a child then need to remove undefined
		const steps = children.filter(child => child);
		const step = steps[currentStepNumber - 1];

		const {
			cancelButtonLabel,
			enableBackButton,
			enableCancelButton,
			disableNextButton,
			nextButtonLabel,
			onBackClick,
			onCancelClick,
			onNextClick,
			showBackButton,
			showCancelButton,
			showNextButton,
		} = get(step, 'props', {});

		return (
			<div className={styles.wizard}>
				<WizardHeader
					steps={steps}
					percentageFilled={percentageFilled}
					wizardScreenIndex={currentStepNumber - 1}
				/>
				<div className={styles.kiPanel}>{step}</div>
				<WizardFooter
					onCancelClick={onCancelClick}
					onNextClick={onNextClick}
					onBackClick={onBackClick}
					showBackButton={showBackButton}
					showCancelButton={showCancelButton}
					showNextButton={showNextButton}
					nextButtonLabel={nextButtonLabel}
					cancelButtonLabel={cancelButtonLabel}
					disableNextButton={disableNextButton}
					enableBackButton={enableBackButton}
					enableCancelButton={enableCancelButton}
					showSpinner={this.props.showSpinner}
				/>
			</div>
		);
	}
}

export default KiWizard;
