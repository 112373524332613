import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import LedgerTopBar from '../LedgerTopBar';
import {getToday} from '../../../../utils/dateUtils';
import './LedgerGeneratePosts.scss';
import LedgerGeneratePostsTable from './LedgerGeneratePostsTable';
import {calculateEffectiveDates, fetchLedger, fetchTemplates, generateTemplates} from '../../../../api/ledgersApi';
import GeneratePostsHeader from './GeneratePostsHeader';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../../state/actions/Snackbar';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import KiInput from '../../../../components/KiInput';
import Loader from '../../Loader';
import ledgerSocket from '../../../../socket/ledgerSocket';

const TEMPLATE_SOCKET_NAMESPACE = 'templates';
const CANNOT_CALCULATE = 'Cannot calculate';

function LedgerGeneratePosts({showSnackbar}) {
	const [searchTerm, setSearchTerm] = useState('');
	const [postDate, setPostDate] = useState(getToday());
	const [data, setData] = useState([]);
	const [isCalculateDisabled, setIsCalculateDisabled] = useState(true);
	const [isGenerationFired, setIsGenerationFired] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [isLoadingCalculate, setLoadingCalculate] = useState(true);
	const [ledger, setLedger] = useState({});
	const [areSocketsOn, setAreSocketsOn] = useState(false);
	const {id} = useParams();

	const setTemplatesWithProperties = templates => {
		return templates.map((template, index) => ({
			...template,
			index: index,
			visible: true,
			checked: false,
			calculatedEffectiveDate: '',
			effectiveDateCalendar: '',
		}));
	};

	const deactivateAll = () => {
		setData(data.map(item => ({...item, checked: false})));
	};

	const handleCalculateTemplates = () => {
		setLoadingCalculate(true);
		calculateEffectiveDates(ledger.datasetId, ledger.fundingVehicleId, postDate, data)
			.then(res => {
				setData(pData =>
					pData.map((item, index) => ({
						...item,
						calculatedEffectiveDate:
							res[index].calculatedDate === null ? CANNOT_CALCULATE : res[index].calculatedDate,
						effectiveDateCalendar: res[index].name,
					}))
				);
				setLoadingCalculate(false);
			})
			.catch(() => {
				setData(pData =>
					pData.map(item => ({
						...item,
						calculatedEffectiveDate: CANNOT_CALCULATE,
						effectiveDateCalendar: CANNOT_CALCULATE,
					}))
				);
				setLoadingCalculate(false);
			});
	};

	const getTemplates = () => {
		fetchTemplates(id).then(templates => {
			setData(setTemplatesWithProperties(templates));
			setLoading(false);
		});
	};

	const areAllChecked = (pData = data) => {
		const visibleCount = pData.filter(({visible}) => visible === true).length;
		const checkedCount = pData.filter(({checked, visible}) => checked === true && visible === true).length;
		if (pData) {
			return visibleCount === checkedCount && visibleCount !== 0;
		}
	};

	const toggleCheckAll = () => {
		setData(pData => {
			const isActive = !areAllChecked(pData);
			return pData.map(item => ({...item, checked: isActive && item.visible}));
		});
	};

	const unCheckAll = () => {
		setData(pData => {
			return pData.map(item => ({...item, checked: false && item.visible}));
		});
	};

	const toggle = index => {
		setData(pData => {
			pData[index].checked = !pData[index].checked;
			return [...pData];
		});
	};

	const filterChecked = data => {
		return data.filter(({checked}) => checked === true);
	};

	const generate = () => {
		setIsGenerationFired(true);
		setSearchTerm('');
		deactivateAll();
		generateTemplates(id, postDate, filterChecked(data))
			.then(() => {
				showSnackbar('Generate action initiated.');
				setIsGenerationFired(false);
			})
			.catch(err => {
				showSnackbar(err.message);
			});
	};

	const calculate = () => {
		setIsCalculateDisabled(true);
		handleCalculateTemplates();
	};

	const handlePostDateChange = date => {
		setPostDate(dateToShortDate(date));
		setIsCalculateDisabled(false);
	};

	const BUTTON_ACTIONS = [
		{
			label: 'GENERATE',
			disabled:
				isGenerationFired ||
				data.some(template => template.calculatedEffectiveDate === CANNOT_CALCULATE) ||
				!data.some(template => template.checked),
			onClick: () => generate(),
		},
	];

	//TODO move to utils
	const filterList = (term, list = [], fields) => {
		return list.map(item => {
			let searchString = '';
			fields.forEach(field => {
				if (item[field] !== null && item[field] !== undefined) {
					searchString += item[field];
				}
			});
			return {...item, visible: searchString.toLowerCase().includes(term.toLowerCase())};
		});
	};

	useEffect(
		() => {
			if (searchTerm !== '') {
				unCheckAll();
				setData(pData => filterList(searchTerm, pData, ['code', 'description', 'effectiveDateCalendar']));
			} else {
				setData(pData => pData.map(item => ({...item, visible: true})));
			}
		},
		[searchTerm]
	);

	useEffect(
		() => {
			if (data && data.length && data[0].calculatedEffectiveDate === '' && ledger.datasetId) {
				handleCalculateTemplates(postDate);
			}
		},
		[data, ledger]
	);

	useEffect(() => {
		fetchLedger(id).then(ledger => {
			setLedger(ledger);
		});
	}, []);

	useEffect(() => {
		getTemplates();
	}, []);

	const updateLastGeneration = (index, lastGeneration) => {
		setData(pData => {
			const newData = [...pData];
			newData[index].lastGeneration = lastGeneration;

			return newData;
		});
	};

	useEffect(
		() => {
			if (data.length > 0 && !areSocketsOn) {
				data.forEach(template => {
					const eventName = `${id}.${template._id}`;
					ledgerSocket.on(
						eventName,
						eventData => updateLastGeneration(template.index, eventData.lastGeneration),
						TEMPLATE_SOCKET_NAMESPACE
					);
					ledgerSocket.emitIn(TEMPLATE_SOCKET_NAMESPACE, 'register', eventName);
				});
				setAreSocketsOn(true);
			}
		},
		[data]
	);

	useEffect(() => {
		return () => {
			data.forEach(template => ledgerSocket.off(`${id}.${template._id}`));
		};
	}, []);

	return (
		<section className="ledger generate-posts">
			<LedgerTopBar ledgerId={id} section="Generate Posts" existingLedger={ledger} />
			<GeneratePostsHeader
				ledgerCloseDate={ledger && ledger.closeDate}
				actions={BUTTON_ACTIONS}
				setPostDate={handlePostDateChange}
				postDate={postDate}
				calculate={calculate}
				calculateDisabled={isCalculateDisabled}
			/>
			<div className="ki-panel">
				<div className="horizontal-inputs-container">
					<div className="long-wrapper">
						<KiInput
							label="Search by Post Code, Post Description or Effective Date Calendar"
							name="ledgerListFilter"
							value={searchTerm}
							onChange={val => setSearchTerm(val)}
							className="search-input"
							maxLength={100}
						/>
					</div>
				</div>
				<Loader isActive={isLoading}>
					<LedgerGeneratePostsTable
						data={data}
						toggleActive={toggle}
						toggleAll={toggleCheckAll}
						areAllChecked={areAllChecked()}
						loading={isLoadingCalculate}
					/>
				</Loader>
			</div>
		</section>
	);
}

LedgerGeneratePosts.propTypes = {
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(null, {showSnackbar})(LedgerGeneratePosts);
