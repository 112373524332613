import PropTypes from 'prop-types';
import React, {Component} from 'react';
import SchemaColumn from '../SchemaColumn';
import KiButton from 'components/KiButton';
import ErrorSummary from './ErrorSummary';
import CollapsiblePanel from 'components/CollapsiblePanel';
import './SchemaEditor.scss';

class SchemaEditor extends Component {
	static propTypes = {
		schemaColumns: PropTypes.array,
		errors: PropTypes.array,
		collapsed: PropTypes.bool,
		toggleCollapsed: PropTypes.func,
		saveColumn: PropTypes.func,
		validateFullSubmission: PropTypes.func,
		validateDisabled: PropTypes.bool,
		isReadOnly: PropTypes.bool,
	};

	state = {
		columnFilter: [],
		mapColumns: [],
		mapOptionalColumns: [],
	};

	componentDidMount() {
		this.setState({
			mapColumns: this.getMappedColumns(this.props.schemaColumns),
		});
		this.setState({
			mapOptionalColumns: this.getMappedOptionalColumns(this.props.schemaColumns),
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.schemaColumns !== prevProps.schemaColumns) {
			this.setState({
				mapColumns: this.getMappedColumns(this.props.schemaColumns),
			});
		}
		if (this.props.schemaColumns !== prevProps.schemaColumns) {
			this.setState({
				mapOptionalColumns: this.getMappedOptionalColumns(this.props.schemaColumns),
			});
		}
	}

	getMappedColumns = (columns = []) =>
		columns.reduce((acc, column) => {
			if (column.mapType === 'required' && !(column.messages || []).find(m => m.code === 'columnNotFound')) {
				return [...acc, column.mapColumn];
			}
			return acc;
		}, []);

	getMappedOptionalColumns = (columns = []) =>
		columns.reduce((acc, column) => {
			if (column.mapType === 'optional' && !(column.messages || []).find(m => m.code === 'columnNotFound')) {
				return [...acc, column.mapColumn];
			}
			return acc;
		}, []);

	setColumnFilter = (ids = []) => {
		this.setState({columnFilter: ids});
	};

	clearColumnFilter = () => {
		this.setState({columnFilter: []});
	};

	handleColumnBlur = () => {
		this.setState({focusedColumnId: null});
	};

	handleColumnFocus = id => {
		if (id && !this.props.isReadOnly) {
			this.setState({focusedColumnId: id});
		}
	};

	render() {
		const {
			schemaColumns = [],
			errors = [],
			collapsed,
			toggleCollapsed,
			validateFullSubmission,
			validateDisabled,
		} = this.props;

		const {columnFilter} = this.state;

		return (
			<CollapsiblePanel
				className="submission-schema-editor ki-panel"
				title="Schema Editor"
				collapsed={collapsed}
				onCollapseToggleClick={toggleCollapsed}
			>
				<ErrorSummary errors={errors} setFilter={this.setColumnFilter} clearFilter={this.clearColumnFilter} />
				<div className="schema-summary">
					<div className="info">
						<div>{schemaColumns.length} columns found</div>
					</div>
					<div className="actions">
						<KiButton
							label="validate"
							raised
							primary
							disabled={!!errors.filter(e => e.level === 'error').length || validateDisabled}
							onClick={validateFullSubmission}
						/>
					</div>
				</div>
				<article className="schema-column-list">
					{schemaColumns
						.filter(c => columnFilter.length === 0 || columnFilter.includes(c.id))
						.map(sc => (
							<SchemaColumn
								key={sc.id}
								columnData={sc}
								mapColumns={this.state.mapColumns}
								mapOptionalColumns={this.state.mapOptionalColumns}
								focused={this.state.focusedColumnId === sc.id}
								onBlur={this.handleColumnBlur}
								onFocus={this.handleColumnFocus}
								onChange={this.props.saveColumn}
								isReadOnly={this.props.isReadOnly}
							/>
						))}
				</article>
			</CollapsiblePanel>
		);
	}
}

export default SchemaEditor;
