// Globals
import React, {useContext} from 'react';
import Select from 'react-select';
import dateHelpers from 'ki-common/utils/dateHelpers';

// Website imports
import KiDatePicker from '../../../../components/KiDatePicker';
import '../../../../components/FlyoutManageViews/components/ViewForms/ViewForms.scss';

// Local imports
import {DataExplorerContext} from '../../DataExplorerContext';

const granularityOptions = [
	{
		label: 'daily',
		value: 'daily',
	},
	{
		label: 'weekly',
		value: 'weekly',
	},
	{
		label: 'weeklyFixed',
		value: 'weeklyFixed',
	},
	{
		label: 'biWeekly',
		value: 'biWeekly',
	},
	{
		label: 'biWeeklyFixed',
		value: 'biWeeklyFixed',
	},
	{
		label: 'fortnightly',
		value: 'fortnightly',
	},
	{
		label: 'monthly',
		value: 'monthly',
	},
	{
		label: 'quarterly',
		value: 'quarterly',
	},
	{
		label: 'semiAnnually',
		value: 'semiAnnually',
	},
	{
		label: 'annually',
		value: 'annually',
	},
];

const CohortBandsDate = () => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark: {
			explorerData,
			explorerData: {granularity, dateRange},
		},
		actions,
		setExplorerData,
	} = dataExplorerContext;

	const handleStartDateChange = value => {
		setExplorerData({dateRange: {start: value, end: explorerData.dateRange?.end || ''}}, true);
	};

	const handleEndDateChange = value => {
		setExplorerData({dateRange: {start: explorerData.dateRange?.start || '', end: value}}, true);
	};

	const handleSetGranularity = option => {
		actions.setGranularity(option.value);
	};

	return (
		<div className="cohort-bands">
			<span className="form-instruction">Granularity Type</span>
			<Select
				classNamePrefix="aut-select"
				isClearable={false}
				options={granularityOptions}
				value={granularityOptions.find(opt => opt.value === granularity)}
				onChange={handleSetGranularity}
			/>

			<div className="group-inputs">
				<KiDatePicker
					label="Start Date"
					onChange={val => handleStartDateChange(dateHelpers.dateToShortDate(val))}
					value={dateRange?.start || ''}
					popperContainer={null}
				/>

				<KiDatePicker
					label="End Date"
					onChange={val => handleEndDateChange(dateHelpers.dateToShortDate(val))}
					value={dateRange?.end || ''}
					popperContainer={null}
				/>
			</div>
		</div>
	);
};

export default CohortBandsDate;
