import {apiUrl, _checkAuth, _getHeaders, _checkStatus} from 'api/apiUtils';

export const fetchDates = (datasetId, includeSystemDates, includePortfolioDates, includeUnmapped) => {
	const url = new URL(`${apiUrl}/datasets/${datasetId}/dates`, document.baseURI);
	if (includeSystemDates) url.searchParams.set('includeSystemDates', true);
	if (includePortfolioDates) url.searchParams.set('includePortfolioDates', true);
	if (includeUnmapped) url.searchParams.set('includeUnmapped', true);
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const fetchMappedDates = datasetId => {
	const url = new URL(`${apiUrl}/datasets/${datasetId}/dates/mappedDates`, document.baseURI);
	return fetch(url, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const fetchPortfolioDates = datasetId => {
	return fetch(`${apiUrl}/datasets/${datasetId}/dates/portfolio`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const fetchDate = id => {
	return fetch(`${apiUrl}/datasets/all/dates/${id}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const createDate = (datasetId, date) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/dates`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(date),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const updateDate = (datasetId, id, changes) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/dates/${id}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(changes),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const deleteDate = (datasetId, id) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/dates/${id}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const createEntry = (datasetId, dateId, entry) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/dates/${dateId}/entry/`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(entry),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const updateEntry = (datasetId, dateId, entryId, changes) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/dates/${dateId}/entry/${entryId}`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(changes),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const deleteEntry = (datasetId, dateId, entryId) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/dates/${dateId}/entry/${entryId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const getCalculatedDate = (datasetId, groupId, statementDate) => {
	return fetch(`${apiUrl}/calculatedDates/single/${datasetId}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify({
			datasetId,
			groupId,
			statementDate,
		}),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const getCalculatedDates = (request, datasetId) => {
	return fetch(`${apiUrl}/calculatedDates/${datasetId}`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(request),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const getCalculatedDateInfo = (
	dateContextId,
	dateContextList,
	statementDate,
	fundingVehicleId,
	isFixedDate,
	datasetId
) => {
	const dateContext = dateContextList.find(d => d._id === dateContextId);
	const calculatedDatesRequest = [
		{
			groupId: dateContext.groupId,
			statementDate: statementDate,
			fundingVehicleId: fundingVehicleId,
			isFixedDate: isFixedDate,
		},
	];
	return getCalculatedDates(calculatedDatesRequest, datasetId).then(info => {
		if (info.length) return info;
	});
};

export default {
	fetchDates,
	fetchDate,
	createDate,
	updateDate,
	deleteDate,
	createEntry,
	updateEntry,
	deleteEntry,
	getCalculatedDate,
	getCalculatedDates,
	fetchMappedDates,
	getCalculatedDateInfo,
};
