// Global imports
import React, {createContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';

// Project imports
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import {usePrevious, useMergedState} from 'utils/customHooks';

// Local imports
import {
	fetchBookmarkById,
	fetchDefaultBookmark,
	fetchScenarios,
	fetchModel,
	fetchQuickFilters,
	fetchBorrowingBaseScenarios,
} from 'api/fundingAnalysisApi';
import {fetchPortfolioDates, getCalculatedDateInfo} from 'api/datasetDatesApi';

const defaultState = {
	_id: null,
	createdBy: null,
	createdAt: null,
	updatedAt: null,
	isDefault: false,
	isFavorite: false,
	name: '',
	datasetId: null,
	createDate: null,
	tags: [],
	settings: {
		viewType: null, // scenario|summary|pool|excess|breaches
		tableType: null,
		sortOrder: 'asc',
		sortColumn: null,
		constraintGroup: 'setup',
		statementDate: dateToShortDate(new Date()),
		dateContext: '',
		scenarioType: 'assetSnapshot',
		scenarioId: null,
		transferDate: null,
		fundingVehicleIds: ['all'],
	},
	viewColumns: {
		scenario: [],
		summary: [],
		breaches: [],
		compliance: [],
	},
};

// Main context
export const BookmarkContext = createContext();
BookmarkContext.displayName = 'Funding Bookmark Context';

// Main provider
export const BookmarkProvider = ({children}) => {
	const history = useHistory();
	const [state, setState, resetState] = useMergedState(defaultState);
	const [parent, setParent] = useState({});
	const [appliedBookmark, setAppliedBookmark] = useState(_.cloneDeep(defaultState));
	const [scenario, setScenario] = useState({});
	const [appliedScenario, setAppliedScenario] = useState({});
	const [model, setModel] = useState({});
	const [hasChanges, setHasChanges] = useState(false);
	const prevState = usePrevious(state);

	// Initialization variables
	const [coreSettings, setCoreSettings, resetCoreSettings] = useMergedState({
		datasetId: '',
		bookmarkId: null,
		scenarioId: null,
	});

	// UI State Items
	const [datasetDateList, setDatasetDateList] = useState([]);
	const [scenarioList, setScenarioList] = useState([]);
	const [scenarioFilterList, setScenarioFilterList] = useState([]);
	const [scenarioBorrowingBaseList, setScenarioBorrowingBaseList] = useState([]);
	const [infoRibbonMessage, setInfoRibbonMessage] = useState(null);

	useEffect(
		() => {
			if (state?._id && prevState?._id && prevState._id === state._id) {
				setHasChanges(true);
			}
		},
		[state]
	);

	const resetBookmarkContext = () => {
		resetCoreSettings({
			datasetId: '',
			bookmarkId: null,
			scenarioId: null,
		});
	};

	const fetchScenarioQuickFilters = async ({statementDate, dateContext, viewType}) => {
		switch (viewType) {
			// Borrowing Base tableType
			case 'walkOfAssets':
			case 'borrowingBaseBreaches':
			case 'excessWithoutDuplication': {
				if (!coreSettings.datasetId) return;
				const scenariosRes = await fetchBorrowingBaseScenarios(
					coreSettings.datasetId,
					statementDate,
					dateContext
				);
				setScenarioBorrowingBaseList(scenariosRes);
				return scenariosRes;
			}
			case 'scenario':
				setScenarioFilterList(scenarioList);
				return scenarioList;
			default: {
				if (!coreSettings.datasetId) {
					return false; // Do not tyr to fetch lists with no datasetId
				}
				const lists = await fetchQuickFilters(
					coreSettings.datasetId,
					statementDate || dateToShortDate(new Date()),
					dateContext
				);
				setScenarioFilterList(lists.scenarioList);
				return lists.scenarioList;
			}
		}
	};

	// If there is a passed dataset use that, otherwise use state
	const getScenarios = async datasetIdParam => {
		const scenarios = await fetchScenarios(datasetIdParam || coreSettings.datasetId);
		setScenarioList(scenarios);
		return scenarios;
	};

	const getRibbonMessage = () => {
		if (!coreSettings.datasetId) return null;
		if (!['summary', 'eligibility', 'borrowingBase'].includes(state.settings.tableType)) {
			return null;
		}
		let targetDate = datasetDateList.find(dateColumn => dateColumn._id === state.settings.dateContext);
		if (!targetDate) {
			targetDate = datasetDateList.find(dateColumn => dateColumn.name === 'Latest Snapshot');
		}

		return getCalculatedDateInfo(
			targetDate._id,
			datasetDateList,
			state.settings.statementDate || dateToShortDate(new Date()),
			undefined,
			false,
			coreSettings.datasetId
		).then(res => {
			setInfoRibbonMessage(
				`Data results for ${res[0].calculatedDate} using Statement Date and ${targetDate.name} context`
			);
		});
	};

	useEffect(
		() => {
			getRibbonMessage();
			fetchScenarioQuickFilters(state.settings);
		},
		[state.settings.dateContext, state.settings.statementDate, state.settings.tableType]
	);

	const applyBookmark = (newBookmark = state, newScenario = scenario) => {
		setAppliedBookmark(_.cloneDeep(newBookmark));
		setAppliedScenario(_.cloneDeep(newScenario));
		setHasChanges(false);
	};

	// Just loads the correct bookmark into the state, another call is made to get data
	const loadBookmark = async (
		bookmarkData,
		apply = false,
		datasetDates = datasetDateList,
		scenarios = scenarioList
	) => {
		const settings = bookmarkData.settings;
		let targetScenario = scenario; // Used to prevent race condition with setScenario
		let scenarioContextList = []; // Used to prevent race condition with setBorrowingBaseScenarios

		// Default statement date to current date
		if (!settings.statementDate) {
			_.set(bookmarkData, 'settings.statementDate', dateToShortDate(new Date()));
		}

		if (settings.tableType === 'scenario') {
			// If this is a scenario view and none is selected use the first one from the list
			if (_.isEmpty(settings.scenarioId)) {
				_.set(bookmarkData, 'settings.scenarioId', scenarios[0]._id);
			}

			// Fetch the associated scenario
			targetScenario = scenarios.find(s => s._id === settings.scenarioId);
			setScenario(targetScenario);

			// Fetch the associated model
			// TODO check if its the same model to prevent extra calls
			let model = null;
			if (targetScenario?.fundingModelId && targetScenario.fundingModelId === 'Not Found') {
				model = await fetchModel(targetScenario.fundingModelId).catch(() => Promise.resolve(null));
			}
			setModel(model);
			_.set(bookmarkData, 'settings.isBlended', !!model?.isBlended);
		} else {
			// All non-scenario views need a date context
			if (!settings.dateContext) {
				const latestDateContext = datasetDates.find(date => date.name === 'Latest Snapshot');
				_.set(bookmarkData, 'settings.dateContext', latestDateContext._id);
			}
			scenarioContextList = await fetchScenarioQuickFilters(bookmarkData.settings);
		}

		// Check if the original scenario is still valid, default to End of Day / lastApproved
		if (settings.tableType === 'borrowingBase') {
			// Find the scenario in the list if it exists
			targetScenario = scenarioContextList.find(s => s._id === settings.scenarioId);
			// Fallback to first scenario in the list
			targetScenario = targetScenario || scenarioContextList[0];

			if (!targetScenario) {
				// Fallback to End of Day / lastApproved if no scenarios are found
				_.set(bookmarkData, 'settings.scenarioId', null);
				_.set(bookmarkData, 'settings.scenarioType', 'lastApproved');
			} else {
				_.set(bookmarkData, 'settings.scenarioId', targetScenario._id);
			}
		}

		resetState(bookmarkData);

		if (apply) {
			applyBookmark(bookmarkData, targetScenario);
		}
	};

	const loadBookmarkById = async bookmarkId => {
		const bookmarkData = await fetchBookmarkById(bookmarkId);
		loadBookmark(bookmarkData);
	};

	// If there is a passed dataset use that, otherwise use state
	const fetchDatasetDates = async datasetIdParam => {
		const dates = await fetchPortfolioDates(datasetIdParam || coreSettings.datasetId);
		setDatasetDateList(dates);
		return dates;
	};

	const _initializeBookmark = async () => {
		const {datasetId, bookmarkId, scenarioId} = coreSettings;
		// console.log(`_initializeBookmark datasetId:${datasetId}, bookmarkId:${bookmarkId}, scenarioId:${scenarioId}`)
		if (!datasetId) {
			return false; // Shortcut out since page is not loaded
		}

		// Clear all pre-existing fetch data

		const datasetDatesRes = await fetchDatasetDates(datasetId);
		let scenariosRes = await getScenarios(datasetId);

		let bookmarkData;
		if (bookmarkId) {
			//console.log(`_initializeBookmark bookmarkId`, bookmarkId);
			bookmarkData = await fetchBookmarkById(bookmarkId);
			// Links from the scenarios list will include the bookmarkId and the scenario Id
			// otherwise leave this field alone or bookmarks saved with a scenario will not load
			// the correct scenario
			if (scenarioId) {
				_.set(bookmarkData, 'settings.scenarioId', scenarioId);
			}
		} else if (scenarioId) {
			//console.log(`_initializeBookmark scenarioId`, scenarioId);
			bookmarkData = await fetchDefaultBookmark(datasetId, 'scenario');
			_.set(bookmarkData, 'settings.scenarioId', scenarioId);
			_.set(bookmarkData, 'settings.scenarioType', 'hypo');
		} else if (window.BORROWING_BASE_WOA_ENABLED) {
			//console.log(`_initializeBookmark datasetId`, datasetId);
			bookmarkData = await fetchDefaultBookmark(datasetId, 'borrowingBase');
			scenariosRes = await fetchBorrowingBaseScenarios(
				coreSettings.datasetId,
				_.get(bookmarkData, 'settings.statementDate', dateToShortDate(new Date())),
				bookmarkData.settings.dateContext
			);
		} else {
			//console.log(`_initializeBookmark datasetId`, datasetId);
			bookmarkData = await fetchDefaultBookmark(datasetId, 'summary');
		}
		if (bookmarkData) {
			await loadBookmark(bookmarkData, true, datasetDatesRes, scenariosRes);
		}
	};

	const initializeBookmark = async (datasetId, bookmarkId, scenarioId) => {
		resetState(defaultState);
		setParent(null);
		const newSettings = {
			datasetId,
			bookmarkId,
			scenarioId,
		};
		if (datasetId !== coreSettings.datasetId) {
			resetCoreSettings(newSettings); // Fully replace
		} else {
			setCoreSettings(newSettings); // Only change differences
		}
	};

	// When one of the core values changes, re-initialize the bookmark
	useEffect(
		() => {
			_initializeBookmark();
		},
		[coreSettings]
	);

	// Requires more than just a re-fetch of table data
	// Re-load for setting scenario, model, and isBlended
	const setScenarioId = async scenarioId => {
		const bookmarkData = _.cloneDeep(state);
		_.set(bookmarkData, 'settings.scenarioId', scenarioId);
		loadBookmark(bookmarkData);
	};

	// Requires more than just a re-fetch of table data
	const setViewType = async viewType => {
		setParent(null);
		const bookmarkData = _.cloneDeep(state);
		const latestSnapshotContext = datasetDateList.find(date => date.name === 'Latest Snapshot');

		const currentTableType = _.get(bookmarkData, 'settings.tableType', null);
		// const currentViewType = _.get(bookmarkData, 'settings.viewType', null);
		// console.log(`currentTableType: ${currentTableType}, currentViewType: ${currentViewType}, newViewType: ${viewType}`);

		// Handle if the viewType did not change and is borrowingBase
		if (currentTableType === 'borrowingBase' && viewType === 'borrowingBase') {
			return false;
		}

		// Handle the root of the viewType change
		if (currentTableType !== 'borrowingBase' && viewType === 'borrowingBase') {
			// Going from borrowing base type to any other type
			const bookmarkData = await fetchDefaultBookmark(coreSettings.datasetId, 'borrowingBase');
			history.push(`/fundingAnalysis/${bookmarkData.datasetId}/bookmark/${bookmarkData._id}`);
			return false;
		}
		if (currentTableType === 'borrowingBase' && viewType !== 'borrowingBase') {
			// Going from any other type to borrowing base type
			const bookmarkData = await fetchDefaultBookmark(coreSettings.datasetId, viewType);
			history.push(`/fundingAnalysis/${bookmarkData.datasetId}/bookmark/${bookmarkData._id}`);
			return false;
		}

		// Switching between non-borrowing base types
		_.set(bookmarkData, 'settings.transferDate', null);
		_.set(bookmarkData, 'settings.viewType', viewType);
		_.set(bookmarkData, 'settings.tableType', viewType);
		_.set(bookmarkData, 'settings.dateContext', latestSnapshotContext._id);

		switch (viewType) {
			case 'summary':
				_.set(bookmarkData, 'settings.scenarioType', 'lastApproved');
				_.set(bookmarkData, 'settings.scenarioId', '');
				_.set(bookmarkData, 'settings.constraintGroup', 'setup');
				break;
			case 'scenario':
				_.set(bookmarkData, 'settings.scenarioId', scenarioList[0]._id);
				_.set(bookmarkData, 'settings.scenarioType', 'hypo');
				_.set(bookmarkData, 'settings.statementDate', null);
				_.set(bookmarkData, 'settings.constraintGroup', 'setup');
				// isBlended set in loadBookmark method
				break;
			default:
				break;
		}

		loadBookmark(bookmarkData);
	};

	// Start - Drilldown Logic
	// Save a parent view of the current state, then modify the current view to add
	// required settings for the drilldown views.
	// Borrowing Base Drilldowns do not use the fvId field
	const loadDrillDown = (type, fvId = null) => {
		const newParent = _.cloneDeep(state);
		setParent(newParent);

		const bookmarkData = _.cloneDeep(state);
		_.set(bookmarkData, 'settings.viewType', type);
		if (fvId) {
			_.set(bookmarkData, 'settings.fundingVehicleIds', [fvId]);
		}
		loadBookmark(bookmarkData, true);
	};

	const restoreParent = () => {
		const bookmarkData = _.cloneDeep(parent);
		setParent(null);
		loadBookmark(bookmarkData, true);
	};
	// End - Drilldown Locig

	return (
		<BookmarkContext.Provider
			displayName="Funding Bookmark Provider"
			value={{
				bookmark: state,
				scenario,
				model,
				setBookmark: setState,
				loadBookmark,
				loadBookmarkById,
				initializeBookmark,
				setScenarioId,
				setViewType,
				loadDrillDown,
				parent,
				restoreParent,
				appliedBookmark,
				applyBookmark,
				appliedScenario,
				ui: {
					infoRibbonMessage,
					datasetDateList,
					scenarioList,
					scenarioFilterList,
					scenarioBorrowingBaseList,
				},
				hasChanges,
				resetBookmarkContext,
			}}
		>
			{children}
		</BookmarkContext.Provider>
	);
};

BookmarkProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

BookmarkProvider.defaultProps = {};
