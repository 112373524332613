import React from 'react';
import PropTypes from 'prop-types';
import KiSelect from '../../../../../components/KiSelect';
import KiInput from '../../../../../components/KiInput';
import {delinquencyOptions as DELINQUENCY_OPTIONS} from 'ki-common/options/assumptions';

const DelinquencySubForm = ({typeLabel, vectors, subFormData, subFormErrors, onChange}) => {
	const handleNewDelinquencyTypeChange = type => {
		onChange({type, rate: '0', months: '0', vectorId: null});
	};

	const vectorOptions = vectors.map(x => ({label: x.name, value: x._id}));

	return (
		<>
			<div className="sidebar-form-section">
				<span className="form-instruction">{typeLabel}</span>
				<KiSelect
					options={DELINQUENCY_OPTIONS}
					onChange={option => handleNewDelinquencyTypeChange(option?.value || null)}
					value={DELINQUENCY_OPTIONS.filter(opt => opt.value === subFormData.type)}
					isClearable={true}
					errorMessage={subFormErrors?.type}
				/>
			</div>
			{[DELINQUENCY_OPTIONS[0].value, DELINQUENCY_OPTIONS[1].value].includes(subFormData.type) && (
				<div className="sidebar-form-section">
					<span className="form-instruction">Rate %</span>
					<KiInput
						className="substring-size-input"
						onChange={rate => onChange({rate})}
						autoComplete="off"
						isNumberMasked={true}
						maskConfig={{allowNegative: false}}
						value={subFormData.rate}
						error={subFormErrors?.rate}
					/>
				</div>
			)}
			{subFormData.type === DELINQUENCY_OPTIONS[1].value && (
				<div className="sidebar-form-section">
					<span className="form-instruction">Months</span>
					<KiInput
						className="substring-size-input"
						onChange={months => onChange({months})}
						autoComplete="off"
						isNumberMasked={true}
						maskConfig={{allowNegative: false}}
						value={subFormData.months}
						error={subFormErrors?.months}
					/>
				</div>
			)}
			{subFormData.type === DELINQUENCY_OPTIONS[2].value && (
				<div className="sidebar-form-section">
					<span className="form-instruction">Vector</span>
					<KiSelect
						options={vectorOptions}
						onChange={option => onChange({vectorId: option.value, rate: 100})}
						value={vectorOptions.filter(opt => opt.value === subFormData.vectorId)}
					/>
				</div>
			)}
		</>
	);
};

DelinquencySubForm.propTypes = {
	typeLabel: PropTypes.string.isRequired,
	subFormData: PropTypes.object.isRequired,
	subFormErrors: PropTypes.object,
	vectors: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default DelinquencySubForm;
