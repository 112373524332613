// Globals
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Website imports
import {KiInput} from '../../../../components/KiInput';

// Local imports
import CohortBandsOdd from './CohortBandsOdd';
import {DataExplorerContext} from '../../DataExplorerContext';

const CohortBands = ({setFormErrors, formErrors}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark: {explorerData},
		setExplorerData,
	} = dataExplorerContext;

	const handleSetCohortBandType = ({value}) => {
		switch (value) {
			case 'custom-even':
				setFormErrors({'custom-even': 'Required', 'custom-odd': ''});
				break;
			case 'custom-odd':
				setFormErrors({'custom-odd': 'Required', 'custom-even': ''});
				break;
			default:
				setFormErrors({});
		}
		setExplorerData({bands: {type: value, step: null, steps: []}, granularity: ''}, true);
	};

	const handleSetCohortBandEvenStep = value => {
		setExplorerData({bands: {...explorerData.bands, step: Number(value)}, granularity: value}, true);
		setFormErrors({'custom-even': value ? '' : 'Required'});
	};

	const granularityOptions = [
		{label: 'Default', value: 'default'},
		{label: 'Custom Even', value: 'custom-even'},
		{label: 'Custom Odd', value: 'custom-odd'},
	];

	return (
		<div>
			<span className="form-instruction">Granularity Type</span>
			<Select
				classNamePrefix="aut-select"
				isDisabled={false}
				value={granularityOptions.find(opt => opt.value === explorerData.bands.type)}
				isClearable={false}
				options={granularityOptions}
				onChange={handleSetCohortBandType}
			/>
			{explorerData.bands.type === 'custom-even' && (
				<KiInput
					name="custom-even"
					label="Granularity"
					type="text"
					value={explorerData.bands.step}
					onChange={handleSetCohortBandEvenStep}
					error={formErrors['custom-even']}
					isNumberMasked={true}
				/>
			)}
			{explorerData.bands.type === 'custom-odd' && (
				<CohortBandsOdd setFormErrors={setFormErrors} formErrors={formErrors} />
			)}
		</div>
	);
};

CohortBands.propTypes = {
	setFormErrors: PropTypes.func,
	formErrors: PropTypes.object,
};

export default CohortBands;
