import React from 'react';
import PropTypes from 'prop-types';
import KiIconButton from 'components/KiIconButton';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import TemplateLine from './TemplateLine';
import KiCheckbox from 'components/KiCheckbox';
import {connect} from 'react-redux';
import {addTemplateLine, changeTemplate, removeTemplate, undoTemplate} from './actions';
import KiTooltip from '../../../../../components/KiTooltip';

function TemplateItem({
	template,
	index,
	changeTemplate,
	addTemplateLine,
	dateContextOptions,
	undoTemplate,
	removeTemplate,
	fvId,
}) {
	const {valid, active, modified, code, description, effectiveDate, lineItems, originalIndex} = template;

	const onItemChange = (key, itemIndex, value) => {
		changeTemplate(`lineItems[${itemIndex}][${key}]`, index, value, originalIndex);
	};

	return (
		<div className="boxed-container">
			<div className="horizontal-inputs-container significant">
				<div className="active-wrapper">
					<KiCheckbox
						className="select-item-checkbox"
						label="Active"
						checked={active}
						disabled={!valid}
						onChange={value => changeTemplate('active', index, value, originalIndex)}
					/>
					{!valid && (
						<KiTooltip
							message="Must be complete to activate"
							maxWidth={400}
							direction="right"
							className="ledger-info warning"
						>
							<i className="material-icons">info</i>
						</KiTooltip>
					)}
				</div>
				<div>
					<KiInput
						type="text"
						label="Post Code"
						value={code}
						onChange={value => changeTemplate('code', index, value, originalIndex)}
					/>
				</div>
				<div className="long-wrapper">
					<KiInput
						type="text"
						label="Post Description"
						value={description}
						onChange={value => changeTemplate('description', index, value, originalIndex)}
					/>
				</div>
				<div>
					<span className="theme-label">Effective Date</span>
					<KiSelect
						options={dateContextOptions}
						value={effectiveDate}
						onChange={value => changeTemplate('effectiveDate', index, value, originalIndex)}
					/>
				</div>
				<div className="to-right button-wrapper button-wrapper-size-3">
					<KiIconButton icon="add" onClick={() => addTemplateLine(index)} />
					{originalIndex !== null && (
						<KiIconButton
							icon="undo"
							disabled={!modified}
							onClick={() => undoTemplate(index, originalIndex)}
						/>
					)}
					<KiIconButton icon="delete" onClick={() => removeTemplate(index)} />
				</div>
			</div>
			{lineItems.map((item, lineIndex) => (
				<TemplateLine
					item={item}
					itemIndex={index}
					key={lineIndex}
					index={lineIndex}
					onItemChange={onItemChange}
					deleteDisabled={lineItems.length <= 2}
					fvId={fvId}
				/>
			))}
		</div>
	);
}

const mapStateToProps = ({ledgerTemplateConfiguration}) => ({
	dateContextOptions: ledgerTemplateConfiguration.dateContextOptions,
});

TemplateItem.propTypes = {
	template: PropTypes.object.isRequired,
	fvId: PropTypes.string,
	index: PropTypes.number.isRequired,
	changeTemplate: PropTypes.func.isRequired,
	addTemplateLine: PropTypes.func.isRequired,
	undoTemplate: PropTypes.func.isRequired,
	removeTemplate: PropTypes.func.isRequired,
	dateContextOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, {changeTemplate, addTemplateLine, undoTemplate, removeTemplate})(TemplateItem);
