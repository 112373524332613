import {format, getFormatStringType} from 'ki-common/utils/displayFormatter';
import {buildTitle, determineScenarioType} from 'ki-common/utils/explorerUtils';
import {explorerApi, datasetDatesApi} from 'api';
import options from 'ki-common/options';
const {defaultAggregatePaymentReportColumns} = require('ki-common/options/systemColumns');
const exploreRequestBuilder = require('ki-common/utils/exploreRequestBuilder');

export const getColumns = (rows, columns) => {
	return columns.map(c => {
		return {
			Header: c.displayName,
			accessor: c.columnName,
		};
	});
};

export const formatData = (rows = [], columns = []) => {
	const transformedRows = [];
	const displayFormats = columns.map(c => c.displayFormat);
	const transformedColumns = getColumns(rows, columns);
	const columnAccessors = transformedColumns.map(c => c.accessor);

	rows.forEach(row => {
		const r = {};
		row.data.forEach((cell, index) => {
			r[columnAccessors[index]] = format(cell, displayFormats[index]);
		});
		transformedRows.push(r);
	});
	return {
		transformedColumns: [...transformedColumns],
		transformedRows: [...transformedRows],
	};
};

export const defaultData = {
	rows: [],
	columns: [],
};

export const getSummaryColumn = (type, data, tableType, columnType, timeSeriesSummaryColumn, timeSeriesColumnId) => {
	// TODO: this really needs refactoring. map() returning nulls then filtering is awkward.
	const mappedSummaryColumn = JSON.parse(JSON.stringify([...data.columns]))
		.slice(1)
		.map(col => {
			if (
				getFormatStringType(col.displayFormat) !== columnType.value &&
				type !== 'pie' &&
				timeSeriesColumnId !== 'all'
			) {
				return null;
			}
			return col;
		})
		.filter(col => {
			if (timeSeriesSummaryColumn && tableType !== 'timeSeries') {
				return (
					col !== null && (col.id === timeSeriesSummaryColumn._id || col.id === timeSeriesSummaryColumn.id)
				);
			}
			return col !== null;
		})
		.find(col => {
			return col !== null;
		});
	if (!mappedSummaryColumn) {
		return undefined;
	}
	mappedSummaryColumn.displayName = buildTitle(
		mappedSummaryColumn.displayName,
		mappedSummaryColumn.calculation,
		mappedSummaryColumn.calculateColumn
	);
	mappedSummaryColumn._id = mappedSummaryColumn.id;
	return mappedSummaryColumn;
};

export const fetchCalculatedDate = async (dateContextId, statementDate, fundingVehicleId, isFixedDate, datasetId) => {
	try {
		return await datasetDatesApi.getCalculatedDates(
			[
				{
					groupId: dateContextId,
					statementDate: statementDate,
					fundingVehicleId: fundingVehicleId,
					isFixedDate: isFixedDate,
				},
			],
			datasetId
		);
	} catch (err) {
		return Promise.reject(err);
	}
};

/*
This builds an exploration bookmark for a payment query
config - contains report and param level override values
card - contains all the report card settings
 */

const _buildPaymentView = async (
	fundingVehicleId,
	card,
	latestSnapshotDate,
	datasetColumns,
	scenarioId,
	statementDate
) => {
	// also need to get a rando cohort as an asset level query requires a faux cohort to process (?!)
	const cohorts = datasetColumns.filter(c => c.columnType === 'cohort');

	const view = {
		explorerData: {
			dateContext: latestSnapshotDate._id.toString(),
			tableType: card.settings.type,
			snapshotType: 'standard',
			columns: [cohorts[0]].concat(
				defaultAggregatePaymentReportColumns.map(c => Object.assign({}, {...c}, {datasetId: card.datasetId}))
			),
			datasetId: card.datasetId,
			quickFilters: {
				fundingVehicleId: fundingVehicleId,
				scenarioId: scenarioId,
			},
			sortOrder: 'asc',
			sortColumn: 'pa0000000000000000000001',
		},
	};

	// build state from bookmark
	const explorerState = exploreRequestBuilder.getExplorerStateFromBookmark({}, view);

	////////////////////////////////////////////////////////////

	// overrides
	explorerState.statementDate = statementDate;
	explorerState.quickFilters.scenarioType = determineScenarioType(explorerState.quickFilters.scenarioId);

	// This will cause an error on the BE call if a non-hypo scenario is used with a hypo pool
	if (explorerState.quickFilters.scenarioType != 'hypo') {
		explorerState.quickFilters.hypoPoolId = '';
	}

	// card specific overrides
	explorerState.transpose = card.settings.transpose || false;
	explorerState.showTotals = card.settings.showTotals || false;
	explorerState.maxRecords = card.settings.numberOfRows
		? card.settings.numberOfRows
		: explorerState.maxRecords || null;
	explorerState.timeseries.chronological = card.settings.chronological || false;

	// get columns for request builder
	//const datasetCols = await _getDatasetCols(explorerState.datasetId);
	// build the request, this will calc the date to use and embed it
	const beRequest = await exploreRequestBuilder.buildRequestFromDataExplorerState(
		explorerState,
		false,
		datasetColumns
	);
	delete beRequest.pageSize; // totals don't work on BE if you send pageSize
	delete beRequest.pageNumber;
	return beRequest;
};

export async function fetchData(
	datasetColumns,
	view,
	card,
	statementDate,
	fundingVehicle,
	pagingSortingSettings,
	scenarioId
) {
	const fundingVehicleId = fundingVehicle && fundingVehicle._id ? fundingVehicle._id : null;
	const dateContextList = await datasetDatesApi.fetchPortfolioDates(card.datasetId);
	//const dateContext = dateContextList.find(d => d._id === view.explorerData.dateContext);
	const dateContext = dateContextList.find(d => d.isLatestSnapshot);

	const newExplorerRequest = await _buildPaymentView(
		fundingVehicleId,
		card,
		dateContext,
		datasetColumns,
		scenarioId,
		statementDate
	);

	const dateContextInfo = await fetchCalculatedDate(
		dateContext.groupId,
		statementDate,
		fundingVehicleId,
		false, //view.explorerData.isFixedDate,
		card.datasetId
	);
	let scenarioType;

	// not sure the reasoning behind these copying logic found in explorer
	switch (scenarioId) {
		case '':
		case 'assetSnapshot':
			scenarioType = 'assetSnapshot';
			break;
		case 'lastApproved':
			scenarioType = 'lastApproved';
			break;
		case 'lastCommitted':
			scenarioType = 'hypo';
			break;
		default:
			// any actual scenarioIds
			scenarioType = 'hypo';
	}

	// card display overrides
	newExplorerRequest.statementDate = statementDate;
	newExplorerRequest.quickFilters.fundingVehicleId = fundingVehicleId;
	if (card.settings.chartType === 'data') {
		newExplorerRequest.pageSize = pagingSortingSettings.pageSize;
	} else {
		newExplorerRequest.pageSize = 360; //show all data points for gadget charts
	}
	newExplorerRequest.pageNumber = pagingSortingSettings.pageNumber;
	newExplorerRequest.sortColumn = pagingSortingSettings.sort[0].id;
	//removing this conditional for now.. making chronological checkbox the source of truth for sorting
	// if (card.settings.chartType === 'data') {
	// 	newExplorerRequest.explorerData.sortOrder = pagingSortingSettings.sort[0].desc ? 'desc' : 'asc';
	// } else {
	// 	newExplorerRequest.explorerData.sortOrder = card.settings.chronological ? 'asc' : 'desc';
	// }
	newExplorerRequest.sortOrder = card.settings.chronological ? 'asc' : 'desc';
	// card display saved settings
	newExplorerRequest.showTotals = card.settings.showTotals;
	newExplorerRequest.transpose = card.settings.transpose;
	//newExplorerRequest.chronological = card.settings.chronological;
	newExplorerRequest.quickFilters.scenarioId = scenarioId;
	newExplorerRequest.quickFilters.scenarioType = scenarioType;
	// fetch the data
	let newData;
	const data = await explorerApi.fetchExplore(newExplorerRequest, datasetColumns);
	if (data) {
		newData = {...data, dateContextInfo: dateContextInfo};
	} else {
		newData = {dateContextInfo: dateContextInfo, columns: [], rows: []};
	}
	return newData;
}

export const getSummaryColumnList = (view, columnType) => {
	return view.explorerData.columns
		.slice(1)
		.filter(col => {
			if (view.explorerData.tableType !== 'timeSeries') {
				return getFormatStringType(col.displayFormat) === columnType.value && col.columnType !== 'asset';
			}
			return col.columnType !== 'asset';
		})
		.map(col => {
			return {
				...col,
				displayName: buildTitle(col.displayName, col.calculation, col.calculateColumn),
			};
		});
};

export const getDisplayTypes = () => {
	return [
		{
			label: 'Data',
			value: 'data',
			type: 'data',
		},
		...options.aggregateChartTypes,
	];
};

export const getChartTypes = tableType => {
	return tableType === 'timeSeries'
		? options.aggregateChartTypes.filter(op => op.type !== 'pie' && op.type !== 'column')
		: options.aggregateChartTypes;
};

export default {
	formatData,
	defaultData,
	getSummaryColumn,
	fetchData,
	getSummaryColumnList,
	getDisplayTypes,
	getChartTypes,
};
