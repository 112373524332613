import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import KiOperatorPicker from 'components/KiOperatorPicker';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import options from 'ki-common/options';

class FormulaRow extends React.Component {
	static propTypes = {
		provided: PropTypes.object,
		snapshot: PropTypes.any,
		operand: PropTypes.object,
		idx: PropTypes.number,
		selectOperatorInputField: PropTypes.func.isRequired,
		selectNewOperator: PropTypes.func.isRequired,
		selectedOperatorColumn: PropTypes.string,
		selectedOperatorRow: PropTypes.number,
		showOperatorPicker: PropTypes.bool,
		editOperand: PropTypes.func.isRequired,
		deleteOperand: PropTypes.func.isRequired,
		includeOffset: PropTypes.bool,
		onOffsetChange: PropTypes.func,
		isForDebt: PropTypes.bool,
	};

	static defaultProps = {
		includeOffset: false,
	};

	formatColumnDisplayName = (column = {}) => {
		if (column.columnType === 'aggregate') {
			return `${column.displayName} (${column.calculation})`;
		}
		return column.displayName;
	};

	getItemStyle = (isDragging, draggableStyle) => {
		if (isDragging) {
			return {
				...draggableStyle,
				display: 'table',
				backgroundColor: '#F1F7FF',
				border: '1px solid #999',
				boxShadow: '1px 3px 4px 1px rgba(0, 0, 0, 0.2)',
			};
		}
		return draggableStyle;
	};

	render() {
		const {
			provided = {},
			operand,
			idx,
			selectedOperatorRow,
			selectedOperatorColumn,
			showOperatorPicker,
		} = this.props;
		if (!operand.logic.value && idx === 0) {
			operand.logic = {
				type: 'operator',
				value: '+',
			};
		}
		const isMinMax = _has(operand, 'functionType.value');
		return (
			<tr
				key={idx}
				ref={provided.innerRef}
				{..._get(provided, 'draggableProps', {})}
				{..._get(provided, 'dragHandleProps', {})}
				style={this.getItemStyle(this.props.snapshot.isDragging, _get(provided, 'draggableProps.style', {}))}
			>
				<td style={{overflow: 'initial', position: 'relative', cursor: 'pointer', width: '60px'}}>
					<div onClick={() => this.props.selectOperatorInputField(idx, 'logic')}>
						{_get(operand, 'logic.value') || '.........'}
					</div>

					{/* Operator Picker */}
					{selectedOperatorRow === idx &&
						selectedOperatorColumn === 'logic' &&
						showOperatorPicker && (
							<KiOperatorPicker
								options={idx === 0 ? ['+', '-', 'CLR'] : ['+', '-', '/', '*', '^', 'CLR']}
								onSelect={option => this.props.selectNewOperator(option, idx, 'logic')}
							/>
						)}
				</td>

				{/* User can select more than a single opening parenthesis */}
				<td style={{overflow: 'initial', position: 'relative', cursor: 'pointer', width: '60px'}}>
					<div onClick={() => this.props.selectOperatorInputField(idx, 'openingGroup')}>
						{!_isEmpty(operand.openingGroup)
							? operand.openingGroup.map(parenthesis => parenthesis.value)
							: '.........'}
					</div>

					{/* Operator Picker */}
					{selectedOperatorRow === idx &&
						selectedOperatorColumn === 'openingGroup' &&
						showOperatorPicker && (
							<KiOperatorPicker
								options={['(', 'CLR']}
								onSelect={option => this.props.selectNewOperator(option, idx, 'openingGroup')}
							/>
						)}
				</td>

				{/* Name or value of operand (column/function/constant) */}
				<td>
					{/* Constants */}
					{_get(operand, 'type.value') === 'numeric' && operand.value}

					{/* Min/ max */}
					{isMinMax && `${operand.functionType.value}`.toUpperCase()}
					{isMinMax && '('}

					{/* Function column names */}
					{/* Add a comma after each columnName, except for the last column */}
					{_get(operand, 'type.value') === 'function' &&
						operand.value.map((val, idx) => (
							<div key={idx} style={{display: 'inline-table'}}>
								{`${this.formatColumnDisplayName(val)}${idx !== operand.value.length - 1 ? ', ' : ''}`}
							</div>
						))}

					{/* Column name */}
					{_get(operand, 'type.value') === 'column' && this.formatColumnDisplayName(operand.value)}
					{_get(operand, 'type.value') === 'subAccountColumn' && this.formatColumnDisplayName(operand.value)}
					{_has(operand, 'functionType.value') && ')'}
				</td>
				{this.props.isForDebt && (
					<Fragment>
						{this.props.includeOffset ? (
							_get(operand, 'type.value') === 'column' ? (
								<td style={{width: '10rem', display: 'inline-flex', paddingRight: '1rem'}}>
									<button
										style={{
											height: '3rem',
											borderRadius: '1rem 0 0 1rem',
											backgroundColor: '#EEE',
										}}
										onClick={() =>
											this.props.onOffsetChange(this.props.idx, (operand.offset || 0) - 1)
										}
									>
										-
									</button>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											borderWidth: '1px 0 1px 0',
											borderColor: '#CCC',
											borderStyle: 'solid',
											minWidth: '3rem',
											justifyContent: 'center',
										}}
									>
										{operand.offset || 0}
									</div>
									<button
										style={{
											height: '3rem',
											borderRadius: '0 1rem 1rem 0',
											backgroundColor: '#EEE',
										}}
										onClick={() =>
											this.props.onOffsetChange(this.props.idx, (operand.offset || 0) + 1)
										}
									>
										+
									</button>
								</td>
							) : (
								<td style={{width: '10rem'}}>N/A</td>
							)
						) : (
							<td style={{width: '10rem'}}>N/A</td>
						)}
					</Fragment>
				)}
				{/* User can select more than a single closing parenthesis */}
				<td style={{overflow: 'initial', position: 'relative', cursor: 'pointer', width: '60px'}}>
					<div onClick={() => this.props.selectOperatorInputField(idx, 'closingGroup')}>
						{!_isEmpty(operand.closingGroup)
							? operand.closingGroup.map(parenthesis => parenthesis.value)
							: '.........'}
					</div>

					{/* Operator Picker */}
					{selectedOperatorRow === idx &&
						selectedOperatorColumn === 'closingGroup' &&
						showOperatorPicker && (
							<KiOperatorPicker
								options={[')', 'CLR']}
								onSelect={option => this.props.selectNewOperator(option, idx, 'closingGroup')}
							/>
						)}
				</td>
				<td className="table-icon" style={{width: '40px'}}>
					<i title="Edit" className="material-icons" onClick={() => this.props.editOperand(operand)}>
						mode_edit
					</i>
				</td>
				<td className="table-icon" style={{width: '40px'}}>
					<i title="Delete" className="material-icons" onClick={() => this.props.deleteOperand(operand)}>
						delete
					</i>
				</td>
			</tr>
		);
	}
}

class NumericOperandList extends React.Component {
	static propTypes = {
		calculationOperands: PropTypes.array,
		selectOperatorInputField: PropTypes.any,
		selectNewOperator: PropTypes.any,
		selectedOperatorColumn: PropTypes.any,
		selectedOperatorRow: PropTypes.any,
		showOperatorPicker: PropTypes.any,
		onDragEnd: PropTypes.func,
		editOperand: PropTypes.func.isRequired,
		deleteOperand: PropTypes.func.isRequired,
		isForDebt: PropTypes.bool,
		onOffsetChange: PropTypes.func,
		includeOffset: PropTypes.bool,
	};

	static defaultProps = {
		calculationOperands: [],
		onDragEnd: () => null,
	};

	onDragEnd = result => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// a little function to help us with reordering the result
		const reorder = (list, startIndex, endIndex) => {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);
			return result;
		};

		const calculationOperands = reorder(
			this.props.calculationOperands,
			result.source.index,
			result.destination.index
		).map((operand, index) => {
			//Map over all calculationOperands and clear out all parentheses and logic
			return {
				...operand,
				id: index,
				logic:
					index === 0
						? {
								type: 'operator',
								value: '+',
						  }
						: operand.logic,
				openingGroup: _get(this.props.calculationOperands, `${index}.openingGroup`, []),
				closingGroup: _get(this.props.calculationOperands, `${index}.closingGroup`, []),
			};
		});
		return this.props.onDragEnd(calculationOperands);
	};

	selectNewOperator = (option, idx, column) => {
		// Clone calculation operands so we can update it without mutating state.
		const calculationOperands = _cloneDeep(this.props.calculationOperands);

		// Find the row to update.
		const selectedRow = calculationOperands.find(operator => operator.id === idx);

		// Clear out value of logic cell
		if (option === 'CLR' && column === 'logic') {
			selectedRow.logic = '.........';
		}

		// Clear out the correct group column
		if (option === 'CLR' && column !== 'logic') {
			selectedRow[column] = [];
		}

		// Push parenthesis to their respective arrays
		if (option === '(' || option === ')') {
			// WARNING: Using `.push` here is dangerous since the "openingGroup" key on rows have the same reference.
			selectedRow[column] = [
				...selectedRow[column],
				{
					type: 'operator',
					value: option,
				},
			];
		}

		// Update logic string value
		if (['+', '-', '/', '*', '^'].includes(option)) {
			selectedRow.logic = {
				type: 'operator',
				value: option,
			};
		}

		return this.props.selectNewOperator(calculationOperands);
	};

	editOperand = operand => {
		const operandType = _get(operand, 'type'); // this is edit an inserted

		return this.props.editOperand({
			logic: {label: operand.logic.value, value: operand.logic.value},
			operandType,
			openingGroup: operand.openingGroup,
			closingGroup: operand.closingGroup,
			operandValue: operand.functionType || operand.value.length ? null : operand.value,
			constant: !operand.functionType && operand.value.length ? operand.value : '',
			offset: operand.offset || 0,
			editingOperandId: operand.id,
			functionType: operand.functionType || null,
			functionValues: operand.functionType ? operand.value : [],
		});
	};

	deleteOperand = operand => {
		const calculationOperands = this.props.calculationOperands.filter(co => co.id !== operand.id).map((op, idx) => {
			op.id = idx;
			return op;
		});

		if (operand.id === 0 && calculationOperands.length !== 0) {
			calculationOperands[0].logic = options.algebraicTypes.find(op => op.value === '+');
		}

		return this.props.deleteOperand(calculationOperands);
	};

	render() {
		const {calculationOperands = [], ...rest} = this.props;

		return (
			<section className="selected-operands">
				<table className="table table-hover selected-operands-table">
					<thead>
						<tr>
							<th>LOGIC</th>
							<th>GROUP</th>
							<th>OPERAND</th>
							{this.props.includeOffset && <th>OFFSET</th>}
							<th>GROUP</th>
							<th />
							<th />
						</tr>
					</thead>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="droppable">
							{provided => (
								<tbody
									ref={provided.innerRef}
									// style={getListStyle(snapshot.isDraggingOver)}
								>
									{calculationOperands.map((item, index) => (
										<Draggable key={item.id} draggableId={index + 1} index={index}>
											{(provided, snapshot) => (
												<FormulaRow
													provided={provided}
													snapshot={snapshot}
													operand={item}
													idx={index}
													{...rest}
													includeOffset={
														this.props.includeOffset &&
														!['debtCalculation', 'fvSetup', 'borrowingBase'].includes(
															_get(item, 'value.columnType')
														)
													}
													onOffsetChange={this.props.onOffsetChange}
													selectNewOperator={this.selectNewOperator}
													deleteOperand={this.deleteOperand}
													editOperand={this.editOperand}
													isForDebt={this.props.isForDebt}
												/>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</tbody>
							)}
						</Droppable>
					</DragDropContext>
				</table>
			</section>
		);
	}
}

export default NumericOperandList;
