// Dependencies
import React, {Component} from 'react';

class TestStepThree extends Component {
	render() {
		return <div className="wrapper">This is step three</div>;
	}
}

export default TestStepThree;
