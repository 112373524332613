import KiChart from './KiChart';

class KiAreaChart extends KiChart {
	buildParamConfig() {
		const paramConfig = {
			chart: {
				type: 'area',
				height: this.params.height,
			},
			title: {
				text: this.params.chartTitle,
			},
			xAxis: {
				categories: this.params.categories,
				title: {
					text: this.params.xTitle,
				},
			},
			yAxis: {
				title: {
					text: this.params.yTitle,
				},
			},
			plotOptions: {
				area: {
					stacking: 'normal',
				},
			},
			series: this.params.series,
		};
		return paramConfig;
	}
}

export default KiAreaChart;
