export const USER_GROUPS = {
	USERS: 'Users',
	USER_ADMINS: 'UserAdmins',
	SYSTEM_ADMINS: 'SystemAdmins',
	API: 'API',
};

export const USER_GROUP_LIST = [
	{
		name: USER_GROUPS.USERS,
		label: 'Users',
	},
	{
		name: USER_GROUPS.USER_ADMINS,
		label: 'User Admins',
	},
	{
		name: USER_GROUPS.SYSTEM_ADMINS,
		label: 'System Admins',
	},
	{
		name: USER_GROUPS.API,
		label: 'API',
	},
];

export const userGroupsIntersect = (firstList, secondList) => {
	return firstList.some(r => secondList.includes(r));
};
