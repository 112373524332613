// Dependencies
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import Select, {components} from 'react-select';
// Externals
import KiButton from 'components/KiButton';
import KiDnDList from 'components/KiDnDList';

import '../../index.scss';

const CustomOption = props => {
	const {data, innerRef, innerProps} = props;
	return data.custom ? (
		<div ref={innerRef} {...innerProps} style={{padding: '10px'}}>
			<a style={{display: 'block', cursor: 'pointer', fontWeight: 'bold'}} onClick={data.onClick}>
				{data.text}
			</a>
		</div>
	) : (
		<components.Option {...props} />
	);
};

CustomOption.propTypes = {
	data: PropTypes.object,
	innerRef: PropTypes.func,
	innerProps: PropTypes.object,
};

export class TrackForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		trackToEdit: PropTypes.object,
		closeForm: PropTypes.func,
		addBlock: PropTypes.func,
		allBlocks: PropTypes.array.isRequired,
		// isAdmin: PropTypes.bool,
	};

	static defaultProps = {};

	state = {
		tracks: [],
		blocksToAdd: null,
		blocksAdded: [],
	};

	componentDidMount() {
		const {trackToEdit} = this.props;
		if (!_isEmpty(trackToEdit)) {
			this.setState({
				blocksAdded: trackToEdit.blocks,
			});
		}
	}

	onAddBlocks = () => {
		const newBlocksAdded = this.state.blocksAdded.concat([...this.state.blocksToAdd]);
		this.setState({
			blocksToAdd: [],
			blocksAdded: newBlocksAdded,
		});
	};

	onRemoveBlock = index => {
		const blocks = [...this.state.blocksAdded];
		blocks.splice(index, 1);
		this.setState({blocksAdded: blocks});
	};

	renderBlockRow = (selectedRow, index) => {
		return (
			<div className="edit-track-form-selected-blocks">
				<div>{selectedRow.name}</div>
				<i title="Remove" className={`material-icons`} onClick={() => this.onRemoveBlock(index)}>
					delete
				</i>
			</div>
		);
	};

	onBlockRowReorder = blocksDnd => {
		const blocks = blocksDnd.map(block => {
			return block.content;
		});

		this.setState({blocksAdded: blocks});
	};

	onSaveTrack = () => {
		const {trackToEdit} = this.props;
		const trackForSaving = {
			...trackToEdit,
			blocks: this.state.blocksAdded,
		};
		this.props.submitMethod(trackForSaving);
		this.props.closeForm();
	};

	blocksOptions = () => {
		const items = [...this.props.allBlocks];
		items.unshift({custom: true, text: 'Add New', onClick: this.props.addBlock});
		return items;
	};

	render() {
		// const {isAdmin} = this.props;
		// const {name, nameError} = this.state;
		const {trackToEdit} = this.props;
		// const saveDisabled = false;
		return (
			<div className="edit-track-form" style={{minHeight: `${170 + this.state.blocksAdded.length * 63}px`}}>
				<header style={{borderBottom: '1px solid #ccc'}}>
					<div className="track-title">
						<i
							title="Expand Blocks"
							className={'material-icons'}
							onClick={() => {
								this.setState({
									panelMode: null,
									trackName: '',
									trackNameError: null,
									currentTrack: null,
								});
								this.props.closeForm();
							}}
						>
							keyboard_arrow_down
						</i>
						Track: {`${trackToEdit.name} ${trackToEdit.isDefault ? '(Default)' : ''}`}
					</div>
				</header>
				<div className="edit-track-form">
					<div className="block-selection-row">
						<Select
							components={{Option: CustomOption}}
							classNamePrefix="aut-select"
							className="edit-track-block-selector"
							getOptionLabel={option => option.name}
							getOptionValue={option => option._id}
							value={this.state.blocksToAdd}
							isClearable={true}
							isMulti={true}
							closeOnSelect={false}
							options={this.blocksOptions()}
							onChange={values => this.setState({blocksToAdd: values})}
							placeholder="Select blocks to add..."
						/>
						<KiButton
							className="edit-track-form-add-btn structure-block-item"
							onClick={() => this.onAddBlocks()}
						>
							<i className="material-icons">add</i>
						</KiButton>
					</div>
					<div style={{minHeight: `${this.state.blocksAdded.length * 72}px`}}>
						<KiDnDList
							items={this.state.blocksAdded.map((block, index) => ({
								id: index,
								content: block,
								idx: index,
							}))}
							onReorder={this.onBlockRowReorder}
							contentRenderFunc={this.renderBlockRow}
							extendListClass={'edit-track-form-block-list-item'}
							extendItemClass={'edit-track-form-block-list-item'}
						/>
					</div>
					<div
						className="new-model-form-buttons"
						style={{
							paddingRight: 0,
							paddingTop: '2rem',
							paddingBottom: '2rem',
							borderTop: '1px solid #ccc',
						}}
						align="right"
					>
						<KiButton
							flat
							primary
							onClick={() => {
								this.setState({
									panelMode: null,
									trackName: '',
									trackNameError: null,
									currentTrack: null,
								});
								this.props.closeForm();
							}}
							label="Cancel"
						/>
						<KiButton raised primary disabled={false} onClick={this.onSaveTrack} label="Save" />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	user: state.user,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps())(TrackForm);
