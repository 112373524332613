import * as actionTypes from './actionTypes';
import {DATE_CONTEXT, VIEW_TYPES} from './components/LedgerExploration';
import {getFirstDayOfMonth} from '../../utils/dateUtils';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

const initialState = {
	viewType: VIEW_TYPES.BALANCE,
	dateContext: DATE_CONTEXT.POSTING_DATE,
	startDate: getFirstDayOfMonth(new Date()),
	endDate: dateToShortDate(new Date()),
	accountId: null,
};

const LedgerExploration = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.VIEW_TYPE_CHANGE:
			return {...state, viewType: action.viewType, accountId: null};
		case actionTypes.DATE_CONTEXT_CHANGE:
			return {...state, dateContext: action.dateContext};
		case actionTypes.START_DATE_CHANGE: {
			const {startDate} = action;
			if (startDate > state.endDate) {
				return {...state, startDate: startDate, endDate: startDate};
			}
			return {...state, startDate: startDate};
		}
		case actionTypes.END_DATE_CHANGE: {
			const {endDate} = action;
			if (endDate < state.startDate) {
				return {...state, endDate: endDate, startDate: getFirstDayOfMonth(new Date(endDate))};
			}
			return {...state, endDate: endDate};
		}
		case actionTypes.ACCOUNT_VIEW:
			return {...state, viewType: VIEW_TYPES.ACCOUNT, accountId: action.accountId};
		case actionTypes.RESET_STATE:
			return {...initialState};
		default:
			return state;
	}
};

export default LedgerExploration;
