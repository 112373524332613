const SET_IS_VEHICLE_LEDGER = 'SET_IS_VEHICLE_LEDGER';
const SET_PURE_TEMPLATES = 'SET_PURE_TEMPLATES';
const SET_TEMPLATE_LISTS = 'SET_TEMPLATE_LISTS';
const SET_DATE_CONTEXTS = 'SET_DATE_CONTEXTS';
const SET_AMOUNTS = 'SET_AMOUNTS';
const SET_ACCOUNTS = 'SET_ACCOUNTS';
const SET_ENTITIES = 'SET_ENTITIES';
const UNDO_TEMPLATE = 'UNDO_TEMPLATE';
const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
const ADD_TEMPLATE = 'ADD_TEMPLATE';
const REMOVE_TEMPLATE_LINE = 'REMOVE_TEMPLATE_LINE';
const ADD_TEMPLATE_LINE = 'ADD_TEMPLATE_LINE';
const CHANGE_TEMPLATE = 'CHANGE_TEMPLATE';
const SEARCH_TEMPLATES = 'SEARCH_TEMPLATES';
const LOADING = 'LOADING';

export {
	SET_IS_VEHICLE_LEDGER,
	SET_PURE_TEMPLATES,
	SET_TEMPLATE_LISTS,
	SET_DATE_CONTEXTS,
	SET_AMOUNTS,
	SET_ACCOUNTS,
	SET_ENTITIES,
	UNDO_TEMPLATE,
	REMOVE_TEMPLATE,
	ADD_TEMPLATE,
	REMOVE_TEMPLATE_LINE,
	ADD_TEMPLATE_LINE,
	CHANGE_TEMPLATE,
	SEARCH_TEMPLATES,
	LOADING,
};
