const _get = require('lodash/get');

function getSortFuncByField(fieldName, sortAsc) {
	return (a, b) => {
		const valueOne = _get(a, fieldName, a).toLocaleString().toLocaleLowerCase();
		const valueTwo = _get(b, fieldName, b).toLocaleString().toLocaleLowerCase();

		if (valueOne < valueTwo) {
			return sortAsc ? -1 : 1;
		}
		if (valueOne > valueTwo) {
			return sortAsc ? 1 : -1;
		}
		return 0;
	};
}

function getSortAscFuncByField(fieldName) {
	return getSortFuncByField(fieldName, true);
}

function getSortDescFuncByField(fieldName) {
	return getSortFuncByField(fieldName, false);
}

module.exports = {
	getSortFuncByField,
	getSortAscFuncByField,
	getSortDescFuncByField
};
