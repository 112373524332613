import React, {Fragment, useEffect, useState} from 'react';
import LedgerTopBar from '../LedgerTopBar';
import {useParams} from 'react-router';
import {
	commitManualEntries,
	deleteManualEntries,
	fetchLedger,
	fetchLedgerAccountsMapping,
	fetchManualEntries,
} from '../../../../api/ledgersApi';
import './LedgerManualEntry.scss';
import {compareDates, formatDate} from '../../../../utils/dateUtils';
import LedgerSubmitHeader from '../LedgerSubmitHeader';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {showSnackbar} from '../../../../state/actions/Snackbar';
import {convertEntryToFormData, convertEntriesToListData} from './manulEntryUtils';
import KiConfirmModal from 'components/KiConfirmModal';
import ManualEntryTable from './ManulEntryTable';
import ManualEntryForm from './ManualEntryForm';
import {TRANSACTION_TYPE_DEBIT} from '../../ledgerUtils';
import cloneDeep from 'lodash/cloneDeep';
import Loader from '../../Loader';
import KiProgressBar from '../../../../components/KiProgressBar';
import get from 'lodash/get';
const POST_DATE_IN_SUBMISSION_ERROR_CODE = 'POST_DATE_IN_SUBMISSION';

export const initialPosting = {account: '', lineItem: '', amount: '', type: TRANSACTION_TYPE_DEBIT};
const initialFormData = {
	postCode: '',
	description: '',
	postingDate: '',
	effectiveDate: '',
	postings: [cloneDeep(initialPosting), cloneDeep(initialPosting)],
};

function LedgerManualEntry({showSnackbar}) {
	const {id} = useParams();

	const [ledger, setLedger] = useState({});
	const [entries, setEntries] = useState([]);
	const [convertedEntries, setConvertedEntries] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [accountOptions, setAccountOptions] = useState([]);
	const [formData, setFormData] = useState(cloneDeep(initialFormData));
	const [showClearConfirm, setShowClearConfirm] = useState(false);
	const [showCommitConfirm, setShowCommitConfirm] = useState(false);
	const [isLoadingEntries, setLoadingEntries] = useState(true);
	const [isLoadingLedger, setLoadingLedger] = useState(true);

	const getLedger = () => {
		return fetchLedger(id).then(ledger => {
			setLedger(ledger);
			setLoadingLedger(false);
			return ledger;
		});
	};

	const isAnyEntryIncorrect = () => {
		if (convertedEntries) {
			return !!convertedEntries.find(({valid}) => valid === false);
		}
	};

	useEffect(() => {
		getLedger();
	}, []);

	const getEntries = () => {
		setLoadingEntries(true);
		fetchManualEntries(id).then(entries => {
			setEntries(entries);
			setLoadingEntries(false);
		});
	};

	useEffect(() => {
		getEntries();
	}, []);

	useEffect(() => {
		setLoadingEntries(true);
		fetchLedgerAccountsMapping(id, false).then(accounts => {
			setAccounts(accounts);
			setLoadingEntries(false);
		});
	}, []);

	useEffect(
		() => {
			setAccountOptions(
				accounts
					.filter(account => account.active)
					.map(account => ({label: account.description, value: account._id}))
			);
		},
		[accounts]
	);

	useEffect(
		() => {
			if (accounts.length && ledger && ledger.closeDate) {
				setConvertedEntries(convertEntriesToListData(entries, ledger.closeDate, accounts));
			}
		},
		[accounts, entries, ledger]
	);

	const clearForm = () => {
		setFormData(cloneDeep(initialFormData));
	};

	const onClear = () => {
		const entriesIds = entries.map(entry => entry._id);
		deleteManualEntries(id, entriesIds).then(() => {
			clearForm();
			getEntries();
			showSnackbar('All pending manual entries have been cleared.');
		});
		setShowClearConfirm(false);
	};

	const commitEntries = () => {
		const entriesIds = entries.map(entry => entry._id);
		commitManualEntries(id, entriesIds)
			.then(() => {
				clearForm();
				getEntries();
				showSnackbar('All pending manual entries have been committed.');
			})
			.catch(err => {
				const errMessage =
					get(err, 'data.error.errorCode') === POST_DATE_IN_SUBMISSION_ERROR_CODE
						? err.message
						: 'Error committing manual entries';
				showSnackbar(errMessage);
			});
		setShowCommitConfirm(false);
	};

	const onCommitEntries = () => {
		getLedger().then(ledger => {
			const isAnyEntryInvalid =
				entries.findIndex(entry => compareDates(ledger.closeDate, entry.effectiveDate)) !== -1;
			if (isAnyEntryInvalid) {
				showSnackbar(
					'One or more manual entries have an effective date on or before the ledger close date. These entries must be corrected before committing.'
				);
			} else {
				setShowCommitConfirm(true);
			}
		});
	};

	const BUTTON_ACTIONS = [
		{
			label: 'CLEAR',
			isFlat: true,
			onClick: () => setShowClearConfirm(true),
		},
		{
			label: 'COMMIT',
			onClick: onCommitEntries,
			disabled: entries.length === 0 || isAnyEntryIncorrect(),
		},
	];

	const afterDeleteExistingEntry = () => {
		getEntries();
		showSnackbar('Entry has been deleted.');
	};

	const onExistingEntryEdit = item => {
		setFormData(convertEntryToFormData(item, accountOptions));
	};

	const onEntrySave = () => {
		clearForm();
		getEntries();
		showSnackbar('Entry has been saved.');
	};

	return (
		<div className="ledger">
			<LedgerTopBar ledgerId={id} existingLedger={ledger} section="Manual Entry" />
			<div className="ledger-manual-entry">
				<Loader
					isActive={isLoadingLedger}
					loaderContent={() => (
						<section className="ki-panel">
							<KiProgressBar />
						</section>
					)}
				>
					{ledger && (
						<Fragment>
							<section className="ki-panel">
								<LedgerSubmitHeader actions={BUTTON_ACTIONS}>
									<h2>
										Ledger Close Date: <span>{formatDate(ledger.closeDate)}</span>
									</h2>
								</LedgerSubmitHeader>
							</section>
							<section className="ki-panel entry-panel">
								{accountOptions && accountOptions.length ? (
									<ManualEntryForm
										formData={formData}
										ledger={ledger}
										accountOptions={accountOptions}
										afterSave={onEntrySave}
										setFormData={setFormData}
										undoChanges={clearForm}
									/>
								) : (
									<div className="box-info">
										There must be active accounts to create or edit manual entries.
									</div>
								)}
								<div className="saved-postings">
									<Loader isActive={isLoadingEntries}>
										<ManualEntryTable
											entries={convertedEntries}
											ledgerId={id}
											afterDelete={afterDeleteExistingEntry}
											onEdit={onExistingEntryEdit}
										/>
									</Loader>
								</div>
							</section>
						</Fragment>
					)}
				</Loader>
			</div>
			<KiConfirmModal
				message="Are you certain you want to delete all pending manual entries without adding them to the journal?"
				acceptFunc={onClear}
				rejectFunc={() => setShowClearConfirm(false)}
				acceptLabel="Clear"
				rejectLabel="Cancel"
				active={showClearConfirm}
			/>
			<KiConfirmModal
				message="Are you ready to commit all manual entries to the journal?"
				acceptFunc={commitEntries}
				rejectFunc={() => setShowCommitConfirm(false)}
				acceptLabel="Commit"
				rejectLabel="Cancel"
				active={showCommitConfirm}
			/>
		</div>
	);
}

LedgerManualEntry.propTypes = {
	showSnackbar: PropTypes.func.isRequired,
};

export default connect(null, {showSnackbar})(LedgerManualEntry);
