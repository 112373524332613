import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import KiInput from 'components/KiInput';
import KiSelect from 'components/KiSelect';
import styles from './creditSupportsForm.theme.scss';
import KiCheckbox from 'components/KiCheckbox';
import KiButton from 'components/KiButton';
import {accrualMethodOptions} from 'ki-common/options';
import _ from 'lodash';
import {columnServiceApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';

export const SUPPORT_TYPE_OPTIONS = [
	{
		value: 'Reserve',
		label: 'Reserve',
	},
	{
		value: 'Insurance',
		label: 'Insurance',
	},
	{
		value: 'Liq Fac',
		label: 'Liquidity Facility',
	},
	{
		value: 'Collection',
		label: 'Collection',
	},
];

export const getNameId = (supportType, supportNumber) =>
	[
		'S_',
		_.get(SUPPORT_TYPE_OPTIONS.find(x => x.value === supportType), 'label', '').replace(/\s/gi, ''),
		'_',
		supportNumber,
	].join('');

const RATE_TYPE_OPTIONS = [{value: 'fixed', label: 'Fixed'}, {value: 'floating', label: 'Floating'}];

export function CreditSupportsForm(props) {
	const dispatch = useDispatch();
	const [name, setName] = useState(props.name);
	const [supportType, setSupportType] = useState(props.supportType);
	const [floatingIndices, setFloatingIndices] = useState([]);
	const [floatingIndex, setFloatingIndex] = useState(props.floatingIndex);
	const [rateType, setRateType] = useState(props.rateType);
	const [rateAmount, setRateAmount] = useState(props.rateAmount);
	const [margin, setMargin] = useState(props.margin);
	const [accrualMethod, setAccrualMethod] = useState(props.accrualMethod);
	const [target, setTarget] = useState(props.target);
	const [insurance, setInsurance] = useState(props.insurance);
	const [isAsset, setIsAsset] = useState(props.isAsset);
	const [savePending, setSavePending] = useState(false);

	useEffect(() => {
		columnServiceApi
			.getIndexColumnsFromService(props.datasetId)
			.then(indices => {
				setFloatingIndices(indices);
			})
			.catch(() => dispatch(showSnackbar('Failure to load indices')));
	}, []);

	useEffect(
		() => {
			setName(props.name);
			setSupportType(props.supportType);
			setFloatingIndex(props.floatingIndex);
			setRateType(props.rateType);
			setRateAmount(props.rateAmount);
			setMargin(props.margin);
			setAccrualMethod(props.accrualMethod);
			setTarget(props.target);
			setInsurance(props.insurance);
			setIsAsset(props.isAsset);
		},
		[props.supportId]
	);

	const handleSave = () => {
		const creditSupportToBeSaved = {
			_id: props.supportId,
			datasetId: props.datasetId,
			fundingVehicleId: props.fundingVehicleId,
			rowType: 'creditSupport',
			name,
			nameId: getNameId(supportType, props.getCreditSupportNumber(props.supportId, supportType)),
			supportType,
			supportNumber: props.getCreditSupportNumber(props.supportId, supportType),
			rateType,
			rateAmount: (rateType === 'fixed' && rateAmount) || '0.0',
			margin: (rateType === 'floating' && margin) || '0.0',
			floatingIndex,
			accrualMethod,
			target,
			insurance,
			isAsset,
		};
		if (!['Reserve', 'Liq Fac'].includes(supportType)) {
			delete creditSupportToBeSaved.target;
		}
		if (supportType === 'Insurance') {
			delete creditSupportToBeSaved.rateType;
			delete creditSupportToBeSaved.rateAmount;
			delete creditSupportToBeSaved.floatingIndex;
			delete creditSupportToBeSaved.accrualMethod;
		} else {
			delete creditSupportToBeSaved.insurance;
			if (rateType !== 'floating') {
				delete creditSupportToBeSaved.floatingIndex;
			}
		}
		setSavePending(true);
		return props.onSave(creditSupportToBeSaved).finally(() => {
			setName('');
			setSupportType(SUPPORT_TYPE_OPTIONS[0].value);
			setFloatingIndex('');
			setRateType(RATE_TYPE_OPTIONS[0].value);
			setRateAmount('0.0');
			setMargin('0.0');
			setAccrualMethod(accrualMethodOptions[0].value);
			setTarget('');
			setInsurance(false);
			setSavePending(false);
			setIsAsset(false);
		});
	};

	const handleCancel = () => {
		props.history.goBack();
	};

	return (
		<section className={styles.root}>
			<KiInput
				label="Account Name"
				onChange={val => setName(val)}
				value={name}
				error={props.name !== name && !props.isNameUnique(name) && 'Name in use'}
			/>
			<label className={styles.label}>Support Type</label>
			<KiSelect
				className={styles.supportSelect}
				options={SUPPORT_TYPE_OPTIONS}
				value={SUPPORT_TYPE_OPTIONS.filter(x => x.value === supportType)}
				label={'Support Type'}
				onChange={opt => setSupportType(opt.value)}
			/>
			<KiInput
				label="Support #"
				value={getNameId(supportType, props.getCreditSupportNumber(props.supportId, supportType))}
				readOnly
			/>
			{['Reserve', 'Liq Fac'].includes(supportType) && (
				<React.Fragment>
					<label className={styles.label}>{supportType === 'Reserve' ? 'Target' : 'Limit'}</label>
					<KiSelect
						getOptionValue={opt => opt._id}
						getOptionLabel={opt => opt.displayName}
						options={props.availableTargets}
						value={props.availableTargets.filter(x => x._id === target)}
						onChange={opt => setTarget(opt._id)}
					/>
				</React.Fragment>
			)}
			{supportType !== 'Insurance' && (
				<div className={styles.rateRow}>
					<div className={styles.rateRowItem}>
						<label className={styles.label}>Rate</label>
						<KiSelect
							options={RATE_TYPE_OPTIONS}
							value={RATE_TYPE_OPTIONS.filter(x => x.value === rateType)}
							onChange={opt => setRateType(opt.value)}
						/>
					</div>
					<div className={styles.rateRowItem}>
						{rateType === 'fixed' && (
							<KiInput
								label="Rate"
								value={rateAmount}
								onChange={val => setRateAmount(val)}
								isNumberMasked={true}
								maskConfig={{suffix: '%'}}
								sendReactEvent={true}
							/>
						)}
						{rateType === 'floating' && (
							<KiInput
								label="Margin"
								value={margin}
								onChange={val => setMargin(val)}
								isNumberMasked={true}
								maskConfig={{suffix: '%'}}
								sendReactEvent={true}
							/>
						)}
					</div>
					{rateType === 'floating' && (
						<div className={styles.rateRowItem}>
							<label className={styles.label}>Index</label>
							<KiSelect
								options={floatingIndices}
								getOptionValue={opt => opt._id}
								getOptionLabel={opt => opt.displayName}
								value={floatingIndices.filter(x => x._id === floatingIndex)}
								onChange={opt => setFloatingIndex(opt._id)}
							/>
						</div>
					)}
					<div className={styles.rateRowItem}>
						<label className={styles.label}>Accrual Method</label>
						<KiSelect
							options={accrualMethodOptions}
							value={accrualMethodOptions.filter(x => x.value === accrualMethod)}
							onChange={opt => setAccrualMethod(opt.value)}
						/>
					</div>
				</div>
			)}
			<br />
			{supportType === 'Insurance' && (
				<KiCheckbox label="Defaulted" checked={!!insurance} onChange={val => setInsurance(val)} />
			)}
			<KiCheckbox label="Asset" checked={!!isAsset} onChange={val => setIsAsset(val)} />
			<footer className={styles.footer}>
				<KiButton flat primary onClick={handleCancel} label="Cancel" />
				<KiButton
					className={styles.saveBtn}
					primary
					raised
					label="Save"
					disabled={savePending || !name || !props.isNameUnique(name)}
					onMouseUp={handleSave}
				/>
			</footer>
		</section>
	);
}

CreditSupportsForm.propTypes = {
	supportId: PropTypes.string,
	name: PropTypes.string,
	supportType: PropTypes.string,
	rateType: PropTypes.string,
	rateAmount: PropTypes.string,
	margin: PropTypes.string,
	floatingIndex: PropTypes.string,
	accrualMethod: PropTypes.string,
	target: PropTypes.string,
	insurance: PropTypes.bool,
	onSave: PropTypes.func,
	fundingVehicleId: PropTypes.string.isRequired,
	datasetId: PropTypes.string,
	availableTargets: PropTypes.arrayOf(PropTypes.object),
	getCreditSupportNumber: PropTypes.func.isRequired,
	isNameUnique: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	isAsset: PropTypes.bool,
};

CreditSupportsForm.defaultProps = {
	name: '',
	supportType: SUPPORT_TYPE_OPTIONS[0].value,
	supportNumber: 1,
	rateType: RATE_TYPE_OPTIONS[0].value,
	rateAmount: '0.0',
	margin: '0.0',
	floatingIndex: '',
	accrualMethod: accrualMethodOptions[0].value,
	target: '',
	insurance: false,
	isAsset: false,
	availableTargets: [],
	isNameUnique: () => true,
};

export default CreditSupportsForm;
