import PropTypes from 'prop-types';
import React, {Component} from 'react';
import noop from 'lodash/noop';
import styles from '../../AssociatedData.theme.scss';
import _groupBy from 'lodash/groupBy';

export class SchemaErrorSummaryItem extends Component {
	static propTypes = {
		text: PropTypes.string,
		count: PropTypes.number,
		level: PropTypes.oneOf(['error', 'warning']),
		onClick: PropTypes.func,
	};

	render() {
		const {level, count, onClick = noop, text} = this.props;

		return (
			<div
				className={`${styles.error} ${styles[`level${level}`]}`}
				onClick={level === 'warning' ? onClick : noop}
			>
				{`${count} ${text}`}
			</div>
		);
	}
}

export class SchemaErrorSummary extends Component {
	static propTypes = {
		errors: PropTypes.array,
	};

	getErrorMessage = (type, count = 1) => {
		const isMultiple = count > 1;
		switch (type) {
			case 'newColumnFound':
				return `new column${isMultiple ? 's' : ''} found`;

			case 'columnNotFound':
				return `column${isMultiple ? 's' : ''} missing`;

			case 'dataTypeMismatchNumeric':
				return `numeric data type mismatch${count > 1 ? 'es' : ''}`;

			case 'dataTypeMismatchShortDate':
				return `shortDate data type mismatch${isMultiple ? 'es' : ''}`;

			case 'dataTypeMismatchLongDate':
				return `longDate data type mismatch${isMultiple ? 'es' : ''}`;

			case 'dataTypeMismatchCharacter':
				return `string data type mismatch${isMultiple ? 'es' : ''}`;

			case 'AssetIDNotFound':
				return 'required column "AssetID" not mapped';

			case 'BalanceNotFound':
				return 'required column "Balance" not mapped';

			case 'columnSpecialCharacters':
				return `column${isMultiple ? 's' : ''} contain${isMultiple ? '' : 's'} spaces or blanks`;

			default:
				return null;
		}
	};

	render() {
		const errors = _groupBy(this.props.errors, error => error.code);
		return (
			<div className={styles.schemaErrorPanel}>
				{this.props.errors.length ? (
					<div className={styles.schemaErrorSummary}>
						{Object.keys(errors).map(errorType => (
							<SchemaErrorSummaryItem
								key={errorType}
								text={this.getErrorMessage(errorType, errors[errorType].length) || 'Unknown Error'}
								count={errors[errorType].length}
								level={errors[errorType][0].level}
							/>
						))}
					</div>
				) : (
					<div className={styles.schemaErrorSummary}>No errors found</div>
				)}
			</div>
		);
	}
}

export default SchemaErrorSummary;
