//eslint-disable-next-line no-unused-vars
import {apiUrl, _checkAuth, _handleResponse, _getHeaders, _checkStatus} from 'api/apiUtils';
import analytics from 'analytics';

export function fetchDebtExplore(request) {
	analytics.sendEvent('debt-explorer', 'fetch');

	const ts = new Date().getTime();
	return fetch(`${apiUrl}/debt/explore`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(request),
	})
		.then(response => {
			analytics.sendTimingEvent('debt-explorer-timing', 'fetch', new Date().getTime() - ts);
			return response;
		})
		.then(_checkAuth)
		.then(_checkStatus);
}

export const fetchDefaultBookmark = datasetId => {
	return fetch(`${apiUrl}/debtBookmarks/default/${datasetId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const fetchBookmarks = datasetId => {
	return fetch(`${apiUrl}/debtBookmarks${datasetId ? `?datasetId=${datasetId}` : ''}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const fetchBookmark = bookmarkId => {
	return fetch(`${apiUrl}/debtBookmarks/${bookmarkId}`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const addBookmark = bookmark => {
	return fetch(`${apiUrl}/debtBookmarks`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(bookmark),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const saveBookmark = bookmark => {
	return fetch(`${apiUrl}/debtBookmarks`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(bookmark),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const deleteBookmark = bookmarkId => {
	return fetch(`${apiUrl}/debtBookmarks/${bookmarkId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export default {
	fetchDebtExplore,
	fetchDefaultBookmark,
	fetchBookmarks,
	fetchBookmark,
	saveBookmark,
	deleteBookmark,
	addBookmark,
};
