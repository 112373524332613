import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormBlockItemContainer.theme.scss';
import cx from 'classnames';
const FormBlockItemContainer = props => {
	if (!props.isVisible) return null;
	return (
		<div className={cx(styles.root, props.className)} style={props.style}>
			<header className={styles.header}>
				<div className={styles.title}>{props.title}</div>
				<i hidden={props.readOnly} className={styles.deleteIcon} onMouseUp={props.onDelete}>
					clear
				</i>
			</header>
			<section className={styles.body}>{props.children}</section>
		</div>
	);
};

FormBlockItemContainer.propTypes = {
	isVisible: PropTypes.bool,
	readOnly: PropTypes.bool,
	onDelete: PropTypes.func.isRequired,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	style: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.any,
};

FormBlockItemContainer.defaultProps = {
	isVisible: false,
	readOnly: false,
	style: {},
};

export default FormBlockItemContainer;
