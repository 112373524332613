const validatejs = require('validate.js');
const fundingVehicles = require('./fundingVehicles');
const pools = require('./pools');
const dateHelpers = require('../utils/dateHelpers');
const flatten = require('flat');
const _isString = require('lodash/isString');
const _get = require('lodash/get');
const _isEmpty = require('lodash/isEmpty');
const submissions = require('./submissions');
const accounts = require('./accounts');
const bookmarks = require('./bookmarks');
const categorySchemas = require('./categorySchemas');
const schemaColumns = require('./schemaColumns');
const scenarios = require('./scenarios');
const dashboards = require('./dashboards');
const gadgets = require('./gadgets');
const extracts = require('./extracts');
const assetAdjustments = require('./assetAdjustments');
const datasetsCriteria = require('./datasetsCriteria');
const reportDefinitions = require('./reportDefinitions');
const reportGroups = require('./reportGroups');
const individualReports = require('./individualReports');

const longDateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
const shortDateRegex = /^(\d{4})-(\d{2})$/;

const getMessage = (options, message) => {
	return options.message ? options.message : message;
};

// Custom Validators
validatejs.validators.isJavascriptDate = function(value, options) {
	return value instanceof Date ? null : getMessage(options, 'is not instance of Javascript date');
};

validatejs.validators.isShortDate = function(value, options) {
	return _isString(value) && value.match(dateHelpers.dateStringRegex)
		? null
		: getMessage(options, 'is not a valid YYYY-MM-DD date string');
};

validatejs.validators.isCustomSchemaDate = function(value) {
	let isValid = _isString(value);
	const lowerValue = isValid ? value.toLowerCase() : ''; // Dont try to lower case a non string
	isValid = isValid && !lowerValue.match('[a-ce-ln-xz]');
	return isValid && lowerValue.match('(?=(.*[d]{2,}){0,1})(?=(.*[m]{2,}){1})(?=(.*[y]{2,}){1})')
		? null
		: 'Invalid inputs';
};

/**
 * Tool to compare dates for validation
 *
 * options.targetAttribute		name of another field in object to compare against
 * options.targetIsBefore		true if target is < or = to the compare date
 * options.targetDate			date to compare against in string form YYYY-MM-DD
 * options.message				specific message to return on failure
 */
validatejs.validators.compareDate = function(value, options, key, attributes) {
	const valueDate = value instanceof Date ? value.getTime() : new Date(value).getTime();
	let target;
	if (options.targetAttribute) {
		target = _get(attributes, options.targetAttribute); // Must use get so nested attributes can be fetched with dot notation
	} else {
		target = options.targetDate;
	}

	const targetDate = target instanceof Date ? target.getTime() : new Date(target).getTime();
	if (options.isTargetBefore && targetDate > valueDate) {
		// Target should be before
		return getMessage(options, 'is before target date');
	}

	if (!options.isTargetBefore && targetDate < valueDate) {
		// Target should be after
		return getMessage(options, 'is after target date');
	}

	return null;
};

// eslint-disable-next-line complexity
validatejs.validators.targetArray = function(value, options, key, attributes) {
	if (!attributes.logic || !attributes.dataColumn) {
		return null; // Cannot validate if logic OR data column is not selected
	}

	// eslint-disable-next-line no-prototype-builtins
	const emptyAllowed = ['is_null', 'is_not_null', 'in', 'not_in'].includes(attributes.logic);

	// There must be at least one target
	if (value.length === 1) {
		if (!emptyAllowed && (value[0] === '' || value[0] === null || value[0] === undefined)) {
			return 'cannot be empty';
		}
		// eslint-disable-next-line no-prototype-builtins
		if (attributes && attributes.hasOwnProperty('logic') && attributes.logic === 'between') {
			return 'both values must be entered';
		}
	}

	// Check that all targets are numeric
	if (attributes.dataColumn.dataType === 'numeric' && value && !value.every(i => !isNaN(i))) {
		return 'must be numeric';
	}

	// Check that all targets are valid short date format YYYY-MM
	if (attributes.dataColumn.dataType === 'date_short') {
		if (value[0] && !shortDateRegex.test(value[0])) {
			return 'requires format YYYY-MM';
		}
		if (value[1] && !shortDateRegex.test(value[1])) {
			return 'requires format YYYY-MM';
		}
	}

	// Check that all targets are valid long date format YYYY-MM-DD
	if (attributes.dataColumn.dataType === 'date_long') {
		if (value[0] && !longDateRegex.test(value[0])) {
			return 'requires format YYYY-MM-DD';
		}
		if (value[1] && !longDateRegex.test(value[1])) {
			return 'requires format YYYY-MM-DD';
		}
	}

	if (value.length === 2) {
		if (_isEmpty(value[0]) || _isEmpty(value[1])) {
			return 'both values must be entered';
		}

		// eslint-disable-next-line no-prototype-builtins
		if (attributes.hasOwnProperty('logic') && attributes.logic.value === 'between') {
			if (typeof value[0] === 'string' && typeof value[1] === 'string') {
				const date0 = value[0].split('-');
				const date1 = value[1].split('-');
				//checking if string to make sure .match doesn't crash in case it's an integer or object for some reason.
				if (attributes.dataColumn.dataType === 'date_short') {
					// both short date formats, check that the first comes before the second
					if (new Date(date0[0], date0[1] - 1) >= new Date(date1[0], date1[1] - 1)) {
						return 'invalid range';
					}
					return null;
				}
				if (attributes.dataColumn.dataType === 'date_long') {
					// both short date formats, check that the first comes before the second
					if (new Date(date0[0], date0[1] - 1, date0[2]) >= new Date(date1[0], date1[1] - 1, date1[2])) {
						return 'invalid range';
					}
					return null;
				}
			}
			if (isNaN(value[0]) !== isNaN(value[1])) {
				return 'invalid range';
			}
			if (isNaN(value[0]) && isNaN(value[1])) {
				if (value[0] >= value[1]) {
					return 'invalid range';
				}
			} else if (parseFloat(value[0]) >= parseFloat(value[1])) {
				return 'invalid range';
			}
		}
	}
	return null;
};

validatejs.validators.concentrationArray = function(value, options, key, attributes) {
	if (!attributes.dataColumn) {
		return null; // Cannot validate if data column is not selected
	}
	if (attributes.dataColumn.dataType === 'numeric' && value && !value.every(i => !isNaN(i))) {
		return 'must be numeric';
	}
	if (attributes.dataColumn.dataType === 'date_long' && value && !value.every(i => longDateRegex.test(i))) {
		return 'requires format YYYY-MM-DD';
	}
	if (attributes.dataColumn.dataType === 'date_short' && value && !value.every(i => shortDateRegex.test(i))) {
		return 'requires format YYYY-MM';
	}
};

//eslint-disable-next-line complexity
validatejs.validators.targetArrayConcentration = function(value, options, key, attributes) {
	if (!(value instanceof Array)) {
		return 'is not formatted as an array';
	}
	if (value.length === 1) {
		// eslint-disable-next-line no-prototype-builtins
		if (attributes && attributes.hasOwnProperty('logic') && attributes.logic === 'between') {
			return 'min and max required';
		}
		if (value[0] === '' || isNaN(value[0])) {
			return 'must be numeric';
		}
		if (parseFloat(value[0]) < 0) {
			return 'must be between 0 and 100';
		}
		if (parseFloat(value[0]) > 100) {
			return 'must be between 0 and 100';
		}
	}
	if (value.length === 2) {
		if (_isEmpty(value[0]) || _isEmpty(value[1])) {
			return 'both values must be entered';
		}
		if (value[0] === '' || isNaN(value[0])) {
			return 'min must be numeric';
		}
		if (value[1] === '' || isNaN(value[1])) {
			return 'max must be numeric';
		}
		if (parseFloat(value[0]) < 0) {
			return 'min must be between 0 and 100';
		}
		if (parseFloat(value[0]) > 100) {
			return 'min must be between 0 and 100';
		}
		if (parseFloat(value[1]) < 0) {
			return 'max must be between 0 and 100';
		}
		if (parseFloat(value[1]) > 100) {
			return 'max must be between 0 and 100';
		}
		if (parseFloat(value[0]) >= parseFloat(value[1])) {
			return 'invalid range';
		}
		if (parseFloat(value[1]) <= parseFloat(value[0])) {
			return 'invalid range';
		}
	}
	return null;
};

validatejs.validators.targetArrayConcentrationNonPercent = function(value, options, key, attributes) {
	if (value.length === 1) {
		// eslint-disable-next-line no-prototype-builtins
		if (attributes && attributes.hasOwnProperty('logic') && attributes.logic === 'between') {
			return 'min and max required';
		}
		if (value[0] === '' || isNaN(value[0])) {
			return 'must be numeric';
		}
	}
	if (value.length === 2) {
		if (_isEmpty(value[0]) || _isEmpty(value[1])) {
			return 'both values must be entered';
		}
		if (value[0] === '' || isNaN(value[0])) {
			return 'min must be numeric';
		}
		if (value[1] === '' || isNaN(value[1])) {
			return 'max must be numeric';
		}
		if (parseFloat(value[0]) >= parseFloat(value[1])) {
			return 'invalid range';
		}
		if (parseFloat(value[1]) <= parseFloat(value[0])) {
			return 'invalid range';
		}
	}
	return null;
};

validatejs.extend(validatejs.validators.datetime, {
	// The value is guaranteed not to be null or undefined but otherwise it
	// could be anything.
	parse: function(value) {
		return new Date(value).getTime();
	},
	// Input is a unix timestamp
	format: function(value) {
		return new Date(value).toISOString();
	},
});

/**
 * Will only validate fields that are present in the object to validate
 * @param  {[type]} object      Object of values to be validated
 * @param  {[type]} constraints Object of constraints to be used
 * @return {[type]}             Validation result (undefined if no errors)
 */
const validatePatch = (object, constraints) => {
	// The object must be flattened first so that nested objects
	// can request the correct constraints which are stored in
	// string dot notation
	const fields = Object.keys(flatten(object));
	const newConstraints = {};
	fields.forEach(field => {
		const constraint = constraints[field];
		if (constraint) {
			newConstraints[field] = constraint;
		}
	});
	return validatejs(object, newConstraints);
};

const validateAsync = (object, constraints) => {
	return new validatejs.Promise((resolve, reject) => {
		const validation = validatejs.validate(object, constraints);
		if (validation === undefined) {
			resolve(undefined);
		}
		reject(new Error(JSON.stringify(validation)));
	});
};

const validateAsyncPatch = (object, constraints) => {
	return new validatejs.Promise((resolve, reject) => {
		const validation = validatePatch(object, constraints);
		if (validation === undefined) {
			resolve(undefined);
		}
		reject(new Error(JSON.stringify(validation)));
	});
};

module.exports = {
	validatejs: validatejs,
	validate: validatejs.validate,
	validateSingle: validatejs.single,
	validatePatch: validatePatch,
	validateAsync: validateAsync,
	validateAsyncPatch: validateAsyncPatch,
	fundingVehicles,
	pools,
	submissions,
	accounts,
	bookmarks,
	categorySchemas,
	schemaColumns,
	scenarios,
	dashboards,
	gadgets,
	extracts,
	datasetsCriteria,
	reportDefinitions,
	reportGroups,
	individualReports,
	assetAdjustments,
};
