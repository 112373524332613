import {apiUrl, _handleResponse, _getHeaders} from 'api/apiUtils';

export const fetchCalendar = calendarId => {
	return fetch(`${apiUrl}/calendars/${calendarId}`, {
		method: 'GET',
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchCalendars = () => {
	return fetch(`${apiUrl}/calendars`, {
		method: 'GET',
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const createCalendar = calendar => {
	return fetch(`${apiUrl}/calendars`, {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(calendar),
		headers: _getHeaders('POST'),
	}).then(_handleResponse);
};

export const updateCalendar = (id, changes) => {
	return fetch(`${apiUrl}/calendars/${id}`, {
		method: 'PUT',
		credentials: 'include',
		body: JSON.stringify(changes),
		headers: _getHeaders('PATCH'),
	}).then(_handleResponse);
};

export const deleteCalendar = id => {
	return fetch(`${apiUrl}/calendars/${id}`, {
		method: 'DELETE',
		credentials: 'include',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export default {
	fetchCalendar,
	fetchCalendars,
	createCalendar,
	updateCalendar,
	deleteCalendar,
};
