// Globals
import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// Project imports

import KiButton from 'components/KiButton';
import {showSnackbar} from 'state/actions/Snackbar';
import {rejectScenario, approveScenario, submitScenario} from 'api/fundingAnalysisApi';

// Local imports
import styles from './fundingScenarioActions.theme.scss';
import FundingAnalysisContext from '../../fundingAnalysisContext';
import {BookmarkContext} from '../../fundingAnalysisBookmark';

function FundingScenarioActions() {
	// Redux State
	const dispatch = useDispatch();
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));

	// Context State
	const bookmarkContext = useContext(BookmarkContext);
	const scenario = bookmarkContext.appliedScenario;
	const bookmark = bookmarkContext.appliedBookmark;
	const fundingAnalysisContext = useContext(FundingAnalysisContext);

	// Local State
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [disableApprovals, setDisableApprovals] = useState(false);

	const onResponse = type => {
		dispatch(showSnackbar(`Scenario ${type} processing`));
	};

	// On Mount
	useEffect(() => {
		setIsSubmitted(false);
	}, []);

	const onReject = async () => {
		try {
			setDisableApprovals(true);
			await rejectScenario(scenario._id);
			onResponse('reject');
		} catch (err) {
			setDisableApprovals(false);
			fundingAnalysisContext.addError(`[ ${scenario.name} ] - ${err.message}`);
		}
	};

	const onApprove = async () => {
		try {
			setDisableApprovals(true);
			await approveScenario(scenario._id);
			onResponse('approve');
		} catch (err) {
			setDisableApprovals(false);
			fundingAnalysisContext.addError(`[ ${scenario.name} ] - ${err.message}`);
		}
	};

	const onSubmit = async () => {
		try {
			setIsSubmitted(true);
			await submitScenario(scenario._id);
			onResponse('submit');
		} catch (err) {
			setIsSubmitted(false);
			fundingAnalysisContext.addError(`[ ${scenario.name} ] - ${err.message}`);
		}
	};
	const approvalsEnabled = window.FUNDING_APPROVALS_ENABLED === 'true';
	const displayAnyActionButton = scenario?._id && bookmark.settings.viewType === 'scenario';

	const displayReject =
		displayAnyActionButton && isAdmin && ['hypo', 'approved', 'submitted'].includes(scenario.status);
	const displaySubmit = displayAnyActionButton && scenario.status === 'hypo';
	const displayApprove = displayAnyActionButton && isAdmin && !approvalsEnabled && scenario.status === 'submitted';

	return (
		<div className={styles.root}>
			<React.Fragment>
				{displayReject && (
					<KiButton
						label="REJECT"
						type="submit"
						iconButton={false}
						primary
						disabled={disableApprovals}
						onClick={() => onReject()}
					/>
				)}
				{displaySubmit && (
					<KiButton
						label="SUBMIT"
						type="submit"
						iconButton={false}
						primary
						disabled={isSubmitted}
						onClick={() => onSubmit()}
					/>
				)}
				{displayApprove && (
					<KiButton
						label="APPROVE"
						type="submit"
						iconButton={false}
						primary
						disabled={disableApprovals}
						onClick={() => onApprove()}
					/>
				)}
			</React.Fragment>
		</div>
	);
}

export default FundingScenarioActions;
