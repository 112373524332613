import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {userGroupsIntersect} from '../../utils/userGroupsUtil';

function Available({user, groups, children}) {
	return user.groups && userGroupsIntersect(user.groups, groups) ? children : null;
}

Available.propTypes = {
	user: PropTypes.object.isRequired,
	groups: PropTypes.arrayOf(PropTypes.string).isRequired,
	children: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
});

export default connect(mapStateToProps)(Available);
