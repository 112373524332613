const jsep = require('jsep');
jsep.addBinaryOp('^', 10);
const _ = require('lodash');

// jsep constants
const UNARY_EXPRESSION = 'UnaryExpression';
const BINARY_EXPRESSION = 'BinaryExpression';
const CALL_EXPRESSION = 'CallExpression';
const CONDITIONAL_EXPRESSION = 'ConditionalExpression';
const LOGICAL_EXPRESSION = 'LogicalExpression';
const IDENTIFIER = 'Identifier';
const LITERAL = 'Literal';

// middleware constants
const COLUMN_IDENTIFIER = 'COLUMNID_';
const SUBACCOUNTCOLUMN_IDENTIFIER = 'SUBACCOUNTCOLUMNID_';

const logicalOperators = [
	{
		label: '==',
		value: '=',
	},
	{
		label: '!=',
		value: '<>',
	},
	{
		label: '&&',
		value: 'and',
	},
	{
		label: '||',
		value: 'or',
	},
];

const getEquationFromArray = equationArray => {
	let mappedArrayString;
	const equationSettingsMap = new Map();
	const mappedEquationArray = equationArray
		.map(item => {
			switch (item.type) {
				case 'operator':
					return item.value;
				case 'constant':
				case 'numeric':
				case 'boolean':
					return `'${item.value}'`;
				case 'column': {
					if (parseInt(item.offset)) {
						const key = `${item.value}_${parseInt(item.offset) < 0 ? 'offsetMinus' : 'offsetPlus'}${Math.abs(
							item.offset
						)}`;
						equationSettingsMap.set(key, item);
						return `${COLUMN_IDENTIFIER}${key}`;
					}
					equationSettingsMap.set(item.value, item);
					return `${COLUMN_IDENTIFIER}${item.value}`;
				}
				case 'subAccountColumn':
					equationSettingsMap.set(item.value, item);
					return `${SUBACCOUNTCOLUMN_IDENTIFIER}${item.value}`;
				case 'min':
				case 'max':
					mappedArrayString = item.value.map(val => (val = `${COLUMN_IDENTIFIER}${val}`)).toString();
					equationSettingsMap.set(mappedArrayString, item);
					//item.value = item.value.map(val => (val = `${COLUMN_IDENTIFIER}${val}`));
					return `${item.type}(${mappedArrayString})`;
				default:
					return '_INVALID_OPERATOR_';
			}
		})
		.join('');

	return {
		equationSettingsMap,
		mappedEquationArray,
	};
};

// This assumes the LITERAL flag is in use
const regex = /^\d{4}-\d{2}(-\d{2}){0,1}$/;
const getLiteralNode = value => {
	let type = 'string';
	if (!isNaN(Number(value))) {
		type = 'numeric';
	} else if (value === true || value === 'true' || value === false || value === 'false') {
		type = 'boolean';
	} else if (value.match(regex)) {
		type = 'date_long';
	}
	return {type, value: `${value}`};
};

// eslint-disable-next-line
const parseBinaryTreeFormat = (nodeInitial, settingsMap = {}) => {
	let node = _.cloneDeep(nodeInitial);
	if (node.type) {
		if (node.type === BINARY_EXPRESSION) {
			delete node.type;
			if (node.operator) {
				node.op = _.get(logicalOperators.find(op => op.label === node.operator), 'value', node.operator);
				delete node.operator;
			}
		} else if (node.type === UNARY_EXPRESSION) {
			node.type = 'numeric';
			node.value = `${node.operator}${node.argument.raw}`;
			delete node.argument;
			delete node.prefix;
			delete node.operator;
		} else if (node.type === CALL_EXPRESSION) {
			node = Object.assign(node, settingsMap.get(node.arguments.map(n => n.name).toString()));
			node.type = node.callee.name;
			delete node.callee;
			delete node.arguments;
		} else if (node.type === CONDITIONAL_EXPRESSION) {
			// If the value is literal then get the node otherwise recursive call
			node.extension = {};
			if (node.consequent.type === LITERAL) {
				node.extension['TrueColumn'] = getLiteralNode(node.consequent.value);
			} else {
				node.extension['TrueColumn'] = parseBinaryTreeFormat(node.consequent, settingsMap);
			}

			if (node.alternate.type === LITERAL) {
				node.extension['FalseColumn'] = getLiteralNode(node.alternate.value);
			} else {
				node.extension['FalseColumn'] = parseBinaryTreeFormat(node.alternate, settingsMap);
			}

			delete node.type;
			delete node.consequent;
			delete node.alternate;
			node.left = node.test.left;
			node.right = node.test.right;
			node.operator = node.test.operator;
			delete node.test;
		} else if (node.type === LOGICAL_EXPRESSION) {
			node.op = logicalOperators.find(op => op.label === node.operator).value;
			delete node.type;
			delete node.operator;
		} else if (node.type === IDENTIFIER) {
			const isSubAccount = node.name.indexOf(SUBACCOUNTCOLUMN_IDENTIFIER) > -1;
			if (isSubAccount) {
				const key = node.name.slice(
					node.name.indexOf(SUBACCOUNTCOLUMN_IDENTIFIER) + SUBACCOUNTCOLUMN_IDENTIFIER.length
				);
				node = Object.assign(node, settingsMap.get(key));
				node.type = 'subAccountColumn';
				delete node.name;
			} else {
				const key = node.name.slice(node.name.indexOf(COLUMN_IDENTIFIER) + COLUMN_IDENTIFIER.length);
				node = Object.assign(node, settingsMap.get(key));
				node.type = 'column';
				delete node.name;
			}
		} else if (node.type === LITERAL) {
			// node.type = 'numeric';
			// node.value = `${node.value}`;
			// delete node.raw;
			node = getLiteralNode(node.value);
		}
	}
	if (node.operator && !!node.left && !!node.right) {
		node['op'] = _.get(logicalOperators.find(op => op.label === node.operator), 'value', node.operator);
		delete node.operator;
	}
	if (node.left) {
		node.left = parseBinaryTreeFormat(node.left, settingsMap);
	}
	if (node.right) {
		node.right = parseBinaryTreeFormat(node.right, settingsMap);
	}
	return node;
};

const unEscapeBinary = formula => {
	const toReturn = {};
	for (const [key, value] of Object.entries(formula)) {
		if (_.isPlainObject(value)) {
			// Objects get sent back through this call
			toReturn[key] = unEscapeBinary(value);
		} else {
			// If it's a string unescape otherwise original value
			toReturn[key] = typeof value === 'string' ? _.unescape(value) : value;
		}
	}
	return toReturn;
};

const getExpressionTree = equationArray => {
	if (!equationArray) {
		throw new Error('equationArray is required');
	} else if (!Array.isArray(equationArray)) {
		if (_.isPlainObject(equationArray.left)) {
			// passed in array is already an expanded expression tree (dateCalcs are stored expanded), return it.
			return equationArray;
		}
		throw new Error('equationArray is not an array');
	} else if (!equationArray.length) {
		throw new Error('equationArray is empty');
	}

	// prevent mutations and escape constants
	const ops = _.cloneDeep(equationArray).map(entry => {
		if (entry.type == 'constant') {
			return Object.assign({}, entry, {
				value: _.escape(entry.value),
			});
		}
		return entry;
	});

	const firstType = ops[0].type;
	const firstSign = ops[0].value; // jsep parser doesn't like leading operator
	if (firstSign === '+' || firstSign === '-') {
		ops.shift();
	}
	const expression = getEquationFromArray(ops);
	let formula = parseBinaryTreeFormat(jsep(expression.mappedEquationArray.toString()), expression.equationSettingsMap);
	formula = unEscapeBinary(formula);
	// BLD-15232 Bug was introduced here with debt calculations, previously a calculation had to define an
	// operation even if there was only one element (eg. +1 or -Column, etc). Debt Calculations only require
	// an operation if there are two elements and never in the leading position
	if (ops.length === 1) {
		// jsep has problems converting a single value to binary
		formula = {left: formula};
		formula.right = null;
		// Check that firstSign is an operator, if not default to '+'
		formula.op = firstType !== 'operator' ? '+' : firstSign;
	}
	return formula;
};

module.exports = {
	getExpressionTree,
};
