// Globals
import React, {useState, useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Local imports
import KiButton from 'components/KiButton';
import KiCheckbox from 'components/KiCheckbox';
import KiInput from 'components/KiInput';
import {upsertBookmark, fetchBookmarks} from 'api/fundingAnalysisApi';
import {showSnackbar} from 'state/actions/Snackbar';

// Relative imports
import FundingAnalysisContext from '../fundingAnalysisContext';
import {BookmarkContext} from '../fundingAnalysisBookmark';
import styles from './saveBookmarkMenu.theme.scss';

function SaveBookmarkMenu({onCancel}) {
	// Browser State
	const history = useHistory();
	const {datasetId} = useParams();

	// Redux State
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);

	// Context State
	const fundingAnalysisContext = useContext(FundingAnalysisContext);
	const bookmarkContext = useContext(BookmarkContext);
	const bookmark = bookmarkContext.appliedBookmark;

	// Local State
	const [name, setName] = useState(bookmark.name);
	const [nameError, setNameError] = useState();
	const [bookmarkList, setBookmarkList] = useState();
	const [tags, setTags] = useState(bookmark.tags);
	const [isDefault, setDefault] = useState(bookmark.isDefault);
	const [isFavorite, setFavorite] = useState(bookmark.isFavorite);

	const fetchAllBookmarks = async () => {
		const bookmarks = await fetchBookmarks(datasetId);
		setBookmarkList(bookmarks);
	};
	useEffect(() => {
		fetchAllBookmarks();
	}, []);

	const onSaveBookmark = async (isSaveAs = false) => {
		const bookmarkToSave = _.cloneDeep(bookmark);
		if (!name) {
			setNameError('Name is required.');
			return;
		} else if (isSaveAs && bookmarkList.find(b => b.name === name)) {
			setNameError('Name must be unique.');
			return;
		} else if (!isSaveAs && bookmarkList.find(b => b.name === name && b._id !== bookmarkToSave._id)) {
			setNameError('Name must be unique.');
			return;
		} else {
			setNameError(null);
		}
		bookmarkToSave.name = name;
		bookmarkToSave.tags = tags;
		bookmarkToSave.isDefault = isDefault;
		bookmarkToSave.isFavorite = isFavorite;
		bookmarkToSave.isSystem = false;
		bookmarkToSave.createdBy = user.userId;
		bookmarkToSave.datasetId = datasetId;
		if (bookmarkToSave.settings?.fundingVehicleIds[0]?.fvId) {
			bookmarkToSave.settings.fundingVehicleIds = bookmarkToSave.settings.fundingVehicleIds.map(t => t.fvId);
		}
		if (bookmarkToSave.settings?.criteriaSources[0]?.fvId) {
			bookmarkToSave.settings.criteriaSources = bookmarkToSave.settings.criteriaSources.map(t => t.fvId);
		}
		const isBasedOfPendingDate = !!bookmarkToSave.settings.transferDate;
		if (isBasedOfPendingDate) {
			bookmarkToSave.settings.transferDate = null;
			bookmarkToSave.settings.scenarioType = 'lastApproved';
		}
		if (isSaveAs) {
			delete bookmarkToSave._id;
		}
		try {
			const res = await upsertBookmark(bookmarkToSave);
			onCancel();
			if (!isSaveAs) {
				bookmarkContext.loadBookmark(bookmarkToSave, true);
				fetchAllBookmarks();
			} else {
				history.push(`/fundingAnalysis/${res.datasetId}/bookmark/${res._id}`);
			}
			// BLD-22685 revert 6beaed41a845ab742421eb6dd0ef746fdc71c6c8
			const message = isBasedOfPendingDate
				? 'Saved view will be saved with End of Day scenario context'
				: 'Bookmark successfully saved.';
			dispatch(showSnackbar(message));
		} catch (err) {
			fundingAnalysisContext.addError(`[ Error saving ${bookmarkToSave.name} ] - ${err.message}`);
		}
	};

	return (
		<div>
			<div className={styles.title}>Save Funding View</div>
			<div className={styles.form}>
				<KiInput
					name="name"
					label="View Name"
					type="text"
					value={name}
					error={nameError}
					onChange={val => setName(val)}
				/>
				<CreatableSelect
					classNamePrefix="aut-select"
					isMulti={true}
					options={[]}
					onChange={values => setTags(values ? values.map(tag => tag.value) : [])}
					placeholder={'Add Tags'}
					isClearable={true}
					value={tags.map(t => {
						return {value: t, label: t};
					})}
				/>
			</div>
			<KiCheckbox name="isDefault" checked={isDefault} label="Use as Default For Dataset" onChange={setDefault} />
			<KiCheckbox name="isFavorite" checked={isFavorite} label="Favorite" onChange={setFavorite} />
			<div className={styles.formButtons}>
				<KiButton flat primary onClick={() => onCancel()} label="Cancel" />
				<KiButton raised primary onClick={() => onSaveBookmark(true)} label="Save As" />
				<KiButton
					disabled={bookmark.isSystem}
					raised
					primary
					onClick={() => onSaveBookmark(false)}
					label="Save"
				/>
			</div>
		</div>
	);
}

SaveBookmarkMenu.propTypes = {
	onCancel: PropTypes.func.isRequired,
};

export default SaveBookmarkMenu;
