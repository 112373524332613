import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import styles from './KiTooltip.theme.scss';

export class KiTooltipOverlay extends Component {
	static propTypes = {
		message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
		offsets: PropTypes.object,
		containerPosition: PropTypes.object,
		direction: PropTypes.string,
		maxWidth: PropTypes.number,
	};

	static defaultProps = {
		maxWidth: 250,
	};

	state = {
		position: null,
	};

	componentDidMount = () => {
		const dom = ReactDOM.findDOMNode(this.overlayRef); // eslint-disable-line
		this.setState({
			position: dom.getBoundingClientRect(),
		});
	};

	componentDidUpdate = () => {
		const {position} = this.state;
		const dom = ReactDOM.findDOMNode(this.overlayRef); // eslint-disable-line
		const newPosition = dom.getBoundingClientRect();

		if (!_isEqual(position.height, newPosition.height) || !_isEqual(position.height, newPosition.height)) {
			this.setState({
				position: newPosition,
			});
		}
	};

	render() {
		const {position} = this.state;
		const {message, offsets, containerPosition, direction, maxWidth} = this.props;
		if (!message) {
			return null; // Shortcut render before page fully loaded
		}

		// Apply user offsets
		const style = {
			left: _get(offsets, 'left', 0),
			top: _get(offsets, 'top', 0),
			maxWidth,
		};

		// Offset relative to bottom/middle of target
		style.left += _get(containerPosition, 'left', 0) + Math.round(_get(containerPosition, 'width', 0) / 2);
		style.top += _get(containerPosition, 'top', 0) + _get(containerPosition, 'height', 0);

		// Offset by width of tooltip
		if (direction) {
			// if right float let left be default, if left float offset by width of element
			style.left =
				direction === 'right' ? style.left : style.left - Math.min(_get(position, 'width', 0), maxWidth);
		} else {
			// if no direction center the popup
			style.left -= Math.round(Math.min(_get(position, 'width', 0), maxWidth) / 2);
		}

		return (
			<div
				className={styles.tooltip}
				style={style}
				ref={overlay => {
					this.overlayRef = overlay;
				}}
			>
				{message instanceof Function ? message() : message}
			</div>
		);
	}
}

export class KiTooltip extends Component {
	static propTypes = {
		// placement: PropTypes.string, // top, left, right, bottom,
		message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
		offsets: PropTypes.object, // {top, left}
		children: PropTypes.node,
		className: PropTypes.string,
		direction: PropTypes.string,
		maxWidth: PropTypes.number,
	};

	state = {
		isActive: false,
		position: null,
	};

	componentDidMount = () => {
		const dom = ReactDOM.findDOMNode(this.wrapperRef); // eslint-disable-line
		this.setState({
			position: dom.getBoundingClientRect(),
		});
	};

	componentDidUpdate = () => {
		const {position} = this.state;
		const dom = ReactDOM.findDOMNode(this.wrapperRef); // eslint-disable-line
		const newPosition = dom.getBoundingClientRect();

		if (
			!_isEqual(position.top, newPosition.top) ||
			!_isEqual(position.left, newPosition.left) ||
			!_isEqual(position.height, newPosition.height) ||
			!_isEqual(position.width, newPosition.width)
		) {
			this.setState({
				position: newPosition,
			});
		}
	};

	showTooltip = () => {
		this.setState({isActive: true});
	};

	hideTooltip = () => {
		this.setState({isActive: false});
	};

	render() {
		const {isActive} = this.state;
		const {message, offsets, direction, maxWidth} = this.props;
		return (
			<div
				className={`${styles.tooltipContainer} ${this.props.className || ''}`}
				onMouseEnter={this.showTooltip}
				onMouseLeave={this.hideTooltip}
				ref={wrapper => {
					this.wrapperRef = wrapper;
				}}
			>
				{this.props.children}
				{isActive && (
					<KiTooltipOverlay
						message={message}
						offsets={offsets}
						containerPosition={this.state.position}
						direction={direction}
						maxWidth={maxWidth}
					/>
				)}
			</div>
		);
	}
}

export default KiTooltip;
