const _defaultsDeep = require('lodash/defaultsDeep');
const moment = require('moment');
const options = require('../../options');
const baselineConstraints = {
	type: {
		presence: {allowEmpty: false},
	},
	startDate: (value, attributes) => {
		if (attributes.parentId) {
			return {
				presence: false,
			};
		} else {
			return {
				presence: {allowEmpty: false},
				date: true,
			};
		}
	},
	recurrenceType: {
		presence: {allowEmpty: false},
		inclusion: options.fvDateRecurrenceOptions.map(x => x.value),
	},
};

const websiteConstraints = {
	range: (value, attributes) => {
		if (['monthly_end', 'monthly'].includes(attributes.recurrenceType)) {
			return {
				inclusion: ['all', 'endBy', 'endAfter', 'maturityDate'],
			};
		}
		return {
			presence: false,
		};
	},
	endDate: (value, attributes) => {
		if (attributes.recurrenceType !== 'none' && attributes.range === 'endBy') {
			return {
				presence: {allowEmpty: false},
				date: {
					earliest: moment.utc(attributes.startDate).add(1, 'day'),
					message: 'must be after start date',
				},
			};
		}
		return null;
	},
	endAfter: (value, attributes) => {
		if (!attributes.recurrenceType || attributes.recurrenceType === 'none') {
			return null;
		}
		if (attributes.range === 'endAfter') {
			return {
				numericality: {
					greaterThan: 0,
				},
			};
		}
		return null;
	},
};

const mongoConstraints = {
	fundingVehicleId: {
		presence: {allowEmpty: false},
	},
	rowType: {
		presence: {allowEmpty: false},
	},
};

const getConstraints = options => {
	if (options && options.isMongo) {
		return _defaultsDeep({}, mongoConstraints, baselineConstraints);
	}
	return _defaultsDeep({}, websiteConstraints, baselineConstraints);
};

module.exports = {
	getConstraints,
};
