import {
	COLUMN_MODAL_CLOSE,
	COLUMN_MODAL_OPEN
} from 'state/actions/ColumnModal';

const initialState = {
	active: false
};

const ColumnModal = (state = initialState, action) => {
	switch (action.type) {
		case COLUMN_MODAL_OPEN:
			return {
				...initialState,
				active: true
			};

		case COLUMN_MODAL_CLOSE:
			return initialState;

		default:
			return state;
	}
};

export default ColumnModal;