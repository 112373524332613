import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import _get from 'lodash/get';
import KiInput from 'components/KiInput';
class TextFormSubstringFields extends Component {
	static propTypes = {
		active: PropTypes.bool.isRequired,
		selectedColumns: PropTypes.array,
		assetColumns: PropTypes.array,
		size: PropTypes.number,
		direction: PropTypes.string,
		onColumnSelect: PropTypes.func.isRequired,
		onDirectionSelect: PropTypes.func.isRequired,
		onSizeChange: PropTypes.func.isRequired,
		onPositionChange: PropTypes.func.isRequired,
		position: PropTypes.string,
	};

	static defaultProps = {
		active: false,
		size: 0,
		selectedColumns: [],
		assetColumns: [],
		direction: 'left',
	};

	state = {
		midZeroError: '',
	};

	generateExampleResult = (input = '', direction = 'left', size = 0, position = 0) => {
		const isLeft = direction === 'left';
		const isRight = direction === 'right';
		let result = '';
		if (size !== 0) {
			if (isLeft) {
				result = input.substr(0, size);
			} else if (isRight) {
				result = input.substr(-1 * size);
			} else {
				result = input.substr(position - 1, size);
			}
		}
		return `"${input}" → "${result}"`;
	};

	setPosition = val => {
		if (val === 0) {
			this.setState({
				midZeroError: 'Zero is not a valid position for a mid substring',
			});
			return;
		}
		this.setState({midZeroError: ''});
		this.props.onPositionChange(val);
	};

	render() {
		const {
			active,
			selectedColumns,
			assetColumns,
			direction,
			size,
			position,
			onColumnSelect,
			onDirectionSelect,
			onSizeChange,
		} = this.props;

		if (!active) {
			return null;
		}
		const selectOptions = [{
			label: 'Left',
			value: 'left'
		}, {
			label: 'Right',
			value: 'right'
		}, {
			label: 'Mid',
			value: 'mid'
		}, ];

		return (
			<div>
				<div className="calculation-form-section">
					<span className="form-instruction">Column:</span>
					<Select
						classNamePrefix="aut-select"
						value={_get(selectedColumns, '0', null)}
						options={assetColumns.filter(ac => ac.dataType === 'string')}
						isClearable={false}
						getOptionLabel={option => option.displayName}
          				getOptionValue={option => option._id}
						onChange={onColumnSelect}
					/>
				</div>
				<div className="calculation-form-section">
					<span className="form-instruction">Select:</span>
					<Select
						classNamePrefix="aut-select"
						value={selectOptions.find(opt => opt.value === direction)}
						options={selectOptions}
						isClearable={false}
						onChange={({value}) => onDirectionSelect(value)}
					/>
				</div>
				<div className="calculation-form-section">
					{this.props.direction === 'mid' && (
						<div className="calculation-form-section">
							<span className="form-instruction">
								<span>Position:</span>
							</span>
							<KiInput
								className="substring-size-input"
								error={this.state.midZeroError}
								value={position}
								type="number"
								onChange={val => this.setPosition(parseInt(val))}
							/>
						</div>
					)}
					<span className="form-instruction">
						<span>Size:</span>
					</span>
					<KiInput
						className="substring-size-input"
						value={size === 0 ? '' : size}
						type="number"
						onChange={val => {
							if (!val) {
								onSizeChange(0);
							} else if (!isNaN(parseInt(val)) && parseInt(val) >= 0) {
								onSizeChange(parseInt(val));
							}
						}}
					/>
					<span className="form-instruction">
						<span className="example">
							Example {this.generateExampleResult('My String', direction, size, position)}
						</span>
					</span>
				</div>
			</div>
		);
	}
}

export default TextFormSubstringFields;
