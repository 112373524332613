import React from 'react';

const thisYear = new Date().getUTCFullYear();

function Footer() {
	return (
		<footer id="main-footer">
			&copy; Copyright {thisYear} Moodys Analytics, Inc. and/or its licensors and affiliates. All rights reserved.
			Build: {window.VERSION}
		</footer>
	);
}

export default Footer;
