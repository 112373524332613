import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import KiInput from 'components/KiInput';
import options from 'ki-common/options';
import {updateColumns, updateColumn} from './actions';
import _ from 'lodash';

export class ColumnSettingList extends Component {
	static propTypes = {
		columns: PropTypes.array.isRequired,
		allSettings: PropTypes.array.isRequired,
		settingList: PropTypes.array.isRequired,
		updateColumns: PropTypes.func.isRequired,
		updateColumn: PropTypes.func.isRequired,
		tableType: PropTypes.string,
		settingType: PropTypes.string,
		setFormIsValid: PropTypes.func.isRequired,
		timeSeriesColumn: PropTypes.string,
		setFormHasChanges: PropTypes.func,
	};

	static defaultProps = {
		columns: [],
		settingList: [],
	};

	moveUp = index => {
		const settingList = this.props.settingList.slice();
		settingList.splice(index - 1, 0, settingList[index]);
		settingList.splice(index + 1, 1);
		this.props.updateColumns(settingList, this.props.settingList[0].columnType);
		this.props.setFormHasChanges();
	};

	moveDown = index => {
		const settingList = this.props.settingList.slice();
		settingList.splice(index + 2, 0, settingList[index]);
		settingList.splice(index, 1);
		this.props.updateColumns(settingList, this.props.settingList[0].columnType);
		this.props.setFormHasChanges();
	};

	remove = index => {
		const settingList = this.props.settingList.slice();
		settingList.splice(index, 1);
		this.props.updateColumns(settingList, this.props.settingList[0].columnType);
		this.props.setFormHasChanges();
	};

	add = setting => {
		const settingList = this.props.settingList.slice();
		delete setting.selectName;
		settingList.push(Object.assign({}, setting));
		this.props.updateColumns(settingList, setting.columnType);
		this.props.setFormHasChanges();
	};

	update = (idx, setting, key, value) => {
		const {allSettings} = this.props;
		const colType = setting.columnType;
		const offset = colType === 'aggregate' ? 1 : allSettings.filter(c => c.columnType === 'aggregate').length + 1;
		setting = Object.assign({}, setting);
		setting[key] = value;
		this.props.updateColumn(idx + offset, setting);
		this.props.setFormHasChanges();
	};

	getDataFormats = col => {
		switch (col.dataType) {
			case 'numeric':
				return options.numericFormats;
			case 'date_short':
				return options.shortDateFormats;
			case 'date_long':
				return options.dateFormats;
			default:
				return options.textFormats;
		}
	};

	getNoColumnMessage = () => {
		const {tableType, settingType, setFormIsValid} = this.props;
		if (tableType === 'asset' && settingType === 'asset') {
			setFormIsValid(false);
			return 'Error: At least one column is required';
		}
		if ((tableType === 'cohort' || tableType === 'timeSeries') && settingType === 'aggregate') {
			setFormIsValid(false);
			return 'Error: At least one column is required';
		}
		setFormIsValid(true);
		return 'No Columns Selected';
	};

	render() {
		const {columns, settingList, timeSeriesColumn, setFormIsValid} = this.props;
		const formattedColumns = columns.map(c => {
			return Object.assign({}, c, {
				selectName: c.calculation ? `${c.displayName} - ${c.calculation}` : c.displayName,
			});
		});
		return (
			<div className="settings-filter-list-body">
				<Select
					classNamePrefix="aut-select"
					value={null}
					closeOnSelect={true}
					getOptionLabel={option => option.selectName}
					getOptionValue={option => option._id}
					hint="Select Columns"
					isClearable={false}
					options={_.sortBy(formattedColumns, colOption => _.get(colOption, 'selectName', '').toLowerCase())}
					onChange={this.add}
				/>

				{settingList.length === 0 ? (
					<div className="sidebar-form-section">
						<p className="no-columns-msg">{this.getNoColumnMessage()}</p>
					</div>
				) : (
					setFormIsValid(true)
				)}
				{settingList.map((setting, idx) => {
					let settingType = setting.calculation || setting.dataType;
					const op = _.get(setting, 'formula.op', '');
					if (op.includes('bf_')) {
						settingType = 'FUNCTION';
					}
					return (
						<section key={idx} className="sidebar-form-setting">
							<header>
								<div>
									<p className="setting-name">
										{columns.find(col => col._id === setting._id)?.displayName ||
											columns.find(col => col._id === setting.assetColumnId)?.displayName}
									</p>
									<p className="setting-type">{settingType}</p>
								</div>
								<div className="setting-icons">
									<i
										title="Move Down"
										className={`material-icons ${idx === settingList.length - 1 ? 'disabled' : ''}`}
										onClick={() => this.moveDown(idx)}
									>
										keyboard_arrow_down
									</i>
									<i
										title="Move Up"
										className={`material-icons ${idx === 0 ? 'disabled' : ''}`}
										onClick={() => this.moveUp(idx)}
									>
										keyboard_arrow_up
									</i>
									<i
										title="Remove"
										className={`material-icons ${
											timeSeriesColumn === setting._id ? 'disabled' : ''
										}`}
										onClick={() => this.remove(idx)}
									>
										clear
									</i>
								</div>
							</header>
							<div className="setting-input-fields">
								<span>
									<div className="form-instruction">Format:</div>
									<Select
										classNamePrefix="aut-select"
										isDisabled={false}
										value={
											setting.dataType === 'string'
												? options.textFormats[0]
												: this.getDataFormats(setting).find(
														s => s.value === setting.displayFormat
												  )
										}
										isClearable={false}
										options={this.getDataFormats(setting)}
										onChange={val => this.update(idx, setting, 'displayFormat', val.value)}
									/>
								</span>
								<KiInput
									name="displayName"
									label="Display Name"
									type="text"
									value={setting.displayName}
									error={null}
									onChange={val => this.update(idx, setting, 'displayName', val)}
								/>
							</div>
						</section>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
	updateColumns,
	updateColumn,
});

export default connect(mapStateToProps, mapDispatchToProps())(ColumnSettingList);
