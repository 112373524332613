import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ListUpdatedAtCell = ({value}) => {
	return <>{value ? moment(value).format('YYYY-MM-DD HH:mm') : ''}</>;
};

ListUpdatedAtCell.propTypes = {
	value: PropTypes.string,
};

export default ListUpdatedAtCell;
