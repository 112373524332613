import PropTypes from 'prop-types';
import React from 'react';
import styles from './kiList.theme.scss';
import cx from 'classnames';

export const KiList = props => {
	const classNames = cx(styles.kiList, props.className, {
		[`${styles.selectable}`]: props.selectable,
	});

	const children = React.Children.map(props.children, child => <li className={`${styles.kiListItem}`}>{child}</li>);

	return <ul className={classNames}>{children}</ul>;
};

KiList.propTypes = {
	className: PropTypes.string,
	selectable: PropTypes.bool,
	children: PropTypes.node,
};

export default KiList;
