import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ColumnPicker from 'components/ColumnPicker';
import options from 'ki-common/options';
import {requeryColumnList} from 'containers/datasetList/actions';
import {connect} from 'react-redux';
import {showSnackbar} from 'state/actions/Snackbar';
import {datasetsApi} from 'api';
import styles from './MiniCreator.theme.scss';
import {defaultMinimalFieldColumnSpecification} from 'api/columnServiceApi';

const DATATYPE_MAP = new Map([
	['numeric', 'numeric'],
	['string', 'text'],
	['date_long', 'date'],
	['date_short', 'date'],
]);

class MiniCreator extends Component {
	static propTypes = {
		datasetId: PropTypes.string,
		fetchDataset: PropTypes.func,
		showSnackbar: PropTypes.func,
		onSave: PropTypes.func,
		columnType: PropTypes.string,
		onCancel: PropTypes.func,
		user: PropTypes.object,
		reloadView: PropTypes.func,
		noNewColumn: PropTypes.bool,
		columns: PropTypes.array,
	};

	state = {
		columnToEdit: null,
	};

	saveColumn = columnJson => {
		return datasetsApi
			.upsertColumns(columnJson, this.props.datasetId)
			.then(({newIds = []}) => {
				return this.props.fetchDataset(this.props.datasetId).then(() => {
					this.props.onSave(newIds[0]);
				});
			})
			.catch(() => {
				this.props.showSnackbar('Error saving column.');
			});
	};

	setColumnToEdit = () => {
		return null;
	};

	render() {
		return (
			<div className={styles.root}>
				<header className={styles.header}>Create Calculation</header>
				<ColumnPicker
					className="add-column-selector inline"
					options={options.calculatedAssetColumnTypes.filter(
						ct =>
							this.props.columnType
								? ct.value === DATATYPE_MAP.get(this.props.columnType)
								: ct.value !== 'conditional'
					)}
					returnNewRows={this.saveColumn}
					columnToEdit={this.state.columnToEdit}
					setColumnToEdit={this.setColumnToEdit}
					closeForm={this.props.onCancel}
					mode="inline"
					user={this.props.user}
					reloadView={this.props.reloadView}
					columnType={'asset'}
					noNewColumn={this.props.noNewColumn}
					columns={this.props.columns}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	columns: state.datasetList.columnList,
	datasetId: state.datasetList.selected.datasetId,
	user: state.user,
});

const mapDispatchToProps = dispatch => ({
	showSnackbar: msg => dispatch(showSnackbar(msg)),
	//fetchDataset: id => dispatch(fetchDataset(id)),
	requeryColumnList: id => requeryColumnList(id, defaultMinimalFieldColumnSpecification),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniCreator);
