import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './editView.theme.scss';
import CreatableSelect from 'react-select/creatable';
import KiInput from 'components/KiInput';
import KiButton from 'components/KiButton';
import KiCheckbox from 'components/KiCheckbox';
import get from 'lodash/get';

export const DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_MAP = new Map([
	['encumbranceAssetLevel', 'Encumbrance Asset Level'],
	['encumbrancePoolSummary', 'Encumbrance Pool Summary'],
	['encumbranceStratification', 'Encumbrance Stratification'],
]);

export class EditView extends Component {
	static propTypes = {
		view: PropTypes.object,
		cancel: PropTypes.func,
		save: PropTypes.func,
		copy: PropTypes.func,
		userBookmarks: PropTypes.array,
		intitialName: PropTypes.string,
		userId: PropTypes.string,
		isCopy: PropTypes.bool,
		isAdmin: PropTypes.bool,
	};

	static defaultProps = {
		view: {},
		userBookmarks: [],
		isCopy: false,
	};

	state = {
		formValues: {
			name: this.props.intitialName || this.props.view.name || '',
			tags: get(this.props.view, 'tags', []).map(t => {
				return {value: t, label: t};
			}),
			isDefault: this.props.view.isDefault,
			isFavorite: this.props.view.isFavorite,
			isGlobal: this.props.view.isGlobal,
			isStratification: this.props.view.dataTransferViewId === 'encumbranceStratification',
		},
		formErrors: {},
	};

	componentDidUpdate(prevProps) {
		if (prevProps.view.dataTransferViewId !== this.props.view.dataTransferViewId) {
			this.setState(state => ({
				...state,
				formValues: {
					...state.formValues,
					isStratification: this.props.view.dataTransferViewId === 'encumbranceStratification',
				},
			}));
		}
	}

	valueChanged = (name, value) => {
		this.setState(
			{
				formValues: {...this.state.formValues, [name]: value},
			},
			() => {
				if (name === 'name') {
					this.validateField(name, value);
				}
			}
		);
	};

	validateField = (name, value) => {
		if (!value.trim()) {
			this.setState({
				formErrors: {...this.state.formErrors, [name]: 'Required'},
			});
		} else {
			this.setState({
				formErrors: {...this.state.formErrors, [name]: null},
			});
		}
	};

	handleTagsChanged = vals => {
		this.setState({
			formValues: {...this.state.formValues, tags: vals},
		});
	};

	getUpdatedView = () => {
		this.setState({formErrors: {}});
		const view = Object.assign(
			{},
			this.props.view,
			{name: this.state.formValues.name},
			{isDefault: this.state.formValues.isDefault},
			{tags: get(this.state.formValues, 'tags', []).map(t => t.value)},
			{isFavorite: this.state.formValues.isFavorite},
			{isGlobal: this.state.formValues.isGlobal},
			{createdBy: this.props.userId}
		);
		if (!this.state.formValues.isGlobal) {
			view.dataTransferViewId = null;
		} else if (this.state.formValues.isStratification) {
			view.dataTransferViewId = 'encumbranceStratification';
		} else if (!this.state.formValues.isStratification && view.dataTransferViewId === 'encumbranceStratification') {
			// only set to null when it was already set to encumbrance (or else it won't clear)
			view.dataTransferViewId = null;
		} else {
			// finally, if it was already set to something else just ignore it so PATCH will retain original value.
			delete view.dataTransferViewId;
		}
		if (view.isDefaultFundingBreaches) {
			delete view.isDefaultFundingBreaches;
		}
		if (view.isDefaultFundingExcess) {
			delete view.isDefaultFundingExcess;
		}
		if (this.props.isCopy) {
			delete view._id;
			view.createdBy = this.props.userId; // all copies are user level to start
		}
		return view;
	};

	handleCancel = () => {
		this.setState(
			{
				formValues: {
					name: this.props.intitialName || this.props.view.name,
					tags: get(this.props.view, 'tags', []).map(t => {
						return {value: t, label: t};
					}),
					isDefault: this.props.view.isDefault,
					isFavorite: this.props.view.isFavorite,
					isStratification: this.props.view.dataTransferViewId === 'encumbranceStratification',
					isGlobal: this.props.view.isGlobal,
				},
				formErrors: {},
			},
			() => this.props.cancel()
		);
	};

	handleSave = () => {
		if (this.state.formValues.name.trim()) {
			this.props.save(this.getUpdatedView());
		} else {
			this.validateField('name', this.state.formValues.name);
		}
	};

	handleCopy = () => {
		const nameValue = this.state.formValues.name;
		if (!nameValue.trim()) {
			this.validateField('name', nameValue);
		} else if (this.props.view.name === nameValue || this.props.userBookmarks.find(v => v.name === nameValue)) {
			this.setState({
				formErrors: {
					...this.state.formErrors,
					name: 'View name already exists. Please enter another name.',
				},
			});
		} else {
			const copiedView = this.getUpdatedView();
			if (copiedView.dataTransferViewId !== 'encumbranceStratification') {
				// only allow encumbranceStratification tags to get copied as other dataTransferViewIds are only allowed to be used once.
				delete copiedView.dataTransferViewId;
			}
			// copied views shouldn't have isSystem
			delete copiedView.isSystem;
			this.props.copy(copiedView);
		}
	};

	render() {
		return (
			<div>
				<div className={styles.title}>{this.props.isCopy ? 'Copy' : 'Edit'}</div>
				<div className={styles.form}>
					<KiInput
						name="name"
						label="View Name"
						type="text"
						value={this.state.formValues.name}
						error={this.state.formErrors.name}
						onChange={val => this.valueChanged('name', val)}
					/>
					<CreatableSelect
						classNamePrefix="aut-select"
						isMulti={true}
						options={[]}
						onChange={this.handleTagsChanged}
						placeholder={'Add Tags'}
						isClearable={true}
						value={this.state.formValues.tags}
					/>
				</div>
				<KiCheckbox
					name="isDefault"
					checked={this.state.formValues.isDefault}
					label="Use as Default For Dataset"
					onChange={val => this.valueChanged('isDefault', val)}
				/>
				<KiCheckbox
					name="isFavorite"
					checked={this.state.formValues.isFavorite}
					label="Favorite"
					onChange={val => this.valueChanged('isFavorite', val)}
				/>
				{this.props.isAdmin && (
					<KiCheckbox
						name="isGlobal"
						checked={this.state.formValues.isGlobal}
						label="Global"
						onChange={val => this.valueChanged('isGlobal', val)}
					/>
				)}
				{!this.props.view.viewColumns && (
					<KiCheckbox
						name="isStratification"
						disabled={
							!this.state.formValues.isGlobal ||
							(this.props.view.dataTransferViewId &&
								this.props.view.dataTransferViewId !== 'encumbranceStratification')
						}
						checked={this.state.formValues.isStratification}
						label="Stratification"
						title={
							(this.props.view.dataTransferViewId &&
								this.props.view.dataTransferViewId !== 'encumbranceStratification' &&
								`View is already assigned to ${DATA_TRANSFER_ASSET_LEVEL_VIEW_ID_MAP.get(
									this.props.view.dataTransferViewId
								) || 'another view type.'}`) ||
							undefined
						}
						style={{
							cursor:
								(this.props.view.dataTransferViewId &&
									this.props.view.dataTransferViewId !== 'encumbranceStratification' &&
									'not-allowed') ||
								undefined,
						}}
						onChange={val => this.valueChanged('isStratification', val)}
					/>
				)}
				<div
					className={`${styles.warningText} ${
						!this.props.view.isDefault &&
						this.state.formValues.isDefault &&
						this.props.userBookmarks.find(v => v.isDefault == true)
							? ''
							: styles.hidden
					}`}
				>
					A default bookmark exists already and will be replaced.
				</div>
				<div className={styles.formButtons}>
					<KiButton flat primary onClick={this.handleCancel} label="Cancel" />
					<KiButton raised primary onClick={this.handleCopy} label="Save As" />
					<KiButton
						raised
						primary
						onClick={this.props.isCopy ? this.handleCopy : this.handleSave}
						label="Save"
					/>
				</div>
			</div>
		);
	}
}
export default EditView;
