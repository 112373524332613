// Globals
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames/bind';

// Project imports
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import KiFontIcon from 'components/KiFontIcon';
import KiModal2 from 'components/KiModal2';
import KiTooltip from 'components/KiTooltip';
import {KiPortfolioDate} from 'components/KiIcons';

// Action imports
import {deleteDate, deleteEntry} from '../actions';

// Local imports
import DateEntryItem from './dateEntryItem';
import styles from './dateGroupItem.theme.scss';

const cx = classNames.bind(styles);

// The modal control functions are being pased down so that we
// have a single modal for date controls at any given time
function DateGroupItem(props) {
	const dispatch = useDispatch();

	const {dateGroup, newDateEntry, editDateEntry} = props;

	// Redux state references
	const isAdmin = useSelector(state => state.user.groups.includes('SystemAdmins'));
	const isLoading = useSelector(state => state.datasetDates.isLoading);

	// Local state
	const [isOpen, setIsOpen] = useState(false);
	const [deleteState, setDeleteStateActual] = useState({
		showModal: false,
		isPending: false,
		isEntry: false,
	});

	// Allows old style state updates
	// eg setDeleteState({showModal: true})
	const setDeleteState = value => {
		setDeleteStateActual({
			...deleteState,
			...value,
		});
	};

	const handleDeleteCancel = () => {
		setDeleteStateActual({
			showModal: false,
			isPending: false,
			isEntry: false,
		});
	};

	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	// Only trigger when the date group is updated in some way
	// IE delete was successful or an item was added
	useEffect(
		() => {
			handleDeleteCancel(); // Reset the delete handler
		},
		[dateGroup]
	);

	// If a delete was attempted and the redux shows that the call
	// is no longer loading, clear out the delete box
	useEffect(
		() => {
			if (deleteState.isPending && !isLoading) {
				handleDeleteCancel(); // Reset the delete handler
			}
		},
		[isLoading]
	);

	const handleDeleteConfirm = () => {
		setDeleteState({
			isPending: true,
		});
		if (deleteState.isEntry) {
			dispatch(deleteEntry(dateGroup.datasetId, dateGroup._id, deleteState.entryId));
		} else {
			dispatch(deleteDate(dateGroup.datasetId, dateGroup._id));
		}
	};

	const handleDeleteDate = () => {
		setDeleteState({
			showModal: true,
			isPending: false,
			isEntry: false,
		});
	};

	const handleDeleteDateEntry = entryId => {
		setDeleteState({
			showModal: true,
			isPending: false,
			isEntry: true,
			entryId,
		});
	};

	const getEntryList = () => {
		if (isOpen) {
			// Make sure that default is always first in the list
			const sortedEntries = _.sortBy(
				dateGroup.entries,
				entry => (_.isEqual(entry.fundingVehicles, ['default']) ? '' : String(entry._id))
			);
			return (
				<React.Fragment>
					{sortedEntries.map(entry => (
						<DateEntryItem
							key={entry._id}
							dateEntry={entry}
							editDateEntry={() => editDateEntry(entry._id)}
							deleteDateEntry={() => handleDeleteDateEntry(entry._id)}
						/>
					))}
				</React.Fragment>
			);
		} else {
			return null;
		}
	};

	const getHoverMessage = () => {
		return (
			<p>
				{`Created on ${dateToShortDate(new Date(dateGroup.createdOn))} by ${dateGroup.createdBy || 'System'}`}
				<br />
				{`Modified on ${dateToShortDate(new Date(dateGroup.updatedOn))} by ${dateGroup.updatedBy || 'System'}`}
			</p>
		);
	};

	const mappedDate = props.mappedDates.find(m => m.defaultValue === dateGroup.groupId);

	return (
		<div className={styles.root}>
			<div className={styles.headerRow} onClick={() => (dateGroup.isPortfolioDate ? () => null : toggleIsOpen())}>
				<div className={cx({dateName: true}, {dateNameActive: isOpen})}>
					{dateGroup.isPortfolioDate && <KiPortfolioDate />}
					{!dateGroup.isPortfolioDate &&
						!isOpen && (
							<i
								className="material-icons list-icon-btn"
								onClick={!dateGroup.isPortfolioDate ? () => setIsOpen(!isOpen) : undefined}
							>
								keyboard_arrow_right
							</i>
						)}
					{!dateGroup.isPortfolioDate &&
						isOpen && (
							<i
								className="material-icons list-icon-btn"
								onClick={!dateGroup.isPortfolioDate ? () => setIsOpen(!isOpen) : undefined}
							>
								keyboard_arrow_down
							</i>
						)}
					<KiTooltip message={getHoverMessage()}>{`${dateGroup.name}${
						mappedDate ? ` (${mappedDate.displayName})` : ''
					}`}</KiTooltip>
				</div>
				{isAdmin && (
					<div className={styles.dateControls}>
						{!dateGroup.isPortfolioDate && (
							<KiFontIcon className="list-icon-btn" value="add_box" onClick={newDateEntry} />
						)}
						{dateGroup.isPortfolioDate &&
							!dateGroup.readOnly && (
								<KiFontIcon
									className="list-icon-btn"
									value="edit"
									onClick={() => editDateEntry(dateGroup.entries[0]._id)}
								/>
							)}
						{dateGroup.readOnly && (
							<KiFontIcon
								className="list-icon-btn"
								value="search"
								onClick={() => editDateEntry(dateGroup.entries[0]._id)}
							/>
						)}
						{!dateGroup.readOnly && (
							<KiFontIcon className="list-icon-btn" value="delete" onClick={handleDeleteDate} />
						)}
					</div>
				)}
				{!isAdmin &&
					dateGroup.isPortfolioDate && (
						<KiFontIcon
							className="list-icon-btn"
							value="search"
							onClick={() => editDateEntry(dateGroup.entries[0]._id)}
						/>
					)}
			</div>
			{getEntryList()}
			{deleteState.showModal && (
				<KiModal2
					active={true}
					header={'Confirm Deletion'}
					onClose={handleDeleteCancel}
					actions={[
						{label: 'Cancel', onClick: handleDeleteCancel},
						{
							label: deleteState.isPending ? 'Deleting...' : 'Delete',
							onClick: () => handleDeleteConfirm(),
							disabled: deleteState.isPending,
						},
					]}
				>
					{deleteState.isEntry
						? 'Are you sure you want to delete this date entry ?'
						: 'Are you sure you want to delete this Date? Doing so will remove the current mapping, but the	date will be available for future use if you wish to remap later.'}
				</KiModal2>
			)}
		</div>
	);
}

DateGroupItem.propTypes = {
	dateGroup: PropTypes.object,
	newDateEntry: PropTypes.func,
	editDateEntry: PropTypes.func,
	mappedDates: PropTypes.array,
};

export default DateGroupItem;
