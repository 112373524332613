// Globals
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import options from 'ki-common/options';

// Website imports
import KiInput from '../../../../components/KiInput';
import KiRadioGroup from '../../../../components/KiRadioGroup';
import KiRadioButton from '../../../../components/KiRadioButton';

// Local imports
import {DataExplorerContext} from '../../DataExplorerContext';
import {QuickFilterSettingContext} from '../../contexts/QuickFilterSettingContext';
import CohortBands from './CohortBands';
import CohortBandsDate from './CohortBandsDate';

export const Cohort = ({setFormErrors, formErrors}) => {
	const dataExplorerContext = useContext(DataExplorerContext);
	const {
		bookmark: {explorerData},
		actions,
		setExplorerData,
	} = dataExplorerContext;

	const quickFilterSettingContext = useContext(QuickFilterSettingContext);
	const {getCohortDropdown, getGroupByDropdown} = quickFilterSettingContext;

	const handleSetCohortDisplayFormat = option => {
		actions.setCohortData({displayFormat: option.value});
	};

	const handleSetCohortDisplayName = displayName => {
		actions.setCohortData({displayName});
		setFormErrors({displayName: displayName ? '' : 'Required'});
	};

	const handleSetIntervalBoundaries = value => {
		setExplorerData({startInclusive: value === 'start' ? true : false}, true);
	};

	const determineFormatType = dataType => {
		switch (dataType) {
			case 'numeric':
				return options.numericFormats;
			case 'date_long':
				return options.dateFormats;
			case 'date_short':
				return options.shortDateFormats;
			default:
				return options.textFormats;
		}
	};

	const cohortDataType = explorerData.columns[0].dataType;
	const formatTypeOptions = determineFormatType(cohortDataType);

	return (
		<div className="cohort-form">
			<div className="sidebar-form-section">
				<span className="form-instruction">Cohort</span>
				{getCohortDropdown()}
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">Group By</span>
				{getGroupByDropdown()}
			</div>
			<div className="sidebar-form-section">
				<span className="form-instruction">Format</span>
				<Select
					classNamePrefix="aut-select"
					value={
						cohortDataType === 'string'
							? formatTypeOptions.find(opt => opt.value === options.textFormats[0])
							: formatTypeOptions.find(opt => opt.value === explorerData.columns[0].displayFormat)
					}
					isClearable={false}
					options={formatTypeOptions}
					onChange={handleSetCohortDisplayFormat}
				/>
			</div>
			<div className="sidebar-form-section">
				<KiInput
					name="cohortDisplayName"
					label="Display Name"
					type="text"
					value={explorerData.columns[0].displayName}
					error={formErrors.displayName}
					onChange={handleSetCohortDisplayName}
				/>
			</div>

			{cohortDataType === 'numeric' && (
				<div className="sidebar-form-section">
					<CohortBands setFormErrors={setFormErrors} formErrors={formErrors} />
				</div>
			)}

			{(cohortDataType === 'date_long' || cohortDataType === 'date_short') && <CohortBandsDate />}

			{cohortDataType === 'numeric' && (
				<div className="sidebar-form-section">
					<span className="form-instruction">Interval Boundaries</span>
					<KiRadioGroup
						value={explorerData.startInclusive ? 'start' : 'end'}
						onChange={handleSetIntervalBoundaries}
					>
						<KiRadioButton label="Start Inclusive" value="start" />
						<KiRadioButton label="End Inclusive" value="end" />
					</KiRadioGroup>
				</div>
			)}
		</div>
	);
};

Cohort.propTypes = {
	setFormErrors: PropTypes.func,
	formErrors: PropTypes.object,
};

export default Cohort;
