const mongoObjectIDStringRegex = require('../../utils/testUtils').mongoObjectIDStringRegex;

const getConstraints = () => ({
	'_id': {
		presence: false,
		format: {
			pattern: mongoObjectIDStringRegex,
			flags: 'i',
			message: '_id should be a valid string of a mongo ObjectID',
		},
	},
	'name': {
		presence: {allowEmpty: false},
	}
});

module.exports = {
	getConstraints
};
