import PropTypes from 'prop-types';
import React from 'react';

const ViewLink = props => {
	const {bookmark, user, isAdmin, allowAll} = props;
	const isEnabled = allowAll || bookmark.createdBy === user.userId || (isAdmin && bookmark.isGlobal);
	return isEnabled ? (
		<div className="list-icon-btn">
			<i title={props.title} alt={props.alt} className="material-icons" onClick={props.onClick}>
				{props.iconName}
			</i>
		</div>
	) : (
		<div className="list-icon-btn">
			<i title={props.title} alt={props.alt} className={`material-icons disabled`}>
				{props.iconName}
			</i>
		</div>
	);
};

ViewLink.propTypes = {
	bookmark: PropTypes.object,
	user: PropTypes.object,
	isAdmin: PropTypes.bool,
	allowAll: PropTypes.bool,
	title: PropTypes.string,
	alt: PropTypes.string,
	iconName: PropTypes.string,
	onClick: PropTypes.func,
};

export default ViewLink;
