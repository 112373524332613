import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _has from 'lodash/has';
import FunctionMappingListItem from '../FunctionMappingListItem';

export class FunctionMappingList extends Component {
	static propTypes = {
		isAdmin: PropTypes.bool.isRequired,
		bfColumns: PropTypes.array.isRequired,
		bfDefaultMappings: PropTypes.array.isRequired,
		saveColumn: PropTypes.func.isRequired,
		deleteColumn: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired,
		selectedFunction: PropTypes.object,
		selectedVariable: PropTypes.object,
		onVariableClick: PropTypes.func.isRequired,
		assetColumns: PropTypes.array.isRequired,
	};

	render() {
		const {
			isAdmin,
			bfColumns,
			saveColumn,
			deleteColumn,
			user,
			bfDefaultMappings,
			selectedFunction,
			selectedVariable,
			onVariableClick,
			assetColumns,
		} = this.props;

		let filterdBfColumns = selectedFunction
			? bfColumns.filter(col => _get(col, 'formula.op', '') === selectedFunction.opType)
			: bfColumns;
		filterdBfColumns = selectedVariable
			? filterdBfColumns.filter(col => _has(col, `formula.left.value.${selectedVariable.name}`))
			: filterdBfColumns;

		// react allows returning an array of elements without a wrapping div now
		return filterdBfColumns.map((col, index) => {
			return (
				<FunctionMappingListItem
					isAdmin={isAdmin}
					user={user}
					key={index}
					bfColumn={col}
					assetColumns={assetColumns.filter(
						col => col.columnType === 'asset' && col.columnFormType !== 'cumulative'
					)}
					saveColumn={saveColumn}
					deleteColumn={deleteColumn}
					bfDefaultMappings={bfDefaultMappings}
					onVariableClick={onVariableClick}
				/>
			);
		});
	}
}

export default FunctionMappingList;
