const _defaultsDeep = require('lodash/defaultsDeep');

const baselineConstraints = {
	name: {
		presence: {allowEmpty: false},
		length: {
			minimum: 1,
			maximum: 100,
			message: 'must be between 1 to 100 characters',
		},
	},
	datasetId: {
		presence: {allowEmpty: false},
	},
	legalName: {
		presence: false,
		length: {
			maximum: 100,
			message: 'must be no more than 100 characters',
		},
	},
};

const websiteConstraints = {
	amountBorrowed: value => {
		if (value) {
			return {
				numericality: true,
			};
		}
		return {
			presence: false,
		};
	},
	warehouseLimit: value => {
		if (value) {
			return {
				numericality: true,
			};
		}
		return {
			presence: false,
		};
	},
	dealId: value => {
		if (value) {
			return {
				format: {
					pattern: '^[A-Za-z0-9+\\-_\'<>=?\\/%&*#$()\\[\\] ]+$',
					message: "'%{value}' is not allowed",
				},
			};
		}
		return {
			presence: false,
		};
	},
};

const mongoConstraints = {};

const getConstraints = options => {
	if (options && options.isMongo) {
		return _defaultsDeep({}, mongoConstraints, baselineConstraints);
	}
	return _defaultsDeep({}, websiteConstraints, baselineConstraints);
};

module.exports = {
	getConstraints,
};
