import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from '../ReplineForm.theme.scss';
import {components} from 'react-select';

const CohortsLabel = ({data, selectedCohort, isCohortBandAble, selectRef, onCohortSelect}) => {
	const currentElement = data.data?.value;
	return (
		<div
			className={classNames({
				[styles.selectLabel]: true,
				[styles.selectedSelectLabel]: selectedCohort && currentElement?._id === selectedCohort._id,
			})}
			onClick={() => {
				selectRef.current.select.blur();
				onCohortSelect(currentElement);
			}}
		>
			{isCohortBandAble(currentElement) && <i className="material-icons">edit</i>}
			<components.MultiValueLabel {...data} />
		</div>
	);
};
CohortsLabel.propTypes = {
	data: PropTypes.object,
	selectedCohort: PropTypes.object,
	isCohortBandAble: PropTypes.func.isRequired,
	selectRef: PropTypes.shape({current: PropTypes.object}).isRequired,
	onCohortSelect: PropTypes.func.isRequired,
};

export default CohortsLabel;
