import _inRange from 'lodash/inRange';
import analytics from 'analytics';

export const _checkStatus = (response, parseResult = true, expectedStatusCode) => {
	if (expectedStatusCode && response.status !== expectedStatusCode) {
		if (parseResult) {
			return response.json().then(err => Promise.reject(err));
		}
		return Promise.reject(response);
	} else if (!_inRange(response.status, 200, 300)) {
		if (parseResult) {
			return response.json().then(err => Promise.reject(err));
		}
		return Promise.reject(response);
	} else if (parseResult) {
		return response.json();
	}
	return response;
};

export const _checkAuth = res => {
	if (res.status === 401) {
		window.location.assign(`login`);
	}
	return res;
};

export const _getHeaders = () => {
	return new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
	});
};

export const _getHeadersFile = () => {
	return new Headers({
		'Content-Type': 'text/csv',
		Accept: 'text/csv',
	});
};

export const _handleResponse = response => {
	_checkAuth(response);
	if (response.status !== 200) {
		return response.json().then(response => {
			analytics.sendErrorEvent(response.message, false);
			return Promise.reject(response);
		});
	}

	return response.json().catch(() => {
		return response.statusText; // If the json parse blows up return the statusText
	});
};

export const apiUrl = `web`;
