const _ = require('lodash');

const getViewParams = (entity, isFormula) => {
	const params = {
		sources: {
			// Core
			includeBorrowingBase: true,
			includeDateColumns: true,
			includeDebtCalculations: true,
			// Entity Inputs
			includeDebtInputs: {
				entityTypes: ['fundingVehicle'],
			},
			includeFundingVehicle: true,
			// Aggregations
			includePoolAggregateColumns: true,
			includeTrancheSetupAggregateColumns: true,
			includeTrancheCalcAggregateColumns: true,
			includeCreditSupportSetupAggregateColumns: true,
			includeCreditSupportCalcAggregateColumns: true,
			includeFeeSetupAggregateColumns: true,
			includeFeeCalcAggregateColumns: true,
			includeManualInputDebtAggregations: true,
			includeAbsIndexColumns: true,
		},
		options: {
			convertAssetDateColumnsToFVDate: true,
			debtInputsNoChildColumns: true,
		},
		filter: {},
	};

	// Formulas must us actual (child) columns
	if (isFormula) {
		_.set(params, 'options.debtInputsNoChildColumns', false);
	}

	switch (entity) {
		case 'fundingVehicle':
			_.set(params, 'filters.entityTypes', ['fundingVehicle']);
			break;
		case 'tranche':
		case 'debt':
			_.set(params, 'sources.includeTrancheSetupColumns', true);
			_.set(params, 'filters.entityTypes', ['fundingVehicle', 'tranche']);
			_.set(params, 'sources.includeDebtInputs', {entityTypes: ['fundingVehicle', 'tranche']});
			_.set(params, 'sources.includeAbsRatingColumns', true);
			break;
		case 'creditSupport':
			_.set(params, 'sources.includeCreditSupportSetupColumns', true);
			_.set(params, 'filters.entityTypes', ['fundingVehicle', 'creditSupport']);
			_.set(params, 'sources.includeDebtInputs', {entityTypes: ['fundingVehicle', 'creditSupport']});
			break;
		case 'fee':
			_.set(params, 'sources.includeFeeSetupColumns', true);
			_.set(params, 'filters.entityTypes', ['fundingVehicle', 'fee']);
			_.set(params, 'sources.includeDebtInputs', {entityTypes: ['fundingVehicle', 'fee']});
			break;
		case 'trigger':
			_.set(params, 'sources.includeTriggerSetupColumns', true);
			_.set(params, 'filters.entityTypes', ['fundingVehicle', 'trigger']);
			_.set(params, 'sources.includeDebtInputs', {entityTypes: ['fundingVehicle', 'trigger']});
			break;
		case 'pool':
			_.set(params, 'sources.includeAggregateColumns', true);
			_.set(params, 'filters.entityTypes', ['fundingVehicle']);
			break;
		case 'counterparty':
			_.set(params, 'sources.includeDebtCalculations', false);
			_.set(params, 'sources.includeAbsIndexColumns', false);
			_.set(params, 'sources.includeAbsRatingColumns', true);
			_.set(params, 'sources.includeCounterpartySetupColumns', true);
			break;
		default:
			return {sources: {}, options: {}, filter: {}};
	}
	return params;
};

const getActivityViewParams = entity => {
	switch (entity) {
		case 'fundingVehicle':
			return {
				sources: {
					includeCohortColumns: true, // filter to only columnName === 'kiFVName'
					includeDebtInputs: {entityTypes: ['fundingVehicle']},
				},
				options: {},
				filters: {},
			};
		case 'debt':
			return {
				sources: {
					includeTrancheSetupColumns: true, // filter to only columnName = name
					includeDebtInputs: {entityTypes: ['tranche']},
				},
				options: {},
				filters: {},
			};
		case 'creditSupport':
			return {
				sources: {
					includeCreditSupportSetupColumns: true, // filter to only columnName = name
					includeDebtInputs: {entityTypes: ['creditSupport']},
				},
				options: {},
				filters: {},
			};
		case 'fee':
			return {
				sources: {
					includeFeeSetupColumns: true, // filter to only columnName = name
					includeDebtInputs: {entityTypes: ['fee']},
				},
				options: {},
				filters: {},
			};
		case 'trigger':
			return {
				sources: {
					includeTriggerSetupColumns: true, // filter to only columnName = name
					includeDebtInputs: {entityTypes: ['trigger']},
				},
				options: {},
				filters: {},
			};
		default:
			return {sources: {}, options: {}, filter: {}};
	}
};

const getColServiceParamsForView = (viewEntity, params) => {
	const {isActivity = false, isFormula = false} = params;
	return isActivity ? getActivityViewParams(viewEntity) : getViewParams(viewEntity, isFormula);
};

const getAvailableScenarioContextOptions = (opts = [], entityType, viewType) =>
	opts.filter(
		opt =>
			opt &&
			// filter lastCommitted (Last Locked) from everything EXCEPT pool transaction
			!(opt.value === 'lastCommitted' && !(entityType === 'pool' && viewType === 'transaction')) &&
			// filter lastApproved (End of Day) from poolTransaction
			!(opt.value === 'lastApproved' && entityType === 'pool' && viewType === 'transaction')
	);

module.exports = {
	getColServiceParamsForView,
	getAvailableScenarioContextOptions,
};
