import React from 'react';
import PropTypes from 'prop-types';
import KiIconButton from 'components/KiIconButton';
import styles from './InterestRateAssumption.theme.scss';
import Select from 'react-select';
import RateSelector, {ControlWithLabel} from './RateSelector';

const InterestRateAssumption = props => {
	return (
		<div className={styles.interestRateAssumption} style={{minWidth: '30rem'}}>
			<div className={styles.interestRateRowInputContainer}>
				<Select
					menuIsOpen={props.readOnly ? false : undefined}
					isSearchable={props.readOnly ? false : undefined}
					components={{
						Control: ControlWithLabel,
					}}
					styles={{
						container: provided => ({
							...provided,
							flex: '1 1 14rem',
						}),
					}}
					menuPosition="fixed"
					classNamePrefix="aut-select ira"
					label="Index"
					options={props.indexList}
					onChange={opt => props.updateRateSelections(props.vectorIndex, {indexName: opt?.value ?? null})}
					value={props.indexList.filter(
						index => index.value === (props.irMapping.indexName?.value || props.irMapping.indexName)
					)}
				/>
				<RateSelector
					readOnly={props.readOnly}
					rateAmount={props.irMapping.rate}
					vectors={props.vectorList}
					vectorId={props.irMapping.vectorId}
					onRateChange={(rate, vectorId) => props.updateRateSelections(props.vectorIndex, {vectorId, rate})}
					rateLabel="Rate"
				/>
			</div>
			<KiIconButton
				icon="remove_circle"
				inverse
				hidden={props.readOnly}
				disabled={props.readOnly}
				onClick={() => props.removeRateSelection(props.vectorIndex)}
			/>
		</div>
	);
};

InterestRateAssumption.propTypes = {
	readOnly: PropTypes.bool,
	vectorIndex: PropTypes.number.isRequired,
	vectorList: PropTypes.array.isRequired,
	indexList: PropTypes.array.isRequired,
	irMapping: PropTypes.object.isRequired,
	updateRateSelections: PropTypes.func.isRequired,
	removeRateSelection: PropTypes.func.isRequired,
};

InterestRateAssumption.defaultProps = {
	readOnly: false,
	vectors: [],
	vectorList: [],
	indexList: [],
};

export default InterestRateAssumption;
