import PropTypes from 'prop-types';
import React from 'react';

export const SubmissionMessage = props => {
	if (props.active) {
		return <div className={props.className}>{props.text}</div>;
	}
	return null;
};

SubmissionMessage.propTypes = {
	text: PropTypes.string,
	active: PropTypes.bool,
	className: PropTypes.string,
};

export default SubmissionMessage;
