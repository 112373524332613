import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import TopBar from './components/TopBar';
import {fetchDataset, fetchDatasetList} from 'containers/datasetList/actions';
import {fetchProjectionScenarios} from 'containers/forecasting/actions';
import {openContextSidebarPanel} from 'state/actions/App';
import ScenariosForm from './components/Scenarios';
import ContextSidebar from '../../components/ContextSidebar';
import ContextIcons from '../../components/ContextSidebar/icons';
import FlyoutAssumptions from './components/Assumptions/FlyoutAssumptions';
import FlyoutReplines from './components/Replines/FlyoutReplines';
import './index.scss';
import FlyoutVectors from './components/Vectors/FlyoutVectors';
import FlyoutPricingAssumptions from './components/PricingAssumptions/FlyoutPricingAssumptions';
import FlyoutNotionalPools from './components/NotionalPools/FlyoutNotionalPools';

export class Forecasting extends Component {
	static propTypes = {
		app: PropTypes.object.isRequired,
		fetchProjectionScenarios: PropTypes.func.isRequired,
		fetchDatasetList: PropTypes.func.isRequired,
		projectionScenarios: PropTypes.array,
		allDatasets: PropTypes.array,
	};

	static defaultProps = {
		projectionScenarios: [],
		allDatasets: [],
	};

	state = {
		projectionScenarios: [],
	};

	componentDidMount() {
		this.props.fetchProjectionScenarios();
		if (!this.props.allDatasets.length) {
			this.props.fetchDatasetList();
		}
		document.title = `${this.props.app.kiVersion} - Forecasting`;
	}

	render() {
		return (
			<div className="container-wrapper">
				<div className="container-body">
					<TopBar />
					<div className="ki-panel">
						<ScenariosForm scenarios={this.props.projectionScenarios} datasets={this.props.allDatasets} />
					</div>
				</div>
				<ContextSidebar
					items={[
						{
							name: 'Assumptions',
							icon: <ContextIcons.AssumptionsIcon />,
							element: <FlyoutAssumptions />,
						},
						{
							name: 'Vectors',
							icon: <ContextIcons.VectorsIcon />,
							element: <FlyoutVectors />,
						},
						{
							name: 'Replines',
							icon: <ContextIcons.ReplinesIcon />,
							element: <FlyoutReplines />,
						},
						{
							name: 'Tranche Level Pricing Assumptions',
							icon: <ContextIcons.PricingAssumptionsIcon />,
							element: <FlyoutPricingAssumptions />,
						},
						{
							name: 'Notional Pools',
							icon: <ContextIcons.NotionalPoolsIcon />,
							element: <FlyoutNotionalPools />,
						},
					]}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	app: state.app,
	projectionScenarios: state.forecasting.projectionScenarios,
	allDatasets: state.datasetList.data,
});

const mapDispatchToProps = () => ({
	fetchDataset,
	fetchDatasetList,
	fetchProjectionScenarios,
	openContextSidebarPanel,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(Forecasting);
