import {apiUrl, _checkAuth, _handleResponse, _getHeaders} from 'api/apiUtils';
import analytics from 'analytics';
import {datasetDatesApi} from 'api';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import moment from 'moment';
import columnServiceApi from './columnServiceApi';

const getMostRecentSnapshot = dataset => {
	const sortStringDates = (a, b) => {
		const dateA = new Date(a.snapshotDate).getTime();
		const dateB = new Date(b.snapshotDate).getTime();
		return dateA > dateB ? 1 : -1;
	};
	return dataset.snapshots.sort(sortStringDates)[dataset.snapshots.length - 1];
};

const findDefaultDateContext = (dateContextList, selectedDateContext) => {
	const match = dateContextList.find(
		d => (selectedDateContext ? d._id === selectedDateContext : d.isLatestSnapshot === true)
	);
	if (!match) {
		return dateContextList[0];
	}
	return match;
};

const findProfileDateContextByName = (dateContextList, dateContextName = 'Latest Snapshot') => {
	const match = dateContextList.find(dateContext => {
		return (
			dateContext.name == dateContextName && dateContext.isPortfolioDate === true && dateContext.readOnly === true
		);
	});
	if (!match) {
		return dateContextList[0];
	}
	return match;
};

export const deleteBookmark = bookmarkId => {
	analytics.sendEvent('bookmarks', 'delete');
	return fetch(`${apiUrl}/bookmarks/${bookmarkId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	}).then(_handleResponse);
};

export const generateBookmark = async (dataset, statementDate, dateContext, isFixedDate) => {
	const [dateContextList, datasetColumns] = await Promise.all([
		datasetDatesApi.fetchPortfolioDates(dataset.datasetId),
		columnServiceApi.getColumnsFromService(dataset.datasetId, {
			sources: {},
			options: {
				includeAllColumns: true,
				embedColumns: true,
			},
		}),
	]);
	const selectedSnapshot = getMostRecentSnapshot(dataset);

	const assetNumberAssetColumn = datasetColumns.find(col => col.columnName === selectedSnapshot.assetNumberMap);

	const assetCountAssetColumn = datasetColumns.find(col => col.columnName === 'kiAssetCount');
	const assetCountColumn = datasetColumns.find(
		col =>
			col.assetColumnId === assetCountAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM'
	);
	const assetCountPercentColumnPortfolio = datasetColumns.find(
		col =>
			col.assetColumnId === assetCountAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM_PCT' &&
			col.displayName === 'Asset Count'
	);

	const balanceAssetColumn = datasetColumns.find(col => col.columnName === selectedSnapshot.balanceMap);
	const balanceCohortColumn = Object.assign(
		datasetColumns.find(col => col.assetColumnId === balanceAssetColumn._id && col.columnType === 'cohort'),
		{
			numberOfBuckets: 25,
			bucket: {
				min: '',
				max: '',
				value: '',
			},
			granularity: '',
		}
	);
	const balanceSumColumn = datasetColumns.find(
		col =>
			col.assetColumnId === balanceAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM' &&
			col.displayName === 'Balance'
	);
	const balanceSumPercentColumnPortfolio = datasetColumns.find(
		col =>
			col.assetColumnId === balanceAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM_PCT' &&
			col.displayName === 'Balance'
	);

	const bookmark = {
		name: 'Dataset View',
		datasetId: dataset.datasetId,
		isDefault: true,
		isGlobal: true,
		createdBy: null,
		createDate: new Date().toISOString(),
		tags: [],
		isGadget: false,
		explorerData: {
			name: 'Dataset View',
			datasetId: dataset.datasetId,
			pageSize: 100,
			pageNumber: 1,
			sortOrder: 'asc',
			sortColumn: balanceCohortColumn._id,
			statementDate: statementDate || dateToShortDate(new Date()),
			isFixedDate: isFixedDate || false,
			dateContext: findDefaultDateContext(dateContextList)._id,
			startDateContext: findProfileDateContextByName(dateContextList, 'Start of Month')._id,
			endDateContext: findProfileDateContextByName(dateContextList, 'Month End')._id,
			tableType: 'cohort',
			quickFilters: {
				scenarioId: '',
				fundingVehicleId: '',
				poolId: '',
				hypoFundingVehicleId: '',
				hypoPoolId: '',
				scenarioType: 'assetSnapshot',
			},
			columns: [
				balanceCohortColumn,
				assetCountColumn,
				assetCountPercentColumnPortfolio,
				balanceSumColumn,
				balanceSumPercentColumnPortfolio,
				assetNumberAssetColumn,
				balanceAssetColumn,
			],
			bands: {
				type: 'default',
			},
			startInclusive: true,
		},
	};
	return bookmark;
};

export const fetchBookmarks = () => {
	return fetch(`${apiUrl}/bookmarks/`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const fetchBookmarksByUserId = (userId, datasetId) => {
	if (!userId) {
		return fetchBookmarks();
	}
	return fetch(`${apiUrl}/bookmarks/getByUserId/${userId}?datasetId=${datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

export const createBookmark = bookmark => {
	analytics.sendEvent('bookmarks', 'create');
	return fetch(`${apiUrl}/bookmarks`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(bookmark),
	}).then(_handleResponse);
};

export const upsertBookmark = bookmark => {
	analytics.sendEvent('bookmarks', 'update');
	return fetch(`${apiUrl}/bookmarks`, {
		credentials: 'include',
		method: 'PATCH',
		headers: _getHeaders('PATCH'),
		body: JSON.stringify(bookmark),
	}).then(_handleResponse);
};

export const checkBookmarkExists = name => {
	analytics.sendEvent('bookmarks', 'check-unique-name');
	const encodedName = encodeURI(name);
	return fetch(`${apiUrl}/bookmarks/exists/${encodedName}`, {
		credentials: 'include',
		headers: _getHeaders(),
	}).then(_handleResponse);
};

// fetchBookmarkDefault
export const fetchBookmark = (bookmarkId, dataset, userId) => {
	if (bookmarkId) {
		analytics.sendEvent('bookmarks', 'get-by-id');
		return fetch(`${apiUrl}/bookmarks/${bookmarkId}`, {
			credentials: 'include',
			headers: _getHeaders(),
		}).then(_handleResponse);
	}
	return fetch(`${apiUrl}/bookmarks/dataset/${dataset.datasetId}?userId=${userId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(response => {
			if (response.status !== 200) {
				return response.json().then(response => {
					analytics.sendErrorEvent(response.message, false);
					return Promise.reject(response);
				});
			}
			return response.json().then(async bookmark => {
				if (!bookmark) {
					const bookmark = await generateBookmark(dataset);
					return createBookmark(bookmark).then(result => {
						bookmark._id = result._id;
						return bookmark;
					});
				} else {
					return bookmark;
				}
			});
		});
};

const _getExcessGroupByBreachCols = (
	dataset,
	assetNumberAssetColumn,
	balanceAssetColumn,
	dataColumn,
	datasetColumns
) => {
	let firstColumn;
	// if the dataColumn is an aggregate us it directly its part of a groupBy request
	if (dataColumn.columnType === 'aggregate') {
		firstColumn = dataColumn;
	}
	// if the dataColumn is an asset then find the matching cohort and use that
	if (dataColumn.columnType === 'asset') {
		const cohortCol = datasetColumns.find(
			col => col.assetColumnId === dataColumn._id && col.columnType === 'cohort'
		);
		firstColumn = cohortCol;
	}

	const assetCountAssetColumn = datasetColumns.find(col => col.columnName === 'kiAssetCount');
	const assetCountColumn = datasetColumns.find(
		col =>
			col.assetColumnId === assetCountAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM'
	);
	const assetCountPercentColumnPortfolio = datasetColumns.find(
		col =>
			col.assetColumnId === assetCountAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM_PCT' &&
			col.displayName === 'Asset Count'
	);
	const balanceSumColumn = datasetColumns.find(
		col =>
			col.assetColumnId === balanceAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM' &&
			col.displayName === 'Balance'
	);
	const balanceSumPercentColumnPortfolio = datasetColumns.find(
		col =>
			col.assetColumnId === balanceAssetColumn._id &&
			col.columnType === 'aggregate' &&
			col.calculation === 'SUM_PCT' &&
			col.displayName === 'Balance'
	);
	const columns = [
		firstColumn,
		assetCountColumn,
		assetCountPercentColumnPortfolio,
		balanceSumColumn,
		balanceSumPercentColumnPortfolio,
	];
	return columns;
};

export const generateBookmarkForFundingExcessBreaches = async (
	drilldownType,
	dataset,
	statementDate,
	dateContext,
	dataColumnId,
	groupBy,
	fvId,
	scenarioType,
	transferDate,
	scenarioId,
	tableType
) => {
	const [dateContextList, datasetColumns] = await Promise.all([
		datasetDatesApi.fetchPortfolioDates(dataset.datasetId),
		columnServiceApi.getColumnsFromService(dataset.datasetId, {
			sources: {},
			options: {
				includeAllColumns: true,
				embedColumns: true,
			},
		}),
	]);

	const selectedSnapshot = getMostRecentSnapshot(dataset);
	const assetNumberAssetColumn = datasetColumns.find(col => col.columnName === selectedSnapshot.assetNumberMap);
	const balanceAssetColumn = datasetColumns.find(col => col.columnName === selectedSnapshot.balanceMap);
	const dataColumn = datasetColumns.find(col => col._id === dataColumnId);

	let type;
	let viewColumns;

	// Excess/Compliance Views, Aggregate data cols, or having a groupBy set change the view
	// to be a Summary type with different columns
	if (
		drilldownType === 'excess' ||
		dataColumn.columnType === 'aggregate' ||
		(groupBy && groupBy !== assetNumberAssetColumn._id.toString())
	) {
		type = 'cohort';
		viewColumns = _getExcessGroupByBreachCols(
			dataset,
			assetNumberAssetColumn,
			balanceAssetColumn,
			dataColumn,
			datasetColumns
		);
		// to allow drill into asset
		viewColumns.push(assetNumberAssetColumn, balanceAssetColumn);
	} else {
		type = 'asset';
		const cohortCol = datasetColumns.find(
			col => col.assetColumnId === dataColumn._id && col.columnType === 'cohort'
		);
		viewColumns = [cohortCol, assetNumberAssetColumn, balanceAssetColumn, dataColumn];
	}

	const bookmark = {
		datasetId: dataset.datasetId,
		name: 'Funding View',
		isDefault: false,
		createdBy: null,
		createDate: new Date().toISOString(),
		tags: [],
		isGadget: false,
		explorerData: {
			datasetId: dataset.datasetId,
			pageSize: 100,
			pageNumber: 1,
			sortOrder: 'asc',
			groupBy: null, // Null is the default for explorer and is the same as matching AssetId in funding
			sortColumn: viewColumns[0]._id,
			statementDate: moment(statementDate)
				.add(1, 'days')
				.format('YYYY-MM-DD'),
			isFixedDate: false,
			isFundingLink: true,
			dateContext: findDefaultDateContext(dateContextList)._id,
			startDateContext: findProfileDateContextByName(dateContextList, 'Start of Month')._id,
			endDateContext: findProfileDateContextByName(dateContextList, 'Month End')._id,
			tableType: type,
			quickFilters: {
				scenarioId: '',
				fundingVehicleId: fvId,
				poolId: '',
				hypoFundingVehicleId: '',
				hypoPoolId: '',
				scenarioType: 'assetSnapshot',
			},
			columns: viewColumns,
			bands: {
				type: 'default',
			},
			numberOfBuckets: 25,
			bucket: {
				min: '',
				max: '',
				value: '',
			},
			granularity: '',
			startInclusive: true,
		},
	};
	if (groupBy && groupBy !== assetNumberAssetColumn._id.toString()) {
		let groupByColumn = datasetColumns.find(col => col._id === groupBy);
		if (groupByColumn.columnType === 'asset') {
			groupByColumn = datasetColumns.find(col => col.assetColumnId === groupBy && col.columnType === 'cohort');
		}
		bookmark.explorerData.groupBy = groupByColumn;
	}

	// Breaches / Exclusions
	if (drilldownType === 'breaches') {
		bookmark.isDefaultFundingBreaches = true;
		bookmark.name = 'Funding Breaches View';
	}

	// Compliance
	if (drilldownType === 'excess') {
		bookmark.isDefaultFundingExcess = true;
		bookmark.name = 'Funding Excess Concentration View';
	}

	switch (scenarioType) {
		case 'assetSnapshot':
		case 'lastApproved':
			bookmark.explorerData.quickFilters.scenarioType = scenarioType;
			bookmark.explorerData.quickFilters.scenarioId = scenarioType;
			break;
		case 'lastCommitted':
			bookmark.explorerData.quickFilters.scenarioType = scenarioType;
			bookmark.explorerData.quickFilters.scenarioId = transferDate;
			break;
		case 'hypo':
			bookmark.explorerData.quickFilters.scenarioType = scenarioType;
			bookmark.explorerData.quickFilters.scenarioId = scenarioId;
			break;
		default:
			break;
	}

	// not sure this is needed with the hypo scenarioType
	if (tableType === 'scenario') {
		bookmark.explorerData.quickFilters.scenarioId = scenarioId;
	}

	return bookmark;
};

export const fetchBreachesBookmark = (
	dataset,
	statementDate,
	dateContext,
	dataColumnId,
	groupBy,
	fvId,
	scenarioType,
	transferDate,
	scenarioId,
	tableType
) => {
	return fetch(`${apiUrl}/bookmarks/dataset/breaches/${dataset.datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(response => {
			if (response.status !== 200) {
				return response.json().then(response => {
					analytics.sendErrorEvent(response.message, false);
					return Promise.reject(response);
				});
			}
			return response.json().then(async bookmark => {
				if (bookmark) {
					deleteBookmark(bookmark._id);
				}
				const bm = await generateBookmarkForFundingExcessBreaches(
					'breaches',
					dataset,
					statementDate,
					dateContext,
					dataColumnId,
					groupBy,
					fvId,
					scenarioType,
					transferDate,
					scenarioId,
					tableType
				);
				return createBookmark(bm).then(result => {
					bm._id = result._id;
					return bm;
				});
			});
		});
};

export const fetchExcessBookmark = (
	dataset,
	statementDate,
	dateContext,
	dataColumnId,
	groupBy,
	fvId,
	scenarioType,
	transferDate,
	scenarioId,
	tableType
) => {
	return fetch(`${apiUrl}/bookmarks/dataset/excess/${dataset.datasetId}`, {
		credentials: 'include',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(response => {
			if (response.status !== 200) {
				return response.json().then(response => {
					analytics.sendErrorEvent(response.message, false);
					return Promise.reject(response);
				});
			}
			return response.json().then(async bookmark => {
				if (bookmark) {
					deleteBookmark(bookmark._id);
				}
				const bm = await generateBookmarkForFundingExcessBreaches(
					'excess',
					dataset,
					statementDate,
					dateContext,
					dataColumnId,
					groupBy,
					fvId,
					scenarioType,
					transferDate,
					scenarioId,
					tableType
				);
				return createBookmark(bm).then(result => {
					bm._id = result._id;
					return bm;
				});
			});
		});
};

export const getDefaultBookmark = dataset => {
	return generateBookmark(dataset);
};

export default {
	fetchBookmarks,
	fetchBookmark,
	createBookmark,
	upsertBookmark,
	checkBookmarkExists,
	deleteBookmark,
	fetchBookmarksByUserId,
	getDefaultBookmark,
	generateBookmark,
	fetchBreachesBookmark,
	fetchExcessBookmark,
};
