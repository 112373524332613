import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from '../wizard.theme.scss';
import KiButton from 'components/KiButton';
import KiButtonSpinner from 'components/KiButtonSpinner';

export class WizardFooter extends Component {
	static propTypes = {
		onCancelClick: PropTypes.func,
		onNextClick: PropTypes.func,
		onBackClick: PropTypes.func,
		showBackButton: PropTypes.bool,
		showCancelButton: PropTypes.bool,
		showNextButton: PropTypes.bool,
		disableNextButton: PropTypes.bool,
		nextButtonLabel: PropTypes.string,
		cancelButtonLabel: PropTypes.string,
		showSpinner: PropTypes.bool,
	};

	static defaultProps = {
		showSpinner: false,
	};

	render() {
		const {
			showBackButton,
			showCancelButton,
			showNextButton,
			onBackClick,
			onCancelClick,
			onNextClick,
			cancelButtonLabel,
			nextButtonLabel,
			disableNextButton,
			showSpinner,
		} = this.props;

		return (
			<div className={styles.footer}>
				<div className={styles.left}>
					{showBackButton && (
						<KiButton primary className={styles.backButton} onClick={onBackClick}>
							Back
						</KiButton>
					)}
				</div>

				<div className={styles.right}>
					{showCancelButton && (
						<KiButton className={styles.cancelButton} onClick={onCancelClick}>
							{cancelButtonLabel}
						</KiButton>
					)}

					{showNextButton && (
						<KiButtonSpinner
							showSpinner={showSpinner}
							primary={true}
							id="wizard-next-button"
							disabled={disableNextButton}
							className={styles.nextButton}
							onClick={onNextClick}
							label={nextButtonLabel}
						/>
					)}
				</div>
			</div>
		);
	}
}
export default WizardFooter;
