import {Manager} from 'socket.io-client';
const ledgerManager = new Manager({
	transports: ['websocket'],
	autoConnect: false,
	reconnectionAttempts: 50,
	path: `${new URL(document.baseURI).pathname}web/ledgers/socket.io`,
});

export default {
	pendingSubs: [],
	ledgerSocket: ledgerManager.socket('/'),
	namespaces: {
		jobs: ledgerManager.socket('/jobs'),
		templates: ledgerManager.socket('/templates'),
	},
	initialize(store) {
		if (window.LEDGER_ENABLED !== 'true') {
			return;
		}
		this.store = store;
		this.ledgerSocket.open();
		this.namespaces.jobs.open();
		this.namespaces.templates.open();
		this.pendingSubs.forEach(({event, cb = () => null, namespace}) => {
			this.on(event, cb, namespace);
		});
	},
	on(event, cb, namespace) {
		if (!this.ledgerSocket) {
			const pendingSub = {
				event,
				cb,
			};
			if (namespace) {
				pendingSub.namespace = namespace;
			}
			this.pendingSubs.push(pendingSub);
		} else if (namespace) {
			this.namespaces[namespace].on(event, (...args) => {
				const result = cb(...args);
				return result instanceof Function ? result(this.store.dispatch, this.store.getState) : result;
			});
		} else {
			this.ledgerSocket.on(event, (...args) => {
				const result = cb(...args);
				return result instanceof Function ? result(this.store.dispatch, this.store.getState) : result;
			});
		}
	},
	emit(msg, data) {
		this.ledgerSocket.emit(msg, data);
	},
	emitIn(namespace, msg, data) {
		this.namespaces[namespace].emit(msg, data);
	},
	off(event, fn) {
		this.ledgerSocket.off(event, fn);
	},
	selfDestruct() {
		if (window.LEDGER_ENABLED !== 'true') {
			return;
		}

		this.namespaces.jobs.close();
		this.namespaces.templates.close();
		this.ledgerSocket.close();
	},
};
