import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiInput from 'components/KiInput';
import KiIconButton from 'components/KiIconButton';
import KiSelect from 'components/KiSelect';
import DebtInputAccountFormRow from './DebtInputAccountFormRow';
import _ from 'lodash';

class DebtInputAccountForm extends Component {
	static propTypes = {
		formValues: PropTypes.object.isRequired,
		unsavedValues: PropTypes.object.isRequired,
		onSaveNewAccountCol: PropTypes.func.isRequired,
		debtColumns: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		onDeleteAccountColumn: PropTypes.func.isRequired,
		templateNames: PropTypes.array.isRequired,
		setAccountColumnActivity: PropTypes.func.isRequired,
		onSaveNewMapping: PropTypes.func.isRequired,
	};

	state = {
		newAccountColumnName: '',
		accountColumnToAdd: null,
		isDeleteConfirmActive: false,
		accountColumnToDelete: null,
		mappedDebtCalcs: {},
	};

	render() {
		const {
			formValues,
			unsavedValues,
			debtColumns,
			templateNames,
			setAccountColumnActivity,
			onSaveNewMapping,
			onDeleteAccountColumn,
		} = this.props;
		const accountColumns = unsavedValues.accountColumns || formValues.accountColumns || [];
		const nameInUse = formValues.name !== unsavedValues.name && templateNames.includes(unsavedValues.name);

		// Old filter if needed
		const mappingOptions = debtColumns.filter(
			c =>
				(!c.accountColumns &&
					((c.calcEntityLevel || c.entityType) === formValues.viewType &&
						(c.dataType === 'numeric' && ((c.isAccount || !!c.formulas) && !c.accountId)))) ||
				(c.accountId && c.accountId === formValues._id && !c.isAccountBalance)
		);

		// const mappingOptions = debtColumns.filter(
		// 	c =>
		// 		// Column is not a register primary AND ??? AND ???
		// 		(!c.accountColumns && ((c.isAccount || !!c.formulas) && !c.accountId)) ||
		// 		// If this is a child column AND of this entry AND not the starting or ending balance
		// 		(c.accountId && c.accountId === formValues._id && !c.isAccountBalance)
		// );

		// Add the create new option, filter to only unmapped debt inputs
		const newMappingOptions = [
			{
				_id: 'new',
				displayName: 'Create New...',
				className: 'option-bold-italic',
			},
			...mappingOptions.filter(c => !c.sourceId),
		];

		return (
			<section className="account-section">
				<div className="account-beginning-col">
					<p style={{textDecoration: 'underline'}} className={'account-section-label h3-size'}>
						Beginning Balance Column:
					</p>
					<KiInput
						name={'beginning_balance'}
						value={`${formValues.name || unsavedValues.name || ''}_Beginning_Bal`}
						label={'Debt Input Name'}
						disabled={true}
						className="balance_column_name"
					/>
				</div>
				{formValues.editTemplate !== false && formValues._id ? (
					<section>
						{this.state.accountColumnToAdd && this.state.accountColumnToAdd._id === 'new' ? (
							<div className="account-section-column-row">
								<KiInput
									name={'beginning_balance'}
									value={this.state.newAccountColumnName}
									label={'Debt Input Name'}
									disabled={false}
									className="balance_column_name"
									onChange={val => this.setState({newAccountColumnName: val})}
								/>
								<div className="account-section-action-buttons">
									<KiIconButton
										icon={'add'}
										disabled={
											nameInUse ||
											// valueError ||
											// nameError ||
											this.state.newAccountColumnName.length === 0
										}
										onClick={() => {
											this.props.onSaveNewAccountCol({
												newAccountColumnName: this.state.newAccountColumnName,
											});
											this.setState({
												newAccountColumnName: '',
												accountColumnToAdd: null,
											});
										}}
									/>
									<KiIconButton
										icon={'close'}
										onClick={() => this.setState({accountColumnToAdd: null})}
									/>
								</div>
							</div>
						) : (
							<div className="account-section-column-row">
								<KiSelect
									getOptionLabel={option => option.displayName || option.name}
									getOptionValue={option => option._id}
									options={formValues._id ? newMappingOptions : mappingOptions}
									value={this.state.accountColumnToAdd}
									onChange={val => this.setState({accountColumnToAdd: val})}
									className="account-section-column-select"
								/>
								<KiIconButton
									icon="add"
									disabled={!_.get(this.state.accountColumnToAdd, '_id')}
									onClick={() => {
										this.setState({accountColumnToAdd: null});
										this.props.onChange('addAccountColumn', this.state.accountColumnToAdd._id);
									}}
								/>
							</div>
						)}
					</section>
				) : (
					!formValues.isTrancheAccountTemplate && (
						<div className="account-section-column-row save-account-message">
							Save the account first in order to create and apply account columns.
						</div>
					)
				)}
				{accountColumns.length > 0 &&
					accountColumns.map((item, index) => (
						<DebtInputAccountFormRow
							key={index}
							debtColumns={debtColumns}
							setAccountColumnActivity={setAccountColumnActivity}
							onSaveNewMapping={onSaveNewMapping}
							accountColumn={item}
							formValues={formValues}
							onDeleteAccountColumn={onDeleteAccountColumn}
						/>
					))}
				<div>
					<p style={{textDecoration: 'underline'}} className={'account-section-label h3-size'}>
						Ending Balance Column:
					</p>
					<KiInput
						name={'beginning_balance'}
						value={`${formValues.name || unsavedValues.name || ''}_Ending_Bal`}
						label={'Debt Input Name'}
						disabled={true}
						className="balance_column_name"
					/>
				</div>
			</section>
		);
	}
}

export default DebtInputAccountForm;
