import {loginApi} from 'api';
import {USERID_CHECK_LOGIN, USER_LOGIN, USER_UPDATE, USER_LOGOUT} from './constants';

const checkUserId = userId => dispatch => {
	dispatch({
		type: USERID_CHECK_LOGIN,
		status: 'pending',
		username: '',
	});

	return loginApi
		.checkUserId(userId)
		.then(result =>
			dispatch({
				type: USERID_CHECK_LOGIN,
				status: result.success,
				message: result.message,
			})
		)
		.catch(result =>
			dispatch({
				type: USERID_CHECK_LOGIN,
				status: result.success,
				message: result.message,
			})
		);
};

const login = (username, password) => dispatch => {
	dispatch({
		type: USER_LOGIN,
		status: 'pending',
		username: '',
	});

	return loginApi
		.login(username, password)
		.then(result =>
			dispatch({
				type: USER_LOGIN,
				status: result.status,
				message: result.message,
				user: result.user,
			})
		)
		.catch(result =>
			dispatch({
				type: USER_LOGIN,
				status: 'error',
				message: result.message,
				user: {},
			})
		);
};

const updateUser = user => dispatch => {
	dispatch({
		type: USER_UPDATE,
		user: user,
	});
};

const logout = () => dispatch => {
	return loginApi.logout().then(() => {
		return dispatch({
			type: USER_LOGOUT,
		});
	});
};

export {login, logout, updateUser, checkUserId};
