import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styles from './KiTabs.theme.scss';

export class KiTabs extends Component {
	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
		index: PropTypes.number, //eslint-disable-line
		onChange: PropTypes.func,
	};

	static defaultProps = {
		className: '',
	};

	state = {
		index: this.props.index || 0,
	};

	componentDidUpdate(prevProps) {
		if (this.props.index !== prevProps.index) {
			this.setState({index: this.props.index});
		}
	}

	handleTabClick = index => {
		this.setState({index: index});
		if (this.props.onChange) {
			this.props.onChange(index);
		}
	};

	render() {
		return (
			<div className={`${styles.kiTabs} ${this.props.className}`}>
				<nav>
					{React.Children.map(this.props.children, (tab, idx) => {
						if (!tab) {
							return null;
						}
						return (
							<div
								onClick={() => this.handleTabClick(idx)}
								className={`${styles.tab} ${this.state.index === idx ? styles.active : ''} ${
									tab.props.disabled ? styles.disabled : ''
								}`}
							>
								{tab.props.title}
							</div>
						);
					})}
				</nav>
				<section className={styles.tabContent}>{this.props.children[this.state.index]}</section>
			</div>
		);
	}
}

export {default as KiTab} from './KiTab';

export default KiTabs;
