import React from 'react';
import PropTypes from 'prop-types';
import KiIconButton from '../../../../components/KiIconButton';
import KiInput from '../../../../components/KiInput';
import KiSelect from '../../../../components/KiSelect';
import {TRANSACTION_TYPE_CREDIT, TRANSACTION_TYPE_DEBIT, TRANSACTION_TYPE_OPTIONS} from '../../ledgerUtils';

function ManualEntryLineItem({item, deleteDisabled, onItemChange, onItemDelete, accountOptions}) {
	const {lineItem, account, amount, type} = item;

	const onAmountBlur = () => {
		if (amount < 0) {
			onItemChange('amount', Math.abs(amount));
			onItemChange('type', type === TRANSACTION_TYPE_DEBIT ? TRANSACTION_TYPE_CREDIT : TRANSACTION_TYPE_DEBIT);
		}
	};

	return (
		<div className="horizontal-inputs-container">
			<div />
			<div className="long-wrapper">
				<KiInput
					type="text"
					value={lineItem}
					label="Line Description"
					onChange={value => onItemChange('lineItem', value)}
				/>
			</div>
			<div className="double-wrapper">
				<span className="theme-label">Account</span>
				<KiSelect options={accountOptions} onChange={value => onItemChange('account', value)} value={account} />
			</div>
			<div>
				<KiInput
					type="number"
					value={amount}
					label="Amount"
					onChange={value => onItemChange('amount', value)}
					onBlur={onAmountBlur}
				/>
			</div>
			<div className="no-label-select-wrapper">
				<KiSelect
					options={TRANSACTION_TYPE_OPTIONS}
					onChange={value => onItemChange('type', value)}
					value={type}
				/>
			</div>
			<div className="to-right button-wrapper button-wrapper-size-3">
				<KiIconButton icon="delete" title="Delete" disabled={deleteDisabled} onMouseUp={onItemDelete} />
			</div>
		</div>
	);
}

ManualEntryLineItem.propTypes = {
	item: PropTypes.object.isRequired,
	deleteDisabled: PropTypes.bool.isRequired,
	onItemChange: PropTypes.func.isRequired,
	onItemDelete: PropTypes.func.isRequired,
	accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManualEntryLineItem;
