import {TRANSACTION_TYPE_OPTIONS} from '../../../ledgerUtils';
import {cloneDeep, get} from 'lodash';

export const isTemplateValid = (template, isVehicleLedger) => {
	const templateProperties = ['code', 'description', 'effectiveDate'];
	const lineProperties = ['description', 'account', 'dateContext', 'amount', 'entryType'];
	if (isVehicleLedger) {
		lineProperties.push('entityName');
	}
	if (
		!templateProperties.some(
			templateProp =>
				get(template, `${templateProp}`, '')
					.toString()
					.trim() === ''
		)
	) {
		return !template.lineItems.some(lineItem => {
			return lineProperties.some(
				lineProp =>
					get(lineItem, `${lineProp}`, '')
						.toString()
						.trim() === ''
			);
		});
	}
	return false;
};

export const convertTemplatesToList = (
	templates,
	isVehicleLedger,
	dateContextOptions,
	amountOptions,
	accountOptions,
	entityTypeOptions,
	entityNameOptions
) => {
	templates = cloneDeep(templates);
	if (templates.length) {
		return templates.map((template, index) => {
			template.effectiveDate = dateContextOptions.find(item => item.value === template.effectiveDate) || '';
			template.lineItems.forEach(lineItem => {
				lineItem.dateContext = dateContextOptions.find(item => item.value === lineItem.dateContext) || '';
				lineItem.amount = amountOptions.find(item => item.value === lineItem.amount) || '';
				lineItem.account = accountOptions.find(item => item.value === lineItem.accountId) || '';
				lineItem.entryType = TRANSACTION_TYPE_OPTIONS.find(item => item.value === lineItem.entryType) || '';
				if (isVehicleLedger) {
					lineItem.entityName = entityNameOptions.find(item => item.value === lineItem.entityId) || '';
					if (lineItem.entityName) {
						lineItem.entityType =
							entityTypeOptions.find(item => item.value === lineItem.entityName.rowType) || '';
					} else {
						lineItem.entityType = '';
					}
				}
			});

			return {
				...template,
				valid: isTemplateValid(template, isVehicleLedger),
				deleted: false,
				visible: true,
				modified: false,
				originalIndex: index,
			};
		});
	} else {
		return [];
	}
};

export const convertTemplatesForSave = (templates, isVehicleLedger) => {
	const newTemplates = [];
	const deletedTemplatesIds = [];
	templates.forEach(template => {
		if (template.deleted) {
			deletedTemplatesIds.push(template._id);
		} else {
			template.effectiveDate = template.effectiveDate ? template.effectiveDate.value : '';
			template.lineItems.forEach(lineItem => {
				lineItem.dateContext = lineItem.dateContext ? lineItem.dateContext.value : '';
				lineItem.amount = lineItem.amount ? lineItem.amount.value : '';
				lineItem.entryType = lineItem.entryType ? lineItem.entryType.value : '';
				lineItem.accountId = lineItem.account ? lineItem.account.value : '';
				if (isVehicleLedger) {
					lineItem.entityId = lineItem.entityName ? lineItem.entityName.value : '';
					delete lineItem.entityType;
					delete lineItem.entityName;
				}
				delete lineItem.account;
			});
			delete template.lastGeneration;
			delete template.visible;
			delete template.modified;
			delete template.originalIndex;
			delete template.valid;
			delete template.deleted;
			newTemplates.push(template);
		}
	});

	return {templates: newTemplates, deletedTemplatesIds};
};

export const filterTemplateList = (templates, searchTerm) => {
	const phraseToFind = searchTerm.toLowerCase();
	return templates.map(template => {
		template.visible = false;
		const templatePhrase = template.code + template.description + get(template, 'effectiveDate.label', '');

		if (templatePhrase.toLowerCase().includes(phraseToFind)) {
			template.visible = true;
		} else {
			for (const lineItem of template.lineItems) {
				const lineItemPhrase =
					lineItem.description +
					get(lineItem, 'dateContext.label', '') +
					get(lineItem, 'amount.label', '') +
					get(lineItem, 'account.label', '') +
					get(lineItem, 'entityType.label', '') +
					get(lineItem, 'entityName.label', '');
				if (lineItemPhrase.toLowerCase().includes(phraseToFind)) {
					template.visible = true;
					break;
				}
			}
		}

		return template;
	});
};
