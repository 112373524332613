import * as actionTypes from './actionTypes';

const initialState = {
	dates: [],
	usage: null,
	isLoading: false,
	mappedDates: [],
};

export const DatasetDates = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DD_FETCH_DATES: {
			switch (action.status) {
				case 'pending': {
					return {
						...state,
						isLoading: true,
						usage: null,
					};
				}
				case 'success': {
					return {
						...state,
						isLoading: false,
						dates: action.data,
						usage: null,
					};
				}
				case 'error': {
					return {
						...state,
						isLoading: false,
						error: action.error,
						usage: null,
					};
				}
				default: {
					return state;
				}
			}
		}

		case actionTypes.DD_FETCH_MAPPED_DATES: {
			switch (action.status) {
				case 'success': {
					return {
						...state,
						mappedDates: action.data,
					};
				}
				default: {
					return state;
				}
			}
		}

		case actionTypes.DD_CREATE_DATE: {
			switch (action.status) {
				case 'pending': {
					return {
						...state,
						isLoading: true,
						usage: null,
					};
				}
				case 'success': {
					return {
						...state,
						isLoading: false,
						dates: [action.data, ...state.dates],
						usage: null,
					};
				}
				case 'error': {
					return {
						...state,
						isLoading: false,
						error: action.error,
						usage: null,
					};
				}
				default: {
					return state;
				}
			}
		}

		case actionTypes.DD_UPDATE_DATE: {
			switch (action.status) {
				case 'pending': {
					return {
						...state,
						isLoading: true,
						usage: null,
					};
				}
				case 'success': {
					return {
						...state,
						isLoading: false,
						dates: state.dates.reduce((acc, date) => {
							if (date._id === action.data._id) {
								acc.push(action.data);
							} else {
								acc.push(date);
							}
							return acc;
						}, []),
					};
				}
				case 'error': {
					return {
						...state,
						isLoading: false,
						error: action.error,
						usage: null,
					};
				}
				default: {
					return state;
				}
			}
		}

		case actionTypes.DD_DELETE_DATE: {
			switch (action.status) {
				case 'pending': {
					return {
						...state,
						isLoading: true,
						usage: null,
					};
				}
				case 'success': {
					return {
						...state,
						isLoading: false,
						dates: state.dates.filter(date => date._id !== action.data),
						usage: null,
					};
				}
				case 'error': {
					return {
						...state,
						isLoading: false,
						error: action.error,
						usage: null,
					};
				}
				case 'inUse': {
					return {
						...state,
						isLoading: false,
						usage: action.data,
					};
				}
				default: {
					return state;
				}
			}
		}

		// All entry changes are actually an update to the main date
		// all three actions return the updated date object so replace them
		case actionTypes.DD_CREATE_DATE_ENTRY:
		case actionTypes.DD_DELETE_DATE_ENTRY:
		case actionTypes.DD_UPDATE_DATE_ENTRY: {
			switch (action.status) {
				case 'pending': {
					return {
						...state,
						isLoading: true,
						usage: null,
					};
				}
				case 'success': {
					return {
						...state,
						isLoading: false,
						dates: state.dates.reduce((acc, date) => {
							if (date._id === action.data._id) {
								acc.push(action.data);
							} else {
								acc.push(date);
							}
							return acc;
						}, []),
					};
				}
				case 'error': {
					return {
						...state,
						isLoading: false,
						error: action.error,
						usage: null,
					};
				}
				default: {
					return state;
				}
			}
		}

		default:
			return state;
	}
};

export default DatasetDates;
