import React, {Component} from 'react';
import PropTypes from 'prop-types';
import KiProgressBar from 'components/KiProgressBar';
import styles from '../../debt.theme.scss';
import KiFontIcon from 'components/KiFontIcon';
import pemStyles from './ParsedErrorMessage.theme.scss';
import KiInput from 'components/KiInput';
import KiDatePicker from 'components/KiDatePicker';
import {formatDate} from '../../../../utils/dateUtils';
import moment from 'moment';
import classNames from 'classnames';

export const LoadingComponent = ({loading}) => {
	if (!loading) {
		return false;
	}
	return (
		<div className="-loading -active" style={{zIndex: 0}}>
			<KiProgressBar className={styles.progressBar} />
		</div>
	);
};
LoadingComponent.propTypes = {
	loading: PropTypes.bool,
};

export const BEStackTraceErrorRegex = new RegExp(/^500 - "(?!Oops!)/);

export const ParsedErrorMessage = props => {
	if (BEStackTraceErrorRegex.test(props.message) && props.stackTrace) {
		return (
			<section className={pemStyles.root}>
				<h6 className={pemStyles.title}>DEBUG MODE: 500 - Exception thrown in service layer:</h6>
				<pre className={pemStyles.stackTrace}>{props.stackTrace}</pre>
				<div className={pemStyles.row}>
					<div className={pemStyles.requestBodyContainer}>
						<header>
							<h6 className={pemStyles.title}>Website Request Body</h6>
							<p>
								{props.feRequestMethod} {props.feRequestURI}
							</p>
						</header>
						<pre>{JSON.stringify(props.feRequestBody, null, 4)}</pre>
					</div>
					<div className={pemStyles.requestBodyContainer}>
						<header>
							<h6 className={pemStyles.title}>BE Request Body</h6>
							<p>
								{props.beRequestMethod} {props.beRequestURI}
							</p>
						</header>
						<pre>{JSON.stringify(props.beRequestBody, null, 4)}</pre>
					</div>
				</div>
			</section>
		);
	}
	return <p className={styles.errorMsg}>{String(props.message)}</p>;
};
ParsedErrorMessage.propTypes = {
	message: PropTypes.string,
	stackTrace: PropTypes.string,
	feRequestBody: PropTypes.object,
	feRequestMethod: PropTypes.oneOf(['GET', 'POST', 'PUT', 'PATCH', 'DELETE']),
	feRequestURI: PropTypes.string,
	beRequestBody: PropTypes.object,
	beRequestMethod: PropTypes.oneOf(['GET', 'POST', 'PUT', 'PATCH', 'DELETE']),
	beRequestURI: PropTypes.string,
};

ParsedErrorMessage.defaultProps = {
	message: '',
	stackTrace: '',
	feRequestBody: {},
	feRequestMethod: 'POST',
	feRequestURI: '',
	beRequestBody: {},
	beRequestMethod: 'POST',
	beRequestURI: '???',
};

export class EditableTableCell extends Component {
	static propTypes = {
		columnName: PropTypes.string,
		index: PropTypes.number,
		data: PropTypes.string,
		updateManulaInputDebtData: PropTypes.func,
		dataType: PropTypes.string,
		fetchedDataValue: PropTypes.string,
	};

	static defaultProps = {
		data: '0.00',
	};

	state = {
		updatedValue: this.props.dataType === 'numeric' ? '0.00' : '',
	};

	componentDidMount() {
		this.setState({updatedValue: this.props.data});
	}

	handleUpdatedData = value => {
		const {dataType} = this.props;
		let parsedValue = dataType === 'numeric' ? value.replace(/,/g, '') : value;
		if (dataType === 'numeric' && parsedValue === '') {
			parsedValue = '0.00';
		}
		if (dataType === 'date_long' && parsedValue) {
			parsedValue = formatDate(parsedValue);
		}
		if (dataType === 'date_short' && parsedValue) {
			parsedValue = moment(parsedValue).format('YYYY-MM');
		}
		if (parsedValue !== null && (String(parsedValue).length || ['string', 'numeric'].includes(dataType))) {
			//don't save emtpy data
			//this.props.updateManulaInputDebtData(index, columnName, parsedValue);
			this.setState({updatedValue: parsedValue});
		}
	};

	saveChangesOnBlur = () => {
		const {index, columnName} = this.props;
		this.props.updateManulaInputDebtData(index, columnName, this.state.updatedValue);
	};

	handleRevertData = () => {
		const {index, columnName} = this.props;
		this.props.updateManulaInputDebtData(index, columnName, this.props.fetchedDataValue);
	};

	render() {
		return (
			<div style={{display: 'flex'}}>
				{this.props.dataType === 'string' && (
					<KiInput
						autoFocus={false}
						value={this.state.updatedValue}
						onChange={val => this.handleUpdatedData(val)}
						onBlur={() => this.saveChangesOnBlur()}
						className={styles.manualInput}
						style={{
							borderWidth: '1px',
							borderStyle: 'solid',
							padding: '.5rem',
							fontSize: '1.2rem',
							borderColor: this.props.fetchedDataValue !== this.state.updatedValue ? '#135E82' : '#CCC',
						}}
					/>
				)}

				{this.props.dataType === 'numeric' && (
					<KiInput
						autoFocus={false}
						placeholder="0.00"
						value={this.state.updatedValue}
						isNumberMasked={true}
						maskConfig={{decimalLimit: 9}}
						onChange={val => this.handleUpdatedData(val)}
						onBlur={() => this.saveChangesOnBlur()}
						className={styles.manualInput}
						style={{
							borderWidth: '1px',
							borderStyle: 'solid',
							padding: '.5rem',
							fontSize: '1.2rem',
							borderColor: this.props.fetchedDataValue !== this.state.updatedValue ? '#135E82' : '#CCC',
						}}
					/>
				)}
				{this.props.dataType === 'date_short' && (
					<KiDatePicker
						dateFormat={'yyyy-MM'}
						onChange={val => this.handleUpdatedData(val)}
						onBlur={() => this.saveChangesOnBlur()}
						value={this.state.updatedValue}
						placeholderText={this.props.fetchedDataValue}
						className={classNames(styles.tableDatepicker, {
							[styles.datepickerOriginal]: this.props.fetchedDataValue !== this.state.updatedValue,
						})}
					/>
				)}
				{this.props.dataType === 'date_long' && (
					<KiDatePicker
						onBlur={() => this.saveChangesOnBlur()}
						onChange={val => this.handleUpdatedData(val)}
						value={this.state.updatedValue}
						placeholderText={this.props.fetchedDataValue}
						className={classNames(styles.tableDatepicker, {
							[styles.datepickerOriginal]: this.props.fetchedDataValue !== this.state.updatedValue,
						})}
					/>
				)}
				{this.props.fetchedDataValue !== this.state.updatedValue && (
					<div className="list-icon-btn">
						<KiFontIcon value="undo" onClick={() => this.handleRevertData()} className="save-cell-icon" />
					</div>
				)}
			</div>
		);
	}
}
