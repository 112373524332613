// Globals
import React, {useEffect, useState, useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

// Website imports
import {dataBookmarksApi, datasetDatesApi, viewsApi} from 'api';
import {closeContextSidebarPanel} from 'state/actions/App';
import {showSnackbar} from 'state/actions/Snackbar';
import {KiProgressBar} from 'components/KiProgressBar';
//import {useMergedState} from 'utils/customHooks';
import {KiIconCard} from 'components/KiIcons';
import ContextSidebar from 'components/ContextSidebar';
import ContextIcons from 'components/ContextSidebar/icons';
import FlyoutDates from 'components/FlyoutDates';
import FlyoutCardList from 'components/FlyoutCardList';
import FlyoutCalculations from 'components/FlyoutCalculations';
import FlyoutFilters from 'components/FlyoutFilters/index';
import FlyoutManageViews from 'components/FlyoutManageViews';
import FlyoutManageViewList from 'components/FlyoutManageViewList';
import {fetchDatasetList, fetchDataset, requeryColumnList} from 'containers/datasetList/actions';
import {fetchFundingVehicleList} from 'containers/fundingVehicleList/actions';
import {fetchViewsByDataset} from 'components/FlyoutManageViews/actions';
import KiErrorBoundary from 'components/KiErrorBoundary';

// Local imports
import {DataExplorerHeader} from './components/DataExplorerHeader';
import {DataExplorerQuickFilters} from './components/DataExplorerQuickFilters';
import ExplorerTable from './components/ExplorerTable';
import styles from './dataExplorerLayout.theme.scss';
import {DataExplorerContext} from './DataExplorerContext';
import {BookmarkApiContext} from './contexts/BookmarkApiContext';

// Logger
import ReactLogger from 'utils/ReactLogger';
const logger = new ReactLogger({level: window.REACT_LOG_LEVEL});

function DataExplorerLayout() {
	// Browser State
	const location = useLocation();
	const history = useHistory();
	const {datasetId} = useParams();
	const queryParams = new URLSearchParams(location.search);
	const bookmarkId = queryParams.get('bookmarkId');

	// Redux State
	const dispatch = useDispatch();
	// const app = useSelector(state => state.app);
	const selectedDataset = useSelector(state => state.datasetList.selected);
	const user = useSelector(state => state.user);

	// Context State
	const dataExplorerContext = useContext(DataExplorerContext);
	const bookmark = dataExplorerContext.bookmark;
	const renderState = dataExplorerContext.renderState;

	const bookmarkApiContext = useContext(BookmarkApiContext);

	// Local State
	const [datasetList, setDatasetList] = useState([]);
	const [datasetBlock, setDatasetBlock] = useState({
		isLoading: true,
		dataset: {},
		datasetName: '',
	});
	const [datasetViewList, setDatasetViewList] = useState([]);
	const [deleteError, setDeleteError] = useState(null);
	const [generalError, setGeneralError] = useState(null);

	// TODO see if these datasets can be fetched without columns
	// TODO can we remove references to selectedDataset?
	const fetchDatasetData = async () => {
		logger.log('fetchDatasetData');
		const currentDatasetId = datasetId || selectedDataset?.datasetId || datasetList?.[0].datasetId;
		const fetchedDataset = await dispatch(fetchDataset(currentDatasetId));

		dispatch(fetchFundingVehicleList(currentDatasetId));

		await fetchViewsByDataset(user.userId, datasetId, user.groups.includes('SystemAdmins'));
		const allViews = await viewsApi.fetchViewsByDataset(datasetId);
		setDatasetViewList(allViews);

		const datasetColumnList = await dispatch(
			requeryColumnList(datasetId, {
				sources: {
					includeAssetColumns: true,
					includeCohortColumns: true,
					includeAggregateColumns: true,
					includeAssetCalculations: true,
					includeBusinessFunctions: true,
					includeDebtCalculations: true,
					includeFundingVehicle: true,
					includeBorrowingBase: true,
					includeWaterfallCalculations: true,
					includeDateColumns: true,
				},
				filter: {},
				filters: {},
				options: {
					embedColumns: true,
				},
			})
		);
		dataExplorerContext.setAllColumns(datasetColumnList);

		const dateContextList = await datasetDatesApi.fetchPortfolioDates(currentDatasetId);
		dataExplorerContext.setDateContextList(dateContextList);

		// NOTE: this must be set AFTER funding vehicles, columns, and dates are fetched otherwise
		// those lists will not be available during the loading of the bookmark
		setDatasetBlock({
			isLoading: false,
			dataset: fetchedDataset,
			datasetName: fetchedDataset.name,
		});

		// If there is not datasetId on the URL add one here and re-load page
		if (!datasetId) {
			history.push(`/dataExplorer/${currentDatasetId}`);
		}

		return fetchedDataset;
	};

	const lookupBookmark = async dataset => {
		logger.log('DataExplorerLayout - lookupBookmark');
		let bookmarkToLoad;
		if (queryParams.get('fundingView') === 'true') {
			if (queryParams.get('exclusionType') === 'comp_test_name') {
				bookmarkToLoad = await dataBookmarksApi.fetchExcessBookmark(
					datasetBlock.dataset,
					queryParams.get('fundingSnapshotDate'),
					queryParams.get('dateContext'),
					queryParams.get('dataColumnId'),
					queryParams.get('groupBy'),
					queryParams.get('fvId'),
					queryParams.get('scenarioType'),
					null,
					queryParams.get('scenarioId'),
					queryParams.get('tableType')
				);
			}
			if (queryParams.get('exclusionType') === 'br_ex_name') {
				bookmarkToLoad = await dataBookmarksApi.fetchBreachesBookmark(
					datasetBlock.dataset,
					queryParams.get('fundingSnapshotDate'),
					queryParams.get('dateContext'),
					queryParams.get('dataColumnId'),
					queryParams.get('groupBy'),
					queryParams.get('fvId'),
					queryParams.get('scenarioType'),
					null,
					queryParams.get('scenarioId'),
					queryParams.get('tableType')
				);
			}
		} else {
			bookmarkToLoad = await dataBookmarksApi.fetchBookmark(bookmarkId || null, dataset, user.userId);
			// on initial load set date to today
			if (
				!bookmarkToLoad.explorerData.isFixedDate &&
				!queryParams.get('snapshotDate') &&
				!bookmarkToLoad.explorerData.isFundingLink
			) {
				bookmarkToLoad.explorerData.statementDate = dateToShortDate(new Date());
			}
		}
		const {
			statementDate,
			datasetId,
			dateContext,
			isFixedDate,
			quickFilters: {scenarioType, scenarioId, fundingVehicleId},
		} =
			bookmarkToLoad?.explorerData || {};
		const fetchedQuickFilterLists = await dataExplorerContext
			.fetchQuickFiltersForBookmark(
				datasetId,
				scenarioId,
				fundingVehicleId,
				statementDate,
				scenarioType,
				dateContext,
				dataExplorerContext.dateContextList,
				isFixedDate
			)
			.then((quickFilterLists = {}) => {
				dataExplorerContext.setQuickFilterLists(quickFilterLists);
				return quickFilterLists;
			})
			.catch(err => {
				logger.error({err}, 'Error pre-fetching quick filters for bookmark');
			});
		// eslint-disable-next-line no-console
		// console.dir(bookmarkToLoad);
		dataExplorerContext.actions.loadBookmark(bookmarkToLoad, fetchedQuickFilterLists);
		return bookmarkToLoad;
	};

	useEffect(
		() => {
			logger.log('DataExplorerLayout - useEffect [datasetId]');
			async function loadDatasetThenBookmark() {
				if (!datasetList.length || !selectedDataset.category) {
					const listResponse = await dispatch(fetchDatasetList());
					const fetchedDatasetList = listResponse.datasetList;
					setDatasetList(fetchedDatasetList);
				}
				fetchDatasetData();
			}
			loadDatasetThenBookmark();
		},
		[datasetId]
	);

	// Only lookup a bookmark once the dataset has been finalized
	useEffect(
		() => {
			if (datasetBlock?.dataset?.datasetId) {
				logger.log('useEffect [datasetBlock.dataset]');
				lookupBookmark(datasetBlock.dataset);
			}
		},
		[datasetBlock.dataset]
	);

	useEffect(
		() => {
			if (datasetBlock?.dataset?.datasetId) {
				lookupBookmark(datasetBlock.dataset);
			}
		},
		[bookmarkId]
	);

	const clickBookmark = bookmark => {
		history.push(`/dataExplorer/${bookmark.datasetId}?bookmarkId=${bookmark._id}`);
		dispatch(closeContextSidebarPanel());
	};

	const fetchBookmarkList = async () => {
		logger.log('DataExplorerLayout - fetchBookmarkList');
		const allViews = await viewsApi.fetchViewsByDataset(datasetId);
		setDatasetViewList(allViews);
		setDeleteError(null);
		setGeneralError(null);
	};

	const copyBookmark = bookmark => {
		logger.log('DataExplorerLayout - copyBookmark');
		return bookmarkApiContext.copyBookmark(bookmark).then(() => fetchBookmarkList());
	};

	const deleteBookmark = async bookmark => {
		logger.log('DataExplorerLayout - deleteBookmark');
		// eslint-disable-next-line no-unused-vars
		try {
			await dataBookmarksApi.deleteBookmark(`${bookmark._id}`);
			dispatch(showSnackbar(`Deleted view "${bookmark.name}" successfully`));
			setDeleteError(null);
			fetchBookmarkList();
			if (bookmark._id === bookmarkId) {
				// Clear bookmark ID off of param string
				history.push(`/dataExplorer/${bookmark.datasetId}`);
			}
		} catch (error) {
			// Delete dependency error
			if (error.message.includes('dependencie')) {
				setDeleteError(error);
			} else {
				setGeneralError(error);
			}
		}
	};

	const getCalculatedDateMessage = () => {
		if (!renderState || renderState.isLoading) {
			return '';
		}
		const {snapshotType} = dataExplorerContext.appliedBookmark.explorerData;
		const {
			calculatedDateInfo: {
				calculatedDate,
				name: dateContextName,
				startCalculatedDate,
				endCalculatedDate,
				startName,
				endName,
			} = {},
		} = dataExplorerContext;
		if (snapshotType === 'encumbrance') {
			return `Data results from ${startCalculatedDate} to ${endCalculatedDate} using ${startName} and ${endName} for ${
				renderState.requestedStatementDate
			}`;
		} else if (calculatedDate && calculatedDate <= renderState.requestedStatementDate) {
			return `Data results for ${calculatedDate} using ${dateContextName}`;
		} else if (calculatedDate) {
			return `There is no ${snapshotType === 'blended' ? 'blended' : ''} data found for ${
				renderState.requestedStatementDate
			} using ${dateContextName}}`;
		}
		return '';
	};

	const renderTable = () => {
		if (!renderState.isLoading && renderState.error) {
			const errorTxt = renderState.error?.message || renderState.error;
			return <div>{`${errorTxt}`}</div>;
		}
		if (!renderState.isLoading && !renderState.data) {
			return <div>No Data Found</div>;
		}
		return (
			<KiErrorBoundary message="Error displaying table data">
				<ExplorerTable timeSeriesGranularity={datasetBlock.dataset.timeSeriesGranularity} />
			</KiErrorBoundary>
		);
	};

	const renderPanel = () => {
		return (
			<React.Fragment>
				<DataExplorerQuickFilters />
				<div className={styles.statusBar}>{getCalculatedDateMessage()}</div>
				{renderTable()}
			</React.Fragment>
		);
	};

	if (datasetBlock.isLoading) {
		return (
			<div className="container-body">
				<KiProgressBar />
			</div>
		);
	}

	return (
		<React.Fragment>
			<article className="funding-vehicle-container container-body">
				<DataExplorerHeader
					datasetBlock={datasetBlock}
					datasetList={datasetList}
					fetchBookmarkList={fetchBookmarkList}
					bookmarkList={datasetViewList}
				/>
				<div className={'ki-panel'}>{renderPanel()}</div>
			</article>
			<ContextSidebar
				items={[
					{
						name: 'View Settings',
						icon: <ContextIcons.MaterialIcon name="settings" />,
						element: (
							<FlyoutManageViews
								reloadView={() => console.log('Reload View')} //eslint-disable-line no-console
								currentViewId={bookmark._id}
								user={user}
								applyView={() => console.log('Apply View')} //eslint-disable-line no-console
								setViewHasChanges={dataExplorerContext.setHasChanges}
								currentView={bookmark}
								explorerView={bookmark.explorerData}
								isDataExploration={true}
							/>
						),
					},
					// {
					// 	name: 'New View Settings',
					// 	icon: <ContextIcons.MaterialIcon name="settings" />,
					// 	element: <ViewSettings />,
					// },
					{
						name: 'Manage Views',
						icon: <ContextIcons.ViewsIcon />,
						element: (
							<FlyoutManageViewList
								allViewsList={datasetViewList}
								currentView={bookmark}
								generalErrors={generalError} // Generalized errors
								deleteError={deleteError} // Specific to errors preventing deletion
								onClickCallback={clickBookmark}
								onDeleteCallback={deleteBookmark} // Should re-fetch view list if deleting self (reload page)
								onCopyCallback={copyBookmark}
								setDeleteError={setDeleteError}
								setGeneralError={setGeneralError}
							/>
						),
					},
					{
						name: 'Manage Filters',
						icon: <ContextIcons.FiltersIcon />,
						element: <FlyoutFilters datasetId={datasetId} user={user} bookmarkId={bookmark._id} />,
					},
					{
						name: 'Calculations',
						icon: <ContextIcons.CalculationsIcon />,
						element: <FlyoutCalculations user={user} />, //reloadView={()=({})}
					},
					{
						name: 'Manage Dates',
						icon: <ContextIcons.MaterialIcon name="date_range" />,
						element: <FlyoutDates />,
					},
					{
						name: 'Manage Cards',
						icon: <KiIconCard />,
						element: <FlyoutCardList datasetId={datasetId} bookmark={bookmark} explorerViewType="data" />,
					},
				]}
			/>
		</React.Fragment>
	);
}

export default DataExplorerLayout;
