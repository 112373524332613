import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import {showSnackbar} from 'state/actions/Snackbar';
import FlyoutConfirmPanel from 'components/FlyoutConfirmPanel';
import TriggersForm from './forms/TriggersForm';
import {fetchTriggers, addTrigger, deleteTrigger, updateTrigger} from 'api/triggersApi';
import {getColumnsFromService} from 'api/columnServiceApi';
import 'components/FlyoutCalculations/components/ColumnList/ColumnList.scss';
import './ModelSettings.scss';

export class ManageTriggers extends Component {
	static propTypes = {
		showSnackbar: PropTypes.func,
		datasetId: PropTypes.string,
		fundingVehicle: PropTypes.object,
		fundingVehicles: PropTypes.array,
		dataset: PropTypes.object,
		panelMode: PropTypes.bool,
		onTriggerAdded: PropTypes.func,
	};

	static defaultProps = {
		onTriggerAdded: () => ({}),
	};

	state = {
		panelMode: this.props.panelMode,
		allTriggers: [],
		filteredTriggers: [],
		noDeleteReasons: false,
		columns: [],
	};

	componentDidMount() {
		this.refreshTriggers();
		this.refreshWaterfallColumns();
	}

	refreshWaterfallColumns() {
		return getColumnsFromService(this.props.datasetId, {
			sources: {
				includeWaterfallCalculations: true,
				includeWaterfallFormulaColumns: true,
				includeWaterfallVariableColumns: true,
			},
		}).then(columns => {
			this.setState({columns: columns});
		});
	}

	refreshTriggers = () => {
		return fetchTriggers(this.props.datasetId).then(triggers => {
			this.props.onTriggerAdded(triggers);
			this.setState({allTriggers: triggers, filteredTriggers: triggers});
		});
	};

	createTrigger = () => {
		this.setState({
			panelMode: 'add',
			currentTrigger: null,
		});
	};

	copyTrigger = trigger => {
		this.setState({
			panelMode: 'add',
			currentTrigger: {...trigger, _id: null, name: `${trigger.name}_copy`},
		});
	};

	editTrigger = trigger => {
		this.setState({
			panelMode: 'edit',
			currentTrigger: trigger,
		});
	};

	cancelNoDeleteReasons = () => {
		this.setState({
			noDeleteReasons: null,
		});
	};

	deleteTriggerWarning = trigger => {
		return deleteTrigger(trigger._id, trigger.datasetId)
			.then(() => {
				this.props.showSnackbar('Trigger deleted successfully.');
				this.refreshTriggers();
				this.setState({
					showDeleteConfirm: false,
				});
			})
			.catch(err => {
				if (err.data && err.data.dependencies) {
					// dependencies were found for view, cannot delete
					this.setState({
						noDeleteReasons: err.data,
						showDeleteConfirm: false,
					});
				} else {
					showSnackbar('Error deleting trigger.');
				}
			});
	};

	saveTrigger = trigger => {
		trigger.datasetId = this.props.datasetId;
		if (!trigger._id) {
			return addTrigger(trigger).then(() => {
				this.props.showSnackbar('Trigger created successfully.');
				this.refreshTriggers();
			});
		}
		return updateTrigger(trigger).then(() => {
			this.props.showSnackbar('Trigger updated successfully.');
			this.refreshTriggers();
		});
	};

	onSearch = event => {
		const searchTerm = _get(event, 'target.value', '');
		const searchTermCaseless = searchTerm.toUpperCase();
		this.setState({
			searchTerm: searchTerm,
			filteredTriggers: this.state.allTriggers.filter(trigger => {
				return trigger.name.toUpperCase().includes(searchTermCaseless.toUpperCase());
			}),
		});
	};

	render() {
		return (
			<div className="calculation-flyout-root">
				<header className="flyout-panel-title">MANAGE TRIGGERS</header>
				{this.state.showDeleteConfirm && (
					<FlyoutConfirmPanel
						header={'Delete Trigger'}
						message={`Are you sure you want to delete the trigger ${this.state.currentTrigger.name ||
							this.state.currentTrigger.displayName} from all tracks?`}
						acceptFunc={() => this.deleteTriggerWarning(this.state.currentTrigger)}
						rejectFunc={() => this.setState({showDeleteConfirm: false})}
						acceptLabel={'Delete'}
						rejectLabel={'Cancel'}
					/>
				)}
				{this.state.noDeleteReasons && (
					<FlyoutConfirmPanel
						header={'Delete'}
						message={
							<span>
								Trigger{' '}
								<span className="bold">
									&quot;{this.state.currentTrigger.name || this.state.currentTrigger.displayName}&quot;
								</span>{' '}
								cannot be deleted because it is associated with the following:
							</span>
						}
						rejectFunc={this.cancelNoDeleteReasons}
						rejectLabel={'CLOSE'}
						hideAccept={true}
						reasonList={this.state.noDeleteReasons.dependencies}
					/>
				)}
				{!this.state.panelMode && (
					<div>
						<div className="cta-icon-btn" onClick={() => this.createTrigger()}>
							<i className="material-icons">add_circle</i>
							<p>Add a Trigger</p>
						</div>
						<section className="flyout-panel-search">
							<p>Search Triggers</p>
							<input placeholder="Name or Tag" onChange={e => this.onSearch(e)} />
						</section>
						<div className="column-list">
							{this.state.filteredTriggers.map(trigger => {
								return (
									<div key={trigger._id} className="column-list-item">
										<div className="list-icon-btn no-rollover">
											<div className="column-name">{trigger.name}</div>
										</div>
										<div className="icons-cell-right">
											<div className="list-icon-btn">
												<i
													title="Copy Trigger"
													alt="Copy Trigger"
													className="material-icons"
													onClick={() => this.copyTrigger(trigger)}
												>
													content_copy
												</i>
											</div>
											<div className="list-icon-btn">
												<i
													title="Edit Trigger"
													alt="Edit Trigger"
													className="material-icons"
													onClick={() => this.editTrigger(trigger)}
												>
													mode_edit
												</i>
											</div>
											<div className="list-icon-btn">
												<i
													title="Delete Trigger"
													alt="Delete BloTriggerck"
													className="material-icons"
													onClick={() => {
														this.setState({
															currentTrigger: trigger,
															showDeleteConfirm: true,
														});
													}}
												>
													delete
												</i>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						{this.state.allTriggers === 0 && <p>No Triggers found</p>}
					</div>
				)}
				{this.state.panelMode && (
					<TriggersForm
						fetchAllReportingDates={() => ({})}
						fundingVehicle={this.props.fundingVehicle}
						submitMethod={this.saveTrigger}
						columnList={this.state.columns.filter(col => col.isWaterfall === true)} //TODO: add this back in when booleans are ready
						triggersList={this.state.allTriggers}
						existingTags={[]}
						columnToEdit={this.state.currentTrigger}
						closeForm={() => {
							this.setState({
								panelMode: null,
							});
						}}
						isAdmin={true}
						fundingVehicles={this.props.fundingVehicles.filter(
							fv => fv.datasetId === this.props.dataset.datasetId
						)}
						datasetId={this.props.datasetId}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	dataset: state.datasetList.selected,
	fundingVehicle: state.fundingVehicle.selected,
	fundingVehicles: state.fundingVehicleList.data,
});

const mapDispatchToProps = () => ({
	showSnackbar,
});

export default connect(mapStateToProps, mapDispatchToProps())(ManageTriggers);
