import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select, {KiSelect} from 'components/KiSelect';
import KiInput from 'components/KiInput';
import KiButton from 'components/KiButton';
import KiRadioButton from 'components/KiRadioButton';
import KiRadioGroup from 'components/KiRadioGroup';
import {connect} from 'react-redux';
import _pick from 'lodash/pick';
import _ from 'lodash';
import {addFundingVehicleSetting, updateFundingVehicleSetting} from '../actions';
import options, {accrualMethodOptions} from 'ki-common/options';
import {columnServiceApi} from 'api';
import {showSnackbar} from 'state/actions/Snackbar';

import './debtForm.scss';

const CLASS_TYPES = options.classTypes;
const SENIORITY = options.seniorityTypes;
const PAY_TYPES = options.payTypes;
const BALANCE_TYPES = options.balanceType;

class DebtForm extends Component {
	static propTypes = {
		history: PropTypes.object,
		match: PropTypes.object,
		dataset: PropTypes.object,
		updateFundingVehicleSetting: PropTypes.func.isRequired,
		addFundingVehicleSetting: PropTypes.func.isRequired,
		_id: PropTypes.string,
		name: PropTypes.string,
		cusip: PropTypes.string,
		isin: PropTypes.string,
		trancheNumber: PropTypes.number,
		nameId: PropTypes.string,
		seniority: PropTypes.string,
		payType: PropTypes.string,
		balanceType: PropTypes.string,
		payDate: PropTypes.string,
		debtNames: PropTypes.array,
		classId: PropTypes.string,
		debts: PropTypes.array,
		interestType: PropTypes.string,
		rate: PropTypes.string,
		accrualMethod: PropTypes.string,
		margin: PropTypes.string,
		percentOwned: PropTypes.string,
		index: PropTypes.string,
		dates: PropTypes.array,
		selectedDateGroup: PropTypes.string,
		couponResetDate: PropTypes.string,
		showSnackbar: PropTypes.func.isRequired,
		originalCoupon: PropTypes.number,
	};

	static defaultProps = {
		_id: null,
		name: null,
		cusip: null,
		isin: null,
		classId: null,
		nameId: null,
		seniority: null,
		payType: null,
		balanceType: null,
		payDate: null,
		debtNames: [],
		currentTranche: {},
		debts: [],
		interestType: '',
		rate: '0.0',
		accrualMethod: '',
		margin: '0.0',
		percentOwned: '100.0',
		index: '',
		dates: [],
		selectedDateGroup: null,
		originalCoupon: null,
		couponResetDate: null,
	};

	state = {
		_id: null,
		name: null,
		cusip: null,
		isin: null,
		classId: null,
		nameId: null,
		seniority: null,
		payType: null,
		balanceType: null,
		payDate: null,
		cusipError: null,
		seniorityError: null,
		payTypeError: null,
		balanceTypeError: null,
		accrualMethodError: null,
		nameLengthError: null,
		percentOwnedError: null,
		interestType: 'fixed',
		rate: null,
		accrualMethod: null,
		margin: null,
		percentOwned: null,
		index: null,
		selectedDateGroup: null,
		indices: [],
		originalCoupon: null,
		couponResetDate: null,
	};

	async componentDidMount() {
		const indices = await columnServiceApi.getIndexColumnsFromService(this.props.dataset.datasetId).catch(() => {
			this.props.showSnackbar('Failure to load indices');
		});

		this.setState({
			name: this.props.name || null,
			cusip: this.props.cusip || null,
			isin: this.props.isin || null,
			classId: this.props.classId || 'A',
			trancheNumber: this.getTrancheNumber(),
			nameId: this.props.nameId || null,
			seniority: this.props.seniority || null,
			payType: this.props.payType || null,
			balanceType: this.props.balanceType || null,
			payDate: this.props.payDate || null,
			interestType: this.props.interestType || 'fixed',
			rate: this.props.rate || '0.0',
			accrualMethod: this.props.accrualMethod || '',
			margin: this.props.margin || '0.0',
			percentOwned: this.props.percentOwned || '100.0',
			index: this.props.index || null,
			selectedDateGroup: this.props.selectedDateGroup || null,
			indices: indices || [],
			originalCoupon: this.props.originalCoupon || 0,
			couponResetDate: this.props.couponResetDate || null,
		});
	}

	componentDidUpdate(prevProps) {
		if (_.isEqual(this.props, prevProps)) return null; //don't reset form unless props changed
		this.setState({
			name: this.props.name || null,
			cusip: this.props.cusip || null,
			isin: this.props.isin || null,
			classId: this.props.classId || 'A',
			trancheNumber: this.props.trancheNumber
				? this.props.trancheNumber
				: this.props.debts.filter(debt => debt.classId === this.state.classId).length + 1,
			nameId: this.props.nameId || null,
			seniority: this.props.seniority || null,
			payType: this.props.payType || null,
			balanceType: this.props.balanceType || null,
			payDate: this.props.payDate || null,
			interestType: this.props.interestType || 'fixed',
			rate: this.props.rate || '0.0',
			accrualMethod: this.props.accrualMethod || '',
			margin: this.props.margin || '0.0',
			percentOwned: this.props.percentOwned || '100.0',
			index: this.props.index || null,
			selectedDateGroup: this.props.selectedDateGroup || null,
			originalCoupon: this.props.originalCoupon || 0,
			couponResetDate: this.props.couponResetDate || null,
		});
	}

	handleSubmit = () => {
		const updateBody = {
			rowType: 'debt',
			datasetId: this.props.dataset.datasetId,
			fundingVehicleId: this.props.match.params.id,
			name: this.state.name.trim(),
			cusip: this.state.cusip,
			isin: this.state.isin,
			classId: this.state.classId || 'A',
			trancheNumber: this.getTrancheNumber(),
			nameId: `T_${this.state.classId || 'A'}_${this.getTrancheNumber()}`,
			seniority: this.state.seniority,
			payType: this.state.payType,
			balanceType: this.state.balanceType,
			interestType: this.state.interestType,
			accrualMethod: this.state.accrualMethod,
			payDate: this.state.payDate,
			selectedDateGroup: this.state.selectedDateGroup,
			percentOwned: this.state.percentOwned,
		};

		!updateBody.seniority
			? this.setState({seniorityError: 'Please select seniority!'})
			: this.setState({seniorityError: null});

		!updateBody.payType
			? this.setState({payTypeError: 'Please select Pay Type!'})
			: this.setState({payTypeError: null});

		!updateBody.balanceType
			? this.setState({balanceTypeError: 'Please select Balance Type!'})
			: this.setState({balanceTypeError: null});

		!updateBody.accrualMethod
			? this.setState({accrualMethodError: 'Please select Accrual Method!'})
			: this.setState({accrualMethodError: null});

		updateBody.name.length > 50
			? this.setState({nameLengthError: 'Tranche Name is limited to 50 characters'})
			: this.setState({nameLengthError: null});

		if (updateBody.percentOwned) {
			const fltPctOwned = parseFloat(updateBody.percentOwned);
			if (fltPctOwned < 0 || fltPctOwned > 100) {
				this.setState({percentOwnedError: 'Percent Owned must be between 0 and 100'});
			} else {
				this.setState({percentOwnedError: null});
			}
		}

		if (
			!updateBody.payType ||
			!updateBody.seniority ||
			!updateBody.balanceType ||
			!updateBody.accrualMethod ||
			updateBody.name.length > 10
		) {
			return;
		}

		if (updateBody.interestType === 'fixed') {
			// purposely doing a direct assignment since it's a locally created object. Immutability not a concern.
			Object.assign(updateBody, {
				rate: this.state.rate || '0.0',
				margin: '0.0',
				index: null,
				originalCoupon: this.state.originalCoupon,
				couponResetDate: null,
			});
		} else if (updateBody.interestType === 'floating') {
			Object.assign(updateBody, {
				rate: '0.0',
				margin: this.state.margin || '0.0',
				index: this.state.index || null,
				originalCoupon: Number(this.state.originalCoupon) || 0,
				couponResetDate: this.state.couponResetDate || null,
			});
		}
		if (!this.props._id || this.props._id === 'create') {
			return this.props.addFundingVehicleSetting(updateBody).then(result => {
				if (result && result._id) {
					this.setState({isLoading: false});
					this.props.history.push(
						`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
							this.props.match.params.id
						}/capitalStructure/debts`
					);
				}
			});
		} else {
			return this.props.updateFundingVehicleSetting({_id: this.props._id, ...updateBody}).then(() => {
				this.setState({isLoading: false});
			});
		}
	};

	handleCancel = () => {
		return this.props.history.push(
			`/datasets/${this.props.dataset.datasetId}/fundingVehicles/${
				this.props.match.params.id
			}/capitalStructure/debts`
		);
	};

	validatePercentOwned = pctOwned => {
		const fltPctOwned = parseFloat(pctOwned);
		if (fltPctOwned < 0 || fltPctOwned > 100) {
			this.setState({percentOwnedError: 'Percent Owned must be between 0 and 100'});
		} else {
			this.setState({percentOwnedError: null});
		}
		this.setState({percentOwned: pctOwned});
	};

	validateCusip = cusip => {
		cusip = cusip.toUpperCase();
		if (!cusip) {
			return true;
		}
		if (!/^[0-9A-Z@#*]{9}$/.test(cusip)) {
			return false;
		}
		let sum = 0;
		const cusipChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ*@#'.split('');

		for (let i = 0; i < cusip.length - 1; i++) {
			let num;
			const item = cusip[i];
			const code = item.charCodeAt(0);
			const isChar = code >= 'A'.charCodeAt(0) && code <= 'Z'.charCodeAt(0);

			if (isChar) {
				num = cusipChars.indexOf(item) + 10;
			} else {
				num = Number(item);
			}

			if (i % 2 != 0) {
				num *= 2;
			}

			num = (num % 10) + Math.floor(num / 10);
			sum += num;
		}
		return (10 - (sum % 10)) % 10 === Number(cusip[cusip.length - 1]);
	};

	getTrancheNumber = () => {
		const classId = this.state.classId || 'A';
		const numOfTrachesForClass = this.props.debts.filter(debt => debt.classId === classId).length;
		if (this.props._id && this.props.classId === this.state.classId) {
			return this.props.trancheNumber;
		}
		return numOfTrachesForClass + 1;
	};

	render() {
		const nameInUse = this.props.name !== this.state.name && this.props.debtNames.includes(this.state.name);
		if (this.props.match.params.debtId)
			return (
				<div
					onSubmit={this.handleSubmit}
					className="numeric-debt-formula-modal"
					style={{minWidth: '50rem', padding: '3rem'}}
				>
					<KiInput
						label="Tranche Name"
						value={this.state.name || ''}
						onChange={value => this.setState({name: value})}
						error={(nameInUse && 'Name in use') || this.state.nameLengthError}
					/>
					<div style={{display: 'flex', flexDirection: 'row'}}>
						<div className={'input-row'}>
							<KiInput
								label="CUSIP"
								value={(this.state.cusip && this.state.cusip.toUpperCase()) || ''}
								onChange={value => {
									if (this.validateCusip(value)) {
										this.setState({cusipError: null});
									} else {
										this.setState({cusipError: 'Invalid CUSIP'});
									}
									this.setState({cusip: value});
								}}
								error={this.state.cusipError}
							/>
						</div>
						<div className={'input-row'} style={{marginLeft: '5rem'}}>
							<KiInput
								label="ISIN"
								value={this.state.isin || ''}
								onChange={value => this.setState({isin: value})}
							/>
						</div>
					</div>
					<div className={'input-row select-wrapper class-select'} style={{width: '400px'}}>
						<span className={'theme-label'}>Class</span>
						<Select
							className={''}
							value={CLASS_TYPES.filter(option => option.value === this.state.classId)}
							options={CLASS_TYPES}
							onChange={option => this.setState({classId: option.value})}
							menuPortalTarget={document.body}
							styles={{menuPortal: base => ({...base, zIndex: 9999})}}
						/>
					</div>
					<div style={{display: 'flex', flexDirection: 'row', marginTop: '2rem'}}>
						<div className={'input-row'}>
							<KiInput
								label="Tranche Number"
								value={this.getTrancheNumber()}
								isNumberMasked={true}
								disabled={true}
								maskConfig={{
									allowNegative: false,
								}}
							/>
						</div>
						<div className={'input-row'} style={{marginLeft: '5rem'}}>
							<KiInput
								label="Name"
								disabled={true}
								value={`T_${this.state.classId || 'A'}_${this.getTrancheNumber()}`}
							/>
						</div>
					</div>
					<div
						className={'input-row select-wrapper seniority-select'}
						style={{marginBottom: '1rem', marginRight: '5rem', width: '400px'}}
					>
						<span className={'theme-label'}>Seniority</span>
						<Select
							className={''}
							value={SENIORITY.filter(option => option.value === this.state.seniority)}
							options={SENIORITY}
							onChange={option => this.setState({seniority: option ? option.value : option})}
							menuPortalTarget={document.body}
							styles={{menuPortal: base => ({...base, zIndex: 9999})}}
						/>
						{this.state.seniorityError && (
							<span className={'theme-label text-error-red'}>{this.state.seniorityError}</span>
						)}
					</div>
					<div style={{display: 'flex', flexDirection: 'row', marginTop: '2rem'}}>
						<div
							className={'input-row select-wrapper paytype-select'}
							style={{marginBottom: '1rem', marginRight: '5rem', width: '400px'}}
						>
							<span className={'theme-label'}>Pay Type</span>
							<Select
								className={''}
								value={PAY_TYPES.filter(option => option.value === this.state.payType)}
								options={PAY_TYPES}
								onChange={option => this.setState({payType: option ? option.value : option})}
								menuPortalTarget={document.body}
								styles={{menuPortal: base => ({...base, zIndex: 9999})}}
							/>
							{this.state.payTypeError && (
								<span className={'theme-label text-error-red'}>{this.state.payTypeError}</span>
							)}
						</div>
						<div
							className={'input-row select-wrapper balancetype-select'}
							style={{marginBottom: '1rem', marginRight: '5rem', width: '400px'}}
						>
							<span className={'theme-label'}>Balance Type</span>
							<Select
								className={''}
								value={BALANCE_TYPES.filter(option => option.value === this.state.balanceType)}
								options={BALANCE_TYPES}
								onChange={option => this.setState({balanceType: option ? option.value : option})}
								menuPortalTarget={document.body}
								styles={{menuPortal: base => ({...base, zIndex: 9999})}}
							/>
							{this.state.balanceTypeError && (
								<span className={'theme-label text-error-red'}>{this.state.balanceTypeError}</span>
							)}
						</div>
					</div>

					<div>
						<span className={'theme-label'}>Percent Owned</span>
						<KiInput
							name="rate"
							value={this.state.percentOwned}
							isNumberMasked={true}
							sendReactEvent={true}
							maskConfig={{suffix: '%', decimalLimit: 9}}
							onChange={value => this.validatePercentOwned(value)}
						/>
						{this.state.percentOwnedError && (
							<span className={'theme-label text-error-red'}>{this.state.percentOwnedError}</span>
						)}
					</div>

					<div className={'input-row'} style={{marginTop: '1.5rem'}}>
						<span className={'theme-label'}>Interest Type</span>
						<KiRadioGroup
							className={'input-row-radio-group'}
							value={this.state.interestType}
							onChange={value =>
								this.setState({
									interestType: value,
									originalCoupon: this.state.rate / 100,
								})
							}
						>
							<KiRadioButton label="Fixed" value="fixed" />
							<KiRadioButton label="Floating" value="floating" />
						</KiRadioGroup>
					</div>
					{this.state.interestType === 'fixed' && (
						<div className={'input-row'} style={{display: 'flex', flexDirection: 'row'}}>
							<div>
								<span className={'theme-label'}>Rate</span>
								<KiInput
									name="rate"
									value={this.state.rate}
									isNumberMasked={true}
									sendReactEvent={true}
									maskConfig={{suffix: '%', decimalLimit: 9}}
									onChange={value => this.setState({rate: value, originalCoupon: value / 100})}
								/>
							</div>
							<div style={{marginLeft: '5rem'}}>
								<span className={'theme-label'}>Original Coupon</span>
								<KiInput
									name="originalCoupon"
									value={this.state.originalCoupon}
									isNumberMasked={true}
									maskConfig={{decimalLimit: 9}}
									sendReactEvent={true}
									onChange={value => this.setState({originalCoupon: value})}
									disabled
								/>
							</div>
						</div>
					)}
					{this.state.interestType === 'floating' && (
						<div className={'input-row'} style={{display: 'flex', flexDirection: 'row'}}>
							<div>
								<span className={'theme-label'}>Margin</span>
								<KiInput
									name="margin"
									value={this.state.margin}
									isNumberMasked={true}
									sendReactEvent={true}
									maskConfig={{suffix: '%'}}
									onChange={value => this.setState({margin: value})}
								/>
							</div>
							<div style={{marginLeft: '5rem'}}>
								<span className={'theme-label'}>Original Coupon</span>
								<KiInput
									name="originalCoupon"
									value={this.state.originalCoupon}
									isNumberMasked={true}
									maskConfig={{decimalLimit: 9}}
									sendReactEvent={true}
									onChange={value => this.setState({originalCoupon: value})}
								/>
							</div>
							<div style={{marginLeft: '7rem', width: '200px'}}>
								<p style={{marginBottom: '1rem'}} className={'theme-label'}>
									Index
								</p>
								<Select
									value={this.state.indices.filter(option => option._id === this.state.index)}
									options={this.state.indices}
									getOptionValue={opt => opt._id}
									getOptionLabel={opt => opt.displayName}
									onChange={option => this.setState({index: option._id})}
								/>
							</div>
						</div>
					)}
					{this.state.interestType === 'floating' && (
						<div className={'input-row'} style={{width: '400px'}}>
							<span className={'theme-label'}>Reset Date</span>
							<KiSelect
								options={this.props.dates}
								placeholder={'Select Date...'}
								value={this.props.dates.find(date => date.groupId === this.state.couponResetDate)}
								onChange={option => this.setState({couponResetDate: _.get(option, 'groupId', null)})}
								getOptionLabel={option => option.name}
								isClearable={true}
								getOptionValue={option => option.groupId}
							/>
						</div>
					)}
					<div className={'input-row'} style={{marginTop: '2rem', width: '400px'}}>
						<span className={'theme-label'}>Accrual Method</span>
						<Select
							value={accrualMethodOptions.filter(option => option.value === this.state.accrualMethod)}
							options={accrualMethodOptions}
							onChange={option => this.setState({accrualMethod: option ? option.value : option || ''})}
							isClearable={true}
							menuPortalTarget={document.body}
							styles={{menuPortal: base => ({...base, zIndex: 9999})}}
						/>
						{this.state.accrualMethodError && (
							<span className={'theme-label text-error-red'}>{this.state.accrualMethodError}</span>
						)}
					</div>
					<div className={'input-row'} style={{marginTop: '2rem', width: '400px'}}>
						<span className={'theme-label'}>Pay Date</span>
						<KiSelect
							options={this.props.dates}
							placeholder={'Select Date...'}
							value={this.props.dates.find(date => date.groupId === this.state.selectedDateGroup)}
							onChange={option => this.setState({selectedDateGroup: _.get(option, 'groupId', null)})}
							getOptionLabel={option => option.name}
							isClearable={true}
							getOptionValue={option => option.groupId}
						/>
						<p style={{marginTop: '1rem', width: '450px'}} className={'theme-label'}>
							*This is only required if the tranche overrides the deal number of days in period
						</p>
					</div>
					<div className="actions">
						<KiButton flat primary onMouseUp={this.handleCancel} label="Cancel" />
						<KiButton
							raised
							primary
							disabled={
								nameInUse || !this.state.name || this.state.cusipError || this.state.percentOwnedError
							}
							onClick={this.handleSubmit}
							label="Save"
						/>
					</div>
				</div>
			);
	}
}
const mapStateToProps = (state, ownProps) => {
	const setting = state.fundingVehicle.selectedSettings.debt.find(s => s._id === ownProps.match.params.debtId) || {};
	const settingFields = _pick(setting, [
		'_id',
		'name',
		'cusip',
		'isin',
		'classId',
		'trancheNumber',
		'nameId',
		'seniority',
		'payType',
		'balanceType',
		'payDate',
		'interestType',
		'rate',
		'accrualMethod',
		'margin',
		'index',
		'selectedDateGroup',
		'originalCoupon',
		'couponResetDate',
		'percentOwned',
	]);
	return {
		...settingFields,
		debtNames: state.fundingVehicle.selectedSettings.debt.map(({name}) => name),
		fundingVehicleId: ownProps.match.params.id,
		dates: state.datasetDates.dates,
	};
};

const mapDispatchToProps = () => ({
	updateFundingVehicleSetting,
	addFundingVehicleSetting,
	showSnackbar,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps()
)(DebtForm);
