//eslint-disable-next-line no-unused-vars
import {apiUrl, _checkAuth, _handleResponse, _getHeaders, _checkStatus} from 'api/apiUtils';

export const fetchTemplates = datasetId => {
	return fetch(`${apiUrl}/datasets/${datasetId}/manualInputTemplates`, {
		credentials: 'include',
		method: 'GET',
		headers: _getHeaders(),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const addTemplate = template => {
	return fetch(`${apiUrl}/datasets/${template.datasetId}/manualInputTemplates`, {
		credentials: 'include',
		method: 'POST',
		headers: _getHeaders('POST'),
		body: JSON.stringify(template),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export const deleteTemplate = (datasetId, templateId) => {
	return fetch(`${apiUrl}/datasets/${datasetId}/manualInputTemplates/${templateId}`, {
		credentials: 'include',
		method: 'DELETE',
		headers: _getHeaders('DELETE'),
	})
		.then(_checkAuth)
		.then(_checkStatus);
};

export default {
	fetchTemplates,
	addTemplate,
	deleteTemplate,
};
