import React from 'react';
import PropTypes from 'prop-types';

import {cashFlowOptions as CASHFLOW_OPTIONS} from 'ki-common/options/assumptions';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';

import KiCheckbox from 'components/KiCheckbox';
import KiDatePicker from 'components/KiDatePicker';
import KiSelect from 'components/KiSelect';

import FormBlockItemContainer from '../../common/FormBlockItemContainer';

const Cashflow = props => {
	// not purging the time will prevent today from being selected, which is what is wanted
	const minDate = new Date();
	const handleFirstLossToggle = val =>
		props.onChange({
			firstLoss: {
				runMode: val ? CASHFLOW_OPTIONS[0].value : null,
			},
		});

	const handleCashflowChange = option =>
		props.onChange({
			firstLoss: {
				runMode: option.value,
			},
		});

	const handleSettleDateChange = val =>
		props.onChange({
			generalAssumptions: {
				pastSettlesDate: dateToShortDate(val),
			},
		});

	const handleDelete = () =>
		props.onChange({
			cashFlowState: false,
			generalAssumptions: {
				pastSettlesDate: null,
			},
			firstLoss: {
				runMode: null,
			},
		});

	return (
		<FormBlockItemContainer
			onDelete={handleDelete}
			title="Cashflow"
			isVisible={props.isVisible}
			readOnly={props.readOnly}
		>
			<KiCheckbox
				label="Calculate First Loss"
				id="CalcFirstLossChkBox"
				name="Calculate First Loss"
				onChange={!props.readOnly ? handleFirstLossToggle : () => null}
				value={!!props.cashFlow}
				checked={!!props.cashFlow}
			/>
			{!!props.cashFlow && (
				<React.Fragment>
					<label className="form-instruction">Solve For:</label>
					<KiSelect
						readOnly={props.readOnly}
						classNamePrefix="aut-select"
						options={CASHFLOW_OPTIONS}
						onChange={handleCashflowChange}
						value={CASHFLOW_OPTIONS.filter(opt => opt.value === (props.cashFlow?.value || props.cashFlow))}
					/>
				</React.Fragment>
			)}
			<div style={{maxWidth: '250px'}}>
				<KiDatePicker
					label="Settle Date"
					readOnly={props.readOnly}
					minDate={minDate}
					excludeDates={[new Date()]}
					onChange={handleSettleDateChange}
					value={props.settleDate}
				/>
			</div>
		</FormBlockItemContainer>
	);
};

Cashflow.propTypes = {
	onChange: PropTypes.func.isRequired,
	cashFlow: PropTypes.any,
	settleDate: PropTypes.string,
	isVisible: PropTypes.bool,
	readOnly: PropTypes.bool,
};

Cashflow.defaultProps = {
	isVisible: false,
	readOnly: false,
};

export default Cashflow;
