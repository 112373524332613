export const convertAccountsForSave = accountList => {
	const newAccounts = [];
	const deletedAccountsIds = [];
	accountList.forEach(account => {
		if (account.deleted) {
			deletedAccountsIds.push(account._id);
		} else {
			delete account.visible;
			delete account.valid;
			delete account.deleted;
			delete account.modified;
			delete account.originalIndex;
			delete account.isUsed;
			newAccounts.push(account);
		}
	});

	return {accounts: newAccounts, deletedAccountsIds};
};

export const filterAccountList = (term, list) => {
	const fields = ['type', 'description', 'code'];
	list.forEach(item => {
		let searchString = '';
		fields.forEach(field => {
			if (item[field] !== null) {
				searchString += item[field];
			}
		});
		item.visible = searchString.toLowerCase().includes(term.toLowerCase());
	});
	return list && list.length ? list : [];
};

export const isAccountValid = account => {
	const properties = ['type', 'description', 'code'];
	return !properties.some(prop => account[prop].toString().trim() === '');
};

export const convertsAccountsToList = accounts => {
	return accounts.map((account, index) => ({
		...account,
		visible: true,
		valid: isAccountValid(account),
		deleted: false,
		modified: false,
		originalIndex: account._id ? index : null,
	}));
};

export const ACCOUNTS_IN_USE_ERROR_CODE = 'ACCOUNTS_IN_USE';
