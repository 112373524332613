import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Select from 'react-select';
import {KiCreatable} from 'components/KiSelect';
import KiCheckbox from 'components/KiCheckbox';
import KiInput from 'components/KiInput';
import options from 'ki-common/options';
import calcColumns from 'ki-common/utils/calcColumns';
import KiButton from 'components/KiButton';
import CondCaseStatementRow from './components/CondCaseStatementRow';
import ColConstantFuncPicker from './components/ColConstFuncPicker';
import uuidV4 from 'uuid/v4';
import _ from 'lodash';
import CalculationOverview from './components/ConditionOverview';

export class ConditionalForm extends Component {
	static propTypes = {
		submitMethod: PropTypes.func.isRequired,
		columnList: PropTypes.array.isRequired,
		existingTags: PropTypes.array.isRequired,
		columnToEdit: PropTypes.object,
		mode: PropTypes.string,
		closeForm: PropTypes.func,
		isAdmin: PropTypes.bool,
		reloadView: PropTypes.func,
		schemaList: PropTypes.array.isRequired,
		getAggregationOptions: PropTypes.func,
	};

	static defaultProps = {
		existingTags: [],
	};

	state = {
		displayName: '',
		outputDataType: null,
		displayFormat: null,
		nameError: '',
		tags: [],
		assetColumns: [],
		conditionRows: [],
		elseType: null,
		elseValue: null,
		thenType: null,
		thenValue: null,
		conditions: [],
		isGlobal: this.props.columnToEdit && this.props.columnToEdit.createdBy === '' ? true : false,
		selectedSchema: null,
		schemaColumns: [],
		selectedAggregate: null,
	};

	UNSAFE_componentWillMount() {
		const {columnToEdit} = this.props;
		const assetColumns = this.props.columnList.filter(col => {
			if (!!col.formula && columnToEdit && columnToEdit._id) {
				return (
					col.columnType === 'asset' &&
					col._id !== columnToEdit._id &&
					(_.get(columnToEdit, 'schemaId', '') !== '' ? !col.isSubaccount : true)
				);
			} else {
				return (
					col.columnType === 'asset' &&
					(_.get(columnToEdit, 'schemaId', '') !== '' ? !col.isSubaccount : true)
				);
			}
		});

		// const allColumns = this.props.columnList.filter(col => col._id !== _.get(columnToEdit, '_id') && col.columnType === 'asset');
		this.setState(
			{
				assetColumns: assetColumns,
			},
			() => {
				if (columnToEdit) {
					this.populateColumnState();
				}
			}
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!_.isEqual(this.props.columnList, nextProps.columnList)) {
			this.setState({
				assetColumns: nextProps.columnList.filter(
					col =>
						col.columnType === 'asset' &&
						col._id !== _.get(nextProps, 'columnToEdit._id') &&
						(this.state.selectedSchema !== null ? !col.isSubaccount : true)
				),
			});
		}
		if (!_.isEqual(this.props.schemaList, nextProps.schemaList)) {
			const selectedSchema = nextProps.schemaList.find(s => s._id === _.get(nextProps, 'columnToEdit.schemaId'));
			const schemaColumns = selectedSchema ? selectedSchema.schemaColumns : [];
			this.setState({
				selectedSchema: selectedSchema,
				schemaColumns: schemaColumns,
			});
		}
	}

	populateColumnState = () => {
		const {columnToEdit} = this.props;
		const selectedSchema = this.props.schemaList.find(s => s._id === columnToEdit.schemaId);
		const schemaColumns = selectedSchema ? selectedSchema.schemaColumns : [];
		const formulaData = calcColumns.parseConditionalColumn(columnToEdit.formula, schemaColumns);
		let dataType = columnToEdit.dataType;
		if (formulaData.elseData.type.value === 'column') {
			dataType = formulaData.elseData.value.dataType;
		}
		if (formulaData.elseData.type.value === 'constant') {
			dataType = calcColumns.getConstantType(formulaData.elseData.value);
		}
		//TODO: tech debt fix this issue upstream in the parser
		formulaData.conditions = formulaData.conditions.map(cond => {
			cond.caseStatements = cond.caseStatements.map((cs, idx) => {
				cs.id = uuidV4();
				if (idx === cond.caseStatements.length - 1) {
					cs.logic = null;
				}
				return cs;
			});
			return cond;
		});
		this.setState(
			{
				displayName: columnToEdit.displayName,
				displayFormat: options.numericFormats.find(opt => opt.value === columnToEdit.displayFormat),
				tags: columnToEdit.tags,
				conditions: formulaData.conditions,
				elseType: formulaData.elseData.type,
				elseValue: formulaData.elseData.value,
				outputDataType: options.conditionalOutputDataTypes.find(op => op.value === dataType) || dataType,
				selectedSchema: selectedSchema,
				schemaColumns: schemaColumns,
				selectedAggregate: this.props
					.getAggregationOptions(columnToEdit.dataType)
					.find(option => option.value === columnToEdit.aggregation),
			},
			() => {
				this.setState({
					displayFormat: this.getDisplayFormats().find(op => op.value === columnToEdit.displayFormat),
				});
			}
		);
	};

	getSaveAndFormatDisabled = () => {
		const {
			conditions,
			elseType,
			elseValue,
			displayName,
			displayFormat,
			outputDataType,
			selectedSchema,
			selectedAggregate,
		} = this.state;
		if (elseValue && elseValue.type === 'column' && elseValue.value.dataType !== outputDataType.value) {
			return true;
		}
		if (elseValue && elseType.value === 'constant') {
			if (outputDataType.value === 'numeric' && isNaN(elseValue)) {
				return true;
			}
			if (outputDataType.value === 'string' && !isNaN(elseValue)) {
				return true;
			}
		}

		// if a schema is selected an aggregation must also be
		const validSchema = selectedSchema ? !!selectedAggregate : true;

		return (
			!conditions.length ||
			!elseValue ||
			!displayName.trim().length ||
			!validSchema ||
			(!displayFormat && outputDataType.value !== 'string' && outputDataType.value !== 'date_long')
		);
	};

	setName = val => {
		this.setState({
			displayName: val,
			nameError: val.trim().length ? null : "Name can't be blank",
		});
	};

	isDuplicateNameError = () => {
		// Should be able to kepe the same name when editing a column
		if (_.get(this, 'props.columnToEdit.displayName') === this.state.displayName) {
			return;
		}
		const summaryCols = this.props.columnList.filter(
			col => (!!col.formula || !!col.asOfDateColumn) && col.columnType === 'asset'
		);
		// Check if the name already exists in summaryCols
		return summaryCols.find(col => _.get(col, 'displayName').trim() === this.state.displayName.trim());
	};

	onSubmit = () => {
		const {columnToEdit} = this.props;
		const {
			displayName,
			tags,
			displayFormat,
			conditions,
			elseType,
			elseValue,
			isGlobal,
			outputDataType,
			selectedSchema,
			selectedAggregate,
		} = this.state;
		this.setState({isSaving: true});
		if (!displayName.trim()) {
			this.setState({
				nameError: "Name can't be blank",
				isSaving: false,
			});
			return;
		}
		if (this.isDuplicateNameError()) {
			this.setState({nameError: 'Name is already in use', isSaving: false});
			return;
		} else {
			this.setState({nameError: ''});
		}

		const columnForSaving = {
			_id: columnToEdit ? columnToEdit._id : null,
			columnName: displayName,
			displayName: displayName,
			tags: tags,
			displayFormat: _.get(displayFormat, 'value', 'string'),
			dataType: outputDataType.value,
			columnType: 'asset',
			conditions: conditions,
			elseData: {
				type: elseType,
				value: elseValue._id || elseValue,
			},
			columnFormType: 'conditional',
			formula: null,
			createdBy: columnToEdit ? columnToEdit.createdBy : null,
			isGlobal: isGlobal,
		};
		if (selectedSchema) {
			columnForSaving.schemaId = selectedSchema._id;
			columnForSaving.aggregation = selectedAggregate.value;
		}
		return this.props.submitMethod(columnForSaving);
	};

	onConditionChange = (val, idx = -1) => {
		const conditionRows = [...this.state.conditionRows];
		if (idx < 0) {
			val.id = uuidV4();
			conditionRows.push(val);
		} else {
			conditionRows[idx] = val;
		}
		this.setState({conditionRows: conditionRows});
	};

	onConditionDelete = id => {
		let {conditionRows} = this.state;
		conditionRows = conditionRows.filter(c => c.id !== id);
		conditionRows[conditionRows.length - 1].logic = null;
		this.setState({conditionRows: conditionRows});
	};

	resetForm = () => {
		this.setState({
			conditionId: null,
			thenType: null,
			thenValue: '',
			conditionRows: [],
		});
	};

	onAddCondition = () => {
		const {conditionRows, conditions, thenType, thenValue} = this.state;
		if (this.state.conditionId) {
			this.setState({
				conditions: this.state.conditions.reduce((acc, c) => {
					if (c.id === this.state.conditionId) {
						return [
							...acc,
							{
								...c,
								caseStatements: [...conditionRows],
								then: {
									type: thenType,
									value: thenValue,
								},
							},
						];
					}
					return [...acc, c];
				}, []),
			});
		} else {
			const newCondition = {
				id: uuidV4(),
				caseStatements: [...conditionRows],
				then: {
					type: thenType,
					value: thenValue,
				},
			};
			this.setState({conditions: [...conditions, newCondition]});
		}
		this.resetForm();
	};

	getAddConditionDisabled = () => {
		const {conditionRows, thenType, thenValue, outputDataType} = this.state;
		if (!thenValue || !conditionRows.length) {
			return true;
		}
		if (thenValue && thenValue.type === 'column' && thenValue.value.dataType !== outputDataType.value) {
			return true;
		}
		if (thenValue && thenType.value === 'constant') {
			if (outputDataType.value === 'numeric' && isNaN(thenValue)) {
				return true;
			}
			if (outputDataType.value === 'string' && !isNaN(thenValue)) {
				return true;
			}
		}
		return conditionRows.find((row, idx) => {
			if (idx === conditionRows.length - 1) {
				return !row.operator || !row.operands[0].value || !row.operands[1].value;
			}
			return !row.operator || !row.logic || !row.operands[0].value || !row.operands[1].value;
		})
			? true
			: false;
	};

	getDisplayFormats = () => {
		const {outputDataType} = this.state;
		if (!outputDataType) {
			return options.numericFormats;
		}
		switch (outputDataType.value) {
			case 'numeric':
				return options.numericFormats;
			case 'date_long':
				return options.dateFormats;
			default:
				return options.textFormats;
		}
	};

	getCalculationOverviewError = () => {
		const {outputDataType, conditions} = this.state;
		let thenError = '';
		if (!outputDataType) {
			thenError;
		}
		conditions.forEach(condition => {
			if (condition.then.type.value === 'column' && condition.then.value.dataType !== outputDataType.value) {
				thenError = `All then values must be type ${
					options.conditionalOutputDataTypes.find(op => op.value === outputDataType.value).label
				}`;
			}
			if (
				condition.then.type.value === 'constant' &&
				isNaN(condition.then.value) &&
				outputDataType.value === 'numeric'
			) {
				thenError = 'All then values must be type number';
			}
			if (
				condition.then.type.value === 'constant' &&
				!isNaN(condition.then.value) &&
				outputDataType.value === 'string'
			) {
				thenError = 'All then values must be type string';
			}
		});
		return thenError;
	};

	filterColumns = (columns, dataType) => {
		return columns.filter(col => {
			if (dataType === 'date_long') {
				return col.dataType === 'date_long' || col.dataType === 'date_short';
			}
			return col.dataType === dataType;
		});
	};

	handleSchemaChange = schema => {
		const assetColumns = this.props.columnList.filter(
			col =>
				col.columnType === 'asset' &&
				col._id !== _.get(this.props, 'columnToEdit._id') &&
				(schema !== null ? !col.isSubaccount : true)
		);
		this.setState({
			selectedSchema: schema,
			schemaColumns: schema ? schema.schemaColumns : [],
			conditionRows: [],
			conditions: [],
			elseValue: null,
			elseType: null,
			assetColumns: assetColumns,
		});
	};

	handleAggregationChange = aggregation => {
		this.setState({selectedAggregate: aggregation});
	};

	render() {
		const {
			displayName,
			nameError,
			displayFormat,
			conditionRows,
			assetColumns,
			thenType,
			thenValue,
			elseType,
			elseValue,
			outputDataType,
			selectedSchema,
			schemaColumns,
			selectedAggregate,
			isSaving,
		} = this.state;
		return (
			<section className="column-selector-form inline-column-form">
				<div>
					<section className="calculation-name-tags">
						<KiInput
							label="Name"
							value={displayName}
							onChange={val => this.setName(val)}
							error={nameError}
						/>
						<div className="calculation-form-section no-top-margin">
							<span className="form-instruction">Tags:</span>
							<KiCreatable
								classNamePrefix="aut-select"
								isMulti={true}
								options={this.props.existingTags.map(t => ({
									value: t,
									label: t,
								}))}
								value={this.state.tags.map(t => ({
									value: t,
									label: t,
								}))}
								onChange={val => this.setState({tags: val.map(t => t.value)})}
								placeholder="Add some tags"
								noResultsText={false}
							/>
						</div>
						<div className="calculation-form-section">
							<span className="form-instruction">Output Data Type:</span>
							<Select
								classNamePrefix="aut-select"
								value={outputDataType}
								isDisabled={outputDataType ? true : false}
								isClearable={false}
								options={options.conditionalOutputDataTypes}
								onChange={selected =>
									this.setState({
										outputDataType: selected,
										displayFormat: null,
									})
								}
							/>
						</div>
						{outputDataType && (
							<div className="calculation-form-section">
								<span className="form-instruction">Choose a Schema:</span>
								<Select
									classNamePrefix="aut-select"
									options={this.props.schemaList}
									value={selectedSchema}
									getOptionLabel={option => option.name}
									getOptionValue={option => option._id}
									isClearable={true}
									onChange={selected => this.handleSchemaChange(selected)}
									onBlur={() => this.setState({schemaError: ''})}
								/>
							</div>
						)}
						{outputDataType &&
							selectedSchema && (
								<div className="calculation-form-section">
									<span className="form-instruction">Select an Aggregation Type</span>
									<Select
										classNamePrefix="aut-select"
										value={selectedAggregate}
										isClearable={true}
										options={this.props.getAggregationOptions(_.get(outputDataType, 'value'))}
										onChange={selected => this.handleAggregationChange(selected)}
									/>
								</div>
							)}
						{!['string', 'date_long', 'date_short'].includes(_.get(outputDataType, 'value', null)) ? (
							<div className="calculation-form-section">
								<span className="form-instruction">Format:</span>
								<Select
									classNamePrefix="aut-select"
									isDisabled={!outputDataType}
									value={displayFormat}
									isClearable={false}
									options={this.getDisplayFormats()}
									onChange={selected => this.setState({displayFormat: selected})}
								/>
							</div>
						) : null}
					</section>
					{outputDataType ? (
						<div>
							<section className="form-instruction-box">
								<header className="form-instruction bold">
									<span>Condition:</span>
									{!!this.state.conditionId && (
										<i className="material-icons" onClick={() => this.resetForm()}>
											close
										</i>
									)}
								</header>
								{conditionRows.map((condition, idx) => {
									return (
										<CondCaseStatementRow
											key={idx}
											index={idx}
											columns={assetColumns}
											onChange={val => this.onConditionChange(val, idx)}
											onDelete={this.onConditionDelete}
											condition={condition}
											reloadView={this.props.reloadView}
											schemaColumns={this.state.schemaColumns}
										/>
									);
								})}
								{!conditionRows.length || conditionRows[conditionRows.length - 1].logic ? (
									<CondCaseStatementRow
										columns={assetColumns}
										onChange={this.onConditionChange}
										onDelete={this.onConditionDelete}
										condition={null}
										reloadView={this.props.reloadView}
										schemaColumns={this.state.schemaColumns}
									/>
								) : null}
								<div className="add-condition">
									<span>
										<span className="form-instruction bold">Then...</span>
										<ColConstantFuncPicker
											onChange={(type, value) =>
												this.setState({
													thenType: type,
													thenValue: value,
												})
											}
											columns={this.filterColumns(assetColumns, outputDataType.value)}
											outputDataType={outputDataType.value}
											type={thenType}
											value={thenValue}
											log="Then..."
											reloadView={this.props.reloadView}
											schemaColumns={this.filterColumns(schemaColumns, outputDataType.value)}
										/>
									</span>
									<KiButton
										disabled={this.getAddConditionDisabled()}
										raised
										primary
										onClick={() => this.onAddCondition()}
									>
										{this.state.conditionId ? 'Save' : 'Add'} Condition
									</KiButton>
								</div>
							</section>
							<section className="form-instruction-box">
								<span className="form-instruction bold">Else...</span>
								<ColConstantFuncPicker
									onChange={(type, value) =>
										this.setState({
											elseType: type,
											elseValue: value,
										})
									}
									columns={this.filterColumns(assetColumns, outputDataType.value)}
									outputDataType={outputDataType.value}
									type={elseType}
									value={elseValue}
									log="Else..."
									reloadView={this.props.reloadView}
									schemaColumns={this.filterColumns(schemaColumns, outputDataType.value)}
								/>
							</section>
							<p
								className={!this.getCalculationOverviewError().length ? 'hide' : ''}
								style={{
									color: '#de3226',
									padding: '1rem',
									border: '1px solid #de3226',
									marginTop: '1rem',
								}}
							>
								{this.getCalculationOverviewError()}
							</p>
							<CalculationOverview
								conditions={this.state.conditions}
								onDeleteClick={id => {
									this.setState({
										conditions: this.state.conditions.filter(c => c.id !== id),
									});
								}}
								onMoveUpClick={id => {
									const idx = this.state.conditions.findIndex(c => c.id === id);
									if (idx !== -1 && idx !== 0) {
										const conditionsList = this.state.conditions.slice();
										conditionsList.splice(idx - 1, 0, conditionsList[idx]);
										conditionsList.splice(idx + 1, 1);
										this.setState({
											conditions: conditionsList,
										});
									}
								}}
								onMoveDownClick={id => {
									const idx = this.state.conditions.findIndex(c => c.id === id);
									if (idx !== -1 && idx !== this.state.conditions.length + 1) {
										const conditionsList = this.state.conditions.slice();
										conditionsList.splice(idx + 2, 0, conditionsList[idx]);
										conditionsList.splice(idx, 1);
										this.setState({
											conditions: conditionsList,
										});
									}
								}}
								onEditClick={id => {
									const match = this.state.conditions.find(c => c.id === id);
									if (match) {
										this.setState({
											conditionId: id,
											conditionRows: [...match.caseStatements],
											thenType: match.then.type,
											thenValue: match.then.value,
										});
									}
								}}
								resetForm={this.resetForm}
								selectedId={this.state.conditionId}
								elseValue={_.get(elseValue, 'displayName', elseValue)}
							/>
						</div>
					) : null}
					{this.props.mode === 'inline' &&
						this.props.isAdmin &&
						!_.get(this, 'props.columnToEdit._id', null) && (
							<div className="calculation-form-section">
								<KiCheckbox
									name="isGlobal"
									checked={this.state.isGlobal}
									label="Make Global"
									onChange={val => this.setState({isGlobal: val})}
								/>
							</div>
						)}
				</div>
				<section className="format-and-save">
					<div className="inline-column-form-buttons">
						<KiButton flat primary onClick={() => this.props.closeForm()}>
							Cancel
						</KiButton>
						<KiButton
							disabled={this.getSaveAndFormatDisabled() || isSaving}
							raised
							primary
							onClick={() => this.onSubmit()}
						>
							Save
						</KiButton>
					</div>
				</section>
			</section>
		);
	}
}

export default ConditionalForm;
