const _sortBy = require('lodash/sortBy');
/**
 *
 * @param {object[]} [columns]
 * @param {string} dataColumn
 * @param {boolean} [isNew]
 * @param {boolean} [isGrouped]
 * @param {boolean} [onlyNumeric]
 * @param {string[]} [eligibleColumns]
 */
export const getCohortColumns = (columns = [], dataColumn, isNew, isGrouped, onlyNumeric, eligibleColumns = []) =>
	// _sortBy to sort them by displayName, so they're not just thrown in the list in random order
	_sortBy(
		columns.reduce((acc, c) => {
			// if numeric only, omit any non-numerics
			if (onlyNumeric && c.dataType !== 'numeric') return acc;
			// if groups and filtering out eligible columns, omit
			if (isGrouped && eligibleColumns.includes(c._id)) return acc;
			// if new, and columnType doesn't match aggregate/asset, omit
			if (isNew && c.columnType !== (isGrouped ? 'aggregate' : 'asset')) return acc;
			// if existing, and matching dataColumn doesn't match type, omit
			if (!isNew && columns.find(c => c._id === dataColumn)?.columnType !== c.columnType) return acc;
			return [...acc, c];
		}, []),
		'displayName'
	);

export default {
	getCohortColumns,
};
