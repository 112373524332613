const actionTypes = [
	{
		value: 'pool_assign',
		label: 'Assign Pool',
	},
	{
		value: 'pool_unassign',
		label: 'Unassign Pool',
	},
	{
		value: 'pool_activate',
		label: 'Activate Pool',
	},
	{
		value: 'pool_deactivate',
		label: 'Deactivate Pool',
	},
	{
		value: 'pool_update',
		label: 'Update Pool',
	},
];

const entityTypes = [
	{
		value: 'pool',
		label: 'Pools',
	},
];

module.exports = {
	actionTypes,
	entityTypes,
};
