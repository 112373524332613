import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import KiAppBar from 'components/KiAppBar';
import KiIconButton from 'components/KiIconButton';
import ScenarioFormModal from './Scenarios/components/ScenarioFormModal';
import {SCENARIO_DISPLAY_MODES} from '../reducer';

function TopBar({scenarioDisplayMode}) {
	const [modalVisible, setModalVisible] = useState(false);
	const tab = 'Forecasting Scenarios';
	return (
		<>
			<header>
				<KiAppBar className="top-bar">
					<div className="top-bar-breadcrumb">
						<h1>{tab}</h1>
					</div>
					{scenarioDisplayMode === SCENARIO_DISPLAY_MODES.LIST && (
						<KiIconButton
							icon="add_circle"
							inverse
							onClick={() => setModalVisible(true)}
							title="Create a new scenario"
						/>
					)}
				</KiAppBar>
			</header>
			{/* not using isActive to let component reset and pull fresh data again */}
			{modalVisible && <ScenarioFormModal isActive={true} onClose={() => setModalVisible(false)} />}
		</>
	);
}

TopBar.propTypes = {
	scenarioDisplayMode: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
	scenarioDisplayMode: state.forecasting.scenarioDisplayMode,
});

export default connect(mapStateToProps)(TopBar);
